import React, { useState, useEffect } from 'react'
import * as yup from 'yup'
import moment from 'moment';
import { urlRegex, phoneRegex, emailRegex } from '../../../constant/regularExpression';
import { useFormik } from 'formik';
import {
    TextField, Grid, Button,
    Select,
    FormControl, InputLabel, MenuItem
} from '@material-ui/core'
import { useGetOrderSldIdMutation } from '../../../services/services'
export default function AddCourieDetails({ AddData, setDialog }) {
    const [sldId, setSldId] = useState("");
    const [count, setCount] = useState(0);
    const [OrderId, setOrderType] = useState(0);
    const [Product, setProduct] = useState([])
    const [orderSldId, orderSldIdInfo] = useGetOrderSldIdMutation();
    const getOrderSld = orderSldIdInfo.data
    const formik = useFormik({

        initialValues: {
            sld_order_id: "",
            order_id: "",
            tracking_status: "DISPATCHED",
            tracking_description: "",
            tracking_id: "",
            courier_company: "",
            tracking_url: "",
            courier_details: "",
            courier_date: "",
            courier_amount_paid: "",
            courier_weight: "",
            tracking_support_number: "",
            tracking_email: "",
        },
        validationSchema: yup.object({
            // sld_order_id:yup.string().required("Required!"),
            order_id:yup.string().required("Required!"),
            //tracking_status: yup.string().required("Required!"),
            tracking_description: yup.string().required("Required!"),
            tracking_id: yup.string().required("Required!"),
            courier_company: yup.string().required("Required!"),
            tracking_url: yup.string().matches(urlRegex,'Enter correct url!').required("Required!"),
            tracking_support_number: yup.string().matches(phoneRegex,'Enter correct mobile number!').required("Required!"),
            tracking_email: yup.string().matches(emailRegex,'Enter correct email!'),
            courier_details: yup.string().required("Required!"),
            courier_date: yup.string().required("Required!"),
            courier_amount_paid: yup.number().required("Required!"),
            courier_weight: yup.number().required("Required!"),
        }),
        onSubmit: (values) => {
            let startDate = moment(values.courier_date).format('YYYY-MM-DD HH:mm:ss')
            values.courier_date = startDate
            values.sld_order_id = sldId
            AddData(values)

        },
    })
    const [disabled, setDisabled] = React.useState(true);
    const dialogclose = () => {
        setDialog(false)
    }

    useEffect(() => {
        if(count){
            orderSldId(sldId)
        }
    }, [count])

    useEffect(() => {
        let { isSuccess, data } = orderSldIdInfo;
        if (isSuccess) {
            setProduct(data.data)
            setDisabled(false)
        }
    }, [orderSldIdInfo])

    const getOrderDetails = () => {
        if(sldId){
            setCount(count + 1)
        }else{
            return
        }
    }
    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3} fullWidth={true}>
                    <Grid item xs={6}>
                        <TextField
                            name="sld_order_id"
                            onChange={(e) => {
                                setSldId(e.target.value)
                                formik.setFieldValue("sld_order_id",e.target.value)
                            }}
                            fullWidth={true}
                            label="Sld Order Id"
                            variant="outlined"
                            size="large"
                            value={sldId} />
                        {!sldId && formik.touched.sld_order_id && <span style={{ color: "red", fontSize: "12px" }}>Required!</span>}

                    </Grid>
                    <Grid item xs={6}>
                        <Button 
                        size="large" 
                        variant="contained" color="primary"  onClick={(e) => getOrderDetails()} >get details</Button>
                    </Grid>
                    {getOrderSld && (Product.length ?
                        <>
                            <Grid item xs={6}>
                                <FormControl style={{ margin: 0 }} fullWidth={true} variant="outlined">
                                    <InputLabel htmlFor="outlined-Institue-Name-native-simple">Order type</InputLabel>
                                    <Select
                                        name='order_id'
                                        onBlur={formik.handleBlur}
                                        value={OrderId}
                                        onChange={(e) => {
                                            formik.setFieldValue("order_id",e.target.value)
                                            setOrderType(e.target.value)
                                        }}
                                        label="Order type">
                                        {Product.length && Product[0].order_detail.map((data,ind) => (<MenuItem key={ind} value={data.order_id}> {data.order_title}</MenuItem>))}
                                    </Select>
                                </FormControl>
                                {formik.touched.order_id && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.order_id}</span>}

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="tracking_description"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.tracking_description}
                                    fullWidth={true}
                                    disabled={disabled}

                                    label="tracking description"
                                    variant="outlined"
                                    size="large" />
                                {formik.touched.tracking_description && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.tracking_description}</span>}

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="tracking_id"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.tracking_id}
                                    fullWidth={true}
                                    disabled={disabled}

                                    label="tracking id"
                                    variant="outlined"
                                    size="large" />
                                {formik.touched.tracking_id && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.tracking_id}</span>}

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="courier_company"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.courier_company}
                                    fullWidth={true}
                                    disabled={disabled}

                                    label="courier company"
                                    variant="outlined"
                                    size="large" />
                                {formik.touched.courier_company && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.courier_company}</span>}

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="tracking_url"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.tracking_url}
                                    fullWidth={true}
                                    disabled={disabled}

                                    label="tracking url"
                                    variant="outlined"
                                    size="large" />
                                {formik.touched.tracking_url && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.tracking_url}</span>}

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="courier_details"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.courier_details}
                                    fullWidth={true}
                                    disabled={disabled}

                                    label="courier details"
                                    variant="outlined"
                                    size="large" />
                                {formik.touched.courier_details && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.courier_details}</span>}

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="tracking_support_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.tracking_support_number}
                                    fullWidth={true}
                                    disabled={disabled}

                                    label="tracking support number"
                                    variant="outlined"
                                    size="large" />

                                {formik.touched.tracking_support_number && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.tracking_support_number}</span>}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="tracking_email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.tracking_email}
                                    fullWidth={true}
                                    disabled={disabled}

                                    label="tracking email"
                                    variant="outlined"
                                    size="large" />

                                {formik.touched.tracking_email && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.tracking_email}</span>}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="courier_date"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.courier_date}
                                    fullWidth={true}
                                    disabled={disabled}

                                    // label="courier date"
                                    type="datetime-local"
                                    variant="outlined"
                                    size="large" />
                                {formik.touched.courier_date && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.courier_date}</span>}

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="courier_amount_paid"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.courier_amount_paid}
                                    fullWidth={true}
                                    disabled={disabled}

                                    label="courier amount paid"
                                    variant="outlined"
                                    size="large" />

                                {formik.touched.courier_amount_paid && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.courier_amount_paid}</span>}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="courier_weight"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.courier_weight}
                                    fullWidth={true}
                                    disabled={disabled}

                                    label="courie weight (KG)"
                                    variant="outlined"
                                    size="large" />

                                {formik.touched.courier_weight && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.courier_weight}</span>}
                            </Grid>
                            <Grid item xs={12} className="mt-5">
                                <div className="d-flex justify-content-end">
                                    <Button
                                        //  disabled ={disabled}

                                        onClick={dialogclose}
                                        variant="contained"
                                        color="secondary"
                                    >Close</Button>
                                    <Button
                                        disabled={disabled}

                                        type="submit"
                                        style={{ marginLeft: 20 }}
                                        variant="contained"
                                        color="primary"
                                    > Save</Button>
                                </div>
                            </Grid>

                        </>
                        :<Grid item xs={12} className="text-center text-light bg-secondary rounded my-2" > ORDER DATA NOT FOUND </Grid>)
                    }
                </Grid>

            </form>
        </div >
    )
}
