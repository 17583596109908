import React, { forwardRef } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// ** MUI Imports
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Grid, TablePagination } from "@material-ui/core";

const CustomInput = forwardRef((props, ref) => {
  const startDate =
    props.start !== null
      ? moment(props.start).utcOffset("+05:30").format("DD-MM-YYYY")
      : "";
  const endDate =
    props.end !== null
      ? ` - ${moment(props.end).utcOffset("+05:30").format("DD-MM-YYYY")}`
      : null;
  const value = `${startDate}${endDate !== null ? endDate : ""}`;
  if (props.start === null && props.dates.length && props.setDates) {
    props.setDates([]);
  }
  // props.start === null && props.dates.length && props.setDates ? props.setDates([]) : null
  const updatedProps = { ...props };
  delete updatedProps.setDates;
  return (
    <TextField
      fullWidth
      inputRef={ref}
      {...updatedProps}
      label={props.label || ""}
      value={value}
    />
  );
});

const DataTableHeader = (props) => {
  // ** Props
  const {
    searchValue,
    setSearchValue,
    endDateRange,
    startDateRange,
    dates,
    setDates,
    setStartDateRange,
    setEndDateRange,
    isSearch,
    isPagination,
  } = props;

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(+event.target.value);
    props.setPage(0);
  };

  const handleOnChangeRange = (dates) => {
    const [start, end] = dates;
    if (start !== null && end !== null) {
      setDates(dates);
    }
    setStartDateRange(start);
    setEndDateRange(end);
  };

  return (
    <Grid container justifyContent="space-between" className="px-3 py-3">
      {/* <Grid item xs={3}>
                <DatePicker
                    isClearable
                    selectsRange
                    monthsShown={2}
                    endDate={endDateRange}
                    selected={startDateRange}
                    startDate={startDateRange}
                    shouldCloseOnSelect={false}
                    id='date-range-picker-months'
                    onChange={handleOnChangeRange}
                    customInput={
                        <CustomInput
                            dates={dates}
                            setDates={setDates}
                            label='Search by Date..'
                            end={endDateRange}
                            start={startDateRange}
                        />
                    }
                />
            </Grid> */}
      {!props?.isSearch && (
        <Grid item xs={3} className="pt-3">
          <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
            <TextField
              fullWidth
              size="small"
              value={searchValue}
              sx={{ mr: 4, mb: 2 }}
              placeholder="Search..."
              onChange={(e) => setSearchValue(e.target.value)}
              variant="standard"
            />
          </Box>
        </Grid>
      )}
      {/* {!props.isPagination && ( */}
      {!props?.isPagination && (
        <Grid item xs={3}>
          <TablePagination
            rowsPerPageOptions={[0, 10, 25, 100]}
            component="div"
            count={props.totalItem}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={(e, newPage) => {
              props.setPage(newPage);
            }}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      )}

      {/* )} */}
    </Grid>
  );
};

export default DataTableHeader;
