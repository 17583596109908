import React, { useState, useEffect } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import AddDataHeader from "../AddDataHeader";
import DataNotFound from "../DataNotFound";
import FormDialog from "../reusableComponents/NewFormDialog";
import { useGetAllBatchQuery, useAddBatchMutation, useUpdateBatchMutation, useDeleteBatchMutation } from "../../../../services/offlineModuleServices/batchServices";
import AddEditBatchDetails from "./AddEditBatchDetails";


export default function Batch() {

    let [getid, setGetid] = useState("");
    const [data, setData] = useState("");
    const [addDialog, setAddDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [render, setRender] = useState(false)


    const { data: AllBatches, isAllBatchesFetching, isAllBatchesLoading } = useGetAllBatchQuery({
        orderBy: "",
        page: "",
        limit: ""
    })
    const [addBatch, addBatchInfo] = useAddBatchMutation()
    const [updateBatch, updateBatchInfo] = useUpdateBatchMutation()
    const [deleteBatch, deleteBatchInfo] = useDeleteBatchMutation()


    const columns = [
        {
            id: "SNo",
            label: "S.No",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "batchName",
            label: "Batch Name",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "students",
            label: "No Of Students",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "batchStartDate",
            label: "Batch Start Date",
            minWidth: 120,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "endDate",
            label: "End Date",
            minWidth: 120,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "newAdmissionDate",
            label: "New Admission Date",
            minWidth: 120,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "time",
            label: "Time",
            minWidth: 120,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "totalFees",
            label: "Total Fess",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "subject",
            label: "Subject",
            minWidth: 200,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "type",
            label: "Type",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "lecturePlayed",
            label: "Lecture Played",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "hallNo",
            label: "Hall NO.",
            minWidth: 90,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "facultyName",
            label: "Faculty Name",
            minWidth: 200,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "level",
            label: "Level",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "course",
            label: "Course",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "center",
            label: "Center",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "actions",
            label: "Actions",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
    ];

    function createData(SNo, batchName, students, batchStartDate, endDate, newAdmissionDate, time, totalFees, subject, type, lecturePlayed, hallNo, facultyName, level, course, center, actions) {
        return {
            SNo,
            batchName,
            students,
            batchStartDate,
            endDate,
            newAdmissionDate,
            time,
            totalFees,
            subject,
            type,
            lecturePlayed,
            hallNo,
            facultyName,
            level,
            course,
            center,
            actions
        };
    }

    let rows = [];
    const totalItem = AllBatches ? AllBatches.totalItem : 0;

    let totalData = "ok";

    useEffect(() => {
        getid && deleteBatch(getid) && setGetid("");
    }, [getid]);

    if (
        AllBatches
    ) { totalData = totalItem 
        rows = AllBatches.data.map((ele, index) => {
            return createData(
                index + 1,
                ele.batch_name,
                ele.no_of_student,
                ele.batch_start_date,
                ele.batch_end_date,
                ele.batch_new_admission,
                ele.batch_timing,
                ele.fees,
                ele.subject.map((ele, index) => ele.subject_name).join(" , "),
                ele.category_name,
                ele.lecture_played,
                ele.hall_name,
                ele.faculty.map((ele) => ele.faculty_name).join(" , "),
                ele.level_name,
                ele.course_name,
                ele.center_name,

                <div className="d-flex">
                    <EditDelete
                        setEditDialog={setEditDialog}
                        type="edit"
                        setData={setData}
                        data={ele}
                    />
                    <EditDelete type="delete" setGetid={setGetid} id={ele._id} />
                </div>
            );
        });
    }


    const dialogclose = () => {
        setEditDialog(false);
        setAddDialog(false);
        setRender(!render);
    };



    return (
        <div>
            {/* {!viewDialog && ( */}
            <AddDataHeader
                addFormName={"Add New Batch"}
                isSearch={true}
                isPagination={true}
                setAddDialog={setAddDialog}
                isButton={true}
                Adddialog={addDialog}
                form={
                    <AddEditBatchDetails
                        onSubmit={(dataForAdd) => { addBatch(dataForAdd) }}
                        formName="ADD BATCH"
                        setOpen={setAddDialog}
                        btnName="Save Batch"
                    />
                }
            />
            {/* )} */}


            <>
                {rows.length ? (
                    <CreateTableAddButton
                        editDialog={editDialog}
                        // setPage={setPage}
                        rows={rows}
                        editform={
                            <AddEditBatchDetails
                                onSubmit={(dataForUpdate) => { updateBatch({ body: dataForUpdate, id: data._id }) }}
                                formName="EDIT BATCH"
                                setOpen={setEditDialog}
                                btnName="Update Batch"
                                formType="edit"
                                initialData={data}

                            />
                        }
                        columns={columns}
                        isButton={true}
                        editFormName={"Edit Batch"}
                    />
                ) : totalData == 0 ? (
                    <DataNotFound />
                ) : (
                    <SkeletonListing />
                )}

            </>


            {addBatchInfo && (
                <Toster info={addBatchInfo} dialogclose={dialogclose} />
            )}

            {updateBatchInfo && (
                <Toster info={updateBatchInfo} dialogclose={dialogclose} />
            )}

            {deleteBatchInfo && (
                <Toster info={deleteBatchInfo} dialogclose={dialogclose} />
            )}
        </div>
    );
}
