// **** React Imports
import React, { useState, useEffect, useCallback, useRef } from "react";

// **** Formik Imports
import { useFormik } from "formik";

// **** Yup Imports
import * as yup from "yup";

// **** Material Imports
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
    Grid,
    Typography,
    Select,
    MenuItem,
    InputLabel,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    Input,
    FormControl
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";

// **** Services Imports
import { useGetAllCoursesQuery, useGetAllSubjectNamesQuery, useGetAallFacultieNamesQuery, useGetAllLevelNamesQuery } from "../../../../services/services"
import { useGetAllCenterQuery } from "../../../../services/offlineModuleServices/centersServices";
import { useGetConfigQuery } from "../../../../services/offlineModuleServices/configServices";

// **** Custom File Imports
import SkeletonListing from "../SkeletonListing";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export default function AddEditBatchDetails(props) {

    // **** Services
    const { data: allCourses, isFetching: isAllCoursesFetching, isLoading: isAllCoursesLoading } = useGetAllCoursesQuery()
    const { data: allSubjects, isFetching: isAllSubjectFetching, isLoading: isAllSubjectLoading } = useGetAllSubjectNamesQuery()
    const { data: allFaculties, isFetching: isAllFacultiesFetching, isLoading: isAllFacultiesLoading } = useGetAallFacultieNamesQuery()
    const { data: allLevels, isFetching: isAllLevelsFetching, isLoading: isAllLevelsLoading } = useGetAllLevelNamesQuery()
    const { data: AllbatchTimings, isFetching: isAllbatchTimingsFetching, isLoading: isAllbatchTimingsLoading } = useGetConfigQuery({
        limit: "",
        page: "",
        key: "batch_timing"
    })
    const { data: AllHalls, isFetching: isAllHallsFetching, isLoading: isAllHallsLoading } = useGetConfigQuery({
        limit: "",
        page: "",
        key: "hall"
    })

    const { data: AllCenters, isFetching: isAllCentersFetching, isLoading: isAllCentersLoading } = useGetAllCenterQuery()

    let initialValues;

    // **** Form Initial Values 
    props.formType == "edit" ?

        // **** For Edit
        initialValues = {
            batch_name: props.initialData.batch_name,
            batch_start_date: props.initialData.batch_start_date,
            batch_end_date: props.initialData.batch_end_date,
            lecture_played: Number(props.initialData.lecture_played),
            fees: Number(props.initialData.fees),
            batch_timing_id: props.initialData.batch_timing_id,
            category_id: props.initialData.category_id,
            subject: props.initialData.subject.map(ele => ({ ...ele, _id: ele.subject_id })),
            faculty: props.initialData.faculty.map(ele => ({ ...ele, _id: ele.faculty_id })),
            level_id: { _id: props.initialData.level_id, level_name: props.initialData.level_name },
            hall_id: props.initialData.hall_id,
            course_id: { _id: props.initialData.course_id, name: props.initialData.course_name },
            batch_new_admission: props.initialData.batch_new_admission,
            center_id: props.initialData.center_id

        }
        :

        // **** For Add
        initialValues = {
            batch_name: "",
            batch_start_date: "",
            batch_end_date: "",
            lecture_played: "",
            fees: "",
            batch_timing_id: "",
            category_id: "",
            subject: [],
            faculty: [],
            level_id: "",
            hall_id: "",
            course_id: "",
            batch_new_admission: "",
            center_id: "",

        }


    // **** Form Validation
    const validationSchema = yup.object({
        batch_name: yup.string().required("Required !"),
        batch_start_date: yup.date().required("Required !"),
        batch_end_date: yup.date().required("Required !"),
        lecture_played: yup.number().required("Required !"),
        fees: yup.number().required("Required !"),
        batch_timing_id: yup.string().required("Required !"),
        category_id: yup.string().required("Required !"),
        subject: yup.array().required("Required !"),
        faculty: yup.array().required("Required !"),
        level_id: yup.object().required("Required !"),
        hall_id: yup.string().required("Required !"),
        course_id: yup.object().required("Required !"),
        batch_new_admission: yup.date().required("Required !"),

    })

    // **** Formik 
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            values = {
                ...values,
                subject: values.subject.map((ele) => ele._id),
                faculty: values.faculty.map((ele) => ele._id),
                level_id: values.level_id._id,
                course_id: values.course_id._id
            }
              
            props.onSubmit(values)

        },
    });

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                {
                    isAllCentersFetching ||
                        isAllCentersLoading ||
                        isAllHallsFetching ||
                        isAllHallsLoading ||
                        isAllbatchTimingsFetching ||
                        isAllbatchTimingsLoading ||
                        isAllCoursesFetching ||
                        isAllCoursesLoading ||
                        isAllSubjectFetching ||
                        isAllSubjectLoading ||
                        isAllFacultiesFetching ||
                        isAllFacultiesLoading ||
                        isAllLevelsFetching ||
                        isAllLevelsLoading ?
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <SkeletonListing />
                            </Grid>
                        </Grid>
                        :

                        <>
                            <Grid container spacing={3}>

                                {/* Batch Name */}
                                <Grid item xs={4}>
                                    <InputLabel className="mb-2 mt-3" id="batch_name"> <span className="text-danger">*</span> Batch Name</InputLabel>
                                    <TextField
                                        name="batch_name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.batch_name}
                                        fullWidth={true}
                                        variant="outlined"
                                        size="small"
                                    />

                                    {formik.touched.batch_name && (
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {formik.errors.batch_name}
                                        </span>
                                    )}

                                </Grid>

                                {/* Batch Start Date */}
                                <Grid item xs={4}>
                                    <InputLabel className="mb-2 mt-3" id="batch_start_date"> <span className="text-danger">*</span> Batch Start Date</InputLabel>
                                    <TextField
                                        type="date"
                                        name="batch_start_date"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.batch_start_date}
                                        fullWidth={true}
                                        variant="outlined"
                                        size="small"
                                    />

                                    {formik.touched.batch_start_date && (
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {formik.errors.batch_start_date}
                                        </span>
                                    )}

                                </Grid>

                                {/* Batch End Date */}
                                <Grid item xs={4}>
                                    <InputLabel className="mb-2 mt-3" id="batch_end_date"> <span className="text-danger">*</span> Batch End Date</InputLabel>
                                    <TextField
                                        type="date"
                                        name="batch_end_date"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.batch_end_date}
                                        fullWidth={true}
                                        variant="outlined"
                                        size="small"
                                    />

                                    {formik.touched.batch_end_date && (
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {formik.errors.batch_end_date}
                                        </span>
                                    )}

                                </Grid>

                                {/* Lecture Played */}
                                <Grid item xs={4}>
                                    <InputLabel className="mb-2 mt-3" id="lecture_played"> <span className="text-danger">*</span> Lecture Played</InputLabel>
                                    <TextField
                                        type="number"
                                        name="lecture_played"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.lecture_played}
                                        fullWidth={true}
                                        variant="outlined"
                                        size="small"
                                    />

                                    {formik.touched.lecture_played && (
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {formik.errors.lecture_played}
                                        </span>
                                    )}

                                </Grid>

                                {/* Total Fees */}
                                <Grid item xs={4}>
                                    <InputLabel className="mb-2 mt-3" id="fees"> <span className="text-danger">*</span> Total Fees</InputLabel>

                                    <TextField
                                        type="number"
                                        name="fees"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.fees}
                                        fullWidth={true}
                                        variant="outlined"
                                        size="small"
                                    />

                                    {formik.touched.fees && (
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {formik.errors.fees}
                                        </span>
                                    )}

                                </Grid>

                                {/* New Admission Date */}
                                <Grid item xs={4}>
                                    <InputLabel className="mb-2 mt-3" id="batch_new_admission"> <span className="text-danger">*</span> New Admission Date</InputLabel>

                                    <TextField
                                        type="date"
                                        name="batch_new_admission"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.batch_new_admission}
                                        fullWidth={true}
                                        variant="outlined"
                                        size="small"
                                    />

                                    {formik.touched.batch_new_admission && (
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {formik.errors.batch_new_admission}
                                        </span>
                                    )}

                                </Grid>

                                {/* Batch Type */}
                                <Grid item xs={4}>
                                    {/* <FormControl fullWidth> */}
                                    <InputLabel className="mb-2 mt-3" id="category_id"> <span className="text-danger">*</span> Batch Type</InputLabel>

                                    <Select
                                        name="category_id"
                                        labelId="category_id"
                                        id="category_id"
                                        value={formik.values.category_id}
                                        label="category"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        fullWidth
                                    >
                                        {/* {allExpenseCategories && */}
                                        {/* // allExpenseCategories.data.map((ele) => { */}
                                        {/* // return ( */}
                                        <MenuItem value="61cc620babd26d00165af539">category_name</MenuItem>
                                        {/* // ); */}
                                        {/* // })} */}
                                    </Select>
                                    {/* </FormControl> */}
                                    {formik.touched.category_id && (
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {formik.errors.category_id}
                                        </span>
                                    )}

                                </Grid>

                                {/* Batch Timing */}
                                <Grid item xs={4}>
                                    {/* <FormControl fullWidth> */}
                                    <InputLabel className="mb-2 mt-3" id="category_id"> <span className="text-danger">*</span> Batch Timing</InputLabel>
                                    <Select
                                        name="batch_timing_id"
                                        labelId="batch_timing_id"
                                        id="batch_timing_id"
                                        value={formik.values.batch_timing_id}
                                        label="batch_timing_id"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        fullWidth
                                    >
                                        {AllbatchTimings?.status ?
                                            AllbatchTimings?.data?.map((ele) => {
                                                return (
                                                    <MenuItem value={ele._id}>{ele.batch_time_from + "-" + ele.batch_time_to}  </MenuItem>
                                                );
                                            })
                                            :
                                            <MenuItem> No Batch Timing Found</MenuItem>
                                        }
                                    </Select>
                                    {/* </FormControl> */}
                                    {formik.touched.batch_timing_id && (
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {formik.errors.batch_timing_id}
                                        </span>
                                    )}

                                </Grid>

                                {/* Subjects */}
                                <Grid item xs={4}>
                                    <InputLabel className="mb-2 mt-3" id="subject"> <span className="text-danger">*</span> Subjects</InputLabel>

                                    <Autocomplete
                                        multiple
                                        id="subject"
                                        value={formik.values.subject}
                                        onChange={(e, newValue) => formik.setFieldValue('subject', newValue)}
                                        options={allSubjects?.data}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.subject_name}
                                        isOptionEqualToValue={(opt, value) => opt._id === value._id}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.subject_name}
                                            </li>
                                        )}
                                        renderInput={(params) => <TextField {...params} variant="standard" placeholder="Type Subject Name..." />}
                                        size="small"
                                        fullWidth
                                    />
                                    {formik.touched.subject && (
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {formik.errors.subject}
                                        </span>
                                    )}

                                </Grid>

                                {/* Faculty */}
                                <Grid item xs={4}>
                                    <InputLabel className="mb-2 mt-3" id="faculty"> <span className="text-danger">*</span> Faculty</InputLabel>

                                    <Autocomplete
                                        multiple
                                        id="faculty"
                                        value={formik.values.faculty}
                                        onChange={(e, newValue) => formik.setFieldValue('faculty', newValue)}
                                        options={allFaculties?.data}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.faculty_name}
                                        isOptionEqualToValue={(opt, value) => opt._id === value._id}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.faculty_name}
                                            </li>
                                        )}
                                        renderInput={(params) => <TextField {...params} variant="standard" placeholder="Type faculty Name..." />}
                                        size="small"
                                        fullWidth
                                    />

                                    {formik.touched.subject && (
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {formik.errors.subject}
                                        </span>
                                    )}

                                </Grid>

                                {/* Level */}
                                <Grid item xs={4}>
                                    {/* <FormControl fullWidth> */}
                                    <InputLabel className="mb-2 mt-3" id="level_id"> <span className="text-danger">*</span> Level</InputLabel>
                                    <Autocomplete
                                        value={formik.values.level_id}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue("level_id", newValue);
                                        }}
                                        getOptionLabel={(option) => option.level_name || ""}
                                        isOptionEqualToValue={(option, value) => option._id === value._id}
                                        id="level_id"
                                        options={allLevels?.data}
                                        renderInput={(params) => <TextField variant='standard' {...params} />}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                {option.level_name}
                                            </li>
                                        )}
                                    />


                                    {/* </FormControl> */}
                                    {formik.touched.level_id && (
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {formik.errors.level_id}
                                        </span>
                                    )}

                                </Grid>

                                {/* Course  */}
                                <Grid item xs={4}>
                                    {/* <FormControl fullWidth> */}
                                    <InputLabel className="mb-2 mt-3" id="course_id"> <span className="text-danger">*</span> Course</InputLabel>

                                    <Autocomplete
                                        value={formik.values.course_id}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue("course_id", newValue);
                                        }}
                                        getOptionLabel={(option) => option.name || ""}
                                        isOptionEqualToValue={(option, value) => option._id === value._id}
                                        id="course_id"
                                        options={allCourses?.data}
                                        renderInput={(params) => <TextField variant='standard' {...params} />}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                {option.name}
                                            </li>
                                        )}
                                    />

                                    {/* </FormControl> */}
                                    {formik.touched.course_id && (
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {formik.errors.course_id}
                                        </span>
                                    )}

                                </Grid>

                                {/* Hall */}
                                <Grid item xs={4}>
                                    <InputLabel className="mb-2 mt-3" id="hall_id"> <span className="text-danger">*</span> Hall</InputLabel>

                                    <Select
                                        name="hall_id"
                                        labelId="hall_id"
                                        id="hall_id"
                                        value={formik.values.hall_id}
                                        label="hall_id"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        fullWidth
                                    >
                                        {AllHalls?.status ?
                                            AllHalls.data.map((ele) => {
                                                return (
                                                    <MenuItem value={ele._id}>{ele.hall_name}</MenuItem>
                                                );
                                            })
                                            :
                                            <MenuItem value=""> No Hall Found </MenuItem>
                                        }
                                    </Select>
                                    {formik.touched.hall_id && (
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {formik.errors.hall_id}
                                        </span>
                                    )}

                                </Grid>

                                {/* Center */}
                                <Grid item xs={4}>
                                    <InputLabel className="mb-2 mt-3" id="center_id"> <span className="text-danger">*</span> Center</InputLabel>

                                    <Select
                                        name="center_id"
                                        labelId="center_id"
                                        id="center_id"
                                        value={formik.values.center_id}
                                        label="center_id"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        fullWidth
                                    >
                                        {AllCenters &&
                                            AllCenters.data.map((ele) => {
                                                return (
                                                    <MenuItem value={ele._id}>{ele.center_name}</MenuItem>
                                                );
                                            })}
                                    </Select>
                                    {formik.touched.center_id && (
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {formik.errors.center_id}
                                        </span>
                                    )}

                                </Grid>

                            </Grid>

                            {/* Button */}
                            <Grid className="mt-4 text-right" >
                                <Button variant="contained" color="secondary" onClick={() => { props.setOpen(false) }} > Close</Button>
                                <Button variant="contained" color="primary" type="submit" className="ml-3"> {props.btnName}</Button>
                            </Grid>
                        </>

                }


            </form>

        </div >
    );
}

