import React, { useState, useEffect } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import AddDataHeader from "../AddDataHeader";
import DataNotFound from "../DataNotFound";
import FormDialog from "../reusableComponents/NewFormDialog";
import { useGetConfigQuery, useAddConfigMutation, useUpdateConfigDataMutation, useDeleteConfigDataMutation } from "../../../../services/offlineModuleServices/configServices";
import AddBatchTiming from "./batch-timing/AddBatchTiming";
import EditBatchTiming from "./batch-timing/EditBatchTiming";


function formatTime(time) {
    var timeSplit = time.split(':'),
        hours,
        minutes,
        meridian;

    hours = timeSplit[0];
    minutes = timeSplit[1];
    if (hours > 12) {
        meridian = 'pm';
        hours -= 12;
    } else if (hours < 12) {
        meridian = 'am';
        if (hours == 0) {
            hours = 12;
        }
    } else {
        meridian = 'pm';
    }
    return (hours + ':' + minutes + ' ' + meridian);
}


export default function BatchTiming() {


    let [getid, setGetid] = useState("");
    const [data, setData] = useState("");
    const [addDialog, setAddDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [render, setRender] = useState(false)

    const { data: AllbatchTimings, isAllbatchTimingsFetching, isAllbatchTimingsLoading } = useGetConfigQuery({
        limit: "",
        page: "",
        key: "batch_timing"
    })
    const [addBatchTiming, addBatchTimingInfo] = useAddConfigMutation()
    const [updateBatchTiming, updateBatchTimingInfo] = useUpdateConfigDataMutation()
    const [deleteBatchTiming, deleteBatchTimingInfo] = useDeleteConfigDataMutation()



    const columns = [
        {
            id: "SNo",
            label: "S.No",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "batchTimingfrom",
            label: "Batch Timing From",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "batchTimingto",
            label: "Batch Timing To",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "actions",
            label: "Actions",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
    ];
    function createData(SNo, batchTimingfrom, batchTimingto, actions) {
        return {
            SNo,
            batchTimingfrom,
            batchTimingto,
            actions,
        };
    }

    let rows = [];
    const totalItem = AllbatchTimings ? AllbatchTimings.totalItem : 0;

    let totalData = "ok";

    useEffect(() => {
        getid && deleteBatchTiming({
            key: "batch_timing",
            configData: {
                _id: getid
            }
        }) && setGetid("");
    }, [getid]);

    if (
        AllbatchTimings?.status
    ) {
        totalData = AllbatchTimings?.totalItem;
        rows = AllbatchTimings?.data?.map((ele, index) => {
            return createData(
                index + 1,
                ele.batch_time_from,
                ele.batch_time_to,
                <div>
                    <EditDelete
                        setEditDialog={setEditDialog}
                        type="edit"
                        setData={setData}
                        data={ele}
                    />
                    <EditDelete type="delete" setGetid={setGetid} id={ele._id} />
                </div>
            );
        });
    }
   

    const dialogclose = () => {
        setEditDialog(false);
        setAddDialog(false);
        setRender(!render);
    };

    const updateBtnClickHandler = (dataForUpdate) => {
        const { batch_time_from, batch_time_to } = dataForUpdate
        updateBatchTiming({
            key: "batch_timing",
            configData: {
                is_active: true,
                _id: data._id,
                batch_time_from: batch_time_from,
                batch_time_to: batch_time_to
            }
        });
    };

    const addBtnClickHandler = (dataForAdd) => {
        const { batch_time_from, batch_time_to } = dataForAdd
        addBatchTiming({
            key: "batch_timing",
            configData: {
                is_active: true,
                batch_time_from: batch_time_from,
                batch_time_to: batch_time_to
            }
        })
    }

    return (
        <div>
            {/* {!viewDialog && ( */}
            <AddDataHeader
                addFormName={"Add New Batch Timing"}
                isSearch={true}
                totalItem={totalItem}
                isPagination={true}
                setAddDialog={setAddDialog}
                isButton={true}
                Adddialog={addDialog}
                form={
                    <AddBatchTiming open={addDialog} onClose={() => setAddDialog(false)} onSubmit={(dataForAdd) => addBtnClickHandler(dataForAdd)} />
                }
            />
            {/* )} */}


            <>
                {rows.length ? (
                    <CreateTableAddButton
                        // setPage={setPage}
                        rows={rows}

                        columns={columns}
                        isButton={true}
                        editFormName={"Edit Batch Timing"}
                    />
                ) : totalData == 0 ? (
                    <DataNotFound />
                ) : (
                    <SkeletonListing />
                )}

            </>





            {addBatchTimingInfo && (
                <Toster info={addBatchTimingInfo} dialogclose={dialogclose} />
            )}

            {updateBatchTimingInfo && (
                <Toster info={updateBatchTimingInfo} dialogclose={dialogclose} />
            )}

            {deleteBatchTimingInfo && (
                <Toster info={deleteBatchTimingInfo} dialogclose={dialogclose} />
            )}

            {   editDialog &&
                <EditBatchTiming open={editDialog} onClose={() => setEditDialog(false)} initialData={data} onSubmit={(dataForUpdate) => updateBtnClickHandler(dataForUpdate)} />
            }


        </div>
    );
}
