import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem('__authToken__')

export const reviewApi = createApi({
  tagTypes: ['review'],
  reducerPath: "reviewApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/review/`
  }),


  endpoints: (build) => ({
    getAllReviews: build.mutation({
      providesTags: ['review'] , 
        query(body) {
        return {
          url: "all-reviews",
          headers: {
            "x-access-token": token
          },
          method: "POST",
          body
        }
      },
    }),

    deleteReviews: build.mutation({
      invalidatesTags: ['review'],
      query(id) {
        return {
          url: `${id}`,
          headers: {
            "x-access-token": token
          },
          method: "DELETE",
        };
      },
    }),


    getReviewsById: build.mutation({
      query: (id) => ({
        url: `${id}`,
        headers: {
          "x-access-token": token,
        },
        method: "GET",
      }),
    }),  
    

    updateReviews: build.mutation({
      invalidatesTags: ['review'],
      query(data) {

        return {
          url: data.id,
          headers: {
            "x-access-token": token
          },
          method: "PUT",
          body: { approval_action: data.approval_action, varify_status: data.varify_status },
        };
      },
    }),

  })
})


  
export const {
  useGetAllReviewsMutation,
  useUpdateReviewsMutation,
  useGetReviewsByIdMutation,
  useDeleteReviewsMutation,
} = reviewApi
