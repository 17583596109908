import React, { useState } from "react";
import EditDelete from "./EditDelete";
import Toster from "./Toster";
import { Paper, Grid, Button } from "@material-ui/core";
import {
  useGetCmsQuery,
  useUpdateCmsStatusMutation,
  useUpdateCMSDataMutation,
} from "../../../services/cmsServices";
import FormDialog from "./reusableComponents/NewFormDialog";
import DataNotFound from "./DataNotFound";
import AuthWrapper from "../../../utils/AuthWrapper";

export default function CmsPaymentMethods() {
  const {
    data: AllData,
    isFetching,
    isLoading,
  } = useGetCmsQuery({ key: "makes_us_different_img" });

  const [updateImage, updateImageInfo] = useUpdateCMSDataMutation();
  const [updateIsShowStatus, updateIsShowInfo] = useUpdateCmsStatusMutation();

  const [editDialog, setEditDialog] = useState(false);
  const [data, setData] = useState(null);

  const UpdateData = (data) => {
    updateIsShowStatus(data);
  };

  const onClickHandler = (dataForUpdate) => {
    let form_data = new FormData();

    form_data.append("key", "makes_us_different_img");
    form_data.append("cms_data", "");
    form_data.append("image", dataForUpdate.makes_us_different_img);

    updateImage(form_data);
  };

  const dialogclose = () => {
    setEditDialog(false);
  };

  return (
    <>
      {AllData != undefined && AllData.data.length ? (
        <div>
          {AllData != undefined ? (
            <AuthWrapper type="ACTION" name="CMS_MAKE_US_DIFFERENT_HIDE_ON_MOBILE">
              <Grid item xs={12} className="pt-4">
                {AllData.data[0].makes_us_different_img
                  .makes_us_different_img_show ? (
                  // <<<<<<<<<<<<<<<<<<<<< IF IS_SHOW IS TRUE THEN ""HIDE BUTTON"" ENABLED >>>>>>>>>>>>>>>>>>>>>>>>>>>>

                  <Button
                    variant="contained"
                    onClick={() => {
                      UpdateData({
                        key: "makes_us_different_img",
                        cms_data: {
                          is_show: false,
                        },
                      });
                    }}
                    color="secondary"
                  >
                    hide on mobile
                  </Button>
                ) : (
                  // <<<<<<<<<<<<<<<<<<<<<<<< IF IS_SHOW IS FALSE THEN ""SHOW BUTTON"" ENABLED >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

                  <Button
                    variant="contained"
                    onClick={() => {
                      UpdateData({
                        key: "makes_us_different_img",
                        cms_data: {
                          is_show: true,
                        },
                      });
                    }}
                    color="primary"
                  >
                    {" "}
                    show on mobile{" "}
                  </Button>
                )}
              </Grid>
            </AuthWrapper>
          ) : null}
          <AuthWrapper type="ACTION" name="CMS_MAKE_US_DIFFERENT_LIST">
            {isLoading || isFetching ? (
              "Loading"
            ) : (
              <Grid container justifyContent="center">
                <Paper variant="outlined" className="mt-5" xs={6}>
                  <img
                    src={
                      AllData.data[0].makes_us_different_img
                        .makes_us_different_img
                    }
                    alt="No Image !"
                  />
                  <AuthWrapper type="ACTION" name="CMS_MAKE_US_DIFFERENT_EDIT">
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <EditDelete
                        setEditDialog={setEditDialog}
                        type="edit"
                        setData={setData}
                        data={null}
                      />
                    </Grid>
                  </AuthWrapper>
                </Paper>
              </Grid>
              // <Paper variant="outlined" className="mt-5" xs={6}>
              //   <img
              //     className="d-flex text-center"
              //     src={
              //       AllData.data[0].makes_us_different_img.makes_us_different_img
              //     }
              //     alt = "No Image !"
              //   />
              //   <Grid
              //     container
              //     direction="row"
              //     justifyContent="center"
              //     alignItems="center"
              //   >
              //     <EditDelete
              //       setEditDialog={setEditDialog}
              //       type="edit"
              //       setData={setData}
              //       data={null}
              //     />
              //   </Grid>
              // </Paper>
            )}
          </AuthWrapper>
          {editDialog && (
            <FormDialog
              open={editDialog}
              setOpen={setEditDialog}
              formName={"Update Image"}
              btnName={"Update"}
              onBtnClick={onClickHandler}
              // useAs="form"

              fields={[
                {
                  fieldType: "file",
                  // fieldName: "Image",
                  initialValue: "",
                  accessKey: "makes_us_different_img",
                  fileRequired: false,
                },
              ]}
            />
          )}

          {updateImageInfo.data && (
            <Toster info={updateImageInfo} dialogclose={dialogclose} />
          )}

          {updateIsShowInfo.data && (
            <Toster info={updateIsShowInfo} dialogclose={dialogclose} />
          )}
        </div>
      ) : (
        <DataNotFound />
      )}
    </>
  );
}
