import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const callerPerformanceApi = createApi({
    // tagTypes: ["caller"],
    reducerPath: "callerPerformanceApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/sld-user/`,
    }),
    endpoints: (builder) => ({
        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET "CALLER PERFORMANCE" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        GetCallerPerformanceData: builder.query({
            // providesTags: ["caller"],
            query: (body) => {
                return {
                    url: "sld-users-incenive-orders",
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,
                };
            },
        }),
        getUserKeyAcess: builder.query({
            query() {
              return {
                url: `product-keys`,
                headers: {
                  "x-access-token": token,
                },
                method: "GET",
              };
            },
            providesTags: ["user"],
          }),

    }),
});

export const {
    useGetUserKeyAcessQuery,
    useGetCallerPerformanceDataQuery,
} = callerPerformanceApi;


