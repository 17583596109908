import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Divider, Typography, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, Box, Paper } from '@material-ui/core'
import {
    useGetCourseByIdQuery,
} from "../../../services/services";
import SkeletonListing from './SkeletonListing';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemButton from '@mui/material/ListItemButton';



function ViewCourseDetails({ open, setOpen, data }) {


    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const { name, course_code, type, level, _id } = data
    const { data: singleCourseData, isFetching, isLoading } = useGetCourseByIdQuery(_id)

      



    return (
        <div>
            <Dialog
                fullWidth
                maxWidth="lg"
                open={open}
                onClose={() => { setOpen(false) }}>

                <DialogTitle className='text-light' style={{ backgroundColor: "#5664d2" }} > <Typography variant='h5'> Course Details </Typography></DialogTitle>
                {isFetching || isLoading || !singleCourseData ?
                    <SkeletonListing />
                    :

                    <DialogContent >
                        <Grid container className='px-3 py-3' >
                            <Grid container item xs={12} justifyContent="space-evenly">

                                <Grid item xs={3}  className="border rounded "  style={{ backgroundColor: "rgb(215 219 219)" }} >
                                    <Grid container spacing={2} >
                                        <Grid item xs={12} className="text-center" >
                                            <Typography variant='subtitle1'  > Course Name  </Typography>
                                        </Grid>
                                        <Grid item xs={12} className="text-center " >
                                            <Typography variant='h6'  > {singleCourseData?.data[0]?.name} </Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                <Grid item xs={3} className="border rounded "  style={{ backgroundColor: "rgb(215 219 219)" }} >
                                    <Grid container spacing={2} justifyContent="baseline">
                                        <Grid item xs={12} className="text-center" >
                                            <Typography variant='subtitle1'  > Course Code  </Typography>
                                        </Grid>
                                        <Grid item xs={12} className="text-center " >
                                            <Typography variant='h6'   > {singleCourseData?.data[0]?.course_code} </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={3} className="border rounded " style={{ backgroundColor: "rgb(215 219 219)" }} >
                                    <Grid container spacing={2} justifyContent="baseline">
                                        <Grid item xs={12} className="text-center" >
                                            <Typography variant='subtitle1'  > Course Type  </Typography>
                                        </Grid>
                                        <Grid item xs={12} className="text-center " >
                                            <Typography variant='h6'  > {singleCourseData?.data[0]?.type == "MAIN_COURSE" ? "Main Course" : "Other Course"} </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >

                                <Divider className="bg-dark mt-3" />
                            </Grid>

                            <Grid item xs={12}>


                                {singleCourseData?.data[0]?.level.map((ele, index) => (
                                    <>
                                        <Typography style={{ fontSize: "25px" }} className=" mt-2 mb-2 text-info"> {ele.level_name} </Typography>
                                        <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} className="mb-3 ">
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                style={{ backgroundColor: "rgb(215 219 219)" }}

                                            >
                                                <Typography   >
                                                    View Subjects
                                                </Typography>
                                            </AccordionSummary>

                                            <AccordionDetails>
                                                <Grid container justifyContent="space-between">
                                                    {ele.subject.map((ele, indexNum) => (
                                                        <Grid item xs={5} className=" my-1 px-2">
                                                            <Typography> {`${indexNum + 1} :  ${ele.subject_name}`} </Typography>
                                                        </Grid>
                                                    ))}


                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </>
                                ))}
                            </Grid>


                        </Grid>
                    </DialogContent>
                }
                <DialogActions>
                    <Button variant='contained' color='secondary' onClick={() => { setOpen(false) }}> Close </Button>

                </DialogActions>
            </Dialog>
        </div >
    )
}

export default ViewCourseDetails
