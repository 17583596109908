import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid, TextField } from "@material-ui/core";



const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function WelcomePage() {
    const classes = useStyles();


    return (
        <>
            <Grid className="d-flex aline-items-center justify-content-center" container spacing={3} style={{ marginTop: 50 }}>
                <Grid item xs={8} >
                    <Card style={{ background: "#1FB5B5" }} className={classes.root}>
                        <CardContent className="m-5 border-bottom border-light" align="center">
                            <Typography align="center" style={{ color: "#fff" }}>
                                <h3>WELCOME TO Smart Learning Destination</h3>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>


        </>
    );
}
