import React, { useState, useEffect } from "react";
import CreateTableAddButton from "../CreateTableAddButton";
import ViewBlog from "./ViewBlog";
import Loader from "../Loader";
import AddDataHeader from "../AddDataHeader";
import {
  useGetAllPendingBlogMutation,
  useUpdatePendingBlogCommentMutation,
} from "../../../../services/blogServices";
import SkeletonListing from "../SkeletonListing";
import DataNotFound from "../DataNotFound";
import { Avatar, Button } from "@material-ui/core";
import { toast } from "react-toastify";
import AuthWrapper from "../../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../../utils/getFilterFunction";

export default function BlogCount(count) {
  const columns = [
    {
      id: "SNo",
      label: "Sr. No.",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "title",
      label: "Title",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "approvedBy",
      label: "Approved By",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "comments",
      label: "Comments",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "commentBy",
      label: "CommentBy",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "approvalStatus",
      label: "Approval Status",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "Action",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: ["CMS_BLOG_COMMENT_DISAPPROVE", "CMS_BLOG_COMMENT_APPROVE"],
    },
  ];

  function createData(
    SNo,
    title,
    approvedBy,
    comments,
    commentBy,
    approvalStatus,
    Action
  ) {
    return {
      SNo,
      title,
      approvedBy,
      comments,
      commentBy,
      approvalStatus,
      Action,
    };
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: [
      "blogId",
      "comment",
      "commentedByUserId",
      "commentedByUserType",
      "commentedByUserName",
      "commentedOnDateTime",
    ],
    page: page + 1,
    orderBy:
      count?.count?.data === 0 ? "commentedOnDateTime" : "approvalStatus",
  };

  let rows = [];

  const [data, setData] = useState("");
  const [viewDialog, setViewDialog] = useState(false);
  const [getAllData, getAllDataInfo] = useGetAllPendingBlogMutation();
  const [updateStatus, updateStatusInfo] =
    useUpdatePendingBlogCommentMutation();
  const AllData = getAllDataInfo?.data;
  const totalItem = AllData ? AllData.totalItem : 0;
  const [render, setRender] = useState(false);
  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page, updateStatus]);

  const handleUpdateStatus = (status, id, newAlignment) => {
    updateStatus({ body: { approvalStatus: status }, id: id }).then((res) => {
      if (res?.data?.status) {
        setRender(!render);
        toast("status update successfully");
      } else {
        toast("Something went wrong!");
      }
    });
  };

  let totalData = "ok";
  if (AllData) {
    totalData = AllData?.status;
    rows = AllData?.data?.map((data, index) =>
      createData(
        index + 1,
        data?.image ? (
          <div>
            <div className="d-flex justify-content-center">
              <Avatar src={data?.image} />
            </div>
            <span className="text-info font-weight-bold">
              {data?.blogTitle}
            </span>
            <br />
          </div>
        ) : null,
        data?.approvedByUserName,
        data?.comment,
        data?.commentedByUserName,
        data?.approvalStatus,
        <div className="d-flex justify-content-center">
          {data?.approvalStatus === "APPROVED" ? (
            <AuthWrapper type="ACTION" name="CMS_BLOG_COMMENT_DISAPPROVE">
              <Button
                sx={{ fontSize: "12px", textTransform: "none" }}
                className="border border-danger cursor-pointer border-start text-danger  fw-semibold"
                size="small"
                onClick={() => {
                  handleUpdateStatus("DISAPPROVED", data?._id);
                }}
              >
                Disapproved
              </Button>{" "}
            </AuthWrapper>
          ) : null}
          {data?.approvalStatus === "DISAPPROVED" ? (
            <AuthWrapper type="ACTION" name="CMS_BLOG_COMMENT_APPROVE">
              <Button
                sx={{ fontSize: "12px", textTransform: "none" }}
                className="border border-primary cursor-pointer border-start text-primary  fw-semibold"
                size="small"
                onClick={() => {
                  handleUpdateStatus("APPROVED", data?._id);
                }}
              >
                Approved
              </Button>
            </AuthWrapper>
          ) : null}
          {data?.approvalStatus === "PENDING" ? (
            <div>
              {" "}
              <AuthWrapper type="ACTION" name="CMS_BLOG_COMMENT_DISAPPROVE">
                <Button
                  sx={{ fontSize: "12px", textTransform: "none" }}
                  className="cursor-pointer border border-secondary  fw-semibold"
                  size="small"
                  onClick={() => {
                    handleUpdateStatus("DISAPPROVED", data?._id);
                  }}
                >
                  Disapproved
                </Button>
              </AuthWrapper>{" "}
              <AuthWrapper type="ACTION" name="CMS_BLOG_COMMENT_APPROVE">
                <Button
                  sx={{ fontSize: "12px", textTransform: "none" }}
                  className="cursor-pointer border border-secondary fw-semibold ml-2 mt-1"
                  size="small"
                  onClick={() => {
                    handleUpdateStatus("APPROVED", data?._id);
                  }}
                >
                  Approved
                </Button>
              </AuthWrapper>
            </div>
          ) : null}
        </div>
      )
    );
  }

  return (
    <div>
      {!viewDialog && (
        <AddDataHeader
          setPage={setPage}
          page={page}
          isSearch={
            !AuthWrapper({
              type: "ACTION",
              name: "CMS_BLOG_COMMENT_LIST",
              inBoolean: true,
            })
          }
          isPagination={
            !AuthWrapper({
              type: "ACTION",
              name: "CMS_BLOG_COMMENT_LIST",
              inBoolean: true,
            })
          }
          excelBtn={false}
          totalItem={totalItem}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          searchBarApi={getAllData}
          paginationData={paginationData}
          fullsize={false}
          isButton={false}
        />
      )}

      <AuthWrapper type="ACTION" name="CMS_BLOG_COMMENT_LIST">
        {getAllDataInfo?.isLoading || updateStatusInfo?.isLoading ? (
          <Loader />
        ) : (
          <>
            {" "}
            {!viewDialog ? (
              AllData?.status === true ? (
                <CreateTableAddButton
                  rows={rows}
                  fullsize={true}
                  columns={getFilterdColumns(columns)}
                />
              ) : AllData?.status === false ? (
                <DataNotFound />
              ) : (
                <SkeletonListing />
              )
            ) : (
              <ViewBlog viewDialog={setViewDialog} data={data} />
            )}
          </>
        )}
      </AuthWrapper>
    </div>
  );
}
