import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Books from './Books';
import TabPanel from './CreateTabPanel';
import Product from './Product';
import { userAccess } from '../../../helpers/userAccess'


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },

}));
export default function BooksAndProducts() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    {userAccess("book") ? <Tab style={{ textTransform: 'capitalize' }} label="Books Stock" {...a11yProps(0)} /> : null}
                    {userAccess("book-product") ? <Tab style={{ textTransform: 'capitalize' }} label="Books Product" {...a11yProps(1)} /> : null}
                </Tabs>
            </AppBar>

            <TabPanel value={value} index={0}>
                <Books />
            </TabPanel>
            <TabPanel style={{ paddingBottom: 50 }} value={value} index={1}>
                <Product />
            </TabPanel >

        </div >
    )
}
