import { Button, FormControl, Grid, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useGetAllCashCollectionDataMutation } from '../../../../services/offlineModuleServices/cashCollectionServices';
import SkeletonListing from '../SkeletonListing';
import { useGetAllSldUserMutation } from '../../../../services/services';




export default function Account() {

  const [date, setDate] = useState("");
  const [user, setUser] = useState("");
  const [isDepositOpen, setIsDepositOpen] = useState(true);


  const [getAllSLDUsers, getAllSLDUsersInfo] = useGetAllSldUserMutation()

  const paginationData = {
    limit: "",
    query: "",
    params: [
      "name"
    ],
    page: ""
  }

  useEffect(() => {
    getAllSLDUsers(paginationData)
  }, [])


  const filterData = {
    sld_user_id: user,
    date: date

  }

  const [allCashData, AllCashDataInfo] = useGetAllCashCollectionDataMutation()

  useEffect(() => {
    allCashData({ body: { sld_user_id: user == "All" ? "" : user, date: date }, id: "627ca762cd46b5abc8ef92b5" })
  }, [date, user])

  const cashData = AllCashDataInfo.data
  const AllSldUsers = getAllSLDUsersInfo.data

    

  return (
    <>


      <div className='d-flex justify-content-center'>

        <Grid container style={{ width: "80%" }} className="mt-5" >

          {/* Cash Accounts Section */}

          <Grid container className='mb-5 rounded pb-3 ' style={{ border: "1px solid grey", borderBottom: "3px solid rgb(86 100 210)" }}>

            <Grid container item xs={12} justifyContent="space-between" style={{ backgroundColor: "rgb(86 100 210)" }} className=' py-3 px-2'>
              <Grid item xs={6}>
                <Typography style={{ fontSize: "18px" }} className="text-light" > Cash Accounts </Typography>
              </Grid>

              <Grid container item xs={6}>
                <Grid container item xs={6} >

                  <Grid item xs={3} style={{ fontSize: "18px" }} className="text-light" > Date:</Grid>
                  <Grid item xs={7}>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        leftArrowButtonText='dfd'
                        clearable
                        name="date"
                        onChange={(val) => { setDate(val) }}
                        value={date}
                        views={['day', 'month', 'year']}
                        inputFormat="dd MMM yyyy"

                        renderInput={(params) => <TextField name='date' className="bg-light px-2"   {...params} />}

                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Grid container item xs={6} >

                  <Grid item xs={3} style={{ fontSize: "18px" }} className="text-light" > User:</Grid>
                  <Grid item xs={7}>

                    <FormControl fullWidth >
                      <Select
                        className="bg-light px-2"
                        onChange={(e) => { setUser(e.target.value) }}

                      >
                        <MenuItem value="All"> All </MenuItem>

                        {
                          AllSldUsers?.data?.map(ele => (
                            <MenuItem value={ele._id}> {ele.name} </MenuItem>

                          ))
                        }


                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>





            </Grid>

            <Grid container item xs={12} className="px-2 mt-2 py-2" style={{ backgroundColor: "#dee2e6" }} >
              {
                cashData?.data?.cashData?.map(ele => {
                  return (
                    <Grid item xs={1} style={{ fontSize: "20px" }}>
                      &#8377; {ele.currency_note_value}
                    </Grid>
                  )
                }

                )
              }

            </Grid>

            <Grid container item xs={12} className="px-2 mt-2 py-2" >
              {
                cashData?.data?.cashData?.map(ele => {
                  return (
                    <Grid item xs={1} style={{ fontSize: "20px" }}>
                      {ele.available_note_count}
                    </Grid>
                  )
                }

                )
              }

            </Grid>

            <Grid container item xs={12} justifyContent="flex-end" className='px-3'>
              <Grid direction="column" >

                <Typography variant="h6" > Total Amount</Typography>
                <Typography variant="h6" className="text-info text-center"> &#8377; {cashData?.data?.cashData?.reduce((acc, currentValue) => acc + parseInt(currentValue.currency_note_value) * currentValue.available_note_count, 0)} </Typography>
              </Grid>


            </Grid>

          </Grid>


          {/* Cash Transaction Details Section */}


          <Grid container className='mb-5 rounded pb-5 ' style={{ border: "1px solid grey", borderBottom: "3px solid rgb(86 100 210)" }}>

            <Grid container item xs={12} justifyContent="space-between" style={{ backgroundColor: "rgb(86 100 210)" }} className=' py-3 px-2'>
              <Grid item xs={6}>
                <Typography style={{ fontSize: "18px" }} className="text-light" > Cash Transaction Details</Typography>
              </Grid>

              <Grid container style={{ backgroundColor: "rgb(86 100 210)" }} className="" >
                <Grid item xs={6} className={isDepositOpen && " border-bottom border-danger"} >
                  <Button fullWidth className='text-light' onClick={() => setIsDepositOpen(true)} > DEPOSIT</Button>
                </Grid>

                <Grid item xs={6} className={!isDepositOpen && "border-bottom border-danger"}  >
                  <Button fullWidth className='text-light' onClick={() => setIsDepositOpen(false)} > WITHDRAWAL</Button>
                </Grid>
              </Grid>

            </Grid>




            <Grid container item xs={12} className="px-2 mt-2 py-2" style={{ backgroundColor: "#dee2e6" }} >
              {
                cashData?.data?.cashData?.map(ele => {
                  return (
                    <Grid item xs={1} style={{ fontSize: "20px" }}>
                      &#8377; {ele.currency_note_value}
                    </Grid>
                  )
                }

                )
              }

            </Grid>

            <Grid container item xs={12} className="px-2 mt-2 py-2" >
              {
                cashData?.data?.cashData?.map(ele => {
                  return (
                    <Grid item xs={1} style={{ fontSize: "20px" }}>
                      {isDepositOpen ? ele.deposite_count : ele.withdraw_count}
                    </Grid>
                  )
                }

                )
              }

            </Grid>

            <Grid container item xs={12} justifyContent="flex-end" className='px-3'>
              <Grid direction="column" >

                <Typography variant="h6" > Total Amount</Typography>
                <Typography variant="h6" className={isDepositOpen ? "text-info text-center" : "text-danger text-center "} >   &#8377; {cashData?.data?.cashData?.reduce((acc, currentValue) => acc + parseInt(currentValue.currency_note_value) * (isDepositOpen ? currentValue.deposite_count : currentValue.withdraw_count), 0)} </Typography>
              </Grid>


            </Grid>

          </Grid>

          {/* Online Account Section */}


          <Grid container className='mb-5 rounded pb-5 ' style={{ border: "1px solid grey", borderBottom: "3px solid rgb(86 100 210)" }}>

            <Grid container item xs={12} justifyContent="space-between" style={{ backgroundColor: "rgb(86 100 210)" }} className=' py-3 px-2'>
              <Grid item xs={6}>
                <Typography style={{ fontSize: "18px" }} className="text-light" > Online Accounts</Typography>
              </Grid>

            </Grid>

            <Grid container item xs={12} className="px-2 mt-2 py-2" style={{ backgroundColor: "#dee2e6" }} >
              {
                cashData?.data?.onlinePaymentData?.map(ele => {
                  return (
                    <Grid item xs={1} style={{ fontSize: "20px" }}>
                      {ele.currency_note_name}
                    </Grid>
                  )
                }

                )
              }

            </Grid>

            <Grid container item xs={12} className="px-2 mt-2 py-2" >
              {
                cashData?.data?.onlinePaymentData?.map(ele => {
                  return (
                    <Grid item xs={1} style={{ fontSize: "20px" }} className="text-info">
                      &#8377; {ele.available_amount}
                    </Grid>
                  )
                }

                )
              }

            </Grid>

            <Grid container item xs={12} justifyContent="flex-end" className='px-3'>
              <Grid direction="column" >

                <Typography variant="h6" > Total Amount</Typography>
                <Typography variant="h6" className="text-info text-center" >   &#8377; {cashData?.data?.onlinePaymentData?.reduce((acc, currentValue) => acc + currentValue.available_amount, 0)} </Typography>
              </Grid>


            </Grid>


          </Grid>

          {/* Online Transaction Details Section */}


          <Grid container className='mb-5 rounded pb-5 ' style={{ border: "1px solid grey", borderBottom: "3px solid rgb(86 100 210)" }}>

            <Grid container item xs={12} justifyContent="space-between" style={{ backgroundColor: "rgb(86 100 210)" }} className=' py-3 px-2'>
              <Grid item xs={6}>
                <Typography style={{ fontSize: "18px" }} className="text-light" > Online Transaction Details</Typography>
              </Grid>

              <Grid container style={{ backgroundColor: "rgb(86 100 210)" }} className="" >
                <Grid item xs={6} className={isDepositOpen && " border-bottom border-danger"} >
                  <Button fullWidth className='text-light' onClick={() => setIsDepositOpen(true)} > DEPOSIT</Button>
                </Grid>

                <Grid item xs={6} className={!isDepositOpen && "border-bottom border-danger"}  >
                  <Button fullWidth className='text-light' onClick={() => setIsDepositOpen(false)} > WITHDRAWAL</Button>
                </Grid>
              </Grid>


            </Grid>

            <Grid container item xs={12} className="px-2 mt-2 py-2" style={{ backgroundColor: "#dee2e6" }} >
              {
                cashData?.data?.onlinePaymentData?.map(ele => {
                  return (
                    <Grid item xs={1} style={{ fontSize: "20px" }}>
                      {ele.currency_note_name}
                    </Grid>
                  )
                }

                )
              }

            </Grid>

            <Grid container item xs={12} className="px-2 mt-2 py-2" >
              {
                cashData?.data?.onlinePaymentData?.map(ele => {
                  return (
                    <Grid item xs={1} style={{ fontSize: "20px" }} className={isDepositOpen ? "text-info" : "text-danger"}>
                      &#8377; {isDepositOpen ? ele.deposite_amount : ele.withdraw_amount}
                    </Grid>
                  )
                }

                )
              }

            </Grid>


            <Grid container item xs={12} justifyContent="flex-end" className='px-3'>
              <Grid direction="column" >

                <Typography variant="h6" > Total Amount</Typography>
                <Typography variant="h6" className={isDepositOpen ? "text-info text-center" : "text-danger text-center "} >   &#8377; {cashData?.data?.onlinePaymentData?.reduce((acc, currentValue) => acc + (isDepositOpen ? currentValue.deposite_amount : currentValue.withdraw_amount), 0)} </Typography>
              </Grid>


            </Grid>

          </Grid>


        </Grid>
      </div>

    </>

  )
}
