import React, { useState, useEffect } from "react";
import EditDelete from "./EditDelete";
import CreateTableAddButton from "./CreateTableAddButton";
import { Avatar, Link } from "@material-ui/core";
import UpdateFaculty from "./faculty/UpdateFaculty";
import Loader from "./Loader";
import AddDataHeader from "./AddDataHeader";
import {
  useGetAllSocialNetworksQuery,
  useAddSocialNetworkMutation,
  useDeleteSocialNetworkMutation,
  useUpdateSocialNetworkMutation,
} from "../../../services/services";
import Toster from "./Toster";
import AddFaculty from "./faculty/AddFaculty";
import ViewFaculties from "./faculty/ViewFaculties";
import SkeletonListing from "./SkeletonListing";
import DataNotFound from "./DataNotFound";
import AddSocial from "./AddSocial";
import UpdateSocialNetwork from "./UpdateSocialNetwork";
import AuthWrapper from "../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../utils/getFilterFunction";

export default function SocialNetworks() {
  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "Image",
      label: "Image",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "name",
      label: "Social Network Name",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "description",
      label: "Description",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "IsActive",
      label: "IsActive",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },

    {
      id: "Actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: ["CONFIGURATION_SOCIAL_MEDIA_EDIT", "CONFIGURATION_SOCIAL_MEDIA_DELETE"],

    },
  ];
  function createData(SNo, Image, name, description, IsActive, Actions) {
    return { SNo, Image, name, description, IsActive, Actions };
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: ["name"],
    page: page + 1,
  };

  let rows = [];
  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [AddDialog, setDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const getAllData = useGetAllSocialNetworksQuery();
  const [deleteFaculty, deleteFacultyInfo] = useDeleteSocialNetworkMutation();
  const [addData, addDataInfo] = useAddSocialNetworkMutation();
  const [updateData, updateDataInfo] = useUpdateSocialNetworkMutation();
  const AallFaculties = getAllData.data;
  const totalItem = AallFaculties ? AallFaculties.totalItem : 0;
  const [render, setRender] = useState(false);

  useEffect(() => {
    getid && deleteFaculty(getid) && setGetid("");
  }, [getid]);

  const AddData = (data) => {
    addData(data);
  };
  const UpdateData = (data, id) => {
    updateData({
      data,
      id: id,
    });
  };
  let totalData = "ok";
  if (AallFaculties) {
    totalData = AallFaculties.totalItem;
    rows = AallFaculties.data.map((data, index) =>
      createData(
        index + 1,
        <img
          width={60}
          height={60}
          src={data.image}
          alt=""
          className="img-fluid rounded "
        />,
        <Link target="_blank" href={data.link}>
          {data.social_media_name}
        </Link>,
        data.description,
        data.is_active ? "Yes" : "No",
        <div className="d-flex justify-content-center">
          {/* <EditDelete setViewDialog={setViewDialog}
                    type="view" setData={setData}
                    setGetid={setGetid} data={data} /> */}
          <AuthWrapper type="ACTION" name="CONFIGURATION_SOCIAL_MEDIA_EDIT">
            <EditDelete
              setEditDialog={setEditDialog}
              type="edit"
              setData={setData}
              setGetid={setGetid}
              data={data}
            />
          </AuthWrapper>
          <AuthWrapper type="ACTION" name="CONFIGURATION_SOCIAL_MEDIA_DELETE">
            <EditDelete type="delete" setGetid={setGetid} id={data._id} />
          </AuthWrapper>
        </div>
      )
    );
  }

  useEffect(() => {}, [render]);

  const dialogclose = () => {
    setEditDialog(false);
    setViewDialog(false);
    setDialog(false);
    setRender(!render);
    window.location.reload(false);
  };
  return (
    <div>
      {!viewDialog && (
        <AddDataHeader
          addFormName={"Add Social Network"}
          setPage={setPage}
          isSearch={
            !AuthWrapper({
              type: "ACTION",
              name: "CONFIGURATION_SOCIAL_MEDIA_LIST",
              inBoolean: true,
            })
          }
          isPagination={
            !AuthWrapper({
              type: "ACTION",
              name: "CONFIGURATION_SOCIAL_MEDIA_LIST",
              inBoolean: true,
            })
          }
          addBtn={
            !AuthWrapper({
              type: "ACTION",
              name: "CONFIGURATION_SOCIAL_MEDIA_ADD",
              inBoolean: true,
            })
          }
          page={page}
          excelBtn={false}
          totalItem={totalItem}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setAddDialog={setDialog}
          searchBarApi={getAllData}
          paginationData={paginationData}
          isButton={true}
          Adddialog={AddDialog}
          form={<AddSocial AddData={AddData} setDialog={setDialog} />}
        />
      )}
      <AuthWrapper type="ACTION" name="CONFIGURATION_SOCIAL_MEDIA_LIST">
        {deleteFacultyInfo.isLoading ||
        updateDataInfo.isLoading ||
        addDataInfo.isLoading ? (
          <Loader />
        ) : (
          <>
            {!viewDialog ? (
              rows.length ? (
                <CreateTableAddButton
                  editDialog={editDialog}
                  rows={rows}
                  editform={
                    <UpdateSocialNetwork
                      UpdateData={UpdateData}
                      editData={data}
                      editDialog={setEditDialog}
                    />
                  }
                  columns={getFilterdColumns(columns)}
                  isButton={true}
                  editFormName={"Edit Social Network"}
                />
              ) : totalData == 0 ? (
                <DataNotFound />
              ) : (
                <SkeletonListing />
              )
            ) : (
              <ViewFaculties viewDialog={setViewDialog} data={data} />
            )}
          </>
        )}{" "}
      </AuthWrapper>

      {addDataInfo.data && (
        <Toster info={addDataInfo} dialogclose={dialogclose} />
      )}
      {deleteFacultyInfo.data && (
        <Toster info={deleteFacultyInfo} dialogclose={dialogclose} />
      )}
      {updateDataInfo && (
        <Toster info={updateDataInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
