import { Button, Typography } from "@mui/material";

const UnAuthorized = () => {
  const handleGoBack = () => {
    window.location.replace("/");
  };
  return (
    <div className="d-flex justify-content-center align-items-center" style={{height:"100vh"}}>
      <div className="text-center">
        <h4 className="text-danger">
          You are not authorized to access this website.
        </h4>
        <Typography className="mt-4 ">Please contact your admin</Typography>
        <Button
          className=" bg-info mt-4 text-white px-5 rounded font-bold py-2"
          onClick={() => handleGoBack()}
        >
          Go Back
        </Button>
      </div>
    </div>
  );
};
export default UnAuthorized;
