import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AllUsers from "./AllUsers";
import AllFranchises from "./userAndFranchise/AllFranchises";
import AuthWrapper from "../../../utils/AuthWrapper";
import TabPanel from "./CreateTabPanel";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


const tabsNames = [
  {
    tabName: "Web Users",
    tabId: "USER_FRANCHISE_WEB_USERS",
  },
  {
    tabName: "Franchises",
    tabId: "USER_FRANCHISE_FRANCHISE",
  },
];


export default function UsersandFranchise() {
  let tabsToShow = tabsNames?.filter((e, i) => {
    return AuthWrapper({ type: "TAB", name: e?.tabId, inBoolean : true});
  });
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getTabPanel = (ele) => {
    let moduleToShow;
    switch (ele) {
      case "Web Users":
        moduleToShow = <AllUsers />;
        break;
      case "Franchises":
        moduleToShow = <AllFranchises />;
        break;
      default:
        break;
    }
    return moduleToShow;
  };
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          {tabsToShow.map((ele, ind) => {
            return (
              <Tab
                style={{ textTransform: "capitalize" }}
                label={ele?.tabName}
                {...a11yProps(ind)}
              />
            );
          })}
        </Tabs>
      </AppBar>

      {tabsToShow.map((ele, ind) => {
        return (
          <TabPanel value={value} index={ind}>
            {getTabPanel(ele?.tabName)}
          </TabPanel>
        );
      })}
    </div>
  );
}
