
import React, { useState, useEffect } from 'react';
import EditDelete from './EditDelete';
import CreateTableAddButton from './CreateTableAddButton'
import AddProduct from './AddProduct';
import Toster from './Toster';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Filters from './Filters';
import ViewProductBook from './ViewProductBook';
import UpdateProduct from './UpdateProduct';
import Loader from './Loader';
import AddDataHeader from './AddDataHeader';
import {
    Avatar,
    AccordionDetails, AccordionSummary, Typography, Accordion
} from "@material-ui/core";
import {
    useGetAllBookProductMutation,
    useAddBookProductsMutation,
    useDeletBookProductMutation,
    useUpdateBookProductMutation,
} from '../../../services/services';
import SkeletonListing from './SkeletonListing';
import DataNotFound from './DataNotFound';


export default function Product() {
    const [filteredPagination, setFilteredPagination] = React.useState([]);

    const columns = [
        {
            id: 'SNo',
            label: 'S.No',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'image',
            label: 'Image',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'name',
            label: 'Nickname',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'title',
            label: 'Title',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'MRP',
            label: 'MRP',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'course',
            label: 'Course Name',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'createat',
            label: 'Create-At',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'actions',
            label: 'Actions',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
    ];

    function createData(SNo, image, name, title, MRP, course, createat, actions) {
        return { SNo, image, name, title, MRP, course, createat, actions };
    }

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const paginationData = {
        "limit": rowsPerPage,
        "query": "",
        "params": [
            "product_title", "course_name", "level_name"
        ],
        "filterBy": filteredPagination,

        "page": page + 1
    }

    let rows = []
    let [getid, setGetid] = useState('')
    const [data, setData] = useState('')
    const [AddDialog, setDialog] = useState(false)
    const [editDialog, setEditDialog] = useState(false)
    const [viewDialog, setViewDialog] = useState(false)
    const [getAllData, getAllDataInfo] = useGetAllBookProductMutation()
    const [addData, addDataInfo] = useAddBookProductsMutation();
    const [deleteData, deleteDataInfo] = useDeletBookProductMutation()
    const [updateData, updateDataInfo] = useUpdateBookProductMutation()
    const AllData = getAllDataInfo.data
    const totalItem = AllData ? AllData.totalItem : 0
    const [render, setRender] = useState(false)
    useEffect(() => {
        getAllData(paginationData)
    }, [render, rowsPerPage, page, filteredPagination])

    useEffect(() => {
        getid && deleteData(getid) && setGetid("")
    }, [getid])

    const AddData = (data) => {
        addData(data)
    }
    const UpdateProductData = (data, id) => {
          
        updateData({
            body: data,
            id: id
        })
    }
    let totalData = 'ok'
    if (AllData) {
        totalData = AllData.totalItem
        rows = AllData.data.map((data, index) => createData(
            index + 1,
            <div className="d-flex justify-content-center">
                <Avatar src={data.image} />
            </div>,
            data.nickname,
            data.product_title,
            data.product_mrp,
            data.course_name,
            data.createdAt,
            <div className="d-flex">
                <EditDelete setViewDialog={setViewDialog}
                    type="view" setData={setData}
                    setGetid={setGetid} data={data} />

                <EditDelete
                    setEditDialog={setEditDialog}
                    type="edit" setData={setData}
                    setGetid={setGetid} data={data} />

                <EditDelete type="delete"
                    setGetid={setGetid}
                    id={data._id} />
            </div>)
        )
    }
    const dialogclose = () => {
        setEditDialog(false)
        setViewDialog(false)
        setDialog(false)
        setRender(!render)
    }
    return (
        <div>
            {!viewDialog &&
                <>
                    <AddDataHeader
                        addFormName={"Add Book Product"}
                        setPage={setPage}
                        page={page}
                        excelBtn={false}
                        totalItem={totalItem}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        setAddDialog={setDialog}
                        searchBarApi={getAllData}
                        paginationData={paginationData}
                        isButton={true}
                        Adddialog={AddDialog}
                        form={<AddProduct AddData={AddData} setDialog={setDialog} />}
                    />

                    <Accordion className=''>
                        <AccordionSummary className='text-primary  '
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header" >
                            <Typography className='d-flex align-items-center ' ><FilterListIcon /> <span > Filter</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails className=' d-flex justify-content-between'>
                            <Filters
                                setFilteredPagination={setFilteredPagination}
                                isSubFilter={true}
                                isFacFilter={true}
                                isSyllabusFilter={true}
                                isFromFilter={false}
                                isToFilter={false}
                            />
                        </AccordionDetails>
                    </Accordion>
                </>
            }

            {deleteDataInfo.isLoading || addDataInfo.isLoading || updateDataInfo.isLoading ? <Loader /> :
                <>
                    {!viewDialog ?
                        rows.length ?
                            <CreateTableAddButton
                                editDialog={editDialog}
                                rows={rows}
                                fullsize={true}
                                editform={
                                    <UpdateProduct
                                        UpdateProductData={UpdateProductData}
                                        editProduct={data}
                                        editDialog={setEditDialog} />
                                }
                                columns={columns} isButton={true}
                                editFormName={'Edit Book Product'} />
                            : totalData == 0 ? <DataNotFound /> : <SkeletonListing />
                        :
                        <ViewProductBook viewDialog={setViewDialog} data={data} />
                    }
                </>
            }
            {addDataInfo.data && <Toster info={addDataInfo} dialogclose={dialogclose} />}
            {deleteDataInfo.data && <Toster info={deleteDataInfo} dialogclose={dialogclose} />}
            {updateDataInfo && <Toster info={updateDataInfo} dialogclose={dialogclose} />}
        </div>
    )
}