import { Button, Grid, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import UsbIcon from "@material-ui/icons/Usb";
import AddEditOfflineOrder from "./AddEditOfflineOrder";
import AddEditPendriveOrder from "./addEditOrder/AddEditPendriveOrder";
import AddVirtualClassOrder from "./virtualClassOrder/AddVirtualClassOrder";
import AddSemiVirtualClassOrder from "./semiVirtualClassOrder/AddSemiVirtualClassOrder";
import AddPendriveClassOrder from "./pendriveClassOrder/AddPendriveClassOrder";

const cardDetails = [
  // {
  //   icon: <DesktopMacIcon fontSize="large" style={{ color: "white" }} />,
  //   name: "Virtual Class Order",
  // },
  {
    icon: <UsbIcon fontSize="large" style={{ color: "white" }} />,
    name: "Pendrive Class Order",
  },
  // {
  //   icon: <UsbIcon fontSize="large" style={{ color: "white" }} />,
  //   name: "Semi-Virtual Class Order",
  // },
];

export default function OfflineDashboard() {
  const [openVirtualOrderForm, setOpenVirtualOrderForm] = useState(false);
  const [openSemiVirtualOrderForm, setOpenSemiVirtualOrderForm] =
    useState(false);

  const [openPendriveOrderForm, setOpenPendriveOrderForm] = useState(false);


  const handleNewOrderClick = (type) => {
    switch (type) {
      case "Virtual Class Order":
        setOpenSemiVirtualOrderForm(false);
        setOpenPendriveOrderForm(false);
        setOpenVirtualOrderForm(true);
        break;
      case "Semi-Virtual Class Order":
        setOpenVirtualOrderForm(false);
        setOpenPendriveOrderForm(false);
        setOpenSemiVirtualOrderForm(true);
        break;
      case "Pendrive Class Order":
        setOpenVirtualOrderForm(false);
        setOpenSemiVirtualOrderForm(false);
        setOpenPendriveOrderForm(true);
        break;

      default:
        break;
    }
  };

  return (
    <div>
      {!openVirtualOrderForm && !openSemiVirtualOrderForm && !openPendriveOrderForm && (
        <Grid
          container
          spacing={4}
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="p-5"
        >
          {cardDetails.map((ele) => {
            return (
              <Grid item xs={3}>
                <Paper
                  elevation={20}
                  style={{ height: "325px" }}
                  className="p-4 text-center offline-order-card animate-grow"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ borderRadius: "50%", padding: "30px" }}
                    className="animate-360-deg"
                  >
                    {ele.icon}
                  </Button>
                  <Typography variant="h5" className="mt-5">
                    {" "}
                    {ele.name}{" "}
                  </Typography>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="small"
                    className="mt-5 translate-btn"
                    onClick={() => {
                      handleNewOrderClick(ele.name);
                    }}
                  >
                    {" "}
                    <AddShoppingCartIcon /> &nbsp; &nbsp; &nbsp; NEW ORDER
                  </Button>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      )}
      {openVirtualOrderForm && (
        <AddVirtualClassOrder setOpenOrderForm={setOpenVirtualOrderForm} />
      )}
      {openSemiVirtualOrderForm && (
        <AddSemiVirtualClassOrder
          setOpenOrderForm={setOpenSemiVirtualOrderForm}
        />
      )}
      {openPendriveOrderForm && (
        <AddPendriveClassOrder setOpenOrderForm={setOpenPendriveOrderForm} />
      )}

      {/* <AddEditOfflineOrder
        setOpenOrderForm={setOpenOrderForm}
        orderType={orderType}
      /> */}
      {/* <AddEditPendriveOrder /> */}

    </div>
  );
}
