import React, { useState, useEffect } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import { Avatar } from "@material-ui/core";
import Loader from "../Loader";
import AddDataHeader from "../AddDataHeader";
import {
  useAddFacultyMutation
} from "../../../../services/services";
import {
  useUpdateTicketDetailsMutation,
  useGetTicketMutation,
  useDeleteTicketMutation
} from "../../../../services/ticketservices";
import Toster from "../Toster";
import AddFaculty from ".././faculty/AddFaculty";
import ViewTicket from ".././tickets/ViewTicket";
import EditTicket from ".././tickets/EditTicket";
import SkeletonListing from "../SkeletonListing";
import DataNotFound from "../DataNotFound";
import moment from "moment";
import AuthWrapper from "../../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../../utils/getFilterFunction";

export default function AllTickets() {
  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "user_name",
      label: "User",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "type_of_ticket",
      label: "Type of Ticket",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "current_status",
      label: "Current Status",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "ticket_id",
      label: "ticket id",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "updated_at",
      label: "Last Updated",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "joining_date",
      label: "Join Date",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "Actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: ['POST_SELL_SUPPORT_VIEW', 'POST_SELL_SUPPORT_DELETE']
    },
  ];
  function createData(
    SNo,
    user_name,
    type_of_ticket,
    current_status,
    ticket_id,
    updated_at,
    joining_date,
    Actions
  ) {
    return {
      SNo,
      user_name,
      type_of_ticket,
      current_status,
      ticket_id,
      updated_at,
      joining_date,
      Actions,
    };
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [editDialog, setEditDialog] = useState(false)

  const paginationData = {
    limit: rowsPerPage,
    query: "",

    page: page + 1,

    "params": [
      "ticket_id",
      "user_id",
      "current_status",
      "reason",
      "type_of_ticket",
      "created_on"
    ],

    "filterBy": []
  };

  let rows = [];
  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [AddDialog, setDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [getAllData, getAllDataInfo] = useGetTicketMutation();
  const [deleteTicket, deleteTicketInfo] = useDeleteTicketMutation();
  const [updateTicket, updateTicketInfo] = useUpdateTicketDetailsMutation();
  const [addData, addDataInfo] = useAddFacultyMutation();
  const AallTickets = getAllDataInfo.data;

  const totalItem = AallTickets ? AallTickets.totalItem : 0;
  const [render, setRender] = useState(false);

  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page]);

  useEffect(() => {
    getid && deleteTicket(getid) && setGetid("");
  }, [getid]);

  const AddData = (data) => {
    addData(data);
  };
  const UpdateData = (data, id) => {
    data.id = id;
    updateTicket(data)
  }

  let totalData = "ok";

  if (AallTickets) {
    totalData = AallTickets.totalItem;
    rows = AallTickets.data.map((data, index) =>
      createData(
        index + 1,
        data.user_name,
        data.type_of_ticket.replaceAll("_", " "),
        data.current_status,
        data.ticket_id,
        moment(data.updatedAt).format("DD-MM-YYYY hh:mm:ss A"),
        moment(data.created_on).format("DD-MM-YYYY hh:mm:ss A"),
        <div className="">
          {/* <Switch
            onClick={() => {
              updateIsShowStatus({
                key: "contact_us",
                cms_data: {
                  is_show: !data.is_show,
                  contact_us_id: data._id,
                },
              });
            }}
            checked={data.is_show}
          /> */}
          <AuthWrapper type="ACTION" name="POST_SELL_SUPPORT_VIEW">
            <EditDelete
              setViewDialog={setViewDialog}
              type="view"
              setData={setData}
              setGetid={setGetid}
              data={data}
            />
          </AuthWrapper>
          <br />
          <AuthWrapper type="ACTION" name="POST_SELL_SUPPORT_DELETE">
            <EditDelete type="delete" setGetid={setGetid} id={data._id} />
          </AuthWrapper>
          {/* <EditDelete
                    setEditDialog={setEditDialog}
                    type="edit" setData={setData}
                    setGetid={setGetid} data={data} /> */}
        </div>
      )
    );
  }
  const dialogclose = () => {
    setViewDialog(false);
    setDialog(false);
    setRender(!render);
  };
  return (
    <div>
      {!viewDialog && (
        <AddDataHeader
          addFormName={"Add Ticket"}
          setPage={setPage}
          page={page}
          excelBtn={false}
          totalItem={totalItem}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setAddDialog={setDialog}
          searchBarApi={getAllData}
          paginationData={paginationData}
          isButton={true}
          Adddialog={AddDialog}
          addBtn={true}
          isSearch={
            !AuthWrapper({
              type: "ACTION",
              name: "POST_SELL_SUPPORT_LIST",
              inBoolean: true,
            })
          }
          isPagination={
            !AuthWrapper({
              type: "ACTION",
              name: "POST_SELL_SUPPORT_LIST",
              inBoolean: true,
            })
          }
          form={<AddFaculty AddData={AddData} setDialog={setDialog} />}
        />
      )}

      <AuthWrapper type="ACTION" name="POST_SELL_SUPPORT_LIST">
        {deleteTicketInfo.isLoading ||
          addDataInfo.isLoading ? (
          <Loader />
        ) : (
          <>
            {!viewDialog ? (
              rows.length ? (
                <CreateTableAddButton
                  editDialog={editDialog}
                  setPage={setPage}
                  rows={rows}
                  editform={<EditTicket editData={data}
                    UpdateData={UpdateData}
                    editDialog={setEditDialog} />}
                  columns={getFilterdColumns(columns)}
                  isButton={true}
                  editFormName={'Edit ticket'}
                />
              ) : totalData == 0 ? (
                <DataNotFound />
              ) : (
                <SkeletonListing />
              )
            ) : (
              <ViewTicket viewDialog={setViewDialog} data={data} UpdateData={UpdateData} />
              // <EditTicket 
            )}
          </>
        )}
      </AuthWrapper>

      {addDataInfo.data && (
        <Toster info={addDataInfo} dialogclose={dialogclose} />
      )}
      {deleteTicketInfo.data && (
        <Toster info={deleteTicketInfo} dialogclose={dialogclose} />
      )}
      {updateTicketInfo.data && (
        <Toster info={updateTicketInfo} dialogclose={dialogclose} />
      )}


    </div>
  );
}
