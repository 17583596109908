import React, { useState, useEffect } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import AddDataHeader from "../AddDataHeader";
import DataNotFound from "../DataNotFound";
import FormDialog from "../reusableComponents/NewFormDialog";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Dialog, DialogContent, DialogActions, DialogTitle, Slide, Grid, Button, Typography, Divider, IconButton } from "@material-ui/core";
import { useGetAllCenterQuery, useAddCenterMutation, useUpdateCenterMutation, useDeleteCenterMutation, useChangeDefaultCenterMutation } from "../../../../services/offlineModuleServices/centersServices";
import AddCenter from "./AddEditCenter";
import EditCenter from "./EditCenter";




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function AllCenters() {

    const [getid, setGetid] = useState("");
    const [data, setData] = useState("");
    const [addDialog, setAddDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [viewDialog, setViewDialog] = useState(false)
    const [render, setRender] = useState(false)

    const { data: AllCenters, isAllCentersFetching, isAllCentersLoading } = useGetAllCenterQuery()
    const [addCenter, addCenterInfo] = useAddCenterMutation()
    const [updateCenter, updateCenterInfo] = useUpdateCenterMutation()
    const [deleteCenter, deleteCenterInfo] = useDeleteCenterMutation()
    const [changeDefaultCenter, changeDefaultCenterInfo] = useChangeDefaultCenterMutation()







    const columns = [
        {
            id: "SNo",
            label: "S.No",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "centerName",
            label: "center Name",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "address",
            label: "Address",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "actions",
            label: "Actions",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
    ];
    function createData(SNo, centerName, address, actions) {
        return {
            SNo,
            centerName,
            address,
            actions,
        };
    }
    let rows = [];
    const totalItem = AllCenters ? AllCenters.totalItem : 0;

    let totalData = "ok";


    useEffect(() => {
        getid && deleteCenter(getid) && setGetid("");
    }, [getid]);

    if (
        AllCenters
    ) {
        totalData = AllCenters.totalItem
        rows = AllCenters.data.map((ele, index) => {
            return createData(
                index + 1,
                ele.center_name,
                ele.full_address,
                <div>
                    <EditDelete
                        setViewDialog={setViewDialog}
                        type="view"
                        setData={setData}
                        data={ele}
                    />
                    <EditDelete
                        setEditDialog={setEditDialog}
                        type="edit"
                        setData={setData}
                        data={ele}
                    />
                    <EditDelete type="delete" setGetid={setGetid} id={ele._id} />

                    {!ele.defaultOnInvoice ?
                        <IconButton  > <VerifiedUserIcon onClick={() => { changeDefaultCenter(ele._id) }} /> </IconButton>

                        :
                        <IconButton className="text-success" > <VerifiedUserIcon /> </IconButton>

                    }
                </div>
            );
        });
    }


    const dialogclose = () => {
        setEditDialog(false);
        setAddDialog(false);
        setRender(!render);
    };

    const updateBtnClickHandler = (dataForUpdate) => {
        updateCenter({ body: dataForUpdate, id: data._id });
    };

    const addBtnClickHandler = (dataForAdd) => {
        addCenter(dataForAdd)
    }


    return (
        <div>
            {/* {!viewDialog && ( */}
            <AddDataHeader
                addFormName={"Add New Center"}
                isSearch={true}
                isPagination={true}
                setAddDialog={setAddDialog}
                isButton={true}
                Adddialog={addDialog}
                form={
                    <AddCenter onClose={() => setAddDialog(false)} />
                }
            />
            {/* )} */}


            <>
                {rows.length ? (
                    <CreateTableAddButton
                        editDialog={editDialog}
                        // setPage={setPage}
                        rows={rows}
                        fullWidth={false}
                        editform={
                            <EditCenter onClose={() => setEditDialog(false)}  initialData= {data} />
                        }
                        columns={columns}
                        isButton={true}
                        editFormName={"Edit Center"}
                    />
                ) : totalData == 0 ? (
                    <DataNotFound />
                ) : (
                    <SkeletonListing />
                )}

            </>





            {addCenterInfo && (
                <Toster info={addCenterInfo} dialogclose={dialogclose} />
            )}

            {updateCenterInfo && (
                <Toster info={updateCenterInfo} dialogclose={dialogclose} />
            )}

            {deleteCenterInfo && (
                <Toster info={deleteCenterInfo} dialogclose={dialogclose} />
            )}

            {changeDefaultCenterInfo && (
                <Toster info={changeDefaultCenterInfo} dialogclose={dialogclose} />
            )}


            {viewDialog &&
                <Dialog
                    open={viewDialog}
                    fullWidth
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => { setViewDialog(false) }}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle style={{ backgroundColor: "#5664d2" }} className="text-light">Center Details</DialogTitle>
                    <DialogContent>

                        <Grid container className="mt-4" >
                            <Grid item xs={3}  >
                                Center Name :
                            </Grid>
                            <Grid item xs={8} >
                                <Typography variant="body1"> {data.center_name} </Typography>
                            </Grid>
                        </Grid>
                        <Divider className="mt-2" />

                        <Grid container className="mt-4" >
                            <Grid item xs={3} >
                                Mobile:
                            </Grid>
                            <Grid item xs={9} >
                                <Typography variant="body1"> {data.contact_no} </Typography>
                            </Grid>
                        </Grid>
                        <Divider className="mt-2" />


                        <Grid container className="mt-4" >
                            <Grid item xs={3} >
                                Email :
                            </Grid>
                            <Grid item xs={9} >
                                <Typography variant="body1"> {data.email} </Typography>
                            </Grid>
                        </Grid>
                        <Divider className="mt-2" />

                        <Grid container className="mt-4" >
                            <Grid item xs={3} >
                                Website :
                            </Grid>
                            <Grid item xs={9} >
                                <Typography variant="body1">  {data.website} </Typography>
                            </Grid>
                        </Grid>
                        <Divider className="mt-2" />

                        <Grid container className="mt-4" >
                            <Grid item xs={3} >
                                Address:
                            </Grid>
                            <Grid item xs={9} >
                                <Typography variant="body1"> {data.full_address} </Typography>
                            </Grid>
                        </Grid>
                        <Divider className="mt-2" />

                        <Grid container className="mt-4" >
                            <Grid item xs={3} >
                                Office Timing :
                            </Grid>
                            <Grid item xs={9} >
                                <Typography variant="body1">  {data.office_timing} </Typography>
                            </Grid>
                        </Grid>
                        <Divider className="mt-2" />

                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary" onClick={() => { setViewDialog(false) }}>Close</Button>
                    </DialogActions>
                </Dialog>
            }

        </div>
    );
}
