import React, { useState, useEffect } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import AddDataHeader from "../AddDataHeader";
import DataNotFound from "../DataNotFound";
import FormDialog from "../reusableComponents/NewFormDialog";
import AddEditFile from "./AddEditFile";
import { useGetAllFilesQuery } from "../../../../services/file explorer Services/fileExplorerServices";
import { Avatar, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography, } from "@material-ui/core";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import  Tooltip , { tooltipClasses }  from "@material-ui/core/Tooltip";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { useAddFileMutation, useUpdateFileMutation, useDeleteFileMutation } from "../../../../services/file explorer Services/fileExplorerServices";
import moment from "moment";
import AuthWrapper from "../../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../../utils/getFilterFunction";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export default function FileExplorer() {

  const [filteredPagination, setFilteredPagination] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [render, setRender] = useState(false);
  const [hasActionsData, setHasActionsData] = useState(false);

  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: [
      "name"
    ],
    page: page + 1,
    filterBy: filteredPagination
  }
  const [AddFile, AddFileInfo] = useAddFileMutation()
  const [UpdateFile, UpdateFileInfo] = useUpdateFileMutation()
  const [DeleteFile, DeleteFileInfo] = useDeleteFileMutation()

  const [pagination, setPagination] = useState(paginationData)
  const { data: AllFiles, isFetching, isLoading } = useGetAllFilesQuery(pagination)


  useEffect(() => {

    setPagination(paginationData);
  }, [render, page, rowsPerPage, filteredPagination]);



  const [addDialog, setAddDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewImageURL, setViewImageURL] = useState("");


  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [isCopied, setIsCopied] = useState("");

  let copiedInitialState = AllFiles?.data.reduce((obj, ele) => ({ ...obj, [ele._id]: false }), {})


  useEffect(() => {
    setIsCopied(copiedInitialState && copiedInitialState)
  }, [isFetching])


  const columns = [
    {
      id: "image",
      label: "Image",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "name",
      label: "Name",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "type",
      label: "Type",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "date",
      label: "Date",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: ['FILE_EXPLORER_EDIT', 'FILE_EXPLORER_COPY', 'FILE_EXPLORER_DELETE']
    },
  ];
  function createData(image, name, type, date, actions) {
    return {
      image,
      name,
      type,
      date,
      actions,
    };
  }

  let rows = [];

  let totalData = "ok";

  useEffect(() => {
    getid && DeleteFile(getid) && setGetid("");
  }, [getid]);

  const totalItem = AllFiles ? AllFiles.totalItem : 0;

  if (
    !isFetching &&
    !isLoading &&
    isCopied &&
    AllFiles
  ) {

    totalData = AllFiles.totalItem;
    rows = AllFiles.data.map((ele, index) => {
      return createData(
        <div className="d-flex justify-content-center">
          <BootstrapTooltip placement="top-end" title="View Image" arrow>
            <Button onClick={() => { setViewImageURL(ele.url) }} > <Avatar src={ele.url} /> </Button>
          </BootstrapTooltip>

        </div>,
        ele.name,
        ele.type,
        moment(ele.date).format("DD-MM-YYYY"),
        <div>
          <AuthWrapper type="ACTION" name="FILE_EXPLORER_COPY">
            <CopyToClipboard
              text={ele.url}
              onCopy={() => { setIsCopied({ ...copiedInitialState, [ele._id]: true }) }}
            >
              <BootstrapTooltip placement="top-end" title={isCopied[ele._id] ? <span style={{ fontSize: "12px" }} > <span className="text-success font-weight-bold" > &#10003; </span> copied</span> : "copy link"} arrow>
                <IconButton color="primary">
                  <FileCopyOutlinedIcon />
                </IconButton>
              </BootstrapTooltip>

            </CopyToClipboard>
          </AuthWrapper>
          <AuthWrapper type="ACTION" name="FILE_EXPLORER_EDIT">
            <EditDelete type="edit" setEditDialog={setEditDialog} setData={setData} data={ele} />
          </AuthWrapper>
          <AuthWrapper type="ACTION" name="FILE_EXPLORER_DELETE">

            <EditDelete type="delete" setGetid={setGetid} id={ele._id} />
          </AuthWrapper>
        </div>
      );
    });
  }

  // );
  //   }

  const dialogclose = () => {
    setAddDialog(false)
    setEditDialog(false)
  }

  return (
    <div>
      {!viewDialog && (
        <AddDataHeader
          addFormName={"Upload File"}
          setPage={setPage}
          page={page}
          excelBtn={false}
          totalItem={totalItem}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          searchBarApi={setPagination}
          setAddDialog={setAddDialog}
          isButton={true}
          paginationData={pagination}
          Adddialog={addDialog}
          isSearch={
            !AuthWrapper({
              type: "ACTION",
              name: "FILE_EXPLORER_LIST",
              inBoolean: true,
            })
          }
          isPagination={
            !AuthWrapper({
              type: "ACTION",
              name: "FILE_EXPLORER_LIST",
              inBoolean: true,
            })
          }
          addBtn={
            !AuthWrapper({
              type: "ACTION",
              name: "FILE_EXPLORER_UPLOAD",
              inBoolean: true,
            })
          }
          form={
            <AddEditFile setOpen={setAddDialog} onSave={AddFile} />

          }
        />
      )}
      <>
        <AuthWrapper type="ACTION" name="FILE_EXPLORER_LIST">
          {rows?.length ? (
            <CreateTableAddButton
              editDialog={editDialog}
              setPage={setPage}
              rows={rows}
              editform={
                <AddEditFile edit setOpen={setEditDialog} onSave={UpdateFile} initialData={data} />

              }
              columns={getFilterdColumns(columns)}
              isButton={true}
              editFormName={"Edit File"}
            />
          ) : totalData == 0 ? (
            <DataNotFound />
          ) : (
            <SkeletonListing />
          )
          }
        </AuthWrapper>


      </>

      {viewImageURL &&
        <Dialog open={viewImageURL}>
          <DialogTitle style={{ backgroundColor: "rgb(86 100 210)" }} >
            <Grid container item xs={12} justifyContent="flex-end">
              <IconButton onClick={() => { setViewImageURL("") }} > <Typography className="text-light"> X </Typography> </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container item xs={12}   >
              <Grid >
                <img
                  className="py-5"
                  alt="Image"
                  src={viewImageURL}
                  style={{ width: 500, height: 500 }}
                />
              </Grid>
            </Grid>
          </DialogContent>

        </Dialog>

      }

      {
        AddFileInfo && (
          <Toster info={AddFileInfo} dialogclose={dialogclose} />
        )
      }

      {UpdateFileInfo && (
        <Toster info={UpdateFileInfo} dialogclose={dialogclose} />
      )}

      {DeleteFileInfo && (
        <Toster info={DeleteFileInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
