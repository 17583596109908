import Delete from "@material-ui/icons/Delete";
import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const facultyCommisionRuleApi = createApi({
  // tagTypes: ["facultyCommisionRule"],
  reducerPath: "facultyCommisionRule",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/commission-rule/`,
  }),
  endpoints: (builder) => ({

    addFacultyCommisionRule: builder.mutation({
      // invalidatesTags: ["facultyCommisionRule"],
      query: (body) => {
        return {
          url: "add-new-rule",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,

        }
      }

    }),

    deleteFacultyCommisionRule: builder.mutation({
      // invalidatesTags: ["facultyCommisionRule"],                               
      query: (commisionId) => {
        return {
          url: `${commisionId}`,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE"
        }
      }

    }),

  }),

});

export const {
  useAddFacultyCommisionRuleMutation,
  useDeleteFacultyCommisionRuleMutation
} = facultyCommisionRuleApi;