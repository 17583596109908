import React, { useState, useEffect } from "react";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import * as yup from 'yup'
import { useFormik } from 'formik';
import {
  Grid,
  Button,
  Card,
  Box,
  CardContent,
  Dialog, Zoom, DialogTitle, DialogContent, TextField
} from "@material-ui/core";
import {
  useUpdateFacultyMutation,
  useGetSingleFacultyMutation,
} from '../../../../services/services'
import DataNotFound from "../DataNotFound";

export default function Contacts({ viewDialog, data, setFacultyData }) {

  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "issue_type",
      label: "Issue",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "contact_number",
      label: "Contact Number",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    }
  ];
  function createData(SNo, issue_type, contact_number) {
    return {
      SNo, issue_type, contact_number
    };
  }

  let rows = [];
  const [updateData, updateDataInfo] = useUpdateFacultyMutation();
  const [singleFacultyData, singleFacultyDataInfo] = useGetSingleFacultyMutation();
  const [updateDialog, setUpdateDialog] = useState(false);

  if (typeof data.contacts == 'object') {
    let ind = 1
    for (let key in data.contacts) {
      rows.push(
        createData(
          ind,
          key.replaceAll('_', ' '),
          data.contacts[key]
        )
      );
      ind++
    }
  }


  useEffect(() => {
    singleFacultyData(data._id)
  }, [updateDataInfo])

  const dialogclose = () => {
    setUpdateDialog(false);
  };

  return (
    <>
      <Card sx={{ display: "flex" }} className="m-3" >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>

            <Button
              onClick={() => viewDialog(false)}
              className="text-light bg-danger float-right btn btn-sm btn-danger"
            >
              Close
            </Button>
          </CardContent>
          {singleFacultyDataInfo.isSuccess == true && singleFacultyDataInfo.data.data.length ?
            singleFacultyDataInfo.isLoading == false ?
              <div>

                {setFacultyData(singleFacultyDataInfo.data.data[0])}
                <Button
                  onClick={() => {
                    setUpdateDialog(true);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Update Contact
                </Button>
                {
                  rows.length ?
                    <CreateTableAddButton
                      editDialog={null}
                      setPage={null}
                      rows={rows}
                      editform={null}
                      columns={columns}
                      isButton={true}
                      editFormName={null}
                    /> :
                    <Grid xs={12} className="my-1 text-center bg-light p-3" >NO DATA FOUND</Grid>
                }


                {updateDialog ? <AddMedia
                  updateDialog={updateDialog}
                  setUpdateDialog={setUpdateDialog}
                  updateData={updateData}
                  facultyId={data._id}
                  currentData={singleFacultyDataInfo.data.data[0].contacts} /> : null}
                {updateDataInfo.data && (
                  <Toster info={updateDataInfo} dialogclose={dialogclose} />
                )}
              </div>
              :
              <SkeletonListing />
            :
            <Grid container justifyContent="center">
              <DataNotFound />
            </Grid>

          }
        </Box>
      </Card>
    </>

  );
}

function AddMedia({ updateDialog, setUpdateDialog, updateData, facultyId, currentData }) {
  const formik = useFormik({

    initialValues: {
      ...currentData
    },
    validationSchema: yup.object({
      dispatch: yup.string().required("Required!"),
      doubt_solving: yup.string().required("Required!"),
      link_doubt_solving: yup.string().required("Required!"),
      technical_issue: yup.string().required("Required!")
    }),
    onSubmit: (values, e) => {
      var form_data = new FormData()
      form_data.append('key', 'contacts')
      form_data.append('update_data', JSON.stringify(values))
      updateData({ data: form_data, id: facultyId })
    }
  })

  return (
    <Dialog
      fullScreen={false}
      open={updateDialog}
      //scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
      TransitionComponent={Zoom}
    >
      <DialogTitle id="scroll-dialog-title">Update Contact</DialogTitle>
      <DialogContent
      //dividers={scroll === "paper"}
      >
        <form id="updateForm" onSubmit={formik.handleSubmit}>
          <Grid style={{ paddingLeft: 10, paddingRight: 10, marginTop: 20, marginLeft: 10 }} >
            <Grid container spacing={1} >
              <Grid item xs={6}>
                <TextField
                  name="dispatch"
                  label="Dispatch"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.dispatch}
                  fullWidth={true}
                  variant="outlined"
                  size="medium" />
                {formik.touched.dispatch && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.dispatch}</span>}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="doubt_solving"
                  label="Doubt Solving"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.doubt_solving}
                  fullWidth={true}
                  variant="outlined"
                  size="medium" />
                {formik.touched.doubt_solving && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.doubt_solving}</span>}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="link_doubt_solving"
                  label="Link Doubt Solving"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.link_doubt_solving}
                  fullWidth={true}
                  variant="outlined"
                  size="medium" />
                {formik.touched.link_doubt_solving && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.link_doubt_solving}</span>}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="technical_issue"
                  label="Technical Issue"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.technical_issue}
                  fullWidth={true}
                  variant="outlined"
                  size="medium" />
                {formik.touched.technical_issue && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.technical_issue}</span>}
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid container spacing={3} >
              <Grid item xs={12}>
                <div className="text-right">
                  <Button
                    onClick={() => setUpdateDialog(false)}
                    variant="contained"
                    color="secondary">
                    Close</Button>
                  <Button
                    type="submit"
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    color="primary">
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid >
        </form >
      </DialogContent>
    </Dialog>
  )

}
