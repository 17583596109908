
import React, { useState, useEffect } from 'react';
import EditDelete from './EditDelete';
import CreateTableAddButton from './CreateTableAddButton'
import UpdateBooks from './UpdateBooks'
import AddBooks from './AddBooks';
import Toster from './Toster';
import ViewBook from './ViewBook';
import {
    useGetAllBooksMutation, useDeletBookMutation,
    useAddBooksMutation, useUpdateBooksMutation,
} from '../../../services/services';
import SkeletonListing from './SkeletonListing';
import Loader from './Loader';
import AddDataHeader from './AddDataHeader';
import DataNotFound from './DataNotFound';
import { AccordionDetails, AccordionSummary, Typography, Accordion } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Filters from './Filters';

export default function Institute() {
    const [filteredPagination, setFilteredPagination] = React.useState([]);

    const columns = [
        {
            id: 'SNo',
            label: 'S.No',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'bookName',
            label: ' Book Name',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'totalQuantity',
            label: 'Total Quantity',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'totalSold',
            label: 'Total Sold',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'remainingQuantity',
            label: 'Remaining Quantity',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'bookAddedDate',
            label: 'Book Added Date',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'Actions',
            label: 'Actions',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
    ];
    function createData(SNo, bookName, totalQuantity, totalSold, remainingQuantity, bookAddedDate, Actions) {
        return { SNo, bookName, totalQuantity, totalSold, remainingQuantity, bookAddedDate, Actions };
    }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [render, setRender] = useState(false)
    const paginationData = {
        "limit": rowsPerPage,
        "query": "",
        "params": [
            "book_name", "title"
        ],
        "filterBy": filteredPagination,

        "page": page + 1
    }

    let rows = []
    let [getid, setGetid] = useState('')
    const [data, setData] = useState('')
    const [AddDialog, setDialog] = useState(false)
    const [editDialog, setEditDialog] = useState(false)
    const [viewDialog, setViewDialog] = useState(false)
    const [getAllData, getAllDataInfo] = useGetAllBooksMutation()
    const [deleteBookStock, deleteBookStockInfo] = useDeletBookMutation()
    const [addBooks, addBooksInfo] = useAddBooksMutation()

    const [updateBook, updateBookInfo] = useUpdateBooksMutation()
    const allBookstock = getAllDataInfo.data

    const totalItem = allBookstock ? allBookstock.totalItem : 0
    useEffect(() => {
        getAllData(paginationData)
    }, [render, rowsPerPage, page, filteredPagination])

    useEffect(() => {
        getid && deleteBookStock(getid) && setGetid("")
    }, [getid])

    const AddData = (data) => {
        addBooks(data)
    }
    const UpdateData = (data) => {
        updateBook(data)
    }
    let totalData = 'ok'
    if (allBookstock) {
        totalData = allBookstock.totalItem
        rows = allBookstock.data.map((data, index) => createData(
            index + 1,
            data.book_name,
            data.quantity,
            "",
            "",
            data.createdAt,
            <div>
                <EditDelete setViewDialog={setViewDialog}
                    type="view" setData={setData}
                    setGetid={setGetid} data={data} />
                <EditDelete
                    setEditDialog={setEditDialog}
                    type="edit" setData={setData}
                    setGetid={setGetid} data={data} />
                <EditDelete type="delete"
                    setGetid={setGetid}
                    id={data._id} />
            </div>
        )
        )
    }
    const dialogclose = () => {
        setEditDialog(false)
        setViewDialog(false)
        setDialog(false)
        setRender(!render)
    }
    return (
        <div>
            {!viewDialog &&
                <>
                    <AddDataHeader
                        addFormName={"Add Book Stock"}
                        setPage={setPage}
                        page={page}
                        excelBtn={false}
                        totalItem={totalItem}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        setAddDialog={setDialog}
                        searchBarApi={getAllData}
                        paginationData={paginationData}
                        isButton={true}
                        Adddialog={AddDialog}
                        form={<AddBooks AddData={AddData} setDialog={setDialog} />}
                    />

                    <Accordion className=''>
                        <AccordionSummary className='text-primary  '
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header" >
                            <Typography className='d-flex align-items-center ' ><FilterListIcon /> <span > Filter</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails className=' d-flex justify-content-between'>
                            <Filters
                                setFilteredPagination={setFilteredPagination}
                                isSubFilter={true}
                                isFacFilter={true}
                                isSyllabusFilter={false}
                                isFromFilter={false}
                                isToFilter={false}
                                isBookName={true}
                            />
                        </AccordionDetails>
                    </Accordion>
                </>
            }

            {deleteBookStockInfo.isLoading || updateBookInfo.isLoading ? <Loader /> :
                <>
                    {!viewDialog ?
                        rows.length ?
                            <CreateTableAddButton
                                editDialog={editDialog}
                                setPage={setPage}
                                rows={rows}
                                editform={
                                    <UpdateBooks editData={data}
                                        UpdateData={UpdateData}
                                        editDialog={setEditDialog} />
                                }
                                columns={columns} isButton={true}
                                editFormName={'Edit Book Stock'} />
                            : totalData == 0 ? <DataNotFound /> : <SkeletonListing />
                        :
                        <ViewBook viewDialog={setViewDialog} data={data} />
                    }
                </>
            }
            {addBooksInfo.data && <Toster info={addBooksInfo} dialogclose={dialogclose} />}
            {deleteBookStockInfo.data && <Toster info={deleteBookStockInfo} dialogclose={dialogclose} />}
            {updateBookInfo && <Toster info={updateBookInfo} dialogclose={dialogclose} />}
        </div>
    )
}