import React, { useState } from 'react';
import {
    makeStyles,
    TextField,
    Button,
    FormControl,
    InputLabel, Select
} from '@material-ui/core';
import { useGetAllCategoriesQuery } from '../../../services/services';
import * as yup from 'yup'
import { useFormik } from 'formik';


export default function UpdateCategories({ editData, editDialog, UpdateData }) {


    const dialogclose = () => { editDialog(false) }
    const getAllCategory = useGetAllCategoriesQuery()
    const allCategory = getAllCategory.data;
    const formik = useFormik({
        initialValues: {
            parent_category_id: editData.parent_category_id,
            category_name: editData.category_name,
            order_rank: editData.order_rank,
            category_code: editData.category_code
        },
        validationSchema: yup.object({
            category_name: yup.string().required("Required !"),
            order_rank: yup.string().required("Required !"),
            category_code: yup.string().required("Required !"),
        }),
        onSubmit: (values) => {
            UpdateData(values, editData._id)
        }
    })

    return (
        <>
            <form id="addfacultyform" onSubmit={formik.handleSubmit}>
                <FormControl style={{ margin: 0 }} fullWidth={true} variant="outlined" >
                    <InputLabel htmlFor="outlined-Institue-Name-native-simple">parent category</InputLabel>
                    <Select native
                        name="parent_category_id"
                        size='small'
                        autoFocus={true}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.parent_category_id}
                        label="parent category">
                        <option aria-label="None" value={editData.parent_category_id} >{editData.parent_category_name}</option>
                        {allCategory && allCategory.data.map((data) => <option aria-label="None" value={data._id} >{data.category_name}</option>)}
                    </Select>
                </FormControl>

                <br /><br />

                <TextField
                    name="category_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.category_name}
                    fullWidth={true}
                    label="category name "
                    variant="outlined"
                    size="small" />
                {formik.touched.category_name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.category_name}</span>}

                <br /><br />


                <TextField
                    name="order_rank"
                    onChange={formik.handleChange}
                    InputProps={{ inputProps: { min: 0 } }}
                    onBlur={formik.handleBlur}
                    value={formik.values.order_rank}
                    fullWidth={true}
                    type="number"
                    min="0"
                    label="Order Rank "
                    variant="outlined"
                    size="small" />
                {formik.touched.order_rank && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.order_rank}</span>}

                <br /><br />

                <TextField
                    name="category_code"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.category_code}
                    fullWidth={true}
                    label="Category Code "
                    variant="outlined"
                    size="small" />
                {formik.touched.category_code && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.category_code}</span>}

                <br /><br />

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => dialogclose(false)}
                >
                    Close</Button>
                <Button
                    type="submit"
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    color="primary">
                    Save
                </Button>
            </form>
        </>
    )
}
