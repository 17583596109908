import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const batchApi = createApi({
    tagTypes: ["batch"],
    reducerPath: "batchApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/offline-batch/`,
    }),
    endpoints: (builder) => ({
        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET "batch" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        GetAllBatch: builder.query({
            providesTags: ["batch"],
            query: () => {
                return {
                    url: "get-all-offline-batches", 
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                };
            },
        }),

        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ADD "batch" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        AddBatch: builder.mutation({
            invalidatesTags: ["batch"],
            query: (body) => {
                return {
                    url: "add-offline-batches", 
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,
                };
            },
        }),


        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< UPDATE "batch" DATA SECTION >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        UpdateBatch: builder.mutation({
            invalidatesTags: ["batch"],
            query: ({ body, id }) => {
                return {
                    url: id, 
                    headers: {
                        "x-access-token": token,
                    },
                    method: "PUT",
                    body,
                };
            },
        }),


        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< DELETE DATA IN "batch" SECTION >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        DeleteBatch: builder.mutation({
            invalidatesTags: ["batch"],
            query: (id) => {
                return {
                    url: id, 
                    headers: {
                        "x-access-token": token,
                    },
                    method: "DELETE",
                };
            },
        }),


    }),
});

export const {
    useGetAllBatchQuery ,
    useAddBatchMutation , 
    useUpdateBatchMutation , 
    useDeleteBatchMutation ,
} = batchApi;


