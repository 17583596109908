import React, { useState, useEffect } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import ViewBlog from "./ViewBlog";
import Toster from "../Toster";
import AddBlog from "./AddBlog";
import Loader from "../Loader";
import AddDataHeader from "../AddDataHeader";
import {
  useAddBlogMutation,
  useGetAllBlogMutation,
  useDeletBlogMutation,
  useUpdateBlogMutation,
} from "../../../../services/blogServices";
import SkeletonListing from "../SkeletonListing";
import DataNotFound from "../DataNotFound";
import { Avatar } from "@material-ui/core";
import AuthWrapper from "../../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../../utils/getFilterFunction";

export default function Blog() {
  const columns = [
    {
      id: "SNo",
      label: "Sr. No.",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "title",
      label: "Title",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "focusKeywords",
      label: "Focus Keywords",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "slugs",
      label: "Slugs",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "tags",
      label: "Tags",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "image_alt_text",
      label: "Image Alt Text",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "metaTitle",
      label: "Meta Title",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "Action",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: ["CMS_BLOG_VIEW", "CMS_BLOG_EDIT", "CMS_BLOG_DELETE"],
    },
  ];

  function createData(
    SNo,
    title,
    focusKeywords,
    slugs,
    tags,
    metaTitle,
    Action
  ) {
    return {
      SNo,
      title,
      focusKeywords,
      slugs,
      tags,
      metaTitle,
      Action,
    };
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: ["name", "title"],
    page: page + 1,
  };

  let rows = [];

  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [AddDialog, setDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [getAllData, getAllDataInfo] = useGetAllBlogMutation();
  const [deleteData, deleteDataInfo] = useDeletBlogMutation();
  const [addData, addDataInfo] = useAddBlogMutation();
  const [updateBlog, updateDataInfo] = useUpdateBlogMutation();
  const AllData = getAllDataInfo.data;
  const totalItem = AllData ? AllData.totalItem : 0;
  const [render, setRender] = useState(false);

  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page]);

  useEffect(() => {
    getid && deleteData(getid) && setGetid("");
  }, [getid]);

  const AddData = (data) => {
    addData(data);
  };
  const UpdateData = (data, id) => {
    updateBlog({ body: data, id: id });
  };

  let totalData = "ok";
  if (AllData) {
    totalData = AllData.totalItem;
    rows = AllData.data.map((data, index) =>
      createData(
        index + 1,
        data?.image ? (
          <div>
            <div className="d-flex justify-content-center">
              <Avatar src={data?.image} />
            </div>
            <span className="text-info font-weight-bold">{data?.title}</span>
            <br />
          </div>
        ) : null,
        data?.focusKeywords
          ? data?.focusKeywords.map((ele, index) => {
              return index + 1 == data.focusKeywords.length ? ele : ele + ", ";
            })
          : null,
        data.slugs,
        data?.tags
          ? data?.tags.map((ele, index) => {
              return index + 1 == data.tags.length ? ele : ele + ", ";
            })
          : null,
        data.metaTitle,
        <div className="d-flex justify-content-center">
          <AuthWrapper type="ACTION" name="CMS_BLOG_VIEW">
            <EditDelete
              setViewDialog={setViewDialog}
              type="view"
              setData={setData}
              setGetid={setGetid}
              data={data}
            />
          </AuthWrapper>
          <AuthWrapper type="ACTION" name="CMS_BLOG_EDIT">
            <EditDelete
              setEditDialog={setEditDialog}
              type="edit"
              setData={setData}
              setGetid={setGetid}
              data={data}
            />
          </AuthWrapper>
          <AuthWrapper type="ACTION" name="CMS_BLOG_DELETE">
            <EditDelete type="delete" setGetid={setGetid} id={data._id} />
          </AuthWrapper>
        </div>
      )
    );
  }
  const dialogclose = () => {
    setEditDialog(false);
    setViewDialog(false);
    setDialog(false);
    setRender(!render);
  };
  return (
    <div>
      {!viewDialog && (
        <AddDataHeader
          addFormName={"Add Blog"}
          setPage={setPage}
          page={page}
          excelBtn={false}
          totalItem={totalItem}
          rowsPerPage={rowsPerPage}
          isSearch={
            !AuthWrapper({
              type: "ACTION",
              name: "CMS_BLOG_LIST",
              inBoolean: true,
            })
          }
          isPagination={
            !AuthWrapper({
              type: "ACTION",
              name: "CMS_BLOG_LIST",
              inBoolean: true,
            })
          }
          addBtn={
            !AuthWrapper({
              type: "ACTION",
              name: "CMS_BLOG_ADD",
              inBoolean: true,
            })
          }
          setRowsPerPage={setRowsPerPage}
          setAddDialog={setDialog}
          searchBarApi={getAllData}
          paginationData={paginationData}
          fullsize={false}
          isButton={true}
          Adddialog={AddDialog}
          form={<AddBlog AddData={AddData} setDialog={setDialog} />}
        />
      )}
      <AuthWrapper type="ACTION" name="CMS_BLOG_LIST">
        {deleteDataInfo.isLoading ||
        addDataInfo.isLoading ||
        updateDataInfo.isLoading ? (
          <Loader />
        ) : (
          <>
            {!viewDialog ? (
              rows?.length ? (
                <CreateTableAddButton
                  editDialog={editDialog}
                  rows={rows}
                  fullsize={true}
                  editform={
                    <AddBlog
                      UpdateData={UpdateData}
                      editData={data}
                      editDialog={setEditDialog}
                      formType="edit"
                    />
                  }
                  columns={getFilterdColumns(columns)}
                  editFormName={"Edit Blog"}
                />
              ) : totalData == 0 ? (
                <DataNotFound />
              ) : (
                <SkeletonListing />
              )
            ) : (
              <ViewBlog viewDialog={setViewDialog} data={data} />
            )}
          </>
        )}{" "}
      </AuthWrapper>
      {deleteDataInfo.data && (
        <Toster info={deleteDataInfo} dialogclose={dialogclose} />
      )}
      {addDataInfo.data && (
        <Toster info={addDataInfo} dialogclose={dialogclose} />
      )}
      {updateDataInfo && (
        <Toster info={updateDataInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
