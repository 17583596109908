import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../CreateTabPanel";
import { userAccess } from "../../../../helpers/userAccess";
import CashBackRequests from "./CashBackRequests";
import RefundRequests from "./RefundRequests";
import GSTInvoiceRequests from "./GSTInvoiceRequests";
import CancelOrderRequests from "./CancelOrderRequests";
import { useLocation } from "react-router-dom";
import AuthWrapper from "../../../../utils/AuthWrapper";

function TabPanels(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={10} style={{ padding: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanels.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const tabsNames = [
  {
    tabName: "Cashback Request",
    tabId: "ORDER_REQUEST_CASHBACK_REQUEST",
  },
  {
    tabName: "Cancel Request",
    tabId: "ORDER_REQUEST_CANCEL_REQUEST",
  },
  {
    tabName: "Refund Request",
    tabId: "ORDER_REQUEST_REFUND_REQUEST",
  },
  {
    tabName: "GST Invoice Request",
    tabId: "ORDER_REQUEST_GST_INVOICE_REQUEST",
  },
];

export default function StudentRequests() {
  let { search } = useLocation();

  const query = new URLSearchParams(search);
  let refund = query.get("refund");
  // let cancel=query.get('cancel');

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  // const [value, setValue] = React.useState(refund == "true" ? 1 : 0);

  let tabsToShow = tabsNames?.filter((e, i) => {
    return AuthWrapper({ type: "TAB", name: e?.tabId, inBoolean: true });
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getTabPanel = (ele) => {
    let moduleToShow;
    switch (ele) {
      case "Cashback Request":
        moduleToShow = <CashBackRequests />;
        break;
      case "Cancel Request":
        moduleToShow = <CancelOrderRequests />;
        break;
      case "Refund Request":
        moduleToShow = <RefundRequests />;
        break;
      case "GST Invoice Request":
        moduleToShow = <GSTInvoiceRequests />;
        break;
      default:
        break;
    }
    return moduleToShow;
  };

  return (
    <div className={classes?.root}>
      <AppBar position="static">
        <Tabs
          variant="scrollable"
          // value={value}
          // onChange={handleChange}
          // aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
          style={{ maxWidth: "85vw" }}
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          {tabsToShow?.map((ele, ind) => {
            return (
              <Tab
                style={{ textTransform: "capitalize" }}
                label={ele?.tabName}
                {...a11yProps(ind)}
              />
            );
          })}
        </Tabs>
      </AppBar>

      {tabsToShow.map((ele, ind) => {
        return (
          <TabPanel value={value} index={ind}>
            {getTabPanel(ele?.tabName)}
          </TabPanel>
        );
      })}
    </div>
  );
}
