import React,{useState} from 'react'
import {
  Dialog, DialogActions, Button, DialogContent, DialogTitle, DialogContentText, TextField, MenuItem,
  FormControl, RadioGroup, InputLabel, Select, FormLabel, Grid,FormControlLabel,Radio
} from '@material-ui/core';

import EditPrePayment from '../../EditPrePayment';
function EditStatus({ setStatusDialog, statusDialog, data, setOrderStatus,setPrepaymentData, setCount, count , setPrePaymentSelected }) {
  const [status, setStatus] = useState({ status: "" });
  const [prePayment, setPrePaymentRemark] = useState(false);
  const [prePaymentDialog, setPrePayment] = useState(false);
  const [prePaymentChecked, setPrePaymentChecked] = useState(false);
  let nextStatus = []

  const setNextStatus=()=>{
    switch(data.current_status){
      case "ORDER_ADDED":
        nextStatus = ["ORDER_RECIEVED","ORDER_PENDING"]
      break;
      case "ORDER_PENDING":
        nextStatus = ["AWAITING"]
      break;
      case "ORDER_RECIEVED":
        nextStatus = ["DISPATCHED"]
      break;
      case "AWAITING":
        nextStatus = ["DISPATCHED"]
      break;
      case "DISPATCHED":
        nextStatus = ["COMPLETED"]
      break;
      case "REFUND_PENDING":
        nextStatus = ["REFUND_COMPLETED"]
      break;
      case "CANCEL_REFUND_PENDING":
        nextStatus = ["CANCEL_REFUND_PENDING_COMPLETED"]
      break;
      case "COMPLETED":
        nextStatus = []
      break;

    }
  }

  setNextStatus()

  const handleClose = () => {
    setStatusDialog(false);
    setPrePaymentRemark(false)
    setPrePaymentChecked(false)
  };
  
  const handleOpen = () => {
    setCount(count + 1)
    setOrderStatus({ sld_order_id: data.sld_order_id, order_id: data._id, status: status.status , order_type:"NORMAL"})
    setStatusDialog(false);
    setPrePaymentRemark(false)
    setPrePaymentSelected(prePaymentChecked)
    //setPrePaymentChecked(false)
  }
  return (
    <div>
      <div>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
        <Dialog open={statusDialog} 
        //onClose={handleOpen}
        fullWidth={true}
        >
          <DialogTitle>Status</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Change Current status of Order
            </DialogContentText>
            
            <Grid item xs={12} className='mt-4'>
              <FormControl variant="outlined" fullWidth={true}  >
                <InputLabel id="demo-simple-select-helper-label">status</InputLabel>

                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  onChange={(e) => {
                    setStatus({ status: e.target.value })
                  }}
                  label="Status"
                >
                  {nextStatus.map((ele)=>{
                    return <MenuItem value={ele} onClick={() => {
                              setPrePaymentRemark(ele == "DISPATCHED" ? true:false)
                            }}>{ele}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            {prePayment &&
             <Grid item xs={12} className='mt-4'>
              <FormControl ClassName="mt-3">
                <FormLabel id="demo-radio-buttons-group-label">Payment</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                >
                  <FormControlLabel checked={prePaymentChecked} control={<Radio />} onClick={()=>{setPrePayment(!prePaymentChecked ? true :false)
                                                                                                setPrePaymentChecked(!prePaymentChecked)}} 
                  label="PrePayment" />
                </RadioGroup>
              </FormControl>
              </Grid>

            }
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleClose}>Cancel</Button>
            <Button variant="contained" color="primary" onClick={handleOpen}>save</Button>
          </DialogActions>
        </Dialog>


        {prePaymentDialog &&
              <EditPrePayment 
              data={data} 
              setPrePayment={setPrePayment} 
              prePaymentDialog={prePaymentDialog}  
              setPrePaymentChecked={setPrePaymentChecked}
              setPrepaymentData={setPrepaymentData}
              />
        }
      </div>
    </div>
  )
}

export default EditStatus
