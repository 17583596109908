import React, { useState, useEffect } from "react";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import * as yup from 'yup'
import { useFormik } from 'formik';
import {
  Grid,
  Button,
  Card,
  Box,
  CardContent,
  Dialog, Zoom, DialogTitle, DialogContent, TextField
} from "@material-ui/core";
import {
  useUpdateFacultyMutation,
  useGetSingleFacultyMutation
} from '../../../../services/services'
import DataNotFound from "../DataNotFound";


export default function BankDetails({ viewDialog, data, setFacultyData }) {

  const [updateData, updateDataInfo] = useUpdateFacultyMutation();
  const [singleFacultyData, singleFacultyDataInfo] = useGetSingleFacultyMutation();
    
  const [updateDialog, setUpdateDialog] = useState(false);

  useEffect(() => {
    singleFacultyData(data._id)
  }, [updateDataInfo])

  const dialogclose = () => {
    setUpdateDialog(false);
  };

  return (
    <>
      <Card sx={{ display: "flex" }} className="m-3" >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>

            <Button
              onClick={() => viewDialog(false)}
              className="text-light bg-danger float-right btn btn-sm btn-danger"
            >
              Close
            </Button>
          </CardContent>

          {singleFacultyDataInfo.isSuccess == true && singleFacultyDataInfo.data.data.length ?
            singleFacultyDataInfo.isLoading == false ?
              <div>
                {setFacultyData(singleFacultyDataInfo.data.data[0])}
                <Button
                  onClick={() => {
                    setUpdateDialog(true);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Update Bank Details
                </Button>

                <Card className="m-3 pb-3">
                  <Grid
                    container
                    spacing={1}
                    direction="column"
                    alignItems="left"
                    justifyContent="left"
                  >
                    <Grid item xs={12}>
                      {
                        singleFacultyDataInfo.data.data[0].bank_details != undefined ?
                          <CardContent className="bg-light p-3 border-primary infoCards">

                            <Grid container spacing={6}>
                              <Grid className="text-muted text-left" item md={6}>
                                <h5>Account Number</h5>

                              </Grid>
                              <Grid className="text-muted text-left" item md={6}>
                                <h5>{singleFacultyDataInfo.data.data[0].bank_details.bank_ac_number}</h5>

                              </Grid>

                            </Grid>
                            <hr />

                            <Grid container spacing={6}>
                              <Grid className="text-muted text-left" item md={6}>
                                <h5>Bank Name</h5>

                              </Grid>
                              <Grid className="text-muted text-left" item md={6}>
                                <h5>{singleFacultyDataInfo.data.data[0].bank_details.bank_name}</h5>

                              </Grid>

                            </Grid>
                            <hr />

                            <Grid container spacing={6}>
                              <Grid className="text-muted text-left" item md={6}>
                                <h5>Bank Remark</h5>

                              </Grid>
                              <Grid className="text-muted text-left" item md={6}>
                                <h5>{singleFacultyDataInfo.data.data[0].bank_details.bank_remark}</h5>

                              </Grid>

                            </Grid>

                            {/* <Grid container spacing={6}>
                              <Grid className="text-muted text-left" item md={6}>
                                <h5>Branch Name</h5>

                              </Grid>
                              <Grid className="text-muted text-left" item md={6}>
                                <h5>{singleFacultyDataInfo.data.data[0].bank_details.branch_name}</h5>

                              </Grid>

                            </Grid> */}

                            <Grid container spacing={6}>
                              <Grid className="text-muted text-left" item md={6}>
                                <h5>IFSC Code</h5>

                              </Grid>
                              <Grid className="text-muted text-left" item md={6}>
                                <h5>{singleFacultyDataInfo.data.data[0].bank_details.ifsc_code}</h5>

                              </Grid>

                            </Grid>


                          </CardContent> :
                          <CardContent className="bg-light p-3 border-top border-bottom border-primary infoCards">
                            NO DATA FOUND
                          </CardContent>
                      }

                    </Grid>
                  </Grid>
                </Card>


                {updateDialog ? <UpdateBankDetails
                  updateDialog={updateDialog}
                  setUpdateDialog={setUpdateDialog}
                  updateData={updateData}
                  facultyId={data._id}
                  currentData={{ ...singleFacultyDataInfo.data.data[0].bank_details }} /> : null}
                {updateDataInfo.data && (
                  <Toster info={updateDataInfo} dialogclose={dialogclose} />
                )}
              </div>
              :
              <SkeletonListing />

            :
            <Grid container justifyContent="center">
              <DataNotFound />
            </Grid>

          }

        </Box>
      </Card>
    </>

  );
}

function UpdateBankDetails({ updateDialog, setUpdateDialog, updateData, facultyId, currentData }) {

  const formik = useFormik({
    initialValues: {
      ...currentData
    },
    validationSchema: yup.object({
      bank_ac_number: yup.string().required("Required!"),
      bank_name: yup.string().required("Required!"),
      remark: yup.string().required("Required!"),
      // branch_name: yup.string().required("Required!"),
      ifsc_code: yup.string().required("Required!")
    }),
    onSubmit: (values, e) => {
      var form_data = new FormData()
      form_data.append('key', 'faculty_bank_details')
      form_data.append('update_data', JSON.stringify(values))
      updateData({ data: form_data, id: facultyId })
    }
  })

  return (
    <Dialog
      fullScreen={false}
      open={updateDialog}
      //scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
      TransitionComponent={Zoom}
    >
      <DialogTitle id="scroll-dialog-title">Update Bank Details</DialogTitle>
      <DialogContent
      //dividers={scroll === "paper"}
      >
        <form id="updateForm" onSubmit={formik.handleSubmit}>
          <Grid style={{ paddingLeft: 10, paddingRight: 10, marginTop: 20, marginLeft: 10 }} >
            <Grid container spacing={1} >
              <Grid item xs={6}>
                <TextField
                  name="bank_ac_number"
                  label="Account Number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bank_ac_number}
                  fullWidth={true}
                  variant="outlined"
                  size="medium" />
                {formik.touched.bank_ac_number && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.bank_ac_number}</span>}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="bank_name"
                  label="Bank Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bank_name}
                  fullWidth={true}
                  variant="outlined"
                  size="medium" />
                {formik.touched.bank_name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.bank_name}</span>}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="ifsc_code"
                  label="ifsc Code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ifsc_code}
                  fullWidth={true}
                  variant="outlined"
                  size="medium" />
                {formik.touched.ifsc_code && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.ifsc_code}</span>}
              </Grid>

              {/* <Grid item xs={6}>
                <TextField
                  name="branch_name"
                  label="Branch Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.branch_name}
                  fullWidth={true}
                  variant="outlined"
                  size="medium" />
                {formik.touched.branch_name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.branch_name}</span>}
              </Grid> */}

              <Grid item xs={6}>
                <TextField
                  name="remark"
                  label="Remark"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.remark}
                  fullWidth={true}
                  variant="outlined"
                  size="medium" />
                {formik.touched.remark && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.remark}</span>}
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid container spacing={3} >
              <Grid item xs={12}>
                <div className="text-right">
                  <Button
                    onClick={() => setUpdateDialog(false)}
                    variant="contained"
                    color="secondary">
                    Close</Button>
                  <Button
                    type="submit"
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    color="primary">
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid >
        </form >
      </DialogContent>
    </Dialog>
  )

}
