import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import axios from "../../config/Axios";
let _this;

const useStyles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {

        color: theme.palette.text.secondary,
    },
    grid: {
        padding: theme.spacing(4),
    },
    authBgGrid: {
        padding: theme.spacing(4),
        background: "#5664D291",
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    inputRoot: {
        fontSize: 16
    },
    labelRoot: {
        fontSize: 14,
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class SetNewPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            notifyMsg: "",
            isLoading: false
        }

        _this = this
    }


    handleInput = (value, name) => {
        this.setState({
            [name]: value
        })
    }

    signup = (e) => {
        e.preventDefault();
        let { mobile, password } = this.state

        if (mobile === "") {
            this.setState({ notifyMsg: "Mobile Number is required" })
            return;
        }

        if (password === "") {
            this.setState({ notifyMsg: "Password is required" })
            return;
        }

        let data = {
            mobile: mobile,
            password: password
        };

        this.setState({ isLoading: true })

        axios.post('/user/login', data)
            .then(function (response) {
                let { data } = response
                if (data.status) {
                    // localStorage.setItem('__authToken__', data.token)
                    // localStorage.setItem('__authUserData__', JSON.stringify(data.data))
                    // _this.setState({ isLoading: false })
                    // window.location.replace('/dashboard')
                } else {

                    _this.setState({ notifyMsg: "opps! something went wrong", isLoading: false })
                }
            })
            .catch(function (error) {
                  
                _this.setState({ notifyMsg: "opps! something went wrong", isLoading: false })
            });
    }


    render() {
        const { classes } = this.props;
        const { theme } = this.props;
        return (
            <ThemeProvider theme={theme}>
                {this.state.notifyMsg !== "" ? <Snackbar open={this.state.notifyMsg !== "" ? true : false} autoHideDuration={6000} onClose={() => this.setState({ notifyMsg: "" })}>
                    <Alert onClose={() => this.setState({ notifyMsg: "" })} severity="error">
                        {this.state.notifyMsg}
                    </Alert>
                </Snackbar> : null}
                <div className={classes.root}>
                    <Grid container justify="center"
                        alignItems="center" style={{ height: "100vh" }}>
                        <Grid item xs={6} md={6} lg={6} xl={6}>
                            <Paper className={classes.paper}>
                                <Grid container justify="center" alignItems="center" direction="row">
                                    <Grid item xs={6} md={6} lg={6} xl={6} className={classes.grid}>
                                        <CssBaseline />
                                        <div className={classes.paper}>
                                            <div className="text-center mb-4">
                                                <Typography component="h2" className="mb-3" variant="h5" style={{ fontWeight: 500, color: "#424040" }}>
                                                    Welcome To <Link to="/login" style={{ textDecoration: "none" }}>LikhaPadi</Link></Typography>
                                            </div>
                                            <Typography component="h1" className="mb-3" variant="h5" style={{ fontWeight: 500, color: "#424040" }}>
                                                Set New Password</Typography>
                                            <form className={classes.form} noValidate>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    name="password"
                                                    label="Password"
                                                    type="password"
                                                    id="password"
                                                    autoComplete="current-password"
                                                    InputProps={{ classes: { root: classes.inputRoot } }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    name="confirm-password"
                                                    label="Confirm Password"
                                                    type="confirm-password"
                                                    id="confirm-password"
                                                    autoComplete="current-password"
                                                    InputProps={{ classes: { root: classes.inputRoot } }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                        }
                                                    }}
                                                />
                                                <Grid container alignItems="center" className="mt-3">
                                                    <Grid item xs>
                                                        <div className="d-flex align-items-center">
                                                            <div className='position-relative'>
                                                                <Button
                                                                    type="submit"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className={classes.submit}
                                                                >Submit</Button>
                                                                {this.state.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>



                                            </form>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6} xl={6} className={classes.authBgGrid}>
                                        <img src="assets/images/login-img.png" alt="" className="img-fluid" />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                    </Grid>
                </div>
            </ThemeProvider>
        )
    }
}


export default withStyles(useStyles, { withTheme: true })(SetNewPassword);