import React, { useState } from 'react';
import {
    TextField, Typography, Button, makeStyles,
} from '@material-ui/core'
import BackupIcon from '@material-ui/icons/Backup';
import { useFormik } from 'formik';
import * as yup from 'yup'


const useStyles = makeStyles((theme) => ({
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        minWidth: 100,
    },
    tab: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

}));

export default function AddProcessedBY({AddData,setDialog,id}) {
    const classes = useStyles();
    const [imageval, setImageVal] = useState("")
    const formik = useFormik({
        initialValues: {
           
           
            processed_discription: "",
           
        },
        onSubmit: (values) => {
           
            values.id = id
            AddData(values)
        }
    })
    const dialogclose =()=>{
        setDialog(false)  
    }
    return (
        <>
                <form onSubmit={formik.handleSubmit}>
                    <div style ={{display:"flex"}} sx={{ '& button': { m: 1 } }}>

                    <TextField
                        name="processed_discription"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.processed_discription}
                        fullWidth={true}
                        label=""
                        variant="outlined"
                        size="small"
                    />
                    {formik.touched.processed_discription && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.processed_discription}</span>}
                   
                    <Button
                        size="small"
                        type="submit"
                        style={{ marginLeft:0,fontSize: "10px" ,padding:"0"}}
                        variant="contained"
                        color="primary"
                    > by</Button>
                    </div>
                </form>
        </>
    )
}
