import { Avatar, Button, Dialog, DialogContent, Divider, Grid, Typography } from '@material-ui/core'
import LanguageIcon from '@material-ui/icons/Language';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import React from 'react'
import { borderRight } from '@mui/system';
import { useGetConfigQuery } from '../../../../../services/offlineModuleServices/configServices';
import SkeletonListing from '../../SkeletonListing';
import moment from 'moment';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";

// **** Import Logo
import sldLogo from '../../sldlogo.png'

// **** Use Styles
const useStyles = makeStyles({
    table: {
        minWidth: 650,
        "& .MuiTableCell-root": {
            borderLeft: "1px solid black",
            border: "1px solid black"

        },
        "& .MuiTable-root": {
            border: "1px solid black"

        }
    }
});

function PendriveOrderInvoice(props) {

    const classes = useStyles();

    // **** Destructuring Props
    const { data, receiptFor , onClose } = props
    const { data: displayData } = data

    const { data: AllTermsAndConditions, isFetching: isAllTermsAndConditionsFetching, isLoading: isAllTermsAndConditionsLoading } = useGetConfigQuery({
        key: "instructions_to_follow",
        limit: "",
        page: "",
    })

    return (
        <div>
            {isAllTermsAndConditionsFetching || isAllTermsAndConditionsLoading ?
                <SkeletonListing />
                :
                <Dialog open={true} fullScreen >
                    <DialogContent>
                        <Grid container className='px-3' justifyContent='center' style={{ minHeight: "100vh" }} >
                            <Grid item xs={12}>
                                <Grid container className='mb-5' justifyContent='flex-end' >
                                    <Button variant='contained' color='secondary' onClick={onClose}  > Back </Button>
                                </Grid>

                                {/* Header */}
                                <Grid item xs={12} className="px-4"  >

                                    <Grid container spacing={8}  >
                                        <Grid item xs={4}>
                                            <img src={sldLogo} alt="Logo" height="80%" width="100%" />
                                        </Grid>

                                        <Grid item xs={8}   >
                                            <Typography variant='h5' > SMART LEARNING DESTINATION </Typography>
                                            <Grid container  >
                                                <Grid item xs={12}>
                                                    <Typography variant='caption' component="div" > <LanguageIcon fontSize='small' /> https://www.smartlearningdestination.com/ </Typography>
                                                    <Typography variant='caption' component="div"> <LocationOnIcon fontSize='small' /> 114 (C) 1st Floor Shreevardhan Complex, Near Lemon Tree Hotel </Typography>
                                                    <Typography className='text-wrap' variant='caption' component="div" > <CallIcon fontSize='small' /> <span  > FOR PURCHASE INQUIRY- 7697044955 / 7277278811, FOR SUPPORT- 8602133776, FOR COMPLAINT- 8871374677 ( Drop Only Wapp ) </span> </Typography>
                                                    <Typography variant='caption' component="div" > <EmailIcon fontSize='small' /> OFFLINESLD@GMAIL.COM </Typography>
                                                    <Typography variant='caption' component="div" > <AccessTimeIcon fontSize='small' /> Office Timing- 10:00 AM - 08:00 PM </Typography>
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </Grid>

                                {/* Divider */}
                                <Grid className='mt-3'>
                                    <Divider className='bg-dark' />
                                </Grid>

                                <Grid container className='mt-5' spacing={4}  >

                                    {/* Invoice Details */}
                                    <Grid item xs={6} className="" >
                                        <Table size="small" sx={{ border: "1px solid black" }} >
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: "1px solid black", bgcolor: "#d7d7d7" }} align='center'> <Typography> Invoice Details </Typography> </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <Grid container  >
                                                        <Grid item xs={12}>
                                                            <Table size="small"  >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: { lg: "22.5%", xs: "45%" }, borderBottom: "1px solid black" }} >  Invoice No. : </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left"  > {displayData.invoice_no} </TableCell>
                                                                    </TableRow>


                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Table size="small"  >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: { lg: "22.5%", xs: "45%" }, borderBottom: "1px solid black" }} > Order Date :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" >  {moment(displayData.order_date).format("DD MMM yyyy")} at {moment(displayData.order_date).format("hh:mm A")} </TableCell>
                                                                    </TableRow>

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Table size="small"  >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: { lg: "22.5%", xs: "45%" }, borderBottom: "1px solid black" }}  > Order Type :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > Pendrive Class </TableCell>
                                                                    </TableRow>

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Table size="small"  >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: { lg: "22.5%", xs: "45%" }, borderBottom: { lg: "0px", xs: "1px solid black" } }} > SLD Order Id :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: { lg: "0px", xs: "1px solid black" } }} align="left" >  {displayData.sld_order_id} </TableCell>
                                                                    </TableRow>

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>





                                                    </Grid>


                                                </TableRow>
                                            </TableBody>
                                        </Table>

                                    </Grid>

                                    {/* Student Information */}
                                    <Grid item xs={6}  >
                                        <Table size="small" sx={{ border: "1px solid black" }} >
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: "1px solid black", bgcolor: "#d7d7d7" }} align='center'   > <Typography> Student Information </Typography> </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <Grid container>

                                                        {/* Name */}
                                                        <Grid item xs={12}>
                                                            <Table size="small"  >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: { lg: "22.5%", xs: "45%" }, borderBottom: "1px solid black" }} >  Name : </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left"  > {displayData.student_name} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        {/* Email */}
                                                        <Grid item xs={12}>
                                                            <Table size="small"  >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: { lg: "22.5%", xs: "45%" }, borderBottom: "1px solid black" }}  > Email :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.email} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        {/* Mobile No. */}
                                                        <Grid item xs={12}>
                                                            <Table size="small"  >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: { lg: "22.5%", xs: "45%" }, borderBottom: "1px solid black" }} > Mob No. :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.mobile_number} </TableCell>
                                                                    </TableRow>

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        {/* CRO No. */}
                                                        <Grid item xs={12}>
                                                            <Table size="small"  >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: { lg: "22.5%", xs: "45%" }, borderBottom: { lg: "0px", xs: "1px solid black" } }} > CRO No. :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: { lg: "0px", xs: "1px solid black" } }} align="left" > {displayData.cro_no || "N/A"} </TableCell>
                                                                    </TableRow>

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>
                                                    </Grid>

                                                </TableRow>
                                            </TableBody>
                                        </Table>

                                    </Grid>

                                    {/* Order Details */}
                                    <Grid item xs={12} className="" >
                                        <Table size="small" sx={{ border: "1px solid black" }} >
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: "1px solid black", bgcolor: "#d7d7d7" }} align='center'   > <Typography> Order Details </Typography> </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <Grid container>

                                                        {/* Course */}
                                                        <Grid item lg={3} xs={6} >
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "40%", borderBottom: "1px solid black" }} >  Course : </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left"  > {displayData.course.course_name} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        {/* Level */}
                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: { lg: "1px solid black", xs: "0px" } }} >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "40%", borderBottom: "1px solid black" }}  > Level :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.level.level_name} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        {/* Due Attempt */}
                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "40%", borderBottom: "1px solid black" }} > Due Attempt :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.attempt_due} </TableCell>
                                                                    </TableRow>

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        {/* Batch Type */}
                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small"  >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "40%", borderBottom: "1px solid black" }} > Batch Type :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.category[0].category_name} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        {/* Mode Name */}
                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "40%", borderBottom: "1px solid black" }} > Mode Name :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.mode_name || "N/A"} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        {/* Book Issued */}
                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: { lg: "1px solid black", xs: "0px" } }} >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "40%", borderBottom: "1px solid black" }} > Book Issued :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.book_issued || "N/A"} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        {/* Delivery Type */}
                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "40%", borderBottom: "1px solid black" }} > Delivery Type :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.delivery_option} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        {/* Blank space */}
                                                        <Grid item lg={3} xs={6} style={{ borderBottom: "1px solid black" }} >
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >
                                                                <TableBody>

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        {/* Address */}
                                                        <Grid item lg={6} xs={12}>
                                                            <Table size="small" sx={{ borderRight: { lg: "1px solid black", xs: "0px" } }} >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "17.5%", borderBottom: "1px solid black" }} > Address :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.address} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        {/* City */}
                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "40%", borderBottom: "1px solid black" }} > City :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.city} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        {/* Pin Code */}
                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small"  >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "40%", borderBottom: "1px solid black" }} > Pin Code :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.pin_code} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        {/* Subjects */}
                                                        <Grid item lg={6} xs={displayData.subject.length <= 2 ? 6 : 12}>
                                                            <Table size="small" sx={{ borderRight: displayData.subject.length <= 2 ? "1px solid black" : "0px" }} >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: { lg: "17.5%", xs: displayData.subject.length <= 2 ? "30%" : "15%" }, borderBottom: displayData.subject.length <= 2 ? "0px" : "1px solid black" }} > Subjects :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: displayData.subject.length <= 2 ? "0px" : "1px solid black" }} align="left" > {displayData.subject.map(ele => ele.subject_name).join(", ")} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        {/* Faculties */}
                                                        <Grid item lg={6} xs={displayData.faculty.length <= 2 ? 6 : 12} >
                                                            <Table size="small"  >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: { lg: "17.5%", xs: displayData.faculty.length <= 2 ? "30%" : "15%" }, borderBottom: displayData.faculty.length <= 2 ? "0px" : "1px solid black" }} > Faculties:  </TableCell>
                                                                        <TableCell sx={{ borderBottom: displayData.faculty.length <= 2 ? "0px" : "1px solid black" }} align="left" > {displayData.faculty.map(ele => ele.faculty_name).join(", ")} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                    </Grid>

                                                </TableRow>
                                            </TableBody>
                                        </Table>

                                    </Grid>

                                    {/* Fees & Payments */}
                                    <Grid item xs={12} className="" >
                                        <Table size="small" sx={{ border: "1px solid black" }} >
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: "1px solid black", bgcolor: "#d7d7d7" }} align='center'   > <Typography> Fees & Payments </Typography> </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <Grid container>

                                                        {/* Total Fees */}
                                                        <Grid item xs={4}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: { xs: "45%" }, borderBottom: { lg: "0px", xs: "1px solid black" } }} >  Total Fees : </TableCell>
                                                                        <TableCell sx={{ borderBottom: { lg: "0px", xs: "1px solid black" } }} align="left"  > &#8377; {displayData.amount_after_discount} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        {/* Fees Paid */}
                                                        <Grid item xs={4}  >
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: { xs: "45%" }, borderBottom: { lg: "0px", xs: "1px solid black" } }}  > Fees Paid :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: { lg: "0px", xs: "1px solid black" } }} align="left" > &#8377; {displayData.fee_paid} </TableCell>
                                                                    </TableRow>

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        {/* Due Amount */}
                                                        <Grid item xs={4}>
                                                            <Table size="small" >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: { xs: "45%" }, borderBottom: { lg: "0px", xs: "1px solid black" } }} > Due Amount :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: { lg: "0px", xs: "1px solid black" } }} align="left" > &#8377; {displayData.due_amount} </TableCell>
                                                                    </TableRow>

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>



                                                    </Grid>


                                                </TableRow>
                                            </TableBody>
                                        </Table>

                                    </Grid>

                                    {/* Other Details */}
                                    <Grid item xs={12} className="" >
                                        <Table size="small" sx={{ border: "1px solid black" }} >
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: "1px solid black", bgcolor: "#d7d7d7" }} align='center'   > <Typography> Other Details </Typography> </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: { lg: "15%", xs: "30%" }, borderBottom: { lg: "0px", xs: "1px solid black" } }} >  Center : </TableCell>
                                                                        <TableCell sx={{ borderBottom: { lg: "0px", xs: "1px solid black" } }} align="left"  >  {displayData.center_name} </TableCell>
                                                                    </TableRow>

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Table size="small"  >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: { lg: "15%", xs: "30%" }, borderBottom: { lg: "0px", xs: "1px solid black" } }}  > Sold By :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: { lg: "0px", xs: "1px solid black" } }} align="left" >  {displayData.sold_by_name} </TableCell>
                                                                    </TableRow>

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                    </Grid>


                                                </TableRow>
                                            </TableBody>
                                        </Table>

                                    </Grid>

                                    {/* Terms */}

                                    <Grid item xs={12} >

                                        <Table size="small" sx={{ border: "1px solid black" }} >
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: "1px solid black", bgcolor: "#d7d7d7" }} align='center'   > <Typography> Terms And Conditions </Typography> </TableCell>
                                                </TableRow>

                                                <Grid container className='p-3' style={{ backgroundColor: "" }} spacing={1} >
                                                    {AllTermsAndConditions?.data.map((ele, index) => {

                                                        return <Grid item xs={6}>
                                                            <Typography variant='subtitle1' className='' >{index + 1}. {ele.instruction} </Typography>
                                                        </Grid>
                                                    })}

                                                </Grid>
                                            </TableBody>
                                        </Table>
                                    </Grid>


                                    {/* Also Available on SLD */}
                                    <Grid item xs={12} className="" >
                                        <Table size="small" sx={{ border: "1px solid black" }} >
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: "1px solid black", bgcolor: "#d7d7d7" }} align='center'   > <Typography> Also Available On SLD </Typography> </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>

                                        <Grid container spacing={3} className="mt-3">
                                            {
                                                [1, 2, 3, 4, 5, 6].map(ele => (
                                                    <Grid item lg={2} md={3} sm={4} xs={6}>
                                                        <CreateFacultyImage image={""} name="Himanshu" />
                                                    </Grid>

                                                ))
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent >
                </Dialog >



            }


        </div >
    )
}

export default PendriveOrderInvoice;




const CreateFacultyImage = ({ image, name }) => {
    return (
        <>
            <Grid container >
                <Grid item xs={12}>
                    <Avatar variant='rounded' src={image} style={{ width: "100%" }} />
                </Grid>
                <Grid item xs={12} >
                    <Typography className='text-center' > {name} </Typography>
                </Grid>

            </Grid>
        </>
    )
}


