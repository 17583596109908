import React, { useState } from "react"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { Dialog, DialogContent, DialogTitle, FormControl, InputLabel, TextField, Grid, Button, Typography } from "@material-ui/core"
import * as yup from "yup"
import CurrencyDetailsForm from "./CurrencyDetailsForm"
import AddCurrencyDetailsForm from "../AddCurrencyDetailsForm"
import { useUpdateOfflineOrderMutation } from "../../../../services/offlineModuleServices/offlineOrdersServices"
import Toster from "../Toster"

export default function UpdateFeesDialog({ open, onClose, onUpdate, data, updateOrder, updateOrderInfo }) {

    const [viewCurrencyDetailsForm, setViewCurrencyDetailsForm] = useState(false);
    const [paidFees, setPaidFees] = useState("");

    return (
        <div>

            <Dialog open={true} onClose={() => onClose()} maxWidth="sm" fullWidth >
                <DialogTitle style={{ backgroundColor: "#5664d2", borderBottomRightRadius: "20px" }} className="text-white mb-3 "  > Update Fees </DialogTitle>
                <DialogContent>
                    <Grid className="mb-3">
                        <Typography  > Total Remaining Amount : <span className="text-info"> &#8377; {data.due_amount} </span></Typography>
                    </Grid>
                    <Formik
                        initialValues={{ fees_paid: "" }}
                        validationSchema={yup.object({
                            fees_paid: yup.number().typeError("Amount should be a Number !").required("Amount is required")
                        })}
                        onSubmit={(values) => {
                            values.fees_paid = parseInt(values.fees_paid)
                              
                            setPaidFees(values.fees_paid)
                            setViewCurrencyDetailsForm(true)

                        }}
                    >
                        {
                            (props) => (
                                <>

                                    <Form>
                                        <Field name="fees_paid">
                                            {
                                                ({ field }) => (
                                                    <FormControl fullWidth>
                                                        {/* <InputLabel id = "fees_paid"> New Amount </InputLabel> */}
                                                        <TextField
                                                            {...field}
                                                            label="New Amount"
                                                            type="text"

                                                        />
                                                    </FormControl>
                                                )
                                            }
                                        </Field>

                                        <ErrorMessage name="fees_paid" >
                                            {errorMsg => <span className="text-danger"> {errorMsg} </span>}
                                        </ErrorMessage>
                                        {
                                            props.values.fees_paid > data.due_amount &&
                                            <span className="text-danger"> Amount should be less than or equal to Remaining Fees</span>
                                        }


                                        <Grid container justifyContent="flex-end" className="mt-5">
                                            <Grid item xs={6}>
                                                <Button fullWidth variant="contained" color="secondary" onClick={() => onClose()}> Close</Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth variant="contained" color="primary" disabled={props.values.fees_paid > data.due_amount || props.errors.fees_paid || !props.values.fees_paid} type="submit" className="ml-1"> Update Amount </Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                </>
                            )
                        }
                    </Formik>
                </DialogContent>
            </Dialog>

            {viewCurrencyDetailsForm &&
                <Dialog open={true} fullScreen >

                    <AddCurrencyDetailsForm onClose={() => setViewCurrencyDetailsForm(false)} paidFees={paidFees} id={data._id} orderFor="update_fees" closeUpdateFeesDialog={() => onClose()} updateOrder={updateOrder} updateOrderInfo={updateOrderInfo} />
                </Dialog>
            }





        </div >
    )
}