import React, { useState, useEffect } from "react";
import CreateTableAddButton from "../CreateTableAddButton";
import AddDataHeader from "../AddDataHeader";
import { useGetAllSubscriberMutation } from "../../../../services/services";
import SkeletonListing from "../SkeletonListing";
import DataNotFound from "../DataNotFound";
import moment from "moment/moment";
import AuthWrapper from "../../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../../utils/getFilterFunction";

export default function Subscriber() {
  const columns = [
    {
      id: "SNo",
      label: "Sr. No.",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "email",
      label: "Email",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "subcribed_status",
      label: "Subcribed Status",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "createdAt",
      label: "Subscribed At",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];

  function createData(SNo, email, subcribed_status, createdAt) {
    return {
      SNo,
      email,
      subcribed_status,
      createdAt,
    };
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: ["email", "subcribed_status", "createdAt", "updatedAt"],
    dateFilter: {
      startDate: "",
      endDate: "",
    },
    page: page + 1,
    filterBy: [
      {
        fieldName: "",
        value: "",
      },
    ],
  };

  let rows = [];

  const [getAllData, getAllDataInfo] = useGetAllSubscriberMutation();
  const AllData = getAllDataInfo.data;
  const totalItem = AllData ? AllData.totalItem : 0;
  const [render, setRender] = useState(false);

  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page]);

  let totalData = "ok";
  if (AllData) {
    totalData = AllData.totalItem;
    rows = AllData.data.map((data, index) =>
      createData(
        index + 1,
        data.email,
        data.subcribed_status == true ? "Yes" : "No",
        moment(data.createdAt).format("DD-MM-YYYY hh:mm:ss A")
      )
    );
  }

  return (
    <div>
      <AddDataHeader
        setPage={setPage}
        page={page}
        isSearch={
          !AuthWrapper({
            type: "ACTION",
            name: "CMS_SUBSCRIBER_LIST",
            inBoolean: true,
          })
        }
        isPagination={
          !AuthWrapper({
            type: "ACTION",
            name: "CMS_SUBSCRIBER_LIST",
            inBoolean: true,
          })
        }totalItem={totalItem}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchBarApi={getAllData}
        paginationData={paginationData}
        fullsize={false}
        addBtn={true}
      />
      <AuthWrapper type="ACTION" name="CMS_SUBSCRIBER_LIST">
        {rows.length ? (
          <CreateTableAddButton rows={rows} fullsize={true} columns={getFilterdColumns(columns)} />
        ) : totalData == 0 ? (
          <DataNotFound />
        ) : (
          <SkeletonListing />
        )}
      </AuthWrapper>
    </div>
  );
}
