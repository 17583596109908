import React, { useEffect, useState } from "react";
import { Button, TextareaAutosize, DialogContent, DialogContentText, Dialog, DialogActions, DialogTitle, makeStyles, withStyles } from '@material-ui/core';
import EditDelete from "../EditDelete";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import '../../../.././ViewBook.css'
// import DataNotFound from '../DataNotFound';
import SkeletonListing from '../SkeletonListing';
import moment from "moment";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
// import LocalLibraryTwoToneIcon from '@material-ui/icons/LocalLibraryTwoTone';
// import TitleIcon from '@material-ui/icons/Title';
// import SubjectIcon from '@material-ui/icons/Subject';
// import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
// import VpnKeyIcon from '@material-ui/icons/VpnKey';
// import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
// import DescriptionIcon from '@material-ui/icons/Description';
// import LanguageIcon from '@material-ui/icons/Language';
// import PaymentIcon from '@material-ui/icons/Payment';
// import LayersIcon from '@material-ui/icons/Layers';
// import LocalMallIcon from '@material-ui/icons/LocalMall';
// import MergeTypeIcon from '@material-ui/icons/MergeType';
// import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
// import PersonIcon from '@material-ui/icons/Person';
// import AttachmentIcon from '@material-ui/icons/Attachment';
// import CropFreeIcon from '@material-ui/icons/CropFree';

import {

    useGetTicketDetailsMutation
} from "../../../../services/ticketservices";
import EditTicket from "./EditTicket";
import FormDialog from "../reusableComponents/NewFormDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: 18,
        flexBasis: '33.33%',
        flexShrink: 0,

    },
    table: {
        padding: 10,
        margin: 5

    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

export default function ViewTicket({ data, viewDialog, UpdateData }) {
    const classes = useStyles();

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);
    const [getTicketData, getTicketDataInfo] = useGetTicketDetailsMutation();
    const [dataTicket, setData] = useState("");
    const [editDialog, setEditDialog] = useState(false);
    useEffect(() => {
        getTicketData(data._id)
    }, [data._id])
    const getAllData = getTicketDataInfo.data



    const handleClickOpen = () => {
        setEditDialog(true);
    };

    const handleClose = () => {
        setEditDialog(false);
    };

    return (
        <>

            <div className="d-flex justify-content-between m-2 p-3 border-info border-bottom">
               <div>
                   <h5 className="text-muted" > Ticket Flow </h5>
               </div>
           
               <div>
                    <EditDelete type="edit" setEditDialog={setEditDialog} setData={setData} />
                    <Dialog
                        open={editDialog}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                    >

                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                {editDialog && <EditTicket editData={getAllData}
                                    UpdateData={UpdateData}
                                    editDialog={setEditDialog} />}
                            </DialogContentText>
                        </DialogContent>

                    </Dialog>
                <Button onClick={() => {
                    viewDialog(false)
                }} style={{ }}>
                    <HighlightOffIcon color='secondary' />
                </Button>
               </div> 
            </div>


            {editDialog &&

                <FormDialog />

            }
            <div className="d-flex justify-content-center">
                            <div className="container">

                                {/* <React.Fragment> */}
                                    <TimelineItem className="bg-info pl-2" >
                                    <TimelineOppositeContent color="text.secondary" className="text-center text-light">

                                        <Typography variant="" component="span">
                                            Status
                                        </Typography>

                                    </TimelineOppositeContent>
                                    <TimelineOppositeContent color="text.secondary" className="text-center text-light">

                                        <Typography variant="" component="span">
                                            Description
                                        </Typography>

                                    </TimelineOppositeContent>
                                    <TimelineOppositeContent color="text.secondary" className="text-center text-light">

                                        <Typography variant="" component="span">
                                            Date
                                        </Typography>

                                    </TimelineOppositeContent>
                                    <TimelineOppositeContent color="text.secondary" className="text-center text-light">

                                        <Typography variant="" component="span">
                                        Website User<br/>Note
                                        </Typography>

                                    </TimelineOppositeContent>
                                    <TimelineOppositeContent color="text.secondary" className="text-center text-light">

                                        <Typography variant="" component="span">
                                        SLD User<br/>Type
                                        </Typography>

                                    </TimelineOppositeContent>
                                    <TimelineOppositeContent color="text.secondary" className="text-center text-light">

                                        <Typography variant="" component="span">
                                        SLD User<br/>Name
                                        </Typography>

                                    </TimelineOppositeContent>
                                    <TimelineOppositeContent color="text.secondary" className="text-center text-light">

                                        <Typography variant="" component="span">
                                        SLD User<br/>Note
                                        </Typography>

                                    </TimelineOppositeContent>
                                    <TimelineOppositeContent color="text.secondary" className="text-center text-light">

                                        <Typography variant="" component="span">
                                        Updated<br/>By
                                        </Typography>

                                    </TimelineOppositeContent>
                                    </TimelineItem>
                                    <Timeline className="bg-light border" >
                                        {getAllData != undefined && getAllData.status ? getAllData.data.ticket_flow.map((flowdata) =>

                                            <TimelineItem>

                                                <TimelineSeparator>
                                                    <TimelineDot />
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineOppositeContent color="text.secondary" className="text-center">

                                                    <Typography variant="" component="span">
                                                        {flowdata.status.replaceAll("_"," ")}
                                                    </Typography>

                                                </TimelineOppositeContent>
                                                <TimelineOppositeContent color="text.secondary" className="text-center">

                                                    <Typography variant="" component="span">
                                                        {flowdata.description}
                                                    </Typography>

                                                </TimelineOppositeContent>
                                                <TimelineOppositeContent color="text.secondary" className="text-center">

                                                    <Typography variant="" component="span">
                                                        {moment(flowdata.date).format("DD-MM-YYYY hh:mm:ss A")}
                                                    </Typography>

                                                </TimelineOppositeContent>
                                                <TimelineOppositeContent color="text.secondary" className="text-center">

                                                    <Typography variant="" component="span">
                                                        {flowdata.website_user_note}
                                                    </Typography>

                                                </TimelineOppositeContent>
                                                <TimelineOppositeContent color="text.secondary" className="text-center">

                                                    <Typography variant="" component="span">
                                                        {flowdata.sld_user_type}
                                                    </Typography>

                                                </TimelineOppositeContent>
                                                <TimelineOppositeContent color="text.secondary" className="text-center">

                                                    <Typography variant="" component="span">
                                                        {flowdata.sld_user_name}
                                                    </Typography>

                                                </TimelineOppositeContent>
                                                <TimelineOppositeContent color="text.secondary" className="text-center">

                                                    <Typography variant="" component="span">
                                                        {flowdata.sld_user_note}
                                                    </Typography>

                                                </TimelineOppositeContent>
                                                <TimelineOppositeContent color="text.secondary" className="text-center">

                                                    <Typography variant="" component="span">
                                                        {flowdata.updated_by_type}
                                                    </Typography>

                                                </TimelineOppositeContent>

                                            </TimelineItem>



                                        ) : <SkeletonListing/>}
                                    </Timeline>

                                {/* </React.Fragment> */}
                            </div>
            </div>
        </>
    )
}