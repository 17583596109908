import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const questionApi = createApi({
  reducerPath: "questionapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/`,
  }),
  endpoints: (builder) => ({
    getAllQuestion: builder.mutation({
      query: (body) => ({
        url: `question/`,
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }), 
    updateAnswer: builder.mutation({
        query: (body) => ({
          url: `question/add-answer`,
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        }),
      }), 
      deleteQuestion : builder.mutation({
        query: (id) => {
            return ({
                url: `/question/${id}`,
                headers: {
                  "x-access-token": token,
                },
                method: "DELETE",
              })
        }
      }), 
      deleteAnswer:  builder.mutation({
        query: ({id , ansid}) => {
            return ({
                url: `/question/${id}/${ansid} `,
                headers: {
                  "x-access-token": token,
                },
                method: "DELETE",
              })
        }
      }),  
      getQuestionById: builder.mutation({
        query: (id) => ({
          url: `question/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "GET",
        }),
      }),  
      

    
  }),
});
export const {
useGetAllQuestionMutation ,
useUpdateAnswerMutation ,
useDeleteQuestionMutation ,
useDeleteAnswerMutation,
useGetQuestionByIdMutation
} = questionApi;
