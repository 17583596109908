import React from 'react';
import CreateTable from './CreateTable';
import AddEvent from './AddEvent';
import EditDelete from './EditDelete';
import {Button} from '@material-ui/core'



const columns = [    
    {
        id: 'image',
        label: 'Event Image',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'eventname',
        label: 'Event Name',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'paylink',
        label: 'Payment Link',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'Buttontext',
        label: 'Button Text',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'isActive',
        label: 'Status',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'latupdated',
        label: 'Last Updated',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'actions',
        label: 'Actions',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
];
function createData(image,eventname,paylink,Buttontext,isActive,latupdated,actions) {
    return {image,eventname,paylink,Buttontext,isActive,latupdated,actions};
}
const rows = [
    createData(<img src='https://via.placeholder.com/100x50?text=Event'/>,
    <Button color="primary">Franchise</Button>,
    <a href='#'>https://www.codiotics.com</a>,
    "Click to Apply","Active","16/08/2021 11:55 am",
    <EditDelete type="delete" />),
    

];


export default function AllEvents(props) {


    return (
        <CreateTable isButton={true} addFormName={"Add Event"} form={<AddEvent/>}  rows={rows} columns={columns} />
    )
}
