import React, { useEffect } from 'react'
import { TextField, } from '@material-ui/core';

export default function DateFilter({ name, setFilterData, filterData }) {
    useEffect(() => { }, [filterData])

    return (
        <div className='d-flex justify-content-start' >
            <div className='border rounded bg-secondary text-light d-flex align-items-center py-1 px-2' >
                {name}
            </div>
            <div>
                <TextField
                    type='date'
                    onChange={(e) => setFilterData(e.target.value)}
                    defaultValue={null}
                    fullWidth={true}
                    variant="outlined"
                    value={filterData}
                    size="small" />
            </div>
        </div>
    )
}
