import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Grid,
} from "@material-ui/core";


export default function FormDialog(props) {
  const {
    open,
    setOpen,
    formType,
    formName,
    fields,
    btnName,
    onBtnClick,
    useAs,
  } = props;

  let initialState = {};
  let formValidation = {};

  {
    fields &&
      fields.forEach((el) => {
        const initialValue = el.initialValue;
        initialState[el.accessKey] = initialValue;
        formValidation[el.accessKey] = el.validation;
      });
  }

  const [inputValues, setInputValues] = useState(initialState);
  const [fileValues, setFileValues] = useState({});

  const formik = useFormik({
    initialValues: {
      ...initialState,
    },
    validationSchema: yup.object({
      ...formValidation,
    }),
    onSubmit: (values) => {
      values = { ...values, ...fileValues };
      onBtnClick(values);
      setOpen(false);
    },
  });

  const handleClose = () => {
    setOpen(false);
    setInputValues(initialState);
  };

  const onChangeHandler = (e) => {
    if (e.target.type === "file") {
      const file = e.target.files[0];
      setFileValues({ ...fileValues, [e.target.name]: file });
    } else {
      const newValue = e.target.value;
      setInputValues({ ...inputValues, [e.target.name]: newValue });
    }
  };



  return (
    <div>
      {useAs == "form" ? (
        <Grid container justifyContent="center">
          <form onSubmit={formik.handleSubmit}>
            <Grid>
              {fields &&
                fields.map((el) => {
                  const { fieldType } = el;
                  return fieldType === "select" ? (
                    <TextField
                      
                      select
                      margin="dense"
                      id="name"
                      name={el.accessKey}
                      label={el.fieldName}
                      type={el.fieldType}
                      fullWidth
                      variant="outlined"
                      value={
                        fieldType === "file" ? null : inputValues[el.accessKey]
                      }
                      onChange={onChangeHandler}
                      inputProps={el.inputProps}
                    >
                      {el.DropdownOptions.map((option) => (
                        <option value={option._id}>
                          {el.label_2
                            ? option[el.label_1] + " " + option[el.label_2]
                            : option[el.label_1]}
                        </option>
                      ))}
                    </TextField>
                  ) : (

                    <>
                      <TextField
                        
                        margin="dense"
                        id="name"
                        name={el.accessKey}
                        label={el.fieldName}
                        type={el.fieldType}
                        fullWidth
                        variant="outlined"
                        onChange={
                          fieldType === "file"
                            ? onChangeHandler
                            : formik.handleChange
                        }
                        multiline={fieldType == "file" ? false : true}
                        minRows={fieldType == "file" ? 0 : 3}
                        inputProps={el.inputProps}
                        onBlur={formik.handleBlur}
                        value={
                          fieldType === "file"
                            ? fileValues[el.accessKey]
                            : formik.values[el.accessKey]
                        }
                      />
                      {fieldType == "file" ?
                        (el.fileRequired != undefined && el.fileRequired && !fileValues[el.accessKey] && <span style={{ color: "red", fontSize: "12px" }}>Required !</span>)
                        : (formik.touched[el.accessKey] && (<span style={{ color: "red", fontSize: "12px" }}>{formik.errors[el.accessKey]}</span>)
                        )}
                    </>
                  );
                })}
            </Grid>

            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                {btnName}
              </Button>
            </DialogActions>
          </form>
        </Grid>
      ) : (
        <Dialog open={open} onClose={handleClose} fullWidth={props.fullWidth == undefined ? false : props.fullWidth} >
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>{formName}</DialogTitle>
            <DialogContent>
              {fields &&
                fields.map((el) => {
                  const { fieldType } = el;
                  return fieldType === "select" ? (
                    <TextField
                      
                      select
                      margin="dense"
                      id="name"
                      name={el.accessKey}
                      label={el.fieldName}
                      type={el.fieldType}
                      fullWidth
                      variant="outlined"
                      value={
                        fieldType === "file" ? null : inputValues[el.accessKey]
                      }
                      onChange={onChangeHandler}
                      inputProps={el.inputProps}
                    >
                      {el.DropdownOptions.map((option) => (
                        <option value={option._id}>
                          {el.label_2
                            ? option[el.label_1] + " " + option[el.label_2]
                            : option[el.label_1]}
                        </option>
                      ))}
                    </TextField>
                  ) : (
                    <>
                      <TextField
                        
                        margin="dense"
                        id="name"
                        name={el.accessKey}
                        label={el.fieldName}
                        type={el.fieldType}
                        fullWidth
                        variant="outlined"
                        onChange={
                          fieldType === "file"
                            ? onChangeHandler
                            : formik.handleChange
                        }
                        multiline={fieldType == "file" ? false : true}
                        minRows={fieldType == "file" ? 0 : 3}
                        inputProps={el.inputProps}
                        onBlur={formik.handleBlur}
                        value={
                          fieldType === "file"
                            ? null
                            : formik.values[el.accessKey]
                        }
                      />
                      {fieldType == "file" ?
                        (el.fileRequired != undefined && el.fileRequired && !fileValues[el.accessKey] && <span style={{ color: "red", fontSize: "12px" }}>Required !</span>)
                        : (formik.touched[el.accessKey] && (<span style={{ color: "red", fontSize: "12px" }}>{formik.errors[el.accessKey]}</span>)
                        )}
                    </>
                  );
                })}
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                {btnName}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </div>
  );
}
