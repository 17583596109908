import React from 'react'
import { makeStyles, withStyles, } from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TitleIcon from '@material-ui/icons/Title';
import SubjectIcon from '@material-ui/icons/Subject';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import DescriptionIcon from '@material-ui/icons/Description';
import LanguageIcon from '@material-ui/icons/Language';
import PaymentIcon from '@material-ui/icons/Payment';
import LayersIcon from '@material-ui/icons/Layers';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import PersonIcon from '@material-ui/icons/Person';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CropFreeIcon from '@material-ui/icons/CropFree';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: 18,
        flexBasis: '33.33%',
        flexShrink: 0,

    },
    table: {
        padding: 10,
        margin: 5

    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));


export default function ViewOrder({ viewDialog, data }) {
    const classes = useStyles();
      
    const product_type = data.product_type
    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);
    return (

        <div>
            <div className="d-flex justify-content-center my-3">
                {product_type == "SLD_VIDEO_LECTURE" ?
                    <div>
                        <TableContainer style={{ minWidth: 550 }} className="m-2 border">
                            <Table className={classes.table} aria-label="customized table">
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell className="m-1 " align="left">
                                            <p className="d-flex align-items-center text-muted"> Video Lecture Name </p>
                                        </StyledTableCell>
                                        <StyledTableCell className="m-1" align="left">
                                            <p className="d-flex align-items-center text-muted"> {data.video_lecture_details.video_lecture_name} </p>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell className="m-1 " align="left">
                                            <p className="d-flex align-items-center text-muted"><TitleIcon className="mx-4" fontSize='medium' />   Book Title </p>
                                        </StyledTableCell>
                                        <StyledTableCell className="m-1" align="left">
                                            <h6 className='text-muted'>{data.book_product_details.selling_price}</h6>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell className="m-1 " align="left">
                                            <p className="d-flex align-items-center text-muted"><VpnKeyIcon className="mx-4" fontSize='medium' />   Book Code </p>
                                        </StyledTableCell>
                                        <StyledTableCell className="m-1" align="left">
                                            <h6 className='text-muted'>{data.book_product_details.selling_price}</h6>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell className="m-1 " align="left">
                                            <p className="d-flex align-items-center text-muted"><SubjectIcon className="mx-4" fontSize='medium' />   Subject Name </p>
                                        </StyledTableCell>
                                        <StyledTableCell className="m-1" align="left">
                                            <h6 className='text-muted'>{data.book_product_details.selling_price}</h6>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell className="m-1 " align="left">
                                            <p className="d-flex align-items-center text-muted"><CollectionsBookmarkIcon className="mx-4" fontSize='medium' />   Book Edition </p>
                                        </StyledTableCell>
                                        <StyledTableCell className="m-1" align="left">
                                            <h6 className='text-muted'>{data.book_product_details.selling_price}</h6>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell className="m-1 " align="left">
                                            <p className="d-flex align-items-center text-muted"><FormatAlignCenterIcon className="mx-4" fontSize='medium' />   Book Formate </p>
                                        </StyledTableCell>
                                        <StyledTableCell className="m-1" align="left">
                                            <h6 className='text-muted'>{data.book_product_details.selling_price}</h6>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell className="m-1 " align="left">
                                            <p className="d-flex align-items-center text-muted"><DescriptionIcon className="mx-4" fontSize='medium' />IMP Description </p>
                                        </StyledTableCell>
                                        <StyledTableCell className="m-1" align="left">
                                            <h6 className='text-muted'>{data.book_product_details.selling_price}</h6>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell className="m-1 " align="left">
                                            <p className="d-flex align-items-center text-muted"><LanguageIcon className="mx-4" fontSize='medium' /> Language </p>
                                        </StyledTableCell>
                                        <StyledTableCell className="m-1" align="left">
                                            <h6 className='text-muted'>{data.book_product_details.selling_price}</h6>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                </TableBody>
                                {/* } */}

                            </Table>
                        </TableContainer>
                    </div>
                    :
                    <div>
                        <TableContainer style={{ minWidth: 550 }} className="m-2 border">
                            <Table className={classes.table} aria-label="customized table">
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell className="m-1 " align="left">
                                            <p className="d-flex align-items-center text-muted">  <h6 className='text-muted'>Faculty</h6> </p>
                                        </StyledTableCell>
                                        <StyledTableCell className="m-1" align="left">
                                            {data.book_product_details.faculty.map((faculty) => {
                                                return <h6 className='text-muted'>{faculty.faculty_name}</h6>
                                            })}
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell className="m-1 " align="left">
                                            <p className="d-flex align-items-center text-muted"> <h6 className='text-muted'>Course</h6> </p>
                                        </StyledTableCell>
                                        <StyledTableCell className="m-1" align="left">
                                            <h6 className='text-muted'>{data.book_product_details.course_name}</h6>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell className="m-1 " align="left">
                                            <p className="d-flex align-items-center text-muted"><h6>Category</h6></p>
                                        </StyledTableCell>
                                        <StyledTableCell className="m-1" align="left">
                                            <h6 className='text-muted'>{data.book_product_details.category_name}</h6>

                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell className="m-1 " align="left">
                                            <p className="d-flex align-items-center text-muted"><h6>Level</h6></p>
                                        </StyledTableCell>
                                        <StyledTableCell className="m-1" align="left">
                                            <h6 className='text-muted'>{data.book_product_details.level_name}</h6>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell className="m-1 " align="left">
                                            <p className="d-flex align-items-center text-muted"><h6>Wallet Discount</h6></p>
                                        </StyledTableCell>
                                        <StyledTableCell className="m-1" align="left">
                                            <h6 className='text-muted'>{data.book_product_details.wallet_discount}</h6>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell className="m-1 " align="left">
                                            <p className="d-flex align-items-center text-muted"><h6>SLD Discount 50</h6></p>

                                        </StyledTableCell>
                                        <StyledTableCell className="m-1" align="left">
                                            <h6 className='text-muted'>{data.book_product_details.sld_discount_50}</h6>

                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell className="m-1 " align="left">
                                            <p className="d-flex align-items-center text-muted"><h6>Sharing Percent</h6></p>

                                        </StyledTableCell>
                                        <StyledTableCell className="m-1" align="left">
                                            <h6 className='text-muted'>{data.book_product_details.sharing_percent}</h6>

                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell className="m-1 " align="left">
                                            <p className="d-flex align-items-center text-muted"><h6>Selling Price</h6></p>

                                        </StyledTableCell>
                                        <StyledTableCell className="m-1" align="left">
                                            <h6 className='text-muted'>{data.book_product_details.selling_price}</h6>

                                        </StyledTableCell>
                                    </StyledTableRow>

                                </TableBody>
                                {/* } */}

                            </Table>
                        </TableContainer>
                    </div>
                }
                <TableContainer style={{ minWidth: 550 }} className="m-2 border">
                    <Table className={classes.table} aria-label="customized table">
                        <TableBody>

                            <StyledTableRow>
                                <StyledTableCell className="m-1 " align="left">
                                    <p className="d-flex align-items-center text-muted"><h6>MRP</h6></p>
                                </StyledTableCell>
                                <StyledTableCell className="m-1" align="left">
                                    <h6 className='text-muted'>{data.book_product_details.product_mrp}</h6>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell className="m-1 " align="left">
                                    <p className="d-flex align-items-center text-muted"><h6>Payment To Faculty</h6></p>
                                </StyledTableCell>
                                <StyledTableCell className="m-1" align="left">
                                    <h6 className='text-muted'>{data.book_product_details.payment_to_faculty}</h6>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell className="m-1 " align="left">
                                    <p className="d-flex align-items-center text-muted"><h6>Price Onwhich Commission Calculated</h6></p>
                                </StyledTableCell>
                                <StyledTableCell className="m-1" align="left">
                                    <h6 className='text-muted'>{data.book_product_details.price_onwhich_commission_calculated}</h6>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell className="m-1 " align="left">
                                    <p className="d-flex align-items-center text-muted"><h6>GST Applied Percentage</h6></p>
                                </StyledTableCell>
                                <StyledTableCell className="m-1" align="left">
                                    <h6 className='text-muted'>{data.book_product_details.gst_applied_percentage}</h6>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell className="m-1 " align="left">
                                    <p className="d-flex align-items-center text-muted"><h6>Franchise Discount 50</h6></p>
                                </StyledTableCell>
                                <StyledTableCell className="m-1" align="left">
                                    <h6 className='text-muted'>{data.book_product_details.franchise_discount_50}</h6>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell className="m-1 " align="left">
                                    <p className="d-flex align-items-center text-muted"><h6>Commission Offered</h6></p>
                                </StyledTableCell>
                                <StyledTableCell className="m-1" align="left">
                                    <h6 className='text-muted'>{data.book_product_details.commission_offered}</h6>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell className="m-1 " align="left">
                                    <p className="d-flex align-items-center text-muted"><h6>Commision Amount</h6></p>
                                </StyledTableCell>
                                <StyledTableCell className="m-1" align="left">
                                    <h6 className='text-muted'>{data.book_product_details.commision_amount}</h6>
                                </StyledTableCell>
                            </StyledTableRow>



                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}
