import Delete from "@material-ui/icons/Delete";
import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
const token = localStorage.getItem("__authToken__");
import { PYTHON_URL } from "../constant/BaseURL";

export const excelFileApi = createApi({
  reducerPath: "excelFile",
  baseQuery: fetchBaseQuery({
    baseUrl: PYTHON_URL,
  }),


  endpoints: (builder) => ({

    getVideoLectureSheet: builder.mutation({
      query: (body) => {
        return {
          url: "get-videolecture-sheet?token=" + token,
          headers: {
            //"token": token,
          },
          method: "GET",
          body,

        }
      }
    }),
    getFacultySheet: builder.mutation({
      query: (body) => {
        return {
          url: "get-faculty-sheet?token=" + token,
          headers: {
            //"token": token,
          },
          method: "GET",
          body,
        }
      }
    }),
    getOrderSheet: builder.mutation({
      query: (body) => {
        return {
          url: "get-order-sheet",
          headers: {
            "token": token,
          },
          method: "GET",
          body,

        }
      }
    }),
    addVideoLectureSheet: builder.mutation({
      query: (body) => {
        return {
          url: "sld-video-lecture",
          headers: {
            "token": token,
          },
          method: "POST",
          body,

        }
      }

    }),
    addFacultySheet: builder.mutation({
      query: (body) => {
        return {
          url: "faculty-add-update",
          headers: {
            "token": token
          },
          method: "POST",
          body,

        }
      }

    })

  }),

});

export const {
  useGetVideoLectureSheetMutation,
  useGetFacultySheetMutation,
  useGetOrderSheetMutation,
  useAddVideoLectureSheetMutation,
  useAddFacultySheetMutation
} = excelFileApi;