import React from 'react'
import { Button, Grid, Box, makeStyles, withStyles, Divider, } from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';




const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: 18,
        flexBasis: '33.33%',
        flexShrink: 0,

    },
    table: {
        padding: 10,
        margin: 5

    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

function ViewProductBook({ data, viewDialog }) {
      
    const classes = useStyles();

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);
    return (
        <div className=''>
            <div className='p-3'>

                <Box sx={{
                    flexGrow: 1,
                    bgcolor: 'background.paper',
                }}>
                    <div class="card">
                        <div class="card-body">
                            <Grid container >
                                {/* <Grid xs={12} className=' my-1'>
                                    <Button
                                        onClick={() => viewDialog(false)}
                                        className=" bg-danger float-right btn btn-sm btn-danger">
                                        Close
                                    </Button>
                                </Grid> */}

                                <Button onClick={() => {
                                    viewDialog(false)
                                }} style={{ position: "absolute", right: -10, zIndex: 1000, top: 0 }}>
                                    <HighlightOffIcon color='secondary' />
                                </Button>


                                <Grid xs={3} className='my-1'>
                                    <div style={{ width: 200 }}>
                                        <img src={data.image} alt="..." class="img-thumbnail" />
                                    </div>
                                </Grid>


                                <Grid xs={9} className='my-2'>
                                    <h5 className="mb-3 text-muted bg-light m-2 d-flex align-items-center">
                                        Name : <span className="highlight-text-default  px-2">
                                            {data.nickname}
                                        </span>
                                    </h5>

                                    <h5 className="mb-3 text-muted bg-light m-2 d-flex align-items-center">
                                        Title : <span className="highlight-text-default  px-2">
                                            {data.product_title}
                                        </span>
                                    </h5>

                                    <h5 className="mb-3 text-muted bg-light m-2 d-flex align-items-center">
                                        MRP : <span className="highlight-text-default  px-2">
                                            {data.product_mrp}
                                        </span>
                                    </h5>

                                </Grid>

                            </Grid>
                            <Divider className='my-2' />

                            <div className="d-flex">

                                <TableContainer style={{ maxWidth: 570 }} className="m-2 border">
                                    <h4  className=" productHeadings p-2  text-center  ">About</h4>
                                    <Divider />
                                    <Table className={classes.table} aria-label="customized table">
                                        <TableBody>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    About Produc
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left"> {data.about_book_product} </StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Category Name
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left"> {data.category_name} </StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Course Name
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.course_name}</StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Level Name
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.level_name}</StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Faculty
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left"> {data.faculty.map((faculty) => faculty.faculty_name + ", ")} </StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Selling Price
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.selling_price}</StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Syllabus
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.syllabus}</StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Book
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.books.map((bookData) => {
                                                    return <div>
                                                        Name :- {bookData.book_name}<br />
                                                        Title : - {bookData.title}<br />
                                                    </div>
                                                })}</StyledTableCell>
                                            </StyledTableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <TableContainer style={{ maxWidth: 570 }} className="m-2 border">
                                    <h4  className=" productHeadings p-2 text-center  ">Discount And Commision</h4>
                                    <Divider />
                                    <Table className={classes.table} aria-label="customized table">
                                        <TableBody>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Commision Amount
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left"> {data.commision_amount} </StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Commision Offered
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left"> {data.commission_offered} </StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Commission Calculated
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.price_onwhich_commission_calculated}</StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Other Coupon Applied
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.other_coupon_applied ? "Yes" : "No"}</StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Sld Discount 50
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.sld_discount_50}</StyledTableCell>
                                            </StyledTableRow>


                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Wallet Discount
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.wallet_discount}</StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Franchise Discount 50
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.franchise_discount_50}</StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Wallet Discount Is Applied
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.wallet_discount_is_applied ? "Yes" : "No"}</StyledTableCell>
                                            </StyledTableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>

                            <div className="d-flex">
                                <TableContainer style={{ maxWidth: 570 }} className="m-2 border">
                                    <h4  className=" productHeadings p-2  text-center  ">Other</h4>
                                    <Divider />
                                    <Table className={classes.table} aria-label="customized table">
                                        <TableBody>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    COD
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left"> {data.cod_eligible ? "Yes" : "No"} </StyledTableCell>
                                            </StyledTableRow>


                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Product Format
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.product_format}</StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Delievery Time
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.delievery_time}</StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Dispatch By
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.dispatch_by}</StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Dispatch Time
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.dispatch_time}</StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Important Notice
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.important_notice}</StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Invoice Name
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.invoice_name}</StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Type
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.type}</StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Link
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.link}</StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Meta
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.meta}</StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Note
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.note}</StyledTableCell>
                                            </StyledTableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <TableContainer style={{ maxWidth: 570 }} className="m-2 border">
                                    <h4  className=" productHeadings p-2  text-center  ">Payment</h4>
                                    <Divider />
                                    <Table className={classes.table} aria-label="customized table">
                                        <TableBody>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Payment To Faculty
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.payment_to_faculty}</StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Payment Gateway
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left"> {data.allowed_payment_gateway + ", "} </StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    Sharing Percent
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.sharing_percent}</StyledTableCell>
                                            </StyledTableRow>


                                            <StyledTableRow>
                                                <StyledTableCell className="m-1 font-weight-bold" align="left">
                                                    GST Applied Percentage
                                                </StyledTableCell>
                                                <StyledTableCell className="m-1" align="left">{data.gst_applied_percentage}</StyledTableCell>
                                            </StyledTableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </Box>




            </div>

            {/* 
     <Box sx={{
                flexGrow: 1,
                bgcolor: 'background.paper',
            }}>


                <div class="card">
                    <div class="card-body">
                        <Grid container >

                            <Grid xs={6} className=' my-1'>
                                <div style={{ height: 200, width: 200 }}>
                                    <img src={data.image} alt="..." class="img-thumbnail" />
                                </div>
                            </Grid>

                            <Grid xs={6} className=' my-1'>
                                <Button
                                    onClick={() => viewDialog(false)}
                                    className=" bg-danger float-right btn btn-sm btn-danger">
                                    Close
                                </Button>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>About Book Product :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.about_book_product}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Allowed Payment Gateway :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.allowed_payment_gateway + ","}</span>
                            </Grid>


                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Category Name :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.category_name}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>COD Eligible :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.cod_eligible ? "Yes" : "No"}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Commision Amount :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.commision_amount}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Commission Offered :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.commission_offered}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Course Name :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.course_name}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Created At :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.createdAt}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>delievery_time :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.delievery_time}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Dispatch By :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.dispatch_by}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Dispatch Time :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.dispatch_time}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Faculty :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.faculty.map((data) => {
                                    return data.faculty_name + ","
                                })}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Franchise Discount 50 :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.franchise_discount_50}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>GST Applied Percentage :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.gst_applied_percentage}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Important Notice :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.important_notice}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Invoice Name :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.invoice_name}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Active :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.is_active ? "Yes" : "No"}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Available :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.is_available ? "Yes" : "No"}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Combo :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.is_combo ? "Yes" : "No"}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Deleted :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.is_deleted ? "Yes" : "No"}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Hidden :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.is_hidden ? "Yes" : "No"}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>MRP Show :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.is_mrp_show ? "Yes" : "No"}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Level Name :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.level_name}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Link :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.link}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Meta :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.meta}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Nickname :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.nickname}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Note :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.note}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Other Coupon Applied:</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.other_coupon_applied ? "Yes" : "No"}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Payment To Faculty :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.payment_to_faculty}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Price Onwhich Commission Calculated :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.price_onwhich_commission_calculated}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Product_Format :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.product_format}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Product MRP :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.product_mrp}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Product Title :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.product_title}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Selling Price :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.selling_price}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Sharing Percent :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.sharing_percent}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Sld Discount 50 :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.sld_discount_50}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Syllabus :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.syllabus}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Type :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.type}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Updated At :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.updatedAt}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Wallet Discount :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.wallet_discount}</span>
                            </Grid>

                            <Grid xs={6} className='my-1'>
                                <span className=' m-1 h5'>Wallet Discount Is Applied :</span>
                                <span className=' m-1 h5 font-weight-bold'>{data.wallet_discount_is_applied}</span>
                            </Grid>


                        </Grid>

                    </div>
                </div>
            </Box> */}

        </div>
    )
}

export default ViewProductBook
