import React from 'react';
import { TextField, Button, makeStyles, Checkbox, Grid, FormGroup, FormControlLabel, Box } from '@material-ui/core'
import { useFormik } from 'formik';
import * as yup from 'yup'
import { useGetAllModuleNameQuery } from '../../../services/services';
import { toast } from 'react-toastify'

export default function AddUserGroup({ AddData, setDialog }) {

    const getAllModule = useGetAllModuleNameQuery()
    const allModules = getAllModule.data
    const formik = useFormik({
        initialValues: {
            group_name: "",
            module_group: []
        },
        validationSchema: yup.object({
            group_name: yup.string().required("Required !"),
        }),
        onSubmit: (values) => {
            if (!formik.values.module_group.length) {
                toast("Please select any module.")
                return
            }
            AddData(values)
        }
    })
    const checkAll = () => {
        let modules = allModules.data.map((e, i) => { return e.module_group })
        formik.setFieldValue("module_group", modules)
    }
    const reset = () => {
        formik.setFieldValue("module_group", [])
    }
    const dialogclose = () => {
        setDialog(false)
    }
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Box sx={{ p: 2 }} >
                    <FormGroup>
                        <Grid container >
                            <Grid xs={12} className="border-bottom border-secondary pb-3 mb-2" >
                                <TextField
                                    sx={{ textTransform: "uppercase" }}
                                    name="group_name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.group_name.toLocaleUpperCase()}
                                    label="user group name"
                                    variant="outlined"
                                    size="small" /><br />
                                {formik.touched.group_name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.group_name}</span>}
                            </Grid>
                            <Grid xs={12} className="my-2" >
                                <Button size='small' color='primary' variant='contained' onClick={() => { checkAll() }} >
                                    check all
                                </Button>
                                <Button className='ml-1' size='small' color='primary' variant='contained' onClick={() => { reset() }} >
                                    reset
                                </Button>
                            </Grid>
                            {allModules ?
                                allModules.data.map((data) => {
                                    return (
                                        <Grid xs={3} >
                                            <FormControlLabel
                                                name="module_group"
                                                // defaultValue={}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.module_group}
                                                control={<Checkbox checked={formik.values.module_group.includes(data.module_group)} value={data.module_group} />}
                                                label={data.module_group}
                                            />
                                        </Grid>
                                    )
                                })
                                : "Loading..."
                            }
                        </Grid>
                    </FormGroup>
                    <div className="d-flex justify-content-end">
                        {/* <Button
                        onClick={dialogclose}
                        variant="contained"
                        color="secondary"
                    >Close</Button> */}
                        <Button
                            type="submit"
                            style={{ marginLeft: 20 }}
                            variant="contained"
                            color="primary"
                        > Save</Button>
                    </div>
                </Box>
            </form>
        </>
    )
}
