import React, { useState, useEffect } from "react";
import EditDelete from "./EditDelete";
import CreateTableAddButton from "./CreateTableAddButton";
import TestimonialForm from "./TestimonialForm";
import { useFormik } from "formik";
import * as yup from "yup";
import Toster from "./Toster";
import {
  Avatar,
  Switch,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@material-ui/core";
import FormDialog from "./reusableComponents/NewFormDialog";
import {
  useGetCmsQuery,
  useUpdateCmsStatusMutation,
  useUpdateCMSDataMutation,
  useAddCmsMutation,
} from "../../../services/cmsServices";
import DataNotFound from "./DataNotFound";
import AddEditForm from "./AddEditForm";
import AuthWrapper from "../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../utils/getFilterFunction";

const columns = [
  { id: "image", label: "Image", minWidth: 50 },
  { id: "userName", label: "User Name", minWidth: 50 },
  {
    id: "rating",
    label: "Rating",
    minWidth: 50,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "feedback",
    label: "Feedback",
    minWidth: 50,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 50,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
    authName: [
      "CMS_CUSTOMER_FEEDBACK_CHANGE_STATUS",
      "CMS_CUSTOMER_FEEDBACK_EDIT",
    ],
  },
];
function createData(image, userName, rating, feedback, actions) {
  return { image, userName, rating, feedback, actions };
}

export default function CmsCustomerFeedback() {
  const {
    data: AllData,
    isFetching,
    isLoading,
  } = useGetCmsQuery({ key: "customer_feedback" });
  const [updateInnerData, updateInnerDataInfo] = useUpdateCMSDataMutation();
  const [updateIsShowStatus, updateIsShowInfo] = useUpdateCmsStatusMutation();
  const [addData, updateAddDataInfo] = useAddCmsMutation();
  const [editDialog, setEditDialog] = useState(false);
  const [addDialog, setAddDialog] = useState(false);
  const [formIntialValue, setFormIntialValue] = useState("");

  const dialogclose = () => {
    setEditDialog(false);
  };

  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (
      !isFetching &&
      !isLoading &&
      AllData != undefined &&
      AllData.data.length
    ) {
      let row = [];
      const dataArray = AllData.data[0].customer_feedback;
      dataArray.map((el) => {
        const { _id, user_name, image, rating, feedback, is_show } = el;
        row.push(
          createData(
            <Avatar>
              <img src={image} width="100%" />
            </Avatar>,
            user_name,
            rating,
            feedback,
            <div>
              <AuthWrapper
                type="ACTION"
                name="CMS_CUSTOMER_FEEDBACK_CHANGE_STATUS"
              >
                <Switch
                  onClick={() => {
                    updateIsShowStatus({
                      key: "customer_feedback",
                      cms_data: {
                        is_show: !el.is_show,
                        customer_feedback_id: el._id,
                      },
                    });
                  }}
                  checked={el.is_show}
                />
              </AuthWrapper>
              <AuthWrapper
                type="ACTION"
                name="CMS_CUSTOMER_FEEDBACK_EDIT"
              >
                <EditDelete
                  type="edit"
                  setEditDialog={setEditDialog}
                  setData={setFormIntialValue}
                  data={el}
                />
              </AuthWrapper>
            </div>
          )
        );
      });

      setRows(row);
    }
  }, [isFetching, isLoading]);
  // if (!isFetching && !isLoading && AllData != undefined && AllData.data.length) {
  //   const dataArray = AllData.data[0].customer_feedback;
  //   dataArray.map((el) => {
  //     const {
  //       _id,
  //       user_name,
  //       image,
  //       rating,
  //       feedback,
  //       is_show,
  //     } = el;
  //     rows.push(
  //       createData(
  //         <Avatar>
  //           <img src={image} width="100%" />
  //         </Avatar>,
  //         user_name,
  //         rating,
  //         feedback,
  //         <div>
  //           <Switch
  //             onClick={() => {
  //               updateIsShowStatus({
  //                 key: "customer_feedback",
  //                 cms_data: {
  //                   is_show: !el.is_show,
  //                   customer_feedback_id: el._id,
  //                 },
  //               });
  //             }}
  //             checked={el.is_show}
  //           />
  //           <EditDelete
  //             type="edit"
  //             setEditDialog={setEditDialog}
  //             setData={setFormIntialValue}
  //             data={el}
  //           />
  //         </div>
  //       )
  //     );
  //   });
  // }

  const updateBtnHandler = (dataForUpdate, fileData) => {
    dataForUpdate = JSON.stringify({
      ...dataForUpdate,
      customer_feedback_id: formIntialValue._id,
    });
    let form_data = new FormData();
    form_data.append("key", "customer_feedback");
    form_data.append("cms_data", dataForUpdate);
    form_data.append("image", fileData);
    updateInnerData(form_data);
    setEditDialog(false);
  };

  const addBtnHandler = (dataForAdd, fileData) => {
    dataForAdd = JSON.stringify(dataForAdd);
    let form_data = new FormData();
    form_data.append("key", "customer_feedback");
    form_data.append("cms_data", dataForAdd);
    form_data.append("image", fileData);
    addData(form_data);
    setAddDialog(false);
  };

  return (
    <div>
      <AuthWrapper type="ACTION" name="CMS_CUSTOMER_FEEDBACK_ADD">
        <Grid container spacing={2} className="mt-3 p-3">
          <EditDelete
            type="add"
            onclick={() => {
              setAddDialog(true);
            }}
          />
        </Grid>
      </AuthWrapper>
      <AuthWrapper type="ACTION" name="CMS_CUSTOMER_FEEDBACK_LIST">
        {rows.length ? (
          <CreateTableAddButton
            rows={rows}
            //form={<TestimonialForm />}
            columns={getFilterdColumns (columns)}
            //isButton={true}
            //addFormName={"Add Testimonial Slide"}
          />
        ) : (
          <DataNotFound />
        )}
      </AuthWrapper>
      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<  "Edit Form" Open When editDialog is True >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

      {editDialog && (
        <AddEditForm
          userName={formIntialValue.user_name}
          rating={formIntialValue.rating}
          feedback={formIntialValue.feedback}
          image={formIntialValue.image}
          onClickHandler={updateBtnHandler}
          openForm={editDialog}
          setOpenForm={setEditDialog}
          formName="Update Feedback"
          btnName="Update"
        />
      )}

      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  "Add Form" Open When addDialog is True >>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

      {addDialog && (
        <AddEditForm
          userName=""
          rating=""
          feedback=""
          onClickHandler={addBtnHandler}
          openForm={addDialog}
          setOpenForm={setAddDialog}
          formName="Add New Feedback"
          btnName="Add"
        />
      )}

      {updateIsShowInfo.data && (
        <Toster info={updateIsShowInfo} dialogclose={dialogclose} />
      )}

      {updateInnerDataInfo.data && (
        <Toster info={updateInnerDataInfo} dialogclose={dialogclose} />
      )}
      {updateAddDataInfo.data && (
        <Toster info={updateAddDataInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
