import React, { useState, useEffect } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import AddDataHeader from "../AddDataHeader";
import DataNotFound from "../DataNotFound";
import FormDialog from "../reusableComponents/NewFormDialog";

import { useGetConfigQuery, useAddConfigMutation, useUpdateConfigDataMutation, useDeleteConfigDataMutation } from "../../../../services/offlineModuleServices/configServices";
import AddEditAttempt from "./AddEditAttempt";
import AddAttempt from "./attempt/AddAttempt";
import EditAttempt from "./attempt/EditAttempt";

export default function Attempts() {

    let [getid, setGetid] = useState("");
    const [data, setData] = useState("");
    const [addDialog, setAddDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [render, setRender] = useState(false)


    const { data: AllAttempts, isAllAttemptsFetching, isAllAttemptsLoading } = useGetConfigQuery({
        limit: "",
        page: "",
        key: "attempt"
    })
    const [addAttempt, addAttemptInfo] = useAddConfigMutation()
    const [updateAttempt, updateAttemptInfo] = useUpdateConfigDataMutation()
    const [deleteAttempt, deleteAttemptInfo] = useDeleteConfigDataMutation()


    const columns = [
        {
            id: "SNo",
            label: "S.No",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "attemptDue",
            label: "Attempt Due",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "actions",
            label: "Actions",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
    ];


    function createData(SNo, attemptDue, actions) {
        return {
            SNo,
            attemptDue,
            actions,
        };
    }

    let rows = [];
    const totalItem = AllAttempts ? AllAttempts.totalItem : 0;

    let totalData = "ok";


    useEffect(() => {
        getid && deleteAttempt({
            key: "attempt",
            configData: {
                _id: getid
            }
        }) && setGetid("");
    }, [getid]);

    if (
        AllAttempts?.status
    ) {
        totalData = AllAttempts.totalItem;
        rows = AllAttempts.data.map((ele, index) => {
            return createData(
                index + 1,
                ele.attempt_due_date,
                <div>
                    <EditDelete
                        setEditDialog={setEditDialog}
                        type="edit"
                        setData={setData}
                        data={ele}
                    />
                    <EditDelete type="delete" setGetid={setGetid} id={ele._id} />
                </div>
            );
        });
    }
    
    const dialogclose = () => {
        setEditDialog(false);
        setAddDialog(false);
        setRender(!render);
    };

    const addAttemptHandler = (dataForAdd) => {
        addAttempt(
            {
                key: "attempt",
                configData: {
                    is_active: true,
                    attempt_due_date: dataForAdd.attempt_due_date
                }
            }
        )
    }


    const updateAttemptHandler = (dataForUpdate) => {
        updateAttempt(
            {
                key: "attempt",
                configData: {
                    is_active: true,
                    _id: data._id,
                    attempt_due_date: dataForUpdate.attempt_due_date
                }
            }
        )
    }


    return (
        <div>
            {/* {!viewDialog && ( */}
            <AddDataHeader
                addFormName={"Add New Attempt"}
                isSearch={true}
                totalItem={totalItem}
                isPagination={true}
                setAddDialog={setAddDialog}
                isButton={true}
                Adddialog={addDialog}

            />
            {/* )} */}


            <>
                {rows.length ? (
                    <CreateTableAddButton
                        // editDialog={editDialog}
                        // setPage={setPage}
                        rows={rows}
                        columns={columns}
                        isButton={true}
                    // editFormName={"Edit Attempt Due"}
                    />
                ) : totalData == 0 ? (
                    <DataNotFound />
                ) : (
                    <SkeletonListing />
                )}

            </>

            {
                addDialog &&
                <AddAttempt open={addDialog} onClose={() => setAddDialog(false)} onSubmit={(dataForAdd) => addAttemptHandler(dataForAdd)} />
            }

            {editDialog &&
                <EditAttempt open={editDialog} onClose={() => setEditDialog(false)} onSubmit={(dataForUpdate) => updateAttemptHandler(dataForUpdate)} initialData={data} />
            }


            {addAttemptInfo && (
                <Toster info={addAttemptInfo} dialogclose={dialogclose} />
            )}

            {updateAttemptInfo && (
                <Toster info={updateAttemptInfo} dialogclose={dialogclose} />
            )}

            {deleteAttemptInfo && (
                <Toster info={deleteAttemptInfo} dialogclose={dialogclose} />
            )}



        </div>
    );
}
