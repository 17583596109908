import React, { useState, useEffect } from 'react'
import {

    MenuItem,
    Checkbox, ListItemText,
    OutlinedInput, makeStyles, TextField,
} from '@material-ui/core'
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        minWidth: 100,
    },
    tab: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
export default function MultiSelectFilter({ name, multiple, valueShow, listData, showKey, valueKey, setFilterData, isReset, setIsReset, listDataType
    //onReset 
}) {
    // const [dataValues, setdataValues] = useState([]);
    const [isRender, setIsRender] = useState(0)


    const setHandle = (e) => {


        let couseValue = [...valueShow]


        if (couseValue.length > 0) {
            couseValue.pop()
            couseValue.push(e.target.value)
        } else {
            couseValue.push(e.target.value)
        }


        setFilterData(couseValue)

    }
    // if (isReset && isRender == 0) {
    //     setdataValues([])
    //     setIsRender(1)
    // }


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 100,
            },
        },
    };
    const classes = useStyles();


    return (
        <Box style={{ margin: 0 }}
            variant="outlined"
            size="small"
            className={classes.formControl}
            fullWidth={true}
            sx={{ m: 1, width: 200 }}>
            <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">{name}</InputLabel> */}
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={valueShow}
                    // label={name}
                    onChange={(e) => setHandle(e)}
                    size='small'
                    displayEmpty
                >
                    <MenuItem value= "" disabled >  {name} </MenuItem>
                    {valueKey == "course_code" ? listData?.data?.map((ele) => { return (< MenuItem value={ele.course_code}>{ele.course_code}</MenuItem>) })
                        : listData?.data?.map((ele) => { return (< MenuItem value={ele.level_code}>{ele.level_name}</MenuItem>) })}

                </Select>
            </FormControl>
        </Box>
    );
  
}
