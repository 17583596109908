import React, { useState, useEffect } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import Loader from "../Loader";
import AddDataHeader from "../AddDataHeader";
import DataNotFound from "../DataNotFound";
import {
    AccordionDetails,
    AccordionSummary,
    Typography,
    Accordion,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListIcon from "@material-ui/icons/FilterList";
import Filters from "../Filters";

import {
    useGetAllExcelLogsMutation,
    useDeleteExcelLogMutation,
} from "../../../../services/ExcelLogs/ExcelLogsServices"
import moment from "moment";
import AuthWrapper from "../../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../../utils/getFilterFunction";

export default function VideoLectureExcelLogs() {
    const [getAllExcelLogs, getAllExcelLogsInfo] = useGetAllExcelLogsMutation();
    const [deleteExcelLog, deleteExcelLogInfo] = useDeleteExcelLogMutation();


    const [filteredPagination, setFilteredPagination] = React.useState([]);

    const columns = [
        {
            id: "SNo",
            label: "S.No",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "sheetName",
            label: "Sheet Name",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "UploadedByUser",
            label: "Uploaded By User",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        // {
        //     id: "productType",
        //     label: "Excel Uploaded Type",
        //     minWidth: 50,
        //     align: "center",
        //     format: (value) => value.toLocaleString("en-US"),
        // },
        // {
        //     id: "date",
        //     label: "Date & Time",
        //     minWidth: 50,
        //     align: "center",
        //     format: (value) => value.toLocaleString("en-US"),
        // },
        {
            id: "downloadOriginalSheet",
            label: "Download (Original Sheet)",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "downloadErrorSheet",
            label: "Download (Error Sheet)",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "errorRows",
            label: "Error Rows",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "correctRows",
            label: "Correct Rows",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "totalRows",
            label: "Total Rows",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "updatedRows",
            label: "Updated Rows",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "insertedRows",
            label: "Inserted Rows",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "createdAt",
            label: "Created At",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        // {
        //     id: "updatedAt",
        //     label: "Updated At",
        //     minWidth: 50,
        //     align: "center",
        //     format: (value) => value.toLocaleString("en-US"),
        // },
        {
            id: "uploadedBy",
            label: "Uploaded By",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "actions",
            label: "Actions",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
            authName: ["EXCEL_LOGS_VIDEO_LECTURE_DELETE"]
        },
    ];
    function createData(
        SNo,
        sheetName,
        UploadedByUser,
        productType,
        date,
        downloadOriginalSheet,
        downloadErrorSheet,
        errorRows,
        correctRows,
        totalRows,
        updatedRows,
        insertedRows,
        createdAt,
        updatedAt,
        uploadedBy,
        actions,

    ) {
        return {
            SNo,
            sheetName,
            UploadedByUser,
            productType,
            date,
            downloadOriginalSheet,
            downloadErrorSheet,
            errorRows,
            correctRows,
            totalRows,
            updatedRows,
            insertedRows,
            createdAt: moment(createdAt).format("DD-MM-YYYY hh:mm:ss A"),
            updatedAt,
            uploadedBy,
            actions,
        };
    }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [render, setRender] = useState(false);
    let [getid, setGetid] = useState("");
    const [data, setData] = useState("");
    const [addDialog, setAddDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [viewDialog, setViewDialog] = useState(false);

    const paginationData = {
        limit: rowsPerPage,
        query: "",
        params: ["sheet_name", "date_upload"],
        page: page + 1,
        filterBy: {
            fieldName: "product_type",
            value: "SLD_VIDEO_LECTURE"

        }
    };

    let rows = [];

    const allExcelLogs = getAllExcelLogsInfo.data;


    const totalItem = allExcelLogs ? allExcelLogs.totalItem : 0;

    useEffect(() => {
        getAllExcelLogs(paginationData);
    }, [render, rowsPerPage, page, filteredPagination]);

    useEffect(() => {
        getid && deleteExcelLog(getid) && setGetid("");
    }, [getid]);


    let totalData = "ok";

    if (allExcelLogs) {
        totalData = allExcelLogs.totalItem;
        rows = allExcelLogs.data.map((ele, index) =>
            createData(
                index + 1,
                ele.sheet_name,
                ele.sld_user_type,
                ele.product_type,
                ele.date_time_upload,
                <a href={ele.file_path}>Download (Original Sheet)</a>,
                <a href={ele.error_file_path}>Download (Error Sheet)</a>,
                ele.error_rows,
                ele.correct_rows,
                ele.total_rows,
                ele.updated_rows,
                ele.inserted_rows,
                ele.createdAt,
                ele.updatedAt,
                <div>
                    <h6> {ele.uploaded_by.length ? ele.uploaded_by[0].name : " "}</h6>
                    <div className="text-info">  {ele.uploaded_by.length ? ele.uploaded_by[0].email : " "}</div>
                </div>,
                <AuthWrapper type="ACTION" name="EXCEL_LOGS_VIDEO_LECTURE_DELETE">
                    <EditDelete type="delete" setGetid={setGetid} id={ele._id} />
                </AuthWrapper>

            )
        );
    }

    // );
    //   }
    const dialogclose = () => {
        setEditDialog(false);
        setViewDialog(false);
        setAddDialog(false);
        setRender(!render);
    };
    return (
        <div>
            {!viewDialog && (
                <AddDataHeader
                    addBtn={true}
                    isSearch={
                        !AuthWrapper({
                            type: "ACTION",
                            name: "EXCEL_LOGS_VIDEO_LECTURE_LIST",
                            inBoolean: true,
                        })
                    }
                    isPagination={
                        !AuthWrapper({
                            type: "ACTION",
                            name: "EXCEL_LOGS_VIDEO_LECTURE_LIST",
                            inBoolean: true,
                        })
                    }
                    setPage={setPage}
                    page={page}
                    totalItem={totalItem}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    searchBarApi={getAllExcelLogs}
                    paginationData={paginationData}
                    isButton={true}
                />
            )}




            {/* <Loader /> */}
            {/* ) : ( */}
            <AuthWrapper type="ACTION" name="EXCEL_LOGS_VIDEO_LECTURE_LIST">
                <>
                    {/* {!viewDialog ? ( */}
                    {/* {rows.length ? ( */}
                    <CreateTableAddButton
                        editDialog={editDialog}
                        setPage={setPage}
                        rows={rows}
                        columns={getFilterdColumns(columns)}
                        isButton={true}
                    />
                </>
            </AuthWrapper>
            {deleteExcelLogInfo.data && (
                <Toster info={deleteExcelLogInfo} dialogclose={dialogclose} />
            )}

        </div>
    );
}
