import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const incomeApi = createApi({
    tagTypes: ["income", "incomeStatement"],
    reducerPath: "incomeApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/` }),
    endpoints: (build) => ({
  
      getAllIncomeCategory: build.query({
        query() {
          return {
            url: `income-category/get-all`,
            headers: {
              "x-access-token": token,
            },
            method: "GET",
          };
        },
        providesTags: ["income"],
      }),
  
      getIncomeById: build.query({
        query({ body, id }) {
          return {
            url: `income-category/${id}`,
            headers: {
              "x-access-token": token,
            },
            method: "GET",
            body,
          };
        },
      }),
  
      updateIncomeCategory: build.mutation({
        invalidatesTags: ["income"],
        query({ body, id }) {
          return {
            url: `income-category/${id}`,
            headers: {
              "x-access-token": token,
            },
            method: "PUT",
            body,
          };
        },
      }),
      addIncomeCategory: build.mutation({
        invalidatesTags: ["income"],
        query(body) {
          return {
            url: `income-category/add-income-category`,
            headers: {
              "x-access-token": token,
            },
            method: "POST",
            body,
          };
        },
      }),
      deleteIncomeCategory: build.mutation({
        invalidatesTags: ["income"],
        query(id) {
          return {
            url: `income-category/${id}`,
            headers: {
              "x-access-token": token,
            },
            method: "DELETE",
          };
        },
      }),
  
      getAllIncomeDetails: build.mutation({
        query(body) {
          return {
            url: `income-register/get-all-incomes`,
            headers: {
              "x-access-token": token,
            },
            method: "POST",
            body ,
          };
        },
        providesTags: ["incomeStatement"],
      }),
  
      addIncomeDetails: build.mutation({
        invalidatesTags: ["incomeStatement"],
        query(body) {
          return {
            url: `income-register/add-income`,
            headers: {
              "x-access-token": token,
            },
            method: "POST",
            body,
          };
        },
      }),
  
      updateIncomeDetails: build.mutation({
        invalidatesTags: ["incomeStatement"],
        query({ body, id }) {
  
          return {
            url: `income-register/${id}`,
            headers: {
              "x-access-token": token,
            },
            method: "PUT",
            body,
          };
        },
      }),
  
      deleteIncomeDetails: build.mutation({
        invalidatesTags: ["incomeStatement"],
        query(id) {
          return {
            url: `income-register/${id}`,
            headers: {
              "x-access-token": token,
            },
            method: "DELETE",
          };
        },
      }),
  
      getSingleIncomeDetails: build.query({
        query(id) {
          return {
            url: `income-register/${id}`,
            headers: {
              "x-access-token": token,
            },
            method: "GET",
          };
        },
      }),
    }),
  });
  
  export const {
    useGetAllIncomeCategoryQuery,
    useGetIncomeByIdQuery,
    useAddIncomeCategoryMutation,
    useUpdateIncomeCategoryMutation,
    useDeleteIncomeCategoryMutation,
  
    useGetAllIncomeDetailsMutation,
    useAddIncomeDetailsMutation,
    useUpdateIncomeDetailsMutation , 
    useDeleteIncomeDetailsMutation,
    useGetSingleIncomeDetailsQuery,
  } = incomeApi;