import React, { Component, Fragment } from 'react'
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { Box, Button, Grid, Typography } from '@material-ui/core';

import navigation from "./_nav";

const drawerWidth = 300;
const useStyles = theme => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.6em',
            height: 10
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid #f3f3f3',
            borderRadius: "25px",
            height: 10
        }
    },
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: {
        ...theme.mixins.toolbar,
        paddingLeft: "15px",
        paddingRight: "15px",
        minHeight: "auto !important",
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        // padding: theme.spacing(3),
    },
    select: {
        '&:before': {
            borderColor: "none",
        },
        '&:after': {
            borderColor: "none",
        }
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    optionIcon: {
        fontSize: 16,
        color: "grap"
    },
    list: {
        maxHeight: "85vh",
        overflowY: "auto",
        color: "#666666",
    },
    listItem: {
        paddingLeft: "30px",
        width: "95%",
        borderRadius: "0px 100px 100px 0px",
        marginBottom: "10px"
    },
    parentListItem: {
        width: "95%",
        borderRadius: "0px 100px 100px 0px",
        marginBottom: "10px"
    },
    subchildListItem: {
        paddingLeft: "75px",
        color: "#666666",
        width: "95%",
        borderRadius: "0px 100px 100px 0px",
        marginBottom: "10px"
    },
    listItemIcon: {
        minWidth: "50px",
        display: "flex",
        justifyContent: "center",
        color: "#656464"
    },

});

class TheMobileSidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: this.props.open,
            anchorEl: null,
            optionOpen: null,
            userData: JSON.parse(localStorage.getItem('__authUserData__'))
        }

        this.handleDrawerClose = this.props.handleDrawerClose;
    }



    static getDerivedStateFromProps(props, state) {
        state.open = props.open;
        return state;
    }

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget })

    };

    handleClose = (event) => {
        this.setState({ anchorEl: null });
    };

    handleDrawer = () => {
        this.setState({ open: !this.state.open })
        this.handleDrawerClose();
    };


    handleCollapseClick = (index) => {
        let { optionOpen } = this.state
        if (optionOpen !== index) {
            this.setState({ optionOpen: index })
        } else {
            this.setState({ optionOpen: null })
        }

    }



    render() {
        const container = window !== undefined ? () => window.document.body : undefined;
        const { classes } = this.props;
        const { theme } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor={"left"}
                    open={this.state.open}
                    onClose={this.handleDrawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}

                >
                    <div className={classes.toolbar}>
                        <Grid container className="my-3">
                            <Grid item lg={10}>
                                <Avatar alt="User Name" src="/" />
                                <Typography variant="subtitle2" className="mt-0 text-capitalize" gutterBottom style={{ fontWeight: 600 }}>User Name</Typography>
                                <Typography variant="body2" className="mt-2" gutterBottom style={{ fontWeight: 500, color: "gray", fontSize: 12 }} >+91 - 1234567890</Typography>
                                <Typography variant="body2" className="mt-2" gutterBottom style={{ fontWeight: 500, color: "gray", fontSize: 12 }} >mail.google.com</Typography>
                            </Grid>
                            <Grid item lg={2}>
                                <Box display="flex" flexWrap="wrap" alignContent="space-around" style={{ height: "100%" }}>
                                    <Box>
                                        <IconButton onClick={this.handleDrawer}>
                                            {/* {theme.direction === 'rtl' ? <Close /> : <Close />} */}
                                        </IconButton>
                                    </Box>

                                    <Box flexShrink={1}>
                                        <ArrowDropDown aria-controls="simple-menu" style={{ fontWeight: 500, cursor: "pointer" }} className="mb-0" aria-haspopup="true" onClick={this.handleClick} />
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={this.state.anchorEl}
                                            keepMounted
                                            open={Boolean(this.state.anchorEl)}
                                            onClose={this.handleClose}
                                        >
                                            <MenuItem onClick={this.handleClose}>Profile</MenuItem>
                                            <MenuItem onClick={this.handleClose}>My account</MenuItem>
                                            <MenuItem onClick={this.handleClose}>Logout</MenuItem>
                                          
                                        </Menu>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                    <Divider />
                    <List className={classes.list}>
                        {
                            navigation.map((el, index) => {
                                return (
                                    <Fragment key={index}>

                                        <ListItem onClick={(e) => this.setState({ optionOpen: null })}>
                                            <Typography variant="caption" display="block" style={{ fontSize: "10px", fontWeight: 400, textTransform: "uppercase", letterSpacing: "1px" }}>{el.name}</Typography>
                                        </ListItem>


                                        {
                                            el._children.map((child, i) => {
                                                return (
                                                    <Fragment key={i}>
                                                        {child._children.length === 0 ?
                                                            <Link to={child.to} style={{ textDecoration: "none", color: "inherit" }} onClick={this.handleDrawer}>
                                                                <ListItem button className={classes.listItem} onClick={(e) => this.setState({ optionOpen: null })}>
                                                                    <ListItemIcon className={classes.listItemIcon}>
                                                                        <img src={child.icon} width={28} alt="" />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={<Typography style={{ fontSize: 14, fontWeight: 400 }}>{child.name}</Typography>} />
                                                                </ListItem>
                                                            </Link>
                                                            :
                                                            <>
                                                                <ListItem className={classes.parentListItem} button onClick={() => this.handleCollapseClick(i)}>

                                                                    {this.state.optionOpen === i ? <ExpandLess className={classes.optionIcon} /> : <ExpandMore className={classes.optionIcon} />}
                                                                    <ListItemIcon className={classes.listItemIcon}>
                                                                        <img src={child.icon} width={28} alt="" />
                                                                    </ListItemIcon>
                                                                    <ListItemText className="" primary={<Typography style={{ fontSize: 14, fontWeight: 400 }}>{child.name}</Typography>} />
                                                                </ListItem>
                                                                <Collapse in={this.state.optionOpen === i} timeout="auto" unmountOnExit>
                                                                    <List component="div" disablePadding>
                                                                        {child._children.map((subchild, subchildindex) => {
                                                                            return (
                                                                                <Link key={subchildindex} to={subchild.to} onClick={this.handleDrawer} style={{ textDecoration: "none", color: "inherit" }}>
                                                                                    <ListItem button className={classes.subchildListItem}>
                                                                                        <ListItemText primary={<Typography style={{ fontSize: 13, fontWeight: 400 }}>{subchild.name}</Typography>} />
                                                                                    </ListItem>
                                                                                </Link>

                                                                            )
                                                                        })}
                                                                    </List>
                                                                </Collapse>
                                                            </>

                                                        }

                                                    </Fragment>
                                                )
                                            })
                                        }
                                        <Divider />
                                    </Fragment>
                                )
                            })
                        }
                        <ListItem onClick={(e) => this.setState({ optionOpen: null })}>
                            <ListItemText primary={<Typography style={{ fontSize: 14, fontWeight: 600 }}>Need Help?</Typography>}></ListItemText>
                        </ListItem>
                        <ListItem onClick={(e) => this.setState({ optionOpen: null })}>
                            <ListItemText primary={<Typography style={{ fontSize: 14, fontWeight: 600 }}>Settings</Typography>}></ListItemText>
                        </ListItem>
                    </List>
                </Drawer>
            </ThemeProvider >
        )
    }
}

export default withStyles(useStyles, { withTheme: true })(TheMobileSidebar);
