import React, { } from 'react';
import { Grid } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import { Button, } from '@material-ui/core';

import { useFormik } from 'formik';
import * as yup from 'yup'


export default function EmailCallRequest({ editData, editDialog, UpdateData }) {
    const dialogclose = () => { editDialog(false) }
    const formik = useFormik({
        initialValues: {
            mobile: "1234567890",
            description: `Thank You ! For The Request We Will Contact You Soon. Contact Us : ( 8109777788 / 9179777788 ) www.smartlearningdestination.com `
           ,
        },
        validationSchema: yup.object({
            mobile: yup.string().required("Required !"),
            description: yup.string().required("Required !"),
        }),
        onSubmit: (values) => {
            UpdateData(values, editData._id)
        }
    })

    return (

        <form onSubmit={formik.handleSubmit}>
            <TextField
                name="mobile"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobile}
                fullWidth={true}
                label="Mobile Number "
                variant="outlined"
                size="small" />
            {formik.touched.mobile && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.mobile}</span>}

            <br /><br />

            <TextField
                multiline
                name="description"
                onChange={formik.handleChange}
                InputProps={{ inputProps: { min: 0 } }}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                fullWidth={true}
                label="Description "
                variant="outlined"
                size="large" />
            {formik.touched.description && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.description}</span>}

            <br /><br />


            <Grid container>
                <Grid xs={6}></Grid>
                <Grid className="d-flex justify-content-between" xs={6}>
                    <Button onClick={() => dialogclose(false)} variant="contained" color="secondary">
                        Close
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        Save
                    </Button>
                </Grid>
            </Grid>

        </form>

    )
}
