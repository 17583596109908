import React from 'react';
import CreateTable from './CreateTable';
import EditDelete from './EditDelete';


const columns = [    
    {
        id: 'Sno',
        label: 'S. No.',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'SMSfor',
        label: 'SMS For',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'SMStext',
        label: 'SMS Text',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'actions',
        label: 'Actions',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
];
function createData(Sno,SMSfor,SMStext,actions) {
    return {Sno,SMSfor,SMStext,actions};
}
const rows = [
    createData(1,"Call back Request Reply","Thanks 4 The Request We Will Contact U Soon or U Can Also Contact Us On 8109777788 / 9179777788 For Immediate support. www.smartlearningdestination.com", <EditDelete type="delete" />),
    

];


export default function DefinedSMS(props) {

    return (
        <CreateTable isButton={false}   rows={rows} columns={columns} />
    )
}
