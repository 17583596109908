import React from 'react'
import { Button, Grid, Box, makeStyles, withStyles, Typography } from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Paper, TableHead } from '@mui/material';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: 18,
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    table: {
        padding: 10,
        margin: 5
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

function ViewCoupons({ viewDialog, data }) {

      

    let allProduct = data.product.map((data) => {
        return data.product_title
    })

    let allFaculty = data.faculty.map((data) => {
        return data.faculty_name
    })
    const classes = useStyles();

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);
    return (

        <div className="d-flex justify-content-center my-3">

            <Button onClick={() => {
                viewDialog(false)
            }} style={{ position: "absolute", right: -10, zIndex: 1000, top: 75 }}>
                <HighlightOffIcon color='secondary' />
            </Button>

            <Grid container spacing={2} justifyContent="center" >
                <Grid item xs={10}>
                    <TableContainer className="m-2 border">
                        <Table className={classes.table} aria-label="customized table">
                            <TableBody>

                                {/* Coupon Code */}
                                <StyledTableRow>
                                    <StyledTableCell className="m-1 " align="left">
                                        <p className="d-flex align-items-center "> Coupon Code </p>
                                    </StyledTableCell>
                                    <StyledTableCell className="m-1" align="left">
                                        <p className="d-flex align-items-center text-muted">  {data.coupon_code} </p>
                                    </StyledTableCell>
                                </StyledTableRow>

                                {/* Coupon Type */}
                                <StyledTableRow>
                                    <StyledTableCell className="m-1 " align="left">
                                        <p className="d-flex align-items-center "> Coupon Type </p>
                                    </StyledTableCell>
                                    <StyledTableCell className="m-1" align="left">
                                        <p className="d-flex align-items-center text-muted">  {data.coupon_type} </p>
                                    </StyledTableCell>
                                </StyledTableRow>

                                {/* Coupon Applied */}
                                {data.coupon_type == "ORDER" && <StyledTableRow>
                                    <StyledTableCell className="m-1 " align="left">
                                        <p className="d-flex align-items-center "> Coupon Applied </p>
                                    </StyledTableCell>
                                    <StyledTableCell className="m-1" align="left">
                                        <p className="d-flex align-items-center text-muted">  {data.applied_on_type + ", "} </p>
                                    </StyledTableCell>
                                </StyledTableRow>}

                                {/* Max Count */}
                                <StyledTableRow>
                                    <StyledTableCell className="m-1 " align="left">
                                        <p className="d-flex align-items-center "> Max Count </p>
                                    </StyledTableCell>
                                    <StyledTableCell className="m-1" align="left">
                                        <p className="d-flex align-items-center text-muted">  {data.max_count} </p>
                                    </StyledTableCell>
                                </StyledTableRow>

                                {/* Applied Count */}
                                <StyledTableRow>
                                    <StyledTableCell className="m-1 " align="left">
                                        <p className="d-flex align-items-center "> Applied Count </p>
                                    </StyledTableCell>
                                    <StyledTableCell className="m-1" align="left">
                                        <p className="d-flex align-items-center text-muted">  {data.applied_count} </p>
                                    </StyledTableCell>
                                </StyledTableRow>

                                {
                                    data.coupon_type == "ORDER" &&
                                    <>
                                        {/* Discount Type */}
                                        < StyledTableRow >
                                            <StyledTableCell className="m-1 " align="left">
                                                <p className="d-flex align-items-center "> Discount Type </p>
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1" align="left">
                                                <p className="d-flex align-items-center text-muted">  {data.discount_type} </p>
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        {/* Discount Value */}
                                        <StyledTableRow>
                                            <StyledTableCell className="m-1 " align="left">
                                                <p className="d-flex align-items-center "> Discount Value </p>
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1" align="left">
                                                <p className="d-flex align-items-center text-primary">  {data.discount_value} </p>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </>

                                }


                                {/* User */}
                                <StyledTableRow>
                                    <StyledTableCell className="m-1 " align="left">
                                        <p className="d-flex align-items-center "> User </p>
                                    </StyledTableCell>
                                    <StyledTableCell className="m-1" style={{ height: "30px" }} align="left">

                                        <TableContainer  >
                                            <Table aria-label="simple table" size="small" >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className=' font-weight-bold ' align='center'  > Name </TableCell>
                                                        <TableCell className=' font-weight-bold ' align='center'  > Email </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {data.user.map((user, index) => (
                                                        <TableRow
                                                            key={index}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row" align='center' >
                                                                {user.first_name + " " + user.last_name}
                                                            </TableCell>
                                                            <TableCell align='center' >{user.email}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                    </StyledTableCell>
                                </StyledTableRow>

                                {/* asefsdjfsdjfsdf */}

                                {/* Starts from */}
                                <StyledTableRow>
                                    <StyledTableCell className="m-1 " align="left">
                                        <p className="d-flex align-items-center "> Starts From </p>
                                    </StyledTableCell>
                                    <StyledTableCell className="m-1" align="left">
                                        <p className="d-flex align-items-center text-muted">  {data.starts_from ? moment(data.starts_from).format("DD-MM-YYYY hh:mm:ss A") : ""} </p>
                                    </StyledTableCell>
                                </StyledTableRow>

                                {/* Expiry Time */}
                                <StyledTableRow>
                                    <StyledTableCell className="m-1 " align="left">
                                        <p className="d-flex align-items-center "> Expiry Time </p>
                                    </StyledTableCell>
                                    <StyledTableCell className="m-1" align="left">
                                        <p className="d-flex align-items-center text-muted">  {data.expiry_time ? moment(data.expiry_time).format("DD-MM-YYYY hh:mm:ss A") : ""} </p>
                                    </StyledTableCell>
                                </StyledTableRow>

                                {/* Generated By */}
                                <StyledTableRow>
                                    <StyledTableCell className="m-1 " align="left">
                                        <p className="d-flex align-items-center "> Generated By </p>
                                    </StyledTableCell>
                                    <StyledTableCell className="m-1" align="left">
                                        <p className="d-flex align-items-center text-muted">  {data.generated_by_sldUserType} </p>
                                    </StyledTableCell>
                                </StyledTableRow>

                                {/* Active */}
                                {/* <StyledTableRow>
                                    <StyledTableCell className="m-1 " align="left">
                                        <p className="d-flex align-items-center "> Active </p>
                                    </StyledTableCell>
                                    <StyledTableCell className="m-1" align="left">
                                        <p className={`d-flex align-items-center ${data.is_active ? "text-success" : "text-danger"} `}>  {data.is_active ? "Yes" : "No"} </p>
                                    </StyledTableCell>
                                </StyledTableRow> */}

                                {/* Product */}
                                {data.coupon_type == "PRODUCT" && <StyledTableRow>
                                    <StyledTableCell className="m-1 " align="left">
                                        <p className="d-flex align-items-center "> Product </p>
                                    </StyledTableCell>
                                    <StyledTableCell className="m-1" align="left">

                                        <Grid container spacing={3} >


                                            {
                                                data?.product?.map(ele => {
                                                    return (
                                                        <Grid item xs={4} key={ele.product_id} >
                                                            <Paper elevation={3} sx={{ p: 1 }}  >
                                                                <Typography style={{ borderBottom: "1px solid gray", paddingBottom: 4, color: 'rgb(57 155 231)', fontSize: '15px' }}  > {ele.product_title} </Typography>

                                                                <TableContainer style={{ marginTop: 5 }} >
                                                                    <Table aria-label="simple table" size="small" >
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell className=' font-weight-bold '   > Faculty Name </TableCell>
                                                                                <TableCell className=' font-weight-bold ' align='center'  > Discount </TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {ele?.faculty_data?.map((data, index) => (
                                                                                <TableRow
                                                                                    key={data?._id}
                                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                >
                                                                                    <TableCell component="th" scope="row"  >
                                                                                        {data?.faculty_name}
                                                                                    </TableCell>
                                                                                    <TableCell align='center' >
                                                                                        {
                                                                                            data?.discount_type === 'PERCENTAGE' ? (
                                                                                                <span> {data?.faculty_discount} %  </span>

                                                                                            )
                                                                                                :
                                                                                                (
                                                                                                    <span> &#8377; {data?.faculty_discount}   </span>
                                                                                                )
                                                                                        }
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Paper>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>

                                        {/* <TableContainer >
                                            <Table aria-label="simple table" size="small" >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className=' font-weight-bold ' align='center'  > Type </TableCell>
                                                        <TableCell className=' font-weight-bold ' align='center'  > Title </TableCell>
                                                        <TableCell className=' font-weight-bold ' align='center'  > Discount </TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {data.product.map((product, index) => (
                                                        <TableRow
                                                            key={index}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row" align='center' >
                                                                {product.product_type}
                                                            </TableCell>
                                                            <TableCell align='center' >{product.product_title}</TableCell>
                                                            <TableCell align='center' > {product.discount_type === "PERCENTAGE" ? `${product.discount_value} % ` : <span> &#8377; {product.discount_value} </span>}  </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer> */}

                                        {/* {data.product.map((product) => {
                                            return (<>
                                                <p className="d-flex align-items-center text-muted"><b className='mx-1'>Type : </b>   {product.product_type} </p>
                                                <p className="d-flex align-items-center text-muted"><b className='mx-1'>Title : </b>  {product.product_title} </p>
                                                <p className="d-flex align-items-center text-muted"><b className='mx-1'>Dicount : </b> {product.discount_value} </p>
                                                <br />
                                            </>
                                            )
                                        })} */}

                                    </StyledTableCell>
                                </StyledTableRow>}

                                {/* discount by */}
                                {data.coupon_type == "FACULTY" &&
                                    <StyledTableRow>
                                        <StyledTableCell className="m-1 " align="left">
                                            <p className="d-flex align-items-center "> Discount By </p>
                                        </StyledTableCell>
                                        <StyledTableCell className="m-1" align="left">
                                            <p className="d-flex align-items-center text-muted">{data.discountBy} </p>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                }

                                {/* Faculty */}
                                {data.coupon_type == "FACULTY" &&
                                    <StyledTableRow>
                                        <StyledTableCell className="m-1 " align="left">
                                            <p className="d-flex align-items-center "> Faculty </p>
                                        </StyledTableCell>
                                        <TableContainer >
                                            <Table aria-label="simple table" size="small" >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className=' font-weight-bold ' align='center'  > Faculty Name </TableCell>
                                                        <TableCell className=' font-weight-bold ' align='center'  > Discount </TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {data.faculty.map((faculty, index) => (
                                                        <TableRow
                                                            key={index}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align='center' >{faculty.faculty_name}</TableCell>
                                                            <TableCell align='center' > {faculty.discount_type === "PERCENTAGE" ? `${faculty.discount_value} % ` : <span> &#8377; {faculty.discount_value} </span>}  </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {/* <StyledTableCell className="m-1" align="left">
                                            {data.faculty.map((faculty) => {
                                                return (<>
                                                    <p className="d-flex align-items-center text-muted"><b className='mx-1'>Name : </b>   {faculty.faculty_name} </p>
                                                    <p className="d-flex align-items-center text-muted"><b className='mx-1'>Dicount : </b> {faculty.discount_value} </p>
                                                    <br />
                                                </>
                                                )
                                            })}

                                        </StyledTableCell> */}
                                    </StyledTableRow>}

                                {/* Min Order Amount */}
                                {data.coupon_type == "ORDER" &&
                                    <StyledTableRow>
                                        <StyledTableCell className="m-1 " align="left">
                                            <p className="d-flex align-items-center "> Min Order Amount </p>
                                        </StyledTableCell>
                                        <StyledTableCell className="m-1" align="left">
                                            <p className="d-flex align-items-center text-primary"> &#8377; {data.min_order_amount} </p>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                }


                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>



            </Grid>


        </div >






        // <div className='p-3'>

        //     <Box sx={{
        //         flexGrow: 1,
        //         bgcolor: 'background.paper',
        //         //  display: 'flex', 
        //         //  height: 554 
        //     }}>
        //         {/* <div>

        //             <h1>Name : {data.name} </h1>

        //         </div> */}

        //         <div class="card">
        //             <div class="card-body">
        //                 <Grid container >

        //                     <Grid xs={6} className=' my-1'>
        //                         <div style={{ width: 200 }}>
        //                             {/* <img src={data.image} alt="..." class="img-thumbnail" /> */}
        //                         </div>
        //                     </Grid>

        //                     <Grid xs={12} className=' my-1'>
        //                         <Button
        //                             onClick={() => viewDialog(false)}
        //                             className="text-light bg-danger float-right btn btn-sm btn-danger">
        //                             Close
        //                         </Button>

        //                     </Grid>

        //                     <Grid xs={6} className='my-2'>

        //                         <span className=' m-1 h5'>Coupon Code :</span>
        //                         <span className=' m-1 h5 font-weight-bold'>{data.coupon_code}</span>

        //                     </Grid>

        //                     <Grid xs={6} className='my-2'>

        //                         <span className=' m-1 h5'>Coupon Type :</span>
        //                         <span className=' m-1 h5 font-weight-bold'>{data.coupon_type}</span>

        //                     </Grid>

        //                     <Grid xs={6} className='my-2'>

        //                         <span className=' m-1 h5'>Discount Type :</span>
        //                         <span className=' m-1 h5 font-weight-bold'>{data.discount_type}</span>

        //                     </Grid>

        //                     <Grid xs={6} className='my-2'>

        //                         <span className=' m-1 h5'>Discount Value :</span>
        //                         <span className=' m-1 h5 font-weight-bold'>{data.discount_value}</span>

        //                     </Grid>

        //                     <Grid xs={6} className='my-2'>

        //                         <span className=' m-1 h5'>Apply On :</span>
        //                         <span className=' m-1 h5 font-weight-bold'>{data.applied_on_type}</span>

        //                     </Grid>

        //                     <Grid xs={6} className='my-2'>

        //                         <span className=' m-1 h5'>Start From :</span>
        //                         <span className=' m-1 h5 font-weight-bold'>{data.starts_from}</span>

        //                     </Grid>

        //                     <Grid xs={6} className='my-2'>

        //                         <span className=' m-1 h5'>Expire Time :</span>
        //                         <span className=' m-1 h5 font-weight-bold'>{data.expiry_time}</span>

        //                     </Grid>

        //                     <Grid xs={6} className='my-2'>

        //                         <span className=' m-1 h5'>Faculty :</span>
        //                         <span className=' m-1 h5 font-weight-bold'>{allFaculty + " " +" , "}</span>

        //                     </Grid>

        //                     <Grid xs={6} className='my-2'>

        //                         <span className=' m-1 h5'>Coupon Code :</span>
        //                         <span className=' m-1 h5 font-weight-bold'>{data.coupon_code}</span>

        //                     </Grid>

        //                     <Grid xs={6} className='my-2'>

        //                         <span className=' m-1 h5'>Max Count :</span>
        //                         <span className=' m-1 h5 font-weight-bold'>{data.max_count}</span>

        //                     </Grid>


        //                     <Grid xs={6} className='my-2'>

        //                         <span className=' m-1 h5'>Min Order Ammount :</span>
        //                         <span className=' m-1 h5 font-weight-bold'>{data.min_order_amount}</span>

        //                     </Grid>

        //                     <Grid xs={6} className='my-2'>

        //                         <span className=' m-1 h5'>Product :</span>
        //                         <span className=' m-1 h5 font-weight-bold'>{allProduct+" , " }</span>

        //                     </Grid>


        //                     <Grid xs={6} className='my-2'>

        //                         <span className=' m-1 h5'>Active :</span>
        //                         <span className=' m-1 h5 font-weight-bold'>{data.is_active ? "Yes" : "No"}</span>


        //                     </Grid>


        //                     <Grid xs={6} className='my-2'>

        //                         <span className=' m-1 h5'>Create At :</span>
        //                         <span className=' m-1 h5 font-weight-bold'>{data.createdAt}</span>


        //                     </Grid>

        //                 </Grid>

        //             </div>
        //         </div>
        //     </Box>

        // </div>
    )
}

export default ViewCoupons
