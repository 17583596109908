import React, { useState } from 'react';
import {
    Dialog, DialogContent,
    DialogTitle, Zoom, Typography,
    Card, CardContent, Grow, Paper,
} from '@material-ui/core'
import { Button, } from '@material-ui/core';
import UpdateInQuiry from './UpdateInquiry';


export default function ViewCallbackRequest({ editData, viewDialog, UpdateData }) {
    const [scroll, setScroll] = React.useState('paper');
    const [isForm, setForm] = useState(false)
    return (
        <Dialog
            open={true}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth="lg"
            TransitionComponent={Zoom}

        >
            <DialogTitle id="scroll-dialog-title">
                <Button variant="outlined" color="primary" onClick={() => setForm(true)}>Add</Button>
            </DialogTitle>
            <DialogContent dividers={scroll === 'paper'} style={{ minWidth: 600, minHeight: 300 }}>

                <Grow
                    in={isForm}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(isForm ? { timeout: 1000 } : {})}
                >
                    <Paper sx={{ m: 1 }} elevation={4}>
                        {isForm &&
                            <UpdateInQuiry isCencel={true} editData={editData} UpdateData={UpdateData} />
                        }
                    </Paper>
                </Grow>

                {
                    editData.follow_up.map((data) => {
                          
                        return (
                            <div className="bg-dark  cardShadow mb-5" style={{ minWidth: 600 }}>
                                <Card sx={{ minWidth: 475 }} className="p-1" >
                                    <CardContent style={{ maxWidth: 800, minWidth: 600 }} className="py-0">
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            <h6 className="text-primary">Description</h6>
                                            <p style={{ fontSize: 14 }}>{data.discription}</p>
                                        </Typography>
                                        <Typography variant="h5" component="div">
                                            <h6 className="text-primary">Call Back Time</h6>
                                            <p style={{ fontSize: 14 }}>{data.createat} </p>
                                        </Typography>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            <h6 className="text-primary">Follow up Time :</h6>
                                            <p style={{ fontSize: 14 }}> 2019-01-24 00:01:22</p>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                    })
                }

            </DialogContent>
            <div className="text-right p-2">
                <Button color='secondary' variant="contained" onClick={() => viewDialog(false)} >Close</Button>
            </div>
        </Dialog>
    )
}
