import React, { useState, useEffect } from "react";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import * as yup from 'yup'
import { useFormik } from 'formik';
import {
  Grid,
  Button,
  Card,
  Box,
  CardContent,
  Dialog, Zoom, DialogTitle, DialogContent, TextField,
  IconButton
} from "@material-ui/core";
import {
  useUpdateFacultyMutation,
  useGetSingleFacultyMutation
} from '../../../../services/services'
import DeleteIcon from '@material-ui/icons/Delete';
import DataNotFound from "../DataNotFound";


export default function Remarks({ viewDialog, data, setFacultyData }) {

  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "remark",
      label: "Remark",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "date",
      label: "Date",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];
  function createData(SNo, remark, date, actions) {
    return {
      SNo, remark, date, actions
    };
  }
  const [render, setRender] = useState(false);

  let rows = [];
  const [updateData, updateDataInfo] = useUpdateFacultyMutation();
  const [singleFacultyData, singleFacultyDataInfo] = useGetSingleFacultyMutation();
  const [addDialog, setAddDialog] = useState(false);

  if (typeof data.remark == 'object') {
    data.remark.map((ele, ind) => {
      rows.push(
        createData(
          ind + 1,
          ele.remark,
          ele.date,
          <div>
            <IconButton onClick={() => { deleteRemark(ele._id) }} color="secondary"><DeleteIcon /></IconButton>
          </div>
        )
      );
    })
  }


  useEffect(() => {
    singleFacultyData(data._id)
  }, [updateDataInfo])

  const deleteRemark = (remarkId) => {
    var form_data = new FormData()
    form_data.append('key', 'remove_remark')
    form_data.append('update_data', JSON.stringify({ remark_id: remarkId }))
    updateData({ data: form_data, id: data._id })
  }

  const dialogclose = () => {
    setAddDialog(false);
    setRender(!render);
  };

  return (
    <>
      <Card sx={{ display: "flex" }} className="m-3" >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
         {singleFacultyDataInfo.isSuccess == true && singleFacultyDataInfo.data.data.length ?
            singleFacultyDataInfo.isLoading == false ?
              <div>
                {setFacultyData(singleFacultyDataInfo.data.data[0])}
                <Card sx={{ display: "flex" }} className="m-3" >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <Button
                        onClick={() => {
                          setAddDialog(true);
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Add Remark
                      </Button>
                      <Button
                        onClick={() => viewDialog(false)}
                        className="text-light bg-danger float-right btn btn-sm btn-danger"
                      >
                        Close
                      </Button>
                    </CardContent>
                  </Box>
                </Card>

                {
                  rows.length ?
                    <CreateTableAddButton
                      editDialog={null}
                      setPage={null}
                      rows={rows}
                      editform={null}
                      columns={columns}
                      isButton={true}
                      editFormName={null}
                    /> :
                    <Grid xs={12} className="my-1 text-center bg-light p-3" >NO DATA FOUND</Grid>
                }


              </div>
              :
              <SkeletonListing />
            :
            <Grid container justifyContent="center">
              <DataNotFound />
            </Grid>

          }
          {addDialog ? <AddRemark addDialog={addDialog} setAddDialog={setAddDialog} updateData={updateData} facultyId={data._id} /> : null}
          {updateDataInfo.data && (
            <Toster info={updateDataInfo} dialogclose={dialogclose} />
          )}

        </Box>
      </Card>
    </>

  );
}

function AddRemark({ addDialog, setAddDialog, updateData, facultyId }) {

  const formik = useFormik({

    initialValues: {
      remark: ""
    },
    validationSchema: yup.object({
      remark: yup.string().required("Required!")
    }),
    onSubmit: (values, e) => {
      var form_data = new FormData()
      form_data.append('key', 'add_remark')
      form_data.append('update_data', JSON.stringify(values))
      updateData({ data: form_data, id: facultyId })
    },
  })

  return (
    <Dialog
      fullScreen={false}
      open={addDialog}
      //scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      // maxWidth="lg"
      fullWidth={true}
      TransitionComponent={Zoom}
    >
      <DialogTitle id="scroll-dialog-title">Add Details</DialogTitle>
      <DialogContent
      //dividers={scroll === "paper"}
      >
        <form id="addfacultyform" onSubmit={formik.handleSubmit}>
          <Grid style={{ paddingLeft: 10, paddingRight: 10, marginTop: 20, marginLeft: 10 }} >
            <TextField
              name="remark"
              label="Faculty remark"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.remark}
              fullWidth={true}
              variant="outlined"
              size="medium" />
            {formik.touched.remark && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.remark}</span>}
            <br />
            <br />
            <Grid container spacing={3} >
              <Grid item xs={12}>
                <div className="text-right">
                  <Button
                    onClick={() => setAddDialog(false)}
                    variant="contained"
                    color="secondary">
                    Close</Button>
                  <Button
                    type="submit"
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    color="primary">
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid >
        </form >
      </DialogContent>
    </Dialog>
  )

}
