import React from 'react'
import {
    TextField,
    Grid,
    FormGroup,
    FormControlLabel,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    Zoom,
    Button,
    Checkbox
} from '@material-ui/core'
import { useFormik } from 'formik';
import * as yup from 'yup'
import { useGetAllModuleNameQuery } from '../../../services/services';
import { toast } from 'react-toastify'
import { useGetUserKeyAcessQuery } from '../../../services/callerPerformanceServices';

export default function KeyUserAccess(props) {
  
    const [scroll, setScroll] = React.useState('paper');
    const getAllModule = useGetUserKeyAcessQuery()
    const allModules = getAllModule.data
    const formik = useFormik({
        initialValues: {
            
            product_keys_access: props?.data?.product_keys_access ?props?.data?.product_keys_access : []
        },
    
        onSubmit: (values) => {
           let dataValues=[]
            allModules?.data?.allAccessKeysAdmin?.map((data) => {
                if(values.product_keys_access.includes(data[Object.keys(data)[0]][0]))
                {
                    dataValues.push(data[Object.keys(data)[0]])

                } 
            })
            values.product_keys_access=dataValues.flat()
           
            if (!formik.values.product_keys_access.length) {
                toast("Please select any module.")
                return
            }
            props.UpdateData(values, props.data._id)

        }
    })

    const checkAll = () => {
        let dataValues=[]
        allModules?.data?.allAccessKeysAdmin?.map((data) => {
                dataValues.push(data[Object.keys(data)[0]][0])
            
        })
        let modules=dataValues
        formik.setFieldValue("product_keys_access", modules)
    }

    const dialogclose = () => {
        editDialog(false)
    }
    return (
        <>

            <Dialog
                fullScreen={true}
                open={true}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth={true}
                TransitionComponent={Zoom}

            >
                <DialogTitle id="scroll-dialog-title">
                    <Grid container>
                        <Grid item xs={6}>
                        key Access of VideoLectures
                        </Grid>

                        <Grid item xs={6}>
                            <Button
                                onClick={() => props.setKeyAccess(false)}
                                className="text-light bg-danger float-right btn btn-sm btn-danger">
                                Close
                            </Button>
                        </Grid>
                    </Grid>

                </DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>

                    <form onSubmit={formik.handleSubmit}>
                        <Box sx={{ p: 2 }} >
                            <Grid xs={12} className="border-bottom border-secondary pb-3 mb-2" >
                                <TextField
                                    name="group_name"
                                    onChange={()=>{}}
                                   
                                    value={props?.data?.group_name}
                                    label="user group name"
                                    variant="outlined"
                                    size="small" />
                                {/* {formik.touched.group_name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.group_name}</span>} */}
                            </Grid>
                            <Grid xs={12} className="my-2" >
                                <Button size='small' color='primary' variant='contained' onClick={() => { checkAll() }} >
                                    check all
                                </Button>
                            </Grid>
                            <FormGroup>
                                <Grid container >
                                    {allModules &&
                                        allModules?.data?.allAccessKeysAdmin.map((data) => {
                                            return (
                                                <Grid xs={3} >
                                                    <FormControlLabel
                                                        key={data._id}
                                                        name="product_keys_access"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.product_keys_access}

                                                        control={<Checkbox checked={formik.values.product_keys_access.includes(data[Object.keys(data)[0]][0])} value={data[Object.keys(data)[0]][0]} />}

                                                        label={Object.keys(data)[0]}
                                                    />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </FormGroup>
                            <div className="d-flex justify-content-end">
                                {/* <Button
                        onClick={dialogclose}
                        variant="contained"
                        color="secondary"
                                >Close</Button>  */}
                                <Button
                                    type="submit"
                                    style={{ marginLeft: 20 }}
                                    variant="contained"
                                    color="primary"
                                > Save</Button>
                            </div>
                        </Box>
                    </form>

                </DialogContent>
            </Dialog>


        </>
    )
}
