import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const studentRequestApi = createApi({
  tagTypes: ["cashback", "refund", "gst", "Cancel-order"],
  reducerPath: "studentRequestApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/order/`,
  }),
  endpoints: (builder) => ({
    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    GetCashbackRequests: builder.mutation({
      providesTags: ["cashback"],
      query: (body) => {
        return {
          url: "all-cashback-request",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    GetRefundRequests: builder.mutation({
      providesTags: ["refund"],
      query: (body) => {
        return {
          url: "all-refund-request",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    GetCancelOrderRequests: builder.mutation({
      providesTags: ["Cancel-order"],
      query: (body) => {
        return {
          url: "all-cancel-request",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    GetGstRequests: builder.mutation({
      providesTags: ["gst"],
      query: (body) => {
        return {
          url: "all-gst",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< UPDATE STATUS  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    UpdateRequestStatus: builder.mutation({
      invalidatesTags: ["cashback", "refund"],
      query: ({ body, id }) => {
        return {
          url: `initiate-refund-cashback/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),

    initiateRefund: builder.mutation({
      invalidatesTags: ["cashback", "refund"],
      query: ({ body, id }) => {
        return {
          url: `initiate-refund/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),

    ApproveRequest: builder.mutation({
      invalidatesTags: ["cashback", "refund", "gst"],
      query: ({ body, id }) => {
        return {
          url: `approve-refund-cashback-gst/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),

    DisapproveRequest: builder.mutation({
      invalidatesTags: ["cashback", "refund", "gst"],
      query: ({ body, id }) => {
        return {
          url: `disapprove-refund-cashback-gst/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),

    ApproveCancelOrderRequest: builder.mutation({
      invalidatesTags: ["Cancel-order"],
      query: ({ body, id }) => {
        return {
          url: `approve-cancel-request/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),

    UpdateGSTRequestDetails: builder.mutation({
      invalidatesTags: ["gst"],
      query: ({ body, id }) => {
        return {
          url: `gst-details-update/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    })

  }),
});

export const {
  useGetGstRequestsMutation,
  useGetCashbackRequestsMutation,
  useGetRefundRequestsMutation,
  useGetCancelOrderRequestsMutation,
  useUpdateRequestStatusMutation,
  useApproveRequestMutation,
  useDisapproveRequestMutation,
  useInitiateRefundMutation,
  useApproveCancelOrderRequestMutation,
  useUpdateGSTRequestDetailsMutation
} = studentRequestApi;

