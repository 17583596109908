import { Avatar, Button, Dialog, DialogContent, Divider, Grid, Typography } from '@material-ui/core'
import LanguageIcon from '@material-ui/icons/Language';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import React from 'react'
import { useGetConfigQuery } from "../../../../services/offlineModuleServices/configServices";
import SkeletonListing from '../SkeletonListing';
import moment from 'moment';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";

// **** Import Logo
import sldLogo from '../sldlogo.png'

// **** Use Styles
const useStyles = makeStyles({
    table: {
        minWidth: 650,
        "& .MuiTableCell-root": {
            borderLeft: "1px solid black",
            border: "1px solid black"

        },
        "& .MuiTable-root": {
            border: "1px solid black"

        }
    }
});

function OfflineOrderInvoice(props) {

    const classes = useStyles();

    // **** Destructuring Props
    const { data, receiptFor } = props
    const { data: displayData } = data

    const { data: AllTermsAndConditions, isAllTermsAndConditionsFetching, isAllTermsAndConditionsLoading } = useGetConfigQuery({
        key: "instructions_to_follow",
        limit: "",
        page: "",
    })

    return (
        <div>
            {isAllTermsAndConditionsFetching || isAllTermsAndConditionsLoading ?
                <SkeletonListing />
                :
                <Dialog open={true} fullScreen >
                    <DialogContent>
                        <Grid container className='px-3' justifyContent='center' style={{ minHeight: "100vh" }} >
                            <Grid item xs={12}>
                                <Grid container >
                                    <Button> Back </Button>
                                </Grid>

                                {/* Header */}
                                <Grid container spacing={8}>
                                    <Grid item xs={3}>
                                        <img src={sldLogo} alt="Logo" height="100%" width="100%" />
                                    </Grid>

                                    <Grid item xs={5}   >
                                        <Typography variant='h4' > SMART LEARNING DESTINATION </Typography>
                                        <Grid container  >
                                            <Grid item xs={12}>
                                                <Typography variant='caption' component="div" > <LanguageIcon fontSize='small' /> https://www.smartlearningdestination.com/ </Typography>
                                                <Typography variant='caption' component="div"> <LocationOnIcon fontSize='small' /> 114 (C) 1st Floor Shreevardhan Complex, Near Lemon Tree Hotel </Typography>
                                                <Typography className='text-wrap' variant='caption' component="div" > <CallIcon fontSize='small' /> <span  > FOR PURCHASE INQUIRY- 7697044955 / 7277278811, FOR SUPPORT- 8602133776, FOR COMPLAINT- 8871374677 ( Drop Only Wapp ) </span> </Typography>
                                                <Typography variant='caption' component="div" > <EmailIcon fontSize='small' /> OFFLINESLD@GMAIL.COM </Typography>
                                                <Typography variant='caption' component="div" > <AccessTimeIcon fontSize='small' /> Office Timing- 10:00 AM - 08:00 PM </Typography>
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                </Grid>

                                {/* Divider */}
                                <Grid className='mt-3'>
                                    <Divider className='bg-dark' />
                                </Grid>

                                <Grid container className='mt-5' >

                                    {/* Invoice Details */}
                                    <Grid item xs={12} className="" >
                                        <Table size="small" sx={{ border: "1px solid black" }} >
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: "1px solid black", bgcolor: "#d7d7d7" }} align='center'> <Typography> Invoice Details </Typography> </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <Grid container>
                                                        <Grid item xs={4}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%", }} >  Invoice No. : </TableCell>
                                                                        <TableCell align="left"  > {displayData.invoice_no} </TableCell>
                                                                    </TableRow>


                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item xs={4}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%", }}  > Order Type :  </TableCell>
                                                                        <TableCell align="left" > {displayData.order_type == "VIRTUAL_CLASS" ? "Virtual Class" : "Semi-Virtual Class"} </TableCell>
                                                                    </TableRow>

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item xs={4}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%", }} > Order Date :  </TableCell>
                                                                        <TableCell align="left" >  {moment(displayData.order_date).format("DD MMM yyyy")} at {moment(displayData.order_date).format("hh:mm A")} </TableCell>
                                                                    </TableRow>

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>



                                                    </Grid>


                                                </TableRow>
                                            </TableBody>
                                        </Table>

                                    </Grid>

                                    {/* Student Information */}
                                    <Grid item xs={12} className="mt-5" >
                                        <Table size="small" sx={{ border: "1px solid black" }} >
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: "1px solid black", bgcolor: "#d7d7d7" }} align='center'   > <Typography> Student Information </Typography> </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <Grid container>
                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%", borderBottom: { lg: "0px", xs: "1px solid black" } }} >  Name : </TableCell>
                                                                        <TableCell sx={{ borderBottom: { lg: "0px", xs: "1px solid black" } }} align="left"  > {displayData.student_name} </TableCell>
                                                                    </TableRow>


                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%", borderBottom: { lg: "0px", xs: "1px solid black" } }}  > Email :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: { lg: "0px", xs: "1px solid black" } }} align="left" > {displayData.email} </TableCell>
                                                                    </TableRow>

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%" }} > Mob No. :  </TableCell>
                                                                        <TableCell align="left" > {displayData.mobile_number} </TableCell>
                                                                    </TableRow>

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%" }} > CRO No. :  </TableCell>
                                                                        <TableCell align="left" > {displayData.cro_no || "N/A"} </TableCell>
                                                                    </TableRow>

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                    </Grid>


                                                </TableRow>
                                            </TableBody>
                                        </Table>

                                    </Grid>

                                    {/* Order Details */}
                                    <Grid item xs={12} className="mt-5" >
                                        <Table size="small" sx={{ border: "1px solid black" }} >
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: "1px solid black", bgcolor: "#d7d7d7" }} align='center'   > <Typography> Order Details </Typography> </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <Grid container>
                                                        <Grid item lg={3} xs={6} >
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%", borderBottom: "1px solid black" }} >  Course : </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left"  > {displayData.course.course_name} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%", borderBottom: "1px solid black" }}  > Level :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.level.level_name} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%", borderBottom: "1px solid black" }} > Due Attempt :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.attempt_due} </TableCell>
                                                                    </TableRow>

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%", borderBottom: "1px solid black" }} > Batch Type :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.category[0].category_name} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>


                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%", borderBottom: "1px solid black" }} > Batch Name :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.batch[0].batch_name} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>


                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%", borderBottom: "1px solid black" }} > Batch Time :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.batch[0].batch_time} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%", borderBottom: "1px solid black" }} > Batch Time :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.batch[0].batch_time} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%", borderBottom: "1px solid black" }} > Mode Name :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.mode_name || "N/A"} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%", borderBottom: "1px solid black" }} > Book Issued :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.book_issued || "N/A"} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%", borderBottom: "1px solid black" }} > Hall :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.hall_number || "N/A"} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%", borderBottom: "1px solid black" }} > Combo :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.combo == "yes" ? "Yes" : "No"} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item lg={3} xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%", borderBottom: "1px solid black" }} > Combo With :  </TableCell>
                                                                        <TableCell sx={{ borderBottom: "1px solid black" }} align="left" > {displayData.batch[1].batch_name} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "15%" }} > Subjects :  </TableCell>
                                                                        <TableCell align="left" > {displayData.subject.map(ele => ele.subject_name).join(", ")} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >
                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "15%" }} > Faculties:  </TableCell>
                                                                        <TableCell align="left" > {displayData.faculty.map(ele => ele.faculty_name).join(", ")} </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                    </Grid>


                                                </TableRow>
                                            </TableBody>
                                        </Table>

                                    </Grid>

                                    {/* Fees & Payments */}
                                    <Grid item xs={12} className="mt-5" >
                                        <Table size="small" sx={{ border: "1px solid black" }} >
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: "1px solid black", bgcolor: "#d7d7d7" }} align='center'   > <Typography> Fees & Payments </Typography> </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <Grid container>
                                                        <Grid item xs={4}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%" }} >  Total Fees : </TableCell>
                                                                        <TableCell align="left"  > &#8377; {displayData.amount_after_discount} </TableCell>
                                                                    </TableRow>


                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item xs={4}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%" }}  > Fees Paid :  </TableCell>
                                                                        <TableCell align="left" > &#8377; {displayData.fee_paid} </TableCell>
                                                                    </TableRow>

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item xs={4}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%" }} > Due Amount :  </TableCell>
                                                                        <TableCell align="left" > &#8377; {displayData.due_amount} </TableCell>
                                                                    </TableRow>

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>



                                                    </Grid>


                                                </TableRow>
                                            </TableBody>
                                        </Table>

                                    </Grid>

                                    {/* Other Details */}
                                    <Grid item xs={12} className="mt-5" >
                                        <Table size="small" sx={{ border: "1px solid black" }} >
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: "1px solid black", bgcolor: "#d7d7d7" }} align='center'   > <Typography> Other Details </Typography> </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%" }} >  Center : </TableCell>
                                                                        <TableCell align="left"  >  {displayData.center_name} </TableCell>
                                                                    </TableRow>


                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Table size="small" sx={{ borderRight: "1px solid black" }} >

                                                                <TableBody>
                                                                    <TableRow >
                                                                        <TableCell sx={{ width: "30%" }}  > Sold By :  </TableCell>
                                                                        <TableCell align="left" >  {displayData.sold_by_name} </TableCell>
                                                                    </TableRow>

                                                                </TableBody>
                                                            </Table>
                                                        </Grid>

                                                    </Grid>


                                                </TableRow>
                                            </TableBody>
                                        </Table>

                                    </Grid>

                                    {/* Also Available on SLD */}
                                    <Grid item xs={12} className="mt-5" >
                                        <Table size="small" sx={{ border: "1px solid black" }} >
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: "1px solid black", bgcolor: "#d7d7d7" }} align='center'   > <Typography> Also Available On SLD </Typography> </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>

                                        <Grid container spacing={3} className="mt-3">
                                            {
                                                [1, 2, 3, 4, 5, 6].map(ele => (
                                                    <Grid item lg={2} md={3} sm={4} xs={6}>
                                                        <CreateFacultyImage image={""} name="Himanshu" />
                                                    </Grid>

                                                ))
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent >
                </Dialog >



            }


        </div >
    )
}

export default OfflineOrderInvoice;




const CreateFacultyImage = ({ image, name }) => {
    return (
        <>
            <Grid container >
                <Grid item xs={12}>
                    <Avatar variant='rounded' src={image} style={{ width: "100%" }} />
                </Grid>
                <Grid item xs={12} >
                    <Typography className='text-center' > {name} </Typography>
                </Grid>

            </Grid>
        </>
    )
}


