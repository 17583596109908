import React, { Component, Fragment } from "react";
import { withStyles, ThemeProvider } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
// import clsx from 'clsx';
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
// import Close from '@material-ui/icons/Close';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Hidden from "@material-ui/core/Hidden";

import { Button, Grid, TextField, Typography } from "@material-ui/core";

import navigation from "./_nav";
import TheMobileSidebar from "./TheMobileSidebar";
import { drawerWidth } from "../constant/layout";
import AuthWrapper from "../utils/AuthWrapper";

const useStyles = (theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.6em",
      height: 10,
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid #f3f3f3",
      borderRadius: "25px",
      height: 10,
    },
  },
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
    minHeight: "auto !important",
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
  },
  select: {
    "&:before": {
      borderColor: "none",
    },
    "&:after": {
      borderColor: "none",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  optionIcon: {
    fontSize: 16,
    color: "grap",
  },
  list: {
    maxHeight: "97vh",
    overflowY: "auto",
    color: "#666666",
  },
  listItem: {
    paddingLeft: "30px",
    width: "95%",
    borderRadius: "0px 100px 100px 0px",
    marginBottom: "10px",
  },
  parentListItem: {
    width: "95%",
    borderRadius: "0px 100px 100px 0px",
    marginBottom: "10px",
  },
  subchildListItem: {
    paddingLeft: "75px",
    color: "#666666",
    width: "95%",
    borderRadius: "0px 100px 100px 0px",
    marginBottom: "10px",
  },
  listItemIcon: {
    minWidth: "20px",
    display: "flex",
    justifyContent: "space-between",
    color: "#656464",
    marginRight: "10px",
  },
});

class TheSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      anchorEl: null,
      optionOpen: null,
      modulesForAccess: localStorage.getItem("modules")
        ? JSON.parse(localStorage.getItem("modules"))
        : [],
      authType: localStorage.getItem("authType"),
      searchTerm: "",
    };

    this.handleDrawerClose = this.props.handleDrawerClose;
  }

  static getDerivedStateFromProps(props, state) {
    state.open = props.open;
    return state;
  }

  handleClick = (event) => {
    alert("handleClickis");
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (event) => {
    this.setState({ anchorEl: null });
  };

  handleDrawer = () => {
    this.setState({ open: false });
    this.handleDrawerClose();
  };

  handleCollapseClick = (index) => {
    let { optionOpen } = this.state;
    if (optionOpen !== index) {
      this.setState({ optionOpen: index });
    } else {
      this.handleDrawer();
      this.setState({ optionOpen: null });
    }
  };

  render() {
    const { classes } = this.props;
    const { theme } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden mdUp implementation="css">
            <TheMobileSidebar
              theme={this.props.theme}
              classes={this.props.classes}
              open={this.state.open}
              handleDrawerClose={this.handleDrawer}
            />
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <Divider />
              <Grid className="p-1 d-flex">
                <TextField
                  value={this.state.searchTerm}
                  onChange={(e) => {
                    this.setState({ searchTerm: e.target.value });
                  }}
                  placeholder={"SEARCH"}
                  style={{ padding: "5px" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon variant="filed" color="info" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <List className={classes.list}>
                {navigation?.map((el, index) => {
                  return (
                    <AuthWrapper type="MODULE" name={el?._children?.map((childs)=> childs?.authModuleName)?.flat()} >
                      <Fragment key={index}>
                        <ListItem
                          onClick={(e) => this.setState({ optionOpen: null })}
                        >
                          <Typography
                            variant="caption"
                            display="block"
                            style={{
                              fontSize: "10px",
                              fontWeight: 400,
                              textTransform: "uppercase",
                              letterSpacing: "1px",
                            }}
                          >
                            {el.name}
                          </Typography>
                        </ListItem>
                        {el._children?.map((child, i) => {
                          return (
                            child.name
                              .toLowerCase()
                              .includes(
                                this.state.searchTerm.toLowerCase()
                              ) && (
                              <AuthWrapper
                                type="MODULE"
                                name={child?.authModuleName}
                              >
                                <Fragment key={i}>
                                  {child._children.length === 0 ? (
                                    <Link
                                      to={child.to}
                                      style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                      }}
                                    >
                                      <ListItem
                                        button
                                        className={classes.listItem}
                                        onClick={(e) => {
                                          this.setState({ optionOpen: null });
                                        }}
                                        style={
                                          child.to == window.location.pathname
                                            ? { backgroundColor: "#8080805e" }
                                            : null
                                        }
                                      >
                                        <Grid style={{ fontSize: 10 }}>
                                          {child.icon}
                                        </Grid>
                                        <ListItemText
                                          primary={
                                            <Typography
                                              style={{
                                                fontSize: 14,
                                                fontWeight: 400,
                                                marginLeft: 5,
                                              }}
                                            >
                                              {child.name}
                                            </Typography>
                                          }
                                        />
                                      </ListItem>
                                    </Link>
                                  ) : (
                                    <>
                                      <ListItem
                                        className={classes.parentListItem}
                                        button
                                        onClick={() =>
                                          this.handleCollapseClick(i)
                                        }
                                      >
                                        {this.state.optionOpen === i ? (
                                          <ExpandLess
                                            className={classes.optionIcon}
                                          />
                                        ) : (
                                          <ExpandMore
                                            className={classes.optionIcon}
                                          />
                                        )}
                                        <ListItemIcon
                                          className={classes.listItemIcon}
                                        >
                                          {/* <img src={child.icon} width={28} alt="" /> */}
                                          {child.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                          className=""
                                          primary={
                                            <Typography
                                              style={{
                                                fontSize: 14,
                                                fontWeight: 400,
                                              }}
                                            >
                                              {child.name}
                                            </Typography>
                                          }
                                        />
                                      </ListItem>

                                      <Collapse
                                        in={this.state.optionOpen === i}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <List component="div" disablePadding>
                                          {child._children.map(
                                            (subchild, subchildindex) => {
                                              return (
                                                <AuthWrapper
                                                  type="MODULE"
                                                  name={
                                                    subchild?.authModuleName
                                                  }
                                                >
                                                  <Link
                                                    key={subchildindex}
                                                    to={subchild.to}
                                                    style={{
                                                      textDecoration: "none",
                                                      color: "inherit",
                                                    }}
                                                  >
                                                    <ListItem
                                                      button
                                                      className={
                                                        classes.subchildListItem
                                                      }
                                                    >
                                                      <ListItemIcon
                                                        className={
                                                          classes.listItemIcon
                                                        }
                                                      >
                                                        {subchild.icon}
                                                      </ListItemIcon>
                                                      <ListItemText
                                                        primary={
                                                          <Typography
                                                            style={{
                                                              fontSize: 14,
                                                              fontWeight: 400,
                                                            }}
                                                          >
                                                            {subchild.name}
                                                          </Typography>
                                                        }
                                                      />
                                                    </ListItem>
                                                  </Link>
                                                </AuthWrapper>
                                              );
                                            }
                                          )}
                                        </List>
                                      </Collapse>
                                    </>
                                  )}
                                </Fragment>
                              </AuthWrapper>
                            )
                          );
                        })}
                        <Divider />
                      </Fragment>
                    </AuthWrapper>
                  );
                })}
                <ListItem onClick={(e) => this.setState({ optionOpen: null })}>
                  <ListItemText
                    primary={
                      <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                        Need Help?
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>
                <ListItem onClick={(e) => this.setState({ optionOpen: null })}>
                  <ListItemText
                    primary={
                      <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                        Settings
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>
              </List>
            </Drawer>
          </Hidden>
        </nav>
      </ThemeProvider>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(TheSidebar);
