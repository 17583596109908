import React, { useEffect, useState } from "react";
import { Button, Grid, Typography, TextField } from "@material-ui/core";
import { useSldUserChangePasswordMutation } from "../../../../services/services";
import * as yup from "yup";
import { ErrorMessage, FieldArray, Formik, Field, Form } from "formik";
import Toster from "../Toster";
import AuthWrapper from "../../../../utils/AuthWrapper";

export default function ChangePassword() {
  const [updatePassword, updatePasswordInfo] =
    useSldUserChangePasswordMutation();

  const initialValues = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };

  const validationSchema = yup.object({
    current_password: yup.string().required("Required!"),
    new_password: yup.string().required("Required!"),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("new_password"), null], "New passwords don't match!")
      .required("Required!"),
  });

  const onSubmit = (values, { resetForm }) => {
    updatePassword(values)
      .then((res) => {
        if (res?.data?.status) {
          resetForm();
          localStorage.removeItem("__authToken__");
          localStorage.setItem("isLoggedIn", "false");
          window.location.replace("/");
        }
      })
      .catch((err) => {});
    //resetForm();
  };

  return (
    <>
      <AuthWrapper type="ACTION" name="CHANGE_PASSWORD_UPDATE">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <Grid
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                marginTop: 20,
                marginLeft: 10,
              }}
            >
              <Typography
                //className={classes.title}
                variant="h6"
                color="primary"
                gutterBottom
              >
                Change Your Password
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Field name="current_password">
                    {(props) => {
                      const { field } = props;
                      return (
                        <TextField
                          {...field}
                          type="text"
                          fullWidth
                          variant="outlined"
                          size="small"
                          label="Current Password"
                          minRows={2}
                          multiline
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage name="current_password">
                    {(errorMsg) => (
                      <span className="text-danger"> {errorMsg} </span>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={4}>
                  <Field name="new_password">
                    {(props) => {
                      const { field } = props;
                      return (
                        <TextField
                          {...field}
                          type="text"
                          fullWidth
                          variant="outlined"
                          size="small"
                          label="New Password"
                          minRows={2}
                          multiline
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage name="new_password">
                    {(errorMsg) => (
                      <span className="text-danger"> {errorMsg} </span>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={4}>
                  <Field name="confirm_password">
                    {(props) => {
                      const { field } = props;
                      return (
                        <TextField
                          {...field}
                          type="text"
                          fullWidth
                          variant="outlined"
                          size="small"
                          label="Confirm Password"
                          minRows={2}
                          multiline
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage name="confirm_password">
                    {(errorMsg) => (
                      <span className="text-danger"> {errorMsg} </span>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <div className="text-right">
                    <Button
                      type="submit"
                      style={{ marginLeft: 20 }}
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Formik>
        {updatePasswordInfo.data && <Toster info={updatePasswordInfo} />}
      </AuthWrapper>
    </>
  );
}
