import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const allTransactionsApi = createApi({
    reducerPath: "allTransactionsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/offline-transaction/`,
    }),
    endpoints: (builder) => ({
        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ADD  "CASH"   >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        GetAllOfflineTransactions: builder.query({
            query: (body) => {
                return {
                    url: "all-offline-transaction",
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,
                };
            },
        }),

        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ADD "Semi-Virtual Order"  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        AddSemiVirtualOrder: builder.mutation({
            query: (body) => {
                return {
                    url: "add-semi-virtual-order",
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,
                };
            },
        }),

        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ADD "Pendrive Order"  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

       



    }),
});

export const {
    useGetAllOfflineTransactionsQuery,
} = allTransactionsApi;



