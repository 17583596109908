import React from 'react'
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';    

export default function AddEvent() {    
    return(
        <form>
            <Grid container >
                <Grid>
                    <CardContent>    
                    <Button variant="outlined" fullWidth={true} startIcon={<BackupIcon />} color="primary" component="label">
                                Upload Event Image
                                <input type="file" hidden />
                            </Button>  <br/><br/> 
                        <TextField name="eventName" label="Event Name"  size="small" /><br /><br />
                        <TextField name="paymentLink"  label="Payment Link"  size="small" /><br /><br />
                        <TextField name="buttonText"  label="Button Text"  size="small" /><br /><br />
                        <Button variant="contained" color="secondary">Close</Button>
                        <Button type="submit" variant="contained" color="primary">Save</Button>
                    </CardContent>
                </Grid>
            </Grid>
        </form>  
    )
}