import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const cmsApi = createApi({
  tagTypes: ["cms"],
  reducerPath: "cmsapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/cms/`,
  }),
  endpoints: (builder) => ({
    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET "CMS" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    GetCms: builder.query({
      providesTags: ["cms"],
      query: (body) => {
        return {
          url: "get-cms",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ADD "CMS" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    AddCms: builder.mutation({
      invalidatesTags: ["cms"],
      query: (body) => {
        return {
          url: "add-cms",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< UPDATE "CMS" IS_SHOW STATUS  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    UpdateCmsStatus: builder.mutation({
      invalidatesTags: ["cms"],
      query: (body) => {
        return {
          url: "change-show-status",
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),

    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< UPDATE SLIDER IMAGE STATUS IN "CMS" SECTION >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    UpdateSliderImageStatus: builder.mutation({
      invalidatesTags: ["cms"],
      query: (body) => {
        return {
          url: "change-slider-image-status",
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),


    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< UPDATE DATA IN "CMS" SECTION >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    UpdateCMSData: builder.mutation({
      invalidatesTags: ["cms"],
      query: (body) => {
        return {
          url: "update-cms",
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),

    // Get Download Template Link
    GetDownloadTemplateLink: builder.query({
      providesTags: ["cms"],
      query: (body) => {
        return {
          url: "get-templates",
          method: "GET",
        };
      },
    }),



  }),
});

export const {
  useAddCmsMutation,
  useGetCmsQuery,
  useUpdateCmsStatusMutation,
  useUpdateSliderImageStatusMutation,
  useUpdateCMSDataMutation,
  useGetDownloadTemplateLinkQuery,
} = cmsApi;


// contact_us------- new add & update single data apis are different
// free_mentoring------- add & update apis workes same
// customer_feedback------- new add & update single data apis are different
// contact_info--------add & update apis workes same
// social_media_link------- add & update apis workes same
// digital_payment_methods------- add & update apis workes same
// makes_us_different_img------- add & update apis workes same
// trail------new add & update single data apis are different
// mobile_no_note------add & update apis workes same
// product_page_banner_image--------add & update apis workes same
// single_product_page_banner_image--------add & update apis workes same
// footer_icons------- update all & update single data
// latest_update_slider------new add & update single data apis are different
// main_slider------new add & update single data apis are different
