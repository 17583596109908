import React, { } from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import { Button, } from '@material-ui/core';

import { useFormik } from 'formik';
import * as yup from 'yup'


export default function ModeForm({ AddData, setDialog, updateModeData, setEditDialog, initialValue, open, formType }) {
    const formik = useFormik({
        initialValues: {
            name: initialValue ? initialValue.name : "",
            description: initialValue ? initialValue.description : "",
            mode_code: initialValue ? initialValue.mode_code : "",
            book_type: initialValue ? initialValue.book_type : "",
        },
        validationSchema: yup.object({
            name: yup.string().required("Required !"),
            description: yup.string().required("Required !"),
            mode_code: yup.string().required("Required !"),
            book_type: yup.string().required("Required !"),

        }),
        onSubmit: (values) => {
            formType == "edit" ?
                updateModeData({body:values , id: initialValue._id }) :
                AddData(values)
        }
    })

    return (
        <>
            {formType == "edit" ?

                // <Dialog open={open} onClose={() => { setEditDialog(false) }}>

                //     <DialogContent>

                        <form onSubmit={formik.handleSubmit}>

                            <TextField
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                                fullWidth={true}
                                label="Name "
                                variant="outlined"
                                size="small" />
                            {formik.touched.name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.name}</span>}

                            <br /><br />

                            <TextField
                                name="description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                                fullWidth={true}
                                minRows={3}
                                multiline
                                label="Description "
                                variant="outlined"
                                size="small" />
                            {formik.touched.description && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.description}</span>}

                            <br /><br />

                            <TextField
                                name="mode_code"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.mode_code}
                                fullWidth={true}
                                // minRows={3}
                                multiline
                                label="mode_code "
                                variant="outlined"
                                size="small" />
                            {formik.touched.mode_code && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.mode_code}</span>}

                            <br /><br />

                            <TextField
                                name="book_type"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.book_type}
                                fullWidth={true}
                                // minRows={3}
                                multiline
                                label="book_type "
                                variant="outlined"
                                size="small" />
                            {formik.touched.book_type && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.book_type}</span>}

                            <br /><br />

                            <Grid container>
                                <Grid xs={6}></Grid>
                                <Grid className="d-flex justify-content-between" xs={6}>
                                    <Button onClick={() => formType == "edit" ? setEditDialog(false) : setDialog(false)} variant="contained" color="secondary">
                                        Close
                                    </Button>
                                    <Button type="submit" variant="contained" color="primary">
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>

                        </form>
                //     </DialogContent>

                // </Dialog>
                :
                <form onSubmit={formik.handleSubmit}>

                    <TextField
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        fullWidth={true}
                        label="Name "
                        variant="outlined"
                        size="small" />
                    {formik.touched.name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.name}</span>}

                    <br /><br />

                    <TextField
                        name="description"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.description}
                        fullWidth={true}
                        minRows={3}
                        multiline
                        label="Description "
                        variant="outlined"
                        size="small" />
                    {formik.touched.description && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.description}</span>}

                    <br /><br />

                    <TextField
                        name="mode_code"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.mode_code}
                        fullWidth={true}
                        multiline
                        label="mode_code "
                        variant="outlined"
                        size="small" />
                    {formik.touched.mode_code && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.mode_code}</span>}

                    <br /><br />

                    <TextField
                        name="book_type"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.book_type}
                        fullWidth={true}
                        // minRows={3}
                        multiline
                        label="book_type "
                        variant="outlined"
                        size="small" />
                    {formik.touched.book_type && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.book_type}</span>}

                    <br /><br />

                    <Grid container>
                        <Grid xs={6}></Grid>
                        <Grid className="d-flex justify-content-between" xs={6}>
                            <Button onClick={() => formType == "edit" ? setEditDialog(false) : setDialog(false)} variant="contained" color="secondary">
                                Close
                            </Button>
                            <Button type="submit" variant="contained" color="primary">
                                Save
                            </Button>
                        </Grid>
                    </Grid>

                </form>

            }

        </>


    )
}
