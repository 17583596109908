import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../constant/BaseURL";
const token = localStorage.getItem("__authToken__");


export const offlineOrdersApi = createApi({
    tagTypes: ["orderListing"],
    reducerPath: "offlineOrdersApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/offline-order/`,
    }),
    endpoints: (builder) => ({
        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ADD  Virtual Order"   >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        AddVirtualOrder: builder.mutation({
            query: (body) => {
                return {
                    url: "add-virtual-order",
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,
                };
            },
        }),

        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ADD "Semi-Virtual Order"  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        AddSemiVirtualOrder: builder.mutation({
            query: (body) => {
                return {
                    url: "add-semi-virtual-order",
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,
                };
            },
        }),

        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ADD "Pendrive Order"  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        AddPendriveOrder: builder.mutation({
            query: (body) => {
                return {
                    url: "add-pen-drive-order",
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,
                };
            },
        }),


        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< UPDATE ORDER  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        UpdateOfflineOrder: builder.mutation({
            invalidatesTags: ["orderListing"],
            query: ({ body, id }) => {
                return {
                    url: id,
                    headers: {
                        "x-access-token": token,
                    },
                    method: "PUT",
                    body,
                };
            },
        }),



        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET "All Virtual Order"  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        GetAllVirtualOrders: builder.query({
            providesTags: ["orderListing"],
            query: (body) => {
                return {
                    url: "all-virtual-order",
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,
                };
            },
        }),

        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET "All Semi-Virtual Order"  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        GetAllSemiVirtualOrders: builder.query({
            providesTags: ["orderListing"],
            query: (body) => {
                return {
                    url: "all-semi-virtual-order",
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,
                };
            },
        }),

        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET "All Pendrive Order"  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        GetAllPendriveOrders: builder.query({
            providesTags: ["orderListing"],
            query: (body) => {
                return {
                    url: "all-pen-drive-order",
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,
                };
            },
        }),

        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET ORDER DETAILS BY ID  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        getOrderDetails: builder.query({
            providesTags: ["orderListing"],
            query: (id) => {
                return {
                    url: id,
                    headers: {
                        "x-access-token": token,
                    },
                    method: "GET",
                };
            },
        }),








    }),
});

export const {
    useAddVirtualOrderMutation,
    useAddSemiVirtualOrderMutation,
    useAddPendriveOrderMutation,
    useUpdateOfflineOrderMutation,
    useGetAllVirtualOrdersQuery,
    useGetAllSemiVirtualOrdersQuery,
    useGetAllPendriveOrdersQuery,
    useGetOrderDetailsQuery,
} = offlineOrdersApi;



