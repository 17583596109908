import React, { useState, useEffect } from "react";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Grid,
  Button,
  Card,
  Box,
  CardContent,
  Dialog,
  Zoom,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  useUpdateFacultyMutation,
  useGetSingleFacultyMutation,
  useGetAllSocialNetworksQuery,
} from "../../../../services/services";
import DeleteIcon from "@material-ui/icons/Delete";
import DataNotFound from "../DataNotFound";
import { getFilterdColumns } from "../../../../utils/getFilterFunction";

export default function SocialMedia({ viewDialog, data, setFacultyData }) {
  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "social_media_name",
      label: "Name",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "link",
      label: "Link",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];
  function createData(SNo, social_media_name, link, actions) {
    return {
      SNo,
      social_media_name,
      link,
      actions,
    };
  }
  const [render, setRender] = useState(false);

  let rows = [];
  const [updateData, updateDataInfo] = useUpdateFacultyMutation();
  const [singleFacultyData, singleFacultyDataInfo] =
    useGetSingleFacultyMutation();
  const [addDialog, setAddDialog] = useState(false);

  if (typeof data.social_media == "object") {
    data.social_media.map((ele, ind) => {
      rows.push(
        createData(
          ind + 1,
          ele.social_media_name,
          ele.link,
          <div>
            <IconButton
              onClick={() => {
                deleteMedia(ele._id);
              }}
              color="secondary"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        )
      );
    });
  }

  useEffect(() => {
    singleFacultyData(data._id);
  }, [updateDataInfo]);

  const deleteMedia = (remarkId) => {
    var form_data = new FormData();
    form_data.append("key", "remove_social_media");
    form_data.append("update_data", JSON.stringify({ _id: remarkId }));
    updateData({ data: form_data, id: data._id });
  };

  const dialogclose = () => {
    setAddDialog(false);
    setRender(!render);
  };

  return (
    <>
      <Card sx={{ display: "flex" }} className="m-3">
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Button
              onClick={() => {
                setAddDialog(true);
              }}
              variant="contained"
              color="primary"
            >
              Add Media
            </Button>
            <Button
              onClick={() => viewDialog(false)}
              className="text-light bg-danger float-right btn btn-sm btn-danger"
            >
              Close
            </Button>
          </CardContent>

          {singleFacultyDataInfo.isSuccess == true &&
          singleFacultyDataInfo.data.data.length ? (
            singleFacultyDataInfo.isLoading == false ? (
              <div>
                {setFacultyData(singleFacultyDataInfo.data.data[0])}

                {rows.length ? (
                  <CreateTableAddButton
                    editDialog={null}
                    setPage={null}
                    rows={rows}
                    editform={null}
                    columns={getFilterdColumns(columns)}
                    isButton={true}
                    editFormName={null}
                  />
                ) : (
                  <Grid xs={12} className="my-1 text-center bg-light p-3">
                    NO DATA FOUND
                  </Grid>
                )}
              </div>
            ) : (
              <SkeletonListing />
            )
          ) : (
            <Grid container justifyContent="center">
              <DataNotFound />
            </Grid>
          )}
          {addDialog ? (
            <AddMedia
              addDialog={addDialog}
              setAddDialog={setAddDialog}
              updateData={updateData}
              facultyId={data._id}
            />
          ) : null}
          {updateDataInfo.data && (
            <Toster info={updateDataInfo} dialogclose={dialogclose} />
          )}
        </Box>
      </Card>
    </>
  );
}

function AddMedia({ addDialog, setAddDialog, updateData, facultyId }) {
  const { data, isSuccess } = useGetAllSocialNetworksQuery();
  if (isSuccess) {
    var allMedia = data.data;
  }
  const formik = useFormik({
    initialValues: {
      link: "",
      social_media_id: "",
    },
    validationSchema: yup.object({
      link: yup.string().required("Required!"),
      social_media_id: yup.string().required("Required!"),
    }),
    onSubmit: (values, e) => {
      var form_data = new FormData();
      form_data.append("key", "add_social_media");
      form_data.append("update_data", JSON.stringify(values));
      updateData({ data: form_data, id: facultyId });
    },
  });

  return (
    <Dialog
      fullScreen={false}
      open={addDialog}
      //scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      // maxWidth="lg"
      fullWidth={true}
      TransitionComponent={Zoom}
    >
      <DialogTitle id="scroll-dialog-title">Add Media</DialogTitle>
      <DialogContent
      //dividers={scroll === "paper"}
      >
        <form id="addfacultyform" onSubmit={formik.handleSubmit}>
          <Grid
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              marginTop: 20,
              marginLeft: 10,
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  name="link"
                  label="Media link"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.link}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.link && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.link}
                  </span>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  style={{ margin: 0 }}
                  fullWidth={true}
                  variant="outlined"
                  className={null}
                >
                  <InputLabel htmlFor="outlined-institute-Name-native-simple">
                    Media Name
                  </InputLabel>

                  <Select
                    onChange={formik.handleChange}
                    id="newSkill"
                    onBlur={formik.handleBlur}
                    value={formik.values.social_media_id}
                    label="Institute Name"
                    name="social_media_id"
                  >
                    {isSuccess &&
                      allMedia.map((data) => {
                        return (
                          <MenuItem value={data._id}>
                            {data.social_media_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                {formik.touched.social_media_id && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.social_media_id}
                  </span>
                )}
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className="text-right">
                  <Button
                    onClick={() => setAddDialog(false)}
                    variant="contained"
                    color="secondary"
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
