// **** React Imports
import React, { useState, useEffect } from 'react'

// **** Formik Imports
import { ErrorMessage, Field, Form, Formik } from 'formik'

// **** Yup Import
import * as Yup from "yup"

// **** Material Imports
import { Autocomplete, Button, Checkbox, Grid, InputLabel, TextField, } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// **** Services Imports
import { useGetAallFacultieNamesQuery } from '../../../../services/services'
import { useAddCenterMutation } from '../../../../services/offlineModuleServices/centersServices';

// **** Custom File Imports
import SkeletonListing from '../SkeletonListing'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import TimePicker from '@mui/lab/TimePicker'
import moment from 'moment'
import Toster from '../Toster';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// **** Mobile No. RegEx
const mobileRegEx = /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/;

function AddCenter(props) {

    // **** Destructuring of Props
    const { onClose } = props

    // **** Services
    const { data: allFaculties, isFetching: isAllFacultiesFetching, isLoading: isAllFacultiesLoading } = useGetAallFacultieNamesQuery()
    const [addCenter, addCenterInfo] = useAddCenterMutation()

    // **** Form Initial Values
    const initialValues = {
        center_name: "",
        contact_no: "",
        email: "",
        website: "",
        full_address: "",
        office_timing: "",
        facultiesForInvoice: []
    }

    // **** Validation Schema
    const validationSchema = Yup.object({
        center_name: Yup.string().required("Please enter center name"),
        contact_no: Yup.string().matches(mobileRegEx, "Invalid Mobile Number").required("Please enter contact number"),
        email: Yup.string().email("Please enter a valid Email").required("Email is required"),
        website: Yup.string().required("Please enter Website URL"),
        full_address: Yup.string().required("Please enter Full Address"),
        // office_timing: Yup.string().required("Please enter Office Timing"),
        // facultiesForInvoice: Yup.array().min(1, "Please Select Faculty").required("Please Select Faculty")

    })

    // **** On Submit Handler
    const onSubmitHandler = (values) => {
        values = {
            ...values,
            facultiesForInvoice: values.facultiesForInvoice.map(faculty => faculty._id)
        }
          
        addCenter(values)
    }

    // **** Main Function Return
    return (
        isAllFacultiesFetching ||
            isAllFacultiesLoading ?
            <SkeletonListing />
            :
            <div>

                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitHandler}
                >
                    {
                        ({ values, setFieldValue }) => (
                            <Form>
                                <Grid container spacing={3} className="mt-3" >

                                    {/* Center Name */}
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor='center_name' className='text-dark' > Center Name  </InputLabel>
                                        <Field name="center_name" >
                                            {
                                                ({ field }) => {
                                                    return (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            id="center_name"
                                                            variant='standard'
                                                        />
                                                    )
                                                }
                                            }
                                        </Field>

                                        <ErrorMessage name='center_name'>
                                            {
                                                (errMsg) => <span className='text-danger'> {errMsg}  </span>
                                            }
                                        </ErrorMessage>
                                    </Grid>

                                    {/* Contact Number */}
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor='contact_no' className='text-dark' > Contact Number  </InputLabel>
                                        <Field name="contact_no">
                                            {
                                                ({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        size="small"
                                                        type="number"
                                                        fullWidth
                                                        variant='standard'
                                                    />
                                                )
                                            }
                                        </Field>

                                        <ErrorMessage name='contact_no'>
                                            {
                                                (errMsg) => <span className='text-danger'> {errMsg}  </span>
                                            }
                                        </ErrorMessage>
                                    </Grid>

                                    {/* Email */}
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor='email' className='text-dark' > Email  </InputLabel>
                                        <Field name="email" >
                                            {
                                                ({ field }) => {
                                                    return (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            id="email"
                                                            variant='standard'
                                                        />
                                                    )
                                                }
                                            }
                                        </Field>

                                        <ErrorMessage name='email'>
                                            {
                                                (errMsg) => <span className='text-danger'> {errMsg}  </span>
                                            }
                                        </ErrorMessage>
                                    </Grid>

                                    {/* Website */}
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor='website' className='text-dark' > Website  </InputLabel>
                                        <Field name="website" >
                                            {
                                                ({ field }) => {
                                                    return (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            id="website"
                                                            variant='standard'
                                                        />
                                                    )
                                                }
                                            }
                                        </Field>

                                        <ErrorMessage name='website'>
                                            {
                                                (errMsg) => <span className='text-danger'> {errMsg}  </span>
                                            }
                                        </ErrorMessage>
                                    </Grid>

                                    {/* Full Address */}
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor='full_address' className='text-dark' > Full Address  </InputLabel>
                                        <Field name="full_address" >
                                            {
                                                ({ field }) => {
                                                    return (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            id="full_address"
                                                            variant='standard'
                                                        />
                                                    )
                                                }
                                            }
                                        </Field>

                                        <ErrorMessage name='full_address'>
                                            {
                                                (errMsg) => <span className='text-danger'> {errMsg}  </span>
                                            }
                                        </ErrorMessage>
                                    </Grid>

                                    {/* Office Timing */}
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor='office_timing' className='text-dark' > Office Timing  </InputLabel>
                                        <Field name="office_timing" >
                                            {
                                                ({ field }) => {
                                                    return (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            id="office_timing"
                                                            variant='standard'
                                                        />
                                                    )
                                                }
                                            }
                                        </Field>

                                        <ErrorMessage name='office_timing'>
                                            {
                                                (errMsg) => <span className='text-danger'> {errMsg}  </span>
                                            }
                                        </ErrorMessage>
                                    </Grid>

                                    {/* Faculty */}
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor='facultiesForInvoice' className='text-dark' > Faculty  </InputLabel>
                                        <Field name="facultiesForInvoice" >
                                            {
                                                ({ field }) => {
                                                    return (
                                                        <Autocomplete
                                                            multiple
                                                            id="facultiesForInvoice"
                                                            value={values.facultiesForInvoice}
                                                            onChange={(event, newValue) => {
                                                                setFieldValue("facultiesForInvoice", newValue)
                                                            }}
                                                            options={allFaculties && allFaculties.data}
                                                            disableCloseOnSelect
                                                            getOptionLabel={(option) => option.faculty_name}
                                                            isOptionEqualToValue={(opt, value) => opt._id === value._id}
                                                            renderOption={(props, option, { selected }) => (
                                                                <li {...props}>
                                                                    <Checkbox
                                                                        icon={icon}
                                                                        checkedIcon={checkedIcon}
                                                                        style={{ marginRight: 8 }}
                                                                        checked={selected}
                                                                    />
                                                                    {option.faculty_name}
                                                                </li>
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField variant='standard' {...params} />
                                                            )}
                                                        />
                                                    )
                                                }
                                            }
                                        </Field>

                                        <ErrorMessage name='facultiesForInvoice'>
                                            {
                                                (errMsg) => <span className='text-danger'> {errMsg}  </span>
                                            }
                                        </ErrorMessage>
                                    </Grid>

                                    {/* Button */}
                                    <Grid container item xs={12} justifyContent="flex-end" mt={2}  >
                                        <Grid item xs={3}>
                                            <Button fullWidth variant='contained' onClick={() => onClose()} color="error" > cancel </Button>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button fullWidth variant='contained' className='ml-2' type='submit' sx={{ backgroundColor: "#5664d2" }} > Save </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                    }
                </Formik>
                {addCenterInfo && (
                    <Toster info={addCenterInfo} dialogclose={onClose} />
                )}
            </div>
    )
}

export default AddCenter
