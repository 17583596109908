import React, { useState, useEffect } from 'react';
import { TextField, Button, } from '@material-ui/core';
import SearchHook from './SearchHook';
import SearchIcon from '@material-ui/icons/Search';

export default function SearchBar({ searchApi, params,setSearchQuery }) {
    const [searchval, setSearchval] = useState("")
    // const [data, setData] = useState(params)
    // const [flag, setFlag] = useState(false)
    function handleSearch(e) {
        setSearchval(e.target.value)
    }
    return (
        <>
            <div style={{ display: "flex" }}>
                <TextField
                    fullWidth={true}
                    label="Search"
                    variant="outlined"
                    size="small"
                    onChange={(e) => handleSearch(e)}
                />

                {/* {flag && <SearchHook searchApi={searchApi} data={{...params}} setFlag={setFlag} />} */}
                <Button variant="contained"
                    color="primary"
                    component="label"
                    onClick={() => {
                        searchval ? searchApi({ ...params, query: searchval }) : searchApi({ ...params, query: "" })
                        setSearchQuery(searchval)
                        // searchval ? setData({ ...params, query: searchval }) : setData({ ...params, query: "" })
                        // setFlag(true)
                    }}
                >
                    <SearchIcon className='font-weight-bold'/>
                </Button>
            </div>
        </>
    )
}
