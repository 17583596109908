import React, { useEffect } from "react";
import { Tabs, Tab, AppBar } from "@material-ui/core";
import TabPanel from "./CreateTabPanel";
import PropTypes from "prop-types";
import VideoLectureOrders from "./VideoLectureOrders";
import CourierDetails from "./CourierDetails";
import OnlineOrderTransaction from "./OnlineOrderTransaction";
import { userAccess } from "../../../helpers/userAccess";
import AuthWrapper from "../../../utils/AuthWrapper";
import { BOOKS_URL } from "../../../constant/BaseURL";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const tabsNames = [
  {
    tabName: "videoLecture order",
    tabId: "SUCCESSFULL_ORDERS_ORDER",
  },
  {
    tabName: "books orders",
    tabId: "SUCCESSFULL_ORDERS_BOOKS_ORDERS",
    url: `${BOOKS_URL}/?redirect=online-book-orders/successfull`,
  },
  {
    tabName: "test series orders",
    tabId: "SUCCESSFULL_ORDERS_TEST_SERIES_ORDERS",
    url: `${BOOKS_URL}/?redirect=online-test-series-orders/successfull`,
  },
  {
    tabName: "tracking-details",
    tabId: "SUCCESSFULL_ORDERS_TRACKING_DETAILS",
  },
  {
    tabName: "order-transaction",
    tabId: "SUCCESSFULL_ORDERS_ORDER_TRANSACTIONS",
  },
 
];

export default function Orders({ inWallet }) {
  let tabsToShow = tabsNames?.filter((e, i) => {
    return AuthWrapper({ type: "TAB", name: e?.tabId, inBoolean: true });
  });
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let orderStatus = [
    "ORDER_RECIEVED",
    "DISPATCHED",
    "REFUND_PENDING",
    "CANCEL_REFUND_PENDING",
    "COMPLETED",
  ];

  const getTabPanel = (ele) => {
    let moduleToShow;
    switch (ele) {
      case "videoLecture order":
        moduleToShow = <VideoLectureOrders orderStatus={orderStatus} />;
        break;
      case "tracking-details":
        moduleToShow = <CourierDetails />;
        break;
      case "order-transaction":
        moduleToShow = <OnlineOrderTransaction />;
        break;
      default:
        break;
    }
    return moduleToShow;
  };

  return (
    <div>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          {tabsToShow?.map((ele, ind) => {
            return (
              <Tab
                style={{ textTransform: "capitalize" }}
                label={ele?.tabName}
                onClick={() => {
                  if (ele?.url) {
                    window.open(
                      `${ele?.url}/&authtoken=${localStorage.getItem(
                        "__authToken__"
                      )}&userData=${localStorage.getItem("userData")}&authType=${localStorage.getItem("authType")}`,
                      "_blank"
                    );
                  }
                }}
                {...a11yProps(ind)}
              />
            );
          })}
        </Tabs>
      </AppBar>

      {tabsToShow?.map((ele, ind) => {
        return (
          <TabPanel value={value} index={ind}>
            {getTabPanel(ele?.tabName)}
          </TabPanel>
        );
      })}
    </div>
  );
}
