
import React, { useState, useEffect } from 'react';
import EditDelete from './EditDelete';
import CreateTableAddButton from './CreateTableAddButton'
import Toster from './Toster';
import Loader from './Loader';
import {
    useGetContactUsMutation,
    useUpdateInquiryMutation,
    useDeletInquiryMutation,
} from '../../../services/services';
import SkeletonListing from './SkeletonListing';
import UpdateInQuiry from './UpdateInquiry';
import AddDataHeader from './AddDataHeader';
import ViewInquiry from './ViewInquiry';
import DataNotFound from './DataNotFound';
import { Button, Grid, TextField } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';
import MaterialTables from "../dashboard/MaterialTables"
import AuthWrapper from '../../../utils/AuthWrapper';
import { getFilterdColumns } from '../../../utils/getFilterFunction';

export default function ContactUs() {
    const columns = [
        {
            id: 'sno',
            label: 'SNO',
            minWidth: 120,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },

        {
            id: 'userName',
            label: 'User Name',
            minWidth: 120,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'userEmail',
            label: 'User Email',
            minWidth: 120,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'userMobile',
            label: 'User Mobile',
            minWidth: 120,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'userSubject',
            label: 'userSubject',
            minWidth: 120,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'userDiscription',
            label: 'User Discription',
            minWidth: 120,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'modifiedAt',
            label: 'Modified-at',
            minWidth: 120,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'Actions',
            label: 'Actions',
            minWidth: 120,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
            authName: ['PRE_SELL_SUPPORT_CONTACT_US_DELETE', 'PRE_SELL_SUPPORT_CONTACT_US_LIST_FOLLOWUP', 'PRE_SELL_SUPPORT_CONTACT_US_ADD_FOLLOWUP']
        },
    ];

    function createData(sno, userName, userEmail, userMobile, userSubject, userDiscription, modifiedAt, Actions) {
        let date = modifiedAt.split('T')
        modifiedAt = date[0]
        return { sno, userName, userEmail, userMobile, userSubject, userDiscription, modifiedAt, Actions };
    }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const paginationData = {
        "limit": rowsPerPage,
        "query": "",
        "params": [
            "email", "mobile", "fullname"
        ],
        "page": page + 1
    }
    let now = new Date()
    let rows = []
    let [getid, setGetid] = useState('')
    const [data, setData] = useState('')
    const [AddDialog, setDialog] = useState(false)
    const [editDialog, setEditDialog] = useState(false)
    const [viewDialog, setViewDialog] = useState(false)
    const [getAllData, getAllDataInfo] = useGetContactUsMutation()
    const [deleteData, deleteDataInfo] = useDeletInquiryMutation()
    const [updateInquiryData, updateDataInfo] = useUpdateInquiryMutation()
    const [feedbackDialogs, setFeedbackDialogs] = useState({ viewFeedback: false, addFeedback: false })
    const AllData = getAllDataInfo.data
    const totalItem = AllData ? AllData.totalItem : 0
    const [render, setRender] = useState(false)
    const [addFeedbackData, setAddFeedbackData] = useState({
        inquiry_id: "",
        discription: "",
        discription_error: "",
        next_date: moment(now).format("YYYY-MM-DD"),
        next_date_error: "",
        next_time: "12:00",
        next_time_error: "",
        intrested_or_not: "",
        intrested_or_not_error: "",
        more_than_3: "YES"
    })
    const [followUpData, setFollowUpData] = useState([])
    const followUpTableHeaders = ["added_by_sldUser", "added_on_date", "discription", "intrested_or_not", "next_date_time", "sldUser_type"]

    useEffect(() => {
        getAllData(paginationData)
    }, [render, rowsPerPage, page,])

    useEffect(() => {
        getid && deleteData(getid) && setGetid("")
    }, [getid])

    const UpdateData = (data, id) => {
        updateInquiryData({ body: data, id: id })
    }
    let totalData = "ok"
    if (AllData) {
        totalData = AllData.totalItem;
        rows = AllData.data.map((data, index) => createData(
            index + 1,
            data.fullname,
            data.email,
            data.mobile,
            data.subject,
            data.discription,
            moment(data.createdAt).format("DD-MM-YYYY hh:mm:ss A"),
            <div >
                <AuthWrapper type="ACTION" name="PRE_SELL_SUPPORT_CONTACT_US_ADD_FOLLOWUP">
                    <Button variant="contained" color="primary" style={{ fontSize: "11px" }} onClick={() => {
                        setFeedbackDialogs({ ...feedbackDialogs, addFeedback: true })
                        setAddFeedbackData({
                            inquiry_id: data._id,
                            discription: "",
                            discription_error: "",
                            next_date: moment(now).format("YYYY-MM-DD"),
                            next_date_error: "",
                            next_time: "12:00",
                            next_time_error: "",
                            intrested_or_not: "",
                            intrested_or_not_error: "",
                            more_than_3: "YES"
                        })
                    }}  >
                        +Add Follow-Up
                    </Button>
                </AuthWrapper>
                <br />
                <AuthWrapper type="ACTION" name="PRE_SELL_SUPPORT_CONTACT_US_LIST_FOLLOWUP">
                    <Button className='mt-2' variant="contained" color="success" style={{ fontSize: "11px" }} onClick={() => {
                        setFeedbackDialogs({ ...feedbackDialogs, viewFeedback: true })
                        setFollowUpData([...data.follow_up])
                    }} >
                        View Follow-Up
                    </Button>
                </AuthWrapper>
                <br />
                <AuthWrapper type="ACTION" name="PRE_SELL_SUPPORT_CONTACT_US_DELETE">
                    <EditDelete type="delete"
                        setGetid={setGetid}
                        id={data._id} />
                </AuthWrapper>
            </div>

        )
        )
    }
    const dialogclose = () => {
        setEditDialog(false)
        setViewDialog(false)
        setDialog(false)
        setRender(!render)
    }

    const handleAddFollowUpSubmit = () => {
        let feedbackData = Object.assign({}, addFeedbackData);
        let isValid = true

        for (let key in feedbackData) {
            if (feedbackData[key] == "" && !key.includes("_error")) {
                isValid = false
                feedbackData[key + "_error"] = "Required !"
            } else {
                feedbackData[key + "_error"] = ""
            }
        }
        setAddFeedbackData(Object.assign({}, feedbackData))
        if (isValid) {
            let dataToSend = {
                discription: feedbackData.discription,
                next_date_time: feedbackData.next_date + " " + feedbackData.next_time,
                intrested_or_not: feedbackData.intrested_or_not,
                more_than_3: "YES"
            }
            updateInquiryData({ body: dataToSend, id: feedbackData.inquiry_id })
            setFeedbackDialogs({ ...feedbackDialogs, addFeedback: false })
        }
    }

    return (
        <>
            <div>
                {!viewDialog && <AddDataHeader
                    addFormName={"Add inquiry"}
                    addBtn={true}
                    setPage={setPage}
                    page={page}
                    isSearch={
                        !AuthWrapper({
                            type: "ACTION",
                            name: "PRE_SELL_SUPPORT_CONTACT_US_LIST",
                            inBoolean: true,
                        })
                    }
                    isPagination={
                        !AuthWrapper({
                            type: "ACTION",
                            name: "PRE_SELL_SUPPORT_CONTACT_US_LIST",
                            inBoolean: true,
                        })
                    }
                    excelBtn={false}
                    totalItem={totalItem}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    setAddDialog={setDialog}
                    searchBarApi={getAllData}
                    paginationData={paginationData}
                    isButton={true}

                />}
                <AuthWrapper type="ACTION" name="PRE_SELL_SUPPORT_CONTACT_US_LIST">
                    {deleteDataInfo.isLoading || updateDataInfo.isLoading ? <Loader /> :
                        <>

                            {!viewDialog ?
                                rows.length ?
                                    <CreateTableAddButton
                                        editDialog={editDialog}
                                        rows={rows}
                                        editform={
                                            <UpdateInQuiry
                                                UpdateData={UpdateData}
                                                editData={data}
                                                editDialog={setEditDialog} />
                                        }
                                        columns={getFilterdColumns(columns)}
                                        editFormName={'Edit inquiry'} />
                                    : totalData == 0 ? <DataNotFound /> : <SkeletonListing />
                                : <ViewInquiry viewDialog={setViewDialog} data={data} />
                            }
                        </>}
                </AuthWrapper>

                {deleteDataInfo.data && <Toster info={deleteDataInfo} dialogclose={dialogclose} />}
                {updateDataInfo && <Toster info={updateDataInfo} dialogclose={dialogclose} />}

            </div>
            <Dialog fullWidth={true} open={feedbackDialogs.addFeedback} onClose={() => { setFeedbackDialogs({ ...feedbackDialogs, addFeedback: false }) }}>
                <DialogTitle>
                    Add Follow-Up
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container>
                            <Grid xs={12} className="my-2" >
                                <TextField
                                    type="text"
                                    fullWidth
                                    onChange={(e) => { setAddFeedbackData({ ...addFeedbackData, discription: e.target.value }) }}
                                    name="discription"
                                    value={addFeedbackData.discription}
                                    label="Discription"
                                    variant="outlined"
                                    multiline
                                    minRows={3}
                                />
                                <span style={{ "fontSize": "12px" }} className='text-danger' >{addFeedbackData.discription_error}</span>
                            </Grid>
                            <Grid xs={12} className="my-2" >
                                <TextField
                                    type="date"
                                    fullWidth
                                    onChange={(e) => { setAddFeedbackData({ ...addFeedbackData, next_date: e.target.value }) }}
                                    name="next_date"
                                    value={addFeedbackData.next_date}
                                    label="Next Follow Up Date"
                                    variant="outlined"
                                />
                                <span style={{ "fontSize": "12px" }} className='text-danger' >{addFeedbackData.next_date_error}</span>
                            </Grid>
                            <Grid xs={12} className="my-2" >
                                <TextField
                                    type="time"
                                    fullWidth
                                    onChange={(e) => { setAddFeedbackData({ ...addFeedbackData, next_time: e.target.value }) }}
                                    name="next_time"
                                    value={addFeedbackData.next_time}
                                    label="Next Follow Up Time"
                                    variant="outlined"
                                />
                                <span style={{ "fontSize": "12px" }} className='text-danger' >{addFeedbackData.next_time_error}</span>
                            </Grid>
                            <Grid xs={12} className="my-2" >
                                <TextField
                                    type="text"
                                    fullWidth
                                    onChange={(e) => { setAddFeedbackData({ ...addFeedbackData, intrested_or_not: e.target.value }) }}
                                    name="intrested_or_not"
                                    value={addFeedbackData.intrested_or_not}
                                    label="Intrested Or Not ?"
                                    variant="outlined"
                                />
                                <span style={{ "fontSize": "12px" }} className='text-danger' >{addFeedbackData.intrested_or_not_error}</span>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='p-3' >
                    <Button className='bg-danger text-light' onClick={() => { setFeedbackDialogs({ ...feedbackDialogs, addFeedback: false }) }}>
                        Close
                    </Button>
                    <Button className='bg-success text-light' onClick={() => handleAddFollowUpSubmit()}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog maxWidth="md" fullWidth={true} open={feedbackDialogs.viewFeedback} size="large" onClose={() => { setFeedbackDialogs({ ...feedbackDialogs, viewFeedback: false }) }}>
                <DialogTitle>
                    Follow-Up List
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {followUpData.length ?
                            <MaterialTables columns={followUpTableHeaders} rows={followUpData} /> :
                            <div className='text-center bg-light'  >No Data Found</div>
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='p-3' >
                    <Button className='bg-danger text-light' onClick={() => { setFeedbackDialogs({ ...feedbackDialogs, viewFeedback: false }) }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    )
}