import { createSlice, configureStore } from "@reduxjs/toolkit";
import {
  facultyApi,
  instituteApi,
  courseApi,
  courseLevelApi,
  subjectApi,
  modeApi,
  categoryApi,
  videoLectureApi,
  usersApi,
  orderApi,
  booksApi,
  LoginApi,
  moduleApi,
  couponApi,
  InquiryApi,
  StatesApi,
  socialApi,
  dashboardApi,
  scratchApi,
  // reviewApi,
  quoteApi,
  OrderExcelApi,
  courierApi,
  expenseApi,
  walletApi,
  hiddenLinkApi,
  subscribeApi,
} from "../services/services";

import { cmsApi } from "../services/cmsServices";
import { cartApi } from "../services/cartServices";
import { pythonApi } from "../services/pyhtonApiSeevices";
import { excelLogsApi } from "../services/ExcelLogs/ExcelLogsServices";
import { incomeApi } from "../services/incomeServices";
import { TicketApi } from "../services/ticketservices";
import { mailblurbApi, emailQueueApi } from "../services/EmailServices";
import { msgTempletApi } from "../services/smsServices";
import { failedOrderRequestApi } from "../services/failedOrderRequestService";
import { facultyCommisionApi } from "../services/facultyCommisionServices";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { studentRequestApi } from "../services/StudentRequestsSevices";
import { callerPerformanceApi } from "../services/callerPerformanceServices";
import { mobileNoLibraryApi } from "../services/mobileNoLibraryServices";
import { facultyPaymentApi } from "../services/facultyPaymentServices";
import { facultyCommisionRuleApi } from "../services/facultyCommisionRuleServices";
import { offlineConfigApi } from "../services/offlineModuleServices/configServices";
import { centersApi } from "../services/offlineModuleServices/centersServices";
import { batchApi } from "../services/offlineModuleServices/batchServices";
import { currencyApi } from "../services/offlineModuleServices/currencyServices";
import { paymentModeApi } from "../services/offlineModuleServices/paymentModeServices";
import { excelFileApi } from "../services/excelFileService";
import { reviewApi } from "../services/ReviewServices";
import { offlineOrdersApi } from "../services/offlineModuleServices/offlineOrdersServices";
import { fileExplorerApi } from "../services/file explorer Services/fileExplorerServices";
import { cashCollectionApi } from "../services/offlineModuleServices/cashCollectionServices";
import {
  timeSheetApi,
  timeTrackingSheetApi,
} from "../services/timeSheetServices";
import { allTransactionsApi } from "../services/offlineModuleServices/allOfflineTransactionsServices";
import { orderTransactionApi } from "../services/orderTransactionService";
import { specializationApi } from "../services/specializationServices";
import { productCartApi } from "../services/ProductCartServices/ProductCartServices";
import { configurationDataApi } from "../services/configuration/ConfigDataServices";
import { blogApi } from "../services/blogServices"; 
import { questionApi } from "../services/questionService";

const authMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  if (result.error && result.payload.status === 401) {
    localStorage.clear();
    window.location.replace("/");
  }

  return result;
};

const authStore = configureStore({
  reducer: {
    [facultyApi.reducerPath]: facultyApi.reducer,
    [instituteApi.reducerPath]: instituteApi.reducer,
    [courseApi.reducerPath]: courseApi.reducer,
    [courseLevelApi.reducerPath]: courseLevelApi.reducer,
    [subjectApi.reducerPath]: subjectApi.reducer,
    [modeApi.reducerPath]: modeApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [videoLectureApi.reducerPath]: videoLectureApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [booksApi.reducerPath]: booksApi.reducer,
    [LoginApi.reducerPath]: LoginApi.reducer,
    [moduleApi.reducerPath]: moduleApi.reducer,
    [couponApi.reducerPath]: couponApi.reducer,
    [InquiryApi.reducerPath]: InquiryApi.reducer,
    [StatesApi.reducerPath]: StatesApi.reducer,
    [cmsApi.reducerPath]: cmsApi.reducer,
    [socialApi.reducerPath]: socialApi.reducer,
    [dashboardApi.reducerPath] : dashboardApi.reducer,
    [scratchApi.reducerPath]: scratchApi.reducer,
    [reviewApi.reducerPath]: reviewApi.reducer,
    [quoteApi.reducerPath]: quoteApi.reducer,
    [courierApi.reducerPath]: courierApi.reducer,
    [expenseApi.reducerPath]: expenseApi.reducer,
    [incomeApi.reducerPath]: incomeApi.reducer,
    [OrderExcelApi.reducerPath]: OrderExcelApi.reducer,
    [excelLogsApi.reducerPath]: excelLogsApi.reducer,
    [cartApi.reducerPath]: cartApi.reducer,
    [pythonApi.reducerPath]: pythonApi.reducer,
    [TicketApi.reducerPath]: TicketApi.reducer,
    [mailblurbApi.reducerPath]: mailblurbApi.reducer,
    [emailQueueApi.reducerPath]: emailQueueApi.reducer,
    [msgTempletApi.reducerPath]: msgTempletApi.reducer,
    [studentRequestApi.reducerPath]: studentRequestApi.reducer,
    [callerPerformanceApi.reducerPath]: callerPerformanceApi.reducer,
    [mobileNoLibraryApi.reducerPath]: mobileNoLibraryApi.reducer,
    [facultyPaymentApi.reducerPath]: facultyPaymentApi.reducer,
    [facultyCommisionApi.reducerPath]: facultyCommisionApi.reducer,
    [facultyCommisionRuleApi.reducerPath]: facultyCommisionRuleApi.reducer,
    [offlineConfigApi.reducerPath]: offlineConfigApi.reducer,
    [centersApi.reducerPath]: centersApi.reducer,
    [batchApi.reducerPath]: batchApi.reducer,
    [currencyApi.reducerPath]: currencyApi.reducer,
    [paymentModeApi.reducerPath]: paymentModeApi.reducer,
    [walletApi.reducerPath]: walletApi.reducer,
    [excelFileApi.reducerPath]: excelFileApi.reducer,
    [offlineOrdersApi.reducerPath]: offlineOrdersApi.reducer,
    [fileExplorerApi.reducerPath]: fileExplorerApi.reducer,
    [cashCollectionApi.reducerPath]: cashCollectionApi.reducer,
    [timeSheetApi.reducerPath]: timeSheetApi.reducer,
    [allTransactionsApi.reducerPath]: allTransactionsApi.reducer,
    [orderTransactionApi.reducerPath]: orderTransactionApi.reducer,
    [failedOrderRequestApi.reducerPath]: failedOrderRequestApi.reducer,
    [timeTrackingSheetApi.reducerPath]: timeTrackingSheetApi.reducer,
    [specializationApi.reducerPath]: specializationApi.reducer,
    [hiddenLinkApi.reducerPath]: hiddenLinkApi.reducer,
    [productCartApi.reducerPath]: productCartApi.reducer,
    [configurationDataApi.reducerPath]: configurationDataApi.reducer,
    [blogApi.reducerPath]: blogApi.reducer,
    [subscribeApi.reducerPath]: subscribeApi.reducer,
    [questionApi.reducerPath]:questionApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      facultyApi.middleware,
      instituteApi.middleware,
      courseApi.middleware,
      courseLevelApi.middleware,
      subjectApi.middleware,
      authMiddleware,
      modeApi.middleware,
      categoryApi.middleware,
      videoLectureApi.middleware,
      usersApi.middleware,
      orderApi.middleware,
      booksApi.middleware,
      LoginApi.middleware,
      moduleApi.middleware,
      couponApi.middleware,
      InquiryApi.middleware,
      StatesApi.middleware,
      cmsApi.middleware,
      socialApi.middleware,
      dashboardApi.middleware,
      scratchApi.middleware,
      reviewApi.middleware,
      quoteApi.middleware,
      OrderExcelApi.middleware,
      courierApi.middleware,
      expenseApi.middleware,
      incomeApi.middleware,
      excelLogsApi.middleware,
      cartApi.middleware,
      pythonApi.middleware,
      studentRequestApi.middleware,
      TicketApi.middleware,
      mailblurbApi.middleware,
      emailQueueApi.middleware,
      mobileNoLibraryApi.middleware,
      msgTempletApi.middleware,
      facultyPaymentApi.middleware,
      facultyCommisionApi.middleware,
      facultyCommisionRuleApi.middleware,
      offlineConfigApi.middleware,
      centersApi.middleware,
      batchApi.middleware,
      currencyApi.middleware,
      paymentModeApi.middleware,
      walletApi.middleware ,
      excelFileApi.middleware ,
      offlineOrdersApi.middleware,
      fileExplorerApi.middleware,
      timeSheetApi.middleware,
      allTransactionsApi.middleware,
      orderTransactionApi.middleware,
      failedOrderRequestApi.middleware,
      timeTrackingSheetApi.middleware,
      specializationApi.middleware,
      hiddenLinkApi.middleware,
      productCartApi?.middleware,
      configurationDataApi?.middleware,
      blogApi?.middleware,
      cashCollectionApi?.middleware,
      callerPerformanceApi?.middleware,
      subscribeApi?.middleware ,
      questionApi?.middleware
    ]),
});
setupListeners(authStore.dispatch);

export default authStore;
