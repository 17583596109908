import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SldUsers from '../SldUsers';
import UserGroup from '../UserGroup';
import SldUsersChangePassword from './SldUsersChangePassword';
import { userAccess } from '../../../../helpers/userAccess'

function TabPanel(props) {
    const { children, value, index, ...other } = props;



    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}

        >
            {value === index && (
                <Box p={10} style={{ padding: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        minWidth: 100,
    },
    tab: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
const tabsNames = [
    {
        accessModule: "sld-user",
        tab: <SldUsers />
    },
    {
        accessModule: "user-group",
        tab: <UserGroup />
    }
]
export default function SldUsersAndGroups(props) {

    const classes = useStyles();
    const [value, setValue] = useState(0)
    let authType = localStorage.getItem("authType")
    // const [value, setValue] = React.useState(props.location.state == undefined
    //     || props.location.state.type == undefined ? 0 :
    //     (props.location.state.type == "FRANCHISE" ? 3 : 0));

    let tabsToShow = tabsNames.filter((e, i) => { return userAccess(e.accessModule) })
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (


        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    {
                        tabsToShow.map((ele, ind) => {
                            return userAccess(ele.accessModule) ? <Tab style={{ textTransform: 'capitalize' }} label={ele.accessModule} {...a11yProps(ind)} /> : null
                        })
                    }
                    {authType == "ADMIN" &&
                        <Tab style={{ textTransform: 'capitalize' }} label={"change-password"} {...a11yProps(2)} />}
                </Tabs>
            </AppBar>
            {
                tabsToShow.map((ele, ind) => {
                    return userAccess(ele.accessModule) ? <TabPanel value={value} index={ind}>{ele.tab}</TabPanel> : null
                })
            }
            {authType == "ADMIN" && <TabPanel value={value} index={2}><SldUsersChangePassword /></TabPanel>}
        </div >
    );
}