import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Books from '../Books';
import TabPanel from '../CreateTabPanel';
import Product from '../Product';
import { userAccess } from '../../../../helpers/userAccess'
import ExpenseCategory from './ExpenseCategory';
import ExpenseStatement from './ExpenseStatement';


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },

}));
const tabsNames = [
    {
        accessModule:"expense-category",
        tab:<ExpenseCategory />,
        tabName: "Expense Category"
    },
    {
        accessModule:"expense-register",
        tab:<ExpenseStatement />,
        tabName: "Expense Statement"
    }
]
export default function Expenses() {
    let tabsToShow = tabsNames.filter((e,i)=>{return userAccess(e.accessModule)})
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    {
                        tabsToShow.map((ele,ind)=>{
                            return userAccess(ele.accessModule) ? <Tab style={{ textTransform: 'capitalize' }} label={ele.tabName} {...a11yProps(ind)} /> :null
                        })
                    }
                </Tabs>
            </AppBar>

            {
                tabsToShow.map((ele,ind)=>{
                    return   userAccess(ele.accessModule) ? <TabPanel value={value} index={ind}>{ele.tab}</TabPanel>:null
                })
            }
        </div >
    )
}
