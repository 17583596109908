import React, { useEffect, useRef, useState } from "react";
import {
  useExportProductCartDataMutation,
  useGetAllUserCartQuery,
} from "../../../../../services/ProductCartServices/ProductCartServices";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Grid,
  Pagination,
  Paper,
  Skeleton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Circle, FileDownload } from "@mui/icons-material";
import { CSVLink } from "react-csv";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import ExportExcel from "./ExcelFormat";
import AuthWrapper from "../../../../../utils/AuthWrapper";
import FilterListIcon from "@mui/icons-material/FilterList";

import ProductCartFilterDialogWrapper from "../FilterDialog/ProductCartFilterDialogWrapper";

const RenderRow = ({ label, value, renderValue, isFilled }) => {
  return (
    <div
      className="d-flex  p-2 border-bottom"
      style={{
        backgroundColor: isFilled ? "rgb(246 246 246)" : "white",
        height: "55px",
      }}
    >
      <div style={{ width: "28%", fontWeight: "500" }}> {label} : </div>
      <div style={{ width: "72%" }}>
        {" "}
        {renderValue ? renderValue() : value}{" "}
      </div>
    </div>
  );
};

const ProductCartListing = () => {
  // States
  let now = new Date();
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpenDateFilter, setIsOpenDateFilter] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    startDate:'',
    endDate: '',
  });
  const [page, setPage] = useState(1);
  // const [exportProductCartData, setExportProductCartData] = useState([]);

  // const exportRef = useRef(null);

  const rowsPerPageOptions = [5, 10, 20, 50, 100];

  // Services
  // const [exportData] = useExportProductCartDataMutation();
  const { data, isFetching, isLoading } = useGetAllUserCartQuery({
    limit: rowsPerPage,
    query: "",
    params: ["first_name", "last_name", "product_name"],
    page: page,
    filterBy: [
      {
        fieldName: "",
        value: "",
      },
    ],
    dateFilter: {
      dateFilterKey :'createdAt',
      startDate: dateFilter?.startDate ? dateFilter?.startDate : "",
      endDate: dateFilter?.endDate ? dateFilter?.endDate : "",
    },
  });

  // Use Effect for Setting Rows
  useEffect(() => {
    if (!isFetching && !isLoading) {
      setRows(data?.data || []);
    }
  }, [isFetching, isLoading, data]);

  return (
    <div style={{ height: "calc(100vh-64px)" }}>
      <Grid
        container
        justifyContent="space-between"
        pt={2}
        px={3}
        style={{
          height: "60px",
          borderBottom: "1px solid #e1e1e1",
          backgroundColor: "white",
        }}
      >
        <AuthWrapper type="ACTION" name="PRE_SELL_SUPPORT_PRODUCT_CART_EXPORT">
          <ExportExcel rowsPerPage={rowsPerPage} page={page} dateFilter={dateFilter} />
        </AuthWrapper>

        <div className="d-flex  align-items-center">
          <div
            className="cursor-pointer mx-5 border border-primary-subtle  px-2 pt-1 rounded-circle "
            onClick={() => {
              setIsOpenDateFilter(true);
            }}
          >
            <FilterListIcon color="primary" fontSize="small" />
          </div>
          {isOpenDateFilter && (
            <ProductCartFilterDialogWrapper
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
              onClose={() => setIsOpenDateFilter(false)}
            />
          )}
          <AuthWrapper type="ACTION" name="PRE_SELL_SUPPORT_PRODUCT_CART_LIST">
            <Grid item>
              <div className="d-flex justify-content-end ">
                <div className="d-flex align-items-center">
                  <div className="pr-2"> Rows per page : </div>
                  <select
                    value={rowsPerPage}
                    onChange={(e) => setRowsPerPage(parseInt(e.target.value))}
                    className={`p-1`}
                    style={{ backgroundColor: "#e1e1e1", borderRadius: "8px" }}
                  >
                    {rowsPerPageOptions.map((option) => {
                      return (
                        <option key={option} value={option}>
                          {" "}
                          {option}{" "}
                        </option>
                      );
                    })}
                  </select>

                  <div className="py-1 px-2 ">
                    Showing &nbsp; {rowsPerPage * (page - 1) + 1} -{" "}
                    {rowsPerPage * (page - 1) + rows.length} of{" "}
                    {data?.totalItem || 0}
                  </div>
                </div>
              </div>
            </Grid>
          </AuthWrapper>
        </div>
      </Grid>

      <AuthWrapper type="ACTION" name="PRE_SELL_SUPPORT_PRODUCT_CART_LIST">
        {isFetching || isLoading ? (
          <Grid container direction="column" spacing={2} p={3}>
            {Array(8)
              .fill(null)
              .map((_, index) => (
                <Grid key={index} item xs={12}>
                  <Skeleton height={"70px"} animation="wave" />
                </Grid>
              ))}
          </Grid>
        ) : (
          <div
            style={{
              height: "calc(100vh - 175px)",
              overflow: "auto",
            }}
          >
            <Grid container direction="column" spacing={2} p={3}>
              {rows.map((row, rowIndex) => (
                <Grid item xs={12} key={rowIndex}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{ height: "100px" }}
                    >
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>
                          <Avatar
                            src={row.image}
                            variant="rounded"
                            sx={{ width: "60px", height: "60px" }}
                          />
                        </Grid>

                        <Grid item xs={5}>
                          <div className="mb-2">{row.product_name}</div>
                          <div
                            className="d-flex align-items-center"
                            style={{ gap: 10 }}
                          >
                            <div className="bg-info text-light px-2 py-1 rounded">
                              {" "}
                              {moment(row.createdAt).format(
                                "DD-MM-yyyy , hh:mm A"
                              )}{" "}
                            </div>

                            <span
                              style={{
                                backgroundColor: row.is_available
                                  ? "rgb(208 252 208)"
                                  : "rgb(252 208 208)",
                                borderRadius: "17px",
                                color: row.is_available
                                  ? "rgb(34 110 6)"
                                  : "rgb(159 1 1)",
                              }}
                              className="px-2 py-1 d-inline-flex align-items-center"
                            >
                              <Circle
                                sx={{ paddingRight: "8px", fontSize: "20px" }}
                              />{" "}
                              {row.is_available ? "Available" : "Not Available"}
                            </span>
                          </div>
                        </Grid>

                        <Grid item xs={3}>
                          <div className="text-primary h6 ">
                            {row.first_name + " " + row.last_name}
                          </div>
                          <div className="text-secondary">
                            {row.email || "-"}
                          </div>
                        </Grid>

                        <Grid item xs={2}>
                          {row.mobile || "-"}
                        </Grid>

                        <Grid item xs={1}>
                          <div
                            style={{
                              backgroundColor: "#e1e1e1",
                              borderRadius: "17px",
                            }}
                            className="px-2 py-1"
                          >
                            <span> QTY : </span>{" "}
                            <span className="h6"> {row.product_quantity} </span>
                          </div>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails sx={{ borderTop: "1px solid #e1e1e1" }}>
                      <Grid container spacing={3} alignItems="stretch">
                        <Grid item xs={6}>
                          <Paper
                            sx={{ border: "1px solid #e1e1e1", height: "100%" }}
                          >
                            <RenderRow
                              label={"Mode Name"}
                              value={row.mode_name || "-"}
                            />

                            <RenderRow
                              label={"Varient Code"}
                              value={row.varient_code || "-"}
                              isFilled={true}
                            />
                            <RenderRow
                              label={"Views"}
                              value={row.no_of_views || "-"}
                            />

                            <RenderRow
                              label={"Validity"}
                              value={row.validity || "-"}
                              isFilled={true}
                            />

                            <RenderRow
                              label={"Language"}
                              value={row.lecture_languages || "-"}
                            />
                            <RenderRow
                              label={"Syllabus"}
                              value={row.syllabus || "-"}
                              isFilled={true}
                            />

                            <RenderRow
                              label={"Group/Module"}
                              value={row.group_or_module || "-"}
                            />

                            <RenderRow
                              label={"Product Type"}
                              value={row.product_type || "-"}
                              isFilled={true}
                            />
                            <RenderRow
                              label={"Selling Price"}
                              renderValue={() => (
                                <span className="text-primary">
                                  {" "}
                                  &#8377; {row.sld_selling_price}{" "}
                                </span>
                              )}
                            />
                            <RenderRow
                              label={"Mode Mrp"}
                              renderValue={() => (
                                <span className="text-primary">
                                  {" "}
                                  &#8377; {row.mode_mrp}{" "}
                                </span>
                              )}
                              isFilled={true}
                            />
                          </Paper>
                        </Grid>

                        <Grid item xs={6}>
                          <Paper sx={{ border: "1px solid #e1e1e1" }}>
                            <RenderRow
                              label={"Courier Type"}
                              value={row.lecture_courier_type || "-"}
                            />
                            <RenderRow
                              label={"Courier Charges"}
                              value={row.book_test_courier_charges || "-"}
                              isFilled={true}
                            />

                            <RenderRow
                              label={"Book Type"}
                              value={row.book_type || "-"}
                            />
                            <RenderRow
                              label={"Relevant Attempt"}
                              value={row.relevant_attempt || "-"}
                              isFilled={true}
                            />
                            <RenderRow
                              label={"Aditional Offer"}
                              value={row.additional_offers || "-"}
                            />
                            <RenderRow
                              label={"Shipping Method"}
                              value={row.shipping_method || "-"}
                              isFilled={true}
                            />
                            <RenderRow
                              label={"Is Hidden"}
                              value={row.is_hidden ? "Yes" : "No"}
                            />

                            <RenderRow
                              label={"Is Faculty Active"}
                              value={row.is_faculty_active ? "Yes" : "No"}
                              isFilled={true}
                            />
                            <RenderRow
                              label={"Is Active"}
                              value={row.is_active ? "Yes" : "No"}
                            />

                            <RenderRow
                              label={"Is Deleted"}
                              value={row.is_deleted ? "Yes" : "No"}
                              isFilled={true}
                            />
                          </Paper>
                        </Grid>

                        {row.note_for_seller && (
                          <Grid item xs={12}>
                            <div className="text-danger h6">
                              {" "}
                              *** &nbsp; {row.note_for_seller} &nbsp; ***{" "}
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </AuthWrapper>

      <AuthWrapper type="ACTION" name="PRE_SELL_SUPPORT_PRODUCT_CART_LIST">
        <div
          className="d-flex justify-content-end px-3 align-items-center"
          style={{
            height: "50px",
            border: "1px solid #e1e1e1",
            backgroundColor: "white",
          }}
        >
          <Pagination
            count={Math.ceil((data?.totalItem || 0) / rowsPerPage)}
            page={page}
            onChange={(e, page) => setPage(page)}
            showFirstButton={true}
            showLastButton={true}
            color="primary"
          />
        </div>
      </AuthWrapper>
    </div>
  );
};

export default ProductCartListing;
