import React, { useState, useEffect } from 'react';
import EditDelete from './EditDelete';
import CreateTableAddButton from './CreateTableAddButton'
import UpdateCoupon from './UpdateCoupon'
import ViewCoupons from './ViewCoupons';
import Toster from './Toster';
import Loader from './Loader';
import AddDataHeader from './AddDataHeader';
import {
    useGetAllScratchCardsMutation,
    useDeletScratchCardMutation,
    useAddScratchCardMutation,
    useUpdateCouponMutation,
} from '../../../services/services';
import SkeletonListing from './SkeletonListing';
import DataNotFound from './DataNotFound';
import AddScratchCards from './AddScratchCards';
import moment from 'moment';
import AuthWrapper from '../../../utils/AuthWrapper';
import { getFilterdColumns } from '../../../utils/getFilterFunction';

export default function ScratchCards() {
    const columns = [
        {
            id: 'SNo',
            label: 'Sr. No.',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'scratchCardName',
            label: 'Scratch Card Name',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'description',
            label: 'Description',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'CreatedBy',
            label: 'Created By',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'user',
            label: 'For User',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'starttime',
            label: 'Start Time',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'expiryTime',
            label: 'Expiry Time',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'isActive',
            label: 'Is Used',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'Action',
            label: 'Actions',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
            authName: ['COUPON_SCRATCH_CARD_SCRATCH_DELETE',]

        },

    ];
    function createData(SNo, scratchCardName,
        description,
        CreatedBy,
        user,
        starttime,
        expiryTime,
        isActive,
        Action,) {
        return {
            SNo, scratchCardName,
            description,
            CreatedBy,
            user,
            starttime,
            expiryTime,
            isActive,
            Action,
        };
    }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const paginationData = {
        "limit": rowsPerPage,
        "query": "",
        "params": [
            "user_id", "validity_end", "validity_start"
        ],
        "page": page + 1
    }


    let rows = []

    let [getid, setGetid] = useState('')
    const [data, setData] = useState('')
    const [AddDialog, setDialog] = useState(false)
    const [editDialog, setEditDialog] = useState(false)
    const [viewDialog, setViewDialog] = useState(false)
    const [getAllData, getAllDataInfo] = useGetAllScratchCardsMutation()
    const [deleteData, deleteDataInfo] = useDeletScratchCardMutation()
    const [addData, addDataInfo] = useAddScratchCardMutation()
    const [updateCouponData, updateDataInfo] = useUpdateCouponMutation()
    const AllData = getAllDataInfo.data
    const totalItem = AllData ? AllData.totalItem : 0
    const [render, setRender] = useState(false)

    useEffect(() => {
        getAllData(paginationData)
    }, [render, rowsPerPage, page,])

    useEffect(() => {
        getid && deleteData(getid) && setGetid("")
    }, [getid])


    const AddData = (data) => {
        addData(data)
    }
    const UpdateData = (data, id) => {

        updateCouponData({ body: data, id: id })
    }

    let totalData = "ok"
    if (AllData) {

        totalData = AllData.totalItem
        rows = AllData.data.map((data, index) => createData(
            index + 1,
            data.scratch_text,
            data.scratch_discription,
            data.createdBysldUser.length ? data.createdBysldUser[0].name + " (" + data.created_by_type + ")" : "" + "(" + data.created_by_type + ")",
            data.user.map((user) => user.email),
            data.validity_start ? moment(data.validity_start).format("DD-MM-YYYY hh:mm:ss A") : "",
            data.validity_end ? moment(data.validity_end).format("DD-MM-YYYY hh:mm:ss A") : "",
            data.is_scratched ? "Yes" : "No",
            <div className="d-flex justify-content-center">
                {/* <EditDelete setViewDialog={setViewDialog}
                    type="view" setData={setData}
                    setGetid={setGetid} data={data} /> */}
                {/* <EditDelete
                    setEditDialog={setEditDialog}
                    type="edit" setData={setData}
                    setGetid={setGetid} data={data} /> */}
                <AuthWrapper type="ACTION" name="COUPON_SCRATCH_CARD_SCRATCH_DELETE">
                    <EditDelete type="delete"
                        setGetid={setGetid}
                        id={data._id} />
                </AuthWrapper>

            </div>
        )
        )
    }
    const dialogclose = () => {
        setEditDialog(false)
        setViewDialog(false)
        setDialog(false)
        setRender(!render)
    }
    return (
        <div>
            {!viewDialog &&
                <AddDataHeader
                    addFormName={"Add Scratch cards"}
                    setPage={setPage}
                    page={page}
                    excelBtn={false}
                    totalItem={totalItem}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    setAddDialog={setDialog}
                    searchBarApi={getAllData}
                    paginationData={paginationData}
                    fullsize={false}
                    isButton={true}
                    isSearch={
                        !AuthWrapper({
                            type: "ACTION",
                            name: "COUPON_SCRATCH_CARD_SCRATCH_LIST",
                            inBoolean: true,
                        })
                    }
                    isPagination={
                        !AuthWrapper({
                            type: "ACTION",
                            name: "COUPON_SCRATCH_CARD_SCRATCH_LIST",
                            inBoolean: true,
                        })
                    }
                    addBtn={
                        !AuthWrapper({
                            type: "ACTION",
                            name: "COUPON_SCRATCH_CARD_SCRATCH_ADD",
                            inBoolean: true,
                        })
                    }
                    Adddialog={AddDialog}
                    form={<AddScratchCards AddData={AddData} setDialog={setDialog} />}
                />
            }
            <AuthWrapper type="ACTION" name="COUPON_SCRATCH_CARD_SCRATCH_LIST">
                {deleteDataInfo.isLoading || addDataInfo.isLoading || updateDataInfo.isLoading ? <Loader /> : <>       {
                    !viewDialog ?
                        rows.length ?
                            <CreateTableAddButton
                                editDialog={editDialog}
                                rows={rows}
                                fullsize={true}
                                editform={
                                    <UpdateCoupon
                                        UpdateData={UpdateData}
                                        editData={data}
                                        editDialog={setEditDialog} />
                                }
                                columns={getFilterdColumns(columns)}
                                editFormName={'Edit Coupon'} />
                            : totalData == 0 ? <DataNotFound /> : <SkeletonListing />
                        :
                        <ViewCoupons viewDialog={setViewDialog} data={data} />}
                </>}
            </AuthWrapper>
            {deleteDataInfo.data && <Toster info={deleteDataInfo} dialogclose={dialogclose} />}
            {addDataInfo.data && <Toster info={addDataInfo} dialogclose={dialogclose} />}
            {updateDataInfo && <Toster info={updateDataInfo} dialogclose={dialogclose} />}

        </div>
    )
}