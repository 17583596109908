// ** React Import
import React from 'react'

// ** Formik and Yup Import
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Button, ListItemText, TextField } from '@mui/material'
import {
    Grid,
    Select,
    MenuItem,
    InputLabel,
    Checkbox,
} from "@material-ui/core";

// ** Custom Styles
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const MODE_OPTIONS = [
    { label: "Own PD", value: "OWN PD" },
    { label: "With PD", value: "WITH PD" },
    { label: "Online Download", value: "ONLINE DOWNLOAD" },
    { label: "Google Drive", value: "GOOGLE DRIVE" },
]

const DELIVERY_OPTION = [
    { label: "Pick Up", value: "PICK UP" },
    { label: "Home Delivery", value: "HOME DELIVERY" },
]

const SUBJECT_OPTIONS = [
    { label: "Own PD", value: "OWN PD" },
    { label: "With PD", value: "WITH PD" },
    { label: "Online Download", value: "ONLINE DOWNLOAD" },
    { label: "Google Drive", value: "GOOGLE DRIVE" },
]




function AddEditPendriveOrder(props) {

    // ** Destructuring Props 
    const { initialData } = props

    // ** Form Initial Values
    const initialValues = {
        student_name: "",
        email: "",
        mobile_number: "",
        cro_number: "",
        attempt_due: "",
        course_type: "",
        course: "",
        subjects: [],
        faculties: [],
        level: "",
        total_fees: 0,
        fee_paid: 0,
        due_amount: 0,
        mode: "",
        book_issued: "",
        delivery_option: "",
        address: "",
        city: "",
        pin: "",
        center: "",
        remark: "",
    }

    // ** Validation RegEx 


    // ** Validation Schema
    const validationSchema = Yup.object({
        student_name: Yup.string().required("Please Enter Name"),
        email: Yup.string().email("Please Enter a Valid Email").required("Please Enter Email"),
        mobile_number: Yup.number().typeError('Please Enter a Valid Mobile No.').required('Mobile No. is required !'),
        course_type: Yup.object().required("Please Select Course Type !"),
        delivery_option: Yup.object().required("Please Select Delivery Option !"),
        address: Yup.string().when("delivery_option", {
            is: (delivery_option) => delivery_option?.value == "HOME DELIVERY",
            then: Yup.string().required("Address is required !")
        }),
        city: Yup.string().when("delivery_option", {
            is: (delivery_option) => delivery_option?.value == "HOME DELIVERY",
            then: Yup.string().required("City is required !")
        }),
        pin: Yup.number().when("delivery_option", {
            is: (delivery_option) => delivery_option?.value == "HOME DELIVERY",
            then: Yup.number().typeError('Pin code should be Number !').required("Pin code is required !")
        }),
        course: Yup.object().required("Please Select Course !"),
        subjects: Yup.array().min(1, "Please Select atleast 1 Subject"),
        faculties: Yup.array().min(1, "Please Select atleast 1 Faculty"),
        center: Yup.object().required("Please Select Center !"),
        level: Yup.object().required("Please Select Level !"),
        total_fees: Yup.number().typeError('Total Fees Should be Number').required("Please Enter Total Fees !"),
        fee_paid: Yup.number().typeError('Fees Paid Should be Number').required("Please Enter Fees Paid !"),
        mode: Yup.object().required("Please Select Mode !")
    })

    const onSubmit = (values) => {
          
    }

    const RenderTextField = (props) => {
        const { label, name, ...rest } = props
        return (
            <>
                <InputLabel className='text-primary' sx={{ mb: 2 }}> {label} </InputLabel>
                <Field name={name}>
                    {
                        ({ field }) => (
                            <TextField
                                variant='standard'
                                fullWidth
                                size='small'
                                {...field}
                                {...rest}

                            />
                        )
                    }
                </Field>
                <ErrorMessage name={name}>
                    {
                        (errMsg) => <span className='text-danger'> {errMsg} </span>
                    }
                </ErrorMessage>
            </>
        )
    }

    const RenderSelectField = (props) => {
        const { label, name, options, renderKey, multiple } = props
        return (
            <>
                <InputLabel className='text-primary' sx={{ mb: 2 }}> {label} </InputLabel>
                <Field name={name}>
                    {
                        ({ field }) => (
                            <Select
                                variant='standard'
                                fullWidth
                                size='small'
                                {...field}
                                renderValue={(selected) => selected[renderKey]}
                            >
                                {
                                    options.length == 0 ?
                                        <Button disabled > No Data Found </Button>
                                        :
                                        <MenuItem disabled value="" > <em> Select {label} </em> </MenuItem>
                                }
                                {
                                    options.map((option, index) => (
                                        <MenuItem key={index} value={option} > {option[renderKey]} </MenuItem>
                                    )
                                    )
                                }

                            </Select>

                        )
                    }
                </Field>

                <ErrorMessage name={name}>
                    {
                        (errMsg) => <span className='text-danger'> {errMsg} </span>
                    }
                </ErrorMessage>
            </>
        )
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {
                (formikProps) => {
                    const { values, setFieldValue } = formikProps


                    return (
                        <Form>
                            <Grid container justifyContent="center">
                                <Grid container item xs={9} py={6} px={3} spacing={4} className="mt-5" >
                                    <Grid item md={3} sm={4} xs={12}>
                                        <RenderTextField name="student_name" label="Name" />
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <RenderTextField name="email" label="Email" />
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <RenderTextField name="mobile_number" label="Mobile NO." />
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <RenderTextField name="cro_number" label="CRO No." />
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <RenderSelectField name="attempt_due" label="Attempt Due" options={[{ attempt_id: "25", attempt_name: "MAY 2022" }]} renderKey="attempt_name" />
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <RenderSelectField name="course_type" label="Course Type" options={[{ attempt_id: "25", attempt_name: "MAY 2022" }]} renderKey="attempt_name" />
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <RenderSelectField name="course" label="Course" options={[{ attempt_id: "25", attempt_name: "MAY 2022" }]} renderKey="attempt_name" />
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <Field name="subjects">
                                            {
                                                ({ field }) => (
                                                    <>
                                                        <InputLabel className='text-primary' sx={{ mb: 2 }}> Subjects </InputLabel>

                                                        <Select
                                                            variant='standard'
                                                            {...field}
                                                            fullWidth
                                                            multiple
                                                            renderValue={(selected) => selected.map((ele) => ele.value).join(', ')}
                                                            MenuProps={MenuProps}
                                                        >
                                                            {

                                                                SUBJECT_OPTIONS.map((ele, index) => (
                                                                    <MenuItem key={index} value={ele}>
                                                                        <Checkbox checked={values.subjects.includes(ele)} />
                                                                        <ListItemText primary={ele.value} />
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </>


                                                )
                                            }
                                        </Field>

                                        <ErrorMessage name="subjects">
                                            {
                                                (errMsg) => <span className='text-danger'> {errMsg} </span>
                                            }
                                        </ErrorMessage>

                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <Field name="faculties">
                                            {
                                                ({ field }) => (
                                                    <>
                                                        <InputLabel className='text-primary' sx={{ mb: 2 }}> Faculties </InputLabel>

                                                        <Select
                                                            variant='standard'
                                                            {...field}
                                                            fullWidth
                                                            multiple
                                                            renderValue={(selected) => selected.map((ele) => ele.label).join(', ')}
                                                            MenuProps={MenuProps}
                                                        >
                                                            {
                                                                SUBJECT_OPTIONS.map((ele) => (
                                                                    <MenuItem key={ele._id} value={ele}>
                                                                        <Checkbox checked={values.faculties.indexOf(ele) > -1} />
                                                                        <ListItemText primary={ele.label} />
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </>


                                                )
                                            }
                                        </Field>

                                        <ErrorMessage name="faculties">
                                            {
                                                (errMsg) => <span className='text-danger'> {errMsg} </span>
                                            }
                                        </ErrorMessage>

                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <RenderSelectField name="level" label="Level" options={[{ attempt_id: "25", attempt_name: "MAY 2022" }]} renderKey="attempt_name" />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <RenderTextField name="total_fees" label="Total Fees" />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <RenderTextField name="fee_paid" label="Fees Paid" />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <InputLabel className='text-primary' sx={{ mb: 2 }}> Due Amount </InputLabel>
                                        <Field name="due_amount">
                                            {
                                                ({ field }) => (
                                                    <TextField
                                                        variant='standard'
                                                        fullWidth
                                                        size='small'
                                                        {...field}
                                                        value={values.total_fees - values.fee_paid}
                                                        disabled

                                                    />
                                                )
                                            }
                                        </Field>
                                        
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <RenderSelectField name="mode" label="Mode" options={MODE_OPTIONS} renderKey="label" />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <RenderTextField name="book_issued" label="Book Issued" />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <RenderSelectField name="delivery_option" label="Delivery Option" options={DELIVERY_OPTION} renderKey="label" />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <RenderSelectField name="center" label="Center" options={[{ attempt_id: "25", attempt_name: "MAY 2022" }]} renderKey="attempt_name" />
                                    </Grid>

                                    <Grid item md={3} sm={4} xs={12}>
                                        <RenderTextField name="address" label="Address" />
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <RenderTextField name="city" label="City" />
                                    </Grid>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <RenderTextField name="pin" label="Pin" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RenderTextField name="remark" label="Remark" multiline={true} minRows={1} maxRows={Infinity} />
                                    </Grid>


                                    <Grid item xs={12} className="mt-3">
                                        <Button variant='contained' type='submit' color='primary' fullWidth > submit </Button>
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Form>
                    )
                }
            }

        </Formik>
    )
}

export default AddEditPendriveOrder
