import React, { useState, useEffect } from "react";
import EditDelete from "./EditDelete";
import CreateTableAddButton from "./CreateTableAddButton";
import UpdateCourseForm from "./UpdateCourseForm";
import Toster from "./Toster";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListIcon from "@material-ui/icons/FilterList";
import AddDataHeader from "./AddDataHeader";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  withStyles,
  Avatar,
  Grid,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  Button,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Accordion,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  DialogContentText,
} from "@material-ui/core";
import CourseForm from "./CourseForm";
import Loader from "./Loader";
import {
  // useGetUserHiddenEmailQuery,
  useGetAllVideoLectureMutation,
  useChangeLectureStatusMutation,
  useDeletVideoLectureMutation,
  useAddVideoLectureMutation,
  useUpdateVideoLectureMutation,
  useGetUsersListingQuery,
  useGetVideoLectureExcelSheetColumnQuery,
} from "../../../services/services";
import SkeletonListing from "./SkeletonListing";
import Varient from "./Varient";
import DataNotFound from "./DataNotFound";
import Filters from "./Filters";
import ViewVideoLectureNew from "./offlineOrders/ViewVideoLectureNew";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import LinkUserAddForm from "./LinkUserAddForm";

import TransferList from "../../common/TransferList/TransferList";
//TOKEN
const userType= localStorage.getItem('authType');
//  Menu Props for select
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};
import { PYTHON_URL } from "../../../constant/BaseURL";
import { getFilterdColumns } from "../../../utils/getFilterFunction";
import AuthWrapper from "../../../utils/AuthWrapper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.light,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const BASE_URL = PYTHON_URL;

// Field Filter Options
// const fieldFilterOptions = [
//   "PRODUCT CODE",
//   "VARIENT CODE",
//   "IMAGE",
//   "PRODUCT NAME",
//   "NICK NAME",
//   "PRODUCT RANK",
//   "COURSE (CA/CS/CMA/ETC)",
//   "LEVEL (FINAL/INTER/FOUNDATION/ETC)",
//   "SUBJECTS",
//   "FACULTIES NAME",
//   "BATCH TYPE",
//   "IS COMBO (YES/NO)",
//   "MODES OF LECTURE DELIEVERY (PD/GD/ETC)",
//   "E-BOOK PRINTABLE OR NOT",
//   "KIT CONTENT",
//   "NO. OF LECTURES (IN NUMBERS ONLY)",
//   "NO. OF LECTURES",
//   "LECTURE DURATION(IN NUMBER OF HOURS ONLY)",
//   "TOTAL LECTURE DURATION",
//   'TOPICS COVERED',
//   "NO. OF VIEWS",
//   "VIEW COUNT AS",
//   "VIEW EXTENSION",
//   "VIEW EXPLAINATION VIDEO URL",
//   "FAST FORWARD SPEED",
//   "VALIDITY",
//   "VALIDITY STARTS FROM",
//   "VALIDITY EXTENSION",
//   "VALIDITY EXPLAINATION VIDEO URL",
//   "LECTURE RECORDING DATE",
//   "RELEVANT ATTEMPT",
//   "DETAILS ABOUT AMENDMENTS",
//   "VIDEO LANGUAGE",
//   "ADDITIONAL OFFERS",
//   "VIDEO RUNS ON",
//   "SYSTEM REQUIREMENTS",
//   "INTERNET CONNECTIVITY",
//   "PROCESSING TIME",
//   "PRODUCT NOTES",
//   "IMP DESCRIPTION",
//   "ADDITIONAL REQUIREMENT",
//   "IMPORTANT NOTICE",
//   "INDEX",
//   "DOCUMENTS TO BE PROVIDED FOR ORDER PROCESSING",
//   "DEMO NAME",
//   "DEMO LINKS",
//   "MULTIPLE DEMO",
//   "UNBOXING VIDEO LINK",
//   "CONTACT NO. FOR DOUBT SOLVING",
//   "CONTACT NO. FOR TRACKING DETAILS",
//   "CONTACT NO. FOR TECHNICAL ISSUES",
//   "FACULTY MULTIPAL EMAIL",
//   "LECTURE DOWNLOAD LINK",
//   "ALLOWED PAYMENT GATEWAYS",
//   "SLD BENEFITS",
//   "SHIPPING METHOD",
//   "COURIER CHARGES",
//   "IS HIDDEN",
//   "IS ACTIVE",
//   "IS DELETED",
//   "IS AVAILABLE",
//   "MRP",
//   "SHOW MRP",
//   "SLD50",
//   "DIS50",
//   "OTHER COUPON APPLIED",
//   "DISCOUNT BY FACULTIES",
//   "START DATE OF DIS BY FACULTIES",
//   "END DATE OF DIS BY FACULTIES",
//   "IN OFFER",
//   "DISCOUNT BY US",
//   "START DATE OF DIS BY US",
//   "END DATE OF DIS BY US",
//   "MAXIMUM DISCOUNT",
//   "WALLET DISCOUNT",
//   "REFERRAL TO PERCENT",
//   "REFERRAL BY PERCENT",
//   "SELLING PRICE",
//   "PRICE ON WHICH COMMISSION IS CALCULATED ONLINE",
//   "PRICE ON WHICH COMMISSION IS CALCULATED OFFLINE",
//   "COMMISSION ONLINE",
//   "COMMISSION OFFLINE",
//   "PAYMENT TO FACULTY ONLINE",
//   "PAYMENT TO FACULTY OFFLINE",
//   "RELEVANT PRODUCTS",
//   "STUDENT ALSO PURCHASED",
//   "GST PROPORTION PERCENT",
//   "GST RATE",
//   "REMARK",
//   "INVOICE INSTITUTE NAME",
//   "ORDER GOOGLE SHEET LINK",
//   "META",
//   "META TITLE",
//   "PRODUCT KEY CODES",
//   "URL SLAG",
//   "ADDITIONAL DISCRIPTION",
//   "SYLLABUS",
//   "GROUP/MODULE",
//   "BATCH RECORDING",
//   "COMBO DISCOUNT",
//   "BOOK TYPE"
// ];

export default function VideoLectures() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredPagination, setFilteredPagination] = useState([]);
  const [data, setData] = useState("");
  const [AddDialog, setDialog] = useState(false);
  const [viewPage, setViewPage] = useState(true);
  const [render, setRender] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [excelDialog, setExcelDialog] = useState(false);
  const [getAllData, getAllDataInfo] = useGetAllVideoLectureMutation();
  const [deleteData, deleteDataInfo] = useDeletVideoLectureMutation();
  const [addData, addDataInfo] = useAddVideoLectureMutation();
  const [updateLectureData, updateDataInfo] = useUpdateVideoLectureMutation();
  const [updateStatus, updateStatusInfo] = useChangeLectureStatusMutation();
  const {data:fieldFilterOptions , isLoading:fieldFilterOptionsIsLoading , isFetching:fieldFilterOptionsIsFetching} = useGetVideoLectureExcelSheetColumnQuery();
  const [excelFields, setExcelFields] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  const token = localStorage.getItem("__authToken__");

  // const [addHiddenLinkData, addHiddenLinkDataInfo] = useAddHiddenLinkMutation()
  // const [getHiddenLinkData, getHiddenLinkDatainfo] = useGetUserHiddenEmailMutation()
  // const [deleteHiddenLinkData, deleteHiddenLinkDatainfo]= useDeleteHiddenEmailMutation()

  const [openSnack, setOpenSnack] = React.useState(false);
  const allUsersListing = useGetUsersListingQuery();
  const AllData = getAllDataInfo.data;
  const totalItem = AllData ? AllData.totalItem : 0;
  let [getid, setGetid] = useState("");
  let [hiddenLinkDialog, setHiddenLinkDialog] = useState(false);
  let [selectedLecture, setSelectedLecture] = useState("");

  const columns = [
    {
      id: "sno",
      label: "S.No",
      minWidth: 50,
      align: "center",
    },
    {
      id: "image",
      label: "Image",
      minWidth: 50,
      align: "center",
    },
    {
      id: "coursename",
      label: "Course Name",
      minWidth: 50,
      align: "center",
    },
    {
      id: "varient",
      label: (
        <TableContainer className="" component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell className="p-1 videoModeHeading">
                  Mode
                </StyledTableCell>
                <StyledTableCell className="p-1 videoModeHeading">
                  MRP
                </StyledTableCell>
                <StyledTableCell className="p-1 videoModeHeading">
                  SP
                </StyledTableCell>
                <StyledTableCell className="p-1 videoModeHeading">
                  Active
                </StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      ),
      minWidth: 50,
      align: "center",
    },
    {
      id: "institute",
      label: "Institute",
      minWidth: 50,
      align: "center",
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      authName: [
        "VIDEO_LECTURE_VIEW",
        "VIDEO_LECTURE_DELETE",
        "VIDEO_LECTURE_COPY",
      ],
    },
  ];
  function createData(sno, image, coursename, varient, institute, actions) {
    return { sno, image, coursename, varient, institute, actions };
  }
  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: ["video_lecture_name", "nickname"],
    filterBy: filteredPagination,
    page: page + 1,
  };
  let rows = [];
 
  const accessModuleOptions = localStorage.getItem("accessModules")
    ? JSON.parse(localStorage.getItem("accessModules"))
    : [];

  useEffect(() => { 
    if(userType !== 'ADMIN'){
      const filteredObjects = accessModuleOptions
      ?.find(
        (moduleOption) =>
          moduleOption?.actionId === "VIDEO_LECTURE_DOWNLOAD_EXCEL"
      )?.fields?.reduce((acc, fields) => {
        acc.push(fields?.fieldName);
        return acc;
      }, []);
    setExcelFields(filteredObjects) 
    setLeft(filteredObjects) 
    }
  }, []);

  useEffect(()=>{
    if(!fieldFilterOptionsIsFetching && !fieldFilterOptionsIsLoading &&  userType ==='ADMIN'){
      setLeft(fieldFilterOptions?.data);
      setExcelFields(fieldFilterOptions?.data)
    }
  },[fieldFilterOptionsIsFetching ,fieldFilterOptionsIsLoading , fieldFilterOptions])

  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page, filteredPagination]);

  useEffect(() => {
    getid && deleteData(getid) && setGetid("");
  }, [getid]);

  const AddData = (data) => {
    addData(data);
  };
  const UpdateData = (data, id) => {
    updateLectureData({ body: data, id: id });
  };
  var totalData = "hello";
  if (AllData) {
    totalData = AllData.totalItem || 0;
    if (AllData?.status) {
      rows = AllData?.data?.map((data, index) =>
        createData(
          index + 1,
          data?.image ? (
            <div>
              <div className="d-flex justify-content-center">
                <Avatar src={data?.image[0]} />
              </div>
              <span className="text-info font-weight-bold">
                {data?.nickname}
              </span>
              <br />
            </div>
          ) : null,
          data?.course
            ? data?.course.map((ele, index) => {
                return index + 1 == data.course?.length
                  ? ele.course_name
                  : ele.course_name + ", ";
              })
            : null,
          data?.varient?.every((ele) => {
            if (ele?.mode_name != null) return true;
          }) ? (
            <div>
              <Varient
                varient={data?.varient}
                lectureId={data?._id}
                updateStatus={updateStatus}
              />
            </div>
          ) : null,
          data?.institute?.institute_name
            ? data?.institute?.institute_name
            : null,
          <>
            <div className="d-flex justify-content-center justify-items-center">
              <AuthWrapper type="ACTION" name="VIDEO_LECTURE_VIEW">
                <EditDelete
                  setViewDialog={setViewDialog}
                  type="view"
                  setData={setData}
                  setGetid={setGetid}
                  data={data}
                  onclick={() => {}}
                  setViewPage={setViewPage}
                />
              </AuthWrapper>
              <AuthWrapper type="ACTION" name="VIDEO_LECTURE_DELETE">
                {/* <EditDelete
                    setEditDialog={setEditDialog}
                    type="edit" setData={setData}
                    setGetid={setGetid} data={data} /> */}
                <EditDelete
                  type="delete"
                  setGetid={setGetid}
                  id={data._id}
                  onclick={() => {}}
                />
              </AuthWrapper>
              <AuthWrapper type="ACTION" name="VIDEO_LECTURE_COPY">
                <Tooltip title="Copy lecture url">
                  <IconButton
                    onClick={() => {
                      handleUrlCopyClick(
                        data?.video_lecture_name,
                        data?._id,
                        "NOT_SECURE"
                      );
                    }}
                    color="primary"
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </AuthWrapper>
              {data?.is_hidden ? (
                <>
                  <Tooltip title="Copy hidden lecture url">
                    <IconButton
                      onClick={() => {
                        handleUrlCopyClick(
                          data?.video_lecture_name,
                          data?._id,
                          "NOT_SECURE_HIDDEN"
                        );
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Copy hidden lecture secure url">
                    <IconButton
                      onClick={() => {
                        handleUrlCopyClick(
                          data?.video_lecture_name,
                          data?._id,
                          "SECURE"
                        );
                      }}
                      color="secondary"
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : null}
            </div>
            {data?.is_hidden ? (
              <div>
                <button
                  className="btn p-0 px-1 btn-sm btn-info text-light btn-round"
                  onClick={() => {
                    setHiddenLinkDialog(true);
                    setSelectedLecture(data?._id);
                  }}
                >
                  add user
                </button>
              </div>
            ) : null}
          </>
        )
      );
    }
  }
  const dialogclose = () => {
    setEditDialog(false);
    setViewDialog(false);
    setDialog(false);
    setRender(!render);
  };

  const handleUrlCopyClick = (name, id, type) => {
    const regex = / /gi;
    let nameToLink = name.replaceAll(regex, "-").toLowerCase();

    let url = "";
    if (type == "NOT_SECURE") {
      url =
        "https://smartlearningdestination.com/video-lecture/" +
        nameToLink +
        "/";
    }
    if (type == "NOT_SECURE_HIDDEN") {
      url =
        "https://smartlearningdestination.com/video-lecture/" +
        nameToLink +
        "/?s=0&lectureId=" +
        id;
    }
    if (type == "SECURE") {
      url =
        "https://smartlearningdestination.com/video-lecture/" +
        nameToLink +
        "/?s=1&lectureId=" +
        id;
    }
    navigator.clipboard.writeText(url);
    setOpenSnack(true);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => {
          setOpenSnack(false);
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const jsonString = JSON.stringify(filteredPagination);
  const encodedJsonString = encodeURIComponent(jsonString);

  var startJsonString = JSON.stringify(right);
  const startEncodedJsonString = encodeURIComponent(startJsonString);

  const handleExcelDownload = () => {
    let url = filteredPagination
      ? BASE_URL +
        "get-videolecture-sheet?token=" +
        token +
        "&search=" +
        searchValue +
        "&filter_by=" +
        encodedJsonString +
        `&columns=${startEncodedJsonString}`
      : BASE_URL +
        "get-videolecture-sheet?token=" +
        token +
        "&search=" +
        searchValue +
        `&columns=${startEncodedJsonString}`;

    let link = document.createElement("a");
    link.href = url;
    link.target = "blank";
    document.body.appendChild(link);
    link.click();
    setExcelDialog(false);
    setRight([]);
    setLeft(excelFields)
  };
  return (
    <div className="">
      {!viewDialog && (
        <>
          <AddDataHeader
            addFormName={"Add Video Lecture"}
            setPage={setPage}
            page={page}
            isSearch={
              !AuthWrapper({
                type: "ACTION",
                name: "VIDEO_LECTURE_LIST",
                inBoolean: true,
              })
            }
            isPagination={
              !AuthWrapper({
                type: "ACTION",
                name: "VIDEO_LECTURE_LIST",
                inBoolean: true,
              })
            }
            videoLectureDownloadTemplate={AuthWrapper({
              type: "ACTION",
              name: "VIDEO_LECTURE_DOWNLOAD_TEMPLATE",
              inBoolean: true,
            })}
            addExcelVideoLecture={AuthWrapper({
              type: "ACTION",
              name: "VIDEO_LECTURE_UPLOAD_EXCEL",
              inBoolean: true,
            })}
            downloadExcelVideoLecture={AuthWrapper({
              type: "ACTION",
              name: "VIDEO_LECTURE_DOWNLOAD_EXCEL",
              inBoolean: true,
            })}
            excelBtn={true}
            currentPageName="videoLecture"
            addBtn={true}
            totalItem={totalItem || 0}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setAddDialog={setDialog}
            searchBarApi={getAllData}
            paginationData={paginationData}
            isButton={true}
            Adddialog={AddDialog}
            form={<CourseForm AddData={AddData} setDialog={setDialog} />}
            filteredPagination={filteredPagination}
            onDownloadExcelClick={(searchValue) => {
              setExcelDialog(true);
              setSearchValue(searchValue);
            }}
          />

          <AuthWrapper type="ACTION" name="VIDEO_LECTURE_LIST">
            <Accordion className="">
              <AccordionSummary
                className="text-primary"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="d-flex align-items-center">
                  <FilterListIcon /> <span> Filter</span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="">
                <Filters
                  setFilteredPagination={setFilteredPagination}
                  isCourseFilter={true} 
                  isModeFilter
                  isLevelFilter={true}
                  isSubFilter={true}
                  isCategoryFilter={true}
                  isFacFilter={true}
                  isLangFilter={true}
                  isRatingFilter={true}
                  isGroupFilter={true}
                  isModuleFilter={true}
                  isSyllabusFilter={true}
                  isActiveFilter={true}
                  isFromFilter={false}
                  isToFilter={false}
                  module="videoLecture"
                />
              </AccordionDetails>
            </Accordion>
          </AuthWrapper>
        </>
      )}

      {deleteDataInfo.isLoading ||
      addDataInfo.isLoading ||
      updateDataInfo.isLoading ||
      updateStatusInfo.isLoading ? (
        <Loader />
      ) : (
        <>
          {!viewDialog ? (
            rows?.length ? (
              <CreateTableAddButton
                editDialog={editDialog}
                rows={rows}
                fullsize={true}
                editform={
                  <UpdateCourseForm
                    UpdateData={UpdateData}
                    editData={data}
                    editDialog={setEditDialog}
                  />
                }
                columns={getFilterdColumns(columns)}
                isButton={true}
                editFormName={"Edit Video Lecture"}
                isVerient={true}
              />
            ) : totalData == 0 ? (
              <DataNotFound />
            ) : (
              <SkeletonListing />
            )
          ) : (
            <ViewVideoLectureNew
              data={data}
              onClose={() => setViewDialog(false)}
            />
          )}
        </>
      )}
      {hiddenLinkDialog && (
        <LinkUserAddForm
          allUsers={allUsersListing?.data?.data}
          product_id={selectedLecture}
          hiddenLinkDialog={hiddenLinkDialog}
          setHiddenLinkDialog={setHiddenLinkDialog}
          // addHiddenLinkData={addHiddenLinkData}
          // deleteHiddenLinkData={deleteHiddenLinkData}
          // deleteHiddenLinkDatainfo={deleteHiddenLinkDatainfo}
          // getHiddenLinkDatainfo={getHiddenLinkDatainfo}
          // getHiddenLinkData={getHiddenLinkData}
        />
      )}
      {deleteDataInfo.data && (
        <Toster info={deleteDataInfo} dialogclose={dialogclose} />
      )}
      {addDataInfo.data && (
        <Toster info={addDataInfo} dialogclose={dialogclose} />
      )}
      {updateDataInfo && (
        <Toster info={updateDataInfo} dialogclose={dialogclose} />
      )}
      {updateStatusInfo && (
        <Toster info={updateStatusInfo} dialogclose={dialogclose} />
      )}
      {/* {addHiddenLinkDataInfo && <Toster info={addHiddenLinkDataInfo} dialogclose={dialogclose} />}
            {deleteHiddenLinkDatainfo && <Toster info={deleteHiddenLinkDatainfo} dialogclose={dialogclose} />} */}

      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={() => {
          setOpenSnack(false);
        }}
        message="Video Lecture URL Copied."
        action={action}
      />

      <Dialog
        open={excelDialog}
        size="large"
        onClose={() => setExcelDialog(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle> Select Fields for Excel Sheet </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container>
              <Grid xs={12}>
                <TransferList
                  left={left}
                  setLeft={setLeft}
                  right={right}
                  setRight={setRight}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="p-3">
          <Button
            className="bg-danger text-light"
            onClick={() => {
              setExcelDialog(false);
              setRight([]);
              setLeft(excelFields)
            }}
          >
            Close
          </Button>
          <Button
            className="bg-success text-light"
            onClick={() => handleExcelDownload()}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
