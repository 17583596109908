import React, { useState, useEffect } from "react";
import EditDelete from "./EditDelete";
import CreateTableAddButton from "./CreateTableAddButton";
import UpdateCategories from "./UpdateCategories";
import ViewCategory from "./ViewCategory";
import Toster from "./Toster";
import { Button, Switch } from "@material-ui/core";
import AddCategory from "./AddCategory";
import Loader from "./Loader";
import AddDataHeader from "./AddDataHeader";
import {
  useGetAllCategoryPaginationMutation,
  useDeleteCategoryMutation,
  useAddCategoryMutation,
  useUpdateCategoryMutation,
  useUpdateCategoryStatusMutation,
} from "../../../services/services";
import SkeletonListing from "./SkeletonListing";
import DataNotFound from "./DataNotFound";
import AuthWrapper from "../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../utils/getFilterFunction";

export default function Categories() {
  const columns = [
    {
      id: "categoryName",
      label: "Category Name",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    // {
    //     id: 'parentCategoryName',
    //     label: 'Parent Category Name',
    //     minWidth: 50,
    //     align: 'center',
    //     format: (value) => value.toLocaleString('en-US'),
    // },
    {
      id: "rank",
      label: "Rank",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "code",
      label: "Code",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },

    {
      id: "categoryIsAtive",
      label: "Category-is-ative",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      hidden: true,
      authName: ["CONFIGURATION_CATEGORY_ACTIVE_DEACTIVE"],
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: [
        "CONFIGURATION_CATEGORY_EDIT",
        "CONFIGURATION_CATEGORY_DELETE",
      ],
    },
  ];
  function createData(
    categoryName,
    parentCategoryName,
    rank,
    code,
    categoryIsAtive,
    actions
  ) {
    return {
      categoryName,
      parentCategoryName,
      rank,
      code,
      categoryIsAtive,
      actions,
    };
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: ["category_name"],
    page: page + 1,
  };

  let rows = [];

  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [AddDialog, setDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [getAllData, getAllDataInfo] = useGetAllCategoryPaginationMutation();
  const [deleteData, deleteDataInfo] = useDeleteCategoryMutation();
  const [addData, addDataInfo] = useAddCategoryMutation();
  const [updateCategoryData, updateDataInfo] = useUpdateCategoryMutation();
  const [updateStatus, updateStatusInfo] = useUpdateCategoryStatusMutation();
  const AllData = getAllDataInfo.data;
  const totalItem = AllData ? AllData.totalItem : 0;
  const [render, setRender] = useState(false);

  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page]);

  useEffect(() => {
    getid && deleteData(getid) && setGetid("");
  }, [getid]);

  const AddData = (data) => {
    addData(data);
  };
  const UpdateData = (data, id) => {
    updateCategoryData({ body: data, id: id });
  };
  let totalData = " ok";
  if (AllData) {
    totalData = AllData.totalItem;
    rows = AllData.data.map((data, index) =>
      createData(
        data.category_name,
        data.parent_category_name,
        data.order_rank,
        data.category_code,
        <AuthWrapper
          type="ACTION"
          name="CONFIGURATION_CATEGORY_ACTIVE_DEACTIVE"
        >
          <Switch
            onClick={() => {
              updateStatus(data._id);
            }}
            checked={data.is_active}
          />
        </AuthWrapper>,
        <div className="d-flex justify-content-center">
          {/* <EditDelete setViewDialog={setViewDialog}
                    type="view" setData={setData}
                    setGetid={setGetid} data={data} /> */}
          <AuthWrapper type="ACTION" name="CONFIGURATION_CATEGORY_EDIT">
            <EditDelete
              setEditDialog={setEditDialog}
              type="edit"
              setData={setData}
              setGetid={setGetid}
              data={data}
            />
          </AuthWrapper>
          <AuthWrapper type="ACTION" name="CONFIGURATION_CATEGORY_DELETE">
            <EditDelete type="delete" setGetid={setGetid} id={data._id} />
          </AuthWrapper>
        </div>
      )
    );
  }
  const dialogclose = () => {
    setEditDialog(false);
    setViewDialog(false);
    setDialog(false);
    setRender(!render);
  };
  return (
    <div>
      <AddDataHeader
        addFormName={"Add Category"}
        setPage={setPage}
        page={page}
        excelBtn={false}
        totalItem={totalItem}
        isSearch={
          !AuthWrapper({
            type: "ACTION",
            name: "CONFIGURATION_CATEGORY_LIST",
            inBoolean: true,
          })
        }
        isPagination={
          !AuthWrapper({
            type: "ACTION",
            name: "CONFIGURATION_CATEGORY_LIST",
            inBoolean: true,
          })
        }
        addBtn={
          !AuthWrapper({
            type: "ACTION",
            name: "CONFIGURATION_CATEGORY_ADD",
            inBoolean: true,
          })
        }
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setAddDialog={setDialog}
        searchBarApi={getAllData}
        paginationData={paginationData}
        isButton={true}
        Adddialog={AddDialog}
        fullWidth={true}
        form={<AddCategory AddData={AddData} setDialog={setDialog} />}
      />
      <AuthWrapper type="ACTION" name="CONFIGURATION_CATEGORY_LIST">
        {deleteDataInfo.isLoading ||
        addDataInfo.isLoading ||
        updateDataInfo.isLoading ||
        updateStatusInfo.isLoading ? (
          <Loader />
        ) : (
          <>
            {" "}
            {!viewDialog ? (
              rows.length ? (
                <CreateTableAddButton
                  editDialog={editDialog}
                  rows={rows}
                  editform={
                    <UpdateCategories
                      UpdateData={UpdateData}
                      editData={data}
                      editDialog={setEditDialog}
                    />
                  }
                  columns={getFilterdColumns(columns)}
                  isButton={true}
                  editFormName={"Edit Categories"}
                />
              ) : totalData == 0 ? (
                <DataNotFound />
              ) : (
                <SkeletonListing />
              )
            ) : (
              <ViewCategory viewDialog={setViewDialog} data={data} />
            )}
          </>
        )}
      </AuthWrapper>

      {deleteDataInfo.data && (
        <Toster info={deleteDataInfo} dialogclose={dialogclose} />
      )}
      {addDataInfo.data && (
        <Toster info={addDataInfo} dialogclose={dialogclose} />
      )}
      {updateDataInfo && (
        <Toster info={updateDataInfo} dialogclose={dialogclose} />
      )}
      {updateStatusInfo && (
        <Toster info={updateStatusInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
