import React from 'react'
import MaterialTable from 'material-table';
import { makeStyles, Tabs, Tab, AppBar, Chip } from "@material-ui/core";
import TabPanel from './CreateTabPanel';
import PropTypes from 'prop-types';
import { useGetUserWalletQuery } from '../../../services/services';
import CreateTableAddButton from "./CreateTableAddButton";
import SkeletonListing from "./SkeletonListing";
import DataNotFound from "./DataNotFound";
import AddDataHeader from './AddDataHeader';
import { IconButton } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function UserWallet(props) {

      
    const { data: UserWalletDetails } = useGetUserWalletQuery({
        user_id: props.userId,
        orderBy: "",
        limit: "",
        page: ""
    })
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const columns = [

        {
            id: "SNo",
            label: "S.No",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "transactionDate",
            label: "Tranaction Date",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },

        {
            id: "remark",
            label: "Remark",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },

        {
            id: "previousAmount",
            label: "Previous Amount",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "usedAmount",
            label: "Used Amount",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "balance",
            label: "Balance Amount",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
    ];

      

    function createData(SNo, transactionDate, remark, previousAmount, usedAmount, balance,) {
        return {
            SNo,
            transactionDate,
            remark,
            previousAmount,
            usedAmount,
            balance,
        };
    }
    let rows = [];
    const totalItem = UserWalletDetails ? UserWalletDetails.totalItem : 0;

      


    let totalData = "ok";

    if (
        UserWalletDetails
    ) {
        totalData = UserWalletDetails.totalItem;
        rows = UserWalletDetails.data.map((ele, index) => {
            return createData(
                index + 1,
                ele.transaction_date,
                ele.remark,
                <span className="text-danger"> {ele.expiry_date} </span>,
                <span className="text-primary"> {ele.previous_amount} </span>,

                ele.used_amount == 0 ?
                    <span className="text-success"> {ele.added_amount} <IconButton> <ArrowUpwardIcon fontSize="small" className="text-success" /> </IconButton>  </span> :
                    <span className="text-danger" >  {ele.used_amount} <IconButton> <ArrowDownwardIcon fontSize="small" className="text-danger" /> </IconButton> </span>,

                <span className="text-info">  {ele.remaining_amount} </span> ,
            );
        });
    }

      


    return (
        <div>
            {/* {!viewDialog && ( */}
            <AddDataHeader
                addBtn={true}
                // addFormName={"Add New Hall"}
                isSearch={true}
                totalItem={totalItem}
                isPagination={true}
                // setAddDialog={setAddDialog}
                isButton={true}
            // Adddialog={addDialog}
            // form={
            //     <FormDialog
            //         open={addDialog}
            //         setOpen={setAddDialog}
            //         formName={"Add New Hall"}
            //         btnName={"Save Hall"}
            //         onBtnClick={addBtnClickHandler}
            //         fields={[
            //             {
            //                 fieldType: "text",
            //                 fieldName: "Hall Name",
            //                 initialValue: "",
            //                 //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
            //                 accessKey: "hall_name",
            //             },
            //         ]}
            //     />
            // }
            />
            {/* )} */}


            <>
                {rows.length ? (
                    <CreateTableAddButton
                        // editDialog={editDialog}
                        // setPage={setPage}
                        rows={rows}
                        // editform={
                        //     <FormDialog
                        //         open={editDialog}
                        //         setOpen={setEditDialog}
                        //         formName={"Edit Expense Category "}
                        //         btnName={"Update"}
                        //         onBtnClick={updateBtnClickHandler}

                        //         fields={[
                        //             {
                        //                 fieldType: "text",
                        //                 fieldName: "Hall Name",
                        //                 initialValue: data.hall_name,
                        //                 //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                        //                 accessKey: "hall_name",
                        //             },
                        //         ]}
                        //     />
                        // }
                        columns={columns}
                        isButton={true}
                    // editFormName={"Edit Hall"}
                    />
                ) : totalItem == 0 ? (
                    <DataNotFound />
                ) : (
                    <SkeletonListing />
                )}

            </>


            {/* {addHallInfo && (
                <Toster info={addHallInfo} dialogclose={dialogclose} />
            )}

            {updateHallInfo && (
                <Toster info={updateHallInfo} dialogclose={dialogclose} />
            )}

            {deleteHallInfo && (
                <Toster info={deleteHallInfo} dialogclose={dialogclose} />
            )} */}

        </div>
    )
}
