import React, { useState, useEffect } from 'react';
import EditDelete from './EditDelete';
import CreateTableAddButton from './CreateTableAddButton'
import Toster from './Toster';
import { Radio } from '@material-ui/core';
import Loader from './Loader';
import {
    useGetAllSubjectsFilterMutation,
    useDeleteSubjectMutation,
    useAddSubjectMutation,
    useUpdateSubjectMutation,
} from '../../../services/services';
import SkeletonListing from './SkeletonListing';
import AddDataHeader from './AddDataHeader';
import DataNotFound from './DataNotFound';
import AddCallbackRequest from './AddCallbackRequest';
import EmailCallRequest from './EmailCallRequest';
import ViewCallbackRequest from './ViewCallbackRequest';
import AddVirtualClass from './AddVirtualClass';

export default function VirtualClass() {
    const columns = [
        { id: 'image', label: 'Image', minWidth: 50 },
        { id: 'clasheading', label: 'Class Heading', minWidth: 50 },
        {
            id: 'classdateandtine',
            label: 'Class Date & Time',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'numberlectures',
            label: ' Number of Lectures',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'classduration',
            label: 'Class Duration',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'fees',
            label: 'Fees',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'demolink',
            label: 'Demo Link',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'combofees',
            label: 'Combo Fees',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'status',
            label: 'Status',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'actions',
            label: 'Actions',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
    ];
    function createData(image, clasheading, classdateandtine, numberlectures, classduration, fees, demolink, combofees, status, actions) {
        return { image, clasheading, classdateandtine, numberlectures, classduration, fees, demolink, combofees, status, actions };
    }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const paginationData = {
        "limit": rowsPerPage,
        "query": "",
        "params": [
            "subject_name", "subject_code"
        ],
        "page": page + 1
    }

    let rows = []
    let [getid, setGetid] = useState('')
    const [data, setData] = useState('')
    const [AddDialog, setDialog] = useState(false)
    const [editDialog, setEditDialog] = useState(false)
    const [viewDialog, setViewDialog] = useState(false)
    const [getAllData, getAllDataInfo] = useGetAllSubjectsFilterMutation()
    const [deleteData, deleteDataInfo] = useDeleteSubjectMutation()
    const [addData, addDataInfo] = useAddSubjectMutation()
    const [updateSubjectData, updateDataInfo] = useUpdateSubjectMutation()
    const AllData = getAllDataInfo.data
    const totalItem = AllData ? AllData.totalItem : 0
    const [render, setRender] = useState(false)

    useEffect(() => {
        getAllData(paginationData)
    }, [render, rowsPerPage, page,])

    useEffect(() => {
        getid && deleteData(getid) && setGetid("")
    }, [getid])

    const AddData = (data) => {
        addData(data)
    }
    const UpdateData = (data, id) => {
        updateSubjectData({ body: data, id: id })
    }
    let totalData = "ok"
    if (AllData) {
        totalData = AllData.totalItem
        rows = AllData.data.map((data, index) => createData(
            <img src='https://via.placeholder.com/100x50?text=Class' />,
            'CA FINAL IDTL FASTTRACK BATCH BY VISHAL BHATTAD SIR...!!!',
            "16/08/2021 11:55PM",
            "30",
            "100 Hours",
            "550000",
            <a href='https://www.codiotics.com'>https://www.codiotics.com</a>,
            "450000",
            "Active",
            <div className="d-flex justify-content-center">
                <EditDelete setViewDialog={setViewDialog}
                    type="view" setData={setData}
                    setGetid={setGetid} data={data} />
                <EditDelete
                    setEditDialog={setEditDialog}
                    type="edit" setData={setData}
                    setGetid={setGetid} data={data} />
                <EditDelete type="delete"
                    setGetid={setGetid}
                    id={data._id} />
            </div>
        )
        )
    }
    const dialogclose = () => {
        setEditDialog(false)
        setViewDialog(false)
        setDialog(false)
        setRender(!render)
    }
      

    return (
        <div>
            <AddDataHeader
                addFormName={"Add Virtual Class"}
                setPage={setPage}
                page={page}
                excelBtn={false}
                totalItem={totalItem}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setAddDialog={setDialog}
                searchBarApi={getAllData}
                paginationData={paginationData}
                isButton={true}
                Adddialog={AddDialog}
                form={<AddVirtualClass AddData={AddData} setDialog={setDialog} />}
            />
            {deleteDataInfo.isLoading || addDataInfo.isLoading || updateDataInfo.isLoading ? <Loader /> : <>       {
                !viewDialog ?
                    rows.length ?
                        <CreateTableAddButton
                            editDialog={editDialog}
                            rows={rows}
                            editform={
                                <EmailCallRequest
                                    UpdateData={UpdateData}
                                    editData={data}
                                    editDialog={setEditDialog} />
                            }
                            columns={columns} isButton={true}
                            editFormName={'Call Back Request Reply'} />
                        : totalData == 0 ? <DataNotFound /> : <SkeletonListing />
                    :
                    <ViewCallbackRequest viewDialog={setViewDialog} data={data} />}
            </>}
            {deleteDataInfo.data && <Toster info={deleteDataInfo} dialogclose={dialogclose} />}
            {addDataInfo.data && <Toster info={addDataInfo} dialogclose={dialogclose} />}
            {updateDataInfo && <Toster info={updateDataInfo} dialogclose={dialogclose} />}

        </div>
    )
}

















// import React from 'react';
// import CreateTable from './CreateTable';
// import EditDelete from './EditDelete';
// import AddVirtualClass from './AddVirtualClass'

// const columns = [
//     { id: 'image', label: 'Image', minWidth: 50 },
//     { id: 'clasheading', label: 'Class Heading', minWidth: 50 },
//     {
//         id: 'classdateandtine',
//         label: 'Class Date & Time',
//         minWidth: 50,
//         align: 'center',
//         format: (value) => value.toLocaleString('en-US'),
//     },
//     {
//         id: 'numberlectures',
//         label: ' Number of Lectures',
//         minWidth: 50,
//         align: 'center',
//         format: (value) => value.toLocaleString('en-US'),
//     },
//     {
//         id: 'classduration',
//         label: 'Class Duration',
//         minWidth: 50,
//         align: 'center',
//         format: (value) => value.toLocaleString('en-US'),
//     },
//     {
//         id: 'fees',
//         label: 'Fees',
//         minWidth: 50,
//         align: 'center',
//         format: (value) => value.toLocaleString('en-US'),
//     },
//     {
//         id: 'demolink',
//         label: 'Demo Link',
//         minWidth: 50,
//         align: 'center',
//         format: (value) => value.toLocaleString('en-US'),
//     },
//     {
//         id: 'combofees',
//         label: 'Combo Fees',
//         minWidth: 50,
//         align: 'center',
//         format: (value) => value.toLocaleString('en-US'),
//     },
//     {
//         id: 'status',
//         label: 'Status',
//         minWidth: 50,
//         align: 'center',
//         format: (value) => value.toLocaleString('en-US'),
//     },
//     {
//         id: 'actions',
//         label: 'Actions',
//         minWidth: 50,
//         align: 'center',
//         format: (value) => value.toLocaleString('en-US'),
//     },
// ];
// function createData(image,clasheading,classdateandtine,numberlectures,classduration,fees,demolink,combofees,status,actions) {
//     return {image,clasheading,classdateandtine,numberlectures,classduration,fees,demolink,combofees,status,actions};
// }
// const rows = [
//     createData(<img src='https://via.placeholder.com/100x50?text=Class'/>, 'CA FINAL IDTL FASTTRACK BATCH BY VISHAL BHATTAD SIR...!!!',
//      "16/08/2021 11:55PM", "30", "100 Hours", "550000", <a href='https://www.codiotics.com'>https://www.codiotics.com</a>,
//      "450000","Active", <EditDelete type="delete" />),
    

// ];


// export default function VirtualClass(props) {

//     return (
//         <CreateTable isButton={true} form={<AddVirtualClass/>} addFormName={"Add Virtual Class"} rows={rows} columns={columns} />
//     )
// }
