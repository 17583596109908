import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Chip, Typography } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../CreateTabPanel";
import FacultyDetails from "../facultyDetails/FacultyDetails";
import FacultyImage from "../facultyDetails/FacultyImage";
import BulkDealsAndAdvancePayments from "../facultyDetails/BulkDealsAndAdvancePayments";
import { userAccess } from "../../../../helpers/userAccess";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Remarks from "../facultyDetails/Remarks";
import SocialMedia from "../facultyDetails/SocialMedia";
import Contacts from "../facultyDetails/Contacts";
import Address from "../facultyDetails/Address";
import GstDetails from "../facultyDetails/GstDetails";
import BankDetails from "../facultyDetails/BankDetails";
import Discounts from "../facultyDetails/Discounts";
import Links from "../facultyDetails/Links";
import Tds from "../facultyDetails/Tds";
import Institute from "../facultyDetails/Institute";
import PaymentDetails from "../facultyDetails/PaymentDetails";
import Commision from "../facultyDetails/Commision";
import CommisionRule from "../facultyDetails/CommisionRule";
import CommisionRuleFlow from "../facultyDetails/CommisionRuleFlow";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ViewFaculties({ viewDialog, data, allSpecialization }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [facultyData, setFacultyData] = useState(data);

  return (
    <div className={classes.root}>
      <Card sx={{ display: "flex" }} className="m-3">
        <CardMedia
          component="img"
          sx={{ width: 60, height: 60 }}
          image={facultyData.image}
          alt="Display Picture"
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography component="div" variant="h6">
              {facultyData.name}
            </Typography>
          </CardContent>
        </Box>
      </Card>

      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="scrollable"
          sx={{ borderRight: 1, borderColor: "divider" }}
          style={{ maxWidth: "85vw" }}
        >
          <Tab
            style={{ textTransform: "capitalize" }}
            label="Details"
            {...a11yProps(0)}
          />
          <Tab
            style={{ textTransform: "capitalize" }}
            label="Image"
            {...a11yProps(1)}
          />
          <Tab
            style={{ textTransform: "capitalize" }}
            label="Remarks"
            {...a11yProps(2)}
          />
          <Tab
            style={{ textTransform: "capitalize" }}
            label="Social Media"
            {...a11yProps(3)}
          />
          <Tab
            style={{ textTransform: "capitalize" }}
            label="Contacts"
            {...a11yProps(4)}
          />
          <Tab
            style={{ textTransform: "capitalize" }}
            label="Address"
            {...a11yProps(5)}
          />
          <Tab
            style={{ textTransform: "capitalize" }}
            label="GST"
            {...a11yProps(6)}
          />
          <Tab
            style={{ textTransform: "capitalize" }}
            label="Bank Details"
            {...a11yProps(7)}
          />
          <Tab
            style={{ textTransform: "capitalize" }}
            label="Discount"
            {...a11yProps(8)}
          />
          <Tab
            style={{ textTransform: "capitalize" }}
            label="Links"
            {...a11yProps(9)}
          />
          {/* <Tab
              style={{ textTransform: "capitalize" }}
              label="TDS"
              {...a11yProps(10)}
            /> */}
          <Tab
            style={{ textTransform: "capitalize" }}
            label="Institute"
            {...a11yProps(10)}
          />
          <Tab
            style={{ textTransform: "capitalize" }}
            label="Payment Details"
            {...a11yProps(11)}
          />
          {/* <Tab
              style={{ textTransform: "capitalize" }}
              label="Commission"
              {...a11yProps(12)}
            /> */}
          <Tab
            style={{ textTransform: "capitalize" }}
            label="Commission Rules"
            {...a11yProps(13)}
          />
          <Tab
            style={{ textTransform: "capitalize" }}
            label="Commission Rules Flow"
            {...a11yProps(14)}
          />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <FacultyDetails
          viewDialog={viewDialog}
          data={facultyData}
          setFacultyData={setFacultyData}
          allSpecialization={allSpecialization}
        />
      </TabPanel>
      <TabPanel style={{ paddingBottom: 50 }} value={value} index={1}>
        <FacultyImage
          viewDialog={viewDialog}
          data={facultyData}
          setFacultyData={setFacultyData}
        />
      </TabPanel>
      <TabPanel style={{ paddingBottom: 50 }} value={value} index={2}>
        <Remarks
          viewDialog={viewDialog}
          data={facultyData}
          setFacultyData={setFacultyData}
        />
      </TabPanel>
      <TabPanel style={{ paddingBottom: 50 }} value={value} index={3}>
        <SocialMedia
          viewDialog={viewDialog}
          data={facultyData}
          setFacultyData={setFacultyData}
        />
      </TabPanel>
      <TabPanel style={{ paddingBottom: 50 }} value={value} index={4}>
        <Contacts
          viewDialog={viewDialog}
          data={facultyData}
          setFacultyData={setFacultyData}
        />
      </TabPanel>
      <TabPanel style={{ paddingBottom: 50 }} value={value} index={5}>
        <Address
          viewDialog={viewDialog}
          data={facultyData}
          setFacultyData={setFacultyData}
        />
      </TabPanel>
      <TabPanel style={{ paddingBottom: 50 }} value={value} index={6}>
        <GstDetails
          viewDialog={viewDialog}
          data={facultyData}
          setFacultyData={setFacultyData}
        />
      </TabPanel>
      <TabPanel style={{ paddingBottom: 50 }} value={value} index={7}>
        <BankDetails
          viewDialog={viewDialog}
          data={facultyData}
          setFacultyData={setFacultyData}
        />
      </TabPanel>
      <TabPanel style={{ paddingBottom: 50 }} value={value} index={8}>
        <Discounts
          viewDialog={viewDialog}
          data={facultyData}
          setFacultyData={setFacultyData}
        />
      </TabPanel>
      <TabPanel style={{ paddingBottom: 50 }} value={value} index={9}>
        <Links
          viewDialog={viewDialog}
          data={facultyData}
          setFacultyData={setFacultyData}
        />
      </TabPanel>
      {/* <TabPanel style={{ paddingBottom: 50 }} value={value} index={10}>
        <Tds viewDialog={viewDialog} data={facultyData} setFacultyData={setFacultyData} />
      </TabPanel> */}
      <TabPanel style={{ paddingBottom: 50 }} value={value} index={10}>
        <Institute
          viewDialog={viewDialog}
          data={facultyData}
          setFacultyData={setFacultyData}
        />
      </TabPanel>
      <TabPanel style={{ paddingBottom: 50 }} value={value} index={11}>
        <PaymentDetails
          viewDialog={viewDialog}
          data={facultyData}
          setFacultyData={setFacultyData}
        />
      </TabPanel>
      {/* <TabPanel style={{ paddingBottom: 50 }} value={value} index={12}>
        <Commision viewDialog={viewDialog} data={facultyData} setFacultyData={setFacultyData} />
      </TabPanel> */}
      <TabPanel style={{ paddingBottom: 50 }} value={value} index={13}>
        <CommisionRule
          viewDialog={viewDialog}
          data={facultyData}
          setFacultyData={setFacultyData}
        />
      </TabPanel>
      <TabPanel style={{ paddingBottom: 50 }} value={value} index={14}>
        <CommisionRuleFlow
          viewDialog={viewDialog}
          data={facultyData}
          setFacultyData={setFacultyData}
        />
      </TabPanel>
    </div>
  );
}
