import React, { Component, useState } from "react";
import clsx from "clsx";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  MenuIcon,
  Grid,
  MenuItem,
  Menu,
  AccountCircle,
  MoreIcon,
  withStyles,
  ThemeProvider,
  fade,
} from "../constant/material";
import PersonIcon from "@material-ui/icons/Person";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { drawerWidth } from "../constant/layout";
import { Button } from "@material-ui/core";
import axios from "axios";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem("__authToken__");
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CircularProgress } from "@mui/material";
import { CRM_URL } from "../constant/BaseURL";
import { BOOKS_URL } from "../constant/BaseURL";

const loginUserType = localStorage.getItem("authType");

const current_timer = "STOP";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
});

class TheHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      anchorEl: null,
      mobileMoreAnchorEl: null,
    };

    this.handleDrawerOpen = this.props.handleDrawerOpen;
    this.setTimerData = this.props.setTimerData;
    this.state = { currentTime: Date.now() };
  }

  static getDerivedStateFromProps(props, state) {
    state.open = props.open;
    return state;
  }

  componentDidMount() {
    this.handleTimerSheet();
  }

  handleDrawer = () => {
    this.setState({ open: !this.state.open });
    this.handleDrawerOpen();
  };

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleTimerStart = () => {
    axios
      .post(
        BASE_URL + "/time-sheet/start-timer",
        { remark: "" },
        { headers: { "x-access-token": token } }
      )
      .then((response) => {
        if (response.data.status) {
          toast(response.data.message);
          this.handleTimerSheet();
        } else {
          toast(response.data.message);
        }
      })
      .catch((err) => {});
  };

  handleTimerSheet = () => {
    axios
      .get(BASE_URL + "/time-sheet/active-timer", {
        headers: { "x-access-token": token },
      })
      .then((response) => {
        if (response.data.status) {
          this.setState({ setTimerData: response });
        }
      })
      .catch((err) => {});
  };

  handleTimerStop = (id) => {
    axios
      .put(
        BASE_URL + `/time-sheet/stop-timer/${id}`,
        { remark: "" },
        { headers: { "x-access-token": token } }
      )
      .then((response) => {
        if (response.data.status) {
          toast(response.data.message);
          this.setState({ setTimerData: null });
        } else {
          toast(response.data.message);
        }
      })
      .catch((err) => {});
  };

  getUserCurrentTimerData = () => {
    axios
      .get(BASE_URL + "/time-sheet/active-timer-of-user", {
        headers: { "x-access-token": token },
      })
      .then((response) => {
        if (response.data.status) {
          toast(response.data.message);
        } else {
          toast(response.data.message);
        }
      })
      .catch((err) => {});
  };

  render() {
    const isMenuOpen = Boolean(this.state.anchorEl);
    const isMobileMenuOpen = Boolean(this.state.mobileMoreAnchorEl);
    const timerData = this?.state?.setTimerData;
    const liveTime = this.state?.currentTime;
    const timeString = timerData?.data?.data?.timer_start;
    const loginTime = Date.parse(timeString);
    setTimeout(() => {
      timerData ? this.setState({ currentTime: Date.now() }) : null;
    }, 1000);
    let changedTime = liveTime - loginTime;

    const hours = Math.floor((changedTime / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((changedTime / 1000 / 60) % 60);
    const seconds = Math.floor((changedTime / 1000) % 60);
    const menuId = "primary-search-account-menu";
    const renderMenu = (
      <Menu
        anchorEl={this.state.anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
        style={{ width: "500px" }}
      >
        {/* <MenuItem onClick={this.handleMenuClose}  ><PersonIcon style={{ marginRight: 5 }} />Profile</MenuItem> */}
        <MenuItem
          onClick={() => {
            localStorage.removeItem("__authToken__");
            localStorage.removeItem("authType");
            localStorage.removeItem("userData");
            localStorage.setItem("isLoggedIn", "false");
            localStorage.removeItem("accessModules");
            localStorage.removeItem("modules");
            window.location.replace("/");
          }}
        >
          {" "}
          <PowerSettingsNewIcon style={{ marginRight: 5 }} />
          Logout
        </MenuItem>
        {/* <MenuItem onClick={this.handleMenuClose}  ><AccountBoxIcon style={{ marginRight: 5 }} />My account</MenuItem> */}
        {loginUserType === "SLD_USER" ? (
          <div>
            {" "}
            <MenuItem className="text-success">
              {" "}
              <Typography style={{ fontSize: "15px" }}>
                Logged In Time
              </Typography>{" "}
            </MenuItem>
            <MenuItem className="d-flex justify-content-center fs-6 text-success pt-1">
              {liveTime <= loginTime ? (
                <CircularProgress />
              ) : timerData ? (
                `${hours}:${minutes}:${seconds} `
              ) : null}
            </MenuItem>
            <MenuItem className="text-success text-center">
              {timerData ? (
                <Button
                  variant="contained"
                  color={current_timer == "STOP" ? "primary" : "secondary"}
                  onClick={() => {
                    this.handleTimerStop(timerData?.data?.data?._id);
                  }}
                >
                  Stop
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color={current_timer == "STOP" ? "primary" : "secondary"}
                  onClick={() => {
                    this.handleTimerStart();
                  }}
                >
                  Start
                </Button>
              )}
            </MenuItem>
          </div>
        ) : null}
      </Menu>
    );

    const mobileMenuId = "primary-search-account-menu-mobile";
    const renderMobileMenu = (
      <Menu
        anchorEl={this.state.mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}
      >
        <MenuItem onClick={this.handleProfileMenuOpen}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    );

    const { classes } = this.props;
    const { theme } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: this.state.open,
            })}
          >
            <Toolbar>
              <Grid container alignItems="center" alignContent="space-between">
                <Grid item md={4} sm={4}>
                  <div className="d-flex align-items-center">
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={this.handleDrawer}
                      edge="start"
                      className={clsx(classes.menuButton, {
                        [classes.hide]: this.state.open,
                      })}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Typography className={classes.title} variant="h6" noWrap>
                      SLD
                    </Typography>
                  </div>
                </Grid>
                <Grid item md={8} sm={8}>
                  <div className="d-flex align-items-center justify-content-end">
                    <div className={classes.grow} />
                    <div
                      className={`${classes.sectionDesktop}d-flex align-items-center justify-content-center`}
                    >   <Button
                    className="h-75  text-white  border border-primary-subtle cursor-pointer  px-3  mr-4"
                    target="_blank"
                    size="small"
                    variant="contained"
                    color="primary"
                    href={`${BOOKS_URL}/?authtoken=${localStorage.getItem(
                      "__authToken__"
                    )}&userData=${localStorage.getItem("userData")}&authType=${localStorage.getItem("authType")}&redirect=/`}
                  >
                    Log in To Book
                  </Button>
                      <Button
                        className="h-75  text-white  border border-primary-subtle cursor-pointer  px-3 "
                        target="_blank"
                        size="small"
                        variant="contained"
                        color="primary"
                        href={`${CRM_URL}/?authToken=${localStorage.getItem(
                          "__authToken__"
                        )}&userData=${localStorage.getItem("userData")}&authType=${localStorage.getItem("authType")}`}
                      >
                        Log in To CRM
                      </Button>
                      <IconButton
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={this.handleProfileMenuOpen}
                        color="inherit"
                      >
                        <AccountCircle />
                      </IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                      <IconButton
                        aria-label="show more"
                        aria-controls={mobileMenuId}
                        aria-haspopup="true"
                        onClick={this.handleMobileMenuOpen}
                        color="inherit"
                      >
                        <MoreIcon />
                      </IconButton>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          {renderMobileMenu}
          {renderMenu}
        </div>
        <ToastContainer />
      </ThemeProvider>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(TheHeader);
