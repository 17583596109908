import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import {
    TableCell, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, DialogContentText, Item
} from '@material-ui/core';
import {
    useGetUserHiddenEmailQuery,
    useAddHiddenLinkMutation,
    useDeleteHiddenEmailMutation
} from '../../../services/services';
import Autocomplete from '@mui/material/Autocomplete';
import Toster from './Toster';
import { Grid } from '@mui/material';

const LinkUserAddForm = function (props) {

    // const [addLink, setAddLink] = useState({})
    const [addHiddenLinkData, addHiddenLinkDataInfo] = useAddHiddenLinkMutation()
    const getHiddenLinkData = useGetUserHiddenEmailQuery({ product_id: props.product_id, product_type: "SLD_VIDEO_LECTURE" })
    const [deleteHiddenLinkData, deleteHiddenLinkDatainfo] = useDeleteHiddenEmailMutation()
    useEffect(() => {
    }, [props.product_id])

    const handleCloseLink = (ele, e) => {
        e.preventDefault()
        deleteHiddenLinkData(ele)
    }




    const formik = useFormik({
        initialValues: {
            product_id: props.product_id,
            product_type: "SLD_VIDEO_LECTURE",
            user_id: ""
        },
        validationSchema: Yup.object({
            user_id: Yup.object()
                .required('This field is required.')
        }),
        onSubmit: values => {
            values.user_id = values.user_id._id
            addHiddenLinkData(values)
        }
    })



    return (
        <>
            <div>
                <Dialog fullWidth open={props.hiddenLinkDialog} onClose={() => { props.setHiddenLinkDialog(false) }}>

                    <DialogTitle>{props.formName}</DialogTitle>
                    <DialogContent >
                       
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container >
                                    <Grid item xs={10}>
                                        <Autocomplete
                                            variant="outlined"
                                            fullWidth={true}
                                            size="small"
                                            id={"userId"}
                                            value={formik.values.user_id}
                                            onChange={(e, newValue) => {
                                                formik.setFieldValue("user_id", newValue)
                                            }}
                                            options={props.allUsers}
                                            getOptionLabel={(option) => {
                                                return option["email"] || ""
                                            }}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    {option["email"]}   
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField label="Choose User" variant="outlined" {...params} />
                                            )}
                                        />
                                        {formik.touched.user_id && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.user_id}</span>}
                                    </Grid>
                                    <Grid item container justifyContent={"flex-end"} xs={2}>
                                      <Button variant="contained" color="primary" type="submit" >save</Button>
                                    </Grid>
                                </Grid>
                            </form>


                        
                        {<DialogContentText >
                            
                                {getHiddenLinkData?.data?.data?.map((ele) => {

                                    return (
                                        <Grid container className="bg-light mt-3 p-2 rounded border border-muted" >
                                            <Grid xs={11}>
                                                    {ele.email}
                                            </Grid>
                                            <Grid xs={1}>
                                                <Button type="button" size="small" variant='content' color="secondary" onClick={(e) => handleCloseLink(ele._id, e)}> x</Button>
                                            </Grid>
                                        </Grid>
                                    )
                                }

                                )}
                            
                        </DialogContentText>}

                    </DialogContent>





                    <DialogActions>


                        <Button onClick={() => { props.setHiddenLinkDialog(false) }} variant="contained" color="secondary" >Cancel</Button>

                    </DialogActions>

                </Dialog>
                {addHiddenLinkDataInfo && <Toster info={addHiddenLinkDataInfo} />}
                {deleteHiddenLinkDatainfo && <Toster info={deleteHiddenLinkDatainfo} />}
            </div>
        </>
    )
}


export default LinkUserAddForm