import React, { useState } from "react";
import FormDialog from "./reusableComponents/NewFormDialog";
import Toster from "./Toster";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  CardActionArea,
  CardActions,
} from "@material-ui/core";
import {
  useGetCmsQuery,
  useUpdateCmsStatusMutation,
  useUpdateCMSDataMutation,
} from "../../../services/cmsServices";
import DataNotFound from "./DataNotFound";
import EditDelete from "./EditDelete";
import { phoneRegex, linkRegex } from "../../../constant/regularExpression";
import * as yup from "yup";
import AuthWrapper from "../../../utils/AuthWrapper";

export default function MultiActionAreaCard() {
  const [editDialog, setEditDialog] = useState(false);
  const [data, setData] = useState("");

  const {
    data: AllData,
    isFetching,
    isLoading,
  } = useGetCmsQuery({ key: "free_mentoring" });

  const [updateInnerData, updateInnerDataInfo] = useUpdateCMSDataMutation();
  const [updateIsShowStatus, updateIsShowInfo] = useUpdateCmsStatusMutation();

  const UpdateData = (data) => {
    updateIsShowStatus(data);
  };

  const onClickHandler = (dataForUpdate) => {
    const cms_data = JSON.stringify(dataForUpdate);
    let formData = new FormData();

    formData.append("key", "free_mentoring");
    formData.append("cms_data", cms_data);
    formData.append("image", dataForUpdate.image);

    updateInnerData(formData);
  };

  const dialogclose = () => {
    setEditDialog(false);
  };

  return (
    <>
      {AllData != undefined && AllData.data.length ? (
        <div>
          <AuthWrapper type="ACTION" name="CMS_CONTACT_MENTORING_HIDE">
            {AllData != undefined ? (
              <Grid item xs={12} className="pt-4">
                {AllData.data[0].free_mentoring.is_show ? (
                  // <<<<<<<<<<<<<<<<<<<<< IF IS_SHOW IS TRUE THEN ""HIDE BUTTON"" ENABLED >>>>>>>>>>>>>>>>>>>>>>>>>>>>

                  <Button
                    variant="contained"
                    onClick={() => {
                      UpdateData({
                        key: "free_mentoring",
                        cms_data: {
                          is_show: false,
                        },
                      });
                    }}
                    color="secondary"
                  >
                    Hide
                  </Button>
                ) : (
                  // <<<<<<<<<<<<<<<<<<<<<<<< IF IS_SHOW IS FALSE THEN ""SHOW BUTTON"" ENABLED >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

                  <Button
                    variant="contained"
                    onClick={() => {
                      UpdateData({
                        key: "free_mentoring",
                        cms_data: {
                          is_show: true,
                        },
                      });
                    }}
                    color="primary"
                  >
                    {" "}
                    Show{" "}
                  </Button>
                )}
              </Grid>
            ) : null}
          </AuthWrapper>
          <AuthWrapper type="ACTION" name="CMS_CONTACT_MENTORING_LIST">
            {isLoading || isFetching ? (
              "Loading"
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={4} className="pt-4">
                  <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea>
                      <CardMedia
                        style={{ width: "100%" }}
                        component="img"
                        image={AllData.data[0].free_mentoring.image}
                        alt="Confused"
                      />
                      <CardContent>
                        <Typography variant="h6" component="div">
                          Whatsapp Number
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          color="primary"
                        >
                          {AllData.data[0].free_mentoring.whatsapp}
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                          Description
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {AllData.data[0].free_mentoring.description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <AuthWrapper
                      type="ACTION"
                      name="CMS_CONTACT_MENTORING_EDIT"
                    >
                      <CardActions>
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <EditDelete
                            setEditDialog={setEditDialog}
                            type="edit"
                            setData={setData}
                            data={AllData.data[0].free_mentoring}
                          />
                        </Grid>
                      </CardActions>
                    </AuthWrapper>
                  </Card>
                </Grid>
              </Grid>
            )}
          </AuthWrapper>
          {editDialog && (
            <FormDialog
              open={editDialog}
              setOpen={setEditDialog}
              formName={"Update Mentoring"}
              btnName={"Update"}
              onBtnClick={onClickHandler}
              // useAs="form"

              fields={[
                {
                  fieldType: "file",
                  // fieldName: "Image",
                  initialValue: null,
                  accessKey: "image",
                },
                {
                  fieldType: "text",
                  fieldName: "Whatsapp No.",
                  initialValue: AllData.data[0].free_mentoring.whatsapp,
                  accessKey: "whatsapp",
                  validation: yup
                    .string()
                    .matches(phoneRegex, "Phone number is not valid")
                    .required("Required !"),
                },
                {
                  fieldType: "text",
                  fieldName: "Description",
                  initialValue: AllData.data[0].free_mentoring.description,
                  accessKey: "description",
                  validation: yup.string().required("Required !"),
                },
              ]}
            />
          )}

          {updateIsShowInfo.data && (
            <Toster info={updateIsShowInfo} dialogclose={dialogclose} />
          )}

          {updateInnerDataInfo.data && (
            <Toster info={updateInnerDataInfo} dialogclose={dialogclose} />
          )}
        </div>
      ) : (
        <DataNotFound />
      )}
    </>
  );
}

// import React, { useState, useEffect } from 'react';
// import EditDelete from './EditDelete';
// import CreateTableAddButton from './CreateTableAddButton'
// import { Avatar, Link } from '@material-ui/core';
// import UpdateFaculty from './faculty/UpdateFaculty'
// import Loader from './Loader'
// import AddDataHeader from './AddDataHeader';
// import {
//     useGetAllSocialNetworksQuery,
//     useAddSocialNetworkMutation,
//     useDeleteSocialNetworkMutation,
//     useUpdateFacultyMutation,
// } from '../../../services/services';
// import Toster from './Toster';
// import AddFaculty from './AddFaculty';
// import ViewFaculties from './faculty/ViewFaculties';
// import SkeletonListing from './SkeletonListing';
// import DataNotFound from './DataNotFound';
// import AddSocial from './AddSocial';
// import AddMentoring from './AddMentoring';

// export default function Mentoring() {
//     const columns = [
//         {
//             id: 'SNo',
//             label: 'S.No',
//             minWidth: 50,
//             align: 'center',
//             format: (value) => value.toLocaleString('en-US'),
//         },
//         {
//             id: 'Image',
//             label: 'Image',
//             minWidth: 50,
//             align: 'center',
//             format: (value) => value.toLocaleString('en-US'),
//         },
//         {
//             id: 'name',
//             label: 'Social Network Name',
//             minWidth: 50,
//             align: 'center',
//             format: (value) => value.toLocaleString('en-US'),
//         },
//         {
//             id: 'description',
//             label: 'Description',
//             minWidth: 50,
//             align: 'center',
//             format: (value) => value.toLocaleString('en-US'),
//         }, {
//             id: 'IsActive',
//             label: 'IsActive',
//             minWidth: 50,
//             align: 'center',
//             format: (value) => value.toLocaleString('en-US'),
//         },

//         {
//             id: 'Actions',
//             label: 'Actions',
//             minWidth: 50,
//             align: 'center',
//             format: (value) => value.toLocaleString('en-US'),
//         },
//     ];
//     function createData(SNo, Image, name, description, IsActive, Actions) {
//         return { SNo, Image, name, description, IsActive, Actions };
//     }
//     const [page, setPage] = React.useState(0);
//     const [rowsPerPage, setRowsPerPage] = React.useState(10);

//     const paginationData = {
//         "limit": rowsPerPage,
//         "query": "",
//         "params": [
//             "name"
//         ],
//         "page": page + 1
//     }

//     let rows = []
//     let [getid, setGetid] = useState('')
//     const [data, setData] = useState('')
//     const [AddDialog, setDialog] = useState(false)
//     const [editDialog, setEditDialog] = useState(false)
//     const [viewDialog, setViewDialog] = useState(false)
//     const getAllData = useGetAllSocialNetworksQuery()
//     const [deleteFaculty, deleteFacultyInfo] = useDeleteSocialNetworkMutation()
//     const [addData, addDataInfo] = useAddSocialNetworkMutation();
//     const [updateData, updateDataInfo] = useUpdateFacultyMutation()
//     const AallFaculties = getAllData.data
//     const totalItem = AallFaculties ? AallFaculties.totalItem : 0
//     const [render, setRender] = useState(false)

//     useEffect(() => {
//         getid && deleteFaculty(getid) && setGetid("")
//     }, [getid])

//     const AddData = (data) => {
//         addData(data)
//     }
//     const UpdateData = (data, id) => {
//         updateData({
//             data,
//             id: id
//         })
//     }
//     let totalData = "ok"
//     if (AallFaculties) {
//         totalData = AallFaculties.totalItem
//         rows = AallFaculties.data.map((data, index) => createData(
//             index + 1,
//             <img
//                 width={60}
//                 height={60}
//                 src={data.image}
//                 alt=""
//                 className="img-fluid rounded "
//             />,
//             <Link target='_blank' href={data.link}>{data.social_media_name}</Link>,
//             data.description,
//             data.is_active ? "Yes" : "No",
//             <div className='d-flex justify-content-center'>
//                 <EditDelete setViewDialog={setViewDialog}
//                     type="view" setData={setData}
//                     setGetid={setGetid} data={data} />

//                 <EditDelete
//                     setEditDialog={setEditDialog}
//                     type="edit" setData={setData}
//                     setGetid={setGetid} data={data} />

//                 <EditDelete type="delete"
//                     setGetid={setGetid}
//                     id={data._id} />
//             </div>)
//         )
//     }

//     useEffect(() => {
//     }, [render])

//     const dialogclose = () => {
//         setEditDialog(false)
//         setViewDialog(false)
//         setDialog(false)
//         setRender(!render)
//         window.location.reload(false)
//     }
//     return (
//         <div>
//             {!viewDialog && <AddDataHeader
//                 addFormName={"Add Mentoring"}
//                 setPage={setPage}
//                 page={page}
//                 excelBtn={false}
//                 isSearch={true}
//                 isPagination={true}
//                 totalItem={totalItem}
//                 rowsPerPage={rowsPerPage}
//                 setRowsPerPage={setRowsPerPage}
//                 setAddDialog={setDialog}
//                 searchBarApi={getAllData}
//                 paginationData={paginationData}
//                 isButton={true}
//                 Adddialog={AddDialog}
//                 form={<AddMentoring AddData={AddData} setDialog={setDialog} />}
//             />}
//             {deleteFacultyInfo.isLoading || updateDataInfo.isLoading || addDataInfo.isLoading ? <Loader /> :
//                 <>
//                     {!viewDialog ?
//                         rows.length ?
//                             <CreateTableAddButton
//                                 editDialog={editDialog}
//                                 rows={rows}
//                                 editform={
//                                     <UpdateFaculty
//                                         UpdateData={UpdateData}
//                                         editfaculty={data}
//                                         editDialog={setEditDialog} />
//                                 }
//                                 columns={columns} isButton={true}
//                                 editFormName={'Edit Faculty'} />
//                             : totalData == 0 ? <DataNotFound /> : <SkeletonListing />
//                         :
//                         <ViewFaculties viewDialog={setViewDialog} data={data} />
//                     }
//                 </>
//             }
//             {addDataInfo.data && <Toster info={addDataInfo} dialogclose={dialogclose} />}
//             {deleteFacultyInfo.data && <Toster info={deleteFacultyInfo} dialogclose={dialogclose} />}
//             {updateDataInfo && <Toster info={updateDataInfo} dialogclose={dialogclose} />}
//         </div>
//     )
// }
