import React, { useState, useEffect } from 'react';
import EditDelete from './EditDelete';
import CreateTableAddButton from './CreateTableAddButton'
import UpdateUserGroup from './UpdateUserGroup';
import AddUserGroup from './AddUserGroup';
import {
    TextField,
    Grid,
    FormGroup,
    FormControlLabel,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    Zoom,
    Button
} from '@material-ui/core';
import Loader from './Loader';
import AddDataHeader from './AddDataHeader'
import {
    useGetAllUserGroupMutation,
    useAddUserGroupMutation,
    useDeletUserGroupMutation,
    useUpdateUserGroupMutation,

} from '../../../services/services';
import Toster from './Toster';
import SkeletonListing from './SkeletonListing';
import ViewUserGroup from './ViewUserGroup';
import DataNotFound from './DataNotFound';
import moment from 'moment';
import KeyGroupAccess from './KeyGroupAccess';




export default function UserGroup() {
    const [keyAccess, setKeyAccess] = useState(false)
    const columns = [
        {
            id: 'SNo',
            label: 'S.No',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        { id: 'usergroupname', align: 'center', label: 'Group Name', minWidth: 50 },
        { id: 'modulename', align: 'center', label: 'Module Name', minWidth: 50, },
        { id: 'createdat', align: 'center', label: 'Created At', minWidth: 50 },
        { id: 'active', align: 'center', label: 'Active', minWidth: 50 },
        { id: 'actions', align: 'center', label: 'Actions', minWidth: 50 },
    ];
    function createData(SNo, usergroupname, modulename, createdat, active, actions) {
        return { SNo, usergroupname, modulename, createdat, active, actions };
    }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const paginationData = {
        "limit": rowsPerPage,
        "query": "",
        "params": [
            "group_name"
        ],
        "page": page + 1
    }
    let rows = []
    let [getid, setGetid] = useState('')
    const [data, setData] = useState('')
    const [AddDialog, setDialog] = useState(false)
    const [editDialog, setEditDialog] = useState(false)
    const [viewDialog, setViewDialog] = useState(false)
    const [getAllData, getAllDataInfo] = useGetAllUserGroupMutation()
    const [deleteData, deleteDataInfo] = useDeletUserGroupMutation()
    const [addData, addDataInfo] = useAddUserGroupMutation();
    const [updateData, updateDataInfo] = useUpdateUserGroupMutation()
    const AllData = getAllDataInfo.data
    const totalItem = AllData ? AllData.totalItem : 0
    const [render, setRender] = useState(false)

    useEffect(() => {
        getAllData(paginationData)
    }, [render, rowsPerPage, page,])

    useEffect(() => {
        getid && deleteData(getid) && setGetid("")
    }, [getid])

    const AddData = (data) => {
        addData(data)
    }
    const UpdateData = (data, id) => {
          
        updateData({
            body: data,
            id: id
        })
    }
    let totalData = "ok"
    if (AllData) {
        totalData = AllData.totalItem
        let arrr = ["mode", "user", "institute", "order", "category"]
        rows = AllData.data.map((data, index) => createData(
            index + 1,
            <Button color="primary">{data.group_name}</Button>,
            <div className=''>
                {
                    data.module_group.map((e, i) => { return <span style={{ "fontSize": "13px" }} className='badge badge-info m-1 py-1 px-2 rounded' > {e} </span> })
                }
            </div>,
            moment(data.createdAt).format("DD-MM-YYYY hh:mm:ss A"),
            data.is_active ? "Yes" : "No",
            <div className="d-flex justify-content-center">
                <EditDelete setViewDialog={setViewDialog}
                    type="view" setData={setData}
                    setGetid={setGetid} data={data}
                    id="" />
                <EditDelete
                    setEditDialog={setEditDialog}
                    type="edit" setData={setData}
                    setGetid={setGetid} data={data}
                    id="" />
                <EditDelete type="keyaccess"
                    setData={setData}
                    setKeyAccessDialog={setKeyAccess}
                    id={data._id}
                    data={data} />
                {/* <EditDelete type="delete"
                    setGetid={setGetid}
                    id={data._id}
                    /> */}
            </div>
        )
        )
    }
    const dialogclose = () => {
        setEditDialog(false)
        setViewDialog(false)
        setDialog(false)
        setRender(!render)
    }

    const UpdateDataAccess = (data, id) => {
          
        updateData({
            body: data,
            id: id
        })
    }
    return (
        <div>
            {!viewDialog && <AddDataHeader
                addFormName={"Add User Group"}
                setPage={setPage}
                page={page}
                excelBtn={false}
                totalItem={totalItem}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setAddDialog={setDialog}
                searchBarApi={getAllData}
                paginationData={paginationData}
                isButton={true}
                Adddialog={AddDialog}
                fullWidth={true}
                form={<AddUserGroup AddData={AddData} setDialog={setDialog} />}
            />}

            {addDataInfo.isLoading || deleteDataInfo.isLoading || updateDataInfo.isLoading ? <Loader /> : <>
                {
                    !viewDialog ?
                        rows.length ?
                            <CreateTableAddButton
                                editDialog={editDialog}
                                rows={rows}
                                editform={
                                    <UpdateUserGroup
                                        UpdateData={UpdateData}
                                        editUserGroup={data}
                                        editDialog={setEditDialog} />
                                }
                                columns={columns} isButton={true}
                                editFormName={'Edit User Group'}
                                setEditDialog={setEditDialog} />
                            : totalData == 0 ? <DataNotFound /> : <SkeletonListing />
                        :
                        <ViewUserGroup viewDialog={setViewDialog} data={data} />}
            </>}
            {keyAccess && <KeyGroupAccess UpdateData={UpdateDataAccess} data={data} keyAccess={keyAccess} setKeyAccess={setKeyAccess} />}
            {addDataInfo.data && <Toster info={addDataInfo} dialogclose={dialogclose} />}
            {deleteDataInfo.data && <Toster info={deleteDataInfo} dialogclose={dialogclose} />}
            {updateDataInfo && <Toster info={updateDataInfo} dialogclose={dialogclose} />}
        </div>
    )
}
