import React, { useState } from 'react';
import { Grid, FormControl, Select, InputLabel, TextareaAutosize } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import { CardContent, Button, } from '@material-ui/core';
import { useGetAllCategoriesQuery } from '../../../services/services';
import { useFormik } from 'formik';
import * as yup from 'yup'


export default function AddEmail({ AddData, setDialog }) {
    const getAllCategory = useGetAllCategoriesQuery()
    const allCategory = getAllCategory.data;

    const formik = useFormik({
        initialValues: {
            mail_type: "",
            body: "",
            subject: "",
            cc: "",
            bcc: "",
            from: "",
        },
        validationSchema: yup.object({
            mail_type: yup.string().required("Required !"),
            body: yup.string().required("Required !"),
            subject: yup.string().required("Required !"),
            cc: yup.string().required("Required !"),
            bcc: yup.string().required("Required !"),
            from: yup.string().required("Required !"),


        }),
        onSubmit: (values) => {
            AddData(values)
        }
    })

    return (

        <form onSubmit={formik.handleSubmit}>




            <TextField
                name="mail_type"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mail_type}
                fullWidth={true}
                label="Mail Type "
                variant="outlined"
                size="small" />
            {formik.touched.mail_type && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.mail_type}</span>}

            <br /><br />
            <TextField
                name="from"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.from}
                fullWidth={true}
                type="email"
                label="Send from Email "
                variant="outlined"
                size="small" />
            {formik.touched.from && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.from}</span>}

            <br /><br />



            <TextField
                name="subject"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.subject}
                fullWidth={true}
                label="subject "
                variant="outlined"
                size="small" />
            {formik.touched.subject && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.subject}</span>}

            <br /><br />
            <TextField
                name="cc"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cc}
                fullWidth={true}
                label="cc "
                variant="outlined"
                size="small" />
            {formik.touched.cc && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.cc}</span>}

            <br /><br />
            <TextField
                name="bcc"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bcc}
                fullWidth={true}
                label="bcc "
                variant="outlined"
                size="small" />
            {formik.touched.bcc && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.bcc}</span>}

            <br /><br />
          

            <TextareaAutosize
                name="body"
                onChange={formik.handleChange}
                InputProps={{ inputProps: { min: 0 } }}
                onBlur={formik.handleBlur}
                value={formik.values.body}
                fullWidth={true}
                variant="outlined"

                placeholder="Body"

                aria-label="minimum height"
                minRows={3}
                style={{ width: "100%" }}
            />
            {formik.touched.body && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.body}</span>}

            <br /><br />
            <Grid container>
                <Grid xs={6}></Grid>
                <Grid className="d-flex justify-content-between" xs={6}>
                    <Button onClick={() => setDialog(false)} variant="contained" color="secondary">
                        Close
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        Save
                    </Button>
                </Grid>
            </Grid>

        </form>

    )
}
