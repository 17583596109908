import React, { useEffect, useState } from "react";
import {
  useDeleteConfigDataMutation,
  useGetAllConfigDataQuery,
  useUpdateConfigDataMutation,
} from "../../../../../../services/configuration/ConfigDataServices";
import EditDelete from "../../../EditDelete";
import Toster from "../../../Toster";
import EditGroups from "../edit/EditGroups";
import GroupListing from "./GroupListing";
import AuthWrapper from "../../../../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../../../../utils/getFilterFunction";

const columns = [
  {
    id: "group_name",
    label: "Group Name",
    minWidth: 50,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "order_rank",
    label: "Order",
    minWidth: 50,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 50,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
    authName: ["CONFIGURATION_GROUPS_DELETE", "CONFIGURATION_GROUPS_EDIT"],

  },
];

const GroupListingWrapper = () => {
  const [rows, setRows] = useState([]);
  const [configDataId, setConfigDataId] = useState("");
  const [getId, setGetId] = useState("");
  const [editDialog, setEditDialog] = useState(false);
  const [editData, setEditData] = useState("");

  const { data, isFetching, isLoading } = useGetAllConfigDataQuery("groups");
  const [deleteConfigData, deleteConfigDataInfo] =
    useDeleteConfigDataMutation();

  const [update, updateInfo] = useUpdateConfigDataMutation();

  useEffect(() => {
    deleteConfigData({
      id: configDataId,
      body: {
        action: "DELETE",
        data: {
          _id: getId,
        },
      },
    });
  }, [getId]);

  const createData = (group_name, order_rank, actions) => {
    return { group_name, order_rank, actions };
  };

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setRows(
        data?.data[0]?.configurationValues?.map((group, index) => {
          return createData(
            group.value_name,
            group.order_rank,
            <div>
              <AuthWrapper type="ACTION" name="CONFIGURATION_GROUPS_DELETE">
                <EditDelete type="delete" setGetid={setGetId} id={group._id} />
              </AuthWrapper>
              <AuthWrapper type="ACTION" name="CONFIGURATION_GROUPS_EDIT">
                <EditDelete
                  type="edit"
                  setEditDialog={setEditDialog}
                  setData={setEditData}
                  data={group}
                />
              </AuthWrapper>
            </div>
          );
        }) || []
      );

      setConfigDataId(data?.data[0]?._id);
    }
  }, [isFetching, isLoading, data]);

  return (
    <>
      <GroupListing
        columns={getFilterdColumns(columns)}
        rows={rows}
        configDataId={configDataId}
      />
      {editDialog && (
        <EditGroups
          editData={editData}
          onClose={() => setEditDialog(false)}
          configDataId={configDataId}
          update={update}
        />
      )}

      {deleteConfigDataInfo?.data && <Toster info={deleteConfigDataInfo} />}
      {updateInfo?.data && <Toster info={updateInfo} />}
    </>
  );
};

export default GroupListingWrapper;
