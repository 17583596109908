import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "./CreateTabPanel";
import SocialNetworks from "./SocialNetworks";
import { userAccess } from "../../../helpers/userAccess";
import Subject from "./Subject";
import Levels from "./Levels";
import Courses from "./Courses";
import Categories from "./Categories";
import Modes from "./Modes";
import Specializations from "./specialization/Specializations";
import GroupListingWrapper from "./Configuration/Groups/list/GroupListingWrapper";
import ModuleListingWrapper from "./Configuration/Modules/list/ModuleListingWrapper";
import SyllabusListingWrapper from "./Configuration/Syllabus/list/SyllabusListingWrapper";
import SourceListingWrapper from "./Configuration/Sources/list/SourceListingWrapper";
import BlogSubCategoryListingWrapper from "./Configuration/Blog-Sub-Category/list/BlogSubCategoryListingWrapper";
import AuthWrapper from "../../../utils/AuthWrapper";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const tabsNames = [
  {
    tabName: "course",
    tabId: "CONFIGURATION_COURSE",
  },
  {
    tabName: "level",
    tabId: "CONFIGURATION_LEVEL",
  },
  {
    tabName: "subject",
    tabId: "CONFIGURATION_SUBJECT",
  },
  {
    tabName: "category",
    tabId: "CONFIGURATION_CATEGORY",
  },
  {
    tabName: "mode",
    tabId: "CONFIGURATION_MODE",
  },
  {
    tabName: "social-media",
    tabId: "CONFIGURATION_SOCIAL_MEDIA",
  },
  {
    tabName: "Group",
    tabId: "CONFIGURATION_GROUPS",
  },
  {
    tabName: "Modules",
    tabId: "CONFIGURATION_MODULE",
  },
  {
    tabName: "Syllabus",
    tabId: "CONFIGURATION_SYLLABUS",
  },
  {
    tabName: "Source",
    tabId: "CONFIGURATION_SOURCE",
  },
  {
    tabName: "Blog-Sub-Category",
    tabId: "CONFIGURATION_BLOG_SUB_CATEGORY",
  },
];

export default function Configuration() {
  let tabsToShow = tabsNames?.filter((e, i) => {
    return AuthWrapper({ type: "TAB", name: e?.tabId, inBoolean: true });
  });
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getTabPanel = (ele) => {
    let moduleToShow;
    switch (ele) {
      case "course":
        moduleToShow = <Courses />;
        break;
      case "level":
        moduleToShow = <Levels />;
        break;
      case "subject":
        moduleToShow = <Subject />;
        break;
      case "category":
        moduleToShow = <Categories />;
        break;
      case "mode":
        moduleToShow = <Modes />;
        break;
      case "social-media":
        moduleToShow = <SocialNetworks />;
        break;
      // case "specialization":
      //   moduleToShow = <Specializations />;
      //   break;
      case "Group":
        moduleToShow = <GroupListingWrapper />;
        break;
      case "Modules":
        moduleToShow = <ModuleListingWrapper />;
        break;
      case "Syllabus":
        moduleToShow = <SyllabusListingWrapper />;
        break;
      case "Source":
        moduleToShow = <SourceListingWrapper />;
        break;
      case "Blog-Sub-Category":
        moduleToShow = <BlogSubCategoryListingWrapper />;
        break;
      default:
        break;
    }
    return moduleToShow;
  };
  return (
    <>
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            {tabsToShow.map((ele, ind) => {
              return (
                <Tab
                  style={{ textTransform: "capitalize" }}
                  label={ele?.tabName}
                  {...a11yProps(ind)}
                />
              );
            })}
          </Tabs>
        </AppBar>

        {tabsToShow.map((ele, ind) => {
          return (
            <TabPanel value={value} index={ind}>
              {getTabPanel(ele?.tabName)}
            </TabPanel>
          );
        })}
      </div>
    </>
  );
}
