import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { makeStyles } from '@material-ui/core/styles';
import * as yup from 'yup'

import Typography from '@material-ui/core/Typography';
import {
    FormControl, InputLabel,
    Grid, Select, MenuItem,
    Checkbox, ListItemText,
    OutlinedInput, CircularProgress, Box,
} from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import { Card, CardContent, Button, } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useFormik } from 'formik';
import {
    useGetAllModeQuery,
    useGetAllSubjectNamesQuery,
    useGetAallFacultieNamesQuery,
    useGetAllCategoriesQuery,
    useGetAllCoursesQuery,
    useGetAllCourseLevelQuery,
    useGetallInstituteNamesQuery,
    useUpdateVideoLectureMutation,
} from '../../../services/services';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        minWidth: 100,
    },
    tab: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function UpdateCourseForm({ UpdateData, editData, editDialog }) {

      

    const classes = useStyles();
    const [manageId, setManageId] = useState(UpdateData._id)
    const [imageval, setImageVal] = useState("")
    const [modes, setModes] = useState()
    const [allLevels, setAllLevels] = useState()
    const [allCourse, setAllCourse] = useState()
    const [allInstitute, setAllInstitute] = useState()
    const [subject, setSubject] = useState()
    const [faculty, setFaculty] = useState()
    const [category, setCategory] = useState()
    const [courseId, setCourseId] = useState()
    const getAllMode = useGetAllModeQuery()
    const getAllSubjectNames = useGetAllSubjectNamesQuery()
    const getAllCategory = useGetAllCategoriesQuery()
    const getAllCourse = useGetAllCoursesQuery()
    const getAllInstitute = useGetallInstituteNamesQuery()
    const getAllLevel = useGetAllCourseLevelQuery(manageId)
    const allFacultieNames = useGetAallFacultieNamesQuery()
    const [flag, setFlag] = useState(true)
    const [updateVideoLecture, videolectureInfo] = useUpdateVideoLectureMutation({
        limit: "",
        query: "",
        params: [""],
        page: "",
    })

    allCourse && allCourse.forEach((data) => {
        if (data.name == manageId) {
            setManageId(data._id)
        }
    })

    useEffect(() => {
        let { status } = videolectureInfo
        if (status == 'fulfilled') {
            let { status, message } = videolectureInfo.data
            if (!status) {
                notify(message)
                setFlag(true)

            } else {
                notify(message)
                setFlag(true)
            }
        }
    }, [videolectureInfo])

    useEffect(() => {
        let { isSuccess, data } = getAllMode;
        if (isSuccess) {
            setModes(data.data)
        }
    }, [getAllMode])
    useEffect(() => {
        let { isSuccess, data } = getAllInstitute;
        if (isSuccess) {
            setAllInstitute(data.data)
        }
    }, [getAllInstitute])
    useEffect(() => {
        let { isSuccess, data } = getAllLevel;
        if (isSuccess) {
            setAllLevels(data.data)
        }
    }, [getAllLevel])
    useEffect(() => {
        let { isSuccess, data } = getAllCourse;
        if (isSuccess) {
            setAllCourse(data.data)
        }
    }, [getAllCourse])

    useEffect(() => {
        let { isSuccess, data } = getAllCategory;
        if (isSuccess) {
            setCategory(data.data)
        }
    }, [getAllCategory])
    useEffect(() => {
        let { isSuccess, data } = getAllSubjectNames;
        if (isSuccess) {
            setSubject(data.data)
        }
    }, [getAllSubjectNames])
    useEffect(() => {
        let { isSuccess, data } = allFacultieNames;
        if (isSuccess) {
            setFaculty(data.data)
        }
    }, [allFacultieNames])

    const notify = (msg) => toast(msg);
    const [subjectValue, setSubjectvalue] = useState([]);
    const subjectChange = (event) => {
        const { target: { value }, } = event;
        setSubjectvalue(typeof value === 'string' ? value.split(',') : value,);
    };
    const [facultyValue, setFacultyvalue] = useState([]);
    const facultyChange = (event) => {
        const { target: { value }, } = event;
        setFacultyvalue(typeof value === 'string' ? value.split(',') : value,);
    };


    const [categoryValue, setcategoryValue] = useState([]);
    const categoryChange = (event) => {
        const { target: { value }, } = event;
        setcategoryValue(typeof value === 'string' ? value.split(',') : value,);
    };

    const formik = useFormik({
        onSubmit: (values) => {
            setFlag(false)
            values.subject = subjectValue
            values.faculty = facultyValue
            values.category = categoryValue
            values.image = imageval

            var form_data = new FormData()
            for (let key in values) {
                form_data.append(key, values[key])
            }
            let id = UpdateData._id
            updateVideoLecture(form_data)
        },


        initialValues: {
            id: editData._id,
            subject: editData.subject,
            category: editData.category,
            faculty: editData.faculty,
            faculty_email: editData.faculty_email,
            faculty_multiple_email: editData.faculty_multiple_email,
            course_name:editData.course_name,
            course_level: editData.course_level,
            institute: editData.institute_id,
            mode: " editData.mode.mode_name",
            mode_discription: editData.mode_discription,
            code: editData.code,
            subcode: editData.subcode,
            search_key_code: editData.search_key_code,
            video_lecture_name: editData.video_lecture_name,
            nickname: editData.nickname,
            franchise_discount_50: editData.franchise_discount_50,
            image: editData.image,
            mrp: editData.mrp,
            sld_selling_price: editData.sld_selling_price,
            gd_link: editData.gd_link,
            demo_link: editData.demo_link,
            demo_name: editData.demo_name,
            demo_link_is_available: editData.demo_link_is_available,
            eligible_cod: editData.eligible_cod,
            contact_technical_support: editData.contact_technical_support,
            contact_tracking_support: editData.contact_tracking_support,
            validity: editData.validity,
            relevant_attempts: editData.relevant_attempts,
            lecture_languages: "editData.lecture_languages[0]",
            duration_of_lecture: editData.duration_of_lecture,
            no_of_lectures: editData.no_of_lectures,
            view_count: editData.view_count,
            internet_connectivity: editData.internet_connectivity,
            material_language: editData.material_language,
            video_runs_on: editData.video_runs_on,
            lecture_recorded_on: editData.lecture_recorded_on,
            system_requirements: editData.system_requirements,
            additional_requirement: editData.additional_requirement,
            rank: editData.rank,
            sld_benefits: editData.sld_benefits,
            syllabus: editData.syllabus,
            validity_start: editData.validity_start,
            no_of_book: editData.no_of_book,
            book_type: editData.book_type,
            printable_or_not: editData.printable_or_not,
            no_of_views: editData.no_of_views,
            topics_covered: editData.topics_covered,
            details_amendments: editData.details_amendments,
            fast_forward_speed: editData.fast_forward_speed,
            no_of_pd: editData.no_of_pd,
            size_of_pd: editData.size_of_pd,
            views_extension: editData.views_extension,
            validity_extension: editData.validity_extension,
            why_to_buy: editData.why_to_buy,
            dispatch_by: editData.dispatch_by,
            dispatch_time: editData.dispatch_time,
            delievery_time: editData.delievery_time,
            contact_for_doubt_solving: editData.contact_for_doubt_solving,
            sld_discount_50: editData.sld_discount_50,
            discount_on_mrp_sp_by_faculty: editData.discount_on_mrp_sp_by_faculty,
            discount_by_faculty: editData.discount_by_faculty,
            discounnt_by_us: editData.discounnt_by_us,
            start_date_dis_by_faculty: editData.start_date_dis_by_faculty,
            end_date_dis_by_faculty: editData.end_date_dis_by_faculty,
            start_date_dis_by_us: editData.start_date_dis_by_us,
            end_date_dis_by_us: editData.end_date_dis_by_us,
            discount_on_mrp_sp_by_us: editData.discount_on_mrp_sp_by_us,
            imp_description: editData.imp_description,
            is_mrp_show: editData.is_mrp_show,
            note: editData.note,
            wallet_discount: editData.wallet_discount,
            wallet_discount_is_applied: editData.wallet_discount_is_applied,
            meta: editData.meta,
            video_lecture_title: editData.video_lecture_title,
            is_not_available: editData.is_not_available,
            allowed_payment_gateway: editData.allowed_payment_gateway,
            commission_offered: editData.commission_offered,
            referral_to_percentage: editData.referral_to_percentage,
            referral_by_percentage: editData.referral_by_percentage,
            invoice_institute_name: editData.invoice_institute_name,
            payment_to_faculty: editData.payment_to_faculty,
            commision_amount: editData.commision_amount,
            price_onwhich_commission_calculated: editData.price_onwhich_commission_calculated,
            gst_applied_percent: editData.gst_applied_percent,
            sharing_percent: editData.sharing_percent,
            is_hidden: editData.is_hidden,
        },
    })

    return (
        <>
            {flag ?
                <form id="addfacultyform" onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}
                        style={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            marginTop: 0,
                            marginLeft: 0
                        }} >
                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined">
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Video Lectures Details
                                    </Typography>
                                    <Grid container spacing={3}>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label=" Video Lecture Name "
                                                variant="outlined"
                                                size="medium"
                                                name="video_lecture_name"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.video_lecture_name}
                                            />
                                        </Grid>

                                        <Grid item xs={4} >
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">
                                                    Course Name
                                                </InputLabel>
                                                <Select native
                                                    name="course_name"
                                                    onChange={(e) => {
                                                        formik.values.course_name = e.target.value
                                                        setManageId(e.target.value)
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.course_name}
                                                    label="Course Name">
                                                    <option aria-label="None" value="" />
                                                    {allCourse && allCourse.map(
                                                        (course) => <option value={course.name}>
                                                            {course.name}</option>)}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Video Levture Title "
                                                variant="outlined"
                                                size="medium"
                                                name="video_lecture_title"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.video_lecture_title}
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label=" Code"
                                                variant="outlined"
                                                size="medium"
                                                name="code"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.code}
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="search_key_code"
                                                variant="outlined"
                                                size="medium"
                                                name="search_key_code"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.search_key_code}
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                label=" Subcode"
                                                fullWidth={true}
                                                variant="outlined"
                                                size="medium"
                                                name="subcode"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.subcode}
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }}
                                                variant="outlined"
                                                className={classes.formControl}
                                                fullWidth={true}
                                                sx={{ m: 1, width: 300 }}>
                                                <InputLabel id="category-multiple-checkbox-label">Category</InputLabel>

                                                <Select
                                                    labelId="category-multiple-checkbox-label"
                                                    id="category-multiple-checkbox"
                                                    multiple
                                                    name="category"
                                                    onChange={categoryChange}
                                                    value={categoryValue}
                                                    input={<OutlinedInput label="category" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                >
                                                    <MenuItem>
                                                        <TextField
                                                            label=" Search"
                                                            fullWidth={true}
                                                            variant="outlined"
                                                            size="small"
                                                            name="Search"
                                                        />
                                                    </MenuItem>

                                                    {category && category.map((category) => (
                                                        <MenuItem key={category._id} value={category.category_name}>
                                                            <Checkbox checked={categoryValue.indexOf(category.category_name) > -1} />
                                                            <ListItemText primary={category.category_name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Mode</InputLabel>
                                                <Select native
                                                    name="mode"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.mode}
                                                    label="Mode">
                                                    {modes && modes.map((mode) => <option >{mode.name}</option>)}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Mode description"
                                                variant="outlined"
                                                size="medium"
                                                name="mode_discription"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.mode_discription}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Lectures recorded on"
                                                variant="outlined"
                                                size="medium"
                                                name="lecture_recorded_on"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.lecture_recorded_on}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }}
                                                variant="outlined"
                                                className={classes.formControl}
                                                fullWidth={true}
                                                sx={{ m: 1, width: 300 }}>
                                                <InputLabel id="subject-multiple-checkbox-label">Subject</InputLabel>
                                                <Select
                                                    labelId="subject-multiple-checkbox-label"
                                                    id="subject-multiple-checkbox"
                                                    multiple
                                                    name="subject"
                                                    onChange={subjectChange}
                                                    value={subjectValue}
                                                    input={<OutlinedInput label="Subject" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                >
                                                    <MenuItem>
                                                        <TextField
                                                            label=" Search"
                                                            fullWidth={true}
                                                            variant="outlined"
                                                            size="small"
                                                            name="Search"
                                                        />
                                                    </MenuItem>
                                                    {subject && subject.map((subject) => (
                                                        <MenuItem key={subject._id} value={subject.subject_name}>
                                                            <Checkbox checked={subjectValue.indexOf(subject.subject_name) > -1} />
                                                            <ListItemText primary={subject.subject_name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            </Grid>






                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Lecture Languages</InputLabel>
                                                <Select native
                                                    name="lecture_languages"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.lecture_languages}
                                                    label="Select Languages ">
                                                    <option aria-label="None" value="" />
                                                    <option>Hindi</option>
                                                    <option>English</option>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>

                                            <FormControl style={{ margin: 0 }} fullWidth={true}

                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">COD Eligible </InputLabel>
                                                <Select native
                                                    name="eligible_cod"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.eligible_cod}
                                                    label="Eligible COD">
                                                    <option aria-label="None" value={false} ></option>
                                                    <option value={true} >Yes</option>
                                                    <option value={false}>No</option>
                                                </Select>
                                            </FormControl></Grid>

                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Level</InputLabel>
                                                <Select native
                                                    name="course_level"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.course_level}
                                                    label="Level">
                                                    <option aria-label="None" value="" />
                                                    {allLevels && allLevels.map((level) => <option >{level.level_name}</option>)}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Book type"
                                                variant="outlined"
                                                size="medium"
                                                name="book_type"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.book_type}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>

                                            <Button
                                                variant="outlined"
                                                fullWidth={true}
                                                startIcon={<BackupIcon />}
                                                color="primary"
                                                component="label" >
                                                Upload Image
                                                <input onChange={(e) => {
                                                    setImageVal(e.target.files[0])
                                                }} name="image" type="file" hidden />
                                            </Button>
                                        </Grid>
                                    </Grid>

                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        About Video Lectures
                                    </Typography>



                                    <Grid container spacing={3}>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Nick name"
                                                variant="outlined"
                                                size="medium"
                                                name="nickname"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.nickname}
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Printable</InputLabel>
                                                <Select native
                                                    name="printable_or_not"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.printable_or_not}
                                                    label="Printable">
                                                    <option aria-label="None" value={false} ></option>
                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>
                                                </Select>
                                            </FormControl></Grid>
                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}

                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Select Syllabus</InputLabel>
                                                <Select native
                                                    name="syllabus"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.syllabus}
                                                    label="Select Syllabus">
                                                    <option aria-label="None" value="" />
                                                    <option >New</option>
                                                    <option >Old</option>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={4}>

                                            <TextField
                                                label="Topics Covered" fullWidth={true}
                                                name="topics_covered"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.topics_covered}
                                                variant="outlined"
                                                size="medium"
                                            /></Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Duration"
                                                variant="outlined"
                                                size="medium"
                                                name="duration_of_lecture"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.duration_of_lecture}
                                            /></Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Number of Lectures"
                                                variant="outlined"
                                                size="medium"
                                                name="no_of_lectures"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.no_of_lectures}
                                            /></Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Relevant Attempts"
                                                variant="outlined"
                                                size="medium"
                                                name="relevant_attempts"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.relevant_attempts}
                                            /></Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Rank"
                                                variant="outlined"
                                                size="medium"
                                                name="rank"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.rank}
                                            /></Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Fast Forward Speed"
                                                variant="outlined"
                                                size="medium"
                                                name="fast_forward_speed"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.fast_forward_speed}
                                            /></Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Validity
                                    </Typography>
                                    <Grid container spacing={3}>

                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Validity "
                                                variant="outlined"
                                                size="medium"
                                                name="validity"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.validity}
                                            /></Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Starts From"
                                                variant="outlined"
                                                size="medium"
                                                name="validity_start"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.validity_start}
                                            /></Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Extension "
                                                variant="outlined"
                                                size="medium"
                                                name="validity_extension"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.validity_extension}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Requirements
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4}  >

                                            <TextField
                                                label="System Requirements" fullWidth={true}
                                                multiline
                                                variant="outlined"
                                                size="medium"
                                                name="system_requirements"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.system_requirements}
                                            /></Grid>
                                        <Grid item xs={4}  >

                                            <TextField
                                                fullWidth={true}
                                                label="Internet Connectivity"
                                                variant="outlined"
                                                size="medium"
                                                name="internet_connectivity"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.internet_connectivity}
                                            /></Grid>
                                        <Grid item xs={4}  >
                                            <TextField
                                                fullWidth={true}
                                                label="Video Runs On"
                                                variant="outlined"
                                                size="medium"
                                                name="video_runs_on"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.video_runs_on}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Pricing
                                    </Typography>

                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="PRICE ON WHICH COMMISSION IS CALCULATED"
                                                variant="outlined"
                                                size="medium"
                                                name="price_onwhich_commission_calculated"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.price_onwhich_commission_calculated}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Payment to faculty"
                                                variant="outlined"
                                                size="medium"
                                                name="payment_to_faculty"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.payment_to_faculty}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Selling Price"
                                                variant="outlined"
                                                size="medium"
                                                name="sld_selling_price"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.sld_selling_price}
                                            /></Grid>
                                        <Grid item xs={4}>

                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Show MRP</InputLabel>
                                                <Select native
                                                    name="is_mrp_show"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.is_mrp_show}
                                                    label="Show MRP">
                                                    <option aria-label="None" value={false} ></option>
                                                    <option value={true}>Show</option>
                                                    <option value={true}>Hide</option>
                                                </Select>
                                            </FormControl></Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="MRP"
                                                variant="outlined"
                                                size="medium"
                                                name="mrp"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.mrp}
                                            /></Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Commission Offered"
                                                variant="outlined"
                                                size="medium"
                                                name="commission_offered"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.commission_offered}
                                            /></Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                label="GST Applied %" fullWidth={true}
                                                multiline
                                                variant="outlined"
                                                size="medium"
                                                name="gst_applied_percent"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.gst_applied_percent}
                                            />
                                        </Grid>
                                    </Grid>

                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Others
                                    </Typography>
                                    <Grid container spacing={3}>

                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}

                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Is Not Available</InputLabel>
                                                <Select native
                                                    label="Is Not Available"
                                                    name="is_not_available"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.is_not_available}>
                                                    <option aria-label="None" value={false} ></option>

                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>

                                                </Select>
                                            </FormControl>

                                        </Grid>


                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Is Hidden</InputLabel>
                                                <Select native label="Is Hideen"
                                                    name="is_hidden"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.is_hidden}
                                                >
                                                    <option value={true} >yes</option>
                                                    <option value={false}>No</option>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Invoice/Institute name"
                                                variant="outlined"
                                                size="medium"
                                                name="invoice_institute_name"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.invoice_institute_name}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Google Drive Link"
                                                variant="outlined"
                                                size="medium"
                                                name="gd_link"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.gd_link}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Additional requirement "
                                                variant="outlined"
                                                size="medium"
                                                name="additional_requirement"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.additional_requirement}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Details Amendments"
                                                variant="outlined"
                                                size="medium"
                                                name="details_amendments"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.details_amendments}
                                            /></Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                label="why to Buy" fullWidth={true}
                                                multiline
                                                variant="outlined"
                                                size="medium"
                                                name="why_to_buy"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.why_to_buy}
                                            /></Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Note" multiline
                                                variant="outlined"
                                                size="medium"
                                                name="note"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.note}
                                            /></Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Meta"
                                                variant="outlined"
                                                size="medium"
                                                name="meta"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.meta}
                                            /></Grid>

                                            







                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Faculty
                                    </Typography>

                                    <Grid container spacing={3}>

                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Institute</InputLabel>
                                                <Select native
                                                    name="institute"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.institute}
                                                    label="Institute">
                                                    <option aria-label="None" value="" />
                                                    {allInstitute && allInstitute.map((institute) => <option >{institute.name}</option>)}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Email"
                                                variant="outlined"
                                                size="medium"
                                                name="faculty_email"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.faculty_email}
                                            /></Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Multipal Email"
                                                variant="outlined"
                                                size="medium"
                                                name="faculty_multiple_email"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.faculty_multiple_email}
                                            />
                                        </Grid>

                                        <Grid item xs={4}>

                                            <FormControl style={{ margin: 0 }}
                                                variant="outlined"
                                                className={classes.formControl}
                                                fullWidth={true}
                                                sx={{ m: 1, width: 300 }}>
                                                <InputLabel id="faculty-multiple-checkbox-label">Select Faculty</InputLabel>
                                                <Select
                                                    labelId="faculty-multiple-checkbox-label"
                                                    id="faculty-multiple-checkbox"
                                                    multiple
                                                    name="faculty"
                                                    onChange={facultyChange}
                                                    value={facultyValue}
                                                    input={<OutlinedInput label="Select Faculty" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                >
                                                    <MenuItem>
                                                        <TextField
                                                            label=" Search"
                                                            fullWidth={true}
                                                            variant="outlined"
                                                            size="small"
                                                            name="Search"
                                                        />
                                                    </MenuItem>
                                                    {faculty && faculty.map((faculty) => (
                                                        <MenuItem key={faculty._id} value={faculty.name}>
                                                            <Checkbox checked={facultyValue.indexOf(faculty.name) > -1} />
                                                            <ListItemText primary={faculty.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Discount
                                    </Typography>


                                    <Grid container spacing={3}>




                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Sharing %"
                                                variant="outlined"
                                                size="medium"
                                                name="sharing_percent"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.sharing_percent}
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Comission Amount"
                                                variant="outlined"
                                                size="medium"
                                                name="commision_amount"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.commision_amount}
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="SLD benefits"
                                                variant="outlined"
                                                size="medium"
                                                name="sld_benefits"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.sld_benefits}
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                label="SLD50 Coupon Discount" fullWidth={true}
                                                variant="outlined"
                                                size="medium"
                                                name="sld_discount_50"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.sld_discount_50}
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                label="DIS50 Franchise Discount" fullWidth={true}
                                                variant="outlined"
                                                size="medium"
                                                name="franchise_discount_50"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.franchise_discount_50}
                                            /></Grid>

                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">wallet Discount Applied</InputLabel>
                                                <Select native
                                                    label="wallet Discount Applied"
                                                    name="wallet_discount_is_applied"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.wallet_discount_is_applied}>
                                                    <option aria-label="None" value={false} ></option>

                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Wallet %"
                                                variant="outlined"
                                                size="medium"
                                                name="wallet_discount"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.wallet_discount}
                                            /></Grid>




                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Referral by In %"
                                                variant="outlined"
                                                size="medium"
                                                name="referral_by_percentage"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.referral_by_percentage}
                                            /></Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                label="Referal to in %" fullWidth={true}
                                                variant="outlined"
                                                size="medium"
                                                name="referral_to_percentage"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.referral_to_percentage}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography className={classes.title}
                                                variant="h6"
                                                color="primary" gutterBottom>
                                                Discount By faculty
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Discount By Faculty "
                                                variant="outlined"
                                                size="medium"
                                                name="discount_by_faculty"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.discount_by_faculty}
                                            /></Grid>
                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Discount On MRP/SP </InputLabel>
                                                <Select native
                                                    label="Discount On"
                                                    name="discount_on_mrp_sp_by_faculty"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.discount_on_mrp_sp_by_faculty}>
                                                    <option aria-label="None" value="" />
                                                    <option >MPR</option>
                                                    <option >SP</option>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="start_date_dis_by_faculty"
                                                variant="outlined"
                                                size="medium"
                                                name="start_date_dis_by_faculty"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.start_date_dis_by_faculty}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="end_date_dis_by_faculty"
                                                variant="outlined"
                                                size="medium"
                                                name="end_date_dis_by_faculty"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.end_date_dis_by_faculty}
                                            />
                                        </Grid>



                                        <Grid item xs={12}>
                                            <Typography className={classes.title}
                                                variant="h6"
                                                color="primary" gutterBottom>
                                                Discount By Us
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Discount By Us"
                                                variant="outlined"
                                                size="medium"
                                                name="discounnt_by_us"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.discounnt_by_us}
                                            />
                                        </Grid>


                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Discount On MRP/SP</InputLabel>
                                                <Select native
                                                    label="Discount On"
                                                    name="discount_on_mrp_sp_by_us"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.discount_on_mrp_sp_by_us}>
                                                    <option aria-label="None" value="" />
                                                    <option >MPR</option>
                                                    <option >SP</option>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="start_date_dis_by_us"
                                                variant="outlined"
                                                size="medium"
                                                name="start_date_dis_by_us"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.start_date_dis_by_us}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="end_date_dis_by_us"
                                                variant="outlined"
                                                size="medium"
                                                name="end_date_dis_by_us"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.end_date_dis_by_us}
                                            />
                                        </Grid>
                                    </Grid>

                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Views
                                    </Typography>



                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Views Extension"
                                                variant="outlined"
                                                size="medium"
                                                name="views_extension"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.views_extension}
                                            /></Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Number Of Views"
                                                variant="outlined"
                                                size="medium"
                                                name="no_of_views"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.no_of_views}
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Views Count"
                                                variant="outlined"
                                                size="medium"
                                                name="view_count"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.view_count}
                                            />
                                        </Grid>

                                    </Grid>

                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Link
                                    </Typography>

                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Demo Name "
                                                variant="outlined"
                                                size="medium"
                                                name="demo_name"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.demo_name}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={4}>
                                        <TextField
                                            fullWidth={true}
                                            label="Demo lectures"
                                            variant="outlined"
                                            size="medium"
                                            name="demo_link"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.demo_link}
                                        />
                                    </Grid> */}
                                        <Grid item xs={4}>
                                            <TextField
                                                label="Demo Link" fullWidth={true}
                                                variant="outlined"
                                                size="medium"
                                                name="demo_link"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.demo_link}
                                            /></Grid>
                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">
                                                    Demo Link Is Available</InputLabel>
                                                <Select native
                                                    name="demo_link_is_available"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.demo_link_is_available}
                                                    label="Demo Link Is Available">
                                                    <option aria-label="None" value={false} ></option>
                                                    <option value={true} >Yes</option>
                                                    <option value={false}>No</option>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Material/Package
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}

                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Choose Material Language</InputLabel>
                                                <Select native
                                                    name="material_language"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.material_language}
                                                    label="Choose Material Language ">
                                                    <option aria-label="None" value="" />
                                                    <option >Hindi</option>
                                                    <option >English</option>
                                                </Select>
                                            </FormControl></Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Number Of Books"
                                                variant="outlined"
                                                size="medium"
                                                name="no_of_book"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.no_of_book}
                                            /></Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Size of PD"
                                                variant="outlined"
                                                size="medium"
                                                name="size_of_pd"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.size_of_pd}
                                            /></Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                label="Number of PD" fullWidth={true}
                                                variant="outlined"
                                                size="medium"
                                                name="no_of_pd"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.no_of_pd}
                                            />
                                        </Grid>
                                    </Grid>

                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Shipping/Delivery
                                    </Typography>

                                    <Grid container spacing={3}>
                                        {/* <Grid item xs={4}>
                                        <TextField
                                            fullWidth={true}
                                            label="Shipping method"
                                            variant="outlined"
                                            size="medium"
                                            name="meta"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.meta}
                                        />
                                    </Grid> */}


                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Allowed payment gateway"
                                                variant="outlined"
                                                size="medium"
                                                name="allowed_payment_gateway"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.allowed_payment_gateway}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Dispatch By"
                                                variant="outlined"
                                                size="medium"
                                                name="dispatch_by"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.dispatch_by}
                                            /></Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Dispatch Time"
                                                variant="outlined"
                                                size="medium"
                                                name="dispatch_time"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.dispatch_time}
                                            /></Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Processing Time"
                                                variant="outlined"
                                                size="medium"
                                                name="delievery_time"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.delievery_time}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent >
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Support
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth='true'
                                                label="Contact for Doubt"
                                                variant="outlined"
                                                size="medium"
                                                name="contact_for_doubt_solving"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.contact_for_doubt_solving}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="TRACKING SUPPORT"
                                                variant="outlined"
                                                size="medium"
                                                name="contact_tracking_support"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.contact_tracking_support}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                label="Technical Support" fullWidth={true}
                                                variant="outlined"
                                                size="medium"
                                                name="contact_technical_support"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.contact_technical_support}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <TextareaAutosize fullWidth={true}
                                aria-label="IMP Description" style={{ width: "100%" }}
                                minRows={6} placeholder="Description"
                                name="imp_description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.imp_description}
                            />

                        </Grid>

                        <Grid item xs={10}></Grid>
                        <Grid item xs={2} >
                            <Button
                                variant="contained"
                                color="secondary">
                                Close
                            </Button>

                            <Button style={{ marginLeft: 20 }}
                                type="submit"
                                variant="contained"
                                color="primary">
                                Save
                            </Button>
                        </Grid>

                    </Grid>

                </form>
                :
                <div style={{ height: "80vh" }} className=" d-flex justify-content-center d-flex align-items-center">
                    <Box sx={{ display: 'flex' }}>
                        {/* <CircularProgress /> */}
                    </Box>
                </div>
            }
            <ToastContainer />




        </>

    )
}
