import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ErrorMessage, FieldArray } from "formik";
import React from "react";

const useStyles = makeStyles((theme) => ({
  adormantField: {
    padding: "0px 0px",
  },
  adormantSelect: {
    backgroundColor: "red",
    margin: "0px 0px",
  },
}));

const CustomTextField = ({ name = "", label, value, onChange, disabled }) => {
  return (
    <div className="position-relative">
      <label> {label} </label>
      <TextField
        name={name}
        type="number"
        value={value}
        onChange={(e) => {
          onChange(e.target?.value);
        }}
        className=""
        fullWidth
        size="small"
        disabled={disabled}
      />

      {/* {name && ( */}
      <ErrorMessage name={name}>
        {(errMsg) => <p className=" text-danger"> {errMsg} </p>}
      </ErrorMessage>
      {/* )} */}
    </div>
  );
};

const CustomTextAdormantField = ({
  name = "",
  label,
  selectValue,
  onSelectChange,
  value,
  onChange,
  helperText,
  isShowHelperText = false,
}) => {
  const classes = useStyles();

  return (
    <div className="position-relative">
      <label> {label} </label>

      <TextField
        name={name}
        type="number"
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        className=""
        fullWidth
        size="small"
        classes={{ root: classes.adormantField }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Select
                value={selectValue}
                onChange={(e) => onSelectChange(e.target?.value)}
                variant="standard"
                disableUnderline
                sx={{
                  bgcolor: "#d1d1d1",
                  padding: "4px 4px",
                  borderLeft: "1px solid gray",
                }}
              >
                <MenuItem value="FLAT"> &#8377; </MenuItem>
                <MenuItem value="PERCENTAGE"> % </MenuItem>
              </Select>
            </InputAdornment>
          ),
          className: "px-0",
        }}
      />

      {isShowHelperText === true && (
        <div className="text-muted  px-1">
          {" "}
          <em> calculated : &nbsp; </em>{" "}
          <span className="text-dark font-weight-bold"> {helperText}</span>{" "}
        </div>
      )}

      {name && (
        <ErrorMessage name={name}>
          {(errMsg) => (
            <p className="position-absolute  text-danger"> {errMsg} </p>
          )}
        </ErrorMessage>
      )}
    </div>
  );
};

const CustomDatePicker = ({ name = "", label, value, onChange }) => {
  return (
    <div className="position-relative">
      <label> {label} </label>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          inputFormat="MM/DD/YYYY"
          value={value}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              fullWidth
              className="bg-white"
            />
          )}
        />
      </LocalizationProvider>

      {name && (
        <ErrorMessage name={name}>
          {(errMsg) => (
            <p className="position-absolute text-danger"> {errMsg} </p>
          )}
        </ErrorMessage>
      )}
    </div>
  );
};

const getValueAfterDiscount = (
  principleAmount = 0,
  discount = 0,
  discountType = "FLAT"
) => {
  if (discountType === "PERCENTAGE") {
    return (
      ((parseInt(principleAmount) || 0) * (100 - (parseFloat(discount) || 0))) /
      100
    );
  } else {
    return (parseInt(principleAmount) || 0) - (parseFloat(discount) || 0);
  }
};

const getDiscountedValue = (
  principleAmount = 0,
  discount = 0,
  discountType = "FLAT"
) => {
  if (discountType === "PERCENTAGE") {
    return (
      ((parseInt(principleAmount) || 0) * (parseFloat(discount) || 0)) /
      100
    ).toFixed(2);
  } else {
    return (
      ((parseFloat(discount) || 0) * 100) /
      (parseInt(principleAmount) || 0)
    ).toFixed(2);
  }
};

const UpdatePrices = ({ formikProps, onClose }) => {
  const { values, setFieldValue, errors } = formikProps;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FieldArray name="facultyWiseData">
          {({ push, remove }) => (
            <Grid container item xs={12} spacing={3}>
              {values?.facultyWiseData
                ?.filter((faculty) => faculty.faculty_mrp)
                ?.map((faculty, facultyIndex) => {
                  return (
                    <Grid
                      item
                      xs={
                        values?.facultyWiseData?.filter(
                          (faculty) => faculty.faculty_mrp
                        )?.length < 5
                          ? 12 /
                            values?.facultyWiseData?.filter(
                              (faculty) => faculty.faculty_mrp
                            )?.length
                          : 4
                      }
                    >
                      <Paper sx={{ border: "1px solid gray" }}>
                        <div className="bg-primary text-light p-2">
                          {" "}
                          {faculty.faculty_name}{" "}
                        </div>
                        <Grid
                          container
                          spacing={2}
                          rowSpacing={3}
                          sx={{ padding: 2 }}
                        >
                          {/* Faculty MRP */}
                          <Grid item xs={6}>
                            <CustomTextField
                              name={`facultyWiseData[${facultyIndex}].faculty_mrp`}
                              value={faculty.faculty_mrp}
                              onChange={(newValue) => {
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].faculty_mrp`,
                                  newValue
                                );
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].price_on_which_commission_is_calculated`,
                                  getValueAfterDiscount(
                                    newValue,
                                    faculty.discount_by_faculty,
                                    faculty.discountByFacultyType
                                  )
                                );
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].payment_to_faculty`,
                                  getValueAfterDiscount(
                                    getValueAfterDiscount(
                                      newValue,
                                      faculty.discount_by_faculty,
                                      faculty.discountByFacultyType
                                    ),
                                    faculty.commissionOnline,
                                    faculty.commissionOnlineType
                                  )
                                );
                              }}
                              label="Faculty MRP"
                            />
                          </Grid>

                          {/* Discount By Faculty */}
                          <Grid item xs={6}>
                            <CustomTextAdormantField
                              name={`facultyWiseData[${facultyIndex}].discount_by_faculty`}
                              isShowHelperText={
                                faculty?.discount_by_faculty ? true : false
                              }
                              helperText={
                                <span>
                                  {faculty.discountByFacultyType ===
                                    "PERCENTAGE" && <span>&#8377; </span>}
                                  {getDiscountedValue(
                                    faculty.faculty_mrp,
                                    faculty.discount_by_faculty,
                                    faculty.discountByFacultyType
                                  )}
                                  {faculty.discountByFacultyType === "FLAT" && (
                                    <span> % </span>
                                  )}
                                </span>
                              }
                              value={faculty.discount_by_faculty}
                              onChange={(newValue) => {
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].discount_by_faculty`,
                                  newValue
                                );
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].price_on_which_commission_is_calculated`,
                                  getValueAfterDiscount(
                                    faculty.faculty_mrp,
                                    newValue,
                                    faculty.discountByFacultyType
                                  )
                                );

                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].payment_to_faculty`,
                                  getValueAfterDiscount(
                                    getValueAfterDiscount(
                                      faculty.faculty_mrp,
                                      newValue,
                                      faculty.discountByFacultyType
                                    ),
                                    faculty.commissionOnline,
                                    faculty.commissionOnlineType
                                  )
                                );
                              }}
                              selectValue={faculty.discountByFacultyType}
                              onSelectChange={(newValue) => {
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].discountByFacultyType`,
                                  newValue
                                );
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].price_on_which_commission_is_calculated`,
                                  getValueAfterDiscount(
                                    faculty.faculty_mrp,
                                    faculty.discount_by_faculty,
                                    newValue
                                  )
                                );

                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].payment_to_faculty`,
                                  getValueAfterDiscount(
                                    getValueAfterDiscount(
                                      faculty.faculty_mrp,
                                      faculty.discount_by_faculty,
                                      newValue
                                    ),
                                    faculty.commissionOnline,
                                    faculty.commissionOnlineType
                                  )
                                );
                              }}
                              label="Discount By Faculty"
                            />
                          </Grid>

                          {/* Price For Comm. Calculation */}
                          <Grid item xs={6}>
                            <CustomTextField
                              name={`facultyWiseData[${facultyIndex}].price_on_which_commission_is_calculated`}
                              value={
                                faculty.price_on_which_commission_is_calculated
                              }
                              label="Comm. Calculation Price"
                              disabled
                            />
                          </Grid>

                          {/* Commission Online */}
                          <Grid item xs={6}>
                            <CustomTextAdormantField
                              name="commissionOnline"
                              value={faculty.commissionOnline}
                              isShowHelperText={
                                faculty?.commissionOnline ? true : false
                              }
                              helperText={
                                <span>
                                  {faculty.commissionOnlineType ===
                                    "PERCENTAGE" && <span>&#8377; </span>}
                                  {getDiscountedValue(
                                    faculty.price_on_which_commission_is_calculated,
                                    faculty.commissionOnline,
                                    faculty.commissionOnlineType
                                  )}
                                  {faculty.commissionOnlineType === "FLAT" && (
                                    <span> % </span>
                                  )}
                                </span>
                              }
                              onChange={(newValue) => {
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].commissionOnline`,
                                  newValue
                                );

                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].payment_to_faculty`,
                                  getValueAfterDiscount(
                                    faculty.price_on_which_commission_is_calculated,
                                    newValue,
                                    faculty.commissionOnlineType
                                  )
                                );
                              }}
                              selectValue={faculty.commissionOnlineType}
                              onSelectChange={(newValue) => {
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].commissionOnlineType`,
                                  newValue
                                );
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].payment_to_faculty`,
                                  getValueAfterDiscount(
                                    faculty.price_on_which_commission_is_calculated,
                                    faculty.commissionOnline,
                                    newValue
                                  )
                                );
                              }}
                              label="Commission Online"
                            />
                          </Grid>


                            {/* Commission offline */}
                            <Grid item xs={6}>
                            <CustomTextAdormantField
                              name="commissionOffline"
                              value={faculty.commissionOffline}
                              isShowHelperText={
                                faculty?.commissionOffline ? true : false
                              }
                              helperText={
                                <span>
                                  {faculty.commissionOfflineType ===
                                    "PERCENTAGE" && <span>&#8377; </span>}
                                  {getDiscountedValue(
                                    faculty.price_on_which_commission_is_calculated,
                                    faculty.commissionOffline,
                                    faculty.commissionOfflineType
                                  )}
                                  {faculty.commissionOfflineType === "FLAT" && (
                                    <span> % </span>
                                  )}
                                </span>
                              }
                              onChange={(newValue) => {
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].commissionOffline`,
                                  newValue
                                );

                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].payment_to_faculty_offline`,
                                  getValueAfterDiscount(
                                    faculty.price_on_which_commission_is_calculated,
                                    newValue,
                                    faculty.commissionOfflineType
                                  )
                                );
                              }}
                              selectValue={faculty.commissionOfflineType}
                              onSelectChange={(newValue) => {
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].commissionOfflineType`,
                                  newValue
                                );
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].payment_to_faculty`,
                                  getValueAfterDiscount(
                                    faculty.price_on_which_commission_is_calculated,
                                    faculty.commissionOffline,
                                    newValue
                                  )
                                );
                              }}
                              label="Commission Offline"
                            />
                          </Grid>


                          {/* Payment To Faculty */}
                          <Grid item xs={6}>
                            <CustomTextField
                              name={`facultyWiseData[${facultyIndex}].payment_to_faculty`}
                              value={faculty.payment_to_faculty}
                              onChange={(newValue) =>
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].payment_to_faculty`,
                                  newValue
                                )
                              }
                              label="Payment To Faculty"
                              disabled
                            />
                          </Grid>

                          
                          {/* Payment To Faculty Offline*/}
                          <Grid item xs={6}>
                            <CustomTextField
                              name={`facultyWiseData[${facultyIndex}].payment_to_faculty_offline`}
                              value={faculty.payment_to_faculty_offline}
                              onChange={(newValue) =>
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].payment_to_faculty_offline`,
                                  newValue
                                )
                              }
                              label="Payment To Faculty Offline"
                              disabled
                            />
                          </Grid>

                          {/* Discount By Us */}
                          <Grid item xs={6}>
                            <CustomTextAdormantField
                              name={`facultyWiseData[${facultyIndex}].discount_by_us`}
                              value={faculty.discount_by_us}
                              isShowHelperText={
                                faculty?.discount_by_us ? true : false
                              }
                              helperText={
                                <span>
                                  {faculty.commissionOnlineType ===
                                    "PERCENTAGE" && <span>&#8377; </span>}
                                  {getDiscountedValue(
                                    faculty.price_on_which_commission_is_calculated,
                                    faculty.discount_by_us,
                                    faculty.discountByUsType
                                  )}
                                  {faculty.discountByUsType === "FLAT" && (
                                    <span> % </span>
                                  )}
                                </span>
                              }
                              onChange={(newValue) => {
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].discount_by_us`,
                                  newValue
                                );
                              }}
                              selectValue={faculty.discountByUsType}
                              onSelectChange={(newValue) =>
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].discountByUsType`,
                                  newValue
                                )
                              }
                              label="Discount By Us"
                            />
                          </Grid>

                          {/* SLD 50 */}
                          <Grid item xs={6}>
                            <CustomTextAdormantField
                              name={`facultyWiseData[${facultyIndex}].sld_50`}
                              value={faculty.sld_50}
                              isShowHelperText={faculty?.sld_50 ? true : false}
                              helperText={
                                <span>
                                  {faculty.commissionOnlineType ===
                                    "PERCENTAGE" && <span>&#8377; </span>}
                                  {getDiscountedValue(
                                    faculty.price_on_which_commission_is_calculated,
                                    faculty.sld_50,
                                    faculty.sld50Type
                                  )}
                                  {faculty.sld50Type === "FLAT" && (
                                    <span> % </span>
                                  )}
                                </span>
                              }
                              onChange={(newValue) =>
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].sld_50`,
                                  newValue
                                )
                              }
                              selectValue={faculty.sld50Type}
                              onSelectChange={(newValue) =>
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].sld50Type`,
                                  newValue
                                )
                              }
                              label="SLD 50"
                            />
                          </Grid>

                          {/* Start Date (Faculty Dis.) */}
                          <Grid item xs={6}>
                            <CustomDatePicker
                              name="start_date_dis_by_faculty"
                              value={faculty.start_date_dis_by_faculty}
                              onChange={(newValue) =>
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].start_date_dis_by_faculty`,
                                  newValue
                                )
                              }
                              label="Start Date (Faculty Dis.)"
                            />
                          </Grid>

                          {/* End Date (Faculty Dis.) */}
                          <Grid item xs={6}>
                            <CustomDatePicker
                              name="end_date_dis_by_faculty"
                              value={faculty.end_date_dis_by_faculty}
                              onChange={(newValue) =>
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].end_date_dis_by_faculty`,
                                  newValue
                                )
                              }
                              label="End Date (Faculty Dis.)"
                            />
                          </Grid>

                          {/* Start Date (Discount By Us) */}
                          <Grid item xs={6}>
                            <CustomDatePicker
                              name="start_date_dis_by_us"
                              value={faculty.start_date_dis_by_us}
                              onChange={(newValue) =>
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].start_date_dis_by_us`,
                                  newValue
                                )
                              }
                              label="Start Date (Dis. By Us)"
                            />
                          </Grid>

                          {/* End Date (Discount By Us) */}
                          <Grid item xs={6}>
                            <CustomDatePicker
                              name="end_date_dis_by_us"
                              value={faculty.end_date_dis_by_us}
                              onChange={(newValue) =>
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].end_date_dis_by_us`,
                                  newValue
                                )
                              }
                              label="End Date (Dis. By Us)"
                            />
                          </Grid>

                          {/* GST Applied (In %) */}
                          <Grid item xs={6}>
                            <CustomTextField
                              name={`facultyWiseData[${facultyIndex}].gst_applied_percent`}
                              value={faculty.gst_applied_percent}
                              onChange={(newValue) =>
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].gst_applied_percent`,
                                  newValue
                                )
                              }
                              label="GST Applied (In %)"
                            />
                          </Grid>

                          {/* GST Sharing (In %) */}
                          <Grid item xs={6}>
                            <CustomTextField
                              name={`facultyWiseData[${facultyIndex}].gst_sharing_percent`}
                              value={faculty.gst_sharing_percent}
                              onChange={(newValue) =>
                                setFieldValue(
                                  `facultyWiseData[${facultyIndex}].gst_sharing_percent`,
                                  newValue
                                )
                              }
                              label="GST Sharing (In %)"
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                })}
            </Grid>
          )}
        </FieldArray>
      </Grid>

      <div className="px-3">
        <Paper className="my-3 py-4 border">
          <Grid items={12} container spacing={2} px={2}>
            {/* Max Discount */}
            <Grid item xs={2}>
              <CustomTextField
                name={`max_discount`}
                value={values.max_discount}
                onChange={(newValue) => {
                  setFieldValue(`max_discount`, newValue);
                }}
                label="Max Discount"
              />
            </Grid>

            {/* Wallet Discount */}
            <Grid item xs={2}>
              <CustomTextAdormantField
                name={`wallet_discount`}
                value={values.wallet_discount}
                onChange={(newValue) => {
                  setFieldValue(`wallet_discount`, newValue);
                }}
                selectValue={values.walletDiscountType}
                onSelectChange={(newValue) => {
                  setFieldValue(`walletDiscountType`, newValue);
                }}
                label="Wallet Discount"
              />
            </Grid>

            {/* Franchise Discount 50 */}
            <Grid item xs={2}>
              <CustomTextAdormantField
                name={`franchise_discount_50`}
                value={values.franchise_discount_50}
                onChange={(newValue) => {
                  setFieldValue(`franchise_discount_50`, newValue);
                }}
                selectValue={values.franchiseDiscount50Type}
                onSelectChange={(newValue) => {
                  setFieldValue(`franchiseDiscount50Type`, newValue);
                }}
                label="Franchise Discount 50"
              />
            </Grid>

            {/* Referral To Percentage */}
            <Grid item xs={2}>
              <CustomTextAdormantField
                name={`referral_to_percentage`}
                value={values.referral_to_percentage}
                onChange={(newValue) => {
                  setFieldValue(`referral_to_percentage`, newValue);
                }}
                selectValue={values.referralToPercentageType}
                onSelectChange={(newValue) => {
                  setFieldValue(`referralToPercentageType`, newValue);
                }}
                label="Referral To Percentage"
              />
            </Grid>

            {/* Referral By Percentage */}
            <Grid item xs={2}>
              <CustomTextAdormantField
                name={`referral_by_percentage`}
                value={values.referral_by_percentage}
                onChange={(newValue) => {
                  setFieldValue(`referral_by_percentage`, newValue);
                }}
                selectValue={values.referralByPercentageType}
                onSelectChange={(newValue) => {
                  setFieldValue(`referralByPercentageType`, newValue);
                }}
                label="Referral By Percentage"
              />
            </Grid>
          </Grid>
        </Paper>
      </div>

      <Grid item xs={12} container justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UpdatePrices;
