
import React, { useState, useEffect } from 'react';
import EditDelete from './EditDelete';
import CreateTableAddButton from './CreateTableAddButton'
import Toster from './Toster';
import { Radio } from '@material-ui/core';
import Loader from './Loader';
import {
    useGetAllInquiryMutation,
    useUpdateInquiryMutation,
    useDeletInquiryMutation,
    useAddCallBackReqMutation,
} from '../../../services/services';
import SkeletonListing from './SkeletonListing';
import AddDataHeader from './AddDataHeader';
import DataNotFound from './DataNotFound';
import AddCallbackRequest from './AddCallbackRequest';
import EmailCallRequest from './EmailCallRequest';
import ViewCallbackRequest from './ViewCallbackRequest';
import { Button, Grid, TextField } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';
import MaterialTables from "../dashboard/MaterialTables"
import AuthWrapper from '../../../utils/AuthWrapper';
import { getFilterdColumns } from '../../../utils/getFilterFunction';

export default function CallBackRequestList() {
    const columns = [
        {
            id: 'sno',
            label: 'SNO.',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'userName',
            label: 'User Name',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'mobile',
            label: 'Mobile',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'email',
            label: 'Email',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'userMessage',
            label: 'User Message',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'courseName',
            label: 'Course Name',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'date',
            label: 'Date',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'attempted',
            label: 'Attempted',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'actions',
            label: 'Actions',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
            authName: ['PRE_SELL_SUPPORT_CALL_BACK_REQUEST_DELETE', 'PRE_SELL_SUPPORT_CALL_BACK_REQUEST_ADD_FOLLOWUP', 'PRE_SELL_SUPPORT_CALL_BACK_REQUEST_LIST_FOLLOWUP']
        },
    ];
    function createData(sno, userName, mobile, email, userMessage, courseName, date, attempted, actions) {
        return { sno, userName, mobile, email, userMessage, courseName, date, attempted, actions };
    }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const paginationData = {
        "limit": rowsPerPage,
        "query": "",
        "params": [
            "email", "mobile", "fullname"
        ],
        "page": page + 1
    }
    let now = new Date()
    let rows = []

    let [getid, setGetid] = useState('')
    const [data, setData] = useState('')
    const [AddDialog, setDialog] = useState(false)
    const [editDialog, setEditDialog] = useState(false)
    const [viewDialog, setViewDialog] = useState(false)
    const [getAllData, getAllDataInfo] = useGetAllInquiryMutation()
    const [deleteData, deleteDataInfo] = useDeletInquiryMutation()
    //const [updateSubjectData, updateDataInfo] = useUpdateQuoteMutation();
    const [feedbackDialogs, setFeedbackDialogs] = useState({ viewFeedback: false, addFeedback: false })
    const [addData, addDataInfo] = useAddCallBackReqMutation()
    const [updateSubjectData, updateDataInfo] = useUpdateInquiryMutation()
    let arr = [true, true]
    const AllData = getAllDataInfo.data
    const totalItem = AllData ? AllData.totalItem : 0
    const [render, setRender] = useState(false)
    const [addFeedbackData, setAddFeedbackData] = useState({
        inquiry_id: "",
        discription: "",
        discription_error: "",
        next_date: moment(now).format("YYYY-MM-DD"),
        next_date_error: "",
        next_time: "12:00",
        next_time_error: "",
        intrested_or_not: "",
        intrested_or_not_error: "",
        more_than_3: "YES"
    })
    const [followUpData, setFollowUpData] = useState([])
    const followUpTableHeaders = ["added_by_sldUser", "added_on_date", "discription", "intrested_or_not", "next_date_time", "sldUser_type"]

    useEffect(() => {
        getAllData(paginationData)
    }, [render, rowsPerPage, page,])

    useEffect(() => {
        getid && deleteData(getid) && setGetid("")
    }, [getid])

    const getFollowupIndicators = (length) => {
        let followupIndicators = []
        let j = 1
        for (let i = 1; i <= length; i++) {
            if (j > 3) {
                return <>{followupIndicators}</>
            } else {
                followupIndicators.push(<Radio checked={true} className="text-success" />)
                j++
            }
        }
        for (let i = 1; i <= (3 - length); i++) {
            followupIndicators.push(<Radio checked={true} className="text-muted" />)
        }
        return <>{followupIndicators}</>


    }

    const AddData = (data) => {
        addData(data)
    }
    const UpdateData = (data, id) => {
        updateSubjectData({ body: data, id: id })
    }
    let totalData = "ok"
    if (AllData) {

        totalData = AllData.totalItem
        rows = AllData.data.map((data, index) => createData(
            index + 1,
            data.fullname,
            data.mobile,
            data.email,
            data.discription,
            data.course,
            moment(data.createdAt).format("DD-MM-YYYY hh:mm:ss A"),
            <div className="d-flex flex-column">
                {getFollowupIndicators(data.attempt_count)}
            </div>,
            <div className=" justify-content-center">
                {/* <EditDelete setViewDialog={setViewDialog}
                    type="view" setData={setData}
                    setGetid={setGetid} data={data} />
                <EditDelete
                    setEditDialog={setEditDialog}
                    type="edit" setData={setData}
                    setGetid={setGetid} data={data} /> */}
                <AuthWrapper type="ACTION" name="PRE_SELL_SUPPORT_CALL_BACK_REQUEST_ADD_FOLLOWUP">
                    <Button variant="contained" color="primary" style={{ fontSize: "11px" }} onClick={() => {
                        setFeedbackDialogs({ ...feedbackDialogs, addFeedback: true })
                        setAddFeedbackData({
                            inquiry_id: data._id,
                            discription: "",
                            discription_error: "",
                            next_date: moment(now).format("YYYY-MM-DD"),
                            next_date_error: "",
                            next_time: "12:00",
                            next_time_error: "",
                            intrested_or_not: "",
                            intrested_or_not_error: "",
                            more_than_3: "YES"
                        })
                    }}  >
                        +FollowUp
                    </Button>
                </AuthWrapper>
                <br />
                <AuthWrapper type="ACTION" name="PRE_SELL_SUPPORT_CALL_BACK_REQUEST_LIST_FOLLOWUP">
                    <Button className='mt-2' variant="contained" color="success" style={{ fontSize: "11px" }} onClick={() => {
                        setFeedbackDialogs({ ...feedbackDialogs, viewFeedback: true })
                        setFollowUpData(data.follow_up != undefined ? [...data.follow_up] : [])
                    }} >
                        FollowUp
                    </Button>
                </AuthWrapper>
                <br />
                <AuthWrapper type="ACTION" name="PRE_SELL_SUPPORT_CALL_BACK_REQUEST_DELETE">
                    <EditDelete type="delete"
                        setGetid={setGetid}
                        id={data._id} />
                </AuthWrapper>
            </div>
        )
        )
    }
    const dialogclose = () => {
        setEditDialog(false)
        setViewDialog(false)
        setDialog(false)
        setRender(!render)
    }
    const handleAddFollowUpSubmit = () => {
        let feedbackData = Object.assign({}, addFeedbackData);
        let isValid = true

        for (let key in feedbackData) {
            if (feedbackData[key] == "" && !key.includes("_error")) {
                isValid = false
                feedbackData[key + "_error"] = "Required !"
            } else {
                feedbackData[key + "_error"] = ""
            }
        }
        setAddFeedbackData(Object.assign({}, feedbackData))
        if (isValid) {
            let dataToSend = {
                discription: feedbackData.discription,
                next_date_time: feedbackData.next_date + " " + feedbackData.next_time,
                intrested_or_not: feedbackData.intrested_or_not,
                more_than_3: "YES"
            }
            updateSubjectData({ body: dataToSend, id: feedbackData.inquiry_id })
            setFeedbackDialogs({ ...feedbackDialogs, addFeedback: false })
        }
    }

    return (
        <>
            <div>
                <AddDataHeader
                    addFormName={"Add Call Back Request"}
                    setPage={setPage}
                    page={page}
                    excelBtn={false}
                    totalItem={totalItem}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    setAddDialog={setDialog}
                    searchBarApi={getAllData}
                    paginationData={paginationData}
                    isButton={true}
                    Adddialog={AddDialog}
                    fullWidth={false}
                    isSearch={
                        !AuthWrapper({
                            type: "ACTION",
                            name: "PRE_SELL_SUPPORT_CALL_BACK_REQUEST_LIST",
                            inBoolean: true,
                        })
                    }
                    isPagination={
                        !AuthWrapper({
                            type: "ACTION",
                            name: "PRE_SELL_SUPPORT_CALL_BACK_REQUEST_LIST",
                            inBoolean: true,
                        })
                    }
                    addBtn={
                        !AuthWrapper({
                            type: "ACTION",
                            name: "PRE_SELL_SUPPORT_CALL_BACK_REQUEST_ADD",
                            inBoolean: true,
                        })
                    }
                    form={<AddCallbackRequest AddData={AddData} setDialog={setDialog} />}
                />

                <AuthWrapper type="ACTION" name="PRE_SELL_SUPPORT_CALL_BACK_REQUEST_LIST">
                    {deleteDataInfo.isLoading || addDataInfo.isLoading || updateDataInfo.isLoading ? <Loader /> : <>       {
                        !viewDialog ?
                            rows.length ?
                                <CreateTableAddButton
                                    editDialog={editDialog}
                                    rows={rows}
                                    editform={
                                        <EmailCallRequest
                                            UpdateData={UpdateData}
                                            editData={data}
                                            editDialog={setEditDialog} />
                                    }
                                    columns={getFilterdColumns(columns)}
                                    isButton={true}
                                    editFormName={'Call Back Request Reply'} />
                                : totalData == 0 ? <DataNotFound /> : <SkeletonListing />
                            :
                            <ViewCallbackRequest UpdateData={UpdateData} editData={data} viewDialog={setViewDialog} data={data} />}
                    </>}
                </AuthWrapper>

                {deleteDataInfo.data && <Toster info={deleteDataInfo} dialogclose={dialogclose} />}
                {addDataInfo.data && <Toster info={addDataInfo} dialogclose={dialogclose} />}
                {updateDataInfo && <Toster info={updateDataInfo} dialogclose={dialogclose} />}

            </div>

            <Dialog fullWidth={true} open={feedbackDialogs.addFeedback} onClose={() => { setFeedbackDialogs({ ...feedbackDialogs, addFeedback: false }) }}>
                <DialogTitle>
                    Add Follow-Up
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container>
                            <Grid xs={12} className="my-2" >
                                <TextField
                                    type="text"
                                    fullWidth
                                    onChange={(e) => { setAddFeedbackData({ ...addFeedbackData, discription: e.target.value }) }}
                                    name="discription"
                                    value={addFeedbackData.discription}
                                    label="Discription"
                                    variant="outlined"
                                    multiline
                                    minRows={3}
                                />
                                <span style={{ "fontSize": "12px" }} className='text-danger' >{addFeedbackData.discription_error}</span>
                            </Grid>
                            <Grid xs={12} className="my-2" >
                                <TextField
                                    type="date"
                                    fullWidth
                                    onChange={(e) => { setAddFeedbackData({ ...addFeedbackData, next_date: e.target.value }) }}
                                    name="next_date"
                                    value={addFeedbackData.next_date}
                                    label="Next Follow Up Date"
                                    variant="outlined"
                                />
                                <span style={{ "fontSize": "12px" }} className='text-danger' >{addFeedbackData.next_date_error}</span>
                            </Grid>
                            <Grid xs={12} className="my-2" >
                                <TextField
                                    type="time"
                                    fullWidth
                                    onChange={(e) => { setAddFeedbackData({ ...addFeedbackData, next_time: e.target.value }) }}
                                    name="next_time"
                                    value={addFeedbackData.next_time}
                                    label="Next Follow Up Time"
                                    variant="outlined"
                                />
                                <span style={{ "fontSize": "12px" }} className='text-danger' >{addFeedbackData.next_time_error}</span>
                            </Grid>
                            <Grid xs={12} className="my-2" >
                                <TextField
                                    type="text"
                                    fullWidth
                                    onChange={(e) => { setAddFeedbackData({ ...addFeedbackData, intrested_or_not: e.target.value }) }}
                                    name="intrested_or_not"
                                    value={addFeedbackData.intrested_or_not}
                                    label="Intrested Or Not ?"
                                    variant="outlined"
                                />
                                <span style={{ "fontSize": "12px" }} className='text-danger' >{addFeedbackData.intrested_or_not_error}</span>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='p-3' >
                    <Button className='bg-danger text-light' onClick={() => { setFeedbackDialogs({ ...feedbackDialogs, addFeedback: false }) }}>
                        Close
                    </Button>
                    <Button className='bg-success text-light' onClick={() => handleAddFollowUpSubmit()}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog maxWidth="md" fullWidth={true} open={feedbackDialogs.viewFeedback} onClose={() => { setFeedbackDialogs({ ...feedbackDialogs, viewFeedback: false }) }}>
                <DialogTitle>
                    Follow-Up List
                </DialogTitle>
                <DialogContent className='' >
                    {followUpData.length ?
                        <MaterialTables columns={followUpTableHeaders} rows={followUpData} /> :
                        <div className='text-center bg-light'  >No Data Found</div>
                    }
                </DialogContent>
                <DialogActions className='p-3' >
                    <Button className='bg-danger text-light' onClick={() => { setFeedbackDialogs({ ...feedbackDialogs, viewFeedback: false }) }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}