import React, { useState, useEffect } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import {
  Avatar,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core";
import Loader from "../Loader";
import AddDataHeader from "../AddDataHeader";
import {
  useGetAallFacultiesMutation,
  useDeleteFacultyMutation,
  useAddFacultyMutation,
} from "../../../../services/services";
import { useGetSpecializationListQuery } from "../../../../services/specializationServices";
import Toster from "../Toster";
import AddFaculty from "./AddFaculty";
import ViewFaculties from "./ViewFaculties";
import SkeletonListing from "../SkeletonListing";
import DataNotFound from "../DataNotFound";
import moment from "moment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListIcon from "@material-ui/icons/FilterList";
import Filters from "../Filters";
import AuthWrapper from "../../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../../utils/getFilterFunction";

export default function Faculties() {
  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "faculty",
      label: "Faculty",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "contact",
      label: "Contact & Institute",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "facultyGrade",
      label: "Grade",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "facultyRank",
      label: "Rank",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "IsActive",
      label: "Active",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "specialization",
      label: "Specialization",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "paymentMethod",
      label: "Payment Method",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "joining_date",
      label: "Join Date",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "Actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: [
        "FACULTY_INSTITUTE_FACULTY_VIEW",
        "FACULTY_INSTITUTE_FACULTY_DELETE",
      ],
    },
  ];

  function createData(
    SNo,
    faculty,
    contact,
    facultyGrade,
    facultyRank,
    IsActive,
    specialization,
    paymentMethod,
    joining_date,
    Actions
  ) {
    return {
      SNo,
      faculty,
      contact,
      facultyGrade,
      facultyRank,
      IsActive,
      specialization,
      paymentMethod,
      joining_date,
      Actions,
    };
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredPagination, setFilteredPagination] = useState([]);
  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: ["name"],
    filterBy: filteredPagination,
    page: page + 1,
  };

  let rows = [];
  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [AddDialog, setDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [getAllData, getAllDataInfo] = useGetAallFacultiesMutation();
  const [deleteFaculty, deleteFacultyInfo] = useDeleteFacultyMutation();
  const [addData, addDataInfo] = useAddFacultyMutation();
  const specializationData = useGetSpecializationListQuery();
  const AallFaculties = getAllDataInfo.data;
  const allSpecialization = specializationData?.data?.status
    ? specializationData.data.data.map((e, i) => {
        return e.specialization;
      })
    : [];
  const totalItem = AallFaculties ? AallFaculties.totalItem : 0;
  const [render, setRender] = useState(false);

  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page, filteredPagination]);

  useEffect(() => {
    getid && deleteFaculty(getid) && setGetid("");
  }, [getid]);

  const AddData = (data) => {
    addData(data);
  };

  let totalData = "ok";
  if (AallFaculties) {
    totalData = AallFaculties.totalItem;
    rows = AallFaculties.data.map((data, index) =>
      createData(
        index + 1,
        <div className="">
          <div className="d-flex justify-content-center">
            <Avatar src={data.image} />
          </div>
          <span className="text-info font-weight-bold">{data.name}</span>
          <br />
          <span className="text-muted">({data.faculty_code})</span>
        </div>,
        <div>
          <span className="text-info font-weight-bold">{data.email}</span>
          <br />
          <span className="text-muted font-weight-bold">
            ({data.institute.institute_name})
          </span>
          <br />
          <span className="text-muted">{data.management_number},</span>
          <br />
          <span className="text-muted">{data.personal_number}</span>
        </div>,
        <div>
          <span className="text-info font-weight-bold">
            {data.faculty_grade}
          </span>
          <br />
          <span className="text-muted">( {data.faculty_grade_basis} )</span>
        </div>,
        data.faculty_rank,
        data.is_active ? "Yes" : "No",
        data.specialization,
        data.payment_method,
        moment(data.joining_date).format("DD-MM-YYYY"),
        <div className="">
          {/* <Switch
            onClick={() => {
              updateIsShowStatus({
                key: "contact_us",
                cms_data: {
                  is_show: !data.is_show,
                  contact_us_id: data._id,
                },
              });
            }}
            checked={data.is_show}
          /> */}
          <AuthWrapper type="ACTION" name="FACULTY_INSTITUTE_FACULTY_VIEW">
            <EditDelete
              setViewDialog={setViewDialog}
              type="view"
              setData={setData}
              setGetid={setGetid}
              data={data}
            />
          </AuthWrapper>
          <br />
          <AuthWrapper type="ACTION" name="FACULTY_INSTITUTE_FACULTY_DELETE">
            <EditDelete type="delete" setGetid={setGetid} id={data._id} />
          </AuthWrapper>
        </div>
      )
    );
  }
  const dialogclose = () => {
    setViewDialog(false);
    setDialog(false);
    setRender(!render);
  };
  return (
    <div>
      {!viewDialog && (
        <AddDataHeader
          addFormName={"Add Faculty"}
          setPage={setPage}
          page={page}
          facultyDownloadTemplate={AuthWrapper({
            type: "ACTION",
            name: "FACULTY_INSTITUTE_FACULTY_DOWNLOAD_TEMPLATE",
            inBoolean: true,
          })}
          addExcelFaculty={AuthWrapper({
            type: "ACTION",
            name: "FACULTY_INSTITUTE_FACULTY_UPLOAD_EXCEL",
            inBoolean: true,
          })}
          downloadExcelFaculty={AuthWrapper({
            type: "ACTION",
            name: "FACULTY_INSTITUTE_FACULTY_DOWNLOAD_EXCEL",
            inBoolean: true,
          })}
          excelBtn={true}
          isSearch={
            !AuthWrapper({
              type: "ACTION",
              name: "FACULTY_INSTITUTE_FACULTY_LIST",
              inBoolean: true,
            })
          }
          isPagination={
            !AuthWrapper({
              type: "ACTION",
              name: "FACULTY_INSTITUTE_FACULTY_LIST",
              inBoolean: true,
            })
          }
          addBtn={
            !AuthWrapper({
              type: "ACTION",
              name: "FACULTY_INSTITUTE_FACULTY_ADD",
              inBoolean: true,
            })
          }
          currentPageName="faculty"
          totalItem={totalItem}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setAddDialog={setDialog}
          searchBarApi={getAllData}
          paginationData={paginationData}
          isButton={true}
          Adddialog={AddDialog}
          form={
            <AddFaculty
              AddData={AddData}
              setDialog={setDialog}
              allSpecialization={allSpecialization}
            />
          }
          filteredPagination={filteredPagination}
        />
      )}
      <AuthWrapper type="ACTION" name="FACULTY_INSTITUTE_FACULTY_LIST">
        {!viewDialog && (
          <Accordion className="">
            <AccordionSummary
              className="text-primary"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="d-flex align-items-center">
                <FilterListIcon /> <span> Filter</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="">
              <Filters
                setFilteredPagination={setFilteredPagination}
                isFacFilter={true}
                module="faculty"
                filterListingData={{ allSpecialization: allSpecialization }}
                isSpecializationFilter={true}
                isGradeFilter={true}
                isRankFilter={false}
                isInstituteFilter={true}
              />
            </AccordionDetails>
          </Accordion>
        )}
      </AuthWrapper>
      {deleteFacultyInfo.isLoading || addDataInfo.isLoading ? (
        <Loader />
      ) : (
        <>
          <AuthWrapper type="ACTION" name="FACULTY_INSTITUTE_FACULTY_LIST">
            {!viewDialog ? (
              rows.length ? (
                <CreateTableAddButton
                  rows={rows}
                  editform={null}
                  columns={getFilterdColumns(columns)}
                  isButton={true}
                />
              ) : totalData == 0 ? (
                <DataNotFound />
              ) : (
                <SkeletonListing />
              )
            ) : (
              <ViewFaculties
                viewDialog={setViewDialog}
                data={data}
                allSpecialization={allSpecialization}
              />
            )}
          </AuthWrapper>
        </>
      )}
      {addDataInfo.data && (
        <Toster info={addDataInfo} dialogclose={dialogclose} />
      )}
      {deleteFacultyInfo.data && (
        <Toster info={deleteFacultyInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
