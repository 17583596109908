import React, { } from 'react';
import { Grid } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import { Button, TextareaAutosize, MenuItem, Select, InputLabel, FormControl } from '@material-ui/core';

import { useFormik } from 'formik';
import * as yup from 'yup'


export default function EditTicket({ editData, editDialog, UpdateData, isCencel }) {

    const dialogclose = () => { editDialog(false) }
    const editTicketData = editData.data
      

    const formik = useFormik({
        initialValues: {
            description: editTicketData.description,
            status: editTicketData.status,
            sld_user_note: editTicketData.sld_user_note,
            website_user_note: editTicketData.website_user_note
        },
        validationSchema: yup.object({
            description: yup.string().required("Required !"),
            status: yup.string().required("Required !"),
            sld_user_note: yup.string().required("Required !"),
            website_user_note: yup.string().required("Required !"),
        }),
        onSubmit: (values) => {
            UpdateData(values, editTicketData._id)
        }
    })

    return (

        <form onSubmit={formik.handleSubmit} className="mb-5 p-2">

            <TextField
                rows={5}
                multiline
                name="description"
                onChange={formik.handleChange}
                InputProps={{ inputProps: { min: 0 } }}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                fullWidth={true}
                label="Description "
                variant="outlined"
                size="large" />
            {formik.touched.description && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.description}</span>}

            <br /><br />
            <FormControl fullWidth className='mb-1'>
                <InputLabel id="demo-simple-select-label" sx={{ width: 250 }}
                    InputLabelProps={{
                        shrink: true,
                    }}>Status</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formik.values.status}
                    onBlur={formik.handleBlur}
                    label="Status"
                    name="status"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}


                >
                    <MenuItem value='RAISED_AND_PENDING'>RAISED AND PENDING</MenuItem>
                    <MenuItem value='OPEN_AND_ACTIVE'>OPEN AND ACTIVE</MenuItem>
                    <MenuItem value='RESOLVED_AND_CLOSED'>RESOLVED AND CLOSED</MenuItem>
                </Select>
            </FormControl>
            {formik.touched.status && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.status}</span>}

            
            <TextField className="mt-4"
                name="sld_user_note"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.sld_user_note}
                id="text"
                label="Sld User Note"
                type="text"
                fullWidth={true}
                sx={{ width: 250 }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            {formik.touched.sld_user_note && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.sld_user_note}</span>}

            <br /><br />
            <TextField
                name="website_user_note"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.website_user_note}
                id="text"
                label="Website User Note"
                type="text"
                fullWidth={true}
                sx={{ width: 250 }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            {formik.touched.website_user_note && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.website_user_note}</span>}

            <br /><br />
            <Button className="m-2" type="submit" variant="contained" color="primary">
                Save
            </Button>

            {!isCencel && <Button className="m-2" onClick={() => dialogclose(false)} variant="contained" color="secondary">
                Close
            </Button>}
        </form>

    )
}
