import React, { useState, useEffect } from "react";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import * as yup from 'yup'
import { useFormik } from 'formik';
import {
  Grid,
  Button,
  Card,
  Box,
  CardContent,
  Dialog, Zoom, DialogTitle, DialogContent, TextField,
  ListItemText
} from "@material-ui/core";
import {
  useUpdateFacultyMutation,
  useGetSingleFacultyMutation,
} from '../../../../services/services'
import DataNotFound from "../DataNotFound";


export default function Address({ viewDialog, data, setFacultyData }) {

  const [updateData, updateDataInfo] = useUpdateFacultyMutation();
  const [singleFacultyData, singleFacultyDataInfo] = useGetSingleFacultyMutation();
  const [updateDialog, setUpdateDialog] = useState(false);


  useEffect(() => {
    singleFacultyData(data._id)
  }, [updateDataInfo])

  const dialogclose = () => {
    setUpdateDialog(false);
  };

  return (
    <>
      <Card sx={{ display: "flex" }} className="m-3" >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>

            <Button
              onClick={() => viewDialog(false)}
              className="text-light bg-danger float-right btn btn-sm btn-danger"
            >
              Close
            </Button>
          </CardContent>

          {singleFacultyDataInfo.isSuccess == true && singleFacultyDataInfo.data.data.length ?
            singleFacultyDataInfo.isLoading == false ?
              <div>
                {setFacultyData(singleFacultyDataInfo.data.data[0])}
                <Button
                  onClick={() => {
                    setUpdateDialog(true);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Update Address
                </Button>

                <Card className="m-3 pb-3">
                  <Grid
                    container
                    spacing={1}
                    direction="column"
                    alignItems="left"
                    justifyContent="left"
                  >
                    {
                      data.address != undefined && (Object.keys(data.address).length > 0) ?

                        <Grid item xs={12}>

                          <CardContent className="bg-light p-3 infoCards">

                            <Grid container spacing={6}>
                              <Grid className="text-muted text-left" item md={6}>
                                <h5>Address Line 1</h5>

                              </Grid>
                              <Grid className="text-muted text-left" item md={6}>
                                <h5>{data.address.address_line1}</h5>

                              </Grid>

                            </Grid>
                            <hr />

                            <Grid container spacing={6}>
                              <Grid className="text-muted text-left" item md={6}>
                                <h5>Address Line 2</h5>

                              </Grid>
                              <Grid className="text-muted text-left" item md={6}>
                                <h5>{data.address.address_line2}</h5>

                              </Grid>

                            </Grid>
                            <hr />

                            <Grid container spacing={6}>
                              <Grid className="text-muted text-left" item md={6}>
                                <h5>City</h5>

                              </Grid>
                              <Grid className="text-muted text-left" item md={6}>
                                <h5>{data.address.city}</h5>

                              </Grid>

                            </Grid>
                            <hr />

                            <Grid container spacing={6}>

                              <Grid className="text-muted text-left" item md={6}>
                                <h5>Pincode</h5>

                              </Grid>
                              <Grid className="text-muted text-left" item md={6}>
                                <h5>{data.address.pincode}</h5>

                              </Grid>

                            </Grid>
                            <hr />

                            <Grid container spacing={6}>
                              <Grid className="text-muted text-left" item md={6}>
                                <h5>State</h5>

                              </Grid>
                              <Grid className="text-muted text-left" item md={6}>
                                <h5>{data.address.state}</h5>

                              </Grid>

                            </Grid>


                          </CardContent>

                        </Grid> :
                        <Grid item xs={6}>

                          <CardContent>
                            <Grid xs={12} className="my-1 text-center bg-light p-3" >NO DATA FOUND</Grid>
                          </CardContent>

                        </Grid>
                    }
                  </Grid>
                </Card>


                {updateDialog ? <UpdateAddress
                  updateDialog={updateDialog}
                  setUpdateDialog={setUpdateDialog}
                  updateData={updateData}
                  facultyId={data._id}
                  currentData={singleFacultyDataInfo.data.data[0].address} /> : null}
                {updateDataInfo.data && (
                  <Toster info={updateDataInfo} dialogclose={dialogclose} />
                )}
              </div>
              :
              <SkeletonListing />

            :
            <Grid container justifyContent="center">
              <DataNotFound />
            </Grid>

          }

        </Box>
      </Card>
    </>

  );
}

function UpdateAddress({ updateDialog, setUpdateDialog, updateData, facultyId, currentData }) {
  const formik = useFormik({

    initialValues: {
      ...currentData
    },
    validationSchema: yup.object({
      address_line1: yup.string().required("Required!"),
      address_line2: yup.string().required("Required!"),
      city: yup.string().required("Required!"),
      pincode: yup.string().required("Required!"),
      state: yup.string().required("Required!")
    }),
    onSubmit: (values, e) => {
      var form_data = new FormData()
      form_data.append('key', 'address')
      form_data.append('update_data', JSON.stringify(values))
      updateData({ data: form_data, id: facultyId })
    }
  })

  return (
    <Dialog
      fullScreen={false}
      open={updateDialog}
      //scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
      TransitionComponent={Zoom}
    >
      <DialogTitle id="scroll-dialog-title">Update Address</DialogTitle>
      <DialogContent
      //dividers={scroll === "paper"}
      >
        <form id="updateForm" onSubmit={formik.handleSubmit}>
          <Grid style={{ paddingLeft: 10, paddingRight: 10, marginTop: 20, marginLeft: 10 }} >
            <Grid container spacing={1} >
              <Grid item xs={6}>
                <TextField
                  name="address_line1"
                  label="Address Line 1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address_line1}
                  fullWidth={true}
                  variant="outlined"
                  size="medium" />
                {formik.touched.address_line1 && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.address_line1}</span>}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="address_line2"
                  label="Address Line 2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address_line2}
                  fullWidth={true}
                  variant="outlined"
                  size="medium" />
                {formik.touched.address_line2 && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.address_line2}</span>}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="city"
                  label="City"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                  fullWidth={true}
                  variant="outlined"
                  size="medium" />
                {formik.touched.city && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.city}</span>}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="pincode"
                  label="Pincode"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pincode}
                  fullWidth={true}
                  variant="outlined"
                  size="medium" />
                {formik.touched.pincode && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.pincode}</span>}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="state"
                  label="State"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.state}
                  fullWidth={true}
                  variant="outlined"
                  size="medium" />
                {formik.touched.state && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.state}</span>}
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid container spacing={3} >
              <Grid item xs={12}>
                <div className="text-right">
                  <Button
                    onClick={() => setUpdateDialog(false)}
                    variant="contained"
                    color="secondary">
                    Close</Button>
                  <Button
                    type="submit"
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    color="primary">
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid >
        </form >
      </DialogContent>
    </Dialog>
  )

}
