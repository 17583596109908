import React, { useState, useEffect } from 'react';
import EditDelete from './EditDelete';
import CreateTableAddButton from './CreateTableAddButton'
import Toster from './Toster';
import { Radio, Button } from '@material-ui/core';
import Loader from './Loader';
import {
    useGetAllEmailQueueMutation
} from '../../../services/EmailServices';
import SkeletonListing from './SkeletonListing';
import AddDataHeader from './AddDataHeader';
import DataNotFound from './DataNotFound';
import AddCallbackRequest from './AddCallbackRequest';
import EmailCallRequest from './EmailCallRequest';
import ViewCallbackRequest from './ViewCallbackRequest';
import moment from 'moment';
import ViewEmail from './ViewEmail';
import AuthWrapper from '../../../utils/AuthWrapper';
import { getFilterdColumns } from '../../../utils/getFilterFunction';

export default function Viewlog() {
    const columns = [
        { id: 'sNumber', label: 'Sr No', minWidth: 50, align: 'center' },
        { id: 'emailType', label: 'Type', minWidth: 50, align: 'center' },
        {
            id: 'emailSubject',
            label: 'Subject',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'from',
            label: 'From',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'sendTo',
            label: 'Send To',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'status',
            label: 'Status',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'createdAt',
            label: 'Created At',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'actions',
            label: 'Actions',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
            authName: ['CUSTOM_EMAIL_EMAIL_QUEUE_VIEW',]

        }
    ];
    function createData(sNumber, emailType, emailSubject, from, sendTo, status, createdAt, actions) {
        return { sNumber, emailType, emailSubject, from, sendTo, status, createdAt, actions };
    }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const paginationData = {
        "limit": rowsPerPage,
        "query": "",
        "params": [
            "emailType",
            "resStatus",
            "status"
        ],
        "page": page + 1,
        "filterBy": []
    }
    const [render, setRender] = useState(false)
    let rows = []
    let [getid, setGetid] = useState('')
    const [data, setData] = useState('')
    const [viewDialog, setViewDialog] = useState(false)
    const [getAllData, getAllDataInfo] = useGetAllEmailQueueMutation()
    const AllData = getAllDataInfo.data
    const totalItem = AllData ? AllData.totalItem : 0

    useEffect(() => {
        getAllData(paginationData)
    }, [rowsPerPage, page])

    let totalData = "ok"
    if (AllData) {
        totalData = AllData.totalItem
        rows = AllData.data.map((data, index) => createData(
            index + 1,
            data.emailType,
            data.emailSubject,
            data.from,
            data.sendTo,
            <span className='text-info font-weight-bold' >{data.status}</span>,
            moment(data.createdAt).format("DD-MM-YYYY hh:mm:ss A"),
            <div>
                <AuthWrapper type="ACTION" name="CUSTOM_EMAIL_EMAIL_QUEUE_VIEW">
                    <EditDelete setViewDialog={setViewDialog}
                        type="view" setData={setData}
                        setGetid={setGetid} data={data} />
                </AuthWrapper>
            </div>
        )
        )
    }

    const dialogclose = () => {
        setViewDialog(false)
        setRender(!render)
    }

    return (
        <div>
            {!viewDialog && <AddDataHeader
                setPage={setPage}
                page={page}
                totalItem={totalItem}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                searchBarApi={getAllData}
                paginationData={paginationData}
                addBtn={true}
                isSearch={
                    !AuthWrapper({
                        type: "ACTION",
                        name: "CUSTOM_EMAIL_EMAIL_QUEUE_LIST",
                        inBoolean: true,
                    })
                }
                isPagination={
                    !AuthWrapper({
                        type: "ACTION",
                        name: "CUSTOM_EMAIL_EMAIL_QUEUE_LIST",
                        inBoolean: true,
                    })
                }

            />}
            <>
                <AuthWrapper type="ACTION" name="CUSTOM_EMAIL_EMAIL_QUEUE_LIST">
                    {!viewDialog ?
                        rows.length ?
                            <CreateTableAddButton
                                rows={rows}
                                columns={getFilterdColumns(columns)}
                                isButton={true}
                            />
                            : totalData == 0 ? <DataNotFound /> : <SkeletonListing />
                        :
                        <ViewEmail viewDialog={setViewDialog} data={{ data }} />
                    }
                </AuthWrapper>
            </>
        </div>
    )
}