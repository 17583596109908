import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import ContactUs from './ContactUs';
import { userAccess } from '../../../helpers/userAccess'
import Quotes from './Quotes'
import CallBackRequestList from './CallBackRequestList'
import FailedOrderRequestList from './order/FailedOrderRequestList'
import ProductCartListing from './ProductCart/list/ProductCartListing';
import AuthWrapper from '../../../utils/AuthWrapper';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}

        >
            {value === index && (
                <Box p={10} style={{ padding: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        minWidth: 100,
    },
    tab: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const tabsNames = [
    {
        tabName: "Contact Us",
        tabId: "PRE_SELL_SUPPORT_CONTACT_US",
    },
    {
        tabName: "Quote Your Price",
        tabId: "PRE_SELL_SUPPORT_QUOTE_YOUR_PRICE",
    },
    {
        tabName: "Call Back Request",
        tabId: "PRE_SELL_SUPPORT_CALL_BACK_REQUEST",
    },
    {
        tabName: "Product Cart",
        tabId: "PRE_SELL_SUPPORT_PRODUCT_CART",
    },
]

export default function Inquiries() {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    let tabsToShow = tabsNames.filter((e, i) => { return AuthWrapper({ type: "TAB", name: e?.tabId, inBoolean: true }) })

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getTabPanel = (ele) => {
        let moduleToShow;
        switch (ele) {
            case "Contact Us":
                moduleToShow = <ContactUs />;
                break;
            case "Quote Your Price":
                moduleToShow = <Quotes />;
                break;
            case "Call Back Request":
                moduleToShow = <CallBackRequestList />;
                break;
            case "Product Cart":
                moduleToShow = <ProductCartListing />;
                break;
            default:
                break;
        }
        return moduleToShow;
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    {
                        tabsToShow.map((ele, ind) => {
                            return <Tab style={{ textTransform: 'capitalize' }} label={ele?.tabName} {...a11yProps(ind)} />
                        })
                    }
                </Tabs>
            </AppBar>

            {
                tabsToShow.map((ele, ind) => {
                    return <TabPanel value={value} index={ind} >{getTabPanel(ele?.tabName)}</TabPanel>
                })
            }
        </div >
    )
}