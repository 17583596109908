import React, { } from 'react';
import { Grid } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import { Button, TextareaAutosize } from '@material-ui/core';

import { useFormik } from 'formik';
import * as yup from 'yup'


export default function UpdateInQuiry({ editData, editDialog, UpdateData ,isCencel}) {
      
    const dialogclose = () => { editDialog(false) }
    const formik = useFormik({
        initialValues: {
            discription: "",
            next_date_time: "",
        },
        validationSchema: yup.object({
            discription: yup.string().required("Required !"),
            next_date_time: yup.string().required("Required !"),
        }),
        onSubmit: (values) => {
            UpdateData(values, editData._id)
        }
    })

    return (

        <form onSubmit={formik.handleSubmit} className="mb-5 p-2">

            <TextField
                rows={5}
                multiline
                name="discription"
                onChange={formik.handleChange}
                InputProps={{ inputProps: { min: 0 } }}
                onBlur={formik.handleBlur}
                value={formik.values.discription}
                fullWidth={true}
                label="description "
                variant="outlined"
                size="large" />
            {formik.touched.discription && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.discription}</span>}

            <br /><br />

            <TextField
                name="next_date_time"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.next_date_time}
                id="datetime-local"
                label="Next Date Time"
                type="datetime-local"
                fullWidth={true}
                sx={{ width: 250 }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            {formik.touched.next_date_time && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.next_date_time}</span>}

            <br /><br />

            <Button className="m-2" type="submit" variant="contained" color="primary">
                Save
            </Button>

            {!isCencel && <Button className="m-2" onClick={() => dialogclose(false)} variant="contained" color="secondary">
                Close
            </Button>}
        </form>

    )
}
