import React, { useState, useEffect } from "react";
import CreateTableAddButton from "../dashboard/CreateTableAddButton";
import SkeletonListing from "../dashboard/SkeletonListing";
import DataNotFound from "../dashboard/DataNotFound";
import AddDataHeader from "../dashboard/AddDataHeader";
import { Link } from "react-router-dom";
import { useGetAllUsersQuery } from "../../../services/services";
import moment from "moment";
import AuthWrapper from "../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../utils/getFilterFunction";

export default function AllUsers() {
  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    { id: "name", align: "center", label: "Name", minWidth: 50 },
    { id: "mobile", align: "center", label: "Mobile", minWidth: 50 },
    { id: "email", align: "center", label: "Email", minWidth: 50 },
    { id: "address", align: "center", label: "Address", minWidth: 50 },
    {
      id: "walletAmount",
      align: "center",
      label: "Wallet Amount",
      minWidth: 50,
    },
    {
      id: "registeredDate",
      align: "center",
      label: "Registered Date",
      minWidth: 50,
    },
  ];
  function createData(
    SNo,
    name,
    mobile,
    email,
    address,
    walletAmount,
    registeredDate
  ) {
    return { SNo, name, mobile, email, address, walletAmount, registeredDate };
  }

  const [filteredPagination, setFilteredPagination] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [render, setRender] = useState(false);

  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: ["first_name", "last_name", "mobile", "email"],
    page: page + 1,
    filterBy: [{ fieldName: "type", value: "STUDENT" }],
  };

  const [pagination, setPagination] = useState(paginationData);
  const {
    data: AllUsers,
    isFetching: isAllUserFetching,
    isLoading: isAllUserLoading,
  } = useGetAllUsersQuery(pagination);
  useEffect(() => {
    setPagination(paginationData);
  }, [render, page, rowsPerPage, filteredPagination]);

  let rows = [];
  const totalItem = AllUsers ? AllUsers.totalItem : 0;

  let totalData = "ok";
  if (AllUsers) {
    totalData = AllUsers.totalItem;
    rows = AllUsers.data.map((data, index) =>
      createData(
        index + 1,
        <Link to={{ pathname: "/UserPage", state: { data: data } }}>
          {data.first_name + " " + data.last_name}
        </Link>,
        data.mobile,
        data.email,
        data.length
          ? data.address[0].address_line1 +
              ", " +
              data.address[0].address_line2 +
              ", " +
              data.address[0].city +
              ", " +
              data.address[0].state +
              ", " +
              data.address[0].country
          : null,
        data.wallet_amount,
        moment(data.createdAt).format("DD-MM-YYYY hh:mm A")
      )
    );
  }

  return (
    <div>
      <AddDataHeader
        setPage={setPage}
        page={page}
        excelBtn={false}
        totalItem={totalItem}
        isSearch={
          !AuthWrapper({
            type: "ACTION",
            name: "USER_FRANCHISE_WEB_USERS_LIST",
            inBoolean: true,
          })
        }
        isPagination={
          !AuthWrapper({
            type: "ACTION",
            name: "USER_FRANCHISE_WEB_USERS_LIST",
            inBoolean: true,
          })
        }
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchBarApi={setPagination}
        paginationData={{ ...pagination }}
        addBtn={true}
      />

      <>
        {" "}
        <AuthWrapper type="ACTION" name="USER_FRANCHISE_WEB_USERS_LIST">
          {rows.length ? (
            <CreateTableAddButton
              setPage={setPage}
              rows={rows}
              columns={getFilterdColumns(columns)}
              />
          ) : totalData == 0 ? (
            <DataNotFound />
          ) : (
            <SkeletonListing />
          )}{" "}
        </AuthWrapper>
      </>
    </div>
  );
}

// import React, { useEffect, useState } from 'react'
// import PhoneIcon from '@material-ui/icons/Phone';
// import {
//     TablePagination, CircularProgress, Box, makeStyles,
//     Card, CardContent, Button, Typography,
//     Grid, Slide, Dialog, DialogActions, DialogContent, DialogTitle, TextField,
// } from '@material-ui/core';
// import EmailIcon from '@material-ui/icons/Email';
// import HomeIcon from '@material-ui/icons/Home';
// import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
// import UpdateIcon from '@material-ui/icons/Update';
// import PersonIcon from '@material-ui/icons/Person';
// import SearchBar from './SearchBar';
// import { Link } from 'react-router-dom';
// import { useGetAllUsersQuery } from '../../../services/services';
// import { useFormik } from 'formik';
// import * as yup from 'yup'
// import { useAddUserMutation } from '../../../services/services';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import SkeletonLoader from './SkeletonLoader';
// import moment from 'moment';

// const useStyles = makeStyles({
//     root: {
//         minWidth: 275,
//     },
//     bullet: {
//         display: 'inline-block',
//         margin: '0 2px',
//         transform: 'scale(0.8)',
//     },
//     title: {
//         fontSize: 24,
//         color: "black",
//         fontWeight: 'bolder'
//     },
//     other: {
//         fontSize: 12,
//         color: "black",

//     },
//     pos: {
//         marginBottom: 12,
//     },
// });
// export default function AllUsers() {
//     const [rowsPerPage, setRowsPerPage] = React.useState(10);
//     const [addUser, addUserInfo] = useAddUserMutation()
//     const [flag, setFlag] = useState(true)
//     const [render, setRender] = useState(false)

//     const [page, setPage] = React.useState(0);
//     const paginationData = {
//         "limit": rowsPerPage,
//         "query": "",
//         "params": [
//             "first_name", "last_name", "type", "mobile", "email"
//         ],
//         "page": page + 1,
//         "filterBy": {
//             fieldName: "type",
//             value: "STUDENT"
//         }
//     }
//     const handleChangePage = (event, newPage) => {
//         setPage(newPage);
//     };
//     const handleChangeRowsPerPage = (event) => {
//         setRowsPerPage(+event.target.value);
//         setPage(0);
//     };
//     const getallusers= useGetAllUsersQuery(paginationData)
//     // useEffect(() => {
//     //     getallusers(paginationData)
//     // }, [page,render])
//     const allUsers = getallusers.data

//     const classes = useStyles();
//     const [open, setOpen] = React.useState(false);
//     const [userId, setUserId] = React.useState('');
//     const [scroll, setScroll] = React.useState('paper');
//     const handleClickOpen = (scrollType) => () => {
//         setOpen(true);
//         setScroll(scrollType);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };
//     const phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/
//     const PassWordRegex = RegExp(
//         /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
//     );
//     const formik = useFormik({
//         initialValues: {
//             first_name: "",
//             last_name: "",
//             mobile: "",
//             email: "",
//             password: "",
//             confirm_password: "",
//         },
//         validationSchema: yup.object({
//             first_name: yup.string().required("Required !"),
//             last_name: yup.string().required("Required !"),
//             mobile: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Number is required').min(10),
//             email: yup.string().email('Must be a valid email').max(255).required('Email is required'),
//             password: yup.string().matches(PassWordRegex, "min 8 letter password, with at least a symbol, upper and lower case letters and a number").required('Password is required'),
//             confirm_password: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required('Password is required')
//         }),
//         onSubmit: (values) => {
//             addUser(values)
//             setFlag(false)

//         }
//     })

//     useEffect(() => {
//         let { status } = addUserInfo
//         if (status == 'fulfilled') {
//             let { status, message } = addUserInfo.data
//             if (!status) {
//                 notify(message)
//                 setFlag(true)

//             } else {
//                 notify(message)
//                 setFlag(true)
//                 handleClose()
//             }
//         }
//     }, [addUserInfo])

//     const notify = (msg) => toast(msg);
//     return (
//         <Grid style={{ paddingTop: 10 }}>
//             <Grid container style={{ padding: 10 }}>
//                 {/* <Grid item xs={2}>
//                     <Button onClick={handleClickOpen('paper')} variant="contained" color="primary">Add Franchise</Button>
//                 </Grid>
//                 <Dialog
//                     open={open}
//                     onClose={handleClose}
//                     scroll={scroll}
//                     aria-labelledby="scroll-dialog-title"
//                     aria-describedby="scroll-dialog-description"
//                     maxWidth="xl"
//                     TransitionComponent={Slide}
//                 >
//                     <DialogTitle id="scroll-dialog-title">Add Franchise</DialogTitle>
//                     {flag ?
//                         <form onSubmit={formik.handleSubmit}>
//                             <DialogContent dividers={scroll === 'paper'}>
//                                 <TextField
//                                     name="first_name"
//                                     onChange={formik.handleChange}
//                                     onBlur={formik.handleBlur}
//                                     value={formik.values.first_name}
//                                     fullWidth={true}
//                                     label="First Name"
//                                     variant="outlined"
//                                     size="small" />
//                                 {formik.touched.first_name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.first_name}</span>}

//                                 <br /><br />
//                                 <TextField
//                                     name="last_name"
//                                     onChange={formik.handleChange}
//                                     onBlur={formik.handleBlur}
//                                     value={formik.values.last_name}
//                                     fullWidth={true}
//                                     label="Last Name"
//                                     variant="outlined"
//                                     size="small" />
//                                 {formik.touched.last_name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.last_name}</span>}

//                                 <br /><br />
//                                 <TextField
//                                     name="mobile"
//                                     onChange={formik.handleChange}
//                                     onBlur={formik.handleBlur}
//                                     value={formik.values.mobile}
//                                     fullWidth={true}
//                                     type='number'
//                                     label="Mobile Number"
//                                     variant="outlined"
//                                     size="small" />
//                                 {formik.touched.mobile && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.mobile}</span>}

//                                 <br /><br />
//                                 <TextField
//                                     name="email"
//                                     onChange={formik.handleChange}
//                                     onBlur={formik.handleBlur}
//                                     value={formik.values.email}
//                                     fullWidth={true}
//                                     label="Email"
//                                     variant="outlined"
//                                     size="small" />
//                                 {formik.touched.email && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.email}</span>}

//                                 <br /><br />
//                                 <TextField
//                                     name="password"
//                                     onChange={formik.handleChange}
//                                     onBlur={formik.handleBlur}
//                                     value={formik.values.password}
//                                     fullWidth={true}
//                                     label="Password"
//                                     variant="outlined"
//                                     size="small" />
//                                 {formik.touched.password && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.password}</span>}

//                                 <br /><br />
//                                 <TextField
//                                     name="confirm_password"
//                                     onChange={formik.handleChange}
//                                     onBlur={formik.handleBlur}
//                                     value={formik.values.confirm_password}
//                                     fullWidth={true}
//                                     label="Confirm Password"
//                                     variant="outlined"
//                                     size="small" />
//                                 {formik.touched.confirm_password && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.confirm_password}</span>}

//                                 <br /><br />
//                             </DialogContent>
//                             <DialogActions style={{ marginRight: 20 }}>
//                                 <Button onClick={handleClose} variant="contained" color="secondary">
//                                     Close
//                                 </Button>
//                                 <Button type='submit' variant="contained" color="primary">
//                                     Save
//                                 </Button>
//                             </DialogActions>
//                         </form>
//                         :
//                         <div style={{ height: "80vh", width: 400 }} className=" d-flex justify-content-center d-flex align-items-center">
//                             <Box sx={{ display: 'flex' }}>
//                                 <CircularProgress />
//                             </Box>
//                         </div>
//                     }
//                 </Dialog> */}
//                 <Grid item xs={5}>
//                     {getallusers.isSuccess ?
//                         <SearchBar searchApi={getallusers} params={paginationData} />
//                         : "Loading."}
//                 </Grid>
//                 <Grid item xs={5}>

//                     <TablePagination
//                         rowsPerPageOptions={[10, 25, 100]}
//                         component="div"
//                         count={allUsers && allUsers.totalItem}
//                         rowsPerPage={rowsPerPage}
//                         page={page}
//                         onPageChange={handleChangePage}
//                         onRowsPerPageChange={handleChangeRowsPerPage}
//                     />
//                 </Grid>
//             </Grid>

//             <Grid container spacing={3}>

//                 {allUsers ? allUsers.data.map((data) => {
//                     return (
//                         <Grid item xs={4}>
//                             <Card className={classes.root}>
//                                 <CardContent>
//                                     <Typography style={{ padding: 10 }}>
//                                         <Grid container  >
//                                             <Grid item xs={2}>
//                                                 <PersonIcon />
//                                             </Grid>
//                                             <Grid itm xs={10}
//                                                 style={{ paddingTop: 2, fontWeight: "bolder" }}>
//                                                 <Link to={{ pathname: '/UserPage', state: { data: data } }}>
//                                                     <Button className="p-0 m-0" onclick={() => setUserId(data)} color="primary"
//                                                         style={{ fontSize: 17, fontWeight: "bold" }}>
//                                                         {data.first_name} {data.last_name}
//                                                     </Button>
//                                                 </Link>
//                                             </Grid>
//                                         </Grid>
//                                     </Typography>
//                                     <Typography style={{ paddingLeft: 10 }}>
//                                         <Grid container spacing={3}>
//                                             <Grid item xs={2}>
//                                                 <PhoneIcon />
//                                             </Grid>
//                                             <Grid itm xs={6} style={{ paddingTop: 10 }}>
//                                                 {data.mobile}
//                                             </Grid>
//                                         </Grid>
//                                     </Typography>
//                                     <Typography style={{ padding: 10 }}>
//                                         <Grid container spacing={3}>
//                                             <Grid item xs={2}>
//                                                 <EmailIcon />
//                                             </Grid>
//                                             <Grid itm xs={6} style={{ paddingTop: 10 }}>
//                                                 {data.email}
//                                             </Grid>
//                                         </Grid>
//                                     </Typography>
//                                     {data.address.length ? <Typography style={{ paddingLeft: 10 }}>
//                                         <Grid container spacing={3}>
//                                             <Grid item xs={2}>
//                                                 <HomeIcon />
//                                             </Grid>
//                                             <Grid itm xs={10} style={{ paddingTop: 10, fontSize: 15 }}>
//                                                 {data.address[0].address_line1
//                                                 +", "+data.address[0].address_line2
//                                                 +", "+data.address[0].city
//                                                 +", "+data.address[0].state
//                                                 +", "+data.address[0].country}
//                                                   </Grid>
//                                         </Grid>
//                                         </Typography>:null
//                                     }
//                                     <Typography style={{ padding: 10 }}>
//                                         <Grid container spacing={3}>
//                                             <Grid item xs={2}>
//                                                 <CurrencyRupeeIcon />
//                                             </Grid>
//                                             <Grid itm xs={10} style={{ paddingTop: 15, fontSize: 15 }}>
//                                                 Wallet Amount :{data.wallet_amount}
//                                             </Grid>
//                                         </Grid>
//                                     </Typography>
//                                     <Typography style={{ paddingLeft: 10 }}>
//                                         <Grid container spacing={3}>
//                                             <Grid item xs={2}>
//                                                 <UpdateIcon />
//                                             </Grid>
//                                             <Grid itm xs={10} style={{ paddingTop: 10, fontSize: 14 }}>
//                                                 Registered On : { moment(data.createdAt).format('DD-MM-YYYY hh:mm A') }
//                                             </Grid>
//                                         </Grid>
//                                     </Typography>
//                                 </CardContent>
//                             </Card>

//                         </Grid>
//                     )
//                 })
//                     : <SkeletonLoader />}
//             </Grid>

//             <ToastContainer />

//         </Grid>
//     )
// }
