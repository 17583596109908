import React, { useState, useEffect } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import AddDataHeader from "../AddDataHeader";
import DataNotFound from "../DataNotFound";
import { useGetAllPaymentModesQuery, useAddPaymentModeMutation, useUpdatePaymentModeMutation, useDeletePaymentModeMutation } from "../../../../services/offlineModuleServices/paymentModeServices";
import AddEditPaymentModeForm from "./AddEditPaymentModeForm";



export default function PaymentMode() {

    let [getid, setGetid] = useState("");
    const [data, setData] = useState("");
    const [addDialog, setAddDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [render, setRender] = useState(false)

    const { data: AllPaymentModes, isAllPaymentModesFetching, isAllPaymentModesLoading } = useGetAllPaymentModesQuery()
    const [addPaymentMode, addPaymentModeInfo] = useAddPaymentModeMutation()
    const [updatePaymentMode, updatePaymentModeInfo] = useUpdatePaymentModeMutation()
    const [deletePaymentMode, deletePaymentModeInfo] = useDeletePaymentModeMutation()


    const columns = [
        {
            id: "SNo",
            label: "S.No",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "paymentModeName",
            label: "Payment Mode Name",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "accountNo",
            label: "UPI Id / Account No.",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "actions",
            label: "Actions",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
    ];
    function createData(SNo, paymentModeName, accountNo, actions) {
        return {
            SNo,
            paymentModeName,
            accountNo,
            actions,
        };
    }
    let rows = [];
    const totalItem = AllPaymentModes ? AllPaymentModes.totalItem : 0;

    let totalData = "ok";


    useEffect(() => {
        getid && deletePaymentMode(getid) && setGetid("");
    }, [getid]);

    if (
        AllPaymentModes
    ) {
        totalData = AllPaymentModes.totalItem;
        rows = AllPaymentModes.data.map((ele, index) => {
            return createData(
                index + 1,
                ele.payment_mode_name,
                ele.UPI_ID_ACCOUNT_NO,
                <div>
                    <EditDelete
                        setEditDialog={setEditDialog}
                        type="edit"
                        setData={setData}
                        data={ele}
                    />
                    <EditDelete type="delete" setGetid={setGetid} id={ele._id} />
                </div>
            );
        });
    }

    const dialogclose = () => {
        setEditDialog(false);
        setAddDialog(false);
        setRender(!render);
    };

    const updateBtnClickHandler = (dataForUpdate) => {
        updatePaymentMode({ body: dataForUpdate, id: data._id });
    };

    const addBtnClickHandler = (dataForAdd) => {
        addPaymentMode(dataForAdd)
    }

    return (
        <div>
            {/* {!viewDialog && ( */}
            <AddDataHeader
                addFormName={"Add New Payment Mode"}
                isSearch={true}
                totalItem={totalItem}
                isPagination={true}
                setAddDialog={setAddDialog}
                isButton={true}
                Adddialog={addDialog}
                form={
                    <AddEditPaymentModeForm
                        onSubmit={addBtnClickHandler}
                        btnName="Save"
                        setOpen={setAddDialog}
                    />
                }
            />
            {/* )} */}


            <>
                {rows.length ? (
                    <CreateTableAddButton
                        editDialog={editDialog}
                        // setPage={setPage}
                        rows={rows}
                        editform={

                            <AddEditPaymentModeForm
                                formType="edit"
                                initialData={data}
                                onSubmit={updateBtnClickHandler}
                                btnName="Update"
                                setOpen={setEditDialog}

                            />
                        }
                        columns={columns}
                        isButton={true}
                        editFormName={"Edit Payment Mode "}
                    />
                ) : totalData == 0 ? (
                    <DataNotFound />
                ) : (
                    <SkeletonListing />
                )}

            </>


            {addPaymentModeInfo && (
                <Toster info={addPaymentModeInfo} dialogclose={dialogclose} />
            )}

            {updatePaymentModeInfo && (
                <Toster info={updatePaymentModeInfo} dialogclose={dialogclose} />
            )}

            {deletePaymentModeInfo && (
                <Toster info={deletePaymentModeInfo} dialogclose={dialogclose} />
            )}

        </div>
    );
}
