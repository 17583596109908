import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, Button, DialogContent, DialogTitle, DialogContentText, TextField, MenuItem, FormControl, InputLabel, Select, Grid, Divider } from '@material-ui/core';
import { useGetVideoLectureVarientQuery } from '../../../services/services';
import { useFormik } from 'formik';
import * as yup from 'yup'
import { toast } from "react-toastify"

function EditOrder({ setEditeDialog, editeDialog, data, UpdateData }) {
  const getVarientData = useGetVideoLectureVarientQuery(data.product_id)
    

    

  const formik = useFormik({
    initialValues: {
      // new_mode_mrp: "",
      // new_mode_effective: "",
      return_amount: "",
      due_amount: "",
      remark: "",
      product_varient_code: ""
    },
    validationSchema: yup.object({
      // new_mode_mrp: yup.number().required("Required !"),
      // new_mode_effective: yup.number().required("Required !"),
      return_amount: yup.number("Only number allowed!"),
      due_amount: yup.number("Only number allowed!"),
      remark: yup.string().required("Required !"),
      product_varient_code: yup.string().required("Required !")
    }),
    onSubmit: (values) => {
      if (!values.return_amount && !values.due_amount) {
        toast("Return or Due any amount is required.")
        return
      }
      let updatedata = {
        body: values,
        id: data._id
      }
      UpdateData(updatedata)
    }
  })
  const handleClickOpen = () => {
    setEditeDialog(true);
  };

  const handleClose = () => {
    setEditeDialog(false);
  };

let varient=[]
if(getVarientData.isSuccess)
{
   varient=getVarientData?.data?.data?.varient.filter((ele, ind) =>{return data?.video_lecture_details.varient.varient_code !== ele.varient_code})
}
  
  return (
    <div>
      <div>
        <Dialog fullWidth={true} open={editeDialog} onClose={handleClose}>

          <DialogTitle>EDIT ORDER</DialogTitle>
          <form onSubmit={formik.handleSubmit}>

            <DialogContent>
              <DialogContentText>
                ORDER CHANGE
              </DialogContentText>

              <FormControl variant="outlined" fullWidth={true}  >
                <InputLabel id="demo-simple-select-helper-label">Mode</InputLabel>

                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  name="product_varient_code"
                  value={formik.values.product_varient_code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label="product_varient_code"
                >
                  {
                  varient.length ? varient?.map((ele, ind) =>
                    
                    <MenuItem className='' value={ele.varient_code}>
                      {ele.mode_name} ({ele.varient_code}), (Rs. {ele.mode_mrp})
                    </MenuItem>
                  
 
                  )
                     : <MenuItem className='' value="">
                       Mode Not Found
                   </MenuItem>
                    
                  }

                </Select>
              </FormControl>



              {/* <TextField
                
                margin="dense"
                // id="name"
                name="new_mode_mrp"
                label="new mode mrp"
                type="text"
                fullWidth
                variant="standard"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.new_mode_mrp}
              />
              {formik.touched.new_mode_mrp && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.new_mode_mrp}</span>}
              <br /><br /> */}

              {/* <TextField
                
                margin="dense"
                // id="name"
                name="new_mode_effective"
                label="new mode effective"
                type="text"
                fullWidth
                variant="standard"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.new_mode_effective}
              />
              {formik.touched.new_mode_effective && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.new_mode_effective}</span>}
              <br /><br /> */}

              <TextField

                margin="dense"
                // id="name"
                name="return_amount"
                label="return amount"
                type="text"
                fullWidth
                variant="standard"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.return_amount}
              />
              {formik.touched.return_amount && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.return_amount}</span>}
              <br /><br />

              <TextField

                margin="dense"
                // id="name"
                name="due_amount"
                label="due amount"
                type="text"
                fullWidth
                variant="standard"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.due_amount}
              />
              {formik.touched.due_amount && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.due_amount}</span>}
              <br /><br />
              <TextField

                margin="dense"
                // id="name"
                name="remark"
                label="remark"
                type="text"
                fullWidth
                variant="standard"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.remark}
                multiline
                minRows={3}
              />
              {formik.touched.remark && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.remark}</span>}
              <br /><br />

            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                type="submit"
                style={{ marginLeft: 20 }}
                variant="contained"
                color="primary"
              > Save</Button>
            </DialogActions>
          </form>

        </Dialog>


      </div>
    </div>
  )
}

export default EditOrder
