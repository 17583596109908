import React, { useState, useEffect } from "react";
// import { makeStyles } from "@material-ui/core";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import MaterialTables from "../MaterialTables"
import Autocomplete from '@mui/material/Autocomplete';
import { Button } from '@material-ui/core';
import DataNotFound from '../DataNotFound';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import {
    Typography, Divider, Paper, Grid, Box, TextField, Badge, ButtonGroup, FormControl, InputLabel, MenuItem, Select, FormGroup,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import DropdownTreeSelect from "react-dropdown-tree-select";
import { useGetVideoLectureProductQuery } from '../../../../services/services';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    // boxShadow: 24,
    // p: 4,
};
export default function CartDetails({ getCart, setDeleteCart, setItemCount, userData, setReferralCode, setCoupenCode, addToCartDetail }) {
      
    const getAllData = useGetVideoLectureProductQuery()
    var AllData = getAllData.data
    const [referral, setReferral] = React.useState('');
    const [coupen, setCoupen] = React.useState('');
    const [itemQuantity, setItemQuantity] = React.useState(1);
    const [varientCode, setVarientCode] = React.useState('');
    const [quantity, setQuantity] = React.useState({
        "user_id": "",
        "product_id": "",
        "product_varient_code": "",
        "type": ""
    })
    const [product, setProduct] = React.useState({ varient: [], product_id: '', lable: '', name: '', image: '' });
      
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true)
        setVarientCode("")
        setItemQuantity(1)
    }
    const handleClose = () => {
        setOpen(false)
    };
    const applyreferral = () => {
        setReferralCode({ user_id: userData._id, referral_code: referral })
    }
    const applyCoupenCode = () => {
        setCoupenCode({ user_id: userData._id, coupon_code: coupen })
    }
    const [addToCart, setAddToCart] = React.useState({
        "user_id": "",
        "product_id": "",
        "product_type": "SLD_VIDEO_LECTURE",
        "product_varient_code": "",
        "product_quantity": 0
    });
    const getSearchableProductData = () => {
        return (AllData != undefined) ? AllData.data.map(e => {
            return {
                lable: e.video_lecture_name,
                name: e.video_lecture_name,
                product_id: e._id,
                varient: e.varient,
                image: e.image
            }
        }) : [{ lable: "Search...", name: "" }]

    }
    useEffect(() => {
        addToCartDetail(addToCart)
        handleClose()
        setItemQuantity(1)
        setVarientCode('')
        setProduct({ varient: [], product_id: '', lable: '', name: '', image: '' })

    }, [addToCart]);
    const addtoData = () => {
        if (varientCode) {
            setAddToCart({
                user_id: userData._id,
                product_id: product.product_id,
                product_type: "SLD_VIDEO_LECTURE",
                product_varient_code: varientCode,
                product_quantity: itemQuantity,
            })
        }

    }

    return (
        <>

            <Grid className="p-4 d-flex justify-content-end" >
                {/* <TextField size="small" value={referral}  id="outlined-basic" label="Referal Code" variant="outlined" onChange={(e) => { setReferral(e.target.value) }} />
                        <Button size="small"  variant="contained"  onClick={applyreferral} >Apply Referal</Button> */}
                <Button className="ml-2" variant="contained" color='primary' onClick={handleOpen}>Add to Cart</Button>
            </Grid>


            <Grid container spacing={2} className="p-4 border-top" >

                <Grid item xs={12} md={9}>
                    <Grid container spacing={2} >
                        {getCart.data.length != 0 ? getCart.data.products.map((data) =>

                            data.is_valid &&
                            <Grid item xs={6} lg={4} >
                                <Card style={{ overflow: "scroll" }} className="border-primary border-top p-2">
                                    <Grid container spacing={2} >
                                        <Grid item xs={6} >
                                            <CardMedia
                                                component="img"
                                                image={data.image}
                                                alt="Live from space album cover"
                                                sx={{ height: "200px" }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className="" >
                                            <div style={{ height: "167px", overflow: "scroll" }} >
                                                <div style={{ fontSize: "14px" }} className="text-primary" >{data.product_name}</div>
                                                <div style={{ fontSize: "13px", fontWeight: "500", marginBottom: "8px" }} className="text-muted" >{data.format_mode}</div>
                                                {data.product_mrp != data.selling_price ?
                                                    (<>
                                                        <div style={{ fontSize: "14px", fontWeight: "700" }} className="text-muted" > ₹ {data.selling_price}</div>
                                                        <div style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid', fontSize: "14px", fontWeight: "700" }} m={1} className="text-muted" >  {data.product_mrp}</div>
                                                    </>) : <div style={{ fontSize: "14px", fontWeight: "700" }} m={1} className="text-muted" > ₹ {data.selling_price}</div>}
                                            </div>
                                            <ButtonGroup size="small" variant="contained"  >
                                                <Button color="primary"
                                                    onClick={() => {
                                                        setItemCount({ user_id: userData._id, product_id: data.product_id, product_varient_code: data.product_varient_code, type: 'decrease' });
                                                    }}
                                                ><RemoveIcon fontSize="small" />
                                                </Button>
                                                <Button className="text-muted pl-4 pr-4" disabled={true}>{data.product_quantity}</Button>
                                                <Button color="primary"
                                                    onClick={() => {
                                                        setItemCount({ user_id: userData._id, product_id: data.product_id, product_varient_code: data.product_varient_code, type: 'increase' });
                                                    }}
                                                ><AddIcon fontSize="small" />
                                                </Button>
                                                <Button style={{ background: "#c70202cf" }} className="text-light" >
                                                    <DeleteIcon fontSize="small" onClick={() => {
                                                        setDeleteCart({ user_id: userData._id, product_id: data.product_id, product_varient_code: data.product_varient_code })
                                                    }} />
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        ) : <DataNotFound />}
                    </Grid>
                </Grid>

                <Grid item xs={12} md={3} className="">
                    <Paper className="border-danger border-top" style={{ padding: '20px', minWidth: "200px", overflow: "scroll" }}>
                        <div>
                            <Typography color="primary" variant="subtitle1" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                <strong className="text-muted">Quantity</strong>  <strong  > {getCart.data.total_quantity_of_products_in_cart}</strong>

                            </Typography>
                            <Divider style={{ marginTop: '10px' }} />



                            <Typography color="primary" variant="subtitle1" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                <strong className="text-muted" > Amount</strong>
                                {/* <div>1</div> */}
                                <strong > {getCart.data.total_selling_amount}</strong>

                            </Typography>
                            <Typography color="primary" variant="subtitle1" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                <strong className="text-muted" > Discount</strong>
                                {/* <strong >1</strong> */}
                                <strong > {getCart.data.total_discount}</strong>

                            </Typography>
                            <Typography color="primary" variant="body2" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <strong className="text-muted" >Delivery Charge</strong>
                                {/* <strong >Quantity</strong> */}
                                <strong > {getCart.data.total_courier_charges}</strong>
                            </Typography>
                            {/* <div style={{ display: 'flex', justifyContent: 'end', marginTop:"10px" }}>
                                    <TextField size="small" value={coupen} color="primary" style={{ width: '100%' }} id="outlined-basic" label="Coupon Code" variant="outlined" onChange={(e) => { setCoupen(e.target.value) }} />
                                    <Button variant="contained" color="primary" onClick={applyCoupenCode}>Apply</Button>
                                </div> */}
                            <Divider style={{ marginTop: '10px' }} />
                            <Typography color="primary" variant="subtitle1" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                <strong className="text-muted" >Order Total</strong>
                                <strong > {getCart.data.total_mrp_amount}</strong>
                            </Typography>

                        </div>
                    </Paper>
                </Grid>

            </Grid>


            {/* modal */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"md"}
                maxHeight={"md"}
            >
                <DialogTitle>
                    <Typography className="bg-secondary p-2 text-light text-center" id="modal-modal-title" variant="h6" component="h2">
                        Product Add To Cart
                        <span onClick={() => { handleClose() }} className="float-right" style={{ cursor: "pointer" }} ><CloseIcon fontSize="small" /></span>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid sx={style} className="bg-light mt-2" >
                        <Grid container className="" >
                            <Grid item sx={12} style={{ width: "100%", display: "block!important" }} >
                                <Autocomplete
                                    onChange={(event, newValue) => {
                                        setProduct(newValue);
                                    }}
                                    fullWidth={true}
                                    id="combo-box-demo"
                                    options={getSearchableProductData()}
                                    getOptionLabel={(option) => option.name || ""}
                                    renderInput={(params) => <TextField fullWidth={true}  {...params} label="Select Product" variant="outlined"
                                    />}
                                />
                            </Grid>
                            <Grid item sx={12} className="m-5">
                                {product && product.product_id &&
                                    <Card className='m-3'>
                                        <CardContent>
                                            <Grid>
                                                <strong className="text-primary"> {product != null ? product.lable : ""}</strong>
                                            </Grid>
                                            <Grid className="mb-3 mt-2">
                                                <strong className="text-muted border-bottom border-secondary " >Vrient Code / Mode Name / Price</strong>
                                            </Grid>

                                            <FormControl className="d-flex">
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="Varient"
                                                    name="radio-buttons-group"
                                                    onClick={(e) =>
                                                        setVarientCode(e.target.value)}
                                                >
                                                    {product.varient.length != 0 ? product.varient.map((dataVarient) =>
                                                        <FormControlLabel className="" value={dataVarient.varient_code} control={<Radio />}
                                                            label={<>
                                                                <span className="text-muted"> {dataVarient.varient_code} </span>
                                                                (<span className="text-muted">{dataVarient.mode_name}</span>) / &#8377;&nbsp;
                                                                <span className="text-primary">{dataVarient.mode_mrp} </span>
                                                            </>}
                                                        />

                                                    ) : ""}</RadioGroup>

                                            </FormControl>

                                            <div style={{ display: "block" }}>
                                                <Grid className="mb-3">
                                                    <strong className="text-muted border-bottom border-secondary" >Add Quantity of Product</strong>
                                                </Grid>
                                                <Grid>
                                                    <ButtonGroup size="small" variant="contained" >
                                                        <Button color="primary"
                                                            onClick={() => {
                                                                setItemQuantity(itemQuantity > 1 ? Math.max(itemQuantity - 1, 0) : itemQuantity);
                                                            }}
                                                        >
                                                            <RemoveIcon fontSize="small" />
                                                        </Button>
                                                        <Button className="text-muted pl-4 pr-4" disabled={true}>
                                                            {
                                                                itemQuantity
                                                            }
                                                        </Button>
                                                        <Button color="primary"
                                                            onClick={() => {
                                                                setItemQuantity(itemQuantity + 1);
                                                            }}
                                                        >
                                                            <AddIcon fontSize="small" />
                                                        </Button>
                                                    </ButtonGroup>
                                                </Grid>
                                            </div>

                                        </CardContent>

                                        <CardActions className="d-flex justify-content-end" >
                                            <Button disabled={varientCode ? false : true} variant="contained" size="small" color='primary' onClick={addtoData}>Add to cart</Button>
                                            <Button variant="contained" size="small" color='secondary' onClick={handleClose}>Cancel</Button>
                                        </CardActions>

                                    </Card>}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>

    );

}
