import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const mailblurbApi = createApi({
  tagTypes: ["mailblurb"],
  reducerPath: "mailblurbapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/mail-blurb/`,
  }),
  endpoints: (builder) => ({
    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET "mailblurb" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    GetAllmailblurb: builder.query({
      providesTags: ["mailblurb"],
      query: (body) => {
        return {
          url: "all",
          headers: {
            "x-access-token": token,
          },
          method: "GET",
          body,
        };
      },
    }),

    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ADD "mailblurb" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    DeleteMailBlurb: builder.mutation({
      invalidatesTags: ["mailblurb"],
      query: (id) => {
        return {
          url:id,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
          
        };
      },
    }),

    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< UPDATE "mailblurb" IS_SHOW STATUS  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    AddMailBlurb: builder.mutation({
      invalidatesTags: ["mailblurb"],
      query: (body) => {
        return {
          url: "add",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< UPDATE SLIDER IMAGE STATUS IN "mailblurb" SECTION >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    UpdateMailblurbData: builder.mutation({
      invalidatesTags: ["mailblurb"],
      query: ({data,id}) => {
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body:data
        };
      },
    }),


    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< UPDATE DATA IN "mailblurb" SECTION >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    getEmailDetails: builder.mutation({
      invalidatesTags: ["mailblurb"],
      query: (id) => {
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "GET",
          
        };
      },
    }),
    sendEmailDetails: builder.mutation({
      invalidatesTags: ["mailblurb"],
      query: (body) => {
        return {
          url: 'send-email',
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
          
        };
      },
    }),



  }),
});

export const {
  useDeleteMailBlurbMutation,
  useGetAllmailblurbQuery,
  useAddMailBlurbMutation,
  useUpdateMailblurbDataMutation,
  useGetEmailDetailsMutation,
  useSendEmailDetailsMutation
} = mailblurbApi;


export const emailQueueApi = createApi({
  tagTypes: ["emailQueue"],
  reducerPath: "emailQueueapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/email-queue/`,
  }),
  endpoints: (builder) => ({
    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET "emailQueue" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    GetAllEmailQueue: builder.mutation({
      providesTags: ["emailQueue"],
      query: (body) => {
        return {
          url: "all-email-data",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

  }),
});

export const {
  useGetAllEmailQueueMutation
} = emailQueueApi;


// contact_us------- new add & update single data apis are different
// free_mentoring------- add & update apis workes same
// customer_feedback------- new add & update single data apis are different
// contact_info--------add & update apis workes same
// social_media_link------- add & update apis workes same
// digital_payment_methods------- add & update apis workes same
// makes_us_different_img------- add & update apis workes same
// trail------new add & update single data apis are different
// mobile_no_note------add & update apis workes same
// product_page_banner_image--------add & update apis workes same
// single_product_page_banner_image--------add & update apis workes same
// footer_icons------- update all & update single data
// latest_update_slider------new add & update single data apis are different
// main_slider------new add & update single data apis are different
