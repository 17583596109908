import Delete from "@material-ui/icons/Delete";
import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem("__authToken__");
export const facultyPaymentApi=createApi({
  
  // tagTypes: ["facultyPayment"],
  reducerPath: "facultyPayment",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/faculty-payment/`,
  }),
  endpoints:(builder)=>({

      addFacultyPayment:builder.mutation({
        // invalidatesTags: ["facultyPayment"],
        query:(body)=>
        {
          return{
            url:"add-new-payment",
            headers: {
              "x-access-token": token,
            },
            method: "POST",
            body,

          }
        }

      }),

      deleteFacultyPayment:builder.mutation({
        // invalidatesTags: ["facultyPayment"],
        query:(paymentId)=>
        {
          return{
            url: `${paymentId}`,
            headers: {
              "x-access-token": token,
            },
            method: "DELETE"
          }
        }

      }),

  }),

});

export const { 
useAddFacultyPaymentMutation,
useDeleteFacultyPaymentMutation
} =facultyPaymentApi;