import React, { useState } from 'react';
import { withStyles, makeStyles, } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Switch } from '@material-ui/core';
import ConfirmDialog from '../../common/ConfirmDialog'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}



const useStyles = makeStyles({
  table: {
    minWidth: 10,
  },
});

export default function Varient({ varient, updateStatus, lectureId }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const [varientData, setVarientData] = useState({})
  return (
    <TableContainer component={Paper} style={{ maxHeight: 130 }}>
      <Table className={classes.table} aria-label="customized table">
        <TableBody>
          {varient.map((data) => (
            <StyledTableRow>
              <StyledTableCell className='videoModeHeading' component="th" scope="row">
                {data.mode_name}
              </StyledTableCell>
              <StyledTableCell className="m-1 videoModeHeading" >{data.mode_mrp}</StyledTableCell>
              <StyledTableCell className="m-1 videoModeHeading" >{data.sld_selling_price}</StyledTableCell>
              <StyledTableCell className="m-1 videoModeHeading" >
                <Switch
                  size="small"
                  onClick={() => {
                    setOpen(true)
                    setVarientData(data)
                  }}
                  checked={data.is_active}
                />
              </StyledTableCell>
              {open ?
                <ConfirmDialog
                  open={open}
                  functionData={{ varient_id: varientData._id, varient_code: varientData.varient_code, id: lectureId }}
                  setOpen={setOpen}
                  onClickYes={updateStatus}
                />
                : null}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
