import React, { useState, useEffect } from "react";
import EditDelete from "./EditDelete";
import CreateTableAddButton from "./CreateTableAddButton";
import ViewCategory from "./ViewCategory";
import Toster from "./Toster";
import AddSubject from "./AddSubject";
import Loader from "./Loader";
import {
  useGetAllSubjectsFilterMutation,
  useDeleteSubjectMutation,
  useAddSubjectMutation,
  useUpdateSubjectMutation,
} from "../../../services/services";
import SkeletonListing from "./SkeletonListing";
import UpdateSubject from "./UpdateSubject";
import AddDataHeader from "./AddDataHeader";
import DataNotFound from "./DataNotFound";
import moment from "moment";
import AuthWrapper from "../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../utils/getFilterFunction";

export default function Categories() {
  const columns = [
    { id: "sno", label: "S.No", minWidth: 50 },
    { id: "subjectname", label: "Subject Name", minWidth: 20 },
    { id: "subjectcode", label: "Subject Code", minWidth: 20 },
    { id: "shortname", label: "Short Name", minWidth: 20 },
    { id: "metaDescription", label: "Meta Description", minWidth: 20 },
    { id: "metaKeywords", label: "Meta Keywords", minWidth: 20 },

    {
      id: "isactive",
      label: "Active",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },

    {
      id: "createat",
      label: "Create At",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: [
        "CONFIGURATION_SUBJECT_EDIT",
        "CONFIGURATION_SUBJECT_DELETE",
      ],
    },
  ];
  function createData(
    sno,
    subjectname,
    subjectcode,
    shortname,
    metaDescription,
    metaKeywords,
    isactive,
    createat,
    actions
  ) {
    return {
      sno,
      subjectname,
      subjectcode,
      shortname,
      isactive,
      createat,
      actions,
      metaDescription,
      metaKeywords,
    };
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: ["subject_name", "subject_code"],
    page: page + 1,
  };

  let rows = [];

  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [AddDialog, setDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [getAllData, getAllDataInfo] = useGetAllSubjectsFilterMutation();
  const [deleteData, deleteDataInfo] = useDeleteSubjectMutation();
  const [addData, addDataInfo] = useAddSubjectMutation();
  const [updateSubjectData, updateDataInfo] = useUpdateSubjectMutation();

  const AllData = getAllDataInfo.data;
  const totalItem = AllData ? AllData.totalItem : 0;
  const [render, setRender] = useState(false);

  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page]);

  useEffect(() => {
    getid && deleteData(getid) && setGetid("");
  }, [getid]);

  const AddData = (data) => {
    addData(data);
  };
  const UpdateData = (data, id) => {
    updateSubjectData({ body: data, id: id });
  };
  let totalData = "ok";

  if (AllData) {
    totalData = AllData.totalItem;
    rows = AllData.data.map((data, index) =>
      createData(
        index + 1,
        data?.subject_name,
        data?.subject_code,
        data?.short_name || "",
        data?.metaDescription || "-",
        data?.metaKeywords || "-",
        data.is_active ? "Yes" : "No",
        moment(data.createdAt).format("DD-MM-YYYY hh:mm:ss A"),
        <div className="d-flex justify-content-center">
          {/* <EditDelete setViewDialog={setViewDialog}
                    type="view" setData={setData}
                    setGetid={setGetid} data={data} /> */}
          <AuthWrapper type="ACTION" name="CONFIGURATION_SUBJECT_EDIT">
            <EditDelete
              setEditDialog={setEditDialog}
              type="edit"
              setData={setData}
              setGetid={setGetid}
              data={data}
            />
          </AuthWrapper>
          <AuthWrapper type="ACTION" name="CONFIGURATION_SUBJECT_DELETE">
            <EditDelete type="delete" setGetid={setGetid} id={data._id} />
          </AuthWrapper>
        </div>
      )
    );
  }
  const dialogclose = () => {
    setEditDialog(false);
    setViewDialog(false);
    setDialog(false);
    setRender(!render);
  };

  return (
    <div>
      <AddDataHeader
        addFormName={"Add Subject"}
        setPage={setPage}
        page={page}
        excelBtn={false}
        totalItem={totalItem}
        rowsPerPage={rowsPerPage}
        isSearch={
          !AuthWrapper({
            type: "ACTION",
            name: "CONFIGURATION_SUBJECT_LIST",
            inBoolean: true,
          })
        }
        isPagination={
          !AuthWrapper({
            type: "ACTION",
            name: "CONFIGURATION_SUBJECT_LIST",
            inBoolean: true,
          })
        }
        addBtn={
          !AuthWrapper({
            type: "ACTION",
            name: "CONFIGURATION_SUBJECT_ADD",
            inBoolean: true,
          })
        }
        setRowsPerPage={setRowsPerPage}
        setAddDialog={setDialog}
        searchBarApi={getAllData}
        paginationData={paginationData}
        isButton={true}
        Adddialog={AddDialog}
        fullWidth={true}
        maxWidth="sm"
        form={<AddSubject AddData={AddData} setDialog={setDialog} />}
      />
      <AuthWrapper type="ACTION" name="CONFIGURATION_SUBJECT_LIST">
        {deleteDataInfo.isLoading ||
        addDataInfo.isLoading ||
        updateDataInfo.isLoading ? (
          <Loader />
        ) : (
          <>
            {" "}
            {!viewDialog ? (
              rows.length ? (
                <CreateTableAddButton
                  maxWidth="sm"
                  fullWidth={true}
                  editDialog={editDialog}
                  rows={rows}
                  editform={
                    <UpdateSubject
                      UpdateData={UpdateData}
                      editData={data}
                      editDialog={setEditDialog}
                    />
                  }
                  columns={getFilterdColumns(columns)}
                  isButton={true}
                  editFormName={"Edit Subject"}
                />
              ) : totalData == 0 ? (
                <DataNotFound />
              ) : (
                <SkeletonListing />
              )
            ) : (
              <ViewCategory viewDialog={setViewDialog} data={data} />
            )}
          </>
        )}
      </AuthWrapper>

      {deleteDataInfo.data && (
        <Toster info={deleteDataInfo} dialogclose={dialogclose} />
      )}
      {addDataInfo.data && (
        <Toster info={addDataInfo} dialogclose={dialogclose} />
      )}
      {updateDataInfo && (
        <Toster info={updateDataInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
