import React from "react";
import {
  Button,
  Grid,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Paper, TableHead } from "@mui/material";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

import {
  useGetAllBlogCommentsQuery,
  useUpdateBlogCommentMutation,
} from "../../../../services/blogServices";
import { useEffect } from "react";
import { useState } from "react";
const parser = new DOMParser();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: 18,
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  table: {
    padding: 10,
    margin: 5,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function ViewCoupons({ viewDialog, data }) {
  const classes = useStyles();
  const [commentData, setCommentData] = useState([]);
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  const paginationData = {
    limit: 100,
    query: "",
    params: [
      "blogId",
      "comment",
      "commentedByUserId",
      "commentedByUserType",
      "commentedByUserName",
      "commentedOnDateTime",
      "replys.reply",
      "replys.replyedByUserType",
      "replys.replyedByUserId",
      "replys.replyedByUserName",
      "replys.isReplyIsApproved",
      "replys.replyedOnDateTime",
    ],
    page: 0,
    filterBy: [{ fieldName: "blogId", value: data?._id }],
  };
  const {
    data: cmtData,
    isFetching,
    isLoading,
  } = useGetAllBlogCommentsQuery(paginationData);
  const [updateCommentStatus] = useUpdateBlogCommentMutation();
  const html = parser.parseFromString(data.description, 'text/html');
  const bodyData = html.body
  useEffect(()=>{
    document.getElementById("description").appendChild(bodyData)
  })
  useEffect(() => {
    if (!isLoading && !isFetching) {
      setCommentData(cmtData?.data);
    }
  }, [cmtData, isFetching, isLoading]); 

  return (
    <div className="d-flex justify-content-center my-3">
      <Button
        onClick={() => {
          viewDialog(false);
        }}
        style={{ position: "absolute", right: -10, zIndex: 1000, top: 75 }}
      >
        <HighlightOffIcon color="secondary" />
      </Button>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <TableContainer className="m-2 border">
            <Table className={classes.table} aria-label="customized table">
              <TableBody>
                {/* Title */}
                <StyledTableRow>
                  <StyledTableCell className="m-1 " align="left">
                    <b className="d-flex align-items-center text-primary ">
                      {" "}
                      Title{" "}
                    </b>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center text-muted">
                      {" "}
                      {data.title}{" "}
                    </p>
                  </StyledTableCell>
                </StyledTableRow> 
                {/*Sub Categories*/}
                <StyledTableRow>
                  <StyledTableCell className="m-1 " align="left">
                    <b className="d-flex align-items-center text-primary ">
                      {" "}
                      Sub-Categories{" "}
                    </b>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center text-muted">
                      {" "}
                      {data?.subCategories.map((el)=><div className="mx-2"> {el}, </div>)}{" "}
                    </p>
                  </StyledTableCell>
                </StyledTableRow> 

                {/* Meta Title */}
                <StyledTableRow>
                  <StyledTableCell className="m-1 " align="left">
                    <b className="d-flex align-items-center text-primary">
                      {" "}
                      Meta Title{" "}
                    </b>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center text-muted">
                      {" "}
                      {data.metaTitle}{" "}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>
                {/* Slugs */}
                <StyledTableRow>
                  <StyledTableCell className="m-1 " align="left">
                    <b className="d-flex align-items-center text-primary">
                      {" "}
                      Slugs{" "}
                    </b>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center text-muted">
                      {" "}
                      {data.slugs}{" "}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>
                {/* url */}
                <StyledTableRow>
                  <StyledTableCell className="m-1 " align="left">
                    <b className="d-flex align-items-center text-primary">
                      {" "}
                      Url{" "}
                    </b>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <a
                      target="black"
                      className="d-flex align-items-center"
                      href={data.url}
                    >
                      {data.url}
                    </a>
                  </StyledTableCell>
                </StyledTableRow>
                {/* Image */}
                <StyledTableRow>
                  <StyledTableCell className="m-1 " align="left">
                    <b className="d-flex align-items-center text-primary">
                      {" "}
                      Image{" "}
                    </b>
                  </StyledTableCell>
                  <StyledTableCell
                    className="m-1"
                    style={{ height: "30px" }}
                    align="left"
                  >
                    <img
                      width={200}
                      src={data.image}
                      alt=""
                      className="img-fluid"
                    />
                  </StyledTableCell>
                </StyledTableRow>
                {/* otherImages */}
                <StyledTableRow>
                  <StyledTableCell className="m-1 " align="left">
                    <b className="d-flex align-items-center text-primary">
                      {" "}
                      Other Images{" "}
                    </b>
                  </StyledTableCell>
                  <StyledTableCell
                    className="m-1"
                    style={{ height: "30px" }}
                    align="left"
                  >
                    {data.otherImages.map((img, index) => (
                      <img
                        width={200}
                        src={img}
                        alt=""
                        className="img-fluid  "
                      />
                    ))}
                  </StyledTableCell>
                </StyledTableRow>
                {/* videoAttachamentUrls */}
                <StyledTableRow>
                  <StyledTableCell className="m-1 " align="left">
                    <b className="d-flex align-items-center text-primary">
                      {" "}
                      Video Attachament Urls{" "}
                    </b>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    {data.videoAttachamentUrls.map((ele, index) => {
                      return index + 1 == data.videoAttachamentUrls.length ? (
                        <a
                          target="black"
                          className="d-flex align-items-center"
                          href={ele}
                        >
                          {ele}
                        </a>
                      ) : (
                        (
                          <a
                            target="black"
                            className="d-flex align-items-center"
                            href={ele}
                          >
                            {ele}
                          </a>
                        ) + ", "
                      );
                    })}
                  </StyledTableCell>
                </StyledTableRow>
                {/* FocusKeywords */}
                <StyledTableRow>
                  <StyledTableCell className="m-1 " align="left">
                    <b className="d-flex align-items-center text-primary">
                      {" "}
                      FocusKeywords{" "}
                    </b>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center text-muted">
                      {data.focusKeywords.map((ele, index) => {
                        return index + 1 == data.focusKeywords.length
                          ? ele
                          : ele + ", ";
                      })}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>
                {/* Tags */}
                <StyledTableRow>
                  <StyledTableCell className="m-1 " align="left">
                    <b className="d-flex align-items-center text-primary">
                      {" "}
                      Tags{" "}
                    </b>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center text-muted">
                      {data.tags.map((ele, index) => {
                        return index + 1 == data.tags.length ? ele : ele + ", ";
                      })}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>
                {/* description */}
                <StyledTableRow>
                  <StyledTableCell className="m-1 " align="left">
                    <b className="d-flex align-items-center text-primary">
                      {" "}
                      Description{" "}
                    </b>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p id="description" className="d-flex align-items-center text-muted">
                      {" "}
                      {/* {data.description}{" "} */}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>
                {/* metaDescription */}
                <StyledTableRow>
                  <StyledTableCell className="m-1 " align="left">
                    <b className="d-flex align-items-center text-primary">
                      {" "}
                      Meta Description{" "}
                    </b>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center text-muted">
                      {" "}
                      {data.metaDescription}{" "}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>
                {/* comments */}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex w-100">
            <div className="mt-3 mr-10">
              <b className=" text-primary ml-4"> Comments </b>
            </div>
            {/* <div className="d-flex w-full justify-content-between mx-3 mt-3"> */}

            {commentData?.length ? (
              <div className="w-100 ">
                <table className="mx-3 mt-3 ml-5">
                  <thead>
                    <tr>
                      <th
                        className="w-25 text-primary"
                        style={{ paddingBottom: "20px" }}
                      >
                        User Name
                      </th>
                      <th
                        className="w-50 text-primary"
                        style={{ paddingBottom: "20px" }}
                      >
                        Comment
                      </th>
                      <th
                        className="w-25 text-primary"
                        style={{ paddingBottom: "20px" }}
                      >
                        Approved
                      </th>
                      <th
                        className="w-25 text-primary"
                        style={{ paddingBottom: "20px" }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {commentData?.map((ele, index) => {
                      return (
                        <>
                          <tr>
                            <td>{ele?.commentedByUserName}</td>
                            <td>{ele?.comment}</td>
                            <td
                              className={
                                ele?.isCommentApproved
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {ele?.isCommentApproved
                                ? "Approved"
                                : "Not Approved"}
                            </td>
                            <td>
                              <Button
                                className={
                                  ele?.isCommentApproved
                                    ? "text-danger "
                                    : "text-success"
                                }
                                style={{
                                  borderColor: ele?.isCommentApproved
                                    ? "rgb(227 53 69)"
                                    : "rgb(40 167 69)",
                                }}
                                variant="outlined"
                                color="success"
                                onClick={() => {
                                  updateCommentStatus(ele?._id).then((res) => {
                                    if (res?.data?.status) {
                                      toast(res?.data?.message);
                                    } else {
                                      toast("Something went wrong!");
                                    }
                                  });
                                }}
                              >
                                {ele?.isCommentApproved
                                  ? "Disapprove"
                                  : "Approve"}
                              </Button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <hr />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>
                <p className="d-flex align-items-center text-muted mt-3 ml-5">
                  {" "}
                  No Comments
                </p>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      <ToastContainer />
    </div>
  );
}

export default ViewCoupons;
