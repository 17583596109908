import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { withStyles, ThemeProvider } from "@material-ui/core/styles";
import { Paper, CircularProgress, Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MuiAlert from "@material-ui/lab/Alert";
import { green } from "@material-ui/core/colors";
import {
  useUserGroupMutation,
  useUserLoginMutation,
} from "../../services/services";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function Login(props) {
  const [uselogin, userloginInfo] = useUserLoginMutation();
  const [userGoup] = useUserGroupMutation();
  const { theme } = props;
  const [flag, setFlag] = useState(true);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const useStyles = () => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      color: theme.palette.text.secondary,
    },
    grid: {
      padding: theme.spacing(4),
    },
    authBgGrid: {
      padding: theme.spacing(4),
      background: "#5664D291",
    },
    inputRoot: {
      fontSize: 16,
    },
    labelRoot: {
      fontSize: 14,
    },
    wrapper: {
      position: "relative",
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  });
  const classes = useStyles();
  withStyles(useStyles, { withTheme: true });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: yup.object({
      username: yup.string().required("Required !"),
      password: yup.string().required("Required !"),
    }),
    onSubmit: (values) => {
      setFlag(false);
      uselogin(values);
    },
  });

  useEffect(() => {
    let { status, data } = userloginInfo;
    if (status == "fulfilled") {
      let { status, message } = userloginInfo.data;
      if (!status) {
        notify(message);
        setFlag(true);
      } else {
        if (userloginInfo?.data?.data?.type === "ADMIN") {
          window.location.replace("/welcome");
          localStorage.setItem(
            "modules",
            userloginInfo?.data?.data?.type == "ADMIN"
              ? ""
              : JSON.stringify(userloginInfo?.data?.data.module_group)
          );
          localStorage.setItem(
            "userData",
            JSON.stringify(userloginInfo?.data?.data)
          );
          localStorage.setItem("authType", userloginInfo?.data?.data?.type);
          localStorage.setItem("__authToken__", data.token);
          localStorage.setItem("isLoggedIn", "true");
          notify(message);
        } else {
          userGoup(data.token).then((res) => {
            if (res?.data?.status) {
              localStorage.setItem(
                "accessModules",
                JSON.stringify(res?.data?.data?.accessModules)
              );
              window.location.replace("/welcome");
              localStorage.setItem(
                "modules",
                userloginInfo?.data?.data?.type == "ADMIN"
                  ? ""
                  : JSON.stringify(userloginInfo?.data?.data.module_group)
              );
              localStorage.setItem(
                "userData",
                JSON.stringify(userloginInfo?.data?.data)
              );
              localStorage.setItem("authType", userloginInfo?.data?.data?.type);
              localStorage.setItem("__authToken__", data.token);
              localStorage.setItem("isLoggedIn", "true");
              notify(message);
            } else {
              window.location.replace("/not-authenticated");
            }
          });
        }
      }
    }
  }, [userloginInfo]);

  // useEffect(() => {
  //   if (
  //     localStorage.getItem("__authToken__") !== null &&
  //     localStorage.getItem("__authToken__") !== undefined &&
  //     localStorage.getItem("isLoggedIn") == "true"
  //   ) {
  //     window.location.replace("/welcome");
  //   }
  // }, []);

  const getQueryParam = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  };

  // Read token from URL
  const token = getQueryParam("authtoken");
  const userDataJson = getQueryParam("userData");
  const authType = getQueryParam("authType");
  const redirect = getQueryParam("redirect");
  const userData = JSON.parse(userDataJson);
   
  const formatedUserData  = {
    username: userData?.username ? userData?.username : userData?.userName ,
    is_accountant: userData?.is_accountant,
    is_caller: userData?.is_caller,
    mobile: userData?.mobile,
    module_group: userData?.module_group,
    offline_caller: userData?.offline_caller,
    online_caller: userData?.online_caller,
    type: userData?.type,
    userType: userData?.userType,
  };

  useEffect(() => {
    if (token !== null) {
      localStorage.setItem("__authToken__", token);
      localStorage.setItem("userData", JSON.stringify(formatedUserData));
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("authType", authType);
      if (authType !== "ADMIN") {
        userGoup(token).then((res) => {
          if (res?.data?.status) {
            localStorage.setItem(
              "accessModules",
              JSON.stringify(res?.data?.data?.accessModules)
            );
            window.location.replace(`/${redirect}`);
          } 
        });
      } 
      else if (authType === 'ADMIN') {
        window.location.replace(`/${redirect}`);
      }
    } else if (
      localStorage.getItem("__authToken__") !== null &&
      localStorage.getItem("__authToken__") !== undefined &&
      localStorage.getItem("isLoggedIn") == "true"
    ) {
      window.location.replace("/welcome");
    }
  }, []);

  const notify = (msg) => toast(msg);
  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "100vh" }}
          >
            <Grid item xs={6} md={6} lg={6} xl={6}>
              <Paper style={{ padding: "50px" }} className={classes.paper}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="row"
                >
                  <Grid
                    item
                    xs={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className={classes.grid}
                  >
                    <CssBaseline />
                    <div className={classes.paper}>
                      <div className="text-center mb-4">
                        <Typography
                          component="h2"
                          className="mb-3"
                          variant="h5"
                          style={{ fontWeight: 500, color: "#424040" }}
                        >
                          Welcome To
                          <br />{" "}
                          <Link to="/login" style={{ textDecoration: "none" }}>
                            Smart Learning Destination{" "}
                          </Link>
                        </Typography>
                      </div>
                      <Typography
                        component="h1"
                        className="mb-3"
                        variant="h5"
                        style={{ fontWeight: 500, color: "#424040" }}
                      >
                        Login
                      </Typography>

                      {flag ? (
                        <form
                          onSubmit={formik.handleSubmit}
                          className={classes.form}
                          noValidate
                          autoComplete={"off"}
                        >
                          <TextField
                            name="username"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.username}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Username"
                            InputProps={{
                              classes: { root: classes.inputRoot },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRoot,
                              },
                            }}
                          />
                          {formik.touched.username && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              {formik.errors.username}
                            </span>
                          )}

                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Password"
                            type="password"
                            name="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            InputProps={{
                              classes: { root: classes.inputRoot },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRoot,
                              },
                            }}
                          />
                          {formik.touched.password && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              {formik.errors.password}
                            </span>
                          )}

                          <Grid container alignItems="center" className="mt-3">
                            <Grid item xs>
                              Forgot Password
                            </Grid>
                            <Grid item></Grid>
                          </Grid>

                          <Grid container alignItems="center" className="my-3">
                            <Grid item xs>
                              <div className="d-flex align-items-center">
                                <div className={classes.wrapper}>
                                  <Button
                                    //onClick={(e) => login(e)}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                  >
                                    Sign In
                                  </Button>
                                </div>
                              </div>
                            </Grid>
                            <Grid item></Grid>
                          </Grid>
                        </form>
                      ) : (
                        <div
                          style={{ height: "40vh" }}
                          className=" d-flex justify-content-center d-flex align-items-center"
                        >
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                          </Box>
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className={classes.authBgGrid}
                  >
                    <img
                      src="assets/images/login-pana.png"
                      alt=""
                      className="img-fluid"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </ThemeProvider>
      <ToastContainer />
    </>
  );
}
