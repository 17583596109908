import React from "react";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";

export default function UpdateLevels({ editData, editDialog, UpdateData }) {
  const dialogclose = () => {
    editDialog(false);
  };
  const formik = useFormik({
    initialValues: {
      level_name: editData.level_name,
      level_code: editData.level_code,
      rank_order: editData.rank_order,
    },
    validationSchema: yup.object({
      level_name: yup.string().required("Required !"),
      level_code: yup.string().required("Required !"),
      rank_order: yup
        .number()
        .typeError("Order rank must be number")
        .min(1, "order rank should be greater than or equal to 1")
        .required("Required"),
    }),
    onSubmit: (values) => {
      UpdateData(values, editData._id);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        name="level_name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.level_name}
        fullWidth={true}
        label="Level name "
        variant="outlined"
        size="small"
      />
      {formik.touched.level_name && (
        <span style={{ color: "red", fontSize: "12px" }}>
          {formik.errors.level_name}
        </span>
      )}

      <br />
      <br />

      <TextField
        name="level_code"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.level_code}
        fullWidth={true}
        label="Level code "
        variant="outlined"
        size="small"
      />
      {formik.touched.level_code && (
        <span style={{ color: "red", fontSize: "12px" }}>
          {formik.errors.level_code}
        </span>
      )}

      <br />
      <br />

      <TextField
        name="rank_order"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.rank_order}
        fullWidth={true}
        label="Order Rank"
        variant="outlined"
        size="small"
      />
      {formik.touched.rank_order && (
        <span style={{ color: "red", fontSize: "12px" }}>
          {formik.errors.rank_order}
        </span>
      )}

      <br />
      <br />

      <Grid container>
        <Grid xs={6}></Grid>
        <Grid className="d-flex justify-content-between" xs={6}>
          <Button
            onClick={() => dialogclose()}
            variant="contained"
            color="secondary"
          >
            Close
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
