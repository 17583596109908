import React from 'react';
import CreateTable from './CreateTable';
import EditDelete from './EditDelete';
import AddLanguage from './AddLanguage';


const columns = [    
    {
        id: 'LanguageName',
        label: 'Language Name',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    
    {
        id: 'isActive',
        label: 'Status',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'latupdated',
        label: 'Last Updated',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'actions',
        label: 'Actions',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
];
function createData(LanguageName,isActive,latupdated,actions) {
    return {LanguageName,isActive,latupdated,actions};
}
const rows = [
    createData("Hindi","Active","16/08/2021 11:55 am",<EditDelete type="delete" />),
    

];


export default function Languages(props) {

    return (
        <CreateTable isButton={true} addFormName={"Add Language"} form={<AddLanguage/>}  rows={rows} columns={columns} />
    )
}
