import React from 'react'
import { CircularProgress, Dialog } from '@mui/material' 
import { Button, Grid, Box, } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import {TextField} from '@material-ui/core';
import {TextareaAutosize} from '@material-ui/core';

const ViewQuestion = ({viewDialog , data , deleteAns, isLoading }) => { 
  return (
   <Dialog className='dialog p-5'  PaperProps={{ style: {
    minHeight: '60%',
    minWidth:'50%',
    maxHeight: '90%',
  }}}  open={true}>

                    <div>
                       {isLoading ? <div className='d-flex justify-content-center items-content-center mt-5'  ><CircularProgress/></div>  :  <Grid container className='p-3'  >
                            <Grid xs={12} className=' my-1'>
                                <Button
                                    onClick={() =>{ viewDialog(false);}}
                                    className="text-light bg-danger float-right btn btn-sm btn-danger">
                                    Close
                                </Button>
                            </Grid>

                            <Grid xs={11} className='my-2'>
                               <h5 className="mb-3 text-muted bg-light m-2 d-flex align-items-center p-3">
                                   Questions: <span className="highlight-text-default  px-2 mx-5">
                                    {data?.question}
                                    </span>
                                </h5> 
                              <div className='row  bg-light w-100' >
                              <h5 className="mb-3  col-2  text-muted  m-2 p-3">
                                Answer: 
                                   </h5> 
                                  <div  className='col-9' >
                                  {data?.answeres?.map((ele) =>{ 
                                      return <div className="highlight-text-default fs-5  d-flex align-items-center p-1 text-17"><TextareaAutosize
                                      disabled={true}
                                      value={ele?.answer}
                                      fullWidth={true}
                                      style={{ width: "100%" }}
                                    /> <span className='text-danger mx-4 cursor-pointer' onClick={()=>{
                                        deleteAns({id:data?._id ,ansid:ele?._id})
                                      }} > <DeleteIcon/> </span>
                                      </div>
                                    } ) }
                                  </div>

                              </div>
                            </Grid>

                        </Grid>}

                    </div>
              
   </Dialog>
  )
}

export default ViewQuestion