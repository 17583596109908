import React,{useState} from 'react'
import MaterialTable from 'material-table';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { KeyboardReturn } from '@mui/icons-material';


export default function MaterialTables({columns,rows}) {
    return (
        <>
        {
            columns.length && rows.length ? 
            <TableContainer className='my-3' component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow 
                        //className='bg-light border-top border-secondary'
                        style={{backgroundColor:"#d1d9e1"}} 
                        >
                        {columns.map((ele,ind)=>{
                        return ele != "_id" ? <TableCell style={{fontWeight:"900"}} className='text-muted pt-2 pb-2' align="center">{ele.replaceAll("_"," ")}</TableCell> : null
                        })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((ele,ind) => { 
                                    //let keys = Object.keys(ele)
                                    return <TableRow
                                                    hover
                                                    key={ind}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    {columns.map((keyEle,keyInd)=>{
                                                        return keyEle  != "_id" ? <TableCell style={{fontSize:"13px" ,color:ele[keyEle]=="CANCELLED"?"red":""}}  align="center" component="th" scope="row">{ele[keyEle]}</TableCell> : null
                                                    })}
                                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>:null
        }
        </>
        
    )
}
