import React, { useState, useEffect, Link, state } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import AddDataHeader from "../AddDataHeader";
import {
  useGetCancelOrderRequestsMutation,
  useApproveCancelOrderRequestMutation,
} from "../../../../services/StudentRequestsSevices";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import DataNotFound from "../DataNotFound";
import moment from "moment";
import {
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";
import ViewCancelOrderRequest from "./cancel-order-request/ViewCancelOrderRequest";
import CheckIcon from "@material-ui/icons/Check";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import AuthWrapper from "../../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../../utils/getFilterFunction";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import { handleOnlyNumbers } from "../../../../helpers/validation";

export default function CashBackRequests() {
  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 20,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "user",
      label: "User",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "order",
      label: "Order",
      minWidth: 20,
      maxWidth: 10,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      style: { maxWidth: 250, overFlow: "scroll" },
    },
    {
      id: "dates",
      label: "Dates",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "reasonForCancellation",
      label: "Cancellation Reason",
      minWidth: 20,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "orderStatus",
      label: "Order Status",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "action",
      label: "Actions",
      minWidth: 20,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: ["CANCEL_ORDERS_REQUEST_VIEW"],
    },
  ];

  function createData(
    SNo,
    user,
    order,
    dates,
    reasonForCancellation,
    orderStatus,
    action
  ) {
    return {
      SNo,
      user,
      order,
      dates,
      reasonForCancellation,
      orderStatus,
      action,
    };
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: [
      "sld_order_id",
      "order_title",
      "shipping_details.mobile",
      "shipping_details.email",
    ],
    page: page + 1,
  };
  let rows = [];
  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [viewDialog, setViewDialog] = useState(false);
  const [getAllData, getAllDataInfo] = useGetCancelOrderRequestsMutation();
  const [approveRequest, approveRequestInfo] =
    useApproveCancelOrderRequestMutation();
  const AllData = getAllDataInfo.data;
  const totalItem = AllData ? AllData.totalItem : 0;
  const [render, setRender] = useState(false);
  const [getSldOrderId, setSldOrderId] = useState("");
  const [remarkDialog, setRemarkDialog] = useState({});

  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page]);

  let totalData = "ok";
  if (AllData) {
    totalData = AllData.totalItem;
    rows = AllData.data.map((ele, index) =>
      createData(
        index + 1,
        <div className="">
          <span className="text-info font-weight-bold">
            {ele?.user_details?.first_name} {ele?.user_details?.last_name}
          </span>
          <br />
          <span className="text-muted font-weight-bold">
            {ele?.user_details?.mobile}
          </span>
          <br />
          <span className="text-muted">({ele?.user_details?.email})</span>
        </div>,
        <div className="">
          <span className="text-info font-weight-bold">
            {ele?.sld_order_id}
          </span>
          <br />
          <span className="text-muted">({ele?.order_title})</span>
        </div>,
        <div className="">
          <span className="text-info font-weight-bold">Order Date</span>
          <br />
          <span className="text-muted font-weight-bold">
            {ele?.order_date
              ? moment(ele.order_date).format("DD-MM-YYYY hh:mm:ss A")
              : ""}
          </span>
          <br />
          <ArrowDownwardIcon fontSize="small" className="text-info" />
          <br />
          <span className="text-info font-weight-bold">Requested Date</span>
          <br />
          <span className="text-muted font-weight-bold">
            {ele?.requested_on_date
              ? moment(ele.requested_on_date).format("DD-MM-YYYY hh:mm:ss A")
              : ""}
          </span>
          <br />
          <ArrowDownwardIcon fontSize="small" className="text-info" />
          <br />
          <span className="text-info font-weight-bold">Cancelled Date</span>
          <br />
          <span className="text-muted font-weight-bold">
            {ele?.cancelled_date
              ? moment(ele.cancelled_date).format("DD-MM-YYYY")
              : ""}
          </span>
          <br />
        </div>,
        ele.reason_for_cancellation,
        <div className="">
          <span className="text-info font-weight-bold">Order Status</span>
          <br />
          <span className="text-muted font-weight-bold">
            {ele.current_status}
          </span>
          <br />
          <span className="text-info font-weight-bold">
            Cancel Request Status
          </span>
          <br />
          <span className="text-muted font-weight-bold">
            {ele.cancellation_status}
          </span>
          <br />
        </div>,
        <div className="">
          {!ele?.is_approved && !ele?.disapproval_remark ? (
            <>
              <AuthWrapper
                type="ACTION"
                name="CANCEL_ORDERS_REQUEST_APPROVE_REQUEST"
              >
                <Tooltip placement="top" title="Approve Request" arrow>
                  <IconButton
                    onClick={() => {
                      setRemarkDialog({
                        approve: true,
                        disApprove: false,
                      });
                      setGetid(ele._id);
                      setSldOrderId(ele?.sld_order_id);
                    }}
                  >
                    <CheckIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </AuthWrapper>

              <AuthWrapper
                type="ACTION"
                name="CANCEL_ORDERS_REQUEST_DISAPPROVE_REQUEST"
              >
                <Tooltip placement="top" title="Disapprove Request" arrow>
                  <IconButton
                    onClick={() => {
                      setRemarkDialog({
                        approve: false,
                        disApprove: true,
                      });
                      setGetid(ele._id);
                      setSldOrderId(ele?.sld_order_id);
                    }}
                  >
                    <CloseIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </AuthWrapper>
            </>
          ) : null}

          <AuthWrapper type="ACTION" name="CANCEL_ORDERS_REQUEST_VIEW">
            <EditDelete
              setViewDialog={setViewDialog}
              type="view"
              setData={setData}
              data={ele}
            />
          </AuthWrapper>
        </div>
      )
    );
  }

  const dialogclose = () => {
    setViewDialog(false);
    setRender(!render);
    setRemarkDialog({
      approve: false,
      disApprove: false,
    });
  };

  function ApproveRequestForm({ getid, open, onClose, approveRequest }) {
    const formik = useFormik({
      initialValues: {
        sld_order_id: getSldOrderId,
        remark: "",
        action: "APPROVED",
        approved_amount: 0,
      },
      validationSchema: yup.object({
        approved_amount: yup.number(),
        // .test(
        //   "amount-test",
        //   "Approved amount must be less than demanded amount ",
        //   (value) => {
        //     return Number(value) <= Number(cashbackInfo?.demandedAmount);
        //   }
        // )
        // .required("Approved amount is required"),
        remark: yup.string().trim().required("Remark is required"),
      }),

      onSubmit: (values) => {
        approveRequest({
          body: values,
          id: getid,
        });
      },
    });

    return (
      <Dialog fullWidth={true} open={open} onClose={onClose}>
        <DialogTitle>Approve Cancel Order</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <label>Approved Amount</label>
            <TextField
              margin="dense"
              name="approved_amount"
              type="text"
              fullWidth
              variant="outlined"
              onChange={(e) =>
                handleOnlyNumbers(e.target.value) &&
                formik.setFieldValue("approved_amount", e.target.value)
              }
              onBlur={formik.handleBlur}
              value={formik.values.approved_amount}
            />
            {formik.touched.approved_amount && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {formik.errors.approved_amount}
              </span>
            )}
            <br />
            <br />

            <label>Approvel remark</label>
            <TextField
              margin="dense"
              name="remark"
              type="text"
              fullWidth
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.remark}
              multiline
              minRows={3}
            />
            {formik.touched.remark && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {formik.errors.remark}
              </span>
            )}
            <br />
            <br />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              type="submit"
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
            >
              {" "}
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  function DisApproveRequestForm({ getid, open, onClose, disapproveRequest }) {
    const formik = useFormik({
      initialValues: {
        sld_order_id: getSldOrderId,
        remark: "",
        action: "DISAPPROVED",
        approved_amount: 0,
      },
      validationSchema: yup.object({
        remark: yup.string().trim().required("Remark is required"),
      }),

      onSubmit: (values) => {
        disapproveRequest({
          body: values,
          id: getid,
        });
      },
    });

    return (
      <Dialog fullWidth={true} open={open} onClose={onClose}>
        <DialogTitle>Disapprove Cashback</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <label>Disapproval remark</label>
            <TextField
              margin="dense"
              name="remark"
              type="text"
              fullWidth
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.remark}
              multiline
              minRows={3}
            />
            {formik.touched.remark && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {formik.errors.remark}
              </span>
            )}
            <br />
            <br />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              type="submit"
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
            >
              {" "}
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }


  return (
    <div>
      <AddDataHeader
        setPage={setPage}
        page={page}
        totalItem={totalItem}
        isSearch={
          !AuthWrapper({
            type: "ACTION",
            name: "CANCEL_ORDERS_REQUEST_LIST",
            inBoolean: true,
          })
        }
        isPagination={
          !AuthWrapper({
            type: "ACTION",
            name: "CANCEL_ORDERS_REQUEST_LIST",
            inBoolean: true,
          })
        }
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchBarApi={getAllData}
        paginationData={paginationData}
        isButton={false}
        addBtn={true}
      />
      {/* {addDataInfo.isLoading || deleteDataInfo.isLoading || updateDataInfo.isLoading ? <Loader /> : <> */}
      <AuthWrapper type="ACTION" name="CANCEL_ORDERS_REQUEST_LIST">
        {!viewDialog ? (
          rows.length ? (
            <CreateTableAddButton
              maxWidth="md"
              fullWidth={false}
              rows={rows}
              columns={getFilterdColumns(columns)}
              isButton={true}
            />
          ) : totalData == 0 ? (
            <DataNotFound />
          ) : (
            <SkeletonListing />
          )
        ) : (
          <ViewCancelOrderRequest
            viewDialog={viewDialog}
            data={data}
            setViewDialog={setViewDialog}
          />
        )}
      </AuthWrapper>
      {/* </>} */}

      {remarkDialog?.approve && (
        <ApproveRequestForm
          onClose={() =>
            setRemarkDialog({
              approve: false,
              disApprove: false,
            })
          }
          open={remarkDialog?.approve}
          approveRequest={approveRequest}
          getid={getid}
        />
      )}
      {remarkDialog?.disApprove && (
        <DisApproveRequestForm
          onClose={() =>
            setRemarkDialog({
              approve: false,
              disApprove: false,
            })
          }
          open={remarkDialog?.disApprove}
          disapproveRequest={approveRequest}
          getid={getid}
        />
      )}

      {/* {remarkDialog && (
        <Dialog fullWidth={true} open={remarkDialog} onClose={dialogclose}>
          <DialogTitle>Remark</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              // id="name"
              name="remark"
              label="remark"
              type="text"
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setApproveRemarkData({
                  ...approveRemarkData,
                  approval_remark: e.target.value,
                });
              }}
              multiline
              minRows={4}
              // onBlur={handleBlur}
              // value={value}
            />
            <p className="text-danger">{approveRemarkData.remarkError}</p>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setRemarkDialog(false);
                setApproveRemarkData({ ...approveRemarkData, remarkError: "" });
              }}
            >
              Cancel
            </Button>
            <Button
              // type="submit"
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
              onClick={() => {
                handleApproveRemarkSubmit();
              }}
            >
              {" "}
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )} */}

      {approveRequestInfo && remarkDialog?.approve && (
        <Toster info={approveRequestInfo} dialogclose={dialogclose} />
      )}
      {approveRequestInfo && remarkDialog?.disApprove &&(
        <Toster info={approveRequestInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
