import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const currencyApi = createApi({
    tagTypes: ["currency"],
    reducerPath: "currencyApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/currency/`,
    }),
    endpoints: (builder) => ({
        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET "currency" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        GetAllCurrencies: builder.query({
            providesTags: ["currency"],
            query: () => {
                return {
                    url: "get-all-currency-notes", //******** *
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                };
            },
        }),

        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ADD "currency" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        AddCurrency: builder.mutation({
            invalidatesTags: ["currency"],
            query: (body) => {
                return {
                    url: "add-currency-note", // ***********
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,
                };
            },
        }),


        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< UPDATE "currency" DATA SECTION >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        UpdateCurrency: builder.mutation({
            invalidatesTags: ["currency"],
            query: ({ body, id }) => {
                return {
                    url: id, //********** *
                    headers: {
                        "x-access-token": token,
                    },
                    method: "PUT",
                    body,
                };
            },
        }),


        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< DELETE DATA IN "currency" SECTION >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        DeleteCurrency: builder.mutation({
            invalidatesTags: ["currency"],
            query: (id) => {
                return {
                    url: id, //********** *
                    headers: {
                        "x-access-token": token,
                    },
                    method: "DELETE",
                };
            },
        }),


    }),
});

export const {
    useGetAllCurrenciesQuery ,
    useAddCurrencyMutation , 
    useUpdateCurrencyMutation , 
    useDeleteCurrencyMutation ,
} = currencyApi;


