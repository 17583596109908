import React from 'react'
import {
   Grid
} from '@material-ui/core';
import { Button } from '@mui/material';


export default function ViewCategory({ data, viewDialog }) {
   return (
      <div>


         <Grid container spacing={1} className="ml-5  mt-2">

            <Grid xs={11} className=' my-1'>
               <Button
                  onClick={() => viewDialog(false)}
                  className="text-light bg-danger float-right btn btn-sm btn-danger">
                  Close
               </Button>

            </Grid>

            <Grid item xl={1} md={11} sm={12} xs={12} className="my-2">
               <div className="card">
                  <div className="card-body row">
                     <div className="col-3 d-flex justify-content-center"><b>Category Name</b></div>
                     <div className="col-1"><b>:-</b></div>
                     <div className="col-8 d-flex justify-content-start"> {data.category_name}</div>
                  </div>

               </div>
            </Grid>
            <Grid item xl={1} md={11} sm={12} xs={12} className="my-2">
               <div className="card">
                  <div className="card-body row">
                     <div className="col-3 d-flex justify-content-center"><b>Parent Category Name</b></div>
                     <div className="col-1"><b>:-</b></div>
                     <div className="col-8 d-flex justify-content-start"> {data.parent_category_name}</div>
                  </div>

               </div>
            </Grid>


            <Grid item xl={1} md={11} sm={12} xs={12} className="my-2">
               <div className="card">
                  <div className="card-body row">
                     <div className="col-3 d-flex justify-content-center"><b>Category Code</b></div>
                     <div className="col-1"><b>:-</b></div>
                     <div className="col-8 d-flex justify-content-start"> {data.category_code}</div>
                  </div>
               </div>
            </Grid>

            <Grid item xl={1} md={11} sm={12} xs={12} className="my-2">
               <div className="card">
                  <div className="card-body row">
                     <div className="col-3 d-flex justify-content-center"><b>Category Ative</b></div>
                     <div className="col-1"><b>:-</b></div>
                     <div className="col-8 d-flex justify-content-start"> {data.is_active ? "Yes" : "No"}</div>
                  </div>
               </div>
            </Grid>


            <Grid item xl={1} md={11} sm={12} xs={12} className="my-2">
               <div className="card">
                  <div className="card-body row">
                     <div className="col-3 d-flex justify-content-center"><b>Create At</b></div>
                     <div className="col-1"><b>:-</b></div>
                     <div className="col-8 d-flex justify-content-start"> {data.createdAt}</div>
                  </div>

               </div>
            </Grid>

            <Grid item xl={1} md={11} sm={12} xs={12} className="my-2">
               <div className="card">
                  <div className="card-body row">
                     <div className="col-3 d-flex justify-content-center"><b>Order Rank</b></div>
                     <div className="col-1"><b>:-</b></div>
                     <div className="col-8 d-flex justify-content-start"> {data.order_rank}</div>
                  </div>

               </div>
            </Grid>
         </Grid>

      </div>
   )
}
