import React, { useState, useEffect } from 'react';
import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import { Button } from "@material-ui/core";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import MyTable from './MyTable';
import { Chip } from '@material-ui/core';
import {  useUpdateUserTypeMutation, useGetUserByIdQuery } from '../../../services/services'
import SkeletonListing from './SkeletonListing';
import Toster from './Toster';
import { Link } from 'react-router-dom';
import { userAccess } from '../../../helpers/userAccess'

export default function UserPage({ location }) {
 
  let userData = location.state.data
    
  const [updateUserType, updateUserTypeInfo] = useUpdateUserTypeMutation()  
  const [render , setRender] = useState(true)

  if(updateUserTypeInfo.isSuccess == true){
    userData = updateUserTypeInfo.data.data
  }

  const makeFranchiseBtnHandler = (id) => {    
    updateUserType({
      body: {
        type: userData.type === "STUDENT" ? "FRANCHISE" : "STUDENT"
      },
      id: id
    })

    setRender(!render)
  }

  const dialogclose = ()=> {
    
  }

  return (
    <>
      <div style={{
        backgroundImage: `url(${"https://wallpaperaccess.com/full/2857990.jpg"})`,
        color: "white",
        padding: 30
      }}>
        <Grid
          container
          spacing={3}
          style={{ paddingBottom: 20 }}
        >
          <Grid item xs={4} className='mx-4 p-0'>

            <h5 className="mb-3  m-2 d-flex align-items-center">
              <PersonIcon className="mr-1" /> : <span className="   px-2">
                {userData.first_name} {userData.last_name}
              </span>
            </h5>

            <h5 className="mb-3  m-2 d-flex align-items-center">
              <PhoneIcon className="mr-1" /> : <span className="   px-2">
                {userData.mobile}
              </span>
            </h5>

            <h5 className="mb-3  m-2 d-flex align-items-center">
              <EmailIcon className="mr-1" /> : <span className="   px-2">
                {userData.email}
              </span>
            </h5>

            <h5 className="mb-3  m-2 d-flex align-items-center">
              <CurrencyRupeeIcon className="mr-1" /> : <span className="   px-2">
                {userData.wallet_amount}
              </span>
            </h5>

           {
             userData.address.length?
             <h5 className="mb-3  m-2 d-flex ">
              <HomeIcon className="mr-1" /> : <span className="   px-2">
                {/* {userData.address} */}
                {userData.address[0].address_line1
                                                +", "+userData.address[0].address_line2
                                                +", "+userData.address[0].city
                                                +", "+userData.address[0].state
                                                +", "+userData.address[0].country}
              </span>
            </h5>
            :null
           }
            


          </Grid>

          <Grid item xs={4} className='p-0'>

            {/* <h5 className="mb-3  m-1 d-flex align-items-center">
              course : <span className="   px-2">
                {userData.first_name} {userData.last_name}
              </span>
            </h5>

            <h5 className="mb-3  m-1 d-flex align-items-center">
              level : <span className="   px-2">
                {userData.mobile}
              </span>
            </h5>*/}

            <h5 className="mb-3  m-1 d-flex align-items-center">
              Type : <span className="   px-2">
                {userData.type}
              </span>
            </h5> 

          </Grid>

          <Grid item xs={2} className="text-center">
            <Button
             className='mb-3' 
             fullWidth={true} 
             variant="outlined" 
             color="secondary" 
             onClick={()=>{makeFranchiseBtnHandler(userData._id)}}>
             {userData.type === "STUDENT" ? "Make Franchise" : "Make Student"}
            </Button>
            <Link to={{
                pathname:"/users-and-franchise",
                state:{type:userData.type}
              }} >
                <Button className='bg-light' fullWidth={true} >Back</Button>
            </Link>
          </Grid>

        </Grid>
      </div>
      <Grid>
        {userData ?(userAccess('order') && userAccess('cart') ? <MyTable userData={userData} /> : null)  : <SkeletonListing />}
      </Grid>

      {updateUserTypeInfo.data && <Toster info={updateUserTypeInfo} dialogclose={dialogclose} />}
    </>
  );
}
