import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const orderTransactionApi = createApi({
    //tagTypes: ["order-transaction"],
    reducerPath: "orderTransactionApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/order-transaction/`,
    }),
    endpoints: (builder) => ({
        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< get order transaction data  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        GetOrderTransactionData: builder.query({
            //providesTags: ["order-transaction"],
            query: (body) => {
                return {
                    url: "all-order-transaction",
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,
                };
            },
        }),


    }),
});

export const {
    useGetOrderTransactionDataQuery
} = orderTransactionApi;


