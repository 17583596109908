import { Dialog, DialogContent, DialogTitle, Divider, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { Button, DialogActions, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from '@mui/material'
import moment from 'moment'
import React from 'react'

export default function OfflineOrderDetailsViewPage({ open, onClose, data }) {
  

    // ****  Even Row color
    const evenRowColor = "#f5f5f5"
    const oddRowColor = "#dbe7ff"

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => onClose(false)}
                fullWidth
                maxWidth="md"
                fullScreen
            >
                <DialogTitle style={{ backgroundColor: "rgb(86 100 210)" }} className="text-light"> Order Details</DialogTitle>
                <DialogActions>
                    <Button variant='contained' color="error" onClick={() => onClose(false)}> Close</Button>
                </DialogActions>
                <DialogContent className='px-5 pb-5'>
                    <Grid container spacing={2} >

                        {/* User Details */}
                        <Grid item xs={6} className="mt-3"  >
                            <Grid container className='mb-2' >
                                <Grid item xs={12}  >
                                    <Typography variant='h6'> User Details</Typography>
                                </Grid>
                            </Grid>
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow sx={{ bgcolor: oddRowColor }} >
                                            <TableCell> Name : </TableCell>
                                            <TableCell> {data.student_name} </TableCell>
                                        </TableRow  >
                                        <TableRow sx={{ bgcolor: evenRowColor }} >
                                            <TableCell> Mobile :  </TableCell>
                                            <TableCell> {data.mobile_number} </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ bgcolor: oddRowColor }}>
                                            <TableCell> Email : </TableCell>
                                            <TableCell> {data.email} </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        {/* Order Details */}
                        <Grid item xs={6} className="mt-3" >
                            <Grid container className='mb-2' >
                                <Grid item xs={12}  >
                                    <Typography variant='h6'> Order Details</Typography>
                                </Grid>
                            </Grid>
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow sx={{ bgcolor: oddRowColor }}>
                                            <TableCell> Date : </TableCell>
                                            <TableCell> {moment(data.order_date).format("DD MMM YYYY")} , {moment(data.order_date).format("HH:MM:SS A")} </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ bgcolor: evenRowColor }}>
                                            <TableCell> Order Id :  </TableCell>
                                            <TableCell> {data.sld_order_id} </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ bgcolor: oddRowColor }}>
                                            <TableCell> Invoice No. : </TableCell>
                                            <TableCell> {data.invoice_no} </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        {/* Other Details */}
                        <Grid item xs={12} className=" mt-3">
                            <Typography variant='h6' className='py-2'> Other Details </Typography>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TableContainer>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow sx={{ bgcolor: oddRowColor }}>
                                                <TableCell> CRO No. : </TableCell>
                                                <TableCell> {data.cro_number || "-"}  </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ bgcolor: evenRowColor }}>
                                                <TableCell> Attempt Due :  </TableCell>
                                                <TableCell> {data.attempt_due} </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ bgcolor: oddRowColor }}>
                                                <TableCell> Batch No. : </TableCell>
                                                <TableCell> {data.batch[0].batch_name} </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ bgcolor: evenRowColor }}>
                                                <TableCell> Combo(With) : </TableCell>
                                                <TableCell> {data.combo.toUpperCase() == "YES" ? data.batch[1].batch_name : "-"} </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ bgcolor: oddRowColor }}>
                                                <TableCell> Subject : </TableCell>
                                                <TableCell>  {data.subject.map(ele => ele.subject_name).join(", ")}  </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ bgcolor: evenRowColor }}>
                                                <TableCell> Course : </TableCell>
                                                <TableCell> {data.course.course_name} </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ bgcolor: oddRowColor }}>
                                                <TableCell> Faculty Name : </TableCell>
                                                <TableCell> {data.faculty.map(ele => ele.faculty_name).join(", ")} </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ bgcolor: evenRowColor }}>
                                                <TableCell> Level : </TableCell>
                                                <TableCell> {data.level.level_name} </TableCell>
                                            </TableRow>


                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                            <Grid item xs={6}>
                                <TableContainer>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow sx={{ bgcolor: oddRowColor }}>
                                                <TableCell> Hall No. : </TableCell>
                                                <TableCell> {data.batch[0].hall_number} </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ bgcolor: evenRowColor }}>
                                                <TableCell> Book Issued : </TableCell>
                                                <TableCell> {data.book_issued || "-"} </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ bgcolor: oddRowColor }}>
                                                <TableCell> Batch Type : </TableCell>
                                                <TableCell> {data.category[0].category_name} </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ bgcolor: evenRowColor }}>
                                                <TableCell> Batch Time : </TableCell>
                                                <TableCell> {data.batch[0].batch_time} </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ bgcolor: oddRowColor }} >
                                                <TableCell> Sold By Name  : </TableCell>
                                                <TableCell> {data.sold_by_slduser_type} </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ bgcolor: evenRowColor }}>
                                                <TableCell> Total Fees : </TableCell>
                                                <TableCell className="text-primary" > &#8377; {data.amount_after_discount} </TableCell>
                                            </TableRow >
                                            <TableRow sx={{ bgcolor: oddRowColor }}>
                                                <TableCell > Fees Paid  : </TableCell>
                                                <TableCell className="text-success" > &#8377; {data.fee_paid} </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ bgcolor: evenRowColor }} >
                                                <TableCell> Due Amount : </TableCell>
                                                <TableCell className="text-danger" > &#8377; {data.due_amount} </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                        {
                            data.remark &&
                            <TableContainer sx={{ mt: 2 }} >
                                <Table size="small" >
                                    <TableBody>
                                        <TableRow  >
                                            <TableCell width={100} > Remark : </TableCell>
                                            <TableCell align='left' > {data.remark} </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }

                        {/* <Grid item xs={6} className="mt-5 px-4" >
                            <Grid container spacing={2} direction="column">
                                <Grid item xs={12} className="text-center bg-info text-light" >
                                    <Typography variant='h6'> Received Payment Notes</Typography>
                                </Grid>
                                <Divider />
                            </Grid>
                        </Grid> */}
                        {/* <Grid item xs={6} className="mt-5 px-4" >
                            <Grid container spacing={2} direction="column">
                                <Grid item xs={12} className="text-center bg-info text-light" >
                                    <Typography variant='h6'> Return Payment Notes</Typography>
                                </Grid>
                                <Divider />
                            </Grid>
                        </Grid> */}
                    </Grid>
                </DialogContent>

            </Dialog>
        </div >
    )
}

const createOtherDetails = (label, value) => (
    <Grid item xs={6}>
        <Grid item xs={12} className="px-3 py-3">
            <Grid container >
                <Grid item xs={4}>

                    <span style={{ fontSize: "14px" }} > {label} </span>
                </Grid>

                <Grid item xs={8} >

                    <span style={{ fontSize: "14px" }} > {value} </span>
                </Grid>
            </Grid>

        </Grid>
        <Divider />
    </Grid>
)
