import React,{useState} from 'react'
import { useFormik } from "formik";
// import Toster from "./Toster";
import * as yup from "yup";
import { Avatar, Switch, Button, Grid, Dialog, DialogTitle, DialogContent, TextField, FormControl, InputLabel, Select, MenuItem, DialogActions } from "@material-ui/core";
const AddEditForm = (props) => {

    
  const [ fileData, setFileData ] = useState([])
  const formik = useFormik({
    initialValues: {
      user_name: props.userName ? props.userName :"" ,
      rating: props.rating? props.rating :"" ,
      feedback: props.feedback? props.feedback :"" ,
      image:"",
     
    
    },
    validationSchema: yup.object({
      user_name: yup.string().required("Required !"),
      rating: yup.number().max(5,"Rating can not be more than 5").min(1,"Rating can not be less than 1").required("Required !"),
      feedback: yup.string().required("Required !")

    }),
    onSubmit: values => {
      if(fileData.length>0){
        props.onClickHandler(values,fileData.length?fileData[0]:[])
      }else{
        if(props.image){
            values.image = props.image 
            props.onClickHandler(values,[])
        }
      }
      
    }

  })
  
  return (
    <>
      <div>
        <Dialog open={props.openForm} onClose={() => { props.setOpenForm(false) }}>
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>{props.formName}</DialogTitle>
            <DialogContent>
               <TextField
                name="user_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.user_name}
                // 
                margin="dense"
                id="user_name"
                label="User Name"
                type="text"
                fullWidth
                variant="outlined"
              />
              {/* {formik.touched?.user_name && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.user_name}
                </span>
              )} */}
               {formik.touched.user_name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.user_name}</span>}


              <TextField
                name="image"
                onChange={(e)=>{setFileData(e.target.files)}}
                onBlur={formik.handleBlur}
                //value={formik.values.image}
                // 
                margin="dense"
                id="image"
                // label="User Image"
                fullWidth
                variant="outlined"
                type="file"
              />
              {formik.touched.image &&!fileData.length ?
                <span style={{ color: "red", fontSize: "12px" }}>
                  Required !
                </span>:null}

              <TextField
                name="rating"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.rating}
                inputProps={{ min: 0, max: 10 }}
                // 
                margin="dense"
                id="rating"
                label="Rating"
                type="number"
                fullWidth
                variant="outlined"
                min={0}
                max={5}
              />
              {formik.touched.rating && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.rating}
                </span>
              )}

              <TextField
                name="feedback"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.feedback}
                // 
                margin="dense"
                id="feedback"
                label="Feedback"
                type="text"
                fullWidth
                variant="outlined"
                multiline
                minRows={3}
              />
              {formik.touched.feedback && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.feedback}
                </span>
              )}

            </DialogContent>
            <DialogActions>
              <Button onClick={() => { props.setOpenForm(false) }} variant="contained" color="secondary" >Cancel</Button>
              <Button variant="contained" color="primary" type="submit" >{props.btnName}</Button>
            </DialogActions>
          </form>
        </Dialog>

      </div>
    </>
  )
}
export default AddEditForm