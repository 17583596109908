import React from 'react';
import { DialogContent, Typography } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Grid, Button, Dialog, DialogActions, DialogTitle, Slide, Box, Paper } from '@material-ui/core'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmDialog(props) {
  return (
    <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogContent>
            <Grid item className='text-center '>
                <HighlightOffIcon style={{ fontSize: "75px", color: "red" }} />
            </Grid>

            <Grid item className='text-center text-secondary mt-4 px-5'>
                <Typography variant='h5'> Are You Sure ?</Typography>
            </Grid>

            {/* <Grid item className='text-center mt-2 mb-4 px-5'>
                <Typography variant='h6'>  Do You Really Want To Delete ?</Typography>
            </Grid> */}

        </DialogContent>
        <DialogActions>
            <Grid container spacing={1} >
                <Grid item xs={6}>
                    <Button variant='contained' color='primary' fullWidth onClick={()=>props.setOpen(false)}>Cancel</Button>

                </Grid>
                <Grid item xs={6}>
                    <Button variant='contained' color='secondary' fullWidth onClick={()=>props.onClickYes(props.functionData)}>Yes</Button>

                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>
  );
}
