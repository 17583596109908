import React, { useState } from "react";
import { TextField, Typography, Button, makeStyles } from "@material-ui/core";
import BackupIcon from "@material-ui/icons/Backup";
import { useFormik } from "formik";
import * as yup from "yup";

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: 400,
    height: 400,
  },
  table: {
    minWidth: 400,
  },
  tab: {
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 500,
  },
}));

export default function AddInstitute({ AddData, setDialog }) {
  const classes = useStyles();
  const [imageval, setImageVal] = useState("");
  const formik = useFormik({
    initialValues: {
      image: "",
      name: "",
      specialization: "",
      description: "",
      metaKeywords: "",
      metaDescription: "",
    },
    validationSchema: yup.object({
      name: yup.string().required("Required !"),
      image: yup.string().required("Required !"),
      description: yup.string().required("Required !"),
      specialization: yup.string().required("Required !"),
    }),
    onSubmit: (values) => {
      values.image = imageval;
      var form_data = new FormData();
      for (let key in values) {
        form_data.append(key, values[key]);
      }
      AddData(form_data);
    },
  });
  const dialogclose = () => {
    setDialog(false);
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Typography
          className={classes.title}
          variant="h6"
          color="primary"
          gutterBottom
        >
          Institute Details
        </Typography>
        <br />
        <Button
          onChange={formik.handleChange}
          variant="outlined"
          fullWidth={true}
          startIcon={<BackupIcon />}
          color="primary"
          component="label"
        >
          Upload Institute Image
          <input
            onChange={(e) => {
              setImageVal(e.target.files[0]);
            }}
            name="image"
            type="file"
            hidden
          />
        </Button>
        {formik.touched.image && (
          <span style={{ color: "red", fontSize: "12px" }}>
            {formik.errors.image}
          </span>
        )}

        <br />
        <br />
        <TextField
          name="name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          fullWidth={true}
          label="Institute Name"
          variant="outlined"
          size="small"
        />
        {formik.touched.name && (
          <span style={{ color: "red", fontSize: "12px" }}>
            {formik.errors.name}
          </span>
        )}
        <br />
        <br />
        <TextField
          name="specialization"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.specialization}
          label="Institute Specialization"
          fullWidth={true}
          multiline
          variant="outlined"
          size="medium"
        />
        {formik.touched.specialization && (
          <span style={{ color: "red", fontSize: "12px" }}>
            {formik.errors.specialization}
          </span>
        )}

        <br />
        <br />
        <TextField
          name="description"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.description}
          label="Institute Description"
          fullWidth={true}
          multiline
          variant="outlined"
          size="medium"
        />
        {formik.touched.description && (
          <span style={{ color: "red", fontSize: "12px" }}>
            {formik.errors.description}
          </span>
        )}
        <br />
        <br />
        <TextField
          name="metaKeywords"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.metaKeywords}
          label="Meta Keywords"
          fullWidth={true}
          multiline
          variant="outlined"
          size="medium"
        />
        {formik.touched.metaKeywords && (
          <span style={{ color: "red", fontSize: "12px" }}>
            {formik.errors.description}
          </span>
        )}
        <br />
        <br />
        <TextField
          name="metaDescription"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.metaDescription}
          label="Meta Description"
          fullWidth={true}
          multiline
          variant="outlined"
          size="medium"
        />
        {formik.touched.metaDescription && (
          <span style={{ color: "red", fontSize: "12px" }}>
            {formik.errors.description}
          </span>
        )}
        <br />
        <br />

        <Button onClick={dialogclose} variant="contained" color="secondary">
          Close
        </Button>
        <Button
          type="submit"
          style={{ marginLeft: 20 }}
          variant="contained"
          color="primary"
        >
          {" "}
          Save
        </Button>
      </form>
    </>
  );
}
