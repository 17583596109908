import { Form, Formik } from "formik";
import React from "react";
import ProductCartFilterDialog from "./ProductCartFilterDialog";

const ProductCartFilterDialogWrapper = ({ onClose, dateFilter , setDateFilter }) => {
  const initialValues = {
    startDate: dateFilter.startDate,
    endDate: dateFilter.endDate,
  };

  const handleSubmit = (values) => {
    setDateFilter(values)
   console.log(values ,"values")
   onClose()
  };
  const handleReset = (formikProps) => {
    setDateFilter({
        startDate :'',
        endDate:''
    });
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ProductCartFilterDialog
            onClose={onClose}
            formikProps={formikProps}
            onReset={() => handleReset(formikProps)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ProductCartFilterDialogWrapper;
