import AuthWrapper from "./AuthWrapper"

export const getFilterdColumns = (columns: any) => {
    if (typeof columns.authName === 'string') {

        return columns?.filter((column: any) => {

            return (
                column.authName === undefined ? true :
                    AuthWrapper({ type: 'ACTION', name: column.authName, inBoolean: true })
            )
        })
    }
    else {
        return columns?.filter((column: any) => {
            return column.authName === undefined ? true :
                column?.authName?.some((auth: any) => AuthWrapper({ type: 'ACTION', name: auth, inBoolean: true }))
        })

    }
}
