import React, { useState, useEffect } from "react";
import CreateTableAddButton from "./CreateTableAddButton";
import SkeletonListing from "./SkeletonListing";
import DataNotFound from "./DataNotFound";
import AddDataHeader from "./AddDataHeader";
import { Link } from "react-router-dom";
import { useGetOrderTransactionDataQuery } from "../../../services/orderTransactionService";
import moment from "moment";
import AuthWrapper from "../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../utils/getFilterFunction";

export default function OnlineOrderTransaction() {
  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    // { id: 'user_name', align: 'center', label: 'User Name', minWidth: 50 },
    { id: "order_id", align: "center", label: "Order Id", minWidth: 50 },
    { id: "payment_id", align: "center", label: "Payment Id", minWidth: 50 },
    { id: "amount", align: "center", label: "Amount (₹)", minWidth: 50 },
    { id: "status", align: "center", label: "Status", minWidth: 50 },
    {
      id: "payment_gateway",
      align: "center",
      label: "Payment Gateway",
      minWidth: 50,
    },
    { id: "type", align: "center", label: "Type", minWidth: 50 },
    { id: "createdAt", align: "center", label: "Date", minWidth: 50 },
  ];
  function createData(
    SNo,
    user_name,
    order_id,
    payment_id,
    amount,
    status,
    payment_gateway,
    type,
    createdAt
  ) {
    return {
      SNo,
      user_name,
      order_id,
      payment_id,
      amount,
      status,
      payment_gateway,
      type,
      createdAt,
    };
  }

  const [filteredPagination, setFilteredPagination] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [render, setRender] = useState(false);

  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: [
      "payment_id",
      "payment_gateway",
      "status",
      "user_first_name",
      "user_last_name",
      "user_email",
      "order_id",
      "type",
      "createdAt",
      "updatedAt",
    ],
    page: page + 1,
  };

  const [pagination, setPagination] = useState(paginationData);
  const {
    data: AllTransactions,
    isFetching: isAllTransactionFetching,
    isLoading: isAllTransactionLoading,
  } = useGetOrderTransactionDataQuery(pagination);
  useEffect(() => {
    setPagination(paginationData);
  }, [render, page, rowsPerPage, filteredPagination]);

  let rows = [];
  const totalItem = AllTransactions ? AllTransactions.totalItem : 0;

  let totalData = "ok";
  if (AllTransactions) {
    totalData = AllTransactions.totalItem;
    rows = AllTransactions.data.map((data, index) =>
      createData(
        index + 1,
        data.user_first_name + " " + data.user_last_name,
        <Link to={{ pathname: "/orders" }}>{data.order_id}</Link>,
        data.payment_id,
        <span className="text-success">{data.amount}</span>,
        data.status,
        data.payment_gateway,
        data.type,
        moment(data.createdAt).format("DD-MM-YYYY hh:mm A")
      )
    );
  }

  return (
    <div>
      <AddDataHeader
        setPage={setPage}
        page={page}
        excelBtn={false}
        totalItem={totalItem}
        isSearch={
          !AuthWrapper({
            type: "ACTION",
            name: "SUCCESSFULL_ORDERS_ORDER_TRANSACTIONS_LIST",
            inBoolean: true,
          })
        }
        isPagination={
          !AuthWrapper({
            type: "ACTION",
            name: "SUCCESSFULL_ORDERS_ORDER_TRANSACTIONS_LIST",
            inBoolean: true,
          })
        }
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchBarApi={setPagination}
        paginationData={{ ...pagination }}
        addBtn={true}
      />

      <>
        <AuthWrapper type="ACTION" name="SUCCESSFULL_ORDERS_ORDER_TRANSACTIONS_LIST">
          {rows.length ? (
            <CreateTableAddButton
              setPage={setPage}
              rows={rows}
              columns={getFilterdColumns(columns)}
              />
          ) : totalData == 0 ? (
            <DataNotFound />
          ) : (
            <SkeletonListing />
          )}
        </AuthWrapper>
      </>
    </div>
  );
}
