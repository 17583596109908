import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const facultyApi = createApi({
  tagTypes: ["faculty"],
  reducerPath: "facultyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/faculty/`,
  }),
  endpoints: (build) => ({
    getAallFacultieNames: build.query({
      providesTags: ["faculty"],
      query() {
        return {
          url: `v1/all-faculty-list`,
          headers: {
            "x-access-token": token,
          },
          method: "GET",
        };
      },
    }),

    addFaculty: build.mutation({
      invalidatesTags: ["faculty"],
      query(body) {
        return {
          url: `add-faculty`,
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),
    getAallFaculties: build.mutation({
      invalidatesTags: ["faculty"],
      query(body) {
        return {
          url: `all-faculties`,
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    deleteFaculty: build.mutation({
      invalidatesTags: ["faculty"],
      query(id) {
        return {
          url: `${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
    }),
    getSingleFaculty: build.mutation({
      invalidatesTags: ["faculty"],
      query(id) {
        return {
          url: `${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "GET",
        };
      },
    }),
    updateFaculty: build.mutation({
      invalidatesTags: ["faculty"],
      query(data) {
        const { id, ...body } = data;
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body: body.data,
        };
      },
    }),
  }),
});
export const {
  useAddFacultyMutation,
  useGetAallFacultiesMutation,
  useDeleteFacultyMutation,
  useGetSingleFacultyMutation,
  useUpdateFacultyMutation,
  useGetAallFacultieNamesQuery,
} = facultyApi;

export const instituteApi = createApi({
  tagTypes: ["institute"],
  reducerPath: "instituteApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/institute/`,
  }),
  endpoints: (builder) => ({
    getallInstituteNames: builder.query({
      query: () => ({
        url: `all-institute-name`,
        headers: {
          "x-access-token": token,
        },
        method: "GET",
      }),
      providesTags: ["institute"],
    }),

    addInstitutes: builder.mutation({
      invalidatesTags: ["institute"],
      query(body) {
        return {
          url: "add-institute",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),
    getallInstitutes: builder.mutation({
      invalidatesTags: ["institute"],
      query(body) {
        return {
          url: "all-institutes",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),
    deleteInstitute: builder.mutation({
      invalidatesTags: ["institute"],
      query(id) {
        return {
          url: `${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
    }),
    updateInstitute: builder.mutation({
      invalidatesTags: ["institute"],
      query(data) {
        const { id, ...body } = data;
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body: body.data,
        };
      },
    }),
  }),
});
export const {
  useGetallInstituteNamesQuery,
  useAddInstitutesMutation,
  useGetallInstitutesMutation,
  useDeleteInstituteMutation,
  useUpdateInstituteMutation,
} = instituteApi;

export const courseApi = createApi({
  tagTypes: ["Course"],
  reducerPath: "coursesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/course/`,
  }),
  endpoints: (builder) => ({
    getAllCourses: builder.query({
      query: () => ({
        url: `v1/all-courses`,
        headers: {
          "x-access-token": token,
        },
        method: "GET",
      }),
      providesTags: ["Course"],
    }),

    getAllCourseFilter: builder.mutation({
      query: (body) => ({
        url: "all-course-filter",
        method: "post",
        body,
        headers: {
          "x-access-token": token,
        },
      }),
      invalidatesTags: ["Course"],
    }),

    addCourse: builder.mutation({
      invalidatesTags: ["Course"],
      query(body) {
        return {
          url: "add-course",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    deleteCourse: builder.mutation({
      invalidatesTags: ["Course"],
      query(id) {
        return {
          url: `${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
    }),

    updateCourse: builder.mutation({
      invalidatesTags: ["Course"],
      query({ body, id }) {
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),

    getCourseById: builder.query({
      query(id) {
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "GET",
        };
      },
    }),

    getAllCourseWithLevel: builder.query({
      query() {
        return {
          url: "v1/all-main-courses",
          headers: {
            "x-access-token": token,
          },
          method: "GET",
        };
      },
    }),
  }),
});
export const {
  useGetAllCoursesQuery,
  useAddCourseMutation,
  useUpdateCourseMutation,
  useDeleteCourseMutation,
  useGetAllCourseFilterMutation,
  useGetCourseByIdQuery,
  useGetAllCourseWithLevelQuery,
} = courseApi;

export const courseLevelApi = createApi({
  tagTypes: ["courseLevel"],
  reducerPath: "CourseLevelApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/level/`,
  }),
  endpoints: (builder) => ({
    getAllCourseLevel: builder.query({
      query: (id) => ({
        url: `all-course-levels/${id}`,
        headers: {
          "x-access-token": token,
        },
        method: "GET",
      }),
      providesTags: ["courseLevel"],
    }),

    getAllLevelNames: builder.query({
      query: (id) => ({
        url: `v1/all-levels`,
        headers: {
          "x-access-token": token,
        },
        method: "GET",
      }),
      providesTags: ["courseLevel"],
    }),

    getAllLevelFilter: builder.mutation({
      query: (body) => ({
        url: "all-levels-filter",
        method: "post",
        body,
        headers: {
          "x-access-token": token,
        },
      }),
      invalidatesTags: ["courseLevel"],
    }),

    addCourseLevel: builder.mutation({
      invalidatesTags: ["courseLevel"],
      query(body) {
        return {
          url: "add-level",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),
    deleteCourseLevel: builder.mutation({
      invalidatesTags: ["courseLevel"],
      query(id) {
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
    }),
    updateCourseLevel: builder.mutation({
      invalidatesTags: ["courseLevel"],
      query({ body, id }) {
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),
  }),
});
export const {
  useGetAllCourseLevelQuery,
  useAddCourseLevelMutation,
  useDeleteCourseLevelMutation,
  useUpdateCourseLevelMutation,
  useGetAllLevelNamesQuery,
  useGetAllLevelFilterMutation,
} = courseLevelApi;

export const subjectApi = createApi({
  tagTypes: ["subjects"],
  reducerPath: "subjectApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/subject/`,
  }),
  endpoints: (builder) => ({
    getAllSubjects: builder.query({
      query: (id) => ({
        url: `all-level-subjects/${id}`,
        headers: {
          "x-access-token": token,
        },
        method: "GET",
      }),
      providesTags: ["subjects"],
    }),

    getAllSubjectsFilter: builder.mutation({
      query: (body) => ({
        url: "all-subjects-filter",
        method: "post",
        body,
        headers: {
          "x-access-token": token,
        },
      }),
      invalidatesTags: ["subjects"],
    }),

    addSubject: builder.mutation({
      invalidatesTags: ["subjects"],
      query(body) {
        return {
          url: "add-subject",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),
    deleteSubject: builder.mutation({
      invalidatesTags: ["subjects"],
      query(id) {
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
    }),
    updateSubject: builder.mutation({
      invalidatesTags: ["subjects"],
      query({ body, id }) {
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),
    getAllSubjectNames: builder.query({
      query: () => ({
        url: `v1/all-subjects`,
        headers: {
          "x-access-token": token,
        },
        method: "GET",
      }),
      providesTags: ["subjects"],
    }),
  }),
});

export const {
  useGetAllSubjectsQuery,
  useAddSubjectMutation,
  useGetAllSubjectsFilterMutation,
  useDeleteSubjectMutation,
  useUpdateSubjectMutation,
  useGetAllSubjectNamesQuery,
} = subjectApi;

export const modeApi = createApi({
  tagTypes: ["mode"],
  reducerPath: "mode",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/mode/`,
  }),
  endpoints: (builder) => ({
    getAllMode: builder.query({
      query: () => ({
        url: `all-modes`,
        headers: {
          "x-access-token": token,
        },
        method: "GET",
      }),
      providesTags: ["mode"],
    }),
    getAllModePagination: builder.mutation({
      query: (body) => ({
        url: `all-modes-pagination`,
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
      providesTags: ["mode"],
    }),
    deleteModes: builder.mutation({
      query: (id) => ({
        url: id,
        method: "DELETE",
        headers: {
          "x-access-token": token,
        },
      }),
      invalidatesTags: ["mode"],
    }),

    addModes: builder.mutation({
      query: (body) => ({
        url: "add-mode",
        method: "post",
        body,
        headers: {
          "x-access-token": token,
        },
      }),
      invalidatesTags: ["mode"],
    }),

    updateModes: builder.mutation({
      query: ({ body, id }) => ({
        url: id,
        method: "PUT",
        body,
        headers: {
          "x-access-token": token,
        },
      }),
      invalidatesTags: ["mode"],
    }),
  }),
});
export const {
  useGetAllModePaginationMutation,
  useGetAllModeQuery,
  useAddModesMutation,
  useDeleteModesMutation,
  useUpdateModesMutation,
} = modeApi;

export const moduleApi = createApi({
  tagTypes: ["modules"],
  reducerPath: "module",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/`,
  }),
  endpoints: (builder) => ({
    getAllModuleName: builder.query({
      query: () => ({
        url: "module/all-module-name",
        headers: {
          "x-access-token": token,
        },
        method: "GET",
      }),
      providesTags: ["mode"],
    }),
  }),
});

export const { useGetAllModuleNameQuery } = moduleApi;

export const dashboardApi = createApi({
  tagTypes: ["dashboard"],
  reducerPath: "dashboard",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/`,
  }),
  endpoints: (builder) => ({
    getAllDashboardData: builder.mutation({
      query: (body) => ({
        url: "dash",
        body,
        headers: {
          "x-access-token": token,
        },
        method: "POST",
      }),
      providesTags: ["dashboard"],
    }),
  }),
});

export const { useGetAllDashboardDataMutation } = dashboardApi;

export const categoryApi = createApi({
  tagTypes: ["category"],
  reducerPath: "categoryapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/category/`,
  }),
  endpoints: (builder) => ({
    getAllCategories: builder.query({
      query: () => ({
        url: "v1/all-categories",
        headers: {
          "x-access-token": token,
        },
        method: "GET",
      }),
      providesTags: ["category"],
    }),

    getAllCategoryPagination: builder.mutation({
      query: (body) => ({
        url: "all-categories",
        method: "post",
        body,
        headers: {
          "x-access-token": token,
        },
      }),
      invalidatesTags: ["category"],
    }),

    addCategory: builder.mutation({
      query: (body) => ({
        url: "add-category",
        method: "post",
        body,
        headers: {
          "x-access-token": token,
        },
      }),
      invalidatesTags: ["category"],
    }),

    deleteCategory: builder.mutation({
      query: (id) => ({
        url: id,
        method: "DELETE",
        headers: {
          "x-access-token": token,
        },
      }),
      invalidatesTags: ["category"],
    }),

    updateCategory: builder.mutation({
      query({ body, id }) {
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),

    updateCategoryStatus: builder.mutation({
      query(id) {
        return {
          url: `change-status/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
        };
      },
    }),
  }),
});
export const {
  useGetAllCategoriesQuery,
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
  useGetAllCategoryPaginationMutation,
  useUpdateCategoryStatusMutation,
} = categoryApi;

export const LoginApi = createApi({
  tagTypes: ["login"],
  reducerPath: "loginapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
  }),
  endpoints: (builder) => ({
    userLogin: builder.mutation({
      query: (body) => ({
        url: "sld-user/login",
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),
    userGroup: builder.mutation({
      query: (accToken) => ({
        url: "/user-group/get-access-modules/ADMIN",
        headers: {
          "x-access-token": accToken,
        },
        method: "GET",
      }),
    }),
  }),
});

export const { useUserLoginMutation, useUserGroupMutation } = LoginApi;

export const videoLectureApi = createApi({
  tagTypes: ["videoLecture", "single-video-lecture"],
  reducerPath: "videoLectureApi",
  baseQuery: retry(fetchBaseQuery({ baseUrl: `${BASE_URL}/video-lecture/` }), {
    maxRetries: 0,
  }),
  endpoints: (builder) => ({
    getVideoLectureProduct: builder.query({
      query: () => ({
        url: "all-video-lecture-listing",
        headers: {
          "x-access-token": token,
        },
        method: "GET",
      }),
      providesTags: ["videoLecture"],
    }),

    getAllVideoLecture: builder.mutation({
      // invalidatesTags: ["videoLecture"],
      query(body) {
        return {
          url: "all-video-lectures-filter",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    addVideoLecture: builder.mutation({
      invalidatesTags: ["videoLecture"],
      query(body) {
        return {
          url: "add-video-lecture",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    deletVideoLecture: builder.mutation({
      invalidatesTags: ["videoLecture"],
      query(id) {
        return {
          url: `${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
    }),

    updateVideoLecture: builder.mutation({
      invalidatesTags: ["videoLecture"],
      query(data) {
        var id = "";
        var x = {};
        for (var key of data.entries()) {
          if (key[0] == "id") {
            id = key[1];
          }
        }
        data.delete("id");
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body: data,
        };
      },
    }),

    changeLectureStatus: builder.mutation({
      invalidatesTags: ["videoLecture"],
      query({ varient_id, varient_code, id }) {
        return {
          url: `change-status/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body: { varient_id, varient_code },
        };
      },
    }),
    getVideoLectureProducts: builder.query({
      invalidatesTags: ["videoLecture"],
      query() {
        return {
          url: "v1/all-video-lectures",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
        };
      },
    }),
    getSingleVideoLecture: builder.query({
      providesTags: ["single-video-lecture"],
      query(id) {
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "GET",
        };
      },
    }),
    getVideoLectureVarient: builder.query({
      invalidatesTags: ["videoLecture"],
      query(id) {
        return {
          url: "video-lecture-varients/" + id,
          headers: {
            "x-access-token": token,
          },
          method: "GET",
        };
      },
    }),

    updateVideoLectureSingleField: builder.mutation({
      invalidatesTags: ["single-video-lecture"],
      query({ body, id }) {
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body: body,
        };
      },
    }),

    updateVideoLectureSingleVarientField: builder.mutation({
      invalidatesTags: ["single-video-lecture"],
      query({ body, id }) {
        return {
          url: `update-varient/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body: body,
        };
      },
    }),

    updateVideoLectureVarientPrices: builder.mutation({
      invalidatesTags: ["video-lecture-prices"],
      query({ body, id }) {
        return {
          url: `update-varient-prices/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body: body,
        };
      },
    }),

    getVideoLectureSubjectAndFaculty: builder.mutation({
      invalidatesTags: ["videoLecture"],
      query(body) {
        return {
          url: "videolecture-data-for-filter",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    getVideoLectureExcelSheetColumn: builder.query({
      //invalidatesTags: ["videoLecture"],
      query() {
        return {
          url: "get-excel-column-sheet",
          headers: {
            "x-access-token": token,
          },
          method: "GET",
        };
      },
    }),
  }),
});
export const {
  useAddVideoLectureMutation,
  useGetAllVideoLectureMutation,
  useGetVideoLectureProductsQuery,
  useDeletVideoLectureMutation,
  useUpdateVideoLectureMutation,
  useUpdateVideoLectureSingleVarientFieldMutation,
  useUpdateVideoLectureVarientPricesMutation,
  useChangeLectureStatusMutation,
  useGetVideoLectureProductQuery,
  useGetSingleVideoLectureQuery,
  useGetVideoLectureVarientQuery,
  useUpdateVideoLectureSingleFieldMutation,
  useGetVideoLectureSubjectAndFacultyMutation,
  useGetVideoLectureExcelSheetColumnQuery,
} = videoLectureApi;

export const usersApi = createApi({
  tagTypes: ["user", "order"],
  reducerPath: "usersapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/`,
  }),
  endpoints: (builder) => ({
    getAllUserGroupNames: builder.query({
      query: () => ({
        url: `user-group/all-user-groups-name`,
        headers: {
          "x-access-token": token,
        },
        method: "GET",
      }),
      providesTags: ["user"],
    }),

    getAllUserGroup: builder.mutation({
      query: (body) => ({
        url: `user-group/all-user-groups`,
        headers: { "x-access-token": token },
        method: "POST",
        body,
      }),
      providesTags: ["user"],
    }),

    addUserGroup: builder.mutation({
      invalidatesTags: ["user"],
      query(body) {
        return {
          url: "user-group/add-user-group",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    updateUserType: builder.mutation({
      invalidatesTags: ["user"],
      query({ body, id }) {
        return {
          url: `user/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),

    deletUserGroup: builder.mutation({
      query(id) {
        return {
          url: `user-group/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
      invalidatesTags: ["user"],
    }),

    updateUserGroup: builder.mutation({
      query({ body, id }) {
        return {
          url: `user-group/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["user"],
    }),

    getUserGroupById: builder.query({
      query(id) {
        return {
          url: `user-group/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "GET",
        };
      },
      providesTags: ["user"],
    }),

    getAllSldUser: builder.mutation({
      query(body) {
        return {
          url: "sld-user/all-sld-users",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
      providesTags: ["user"],
    }),

    getAllSldUsersList: builder.query({
      query() {
        return {
          url: "sld-user/",
          headers: {
            "x-access-token": token,
          },
          method: "GET",
        };
      },
    }),

    getAllCallers: builder.query({
      query(body) {
        return {
          url: "sld-user/caller-list/",
          headers: {
            "x-access-token": token,
          },
          method: "GET",
        };
      },
      providesTags: ["user"],
    }),

    addSldUser: builder.mutation({
      query(body) {
        return {
          url: "sld-user/add-sld-user",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["user"],
    }),

    deletSldUser: builder.mutation({
      query(id) {
        return {
          url: `sld-user/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
      invalidatesTags: ["user"],
    }),

    updateSldUser: builder.mutation({
      query({ body, id }) {
        return {
          url: `sld-user/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["user"],
    }),

    sldUserChangePassword: builder.mutation({
      query(body) {
        return {
          url: `sld-user/change-password`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),
    sldUserResetPasswordByAdmin: builder.mutation({
      query(body) {
        return {
          url: `sld-user/reset-slduser-password`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),
    getUsersListing: builder.query({
      query: () => ({
        url: `user/all-user-listing`,
        headers: {
          "x-access-token": token,
        },
        method: "GET",
      }),
      providesTags: ["user"],
    }),

    getAllUsers: builder.query({
      query(body) {
        return {
          url: "user/all-users",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
      providesTags: ["order", "user"],
    }),

    addUser: builder.mutation({
      invalidatesTags: ["user"],
      query(body) {
        return {
          url: "user/add-user",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    getUserById: builder.query({
      query(id) {
        return {
          url: `user/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "GET",
        };
      },
      providesTags: ["user"],
    }),
  }),
});
export const {
  useGetAllUsersQuery,
  useAddUserMutation,
  useUpdateUserTypeMutation,
  useGetUserByIdQuery,

  useGetAllUserGroupMutation,
  useGetAllUserGroupNamesQuery,
  useAddUserGroupMutation,
  useGetUsersListingQuery,
  useDeletUserGroupMutation,
  useUpdateUserGroupMutation,
  useGetUserGroupByIdQuery,

  useGetAllSldUserMutation,
  useGetAllSldUsersListQuery,
  useAddSldUserMutation,
  useDeletSldUserMutation,
  useUpdateSldUserMutation,
  useSldUserChangePasswordMutation,
  useSldUserResetPasswordByAdminMutation,

  useGetAllCallersQuery,
} = usersApi;

// export const orderApi = createApi({
//   tagTypes: ["order", "user"],
//   reducerPath: "orderapi",
//   baseQuery: retry(
//     fetchBaseQuery({ baseUrl: `${BASE_URL}/order/` }),
//     {
//       maxRetries: 0,
//     }
//   ),
//   endpoints: (builder) => ({
//     getAllOrdersVideoLectures: builder.query({
//       providesTags: ["order"],

//       query: (body) => ({
//         url: "all-orders-video-lecture",
//         headers: {
//           "x-access-token": token,
//         },
//         method: "POST",
//         body,
//       }),

//     }),

//     getAllOrdersBookProducts: builder.mutation({
//       providesTags: ["orderbook"],

//       query: (body) => ({
//         url: "all-orders-book-product",
//         headers: {
//           "x-access-token": token,
//         },
//         method: "POST",
//         body,
//       }),
//     }),
//     getAllUserOrder: builder.query({
//       query: (id) => ({

//         url: `users-orders/${id}`,
//         headers: {
//           "x-access-token": token,
//         },
//         method: "GET",
//       }),
//       providesTags: ["user"],
//     }),

//     OrderStatus: builder.mutation({
//       query: (body) => ({
//         url: `update-order-status`,
//         headers: {
//           "x-access-token": token,
//         },
//         method: "POST",
//         body,
//       }),
//       invalidatesTags: ["order", "user"]
//     }),
//     getSalesReoprt: builder.mutation({
//       query: (body) => ({
//         url: `sales-report-faculty-wise`,
//         headers: {
//           "x-access-token": token,
//         },
//         method: "POST",
//         body,
//       }),
//     }),
//     getOrderSldId: builder.mutation({
//       query: (id) => ({
//         url: `orders-in-sld-order/${id}`,
//         headers: {
//           "x-access-token": token,
//         },
//         method: "GET",
//       }),
//     }),
//     updateCancelOrder: builder.mutation({
//       invalidatesTags: ["order", "user"],
//       query: (data) => ({
//         url: `cancel-order/${data.id}`,
//         headers: {
//           "x-access-token": token,
//         },
//         method: "Put",
//         body: data.remark
//       }),
//     }),
//     updateModeOrder: builder.mutation({
//       invalidatesTags: ["order"],

//       query: (data) => ({
//         url: `update-order-admin/${data.id}`,
//         headers: {
//           "x-access-token": token,
//         },
//         method: "Put",
//         body: { ...data.body },
//       }),
//     }),

//     updateRemarkOrder: builder.mutation({
//       invalidatesTags: ["order", "user"],

//       query: (data) => ({
//         url: `add-remark-admin/${data.id}`,
//         headers: {
//           "x-access-token": token,
//         },
//         method: "Put",
//         body: data.remark,
//       }),
//     }),
//     sendInvoice: builder.mutation({
//       invalidatesTags: ["order"],

//       query: (data) => ({
//         url: `send-invoice`,
//         headers: {
//           "x-access-token": token,
//         },
//         method: "post",
//         body: data,
//       }),
//     }),
//     UpdateCashbackRefundRequest: builder.mutation({
//       invalidatesTags: ["order", "user"],
//       query: ({ body, id }) => {
//         return {
//           url: `initiate-refund-cashback/${id}`,
//           headers: {
//             "x-access-token": token,
//           },
//           method: "PUT",
//           body,
//         };
//       },
//     }),
//   }),
// });
// export const {
//   useGetAllOrdersVideoLecturesQuery,
//   useUpdateRemarkOrderMutation,
//   useGetAllOrdersBookProductsMutation,
//   useOrderStatusMutation,
//   useGetAllUserOrderQuery,
//   useGetSalesReoprtMutation,
//   useGetOrderSldIdMutation,
//   useUpdateCancelOrderMutation,
//   useUpdateModeOrderMutation,
//   useSendInvoiceMutation,
//   useUpdateCashbackRefundRequestMutation
// } = orderApi;

export const orderApi = createApi({
  tagTypes: ["order", "user"],
  reducerPath: "orderapi",
  baseQuery: retry(fetchBaseQuery({ baseUrl: `${BASE_URL}/` }), {
    maxRetries: 0,
  }),
  endpoints: (builder) => ({
    getAllOrdersVideoLectures: builder.query({
      providesTags: ["order"],

      query: (body) => ({
        url: "order/all-orders-video-lecture",
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),

    getAllOrdersBookProducts: builder.mutation({
      providesTags: ["orderbook"],

      query: (body) => ({
        url: "order/all-orders-book-product",
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),
    updateOrderRequestFlage: builder.mutation({
      invalidatesTags: ["order"],
      query(body) {
        return {
          url: `order-request/show-hide-apply-for-order-request-btn-at-web`,
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),
    getAllUserOrder: builder.query({
      query: (id) => ({
        url: `order/users-orders/${id}`,
        headers: {
          "x-access-token": token,
        },
        method: "GET",
      }),
      providesTags: ["user"],
    }),

    OrderStatus: builder.mutation({
      query: (body) => ({
        url: `order/update-order-status`,
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
      invalidatesTags: ["order", "user"],
    }),
    getSalesReoprt: builder.mutation({
      query: (body) => ({
        url: `order/sales-report-faculty-wise`,
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),
    getOrderSldId: builder.mutation({
      query: (id) => ({
        url: `order/orders-in-sld-order/${id}`,
        headers: {
          "x-access-token": token,
        },
        method: "GET",
      }),
    }),
    updateCancelOrder: builder.mutation({
      invalidatesTags: ["order", "user"],
      query: (data) => ({
        url: `order/cancel-order/${data.id}`,
        headers: {
          "x-access-token": token,
        },
        method: "Put",
        body: data.remark,
      }),
    }),
    updateModeOrder: builder.mutation({
      invalidatesTags: ["order"],

      query: (data) => ({
        url: `order/update-order-admin/${data.id}`,
        headers: {
          "x-access-token": token,
        },
        method: "Put",
        body: { ...data.body },
      }),
    }),

    updateRemarkOrder: builder.mutation({
      invalidatesTags: ["order", "user"],

      query: (data) => ({
        url: `order/add-remark-admin/${data.id}`,
        headers: {
          "x-access-token": token,
        },
        method: "Put",
        body: data.remark,
      }),
    }),
    sendInvoice: builder.mutation({
      invalidatesTags: ["order"],

      query: (data) => ({
        url: `order/send-invoice`,
        headers: {
          "x-access-token": token,
        },
        method: "post",
        body: data,
      }),
    }),
    UpdateCashbackRefundRequest: builder.mutation({
      invalidatesTags: ["order", "user"],
      query: ({ body, id }) => {
        return {
          url: `order/initiate-refund-cashback/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),

    alreadyPurchasedDownload: builder.mutation({
      invalidatesTags: ["order"],
      query: (orderId) => ({
        url: `order/download-receipt/${orderId}`,
        headers: { "x-access-token": token || "" },
        method: "GET",
      }),
    }),
  }),
});
export const {
  useGetAllOrdersVideoLecturesQuery,
  useUpdateRemarkOrderMutation,
  useGetAllOrdersBookProductsMutation,
  useOrderStatusMutation,
  useGetAllUserOrderQuery,
  useGetSalesReoprtMutation,
  useGetOrderSldIdMutation,
  useUpdateCancelOrderMutation,
  useUpdateModeOrderMutation,
  useSendInvoiceMutation,
  useUpdateCashbackRefundRequestMutation,
  useUpdateOrderRequestFlageMutation,
  useAlreadyPurchasedDownloadMutation,
} = orderApi;

export const booksApi = createApi({
  tagTypes: ["booksStock"],
  reducerPath: "booksapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/`,
  }),
  endpoints: (builder) => ({
    getAllTestSereis: builder.mutation({
      invalidatesTags: ["booksStock"],
      query: (body) => ({
        url: `test-series/all-filter`,
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),

    getAllBooks: builder.mutation({
      invalidatesTags: ["booksStock"],
      query: (body) => ({
        url: `book-stock/all-books-filter`,
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),

    getAllBooksName: builder.query({
      query: () => ({
        url: "book-stock/all-books-listing",
        headers: {
          "x-access-token": token,
        },
        method: "GET",
      }),
      providesTags: ["booksStock"],
    }),

    addBooks: builder.mutation({
      invalidatesTags: ["booksStock"],
      query: (body) => ({
        url: "book-stock/add-book",
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),
    updateBooks: builder.mutation({
      invalidatesTags: ["booksStock"],
      query({ body, id }) {
        return {
          url: `book-stock/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),

    deletBook: builder.mutation({
      invalidatesTags: ["booksStock"],
      query(id) {
        return {
          url: `book-stock/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
    }),

    getAllBookProduct: builder.mutation({
      invalidatesTags: ["booksStock"],
      query: (body) => ({
        url: `book-product/all-book-products-filter`,
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),

    // "filter"
    getAllBookProductFilter: builder.mutation({
      invalidatesTags: ["booksStock"],
      query: (body) => ({
        url: `book-product/all-filter`,
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),

    getAllVideoLectureProductFilter: builder.mutation({
      invalidatesTags: ["booksStock"],
      query: (body) => ({
        url: `video-lecture/all-video-lectures-filter`,
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),

    getAllVideoLectureProductFilterNames: builder.mutation({
      invalidatesTags: ["booksStock"],
      query: (body) => ({
        url: `video-lecture/lectures-paginated-list`,
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),

    getAllProducts: builder.query({
      query: () => ({
        url: "book-product/book-products-listing",
        headers: {
          "x-access-token": token,
        },
        method: "GET",
      }),
      providesTags: ["booksStock"],
    }),

    addBookProducts: builder.mutation({
      invalidatesTags: ["booksStock"],
      query: (body) => ({
        url: "book-product/add-book-product",
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),

    updateBookProduct: builder.mutation({
      invalidatesTags: ["booksStock"],
      query({ body, id }) {
        return {
          url: `book-product/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),

    deletBookProduct: builder.mutation({
      invalidatesTags: ["booksStock"],
      query(id) {
        return {
          url: `book-product/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
    }),
  }),
});
export const {
  useAddBooksMutation,
  useGetAllBooksMutation,
  useUpdateBooksMutation,
  useDeletBookMutation,
  useGetAllProductsQuery,
  useGetAllBookProductFilterMutation,
  useGetAllVideoLectureProductFilterMutation,
  useGetAllVideoLectureProductFilterNamesMutation,
  useGetAllBookProductMutation,
  useAddBookProductsMutation,
  useUpdateBookProductMutation,
  useDeletBookProductMutation,
  useGetAllBooksNameQuery,
  useGetAllTestSereisMutation,
} = booksApi;

export const couponApi = createApi({
  tagTypes: ["coupon"],
  reducerPath: "couponapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/coupon/`,
  }),
  endpoints: (builder) => ({
    addCoupon: builder.mutation({
      invalidatesTags: ["couponapi"],
      query: (body) => ({
        url: "add-coupon",
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),

    getAllCoupon: builder.mutation({
      invalidatesTags: ["couponapi"],
      query: (body) => ({
        url: `all-coupons`,
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),
    getCouponByid: builder.query({
      invalidatesTags: ["couponapi"],
      query: (id) => ({
        url: `${id}`,
        headers: {
          "x-access-token": token,
        },
        method: "GET",
      }),
    }),
    deletCoupon: builder.mutation({
      invalidatesTags: ["couponapi"],
      query(id) {
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
    }),
    updateCoupon: builder.mutation({
      invalidatesTags: ["couponapi"],
      query({ body, id }) {
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),
  }),
});
export const {
  useAddCouponMutation,
  useGetAllCouponMutation,
  useDeletCouponMutation,
  useUpdateCouponMutation,
  useGetCouponByidQuery,
} = couponApi;

export const InquiryApi = createApi({
  tagTypes: ["Inquiry"],
  reducerPath: "Inquiryapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/enquiry/`,
  }),
  endpoints: (builder) => ({
    getContactUs: builder.mutation({
      invalidatesTags: ["Inquiryapi"],
      query: (body) => ({
        url: `all-contact-us`,
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),

    getAllInquiry: builder.mutation({
      invalidatesTags: ["Inquiryapi"],
      query: (body) => ({
        url: `all-call-back-request`,
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),
    addCallBackReq: builder.mutation({
      invalidatesTags: ["Inquiryapi"],
      query: (body) => ({
        url: "add-call-back-request",
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),
    deletInquiry: builder.mutation({
      invalidatesTags: ["Inquiryapi"],
      query(id) {
        return {
          url: `delete-enquiry/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
    }),
    updateInquiry: builder.mutation({
      invalidatesTags: ["Inquiryapi"],
      query({ body, id }) {
        return {
          url: `add-follow-up/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),
  }),
});
export const {
  useGetAllInquiryMutation,
  useUpdateInquiryMutation,
  useDeletInquiryMutation,
  useGetContactUsMutation,
  useAddCallBackReqMutation,
} = InquiryApi;

export const StatesApi = createApi({
  // tagTypes: ["state"],
  reducerPath: "stateapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/state/`,
  }),
  endpoints: (builder) => ({
    getAllStates: builder.query({
      query() {
        return {
          url: "v1/all-states",
          headers: {
            "x-access-token": token,
          },
          method: "GET",
        };
      },
    }),
  }),
});
export const { useGetAllStatesQuery } = StatesApi;

export const scratchApi = createApi({
  tagTypes: ["scratch"],
  reducerPath: "scratchapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/scratch-card`,
  }),
  endpoints: (builder) => ({
    addScratchCard: builder.mutation({
      invalidatesTags: ["scratch"],
      query(body) {
        return {
          url: "add-scratch-card",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    getAllScratchCards: builder.mutation({
      invalidatesTags: ["scratch"],
      query(body) {
        return {
          url: "all-scratch-card",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    deletScratchCard: builder.mutation({
      invalidatesTags: ["scratch"],
      query(id) {
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
    }),
  }),
});
export const {
  useAddScratchCardMutation,
  useGetAllScratchCardsMutation,
  useDeletScratchCardMutation,
} = scratchApi;

// export const cmsApi = createApi({
//   tagTypes: ["cms"],
//   reducerPath: 'cmsapi',
//   baseQuery: fetchBaseQuery({
//     baseUrl: `${BASE_URL}/cms/`
//   }),
//   endpoints: (builder) => (
//     {
//       GetCms: builder.query({
//         providesTags: ['cms'],
//         query: (body) => {
//           return {
//             url: "get-cms",
//             headers: {
//               "x-access-token": token
//             },
//             method: "POST",
//             body: {
//               key: "main_slider"
//             }
//           }
//         }
//       }),
//       AddCms: builder.mutation({
//         query: (body) => {
//           return {
//             url: "add-cms",
//             headers: {
//               "x-access-token": token
//             },
//             method: "POST",
//             body
//           }
//         }
//       }),
//       UpdateCms: builder.mutation({
//         query: (body) => {
//           return {
//             url: "change-show-status",
//             headers: {
//               "x-access-token": token
//             },
//             method: "PUT",
//             body
//           }
//         }
//       }),
//       UpdateSliderImageStatus: builder.mutation({
//         invalidatesTags: ['cms'],
//         query: (body) => {
//           return {
//             url: "change-slider-image-status",
//             headers: {
//               "x-access-token": token
//             },
//             method: "PUT",
//             body
//           }
//         }
//       }),
//     }
//   )
// })

// export const {
//   useAddCmsMutation,
//   useGetCmsMutation,
//   useGetCmsQuery,
//   useUpdateCmsStatusMutation,
//   useUpdateSliderImageStatusMutation
// } = cmsApi

export const socialApi = createApi({
  tagTypes: ["social"],
  reducerPath: "socialapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/social-media`,
  }),
  endpoints: (builder) => ({
    getAllSocialNetworks: builder.query({
      query() {
        return {
          url: "all-social-media",
          headers: {
            "x-access-token": token,
          },
          method: "GET",
        };
      },
      providesTags: ["social"],
    }),

    addSocialNetwork: builder.mutation({
      invalidatesTags: ["social"],
      query(body) {
        return {
          url: "add-social-network",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),
    deleteSocialNetwork: builder.mutation({
      invalidatesTags: ["social"],
      query(id) {
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
    }),

    updateSocialNetwork: builder.mutation({
      invalidatesTags: ["social"],
      query({ data, id }) {
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body: data,
        };
      },
    }),
  }),
});
export const {
  useGetAllSocialNetworksQuery,
  useAddSocialNetworkMutation,
  useDeleteSocialNetworkMutation,
  useUpdateSocialNetworkMutation,
} = socialApi;

export const reviewApi = createApi({
  tagTypes: ["review"],
  reducerPath: "reviewapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/review`,
  }),
  endpoints: (builder) => ({
    getAllReviews: builder.mutation({
      query(body) {
        return {
          url: "all-reviews",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
      providesTags: ["review"],
    }),

    updateReviews: builder.mutation({
      invalidatesTags: ["review"],
      query(data) {
        return {
          url: data.id,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body: {
            approval_action: data.approval_action,
            varify_status: data.varify_status,
          },
        };
      },
    }),
  }),
});
export const { useGetAllReviewsMutation, useUpdateReviewsMutation } = reviewApi;

export const quoteApi = createApi({
  tagTypes: ["quotes"],
  reducerPath: "quoteApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/quote-your-price`,
  }),
  endpoints: (builder) => ({
    getAllQuotes: builder.mutation({
      query(body) {
        return {
          url: "all-quoted-request",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
      providesTags: ["quotes"],
    }),

    deleteQuote: builder.mutation({
      invalidatesTags: ["quotes"],
      query(id) {
        return {
          url: `${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
    }),
    updateQuote: builder.mutation({
      invalidatesTags: ["quotes"],
      query({ body, id }) {
        return {
          url: `${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body: body,
        };
      },
    }),
  }),
});
export const {
  useGetAllQuotesMutation,
  useDeleteQuoteMutation,
  useUpdateQuoteMutation,
} = quoteApi;

export const courierApi = createApi({
  tagTypes: ["courier"],
  reducerPath: "courierApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/tracking-details`,
  }),
  endpoints: (builder) => ({
    addCourie: builder.mutation({
      query(body) {
        return {
          url: "add-tracking-details",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
      providesTags: ["courier"],
    }),

    deleteCourier: builder.mutation({
      invalidatesTags: ["courier"],
      query(id) {
        return {
          url: `${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
    }),
    updateCourier: builder.mutation({
      invalidatesTags: ["courier"],
      query({ data, id }) {
        return {
          url: `${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body: data,
        };
      },
    }),
    getAllCourierDetails: builder.mutation({
      invalidatesTags: ["courier"],
      query(body) {
        return {
          url: "all-tracking-details",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useAddCourieMutation,
  useGetAllCourierDetailsMutation,
  useUpdateCourierMutation,
  useDeleteCourierMutation,
} = courierApi;

export const expenseApi = createApi({
  tagTypes: ["expense", "expenseStatement"],
  reducerPath: "expenseApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/` }),
  endpoints: (build) => ({
    getAllExpenseCategory: build.query({
      query() {
        return {
          url: `expense-category/get-all`,
          headers: {
            "x-access-token": token,
          },
          method: "GET",
        };
      },
      providesTags: ["expense"],
    }),

    getExpenseById: build.query({
      query({ body, id }) {
        return {
          url: `expense-category/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "GET",
          body,
        };
      },
    }),

    updateExpenseCategory: build.mutation({
      invalidatesTags: ["expense"],
      query({ body, id }) {
        return {
          url: `expense-category/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),
    addExpenseCategory: build.mutation({
      invalidatesTags: ["expense"],
      query(body) {
        return {
          url: `expense-category/add-expense-category`,
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),
    deleteExpenseCategory: build.mutation({
      invalidatesTags: ["expense"],
      query(id) {
        return {
          url: `expense-category/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
    }),

    getAllExpenseDetails: build.mutation({
      query(body) {
        return {
          url: `expense-register/get-all-expenses`,
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
      providesTags: ["expenseStatement"],
    }),

    addExpenseDetails: build.mutation({
      invalidatesTags: ["expenseStatement"],
      query(body) {
        return {
          url: `expense-register/add-expense`,
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    updateExpenseDetails: build.mutation({
      invalidatesTags: ["expenseStatement"],
      query({ body, id }) {
        return {
          url: `expense-register/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),

    deleteExpenseDetails: build.mutation({
      invalidatesTags: ["expenseStatement"],
      query(id) {
        return {
          url: `expense-register/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
    }),

    getSingleExpenseDetails: build.query({
      // invalidatesTags: ["expenseStatement"],
      query(id) {
        return {
          url: `expense-register/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetAllExpenseCategoryQuery,
  useGetExpenseByIdQuery,
  useAddExpenseCategoryMutation,
  useUpdateExpenseCategoryMutation,
  useDeleteExpenseCategoryMutation,

  useGetAllExpenseDetailsMutation,
  useAddExpenseDetailsMutation,
  useUpdateExpenseDetailsMutation,
  useDeleteExpenseDetailsMutation,
  useGetSingleExpenseDetailsQuery,
} = expenseApi;

export const OrderExcelApi = createApi({
  tagTypes: ["orderExcel"],
  reducerPath: "orderExcelapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/`,
  }),
  endpoints: (builder) => ({
    downloadOrderExcel: builder.query({
      query(body) {
        return {
          url: "readExcel/order-excel",
          headers: {
            "x-access-token": token,
          },
          method: "GET",
          body,
        };
      },
      providesTags: ["orderExcel"],
    }),
  }),
});
export const { useDownloadOrderExcelQuery } = OrderExcelApi;

// >>>>>>>>>>>>>>>>>>>> WALLET API <<<<<<<<<<<<<<<<<<<<<<

export const walletApi = createApi({
  // tagTypes: ["wallet"],
  reducerPath: "walletApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/wallet-transaction/`,
  }),
  endpoints: (builder) => ({
    getUserWallet: builder.query({
      // providesTags: ["wallet"],
      query: (body) => {
        return {
          url: "users-transactions",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    getAllUsersWalletTransactions: builder.query({
      // providesTags: ["wallet"],
      query: (body) => {
        return {
          url: "all-wallet-transactions",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const { useGetUserWalletQuery, useGetAllUsersWalletTransactionsQuery } =
  walletApi;

export const FacultyPaymentApi = createApi({
  tagTypes: ["Payment"],
  reducerPath: "Payment",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/`,
  }),
  endpoints: (builder) => ({
    addFacultyPayment: builder.mutation({
      query(body) {
        return {
          url: "faculty-payment/add-new-payment",
          headers: {
            "x-access-token": token,
          },
          method: "Post",
          body,
        };
      },
      providesTags: ["Payment"],
    }),
  }),
});
export const { useAddFacultyPaymentMutation } = FacultyPaymentApi;

//>>>>>>>>>>>>>>>>>>>>>> hidden link api <<<<<<<<<<<<<<<<<<<<<<<<<<<

export const hiddenLinkApi = createApi({
  tagTypes: ["Hlink"],
  reducerPath: "hiddenLink",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/hidden-link/`,
  }),
  endpoints: (builder) => ({
    getUserHiddenEmail: builder.query({
      providesTags: ["Hlink"],
      query(body) {
        return {
          url: "all-users",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    addHiddenLink: builder.mutation({
      query(body) {
        return {
          url: "add",
          headers: {
            "x-access-token": token,
          },
          method: "Post",
          body,
        };
      },
      invalidatesTags: ["Hlink"],
    }),

    deleteHiddenEmail: builder.mutation({
      query(id) {
        return {
          url: `${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
      invalidatesTags: ["Hlink"],
    }),
  }),
});
export const {
  useAddHiddenLinkMutation,
  useGetUserHiddenEmailQuery,
  useDeleteHiddenEmailMutation,
} = hiddenLinkApi;

//>>>>>>>>>>>>>>>>>>>>>>>>> subscribe api <<<<<<<<<<<<<<<<<<<<<<<<<<<
export const subscribeApi = createApi({
  reducerPath: "subscribeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/subscribe/`,
  }),
  endpoints: (builder) => ({
    getAllSubscriber: builder.mutation({
      query(body) {
        return {
          url: "/",
          headers: {
            //"x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),
  }),
});
export const { useGetAllSubscriberMutation } = subscribeApi;
