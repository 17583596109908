import React, { useState } from "react";
import { TextField, Typography, Button, makeStyles } from "@material-ui/core";
import BackupIcon from "@material-ui/icons/Backup";
import { useFormik } from "formik";
import * as yup from "yup";
import { Dialog } from "@mui/material";


export default function UpdateQuestion({ UpdateData, editDialog, data }) { 
  const formik = useFormik({
    initialValues: {
      questionId: data?.question,
      answer: "",
    },
    validationSchema: yup.object({
      questionId: yup.string(),
      answer: yup.string().required("Required !"),
    }),
    onSubmit: (values) => {
        UpdateData({
        questionId: data?._id,
        answer: values?.answer,
      });
    },
  });
  const dialogclose = () => {
    editDialog(false);
  }; 

  return (
    <Dialog className='dialog p-5'  PaperProps={{ style: {
      minHeight: '70%',
      minWidth:'50%',
      maxHeight: '90%',
    }}}    open={true} >
        <form onSubmit={formik.handleSubmit} className="p-5" >
        <Typography 
          variant="h6"
          color="primary"
          gutterBottom
        >
          Update a Answer
        </Typography>
        <br />
        <TextField
          disabled={true}
          name="questionId"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.questionId}
          fullWidth={true}
          label="Question"
          variant="outlined"
          size="small"
        />
        {formik.touched.questionId && (
          <span style={{ color: "red", fontSize: "12px" }}>
            {formik.errors.name}
          </span>
        )}
        <br />
        <br />
        <TextField
          name="answer"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.answer}
          label="Answer"
          fullWidth={true}
          multiline
          variant="outlined"
          size="medium"
        />
        {formik.touched.answer && (
          <span style={{ color: "red", fontSize: "12px" }}>
            {formik.errors.description}
          </span>
        )}
        <br />
        <br />

        <Button onClick={()=>{editDialog(false)}} variant="contained" color="secondary">
          Close
        </Button>
        <Button
          type="submit"
          style={{ marginLeft: 20 }}
          variant="contained"
          color="primary"
        >
          {" "}
          Save
        </Button>
      </form>
    </Dialog>
  );
}
