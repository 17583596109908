import React, { useState, useEffect } from "react";
import EditDelete from "./EditDelete";
import CreateTableAddButton from "./CreateTableAddButton";
import Toster from "./Toster";
import SkeletonListing from "./SkeletonListing";
import AddDataHeader from "./AddDataHeader";
import DataNotFound from "./DataNotFound";
import moment from "moment";
import {
  AccordionDetails,
  AccordionSummary,
  Typography,
  Accordion,
  Button,
  Grid,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListIcon from "@material-ui/icons/FilterList";
import Filters from "./Filters";
import * as yup from "yup";
import { phoneRegex, emailRegex } from "../../../constant/regularExpression";

import {
  useGetAllMobileNoQuery,
  useAddNewMobileNoMutation,
  useUpdateMobileNoMutation,
  useDeleteMobileNoMutation,
} from "../../../services/mobileNoLibraryServices";
import FormDialog from "./reusableComponents/NewFormDialog";
import AuthWrapper from "../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../utils/getFilterFunction";

export default function MobileNoLibrary() {
  const [filteredPagination, setFilteredPagination] = React.useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [render, setRender] = useState(false);
  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: ["name", "email", "mobile"],
    page: page + 1,
    filterBy: filteredPagination,
  };
  const [pagination, setPagination] = useState(paginationData);

  const {
    data: allMobileNos,
    isFetching,
    isLoading,
  } = useGetAllMobileNoQuery(pagination);

  useEffect(() => {
    setPagination(paginationData);
  }, [render, page, rowsPerPage, filteredPagination]);

  const [addNewMobileNo, addNewMobileNoInfo] = useAddNewMobileNoMutation();

  const [updateMobileNo, updateMobileNoInfo] = useUpdateMobileNoMutation();

  const [deleteMobileNo, deleteMobileNoInfo] = useDeleteMobileNoMutation();

  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "name",
      label: "Name",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "mobileNo",
      label: "Mobile No",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "Email",
      label: "Email",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "createdAt",
      label: "Created At",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "updatedAt",
      label: "Updated At",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },

    {
      id: "action",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: [
        "SMS_MOBILE_NUMBER_LIBRARY_EDIT",
        "SMS_MOBILE_NUMBER_LIBRARY_DELETE",
      ],
    },
  ];

  function createData(
    SNo,
    name,
    mobileNo,
    Email,
    createdAt,
    updatedAt,
    action
  ) {
    return {
      SNo,
      name,
      mobileNo,
      Email,
      createdAt,
      updatedAt,
      action,
    };
  }

  let rows = [];
  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [addDialog, setAddDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  // const [viewDialog, setViewDialog] = useState(false);

  const totalItem = allMobileNos ? allMobileNos.totalItem : 0;

  let totalData = "ok";

  if (allMobileNos) {
    totalData = allMobileNos.totalItem;
    rows = allMobileNos.data.map((ele, index) =>
      createData(
        index + 1,
        ele.name,
        ele.mobile,
        ele.email,
        ele.createdAt
          ? moment(ele.createdAt).format("DD-MM-YYYY hh:mm:ss A")
          : "",
        ele.updatedAt
          ? moment(ele.updatedAt).format("DD-MM-YYYY hh:mm:ss A")
          : "",
        <div className="d-flex">
          <AuthWrapper type="ACTION" name="SMS_MOBILE_NUMBER_LIBRARY_EDIT">
            <EditDelete
              setEditDialog={setEditDialog}
              type="edit"
              setData={setData}
              data={ele}
            />
          </AuthWrapper>
          <AuthWrapper type="ACTION" name="SMS_MOBILE_NUMBER_LIBRARY_DELETE">
            <EditDelete type="delete" setGetid={setGetid} id={ele._id} />
          </AuthWrapper>
        </div>
      )
    );
  }
  // );
  //   }
  const dialogclose = () => {
    setEditDialog(false);
    setAddDialog(false);
    setRender(!render);
  };

  const updateClickHandler = (dataForUpdate) => {
    updateMobileNo({ body: dataForUpdate, id: data._id });
  };

  const addBtnClickHandler = (dataForUpdate) => {
    addNewMobileNo(dataForUpdate);
  };

  useEffect(() => {
    getid && deleteMobileNo(getid) && setGetid("");
  }, [getid]);

  const resetClickHandler = () => {
    setStartDate("");
    setEndDate("");
    setRender(!render);
  };

  return (
    <div>
      <AddDataHeader
        addFormName={"Add New"}
        setPage={setPage}
        page={page}
        currentPageName="mobileNumberLibrary"
        excelBtn={true}
        totalItem={totalItem}
        rowsPerPage={rowsPerPage}
        setAddDialog={setAddDialog}
        setRowsPerPage={setRowsPerPage}
        searchBarApi={setPagination}
        paginationData={pagination}
        Adddialog={addDialog}
        downloadMobileExcel={AuthWrapper({
          type: "ACTION",
          name: "SMS_MOBILE_NUMBER_LIBRARY_DOWLOAD_EXCEL",
          inBoolean: true,
        })}
        isSearch={
          !AuthWrapper({
            type: "ACTION",
            name: "SMS_MOBILE_NUMBER_LIBRARY_LIST",
            inBoolean: true,
          })
        }
        isPagination={
          !AuthWrapper({
            type: "ACTION",
            name: "SMS_MOBILE_NUMBER_LIBRARY_LIST",
            inBoolean: true,
          })
        }
        addBtn={
          !AuthWrapper({
            type: "ACTION",
            name: "SMS_MOBILE_NUMBER_LIBRARY_ADD",
            inBoolean: true,
          })
        }
        form={
          <FormDialog
            open={addDialog}
            setOpen={setAddDialog}
            formName={"Add New Mobile No "}
            btnName={"Add"}
            onBtnClick={addBtnClickHandler}
            fields={[
              {
                fieldType: "text",
                fieldName: "Name",
                initialValue: "",
                //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                accessKey: "name",
                validation: yup.string().required("Required"),
              },
              {
                fieldType: "tel",
                fieldName: "Mobile No",
                initialValue: "",
                //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                accessKey: "mobile",
                validation: yup
                  .string()
                  .matches(phoneRegex, "Enter correct mobile number!")
                  .required("Required!"),
              },
              {
                fieldType: "text",
                fieldName: "Email Address",
                initialValue: "",
                //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                accessKey: "email",
                validation: yup
                  .string()
                  .matches(emailRegex, "Enter correct email!")
                  .required("Required!"),
              },
            ]}
          />
        }
      />

      <>
        <AuthWrapper type="ACTION" name="SMS_MOBILE_NUMBER_LIBRARY_LIST">
          {rows.length ? (
            <CreateTableAddButton
              editDialog={editDialog}
              setPage={setPage}
              rows={rows}
              editform={
                <FormDialog
                  open={editDialog}
                  setOpen={setEditDialog}
                  formName={"Update Details "}
                  btnName={"Update"}
                  onBtnClick={updateClickHandler}
                  fields={[
                    {
                      fieldType: "text",
                      fieldName: "Name",
                      initialValue: data.name,
                      //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                      accessKey: "name",
                      validation: yup.string().required("Required"),
                    },
                    {
                      fieldType: "tel",
                      fieldName: "Mobile No",
                      initialValue: data.mobile,
                      //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                      accessKey: "mobile",
                      validation: yup
                        .string()
                        .matches(phoneRegex, "Enter correct mobile number!")
                        .required("Required!"),
                    },
                    {
                      fieldType: "text",
                      fieldName: "Email Address",
                      initialValue: data.email,
                      //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                      accessKey: "email",
                      validation: yup
                        .string()
                        .matches(emailRegex, "Enter correct email!")
                        .required("Required!"),
                    },
                  ]}
                />
              }
              columns={getFilterdColumns(columns)}
              isButton={true}
              // editFormName={"Edit Cash"}
            />
          ) : totalData == 0 ? (
            <DataNotFound />
          ) : (
            <SkeletonListing />
          )}
        </AuthWrapper>
      </>

      {addNewMobileNoInfo.data && (
        <Toster info={addNewMobileNoInfo} dialogclose={dialogclose} />
      )}
      {updateMobileNoInfo.data && (
        <Toster info={updateMobileNoInfo} dialogclose={dialogclose} />
      )}
      {deleteMobileNoInfo.data && (
        <Toster info={deleteMobileNoInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
