import Delete from "@material-ui/icons/Delete";
import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem("__authToken__");
export const facultyCommisionApi=createApi({
  // tagTypes: ["facultyCommision"],
  reducerPath: "facultyCommision",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/faculty-commission/`,
  }),
  endpoints:(builder)=>({

      addFacultyCommision:builder.mutation({
        // invalidatesTags: ["facultyCommision"],
        query:(body)=>
        {
          return{
            url:"add-faculty-Commission",
            headers: {
              "x-access-token": token,
            },
            method: "POST",
            body,

          }
        }

      }),

      deleteFacultyCommision:builder.mutation({
        // invalidatesTags: ["facultyCommision"],
        query:(commisionId)=>
        {
          return{
            url: `${commisionId}`,
            headers: {
              "x-access-token": token,
            },
            method: "DELETE"
          }
        }

      }),

  }),

});

export const { 
useAddFacultyCommisionMutation,
useDeleteFacultyCommisionMutation
} =facultyCommisionApi;