import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const paymentModeApi = createApi({
    tagTypes: ["paymentMode"],
    reducerPath: "paymentModeApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/payment-mode/`,
    }),
    endpoints: (builder) => ({
        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET "paymentMode" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        GetAllPaymentModes: builder.query({
            providesTags: ["paymentMode"],
            query: () => {
                return {
                    url: "all-payment-mode", //******** *
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                };
            },
        }),

        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ADD "paymentMode" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        AddPaymentMode: builder.mutation({
            invalidatesTags: ["paymentMode"],
            query: (body) => {
                return {
                    url: "add-payment-mode", // ***********
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,
                };
            },
        }),


        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< UPDATE "paymentMode" DATA SECTION >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        UpdatePaymentMode: builder.mutation({
            invalidatesTags: ["paymentMode"],
            query: ({ body, id }) => {
                return {
                    url: id, //********** *
                    headers: {
                        "x-access-token": token,
                    },
                    method: "PUT",
                    body,
                };
            },
        }),


        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< DELETE DATA IN "paymentMode" SECTION >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        DeletePaymentMode: builder.mutation({
            invalidatesTags: ["paymentMode"],
            query: (id) => {
                return {
                    url: id, //********** *
                    headers: {
                        "x-access-token": token,
                    },
                    method: "DELETE",
                };
            },
        }),


    }),
});

export const {
    useGetAllPaymentModesQuery,
    useAddPaymentModeMutation,
    useUpdatePaymentModeMutation,
    useDeletePaymentModeMutation,
} = paymentModeApi;


