import React, { useState, useEffect } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import Loader from "../Loader";
import AddDataHeader from "../AddDataHeader";
import DataNotFound from "../DataNotFound";
import {
    AccordionDetails,
    AccordionSummary,
    Typography,
    Accordion,
    TextField,
    Box,
    Grid,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListIcon from "@material-ui/icons/FilterList";
import Filters from "../Filters";
import FormDialog from "../reusableComponents/NewFormDialog";
import { allTransactionsApi, useGetAllOfflineTransactionsQuery } from "../../../../services/offlineModuleServices/allOfflineTransactionsServices";
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'; 
import moment from "moment";





export default function AllOfflineTransactions() {
    const [filteredPagination, setFilteredPagination] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [render, setRender] = useState(false);

    const paginationData = {
        limit: rowsPerPage,
        query: "",
        params: [
            "payment_mode",
            "added_by_name",
            "order_id",
            "transaction_type",
            "added_by_type",
            "added_on_date",
            "remark",
            "center_name"
        ],
        dateFilter: {
            startDate: "",
            endDate: ""
        },
        filterBy: filteredPagination,
        page: page + 1,
    };

    const [pagination, setPagination] = useState(paginationData)

    const { data: AllTransactions, isFetching: isAllTransactionsFetching, isLoading: isAllTransactionsLoading } = useGetAllOfflineTransactionsQuery(pagination)

    useEffect(() => {

        setPagination(paginationData);
    }, [render, page, rowsPerPage, filteredPagination]);

    const columns = [
        {
            id: "SNo",
            label: "S.No",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "recordedAt",
            label: "Recorded At",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "recordedBy",
            label: "Recorded By",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "transactionType",
            label: "Transaction Type",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "depositAmount",
            label: "Deposit Amount",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "withdrawalAmount",
            label: "Withdrawal Amount",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "exchangeAmount",
            label: "Exchange Amount",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "remark",
            label: "Remark",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "centerName",
            label: "Center Name (Code)",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },

    ];
    function createData(
        SNo,
        recordedAt,
        recordedBy,
        transactionType,
        depositAmount,
        withdrawalAmount,
        exchangeAmount,
        remark,
        centerName,
    ) {
        return {
            SNo,
            recordedAt,
            recordedBy,
            transactionType,
            depositAmount,
            withdrawalAmount,
            exchangeAmount,
            remark,
            centerName,
        };
    }


    let rows = [];
    let [getid, setGetid] = useState("");
    const [data, setData] = useState("");
    const [viewDialog, setViewDialog] = useState(false);

    const totalItem = AllTransactions ? AllTransactions.totalItem : 0;



    // useEffect(() => {
    //     getid && deleteExpense(getid) && setGetid("");
    // }, [getid]);

    let totalData = "ok";

    if (AllTransactions) {
        totalData = AllTransactions?.totalItem;
        rows = AllTransactions?.data?.map((ele, index) =>
            createData(
                index + 1,
                `${moment(ele.added_on_date).format("DD MMM yyyy")} at ${moment(ele.added_on_date).format("HH:MM A") } ` ,
                <div className="d-flex">
                    {" "}
                    <h6 className="text-primary">
                        {" "}
                        {ele.added_by_name}
                    </h6>
                    <span className="text-success ml-2">
                        {" "}
                        ({ele.added_by_type}){" "}
                    </span>{" "}
                </div>,
                ele.transaction_type == "DEPOSITE" ? <span className="text-success" > DEPOSIT</span> : ele.transaction_type == "WITHDRAW" ? <span className="text-danger" > WITHDRAWAL</span> : <span className="text-primary">{ele.transaction_type} </span>,
                <span className="text-success"> &#8377; {ele.deposite_amount} </span>,
                <span className="text-danger"> &#8377; {ele.withdraw_amount} </span>,
                <span className="text-primary"> &#8377; {ele.exchange_amount} </span>,
                ele.remark,
                <div className="d-flex">
                    {" "}
                    <h6 className="text-primary">
                        {" "}
                        {ele.center_name}
                    </h6>
                    <span className="text-success ml-2">
                        {" "}
                        ({ele.center_code}){" "}
                    </span>{" "}
                </div>,

            )
        );
    }

    // );
    //   }
    const dialogclose = () => {
        setViewDialog(false);
        setRender(!render);
    };
    return (
        <div>
            {!viewDialog && (
                <AddDataHeader
                    addBtn={true}
                    setPage={setPage}
                    page={page}
                    totalItem={totalItem}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    searchBarApi={setPagination}
                    paginationData={pagination}
                    isButton={true}

                />
            )}

            {/* <Accordion className="">
                <AccordionSummary
                    className="text-primary  "
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className="d-flex align-items-center ">
                        <FilterListIcon /> <span> Filter</span>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className=" d-flex justify-content-between">
                    <Filters
                        setFilteredPagination={setFilteredPagination}
                        // isSubFilter={true}
                        // isFacFilter={true}
                        // isSyllabusFilter={false}
                        isFromFilter={true}
                        isToFilter={true}
                    // isBookName={true}
                    />
                </AccordionDetails>
            </Accordion> */}

            

            {/* {deleteBookStockInfo.isLoading || updateBookInfo.isLoading ? ( */}
            {/* <Loader /> */}
            {/* ) : ( */}

            <Grid container justifyContent="flex-end" >
                <TextField>
                    
                </TextField>
            </Grid>
            <>
                {/* {!viewDialog ? ( */}
                {rows?.length ? (
                    <CreateTableAddButton
                        setPage={setPage}
                        rows={rows}
                        columns={columns}
                        isButton={true}
                    />
                ) : totalData == 0 ? (
                    <DataNotFound />
                ) : (
                    <SkeletonListing />
                )}
            </>


        </div>
    );
}
