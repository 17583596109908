import React, { useState } from "react";
import { TextField, Card } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import { useGetSMSTempletListQuery } from "../../../services/smsServices";
import AddCustomsSms from "./AddCustomsSms";
import AuthWrapper from "../../../utils/AuthWrapper";

export default function CustomSMS() {
  const [title, setTitle] = useState({
    title: "",
    templet_id: "",
    msg_body: "",
  });

  const { data: allSmsTempletLists } = useGetSMSTempletListQuery();

  let titleOptions = [{ title: "", templet_id: "", msg_body: "" }];
  if (allSmsTempletLists) {
    titleOptions = allSmsTempletLists.data.map((ele) => {
      return {
        title: ele.title,
        templet_id: ele.templet_id,
        msg_body: ele.msg_body,
      };
    });
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "80vh" }}
    >
      <Grid item className="d-flex align-items-center">
        <Card
          elevation={24}
          className="p-4"
          style={{
            display: "block",
            width: "30vw",
            transitionDuration: "0.3s",
          }}
        >
          <h5 className="border-bottom pb-2"> SMS Details </h5>
          <Autocomplete
            size="large"
            fullWidth={true}
            className="mb-4"
            onChange={(event, newValue) => {
              setTitle(newValue);
            }}
            value={title}
            options={titleOptions ? titleOptions : []}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => {
              return <TextField {...params} label="Title" />;
            }}
          />
          <AuthWrapper type="ACTION" name="SMS_SEND_SMS_ADD">
            {title
              ? title?.title != "" && (
                  <AddCustomsSms title={title} setTitle={setTitle} />
                )
              : null}
          </AuthWrapper>
        </Card>
      </Grid>
    </Grid>
  );
}
