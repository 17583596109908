import React, { useState, useEffect } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import AddDataHeader from "../AddDataHeader";
import DataNotFound from "../DataNotFound";
import FormDialog from "../reusableComponents/NewFormDialog";
import {
  useGetAllExpenseCategoryQuery,
  useGetExpenseByIdQuery,
  useAddExpenseCategoryMutation,
  useUpdateExpenseCategoryMutation,
  useDeleteExpenseCategoryMutation,
} from "../../../../services/services";

export default function ExpenseCategory() {
  const {
    data: AllExpenseCategories,
    isAllExpenseCategoriesFetching,
    isAllExpenseCategoriesLoading,
  } = useGetAllExpenseCategoryQuery();

  const [addExpenseCategoryData, addExpenseCategoryDataInfo] =
    useAddExpenseCategoryMutation();

  const [updateExpenseCategory, updateExpenseCategoryInfo] =
    useUpdateExpenseCategoryMutation();

  const [deleteExpenseCategory, deleteExpenseCategoryInfo] =
    useDeleteExpenseCategoryMutation();


  const [filteredPagination, setFilteredPagination] = React.useState([]);

  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "categoryName",
      label: "Category Name",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "department",
      label: "Department",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "remark",
      label: "Remark",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];
  function createData(SNo, categoryName, department, remark, actions) {
    return {
      SNo,
      categoryName,
      department,
      remark,
      actions,
    };
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [render, setRender] = useState(false);
  let rows = [];
  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [addDialog, setAddDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
 
  let totalData = "ok";


  useEffect(() => {
    getid && deleteExpenseCategory(getid) && setGetid("");
  }, [getid]);

  if (
    !isAllExpenseCategoriesFetching &&
    !isAllExpenseCategoriesLoading &&
    AllExpenseCategories
  ) {
    rows = AllExpenseCategories.data.map((ele, index) => {
      return createData(
        index + 1,
        ele.category_name,
        ele.department,
        ele.remark,
        <div>
          <EditDelete
            setEditDialog={setEditDialog}
            type="edit"
            setData={setData}
            data={ele}
          />
          <EditDelete type="delete" setGetid={setGetid} id={ele._id} />
        </div>
      );
    });
  }


  // );
  //   }
  const dialogclose = () => {
    setEditDialog(false);
    setViewDialog(false);
    setAddDialog(false);
    setRender(!render);
  };

  const updateBtnClickHandler = (dataForUpdate) => {
    updateExpenseCategory({
      body: dataForUpdate,
      id: data._id,
    });
  };

  return (
    <div>
      {!viewDialog && (
        <AddDataHeader
          addFormName={"Add Expense Category"}
          isSearch = {true}
          isPagination = {true}
          setAddDialog={setAddDialog}
          isButton={true}
          Adddialog={addDialog}
          form={
            <FormDialog
              open={addDialog}
              setOpen={setAddDialog}
              formName={"Add Expense Category "}
              btnName={"Add"}
              onBtnClick={(dataForAdd) => {
                addExpenseCategoryData(dataForAdd);
              }}
              fields={[
                {
                  fieldType: "text",
                  fieldName: "Expense Category",
                  initialValue: "",
                  //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                  accessKey: "category_name",
                },
                {
                  fieldType: "text",
                  fieldName: "Department",
                  initialValue: "",
                  //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                  accessKey: "department",
                },
                {
                  fieldType: "text",
                  fieldName: "Remark",
                  initialValue: "",
                  //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                  accessKey: "remark",
                },
              ]}
            />
          }
        />
      )}

    
      <>
        {rows.length ? (
          <CreateTableAddButton
            editDialog={editDialog}
            setPage={setPage}
            rows={rows}
            editform={
              <FormDialog
                open={editDialog}
                setOpen={setEditDialog}
                formName={"Edit Expense Category "}
                btnName={"Update"}
                onBtnClick={updateBtnClickHandler}
                fields={[
                  {
                    fieldType: "text",
                    fieldName: "Expense Category",
                    initialValue: data.category_name,
                    //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                    accessKey: "category_name",
                  },
                  {
                    fieldType: "text",
                    fieldName: "Department",
                    initialValue: data.department,
                    //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                    accessKey: "department",
                  },
                  {
                    fieldType: "text",
                    fieldName: "Remark",
                    initialValue: data.remark,
                    //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                    accessKey: "remark",
                  },
                ]}
              />
            }
            columns={columns}
            isButton={true}
            editFormName={"Edit Expense Category"}
          />
        ) : totalData == 0 ? (
          <DataNotFound />
        ) : (
          <SkeletonListing />
        )}
      
      </>
     

      {addExpenseCategoryDataInfo && (
        <Toster info={addExpenseCategoryDataInfo} dialogclose={dialogclose} />
      )}

      {updateExpenseCategoryInfo && (
        <Toster info={updateExpenseCategoryInfo} dialogclose={dialogclose} />
      )}

      {deleteExpenseCategoryInfo && (
        <Toster info={deleteExpenseCategoryInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
