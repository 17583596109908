
import React, { useState, useEffect } from 'react';
import EditDelete from './EditDelete';
import CreateTableAddButton from './CreateTableAddButton'
import UpdatEmails from './UpdatEmails'
import AddEmail from './AddEmail';
import Toster from './Toster';
import ViewEmail from './ViewEmail';
import {
    useGetEmailDetailsMutation,
    useGetAllmailblurbQuery, useDeleteMailBlurbMutation, useAddMailBlurbMutation, useUpdateMailblurbDataMutation,
    useSendEmailDetailsMutation
} from '../../../services/EmailServices';
import SkeletonListing from './SkeletonListing';
import Loader from './Loader';
import AddDataHeader from './AddDataHeader';
import DataNotFound from './DataNotFound';
import { AccordionDetails, AccordionSummary, Typography, Accordion, Dialog, DialogContent, DialogContentText, TextField, Grid, Button, DialogActions, DialogTitle } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Filters from './Filters';
import { emailRegex } from '../../../constant/regularExpression'
import AuthWrapper from '../../../utils/AuthWrapper';
import { getFilterdColumns } from '../../../utils/getFilterFunction';

export default function AllEmail() {
    const [filteredPagination, setFilteredPagination] = React.useState([]);
    const columns = [
        {
            id: 'SNo',
            label: 'S.No',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'subject',
            label: 'Subject',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'mail_type',
            label: 'mail type',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'from',
            label: 'from',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'bcc',
            label: 'bcc',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'cc',
            label: 'cc',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'Actions',
            label: 'Actions',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
            authName: ['CUSTOM_EMAIL_EMAIL_TEMPLATE_VIEW', 'CUSTOM_EMAIL_EMAIL_TEMPLATE_EDIT', 'CUSTOM_EMAIL_EMAIL_TEMPLATE_DELETE', 'CUSTOM_EMAIL_EMAIL_TEMPLATE_SEND_MESSAGE']

        },
    ];
    function createData(SNo, subject, mail_type, from, bcc, cc, Actions) {
        return { SNo, subject, mail_type, from, bcc, cc, Actions };
    }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [render, setRender] = useState(false)
    const paginationData = {
        "limit": rowsPerPage,
        "query": "",
        "params": [
            "book_name", "title"
        ],
        "filterBy": filteredPagination,

        "page": page + 1
    }

    let rows = []
    let [getid, setGetid] = useState('')
    const [data, setData] = useState('')
    const [AddDialog, setDialog] = useState(false)
    const [editDialog, setEditDialog] = useState(false)
    const [smsDialog, setSmsDialog] = useState(false)
    const [emailData, setEmail] = useState({ mail_blurb_id: '', email_to: '', error: "" })
    const [viewDialog, setViewDialog] = useState(false)
    const getAllData = useGetAllmailblurbQuery()
    const [deleteEmail, deleteEmailDataInfo] = useDeleteMailBlurbMutation()
    const [addEmail, addEmailInfo] = useAddMailBlurbMutation()
    const [viewData, viewDataInfo] = useGetEmailDetailsMutation()
    const [sendEmail, sendEmailInfo] = useSendEmailDetailsMutation()
    const [updatEmail, updateEmailInfo] = useUpdateMailblurbDataMutation()
    const allBookstock = getAllData.data
    const ViewDataDeatils = viewDataInfo.data
    const totalItem = allBookstock ? allBookstock.totalItem : 0
    useEffect(() => {
        setEmail({ mail_blurb_id: data._id, email_to: "" })
    }, [data])

    useEffect(() => {
        getid && deleteEmail(getid) && setGetid("")
    }, [getid])

    const AddData = (data) => {
        addEmail(data)
    }
    const UpdateData = (data, id) => {
        updatEmail({ data: data, id: id })
    }
    const SendMessage = () => {
        if (!emailData.email_to || !emailRegex.test(emailData.email_to)) {
            setEmail({ ...emailData, error: "Invalid Email." })
        } else {
            sendEmail(emailData)
        }
    }
    useEffect(() => {
        if (data._id) {
            viewData(data._id)
        }
    }, [viewDialog])

    let totalData = 'ok'
    if (allBookstock) {
        totalData = allBookstock.totalItem
        rows = allBookstock.data.map((data, index) => createData(
            index + 1,
            data.subject,
            data.mail_type,
            data.from,
            data.bcc,
            data.cc,
            <div>
                <AuthWrapper type="ACTION" name="CUSTOM_EMAIL_EMAIL_TEMPLATE_VIEW">
                    <EditDelete setViewDialog={setViewDialog}
                        type="view" setData={setData}
                        setGetid={setGetid} data={data} />
                </AuthWrapper>

                <AuthWrapper type="ACTION" name="CUSTOM_EMAIL_EMAIL_TEMPLATE_EDIT">
                    <EditDelete
                        setEditDialog={setEditDialog}
                        type="edit" setData={setData}
                        setGetid={setGetid} data={data} />
                </AuthWrapper>

                <AuthWrapper type="ACTION" name="CUSTOM_EMAIL_EMAIL_TEMPLATE_DELETE">
                    <EditDelete type="delete"
                        setGetid={setGetid}
                        id={data._id} />
                </AuthWrapper>

                <AuthWrapper type="ACTION" name="CUSTOM_EMAIL_EMAIL_TEMPLATE_SEND_MESSAGE">
                    <EditDelete type="sms"
                        setSmsDialog={setSmsDialog}
                        data={data}
                        setData={setData}
                    />
                </AuthWrapper>
            </div>
        )
        )
    }
    const dialogclose = () => {
        setEditDialog(false)
        setViewDialog(false)
        setDialog(false)
        setRender(!render)
    }
    return (
        <div>

            {!viewDialog && <AddDataHeader
                addFormName={"Add Email "}
                setPage={setPage}
                page={page}
                excelBtn={false}
                totalItem={totalItem}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setAddDialog={setDialog}
                searchBarApi={getAllData}
                paginationData={paginationData}
                isButton={true}
                isSearch={
                    !AuthWrapper({
                        type: "ACTION",
                        name: "CUSTOM_EMAIL_EMAIL_TEMPLATE_LIST",
                        inBoolean: true,
                    })
                }
                isPagination={
                    !AuthWrapper({
                        type: "ACTION",
                        name: "CUSTOM_EMAIL_EMAIL_TEMPLATE_LIST",
                        inBoolean: true,
                    })
                }
                addBtn={
                    !AuthWrapper({
                        type: "ACTION",
                        name: "CUSTOM_EMAIL_EMAIL_TEMPLATE_ADD",
                        inBoolean: true,
                    })
                }
                Adddialog={AddDialog}
                form={<AddEmail AddData={AddData} setDialog={setDialog} />}
            />}

            {/* <Accordion className=''>
                <AccordionSummary className='text-primary  '
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header" >
                    <Typography className='d-flex align-items-center ' ><FilterListIcon /> <span > Filter</span></Typography>
                </AccordionSummary>
                <AccordionDetails className=' d-flex justify-content-between'>
                    <Filters
                        setFilteredPagination={setFilteredPagination}
                        isSubFilter={true}
                        isFacFilter={true}
                        isSyllabusFilter={false}
                        isFromFilter={false}
                        isToFilter={false}
                        isBookName={true}
                    />
                </AccordionDetails>
            </Accordion> */}

            {deleteEmailDataInfo.isLoading || updateEmailInfo.isLoading ? <Loader /> :
                <>
                    <AuthWrapper type="ACTION" name="CUSTOM_EMAIL_EMAIL_TEMPLATE_LIST">

                        {!viewDialog ?
                            rows.length ?
                                <CreateTableAddButton
                                    editDialog={editDialog}
                                    setPage={setPage}
                                    rows={rows}
                                    editform={
                                        <UpdatEmails editData={data}
                                            UpdateData={UpdateData}
                                            editDialog={setEditDialog} />
                                    }
                                    columns={getFilterdColumns(columns)}

                                    isButton={true}
                                    editFormName={'Edit Email Details'} />
                                : totalData == 0 ? <DataNotFound /> : <SkeletonListing />
                            :
                            ViewDataDeatils && <ViewEmail viewDialog={setViewDialog} data={ViewDataDeatils} />
                        }
                    </AuthWrapper>
                </>
            }
            {
                <Dialog
                    open={smsDialog}
                    keepMounted
                    onClose={setSmsDialog}
                    aria-describedby="alert-dialog-slide-description"
                >

                    <DialogContent>
                        <DialogTitle>Are you Sure to Send Message on this Email</DialogTitle>

                        <DialogContentText id="alert-dialog-slide-description " className="mt-4">
                            <TextField
                                name="from"
                                onChange={(e) =>
                                    setEmail({ mail_blurb_id: data._id, email_to: e.target.value })
                                }
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                value={emailData.email_to}
                                fullWidth={true}
                                type="text"
                                variant="outlined"
                                size="small" />

                            {emailData.error && <span style={{ color: "red", fontSize: "12px" }}>{emailData.error}</span>}

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"

                            color="secondary"
                            onClick={() => {
                                setSmsDialog(false)
                                // handleClose()

                            }}>Cancel</Button>
                        <Button
                            // type="submit"
                            style={{ marginLeft: 20 }}
                            variant="contained"
                            color="primary"
                            onClick={SendMessage}
                        > Send</Button>
                    </DialogActions>
                </Dialog>
            }
            {addEmailInfo.data && <Toster info={addEmailInfo} dialogclose={dialogclose} />}
            {deleteEmailDataInfo.data && <Toster info={deleteEmailDataInfo} dialogclose={dialogclose} />}
            {updateEmailInfo && <Toster info={updateEmailInfo} dialogclose={dialogclose} />}
            {sendEmailInfo && <Toster info={sendEmailInfo} dialogclose={dialogclose} />}

        </div>
    )
}