import React, { useState, useEffect } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import Loader from "../Loader";
import AddDataHeader from "../AddDataHeader";
import DataNotFound from "../DataNotFound";
import {
  AccordionDetails,
  AccordionSummary,
  Typography,
  Accordion,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListIcon from "@material-ui/icons/FilterList";
import Filters from "../Filters";
import FormDialog from "../reusableComponents/NewFormDialog";
import { userAccess } from "../../../../helpers/userAccess";

import {
  useGetAllIncomeDetailsMutation,
  useAddIncomeDetailsMutation,
  useUpdateIncomeDetailsMutation,
  useDeleteIncomeDetailsMutation,
  useGetSingleIncomeDetailsQuery,
} from "../../../../services/incomeServices";
import AddUpdateIncomeForm from "./AddUpdateIncomeForm";
import moment from "moment";

export default function IncomeStatement() {
  const [getAllIncomes, getAllIncomesInfo] = useGetAllIncomeDetailsMutation();

  const [deleteIncome, deleteIncomeInfo] = useDeleteIncomeDetailsMutation();

  const [filteredPagination, setFilteredPagination] = React.useState([]);

  const [updateIncomeInfo, setUpdateIncomeInfo] = useState("")
  const [addIncomeInfo, setAddIncomeInfo] = useState("")


  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "recordedAt",
      label: "Recorded At",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "recordedBy",
      label: "Recorded By",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "date",
      label: "Date",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "incomeCategory",
      label: "Income Category",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "incomeName",
      label: "Income Name",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "description",
      label: "Description",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "receivedMode",
      label: "Received Mode",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "amount",
      label: "Amount (₹)",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "incomeFrom",
      label: "Income From (Role)",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];
  function createData(
    SNo,
    recordedAt,
    recordedBy,
    date,
    incomeCategory,
    incomeName,
    description,
    receivedMode,
    amount,
    incomeFrom,
    actions
  ) {
    return {
      SNo,
      recordedAt,
      recordedBy,
      date,
      incomeCategory,
      incomeName,
      description,
      receivedMode,
      amount,
      incomeFrom,
      actions,
    };
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [render, setRender] = useState(false);
  const paginationData = {
    limit: rowsPerPage,
    "filterBy": {
      "fieldName": "",
      "value": ""
    },
    query: "",
    params: ["income_from_name","category_name","income_date","created_by_name","title"],
    filterBy: filteredPagination,
    page: page + 1,
  };

  let rows = [];
  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [addDialog, setAddDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const allIncomes = getAllIncomesInfo.data;
    

  const totalItem = allIncomes ? allIncomes.totalItem : 0;

  useEffect(() => {
    getAllIncomes(paginationData);
  }, [render, rowsPerPage, page, filteredPagination]);

  useEffect(() => {
    getid && deleteIncome(getid) && setGetid("");
  }, [getid]);

  let totalData = "ok";

  if (allIncomes) {
    totalData = allIncomes.totalItem;
    rows = allIncomes.data.map((ele, index) =>
      createData(
        index + 1,
        moment(ele.created_on_date).format("DD-MM-YYYY hh:mm:ss A"),
        ele.created_by_type,
        moment(ele.income_date).format("DD-MM-YYYY"),
        ele.category_name,
        ele.title,
        ele.note,
        ele.payment_mode,
        <h6 className="text-danger"> {ele.income_amount} </h6>,
        <div className="d-flex">
          {" "}
          <h6 className="text-primary"> {ele.income_from_name}</h6>
          <span className="text-success ml-2">
            {" "}
            ({ele.income_from_type}){" "}
          </span>{" "}
        </div>,

        <div className="d-flex">
          {
            userAccess("user")&&userAccess("income-category")&&userAccess("sld-user")&&
            <EditDelete
              setEditDialog={setEditDialog}
              type="edit"
              setData={setData}
              setGetid={setGetid}
              data={ele}
            />
          }
          <EditDelete type="delete" setGetid={setGetid} id={ele._id} />
        </div>
      )
    );
  }

  const dialogclose = () => {
    setEditDialog(false);
    setViewDialog(false);
    setAddDialog(false);
    setRender(!render);
  };
  return (
    <div>
      {!viewDialog && (
        <AddDataHeader
          addFormName={"Add Income"}
          setPage={setPage}
          page={page}
          excelBtn={false}
          totalItem={totalItem}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setAddDialog={setAddDialog}
          searchBarApi={getAllIncomes}
          paginationData={paginationData}
          isButton={true}
          Adddialog={addDialog}
          addBtn={userAccess("user")&&userAccess("income-category")&&userAccess("sld-user") ? false : true}
          form={
            <AddUpdateIncomeForm
              setUpdateIncomeInfo={setUpdateIncomeInfo}
              setAddIncomeInfo={setAddIncomeInfo}
              setAddDialog={setAddDialog}
              dialogclose={dialogclose}
            />
          }
        />
      )}

      <Accordion className="">
        <AccordionSummary
          className="text-primary  "
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="d-flex align-items-center ">
            <FilterListIcon /> <span> Filter</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className=" d-flex justify-content-between">
          <Filters
            setFilteredPagination={setFilteredPagination}
            isFromFilter={true}
            isToFilter={true}
          />
        </AccordionDetails>
      </Accordion>
      <>
        {rows.length ? (
          <CreateTableAddButton
            editDialog={editDialog}
            setPage={setPage}
            rows={rows}
            editform={
              <AddUpdateIncomeForm
                formType="edit"
                setUpdateIncomeInfo={setUpdateIncomeInfo}
                setAddIncomeInfo={setAddIncomeInfo}
                setEditDialog={setEditDialog}
                dialogclose={dialogclose}
                data={data}
              />
            }
            columns={columns}
            isButton={true}
            editFormName={"Edit Income"}
          />
        ) : totalData == 0 ? (
          <DataNotFound />
        ) : (
          <SkeletonListing />
        )}
      </>

      {deleteIncomeInfo.data && (
        <Toster info={deleteIncomeInfo} dialogclose={dialogclose} />
      )}

      {addIncomeInfo.data && (
        <Toster info={addIncomeInfo} dialogclose={dialogclose} />
      )}

      {updateIncomeInfo.data && (
        <Toster info={updateIncomeInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
