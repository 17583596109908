import React, { useEffect, useState } from "react";
import { Button, Grid, Box, makeStyles, withStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import Loader from "./Loader";
import { useGetReviewsByIdMutation } from "../../../services/ReviewServices";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: 18,
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  table: {
    padding: 10,
    margin: 5,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));
export default function ViewReviews({ viewDialog, singleDataId }) {
  const [singledata, setSingleData] = useState({});
  const [getDataById, getDataByIdInfo] = useGetReviewsByIdMutation();

  useEffect(() => {
    getDataById(singleDataId).then((res) => {
      setSingleData(res?.data?.data);
    });
  }, [singleDataId]);
  const classes = useStyles();
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  return getDataByIdInfo?.isLoading ? (
    <Loader />
  ) : (
    <div>
      <div class="card">
        <div class="card-body">
          <Grid container>
            <Grid item xs={12} className=" my-1">
              <Button
                onClick={() => viewDialog(false)}
                className="text-light bg-danger float-right btn btn-sm btn-danger"
              >
                Close
              </Button>
            </Grid>
          </Grid>

          <div>
            <p className="mb-3 text-muted  m-2 d-flex align-items-center">
              Title :{" "}
              <span className="font-bold text-xl px-2">
                {singledata.product_type === "SLD_BOOK_PRODUCT"
                  ? singledata.book_product_name 
                  : singledata.product_type === "SLD_VIDEO_LECTURE"
                  ? singledata.video_lecture_name
                  : singledata.product_type === "SLD_TEST_SERIES"
                  ? singledata.test_series_name
                  : "-"}
              </span>
            </p>
            <h5 className="mb-3 text-muted  m-2 d-flex align-items-center">
              Type :-
              <span className="highlight-text-default  px-2">
                {singleDataId
                  ? singledata?.product_type?.replaceAll("_", " ")
                  : ""}{" "}
              </span>
            </h5>
          </div>
          <Grid item xs={12} className="my-2 d-flex">
            <div>
              <h5 className="mb-3 text-muted  m-2 d-flex align-items-center">
                Date :{" "}
                <span className="highlight-text-default  px-2">
                  {moment(singledata?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                </span>
              </h5>
              <h5 className="mb-3 text-muted m-2 d-flex align-items-center">
                User Name :{" "}
                <span className="highlight-text-default  px-2">
                  {singledata.user_id}
                </span>
              </h5>
              <h5 className="mb-3 text-muted m-2 d-flex align-items-center">
                Verified User? :{" "}
                <span className="highlight-text-default  px-2">
                  {singledata.varified_user === false ? "No" : "Yes"}
                </span>
              </h5>
            </div>
            {/* divide */}
            <div>
              <h5 className="mb-3 text-muted  m-2 d-flex align-items-center">
                Approval Status:{" "}
                <span className="highlight-text-default  px-2">
                  {singledata.approval}{" "}
                </span>{" "}
              </h5>

              <h5 className="mb-3 text-muted  m-2 d-flex align-items-center">
                Star :{" "}
                <span className="highlight-text-default  px-2">
                  {singledata.stars}{" "}
                </span>{" "}
              </h5>
              <h5 className="mb-3 text-muted  m-2 d-flex align-items-center">
                Book Rating :
                <span className="highlight-text-default  px-2">
                  {singledata?.bookRating}
                </span>
              </h5>
            </div>
          </Grid>
          <h5 className="mb-3 text-muted  m-2 d-flex align-items-center">
            Feedback :
            <span className="highlight-text-default  px-2">
              {singledata?.feedback}
            </span>
          </h5>
        </div>
      </div>
    </div>
  );
}
