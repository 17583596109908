import React, { useState, useEffect } from 'react'
import {
    FormControl, InputLabel,
    Select, MenuItem,
    Checkbox, ListItemText,
    OutlinedInput, makeStyles, TextField,
} from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        minWidth: 100,
    },
    tab: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
export default function MultiSelectFilter({ name, multiple, listData, showKey, valueKey, setFilterData , isReset, setIsReset, listDataType
    //onReset 
    } ) {
    const [dataValues, setdataValues] = useState([]);
    const [ isRender, setIsRender ] = useState(0) 

    const handleChange = (value) => {
      
       

            if(Array.isArray(value)){
                let selectedData = value.map((e,i)=>{return listDataType == "object" ? e[valueKey] : e}) 
                setFilterData(selectedData)
            }else{
                setFilterData([value])
            }
        
        
        
        setdataValues(value)
        setIsRender(0)
        setIsReset(false)
    };

    if(isReset&&isRender==0){
        setdataValues([])
        setIsRender(1)
    }
    
    
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 100,
            },
        },
    };
    const classes = useStyles();

    return (
        <div>
            <FormControl style={{ margin: 0 }}
                variant="outlined"
                size="small"
                className={classes.formControl}
                fullWidth={true}
                sx={{ m: 1, width: 300 }}>
                {
                    listDataType=="number"?
                    <TextField
                        variant="outlined"
                        id={"filter"}
                        value={dataValues}
                        onChange={(e) => {
                          handleChange(e.target.value)
                        }}
                        label={name}
                        type="number"
                    />:    
                    <Autocomplete
                            variant="outlined"
                            fullWidth={true}
                            multiple={multiple}
                            id={"filter"}
                            value={dataValues?dataValues:[]}
                            onChange={(e, newValue) => {
                                handleChange(newValue)
                            }}
                            options={listData?listData.data:[]}
                            disableCloseOnSelect
                            getOptionLabel={(option) => listDataType == "object" ? option[showKey]:option}
                            isOptionEqualToValue={listDataType == "object" ? (option, value) => option[valueKey] == value[valueKey] : (option, value) => option == value}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    { listDataType == "object" ? option[showKey] : option}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField label={name} variant="outlined" {...params} size='small' />
                            )}/>
                }
            </FormControl>
        </div>
    )
}
