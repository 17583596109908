import React, { useEffect, useState } from "react";
import {
  useDeleteConfigDataMutation,
  useGetAllConfigDataQuery,
  useUpdateConfigDataMutation,
} from "../../../../../../services/configuration/ConfigDataServices";
import EditDelete from "../../../EditDelete";
import Toster from "../../../Toster";
import EditModule from "../edit/EditModule";
import ModuleListing from "./ModuleListing";
import AuthWrapper from "../../../../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../../../../utils/getFilterFunction";

const columns = [
  {
    id: "module_name",
    label: "Module Name",
    minWidth: 50,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "order_rank",
    label: "Order",
    minWidth: 50,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 50,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
    authName: ["CONFIGURATION_MODULE_DELETE", "CONFIGURATION_MODULE_EDIT"],

  },
];

const ModuleListingWrapper = () => {
  const [rows, setRows] = useState([]);
  const [configDataId, setConfigDataId] = useState("");
  const [getId, setGetId] = useState("");
  const [editDialog, setEditDialog] = useState(false);
  const [editData, setEditData] = useState("");

  const { data, isFetching, isLoading } = useGetAllConfigDataQuery("modules");
  const [deleteConfigData, deleteConfigDataInfo] =
    useDeleteConfigDataMutation();

  const [update, updateInfo] = useUpdateConfigDataMutation();

  useEffect(() => {
    deleteConfigData({
      id: configDataId,
      body: {
        action: "DELETE",
        data: {
          _id: getId,
        },
      },
    });
  }, [getId]);

  const createData = (module_name, order_rank, actions) => {
    return { module_name, order_rank, actions };
  };

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setRows(
        data?.data[0]?.configurationValues?.map((module, index) => {
          return createData(
            module.value_name,
            module.order_rank,
            <div>
              <AuthWrapper type="ACTION" name="CONFIGURATION_MODULE_DELETE">
                <EditDelete type="delete" setGetid={setGetId} id={module._id} />
              </AuthWrapper>
              <AuthWrapper type="ACTION" name="CONFIGURATION_MODULE_EDIT">
                <EditDelete
                  type="edit"
                  setEditDialog={setEditDialog}
                  setData={setEditData}
                  data={module}
                />{" "}
              </AuthWrapper>
            </div>
          );
        }) || []
      );

      setConfigDataId(data?.data[0]?._id);
    }
  }, [isFetching, isLoading, data]);

  return (
    <>
      <ModuleListing
        columns={getFilterdColumns(columns)}
        rows={rows}
        configDataId={configDataId}
      />
      {editDialog && (
        <EditModule
          editData={editData}
          onClose={() => setEditDialog(false)}
          configDataId={configDataId}
          update={update}
        />
      )}

      {deleteConfigDataInfo?.data && <Toster info={deleteConfigDataInfo} />}
      {updateInfo?.data && <Toster info={updateInfo} />}
    </>
  );
};

export default ModuleListingWrapper;
