import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Box,
  makeStyles,
  withStyles,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Dialog,
  Zoom,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  TextareaAutosize,
  Card,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import EditDelete from "../EditDelete";
import Toster from "../Toster";
import BackupIcon from "@material-ui/icons/Backup";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  useGetallInstituteNamesQuery,
  useGetAllStatesQuery,
  useUpdateFacultyMutation,
  useGetSingleFacultyMutation,
} from "../../../../services/services";
import { phoneRegex } from "../../../../constant/regularExpression";
import moment from "moment";
import SkeletonListing from "../SkeletonListing";
import DataNotFound from "../DataNotFound";
import Autocomplete from "@mui/material/Autocomplete";
import AuthWrapper from "../../../../utils/AuthWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: 18,
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  table: {
    padding: 10,
    margin: 5,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function FacultyDetails({
  viewDialog,
  data,
  setFacultyData,
  allSpecialization,
}) {
  const classes = useStyles();
  const [dataForUpdate, setDataForUpdate] = useState({});
  const [editDialog, setEditDialog] = useState(false);
  const [updateData, updateDataInfo] = useUpdateFacultyMutation();
  const [singleFacultyData, singleFacultyDataInfo] =
    useGetSingleFacultyMutation();

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  useEffect(() => {
    singleFacultyData(data._id);
  }, [updateDataInfo]);

  const dialogclose = () => {
    setEditDialog(false);
  };

  return (
    <div className="p-3">
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
        }}
      >
        <div class="card">
          <div class="card-body">
            <Grid container justifyContent="center">
              <Grid xs={12} className="d-flex justify-content-end">
                <AuthWrapper
                  type="ACTION"
                  name="FACULTY_INSTITUTE_FACULTY_EDIT"
                >
                  <EditDelete
                    setEditDialog={setEditDialog}
                    setData={setDataForUpdate}
                    type="edit"
                    data={
                      singleFacultyDataInfo.isLoading == false &&
                      singleFacultyDataInfo.isSuccess == true &&
                      singleFacultyDataInfo.data.data.length
                        ? singleFacultyDataInfo.data.data[0]
                        : data
                    }
                  />
                </AuthWrapper>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => viewDialog(false)}
                  className=""
                >
                  Close
                </Button>
              </Grid>
              {singleFacultyDataInfo.isSuccess == true &&
              singleFacultyDataInfo.data.data.length ? (
                singleFacultyDataInfo.isLoading == false ? (
                  <>
                    <Grid xs={2} className="my-2 border-info border-top">
                      {setFacultyData(singleFacultyDataInfo.data.data[0])}
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Name
                      </Card>

                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Email
                      </Card>
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Personal Number
                      </Card>
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Management Number
                      </Card>
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Payment Method
                      </Card>
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Faculty Grade
                      </Card>
                    </Grid>
                    <Grid xs={3} className="my-2 my-2 border-info border-top">
                      {setFacultyData(singleFacultyDataInfo.data.data[0])}
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].name}
                      </Card>
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].email}
                      </Card>
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].personal_number}
                      </Card>
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].management_number}
                      </Card>
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].payment_method}
                      </Card>
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].faculty_grade}
                      </Card>
                    </Grid>
                    <Grid xs={2} className="my-2 my-2 border-info border-top">
                      {setFacultyData(singleFacultyDataInfo.data.data[0])}
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Faculty Grade Basis
                      </Card>
                      {/* <Card style={{height:"50px", overflow:"scroll" }} className="border text-right px-4 py-1 text-info font-weight-bold">
                                Show On Web
                        </Card> */}

                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Is Shown on Web
                      </Card>
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Active
                      </Card>
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Joining Date
                      </Card>
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Rank
                      </Card>
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Specialization
                      </Card>
                    </Grid>
                    <Grid xs={3} className="my-2 my-2 border-info border-top">
                      {setFacultyData(singleFacultyDataInfo.data.data[0])}
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].faculty_grade_basis}
                      </Card>
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].is_show_on_web
                          ? "YES"
                          : "NO"}
                      </Card>
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].is_active
                          ? "YES"
                          : "NO"}
                      </Card>
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {moment(
                          singleFacultyDataInfo.data.data[0].joining_date
                        ).format("DD-MM-YYYY")}
                      </Card>
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].faculty_rank}
                      </Card>
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].specialization}
                      </Card>
                    </Grid>
                    <Grid xs={2} className="ml-1">
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Meta Title
                      </Card>
                    </Grid>
                    <Grid xs={8} className="">
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].title}
                      </Card>
                    </Grid>
                    <Grid xs={2} className="ml-1">
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Meta Data
                      </Card>
                    </Grid>
                    <Grid xs={8} className="">
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].meta}
                      </Card>{" "}
                    </Grid>
                    <Grid xs={2} className="ml-1">
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Keywords
                      </Card>
                    </Grid>
                    <Grid xs={8} className="">
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].keywords}
                      </Card>
                    </Grid>

                    <Grid xs={2} className="ml-1">
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Test Series Meta Title
                      </Card>
                    </Grid>
                    <Grid xs={8} className="">
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].testSeriesMetaTitle}
                      </Card>
                    </Grid>
                    <Grid xs={2} className="ml-1">
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Test Series Meta Data
                      </Card>
                    </Grid>
                    <Grid xs={8} className="">
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].testSeriesMetaData}
                      </Card>{" "}
                    </Grid>
                    <Grid xs={2} className="ml-1">
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Test Series Keywords
                      </Card>
                    </Grid>
                    <Grid xs={8} className="">
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].testSeriesKeywords}
                      </Card>
                    </Grid>

                    <Grid xs={2} className="ml-1">
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Book Products Meta Title
                      </Card>
                    </Grid>
                    <Grid xs={8} className="">
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].bookMetaTitle}
                      </Card>
                    </Grid>
                    <Grid xs={2} className="ml-1">
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Book Products Meta Data
                      </Card>
                    </Grid>
                    <Grid xs={8} className="">
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].bookMetaData}
                      </Card>{" "}
                    </Grid>
                    <Grid xs={2} className="ml-1">
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Book Products Keywords
                      </Card>
                    </Grid>
                    <Grid xs={8} className="">
                      <Card
                        style={{ height: "50px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].bookKeywords}
                      </Card>
                    </Grid>

                    <Grid xs={2} className="ml-1">
                      <Card
                        style={{ height: "200px", overflow: "scroll" }}
                        className="border text-right px-4 py-1 text-info font-weight-bold"
                      >
                        Description
                      </Card>
                    </Grid>
                    <Grid xs={8} className="">
                      <Card
                        style={{ height: "200px", overflow: "scroll" }}
                        className="border text-left px-4 py-1 text-muted font-weight-bold"
                      >
                        {singleFacultyDataInfo.data.data[0].description}
                      </Card>
                    </Grid>
                  </>
                ) : (
                  <SkeletonListing />
                )
              ) : (
                <Grid container justifyContent="center">
                  <DataNotFound />
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </Box>
      {editDialog ? (
        <UpdateFaculty
          updateDialog={editDialog}
          setUpdateDialog={setEditDialog}
          dataForUpdate={dataForUpdate}
          updateData={updateData}
          allSpecialization={allSpecialization}
        />
      ) : null}
      {updateDataInfo.data && (
        <Toster info={updateDataInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}

function UpdateFaculty({
  updateDialog,
  setUpdateDialog,
  dataForUpdate,
  updateData,
  allSpecialization,
}) {
  const classes = useStyles();
  const { data, isSuccess } = useGetallInstituteNamesQuery();
  const getstates = useGetAllStatesQuery();
  let allStates = getstates.data;
  if (isSuccess) {
    var institutedata = data.data;
  }
  const [facultyGrades, setFacultyGrades] = useState([
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ]);
    const [specialization, setSpecialization] = useState("");

  const formik = useFormik({
    initialValues: {
      title: dataForUpdate.title,
      name: dataForUpdate.name,
      personal_number: dataForUpdate.personal_number,
      payment_method: dataForUpdate.payment_method,
      description: dataForUpdate.description,
      management_number: dataForUpdate.management_number,
      meta: dataForUpdate.meta,
      email: dataForUpdate.email,
      faculty_grade: dataForUpdate.faculty_grade,
      faculty_grade_basis: dataForUpdate.faculty_grade_basis,
      keywords: dataForUpdate.keywords,
      is_show_on_web: dataForUpdate.is_show_on_web,
      is_active: dataForUpdate.is_active,
      specialization: dataForUpdate.specialization,
      faculty_rank: dataForUpdate.faculty_rank,
      joining_date: moment(dataForUpdate.joining_date).format("YYYY-MM-DD"),
      bookMetaData: dataForUpdate.bookMetaData,
      bookMetaTitle: dataForUpdate.bookMetaTitle,
      bookKeywords: dataForUpdate.bookKeywords,
      testSeriesMetaData: dataForUpdate.testSeriesMetaData,
      testSeriesMetaTitle: dataForUpdate.testSeriesMetaTitle,
      testSeriesKeywords: dataForUpdate.testSeriesKeywords,
    },
    validationSchema: yup.object({
      title: yup.string(),
      name: yup.string().required("Required !"),
      personal_number: yup
        .string()
        .matches(phoneRegex, "Invalid Mobile Number")
        .required("Mobile Number is required"),
      payment_method: yup.string().required("Required !"),
      description: yup.string().required("Required !"),
      management_number: yup.string(),
      meta: yup.string(),
      email: yup.string().required("Required !"),
      faculty_grade: yup.string().required("Required !"),
      faculty_grade_basis: yup.string().required("This field is required !"),
      is_show_on_web: yup.string().required("Required !"),
      is_active: yup.string().required("Required !"),
      joining_date: yup.string().required("Required !"),
      faculty_rank: yup
        .number()
        .min(1, "Rank must be greater than 0 ")
        .required("This field is required"),
    }),
    onSubmit: (values, e) => {
      var form_data = new FormData();
      form_data.append("key", "faculty_details");
      form_data.append("update_data", JSON.stringify(values));
      updateData({ data: form_data, id: dataForUpdate._id });
    },
  });

  return (
    <Dialog
      fullScreen={false}
      open={updateDialog}
      //scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
      TransitionComponent={Zoom}
    >
      <DialogTitle id="scroll-dialog-title">Update Details</DialogTitle>
      <DialogContent
      //dividers={scroll === "paper"}
      >
        <form id="addfacultyform" onSubmit={formik.handleSubmit}>
          <Grid
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              marginTop: 20,
              marginLeft: 10,
            }}
          >
            <Typography
              className={classes.title}
              variant="h6"
              color="primary"
              gutterBottom
            >
              Personal Details
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <TextField
                  name="name"
                  label="Faculty Name "
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.name && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.name}
                  </span>
                )}
              </Grid>

              <Grid item xs={4}>
                <TextField
                  name="personal_number"
                  label="Personal Number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.personal_number}
                  fullWidth={true}
                  type="number"
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.personal_number && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.personal_number}
                  </span>
                )}
              </Grid>

              <Grid item xs={4}>
                <TextField
                  name="email"
                  label="Faculty Email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  fullWidth={true}
                  type="text"
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.email && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.email}
                  </span>
                )}
              </Grid>

              <Grid item xs={4}>
                <TextField
                  name="payment_method"
                  label="Payment Method"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.payment_method}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.payment_method && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.payment_method}
                  </span>
                )}
              </Grid>

              <Grid item xs={4}>
                <TextField
                  name="management_number"
                  label="Management Number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.management_number}
                  fullWidth={true}
                  type="number"
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.management_number && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.management_number}
                  </span>
                )}
              </Grid>

              {/* Rank */}
              <Grid item xs={4}>
                <TextField
                  name="faculty_rank"
                  type="number"
                  label="Rank"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.faculty_rank}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.faculty_rank && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.faculty_rank}
                  </span>
                )}
              </Grid>
            </Grid>

            <br />
            <Typography
              className={classes.title}
              variant="h6"
              color="primary"
              gutterBottom
            >
              Other Details
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <FormControl>
                  <FormLabel id="faculty_grade_basis">
                    {" "}
                    Faculty Grade Basis
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="faculty_grade_basis"
                    name="faculty_grade_basis"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.faculty_grade_basis}
                  >
                    <FormControlLabel
                      value="COMMISSION PERCENT"
                      control={<Radio />}
                      label="Commission Percent"
                    />
                    <FormControlLabel
                      value="SALES"
                      control={<Radio />}
                      label="Sales"
                    />
                  </RadioGroup>
                  {formik.touched.faculty_grade_basis && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.faculty_grade_basis}
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  style={{ margin: 0 }}
                  fullWidth={true}
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-institute-Name-native-simple">
                    Faculty Grade
                  </InputLabel>

                  <Select
                    onChange={formik.handleChange}
                    id="faculty_grade"
                    onBlur={formik.handleBlur}
                    value={formik.values.faculty_grade}
                    label="Faculty Grade"
                    name="faculty_grade"
                  >
                    {facultyGrades.map((data) => {
                      return <MenuItem value={data}>{data}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                {formik.touched.faculty_grade && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.faculty_grade}
                  </span>
                )}
              </Grid>
              {/* <Grid item xs={4}>
                <FormControl
                  style={{ margin: 0 }}
                  fullWidth={true}
                  variant="outlined"
                  className={classes.formControl}>

                  <InputLabel
                    htmlFor="outlined-institute-Name-native-simple">
                    Show On Web
                  </InputLabel>

                  <Select
                    onChange={formik.handleChange}
                    id="is_show_on_web"
                    onBlur={formik.handleBlur}
                    value={formik.values.is_show_on_web}
                    label="Show On Web"
                    name="is_show_on_web">
                    <MenuItem value={true}>YES</MenuItem>
                    <MenuItem value={false}>NO</MenuItem>
                  </Select>
                </FormControl>
                {formik.touched.is_show_on_web && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.is_show_on_web}</span>}
              </Grid> */}
              <Grid item xs={4}>
                <FormControl
                  style={{ margin: 0 }}
                  fullWidth={true}
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-institute-Name-native-simple">
                    Is Active
                  </InputLabel>

                  <Select
                    onChange={formik.handleChange}
                    id="is_active"
                    onBlur={formik.handleBlur}
                    value={formik.values.is_active}
                    label="Is Active"
                    name="is_active"
                  >
                    <MenuItem value={true}>YES</MenuItem>
                    <MenuItem value={false}>NO</MenuItem>
                  </Select>
                </FormControl>
                {formik.touched.is_active && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.is_active}
                  </span>
                )}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="joining_date"
                  label=""
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.joining_date}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                  type="date"
                />
                {formik.touched.joining_date && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.joining_date}
                  </span>
                )}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="meta"
                  label="Meta Data"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.meta}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.meta && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.meta}
                  </span>
                )}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="title"
                  label="Meta Title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.title && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.title}
                  </span>
                )}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="keywords"
                  label="Keywords"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.keywords}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.keywords && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.keywords}
                  </span>
                )}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="bookMetaData"
                  label="Book Meta Data"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bookMetaData}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.bookMetaData && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.bookMetaData}
                  </span>
                )}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="bookMetaTitle"
                  label="Book Meta Title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bookMetaTitle}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.bookMetaTitle && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.bookMetaTitle}
                  </span>
                )}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="bookKeywords"
                  label="Book Keywords"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bookKeywords}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.bookKeywords && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.bookKeywords}
                  </span>
                )}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="testSeriesMetaData"
                  label="Test Series Meta Data"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.testSeriesMetaData}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.testSeriesMetaData && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.testSeriesMetaData}
                  </span>
                )}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="testSeriesMetaTitle"
                  label="Test Series Meta Title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.testSeriesMetaTitle}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.testSeriesMetaTitle && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.testSeriesMetaTitle}
                  </span>
                )}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="testSeriesMetaData"
                  label="Test Series Keywords"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.testSeriesMetaData}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.testSeriesMetaData && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.testSeriesMetaData}
                  </span>
                )}
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  id="specialization"
                  //freeSolo
                  value={formik.values.specialization}
                  inputValue={formik.values.specialization}
                  onInputChange={(event, newValue) => {
                    setSpecialization(newValue);
                    formik.values.specialization = newValue;
                  }}
                  name="specialization"
                  options={allSpecialization}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      fullWidth={true}
                      variant="outlined"
                      size="medium"
                      {...params}
                      label="Specialization"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  minRows={2}
                  maxRows={5}
                  id="description"
                  name="description"
                  label="Faculty Description"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  fullWidth={true}
                  style={{ width: "100%" }}
                  variant="outlined"
                  size="medium"
                  multiline
                />
                {formik.touched.description && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.description}
                  </span>
                )}
              </Grid>
              <br />
            </Grid>
            <br />
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className="text-right">
                  <Button
                    onClick={() => setUpdateDialog(false)}
                    variant="contained"
                    color="secondary"
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default FacultyDetails;
