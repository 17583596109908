// **** React Import
import React, { useState } from 'react'

// **** Image Capture
import Webcam from "react-webcam";

// **** Formik Imports
import { ErrorMessage, Field, Form, Formik } from 'formik'

// **** Yup Import
import * as Yup from "yup"

// **** Material Imports
import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, ListItemText, MenuItem, Paper, Select, TextField, Tooltip, Typography } from '@mui/material'

// ** Material Icons Import
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import SkeletonListing from '../../SkeletonListing';

// **** Custom File Imports
import CurrencyDetailsForm from '../CurrencyDetailsForm';

// **** Services Imports 
import { useGetConfigQuery } from '../../../../../services/offlineModuleServices/configServices';
import { useGetAllBatchQuery } from '../../../../../services/offlineModuleServices/batchServices';
import { useGetAallFacultieNamesQuery, useGetAllCategoriesQuery, useGetAllCoursesQuery, useGetAllLevelNamesQuery, useGetAllModeQuery, useGetAllSubjectNamesQuery } from '../../../../../services/services';
import { useGetAllCenterQuery } from '../../../../../services/offlineModuleServices/centersServices';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Avatar } from '@material-ui/core';
import AddCurrencyDetailsForm from '../../AddCurrencyDetailsForm';
import {
    useGetVideoLectureProductQuery, useGetVideoLectureVarientQuery
} from "../../../../../services/services";



const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


// **** Mobile RegEx
const mobileRegEx = /^(\+91[\-\s]?)?[0] ? (91) ? [789]\d{ 9 }$/ig

// ** Custom Styles
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const renderTextError = (name) => (
    <ErrorMessage name={name} >
        {
            (errMsg) => (
                <Typography color="error"> {errMsg} </Typography>
            )
        }
    </ErrorMessage>
)

// **** Main Function Starts
function AddPendriveClassOrder(props) {

    // **** Destructuring Props
    const { setOpenOrderForm, orderType } = props

    // **** States
    const [viewCurrencyDetailsForm, setViewCurrencyDetailsForm] = useState(false);
    const [orderFormData, setOrderFormData] = useState("");
    const [imgSrc, setImgSrc] = useState(null);
    const [isWebCam, setIsWebCam] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState("")

    const videoConstraints = {
        width: "100%",
        facingMode: "user",
    };

    // **** Check Device Has Webcam
    navigator.mediaDevices.getUserMedia({
        video: true
    }, () => {
        setIsWebCam(true)
    }, () => {
        setIsWebCam(false)

    });

    // **** Services
    const { data: allCenters, isFetching: isAllCentersFetching, isLoading: isAllCentersLoading } = useGetAllCenterQuery()
    const { data: allProduct, isFetching: isAllProductFetching, isLoading: isAllProductLoading } = useGetVideoLectureProductQuery()
    const { data: varient, isFetching: isVarientFetching, isLoading: isVarientLoading } = useGetVideoLectureVarientQuery(selectedProduct)

    // **** Form Initial Values 
    const initialValues = {
        student_name: "",
        email: "",
        mobile_number: "",
        cro_number: "",
        delivery_option: "",
        address: "",
        city: "",
        pin_code: "",
        total_fee: 0,
        fee_paid: 0,
        due_amount: 0,
        book_issued: "",
        remark: "",
        center_id: "",
        amount_after_discount: 0,
        image: "",
        product_id:"",
        product_varient_code:"",
        product_quantity:0,
        additional_payment_received:0,
        additional_payment_note:"",
        discount_amount:0,
        discount_amount_note:"",
        adjustment_amount:0,
        adjustment_amount_note:"",
        advance_amount:0,
        advance_amount_note:""        
    }

    // **** Form Validation Schema
    let validationSchema = Yup.object({
        student_name: Yup.string().required("Required !"),
        email: Yup.string().required("Required !"),
        mobile_number: Yup.number().required("Required !"),
        delivery_option: Yup.string().required("Please Select Delivery Option !"),

        address: Yup.string().when("delivery_option", {
            is: (delivery_option) => delivery_option == "HOME DELIVERY",
            then: Yup.string().required("Required !")
        }),

        city: Yup.string().when("delivery_option", {
            is: (delivery_option) => delivery_option == "HOME DELIVERY",
            then: Yup.string().required("Required !")
        }),

        pin_code: Yup.string().when("delivery_option", {
            is: (delivery_option) => delivery_option == "HOME DELIVERY",
            then: Yup.string().required("Required !")
        }),
        total_fee: Yup.number().typeError("Total Fees Should be Number").required("Required !"),
        product_quantity: Yup.number().typeError("Product quantity Should be Number").required("Required !"),
        fee_paid: Yup.number()
            .typeError("Fees Paid Should be Number")
            .test({
                name: "max",
                exclusive: false,
                params: {},
                message: "Fees paid should be less than or equal to Total Fees",
                test: (value, ctx) => value <= ctx.parent.total_fee,
            })
            .required("Required !"),
        center_id: Yup.string().required("Please Select Center !"),
    })

    // **** On Submit Handler
    const onSubmitHandler = (values) => {
        values = {
            ...values,
            due_amount: values.total_fee - values.fee_paid,
            amount_after_discount: values.total_fee,
            product_varient_code: values.product_id.varient_code,
            product_id: values.product_id._id,
        }  
        setViewCurrencyDetailsForm(true)
        setOrderFormData(values)
    }

    return (
            isAllCentersFetching ||
            isAllCentersLoading ||
            isAllProductFetching ||
            isAllProductLoading ?
            <SkeletonListing />
            :
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmitHandler}
            >
                {
                    ({ values, setFieldValue }) => (
                        <>
                            {!viewCurrencyDetailsForm ? (
                                <Form>
                                    <Grid container justifyContent="center">
                                        <Grid item xs={11} mt={5} >
                                            <Paper elevation={20}  >
                                                <Grid container justifyContent="space-between">

                                                    {/* **** Form Heading **** */}
                                                    <Grid container item xs={12} justifyContent="center" bgcolor="#5664d2" sx={{ borderBottomRightRadius: "20px" }} py={1} >
                                                        <Typography variant='h6' className='text-white' > Create a Pendrive Class Order </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={5} p={5}>

                                                    {/* Image */}
                                                    <Grid container item xs={12} spacing={3}>
                                                        <Grid item md={2.5} sm={3} xs={6}>
                                                            {
                                                                isWebCam &&
                                                                <Webcam
                                                                    audio={false}
                                                                    screenshotFormat="image/webp"
                                                                    videoConstraints={videoConstraints}
                                                                    height={200}
                                                                    style={{
                                                                        width: "100%",
                                                                        objectFit: "fill",
                                                                    }}
                                                                >
                                                                    {({ getScreenshot }) => (
                                                                        <Grid container justifyContent="center">
                                                                            <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                type="button"
                                                                                fullWidth
                                                                                onClick={() => {
                                                                                    const imageSrc = getScreenshot();
                                                                                    setImgSrc(imageSrc);
                                                                                }}
                                                                            >
                                                                                Capture Image
                                                                            </Button>
                                                                        </Grid>
                                                                    )}
                                                                </Webcam>
                                                            }
                                                        </Grid>

                                                        <Grid item md={2.5} sm={3} xs={6} >
                                                            {imgSrc && (
                                                                <Avatar
                                                                    variant="rounded"
                                                                    style={{ width: "100%", height: "200px" }}
                                                                    src={imgSrc}
                                                                    alt="Image"
                                                                />
                                                            )}
                                                        </Grid>

                                                        {/* **** Back Button **** */}
                                                        <Grid item xs={7} container justifyContent="flex-end" >
                                                            <Grid item >

                                                                <Button
                                                                    variant="contained"
                                                                    color="error"
                                                                    onClick={() => {
                                                                        setOpenOrderForm(false);
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    {/* <KeyboardBackspaceIcon />  */}
                                                                    {/* &nbsp; */}
                                                                    Back

                                                                </Button>
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>

                                                    {/* **** Name **** */}
                                                    <Grid item xs={3}>
                                                        <InputLabel htmlFor='student_name' > <span className='text-danger'> * </span> Name </InputLabel>
                                                        <Field name="student_name">
                                                            {
                                                                ({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        placeholder="Name"
                                                                        size='small'
                                                                        variant='standard'
                                                                        id="student_name"
                                                                        fullWidth
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                        {renderTextError("student_name")}
                                                    </Grid>

                                                    {/* **** Email **** */}
                                                    <Grid item xs={3}>
                                                        <InputLabel htmlFor='email' > <span className='text-danger'> * </span> Email </InputLabel>
                                                        <Field name="email">
                                                            {
                                                                ({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        placeholder="Email"
                                                                        size='small'
                                                                        variant='standard'
                                                                        id="email"
                                                                        fullWidth
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                        {renderTextError("email")}
                                                    </Grid>

                                                    {/* **** Mobile No. **** */}
                                                    <Grid item xs={3}>
                                                        <InputLabel htmlFor='mobile_number' > <span className='text-danger'> * </span> Mobile No. </InputLabel>
                                                        <Field name="mobile_number">
                                                            {
                                                                ({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        placeholder="Mobile No."
                                                                        size='small'
                                                                        variant='standard'
                                                                        id="mobile_number"
                                                                        fullWidth
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                        {renderTextError("mobile_number")}
                                                    </Grid>

                                                    {/* **** CRO No. **** */}
                                                    <Grid item xs={3}>
                                                        <InputLabel htmlFor='cro_number' >  CRO No. </InputLabel>
                                                        <Field name="cro_number">
                                                            {
                                                                ({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        placeholder="CRO No."
                                                                        size='small'
                                                                        variant='standard'
                                                                        id="cro_number"
                                                                        fullWidth
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                        {renderTextError("cro_number")}
                                                    </Grid>

                                                    {/* **** product **** */}
                                                    <Grid item xs={6}>
                                                        <InputLabel htmlFor='product_id' > <span className='text-danger'> * </span> Video Lecture </InputLabel>
                                                        <Field name="product_id">
                                                            {
                                                                ({ field }) => (
                                                                    <Autocomplete
                                                                        id="product_id"
                                                                        value={values.product_id}
                                                                        onChange={(event, newValue) => {
                                                                            setFieldValue("product_id", newValue)
                                                                            setSelectedProduct(newValue._id)
                                                                        }}
                                                                        // filterSelectedOptions= {true}
                                                                        options={allProduct && allProduct.data}
                                                                        getOptionLabel={(option) => option.video_lecture_name || ""}
                                                                        isOptionEqualToValue={(opt, value) => opt._id === value._id}
                                                                        renderOption={(props, option, { selected }) => (
                                                                            <li {...props}>
                                                                                <Checkbox
                                                                                    icon={icon}
                                                                                    checkedIcon={checkedIcon}
                                                                                    style={{ marginRight: 8 }}
                                                                                    checked={selected}
                                                                                />
                                                                                {option.video_lecture_name}
                                                                            </li>
                                                                        )}
                                                                        // style={{ width: 500 }}
                                                                        renderInput={(params) => (
                                                                            <TextField variant='standard' {...params} />
                                                                        )}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </Grid>

                                                    {/* **** varient **** */}
                                                    {varient &&
                                                    <Grid item xs={6}>
                                                        <InputLabel htmlFor='product_varient_code' > <span className='text-danger'> * </span> Varient </InputLabel>
                                                        <Field name="product_varient_code">
                                                            {
                                                                ({ field }) => (
                                                                    <Autocomplete
                                                                        id="product_varient_code"
                                                                        value={values.product_varient_code}
                                                                        onChange={(event, newValue) => {
                                                                            setFieldValue("product_varient_code", newValue)
                                                                        }}
                                                                        options={varient?.data?.varient}
                                                                        getOptionLabel={(option) => option.mode_name || ""}
                                                                        isOptionEqualToValue={(opt, value) => opt.varient_code === value.varient_code}
                                                                        renderOption={(props, option, { selected }) => (
                                                                            <li {...props}>
                                                                                <Checkbox
                                                                                    icon={icon}
                                                                                    checkedIcon={checkedIcon}
                                                                                    style={{ marginRight: 8 }}
                                                                                    checked={selected}
                                                                                />
                                                                                {option.mode_name}
                                                                            </li>
                                                                        )}
                                                                        renderInput={(params) => (
                                                                            <TextField variant='standard' {...params} />
                                                                        )}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </Grid>}  

                                                    {/* **** Center **** */}
                                                    <Grid item xs={3}>
                                                        <InputLabel htmlFor='center_id' > <span className='text-danger'> * </span> Center </InputLabel>
                                                        <Field name="center_id">
                                                            {
                                                                ({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        fullWidth
                                                                        id="center"
                                                                        size="small"
                                                                        variant='standard'

                                                                    >
                                                                        {allCenters &&
                                                                            allCenters.data.map((ele) => {
                                                                                return (
                                                                                    <MenuItem value={ele._id}> {ele.center_name} </MenuItem>
                                                                                );
                                                                            })}
                                                                    </Select>
                                                                )
                                                            }
                                                        </Field>
                                                        {renderTextError("center_id")}
                                                    </Grid>

                                                    {/* **** Total Fees **** */}
                                                    <Grid item xs={3}>
                                                        <InputLabel htmlFor='total_fee' > <span className='text-danger'> * </span> Total Fees </InputLabel>
                                                        <Field name="total_fee" >
                                                            {
                                                                ({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        placeholder="Total Fees"
                                                                        size='small'
                                                                        variant='standard'
                                                                        id="total_fee"
                                                                        fullWidth
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                        {renderTextError("total_fee")}
                                                    </Grid>

                                                    {/* **** Fees Paid **** */}
                                                    <Grid item xs={3}>
                                                        <InputLabel htmlFor='fee_paid' > <span className='text-danger'> * </span> Fees Paid </InputLabel>
                                                        <Field name="fee_paid">
                                                            {
                                                                ({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        placeholder="Fees Paid"
                                                                        size='small'
                                                                        variant='standard'
                                                                        id="fee_paid"
                                                                        fullWidth
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                        {renderTextError("fee_paid")}
                                                    </Grid>

                                                    {/* **** Remaining Fees **** */}
                                                    <Grid item xs={3}>
                                                        <InputLabel htmlFor='remaining_fees' > <span className='text-danger'> * </span> Remaining Fees </InputLabel>
                                                        <Field  >
                                                            {
                                                                () => (
                                                                    <TextField
                                                                        placeholder="Remaining Fees"
                                                                        size='small'
                                                                        variant='standard'
                                                                        id="remaining_fees"
                                                                        value={values.total_fee ? (values.total_fee - values.fee_paid) : 0}
                                                                        disabled
                                                                        fullWidth
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </Grid>

                                                    {/* **** Adjustment Amount **** */}
                                                    <Grid item xs={3}>
                                                        <InputLabel htmlFor='adjustment_amount' > <span className='text-danger'> * </span> Adjustment Amount </InputLabel>
                                                        <Field name="adjustment_amount" >
                                                            {
                                                                ({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        placeholder="Adjustment Amount"
                                                                        size='small'
                                                                        variant='standard'
                                                                        id="adjustment_amount"
                                                                        fullWidth
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                        {renderTextError("adjustment_amount")}
                                                    </Grid>

                                                    {/* **** adjustment_amount_note **** */}
                                                    <Grid item xs={3}>
                                                        <InputLabel htmlFor='adjustment_amount_note' >  Adjustment Amount Note </InputLabel>
                                                        <Field name="adjustment_amount_note">
                                                            {
                                                                ({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        placeholder="Adjustment Amount Note"
                                                                        size='small'
                                                                        variant='standard'
                                                                        id="adjustment_amount_note"
                                                                        fullWidth
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                        {renderTextError("adjustment_amount_note")}
                                                    </Grid>

                                                    {/* **** Advance Amount **** */}
                                                    <Grid item xs={3}>
                                                        <InputLabel htmlFor='advance_amount' > <span className='text-danger'> * </span> Advance Amount </InputLabel>
                                                        <Field name="advance_amount" >
                                                            {
                                                                ({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        placeholder="Advance Amount"
                                                                        size='small'
                                                                        variant='standard'
                                                                        id="advance_amount"
                                                                        fullWidth
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                        {renderTextError("advance_amount")}
                                                    </Grid>

                                                    {/* **** advance_amount_note **** */}
                                                    <Grid item xs={3}>
                                                        <InputLabel htmlFor='advance_amount_note' >  Advance Amount Note </InputLabel>
                                                        <Field name="advance_amount_note">
                                                            {
                                                                ({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        placeholder="Advance Amount Note"
                                                                        size='small'
                                                                        variant='standard'
                                                                        id="advance_amount_note"
                                                                        fullWidth
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                        {renderTextError("advance_amount_note")}
                                                    </Grid>

                                                    {/* **** Book Issued **** */}
                                                    <Grid item xs={3}>
                                                        <InputLabel htmlFor='book_issued' > Book Issued </InputLabel>
                                                        <Field name="book_issued">
                                                            {
                                                                ({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        placeholder="Book Issued"
                                                                        size='small'
                                                                        variant='standard'
                                                                        id="book_issued"
                                                                        fullWidth
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                        {renderTextError("book_issued")}
                                                    </Grid>

                                                    {/* **** Delivery Option**** */}
                                                    <Grid item xs={3}>
                                                        <InputLabel htmlFor='delivery_option' > <span className='text-danger'> * </span> Delivery Option </InputLabel>
                                                        <Field name="delivery_option" >
                                                            {
                                                                ({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        fullWidth
                                                                        id="delivery_option"
                                                                        size="small"
                                                                        variant='standard'

                                                                    >

                                                                        <MenuItem value="PICK UP">Pick Up</MenuItem>
                                                                        <MenuItem value="HOME DELIVERY">Home Delivery</MenuItem>
                                                                    </Select>
                                                                )
                                                            }
                                                        </Field>
                                                        {renderTextError("delivery_option")}
                                                    </Grid>

                                                    {/* **** Product Quantity **** */}
                                                    <Grid item xs={3}>
                                                        <InputLabel htmlFor='product_quantity' > <span className='text-danger'> * </span> Product Quantity </InputLabel>
                                                        <Field name="product_quantity" >
                                                            {
                                                                ({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        placeholder="Product Quantity"
                                                                        size='small'
                                                                        variant='standard'
                                                                        id="product_quantity"
                                                                        fullWidth
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                        {renderTextError("product_quantity")}
                                                    </Grid>

                                                    {
                                                        values.delivery_option == "HOME DELIVERY" &&
                                                        <>
                                                            {/* **** Address  **** */}
                                                            < Grid item xs={3}>
                                                                <InputLabel htmlFor="address" > <span className='text-danger'> * </span> Address </InputLabel>
                                                                <Field name="address" >
                                                                    {
                                                                        ({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                placeholder="address"
                                                                                fullWidth={true}
                                                                                variant="standard"
                                                                                size="small"
                                                                            />
                                                                        )
                                                                    }
                                                                </Field>
                                                                {renderTextError("address")}
                                                            </Grid>

                                                            {/* **** City **** */}
                                                            <Grid item xs={3}>
                                                                <InputLabel htmlFor='city' > <span className='text-danger'> * </span> City </InputLabel>
                                                                <Field name="city" >
                                                                    {
                                                                        ({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                placeholder="City"
                                                                                size='small'
                                                                                variant='standard'
                                                                                id="city"
                                                                                fullWidth
                                                                            />
                                                                        )
                                                                    }
                                                                </Field>
                                                                {renderTextError("city")}
                                                            </Grid>

                                                            {/* **** Pin Code **** */}
                                                            <Grid item xs={3}>
                                                                <InputLabel htmlFor='pin_code' > <span className='text-danger'> * </span> Pin Code </InputLabel>
                                                                <Field name="pin_code" >
                                                                    {
                                                                        ({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                placeholder="Pin Code"
                                                                                size='small'
                                                                                variant='standard'
                                                                                id="pin_code"
                                                                                fullWidth
                                                                            />
                                                                        )
                                                                    }
                                                                </Field>
                                                                {renderTextError("pin_code")}
                                                            </Grid>
                                                        </>
                                                    }

                                                    {/* **** Remark **** */}
                                                    <Grid item xs={12}>
                                                        <InputLabel htmlFor='remark' >  Remark </InputLabel>
                                                        <Field name="remark">
                                                            {
                                                                ({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        placeholder="Remark"
                                                                        size='small'
                                                                        variant='standard'
                                                                        id="remark"
                                                                        fullWidth
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                        {renderTextError("remark")}
                                                    </Grid>

                                                    {/* Button */}
                                                    <Grid item xs={12} >
                                                        <Button className="translate-btn" variant="contained" sx={{ backgroundColor: "#5664d2" }} fullWidth type="submit">
                                                            SAVE
                                                        </Button>

                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                                : (

                                    <AddCurrencyDetailsForm
                                        onClose={setViewCurrencyDetailsForm}
                                        paidFees={values.fee_paid}
                                        data={orderFormData}
                                        orderFor="pendrive_class"
                                    />
                                )
                            }
                        </>
                    )
                }
            </Formik >
    )
}

export default AddPendriveClassOrder
