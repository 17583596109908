
import React, { useState, useEffect, Link, state } from 'react';
import EditDelete from './EditDelete';
import CreateTableAddButton from './CreateTableAddButton'
import Updateslduser from './Updateslduser'
import AddSldUser from './AddSldUser';
import ViewSldUsers from './ViewSldUsers';
import Loader from './Loader';
import AddDataHeader from './AddDataHeader';
import {
    useGetAllSldUserMutation,
    useDeletSldUserMutation,
    useAddSldUserMutation,
    useUpdateSldUserMutation
} from '../../../services/services';
import Toster from './Toster';
import SkeletonListing from './SkeletonListing';
import DataNotFound from './DataNotFound';
import moment from 'moment';
import KeyUserAccess from './KeyUserAccess';


export default function SldUsers() {
    const columns = [
        {
            id: 'SNo',
            label: 'S.No',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'name',
            label: 'Name',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'username',
            label: 'Username',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'group',
            label: 'Group',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'mobile',
            label: 'Mobile Number',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'createat',
            label: 'Create-At',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'actions',
            label: 'Actions',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },

    ];

    function createData(SNo, name, username, group, mobile, createat, actions) {
        return { SNo, name, username, group, mobile, createat, actions };
    }

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const paginationData = {
        "limit": rowsPerPage,
        "query": "",
        "params": [
            "name"
        ],
        "page": page + 1
    }

    let rows = []
    let [getid, setGetid] = useState('')
    const [data, setData] = useState('')
    const [AddDialog, setDialog] = useState(false)
    const [editDialog, setEditDialog] = useState(false)
    const [keyAccess, setKeyAccess] = useState(false)
    const [viewDialog, setViewDialog] = useState(false)
    const [getAllData, getAllDataInfo] = useGetAllSldUserMutation()
    const [deleteData, deleteDataInfo] = useDeletSldUserMutation()
    const [addData, addDataInfo] = useAddSldUserMutation();
    const [updateData, updateDataInfo] = useUpdateSldUserMutation()
    const AllData = getAllDataInfo.data
    const totalItem = AllData ? AllData.totalItem : 0
    const [render, setRender] = useState(false)

    useEffect(() => {
        getAllData(paginationData)
    }, [render, rowsPerPage, page,])

    useEffect(() => {
        getid && deleteData(getid) && setGetid("")
    }, [getid])

    const AddData = (data) => {
        addData(data)
    }
    const UpdateData = (data, id) => {
        updateData({
            body: data,
            id: id
        })
    }

    const UpdateDataAccess = (data, id) => {
          
        updateData({
            body: data,
            id: id
        })
    }
    let totalData = "ok"
    if (AllData) {
        totalData = AllData.totalItem
        rows = AllData.data.map((data, index) => createData(
            index + 1,
            <div className="">
                <span className="text-info font-weight-bold" >{data.name}</span><br />
                <span className="text-muted" >({data.email})</span>
            </div>,
            data.username,
            data.group_name,
            data.mobile,
            moment(data.createdAt).format("DD-MM-YYYY hh:mm:ss A"),
            <div className="d-flex justify-content-center">

                <EditDelete
                    type="view"
                    setData={setData}
                    setViewDialog={setViewDialog}
                    id={data._id}
                    data={data}
                />

                <EditDelete
                    type="edit"
                    setData={setData}
                    setEditDialog={setEditDialog}
                    id=""
                    
                    data={data} />

                <EditDelete type="delete"
                    setGetid={setGetid}
                    id={data._id}
                     />

                <EditDelete type="keyaccess"
                    setData={setData}
                    setKeyAccessDialog={setKeyAccess}
                    id={data._id}
                    
                    data={data} />
            </div>)
        )
    }
    const dialogclose = () => {
        setEditDialog(false)
        setKeyAccess(false)
        setViewDialog(false)
        setDialog(false)
        setRender(!render)
    }
    return (
        <div>
            <AddDataHeader
                addFormName={"Add Sld User"}
                setPage={setPage}
                page={page}
                excelBtn={false}
                totalItem={totalItem}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setAddDialog={setDialog}
                searchBarApi={getAllData}
                paginationData={paginationData}
                isButton={true}
                Adddialog={AddDialog}
                form={<AddSldUser AddData={AddData} setDialog={setDialog} />}
            />
          

            {addDataInfo.isLoading || deleteDataInfo.isLoading || updateDataInfo.isLoading ? <Loader /> : <>
                {
                    !viewDialog ?
                        rows.length ?
                            <CreateTableAddButton
                                maxWidth="md"
                                fullWidth={false}
                                editDialog={editDialog}
                                rows={rows}
                                editform={
                                    <Updateslduser
                                        UpdateData={UpdateData}
                                        editfaculty={data}
                                        editDialog={setEditDialog} />
                                }
                                columns={columns} isButton={true}
                                editFormName={'Edit Sld User'} />
                            : totalData == 0 ? <DataNotFound /> : <SkeletonListing />
                        :
                         <ViewSldUsers viewDialog={setViewDialog} data={data} />
                        
                                
                            
                
                }

                
                   
                
            </>}
            { keyAccess && <KeyUserAccess UpdateData={UpdateDataAccess} data={data} keyAccess={keyAccess} setKeyAccess={setKeyAccess}/>}
            {addDataInfo.data && <Toster info={addDataInfo} dialogclose={dialogclose} />}
            {deleteDataInfo.data && <Toster info={deleteDataInfo} dialogclose={dialogclose} />}
            {updateDataInfo && <Toster info={updateDataInfo} dialogclose={dialogclose} />}
        </div>
    )
}
