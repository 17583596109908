import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const configurationDataApi = createApi({
  tagTypes: ["configData"],
  reducerPath: "configurationDataApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/config-data/`,
  }),
  endpoints: (builder) => ({
    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET ALL >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    GetAllConfigData: builder.query({
      providesTags: ["configData"],
      query: (key) => {
        return {
          url: "all-config-data",
          headers: {
            "x-access-token": token,
          },
          params: {
            key,
          },
          method: "GET",
        };
      },
    }),
    updateConfigData: builder.mutation({
      query({ body, id }) {
        return {
          url: `${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["configData"],
    }),

    deleteConfigData: builder.mutation({
      query({ body, id }) {
        return {
          url: `${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["configData"],
    }),
  }),
});

export const {
  useGetAllConfigDataQuery,
  useUpdateConfigDataMutation,
  useDeleteConfigDataMutation,
} = configurationDataApi;
