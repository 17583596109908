import Delete from "@material-ui/icons/Delete";
import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem("__authToken__");
export const cartApi = createApi({
  tagTypes: ["cart"],
  reducerPath: "cart",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/cart/`,
  }),
  endpoints: (builder) => ({

    GetCart: builder.query({
      providesTags: ["cart"],
      query: (body) => {
        return {
          url: "view-cart",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,

        }
      }
    }),

    DeleteCartProduct: builder.mutation({
      invalidatesTags: ["cart"],
      query: (body) => {
        return {
          url: "remove-item",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,

        }
      }

    }),

    getApplyReferralCode: builder.mutation({
      invalidatesTags: ["cart"],
      query: (body) => {
        return {
          url: "apply-referral-code",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,

        }
      }


    }),
    getApplyCoupenCode: builder.mutation({
      invalidatesTags: ["cart"],
      query: (body) => {
        return {
          url: "coupon-apply",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,

        }
      }


    }),
    updateProductQnty: builder.mutation({
      invalidatesTags: ["cart"],
      query: (body) => {
        return {
          url: "change-quantity",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,

        }
      }

    }),
    addToCartProduct: builder.mutation({
      invalidatesTags: ["cart"],
      query: (body) => {
        return {
          url: "add-to-cart",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,

        }
      }

    }),


  }),

});

export const {
  useGetCartQuery,
  useDeleteCartProductMutation,
  useGetApplyReferralCodeMutation,
  useUpdateProductQntyMutation,
  useAddToCartProductMutation,
  useGetApplyCoupenCodeMutation,
} = cartApi;