import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const specializationApi = createApi({
  tagTypes: ["specialization"],
  reducerPath: "specializationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/specialization/`,
  }),
  endpoints: (builder) => ({
    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET "specialization" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    GetAllSpecialization: builder.query({
      providesTags: ["specialization"],
      query: (body) => {
        return {
          url: "",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ADD "specialization" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    DeleteSpecialization: builder.mutation({
      invalidatesTags: ["specialization"],
      query: (id) => {
        return {
          url:id,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
          
        };
      },
    }),

    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< UPDATE "specialization" IS_SHOW STATUS  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    AddSpecialization: builder.mutation({
      invalidatesTags: ["specialization"],
      query: (body) => {
        return {
          url: "add",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET "SMS templet List" >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    getSpecializationList: builder.query({
      query: () => {
        return {
          url: "all-specialization" , 
          headers: {
            "x-access-token": token,
          },
          method: "GET",
          
        };
      },
    }),

  }),
});

export const {
  useDeleteSpecializationMutation,
  useGetAllSpecializationQuery,
  useAddSpecializationMutation,
  useGetSpecializationListQuery
} = specializationApi;



