import React,{useEffect, useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Fab, IconButton, TextField } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import { CSVLink } from 'react-csv'
import { Grid,Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Zoom from '@material-ui/core/Zoom';
import Dialog from '@material-ui/core/Dialog';
import AddEvent from './AddEvent';

export const ExportReactCSV = ({csvData, fileName}) => {
  return (
      <Button variant="warning">
          <CSVLink data={csvData} filename={fileName}>Export</CSVLink>
      </Button>
  )
}



const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 18,
    fontWeight:'bold'
  },
  body: {
    fontSize: 17,
  },
}))(TableCell);



const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function TableData(props) {
 
  const classes = useStyles();  
  const [columns,setColumns]=useState([])
  const [page, setPage] = useState(0);  
  const[rows,setRows]=useState([])
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

    const handleClickOpen = (scrollType) => () => {
      setOpen(true);
      setScroll(scrollType);
  };

  const handleClose = () => {
      setOpen(false);
  };

  const columnsData=props.columnsData;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.data && props.data.length - page * rowsPerPage);

  useEffect(()=>{
    // fetch('https://jsonplaceholder.typicode.com/users').
    // then(response=>response.json()).
    // then(result=>setUserList(result)).catch(error=>(  
    
    setRows(props.data)
  },[] )

  
  const handleSearch=(val)=>{
    
    const filteredRow=props.data.filter(row=>row.name.toLowerCase().includes(val.toLowerCase())||row.username.toLowerCase().includes(val.toLowerCase()))
    setRows(filteredRow)    
    }
  return (

    <div>
      <Grid container style={{marginTop:25,marginBottom:25,marginLeft:15}}>
      <Grid item xs={1}>
      {props.isButton && <Fab onClick={handleClickOpen('paper')} color="primary" ><AddIcon/></Fab>}
        </Grid>
        <Grid item xs={4}>
        <TextField fullWidth={true} label="Search" variant="outlined" onChange={(e)=>{handleSearch(e.target.value)}}/>        
        </Grid>
        <Grid item xs={2} style={{marginLeft:"5%"}}>
        <CSVLink data={props.data}><Fab color="primary" style={{fontWeight:500}} variant="extended"><GetAppIcon style={{marginRight:10}}/>Download csv</Fab></CSVLink>
        </Grid>
        <Grid item xs={4}>
        <TablePagination
          rowsPerPageOptions={[3,5,8, 10, 20]}
          component="div"
          count={props.data && props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </Grid>
      </Grid>
      {props.isButton && (<Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth="lg"

                TransitionComponent={Zoom}

            >
                <DialogTitle id="scroll-dialog-title">{props.addFormName}</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>

                    {props.form}
                </DialogContent>
                <DialogActions style={{ marginRight: 20 }}>
                    
                </DialogActions>
            </Dialog>)}
      
       
      <TableContainer component={Paper}>
      <Table exportButton={true} className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {columnsData.map(column=>
            <StyledTableCell align="center" key={column.id}>{column.name}</StyledTableCell>
            )}
            
          </TableRow>
        </TableHead>
        <TableBody>          
        {rows && rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(user=> 
        (
        <StyledTableRow>
          <StyledTableCell>{user.id}</StyledTableCell>
          <StyledTableCell align="center">{user.name}</StyledTableCell>
          <StyledTableCell align="center">{user.username}</StyledTableCell>
          <StyledTableCell align="center">{user.email}</StyledTableCell>
          <StyledTableCell align="center">{user.website}</StyledTableCell>
          <StyledTableCell align="center">{user.phone}</StyledTableCell>
        </StyledTableRow>
        ))}
        </TableBody>
      </Table>
    </TableContainer>

    
    </div>
  )
}
