import React, { useState, useEffect } from "react";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import * as yup from 'yup'
import { useFormik } from 'formik';
import {
  Grid,
  Button,
  Card,
  Box,
  CardContent,
  Dialog, Zoom, DialogTitle, DialogContent, TextField
} from "@material-ui/core";
import {
  useUpdateFacultyMutation,
  useGetSingleFacultyMutation
} from '../../../../services/services'
import DataNotFound from "../DataNotFound";
import { Link } from "react-router-dom";


export default function Links({ viewDialog, data, setFacultyData }) {

  const [updateData, updateDataInfo] = useUpdateFacultyMutation();
  const [singleFacultyData, singleFacultyDataInfo] = useGetSingleFacultyMutation();
  const [updateDialog, setUpdateDialog] = useState(false);

  useEffect(() => {
    singleFacultyData(data._id)
  }, [updateDataInfo])

  const dialogclose = () => {
    setUpdateDialog(false);
  };

  return (
    <>
      <Card sx={{ display: "flex" }} className="m-3" >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>

            <Button
              onClick={() => viewDialog(false)}
              className="text-light bg-danger float-right btn btn-sm btn-danger"
            >
              Close
            </Button>
          </CardContent>

          {singleFacultyDataInfo.isSuccess == true && singleFacultyDataInfo.data.data.length ?
            singleFacultyDataInfo.isLoading == false ?
              <div>
                {setFacultyData(singleFacultyDataInfo.data.data[0])}
                <Button
                  onClick={() => {
                    setUpdateDialog(true);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Update Links
                </Button>

                <Card className="m-3 pb-3">
                  <Grid
                    container
                    spacing={1}
                    direction="column"
                    alignItems="left"
                    justifyContent="left"
                  >
                    <Grid item xs={12}>
                      {

                        <CardContent className="bg-light p-3 border-primary infoCards">

                          <Grid container spacing={6}>
                            <Grid className="text-muted text-left" item md={6}>
                              <h5>Portal Detail</h5>
                            </Grid>

                            <Grid className="text-muted text-left" item md={6}>
                              <h5>{singleFacultyDataInfo.data.data[0].portal_details}</h5>
                            </Grid>

                          </Grid>
                          <hr />

                          <Grid container spacing={6}>
                            <Grid className="text-muted text-left" item md={6}>
                              <h5>Portal Link</h5>

                            </Grid>
                            <Grid className="text-muted text-left" item md={6}>
                              <a href={singleFacultyDataInfo.data.data[0].portal_link}  target="_blank">{singleFacultyDataInfo.data.data[0].portal_link}</a>

                            </Grid>

                          </Grid>
                          <hr />


                        </CardContent>
                      }

                    </Grid>
                  </Grid>
                </Card>


                {updateDialog ? <UpdateLinksDetails
                  updateDialog={updateDialog}
                  setUpdateDialog={setUpdateDialog}
                  updateData={updateData}
                  facultyId={data._id}
                  currentData={{
                    // order_google_sheet_link: singleFacultyDataInfo.data.data[0].order_google_sheet_link,
                    portal_link: singleFacultyDataInfo.data.data[0].portal_link , 
                    portal_details : singleFacultyDataInfo.data.data[0].portal_details
                  }} /> : null}
                {updateDataInfo.data && (
                  <Toster info={updateDataInfo} dialogclose={dialogclose} />
                )}
              </div>
              :
              <SkeletonListing />

            :
            <Grid container justifyContent="center">
              <DataNotFound />
            </Grid>

          }

        </Box>
      </Card>
    </>

  );
}

function UpdateLinksDetails({ updateDialog, setUpdateDialog, updateData, facultyId, currentData }) {

  const formik = useFormik({
    initialValues: {
      ...currentData
    },
    validationSchema: yup.object({
      portal_details: yup.string().required("Required!"),
      portal_link: yup.string().required("Required!")
    }),
    onSubmit: (values, e) => {
      var form_data = new FormData()
      form_data.append('key', 'links')
      form_data.append('update_data', JSON.stringify(values))
      updateData({ data: form_data, id: facultyId })
    }
  })

  return (
    <Dialog
      fullScreen={false}
      open={updateDialog}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
      TransitionComponent={Zoom}
    >
      <DialogTitle id="scroll-dialog-title">Update Links</DialogTitle>
      <DialogContent
      >
        <form id="updateForm" onSubmit={formik.handleSubmit}>
          <Grid style={{ paddingLeft: 10, paddingRight: 10, marginTop: 20, marginLeft: 10 }} >
            <Grid container spacing={1} >

              <Grid item xs={12} className = "mb-4">
                <TextField
                  name="portal_details"
                  label="Portal Detail"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.portal_details}
                  fullWidth={true}
                  variant="outlined"
                  size="medium" />
                {formik.touched.portal_details && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.portal_details}</span>}
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="portal_link"
                  label="Portal Link"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.portal_link}
                  fullWidth={true}
                  variant="outlined"
                  size="medium" />
                {formik.touched.portal_link && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.portal_link}</span>}
              </Grid>

            </Grid>
            <br />
            <br />
            <Grid container spacing={3} >
              <Grid item xs={12}>
                <div className="text-right">
                  <Button
                    onClick={() => setUpdateDialog(false)}
                    variant="contained"
                    color="secondary">
                    Close</Button>
                  <Button
                    type="submit"
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    color="primary">
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid >
        </form >
      </DialogContent>
    </Dialog>
  )

}
