import React from "react";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";

import { useFormik } from "formik";
import * as yup from "yup";

export default function AddSubject({ AddData, setDialog }) {
  const formik = useFormik({
    initialValues: {
      subject_name: "",
      subject_code: "",
      short_name: "",
      metaKeywords: "",
      metaDescription: "",
      bookMetaDescription: "",
      bookMetaKeywords: "",
      testSeriesMetaDescription: "",
      testSeriesMetaKeywords: "",
    },
    validationSchema: yup.object({
      subject_name: yup.string().required("subject name is required"),
      subject_code: yup.string().required("subject code is required"),
      short_name: yup.string().required("short name is required"),
      metaKeywords: yup.string().required("Required"),
      metaDescription: yup.string().required("Required"),
      bookMetaDescription: yup.string().required("Required"),
      bookMetaKeywords: yup.string().required("Required"),
      testSeriesMetaDescription: yup.string().required("Required"),
      testSeriesMetaKeywords: yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      AddData(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        name="subject_name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.subject_name}
        fullWidth={true}
        label="Subject name "
        variant="outlined"
        size="small"
        multiline
      />
      {formik.touched.subject_name && (
        <span style={{ color: "red", fontSize: "12px" }}>
          {formik.errors.subject_name}
        </span>
      )}

      <br />
      <br />

      <TextField
        name="subject_code"
        onChange={formik.handleChange}
        InputProps={{ inputProps: { min: 0 } }}
        onBlur={formik.handleBlur}
        value={formik.values.subject_code}
        fullWidth={true}
        type="text"
        min="0"
        label="Subject Code "
        variant="outlined"
        size="small"
      />
      {formik.touched.subject_code && (
        <span style={{ color: "red", fontSize: "12px" }}>
          {formik.errors.subject_code}
        </span>
      )}

      <br />
      <br />
      <TextField
        name="short_name"
        onChange={formik.handleChange}
        InputProps={{ inputProps: { min: 0 } }}
        onBlur={formik.handleBlur}
        value={formik.values.short_name}
        fullWidth={true}
        type="text"
        min="0"
        label="Short Name"
        variant="outlined"
        size="small"
      />
      {formik.touched.short_name && (
        <span style={{ color: "red", fontSize: "12px" }}>
          {formik.errors.short_name}
        </span>
      )}

      <br />
      <br />

      <TextField
        name="metaDescription"
        onChange={formik.handleChange}
        InputProps={{ inputProps: { min: 0 } }}
        onBlur={formik.handleBlur}
        value={formik.values.metaDescription}
        fullWidth={true}
        type="text"
        min="0"
        label="Meta Description"
        variant="outlined"
        size="small"
      />
      {formik.touched.metaDescription && (
        <span style={{ color: "red", fontSize: "12px" }}>
          {formik.errors.metaDescription}
        </span>
      )}

      <br />
      <br />

      <TextField
        name="metaKeywords"
        onChange={formik.handleChange}
        InputProps={{ inputProps: { min: 0 } }}
        onBlur={formik.handleBlur}
        value={formik.values.metaKeywords}
        fullWidth={true}
        type="text"
        min="0"
        label="Meta Keywords"
        variant="outlined"
        size="small"
      />
      {formik.touched.metaKeywords && (
        <span style={{ color: "red", fontSize: "12px" }}>
          {formik.errors.metaKeywords}
        </span>
      )}

      <br />
      <br />
      <TextField
        name="bookMetaDescription"
        onChange={formik.handleChange}
        InputProps={{ inputProps: { min: 0 } }}
        onBlur={formik.handleBlur}
        value={formik.values.bookMetaDescription}
        fullWidth={true}
        type="text"
        min="0"
        label="Book Meta Description"
        variant="outlined"
        size="small"
      />
      {formik.touched.bookMetaDescription && (
        <span style={{ color: "red", fontSize: "12px" }}>
          {formik.errors.bookMetaDescription}
        </span>
      )}

      <br />
      <br />

      <TextField
        name="bookMetaKeywords"
        onChange={formik.handleChange}
        InputProps={{ inputProps: { min: 0 } }}
        onBlur={formik.handleBlur}
        value={formik.values.bookMetaKeywords}
        fullWidth={true}
        type="text"
        min="0"
        label="Book Meta Keywords"
        variant="outlined"
        size="small"
      />
      {formik.touched.bookMetaKeywords && (
        <span style={{ color: "red", fontSize: "12px" }}>
          {formik.errors.bookMetaKeywords}
        </span>
      )}

      <br />
      <br />
      <TextField
        name="testSeriesMetaDescription"
        onChange={formik.handleChange}
        InputProps={{ inputProps: { min: 0 } }}
        onBlur={formik.handleBlur}
        value={formik.values.testSeriesMetaDescription}
        fullWidth={true}
        type="text"
        min="0"
        label="Test Series Meta Description"
        variant="outlined"
        size="small"
      />
      {formik.touched.testSeriesMetaDescription && (
        <span style={{ color: "red", fontSize: "12px" }}>
          {formik.errors.testSeriesMetaDescription}
        </span>
      )}

      <br />
      <br />

      <TextField
        name="testSeriesMetaKeywords"
        onChange={formik.handleChange}
        InputProps={{ inputProps: { min: 0 } }}
        onBlur={formik.handleBlur}
        value={formik.values.testSeriesMetaKeywords}
        fullWidth={true}
        type="text"
        min="0"
        label="Test Series Meta Keywords"
        variant="outlined"
        size="small"
      />
      {formik.touched.testSeriesMetaKeywords && (
        <span style={{ color: "red", fontSize: "12px" }}>
          {formik.errors.testSeriesMetaKeywords}
        </span>
      )}

      <br />
      <br />
      <Grid container>
        <Grid xs={6}></Grid>
        <Grid className="d-flex justify-content-end" xs={6}>
          <Button
            className="mr-3"
            onClick={() => setDialog(false)}
            variant="contained"
            color="secondary"
          >
            Close
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
