import React from 'react'
import '../../.././ViewBook.css'
import { Button, makeStyles, withStyles, } from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LocalLibraryTwoToneIcon from '@material-ui/icons/LocalLibraryTwoTone';
import TitleIcon from '@material-ui/icons/Title';
import SubjectIcon from '@material-ui/icons/Subject';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import DescriptionIcon from '@material-ui/icons/Description';
import LanguageIcon from '@material-ui/icons/Language';
import PaymentIcon from '@material-ui/icons/Payment';
import LayersIcon from '@material-ui/icons/Layers';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import PersonIcon from '@material-ui/icons/Person';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CropFreeIcon from '@material-ui/icons/CropFree';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: 18,
        flexBasis: '33.33%',
        flexShrink: 0,

    },
    table: {
        padding: 10,
        margin: 5

    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));


export default function ViewBook({ viewDialog, data }) {
    const classes = useStyles();

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);
      
    return (
        <div className="d-flex justify-content-center my-3">

            <Button onClick={() => {
                viewDialog(false)
            }} style={{ position: "absolute", right: -10, zIndex: 1000, top: 120 }}>
                <HighlightOffIcon color='secondary' />
            </Button>

            <TableContainer style={{ maxWidth: 570 }} className="m-2 border">
                <Table className={classes.table} aria-label="customized table">
                    <TableBody>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><LocalLibraryTwoToneIcon className="mx-4" fontSize='medium' />   Book Name </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted"> {data.book_name} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><TitleIcon className="mx-4" fontSize='medium' />   Book Title </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted"> {data.title} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><VpnKeyIcon className="mx-4" fontSize='medium' />   Book Code </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted"> {data.book_code} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><SubjectIcon className="mx-4" fontSize='medium' />   Subject Name </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted"> {data.subject} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><CollectionsBookmarkIcon className="mx-4" fontSize='medium' />   Book Edition </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted"> {data.edition} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><FormatAlignCenterIcon className="mx-4" fontSize='medium' />   Book Formate </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted">   {data.format} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><DescriptionIcon className="mx-4" fontSize='medium' />IMP Description </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted">  {data.imp_description} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><LanguageIcon className="mx-4" fontSize='medium' /> Language </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted">  {data.language} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                    </TableBody>
                </Table>
            </TableContainer>

            <TableContainer style={{ maxWidth: 570 }} className="m-2 border">
                <Table className={classes.table} aria-label="customized table">
                    <TableBody>


                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><PaymentIcon className="mx-4" fontSize='medium' />   MRP </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted">   {data.mrp} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><LayersIcon className="mx-4" fontSize='medium' />No Of Pages </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted">   {data.no_of_pages} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><LocalMallIcon className="mx-4" fontSize='medium' />   Quantity </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted">  {data.quantity} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><MergeTypeIcon className="mx-4" fontSize='medium' />   Type </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted">   {data.type} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><FitnessCenterIcon className="mx-4" fontSize='medium' />   Weight </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted">   {data.weight} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><PersonIcon className="mx-4" fontSize='medium' />   Faculty Name </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted">   {data.faculty.map((faculty) => faculty.faculty_name + ", ")} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><AttachmentIcon className="mx-4" fontSize='medium' />   Binding </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted">   {data.binding} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><CropFreeIcon className="mx-4" fontSize='medium' />   ISBN </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted">   {data.isbn} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
