import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const excelLogsApi = createApi({
    // tagTypes: ["excelLogs"],
    reducerPath: "excelLogsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/excel-log/`,
    }),
    endpoints: (build) => ({

        getAllExcelLogs: build.mutation({
            // invalidatesTags: ["excelLogs"],
            query (body) {
                return {
                    url: "get-all-logs",
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,
                }
            }
        }),

        deleteExcelLog: build.mutation({
            // invalidatesTags: ["excelLogs"],
            query (id) {
                return {
                    url: id,
                    headers: {
                        "x-access-token": token,
                    },
                    method: "DELETE",
                }
            }
        }),
    })
})

export const { 
    useGetAllExcelLogsMutation , 
    useDeleteExcelLogMutation , 
} = excelLogsApi;