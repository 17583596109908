import React from 'react'
import {
    FormControl, InputLabel,
    Grid, Select, makeStyles,
    ListItemText, MenuItem,
    OutlinedInput, CircularProgress, Box
} from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        minWidth: 100,
    },
    tab: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

}));
export default function SelectFilters({ name, setFilterData, fields }) {
    const classes = useStyles();
    return (
        <div>
            <FormControl size="small" style={{ margin: 0 }} fullWidth={true}
                variant="outlined"
                className={classes.formControl}>
                <InputLabel htmlFor="outlined-Institue-Name-native-simple">{name}</InputLabel>
                <Select native
                    style={{}}
                    onChange={(e) => setFilterData(e.target.value)}
                    name="product_format"
                    size='large'
                    label={name}
                    MenuProps={{ classes: { paper: classes.menuPaper } }}>
                    <option aria-label="None" />
                    {fields?.map(field => <option>{field}</option>)}
                </Select>
            </FormControl>

        </div>
    )
}
