import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
const token = localStorage.getItem('__authToken__')
import { PYTHON_URL } from "../constant/BaseURL";
export const pythonApi = createApi({
  // tagTypes: ['review'],
  reducerPath: "reviewapi",
  baseQuery: fetchBaseQuery({
    baseUrl:  PYTHON_URL
  }),


  endpoints: (build) => ({
    getOrderSheet: build.mutation({
      // invalidatesTags: ['review'],
      query(body) {
        return {
          url: `get-order-sheet?token=${token}&start_date=${body.from}&end_date=${body.to}`,
          method: "GET"
        };
      },
    }),
    facultyUpdate: build.mutation({
      // invalidatesTags: ['review'],
      query(data) {
        return {
          url: `faculty-add-update?token=${token}`,

          method: "POST",
          body: data,
        };
      },
    }),
    sldVideoLecture: build.mutation({
      // invalidatesTags: ['review'],
      query(data) {
        return {
          url: `sld-video-lecture?token=${token}`,

          method: "POST",
          body: data,


        };
      },
    }),
  })
})


export const {
  useGetOrderSheetMutation,
  useFacultyUpdateMutation,
  useSldVideoLectureMutation,

} = pythonApi
