import React from 'react'
import { TextField } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { CardContent } from '@material-ui/core';

export default function AddLanguages() {
    return (
        <Grid container >
        <Grid>
            <CardContent>                     
                <TextField fullWidth={true} label="Language Name" variant="outlined" size="small" /><br /><br />                
            </CardContent>
        </Grid>
    </Grid>
    )
}
