import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const offlineConfigApi = createApi({
    tagTypes: ["config"],
    reducerPath: "offlineConfigApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/offline-config/`,
    }),
    endpoints: (builder) => ({
        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET "config" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        GetConfig: builder.query({
            providesTags: ["config"],
            query: (body) => {
                return {
                    url: "get-config", //******** *
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,
                };
            },
        }),

        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ADD "config" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        AddConfig: builder.mutation({
            invalidatesTags: ["config"],
            query: (body) => {
                return {
                    url: "add-config", // ***********
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,
                };
            },
        }),


        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< UPDATE DATA IN "config" SECTION >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        UpdateConfigData: builder.mutation({
            invalidatesTags: ["config"],
            query: (body) => {
                return {
                    url: "update-config", //********** *
                    headers: {
                        "x-access-token": token,
                    },
                    method: "PUT",
                    body,
                };
            },
        }),


        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< DELETE DATA IN "config" SECTION >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        DeleteConfigData: builder.mutation({
            invalidatesTags: ["config"],
            query: (body) => {
                return {
                    url: "remove-config", //********** *
                    headers: {
                        "x-access-token": token,
                    },
                    method: "PUT",
                    body,
                };
            },
        }),


    }),
});

export const {
    useAddConfigMutation,
    useGetConfigQuery,
    useUpdateConfigDataMutation,
    useDeleteConfigDataMutation,
} = offlineConfigApi;


