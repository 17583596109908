import React, { useState, useEffect, useCallback } from "react";
import Toster from "../Toster";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {
  useGetAllSldUserMutation,
  useGetAallFacultieNamesQuery,
  useGetAllUsersQuery,
} from "../../../../services/services";

import {
  useGetAllIncomeCategoryQuery,
  useAddIncomeDetailsMutation,
  useUpdateIncomeDetailsMutation,
} from "../../../../services/incomeServices";

import {
  Grid,
  Typography,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { string } from "yup/lib/locale";

let initialValues;

export default function AddUpdateIncomeForm(props) {
    

  props.formType == "edit"
    ? (initialValues = {
      title: props.data.title,
      note: props.data.note,
      income_from_type: props.data.income_from_type,
      income_from_id: props.data.income_from_id,
      income_from_name: props.data.income_from_name,
      income_date: props.data.income_date,
      income_amount: props.data.income_amount,
      payment_mode: props.data.payment_mode,
      category_id: props.data.category_id,
    })
    : (initialValues = {
      title: "",
      note: "",
      income_from_type: "'",
      income_from_id: "",
      income_from_name: "",
      income_date: "",
      income_amount: "",
      payment_mode: "",
      category_id: "",
    });

  const validationSchema = yup.object({
    title: yup.string().required("Required !"),
    note: yup.string().required("Required !"),
    income_from_type: yup.string().required("Required !"),
    income_from_name: yup.string().when("income_from_type", {
      is: (income_from_type) => income_from_type !== "ADMIN",
      then: yup.string().required("Required !"),
    }),
    income_date: yup.string().required("Required !"),
    income_amount: yup.number().required("Required !"),
    payment_mode: yup.string().required("Required !"),
    category_id: yup.string().required("Required !"),
  });

  const [getAllSldUser, getAllSldUserInfo] = useGetAllSldUserMutation();
  const getAllFaculties = useGetAallFacultieNamesQuery();
  const getAllWebUsers = useGetAllUsersQuery({
    limit: "",
    query: "",
    params: [
      "first_name", "last_name", "type", "mobile", "email"
    ],
    page: "",
    filterBy: {
      fieldName: "type",
      value: "STUDENT"

    }
  });
  const getAllWebFranchise = useGetAllUsersQuery({
    limit: "",
    query: "",
    params: [
      "first_name", "last_name", "type", "mobile", "email"
    ],
    page: "",
    filterBy: {
      fieldName: "type",
      value: "FRANCHISE"

    }
  });
  const getAllIncomeCategories = useGetAllIncomeCategoryQuery();
  const [addIncome, addIncomeInfo] = useAddIncomeDetailsMutation();
  const [updateIncome, updateIncomeInfo] = useUpdateIncomeDetailsMutation();
  const allSldUser = getAllSldUserInfo.data;
  const allFaculties = getAllFaculties.data;
  const allWebUsers = getAllWebUsers.data;
  const allWebFranchise = getAllWebFranchise.data;

  const allIncomeCategories = getAllIncomeCategories.data;

    

  useEffect(() => {
    getAllSldUser({
      limit: "",
      query: "",
      params: ["name"],
      page: "",
    });
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const incomeFromDetails = values.income_from_name.split("||");
      values.income_from_id = incomeFromDetails[1];
      values.income_from_name = incomeFromDetails[0];
      props.formType != "edit"
        ? addIncome(values)
        : updateIncome({ body: values, id: props.data._id });
    },
  });

    

  const dropDownsData = (type) => {
    switch (type) {
      case "SLD_USER":
        return (
          allSldUser &&
          allSldUser.data.map((ele) => {
            return (
              <MenuItem value={`${ele.name}||${ele._id}`}> {ele.name}</MenuItem>
            );
          })
        );
        break;

      case "FACULTY":
        return (
          allFaculties &&
          allFaculties.data.map((ele) => {
            return (
              <MenuItem value={`${ele.faculty_name}||${ele._id}`}>
                {" "}
                {ele.faculty_name}
              </MenuItem>
            );
          })
        );

      case "WEB_USER":
        return (
          allWebUsers &&
          allWebUsers.data.map((ele) => {
            return (
              <MenuItem value={`${ele.first_name + ele.last_name}||${ele._id}`}>
                {" "}
                {`${ele.first_name} ${ele.last_name}`}
              </MenuItem>
            );
          })
        );

      case "WEB_FRANCHISE":
        return (
          allWebFranchise &&
          allWebFranchise.data.map((ele) => {
            return (
              <MenuItem value={`${ele.first_name + ele.last_name}||${ele._id}`}>
                {" "}
                {`${ele.first_name} ${ele.last_name}`}
              </MenuItem>
            );
          })
        );



      default:
        break;
    }
  };


  addIncomeInfo && props.setAddIncomeInfo(addIncomeInfo)
  updateIncomeInfo && props.setUpdateIncomeInfo(updateIncomeInfo)
  

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 20,
            marginLeft: 10,
          }}
        >
          <Typography variant="h6" color="primary" gutterBottom>
            Income Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                name="title"
                label="Income Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                fullWidth={true}
                variant="outlined"
                size="small"
              />
              {formik.touched.title && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.title}
                </span>
              )}
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="note"
                label="Note"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.note}
                fullWidth={true}
                variant="outlined"
                size="small"
              />
              {formik.touched.note && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.note}
                </span>
              )}
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="date"
                name="income_date"
                // label="Expense Date "
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.income_date}
                fullWidth={true}
                variant="outlined"
                size="small"
              />
              {formik.touched.income_date && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.income_date}
                </span>
              )}
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="category">Category</InputLabel>
                <Select
                  name="category_id"
                  labelId="category"
                  id="category"
                  value={formik.values.category_id}
                  label="category"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {allIncomeCategories &&
                    allIncomeCategories.data.map((ele) => {
                      return (
                        <MenuItem value={ele._id}>{ele.category_name}</MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              {formik.touched.category_id && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.category_id}
                </span>
              )}
            </Grid>

            <Grid item xs={4}>
              <TextField
                type="number"
                name="income_amount"
                label="Amount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.income_amount}
                fullWidth={true}
                variant="outlined"
                size="small"
              />
              {formik.touched.income_amount && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.income_amount}
                </span>
              )}
            </Grid>

            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="paymentMode">Received Mode</InputLabel>
                <Select
                  name="payment_mode"
                  labelId="paymentMode"
                  id="paymentMode"
                  value={formik.values.payment_mode}
                  label="paymentMode"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="Online">Online</MenuItem>
                </Select>
              </FormControl>
              {formik.touched.payment_mode && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.payment_mode}
                </span>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>   INCOME FROM DETAILS SECTION   <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */}

        <Grid
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 20,
            marginLeft: 10,
          }}
        >
          <Typography variant="h6" color="primary" gutterBottom>
            Income From
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="role">Role</InputLabel>
                <Select
                  name="income_from_type"
                  labelId="role"
                  id="role"
                  value={formik.values.income_from_type}
                  label="role"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={"SLD_USER"}>SLD User</MenuItem>
                  <MenuItem value={"FACULTY"}>Faculty</MenuItem>
                  <MenuItem value={"WEB_USER"}>Web User</MenuItem>
                  <MenuItem value={"WEB_FRANCHISE"}>Web Franchise</MenuItem>
                  {/* <MenuItem value={"OFFLINE_USER"}>Offline User</MenuItem>
                  <MenuItem value={"OFFICIAL"}>Officials</MenuItem> */}
                  <MenuItem value={"OTHERS"}>Others </MenuItem>
                  <MenuItem value={"ADMIN"}>Admin</MenuItem>
                </Select>
              </FormControl>
              {formik.touched.income_from_type && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.income_from_type}
                </span>
              )}
            </Grid>
            {formik.values.income_from_type !== "ADMIN" &&
              (formik.values.income_from_type != "OTHERS" ? (
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="income-from-name">Name</InputLabel>
                    <Select
                      name="income_from_name"
                      labelId="income-from-name"
                      id="income-from-name"
                      value={formik.values.income_from_name}
                      label="income-from-name"
                      onChange={formik.handleChange}
                    >
                      {dropDownsData(formik.values.income_from_type)}
                    </Select>
                  </FormControl>
                  {formik.touched.income_from_name && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.income_from_name}
                    </span>
                  )}
                </Grid>
              ) : (
                <Grid item xs={4}>
                  <TextField
                    name="income_from_name"
                    label="Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.income_from_name}
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                  />
                  {formik.touched.income_from_name && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.income_from_name}
                    </span>
                  )}
                </Grid>
              ))}
          </Grid>
        </Grid>

        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              props.formType != "edit"
                ? props.setAddDialog(false)
                : props.setEditDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
          {props.formType == "edit" ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </form>
     
    </div>
  );
}
