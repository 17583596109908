import React, { useState, useEffect } from 'react';
import { TextField, Button, FormControl, Select, InputLabel } from '@material-ui/core'
import { useFormik } from 'formik';
import * as yup from 'yup'
import { useGetUsersListingQuery, } from '../../../services/services';
import moment from 'moment';

export default function AddScratchCards({ AddData, setDialog }) {
    const allUsersListing = useGetUsersListingQuery()
    const [allUsers, setAllUsers] = useState()

    useEffect(() => {
        let { isSuccess, data } = allUsersListing;
        if (isSuccess) {
            setAllUsers(data.data)
        }
    }, [allUsersListing])



    const formik = useFormik({
        initialValues: {
            scratch_text: "",
            user_id: "",
            scratch_discription: "",
            validity_start: "",
            validity_end: "",

        },
        validationSchema: yup.object({
            scratch_text: yup.string().required("Required !"),
            scratch_discription: yup.string().required("Required !"),
            user_id: yup.string().required("Required !"),
            validity_start: yup.string().required("Required !"),
            validity_end: yup.string().required("Required !"),
        }),
        onSubmit: (values) => {
              
            let startDate = moment(values.validity_start).format('YYYY-MM-DD HH:mm:ss')
            let endDate = moment(values.validity_end).format('YYYY-MM-DD HH:mm:ss')

            values.validity_start = startDate
            values.validity_end = endDate

            AddData(values)
        }
    })
    const dialogclose = () => {
        setDialog(false)
    }
    return (
        <>
            <form onSubmit={formik.handleSubmit}>

                <TextField
                    name="scratch_text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.scratch_text}
                    fullWidth={true}
                    label="Card Text"
                    variant="outlined"
                    size="small"
                />
                {formik.touched.scratch_text && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.scratch_text}</span>}
                <br /><br />

                <FormControl style={{ margin: 0 }} fullWidth={true} variant="outlined" size='small'>
                    <InputLabel htmlFor="outlined-Institue-Name-native-simple">User</InputLabel>
                    <Select
                        style={{ maxHeight: 100, overflowY: "auto" }}
                        native
                        name="user_id"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.user_id}
                        label="User">
                        <option aria-label="None" value="" />
                       
                        {allUsers && allUsers.map(data => <option value={data._id}>{data.email}</option>)}
                    </Select>
                </FormControl>
                {formik.touched.user_id && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.user_id}</span>}

                <br /><br />
                <TextField
                    name="scratch_discription"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.scratch_discription}
                    label="Discription"
                    fullWidth={true}
                    multiline
                    variant="outlined"
                    size="small" />
                {formik.touched.scratch_discription && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.scratch_discription}</span>}
                <br /><br />


                <TextField
                    name="validity_start"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.validity_start}
                    id="datetime-local"
                    label="Start From"
                    size="large"
                    type="datetime-local"
                    fullWidth={true}
                    sx={{ width: 250 }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {formik.touched.validity_start && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.validity_start}</span>}
                <br /><br />


                <TextField
                    name="validity_end"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.validity_end}
                    id="datetime-local"
                    label="Expired date and time"
                    type="datetime-local"
                    fullWidth={true}
                    sx={{ width: 250 }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {formik.touched.validity_end && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.validity_end}</span>}
                <br /><br />


                <Button
                    onClick={dialogclose}
                    variant="contained"
                    color="secondary"
                >Close</Button>
                <Button
                    type="submit"
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    color="primary"
                > Save</Button>
            </form>
        </>
    )
}
