import React from 'react'
import { CircularProgress } from '@material-ui/core';

export default function Loader() {
    return (
        <div className="d-flex justify-content-center align-items-center " style={{ height: "70vh", }}>
            <div style={{
                zIndex: 2000, height: "100vh", width: "100%",
                background: 'rgb(218 218 218)'
            }} className=" d-flex justify-content-center align-items-center ">

                <CircularProgress size="4rem" />
            </div>
        </div>
    )
}
