function calculatePaymentToFaculty(
    faculty_mrp,
    discount_by_faculty,
    commission_amount_offline,
    commission_percentage_offline,
    commission_amount_online,
    commission_percentage_online
  ) {
    let calculatedCommissionAmountOffline = commission_amount_offline;
    let calculatedCommissionPercentageOffline = commission_percentage_offline;
    let calculatedCommissionAmountOnline = commission_amount_online;
    let calculatedCommissionPercentageOnline = commission_percentage_online;
  
    // If commission_amount_offline is in percentage, calculate the amount
    if (typeof commission_amount_offline === 'string' && commission_amount_offline.endsWith('%')) {
      const commissionPercentage = parseFloat(commission_amount_offline) || 0;
      calculatedCommissionPercentageOffline = commissionPercentage;
      calculatedCommissionAmountOffline = (faculty_mrp - discount_by_faculty) * (commissionPercentage / 100);
    }
  
    // If commission_amount_online is in percentage, calculate the amount
    if (typeof commission_amount_online === 'string' && commission_amount_online.endsWith('%')) {
      const commissionPercentage = parseFloat(commission_amount_online) || 0;
      calculatedCommissionPercentageOnline = commissionPercentage;
      calculatedCommissionAmountOnline = (faculty_mrp - discount_by_faculty) * (commissionPercentage / 100);
    }
  
    const price_on_which_commission_is_calculated = faculty_mrp - discount_by_faculty;
    
    let discount_by_us = 0;
    
    // Validate discount_by_us against calculatedCommissionAmountOnline
    if (typeof discount_by_us === 'number' && discount_by_us <= calculatedCommissionAmountOnline) {
      discount_by_us = Math.min(discount_by_us, calculatedCommissionAmountOnline);
    }
    
    const payment_to_faculty_offline = price_on_which_commission_is_calculated - calculatedCommissionAmountOffline;
    const payment_to_faculty = price_on_which_commission_is_calculated - calculatedCommissionAmountOnline;
  
    return {
      price_on_which_commission_is_calculated,
      payment_to_faculty_offline,
      payment_to_faculty,
      calculatedCommissionAmountOffline,
      calculatedCommissionPercentageOffline,
      calculatedCommissionAmountOnline,
      calculatedCommissionPercentageOnline,
      discount_by_us,
    };
  }
  
  // Example usage
  let faculty_mrp = 20;
  const discount_by_faculty = 0;
  let commission_amount_offline = 10; // Can be amount or percentage
  const commission_percentage_offline = '50%'; // Can be amount or percentage
  let commission_amount_online = 10; // Can be amount or percentage
  const commission_percentage_online = '30%'; // Can be amount or percentage
  let discount_by_us = 5; // User-defined discount by us
  
  let {
    price_on_which_commission_is_calculated,
    payment_to_faculty_offline,
    payment_to_faculty,
    calculatedCommissionAmountOffline,
    calculatedCommissionPercentageOffline,
    calculatedCommissionAmountOnline,
    calculatedCommissionPercentageOnline,
  } = calculatePaymentToFaculty(
    faculty_mrp,
    discount_by_faculty,
    commission_amount_offline,
    commission_percentage_offline,
    commission_amount_online,
    commission_percentage_online
  );
  
  // Updating commission_amount_online as percentage
  commission_amount_online = '40%';
  
  ({
    price_on_which_commission_is_calculated,
    payment_to_faculty_offline,
    payment_to_faculty,
    calculatedCommissionAmountOffline,
    calculatedCommissionPercentageOffline,
    calculatedCommissionAmountOnline,
    calculatedCommissionPercentageOnline,
  } = calculatePaymentToFaculty(
    faculty_mrp,
    discount_by_faculty,
    commission_amount_offline,
    commission_percentage_offline,
    commission_amount_online,
    commission_percentage_online
  ));
  

  // Updating discount_by_us to a higher value than commission_amount_online
  discount_by_us = 12;
  
  ({
    price_on_which_commission_is_calculated,
    payment_to_faculty_offline,
    payment_to_faculty,
    calculatedCommissionAmountOffline,
    calculatedCommissionPercentageOffline,
    calculatedCommissionAmountOnline,
    calculatedCommissionPercentageOnline,
    discount_by_us,
  } = calculatePaymentToFaculty(
    faculty_mrp,
    discount_by_faculty,
    commission_amount_offline,
    commission_percentage_offline,
    commission_amount_online,
    commission_percentage_online
  ));
