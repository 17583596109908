import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import {
    Button,
    Grid,
    Tooltip,
    Chip,
    TextField,
  } from "@material-ui/core";

const ProductCartFilterDialog = ({ formikProps, onReset, onClose }) => {
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;

  return (
    <Dialog
      open={true}
      size="large"
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
     <div className="d-flex justify-content-between m-4"> 
     <DialogTitle>Select Dates </DialogTitle>
     <div onClick={onReset}  className="text-danger fw-semibold cursor-pointer" >Reset</div>
     </div>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={5}>
            <Grid xs={6} item>
              <TextField
                type="date"
                fullWidth
                onChange={(e) => {
                  setFieldValue("startDate", e.target.value);
                }}
                name="startDate"
                value={values?.startDate}
                label="From Date"
                variant="outlined"
              />
            </Grid>
            <Grid xs={6} item>
              <TextField
                type="date"
                fullWidth
                onChange={(e) => {
                  setFieldValue("endDate", e.target.value);
                }}
                name="endDate"
                value={values?.endDate}
                label="To Date"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="p-3">
        <Button className="bg-danger text-light" onClick={onClose}>
          Close
        </Button>
        <Button onClick={handleSubmit} type='submit' className="bg-success text-light">Yes</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductCartFilterDialog;
