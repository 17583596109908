
import React ,{useState,useEffect} from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik';
import {
    TextField, Grid, Button,
  Select,
    FormControl, InputLabel,MenuItem
} from '@material-ui/core'
export default function EditCourier({UpdateData,editCourier,editDialog}) {
    const formik = useFormik({
        initialValues: {
            tracking_status:editCourier.current_status,
            tracking_description:""
        },
        validationSchema: yup.object({
            tracking_status:yup.string().required("Required!"),
            tracking_description:yup.string().required("Required!"),
        }),
        onSubmit: (values) => {
            UpdateData(values,editCourier._id)
        },
    })
    const dialogclose =()=>{
        editDialog(false)  
    }


    return (
        <div>
             <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3} fullWidth={true}>
                <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">tracking status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // value={age}
                                label="tracking status"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.tracking_status}
                                // onChange={handleChange}
                                name="tracking_status"
                                variant="outlined"
                            >
                                <MenuItem selected={formik.values.tracking_status == "DISPATCHED" ? true:false} value="DISPATCHED">DISPATCHED</MenuItem>
                                <MenuItem selected={formik.values.tracking_status == "COMPLETED" ? true:false} value="COMPLETED">COMPLETED</MenuItem>
                            </Select>
                        </FormControl>
                        {formik.touched.tracking_status && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.tracking_status}</span>}

                </Grid>

                <Grid item xs={6}>
                    <TextField
                        name="tracking_description"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.tracking_description}
                        fullWidth={true}
                        label="tracking description"
                        variant="outlined"
                        size="large" />
                        {formik.touched.tracking_description && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.tracking_description}</span>}
                        
                </Grid>
                <Grid item xs={12} className="mt-5">
                    <div className="d-flex justify-content-end">
                        <Button                      
                            onClick={dialogclose}
                            variant="contained"
                            color="secondary"
                        >Close</Button>
                        <Button

                            type="submit"
                            style={{ marginLeft: 20 }}
                            variant="contained"
                            color="primary"
                        > Save</Button>
                    </div>
                </Grid>


            </Grid>

        </form>
        </div>
    )
}
