import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Grid, TextField } from "@material-ui/core";

import Paper from "@material-ui/core/Paper";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  SplineSeries,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function RouteNotFound() {
  const classes = useStyles();

  return (
    <>
      <Grid
        className="d-flex aline-items-center justify-content-center"
        container
        spacing={3}
        style={{ marginTop: 50 }}
      >
        <Grid item xs={8}>
          <Card style={{ background: "#FF6865" }} className={classes.root}>
            <CardContent
              className="m-5 border-bottom border-light"
              align="center"
            >
              <Typography align="center" style={{ color: "#fff" }}>
                <h3>404 Route not found!</h3>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
