import React, { useState, useEffect } from "react";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import * as yup from 'yup'
import { useFormik } from 'formik';
import {
  Grid,
  Button,
  Card,
  Box,
  CardContent,
  Dialog, Zoom, DialogTitle, DialogContent, TextField,
  IconButton,
  FormControl, InputLabel, Select, MenuItem
} from "@material-ui/core";
import {
  useGetSingleFacultyMutation
} from '../../../../services/services'
import {
  useAddFacultyCommisionRuleMutation,
  useDeleteFacultyCommisionRuleMutation
} from '../../../../services/facultyCommisionRuleServices'
import DeleteIcon from '@material-ui/icons/Delete';
import { Label } from "@material-ui/icons";
import DataNotFound from "../DataNotFound";


export default function CommisionRule({ viewDialog, data, setFacultyData }) {

  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "rule_type",
      label: "Rule Type",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "target_location",
      label: "Target Location",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "target_amount",
      label: "Target Amount",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "commission_percentage",
      label: "Commission Percentage",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "start_date",
      label: "Start Date",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "end_date",
      label: "End Date",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];
  function createData(SNo,
    rule_type,
    target_location,
    target_amount,
    commission_percentage,
    start_date,
    end_date,
    actions) {
    return {
      SNo,
      rule_type,
      target_location,
      target_amount,
      commission_percentage,
      start_date,
      end_date,
      actions
    };
  }
  const [render, setRender] = useState(false);

  let rows = [];
  const [addData, addDataInfo] = useAddFacultyCommisionRuleMutation();
  const [deleteFacultyCommisionRule, deleteFacultyCommisionRuleInfo] = useDeleteFacultyCommisionRuleMutation();
  const [singleFacultyData, singleFacultyDataInfo] = useGetSingleFacultyMutation();
  const [addDialog, setAddDialog] = useState(false);
  const [lastActionPerformed, setLastActionPerformed] = useState("")

  if (typeof data.commission_rules == 'object') {
    data.commission_rules.map((ele, ind) => {
      rows.push(
        createData(
          ind + 1,
          ele.rule_type,
          ele.target_location,
          ele.target_amount,
          ele.commission_percentage,
          ele.start_date,
          ele.end_date,
          <div>
            <IconButton onClick={() => { deleteDetails(ele._id) }} color="secondary"><DeleteIcon /></IconButton>
          </div>
        )
      );
    })
  }


  useEffect(() => {
    singleFacultyData(data._id)
  }, [addDataInfo, deleteFacultyCommisionRuleInfo])


  const deleteDetails = (ruleId) => {
    setLastActionPerformed("DELETE")
    deleteFacultyCommisionRule(ruleId)
  }

  const dialogclose = () => {
    setAddDialog(false);
    setRender(!render);
  };

  return (
    <>
      <Card sx={{ display: "flex" }} className="m-3" >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Button
              onClick={() => {
                setAddDialog(true);
              }}
              variant="contained"
              color="primary"
            >
              Add Commision Rule
            </Button>
            <Button
              onClick={() => viewDialog(false)}
              className="text-light bg-danger float-right btn btn-sm btn-danger"
            >
              Close
            </Button>
          </CardContent>

          {
            singleFacultyDataInfo.isSuccess == true && singleFacultyDataInfo.data.data.length ?
              singleFacultyDataInfo.isLoading == false ?
                <div>
                  {setFacultyData(singleFacultyDataInfo.data.data[0])}

                  {
                    rows.length ?
                      <CreateTableAddButton
                        editDialog={null}
                        setPage={null}
                        rows={rows}
                        editform={null}
                        columns={columns}
                        isButton={true}
                        editFormName={null}
                      /> :
                      <Grid xs={12} className="my-1 text-center bg-light p-3" >NO DATA FOUND</Grid>
                  }
                  {addDataInfo.data != undefined && lastActionPerformed == "ADD" && (
                    <Toster info={addDataInfo} dialogclose={dialogclose} />)}

                  {deleteFacultyCommisionRuleInfo.data != undefined && lastActionPerformed == "DELETE" && (
                    <Toster info={deleteFacultyCommisionRuleInfo} dialogclose={dialogclose} />)}

                </div>
                :
                <SkeletonListing />

              :
              <Grid container justifyContent="center">
                <DataNotFound />
              </Grid>

          }
          {addDialog ? <AddRuleDetails addDialog={addDialog} setAddDialog={setAddDialog} setLastActionPerformed={setLastActionPerformed} addData={addData} facultyId={data._id} /> : null}

        </Box>
      </Card>
    </>

  );
}

function AddRuleDetails({ addDialog, setAddDialog, setLastActionPerformed, addData, facultyId }) {

  const formik = useFormik({

    initialValues: {
      faculty_id: facultyId,
      commission_percentage: "",
      target_amount: "",
      target_location: "",
      rule_type: "",
      start_date: "",
      end_date: ""
    },
    validationSchema: yup.object({
      commission_percentage: yup.string().required("Required!"),
      target_amount: yup.string().required("Required!"),
      target_location: yup.string().required("Required!"),
      rule_type: yup.string().required("Required!"),
      start_date: yup.string().required("Required!"),
      end_date: yup.string().required("Required!")
    }),
    onSubmit: (values, e) => {
      setLastActionPerformed("ADD")
      addData(values)
      setAddDialog(false)
    },
  })

  return (
    <Dialog
      fullScreen={false}
      open={addDialog}
      //scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
      TransitionComponent={Zoom}
    >
      <DialogTitle id="scroll-dialog-title">Add Details</DialogTitle>
      <DialogContent>
        <form id="addfacultyform" onSubmit={formik.handleSubmit}>
          <Grid style={{ paddingLeft: 10, paddingRight: 10, marginTop: 20, marginLeft: 10 }} >
            <Grid container spacing={1} >

              <Grid item xs={6}>
                <TextField
                  name="commission_percentage"
                  label="Commission Percentage"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.commission_percentage}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.commission_percentage && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.commission_percentage}</span>}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="target_amount"
                  label="Target Amount"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.target_amount}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.target_amount && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.target_amount}</span>}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="target_location"
                  label="Target Location"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.target_location}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.target_location && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.target_location}</span>}
              </Grid>

              <Grid item xs={6}>
                <FormControl
                  style={{ margin: 0 }}
                  fullWidth={true}
                  variant="outlined"
                  className={null}>

                  <InputLabel
                    htmlFor="outlined-institute-Name-native-simple">
                    Rule Type
                  </InputLabel>

                  <Select
                    onChange={formik.handleChange}
                    id="newSkill"
                    onBlur={formik.handleBlur}
                    value={formik.values.rule_type}
                    label="Rule Type"
                    name="rule_type">
                    <MenuItem value="AMOUNT_BASED">AMOUNT BASED</MenuItem>
                    <MenuItem value="LOCATION_BASED">LOCATION BASED</MenuItem>
                  </Select>
                </FormControl>
                {formik.touched.rule_type && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.rule_type}</span>}
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid container spacing={1} >


              <Grid item xs={6}>
                <span>Start Date</span>
                <TextField
                  name="start_date"
                  label=""
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.start_date}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                  type="date"
                />
                {formik.touched.start_date && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.start_date}</span>}
              </Grid>

              <Grid item xs={6}>
                <span>End Date</span>
                <TextField
                  name="end_date"
                  label=""
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.end_date}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                  type="date"
                />
                {formik.touched.end_date && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.end_date}</span>}
              </Grid>

            </Grid>
            <br />
            <br />
            <Grid container spacing={3} >
              <Grid item xs={12}>
                <div className="text-right">
                  <Button
                    onClick={() => setAddDialog(false)}
                    variant="contained"
                    color="secondary">
                    Close</Button>
                  <Button
                    type="submit"
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    color="primary">
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid >
        </form >
      </DialogContent>
    </Dialog>
  )

}
