import React, { useState } from "react";
import CreateTableAddButton from "./CreateTableAddButton";
import EditDelete from "./EditDelete";
import Toster from "./Toster";
import {
  useAddCmsMutation,
  useGetCmsQuery,
  useUpdateCmsStatusMutation,
  useUpdateSliderImageStatusMutation,
  useUpdateCMSDataMutation,
} from "../../../services/cmsServices";
import {
  Switch,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Box,
} from "@material-ui/core";
import Skeleton from "@mui/material/Skeleton";
import EditIcon from "@material-ui/icons/Edit";
import FormDialog from "./reusableComponents/NewFormDialog";
import DataNotFound from "./DataNotFound";
import * as yup from "yup";
import AuthWrapper from "../../../utils/AuthWrapper";

export default function CmsContactDetails(props) {
  const [editDialog, setEditDialog] = useState(false);
  const {
    data: AllData,
    isFetching,
    isLoading,
  } = useGetCmsQuery({ key: "contact_info" });

  const [updateIsShowStatus, updateIsShowInfo] = useUpdateCmsStatusMutation();
  const [updateInnerData, updateInnerDataInfo] = useUpdateCMSDataMutation();

  const dialogclose = () => {
    setEditDialog(false);
  };

  const updateClickHandler = (dataForUpdate) => {
    dataForUpdate = JSON.stringify(dataForUpdate);

    let form_data = new FormData();

    form_data.append("key", "contact_info");
    form_data.append("cms_data", dataForUpdate);

    updateInnerData(form_data);
  };

  return (
    <>
      {AllData != undefined && AllData.data ? (
        <div>
          {editDialog && (
            <FormDialog
              open={editDialog}
              setOpen={setEditDialog}
              formName="Edit Contact Information"
              btnName="Update"
              onBtnClick={updateClickHandler}
              //useAs="form"

              fields={[
                {
                  fieldType: "text",
                  fieldName: "Address",
                  initialValue: AllData.data.length
                    ? AllData.data[0].contact_info.address
                    : "",
                  accessKey: "address",
                  validation: yup.string().required("Required !"),
                },
                {
                  fieldType: "email",
                  fieldName: "Support Mail",
                  initialValue: AllData.data.length
                    ? AllData.data[0].contact_info.support_mail
                    : "",
                  accessKey: "support_mail",
                  validation: yup
                    .string()
                    .email("Invalid email format")
                    .required("Required !"),
                },
              ]}
            />
          )}

          {AllData.data ? (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <AuthWrapper type="ACTION" name="CMS_CONTACT_INFO_HIDE">
                <Grid className="pt-4 mx-2 ">
                  {AllData.data[0].contact_info.is_show ? (
                    <Button
                      variant="contained"
                      onClick={() => {
                        updateIsShowStatus({
                          key: "contact_info",
                          cms_data: {
                            is_show: false,
                          },
                        });
                      }}
                      color="secondary"
                    >
                      Hide
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => {
                        updateIsShowStatus({
                          key: "contact_info",
                          cms_data: {
                            is_show: true,
                          },
                        });
                      }}
                      color="primary"
                    >
                      Show
                    </Button>
                  )}
                </Grid>
              </AuthWrapper>
              <AuthWrapper type="ACTION" name="CMS_CONTACT_INFO_EDIT">
                <Grid item xs={2} className="pt-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setEditDialog(true);
                    }}
                  >
                    <EditIcon fontSize="small" className="mx-1" />
                    Edit
                  </Button>
                </Grid>
              </AuthWrapper>
            </Grid>
          ) : null}
          <AuthWrapper type="ACTION" name="CMS_CONTACT_INFO_LIST">
            {AllData.data ? (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="mt-5"
              >
                <Grid item xs={5}>
                  {isFetching || isLoading ? (
                    <Box>
                      <Skeleton />
                      <Skeleton animation="wave" />
                      <Skeleton animation={false} />
                    </Box>
                  ) : (
                    <Card className="mb-5" sx={{ minWidth: 275 }}>
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          Address :
                        </Typography>

                        <Typography variant="body1" color="primary">
                          {AllData.data.length
                            ? AllData.data[0].contact_info.address
                            : ""}
                          <br />
                          <br />
                        </Typography>

                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          Support Mail :
                        </Typography>

                        <Typography variant="body1" color="primary">
                          {AllData.data.length
                            ? AllData.data[0].contact_info.support_mail
                            : ""}
                          <br />
                        </Typography>
                      </CardContent>
                      <CardActions></CardActions>
                    </Card>
                  )}
                </Grid>
              </Grid>
            ) : (
              <DataNotFound />
            )}
          </AuthWrapper>
          {updateIsShowInfo.data && (
            <Toster info={updateIsShowInfo} dialogclose={dialogclose} />
          )}
          {updateInnerDataInfo.data && (
            <Toster info={updateInnerDataInfo} dialogclose={dialogclose} />
          )}
        </div>
      ) : (
        <DataNotFound />
      )}
    </>
  );
}

// import React from 'react';
// import CreateTableAddButton from './CreateTableAddButton';
// import EditDelete from './EditDelete';

// const columns = [
//     { id: 'purchaseSupport', label: 'Purchase Support', minWidth: 50 },
//     { id: 'afterSaleSupport', label: 'After Sale Support', minWidth: 50 },
//     {
//         id: 'complaints',
//         label: 'Complaints',
//         minWidth: 50,
//         align: 'center',
//         format: (value) => value.toLocaleString('en-US'),
//     },
//     {
//         id: 'buyingOrdering',
//         label: ' Buying Ordering',
//         minWidth: 50,
//         align: 'center',
//         format: (value) => value.toLocaleString('en-US'),
//     },
//     {
//         id: 'status',
//         label: 'Status',
//         minWidth: 50,
//         align: 'center',
//         format: (value) => value.toLocaleString('en-US'),
//     },
//     {
//         id: 'actions',
//         label: 'Actions',
//         minWidth: 50,
//         align: 'center',
//         format: (value) => value.toLocaleString('en-US'),
//     },
// ];
// function createData(purchaseSupport, afterSaleSupport, complaints, buyingOrdering, status, actions,) {
//     return { purchaseSupport, afterSaleSupport, complaints, buyingOrdering, status, actions, };
// }
// const rows = [
//     createData("for purchase : 9179777788 / 8109777788", 'after sale support (11am - 6pm) : 8602777788', "for payment related issue : 8602777788", "9179777788 / 8109777788", "Active", <div><EditDelete type="deactivate" /><EditDelete type="edit" /><EditDelete type="delete" /></div>),

// ];

// export default function CmsContactDetails(props) {

//     return (
//         <div>
//             <CreateTableAddButton rows={rows} form={null} columns={columns} isButton={false} addFormName={"Add New Slider"} />
//         </div>
//     )
// }
