import React, { useState, useEffect } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import AddDataHeader from "../AddDataHeader";
import DataNotFound from "../DataNotFound";
import FormDialog from "../reusableComponents/NewFormDialog";
import { useGetAllCurrenciesQuery, useAddCurrencyMutation, useUpdateCurrencyMutation, useDeleteCurrencyMutation } from "../../../../services/offlineModuleServices/currencyServices";

export default function Currency() {

    let [getid, setGetid] = useState("");
    const [data, setData] = useState("");
    const [addDialog, setAddDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [render, setRender] = useState(false)

    const { data: AllCurrencyNotes, isAllCurrencyNotesFetching, isAllCurrencyNotesLoading } = useGetAllCurrenciesQuery()
    const [addCurrency, addCurrencyInfo] = useAddCurrencyMutation()
    const [updateCurrency, updateCurrencyInfo] = useUpdateCurrencyMutation()
    const [deleteCurrency, deleteCurrencyInfo] = useDeleteCurrencyMutation()

    const columns = [
        {
            id: "SNo",
            label: "S.No",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "noteName",
            label: "Note Name",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "noteValue",
            label: "Note Value",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "actions",
            label: "Actions",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
    ];
    function createData(SNo, noteName, noteValue, actions) {
        return {
            SNo,
            noteName,
            noteValue,
            actions,
        };
    }
    let rows = [];
    const totalItem = AllCurrencyNotes ? AllCurrencyNotes.totalItem : 0;

    let totalData = "ok";


    useEffect(() => {
        getid && deleteCurrency(getid) && setGetid("");
    }, [getid]);

    if (
        AllCurrencyNotes
    ) {
        totalData = AllCurrencyNotes.totalItem;
        rows = AllCurrencyNotes.data.map((ele, index) => {
            return createData(
                index + 1,
                ele.currency_note,
                ele.value,
                <div>
                    <EditDelete
                        setEditDialog={setEditDialog}
                        type="edit"
                        setData={setData}
                        data={ele}
                    />
                    <EditDelete type="delete" setGetid={setGetid} id={ele._id} />
                </div>
            );
        });
    }

    const dialogclose = () => {
        setEditDialog(false);
        setAddDialog(false);
        setRender(!render);
    };

    const updateBtnClickHandler = (dataForUpdate) => {
        updateCurrency({ body: { ...dataForUpdate, currency_name: "INDIAN RUPEE" }, id: data._id });
    };

    const addBtnClickHandler = (dataForAdd) => {
        addCurrency({ ...dataForAdd, currency_name: "INDIAN RUPEE" })
    }

    return (
        <div>
            {/* {!viewDialog && ( */}
            <AddDataHeader
                addFormName={"Add New Note"}
                isSearch={true}
                totalItem={totalItem}
                isPagination={true}
                setAddDialog={setAddDialog}
                isButton={true}
                Adddialog={addDialog}
                form={
                    <FormDialog
                        open={addDialog}
                        setOpen={setAddDialog}
                        formName={"Add New Currency Note"}
                        btnName={"Save"}
                        onBtnClick={addBtnClickHandler}
                        fields={[
                            {
                                fieldType: "text",
                                fieldName: "Note Name",
                                initialValue: "",
                                //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                                accessKey: 'currency_note',
                            },

                            {
                                fieldType: "number",
                                fieldName: "Note Value",
                                initialValue: "",
                                //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                                accessKey: "value",     
                            },
                        ]}

                    />
                }
            />
            {/* )} */}


            <>
                {rows.length ? (
                    <CreateTableAddButton
                        editDialog={editDialog}
                        // setPage={setPage}
                        rows={rows}
                        fullWidth={false}
                        editform={
                            <FormDialog
                                useAs="form"
                                open={editDialog}
                                setOpen={setEditDialog}
                                formName={"Edit Currency Note Details "}
                                btnName={"Update"}
                                onBtnClick={updateBtnClickHandler}

                                fields={[
                                    {
                                        fieldType: "text",
                                        fieldName: "Note Name",
                                        initialValue: data.currency_note,
                                        //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                                        accessKey: "currency_note",
                                    },

                                    {
                                        fieldType: "number",
                                        fieldName: "Note Value",
                                        initialValue: data.value,
                                        //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                                        accessKey: "value",
                                    },
                                ]}
                            />
                        }
                        columns={columns}
                        isButton={true}
                        editFormName={"Edit Currency Note Details"}
                    />
                ) : totalData == 0 ? (
                    <DataNotFound />
                ) : (
                    <SkeletonListing />
                )}

            </>


            {addCurrencyInfo && (
                <Toster info={addCurrencyInfo} dialogclose={dialogclose} />
            )}

            {updateCurrencyInfo && (
                <Toster info={updateCurrencyInfo} dialogclose={dialogclose} />
            )}

            {deleteCurrencyInfo && (
                <Toster info={deleteCurrencyInfo} dialogclose={dialogclose} />
            )}
        </div>
    );
}
