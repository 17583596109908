import React from 'react'
import { Button, Grid, Box, } from '@material-ui/core'

function ViewInstitute({ viewDialog, data }) {
      
    return (
        <div className='p-3'>

            <Box sx={{
                flexGrow: 1,
                bgcolor: 'background.paper',
            }}>
              
                <div class="card">
                    <div class="card-body">
                        <Grid container >

                            <Grid xs={12} className=' my-1'>
                                <Button
                                    onClick={() => viewDialog(false)}
                                    className="text-light bg-danger float-right btn btn-sm btn-danger">
                                    Close
                                </Button>
                            </Grid>

                            <Grid xs={3} className='my-1'>
                                <div style={{ width: 200 }}>
                                    <img src={data.image} alt="..." class="img-thumbnail" />
                                </div>
                            </Grid>


                            <Grid xs={9} className='my-2'>
                                <h5 className="mb-3 text-muted bg-light m-2 d-flex align-items-center">
                                    Name : <span className="highlight-text-default  px-2">
                                        {data.name}
                                    </span>
                                </h5>

                                <h5 className="mb-3 text-muted bg-light m-2 d-flex align-items-center">
                                    Specialization : <span className="highlight-text-default  px-2">
                                        {data.specialization}
                                    </span>
                                </h5>

                                <h5 className="mb-3 text-muted bg-light m-2 d-flex align-items-center">
                                    Description : <span className="highlight-text-default  px-2">
                                        {data.description}
                                    </span>
                                </h5>

                                <h5 className="mb-3 text-muted bg-light m-2 d-flex align-items-center">
                                    Active : <span className="highlight-text-default  px-2">
                                        {data.is_active ? "Yes" : "No"}
                                    </span>
                                </h5>

                                <h5 className="mb-3 text-muted bg-light m-2 d-flex align-items-center">
                                    CreatedAt : <span className="highlight-text-default  px-2">
                                        {data.createdAt}
                                    </span>
                                </h5>


                            </Grid>

                        </Grid>

                    </div>
                </div>
            </Box>

        </div>
    )
}

export default ViewInstitute
