import React, { useState } from 'react'
import { TextField, Typography, Button, makeStyles, } from '@material-ui/core'
import BackupIcon from '@material-ui/icons/Backup';
import { useFormik } from 'formik';
import * as yup from 'yup'

const useStyles = makeStyles((theme) => ({
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        minWidth: 100,
    },
    tab: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

}));

export default function AddSocial({AddData,setDialog}) {
    const classes = useStyles();
    const [imageval, setImageVal] = useState("")

    const formik = useFormik({
        initialValues: {
            social_media_name: "",
            image: "",
            link: "",
            description: "" 
        },
        validationSchema: yup.object({
            social_media_name: yup.string().required("Required !"),
            image: yup.string().required("Required !"),
            description: yup.string().required("Required !"),
            link: yup.string().required("Required !"),
        }),
        onSubmit: (values) => {
            values.image = imageval
            var form_data = new FormData()
            for (let key in values) {
                form_data.append(key, values[key])
            }
              
            AddData(form_data)
        }
    })
    return (
     
            <form onSubmit={formik.handleSubmit} >
                <Button
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth={true}
                    startIcon={<BackupIcon />}
                    color="primary"
                    component="label">
                    Upload Icone
                    <input
                        onChange={(e) => {
                            setImageVal(e.target.files[0])
                        }} name="image" type="file" hidden />
                </Button>
                {formik.touched.image && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.image}</span>}

                <br /><br />
                <TextField
                    name="social_media_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.social_media_name}
                    fullWidth={true}
                    label="Social Network Name"
                    variant="outlined"
                    size="small"
                />
                {formik.touched.social_media_name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.social_media_name}</span>}
                <br /><br />
                <TextField
                    name="link"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.link}
                    label="Our Link"
                    fullWidth={true}
                    multiline
                    variant="outlined"
                    size="medium" />
                {formik.touched.link && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.link}</span>}

                <br /><br />
                <TextField
                    name="description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    label=" Description"
                    fullWidth={true}
                    multiline
                    variant="outlined"
                    size="medium" />
                {formik.touched.description && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.description}</span>}
                <br /><br />
                <div className="text-center">
                    <Button
                        fullWidth={true}
                        type="submit"
                        variant="contained"
                        color="primary"
                    > submit</Button>
                    <br/>
                    <br/>
                     <Button
                        fullWidth={true}
                        onClick={()=>setDialog(false)}
                        variant="contained"
                        color="secondary"
                    > Close</Button>
                </div>
            </form>
   
        )
}
