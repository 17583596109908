import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const blogApi = createApi({
  tagTypes: ["blogapi" , "updateStatus"],
  reducerPath: "blogapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/blog/`,
  }),
  endpoints: (builder) => ({
    addBlog: builder.mutation({
      invalidatesTags: ["blogapi"],
      query: (body) => ({
        url: "add-blog",
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),
    getAllBlog: builder.mutation({
      invalidatesTags: ["blogapi"],
      query: (body) => ({
        url: `get-all-blog`,
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),
    getAllPendingBlog: builder.mutation({
      invalidatesTags: ["blogapi"],
      query: (body) => ({
        url: `blogs-comments`,
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),
    getAllPendingBlogQuery: builder.query({
      providesTags: ["updateStatus"],
      query: (body) => ({
        url: `blogs-comments`,
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),
    getAllBlogComments: builder.query({
      providesTags: ["blogapi"],
      query: (body) => ({
        url: `blogs-comments`,
        headers: {
          "x-access-token": token,
        },
        method: "POST",
        body,
      }),
    }),
    deletBlog: builder.mutation({
      invalidatesTags: ["blogapi"],
      query(id) {
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
    }),
    updateBlogComment: builder.mutation({
      invalidatesTags: ["blogapi"],
      query(id) {
        return {
          url: `approve-comment/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
        };
      },
    }),
    updatePendingBlogComment: builder.mutation({ 
      invalidatesTags: ["updateStatus"],
      query({body , id}  ) {
        return {
          url: `change-approval/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),
    updateBlog: builder.mutation({
      invalidatesTags: ["blogapi"],
      query({ body, id }) {
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),
    getPendingCount: builder.query({
      providesTags: ["blogapi"],
      query: () => ({
        url: `pending-comments`,
        headers: {
          "x-access-token": token,
        },
        method: "GET",
      }),
    }),
  }),
});
export const {
  useAddBlogMutation,
  useGetAllBlogMutation,
  useDeletBlogMutation,
  useUpdateBlogMutation,
  useGetAllBlogCommentsQuery,
  useUpdateBlogCommentMutation,
  useGetAllPendingBlogMutation,
  useUpdatePendingBlogCommentMutation,
  useGetAllPendingBlogQueryQuery,
  useGetPendingCountQuery
} = blogApi;
