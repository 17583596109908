import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Card,
  CardHeader,
  CardMedia,
  CardActions,
  FormControl, InputLabel,
  Select, OutlinedInput, MenuItem, Checkbox, ListItemText
} from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import { ErrorMessage, Formik, Field, Form } from "formik";
import * as yup from "yup";
import EditorConvertToHTML from "../../dashboard/reusableComponents/EditorConvertToHTML";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import {
  useAddFileMutation,
  useDeleteFileMutation,
} from "../../../../services/file explorer Services/fileExplorerServices";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { SITE_URL } from "../../../../../src/constant/BaseURL";
import { useGetAllConfigDataQuery } from "../../../../services/configuration/ConfigDataServices";

const ITEM_HEIGHT =48;
const ITEM_PADDING_TOP =8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddBlog({
  AddData,
  setDialog,
  UpdateData,
  editDialog,
  editData,
  formType,
}) {
  const [AddFile, AddFileInfo] = useAddFileMutation();
  const [DeleteFile, DeleteFileInfo] = useDeleteFileMutation();
  const [imageInputType, setImageInputType] = useState(
    formType === "edit" ? "URL" : ""
  );
  const [uploadedS3ImageId, setUploadedS3ImageId] = useState("");
  const [orderstate, setOrderType] = React.useState([]);
  

  const handleOrderTypeChange = (event ) => {
    const {
        target: { value },
    } = event;
    setOrderType(
        // On autofill we get a the stringified value.
        typeof value === 'string' ? value.split(',') : value,
    );
};

  const handleAddChip = (values, setFieldValue, key, chip) => {
    let currentChips = [...values[key]];
    currentChips.push(chip);
    setFieldValue(key, currentChips);
  }; 
  const handleDeleteChip = (values, setFieldValue, key, index) => {
    let currentChips = [...values[key]];
    currentChips.splice(index, 1);
    setFieldValue(key, currentChips);
  };
  const deleteImage = (setFieldValue) => {
    DeleteFile(uploadedS3ImageId)
      .then((res) => {
        if (res?.data?.status) {
          setFieldValue("image", "");
        }
      })
      .catch((err) => {
        toast(err);
      });
  };
  const uploadImage = async (e, setFieldValue, values) => {
    if (values.image) {
      try {
        let deleteRes = await DeleteFile(uploadedS3ImageId);
        if (!deleteRes?.data?.status) {
          toast(deleteRes?.data?.message);
          return;
        }
      } catch (err) {
        toast(err);
        return;
      }
    }
    let dataToSend = new FormData();
    dataToSend.append("name", e.target.files[0].name);
    dataToSend.append("type", "blog");
    dataToSend.append("file", e.target.files[0]);
    AddFile(dataToSend)
      .then((res) => {
        if (res?.data?.status && res?.data?.data) {
          setFieldValue("image", res.data.data.url);
          setUploadedS3ImageId(res.data.data._id);
          e.target.value = null;
        } else {
          toast(res?.data?.message);
        }
      })
      .catch((err) => {
        toast(err);
      });
  };
  const handleTitleChange = (e, values, setFieldValue) => {
    let currentTitleValue = e.target.value;
    setFieldValue("title", currentTitleValue);
    if (formType !== "edit") {
      const spaceRegex = / /gi;
      currentTitleValue = currentTitleValue.replace(/[^a-zA-Z0-9 ]/gi, " ");
      currentTitleValue = currentTitleValue.trim();
      let autoGeneratedUrl =
        SITE_URL + currentTitleValue.replaceAll(spaceRegex, "-").toLowerCase();
      let autoGeneratedSlugs = currentTitleValue
        .replaceAll(spaceRegex, "-")
        .toLowerCase();
      setFieldValue("url", autoGeneratedUrl);
      setFieldValue("slugs", autoGeneratedSlugs);
    }
  };
   
  const { data, isFetching, isLoading } = useGetAllConfigDataQuery("Blog-Sub-Category");

  const initialValues = {
    title: formType == "edit" ? editData?.title : "",
    subCategories: formType == "edit" ? editData?.subCategories :[], 
    //otherImages: formType === "edit" ? editData.otherImages : [],
    image: formType === "edit" ? editData.image : "",
    description: formType == "edit" ? editData?.description : "",
    metaTitle: formType === "edit" ? editData.metaTitle : "",
    url: formType === "edit" ? editData.url : "",
    // videoAttachamentUrls: formType === "edit" ? editData.videoAttachamentUrls : [],
    focusKeywords: formType === "edit" ? editData.focusKeywords : [],
    metaDescription: formType === "edit" ? editData.metaDescription : "",
    tags: formType === "edit" ? editData.tags : [],
    slugs: formType === "edit" ? editData.slugs : "",
    image_alt_text: formType === "edit" ? editData.image_alt_text : "",
  };

  const validationSchema = yup.object({
    title: yup.string().required("Required !"),
    // otherImages: yup.array(
    //     yup.string().matches(
    //         /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //         'Enter correct url!'
    //     )
    // ).required("Required !"),
    image: yup
      .string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      )
      .required("Required !"),
    description: yup.string().required("Required !"),
    metaTitle: yup.string().required("Required !"),
    url: yup
      .string()
      .matches(
        "(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})(.[a-zA-Z0-9]{2,})?",
        "Enter correct url!"
      )
      .required("Required !"),
    // videoAttachamentUrls: yup.array(
    //     yup.string().matches(
    //         /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //         'Enter correct url!'
    //     )
    // ).required("Required !"),
    focusKeywords: yup.array().required("Required !"),
    metaDescription: yup.string().required("Required !"),
    tags: yup.array().required("Required !"),
    slugs: yup.string().required("Required !"),
  });

  const onSubmit = (values, { resetForm }) => {
    formType == "edit" ? UpdateData(values, editData?._id) : AddData(values);
    resetForm();
  };

  const AddSubCategoryOption ={
     categoryOption :data?.data[0]?.configurationValues?.map((el)=>{
      return {
        label:el?.value_name ,
        value:el?.value_name
      }
     })
  } 

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => { 
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={6} className="mb-3">
                  <Field name="title">
                    {(props) => {
                      const { field } = props;

                      return (
                        <TextField
                          {...field}
                          type="text"
                          fullWidth
                          variant="outlined"
                          size="small"
                          label="Title"
                          minRows={2}
                          multiline
                          onChange={(e) => {
                            handleTitleChange(e, values, setFieldValue);
                          }}
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage name="title">
                    {(errorMsg) => (
                      <span className="text-danger"> {errorMsg} </span>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={6} className="mb-3">
                  <Field name="metaTitle">
                    {(props) => {
                      const { field } = props;

                      return (
                        <TextField
                          {...field}
                          type="text"
                          fullWidth
                          variant="outlined"
                          size="small"
                          label="metaTitle"
                          minRows={2}
                          multiline
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage name="metaTitle">
                    {(errorMsg) => (
                      <span className="text-danger"> {errorMsg} </span>
                    )}
                  </ErrorMessage>
                </Grid>  
                <Grid item xs={6} className="mb-3"  >
                        <FormControl fullWidth={true}>
                            <InputLabel id="order-multiple-checkbox-label">Blog Sub Category</InputLabel>
                            <Select 
                            name="subCategories"
                                labelId="order-multiple-checkbox-label"
                                id="order-multiple-checkbox"
                                multiple
                                variant='outlined'
                                value={values?.subCategories}
                                onChange={(e)=>{handleOrderTypeChange(e) , setFieldValue('subCategories' , e.target.value)}}
                                label="Blog Sub Category"
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                  },
                                  getContentAnchorEl: null ,
                                  style: {
                                    maxHeight: 350,
                                       },
                                }
                               }
                            >
                                {AddSubCategoryOption?.categoryOption?.map((name) => ( 
                                    <MenuItem key={name?.value} value={name?.label}>
                                        <Checkbox checked={values?.subCategories.indexOf(name?.value) > -1} />
                                        <ListItemText primary={name?.value} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> 
                  <ErrorMessage name="subCategories">
                    {(errorMsg) => (
                      <span className="text-danger"> {errorMsg} </span>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={6} className="mb-1">
                  <Field name="url">
                    {(props) => {
                      const { field } = props;

                      return (
                        <TextField
                          {...field}
                          type="text"
                          fullWidth
                          variant="outlined"
                          size="small"
                          label="url"
                          minRows={2}
                          multiline
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage name="url">
                    {(errorMsg) => (
                      <span className="text-danger"> {errorMsg} </span>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={6} className="mb-1">
                  <Field name="slugs">
                    {(props) => {
                      const { field } = props;

                      return (
                        <TextField
                          {...field}
                          type="text"
                          fullWidth
                          variant="outlined"
                          size="small"
                          label="slugs"
                          minRows={2}
                          multiline
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage name="slugs">
                    {(errorMsg) => (
                      <span className="text-danger"> {errorMsg} </span>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={6} className="mb-3 pt-0">
                  <small className="text-danger">
                    **Please press enter after adding the value in below input.
                    (Below input accepts multiple values.)
                  </small>
                  <Field name="focusKeywords">
                    {(props) => {
                      const { field } = props;

                      return (
                        <ChipInput
                          value={values.focusKeywords}
                          onAdd={(chip) =>
                            handleAddChip(
                              values,
                              setFieldValue,
                              "focusKeywords",
                              chip
                            )
                          }
                          onDelete={(chip, index) =>
                            handleDeleteChip(
                              values,
                              setFieldValue,
                              "focusKeywords",
                              index
                            )
                          }
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="focusKeywords"
                          //onBlur={formik.handleBlur}
                          label="focusKeywords"
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage name="focusKeywords">
                    {(errorMsg) => (
                      <span className="text-danger"> {errorMsg} </span>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={6} className="mb-3 pt-0">
                  <small className="text-danger">
                    **Please press enter after adding the value in below input.
                    (Below input accepts multiple values.)
                  </small>
                  <Field name="tags">
                    {(props) => {
                      const { field } = props;

                      return (
                        <ChipInput
                          value={values.tags}
                          onAdd={(chip) =>
                            handleAddChip(values, setFieldValue, "tags", chip)
                          }
                          onDelete={(chip, index) =>
                            handleDeleteChip(
                              values,
                              setFieldValue,
                              "tags",
                              index
                            )
                          }
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="tags"
                          //onBlur={formik.handleBlur}
                          label="tags"
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage name="tags">
                    {(errorMsg) => (
                      <span className="text-danger"> {errorMsg} </span>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={6} className="mb-3">
                  <Field
                    name="image_alt_text">
                    {(props) => {
                      const { field } = props;

                      return (
                        <TextField
                          {...field}
                          type="text"
                          fullWidth
                          variant="outlined"
                          size="small"
                          label="Image Alt Text
"
                          minRows={2}
                          multiline
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage
                    name="image_alt_text"
                  >
                    {(errorMsg) => (
                      <span className="text-danger"> {errorMsg} </span>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={12} className="mb-3">
                  <Field name="metaDescription">
                    {(props) => {
                      const { field } = props;

                      return (
                        <TextField
                          {...field}
                          type="text"
                          fullWidth
                          variant="outlined"
                          size="small"
                          label="metaDescription"
                          minRows={2}
                          multiline
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage name="metaDescription">
                    {(errorMsg) => (
                      <span className="text-danger"> {errorMsg} </span>
                    )}
                  </ErrorMessage>
                </Grid>

                {
                  <Grid
                    item
                    xs={12}
                    className="mb-3 d-flex justify-content-center aline-items-center border rounded mx-2"
                  >
                    <h6 className="p-2 text-muted">Select image input type </h6>
                    <div className="">
                      {(imageInputType == "FILE" || !imageInputType) && (
                        <Button
                          className="mr-1"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setImageInputType("URL");
                            setFieldValue("image", "");
                          }}
                        >
                          url
                        </Button>
                      )}
                      {(imageInputType == "URL" || !imageInputType) && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setImageInputType("FILE");
                            setFieldValue("image", "");
                          }}
                        >
                          file
                        </Button>
                      )}
                    </div>
                  </Grid>
                }
                {imageInputType && (
                  <Grid
                    item
                    xs={12}
                    className="mb-3 d-flex justify-content-left aline-items-center border border-secondary rounded mx-2"
                  >
                    {imageInputType == "URL" && (
                      <Grid item xs={6}>
                        <Field name="image">
                          {(props) => {
                            const { field } = props;

                            return (
                              <TextField
                                {...field}
                                type="text"
                                fullWidth
                                variant="outlined"
                                size="small"
                                label="image"
                                minRows={2}
                                multiline
                              />
                            );
                          }}
                        </Field>
                        <ErrorMessage name="image">
                          {(errorMsg) => (
                            <span className="text-danger"> {errorMsg} </span>
                          )}
                        </ErrorMessage>
                      </Grid>
                    )}
                    {imageInputType == "FILE" && (
                      <Grid>
                        <p>Image</p>
                        <Field name="file">
                          {(props) => {
                            const { field, form } = props;
                            return (
                              <>
                                <TextField
                                  onChange={(e) => {
                                    uploadImage(e, setFieldValue, values);
                                  }}
                                  variant="outlined"
                                  size="large"
                                  label="image"
                                  name="image"
                                  type="file"
                                />
                                <div className="d-inline p-2 m-1">
                                  <CloudUploadOutlinedIcon
                                    className="text-muted"
                                    fontSize="large"
                                  />
                                </div>
                              </>
                            );
                          }}
                        </Field>
                        <Grid>
                          <ErrorMessage name="image">
                            {(errorMsg) => (
                              <span className="text-danger"> {errorMsg} </span>
                            )}
                          </ErrorMessage>
                        </Grid>
                      </Grid>
                    )}
                    {values.image && (
                      <Card className="border ml-2">
                        <CardMedia
                          component="img"
                          height="100"
                          image={values.image}
                          alt=""
                          width={200}
                        />
                        {imageInputType == "FILE" && (
                          <CardActions>
                            <Button
                              className="mx-5"
                              onClick={() => {
                                deleteImage(setFieldValue);
                              }}
                            >
                              <HighlightOffIcon color="secondary" />
                            </Button>
                          </CardActions>
                        )}
                      </Card>
                    )}
                  </Grid>
                )}

                {/* <Grid item xs={6} className="mb-3">
                                <Field name="videoAttachamentUrls">
                                    {(props) => {
                                        const { field } = props;

                                        return (
                                            <ChipInput
                                                value={values.videoAttachamentUrls}
                                                onAdd={(chip) => handleAddChip(values, setFieldValue, "videoAttachamentUrls", chip)}
                                                onDelete={(chip, index) => handleDeleteChip(values, setFieldValue, "videoAttachamentUrls", index)}
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                name="videoAttachamentUrls"
                                                //onBlur={formik.handleBlur}
                                                label="videoAttachamentUrls"
                                            />

                                        );
                                    }}
                                </Field>
                                <ErrorMessage name="videoAttachamentUrls">
                                    {(errorMsg) => (
                                        <span className="text-danger"> {errorMsg} </span>
                                    )}
                                </ErrorMessage>
                            </Grid> */}
                {/* <Grid item xs={6} className="mb-3">
                                <Field name="otherImages">
                                    {(props) => {
                                        const { field } = props;

                                        return (
                                            <ChipInput
                                                value={values.otherImages}
                                                onAdd={(chip) => handleAddChip(values, setFieldValue, "otherImages", chip)}
                                                onDelete={(chip, index) => handleDeleteChip(values, setFieldValue, "otherImages", index)}
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                name="otherImages"
                                                //onBlur={formik.handleBlur}
                                                label="otherImages"
                                            />
                                        );
                                    }}
                                </Field>
                                <ErrorMessage name="otherImages">
                                    {(errorMsg) => (
                                        <span className="text-danger"> {errorMsg} </span>
                                    )}
                                </ErrorMessage>
                            </Grid> */}
                <Grid item xs={12} className="mb-3">
                  <p>Description</p>
                  <Field name="description">
                    {(props) => {
                      const { field } = props;
                      return (
                        <EditorConvertToHTML
                          keyName="description"
                          value={values.description}
                          setFieldValue={setFieldValue}
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage name="description">
                    {(errorMsg) => (
                      <span className="text-danger"> {errorMsg} </span>
                    )}
                  </ErrorMessage>
                </Grid>
              </Grid>
              <Grid container className="mt-5">
                <Grid className="d-flex justify-content-end" xs={12}>
                  <Button
                    onClick={() =>
                      setDialog ? setDialog(false) : editDialog(false)
                    }
                    variant="contained"
                    color="secondary"
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="ml-2"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <ToastContainer />
    </>
  );
}
