import React, { useState } from "react";
import EditDelete from "./EditDelete";
import DataNotFound from "./DataNotFound";
import CreateTableAddButton from "./CreateTableAddButton";
import SectionForm from "./SectionForm";
import Toster from "./Toster";
import { Button, Grid, Paper, Switch, Box } from "@material-ui/core";
import Skeleton from "@mui/material/Skeleton";
import { phoneRegex, linkRegex } from "../../../constant/regularExpression";

import {
  useGetCmsQuery,
  useUpdateCmsStatusMutation,
  useUpdateCMSDataMutation,
} from "../../../services/cmsServices";
import FormDialog from "./reusableComponents/NewFormDialog";
import * as yup from "yup";
import AuthWrapper from "../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../utils/getFilterFunction";

const columns = [
  { id: "sectionName", label: "Section Name", minWidth: 50 },
  {
    id: "MobileNo",
    label: "Mobile No.",
    minWidth: 50,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "WhatsappNo",
    label: "Whatsapp No.",
    minWidth: 50,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "Email",
    label: "Email",
    minWidth: 50,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "AppDownloadLink",
    label: "App Download Link",
    minWidth: 50,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "Actions",
    label: "Actions",
    minWidth: 50,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
    authName: [
      "CMS_CONTACT_FOOTER_ICONS_CHANGE_STATUS",
      "CMS_CONTACT_FOOTER_ICONS_EDIT",
    ],
  },
];

function createData(
  sectionName,
  MobileNo,
  WhatsappNo,
  Email,
  AppDownloadLink,
  Actions
) {
  return {
    sectionName,
    MobileNo,
    WhatsappNo,
    Email,
    AppDownloadLink,
    Actions,
  };
}

export default function CmsFooterIcons() {
  const rows = [];
  const {
    data: AllData,
    isFetching,
    isLoading,
  } = useGetCmsQuery({ key: "footer_icons" });

  const [updateInnerData, updateInnerDataInfo] = useUpdateCMSDataMutation();
  const [updateIsShowStatus, updateIsShowStatusInfo] =
    useUpdateCmsStatusMutation();

  const [editDialog, setEditDialog] = useState(false);
  const [data, setData] = useState("");

  const dialogclose = () => {
    setEditDialog(false);
  };

  if (!isFetching && !isLoading && AllData.data.length) {
    AllData.data[0].footer_icons.forEach((ele) => {
      rows.push(
        createData(
          ele.type_of_data,
          ele.call,
          ele.whatsapp,
          ele.email,
          ele.app_download,
          <div>
            <AuthWrapper type="ACTION" name="CMS_CONTACT_FOOTER_ICONS_CHANGE_STATUS">
              <Switch
                onClick={() => {
                  updateIsShowStatus({
                    key: "footer_icons",
                    cms_data: {
                      footer_icons_id: ele._id,
                      is_show: !ele.is_show,
                    },
                  });
                }}
                checked={ele.is_show}
              />
            </AuthWrapper>
            <AuthWrapper type="ACTION" name="CMS_CONTACT_FOOTER_ICONS_EDIT">
              <EditDelete
                type="edit"
                setEditDialog={setEditDialog}
                data={ele}
                setData={setData}
              />
            </AuthWrapper>
          </div>
        )
      );
    });
  }

  const onClickHandler = (dataForUpdate) => {
    dataForUpdate.footer_icon_id = data._id;

    let formData = new FormData();

    const cms_data = JSON.stringify(dataForUpdate);

    formData.append("key", "footer_icons");
    formData.append("cms_data", cms_data);

    updateInnerData(formData);
  };

  return (
    <div>
      <AuthWrapper type="ACTION" name="CMS_CONTACT_FOOTER_ICONS_LIST">
        {isFetching || isLoading ? (
          <Box>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />

            <Skeleton animation={false} />
          </Box>
        ) : (
          <>
            {rows.length ? (
              <CreateTableAddButton
                rows={rows}
                columns={getFilterdColumns(columns)}
                isButton={true}
                editform={
                  <FormDialog
                    useAs="form"
                    open={editDialog}
                    setOpen={setEditDialog}
                    formName={"Update Footer Icon"}
                    btnName={"Update"}
                    onBtnClick={onClickHandler}
                    fields={[
                      {
                        fieldType: "text",
                        fieldName: "Section Name",
                        initialValue: data.type_of_data,
                        accessKey: "type_of_data",
                        validation: yup.string().required("Required !"),
                        inputProps: { readOnly: true },
                      },
                      {
                        fieldType: "tel",
                        fieldName: "Mobile No.",
                        initialValue: data.call,
                        accessKey: "call",
                        validation: yup
                          .string()
                          .matches(phoneRegex, "Phone number is not valid")
                          .required("Required !"),
                      },
                      {
                        fieldType: "tel",
                        fieldName: "Whatsapp No.",
                        initialValue: data.whatsapp,
                        accessKey: "whatsapp",
                        validation: yup
                          .string()
                          .matches(phoneRegex, "Phone number is not valid")
                          .required("Required !"),
                      },
                      {
                        fieldType: "email",
                        fieldName: "Email Address",
                        initialValue: data.email,
                        accessKey: "email",
                        validation: yup
                          .string()
                          .email("Email is not valid")
                          .required("Required !"),
                      },
                      {
                        fieldType: "text",
                        fieldName: "App Download Link",
                        initialValue: data.app_download,
                        accessKey: "app_download",
                        validation: yup.string().required("Required !"),
                      },
                    ]}
                  />
                }
                editFormName={"Update Footer Icon"}
                editDialog={editDialog}
                fullsize={false}
              />
            ) : (
              <DataNotFound />
            )}
          </>
        )}
      </AuthWrapper>
      {updateInnerDataInfo.data && (
        <Toster info={updateInnerDataInfo} dialogclose={dialogclose} />
      )}

      {updateIsShowStatusInfo.data && (
        <Toster info={updateIsShowStatusInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
