import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import { useGetAllCurrenciesQuery } from "../../../../services/offlineModuleServices/currencyServices";
import { useGetAllPaymentModesQuery } from "../../../../services/offlineModuleServices/paymentModeServices";
import MoneyIcon from "@material-ui/icons/Money";
import { useGetAllCenterQuery } from "../../../../services/offlineModuleServices/centersServices";
import moment from 'moment';
import * as yup from "yup";
import {
    Grid,
    TextField,
    Button,
    Typography,
    DialogActions,
    Select,
    MenuItem,
    InputLabel,
    Dialog,
    DialogContent,
    Slide,
    DialogTitle,
    Divider,
    FormControl,
} from "@material-ui/core";
import SkeletonListing from '../SkeletonListing';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};




function ExchangeNoteForm({ open, setOpen, onSave, quantityAvailable }) {

    const { data: AllCurrencyNotes, isFetching: isAllCurrencyNotesFetching, isLoading: isAllCurrencyNotesLoading } = useGetAllCurrenciesQuery()
    const { data: AllPaymentModes, isFetching: isAllPaymentModesFetching, isLoading: isAllPaymentModesLoading } = useGetAllPaymentModesQuery()
    const { data: AllCenters } = useGetAllCenterQuery()

    const [initialCashData, setInititalCashData] = useState([])
    const [initialOnlinePaymentData, setInititalOnlinePaymentData] = useState([])


    useEffect(() => {

        setInititalCashData(AllCurrencyNotes?.data?.map((ele) => {
            return { currency_note_name: ele.currency_note, currency_note_value: ele.value, payment_mode: "CASH", quantity: 0 }
        }))
    }, [isAllCurrencyNotesFetching])


    useEffect(() => {

        setInititalOnlinePaymentData(AllPaymentModes?.data?.map((ele) => {
            return { currency_note_name: ele.UPI_ID_ACCOUNT_NO, currency_note_value: 0, payment_mode: "ONLINE", quantity: 0 }
        }))
    }, [isAllPaymentModesFetching])


    let initialValues = {
        cash_deposit_details: initialCashData,
        online_deposit_details: initialOnlinePaymentData,
        cash_withdrawal_details: initialCashData,
        online_withdrawal_details: initialOnlinePaymentData,

        balance_amount: 0,
        transaction_type: "EXCHANGE",
        payment_mode: [],
        added_on_date: "",
        remark: "",
        exchange_amount: "",
        center_id: "",
        deposite_cash_data: [],
        withdraw_cash_data: [],
    }

    const validationSchema = yup.object({
        remark: yup.string().required("This field is required !")
    })

    let formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            let mode = []

            values.cash_deposit_details?.map((ele) => ele.quantity).some((ele) => ele > 0) && !mode.includes("CASH") && mode.push("CASH")
            values.online_deposit_details?.map((ele) => ele.currency_note_value).some((ele) => ele > 0) && !mode.includes("ONLINE") && mode.push("ONLINE")

            values.cash_withdrawal_details?.map((ele) => ele.quantity).some((ele) => ele > 0) && !mode.includes("CASH") && mode.push("CASH")
            values.online_withdrawal_details?.map((ele) => ele.currency_note_value).some((ele) => ele > 0) && !mode.includes("ONLINE") && mode.push("ONLINE")

            let defaultCenter;
            AllCenters?.data?.map((ele) => {
                if (ele.defaultOnInvoice) {
                    defaultCenter = ele._id
                }
            })

            values.cash_deposit_details = values.cash_deposit_details.filter((ele) => {
                return ele.quantity != undefined && ele.quantity != 0
            })

            values.online_deposit_details = values.online_deposit_details.filter((ele) => {
                return ele.currency_note_value != undefined && ele.currency_note_value != 0
            })

            values.cash_withdrawal_details = values.cash_withdrawal_details.filter((ele) => {
                return ele.quantity != undefined && ele.quantity != 0
            })

            values.online_withdrawal_details = values.online_withdrawal_details.filter((ele) => {
                return ele.currency_note_value != undefined && ele.currency_note_value != 0
            })

            values = {
                ...values,
                balance_amount: 0,
                payment_mode: mode,
                added_on_date: moment(new Date()).format("YYYY-MM-DD"),

                deposite_amount: values.cash_deposit_details?.map((ele) => ele.quantity ? (ele.currency_note_value * ele.quantity) : 0)?.reduce((acc, currentValue) => acc + currentValue, 0) +
                    values.online_deposit_details?.map((ele) => ele.currency_note_value ? ele.currency_note_value : 0)?.reduce((acc, currentValue) => acc + currentValue, 0),

                withdraw_amount: values.cash_withdrawal_details?.map((ele) => ele.quantity ? (ele.currency_note_value * ele.quantity) : 0)?.reduce((acc, currentValue) => acc + currentValue, 0) +
                    values.online_withdrawal_details?.map((ele) => ele.currency_note_value ? ele.currency_note_value : 0)?.reduce((acc, currentValue) => acc + currentValue, 0),

                center_id: defaultCenter,

                exchange_amount: values.cash_deposit_details?.map((ele) => ele.quantity ? (ele.currency_note_value * ele.quantity) : 0)?.reduce((acc, currentValue) => acc + currentValue, 0) +
                    values.online_deposit_details?.map((ele) => ele.currency_note_value ? ele.currency_note_value : 0)?.reduce((acc, currentValue) => acc + currentValue, 0),

                deposite_cash_data: [...values.cash_deposit_details, ...values.online_deposit_details],
                withdraw_cash_data: [...values.cash_withdrawal_details, ...values.online_withdrawal_details],

            }

            let formValue;
            for (let key in values) {
                if (key != "cash_deposit_details" && key != "cash_withdrawal_details" && key != "online_deposit_details" && key != "online_withdrawal_details") {
                    formValue = { ...formValue, [key]: values[key] }
                }
            }

            onSave(formValue)

              
            resetForm()
        }
    })
    const TotalDepositeAmount = formik.values.cash_deposit_details?.map((ele) => ele.quantity ? (ele.currency_note_value * ele.quantity) : 0)?.reduce((acc, currentValue) => acc + currentValue, 0) +
        formik.values.online_deposit_details?.map((ele) => ele.currency_note_value ? ele.currency_note_value : 0)?.reduce((acc, currentValue) => acc + currentValue, 0)

    const TotalWithDrawalAmount = formik.values.cash_withdrawal_details?.map((ele) => ele.quantity ? (ele.currency_note_value * ele.quantity) : 0)?.reduce((acc, currentValue) => acc + currentValue, 0) +
        formik.values.online_withdrawal_details?.map((ele) => ele.currency_note_value ? ele.currency_note_value : 0)?.reduce((acc, currentValue) => acc + currentValue, 0)

    return (
        <div>
            <Dialog
                open={open}
                fullScreen
                fullWidth
                maxWidth="lg"
                TransitionComponent={Transition}
                keepMounted
                onClose={() => { setOpen(false) }}
                aria-describedby="alert-dialog-slide-description"
            >
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle style={{ backgroundColor: "#5664d2" }} className="text-light">Exchange Amount Detail</DialogTitle>
                    <DialogContent>


                        <Grid container className='mt-5' >
                            <Grid item xs={6} style={{ borderRight: "1px solid grey" }} className="px-2" >
                                <Typography className='mb-4 text-success '  > <div className='d-flex justify-content-center'> <MoneyIcon /> &nbsp;  DEPOSIT  </div> </Typography>
                                <Grid container >
                                    {AllCurrencyNotes?.data.map((ele, index) => {
                                        return (
                                            <Grid item xs={6} className="mt-3">
                                                <Grid container>

                                                    <Grid item xs={5}>
                                                        <Typography> {ele.currency_note} X </Typography>
                                                    </Grid>

                                                    <Grid item xs={3}>
                                                        <TextField
                                                            type="number"
                                                            name={`cash_deposit_details[${index}].quantity`}
                                                            onChange={formik.handleChange}
                                                            value={formik.values.cash_deposit_details[index]?.quantity}
                                                            variant="standard"
                                                            size="small"
                                                        />

                                                    </Grid>

                                                </Grid>
                                            </Grid>)
                                    })}

                                </Grid>
                                <Divider className='mt-5 mb-3 ' />

                                <Grid container >
                                    {AllPaymentModes?.data.map((ele, index) => {
                                        return (
                                            <Grid item xs={6} className="mt-3">
                                                <Grid container>

                                                    <Grid item xs={5}>
                                                        <Typography className='text-primary'> {ele.UPI_ID_ACCOUNT_NO}  </Typography>

                                                    </Grid>

                                                    <Grid item xs={3}>
                                                        <TextField
                                                            type="number"
                                                            name={`online_deposit_details[${index}].currency_note_value`}
                                                            onChange={formik.handleChange}
                                                            value={formik.values.online_deposit_details[index]?.currency_note_value}
                                                            variant="standard"
                                                            size="small"
                                                        />

                                                    </Grid>

                                                </Grid>
                                            </Grid>)
                                    })}

                                </Grid>


                            </Grid>



                            <Grid item xs={6} className="px-3" >
                                <Typography className=' mb-4 text-danger' > <div className='d-flex justify-content-center'> <MoneyIcon /> &nbsp; WITHDRAWAL  </div> </Typography>
                                <Grid container>
                                    {AllCurrencyNotes.data.map((ele, index) => {
                                        return (
                                            <Grid item xs={6} className="mt-3">
                                                <Grid container>

                                                    <Grid item xs={5}>
                                                        <Typography> {ele.currency_note} X </Typography>

                                                    </Grid>

                                                    <Grid item xs={3}>
                                                        <TextField
                                                            type="number"
                                                            name={`cash_withdrawal_details[${index}].quantity`}
                                                            onChange={formik.handleChange}
                                                            value={formik.values.cash_withdrawal_details[index]?.quantity}
                                                            variant="standard"
                                                            size="small"
                                                        />

                                                        <Grid> <em style={{ fontSize: "15px" }}> Available: <span className='text-info'> {quantityAvailable(ele.currency_note)} </span> </em> </Grid>
                                                        {
                                                            formik.values.cash_withdrawal_details[index]?.quantity > quantityAvailable(ele.currency_note) &&
                                                            <Grid> <em style={{ fontSize: "15px" }} className="text-danger"> {quantityAvailable(ele.currency_note) == 0 ? "No Notes Available" : `Only ${quantityAvailable(ele.currency_note)} notes available`}  </em> </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>)
                                    })}

                                </Grid>

                                <Divider className='mt-5 mb-3 ' />

                                <Grid container >
                                    {AllPaymentModes?.data.map((ele, index) => {
                                        return (
                                            <Grid item xs={6} className="mt-3">
                                                <Grid container>

                                                    <Grid item xs={5}>
                                                        <Typography className='text-primary'> {ele.UPI_ID_ACCOUNT_NO}  </Typography>

                                                    </Grid>

                                                    <Grid item xs={3}>
                                                        <TextField
                                                            type="number"
                                                            name={`online_withdrawal_details[${index}].currency_note_value`}
                                                            onChange={formik.handleChange}
                                                            value={formik.values.online_withdrawal_details[index]?.currency_note_value}
                                                            variant="standard"
                                                            size="small"
                                                        />

                                                    </Grid>

                                                </Grid>
                                            </Grid>)
                                    })}

                                </Grid>

                            </Grid>

                            <Grid item xs={12} className="mt-5">
                                <Grid container>
                                    <Grid item xs={1} >
                                        <Typography>Remark</Typography>
                                    </Grid>

                                    <Grid item xs={10} >
                                        <TextField
                                            type="text"
                                            name="remark"
                                            placeholder="Remark"
                                            labelId="remark"
                                            onChange={formik.handleChange}
                                            value={formik.values.remark}
                                            fullWidth={true}
                                            variant="standard"
                                            size="small"
                                        />
                                        {formik.touched.remark && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.remark}</span>}

                                    </Grid>

                                    {TotalWithDrawalAmount > TotalDepositeAmount &&

                                        <Grid item xs={12}>
                                            <Typography className='mt-4 text-center ' >  <span className='text-danger'> You Are Paying <em className='h5 '> Rs. {TotalWithDrawalAmount - TotalDepositeAmount} </em>  more </span> </Typography>
                                        </Grid>
                                    }


                                    <Grid item xs={12} className="text-center mt-5">
                                        <Typography className='mb-3' > Total Deposit Amount:- <span className='text-success'> &#8377; {TotalDepositeAmount} </span> </Typography>
                                        <Typography type="number"> Total Withdrawal Amount:- <span className='text-danger'> &#8377; {TotalWithDrawalAmount} </span> </Typography>

                                    </Grid>

                                </Grid>
                            </Grid>

                        </Grid>



                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary" onClick={() => { setOpen(false) }}>Close</Button>
                        <Button id='saveBtn' variant="contained" disabled={TotalDepositeAmount != TotalWithDrawalAmount && "disabled"} color="primary" type="submit">Save</Button>

                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}

export default ExchangeNoteForm
