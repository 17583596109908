
import React, { useState, useEffect } from 'react';
import EditDelete from './EditDelete';
import CreateTableAddButton from './CreateTableAddButton'
import UpdateCoupon from './UpdateCoupon'
import ViewCoupons from './ViewCoupons';
import Toster from './Toster';
import AddCoupon from './AddCoupon';
import Loader from './Loader';
import AddDataHeader from './AddDataHeader';
import {
    useGetAllCouponMutation,
    useDeletCouponMutation,
    useAddCouponMutation,
    useUpdateCouponMutation,
} from '../../../services/services';
import SkeletonListing from './SkeletonListing';
import DataNotFound from './DataNotFound';


export default function Downloads() {
    const columns = [
        {
            id: 'couponCode',
            label: 'Coupon Code',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'expiryTime',
            label: 'Expiry Time',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'CouponType',
            label: 'Coupon Type',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'Appliedby',
            label: 'Apply By',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'maxUse',
            label: 'Coupon Use',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'Action',
            label: 'Actions',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },

    ];


    function createData(SNo, couponCode,
        expiryTime,
        CouponType,
        Appliedby,
        maxUse,
        Action,) {
        return {
            SNo, couponCode,
            expiryTime,
            CouponType,
            Appliedby,
            maxUse,
            Action,
        };
    }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const paginationData = {
        "limit": rowsPerPage,
        "query": "",
        "params": [
            "coupon_code"
        ],
        "page": page + 1
    }


    let rows = []

    let [getid, setGetid] = useState('')
    const [data, setData] = useState('')
    const [AddDialog, setDialog] = useState(false)
    const [editDialog, setEditDialog] = useState(false)
    const [viewDialog, setViewDialog] = useState(false)
    const [getAllData, getAllDataInfo] = useGetAllCouponMutation()
    const [deleteData, deleteDataInfo] = useDeletCouponMutation()
    const [addData, addDataInfo] = useAddCouponMutation()
    const [updateCouponData, updateDataInfo] = useUpdateCouponMutation()
    const AllData = getAllDataInfo.data
    const totalItem = AllData ? AllData.totalItem : 0
    const [render, setRender] = useState(false)

    useEffect(() => {
        getAllData(paginationData)
    }, [render, rowsPerPage, page,])

    useEffect(() => {
        getid && deleteData(getid) && setGetid("")
    }, [getid])


    const AddData = (data) => {
        addData(data)
    }
    const UpdateData = (data, id) => {
          
        updateCouponData({ body: data, id: id })
    }

    let totalData = "ok"
    if (AllData) {
        totalData = AllData.totalItem
        rows = AllData.data.map((data, index) => createData(
            index + 1,
            data.coupon_code,
            data.expiry_time,
            data.coupon_type,
            data.generated_by_sldUserType=="ADMIN"? "ADMIN" :data.generated_by_sldUser[0].name,
            data.max_count ,
            <div className="d-flex justify-content-center">
                {/* <EditDelete setViewDialog={setViewDialog}
                    type="view" setData={setData}
                    setGetid={setGetid} data={data} />
                <EditDelete
                    setEditDialog={setEditDialog}
                    type="edit" setData={setData}
                    setGetid={setGetid} data={data} />
                <EditDelete type="delete"
                    setGetid={setGetid}
                    id={data._id} /> */}
            </div>
        )
        )
    }
    const dialogclose = () => {
        setEditDialog(false)
        setViewDialog(false)
        setDialog(false)
        setRender(!render)
    }
    return (
        <div>
            {!viewDialog  && <AddDataHeader
                addFormName={"Add Student Deatails"}
                setPage={setPage}
                page={page}
                excelBtn={false}
                totalItem={totalItem}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setAddDialog={setDialog}
                searchBarApi={getAllData}
                paginationData={paginationData}
                fullsize={true}
                isButton={true}
                Adddialog={AddDialog}
                form={<AddCoupon AddData={AddData} setDialog={setDialog} />}
            />}
            {deleteDataInfo.isLoading || addDataInfo.isLoading || updateDataInfo.isLoading ? <Loader /> : <>       {
                !viewDialog ?
                    rows.length ?
                        <CreateTableAddButton
                            editDialog={editDialog}
                            rows={rows}
                            fullsize={true}
                            editform={
                                <UpdateCoupon
                                    UpdateData={UpdateData}
                                    editData={data}
                                    editDialog={setEditDialog} />
                            }
                            columns={columns}
                            editFormName={'Edit Coupon'} />
                        : totalData == 0 ? <DataNotFound /> : <SkeletonListing />
                    :
                    <ViewCoupons viewDialog={setViewDialog} data={data} />}
            </>}
            {deleteDataInfo.data && <Toster info={deleteDataInfo} dialogclose={dialogclose} />}
            {addDataInfo.data && <Toster info={addDataInfo} dialogclose={dialogclose} />}
            {updateDataInfo && <Toster info={updateDataInfo} dialogclose={dialogclose} />}

        </div>
    )
}