import React, { forwardRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid, TextField } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Paper from "@material-ui/core/Paper";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  SplineSeries,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { ValueScale, Animation } from "@devexpress/dx-react-chart";
import MyTable from "./MyTable";
import { useGetAllDashboardDataMutation } from "../../../services/services";
import moment from "moment";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
const Dashboard = () => {
  const classes = useStyles();
  // let now = new Date();
  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");

  // const [excelFilterDatesErrors, setExcelFilterDatesErrors] = useState({
  //   from: "",
  //   to: "",
  // });
  const [getAllData, getAllDataInfo] = useGetAllDashboardDataMutation({});
  const allDashboardData = getAllDataInfo;

  const [excelFilterDates, setExcelFilterDates] = useState({
    from: moment(startOfMonth).format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"), // Using the current date as the default 'to' date
  });

  React.useEffect(() => {
    getAllData({
      startDate: excelFilterDates.from,
      endDate: excelFilterDates.to,
      orderType: "ONLINE_WEB",
    });
  }, [getAllData, excelFilterDates.from, excelFilterDates.to]);

  return (
    <>
      <DialogTitle>Select Dates</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={5}>
            <Grid xs={6} item>
              <TextField
                type="date"
                fullWidth
                onChange={(e) => {
                  setExcelFilterDates({
                    ...excelFilterDates,
                    from: e.target.value,
                  });
                }}
                name="from"
                value={excelFilterDates.from}
                label="From Date"
                variant="outlined"
              />
              {/* <span style={{ fontSize: "12px" }} className="text-danger">
                {excelFilterDatesErrors.from}
              </span> */}
            </Grid>
            <Grid xs={6} item>
              <TextField
                type="date"
                fullWidth
                onChange={(e) => {
                  setExcelFilterDates({
                    ...excelFilterDates,
                    to: e.target.value,
                  });
                }}
                name="to"
                value={excelFilterDates.to}
                label="To Date"
                variant="outlined"
              />
              {/* <span style={{ fontSize: "12px" }} className="text-danger">
                {excelFilterDatesErrors.to}
              </span> */}
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <Grid
        container
        spacing={3}
        style={{ marginTop: 50, padding: 20 }}
        direction="row"
      >
        {/* Heading */}
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              marginBottom: "20px",
              textAlign: "center", // Add this line
            }}
          >
            Online Video Lecture Orders
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ background: "#6ADF78" }} className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              ></Typography>
              <Typography align="center" style={{ color: "#fff" }}>
                <h5>Successfull</h5>
              </Typography>

              <Typography align="center" style={{ color: "#fff" }}>
                <h3>
                  {allDashboardData?.data?.data?.AllOrders?.successfulOrder ||
                    "0"}
                </h3>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ background: "#FFD65F" }} className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              ></Typography>
              <Typography align="center" style={{ color: "#fff" }}>
                <h5>Pending </h5>
              </Typography>

              <Typography align="center" style={{ color: "#fff" }}>
                <h3>
                  {allDashboardData?.data?.data?.AllOrders?.pendingOrder || "0"}
                </h3>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ background: "#FF6348" }} className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              ></Typography>
              <Typography align="center" style={{ color: "#fff" }}>
                <h5>Cancelled</h5>
              </Typography>

              <Typography align="center" style={{ color: "#fff" }}>
                <h3>
                  {allDashboardData?.data?.data?.AllOrders?.cancelledOrder || 0}
                </h3>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ background: "#6c757d" }} className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              ></Typography>
              <Typography align="center" style={{ color: "#fff" }}>
                <h5>Failed</h5>
              </Typography>

              <Typography align="center" style={{ color: "#fff" }}>
                <h3>
                  {allDashboardData?.data?.data?.AllOrders?.failedOrder || 0}
                </h3>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* <Grid container spacing={3} style={{ padding: 20 }}>
        <Grid item xs={4}>
          <Card style={{ background: "#FF6865" }} className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              ></Typography>
              <Typography align="center" style={{ color: "#fff" }}>
                <h5>Today's Failed Orders</h5>
              </Typography>

              <Typography align="center" style={{ color: "#fff" }}>
                <h3>0</h3>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card style={{ background: "#FF6764" }} className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              ></Typography>
              <Typography align="center" style={{ color: "#fff" }}>
                <h5>Today's CallBack Requests</h5>
              </Typography>

              <Typography align="center" style={{ color: "#fff" }}>
                <h3>110</h3>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card style={{ background: "#FF6764" }} className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              ></Typography>
              <Typography align="center" style={{ color: "#fff" }}>
                <h5>Today's Review Rating Pending</h5>
              </Typography>

              <Typography align="center" style={{ color: "#fff" }}>
                <h3>24</h3>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid> */}
      {/* <Grid container style={{ marginTop: "2%" }}>
        <Grid item xs={12} style={{ padding: "1%" }}>
          <Paper>
            <Chart data={data}>
              <ArgumentAxis />
              <ValueAxis />
              <BarSeries valueField="value" argumentField="argument" />
            </Chart>
          </Paper>
        </Grid> */}
      {/* <Grid item xs={6} style={{padding:"0.2%"}}>
           <MyTable button="Recent Sales" data={tableData} columns={columns} />
        </Grid> */}
      {/* </Grid> */}
    </>
  );
};
export default Dashboard;
