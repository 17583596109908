import React, { useState, useEffect } from "react";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  useGetAallFacultieNamesQuery,
  //   useGetAllProductsQuery,
  useGetVideoLectureProductQuery,
  useGetUsersListingQuery,
  useGetAllBookProductFilterMutation,
  useGetAllTestSereisMutation,
} from "../../../services/services";
import { useFormik } from "formik";
import * as yup from "yup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { toast } from "react-toastify";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const notify = (msg) => toast(msg);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function AddCoupon({ setDialog, AddData }) {
  const [viewFaculties, setViewFaculties] = useState([
    {
      discount_value: "",
      faculty_id: "",
      discount_type: "",
      value_error: "",
      id_error: "",
      type_error: "",
    },
  ]);
  const [viewProduct, setViewProduct] = useState([
    {
      //variant: [],
      product_id: "",
      product_type: "SLD_BOOK_PRODUCT",
      id_error: "",
      faculty: [],
      faculty_error: "",
      faculty_discounts: [],
      selected_variant: null,
    },
  ]);
  const [viewVideoLecture, setViewVideoLecture] = useState([
    {
      //variant: [],
      product_id: "",
      product_type: "SLD_VIDEO_LECTURE",
      id_error: "",
      faculty: [],
      faculty_error: "",
      faculty_discounts: [],
      selected_variant: null,
    },
  ]);
  const [viewTestSeries, setViewTestSeries] = useState([
    {
      //variant: [],
      product_id: "",
      product_type: "SLD_TEST_SERIES",
      id_error: "",
      faculty: [],
      faculty_error: "",
      faculty_discounts: [],
      selected_variant: null,
    },
  ]);

  const allFacultieNames = useGetAallFacultieNamesQuery();
  //   const allProduct = useGetAllProductsQuery();
  const [bookProduct, bookProductInfo] = useGetAllBookProductFilterMutation();
  const [testSeriesProduct, testSeriesProductInfo] =
    useGetAllTestSereisMutation();

  const {data:userListData , isFetching  , isLoading} = useGetUsersListingQuery()

  const [allUsers, setAllUsers] = useState();
  const [coupontype, setcouponType] = React.useState();
  const [faculty, setFaculty] = useState();
  const [Product, setProduct] = useState();
  const [testSeries, setTestSeriesProduct] = useState();
  const [videLectures, setVideoLectures] = useState([]);
  const [orderstate, setOrderType] = React.useState([]);
  const [allProductsCoupon, setAllProductsCoupon] = React.useState([]);
  const [users, setUsers] = React.useState([]);

  const allVideolectures = useGetVideoLectureProductQuery();

  useEffect(() => {
    let { isSuccess, data } = allVideolectures;
    if (isSuccess) {
      setVideoLectures(data);
    }
  }, [allVideolectures]);

  let now = new Date();
  const handleOrderTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    setOrderType(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const orders = ["SLD_VIDEO_LECTURE", "SLD_BOOK_PRODUCT", "SLD_TEST_SERIES"];
  useEffect(() => {
    let { isSuccess, data } = allFacultieNames;
    if (isSuccess) {
      setFaculty(data.data);
    }
  }, [allFacultieNames]);

  // const [videoLectureData, videoLectureDataInfo] =
  //   useGetAllVideoLectureProductFilterMutation();

  useEffect(() => {
    bookProduct({
      limit: 10,
      searchValue: "",
      params: [
        "_id",
        "product_name",
        "nickname",
        "code",
        "varient_code",
        "publication_name",
        "faculty.faculty_name",
        "createdAt",
        "updatedAt",
      ],
      page: 1,
      filterBy: [
        {
          fieldName: "",
          value: [],
        },
      ],
      orderBy: "createdAt",
      orderByValue: -1,
      isPaginationRequired: false,
    });

    // videoLectureData({
    //   limit: 10,
    //   searchValue: "",
    //   params: ["video_lecture_name"],
    //   page: 1,
    //   filterBy: [
    //     {
    //       fieldName: "",
    //       value: [],
    //     },
    //   ],
    //   orderBy: "createdAt",
    //   orderByValue: -1,
    //   isPaginationRequired: false,
    // });
    testSeriesProduct({
      limit: 10,
      searchValue: "",
      params: [
        "_id",
        "product_name",
        "nickname",
        "code",
        "varient_code",
        "publication_name",
        "faculty.faculty_name",
        "createdAt",
        "updatedAt",
      ],
      page: 1,
      filterBy: [
        {
          fieldName: "",
          value: [],
        },
      ],
      orderBy: "createdAt",
      orderByValue: -1,
      isPaginationRequired: false,
    });
  }, []);

  // useEffect(() => {
  //   if (videoLectureDataInfo.status == "fulfilled") {
  //     setVideoLectures(videoLectureDataInfo?.data);
  //   }
  // }, [videoLectureDataInfo]);

  useEffect(() => {
    if (bookProductInfo.status == "fulfilled") {
      setProduct(bookProductInfo?.data);
    }
  }, [bookProductInfo]);

  useEffect(() => {
    if (testSeriesProductInfo.status == "fulfilled") {
      setTestSeriesProduct(testSeriesProductInfo?.data?.data);
    }
  }, [testSeriesProductInfo]);

  useEffect(() => {
    if (!isLoading || !isFetching ) {
      setAllUsers(userListData.data);
    }
  }, [userListData ,isLoading ,isFetching ]);

  const today = moment(); // Current date and time
  const futureDate = today.add(5, "minutes"); // Adding 5 minutes

  // futureDate.format("YYYY-MM-DD HH:mm:ss")
  const formik = useFormik({
    initialValues: {
      coupon_code: "",
      min_order_amount: "",
      discount_type: "",
      discount_value: 0,
      starts_date_time: futureDate.format("YYYY-MM-DDTHH:mm") || "",
      expiry_date_time: "",
      coupon_type: coupontype,
      applied_on_type: orderstate,
      faculty: "",
      customer: "",
      max_count: "",
      applied_count: 0,
      // is_active: "",
      product: [],
      discountBy: "",
    },
    validationSchema: yup.object({
      coupon_type: yup.string().required("Required !"),
      discountBy:
        coupontype == "FACULTY"
          ? yup.string().required("Required !")
          : yup.string(),
      discount_value: yup.number(),
      expiry_date_time: yup
        .date()
        .when(
          "starts_date_time",
          (starts_date_time, schema) =>
            starts_date_time &&
            schema.min(
              starts_date_time,
              "Invalid date time, end date should be later than start date time!"
            )
        ),
      starts_date_time: yup
        .date()
        .required("Required !")
        .min(
          new Date(),
          "Invalid date time, start date should be later than current date time!"
        ),
      // discount_type: yup.string().required("Required !"),
      // min_order_amount: yup.string().required("Required !"),
      max_count: yup.number().required("Required !"),
      // customer: yup.array().required("Required !"),
      // is_active: yup.string().required("Required !"),
    }),
    onSubmit: (values) => {
      values.coupon_type = coupontype;
      values.applied_on_type = orderstate;
      values.faculty =
        !viewFaculties[0].faculty_id || coupontype != "FACULTY"
          ? []
          : viewFaculties.map((e, i) => {
              return {
                faculty_id: e.faculty_id._id,
                discount_type: e.discount_type,
                discount_value: parseInt(e.discount_value),
              };
            });
      values.product =
        !allProductsCoupon[0].product_id || coupontype != "PRODUCT"
          ? []
          : allProductsCoupon.map((e, i) => {
              return {
                product_type: e.product_type,
                product_id: e.product_id._id,
                faculty_data: e.faculty_discounts,
                // discount_value: parseInt(e.discount_value),
                // discount_type: e.discount_type
              };
            });
      let startDate = moment(values.starts_date_time).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      let endDate = moment(values.expiry_date_time).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      values.starts_date_time = startDate;
      values.expiry_date_time = endDate;

      if (coupontype == "FACULTY") {
        if (!values.faculty.length) {
          notify("Faculty is required");
          return;
        } else {
          if (!checkValidFacultyData()) {
            return;
          }
        }
      }
      if (coupontype == "PRODUCT") {
        if (!values.product.length) {
          notify("Product is required");
          return;
        } else {
          if (
            !checkValidProductData() &&
            orderstate.includes("SLD_VIDEO_LECTURE")
          ) {
            return;
          }

          if (
            !checkValidProductDataBook() &&
            orderstate.includes("SLD_BOOK_PRODUCT")
          ) {
            return;
          }

          if (
            !checkValidProductDataTestSeries() &&
            orderstate.includes("SLD_TEST_SERIES")
          ) {
            return;
          }
        }
      }
      if (coupontype == "ORDER" && values.min_order_amount < 1) {
        notify("Minimum order amount is required");
        return;
      }

      AddData(values);
    },
  });
  const dialogclose = () => {
    setDialog(false);
  };
  const checkValidFacultyData = () => {
    let facultyData = [...viewFaculties];
    let isValid = true;
    for (let index = 0; index < facultyData.length; index++) {
      facultyData[index].discount_type == ""
        ? (facultyData[index].type_error = "Discount type is required!")
        : (facultyData[index].type_error = "");
      facultyData[index].discount_value == ""
        ? (facultyData[index].value_error = "Discount value is required!")
        : (facultyData[index].value_error = "");
      facultyData[index].faculty_id == ""
        ? (facultyData[index].id_error = "Faculty is required!")
        : (facultyData[index].id_error = "");

      if (
        !facultyData[index].discount_type ||
        !facultyData[index].discount_value ||
        !facultyData[index].faculty_id
      ) {
        isValid = false;
      }
    }
    setViewFaculties([...facultyData]);
    return isValid;
  };
  const checkValidProductData = () => {
    let videoLectureData = [...viewVideoLecture];
    let isValid = true;
    for (let index = 0; index < videoLectureData.length; index++) {
      videoLectureData[index].product_id == ""
        ? (videoLectureData[index].id_error = "Video Lecture is required!")
        : (videoLectureData[index].id_error = "");
      let facultyDiscounts = videoLectureData[index].faculty_discounts.filter(
        (e, i) => {
          return e.discount_type && e.faculty_discount;
        }
      );
      if (!facultyDiscounts.length) {
        videoLectureData[index].faculty_error = "Faculty discount is required!";
        isValid = false;
      } else {
        videoLectureData[index].faculty_error = "";
      }
      if (!videoLectureData[index].product_id) {
        isValid = false;
      }
    }
    setViewVideoLecture([...videoLectureData]);
    return isValid;
  };
  const checkValidProductDataBook = () => {
    let bookProduct = [...viewProduct];
    let isValid = true;
    for (let index = 0; index < bookProduct.length; index++) {
      bookProduct[index].product_id == ""
        ? (bookProduct[index].id_error = "Book Product is required!")
        : (bookProduct[index].id_error = "");
      let facultyDiscounts = bookProduct[index].faculty_discounts.filter(
        (e, i) => {
          return e.discount_type && e.faculty_discount;
        }
      );
      if (!facultyDiscounts.length) {
        isValid = false;
        bookProduct[index].faculty_error = "Faculty discount is required!";
      } else {
        bookProduct[index].faculty_error = "";
      }
      if (!bookProduct[index].product_id) {
        isValid = false;
      }
    }
    setViewProduct([...bookProduct]);
    return isValid;
  };

  const checkValidProductDataTestSeries = () => {
    let testSeriesProduct = [...viewTestSeries];
    let isValid = true;
    for (let index = 0; index < testSeriesProduct.length; index++) {
      testSeriesProduct[index].product_id == ""
        ? (testSeriesProduct[index].id_error = "Book Product is required!")
        : (testSeriesProduct[index].id_error = "");
      let facultyDiscounts = testSeriesProduct[index].faculty_discounts.filter(
        (e, i) => {
          return e.discount_type && e.faculty_discount;
        }
      );
      if (!facultyDiscounts.length) {
        isValid = false;
        testSeriesProduct[index].faculty_error =
          "Faculty discount is required!";
      } else {
        testSeriesProduct[index].faculty_error = "";
      }
      if (!testSeriesProduct[index].product_id) {
        isValid = false;
      }
    }
    setViewTestSeries([...testSeriesProduct]);
    return isValid;
  };
  const addNewFaculty = () => {
    let currentData = [...viewFaculties];
    currentData.push({ discount_value: "", faculty_id: "", discount_type: "" });
    setViewFaculties([...currentData]);
  };
  const handleFacutlyChange = (ele, ind, key) => {
    let currentData = [...viewFaculties];
    key == "faculty_id"
      ? (currentData[ind][key] = ele)
      : (currentData[ind][key] = ele.target.value);
    setViewFaculties([...currentData]);
  };

  const deleteFaculty = (faculty, e) => {
    e.preventDefault();
    let currentData = [...viewFaculties];
    currentData = currentData.filter((data) => {
      return data.faculty_id != faculty;
    });
    if (currentData.length == 0) {
      currentData.push({
        discount_value: "",
        faculty_id: "",
        discount_type: "",
      });
    }
    setViewFaculties(currentData);
  };

  ///book produc start
  const addNewProduct = () => {
    let currentData = [...viewProduct];
    currentData.push({
      //variant: [],
      product_id: "",
      product_type: "SLD_BOOK_PRODUCT",
      id_error: "",
      faculty: [],
      faculty_error: "",
      faculty_discounts: [],
      selected_variant: null,
    });
    setViewProduct([...currentData]);
  };
  const handleProductChange = (ele, ind, key) => {
    let currentData = [...viewProduct];

    key == "product_id"
      ? (currentData[ind][key] = ele)
      : (currentData[ind][key] = ele.target.value);
    currentData[ind]["faculty"] = ele.faculty;
    currentData[ind]["faculty_discounts"] = ele.faculty.map((e, i) => {
      return {
        faculty_id: e.faculty_id,
        faculty_name: e.faculty_name,
        discount_type: e.faculty_mrp == 0 ? "FLAT" : "",
        faculty_discount: e.faculty_mrp == 0 ? 0 : "",
      };
    });
    currentData[ind]["selected_variant"] = ele.code;

    setViewProduct([...currentData]);
  };
  const deleteProduct = (product) => {
    let currentData = [...viewProduct];
    currentData = currentData.filter((data) => {
      return data.product_id != product;
    });
    setViewProduct([...currentData]);
  };

  const handleFacultyDiscountChangeBookProduct = (
    value,
    ind,
    facultyInd,
    key
  ) => {
    let currentData = [...viewProduct];
    currentData[ind]["faculty_discounts"][facultyInd][key] = value;
    setViewProduct([...currentData]);
  };
  // book product end

  //test series start
  const addNewTestSeries = () => {
    let currentData = [...viewTestSeries];
    currentData.push({
      //variant: [],
      product_id: "",
      product_type: "SLD_TEST_SERIES",
      id_error: "",
      faculty: [],
      faculty_error: "",
      faculty_discounts: [],
      selected_variant: null,
    });
    setViewTestSeries([...currentData]);
  };
  const handleProductChangeTestSeries = (ele, ind, key) => {
    let currentData = [...viewTestSeries];

    key == "product_id"
      ? (currentData[ind][key] = ele)
      : (currentData[ind][key] = ele.target.value);
    currentData[ind]["faculty"] = ele.faculty;
    currentData[ind]["faculty_discounts"] = ele.faculty.map((e, i) => {
      return {
        faculty_id: e.faculty_id,
        faculty_name: e.faculty_name,
        discount_type: e.faculty_mrp == 0 ? "FLAT" : "",
        faculty_discount: e.faculty_mrp == 0 ? 0 : "",
      };
    });
    currentData[ind]["selected_variant"] = ele.code;

    setViewTestSeries([...currentData]);
  };
  const deleteProductTestSeries = (product) => {
    let currentData = [...viewTestSeries];
    currentData = currentData.filter((data) => {
      return data.product_id != product;
    });
    setViewTestSeries([...currentData]);
  };

  const handleFacultyDiscountChangeTestSeries = (
    value,
    ind,
    facultyInd,
    key
  ) => {
    let currentData = [...viewTestSeries];
    currentData[ind]["faculty_discounts"][facultyInd][key] = value;
    setViewTestSeries([...currentData]);
  };
  //test series end
  const addNewVideoLecture = () => {
    let currentData = [...viewVideoLecture];
    currentData.push({
      //variant: [],
      product_id: "",
      product_type: "SLD_VIDEO_LECTURE",
      id_error: "",
      faculty: [],
      faculty_error: "",
      faculty_discounts: [],
      selected_variant: null,
    });
    setViewVideoLecture([...currentData]);
  };
  const handleVideoLectureChange = (ele, ind, key) => {
    let currentData = [...viewVideoLecture];
    key == "product_id"
      ? (currentData[ind][key] = ele)
      : (currentData[ind][key] = ele.target.value);

    //key == "product_id" ? currentData[ind]["varient"] = ele.variant : currentData[ind]["varient"] = []
    setViewVideoLecture([...currentData]);
  };
  const handleVarientChange = (ele, ind, key) => {
    let currentData = [...viewVideoLecture];
    currentData[ind]["faculty"] = ele.faculty;
    currentData[ind]["faculty_discounts"] = ele.faculty.map((e, i) => {
      return {
        faculty_id: e.faculty_id,
        faculty_name: e.faculty_name,
        discount_type: e.faculty_mrp == 0 ? "FLAT" : "",
        faculty_discount: e.faculty_mrp == 0 ? 0 : "",
      };
    });
    currentData[ind]["selected_variant"] = {
      mode_mrp: ele.mode_mrp,
      mode_name: ele.mode_name,
      varient_code: ele.varient_code,
      varient_id: ele.varient_id,
    };
    setViewVideoLecture([...currentData]);
  };

  const handleFacultyDiscountChange = (value, ind, facultyInd, key) => {
    let currentData = [...viewVideoLecture];
    currentData[ind]["faculty_discounts"][facultyInd][key] = value;
    setViewVideoLecture([...currentData]);
  };
  const deleteVideoLecture = (videoLecture, e) => {
    e.preventDefault();
    let currentData = [...viewVideoLecture];
    currentData = currentData.filter((data) => {
      return data.product_id != videoLecture;
    });
    if (currentData.length == 0) {
      currentData.push({
        //variant: [],
        product_id: "",
        product_type: "SLD_VIDEO_LECTURE",
        id_error: "",
        faculty: [],
        faculty_error: "",
        faculty_discounts: [],
        selected_variant: null,
      });
    }
    setViewVideoLecture(currentData);
  };

  useEffect(() => {
    let Products = [];
    if (viewProduct.length) {
      if (viewProduct[0].product_id) {
        Products = [...Products, ...viewProduct];
      }
    }
    if (viewVideoLecture.length && viewVideoLecture[0].product_id) {
      Products = [...Products, ...viewVideoLecture];
    }
    if (viewTestSeries.length && viewTestSeries[0].product_id) {
      Products = [...Products, ...viewTestSeries];
    }

    setAllProductsCoupon([...Products]);
  }, [viewProduct, viewVideoLecture, viewTestSeries]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3} fullWidth={true}>
        <Grid item xs={6}>
          <FormControl
            style={{ margin: 0 }}
            fullWidth={true}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-Institue-Name-native-simple">
              type
            </InputLabel>
            <Select
              native
              name="coupon_type"
              onBlur={formik.handleBlur}
              value={formik.values.coupon_type}
              onChange={(e) => {
                formik.values.coupon_type = e.target.value;
                setcouponType(e.target.value);
              }}
              label="type"
            >
              <option aria-label="None" value="" />
              <option value="ORDER"> Order</option>
              <option value="FACULTY"> Faculty</option>
              <option value="PRODUCT"> Product</option>
            </Select>
          </FormControl>
          {formik.touched.coupon_type && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.coupon_type}
            </span>
          )}
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="coupon_code"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.coupon_code}
            fullWidth={true}
            label="Coupon Name"
            variant="outlined"
            size="medium"
          />
        </Grid>

        {(coupontype == "ORDER" || coupontype == "PRODUCT") && (
          <Grid item xs={6}>
            <FormControl fullWidth={true}>
              <InputLabel id="order-multiple-checkbox-label">
                Product Type
              </InputLabel>
              <Select
                labelId="order-multiple-checkbox-label"
                id="order-multiple-checkbox"
                multiple
                variant="outlined"
                value={orderstate}
                onChange={handleOrderTypeChange}
                label="Product Type"
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {orders.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={orderstate.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {coupontype == "ORDER" && (
          <Grid item xs={6}>
            <TextField
              name="min_order_amount"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.min_order_amount}
              fullWidth={true}
              label="Min Order Amount"
              variant="outlined"
              size="medium"
            />
            {formik.touched.min_order_amount && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {formik.errors.min_order_amount}
              </span>
            )}
          </Grid>
        )}
        <Grid item xs={6}>
          <TextField
            name="starts_date_time"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.starts_date_time}
            id="datetime-local"
            label="Start From"
            size="medium"
            type="datetime-local"
            fullWidth={true}
            sx={{ width: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {formik.touched.starts_date_time && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.starts_date_time}
            </span>
          )}
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="expiry_date_time"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.expiry_date_time}
            id="datetime-local"
            label="Expired date and time"
            type="datetime-local"
            fullWidth={true}
            sx={{ width: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {formik.touched.expiry_date_time && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.expiry_date_time}
            </span>
          )}
        </Grid>

        {coupontype == "FACULTY" && (
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="discountBy">Discount By</InputLabel>
              <Select
                labelId="discountBy"
                id="discountBy"
                value={formik.values.discountBy}
                label="Discount By"
                onChange={(e) => {
                  formik.setFieldValue("discountBy", e.target.value);
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"FACULTY"}>FACULTY</MenuItem>
                <MenuItem value={"US"}>US</MenuItem>
              </Select>
            </FormControl>
            {formik.touched.discountBy && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {formik.errors.discountBy}
              </span>
            )}
          </Grid>
        )}

        <Grid item xs={6}>
          {allUsers && (
            <>
              <Autocomplete
                variant="outlined"
                fullWidth={true}
                multiple
                id={"customer"}
                value={users}
                onChange={(e, newValue) => {
                  setUsers(newValue);
                  formik.values.customer = newValue;
                }}
                //filterSelectedOptions= {true}
                options={allUsers}
                disableCloseOnSelect
                getOptionLabel={(option) => option["email"]}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option["email"]}
                  </li>
                )}
                // style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField
                    label="Choose User"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
              {formik.touched.customer && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.customer}
                </span>
              )}
            </>
          )}
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="max_count"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.max_count}
            fullWidth={true}
            label="Max Count "
            variant="outlined"
            size="medium"
            type="number"
          />
          {formik.touched.max_count && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.max_count}
            </span>
          )}
        </Grid>

        {coupontype == "ORDER" && (
          <Grid item xs={6}>
            <FormControl
              style={{ margin: 0 }}
              fullWidth={true}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-Institue-Name-native-simple">
                Discount type
              </InputLabel>
              <Select
                name="discount_type"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.discount_type}
                native
                label="Discount type"
              >
                <option aria-label="None" value="" />
                <option value="PERCENTAGE"> Percentage</option>
                <option value="FLAT"> Flat </option>
              </Select>
            </FormControl>
            {formik.touched.discount_type && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {formik.errors.discount_type}
              </span>
            )}
          </Grid>
        )}
        {coupontype == "ORDER" && (
          <Grid item xs={6}>
            <TextField
              name="discount_value"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.discount_value}
              fullWidth={true}
              label="Discount Value "
              variant="outlined"
              size="medium"
              type="number"
            />
            {formik.touched.discount_value && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {formik.errors.discount_value}
              </span>
            )}
          </Grid>
        )}
        {coupontype == "FACULTY" && (
          <div className="card w-100 p-3 ">
            <h5>Faculty</h5>
            {viewFaculties?.map((data, ind) => {
              return (
                <div className="d-flex justify-content-between my-2">
                  <Grid container spacing={3}>
                    <Grid item xs={5} className="">
                      {/* <FormControl style={{ margin: 0 }} fullWidth={true} variant="outlined" >
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Select Faculty</InputLabel>
                                                <Select native
                                                    value={data.faculty_id}
                                                    onChange={(e) =>
                                                        handleFacutlyChange(e, ind, "faculty_id")
                                                    }
                                                    label="Select Faculty">
                                                    <option aria-label="None" value="" />
                                                    {faculty && faculty.map((faculty) => (<option value={faculty._id}> {faculty.name}</option>))}
                                                </Select>
                                            </FormControl> */}
                      <Autocomplete
                        variant="outlined"
                        id={"name"}
                        onChange={(e, newValue) => {
                          handleFacutlyChange(newValue, ind, "faculty_id");
                        }}
                        value={data.faculty_id}
                        // filterSelectedOptions= {true}
                        options={faculty.filter((ele, ind) => {
                          return !viewFaculties.filter((e, i) => {
                            return (
                              ele.faculty_code == e.faculty_id?.faculty_code
                            );
                          }).length;
                        })}
                        //disableCloseOnSelect
                        getOptionLabel={(option) =>
                          option["name"] ? option["name"] : ""
                        }
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option["name"]}
                          </li>
                        )}
                        // style={{ width: 500 }}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            label="Select Faculty"
                            {...params}
                          />
                        )}
                      />
                      <span
                        className="text-danger px-2"
                        style={{ fontSize: "12px" }}
                      >
                        {data.id_error}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        style={{ margin: 0 }}
                        fullWidth={true}
                        variant="outlined"
                      >
                        <InputLabel htmlFor="outlined-Institue-Name-native-simple">
                          discount value type
                        </InputLabel>
                        <Select
                          value={data.discount_type}
                          onChange={(e) =>
                            handleFacutlyChange(e, ind, "discount_type")
                          }
                          native
                          label="Discount type"
                        >
                          <option aria-label="None" value="" />
                          <option value="PERCENTAGE"> Percentage</option>
                          <option value="FLAT"> Flat </option>
                        </Select>
                      </FormControl>
                      <span
                        className="text-danger px-2"
                        style={{ fontSize: "12px" }}
                      >
                        {data.type_error}
                      </span>
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        value={data.discount_value}
                        onChange={(e) =>
                          handleFacutlyChange(e, ind, "discount_value")
                        }
                        fullWidth={true}
                        label="Discount Value"
                        variant="outlined"
                        size="medium"
                      />
                      <span
                        className="text-danger px-2"
                        style={{ fontSize: "12px" }}
                      >
                        {data.value_error}
                      </span>
                    </Grid>
                    {viewFaculties.length > 1 ? (
                      <Grid item xs={1}>
                        <Button
                          onClick={(e) => deleteFaculty(data.faculty_id, e)}
                          size="medium"
                          type=""
                          style={{ marginLeft: 10 }}
                          variant="contained"
                          color="secondary"
                        >
                          DELETE
                        </Button>
                      </Grid>
                    ) : null}
                  </Grid>
                </div>
              );
            })}

            <Grid item xs={12} className="d-flex justify-content-end">
              <Button
                onClick={() => addNewFaculty()}
                className="mt-5"
                style={{ marginLeft: 20 }}
                variant="contained"
                color="primary"
              >
                ADD NEW DATA
              </Button>
            </Grid>
          </div>
        )}

        {coupontype == "PRODUCT" &&
          orderstate.includes("SLD_VIDEO_LECTURE") && (
            <>
              <div className="card w-100 p-3 mt-3">
                <h5>Video Lecture</h5>

                {viewVideoLecture?.map((data, ind) => {
                  return (
                    <div className="d-flex justify-content-between border rounded p-3 m-3 bg-light">
                      <Grid container spacing={1}>
                        <Grid item xs={6} className="">
                          <Autocomplete
                            variant="outlined"
                            id={"name"}
                            onChange={(e, newValue) => {
                              handleVideoLectureChange(
                                newValue,
                                ind,
                                "product_id"
                              );
                            }}
                            value={data.product_id}
                            options={videLectures?.data?.filter((ele, ind) => {
                              return !viewVideoLecture.filter((e, i) => {
                                return ele._id == e.product_id?._id;
                              }).length;
                            }) || []}
                            
                            getOptionLabel={(option) =>
                              option["video_lecture_name"]
                                ? option["video_lecture_name"]
                                : ""
                            }
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked ={selected}
                                />
                                {option["video_lecture_name"]}
                              </li>
                            )}
                            // style={{ width: 500 }}
                            renderInput={(params) => (
                              <TextField
                                multiline
                                minRows={2}
                                variant="outlined"
                                label="Select Video Lecture"
                                {...params}
                              />
                            )}
                          />
                          <span
                            className="text-danger px-2"
                            style={{ fontSize: "12px" }}
                          >
                            {data.id_error}
                          </span>
                        </Grid>

                        {/* vareint */}
                        <Grid item xs={6} className="">
                          <Autocomplete
                            variant="outlined"
                            id={"name2"}
                            onChange={(e, newValue) => {
                              handleVarientChange(
                                newValue,
                                ind,
                                "varient_code"
                              );
                            }}
                            // value={data.product_id}
                            // filterSelectedOptions= {true}
                            options={
                              data?.product_id?.varient
                                ? data?.product_id?.varient
                                : []
                            }
                            // options={videLectures?.filter((ele,ind)=>{ return !(viewVideoLecture.filter((e,i)=>{return ele._id == data.product_id ? e.product_id.variant: []}) )})}
                            //disableCloseOnSelect
                            getOptionLabel={(option) =>
                              option["mode_name"] ? option["mode_name"] : ""
                            }
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                <Grid container direction="column">
                                  <Grid item xs={12}>
                                    {option["mode_name"]}
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      fontSize: "12px",
                                      color: "blueviolet",
                                    }}
                                  >
                                    {option["varient_code"]} ( &#8377;{" "}
                                    {option["mode_mrp"]})
                                  </Grid>
                                </Grid>
                              </li>
                            )}
                            // style={{ width: 500 }}
                            renderInput={(params) => (
                              <TextField
                                multiline
                                minRows={2}
                                variant="outlined"
                                label="check discount on varient"
                                {...params}
                              />
                            )}
                          />
                        </Grid>

                        {/* faculty data */}
                        <Grid item xs={12} className="border rounded">
                          <p className="font-weight-bolder">
                            Faculty Discount -
                          </p>

                          <Grid
                            container
                            spacing={2}
                            style={{ marginBottom: "10px" }}
                          >
                            <Grid
                              item
                              xs={3}
                              container
                              justifyContent="center"
                              style={{ fontWeight: "600" }}
                            >
                              Faculty Name
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              container
                              justifyContent="center"
                              style={{ fontWeight: "600" }}
                            >
                              Discount
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              container
                              justifyContent="center"
                              style={{ fontWeight: "600" }}
                            >
                              Discount Type
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              container
                              justifyContent="center"
                              style={{ fontWeight: "600" }}
                            >
                              Value
                            </Grid>
                          </Grid>

                          {data.faculty.map((facultyEle, facultyInd) => {
                            return (
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={3}
                                  container
                                  justifyContent="center"
                                >
                                  <Typography>
                                    {facultyEle.faculty_name}
                                  </Typography>
                                </Grid>

                                <Grid
                                  item
                                  xs={3}
                                  container
                                  justifyContent="center"
                                >
                                  <TextField
                                    value={
                                      viewVideoLecture[ind].faculty_discounts[
                                        facultyInd
                                      ].faculty_discount
                                    }
                                    disabled={
                                      viewVideoLecture[ind].faculty_discounts[
                                        facultyInd
                                      ].faculty_discount == "0"
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      handleFacultyDiscountChange(
                                        e.target.value,
                                        ind,
                                        facultyInd,
                                        "faculty_discount"
                                      )
                                    }
                                    variant="outlined"
                                    size="small"
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xs={3}
                                  container
                                  justifyContent="center"
                                >
                                  <Select
                                    name="discount_type"
                                    onChange={(e) =>
                                      handleFacultyDiscountChange(
                                        e.target.value,
                                        ind,
                                        facultyInd,
                                        "discount_type"
                                      )
                                    }
                                    value={
                                      viewVideoLecture[ind].faculty_discounts[
                                        facultyInd
                                      ].discount_type
                                    }
                                    label="Discount type"
                                    fullWidth
                                  >
                                    <option aria-label="None" value="" />
                                    <option value="PERCENTAGE">
                                      Percentage
                                    </option>
                                    <option value="FLAT"> Flat </option>
                                  </Select>
                                </Grid>

                                <Grid
                                  item
                                  xs={3}
                                  container
                                  justifyContent="center"
                                >
                                  &#8377;
                                  {viewVideoLecture[ind].faculty_discounts[
                                    facultyInd
                                  ].discount_type === "PERCENTAGE"
                                    ? (facultyEle.price_on_which_commission_is_calculated *
                                        parseFloat(
                                          viewVideoLecture[ind]
                                            .faculty_discounts[facultyInd]
                                            .faculty_discount || 0
                                        )) /
                                      100
                                    : viewVideoLecture[ind].faculty_discounts[
                                        facultyInd
                                      ].faculty_discount}
                                </Grid>
                              </Grid>
                            );
                          })}

                          <Grid
                            container
                            spacing={2}
                            style={{ marginTop: "10px", paddingRight: "10px" }}
                          >
                            <Grid
                              item
                              xs={3}
                              container
                              justifyContent="center"
                              style={{ fontWeight: "600" }}
                            ></Grid>
                            <Grid
                              item
                              xs={3}
                              container
                              justifyContent="center"
                              style={{ fontWeight: "600" }}
                            ></Grid>
                            <Grid
                              item
                              xs={3}
                              container
                              justifyContent="center"
                              style={{ fontWeight: "600" }}
                            ></Grid>
                            <Grid
                              item
                              xs={3}
                              container
                              justifyContent="center"
                              style={{
                                fontWeight: "500",
                                borderTop: "1px solid rgb(220 220 220)",
                                paddingLeft: "17px",
                              }}
                            >
                              &#8377;{" "}
                              {data.faculty_discounts.reduce(
                                (acc, ele, currentInd) => {
                                  if (ele.discount_type === "PERCENTAGE") {
                                    return (
                                      acc +
                                        parseFloat(
                                          (data.faculty[currentInd]
                                            .price_on_which_commission_is_calculated *
                                            ele.faculty_discount) /
                                            100
                                        ) || 0
                                    );
                                  } else {
                                    return (
                                      acc +
                                      parseFloat(ele.faculty_discount || 0)
                                    );
                                  }
                                },
                                0
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <span
                          className="text-danger px-2"
                          style={{ fontSize: "12px" }}
                        >
                          {data.faculty_error}
                        </span>
                        <br />

                        {viewVideoLecture.length > 1 ? (
                          <Grid item xs={12}>
                            <Button
                              onClick={(e) =>
                                deleteVideoLecture(data.product_id, e)
                              }
                              size="medium"
                              type=""
                              //style={{ marginLeft: 10 }}
                              variant="contained"
                              color="secondary"
                            >
                              {" "}
                              DELETE
                            </Button>
                          </Grid>
                        ) : null}
                      </Grid>
                    </div>
                  );
                })}

                <Grid item xs={12} className="d-flex justify-content-end">
                  <Button
                    onClick={() => addNewVideoLecture()}
                    className="mt-5"
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    color="primary"
                  >
                    ADD NEW DATA
                  </Button>
                </Grid>
              </div>
            </>
          )}

        {coupontype == "PRODUCT" && orderstate.includes("SLD_BOOK_PRODUCT") && (
          <>
            <div className="card w-100 p-3 mt-3">
              <h5>Book Product</h5>

              {viewProduct?.map((data, ind) => {
                return (
                  <div className="d-flex justify-content-between border rounded p-3 m-3 bg-light">
                    <Grid container spacing={1}>
                      <Grid item xs={6} className="">
                        <Autocomplete
                          variant="outlined"
                          id={"name2"}
                          onChange={(e, newValue) => {
                            handleProductChange(newValue, ind, "product_id");
                          }}
                          value={data.product_id}
                          options={Product?.data?.filter((ele, ind) => {
                            return !viewProduct?.filter((e, i) => {
                              return ele._id == e.product_id?._id;
                            }).length;
                          })}
                          getOptionLabel={(option) =>
                            option["product_name"]
                              ? option["product_name"] +
                                "(" +
                                option["code"] +
                                ")"
                              : ""
                          }
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option["product_name"] +
                                "(" +
                                option["code"] +
                                ")"}
                            </li>
                          )}
                          // style={{ width: 500 }}
                          renderInput={(params) => (
                            <TextField
                              multiline
                              minRows={2}
                              variant="outlined"
                              label="Select book product"
                              {...params}
                            />
                          )}
                        />
                        <span
                          className="text-danger px-2"
                          style={{ fontSize: "12px" }}
                        >
                          {data.id_error}
                        </span>
                      </Grid>

                      {/* vareint */}
                      {/* <Grid item xs={6} className="">
                        <Autocomplete
                          variant="outlined"
                          id={"name2"}
                          onChange={(e, newValue) => {
                            handleVarientChange(newValue, ind, "varient_code");
                          }}
                          // value={data.product_id}
                          // filterSelectedOptions= {true}
                          options={
                            data?.product_id?.varient
                              ? data?.product_id?.varient
                              : []
                          }
                          // options={videLectures?.filter((ele,ind)=>{ return !(viewVideoLecture.filter((e,i)=>{return ele._id == data.product_id ? e.product_id.variant: []}) )})}
                          //disableCloseOnSelect
                          getOptionLabel={(option) =>
                            option["mode_name"] ? option["mode_name"] : ""
                          }
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              <Grid container direction="column">
                                <Grid item xs={12}>
                                  {option["mode_name"]}
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    fontSize: "12px",
                                    color: "blueviolet",
                                  }}
                                >
                                  {option["varient_code"]} ( &#8377;{" "}
                                  {option["mode_mrp"]})
                                </Grid>
                              </Grid>
                            </li>
                          )}
                          // style={{ width: 500 }}
                          renderInput={(params) => (
                            <TextField
                              multiline
                              minRows={2}
                              variant="outlined"
                              label="Select varient"
                              {...params}
                            />
                          )}
                        />
                      </Grid> */}

                      {/* faculty data */}
                      <Grid item xs={12} className="border rounded">
                        <p className="font-weight-bolder">
                          Faculty Discount -{" "}
                        </p>

                        <Grid
                          container
                          spacing={2}
                          style={{ marginBottom: "10px" }}
                        >
                          <Grid
                            item
                            xs={3}
                            container
                            justifyContent="center"
                            style={{ fontWeight: "600" }}
                          >
                            Faculty Name
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            container
                            justifyContent="center"
                            style={{ fontWeight: "600" }}
                          >
                            Discount
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            container
                            justifyContent="center"
                            style={{ fontWeight: "600" }}
                          >
                            Discount Type
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            container
                            justifyContent="center"
                            style={{ fontWeight: "600" }}
                          >
                            Value
                          </Grid>
                        </Grid>

                        {data.faculty.map((facultyEle, facultyInd) => {
                          return (
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={3}
                                container
                                justifyContent="center"
                              >
                                <Typography>
                                  {facultyEle.faculty_name}
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                xs={3}
                                container
                                justifyContent="center"
                              >
                                <TextField
                                  value={
                                    viewProduct[ind].faculty_discounts[
                                      facultyInd
                                    ].faculty_discount
                                  }
                                  disabled={
                                    viewProduct[ind].faculty_discounts[
                                      facultyInd
                                    ].faculty_discount == "0"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    handleFacultyDiscountChangeBookProduct(
                                      e.target.value,
                                      ind,
                                      facultyInd,
                                      "faculty_discount"
                                    )
                                  }
                                  variant="outlined"
                                  size="small"
                                />
                              </Grid>

                              <Grid
                                item
                                xs={3}
                                container
                                justifyContent="center"
                              >
                                <Select
                                  name="discount_type"
                                  onChange={(e) =>
                                    handleFacultyDiscountChangeBookProduct(
                                      e.target.value,
                                      ind,
                                      facultyInd,
                                      "discount_type"
                                    )
                                  }
                                  value={
                                    viewProduct[ind].faculty_discounts[
                                      facultyInd
                                    ].discount_type
                                  }
                                  label="Discount type"
                                  fullWidth
                                >
                                  <option aria-label="None" value="" />
                                  <option value="PERCENTAGE">
                                    {" "}
                                    Percentage
                                  </option>
                                  <option value="FLAT"> Flat </option>
                                </Select>
                              </Grid>

                              <Grid
                                item
                                xs={3}
                                container
                                justifyContent="center"
                              >
                                &#8377;
                                {viewProduct[ind].faculty_discounts[facultyInd]
                                  .discount_type === "PERCENTAGE"
                                  ? parseFloat(
                                      (facultyEle.price_on_which_commission_is_calculated *
                                        viewProduct[ind].faculty_discounts[
                                          facultyInd
                                        ].faculty_discount || 0) / 100
                                    ) || 0
                                  : viewProduct[ind].faculty_discounts[
                                      facultyInd
                                    ].faculty_discount}
                              </Grid>
                            </Grid>
                          );
                        })}

                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: "10px", paddingRight: "10px" }}
                        >
                          <Grid
                            item
                            xs={3}
                            container
                            justifyContent="center"
                            style={{ fontWeight: "600" }}
                          ></Grid>
                          <Grid
                            item
                            xs={3}
                            container
                            justifyContent="center"
                            style={{ fontWeight: "600" }}
                          ></Grid>
                          <Grid
                            item
                            xs={3}
                            container
                            justifyContent="center"
                            style={{ fontWeight: "600" }}
                          ></Grid>
                          <Grid
                            item
                            xs={3}
                            container
                            justifyContent="center"
                            style={{
                              fontWeight: "500",
                              borderTop: "1px solid rgb(220 220 220)",
                              paddingLeft: "17px",
                            }}
                          >
                            &#8377;{" "}
                            {data.faculty_discounts.reduce(
                              (acc, ele, currentInd) => {
                                if (ele.discount_type === "PERCENTAGE") {
                                  return (
                                    acc +
                                      parseFloat(
                                        (data.faculty[currentInd]
                                          .price_on_which_commission_is_calculated *
                                          ele.faculty_discount) /
                                          100
                                      ) || 0
                                  );
                                } else {
                                  return (
                                    acc + parseFloat(ele.faculty_discount || 0)
                                  );
                                }
                              },
                              0
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <span
                        className="text-danger px-2"
                        style={{ fontSize: "12px" }}
                      >
                        {data.faculty_error}
                      </span>
                      <br />

                      {viewProduct.length > 1 ? (
                        <Grid item xs={12}>
                          <Button
                            onClick={(e) => deleteProduct(data.product_id, e)}
                            size="medium"
                            type=""
                            //style={{ marginLeft: 10 }}
                            variant="contained"
                            color="secondary"
                          >
                            {" "}
                            DELETE
                          </Button>
                        </Grid>
                      ) : null}
                    </Grid>
                  </div>
                );
              })}

              <Grid item xs={12} className="d-flex justify-content-end">
                <Button
                  onClick={() => addNewProduct()}
                  className="mt-5"
                  style={{ marginLeft: 20 }}
                  variant="contained"
                  color="primary"
                >
                  ADD NEW DATA
                </Button>
              </Grid>
            </div>
          </>
        )}
        {coupontype == "PRODUCT" && orderstate.includes("SLD_TEST_SERIES") && (
          <>
            <div className="card w-100 p-3 mt-3">
              <h5>Test Series</h5>

              {viewTestSeries?.map((data, ind) => {
                return (
                  <div className="d-flex justify-content-between border rounded p-3 m-3 bg-light">
                    <Grid container spacing={1}>
                      <Grid item xs={6} className="">
                        <Autocomplete
                          variant="outlined"
                          id={"name3"}
                          onChange={(e, newValue) => {
                            handleProductChangeTestSeries(
                              newValue,
                              ind,
                              "product_id"
                            );
                          }}
                          value={data.product_id}
                          options={testSeries?.filter((ele, ind) => {
                            return !viewTestSeries?.filter((e, i) => {
                              return ele._id == e.product_id?._id;
                            }).length;
                          })}
                          getOptionLabel={(option) =>
                            option["product_name"]
                              ? option["product_name"] +
                                "(" +
                                option["code"] +
                                ")"
                              : ""
                          }
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option["product_name"] +
                                "(" +
                                option["code"] +
                                ")"}
                            </li>
                          )}
                          // style={{ width: 500 }}
                          renderInput={(params) => (
                            <TextField
                              multiline
                              minRows={2}
                              variant="outlined"
                              label="Select test series"
                              {...params}
                            />
                          )}
                        />
                        <span
                          className="text-danger px-2"
                          style={{ fontSize: "12px" }}
                        >
                          {data.id_error}
                        </span>
                      </Grid>

                      {/* vareint */}
                      {/* <Grid item xs={6} className="">
                        <Autocomplete
                          variant="outlined"
                          id={"name2"}
                          onChange={(e, newValue) => {
                            handleVarientChange(newValue, ind, "varient_code");
                          }}
                          // value={data.product_id}
                          // filterSelectedOptions= {true}
                          options={
                            data?.product_id?.varient
                              ? data?.product_id?.varient
                              : []
                          }
                          // options={videLectures?.filter((ele,ind)=>{ return !(viewTestSeries.filter((e,i)=>{return ele._id == data.product_id ? e.product_id.variant: []}) )})}
                          //disableCloseOnSelect
                          getOptionLabel={(option) =>
                            option["mode_name"] ? option["mode_name"] : ""
                          }
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              <Grid container direction="column">
                                <Grid item xs={12}>
                                  {option["mode_name"]}
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    fontSize: "12px",
                                    color: "blueviolet",
                                  }}
                                >
                                  {option["varient_code"]} ( &#8377;{" "}
                                  {option["mode_mrp"]})
                                </Grid>
                              </Grid>
                            </li>
                          )}
                          // style={{ width: 500 }}
                          renderInput={(params) => (
                            <TextField
                              multiline
                              minRows={2}
                              variant="outlined"
                              label="Select varient"
                              {...params}
                            />
                          )}
                        />
                      </Grid> */}

                      {/* faculty data */}
                      <Grid item xs={12} className="border rounded">
                        <p className="font-weight-bolder">
                          Faculty Discount -{" "}
                        </p>

                        <Grid
                          container
                          spacing={2}
                          style={{ marginBottom: "10px" }}
                        >
                          <Grid
                            item
                            xs={3}
                            container
                            justifyContent="center"
                            style={{ fontWeight: "600" }}
                          >
                            Faculty Name
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            container
                            justifyContent="center"
                            style={{ fontWeight: "600" }}
                          >
                            Discount
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            container
                            justifyContent="center"
                            style={{ fontWeight: "600" }}
                          >
                            Discount Type
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            container
                            justifyContent="center"
                            style={{ fontWeight: "600" }}
                          >
                            Value
                          </Grid>
                        </Grid>

                        {data.faculty.map((facultyEle, facultyInd) => {
                          return (
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={3}
                                container
                                justifyContent="center"
                              >
                                <Typography>
                                  {facultyEle.faculty_name}
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                xs={3}
                                container
                                justifyContent="center"
                              >
                                <TextField
                                  value={
                                    viewTestSeries[ind].faculty_discounts[
                                      facultyInd
                                    ].faculty_discount
                                  }
                                  disabled={
                                    viewTestSeries[ind].faculty_discounts[
                                      facultyInd
                                    ].faculty_discount == "0"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    handleFacultyDiscountChangeTestSeries(
                                      e.target.value,
                                      ind,
                                      facultyInd,
                                      "faculty_discount"
                                    )
                                  }
                                  variant="outlined"
                                  size="small"
                                />
                              </Grid>

                              <Grid
                                item
                                xs={3}
                                container
                                justifyContent="center"
                              >
                                <Select
                                  name="discount_type"
                                  onChange={(e) =>
                                    handleFacultyDiscountChangeTestSeries(
                                      e.target.value,
                                      ind,
                                      facultyInd,
                                      "discount_type"
                                    )
                                  }
                                  value={
                                    viewTestSeries[ind].faculty_discounts[
                                      facultyInd
                                    ].discount_type
                                  }
                                  label="Discount type"
                                  fullWidth
                                >
                                  <option aria-label="None" value="" />
                                  <option value="PERCENTAGE">
                                    {" "}
                                    Percentage
                                  </option>
                                  <option value="FLAT"> Flat </option>
                                </Select>
                              </Grid>

                              <Grid
                                item
                                xs={3}
                                container
                                justifyContent="center"
                              >
                                &#8377;
                                {viewTestSeries[ind].faculty_discounts[
                                  facultyInd
                                ].discount_type === "PERCENTAGE"
                                  ? parseFloat(
                                      (facultyEle.price_on_which_commission_is_calculated *
                                        viewTestSeries[ind].faculty_discounts[
                                          facultyInd
                                        ].faculty_discount || 0) / 100
                                    ) || 0
                                  : viewTestSeries[ind].faculty_discounts[
                                      facultyInd
                                    ].faculty_discount}
                              </Grid>
                            </Grid>
                          );
                        })}

                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: "10px", paddingRight: "10px" }}
                        >
                          <Grid
                            item
                            xs={3}
                            container
                            justifyContent="center"
                            style={{ fontWeight: "600" }}
                          ></Grid>
                          <Grid
                            item
                            xs={3}
                            container
                            justifyContent="center"
                            style={{ fontWeight: "600" }}
                          ></Grid>
                          <Grid
                            item
                            xs={3}
                            container
                            justifyContent="center"
                            style={{ fontWeight: "600" }}
                          ></Grid>
                          <Grid
                            item
                            xs={3}
                            container
                            justifyContent="center"
                            style={{
                              fontWeight: "500",
                              borderTop: "1px solid rgb(220 220 220)",
                              paddingLeft: "17px",
                            }}
                          >
                            &#8377;{" "}
                            {data.faculty_discounts.reduce(
                              (acc, ele, currentInd) => {
                                if (ele.discount_type === "PERCENTAGE") {
                                  return (
                                    acc +
                                      parseFloat(
                                        (data.faculty[currentInd]
                                          .price_on_which_commission_is_calculated *
                                          ele.faculty_discount) /
                                          100
                                      ) || 0
                                  );
                                } else {
                                  return (
                                    acc + parseFloat(ele.faculty_discount || 0)
                                  );
                                }
                              },
                              0
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <span
                        className="text-danger px-2"
                        style={{ fontSize: "12px" }}
                      >
                        {data.faculty_error}
                      </span>
                      <br />

                      {viewTestSeries.length > 1 ? (
                        <Grid item xs={12}>
                          <Button
                            onClick={(e) =>
                              deleteProductTestSeries(data.product_id, e)
                            }
                            size="medium"
                            type=""
                            //style={{ marginLeft: 10 }}
                            variant="contained"
                            color="secondary"
                          >
                            {" "}
                            DELETE
                          </Button>
                        </Grid>
                      ) : null}
                    </Grid>
                  </div>
                );
              })}

              <Grid item xs={12} className="d-flex justify-content-end">
                <Button
                  onClick={() => addNewTestSeries()}
                  className="mt-5"
                  style={{ marginLeft: 20 }}
                  variant="contained"
                  color="primary"
                >
                  ADD NEW DATA
                </Button>
              </Grid>
            </div>
          </>
        )}

        <Grid item xs={12} className="mt-5">
          <div className="d-flex justify-content-end">
            <Button onClick={dialogclose} variant="contained" color="secondary">
              Close
            </Button>
            <Button
              type="submit"
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
            >
              {" "}
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
