import Dashboard from "./view/screens/dashboard/Dashboard";
import Login from "./view/auth/Login";
import Orders from "./view/screens/dashboard/Orders";
// import proccesingOrder
import VideoLectures from "./view/screens/dashboard/VideoLectures";
import Analytics from "./view/screens/dashboard/Analytics";
import BooksAndProducts from "./view/screens/dashboard/BooksAndProducts";
import Employees from "./view/screens/dashboard/Employees";
import CourseandSubjects from "./view/screens/dashboard/CourseandSubjects";
import Cms from "./view/screens/dashboard/Cms";
import FacultyandInstitute from "./view/screens/dashboard/faculty/FacultyandInstitute";
import Finance from "./view/screens/dashboard/Finance";
import Reviews from "./view/screens/dashboard/Reviews";
import Quotes from "./view/screens/dashboard/Quotes";
import UsersandFranchise from "./view/screens/dashboard/UsersandFranchise";
import SldUsersAndGroups from "./view/screens/dashboard/sldUsersAndGroups/SldUsersAndGroups";
import Inquiries from "./view/screens/dashboard/Inquiries";
import VirtualClass from './view/screens/dashboard/Virtualclass'
import Sms from "./view/screens/dashboard/Sms";
import Events from "./view/screens/dashboard/Events";
import Languages from "./view/screens/dashboard/Languages";
import CouponsAndScratchCards from "./view/screens/dashboard/CouponsAndScratchCards";
import CustomEmail from "./view/screens/dashboard/CustomEmail";
import Modes from "./view/screens/dashboard/Modes"
//import CallRequests from "./view/screens/dashboard/CallRequests";
import Categories from "./view/screens/dashboard/Categories";
//import CourierDetails from "./view/screens/dashboard/CourierDetails";
import HTMLeditor from "./view/screens/dashboard/HTMLeditor";
import Downloads from "./view/screens/dashboard/Downloads";
import BillingModule from "./view/screens/dashboard/BillingModule";
import UserPage from "./view/screens/dashboard/UserPage";
import SignIn from "./view/auth/SignIn";
import SignUp from "./view/auth/SignUp";
//import Transaction from "./view/screens/dashboard/Transaction";
import Configuration from "./view/screens/dashboard/Configuration";
import WelcomePage from "./view/screens/dashboard/WelcomePage";
import RouteNotFound from "./view/screens/dashboard/RouteNotFound";
import Expenses from "./view/screens/dashboard/Expenses/Expenses";
import Income from "./view/screens/dashboard/Incomes/Income";
import ExcelLogs from "./view/screens/dashboard/ExcelLogs/ExcelLogs";
import AllTickets from "./view/screens/dashboard/tickets/AllTickets"
import StudentRequests from "./view/screens/dashboard/studentRequests/StudentRequests";
import CallerPerformance from "./view/screens/dashboard/CallerPerformance";
import MobileNoLibrary from "./view/screens/dashboard/MobileNoLibrary";
import OfflineOrders from "./view/screens/dashboard/offlineOrders/OfflineOrders";
import AllWalletTransactions from "./view/screens/dashboard/allWalletTransactions/AllWalletTransactions";
import FileExplorer from "./view/screens/dashboard/fileExplorer/FileExplorer";
import TimeSheet from "./view/screens/dashboard/timeSheet/TimeSheet";
import Hall from "./view/screens/dashboard/offlineOrders/Hall";
import OfflineDashboard from "./view/screens/dashboard/offlineOrders/OfflineDashbord";
import AllVirtualOrders from "./view/screens/dashboard/offlineOrders/AllVirtualOrders";
import AllSemiVirtualOrders from "./view/screens/dashboard/offlineOrders/AllSemiVirtualOrders";
import AllPendriveOrders from "./view/screens/dashboard/offlineOrders/AllPendriveOrders";
import BatchTiming from "./view/screens/dashboard/offlineOrders/BatchTiming";
import Attempts from "./view/screens/dashboard/offlineOrders/Attempts";
import Batch from "./view/screens/dashboard/offlineOrders/Batch";
import Currency from "./view/screens/dashboard/offlineOrders/Currency";
import PaymentMode from "./view/screens/dashboard/offlineOrders/PaymentMode";
import TermsAndConditions from "./view/screens/dashboard/offlineOrders/TermsAndConditions";
import AllCenters from "./view/screens/dashboard/offlineOrders/AllCenters";
import WithdrawalDepositExchange from "./view/screens/dashboard/offlineOrders/WithdrawalDepositExchange";
import AllOfflineTransactions from "./view/screens/dashboard/offlineOrders/AllOfflineTransactions";
import Account from "./view/screens/dashboard/offlineOrders/Account";
import OfflineOrderInvoice from "./view/screens/dashboard/offlineOrders/OfflineOrderInvoice";
import ProcessingOrders from "./view/screens/dashboard/ProccesingOrders";
import FailedOrders from "./view/screens/dashboard/FailedOrders";
import CancelOrders from "./view/screens/dashboard/CancelOrders";
import ProductCartListing from "./view/screens/dashboard/ProductCart/list/ProductCartListing";
import ChangePassword from "./view/screens/dashboard/profile/ChangePassword"
import commanFunction from "./view/screens/dashboard/VideoLecture/commanFunction"
import ReviewAndQuestion from "./view/screens/dashboard/ReviewAndQuestion";
import UnAuthorized from "./view/screens/dashboard/UnauthorizedPage";
// import AllTickets from "./view/screens/dashboard/tickets/AllTickets";
const routes = [

    { path: "/dashboard", name: "Dashboard", component: Dashboard },
    { path: "/not-authenticated", name: "Unauthorized", component: UnAuthorized },
    { path: "/welcome", name: "Welcome-page", component: WelcomePage },
    { path: "/404", name: "route-not-found", component: RouteNotFound },
    { path: "/login", name: "Login", component: Login },
    { path: "/book-and-product", name: "book-and-product", component: BooksAndProducts },
    { path: "/cms", name: "cms", component: Cms },
    { path: "/course-subject", name: "course-subject", component: CourseandSubjects },
    { path: "/employees", name: "employees", component: Employees },
    { path: "/faculty-and-institute", name: "faculty-and-institute", component: FacultyandInstitute },
    { path: "/finance", name: "finance", component: Finance },
    { path: "/orders", name: "Success Full Orders", component: Orders },
    { path: "/pending-orders", name: "Proccessing Orders", component: ProcessingOrders },
    { path: "/failed-orders", name: "Payment Pending Orders", component: FailedOrders },
    { path: "/cancelorders", name: "Cancel Orders", component: CancelOrders },
    { path: "/product-cart", name: "product-cart", component: ProductCartListing },
    { path: "/price-test", name: "price-test", component: commanFunction },

    //{ path: "/courier", name: "Courier", component: CourierDetails },
    { path: "/reviews", name: "reviews", component: ReviewAndQuestion },
    { path: "/quotes", name: "quotes", component: Quotes },
    { path: "/users-and-franchise", name: "users-and-franchise", component: UsersandFranchise },
    { path: "/sld-users-and-groups", name: "sld-users-and-groups", component: SldUsersAndGroups },
    { path: "/video-lectures", name: "video-lectures", component: VideoLectures },
    { path: "/inquiries", name: "inquiries", component: Inquiries },
    { path: "/virtual-class", name: "virtual-classs", component: VirtualClass },
    { path: "/sms", name: "sms", component: Sms },
    { path: "/events", name: "events", component: Events },
    { path: "/languages", name: "languages", component: Languages },
    { path: "/coupons-scatch-cards", name: "coupons-scatch-cards", component: CouponsAndScratchCards },
    { path: "/file-explorer", name: "file-explorer", component: FileExplorer },
    { path: "/time-sheet", name: "time-sheet", component: TimeSheet },
    { path: "/customemail", name: "customemail", component: CustomEmail },
    { path: "/modes", name: "modes", component: Modes },
    // { path: "/callrequests", name: "callrequests", component: CallRequests },
    // { path: "/categories", name: "categories", component: Categories },
    { path: "/htmleditor", name: "htmleditor", component: HTMLeditor },
    { path: "/downloads", name: "downloads", component: Downloads },
    { path: "/billing", name: "billing", component: BillingModule },
    { path: "/UserPage", name: "UserPage", component: UserPage },
    // { path: "/SignIn", name: "SignIn", component: SignIn },
    { path: "/SignUp", name: "SignUp", component: SignUp },
    { path: "/configuration", name: "configuration", component: Configuration },
    //{ path: "/transaction", name: "transaction", component: Transaction },
    { path: "/all-wallet-transactions", name: "All Wallet Transactions", component: AllWalletTransactions },
    { path: "/expenses", name: "expenses", component: Expenses },
    { path: "/incomes", name: "incomes", component: Income },
    { path: "/excellogs", name: "excellogs", component: ExcelLogs },
    { path: "/tickets", name: "tickets", component: AllTickets },
    { path: "/studentrequests", name: "studentrequests", component: StudentRequests },
    { path: "/callerperformance", name: "callerperformance", component: CallerPerformance },
    { path: "/mobilenolibrary", name: "mobilenolibrary", component: MobileNoLibrary },
    { path: "/offlineOrders", name: "offlineOrders", component: OfflineOrders },
    { path: "/offlineDashboard", name: "offlineDashboard", component: OfflineDashboard },
    { path: "/virtualOrders", name: "virtualOrders", component: AllVirtualOrders },
    { path: "/semiVirtualOrders", name: "semiVirtualOrders", component: AllSemiVirtualOrders },
    { path: "/pendriveOrders", name: "pendriveOrders", component: AllPendriveOrders },
    { path: "/batchTiming", name: "batchTiming", component: BatchTiming },
    { path: "/halls", name: "halls", component: Hall },
    { path: "/attempts", name: "attempts", component: Attempts },
    { path: "/batches", name: "batches", component: Batch },
    { path: "/currency", name: "currency", component: Currency },
    { path: "/otherPaymentModes", name: "otherPaymentModes", component: PaymentMode },
    { path: "/terms", name: "terms", component: TermsAndConditions },
    { path: "/centers", name: "centers", component: AllCenters },
    { path: "/withdrawalAndDeposit", name: "withdrawalAndDeposit", component: WithdrawalDepositExchange },
    { path: "/allTransactions", name: "allTransactions", component: AllOfflineTransactions },
    { path: "/account", name: "account", component: Account },
    // { path: "/withdrawalAll", name: "withdrawalAll", component: TermsAndConditions },s
    // { path: "/dataDownload", name: "dataDownload", component: TermsAndConditions },
    { path: "/offlineOrderInvoice", name: "offlineOrderInvoice", component: OfflineOrderInvoice },
    // { path: "/tickets", name: "Tickets", component: AllTickets },
    { path: "/changePassword", name: "changePassword", component: ChangePassword }

];

export default routes;