import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import { useGetAllCurrenciesQuery } from "../../../../services/offlineModuleServices/currencyServices";
import { useGetAllPaymentModesQuery } from "../../../../services/offlineModuleServices/paymentModeServices";
import MoneyIcon from "@material-ui/icons/Money";
import moment from 'moment';
import { useGetAllCenterQuery } from "../../../../services/offlineModuleServices/centersServices";
import { useAddCashMutation, useGetCashDataForValidationQuery, useGetOnlineAmountDataForValidationQuery } from '../../../../services/offlineModuleServices/cashCollectionServices';
import * as yup from "yup";
import {
    Grid,
    TextField,
    Button,
    Typography,
    DialogActions,
    Select,
    MenuItem,
    InputLabel,
    Dialog,
    DialogContent,
    Slide,
    DialogTitle,
    Divider,
    FormControl,
} from "@material-ui/core";
import LoadingButton from '@mui/lab/LoadingButton';
import SkeletonListing from '../SkeletonListing';
import ExchangeNoteForm from './ExchangeNoteForm';
import Toster from '../Toster';
import { useGetAllIncomeCategoryQuery } from '../../../../services/incomeServices';
import { useGetAllExpenseCategoryQuery } from '../../../../services/services';
import {
    useGetAllSldUserMutation,
    useGetAallFacultieNamesQuery,
    useGetAllUsersQuery,
} from "../../../../services/services";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function WithdrawalDepositExchange() {
    const [defaultCenter, setDefaultCenter] = useState("");
    const [render, setRender] = useState(false);
    const { data: AllCurrencyNotes, isFetching: isAllCurrencyNotesFetching, isLoading: isAllCurrencyNotesLoading } = useGetAllCurrenciesQuery()
    const { data: AllPaymentModes, isFetching: isAllPaymentModesFetching, isLoading: isAllPaymentModesLoading } = useGetAllPaymentModesQuery()
    const { data: AllCenters, isFetching: isAllCenterFetching, isLoading: isAllCenterLoading } = useGetAllCenterQuery()
    const { data: getAllIncomeCategories, isFetching: isAllIncomeCategoriesFetching, isLoading: isAllIncomeCategoriesLoading } = useGetAllIncomeCategoryQuery();
    const { data: getAllExpenseCategories, isFetching: isAllExpenseCategoriesFetching, isLoading: isAllExpenseCategoriesLoading } = useGetAllExpenseCategoryQuery();

    const allIncomeCategories = getAllIncomeCategories?.data;
    const allExpenseCategories = getAllExpenseCategories?.data;

    const [getAllSldUser, getAllSldUserInfo] = useGetAllSldUserMutation();
    const getAllFaculties = useGetAallFacultieNamesQuery();
    const getAllWebUsers = useGetAllUsersQuery({
        limit: "",
        query: "",
        params: [
            "first_name", "last_name", "type", "mobile", "email"
        ],
        page: "",
        filterBy: {
            fieldName: "type",
            value: "STUDENT"

        }
    });
    const getAllWebFranchise = useGetAllUsersQuery({
        limit: "",
        query: "",
        params: [
            "first_name", "last_name", "type", "mobile", "email"
        ],
        page: "",
        filterBy: {
            fieldName: "type",
            value: "FRANCHISE"

        }
    });

    useEffect(() => {
        AllCenters?.data.forEach(ele => {
            if (ele.defaultOnInvoice) {
                setDefaultCenter(ele._id)
            }
        })
    }, [isAllCenterFetching, isAllCenterLoading])



    const [addCash, addCashInfo] = useAddCashMutation()
    const { data: AvailableCashData, isFetching: isAvailableCashDataFetching, isLoading: isAvailableCashDataLoading } = useGetCashDataForValidationQuery("627ca762cd46b5abc8ef92b5")
    const { data: AvailableOnlineAmountData, isFetching: isAvailableOnlineAmountDataFetching, isLoading: isAvailableOnlineAmountDataLoading } = useGetOnlineAmountDataForValidationQuery("627ca762cd46b5abc8ef92b5")
    const [initialCashData, setInititalCashData] = useState([])
    const [initialOnlinePaymentData, setInititalOnlinePaymentData] = useState([])
    const [viewExchangeForm, setViewExchangeForm] = useState(false)
    const [loading, setLoading] = useState(false);


    useEffect(() => {

        setInititalCashData(AllCurrencyNotes?.data?.map((ele) => {
            return { currency_note_name: ele.currency_note, currency_note_value: ele.value, payment_mode: "CASH", quantity: 0 }
        }))
    }, [isAllCurrencyNotesFetching])


    useEffect(() => {

        setInititalOnlinePaymentData(AllPaymentModes?.data?.map((ele) => {
            return { currency_note_name: ele.UPI_ID_ACCOUNT_NO, currency_note_value: 0, payment_mode: "ONLINE", quantity: 0 }
        }))
    }, [isAllPaymentModesFetching])

    useEffect(() => {
        getAllSldUser({
            limit: "",
            query: "",
            params: ["name"],
            page: "",
        });
    }, []);

    let initialValues = {
        amount: "",
        cash_data: initialCashData,
        online_payment_data: initialOnlinePaymentData,

        balance_amount: "",
        transaction_type: "",
        payment_mode: [],
        added_on_date: "",
        remark: "",
        // order_id: "",
        deposite_amount: "",
        withdraw_amount: "",
        exchange_amount: "",
        center_id: "",
        deposite_cash_data: [],
        withdraw_cash_data: [],
        expenseRegisterData: {
            title: "",
            note: "",
            expensed_on_type: "",
            expensed_on_id: "",
            expensed_on_name: "",
            category_id: ""
        },

        incomeRegisterData: {
            title: "",
            note: "",
            income_from_type: "",
            income_from_id: "",
            income_from_name: "",
            category_id: ""
        }

    }

    const validationSchema = yup.object({
        amount: yup.number().required("This field is required"),
        transaction_type: yup.string().required("This field is required")
    })

    let formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            setLoading(true)
            let mode = []
            values.cash_data?.map((ele) => ele.quantity).some((ele) => ele > 0) && mode.push("CASH")
            values.online_payment_data?.map((ele) => ele.currency_note_value).some((ele) => ele > 0) && mode.push("ONLINE")

            let defaultCenter;
            AllCenters?.data?.map((ele) => {
                if (ele.defaultOnInvoice) {
                    defaultCenter = ele._id
                }
            })

            values.cash_data = values.cash_data.filter((ele) => {
                return ele.quantity != undefined && ele.quantity != 0
            })

            values.online_payment_data = values.online_payment_data.filter((ele) => {
                return ele.currency_note_value != undefined && ele.currency_note_value != 0
            })


            values = {
                ...values,
                balance_amount: values.amount,
                payment_mode: mode,
                added_on_date: moment(new Date()).format("YYYY-MM-DD"),
                deposite_amount: values.transaction_type == "DEPOSITE" ? values.amount : "",
                withdraw_amount: values.transaction_type == "WITHDRAW" ? values.amount : "",
                center_id: defaultCenter,
                deposite_cash_data: values.transaction_type == "DEPOSITE" ? [...values.cash_data, ...values.online_payment_data] : [],
                withdraw_cash_data: values.transaction_type == "WITHDRAW" ? [...values.cash_data, ...values.online_payment_data] : [],
                incomeRegisterData:
                {
                    ...values.incomeRegisterData,
                    income_from_id: values.incomeRegisterData.income_from_name._id,
                    income_from_name: values.incomeRegisterData.income_from_name.name,
                    note: values.remark
                },
                expenseRegisterData: {
                    ...values.expenseRegisterData,
                    expensed_on_id: values.expenseRegisterData.expensed_on_name._id,
                    expensed_on_name: values.expenseRegisterData.expensed_on_name.name,
                    note: values.remark
                }

            }
            let formValue;
            for (let key in values) {
                if (key != "amount" && key != "cash_data" && key != "online_payment_data") {
                    formValue = { ...formValue, [key]: values[key] }
                }
            }

            addCash(formValue)
            if (addCashInfo && !addCashInfo?.isLoading) {
                setLoading(false)
            }
            if (addCashInfo && addCashInfo?.data?.status) {
                resetForm()
            }
            
            setRender(!render)

        }
    })

    const TotalAmount = formik.values.cash_data?.map((ele) => ele.quantity ? (ele.currency_note_value * ele.quantity) : 0)?.reduce((acc, currentValue) => acc + currentValue, 0) + formik.values.online_payment_data?.map((ele) => ele.currency_note_value ? ele.currency_note_value : 0)?.reduce((acc, currentValue) => acc + currentValue, 0)
    const quantityAvailable = (currencyName) => {
        let availableQty = 0
        AvailableCashData?.data.forEach(ele => {
            if (ele.currency_note_name == currencyName) {
                availableQty = ele.available_note_count
            }
        });

        return availableQty
    }


    const amountAvailableInOnlineModes = (currencyName) => {
        let availableAmount = 0
        AvailableOnlineAmountData?.data.forEach(ele => {
            if (ele.currency_note_name == currencyName) {
                availableAmount = ele.available_amount
            }
        })
        return availableAmount
    }

    const allSldUser = getAllSldUserInfo?.data;
    const allFaculties = getAllFaculties?.data;
    const allWebUsers = getAllWebUsers?.data;
    const allWebFranchise = getAllWebFranchise?.data; 

    const dropDownsData = (type) => {
        switch (type) {
            case "SLD_USER":
                return (
                    allSldUser &&
                    allSldUser?.data?.map((ele) => {
                        return (
                            <MenuItem value={{ _id: ele._id, name: ele.name }}> {ele.name}</MenuItem>
                        );
                    })
                );

            case "FACULTY":
                return (
                    allFaculties &&
                    allFaculties?.data?.map((ele) => {
                        return (
                            <MenuItem value={{ _id: ele._id, name: ele.faculty_name }} key={ele._id} >
                                {" "}
                                {ele.faculty_name}
                            </MenuItem>
                        );
                    })
                );

            case "WEB_USER":
                return (
                    allWebUsers &&
                    allWebUsers?.data?.map((ele) => {
                        return (
                            <MenuItem value={{ _id: ele._id, name: ele.first_name + ele.last_name }} key={ele._id} >
                                {" "}
                                {`${ele.first_name} ${ele.last_name}`}
                            </MenuItem>
                        );
                    })
                );

            case "WEB_FRANCHISE":
                return (
                    allWebFranchise &&
                    allWebFranchise?.data?.map((ele) => {
                        return (
                            <MenuItem value={{ _id: ele._id, name: ele.first_name + ele.last_name }} key={ele._id} >
                                {" "}
                                {`${ele.first_name} ${ele.last_name}`}
                            </MenuItem >
                        );
                    })
                );

            default:
                break;
        }
    };


    return (
        <div>
            {
                isAllCurrencyNotesFetching ||
                    isAllCurrencyNotesLoading ||
                    isAllPaymentModesFetching ||
                    isAllPaymentModesLoading ||
                    isAvailableCashDataFetching ||
                    isAvailableCashDataLoading ||
                    isAllIncomeCategoriesFetching ||
                    defaultCenter === null ||
                    isAllIncomeCategoriesLoading ?
                    <SkeletonListing />
                    :

                    (
                        <form onSubmit={formik.handleSubmit}>
                            <>
                                <Grid container justifyContent='center' >
                                    <Grid container item md={9} xs={11} className="mt-5">

                                        <Grid container spacing={5}  >

                                            <Grid item md={3} xs={12}>
                                                <InputLabel id="type" className='mb-3'> Type</InputLabel>
                                                <FormControl fullWidth>

                                                    <Select
                                                        fullWidth
                                                        name='transaction_type'
                                                        labelId='type'
                                                        placeholder='Type'
                                                        onChange={formik.handleChange}
                                                        value={formik.values.transaction_type}


                                                    >
                                                        <MenuItem value="DEPOSITE" > Deposit </MenuItem>
                                                        <MenuItem value="WITHDRAW" > Withdrawal </MenuItem>
                                                        <MenuItem value="EXCHANGE" onClick={() => { setViewExchangeForm(true) }} > Exchange </MenuItem>

                                                    </Select>
                                                </FormControl>
                                            </Grid>


                                            {
                                                formik.values.transaction_type == "DEPOSITE" &&
                                                <Grid item md={3} xs={12}>
                                                    <InputLabel htmlFor='category_id' className='mb-3'> Category</InputLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            fullWidth
                                                            name="incomeRegisterData.category_id"
                                                            id="category_id"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.incomeRegisterData.category_id}
                                                        >
                                                            {allIncomeCategories &&
                                                                allIncomeCategories?.map((ele) => {
                                                                    return (
                                                                        <MenuItem value={ele._id}>{ele.category_name}</MenuItem>
                                                                    );
                                                                })}

                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            }

                                            {
                                                formik.values.transaction_type == "WITHDRAW" &&
                                                <Grid item md={3} xs={12}>
                                                    <InputLabel htmlFor='category_id' className='mb-3'> Category</InputLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            fullWidth
                                                            name="expenseRegisterData.category_id"
                                                            id="category_id"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.expenseRegisterData.category_id}
                                                        >
                                                            {allExpenseCategories &&
                                                                allExpenseCategories?.map((ele) => {
                                                                    return (
                                                                        <MenuItem value={ele._id}>{ele.category_name}</MenuItem>
                                                                    );
                                                                })}

                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            }

                                            {
                                                formik.values.transaction_type == "DEPOSITE" &&
                                                <Grid item md={3} xs={12}>
                                                    <InputLabel htmlFor='income_from_type' className='mb-3'> Role </InputLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            name="incomeRegisterData.income_from_type"
                                                            id="income_from_type"
                                                            value={formik.values.incomeRegisterData.income_from_type}
                                                            onChange={formik.handleChange}
                                                        >
                                                            <MenuItem value={"SLD_USER"}>SLD User</MenuItem>
                                                            <MenuItem value={"FACULTY"}>Faculty</MenuItem>
                                                            <MenuItem value={"WEB_USER"}>Web User</MenuItem>
                                                            <MenuItem value={"WEB_FRANCHISE"}>Web Franchise</MenuItem>
                                                            <MenuItem value={"OTHERS"}>Others </MenuItem>
                                                            <MenuItem value={"ADMIN"}>Admin</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            }

                                            {
                                                formik.values.transaction_type == "DEPOSITE" && formik.values.incomeRegisterData.income_from_type && formik.values.incomeRegisterData.income_from_type != "ADMIN" &&
                                                <Grid item md={3} xs={12}>
                                                    <InputLabel htmlFor='income_from_name' className='mb-3'> Income From </InputLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            name="incomeRegisterData.income_from_name"
                                                            id="income_from_name"
                                                            value={formik.values.incomeRegisterData.income_from_name.name}
                                                            renderValue={(value) => value.name}
                                                            onChange={formik.handleChange}
                                                        >
                                                            {dropDownsData(formik.values.incomeRegisterData.income_from_type)}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            }
                                            {/* If Type = Withdraw then Role Field */}
                                            {
                                                formik.values.transaction_type == "WITHDRAW" &&
                                                <Grid item md={3} xs={12}>
                                                    <InputLabel htmlFor='expensed_on_type' className='mb-3'> Role </InputLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            name="expenseRegisterData.expensed_on_type"
                                                            id="expensed_on_type"
                                                            value={formik.values.expenseRegisterData.expensed_on_type}
                                                            onChange={formik.handleChange}

                                                        >
                                                            <MenuItem value={"SLD_USER"}>SLD User</MenuItem>
                                                            <MenuItem value={"FACULTY"}>Faculty</MenuItem>
                                                            <MenuItem value={"WEB_USER"}>Web User</MenuItem>
                                                            <MenuItem value={"WEB_FRANCHISE"}>Web Franchise</MenuItem>
                                                            <MenuItem value={"OTHERS"}>Others </MenuItem>
                                                            <MenuItem value={"ADMIN"}>Admin</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            }

                                            {
                                                formik.values.transaction_type == "WITHDRAW" && formik.values.expenseRegisterData.expensed_on_type &&
                                                <Grid item md={3} xs={12} >
                                                    <InputLabel htmlFor='expensed_on_name' className='mb-3'> Expensed on </InputLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            name="expenseRegisterData.expensed_on_name"
                                                            id="expensed_on_name"
                                                            value={formik.values.expenseRegisterData.expensed_on_name.name}
                                                            renderValue={(value) => value.name}
                                                            onChange={formik.handleChange}
                                                        >
                                                            {dropDownsData(formik.values.expenseRegisterData.expensed_on_type)}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            }

                                            {
                                                formik.values.transaction_type == "DEPOSITE" &&
                                                <Grid item md={3} xs={12}>
                                                    <InputLabel id="title" className='mb-3'> Title </InputLabel>
                                                    <TextField
                                                        name='incomeRegisterData.title'
                                                        fullWidth
                                                        variant='standard'
                                                        size='small'
                                                        placeholder='Title'
                                                        onChange={formik.handleChange}
                                                        value={formik.values.incomeRegisterData.title}
                                                    />
                                                </Grid>
                                            }

                                            {
                                                formik.values.transaction_type == "WITHDRAW" &&
                                                <Grid item md={3} xs={12} >
                                                    <InputLabel id="title" className='mb-3'> Title </InputLabel>
                                                    <TextField
                                                        name='expenseRegisterData.title'
                                                        fullWidth
                                                        variant='standard'
                                                        size='small'
                                                        placeholder='Title'
                                                        onChange={formik.handleChange}
                                                        value={formik.values.expenseRegisterData.title}
                                                    />
                                                </Grid>
                                            }

                                            <Grid item md={3} xs={12}>
                                                <InputLabel id="amount" className='mb-3'> Amount </InputLabel>
                                                <TextField
                                                    type="number"
                                                    name='amount'
                                                    fullWidth
                                                    labelId="amount"
                                                    variant='standard'
                                                    size='small'
                                                    placeholder='Amount'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.amount}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <InputLabel id="remark" className='mb-3'> Remark </InputLabel>
                                                <TextField
                                                    type="text"
                                                    name='remark'
                                                    fullWidth
                                                    labelId="remark"
                                                    variant='standard'
                                                    size='small'
                                                    placeholder='Remark'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.remark}
                                                />
                                            </Grid>

                                        </Grid>

                                        {formik.values.amount != TotalAmount &&

                                            < Grid container xs={12} justifyContent="center" className='mt-5' >
                                                <Typography color='error'> Deposite/Withdrawal amount should be equal to Total Amount </Typography>
                                            </Grid>
                                        }

                                        <Grid item xs={12}>
                                            <Typography variant='h6' className='text-primary'  > Note Details</Typography>
                                        </Grid>

                                        <Grid item xs={12} className="text-right mb-3 pr-5">
                                            <Typography variant='subtitle1' className='mt-3' > Total Amount</Typography>
                                            <Typography variant='h5' className=' text-info' >   </Typography>
                                        </Grid>

                                        <Grid item xs={12} className="mt-5">

                                            {/* Cash Details Form */}
                                            <Grid container spacing={2}>

                                                {AllCurrencyNotes?.data.map((ele, index) => {
                                                    return (
                                                        <Grid item md={6} xs={12} >
                                                            <Grid container spacing={2}>
                                                                <Grid item md={4} xs={12}>
                                                                    <Typography> {ele.currency_note} X </Typography>

                                                                </Grid>

                                                                <Grid item md={4} xs={6}>
                                                                    <TextField
                                                                        type="number"
                                                                        name={`cash_data[${index}].quantity`}
                                                                        fullWidth
                                                                        variant='outlined'
                                                                        size='small'
                                                                        label="Count"
                                                                        onChange={formik.handleChange}
                                                                        value={formik.values.cash_data?.length && formik.values.cash_data[index]?.quantity}
                                                                    />
                                                                    {
                                                                        formik.values.transaction_type == "WITHDRAW" &&
                                                                        <Grid> <em style={{ fontSize: "15px" }}> Available: <span className='text-info'> {quantityAvailable(ele.currency_note)} </span> </em> </Grid>
                                                                    }
                                                                    {/* {
                                                                        formik.values.transaction_type == "WITHDRAW" && formik.values.cash_data[index]?.quantity > quantityAvailable(ele.currency_note) &&

                                                                        <Grid> <em style={{ fontSize: "15px" }} className="text-danger">   {quantityAvailable(ele.currency_note) == 0 ? "No Notes Available" : `Only ${quantityAvailable(ele.currency_note)} notes available`}  </em> </Grid>
                                                                    } */}

                                                                </Grid>

                                                                <Grid md={4} item xs={6}>
                                                                    <Typography className='text-center text-info'>&#8377; {formik.values.cash_data?.length && formik.values.cash_data[index]?.quantity * ele.value}  </Typography>
                                                                </Grid>


                                                            </Grid>
                                                        </Grid>)
                                                })}
                                            </Grid>

                                            {
                                                AllCurrencyNotes?.data?.length > 0 &&
                                                <Divider className='mt-5 mb-5' />
                                            }

                                            {/* Online Mode Details Form  */}
                                            <Grid container spacing={2} >

                                                {AllPaymentModes?.data.map((ele, index) => {
                                                    return (
                                                        <Grid item md={6} xs={12} >
                                                            <Grid container spacing={2}>
                                                                <Grid item md={4} xs={12}>
                                                                    <Typography className='text-primary'> {ele.UPI_ID_ACCOUNT_NO}  </Typography>
                                                                </Grid>

                                                                <Grid item md={4} xs={6}>
                                                                    <TextField
                                                                        type="number"
                                                                        name={`online_payment_data[${index}].currency_note_value`}
                                                                        fullWidth
                                                                        variant='outlined'
                                                                        size='small'
                                                                        label="Count"
                                                                        onChange={formik.handleChange}
                                                                        value={formik.values.online_payment_data?.length && formik.values.online_payment_data[index]?.currency_note_value}
                                                                    />

                                                                    {
                                                                        formik.values.transaction_type == "WITHDRAW" &&
                                                                        <Grid> <em style={{ fontSize: "15px" }}> Available: <span className='text-info'> &#8377; {amountAvailableInOnlineModes(ele.UPI_ID_ACCOUNT_NO)} </span> </em> </Grid>
                                                                    }
                                                                    {
                                                                        formik.values.transaction_type == "WITHDRAW" && formik.values.online_payment_data[index].quantity > amountAvailableInOnlineModes(ele.UPI_ID_ACCOUNT_NO) &&
                                                                        <Grid> <em style={{ fontSize: "15px" }} className="text-danger">  &#8377; {amountAvailableInOnlineModes(ele.UPI_ID_ACCOUNT_NO) == 0 ? "Balance Not Available" : `Only &#8377; ${amountAvailableInOnlineModes(ele.UPI_ID_ACCOUNT_NO)} notes available`}  </em> </Grid>
                                                                    }
                                                                </Grid>

                                                                <Grid item md={4} xs={6}>
                                                                    <Typography className='text-center'> &#8377; {formik.values.online_payment_data?.length && formik.values.online_payment_data[index]?.currency_note_value ? formik.values.online_payment_data[index]?.currency_note_value : 0}  </Typography>
                                                                </Grid>


                                                            </Grid>
                                                        </Grid>)
                                                })}
                                            </Grid>

                                        </Grid>

                                        {/* {formik.isSubmitting ? */}
                                        {/* <LoadingButton
                                        size="small"
                                        onClick={formik.handleSubmit}
                                        color="primary"
                                        loading={false}
                                        variant="contained"
                                        loadingPosition="end"
                                        className='mt-5'
                                        fullWidth
                                    >
                                        Save
                                    </LoadingButton> */}
                                        {/* :

} */}
                                        <LoadingButton fullWidth variant='contained' color='primary' className='mt-5' type="submit" loading={loading} loadingPosition="start" >  Save</LoadingButton>


                                        {/* main container Ends */}
                                    </Grid>
                                </Grid>


                                {/* Exchnage Form  */}
                                <ExchangeNoteForm open={viewExchangeForm} setOpen={setViewExchangeForm} onSave={addCash} quantityAvailable={quantityAvailable} />

                            </>

                        </form>
                    )
            }

            {
                addCashInfo && (
                    <Toster info={addCashInfo} />
                )
            }
        </div >

    )
}
