import React, { useState, useEffect } from "react";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import * as yup from 'yup'
import { useFormik } from 'formik';
import {
  Grid,
  Button,
  Card,
  Box,
  CardContent,
  Dialog, Zoom, DialogTitle, DialogContent, TextField,
  FormControl, InputLabel, Select, MenuItem
} from "@material-ui/core";
import {
  useUpdateFacultyMutation,
  useGetSingleFacultyMutation,
  useGetAllStatesQuery
} from '../../../../services/services'
import DataNotFound from "../DataNotFound";


export default function GstDetails({ viewDialog, data, setFacultyData }) {

  const [updateData, updateDataInfo] = useUpdateFacultyMutation();
  const [singleFacultyData, singleFacultyDataInfo] = useGetSingleFacultyMutation();
  const [updateDialog, setUpdateDialog] = useState(false);

  useEffect(() => {
    singleFacultyData(data._id)
  }, [updateDataInfo])

  const dialogclose = () => {
    setUpdateDialog(false);
  };

  return (
    <>
      <Card sx={{ display: "flex" }} className="m-3" >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>

            <Button
              onClick={() => viewDialog(false)}
              className="text-light bg-danger float-right btn btn-sm btn-danger"
            >
              Close
            </Button>
          </CardContent>

          {singleFacultyDataInfo.isSuccess == true && singleFacultyDataInfo.data.data.length ?
            singleFacultyDataInfo.isLoading == false ?
              <div>
                {setFacultyData(singleFacultyDataInfo.data.data[0])}
                <Button
                  onClick={() => {
                    setUpdateDialog(true);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Update Gst Details
                </Button>

                <Card className="m-3 pb-3">
                  <Grid
                    container
                    spacing={1}
                    direction="column"
                    alignItems="left"
                    justifyContent="left"
                  >
                    <Grid item xs={12}>

                      <CardContent className="bg-light p-3 infoCards">

                        <Grid container spacing={6}>
                          <Grid className="text-muted text-left" item md={6}>
                            <h5>Gst Number</h5>

                          </Grid>
                          <Grid className="text-muted text-left" item md={6}>
                            <h5>{data.gst_number}</h5>

                          </Grid>

                        </Grid>
                        <hr />

                        <Grid container spacing={6}>
                          <Grid className="text-muted text-left" item md={6}>
                            <h5>Gst State</h5>

                          </Grid>
                          <Grid className="text-muted text-left" item md={6}>
                            <h5>{data.gst_state}</h5>

                          </Grid>

                        </Grid>
                        <hr />

                        <Grid container spacing={6}>
                          <Grid className="text-muted text-left" item md={6}>
                            <h5>Gst State Code</h5>

                          </Grid>
                          <Grid className="text-muted text-left" item md={6}>
                            <h5>{data.gst_state_code}</h5>

                          </Grid>

                        </Grid>


                      </CardContent>

                    </Grid>
                  </Grid>
                </Card>


                {updateDialog ? <UpdateGstDetails
                  updateDialog={updateDialog}
                  setUpdateDialog={setUpdateDialog}
                  updateData={updateData}
                  facultyId={data._id}
                  currentData={{
                    gst_number: singleFacultyDataInfo.data.data[0].gst_number,
                    gst_state: singleFacultyDataInfo.data.data[0].gst_state,
                    gst_state_code: singleFacultyDataInfo.data.data[0].gst_state_code
                  }} /> : null}
                {updateDataInfo.data && (
                  <Toster info={updateDataInfo} dialogclose={dialogclose} />
                )}
              </div>
              :
              <SkeletonListing />

            :
            <Grid container justifyContent="center">
              <DataNotFound />
            </Grid>

          }

        </Box>
      </Card>
    </>

  );
}

function UpdateGstDetails({ updateDialog, setUpdateDialog, updateData, facultyId, currentData }) {
  const { data, isSuccess } = useGetAllStatesQuery();
  const formik = useFormik({

    initialValues: {
      gst_number: currentData.gst_number,
      state_gst_code: currentData.gst_state_code
    },
    validationSchema: yup.object({
      gst_number: yup.string().required("Required!"),
      state_gst_code: yup.string().required("Required!")
    }),
    onSubmit: (values, e) => {
      var form_data = new FormData()
      form_data.append('key', 'gst_details')
      form_data.append('update_data', JSON.stringify(values))
      updateData({ data: form_data, id: facultyId })
    }
  })

  return (
    <Dialog
      fullScreen={false}
      open={updateDialog}
      //scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      // maxWidth="lg"
      fullWidth={true}
      TransitionComponent={Zoom}
    >
      <DialogTitle id="scroll-dialog-title">Update GST Details</DialogTitle>
      <DialogContent
      //dividers={scroll === "paper"}
      >
        <form id="updateForm" onSubmit={formik.handleSubmit}>
          <Grid style={{ paddingLeft: 10, paddingRight: 10, marginTop: 20, marginLeft: 10 }} >
            <Grid container spacing={1} >
              <Grid item xs={12}>
                <TextField
                  name="gst_number"
                  label="Gst Number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.gst_number}
                  fullWidth={true}
                  variant="outlined"
                  size="medium" />
                {formik.touched.gst_number && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.gst_number}</span>}
              </Grid>
              {/* <Grid item xs={12}>
                <FormControl
                  style={{ margin: 0 }}
                  fullWidth={true}
                  variant="outlined"
                  className={null}>

                  <InputLabel
                    htmlFor="outlined-institute-Name-native-simple">
                    Gst State
                  </InputLabel>

                  <Select
                    onChange={formik.handleChange}
                    id="newSkill"
                    onBlur={formik.handleBlur}
                    value={formik.values.state_gst_code}
                    label="Institute Name"
                    name="state_gst_code">
                    {isSuccess == true ? data.data.map(stateData => {
                      return <MenuItem value={stateData.code}>{stateData.state}</MenuItem>
                    }) : <MenuItem value="">Select</MenuItem>}
                  </Select>
                </FormControl>
                {formik.touched.state_gst_code && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.state_gst_code}</span>}
              </Grid> */}
            </Grid>
            <br />
            <br />
            <Grid container spacing={3} >
              <Grid item xs={12}>
                <div className="text-right">
                  <Button
                    onClick={() => setUpdateDialog(false)}
                    variant="contained"
                    color="secondary">
                    Close</Button>
                  <Button
                    type="submit"
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    color="primary">
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid >
        </form >
      </DialogContent>
    </Dialog>
  )

}
