// **** React Imports
import React from 'react'

// **** Formik Imports
import { ErrorMessage, Field, Form, Formik } from 'formik'

// **** Yup Import
import * as Yup from "yup"

// **** Material Imports
import { Button, Dialog, DialogContent, DialogTitle, Grid, InputLabel, TextField, Typography } from '@mui/material'

// **** Other Imports
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DatePicker from '@mui/lab/DatePicker'
import moment from 'moment'

function AddAttempt(props) {

    // **** Destructuring of Props
    const { open, onClose, onSubmit } = props

    // **** Form Initial Values
    const initialValues = {
        attempt_due_date: ""
    }

    // **** Validation Schema
    const validationSchema = Yup.object({
        attempt_due_date: Yup.date().required("Please select a attempt date"),

    })

    // **** On Submit Handler
    const onSubmitHandler = (values) => {
        values = {
            ...values,
            attempt_due_date: moment(values.attempt_due_date).format("MMM yyyy"),
        }
        onSubmit(values)
          
    }

    // **** Main Function Return
    return (
        <div>
            <Dialog open={open} onClose={() => onClose()} disableEnforceFocus  >
                <DialogTitle sx={{ bgcolor: "#5664d2", borderBottomRightRadius: "20px" }} className="text-white" >
                    Add Attempt Due
                </DialogTitle>
                <DialogContent>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmitHandler}
                    >
                        {
                            ({ values, setFieldValue }) => (
                                <Form>
                                    <Grid container spacing={3} className="mt-3" >

                                        {/* Batch Time From */}
                                        <Grid item xs={12}>
                                            <InputLabel htmlFor='attempt_due_date' className='text-dark' > Attempt Due Date  </InputLabel>
                                            <Field name="attempt_due_date">
                                                {
                                                    ({ field }) => (
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                openTo='month'
                                                                value={values.attempt_due_date}
                                                                views={['month', 'year']}
                                                                onChange={newValue => setFieldValue('attempt_due_date', newValue)}
                                                                renderInput={params => <TextField fullWidth variant='standard' {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    )
                                                }
                                            </Field>

                                            <ErrorMessage name='attempt_due_date'>
                                                {
                                                    (errMsg) => <span className='text-danger'> {errMsg}  </span>
                                                }
                                            </ErrorMessage>
                                        </Grid>

                                        <Grid container item xs={12} justifyContent="flex-end" mt={2}  >
                                            <Grid item xs={3}>
                                                <Button fullWidth variant='contained' onClick={() => onClose()} color="error" > cancel </Button>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Button fullWidth variant='contained' className='ml-2' type='submit' sx={{ backgroundColor: "#5664d2" }} > Save </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }
                    </Formik>

                </DialogContent>
            </Dialog>
        </div>
    )
}

export default AddAttempt
