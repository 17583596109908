import React from "react";
import { Form, Formik } from "formik";
import { object, string, number } from "yup";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useUpdateConfigDataMutation } from "../../../../../../services/configuration/ConfigDataServices";

const EditSource = ({ editData, onClose, configDataId, update }) => {
  // Form Intital Values
  const initialValues = {
    action: "UPDATE",
    data: {
      _id: editData._id,
      value_name: editData.value_name,
      order_rank: editData.order_rank,
    },
  };

  //  Form Validation Schema
  const validationSchema = object({
    data: object().shape({
      value_name: string().required("Required"),
      order_rank: number()
        .typeError("Order rank must be number")
        .min(1, "order rank should be greater than or equal to 1")
        .required("Required"),
    }),
  });

  // Form Submit Handler
  const handleSubmit = (values, { setSubmitting }) => {
    update({
      body: values,
      id: configDataId,
    }).then((res) => {
      if (res?.data?.status) {
        setSubmitting(false);
        onClose();
      } else {
        setSubmitting(false);
      }
    });
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle> Edit Sources </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form>
              <Grid container spacing={4} className="mt-2">
                <Grid item xs={12}>
                  <TextField
                    name="data.value_name"
                    fullWidth
                    size="small"
                    label="Source Name"
                    placeholder="Source Name"
                    value={formikProps.values.data.value_name}
                    onChange={(e) => {
                      formikProps.setFieldValue(
                        "data.value_name",
                        e.target.value
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="data.order_rank"
                    fullWidth
                    size="small"
                    label="Order Rank"
                    placeholder="Order Rank"
                    value={formikProps.values.data.order_rank}
                    onChange={(e) => {
                      formikProps.setFieldValue(
                        "data.order_rank",
                        e.target.value
                      );
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="flex-end"
                  spacing={2}
                >
                  <Grid item>
                    <Button variant="contained" color="error" onClick={onClose}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      type="submit"
                      loading={formikProps.isSubmitting}
                      variant="contained"
                    >
                      Update
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default EditSource;
