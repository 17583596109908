import React, { useState } from "react";
import Toster from "./Toster";
import EditDelete from "./EditDelete";
import FormDialog from "./reusableComponents/NewFormDialog";
import DataNotFound from "./DataNotFound";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Switch,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  TextField,
} from "@material-ui/core";
import {
  useGetCmsQuery,
  useUpdateCmsStatusMutation,
  useUpdateCMSDataMutation,
  useAddCmsMutation,
} from "../../../services/cmsServices";
import ChipInput from "material-ui-chip-input";
import { phoneRegex } from "../../../constant/regularExpression";
import AuthWrapper from "../../../utils/AuthWrapper";
export default function CmsContactUs() {
  const {
    data: AllData,
    isFetching,
    isLoading,
  } = useGetCmsQuery({ key: "contact_us" });
  const [updateIsShowStatus, updateIsShowInfo] = useUpdateCmsStatusMutation();
  const [updateInnerData, updateInnerDataInfo] = useUpdateCMSDataMutation();
  const [addData, updateAddDataInfo] = useAddCmsMutation();
  let cards = [];
  if (!isFetching || (!isLoading && AllData != undefined && AllData.status)) {
    cards =
      AllData != undefined && AllData.data.length
        ? AllData.data[0].contact_us
        : [];
  }
  const [editDialog, setEditDialog] = useState(false);
  const [addDialog, setAddDialog] = useState(false);
  const [data, setData] = useState("");
  const dialogclose = () => {
    setEditDialog(false);
  };
  const deactivateBtnHandler = (payloadId) => {
    updateIsShowStatus({
      key: "contact_us",
      cms_data: {
        contact_us_id: payloadId,
        is_show: false,
      },
    });
  };
  const viewBtnHandler = (payloadId) => {
    updateIsShowStatus({
      key: "contact_us",
      cms_data: {
        contact_us_id: payloadId,
        is_show: true,
      },
    });
  };
  const updateClickHandler = (dataForUpdate) => {
    const { inquiry_type, contact_number } = dataForUpdate;
    let dataWithId = {
      inquiry_type: inquiry_type,
      contact_number: contact_number,
      contact_us_id: data._id,
    };
    dataWithId = JSON.stringify(dataWithId);
    let form_data = new FormData();
    form_data.append("key", "contact_us");
    form_data.append("cms_data", dataWithId);
    updateInnerData(form_data);
  };
  const addBtnClickHandler = (dataForAdd) => {
    const { inquiryType, contactNumber } = dataForAdd;
    let finaldata = {
      inquiry_type: inquiryType,
      contact_number: contactNumber,
    };
    finaldata = JSON.stringify(finaldata);
    let form_data = new FormData();
    form_data.append("key", "contact_us");
    form_data.append("cms_data", finaldata);
    addData(form_data);
  };
  return (
    <div>
      {/* <Grid container spacing={2} className="mt-3 p-3">
        <EditDelete
          type="add"
          onclick={() => {
            setAddDialog(true);
          }}
        />
      </Grid> */}
      <AuthWrapper type="ACTION" name="CMS_CONTACT_US_LIST">
        {isFetching || isLoading ? (
          "Loading"
        ) : (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            className="mt-3 p-3"
          >
            {cards.length ? (
              cards.map((el) => {
                return (
                  <Grid item xs={3}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography variant="" sx={{ fontSize: 14 }}>
                          Inquiry Type :
                        </Typography>
                        <Typography
                          variant="h6"
                          color="primary"
                          component="div"
                        >
                          {el.inquiry_type}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          Contact Number :
                        </Typography>
                        <Typography variant="h6" color="primary">
                          {el.contact_number.toString()}
                          <br />
                        </Typography>
                      </CardContent>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                      >
                        <CardActions>
                          <AuthWrapper
                            type="ACTION"
                            name="CMS_CONTACT_US_CHANGE_STATUS"
                          >
                            <Switch
                              onClick={() => {
                                updateIsShowStatus({
                                  key: "contact_us",
                                  cms_data: {
                                    is_show: !el.is_show,
                                    contact_us_id: el._id,
                                  },
                                });
                              }}
                              checked={el.is_show}
                            />
                          </AuthWrapper>
                          <AuthWrapper
                            type="ACTION"
                            name="CMS_CONTACT_US_EDIT"
                          >
                            <EditDelete
                              type={"edit"}
                              setEditDialog={setEditDialog}
                              setData={setData}
                              data={el}
                            />
                          </AuthWrapper>
                        </CardActions>
                      </Grid>
                    </Card>
                  </Grid>
                );
              })
            ) : (
              <DataNotFound />
            )}
          </Grid>
        )}
      </AuthWrapper>
      {
        editDialog && (
          <UpdateContactUs
            editDialog={editDialog}
            setEditDialog={setEditDialog}
            data={data}
            updateClickHandler={updateClickHandler}
          />
        )
        // (
        //   <FormDialog
        //     open={editDialog}
        //     setOpen={setEditDialog}
        //     formName={"Update Contact "}
        //     btnName={"Update"}
        //     onBtnClick={updateClickHandler}
        //     // useAs="form"
        //     fields={[
        //       {
        //         fieldType: "text",
        //         fieldName: "Inquiry Type",
        //         initialValue: data.inquiry_type,
        //         accessKey: "inquiryType",
        //         inputProps:{ readOnly: true, }
        //       },
        //       {
        //         fieldType: "tel",
        //         fieldName: "Contact Number",
        //         initialValue: data.contact_number,
        //         accessKey: "contactNumber",
        //       },
        //     ]}
        //   />
        // )
      }
      {addDialog && (
        <FormDialog
          open={addDialog}
          setOpen={setAddDialog}
          formName={"Add Contact "}
          btnName={"Add"}
          onBtnClick={addBtnClickHandler}
          // useAs="form"
          fields={[
            {
              fieldType: "text",
              fieldName: "Inquiry Type",
              initialValue: "",
              accessKey: "inquiryType",
            },
            {
              fieldType: "tel",
              fieldName: "Contact Number",
              initialValue: "",
              accessKey: "contactNumber",
            },
          ]}
        />
      )}
      {updateIsShowInfo.data && (
        <Toster info={updateIsShowInfo} dialogclose={dialogclose} />
      )}
      {updateInnerDataInfo.data && (
        <Toster info={updateInnerDataInfo} dialogclose={dialogclose} />
      )}
      {updateAddDataInfo.data && (
        <Toster info={updateAddDataInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
function UpdateContactUs({
  editDialog,
  setEditDialog,
  data,
  updateClickHandler,
}) {
  const formik = useFormik({
    initialValues: {
      inquiry_type: data.inquiry_type,
      contact_number: data.contact_number,
    },
    validationSchema: yup.object({
      contact_number: yup
        .array(
          yup
            .string()
            .matches(phoneRegex, " Invalid Mobile Number")
            .required("Required !")
        )
        .min(1, " Invalid Mobile Number"),
    }),
    onSubmit: (values, e) => {
      updateClickHandler(values);
    },
  });
  const handleAddChip = (chip) => {
    let currentChips = [...formik.values.contact_number];
    currentChips.push(chip);
    formik.setFieldValue("contact_number", currentChips);
  };
  const handleDeleteChip = (chip, index) => {
    let currentChips = [...formik.values.contact_number];
    currentChips.splice(index, 1);
    formik.setFieldValue("contact_number", currentChips);
  };
  return (
    <Dialog
      fullScreen={false}
      open={editDialog}
      //scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle id="scroll-dialog-title">Update Details</DialogTitle>
      <DialogContent>
        <form id="addfacultyform" onSubmit={formik.handleSubmit}>
          <Grid
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              marginTop: 20,
              marginLeft: 10,
            }}
          >
            <Grid item xs={12}>
              <ChipInput
                value={formik.values.contact_number}
                onAdd={(chip) => handleAddChip(chip)}
                onDelete={(chip, index) => handleDeleteChip(chip, index)}
                name="contact_number"
                onBlur={formik.handleBlur}
                label="Contact Number"
              />
            </Grid>
            {formik.touched.contact_number && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {formik.errors.contact_number}
              </span>
            )}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="text-right">
                <Button
                  type="button"
                  onClick={() => setEditDialog(false)}
                  variant="contained"
                  color="secondary"
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  style={{ marginLeft: 20 }}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
