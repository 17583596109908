import React, { useEffect } from "react";
import "./App.css";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import WrapMainApp from "./view/HOC/WrapMainApp";
import Login from "./view/auth/Login";
import SignUp from "./view/auth/SignUp";
import SignIn from "./view/auth/SignIn";
import Register from "./view/auth/Register";
import ForgotPassword from "./view/auth/ForgotPassword";
import OTPVerification from "./view/auth/OTPVerification";
import SetNewPassword from "./view/auth/SetNewPassword";
import TheLayout from "./containers/TheLayout";
import { withStyles } from "@material-ui/core/styles";
import UnAuthorized from "./view/screens/dashboard/UnauthorizedPage";

const useStyles = (theme) => ({
  "*:focus": {
    outline: "none !important",
  },
});

const theme = createTheme({
  labelRoot: {
    fontSize: 30,
  },
  typography: {
    fontFamily: ["Poppins", '"sans-serif"'].join(","),
  },
  palette: {
    primary: {
      // Purple and green play nicely together.
      // main: "#5664D2",
      main: "#5664D2",
    },
    secondary: {
      // This is green.A700 as hex.
      // main: '#5664D2',
      main: "#ff0000",
    },
    danger: {
      // This is green.A700 as hex.
      // main: '#5664D2',
      main: "#ff0000",
    },
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              name="Login Page2"
              render={() => <Login theme={theme} />}
            />

            {/* <Route
              exact
              path="/SignIn"
              name="SignIn"
              render={() => <SignIn theme={theme} />}
            /> */}
            <Route
              exact
              path="/SignUp"
              name="SignUp"
              render={() => <SignUp theme={theme} />}
            />
            <Route
              exact
              path="/forgot-password"
              name="forgot-password"
              render={() => <ForgotPassword theme={theme} />}
            />
            <Route
              exact
              path="/otp-verification"
              name="OTP verification"
              render={() => <OTPVerification theme={theme} />}
            />
            <Route
              exact
              path="/reset-password"
              name="Reset Password"
              render={() => <SetNewPassword theme={theme} />}
            />
            <Route
              exact
              path="/sign-up"
              name="Register Page"
              render={() => <Register theme={theme} />}
            />
             <Route
              exact
              path="/not-authenticated"
              name="Unauthorized"
              render={() => <UnAuthorized theme={theme} />}
            />
            <WrapMainApp>
              <Route
                path="/dashboard"
                name="Dashboard"
                authModuleName={["dashboard"]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                path="/welcome"
                name="Welcome-page"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                path="/404"
                name="route-not-found"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                path="/orders"
                name="Orders"
                authModuleName={[
                  "order",
                  "tracking-details",
                  "order-transaction",
                ]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/pending-orders"
                name="Proccessing Orders"
                authModuleName={[
                  "order",
                  "tracking-details",
                  "order-transaction",
                ]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/failed-orders"
                name="Payment Pending Orders"
                authModuleName={[
                  "order",
                  "tracking-details",
                  "order-transaction",
                ]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/cancelorders"
                name="Cancel Orders"
                // authModuleName={["order","tracking-details","order-transaction"]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/product-cart"
                name="product-cart"
                // authModuleName={["order","tracking-details","order-transaction"]}
                render={() => <TheLayout theme={theme} />}
              />
              {/* <Route
                path="/transaction"
                name="transaction"
                authModuleName={["transaction"]}
                render={() => <TheLayout theme={theme} />}
              /> */}
              <Route
                path="/video-lectures"
                name="video-lectures"
                authModuleName={["video-lecture"]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/cms"
                name="cms"
                authModuleName={["cms"]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/faculty-and-institute"
                name="faculty-and-institute"
                authModuleName={["institute", "faculty"]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/book-and-product"
                name="book-and-product"
                authModuleName={["book", "book-product"]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/inquiries"
                name="inquiries"
                authModuleName={[
                  "enquiry",
                  "quote-your-price",
                  "failed-orders-request",
                ]}
                render={() => <TheLayout theme={theme} />}
              />
              {/* <Route
                path="/courier"
                name="courier"
                authModuleName={["courier"]}
                render={() => <TheLayout theme={theme} />}
              /> */}
              <Route
                path="/employees"
                name="employees"
                authModuleName={["sld-user"]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/reviews"
                name="reviews"
                authModuleName={["review"]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/tickets"
                name="tickets"
                authModuleName={["tickets"]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/quotes"
                name="quotes"
                authModuleName={["quote-your-price"]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/finance"
                name="finance"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/account"
                name="account"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/coupons-scatch-cards"
                name="coupons-scatch-cards"
                authModuleName={["coupon", "scratch-card"]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                path="/file-explorer"
                name="file-explorer"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                path="/time-sheet"
                name="time-sheet"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />

              {/* <Route
                path="/categories"
                name="categories"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              /> */}
              <Route
                path="/reviewemail"
                name="reviewemail"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/languages"
                name="languages"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/modes"
                name="modes"
                authModuleName={["mode"]}
                render={() => <TheLayout theme={theme} />}
              />
              {/* <Route
                path="/callrequests"
                name="callrequests"
                authModuleName={["enquiry"]}
                render={() => <TheLayout theme={theme} />}
              /> */}
              <Route
                path="/sms"
                name="sms"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/events"
                name="events"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/vclasses"
                name="vclasses"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/billing"
                name="billing"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/customemail"
                name="customemail"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/htmleditor"
                name="htmleditor"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/downloads"
                name="downloads"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/all-wallet-transactions"
                name="all-wallet-transactions"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/expenses"
                name="expenses"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/incomes"
                name="incomes"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/excellogs"
                name="excellogs"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/studentrequests"
                name="studentrequests"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                path="/callerperformance"
                name="callerperformance"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                path="/mobilenolibrary"
                name="mobilenolibrary"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/virtual-class"
                name="virtual-class"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                path="/users-and-franchise"
                name="users-and-franchise"
                authModuleName={["user"]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                path="/sld-users-and-groups"
                name="sld-users-and-groups"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                path="/UserPage"
                name="UserPage"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                path="/course-subject"
                name="course-subject"
                authModuleName={["course", "subject"]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                exact
                path="/configuration"
                name="configuration"
                authModuleName={[
                  "course",
                  "level",
                  "subject",
                  "category",
                  "mode",
                  "social-media",
                ]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                exact
                path="/offlineOrders"
                name="offlineOrders"
                authModuleName={["social-media"]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                exact
                path="/offlineDashboard"
                name="offlineDashboard"
                authModuleName={["social-media"]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                exact
                path="/virtualOrders"
                name="virtualOrders"
                authModuleName={["social-media"]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                exact
                path="/semiVirtualOrders"
                name="semiVirtualOrders"
                authModuleName={["social-media"]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                exact
                path="/pendriveOrders"
                name="pendriveOrders"
                authModuleName={["social-media"]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                exact
                path="/batchTiming"
                name="batchTiming"
                authModuleName={["social-media"]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                exact
                path="/halls"
                name="halls"
                authModuleName={["social-media"]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                exact
                path="/attempts"
                name="attempts"
                authModuleName={["social-media"]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                exact
                path="/batches"
                name="batches"
                authModuleName={["social-media"]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                exact
                path="/currency"
                name="currency"
                authModuleName={["social-media"]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                exact
                path="/otherPaymentModes"
                name="otherPaymentModes"
                authModuleName={["social-media"]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                exact
                path="/terms"
                name="terms"
                authModuleName={["social-media"]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                exact
                path="/centers"
                name="centers"
                authModuleName={["social-media"]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                exact
                path="/withdrawalAndDeposit"
                name="withdrawalAndDeposit"
                authModuleName={["social-media"]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                exact
                path="/allTransactions"
                name="allTransactions"
                authModuleName={["social-media"]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                exact
                path="/account"
                name="account"
                authModuleName={["social-media"]}
                render={() => <TheLayout theme={theme} />}
              />
              {/* 
              <Route
                exact
                path="/terms"
                name="terms"
                authModuleName={["social-media"]}
                render={() => <TheLayout theme={theme} />}
              />

              <Route
                exact
                path="/terms"
                name="terms"
                authModuleName={["social-media"]}
                render={() => <TheLayout theme={theme} />}
              /> */}

              <Route
                exact
                path="/offlineOrderInvoice"
                name="offlineOrderInvoice"
                authModuleName={["social-media"]}
                render={() => <TheLayout theme={theme} />}
              />
              <Route
                exact
                path="/changePassword"
                name="changePassword"
                authModuleName={[]}
                render={() => <TheLayout theme={theme} />}
              />
            </WrapMainApp>
          </Switch>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default withStyles(useStyles, { withTheme: true })(App);
