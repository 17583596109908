import React, { useState } from 'react';
import { Grid, FormControl, Select, InputLabel } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import { CardContent, Button, } from '@material-ui/core';
import { useGetAllCategoriesQuery } from '../../../services/services';
import { useFormik } from 'formik';
import * as yup from 'yup'


export default function AddCategory({ AddData, setDialog }) {
    const getAllCategory = useGetAllCategoriesQuery()
    const allCategory = getAllCategory.data;
  
    const formik = useFormik({
        initialValues: {
            parent_category_id: "",
            category_name: "",
            order_rank: "",
            category_code: ""
        },
        validationSchema: yup.object({
            category_name: yup.string().required("Required !"),
            order_rank: yup.string().required("Required !"),
            category_code: yup.string().required("Required !"),
        }),
        onSubmit: (values) => {
            AddData(values)
        }
    })

    return (

        <form onSubmit={formik.handleSubmit}>

            
            <FormControl style={{ margin: 0 }} fullWidth={true}
                variant="outlined"
            >
                <InputLabel htmlFor="outlined-Institue-Name-native-simple">parent category</InputLabel>
                <Select native
                    name="parent_category_id"
                    size='small'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.parent_category_id}
                    label="parent category">
                    <option aria-label="None" value="" />
                    {allCategory && allCategory.data.map((data) => <option aria-label="None" value={data._id} >{data.category_name}</option>)}
                </Select>
            </FormControl>
            {formik.touched.parent_category_id && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.parent_category_id}</span>}

            <br /><br />

            <TextField
                name="category_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.category_name}
                fullWidth={true}
                label="category name "
                variant="outlined"
                size="small" />
            {formik.touched.category_name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.category_name}</span>}

            <br /><br />


            <TextField
                name="order_rank"
                onChange={formik.handleChange}
                InputProps={{ inputProps: { min: 0 } }}
                onBlur={formik.handleBlur}
                value={formik.values.order_rank}
                fullWidth={true}
                type="number"
                min="0"
                label="Order Rank "
                variant="outlined"
                size="small" />
            {formik.touched.order_rank && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.order_rank}</span>}

            <br /><br />

            <TextField
                name="category_code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.category_code}
                fullWidth={true}
                label="Category Code "
                variant="outlined"
                size="small" />
            {formik.touched.category_code && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.category_code}</span>}

            <br /><br />


            <Grid container>
                <Grid xs={12} ></Grid>
                <Grid className="d-flex justify-content-start" xs={6} >
                    <Button onClick={() => setDialog(false)} variant="contained" color="secondary" style={{marginRight:10}}>
                        Close
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        Save
                    </Button>
                </Grid>
            </Grid>

        </form>

    )
}
