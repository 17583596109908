import React from 'react';
import CreateTable from './CreateTable';
import DeleteIcon from '@material-ui/icons/Delete';



const columns = [    
  
    {
        id: 'eventname',
        label: 'Event Name',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'username',
        label: 'User Name',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'usermobile',
        label: 'User Mobile',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'useremail',
        label: 'User Email',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
   
    {
        id: 'latupdated',
        label: 'Last Updated',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'actions',
        label: 'Actions',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
];
function createData(eventname,username,usermobile,useremail,latupdated,actions) {
    return {eventname,username,usermobile,useremail,latupdated,actions};
}
const rows = [
    createData( "HackaThon","ananomys",
    "9876543210",
    "connect@codiotics.com",
    "Click to Apply","16/08/2021 11:55 am",
     <DeleteIcon />),
];


export default function EventInquiries(props) {

    return (
        <CreateTable isButton={false} rows={rows} columns={columns} />
    )
}
