import React, { useState, useEffect, useCallback, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ListItemText from '@mui/material/ListItemText';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';




import {
    Grid,
    Typography,
    Select,
    MenuItem,
    InputLabel,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    Input,
    FormControl
} from "@material-ui/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};




export default function AddEditPaymentModeForm({formType , initialData , onSubmit , btnName , setOpen , }) {


    const initialValues = {
        payment_mode_name: formType == "edit" ? initialData.payment_mode_name : "",
        mobile_no: formType == "edit" ? initialData.mobile_no : "",
        UPI_ID_ACCOUNT_NO: formType == "edit" ? initialData.UPI_ID_ACCOUNT_NO : "",

    }

    const validationSchema = yup.object({
        payment_mode_name: yup.string().required("Required !"),
        mobile_no: yup.number() , 
        UPI_ID_ACCOUNT_NO: yup.string() , 
    })


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
              
            onSubmit(values)

        },
    });


    return (
        <div>
            {/* <Dialog open={open} handleClose={() => { setopen(false) }}> */}
            <form onSubmit={formik.handleSubmit}>
                {/* <DialogTitle> {formName}</DialogTitle> */}
                {/* <DialogContent> */}
                <Grid container spacing={3}>



                    <Grid item xs={12}>
                        {/* <FormControl fullWidth> */}
                        <InputLabel className="mb-2 mt-3" id="payment_mode_name"> <span className="text-danger">*</span> Payment Mode Name</InputLabel>

                        <Select
                            name="payment_mode_name"
                            labelId="payment_mode_name"
                            id="payment_mode_name"
                            value={formik.values.payment_mode_name}
                            label="Payment Mode Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        >

                            <MenuItem value="ACCOUNT"> Account</MenuItem>
                            <MenuItem value="UPI_ID">UPI Id</MenuItem>
                            <MenuItem value="OTHERS"> Others </MenuItem>

                        </Select>
                        {/* </FormControl> */}
                        {formik.touched.payment_mode_name && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                                {formik.errors.payment_mode_name}
                            </span>
                        )}

                    </Grid>




                    <Grid item xs={12}>
                        <InputLabel className="mb-2 mt-3" id="UPI_ID_ACCOUNT_NO"> UPI ID / Account Number </InputLabel>
                        <TextField
                            type="text"
                            name="UPI_ID_ACCOUNT_NO"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.UPI_ID_ACCOUNT_NO}
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                        />

                        {formik.touched.UPI_ID_ACCOUNT_NO && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                                {formik.errors.UPI_ID_ACCOUNT_NO}
                            </span>
                        )}

                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel className="mb-2 mt-3" id="mobile_no"> Mobile No.</InputLabel>
                        <TextField
                            name="mobile_no"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.mobile_no}
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                        />

                        {formik.touched.mobile_no && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                                {formik.errors.mobile_no}
                            </span>
                        )}

                    </Grid>








                </Grid>
                <Grid className="mt-4 text-right" >
                    <Button variant="contained" color="secondary" onClick={() => { setOpen(false) }} > Close</Button>
                    <Button variant="contained" color="primary" type="submit" className="ml-3"> {btnName}</Button>
                </Grid>
                {/* </DialogContent> */}
            </form>
            {/* </Dialog> */}

        </div >
    );
}

