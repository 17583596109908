import React from "react";
import { useUpdateVideoLectureSingleFieldMutation } from "../../../services/services";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Checkbox,
} from "@material-ui/core";
import Toster from "./Toster";
import { ErrorMessage, Field, Form, Formik } from "formik";

const AutoCompleteUpdateVideoLectureSingleField = (props) => {
  const {
    type,
    onClose,
    name,
    label,
    options,
    getOptionLabel,
    initialValue,
    id,
  } = props;


  let initialValues = {
    [name]: initialValue,
  };
  
  const [updateData, updateDataInfo] =
    useUpdateVideoLectureSingleFieldMutation();
    
  // **** on Submit Handler
  const onSubmitHandler = (values) => {
    let dataForUpdate;
      
    switch (type) {
      case "autoComplete":
        dataForUpdate = {
          key: name,
          update_data: values[name].value, // Extract the value from the selected option
        };
          
        updateData({ body: dataForUpdate, id: id });
        break;
      default:
        break;
    }
  };
  
  return (
    <div>
      <Dialog open={true} maxWidth="md" fullWidth>
        <DialogTitle> Update {label} </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={onSubmitHandler}
          >
            {({ values, setFieldValue, setFieldTouched }) => (
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <InputLabel style={{ marginBottom: 18 }} htmlFor={name}>
                      {" "}
                      {label}{" "}
                    </InputLabel>

                    {((type) => {
                      switch (type) {
                        case "autoComplete":
                          return (
                            <Field name={name}>
                              {({ field, form }) => (
                                <Autocomplete
                                  options={options}
                                  getOptionLabel={getOptionLabel}
                                  value={initialValue}
                                  onChange={(e, newValue) => {
                                    setFieldValue(name, newValue);
                                  }}
                                  onBlur={() => setFieldTouched(name, true)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={name}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />
                              )}
                            </Field>
                          );

                        default:
                          break;
                      }
                    })(type)}

                    <ErrorMessage name={name}>
                      {(errMsg) => (
                        <Typography color="error"> {errMsg} </Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>
                <DialogActions className="mt-5">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onClose}
                  >
                    {" "}
                    Close{" "}
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    {" "}
                    Save{" "}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
          {
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={updateDataInfo.isLoading}
              children={""}
            >
              <CircularProgress color="inherit" /> &nbsp; &nbsp; &nbsp;
              <Typography variant="h5">Saving...</Typography>
            </Backdrop>
          }
        </DialogContent>
      </Dialog>
      {updateDataInfo && <Toster info={updateDataInfo} dialogclose={onClose} />}
    </div>
  );
};

export default AutoCompleteUpdateVideoLectureSingleField;
