import React from "react";
import SearchBar from "./SearchBar";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import UploadExcelFile from "./UploadExcelFile";
import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Zoom,
  Tooltip,
  Link,
  TablePagination,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useGetDownloadTemplateLinkQuery } from "../../../services/cmsServices";

export default function AddDataHeader(props) {
  const [scroll, setScroll] = React.useState("paper");
  const [loader, setLoader] = React.useState(false);
  const [searchValue, setSearchVal] = React.useState("");
  const [downloadExcel, setDownloadExcel] = React.useState(false);
  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(+event.target.value);
    props.setPage(0);
  };

  const { data, isFetching, isLoading } = useGetDownloadTemplateLinkQuery();

  return (
    <div>
      <Box style={{ padding: 23 }}>
        <Grid container spacing={3}>
          {!props.addBtn && (
            <Grid item xs={3}>
              {props?.addFormName ? (
                <Button
                  onClick={() => {
                    props.setAddDialog(true);
                  }}
                  variant="contained"
                  color="primary"
                >
                  {props.addFormName}
                </Button>
              ) : null}
            </Grid>
          )}

          {!props.isSearch && (
            <Grid item xs={props.isButton && !props.addBtn ? 4 : 4}>
              <SearchBar
                setSearchQuery={setSearchVal}
                searchApi={props.searchBarApi}
                params={props.paginationData}
              />
            </Grid>
          )}

          {props.addBtn && !props.excelBtn ? <Grid item xs={3}></Grid> : ""}

          {!props.isPagination && (
            <Grid
              className=""
              item
              xs={props.isButton && !props.addBtn ? 5 : 5}
            >
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={props.totalItem}
                rowsPerPage={props.rowsPerPage}
                page={props.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          )}
        </Grid>
      </Box>

      <Dialog
        disableEnforceFocus
        fullScreen={props.fullsize ? props.fullsize : false}
        open={props.Adddialog}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={props?.maxWidth ? props?.maxWidth : "lg"}
        fullWidth={props.fullWidth ? props.fullWidth : false}
        TransitionComponent={Zoom}
      >
        <DialogTitle id="scroll-dialog-title">
          {props.addFormName}
          {props.setAddDialog && (
            <div className="float-right">
              <Button
                onClick={() => {
                  props.setAddDialog(false);
                }}
              >
                <HighlightOffIcon variant="contained" color="secondary" />
              </Button>
            </div>
          )}
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          {props.form}
        </DialogContent>
      </Dialog>
      {props.excelBtn && (
        <Grid item xs={3} spacing={2}>
          <div className="d-flex justify-content-start mb-3 align-items-center">
            {!isFetching && !isLoading && (
              <div className="mx-2 ">
                <span
                  className="text-muted text-info"
                  style={{ fontSize: 13 }}
                ></span>

                <Tooltip
                  placement="right"
                  title="Download the excel file format from below link and copy the below values in corresponding columns while filling excel sheet."
                  arrow
                >
                  <>
                    {props.currentPageName == "videoLecture" && props?.videoLectureDownloadTemplate ? (
                      <Link
                        className="round"
                        // href="https://codiotic-uat-01-public.s3.ap-south-1.amazonaws.com/SLD/Document/video-lecture-template.xlsx"
                        href={data?.data?.videolecture_template}
                      >
                        Download Template
                      </Link>
                    ) : null}
                    {props.currentPageName == "faculty" && props?.facultyDownloadTemplate ? (
                      <Link
                        className="round"
                        // href="https://codiotic-uat-01-public.s3.ap-south-1.amazonaws.com/SLD/Document/faculty_download_template_1.xlsx"
                        href={data?.data?.faculty_template}
                      >
                        Download Template
                      </Link>
                    ) : null}
                  </>
                </Tooltip>
              </div>
            )}
            <UploadExcelFile
              currentPageName={props.currentPageName}
              addExcelFaculty={props?.addExcelFaculty}
              addExcelVideoLecture={props?.addExcelVideoLecture}
              downloadExcelVideoLecture ={props?.downloadExcelVideoLecture}
              downloadExcelFaculty={props?.downloadExcelFaculty}
              downloadMobileExcel={props?.downloadMobileExcel}
              filteredPagination={
                props.filteredPagination ? props.filteredPagination : []
              }
              searchValue={searchValue}
              onDownloadExcelClick={props.onDownloadExcelClick}
            />
          </div>
        </Grid>
      )}
      {/* {props.dwnldexcel && (
        <Grid item xs={12} className=" px-5 py-2 text-right">
          <Button
            variant="contained"
            color="primary"
            
            onClick={() => setDownloadExcel(!downloadExcel)}
          >
            Download Excel
          </Button>
        </Grid>
      )}
      {downloadExcel && <DownloadOrderExcel />} */}
    </div>
  );
}