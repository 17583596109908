import React from 'react'
import { useGetAllUserGroupNamesQuery } from '../../../services/services';
import * as yup from 'yup'
import { useFormik } from 'formik';
import {
    FormControl, TextField,
    InputLabel, Select,
    Button, Grid, makeStyles, 
    Checkbox , FormControlLabel
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        minWidth: 100,
    },
    tab: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
}));
export default function AddSldUser({ setDialog, AddData }) {
    const classes = useStyles();
    const getAllUserGroup = useGetAllUserGroupNamesQuery()
    let alluserGroup = getAllUserGroup.data
    const PassWordRegex = RegExp(
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    );
    const phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/
    const formik = useFormik({
        initialValues: {
            email: "",
            name: "",
            username: "",
            password: "",
            mobile: "",
            group_id: "",
            can_view_lecture_sharing: "" ,
            online_caller:false ,
            offline_caller:false

        },
        validationSchema: yup.object({
            name: yup.string().required("Required !"),
            group_id: yup.string().required("Required !"),
            email: yup.string().email('Invalid email').required("Required !"),
            username: yup.string().required("Required !"),
            password: yup.string().required('Password is required'),
            //password: yup.string().matches(PassWordRegex,"min 8 letter password, with at least a symbol, upper and lower case letters and a number").required('Password is required'),
            mobile: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Number is required').min(10),
            can_view_lecture_sharing: yup.boolean().required('Can view lecture sharing is required'),
            online_caller :yup.boolean() ,
            offline_caller : yup.boolean()
        }),
        onSubmit: (values) => {
            var form_data = new FormData()
            for (let key in values) {
                form_data.append(key, values[key])
            }
            AddData(values)
        }
    }) 
    return (
        <div>
            <form onSubmit={formik?.handleSubmit}  style={{ width: 400 }}>
                <Grid container spacing={3} >
                    <Grid item xs={12}>
                        <FormControl 
                            style={{ margin: 0 }}
                            fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <label >Group Name</label>
                               <select  
                                className='py-2 rounded border border-secondary-subtle focus '
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                   value={formik?.values?.group_id}
                                   label="Group Name"
                                   name="group_id">
                                     {alluserGroup?.data &&  alluserGroup?.data?.map((val, ind) => {
                                    return (
                                    <option
                                     value={val?._id}
                                     key={ind}
                                    className='text-[14px] text-black'>
                                    {val?.group_name}
                                         </option>
                                            )
                                            })}
                                 {/* {alluserGroup &&
                                    alluserGroup.data.map(data => <MenuItem value={data?._id}>
                                        {data?.group_name}
                                    </MenuItem>)} */}
                            </select >
                        </FormControl>
                        {formik.touched.group_id && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.group_id}</span>}
                     
                    </Grid>


                    <Grid item xs={12}>
                        <TextField 
                           
                            name="name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            fullWidth={true}
                            label="Name"
                            variant="outlined"
                            size="small"
                        />
                        {formik.touched.name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.name}</span>}

                    </Grid>



                    <Grid item xs={12}>
                        <TextField 
                            name="username"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.username}
                            fullWidth={true}
                            label="Username Data"
                            variant="outlined"
                            size="small"
                        />
                        {formik.touched.username && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.username}</span>}

                    </Grid>




                    <Grid item xs={12}>
                        <TextField
                            name="mobile"
                            type="string"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.mobile}
                            fullWidth={true}
                            label="Mobile"
                            variant="outlined"
                            size="small"
                        />
                        {formik.touched.mobile && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.mobile}</span>}

                    </Grid>




                    <Grid item xs={12}>
                        <TextField
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            fullWidth={true}
                            label="Email"
                            variant="outlined"
                            size="small"
                        />
                        {formik.touched.email && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.email}</span>}

                    </Grid>


                    <Grid item xs={12}>
                        <TextField 
                            name="password"
                            type="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            fullWidth={true}
                            label="Password"
                            variant="outlined"
                            size="small"
                        />
                        {formik.touched.password && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.password}</span>}


                    </Grid>

                    <Grid item xs={12}>
                        <FormControl
                            style={{ margin: 0 }}
                            fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel
                                htmlFor="outlined-institute-Name-native-simple">
                                Can View Lecture Sharing
                            </InputLabel>
                            <Select
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.can_view_lecture_sharing}
                                label="Can View Lecture Sharing"
                                name="can_view_lecture_sharing">
                                <option aria-labe />
                                <option value={true}>
                                    Yes
                                </option>
                                <option value={false}>
                                    No
                                </option>
                            </Select>
                        </FormControl>
                        {formik.touched.can_view_lecture_sharing && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.can_view_lecture_sharing}</span>}

                    </Grid>
                    <Grid item xs={6}>
                    <FormControlLabel
                       control={<Checkbox name="online_caller"
                          color="primary" 
                          value={formik?.values?.online_caller}
                         onChange={(e)=>{
                            formik?.setFieldValue('online_caller' ,e.target.checked )
                        }} 
                         />}
                    label="Online User"
                  />
                        {formik.touched.online_caller && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.online_caller}</span>}


                    </Grid> 
                    <Grid item xs={6}>
                    <FormControlLabel
                       control={<Checkbox name="offline_caller"
                          color="primary" 
                          value={formik?.values?.offline_caller}
                         onChange={(e)=>{
                            formik?.setFieldValue('offline_caller' ,e.target.checked )
                        }} 
                         />}
                    label="Offline User"
                  />
                        {formik.touched.offline_caller && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.offline_caller}</span>}


                    </Grid>


                    <Grid item xs={12} className='d-flex justify-content-end'>
                        <Button
                            onClick={() => setDialog(false)}
                            variant="contained"
                            color="secondary"
                        >Close</Button>
                        <Button
                            type="submit"
                            style={{ marginLeft: 20 }}
                            variant="contained"
                            color="primary"
                        > Save</Button>

                    </Grid>
                </Grid >
            </form>
        </div>
    )
}
