import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, TextField, TextareaAutosize, Typography, Checkbox, Select, FormControl, InputLabel } from '@material-ui/core';
import { Grid, Paper } from '@material-ui/core';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Autocomplete from '@mui/material/Autocomplete';
import { CardContent } from '@material-ui/core';
import {
    useSendEmailDetailsMutation
} from '../../../services/EmailServices';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
    useGetAllMobileNoListQuery,
} from "../../../services/mobileNoLibraryServices";
import Toster from './Toster';
import { toast } from 'react-toastify';
import { emailRegex } from '../../../constant/regularExpression';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function SendEmailDetails({ mailType, setMailType }) {

    const [sendEmail , sendEmailInfo] = useSendEmailDetailsMutation() 


    const { data: allMobileNos } = useGetAllMobileNoListQuery();

    let emailOptions
    if (allMobileNos) {
        emailOptions = allMobileNos.data.map((ele,ind) => {
            return { name: ele.name, email: ele.email, mobile: ele.mobile }
        })
    }



    const formik = useFormik({
        initialValues: {
            mail_blurb_id:mailType._id,
            email_to:[],
            attachments:"",
            cc:mailType.cc,
            bcc:mailType.bcc
        },
        validationSchema: yup.object({
            email_to:yup.string().matches(emailRegex,'Enter correct email!').required("Required!"),
            attachments:"",
            cc:yup.string().required("Required"),
            bcc:yup.string().required("Required")
        }),
        onSubmit: (values) => {
            let dataToSend = new FormData()
            for(let key in values){
                dataToSend.append(key,values[key])
            }
            sendEmail(dataToSend)    
        },
    });

    // useEffect(()=>{
    //     formik.setFieldValue("title",title.title)
    //     formik.setFieldValue("templet_id",title.templet_id)
    //     formik.setFieldValue("msg_body",title.msg_body)
    // },[title])

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
            <Grid container className=''>
                
                    <Grid item xs={12} className=" my-2" >
                       <TextField
                            label="From"
                            id='from'
                            name='from'
                            type='text'
                            value={mailType.from}
                            onBlur={formik.handleBlur}
                            variant='outlined'
                            inputProps={
                                { readOnly: true}
                            }
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} className=" my-2" >
                       <TextField
                            label="Subject"
                            id='subject'
                            name='subject'
                            type='text'
                            value={mailType.subject}
                            onBlur={formik.handleBlur}
                            variant='outlined'
                            inputProps={
                                { readOnly: true, }
                            }
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} className=" my-2" >
                       <TextField
                            label="cc"
                            id='cc'
                            name='cc'
                            type='text'
                            value={formik.values.cc}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant='outlined'
                            fullWidth
                        />
                        {formik.touched.cc && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                                {formik.errors.cc}
                            </span>)}
                    </Grid>

                    <Grid item xs={12} className=" my-2" >
                       <TextField
                            label="bcc"
                            id='bcc'
                            name='bcc'
                            type='text'
                            value={formik.values.bcc}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant='outlined'
                            fullWidth
                        />
                        {formik.touched.bcc && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                                {formik.errors.bcc}
                            </span>)}
                    </Grid>

                    <Grid item xs={12} className=" my-2" >
                       
                        {
                            emailOptions && 
                            <Autocomplete
                                id="email_to"
                                //freeSolo
                                inputValue={formik.values.email_to}
                                onInputChange={(event,newValue)=>{
                                    formik.setFieldValue("email_to",newValue)
                                }}
                                name="email_to"
                                options={emailOptions}
                                isOptionEqualToValue={(option, value) => option.email === value.email}
                                getOptionLabel={(option) => option.email}
                                renderInput={(params) => 
                                    <TextField 
                                        fullWidth={true}
                                        variant="outlined"
                                        size="medium" 
                                        {...params} 
                                        label="Select Email" 
                                    />}/>
                        }
                        {formik.touched.email_to && formik.values.email_to==null && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                            Required !
                        </span>)}

                    </Grid>

                    <Grid xs={12} className=" my-2">
                        <Button variant='contained' color='primary' type='submit'>Send SMS</Button>
                        <Button className='ml-2' variant='contained' onClick={()=>{setMailType({ mail_type:"",cc:"",bcc:"",_id:"",subject:"",from:"" })}} color='secondary' >Reset</Button>
                    </Grid>
            </Grid>
            </form>
            {sendEmailInfo && <Toster info={sendEmailInfo} />}
        </div>
    )
}
