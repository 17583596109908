import React from 'react';
import SearchBar from './SearchBar';
import { makeStyles } from '@material-ui/core/styles';
import {Table , TableBody , TableCell, TableContainer , TableHead , TableRow, Paper , Box, Grid , Button , DialogActions, DialogContent , DialogTitle , Zoom , TablePagination , Dialog  } from '@material-ui/core';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
// import { Box, Grid } from '@material-ui/core';
// import { Button } from '@material-ui/core';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Zoom from '@material-ui/core/Zoom';
// import { TablePagination } from '@material-ui/core';
// import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 450,
    },
});

export default function CreateTable(props) {
    const classes = useStyles();
    const [rows, setRows] = React.useState(props.rows);
    const [columns, setColumns] = React.useState(props.columns);
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const searchHandler = (args) => {
          
    }
   

    return (
        <>
            <Box style={{ padding: 20 }}>
                <Grid container spacing={3}>
                    {props.isButton ? < Grid item xs={3}>
                        <Button onClick={handleClickOpen('paper')} variant="contained" color="primary">{props.addFormName}</Button>
                    </Grid> : ""}

                    <Grid item xs={props.isButton ? 4 : 6}>
                        <SearchBar style={{ display: "inline-block" }} search={searchHandler} />
                    </Grid>

                    <Grid item xs={props.isButton ? 5 : 6}>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
            </Box>
            {/* test script */}
            {props.isButton && (
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth="lg"
                TransitionComponent={Zoom}
            >
                <DialogTitle id="scroll-dialog-title">{props.addFormName}</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>

                {props.form}
                </DialogContent>
                <DialogActions style={{ marginRight: 20 }}></DialogActions>
            </Dialog>)}

            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>
        </>
    )
}