import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  DialogContent,
  DialogTitle,
  Zoom,
  Dialog,
  TableRow,
  Button,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "auto",
    width: "100%",
    overflow: "auto",
  },
});

export default function CreateTable(props) {
  const classes = useStyles();
  const [rows, setRows] = React.useState();
  const [columns, setColumns] = React.useState(props.columns);

  const [scroll, setScroll] = React.useState("paper");

  useEffect(() => {
    //
    setRows(props.rows);
  }, [props]);
  let arrHi = rows?.every((ele) => {
    ele["institute"] != null;
  });

  // if(rows){Object.keys(rows)
  // .forEach(function eachKey(key) {
  //
  //
  // });}
  const hinddenhandle = (id) => {
    return rows?.every((ele) => {
      if (ele[id] != null) return true;
    });
  };
  return (
    <>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  TABLE HEADINGS >>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    hidden={!hinddenhandle(column.id)}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, ...column.style }}
                    className={
                      column.className != undefined ? column.className : ""
                    }
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns?.map((column) => {
                        const value = row[column.id];
                        return (
                          <>
                            <TableCell
                              key={column.id}
                              hidden={!hinddenhandle(column.id)}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                ...column.style,
                              }}
                              className={
                                column.className != undefined
                                  ? column.className
                                  : ""
                              }
                            >
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* edit dialog */}
      <Dialog
        disableEnforceFocus
        fullScreen={props.fullsize ? props.fullsize : false}
        open={props.editDialog}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={props.maxWidth == undefined ? "lg" : props.maxWidth}
        fullWidth={props.fullWidth == undefined ? false : props.fullWidth}
        TransitionComponent={Zoom}
      >
        <DialogTitle id="scroll-dialog-title">
          {props.editFormName}
          {props.setEditDialog && (
            <div className="float-right">
              <Button
                onClick={() => {
                  props.setEditDialog(false);
                }}
              >
                <HighlightOffIcon variant="contained" color="secondary" />
              </Button>
            </div>
          )}
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          {props.editform}
        </DialogContent>
      </Dialog>
    </>
  );
}
