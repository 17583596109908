import React, { useState, useEffect } from "react";
import CreateTableAddButton from "../CreateTableAddButton";
import SkeletonListing from "../SkeletonListing";
import {
  Grid,
  Button,
  Card,
  Box,
  CardContent,
  IconButton
} from "@material-ui/core";
import {
  useGetSingleFacultyMutation
} from '../../../../services/services'


export default function CommisionRuleFlow({viewDialog, data}) {

    const columns = [
        {
          id: "SNo",
          label: "S.No",
          minWidth: 50,
          align: "center",
          format: (value) => value.toLocaleString("en-US"),
        },
        {
          id: "applicable_from_date",
          label: "Applicable From Date",
          minWidth: 50,
          align: "center",
          format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "applicable_to_date",
            label: "Applicable To Date",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
          },
          {
            id: "commission_amount_offline",
            label: "Commission Amount Offline",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
          },
          {
            id: "commission_amount_online",
            label: "Commission Amount Online",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
          },
          {
            id: "commission_percent_offline",
            label: "Commission Percent Offline",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
          },
          {
            id: "commission_percent_online",
            label: "Commission Percent Online",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
          }
      ];
  function createData(  SNo,
                        applicable_from_date,
                        applicable_to_date,
                        commission_amount_offline,
                        commission_amount_online,
                        commission_percent_offline,
                        commission_percent_online
                        ) {
                        return {
                          SNo,
                          applicable_from_date,
                          applicable_to_date,
                          commission_amount_offline,
                          commission_amount_online,
                          commission_percent_offline,
                          commission_percent_online
                        };
  }

  let rows = [];
  const [singleFacultyData, singleFacultyDataInfo] = useGetSingleFacultyMutation();
  
  if(typeof data.applied_commission_flow == 'object'){
    data.applied_commission_flow.map((ele,ind)=>{
      rows.push(
        createData(
            ind+1,
            ele.applicable_from_date,
            ele.applicable_to_date,
            ele.commission_amount_offline,
            ele.commission_amount_online,
            ele.commission_percent_offline,
            ele.commission_percent_online
        )
      );
    })
  }
  

  useEffect(()=>{
    singleFacultyData(data._id)
  },[])

  return (
    <>
    {
      singleFacultyDataInfo.isLoading==false&&singleFacultyDataInfo.isSuccess==true&&singleFacultyDataInfo.data.data.length ?
      <div>
          <Card sx={{ display: "flex" }} className="m-3" >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Button
                  onClick={() => viewDialog(false)}
                  className="text-light bg-info float-right btn btn-sm btn-danger"
                >
                  Close
                </Button>
              </CardContent>
            </Box>
          </Card>
         
         {
           rows.length ?
            <CreateTableAddButton
              editDialog={null}
              setPage={null}
              rows={rows}
              editform={null}
              columns={columns}
              isButton={true}
              editFormName={null}
            />:
            <Grid xs={12} className="my-1 text-center bg-light p-3" >NO DATA FOUND</Grid>
         }

      </div>
      :
      <SkeletonListing/>
      
    }
    </>
    
  );
}


