import React, { useState } from 'react';

// import material components
import { Button, Grid, Paper, Typography, List, ListItem, ListItemIcon, ListItemText, Collapse, Divider, Slide } from '@material-ui/core';
import { ListItemButton } from '@mui/material';

// import Icons 
import MenuIcon from '@material-ui/icons/Menu';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import MoneyIcon from '@material-ui/icons/Money';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import HomeWorkIcon from '@material-ui/icons/HomeWork';


// import JS files
import Hall from './Hall';
import Attempts from './Attempts';
import Currency from './Currency';
import OfflineDashboard from './OfflineDashbord';
import TermsAndConditions from './TermsAndConditions';
import BatchType from './BatchType';
import BatchTiming from './BatchTiming';
import AllCenters from './AllCenters';
import WithdrawalDepositExchange from './WithdrawalDepositExchange';
import AllVirtualOrders from './AllVirtualOrders';
import AllPendriveOrders from './AllPendriveOrders';
import AllSemiVirtualOrders from './AllSemiVirtualOrders';
import Batch from './Batch';
import PaymentMode from './PaymentMode';
import Account from './Account';
import AllOfflineTransactions from './AllOfflineTransactions';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

// Main Function Start 
export default function OfflineOrders() {


    const initialDisplay = {
        viewHallPage: false,
        viewAttemptPage: false,
        viewCurrencyPage: false,
        viewTermsAndConditionsPage: false,
        viewAllBatchesPage: false,
        viewBatchTypePage: false,
        viewBatchTimingPage: false,
        viewAllCentersPage: false,
        viewWithdrawalDepositExchnagePage: false,
        viewAllVirtualOrdersPage: false,
        viewAllPendriveOrdersPage: false,
        viewAllSemiVirtualOrdersPage: false,
        viewPaymentModePage: false,
        viewAccountsPage: false,
        viewAllTransactionsPage: false,


        viewDashboardPage: true, // this is by default display so this must be the last one.
    }

    const initialNestedListStatus = {
        orders: false,
        config: false,
        centers: false,
        withdrawalDeposit: false,
        dataDownLoad: false
    }

    const [openNestedList, setOpenNestedList] = useState(initialNestedListStatus)

    const handleNavItemClick = (page) => {
        setDispalyPage({ ...initialDisplay, [page]: true })
        setIsDrawerOpen(false)
    }

    const showPage = () => {
        for (let key in displayPage) {
            if (displayPage[key] == true) {
                switch (key) {
                    case "viewHallPage":
                        return <Hall />
                        break;
                    case "viewAttemptPage":
                        return <Attempts />
                        break;
                    case "viewCurrencyPage":
                        return <Currency />
                        break;
                    case "viewTermsAndConditionsPage":
                        return <TermsAndConditions />
                        break;
                    case "viewBatchTypePage":
                        return <BatchType />
                        break;
                    case "viewBatchTimingPage":
                        return <BatchTiming />
                        break;
                    case "viewAllCentersPage":
                        return <AllCenters />
                        break;
                    case "viewWithdrawalDepositExchnagePage":
                        return <WithdrawalDepositExchange />
                        break;
                    case "viewAllVirtualOrdersPage":
                        return <AllVirtualOrders />
                        break;
                    case "viewAllPendriveOrdersPage":
                        return <AllPendriveOrders />
                        break;
                    case "viewAllSemiVirtualOrdersPage":
                        return <AllSemiVirtualOrders />
                        break;
                    case "viewAllBatchesPage":
                        return <Batch />
                        break;
                    case "viewPaymentModePage":
                        return <PaymentMode />
                        break;
                    case "viewAccountsPage":
                        return <Account />
                        break;
                    case "viewAllTransactionsPage":
                        return <AllOfflineTransactions />
                        break;

                    case "viewDashboardPage":  // this is by default display so this must be the last one.
                        return <OfflineDashboard />
                        break;

                    default:
                        break;
                }
            }
        }
    }




    const navListItems = [
        { icon: <DashboardIcon color="primary" />, name: <span >Dashboard</span>, onClick: () => { handleNavItemClick("viewDashboardPage") } },
        {
            icon: <ShoppingBasketIcon color="primary" />, collapseIcon: openNestedList.orders ? "-" : "+", openCollapse: openNestedList.orders ? true : false, name: "Order", onClick: () => { setOpenNestedList({ ...initialNestedListStatus, orders: !openNestedList.orders }) }, nestedList: [

                { name: "Virtual Orders", onClick: () => { handleNavItemClick("viewAllVirtualOrdersPage") } },
                { name: "Pendrive Orders", onClick: () => { handleNavItemClick("viewAllPendriveOrdersPage") } },
                { name: "Semi-Virtual Orders", onClick: () => { handleNavItemClick("viewAllSemiVirtualOrdersPage") } },

            ]
        },
        {
            icon: <DesktopMacIcon color="primary" />, collapseIcon: openNestedList.config ? "-" : "+", openCollapse: openNestedList.config ? true : false, name: "Config", onClick: () => { setOpenNestedList({ ...initialNestedListStatus, config: !openNestedList.config }) }, nestedList: [

                // { name: "Batch Type", onClick: () => { handleNavItemClick("viewBatchTypePage") } },
                { name: "Batch Timing", onClick: () => { handleNavItemClick("viewBatchTimingPage") } },
                { name: "Halls", onClick: () => { handleNavItemClick("viewHallPage") } },
                { name: "Attempts", onClick: () => { handleNavItemClick("viewAttemptPage") } },
                { name: "Batches", onClick: () => { handleNavItemClick("viewAllBatchesPage") } },
                { name: "Currency", onClick: () => { handleNavItemClick("viewCurrencyPage") } },
                { name: "Payment Mode", onClick: () => { handleNavItemClick("viewPaymentModePage") } },
                { name: "Terms", onClick: () => { handleNavItemClick("viewTermsAndConditionsPage") } },
            ]
        },
        {
            icon: <HomeWorkIcon color="primary" />, collapseIcon: openNestedList.centers ? "-" : "+", openCollapse: openNestedList.centers ? true : false, name: "Centers", onClick: () => { setOpenNestedList({ ...initialNestedListStatus, centers: !openNestedList.centers }) }, nestedList: [

                { name: "All Centers", onClick: () => { handleNavItemClick("viewAllCentersPage") } },

            ]
        },
        { icon: <DashboardIcon color="primary" />, name: "Withdrawal & Deposit", onClick: () => { handleNavItemClick("viewWithdrawalDepositExchnagePage") }, },
        { icon: <DashboardIcon color="primary" />, name: "All Transactions", onClick: () => { handleNavItemClick("viewAllTransactionsPage") }, },
        { icon: <AccountBalanceWalletIcon color="primary" />, name: "Account", onClick: () => { handleNavItemClick("viewAccountsPage") }, },
        { icon: <MoneyIcon color="primary" />, name: "Withdrawal All" },
        { icon: <CloudDownloadIcon color="primary" />, name: "Data Download" },


    ]

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [displayPage, setDispalyPage] = useState(initialDisplay)


    const createListItem = (icon, collapseIcon, openCollapse, name, onClickAction, nestedList) => {
        return (
            <>
                <ListItemButton onClick={() => { onClickAction() }}>
                    <ListItemIcon >
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={name} className="text-secondary" />
                    {/* {collapseIcon  */}
                    <ListItemIcon style={{ fontSize: "20px" }} className="d-flex justify-content-end">
                        {collapseIcon ? collapseIcon : ">"}
                    </ListItemIcon>
                    {/* } */}
                </ListItemButton>
                {nestedList &&
                    <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {nestedList && nestedList.map((ele) => {
                                return <ListItemButton sx={{ pl: 4 }} onClick={() => { ele.onClick() }} >
                                    <ListItemText primary={ele.name} />
                                </ListItemButton>
                            })}

                        </List>
                    </Collapse>
                }
                <Divider />
            </>
        )
    }


    return (
        <div>

            <Grid container className='px-5 py-2 mt-1' style={{ backgroundColor: "#5664d2", height: "100%" }} >
                {/* <Grid item xs={8} className="text-center text-light">
                    <Typography variant='h5' ></Typography>
                </Grid> */}

                <Grid item xs={12} className="px-5 d-flex justify-content-end">
                    <MenuIcon className='text-light' fontSize='large' onClick={() => { setIsDrawerOpen(!isDrawerOpen) }} />
                </Grid>
            </Grid>

            <Grid container style={{ height: "100%" }}>
                {isDrawerOpen &&
                    <Grid item xs={2} style={{ height: "86vh", backgroundColor: "white" }} >

                        <Grid container justifyContent='flex-end'> <KeyboardArrowLeftIcon fontSize="large" onClick={() => { setIsDrawerOpen(false) }} /> </Grid>

                        <List >
                            {navListItems.map((ele) => {
                                return createListItem(ele.icon, ele.collapseIcon && ele.collapseIcon, ele.openCollapse && ele.openCollapse, ele.name, ele.onClick, ele.nestedList && ele.nestedList)

                            })}
                        </List>
                    </Grid>
                }
                <Grid item xs={isDrawerOpen ? 10 : 12}>
                    {showPage()}
                </Grid>
            </Grid>

        </div>
    )
}