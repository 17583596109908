import React, { useState, useEffect, Link, state } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import AddDataHeader from "../AddDataHeader";
import {
  useGetRefundRequestsMutation,
  useApproveRequestMutation,
  useDisapproveRequestMutation,
  useInitiateRefundMutation,
} from "../../../../services/StudentRequestsSevices";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import DataNotFound from "../DataNotFound";
import moment from "moment";
import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Avatar,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core";
import ViewRefundRequest from "./refund-request/ViewRefundRequest";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@material-ui/icons/Edit";
import * as yup from "yup";
import { useFormik } from "formik";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListIcon from "@material-ui/icons/FilterList";
import Filters from "../Filters";
import AuthWrapper from "../../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../../utils/getFilterFunction";

export default function RefundRequests() {
  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 20,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "user",
      label: "User",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "order",
      label: "Order",
      minWidth: 20,
      maxWidth: 10,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      style: { maxWidth: 250, overFlow: "scroll" },
    },
    {
      id: "dates",
      label: "Dates",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "amount",
      label: "Amount (₹)",
      minWidth: 20,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "requestStatus",
      label: "Request Status",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "action",
      label: "Actions",
      minWidth: 20,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: ["ORDER_REQUEST_REFUND_REQUEST_VIEW"],
    },
  ];

  function createData(SNo, user, order, dates, amount, requestStatus, action) {
    return {
      SNo,
      user,
      order,
      dates,
      amount,
      requestStatus,
      action,
    };
  }

  const orderRequestStatusList = ["PENDING", "APPROVE", "DISAPPROVE"];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredPagination, setFilteredPagination] = useState([]);
  let startDate = filteredPagination.filter((e, i) => {
    return e.fieldName == "startDate" && e.value;
  });
  let endDate = filteredPagination.filter((e, i) => {
    return e.fieldName == "endDate" && e.value;
  });
  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: [
      "sld_order_id",
      "order_title",
      "shipping_details.mobile",
      "shipping_details.email",
    ],
    filterBy: filteredPagination,
    page: page + 1,
    dateFilter: {
      dateFilterKey: "refund.applied_on_date",
      startDate: startDate.length ? startDate?.[0].value : null,
      endDate: endDate.length ? endDate?.[0].value : null,
    },
  };
  let rows = [];
  let [getid, setGetid] = useState("");
  let [getSldOrderID, setSldOrderID] = useState("");
  const [data, setData] = useState("");
  const [initiateDialog, setInitiateDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [getAllData, getAllDataInfo] = useGetRefundRequestsMutation();
  const [approveRequest, approveRequestInfo] = useApproveRequestMutation();
  const [disapproveRequest, disapproveRequestInfo] =
    useDisapproveRequestMutation();
  const [initiateRequest, initiateRequestInfo] = useInitiateRefundMutation();
  const AllData = getAllDataInfo.data;
  const totalItem = AllData ? AllData.totalItem : 0;
  const [render, setRender] = useState(false);
  const [remarkDialog, setRemarkDialog] = useState(false);
  const [approveRemarkData, setApproveRemarkData] = useState({
    key: "refund",
    approval_remark: "",
    remarkError: "",
  });
  const [typeOfBtnClicked, setTypeOfBtnClicked] = useState("");

  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page, filteredPagination]);

  let totalData = "ok";
  if (AllData) {
    totalData = AllData.totalItem;
    rows = AllData.data.map((ele, index) =>
      createData(
        index + 1,
        <div className="">
          <span className="text-info font-weight-bold">
            {ele.user_details.first_name} {ele.user_details.last_name}
          </span>
          <br />
          <span className="text-muted font-weight-bold">
            {ele.user_details.mobile}
          </span>
          <br />
          <span className="text-muted">({ele.user_details.email})</span>
        </div>,
        <div className="">
          <span className="text-info font-weight-bold">{ele.sld_order_id}</span>
          <br />
          <span className="text-muted">({ele.order_title})</span>
        </div>,
        <div className="">
          <span className="text-info font-weight-bold">Order Date</span>
          <br />
          <span className="text-muted font-weight-bold">
            {ele.order_date
              ? moment(ele.order_date).format("DD-MM-YYYY hh:mm:ss A")
              : ""}
          </span>
          <br />
          <ArrowDownwardIcon fontSize="small" className="text-info" />
          <br />
          <span className="text-info font-weight-bold">Applied Date</span>
          <br />
          <span className="text-muted font-weight-bold">
            {ele.applied_on_date
              ? moment(ele.applied_on_date).format("DD-MM-YYYY hh:mm:ss A")
              : ""}
          </span>
          <br />
          <ArrowDownwardIcon fontSize="small" className="text-info" />
          <br />
          <span className="text-info font-weight-bold">Payment Date</span>
          <br />
          <span className="text-muted font-weight-bold">
            {ele.payment_date
              ? moment(ele.payment_date).format("DD-MM-YYYY")
              : ""}
          </span>
          <br />
        </div>,
        "₹ " + ele.amount,
        <span className="text-info font-weight-bold">{ele.status}</span>,
        <div className="">
          {ele.status == "PENDING" ? (
            <>
              <Tooltip placement="top" title="Approve Request" arrow>
                <IconButton
                  onClick={() => {
                    setRemarkDialog(true);
                    setGetid(ele._id);
                    setTypeOfBtnClicked("APPROVE");
                  }}
                >
                  <CheckIcon color="primary" />
                </IconButton>
              </Tooltip>
              <Tooltip placement="top" title="Disapprove Request" arrow>
                <IconButton
                  onClick={() => {
                    setRemarkDialog(true);
                    setGetid(ele._id);
                    setTypeOfBtnClicked("DISAPPROVE");
                  }}
                >
                  <CloseIcon color="primary" />
                </IconButton>
              </Tooltip>
            </>
          ) : null}
          {!ele.refund_initiated && ele.is_approved ? (
            <Tooltip placement="top" title="Initiate Request" arrow>
              <IconButton
                onClick={() => {
                  setInitiateDialog(true);
                  setGetid(ele._id);
                  setSldOrderID(ele.sld_order_id);
                }}
              >
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
          ) : null}
          <AuthWrapper type="ACTION" name="ORDER_REQUEST_REFUND_REQUEST_VIEW">
            <EditDelete
              setViewDialog={setViewDialog}
              type="view"
              setData={setData}
              data={ele}
            />
          </AuthWrapper>
        </div>
      )
    );
  }

  const dialogclose = () => {
    setInitiateDialog(false);
    setViewDialog(false);
    setRender(!render);
    setRemarkDialog(false);
  };

  const handleApproveRemarkSubmit = () => {
    if (!approveRemarkData.approval_remark) {
      setApproveRemarkData({ ...approveRemarkData, remarkError: "Required!" });
      return;
    }
    if (approveRemarkData.approval_remark && getid) {
      approveRequest({
        body: {
          key: approveRemarkData.key,
          approval_remark: approveRemarkData.approval_remark,
        },
        id: getid,
      });
      return;
    }
  };

  const handleDisapproveRemarkSubmit = () => {
    if (!approveRemarkData.approval_remark) {
      setApproveRemarkData({ ...approveRemarkData, remarkError: "Required!" });
      return;
    }
    if (approveRemarkData.approval_remark && getid) {
      disapproveRequest({
        body: {
          key: approveRemarkData.key,
          disapproval_remark: approveRemarkData.approval_remark,
        },
        id: getid,
      });
      return;
    }
  };

  function InitiateForm({
    setInitiateDialog,
    initiateDialog,
    initiateRequest,
    getid,
  }) {
    let now = new Date();
    const formik = useFormik({
      initialValues: {
        amount: 0,
        payment_date: moment(now).format("YYYY-MM-DD"),
        remark: "",
        sld_order_id: getSldOrderID,
        transaction_details: "",
      },
      validationSchema: yup.object({
        amount: yup.number().required("Only Number !"),
        payment_date: yup.date().required("Required !"),
        remark: yup.string().required("Required !"),
      }),
      onSubmit: (values) => {
        initiateRequest({ body: values, id: getid });
      },
    });

    return (
      <Dialog
        fullWidth={true}
        open={initiateDialog}
        onClose={() => {
          setInitiateDialog(false);
        }}
      >
        <DialogTitle>Initiate Refund</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <TextField
              margin="dense"
              // id="name"
              name="amount"
              label="Amount"
              type="text"
              fullWidth
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.amount}
            />
            {formik.touched.amount && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {formik.errors.amount}
              </span>
            )}
            <br />
            <br />

            <TextField
              margin="dense"
              // id="name"
              name="payment_date"
              label="Payment Date"
              type="date"
              fullWidth
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.payment_date}
            />
            {formik.touched.payment_date && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {formik.errors.payment_date}
              </span>
            )}
            <br />
            <br />

            <TextField
              margin="dense"
              // id="name"
              name="remark"
              label="Remark"
              type="text"
              fullWidth
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.remark}
              multiline
              minRows={3}
            />
            {formik.touched.remark && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {formik.errors.remark}
              </span>
            )}
            <br />
            <br />

            <TextField
              margin="dense"
              // id="name"
              name="transaction_details"
              label="Transaction Details"
              type="text"
              fullWidth
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.transaction_details}
              multiline
              minRows={3}
            />
            {formik.touched.transaction_details && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {formik.errors.transaction_details}
              </span>
            )}
            <br />
            <br />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setInitiateDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
            >
              {" "}
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  return (
    <div>
      <AddDataHeader
        setPage={setPage}
        page={page}
        totalItem={totalItem}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchBarApi={getAllData}
        paginationData={paginationData}
        isButton={false}
        addBtn={true}
        isSearch={
          !AuthWrapper({
            type: "ACTION",
            name: "ORDER_REQUEST_REFUND_REQUEST_LIST",
            inBoolean: true,
          })
        }
        isPagination={
          !AuthWrapper({
            type: "ACTION",
            name: "ORDER_REQUEST_REFUND_REQUEST_LIST",
            inBoolean: true,
          })
        }
        filteredPagination={filteredPagination}
      />

      <AuthWrapper type="ACTION" name="ORDER_REQUEST_REFUND_REQUEST_LIST">
        {/* {
                    !viewDialog &&
                    <Accordion className=''>
                        <AccordionSummary className='text-primary'
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header" >
                            <Typography className='d-flex align-items-center' ><FilterListIcon /> <span > Filter</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails className=''>
                            <Filters
                                setFilteredPagination={setFilteredPagination}
                                isOrderRequestStatusFilter={false}
                                module="orderRequest"
                                filterListingData={{ orderRequestStatusList: orderRequestStatusList }}
                                isFromFilter={true}
                                isToFilter={true}
                            />
                        </AccordionDetails>
                    </Accordion>
                } */}
        {/* {addDataInfo.isLoading || deleteDataInfo.isLoading || updateDataInfo.isLoading ? <Loader /> : <> */}
        {!viewDialog ? (
          rows.length ? (
            <CreateTableAddButton
              maxWidth="md"
              fullWidth={false}
              rows={rows}
              columns={getFilterdColumns(columns)}
              isButton={true}
            />
          ) : totalData == 0 ? (
            <DataNotFound />
          ) : (
            <SkeletonListing />
          )
        ) : (
          <ViewRefundRequest
            viewDialog={viewDialog}
            data={data}
            setViewDialog={setViewDialog}
          />
        )}
      </AuthWrapper>
      {/* </>} */}

      {remarkDialog && (
        <Dialog fullWidth={true} open={remarkDialog} onClose={dialogclose}>
          <DialogTitle>Remark</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              // id="name"
              name="remark"
              label="remark"
              type="text"
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setApproveRemarkData({
                  ...approveRemarkData,
                  approval_remark: e.target.value,
                });
              }}
              multiline
              minRows={4}
              // onBlur={handleBlur}
              // value={value}
            />
            <p className="text-danger">{approveRemarkData.remarkError}</p>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setRemarkDialog(false);
                setApproveRemarkData({ ...approveRemarkData, remarkError: "" });
              }}
            >
              Cancel
            </Button>
            <Button
              // type="submit"
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
              onClick={() => {
                typeOfBtnClicked == "APPROVE"
                  ? handleApproveRemarkSubmit()
                  : typeOfBtnClicked == "DISAPPROVE"
                  ? handleDisapproveRemarkSubmit()
                  : null;
              }}
            >
              {" "}
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {initiateDialog && (
        <InitiateForm
          setInitiateDialog={setInitiateDialog}
          initiateDialog={initiateDialog}
          initiateRequest={initiateRequest}
          getid={getid}
        />
      )}
      {approveRequestInfo && (
        <Toster info={approveRequestInfo} dialogclose={dialogclose} />
      )}
      {disapproveRequestInfo && (
        <Toster info={disapproveRequestInfo} dialogclose={dialogclose} />
      )}
      {initiateRequestInfo && (
        <Toster info={initiateRequestInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
