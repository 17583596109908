import React, { useState } from "react";
import EditDelete from "./EditDelete";
import Toster from "./Toster";
import { Paper, Grid, Button } from "@material-ui/core";
import {
  useGetCmsQuery,
  useUpdateCmsStatusMutation,
  useUpdateCMSDataMutation,
} from "../../../services/cmsServices";
import FormDialog from "./reusableComponents/NewFormDialog";
import DataNotFound from "./DataNotFound";
import AuthWrapper from "../../../utils/AuthWrapper";

export default function CmsPaymentMethods() {
  const {
    data: AllData,
    isFetching,
    isLoading,
  } = useGetCmsQuery({ key: "book_product_page_banner_image" });

  const [updateImage, updateImageInfo] = useUpdateCMSDataMutation();
  const [updateIsShowStatus, updateIsShowInfo] = useUpdateCmsStatusMutation();

  const [editDialog, setEditDialog] = useState(false);
  const [data, setData] = useState(null);

  const UpdateData = (data) => {
    updateIsShowStatus(data);
  };

  const onClickHandler = (dataForUpdate) => {
    const image = dataForUpdate.image;
    let form_data = new FormData();

    form_data.append("key", "book_product_page_banner_image");
    form_data.append("cms_data", "");
    form_data.append("image", image);

    updateImage(form_data);
  };

  const dialogclose = () => {
    setEditDialog(false);
  };

  return (
    <>
      {AllData != undefined && AllData.data.length ? (
        <div>
          {AllData != undefined ? (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <AuthWrapper
                type="ACTION"
                name="CMS_BOOK_PRODUCT_PAGE_BANNER_HIDE"
              >
                <Grid item xs={12} className="pt-4">
                  {AllData.data[0].book_product_page_banner_image
                    .book_product_page_banner_image_show_on_mobile ? (
                    // <<<<<<<<<<<<<<<<<<<<< IF IS_SHOW IS TRUE THEN ""HIDE BUTTON"" ENABLED >>>>>>>>>>>>>>>>>>>>>>>>>>>>

                    <Button
                      variant="contained"
                      onClick={() => {
                        UpdateData({
                          key: "book_product_page_banner_image",
                          cms_data: {
                            is_show: false,
                          },
                        });
                      }}
                      color="secondary"
                    >
                      hide on mobile
                    </Button>
                  ) : (
                    // <<<<<<<<<<<<<<<<<<<<<<<< IF IS_SHOW IS FALSE THEN ""SHOW BUTTON"" ENABLED >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

                    <Button
                      variant="contained"
                      onClick={() => {
                        UpdateData({
                          key: "book_product_page_banner_image",
                          cms_data: {
                            is_show: true,
                          },
                        });
                      }}
                      color="primary"
                    >
                      {" "}
                      show on mobile{" "}
                    </Button>
                  )}
                </Grid>
              </AuthWrapper>
              <AuthWrapper
                type="ACTION"
                name="CMS_BOOK_PRODUCT_PAGE_BANNER_EDIT"
              >
                <Grid item xs={2} className="pt-4">
                  <EditDelete
                    setEditDialog={setEditDialog}
                    type="edit"
                    setData={setData}
                    data={null}
                  />
                </Grid>
              </AuthWrapper>
            </Grid>
          ) : null}
          <AuthWrapper type="ACTION" name="CMS_BOOK_PRODUCT_PAGE_BANNER_LIST">
            {isLoading || isFetching ? (
              "Loading"
            ) : (

              <Grid className="px-5" container justifyContent="center">
                <div xs={6} className="my-5">
                  <img
                    style={{ maxWidth: '68%', height: 'auto' }}
                    src={
                      AllData.data[0].book_product_page_banner_image
                        .book_product_page_banner_image
                    }
                    alt="No Image !"
                  />
                </div>
              </Grid>
            )}
          </AuthWrapper>
          {editDialog && (
            <FormDialog
              open={editDialog}
              setOpen={setEditDialog}
              formName={"Update Book Product Banner Image"}
              btnName={"Update"}
              onBtnClick={onClickHandler}
              // useAs="form"
              fields={[
                {
                  fieldType: "file",
                  // fieldName: "Image",
                  initialValue: null,
                  accessKey: "image",
                },
              ]}
            />
          )}

          {updateImageInfo.data && (
            <Toster info={updateImageInfo} dialogclose={dialogclose} />
          )}

          {updateIsShowInfo.data && (
            <Toster info={updateIsShowInfo} dialogclose={dialogclose} />
          )}
        </div>
      ) : (
        <DataNotFound />
      )}
    </>
  );
}
