import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup'

import {
    Checkbox, TextField, Grid, Button, makeStyles, Select,
    FormControl, InputLabel, OutlinedInput, MenuItem, ListItemText
} from '@material-ui/core'
import {
    useGetAallFacultieNamesQuery,
    useGetAllSubjectNamesQuery,
} from '../../../services/services';

const useStyles = makeStyles((theme) => ({
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        minWidth: 100,
    },
    tab: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export default function UpdateBooks({ editDialog, editData, UpdateData }) {
    const dialogclose = () => { editDialog(false) }
    const facultyArr = editData.faculty.map((data)=>data.faculty_name)
    const [facultyValue, setFacultyValue] = useState([...facultyArr]);
    const classes = useStyles();
    const getAallFaculties = useGetAallFacultieNamesQuery()
    const getAllSubjectNames = useGetAllSubjectNamesQuery()
    const allSubjects = getAllSubjectNames.data
    const allFaculty = getAallFaculties.data
      
    const formik = useFormik({
        initialValues: {
            book_name: editData.book_name,
            title: editData.title,
            edition: editData.edition,
            isbn: editData.isbn,
            quantity: editData.quantity,
            faculty:[],
            subject: editData.subject,
            format: editData.format,
            binding: editData.binding,
            type: editData.type,
            book_code: editData.book_code,
            mrp: editData.mrp,
            no_of_pages: editData.no_of_pages,
            weight: editData.weight,
            language: editData.language,
            imp_description: editData.imp_description,
            is_available: editData.is_available,
        },
        validationSchema: yup.object({
            book_name: yup.string().required("Required !"),
            title: yup.string().required("Required !"),
            edition: yup.string().required("Required !"),
            isbn: yup.string().required("Required !"),
            quantity: yup.number().required("Required !"),
            subject: yup.string().required("Required !"),
            format: yup.string().required("Required !"),
            binding: yup.string().required("Required !"),
            type: yup.string().required("Required !"),
            book_code: yup.string().required("Required !"),
            mrp: yup.number().required("Required !"),
            no_of_pages: yup.number().required("Required !"),
            weight: yup.string().required("Required !"),
            language: yup.string().required("Required !"),
            imp_description: yup.string().required("Required !"),
            is_available: yup.string().required("Required !"),
        }),
        onSubmit: (values) => {
          
        values.faculty = facultyValue.toString()

        let updatedata = {
                body: values,
                id: editData._id
            }
            // UpdateData(updatedata)
              
        }
    })
 
    const facultyChange = (event) => {
          
        const { target: { value }} = event;
          
        
        setFacultyValue(typeof value === 'string' ? value.split(',') : value);
    };

    return (
        <div className="p-3">


            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3} >
                    <Grid item xs={6}  >
                        <TextField
                            name="book_name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.book_name}
                            fullWidth={true}
                            label="Book Name"
                            variant="outlined"
                            size="small"
                        />
                        {formik.touched.book_name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.book_name}</span>}
                        <br /><br />
                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="title"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title}
                            fullWidth={true}
                            label="Book Title"
                            variant="outlined"
                            size="small"
                        />
                        {formik.touched.title && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.title}</span>}
                        <br /><br />
                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="edition"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.edition}
                            fullWidth={true}
                            label="Book Edition"
                            variant="outlined"
                            size="small"
                        />
                        {formik.touched.edition && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.edition}</span>}
                        <br /><br />
                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="isbn"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.isbn}
                            fullWidth={true}
                            label="Book ISBN"
                            variant="outlined"
                            size="small"
                        />
                        {formik.touched.isbn && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.isbn}</span>}
                        <br /><br />
                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="quantity"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.quantity}
                            fullWidth={true}
                            label="Book Quantity"
                            variant="outlined"
                            size="small"
                        />
                        {formik.touched.quantity && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.quantity}</span>}
                        <br /><br />
                    </Grid>

                    <Grid item xs={6}  >
                        <FormControl style={{ margin: 0 }}
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth={true}
                            sx={{ m: 1, width: 300 }}>
                            <InputLabel id="faculty-multiple-checkbox-label">Faculty</InputLabel>

                            <Select
                                labelId="faculty-multiple-checkbox-label"
                                id="faculty-multiple-checkbox"
                                multiple
                                name="faculty"
                                onChange={facultyChange}
                                value={facultyValue}
                                input={<OutlinedInput label="Faculty" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                <MenuItem>
                                    <TextField
                                        label=" Search"
                                        fullWidth={true}
                                        variant="outlined"
                                        size="small"
                                        name="Search"
                                    />
                                </MenuItem>


                                {allFaculty && allFaculty.data.map((data) => (
                                    <MenuItem key={data._id} value={data.name}>
                                        <Checkbox checked={facultyValue.indexOf(data.name) > -1} />
                                        <ListItemText primary={data.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {formik.touched.faculty && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.faculty}</span>}
                        <br /><br />
                    </Grid>

                    <Grid item xs={6}  >
                        <FormControl style={{ margin: 0 }} fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel htmlFor="outlined-Institue-Name-native-simple">Book Subject</InputLabel>
                            <Select native
                                name="subject"
                                size='small'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.subject}
                                label="Book Subject">
                                <option aria-label="None" value="" />
                                {allSubjects && allSubjects.data.map((data) => <option value={data._id}>{data.subject_name}</option>)}
                            </Select>
                        </FormControl>
                        {formik.touched.subject && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.subject}</span>}
                        <br /><br />
                    </Grid>

                    <Grid item xs={6}  >
                        <FormControl style={{ margin: 0 }} fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel htmlFor="outlined-Institue-Name-native-simple">Book Formate</InputLabel>
                            <Select native
                                name="format"
                                size='small'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.format}
                                label="Book Formate">
                                <option aria-label="None" value="" />
                                <option>Hard Copy</option>
                                <option>Soft Copy</option>
                            </Select>
                        </FormControl>
                        {formik.touched.format && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.format}</span>}
                        <br /><br />
                    </Grid>

                    <Grid item xs={6}  >
                        <FormControl style={{ margin: 0 }} fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel htmlFor="outlined-Institue-Name-native-simple">Book Binding</InputLabel>
                            <Select native
                                name="binding"
                                size='small'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.binding}
                                label="Book Binding">
                                <option aria-label="None" value="" />
                                <option>Spiral</option>
                                <option>Paper Back</option>
                            </Select>
                        </FormControl>
                        {formik.touched.binding && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.binding}</span>}
                        <br /><br />
                    </Grid>

                    <Grid item xs={6}  >
                        <FormControl style={{ margin: 0 }} fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel htmlFor="outlined-Institue-Name-native-simple">Book Type</InputLabel>
                            <Select native
                                name="type"
                                size='small'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.type}
                                label="Book Type">
                                <option aria-label="None" value="" />
                                <option>Chart</option>
                                <option>MCQ Book</option>
                                <option>questionnaire</option>
                                <option>Scanner / Compiler</option>
                                <option>Study Material</option>
                            </Select>
                        </FormControl>
                        {formik.touched.type && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.type}</span>}
                        <br /><br />
                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="book_code"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.book_code}
                            fullWidth={true}
                            label="Book Code"
                            variant="outlined"
                            size="small"
                        />
                        {formik.touched.book_code && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.book_code}</span>}
                        <br /><br />
                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="mrp"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.mrp}
                            fullWidth={true}
                            label="Book MRP"
                            variant="outlined"
                            size="small"
                        />
                        {formik.touched.mrp && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.mrp}</span>}
                        <br /><br />
                    </Grid>
                    <Grid item xs={6}  >
                        <TextField
                            name="no_of_pages"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.no_of_pages}
                            fullWidth={true}
                            label="Book No. Of Page"
                            variant="outlined"
                            size="small"
                        />
                        {formik.touched.no_of_pages && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.no_of_pages}</span>}
                        <br /><br />
                    </Grid>
                    <Grid item xs={6}  >
                        <TextField
                            name="weight"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.weight}
                            fullWidth={true}
                            label="Book Weight"
                            variant="outlined"
                            size="small"
                        />
                        {formik.touched.weight && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.weight}</span>}
                        <br /><br />
                    </Grid>
                    <Grid item xs={6}  >
                        <FormControl style={{ margin: 0 }} fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel htmlFor="outlined-Institue-Name-native-simple">Book Language</InputLabel>
                            <Select native
                                name="language"
                                size='small'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.language}
                                label="Book Language">
                                <option aria-label="None" value="" />
                                <option>Hindi</option>
                                <option>English</option>
                            </Select>
                        </FormControl>
                        {formik.touched.language && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.language}</span>}
                        <br /><br />
                    </Grid>
                    <Grid item xs={6}  >
                        <TextField
                            name="imp_description"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.imp_description}
                            fullWidth={true}
                            label="Book Description"
                            variant="outlined"
                            size="small"
                        />
                        {formik.touched.imp_description && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.imp_description}</span>}
                        <br /><br />
                    </Grid>
                    <Grid item xs={6}  >
                        <FormControl style={{ margin: 0 }} fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel htmlFor="outlined-Institue-Name-native-simple">Book Available</InputLabel>
                            <Select native
                                name="is_available"
                                size='small'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.is_available}
                                label="Book Available">
                                <option aria-label="None" value="" />
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </Select>
                        </FormControl>
                        {formik.touched.is_available && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.is_available}</span>}
                        <br /><br />
                    </Grid>

                    <Grid item xs={6}  >
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={dialogclose}
                        >Close</Button>
                        <Button
                            type="submit"
                            style={{ marginLeft: 20 }}
                            variant="contained"
                            color="primary"
                        > Save</Button>
                    </Grid>
                    {/* 2nd Grid */}

                </Grid>
            </form>
        </div>
    )
}
