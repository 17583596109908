import {
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    Checkbox,
    FormControlLabel,
  } from "@material-ui/core";
  import { Link } from "react-router-dom";
  import React from "react";
  
  export default function SignUp() {
    return (
      <div>
        <Grid container style={{ marginTop: "5%" }}>
          <Grid item xs={3}></Grid>
  
          <Grid item xs={6}>
            <Box boxShadow={3} style={{ backgroundColor: "#fff" }}>
              <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                  <Typography
                    align="center"
                    variant="h4"
                    component="h4"
                    style={{ padding: 20 }}
                  >
                    Welcome To
                    <br />
                    <span style={{ color: "#5664D291" }}>
                      <b>Smart Learning Destination</b>
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
              <Grid container style={{ padding: "2%" }}>
                <Grid item xs={7} style={{ padding: "2%" }}>
                  <Typography variant="h5" component="h5">
                    SignUp
                  </Typography>
                  <br />
                  <Grid container>
                      <Grid item xs={6}>
                      <TextField
                    label="First Name*"
                    fullWidth={true}
                    variant="outlined"
                    name="firstname"
                    type=""
                    size="small"
                  />
                  
                </Grid>
                      <Grid item xs={6}>
                      <TextField
                    label="Last Name*"
                    fullWidth={true}
                    variant="outlined"
                    name="lastname"
                    type=""
                    size="small"
                  />
                  
                      </Grid>
                  </Grid><br/>
                  <Grid container>
                      <Grid item xs={6}>
                      <TextField
                    label="Email*"
                    fullWidth={true}
                    variant="outlined"
                    name="email"
                    type="email"
                    size="small"
                  />
                  
                      </Grid>
                      <Grid item xs={6}>
                      <TextField
                    label="Mobile Number*"
                    fullWidth={true}
                    variant="outlined"
                    name="mobile"
                    type="number"
                    size="small"
                  />
                  
                      </Grid>
                  </Grid><br/>
                 
                  
                  <TextField
                    label="Password*"
                    fullWidth={true}
                    variant="outlined"
                    name="password"
                    type="password"
                    size="small"
                  />
                  <br />
                  <br />
                  <TextField
                    label="Confirm Password*"
                    fullWidth={true}
                    variant="outlined"
                    name="confirmpassword"
                    type="password"
                    size="small"
                  />
                  <br />
                  <br />
                
                  <FormControlLabel
                    control={<Checkbox name="checkedB" color="primary" />}
                    label="Remember Me"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                  >
                    SignUp
                  </Button>
                  
                  <Link to="/" style={{ fontWeight: 600 }}><Button color="primary">I am Already a Member</Button></Link>
                  
                  
                </Grid>
                <Grid item xs={5} >
                    <Box style={{ background: "#5664D291" ,marginTop:"12%"}}>
                    <img
                    src="assets/images/login-pana.png"
                    alt=""
                    className="img-fluid"
                  />
                    </Box>
                 
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </div>
    );
  }
  