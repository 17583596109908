import React, { useState, useEffect } from "react";
import EditDelete from "./EditDelete";
import CreateTableAddButton from "./CreateTableAddButton";
import ViewCategory from "./ViewCategory";
import Toster from "./Toster";
import AddLevel from "./AddLevel";
import Loader from "./Loader";

import {
  useGetAllLevelFilterMutation,
  useDeleteCourseLevelMutation,
  useAddCourseLevelMutation,
  useUpdateCourseLevelMutation,
} from "../../../services/services";
import SkeletonListing from "./SkeletonListing";
import UpdateLevels from "./UpdateLevels";
import AddDataHeader from "./AddDataHeader";
import DataNotFound from "./DataNotFound";
import moment from "moment";
import AuthWrapper from "../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../utils/getFilterFunction";

export default function Levels() {
  const columns = [
    { id: "sno", label: "S.No", align: "center", minWidth: 50 },
    { id: "levelname", label: "Level Name", align: "center", minWidth: 50 },
    { id: "levelcode", label: "Level Code", align: "center", minWidth: 50 },
    {
      id: "isactive",
      label: "Active",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "createat",
      label: "Create At",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: ["CONFIGURATION_LEVEL_EDIT", "CONFIGURATION_LEVEL_DELETE"],
    },
  ];
  function createData(sno, levelname, levelcode, isactive, createat, actions) {
    return {
      sno,
      levelname,
      levelcode,
      isactive,
      createat,
      actions,
    };
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: ["level_name"],
    page: page + 1,
  };

  let rows = [];
  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [AddDialog, setDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [getAllData, getAllDataInfo] = useGetAllLevelFilterMutation();
  const [deleteData, deleteDataInfo] = useDeleteCourseLevelMutation();
  const [addData, addDataInfo] = useAddCourseLevelMutation();
  const [updateLevelData, updateDataInfo] = useUpdateCourseLevelMutation();

  const AllData = getAllDataInfo.data;
  const totalItem = AllData ? AllData.totalItem : 0;
  const [render, setRender] = useState(false);

  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page]);

  useEffect(() => {
    getid && deleteData(getid) && setGetid("");
  }, [getid]);

  const AddData = (data) => {
    addData(data);
  };
  const UpdateData = (data, id) => {
    updateLevelData({ body: data, id: id });
  };
  let totalData = "ok";
  if (AllData) {
    totalData = AllData.totalItem;
    rows = AllData.data.map((data, index) =>
      createData(
        index + 1,
        data.level_name,
        data.level_code,
        data.is_active ? "Yes" : "No",
        moment(data.createdAt).format("DD-MM-YYYY hh:mm:ss A"),
        <div className="d-flex justify-content-center">
          {/* <EditDelete setViewDialog={setViewDialog}
                    type="view" setData={setData}
                    setGetid={setGetid} data={data} /> */}
          <AuthWrapper
            type="ACTION"
            name="CONFIGURATION_LEVEL_EDIT"
          >
            <EditDelete
              setEditDialog={setEditDialog}
              type="edit"
              setData={setData}
              setGetid={setGetid}
              data={data}
            />
          </AuthWrapper>
          <AuthWrapper
            type="ACTION"
            name="CONFIGURATION_LEVEL_DELETE"
          >
            <EditDelete type="delete" setGetid={setGetid} id={data._id} />
          </AuthWrapper>
        </div>
      )
    );
  }
  const dialogclose = () => {
    setEditDialog(false);
    setViewDialog(false);
    setDialog(false);
    setRender(!render);
  };
  return (
    <div>
      <AddDataHeader
        addFormName={"Add Level"}
        isSearch={
          !AuthWrapper({
            type: "ACTION",
            name: "CONFIGURATION_LEVEL_LIST",
            inBoolean: true,
          })
        }
        isPagination={
          !AuthWrapper({
            type: "ACTION",
            name: "CONFIGURATION_LEVEL_LIST",
            inBoolean: true,
          })
        }
        addBtn={
          !AuthWrapper({
            type: "ACTION",
            name: "CONFIGURATION_LEVEL_ADD",
            inBoolean: true,
          })
        }
        setPage={setPage}
        page={page}
        excelBtn={false}
        totalItem={totalItem}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setAddDialog={setDialog}
        searchBarApi={getAllData}
        paginationData={paginationData}
        isButton={true}
        Adddialog={AddDialog}
        fullWidth={true}
        maxWidth="xs"
        form={<AddLevel AddData={AddData} setDialog={setDialog} />}
      />
      <AuthWrapper type="ACTION" name="CONFIGURATION_LEVEL_LIST">
        {deleteDataInfo.isLoading ||
        addDataInfo.isLoading ||
        updateDataInfo.isLoading ? (
          <Loader />
        ) : (
          <>
            {!viewDialog ? (
              rows.length ? (
                <CreateTableAddButton
                  editDialog={editDialog}
                  rows={rows}
                  editform={
                    <UpdateLevels
                      UpdateData={UpdateData}
                      editData={data}
                      editDialog={setEditDialog}
                    />
                  }
                  columns={getFilterdColumns(columns)}
                  editFormName={"Edit Level"}
                />
              ) : totalData == 0 ? (
                <DataNotFound />
              ) : (
                <SkeletonListing />
              )
            ) : (
              <ViewCategory viewDialog={setViewDialog} data={data} />
            )}
          </>
        )}
      </AuthWrapper>
      {deleteDataInfo.data && (
        <Toster info={deleteDataInfo} dialogclose={dialogclose} />
      )}
      {addDataInfo.data && (
        <Toster info={addDataInfo} dialogclose={dialogclose} />
      )}
      {updateDataInfo && (
        <Toster info={updateDataInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
