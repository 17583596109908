import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import BlockIcon from '@material-ui/icons/Block';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { DialogContent, IconButton, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import { Fab } from '@material-ui/core';
import { Grid, Button, Dialog, DialogActions, DialogTitle, Slide, Box, Paper } from '@material-ui/core'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function  EditDelete(props) {

    const [type, setType] = React.useState(props.type)
    const [viewConfirmDeleteDialog, setViewConfirmDeleteDialog] = useState(false)

    const handleDeleteBtnClick = () => {
        props.setGetid(props.id)
        setViewConfirmDeleteDialog(false)
    }

    return (
        <>
            {
                (type === "close")
                && <IconButton onClick={props.onclick} color="primary"><CloseIcon /></IconButton>
            }

            {
                (type === "edit")
                && <IconButton onClick={() => {
                    props.setEditDialog(true)
                    props.setData(props.data)
                }} color="primary"><EditIcon fontSize={props.fontSize || ""} /></IconButton>
            }

            {
                (type === "delete")
                && <IconButton onClick={() => {

                    setViewConfirmDeleteDialog(true)
                    // props.setGetid(props.id)

                }} color="secondary"><DeleteIcon /> </IconButton>
            }

            {viewConfirmDeleteDialog &&
                // <Grid container style={{ width: "500px", position: "absolute"  ,zIndex : 100}}>

                <Dialog
                    open={viewConfirmDeleteDialog}
                    TransitionComponent={Transition}
                    // fullWidth
                    keepMounted
                    // onClose={() => { setViewConfirmDeleteDialog(false) }}
                    aria-describedby="alert-dialog-slide-description"
                >
                    {/* <DialogTitle>{"Are You Sure You Want To Delete ?"}</DialogTitle> */}
                    <DialogContent>
                        <Grid item className='text-center '>
                            <HighlightOffIcon style={{ fontSize: "75px", color: "red" }} />
                        </Grid>

                        <Grid item className='text-center text-secondary mt-4 px-5'>
                            <Typography variant='h5'> Are You Sure ?</Typography>
                        </Grid>

                        <Grid item className='text-center mt-2 mb-4 px-5'>
                            <Typography variant='h6'>  Do You Really Want To Delete ?</Typography>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Grid container spacing={1} >
                            <Grid item xs={6}>
                                <Button variant='contained' color='primary' fullWidth onClick={() => setViewConfirmDeleteDialog(false)}>Cancel</Button>

                            </Grid>
                            <Grid item xs={6}>
                                <Button variant='contained' color='secondary' fullWidth onClick={() => { handleDeleteBtnClick() }}>Yes , Delete</Button>

                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>

                // </Grid>
            }


            {
                (type === "deactivate")
                && <IconButton onClick={() => {
                    props.onclick(props.id)
                }}
                    color="secondary">
                    <BlockIcon /></IconButton>
            }

            {
                (type === "view")
                && <IconButton onClick={() => {
                    if (props.onclick != undefined && props.onclick != null) {
                        props.onclick(props.id)
                    }

                    props.setViewDialog(true)
                    props.setData(props.data)
                }} color="primary"><VisibilityIcon /></IconButton>
            }

            {
                (type === "demo")
                && <IconButton onClick={props.onclick} color="primary"><PlayCircleOutlineIcon /></IconButton>
            }

            {
                (type === "sms")
                && <IconButton onClick={() => {
                    props.setSmsDialog(true)
                    props.setData(props.data)
                }} color="primary"><MailOutlineIcon /></IconButton>
            }

            {
                (type === "active")
                && <IconButton onClick={props.onclick} color="primary"><CheckIcon /></IconButton>
            }

            {
                (type === "add")
                && <Fab color="primary" onClick={props.onclick} aria-label="add"><AddIcon /></Fab>
            }

            {
                (type === "keyaccess")
                && <IconButton onClick={() => {
                    if (props.onclick != undefined && props.onclick != null) {
                        props.onclick(props.id)
                    }

                    props?.setKeyAccessDialog(true)
                    props.setData(props.data)
                }} color="primary"><ManageAccountsIcon /></IconButton>
            }
        </>
    )
}
