import React, { useState, useEffect } from "react";
import DateFilter from "./DateFilter";
import SelectFilters from "./SelectFilters";
import {
  useGetAallFacultieNamesQuery,
  useGetAllSubjectNamesQuery,
  useGetAllCategoriesQuery,
  useGetAllCoursesQuery,
  useGetAllLevelNamesQuery,
  useGetallInstituteNamesQuery,
  useGetAllCourseWithLevelQuery,
  getVideoLectureSubjectAndFaculty,
  useGetVideoLectureSubjectAndFacultyMutation,
} from "../../../services/services";
import { Button, FormControl, makeStyles } from "@material-ui/core";
import AutocompleteFilter from "./filter/AutocompleteFilter";
import { date } from "yup";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MultiSelectFilter from "./filter/SelectFilter";
import { Grid } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    minWidth: 100,
  },
  tab: {
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function Filters({
  filterListingData,
  setFilteredPagination,
  module,
  //video filters
  isCourseFilter,
  isModeFilter,
  isLevelFilter,
  isSubFilter,
  isCategoryFilter,
  isFacFilter,
  isLangFilter,
  isRatingFilter,
  //faculty filters
  isGradeFilter,
  isRankFilter,
  isSpecializationFilter,
  isInstituteFilter,
  //order request filters
  isOrderRequestStatusFilter,
  //other filter
  isFromFilter,
  isToFilter,
  isOrderStatus,
  isGroupFilter,
  isModuleFilter,
  isSyllabusFilter,
  isActiveFilter,
}) {
  //video filters data
  const getAallFaculties = useGetAallFacultieNamesQuery();
  const getAllCourseWithLevel = useGetAllCourseWithLevelQuery();
  const [getAllSubjectAndFaculty, getAllSubjectAndFacultyInfo] =
    useGetVideoLectureSubjectAndFacultyMutation();
  let allFaculties = getAallFaculties.data;

  // const allCourses = getAllCourses.data
  const allCourses = getAllCourseWithLevel.data;
  // let allLevels =
  const allLang = { data: [{ name: "HINDI" }, { name: "ENGLISH" }] };
  const allRatings = {
    data: [{ name: 5 }, { name: 4 }, { name: 3 }, { name: 2 }, { name: 1 }],
  };

  //faculty filters data
  const getAllInstitute = useGetallInstituteNamesQuery();
  const allInstitute = getAllInstitute.data;
  const allSpecialization =
    module == "faculty"
      ? { data: filterListingData.allSpecialization }
      : { data: [] };
  const allGrade = {
    data: [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ],
  };
  //order request filter data
  const orderRequestStatusList =
    module == "orderRequest"
      ? { data: filterListingData.orderRequestStatusList }
      : { data: [] };
  //video filters states
  const [course, setCourse] = useState([]);
  const [allLevels, setallLevels] = useState({ data: [] });
  const [allSubjects, setallSubjects] = useState({ data: [] });
  const [allFaculty, setallFaculty] = useState(allFaculties);
  // const [allGroupModuleOptions, setAllGroupModuleOptions] = useState({
  //   data: [],
  // });
  const [allGroupOptions, setAllGroupOptions] = useState({
    data: [],
  });
  const [allModuleOptions, setAllModuleOptions] = useState({
    data: [],
  });
  const [allSyllabusOptions, setAllSyllabusOptions] = useState({
    data: [],
  });

  const [allFacultyFilter, setallFacultyFilter] = useState({ data: [] });
  const [allCategories, setallCategories] = useState({ data: [] });
  const [allModes, setallModes] = useState({ data: [] });

  const [level, setLevel] = useState([]);
  const [subject, setSubject] = useState([]);
  const [category, setCategory] = useState([]);
  const [mode, setMode] = useState([]);
  const [faculty, setFaculty] = useState([]);
  const [lectureLanguages, setLectureLanguages] = useState([]);
  const [rating, setRating] = useState([]);
  //faculty filters states
  const [specialization, setSpecialization] = useState([]);
  const [grade, setGrade] = useState([]);
  const [rank, setRank] = useState([]);
  const [institute, setInstitute] = useState([]);
  const [groups, setGroups] = useState([]);
  const [modules, setModules] = useState([]);
  const [syllabus, setSyllabus] = useState([]);
  const [active, setActive] = useState([]);
  //order request states
  const [orderRequestStatus, setOrderRequestStatus] = useState([]);
  //otehr filters states
  const [orderStatus, setOrderStatus] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    if (!getAllCourseWithLevel.isSuccess && getAllCourseWithLevel.isFetching) {
      setallFaculty(getAallFaculties.data);
    }
  }, [getAllCourseWithLevel.isSuccess, getAllCourseWithLevel.isFetching]);

  let satusFields = ["SHIPPED", "CANCEL", "PANDING", "AWAITING"];

  useEffect(() => {
    setFilteredPagination(getModuleWiseFilterData(module));
  }, [
    course,
    mode,
    level,
    subject,
    category,
    faculty,
    lectureLanguages,
    rating,
    specialization,
    grade,
    rank,
    institute,
    groups,
    modules,
    syllabus,
    active,
    orderStatus,
    fromDate,
    toDate,
    orderRequestStatus,
  ]);

  useEffect(() => {
    if (subject.length) {
      const finalFaculty = allFacultyFilter.data.filter(
        ({ faculty_subjects }) => {
          if (faculty_subjects?.length) {
            return faculty_subjects.some(
              ({ subject_code }) =>
                subject.length && subject.includes(subject_code)
            );
          }
        }
      );

      setallFaculty({ data: finalFaculty });
    }
  }, [subject]);

  useEffect(() => {
    if (allCourses?.data && course.length > 0) {
      let allLevelsData = allCourses?.data?.find(
        (ele) => course[0] == ele.course_code
      );

      if (allLevelsData) {
        let element = allLevelsData?.level;

        if (element.length) {
          setallLevels({ data: element?.flat(1) });
        }
      }
    }
  }, [course]);

  useEffect(() => {
    getAllSubjectAndFaculty({ course_code: "", level_code: "" }).then(
      (response) => {
        setAllGroupOptions({
          data: response.data?.data?.groups,
        });
        setAllModuleOptions({
          data: response.data?.data?.modules,
        });
        setAllSyllabusOptions({ data: response.data?.data?.syllabus });
      }
    );
  }, []);

  useEffect(() => {
    if (course.length > 0 && level.length > 0) {
      getAllSubjectAndFaculty({ course_code: course[0], level_code: level[0] });
    }
  }, [level]);

  useEffect(() => {
    if (getAllSubjectAndFacultyInfo.isSuccess) {
      let subAndFaculty = getAllSubjectAndFacultyInfo.data?.data;

      if (getAllSubjectAndFacultyInfo.data.status) {
        setallSubjects({ data: subAndFaculty.subject });
        setallFacultyFilter({ data: subAndFaculty.faculty });
        setallCategories({ data: subAndFaculty.category });
        setallModes({
          data: subAndFaculty.modes?.map((el) => {
            return {
              label: el,
              value: el,
            };
          }),
        });
      }
    }
  }, [
    getAllSubjectAndFacultyInfo.isLoading,
    getAllSubjectAndFacultyInfo.isSuccess,
  ]);

  const getModuleWiseFilterData = (module) => {
    let filterData = [];
    switch (module) {
      case "faculty":
        filterData = [
          {
            fieldName: "name",
            value: faculty,
          },
          {
            fieldName: "specialization",
            value: specialization,
          },
          {
            fieldName: "faculty_grade",
            value: grade,
          },

          {
            fieldName: "faculty_rank",
            value: rank
              .filter((e, i) => {
                return e;
              })
              .map((e, i) => {
                return parseInt(e);
              }),
          },
          {
            fieldName: "institute.institute_name",
            value: institute,
          },
        ];
        break;
      case "videoLecture":
        filterData = [
          { fieldName: "course_code", value: course },
          { fieldName: "level_code", value: level },
          {
            fieldName: "varient.mode_name",
            value: mode,
          },
          {
            fieldName: "subject",
            value: subject,
          },
          { fieldName: "category", value: category },
          {
            fieldName: "faculty",
            value: faculty,
          },
          { fieldName: "lecture_languages", value: lectureLanguages },
          { fieldName: "rating", value: rating },
          { fieldName: "group_or_module", value: [...groups, ...modules] },
          { fieldName: "syllabus", value: syllabus },
        ];

        if (active.length) {
          filterData = [
            ...filterData,
            {
              fieldName: "is_active",
              value: active[0] === "YES" ? true : false,
            },
          ];
        }
        break;
      case "videoOrder":
        filterData = [{ fieldName: "current_status", value: orderStatus }];
        break;
      case "orderRequest":
        filterData = [
          { fieldName: "STATUS", value: orderRequestStatus },
          { fieldName: "startDate", value: fromDate },
          { fieldName: "endDate", value: toDate },
        ];
        break;
      default:
        break;
    }
    return filterData;
  };

  const resetBtnHandler = () => {
    setCourse([]);
    setLevel([]);
    setSubject([]);
    setFaculty([]);
    setCategory([]);
    setLectureLanguages([]);
    setRating([]);
    setSpecialization([]);
    setGrade([]);
    setRank([]);
    setInstitute([]);
    setGroups([]);
    setModules([]);
    setSyllabus([]);
    setActive([]);
    setFromDate();
    setToDate();
    setOrderStatus();
    setOrderRequestStatus([]);
    setIsReset(true);
  };

  const setCourseHandle = (e) => {
    let couseValue = [...course];

    if (couseValue.length > 0) {
      couseValue.pop();
      couseValue.push(e.target.value);
    } else {
      couseValue.push(e.target.value);
    }

    setCourse(couseValue);
  };

  const setLevelHandle = (e) => {
    let levelValue = [...level];

    if (levelValue.length > 0) {
      levelValue.pop();
      levelValue.push(e.target.value);
    } else {
      levelValue.push(e.target.value);
    }

    setLevel(levelValue);
  };

  return (
    <>
      <Grid container spacing={2}>
        {/* video filter */}
        {isCourseFilter && (
          <Grid item>
            <MultiSelectFilter
              listData={allCourses}
              valueKey={"course_code"}
              valueShow={course}
              setFilterData={setCourse}
              showKey={"course_code"}
              name={"course"}
            />
          </Grid>
        )}

        {isLevelFilter && (
          <Grid item>
            <MultiSelectFilter
              listData={allLevels}
              valueShow={level}
              valueKey={"level_code"}
              setFilterData={setLevel}
              showKey={"level_name"}
              name={"level"}
            ></MultiSelectFilter>
          </Grid>
        )}

        {isSubFilter && (
          <Grid item>
            <AutocompleteFilter
              filterValue={subject}
              setFilterData={setSubject}
              name={"Subject"}
              listData={allSubjects}
              listDataType="object"
              showKey={"subject_name"}
              valueKey={"subject_code"}
              multiple={true}
              isReset={isReset}
              setIsReset={setIsReset}
            />
          </Grid>
        )}

        {isCategoryFilter && (
          <Grid item>
            <AutocompleteFilter
              filterValue={category}
              setFilterData={setCategory}
              name={"Category"}
              listData={allCategories}
              listDataType="object"
              showKey={"category_name"}
              valueKey={"category_code"}
              multiple={true}
              isReset={isReset}
              setIsReset={setIsReset}
            />
          </Grid>
        )}

        {isFacFilter && (
          <Grid item>
            <AutocompleteFilter
              setFilterData={setFaculty}
              name={"Faculty"}
              listData={allFaculty}
              listDataType="object"
              showKey={"faculty_name"}
              valueKey={"faculty_name"}
              multiple={true}
              isReset={isReset}
              setIsReset={setIsReset}
            />
          </Grid>
        )}

        {isLangFilter && (
          <Grid item>
            <AutocompleteFilter
              setFilterData={setLectureLanguages}
              name={"Language"}
              listData={allLang}
              listDataType="object"
              showKey={"name"}
              valueKey={"name"}
              multiple={true}
              isReset={isReset}
              setIsReset={setIsReset}
            />
          </Grid>
        )}

        {isModeFilter && (
          <Grid item>
            <AutocompleteFilter
              filterValue={mode}
              setFilterData={setMode}
              name={"Modes"}
              listData={allModes}
              listDataType="object"
              showKey={"label"}
              valueKey={"value"}
              multiple={true}
              isReset={isReset}
              setIsReset={setIsReset}
            />
          </Grid>
        )}

        {isRatingFilter && (
          <Grid item>
            <AutocompleteFilter
              setFilterData={setRating}
              name={"Rating"}
              listData={allRatings}
              listDataType="object"
              showKey={"name"}
              valueKey={"name"}
              multiple={true}
              isReset={isReset}
              setIsReset={setIsReset}
            />
          </Grid>
        )}

        {isGroupFilter && (
          <Grid item>
            <AutocompleteFilter
              filterValue={groups}
              setFilterData={setGroups}
              name={"Group"}
              listData={allGroupOptions}
              listDataType="string"
              isReset={isReset}
              setIsReset={setIsReset}
              multiple={true}
            />
          </Grid>
        )}

        {isModuleFilter && (
          <Grid item>
            <AutocompleteFilter
              filterValue={modules}
              setFilterData={setModules}
              name={"Module"}
              listData={allModuleOptions}
              listDataType="string"
              isReset={isReset}
              setIsReset={setIsReset}
              multiple={true}
            />
          </Grid>
        )}

        {isSyllabusFilter && (
          <Grid item>
            <AutocompleteFilter
              filterValue={syllabus}
              setFilterData={setSyllabus}
              name={"Syllabus"}
              listData={allSyllabusOptions}
              listDataType="string"
              isReset={isReset}
              setIsReset={setIsReset}
              multiple={true}
            />
          </Grid>
        )}

        {isActiveFilter && (
          <Grid item>
            <AutocompleteFilter
              filterValue={active}
              setFilterData={setActive}
              name={"Active"}
              listData={{ data: ["YES", "NO"] }}
              listDataType="string"
              isReset={isReset}
              setIsReset={setIsReset}
            />
          </Grid>
        )}

        {/* faculty filter */}
        {isSpecializationFilter && (
          <Grid item>
            <AutocompleteFilter
              setFilterData={setSpecialization}
              name={"Specialization"}
              listData={allSpecialization}
              listDataType="string"
              multiple={true}
              isReset={isReset}
              setIsReset={setIsReset}
            />
          </Grid>
        )}

        {isGradeFilter && (
          <Grid item>
            <AutocompleteFilter
              setFilterData={setGrade}
              name={"Grade"}
              listData={allGrade}
              listDataType="string"
              multiple={true}
              isReset={isReset}
              setIsReset={setIsReset}
            />
          </Grid>
        )}

        {isRankFilter && (
          <Grid item>
            <AutocompleteFilter
              setFilterData={setRank}
              name={"Rank"}
              listDataType="number"
              multiple={true}
              isReset={isReset}
              setIsReset={setIsReset}
            />
          </Grid>
        )}

        {isInstituteFilter && (
          <Grid item>
            <AutocompleteFilter
              setFilterData={setInstitute}
              name={"Institute"}
              listData={allInstitute}
              listDataType="object"
              showKey={"name"}
              valueKey={"name"}
              multiple={true}
              isReset={isReset}
              setIsReset={setIsReset}
            />
          </Grid>
        )}

        {/* faculty filter */}

        {isFromFilter && (
          <Grid item>
            <DateFilter
              setFilterData={setFromDate}
              filterData={fromDate}
              name={"From"}
            />
          </Grid>
        )}

        {isToFilter && (
          <Grid item>
            <DateFilter
              setFilterData={setToDate}
              filterData={toDate}
              name={"To"}
            />
          </Grid>
        )}

        {isOrderStatus && (
          <Grid item>
            <SelectFilters
              setFilterData={setOrderStatus}
              fields={satusFields}
              name={"Status"}
            />
          </Grid>
        )}

        {isOrderRequestStatusFilter && (
          <Grid item>
            <AutocompleteFilter
              setFilterData={setOrderRequestStatus}
              name={"Request Status"}
              listData={orderRequestStatusList}
              listDataType="string"
              multiple={true}
              isReset={isReset}
              setIsReset={setIsReset}
            />
          </Grid>
        )}

        <Grid container item xs={12} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={() => resetBtnHandler()}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
