import React from 'react'

export default function DataNotFound() {
    return (
        <div style={{height:"100vh"}} className=" d-flex justify-content-center align-items-center">
            <img
                src="assets/images/data_not_found-.png"
                alt=""
                className="img-fluid"
            />
        </div>
    )
}
