import React, { useState, useEffect } from "react";
import ViewBook from "./ViewBook";
import {
  useGetAllOrdersVideoLecturesQuery,
} from "../../../services/services";
import SkeletonListing from "./SkeletonListing";
import OrderListing from "./OrderListing";
import DataNotFound from "./DataNotFound";
import DataTableHeader from "./reusableComponents/DataTableHeader";
import {
  Button,
  Grid,
  Tooltip,
  Chip,
  TextField,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import { useGetOrderSheetMutation } from "../../../services/pyhtonApiSeevices";
import Toster from "./Toster";
import TransferList from "../../common/TransferList/TransferList";
import { PYTHON_URL } from "../../../constant/BaseURL";
import AuthWrapper from "../../../utils/AuthWrapper";
import getCurrentUrl from "../../../utils/getAuthName";
const BASE_URL = PYTHON_URL;
//TOKEN
const token = localStorage.getItem("__authToken__");
const userType= localStorage.getItem('authType');
// Field Filter Options
const filteredObjects = [
  "SR",
  "ORDER DATE",
  "ORDER TIME",
  "ORDER CODE",
  "STUDENT NAME",
  "ST MOBILE NO",
  "ST ALT MOBILE NO",
  "E-MAIL ID",
  "ADDRESS",
  "CITY",
  "STATE",
  "PINCODE",
  "GST STATE CODE (CONDITION)",
  "STUDENT ORGANISATION NAME",
  "STUDENT ORGANISATION GSTIN NO",
  "STUDENT ORGANISATION ADDRESS",
  "STUDENT ORGANISATION STATE",
  "STUDENT ORGANISATION GST STATE CODE",
  "STUDENT ORGANISATION MOBILE NO",
  "STUDENT ORGANISATION EMAIL",
  "STUDENT ORGANISATION CITY",
  "STUDENT ORGANISATION PINCODE",
  "PRODUCT TITLE",
  "MODE (GD/PD)",
  "VALIDITY",
  "VIEWS",
  "BATCH RECORDING",
  "ATTEMPT",
  "SHIPPING METHOD",
  "COURIER CHARGES REC. FROM STUDENT",
  "MRP",
  "DISCOUNT BY FACULTY",
  "DISCOUNT BY US",
  "DISCOUNT BY COUPON CODE / WALLET / REFERAL FOR US",
  "CASHBACK AMOUNT",
  "CASHBACK APPROVED BY",
  "CASHBACK PAYMENT DATE",
  "CASHBACK TRANSECTION ID",
  "DISCOUNT BY US IN PERCENT",
  "PROMO CODE MADE BY WHICH CALLER",
  "AMOUNT REC INDIVIDUAL ORDER WISE",
  "TOTAL AMOUNT REC",
  "ADVANCE RECEIVED FROM STUDENT",
  "PAYMENT REC IN",
  "PAYMENT GATEWAY",
  "EASEBUS",
  "PAYUMONEY",
  "REFUND",
  "REFUND APPROVED BY",
  "REFUND PAYMENT DATE",
  "REFUND TRANSECTION ID",
  "COURIER TRACKING ID AND COMPANY NAME / LINK",
  "TRANSACTION ID",
  "AMOUNT DUE FROM STUDENT",
  "PRICE ON WHICH COMMISSION IS REC",
  "COMMISSION RATE",
  "COMMISSION IN AMT WHEN IT IS FIXED AMT WISE",
  "COMMISSION AMT",
  "PAYMENT TO FACULTY (ORDER WISE)",
  "FACULTY NAME",
  "FACULTY INSTITUTE NAME (PRODUCT WISE KISKO JAYEGA)",
  "GSTIN NO",
  "INSTITUTE / FACULTY STATE CODE",
  "GST PERCENT",
  "BOOK PERCENT",
  "INVOICE DATE",
  "INVOICE NO",
  "COURIER CHARGES PAID",
  "ANY OTHER EXPS",
  "PROFIT",
  "IGST AMOUNT",
  "SGST AMOUNT",
  "CGST AMOUNT",
  "WITHIN STATE SALE (INCL GST)",
  "OUTSIDE STATE SALE (INCL GST)",
  "NET TOTAL SALE (INCL GST)",
  "NET TOTAL SALE WITH IN STATE (EXCL GST)",
  "NET TOTAL SALE OUTSIDE STATE (EXCL GST)",
  "ORDER BY FRANCHISEE",
  "ORDER FROM ONLINE / OFFLINE",
  "REMARKS FOR MANAGEMENT",
  "ORDER STATUS",
  "WITHDRAWAL AMOUNT",
  "WITHDRAWAL REASON",
  "DEPOSIT AMOUNT",
  "DEPOSIT REASON",
  "INVOICE CREATED BY",
  "BOOKS ISSUED",
  "REMARK FOR STUDENT",
  "QUANTITY",
];


//  Menu Props for select
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

export default function VideoLectureOrders({ inWallet, orderStatus }) {
  const currentPath = window.location.pathname;
  const accessModuleOptions = localStorage.getItem("accessModules")
    ? JSON.parse(localStorage.getItem("accessModules"))
    : [];

  const actionId = getCurrentUrl(
    [
      {
        url: "/orders",
        name: "SUCCESSFULL_ORDERS_ORDER_DOWNLOAD_EXCEL",
      },
      {
        url: "/pending-orders",
        name: "PROCESSING_ORDERS_ORDER_DOWNLOAD_EXCEL",
      },
      {
        url: "/failed-orders",
        name: "PAYMENT_PENDING_ORDERS_ORDER_DOWNLOAD_EXCEL",
      },
      {
        url: "/cancelorders",
        name: "CANCEL_ORDERS_ORDERS_DOWNLOAD_EXCEL",
      },
    ],
    currentPath
  );

  

  let now = new Date();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [render, setRender] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [endDateRange, setEndDateRange] = useState(null);
  const [startDateRange, setStartDateRange] = useState(null);
  const [dates, setDates] = useState([]);
  const [excelDialog, setExcelDialog] = useState(false);
  const [excelFilterDates, setExcelFilterDates] = useState({
    from: moment(now).format("YYYY-MM-DD"),
    to: moment(now).format("YYYY-MM-DD"),
  });
  const [excelFields, setExcelFields] = useState([]);
  const [left, setLeft] = React.useState(filteredObjects);
  const [right, setRight] = React.useState([]);
  const [excelFilterDatesErrors, setExcelFilterDatesErrors] = useState({
    from: "",
    to: "",
  });
  const [getOrderReport, getOrderReportInfo] = useGetOrderSheetMutation();
  const [filteredPagination, setFilteredPagination] = useState([
    {
      fieldName: "current_status",
      value: orderStatus,
    }, 
     {
      fieldName: "order_type",
      value: "ONLINE_WEB",
    },
  ]);
  const [AddDialog, setDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  
  const formattedFilter = [
    {
      fieldName: "current_status",
      value: orderStatus?.join(","),
    },
  ];
  let paginationData = {
    limit: rowsPerPage,
    query: searchValue,
    params: [
      "order_title",
      "_id",
      // "sld_order_id"
    ],
    filterBy: filteredPagination,
    page: page + 1,
  };
  
  
  const [pagination, setPagination] = useState(paginationData);
  const {
    data: AllData,
    isFetching,
    isLoading,
  } = useGetAllOrdersVideoLecturesQuery(pagination);
  let rows = [];
  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");

  useEffect(() => {
    setPagination(paginationData);
  }, [
    searchValue,
    page,
    rowsPerPage,
    startDateRange,
    endDateRange,
    filteredPagination,
  ]);

  useEffect(() => { 
    if(userType !== 'ADMIN'){
    const filteredObjectsOrderList = accessModuleOptions
      ?.find((moduleOption) => moduleOption?.actionId === actionId)
      ?.fields?.reduce((acc, fields) => {
        acc.push(fields?.fieldName);
        return acc;
      }, []);
    setLeft(filteredObjectsOrderList);
    setExcelFields(filteredObjectsOrderList)
    }else{
      setLeft(filteredObjects);
      setExcelFields(filteredObjects)
    }
  },[]);

  

  const totalItem = AllData ? AllData.totalItem : 0;
  // useEffect(() => {
  //   getAllData()
  // }, [render, rowsPerPage, page, filteredPagination])
  let totalData = "ok";
  if (AllData) {
    totalData = AllData?.totalItem;
    rows = AllData?.data;
  }

  const handleExcelDownload = () => {
    let isValid = true;
    if (
      moment(excelFilterDates.from).isAfter(moment(now).format("YYYY-MM-DD"))
    ) {
      setExcelFilterDatesErrors({
        ...excelFilterDatesErrors,
        from: "Invalid Date",
      });
      isValid = false;
    }
    if (moment(excelFilterDates.to).isAfter(moment(now).format("YYYY-MM-DD"))) {
      setExcelFilterDatesErrors({
        ...excelFilterDatesErrors,
        to: "Invalid Date",
      });
      isValid = false;
    }
    if (moment(excelFilterDates.from).isAfter(excelFilterDates.to)) {
      setExcelFilterDatesErrors({
        ...excelFilterDatesErrors,
        from: "Invalid Date",
      });
      isValid = false;
    }
    if (isValid) {
      let url = `${BASE_URL}get-order-sheet?token=${token}&start_date=${
        excelFilterDates.from
      }&end_date=${excelFilterDates.to}&filter_by=${JSON.stringify(
        formattedFilter
      )}&columns=${right}`;

      let link = document.createElement("a");
      link.href = url;
      link.target = "blank";
      document.body.appendChild(link);
      link.click();
      setExcelDialog(false);
      setRight([]);
      
      setLeft(excelFields);
    }
  };

  return (
    <div>
      {/* <AddDataHeader
        addFormName={"Add Order"}
        setPage={setPage}
        page={page}
        currentPageName="videoLectureOrder"
        excelBtn={true}
        dwnldexcel={false}
        totalItem={totalItem}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setAddDialog={setDialog}
        searchBarApi={getAllData}
        paginationData={paginationData}
        isButton={true}
        Adddialog={AddDialog}
        form={<AddOrder AddData={AddData} setDialog={setDialog} />}
      /> */}

      {/* <Accordion className=''>
        <AccordionSummary className='text-primary  '
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header" >
          <Typography className='d-flex align-items-center ' ><FilterListIcon /> <span > Filter</span></Typography>
        </AccordionSummary>
        <AccordionDetails className=' d-flex justify-content-between'>
          <Filters
            setFilteredPagination={setFilteredPagination}
            // isSubFilter={false}
            // isFacFilter={false}
            // isSyllabusFilter={false}
            // isFromFilter={true}
            // isToFilter={true}
            // isOrderStatus={true}
          />
        </AccordionDetails>
      </Accordion> */}
      <Grid container>
        <AuthWrapper
          type="ACTION"
          name={getCurrentUrl(
            [
              {
                url: "/orders",
                name: "SUCCESSFULL_ORDERS_ORDER_DOWNLOAD_EXCEL",
              },
              {
                url: "/pending-orders",
                name: "PROCESSING_ORDERS_ORDER_DOWNLOAD_EXCEL",
              },
              {
                url: "/failed-orders",
                name: "PAYMENT_PENDING_ORDERS_ORDER_DOWNLOAD_EXCEL",
              },
              {
                url: "/cancelorders",
                name: "CANCEL_ORDERS_ORDERS_DOWNLOAD_EXCEL",
              },
            ],
            currentPath
          )}
        >
          <Grid item xs={1} className="m-auto text-center">
            <Tooltip
              placement="bottom-end"
              title="Download Excel"
              arrow
              onClick={() => {
                setExcelFilterDates({
                  from: moment(now).format("YYYY-MM-DD"),
                  to: moment(now).format("YYYY-MM-DD"),
                });
                setExcelFilterDatesErrors({ from: "", to: "" });
                setExcelDialog(true);
              }}
            >
              <Chip
                label={
                  <span className="d-flex justify-content-center align-items-center">
                    <ArrowDownwardIcon fontSize="small" />
                    <span> Excel</span>
                  </span>
                }
                color="primary"
                variant="outlined"
                clickable
              />
            </Tooltip>
          </Grid>
        </AuthWrapper>
        <Grid item xs={11}>
          <DataTableHeader
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            totalItem={totalItem}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            endDateRange={endDateRange}
            startDateRange={startDateRange}
            dates={dates}
            setDates={setDates}
            isPagination={
              !AuthWrapper({
                type: "ACTION",
                name: getCurrentUrl(
                  [
                    {
                      url: "/orders",
                      name: "SUCCESSFULL_ORDERS_ORDER_LIST",
                    },
                    {
                      url: "/pending-orders",
                      name: "PROCESSING_ORDERS_ORDER_LIST",
                    },
                    {
                      url: "/failed-orders",
                      name: "PAYMENT_PENDING_ORDERS_ORDER_LIST",
                    },
                    {
                      url: "/cancelorders",
                      name: "CANCEL_ORDERS_ORDER_LIST",
                    },
                  ],
                  currentPath
                ),
                inBoolean: true,
              })
            }
            isSearch={
              !AuthWrapper({
                type: "ACTION",
                name: getCurrentUrl(
                  [
                    {
                      url: "/orders",
                      name: "SUCCESSFULL_ORDERS_ORDER_LIST",
                    },
                    {
                      url: "/pending-orders",
                      name: "PROCESSING_ORDERS_ORDER_LIST",
                    },
                    {
                      url: "/failed-orders",
                      name: "PAYMENT_PENDING_ORDERS_ORDER_LIST",
                    },
                    {
                      url: "/cancelorders",
                      name: "CANCEL_ORDERS_ORDER_LIST",
                    },
                  ],
                  currentPath
                ),
                inBoolean: true,
              })
            }
            setStartDateRange={setStartDateRange}
            setEndDateRange={setEndDateRange}
          />
        </Grid>
      </Grid>

      {/* <Accordion className=''>
          <AccordionSummary className='text-primary'
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header" >
              <Typography className='d-flex align-items-center' ><FilterListIcon /> <span > Filter</span></Typography>
          </AccordionSummary>
          <AccordionDetails className=''>
              <Filters
                  setFilteredPagination={setFilteredPagination}
                  isFacFilter={true}
                  module="videoOrder"
              />
          </AccordionDetails>
      </Accordion> */}
      <AuthWrapper
        type="ACTION"
        name={getCurrentUrl(
          [
            {
              url: "/orders",
              name: "SUCCESSFULL_ORDERS_ORDER_LIST",
            },
            {
              url: "/pending-orders",
              name: "PROCESSING_ORDERS_ORDER_LIST",
            },
            {
              url: "/failed-orders",
              name: "PAYMENT_PENDING_ORDERS_ORDER_LIST",
            },
            {
              url: "/cancelorders",
              name: "CANCEL_ORDERS_ORDER_LIST",
            },
          ],
          currentPath
        )}
      >
        {/* <br /> */}
        {!viewDialog ? (
          rows?.length ? (
            rows.map((dataEle, eleInd) => {
              return <OrderListing inWallet={inWallet} data={dataEle} />;
            })
          ) : totalData == 0 ? (
            <DataNotFound />
          ) : (
            <SkeletonListing />
          )
        ) : (
          <ViewBook viewDialog={setViewDialog} data={data} />
        )}
      </AuthWrapper>

      {getOrderReportInfo && <Toster info={getOrderReportInfo} />}

      <Dialog
        open={excelDialog}
        size="large"
        onClose={() => setExcelDialog(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Select Dates for Excel Sheet</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={5}>
              <Grid xs={6} item>
                <TextField
                  type="date"
                  fullWidth
                  onChange={(e) => {
                    setExcelFilterDates({
                      ...excelFilterDates,
                      from: e.target.value,
                    });
                  }}
                  name="from"
                  value={excelFilterDates.from}
                  label="From Date"
                  variant="outlined"
                />
                <span style={{ fontSize: "12px" }} className="text-danger">
                  {excelFilterDatesErrors.from}
                </span>
              </Grid>
              <Grid xs={6} item>
                <TextField
                  type="date"
                  fullWidth
                  onChange={(e) => {
                    setExcelFilterDates({
                      ...excelFilterDates,
                      to: e.target.value,
                    });
                  }}
                  name="to"
                  value={excelFilterDates.to}
                  label="To Date"
                  variant="outlined"
                />
                <span style={{ fontSize: "12px" }} className="text-danger">
                  {excelFilterDatesErrors.to}
                </span>
              </Grid>

              <Grid xs={12} item>
                {/* <FormControl sx={{ mt: 4, width: '100%' }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Fields
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={excelFields}
                    onChange={(e) => {
                      setExcelFields(e.target.value);
                    }}
                    input={<OutlinedInput label="Fields" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                    sx={{ width: "100%" }}
                  >
                    {filteredObjects.map((field) => (
                      <MenuItem key={field} value={field}>
                        <Checkbox
                          checked={excelFields.indexOf(field) > -1}
                        />
                        <ListItemText primary={field} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}

                <TransferList
                  left={left}
                  setLeft={setLeft}
                  right={right}
                  setRight={setRight}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="p-3">
          <Button
            className="bg-danger text-light"
            onClick={() => {
              setExcelDialog(false);
              setRight([]);
              setLeft(excelFields);
            }}
          >
            Close
          </Button>
          <Button
            className="bg-success text-light"
            onClick={() => handleExcelDownload()}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
