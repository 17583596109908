import React, { useState, useEffect } from "react";
import {
  makeStyles,
  TextField,
  FormControl,
  InputLabel,
  Grid,
  Select,
  MenuItem,
  Button,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from "@material-ui/core";
import BackupIcon from "@material-ui/icons/Backup";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  useGetallInstituteNamesQuery,
  useGetAllStatesQuery,
} from "../../../../services/services";
import { phoneRegex } from "../../../../constant/regularExpression";
import Autocomplete from "@mui/material/Autocomplete";

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    minWidth: 100,
  },
  tab: {
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  cursorPointer: {
    cursor: "pointer",
  },
}));
export default function AddFaculty({ setDialog, AddData, allSpecialization }) {
  const classes = useStyles();
  const { data, isSuccess } = useGetallInstituteNamesQuery();
  const getstates = useGetAllStatesQuery();
  let allStates = getstates.data;
  if (isSuccess) {
    var institutedata = data.data;
  }
  const [imageval, setImageVal] = useState("");
  const [facultyGrades, setFacultyGrades] = useState([
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ]);
  const [specialization, setSpecialization] = useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      payment_method: "",
      bookMetaData: "",
      bookMetaTitle: "",
      bookKeywords: "",
      testSeriesMetaData: "",
      testSeriesMetaTitle: "",
      testSeriesKeywords: "",
      description: "",
      faculty_rank: "",
      gst_number: "",
      // bank_ac_number: "",
      management_number: "",
      personal_number: "",
      remark: "",
      keywords: "",
      meta: "",
      institute_id: "",
      title: "",
      email: "",
      faculty_grade: "",
      faculty_grade_basis: "",
      joining_date: "",
      image: "",
      // portal_details: "",
      // portal_link: ""
      specialization: "",
    },
    validationSchema: yup.object({
      title: yup.string(),
      name: yup.string().required("This field is required !"),
      personal_number: yup
        .string()
        .matches(phoneRegex, "Invalid Mobile Number")
        .required("Mobile Number is required"),
      faculty_rank: yup
        .number()
        .min(1, "Rank must be greater than 0 ")
        .required("This field is required"),
      payment_method: yup.string().required("This field is required !"),
      description: yup.string(),
      // bank_ac_number: yup.number().required("This field is required !"),
      management_number: yup
        .string()
        .matches(phoneRegex, "Invalid Mobile Number"),
      gst_number: yup.string().required("This field is required !"),
      meta: yup.string(),
      image: yup.object().required("This field is required !"),
      remark: yup.string(),
      // email: yup.string().email('Invalid email !').max(255).required("This field is required !"),
      faculty_grade: yup.string().required("This field is required !"),
      faculty_grade_basis: yup.string().required("This field is required !"),
      joining_date: yup.string().required("This field is required !"),
      specialization: yup.string(),
      // portal_details: yup.string().required("This field is required !"),
      // portal_link: yup.string().required("This field is required !"),
    }),
    onSubmit: (values, e) => {
      values.image = imageval;
      var form_data = new FormData();
      for (let key in values) {
        form_data.append(key, values[key]);
      }
      AddData(form_data);
    },
  });

  return (
    <>
      <form id="addfacultyform" onSubmit={formik.handleSubmit}>
        <Grid
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 20,
            marginLeft: 10,
          }}
        >
          <Typography
            className={classes.title}
            variant="h6"
            color="primary"
            gutterBottom
          >
            Personal Details
          </Typography>
          <Grid container spacing={3}>
            {/* Faculty Name */}
            <Grid item xs={4}>
              <TextField
                name="name"
                label="Faculty Name "
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                fullWidth={true}
                variant="outlined"
                size="medium"
              />
              {formik.touched.name && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.name}
                </span>
              )}
            </Grid>

            {/* Personal Number */}
            <Grid item xs={4}>
              <TextField
                name="personal_number"
                label="Personal Number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.personal_number}
                fullWidth={true}
                type="number"
                variant="outlined"
                size="medium"
              />
              {formik.touched.personal_number && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.personal_number}
                </span>
              )}
            </Grid>

            {/* Faculty Email */}
            <Grid item xs={4}>
              <TextField
                multiple
                name="email"
                label="Faculty Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                fullWidth={true}
                type="text"
                variant="outlined"
                size="medium"
              />
              {formik.touched.email && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.email}
                </span>
              )}
            </Grid>

            {/* Payment Method */}
            <Grid item xs={4}>
              <TextField
                name="payment_method"
                label="Payment Method"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.payment_method}
                fullWidth={true}
                variant="outlined"
                size="medium"
              />
              {formik.touched.payment_method && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.payment_method}
                </span>
              )}
            </Grid>

            {/* Rank */}
            <Grid item xs={4}>
              <TextField
                name="faculty_rank"
                type="number"
                label="Rank"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.faculty_rank}
                fullWidth={true}
                variant="outlined"
                size="medium"
              />
              {formik.touched.faculty_rank && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.faculty_rank}
                </span>
              )}
            </Grid>

            {/* Image */}
            <Grid item xs={4}>
              <Button
                className="py-3"
                onChange={(e) => {
                  formik.setFieldValue("image", {
                    // fieldname: "image",
                    name: e.target.files[0].name,
                    // size: e.target.files[0].size,
                    type: e.target.files[0].type,
                  });
                }}
                onBlur={formik.handleBlur}
                variant="outlined"
                fullWidth={true}
                startIcon={<BackupIcon />}
                color="primary"
                size="medium"
                component="label"
              >
                Upload Faculty Image
                <input
                  onChange={(e) => {
                    setImageVal(e.target.files[0]);
                  }}
                  name="image"
                  type="file"
                  hidden
                />
              </Button>
              {formik.touched.image && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.image}
                </span>
              )}
              <Typography variant="" color="primary" gutterBottom>
                {imageval && imageval.name != undefined ? imageval.name : ""}
              </Typography>
            </Grid>
          </Grid>

          <br />
          <Typography
            className={classes.title}
            variant="h6"
            color="primary"
            gutterBottom
          >
            Finance Details
          </Typography>
          <Grid container spacing={3}>
            {/* <Grid item xs={4}>

                            <TextField
                                name="bank_ac_number"
                                type='number'
                                label="Bank Account Number"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.bank_ac_number}
                                fullWidth={true}
                                variant="outlined"
                                size="medium" />
                            {formik.touched.bank_ac_number && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.bank_ac_number}</span>}
                        </Grid> */}

            {/* <Grid item xs={4}>
                            <FormControl
                                style={{ margin: 0 }}
                                fullWidth={true}
                                variant="outlined"
                                className={classes.formControl}>

                                <InputLabel
                                    htmlFor="outlined-state_gst_code-Name-native-simple">
                                    State
                                </InputLabel>

                                <Select
                                    onChange={formik.handleChange}
                                    id="newSkill"
                                    onBlur={formik.handleBlur}
                                    value={formik.values.state_gst_code}
                                    label="State"
                                    name="state_gst_code">
                                    {getstates.isSuccess && allStates.data.map((data) => {
                                        return <MenuItem value={data.code}>{data.state}</MenuItem>
                                    })
                                    }
                                </Select>
                            </FormControl>
                            {formik.touched.state_gst_code && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.state_gst_code}</span>}
                        </Grid> */}

            {/* GST Number */}
            <Grid item xs={4}>
              <TextField
                name="gst_number"
                label="GST Number"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.gst_number}
                fullWidth={true}
                variant="outlined"
                size="medium"
              />
              {formik.touched.gst_number && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.gst_number}
                </span>
              )}
            </Grid>

            {/* Management Number */}
            <Grid item xs={4}>
              <TextField
                name="management_number"
                label="Management Number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.management_number}
                fullWidth={true}
                type="number"
                variant="outlined"
                size="medium"
              />
              {formik.touched.management_number && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.management_number}
                </span>
              )}
            </Grid>

            {/* Institute Name */}
            <Grid item xs={4}>
              <FormControl
                style={{ margin: 0 }}
                fullWidth={true}
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-institute-Name-native-simple">
                  Institute Name
                </InputLabel>

                <Select
                  onChange={formik.handleChange}
                  id="newSkill"
                  onBlur={formik.handleBlur}
                  value={formik.values.institute_id}
                  label="Institute Name"
                  name="institute_id"
                >
                  {isSuccess &&
                    institutedata.map((data) => {
                      return <MenuItem value={data._id}>{data.name}</MenuItem>;
                    })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <br />

          <Typography
            className={classes.title}
            variant="h6"
            color="primary"
            gutterBottom
          >
            Other Details
          </Typography>
          <Grid container spacing={3}>
            {/* <Grid item xs={4}>
                            <TextField
                                name="portal_details"
                                label="Portal Detail"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.portal_details}
                                fullWidth={true}
                                variant="outlined"
                                size="medium" />
                            {formik.touched.portal_details && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.portal_details}</span>}
                        </Grid> */}

            {/* <Grid item xs={4}>
                            <TextField
                                name="portal_link"
                                label="Portal Link"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.portal_link}
                                fullWidth={true}
                                variant="outlined"
                                size="medium" />
                            {formik.touched.portal_link && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.portal_link}</span>}
                        </Grid> */}

            {/* Faculty Grade Basis */}
            <Grid item xs={4}>
              <FormControl>
                <FormLabel id="faculty_grade_basis">
                  {" "}
                  Faculty Grade Basis
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="faculty_grade_basis"
                  name="faculty_grade_basis"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.faculty_grade_basis}
                >
                  <FormControlLabel
                    value="COMMISSION PERCENT"
                    control={<Radio />}
                    label="Commission Percent"
                  />
                  <FormControlLabel
                    value="SALES"
                    control={<Radio />}
                    label="Sales"
                  />
                </RadioGroup>
                {formik.touched.faculty_grade_basis && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.faculty_grade_basis}
                  </span>
                )}
              </FormControl>
            </Grid>

            {/* Faculty Grade */}
            <Grid item xs={4}>
              <FormControl
                style={{ margin: 0 }}
                fullWidth={true}
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-institute-Name-native-simple">
                  Faculty Grade
                </InputLabel>

                <Select
                  onChange={formik.handleChange}
                  id="faculty_grade"
                  onBlur={formik.handleBlur}
                  value={formik.values.faculty_grade}
                  label="Faculty Grade"
                  name="faculty_grade"
                >
                  {facultyGrades.map((data) => {
                    return <MenuItem value={data}>{data}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              {formik.touched.faculty_grade && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.faculty_grade}
                </span>
              )}
            </Grid>

            {/* Joining Date */}
            <Grid item xs={4}>
              <TextField
                name="joining_date"
                label=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.joining_date}
                fullWidth={true}
                variant="outlined"
                size="medium"
                type="date"
              />
              {formik.touched.joining_date && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.joining_date}
                </span>
              )}
            </Grid>

            {/* Faculty Remark */}
            <Grid item xs={12}>
              <TextField
                name="remark"
                label="Faculty Remark"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.remark}
                fullWidth={true}
                multiline
                minRows={3}
                variant="outlined"
                size="medium"
              />
              {formik.touched.remark && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.remark}
                </span>
              )}
            </Grid>

            {/* Faculty Description */}
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Faculty Description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                fullWidth={true}
                multiline
                minRows={3}
                variant="outlined"
                size="medium"
              />
              {formik.touched.description && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.description}
                </span>
              )}
            </Grid>

            {/* Meta Title */}
            <Grid container item spacing={3}>
              <Grid item xs={4}>
                <TextField
                  name="title"
                  label="Meta Title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.title && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.title}
                  </span>
                )}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="meta"
                  label="Meta Data"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.meta}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.meta && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.meta}
                  </span>
                )}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="keywords"
                  label="Keywords"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.keywords}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.keywords && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.keywords}
                  </span>
                )}
              </Grid>
              <Grid container item spacing={3}>
                <Grid item xs={4}>
                  <TextField
                    name="bookMetaTitle"
                    label="Book Meta Title"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.bookMetaTitle}
                    fullWidth={true}
                    variant="outlined"
                    size="medium"
                  />
                  {formik.touched.bookMetaTitle && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.bookMetaTitle}
                    </span>
                  )}
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    name="bookMetaData"
                    label="Book Meta Data"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.bookMetaData}
                    fullWidth={true}
                    variant="outlined"
                    size="medium"
                  />
                  {formik.touched.bookMetaData && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.bookMetaData}
                    </span>
                  )}
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    name="bookKeywords"
                    label="Book Keywords"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.bookKeywords}
                    fullWidth={true}
                    variant="outlined"
                    size="medium"
                  />
                  {formik.touched.bookKeywords && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.bookKeywords}
                    </span>
                  )}
                </Grid>
              </Grid>

              <Grid container item spacing={3}>
                <Grid item xs={4}>
                  <TextField
                    name="testSeriesMetaTitle"
                    label="Test Series Meta Title"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.testSeriesMetaTitle}
                    fullWidth={true}
                    variant="outlined"
                    size="medium"
                  />
                  {formik.touched.testSeriesMetaTitle && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.testSeriesMetaTitle}
                    </span>
                  )}
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    name="testSeriesMetaData"
                    label="Test Series Meta Data"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.testSeriesMetaData}
                    fullWidth={true}
                    variant="outlined"
                    size="medium"
                  />
                  {formik.touched.testSeriesMetaData && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.testSeriesMetaData}
                    </span>
                  )}
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    name="testSeriesKeywords"
                    label="Test Series Keywords"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.testSeriesKeywords}
                    fullWidth={true}
                    variant="outlined"
                    size="medium"
                  />
                  {formik.touched.testSeriesKeywords && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.testSeriesKeywords}
                    </span>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  id="specialization"
                  //freeSolo
                  inputValue={formik.values.specialization}
                  onInputChange={(event, newValue) => {
                    setSpecialization(newValue);
                    formik.values.specialization = newValue;
                  }}
                  name="specialization"
                  options={allSpecialization}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      fullWidth={true}
                      variant="outlined"
                      size="medium"
                      {...params}
                      label="Specialization"
                    />
                  )}
                />
                {formik.touched.specialization && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.specialization}
                  </span>
                )}
              </Grid>
            </Grid>
            <br />
          </Grid>
          <br />
          <br />
          <Grid container spacing={3}>
            {/* Buttons */}
            <Grid item xs={12}>
              <div className="text-right">
                <Button
                  onClick={() => setDialog(false)}
                  variant="contained"
                  color="secondary"
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  style={{ marginLeft: 20 }}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
