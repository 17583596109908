import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import CmsContactDetails from "./CmsContactDetails";
import CmsCustomerFeedback from "./CmsCustomerFeedback";
import CmsFooterIcons from "./CmsFooterIcons";
import CmsMentoring from "./CmsMentoring";
import CmsMakesUsDifferent from "./CmsMakesUsDifferent";
import CmsContactUs from "./CmsContactUs";
import CmsSingleProductPageBannerImage from "./CmsSingleProductPageBannerImage";
import CmsProductPageBannerImage from "./CmsProductPageBannerImage";
import CmsSingleBookPageBannerImage from "./CmsSingleBookPageBannerImage";
import CmsBookProductBannerImage from "./CmsBookProductBannerImage";
import CmsSingleTestSeriesProductBannerImage from "./CmsSingleTestSeriesProductBannerImage";
import CmsTestSeriesProductBannerImage from "./CmsTestSeriesProductBannerImage";
import Blog from "../dashboard/blog/Blog";
import Subscriber from "../dashboard/subscriber/subscriber";
import BlogCount from "./blog/BlogCount";
import { useGetPendingCountQuery } from "../../../services/blogServices";
import AuthWrapper from "../../../utils/AuthWrapper";
import TabPanel from "./CreateTabPanel";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const tabsNames = [
  {
    tabName: "Contact Info",
    tabId: "CMS_CONTACT_INFO",
  },
  {
    tabName: "Footer Icons",
    tabId: "CMS_CONTACT_FOOTER_ICONS",
  },
  {
    tabName: "Mentoring",
    tabId: "CMS_CONTACT_MENTORING",
  },
  {
    tabName: "Make Us Diffrent",
    tabId: "CMS_MAKE_US_DIFFERENT",
  },
  {
    tabName: "Contact US",
    tabId: "CMS_CONTACT_US",
  },
  {
    tabName: "Customer feedback",
    tabId: "CMS_CUSTOMER_FEEDBACK",
  },
  {
    tabName: "Single Product Page Banner",
    tabId: "CMS_SINGLE_PRODUCT_PAGE_BANNER",
  },
  {
    tabName: "Product Page Banner",
    tabId: "CMS_PRODUCT_PAGE_BANNER",
  },
  {
    tabName: "Single Book Page Banner",
    tabId: "CMS_SINGLE_BOOK_PAGE_BANNER",
  },
  {
    tabName: "Book Product Page Banner",
    tabId: "CMS_BOOK_PRODUCT_PAGE_BANNER",
  },
  {
    tabName: "Single Test Series Page Banner",
    tabId: "CMS_SINGLE_TEST_SERIES_PRODUCT_PAGE_BANNER",
  },
  {
    tabName: "Test Series Product Page Banner",
    tabId: "CMS_TEST_SERIES_PRODUCT_PAGE_BANNER",
  },
  {
    tabName: "Blog",
    tabId: "CMS_BLOG",
  },
  {
    tabName: "Blog Comment",
    tabId: "CMS_BLOG_COMMENT",
  },
  {
    tabName: "Subscriber",
    tabId: "CMS_SUBSCRIBER",
  },
];

export default function Cms() {
  let tabsToShow = tabsNames?.filter((e, i) => {
    return AuthWrapper({ type: "TAB", name: e?.tabId, inBoolean: true });
  });
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getTabPanel = (ele) => {
    let moduleToShow;
    switch (ele) {
      case "Contact Info":
        moduleToShow = <CmsContactDetails />;
        break;
      case "Footer Icons":
        moduleToShow = <CmsFooterIcons />;
        break;
      case "Mentoring":
        moduleToShow = <CmsMentoring />;
        break;
      case "Make Us Diffrent":
        moduleToShow = <CmsMakesUsDifferent />;
        break;
      case "Contact US":
        moduleToShow = <CmsContactUs />;
        break;
      case "Customer feedback":
        moduleToShow = <CmsCustomerFeedback />;
        break;
      case "Single Product Page Banner":
        moduleToShow = <CmsSingleProductPageBannerImage />;
        break;
      case "Product Page Banner":
        moduleToShow = <CmsProductPageBannerImage />;
        break;
        case "Single Book Page Banner":
          moduleToShow = <CmsSingleBookPageBannerImage />;
          break;
        case "Book Product Page Banner":
          moduleToShow = <CmsBookProductBannerImage />;
          break;
          case "Single Test Series Page Banner":
            moduleToShow = <CmsSingleTestSeriesProductBannerImage />;
            break;
          case "Test Series Product Page Banner":
            moduleToShow = <CmsTestSeriesProductBannerImage />;
            break;
      case "Blog":
        moduleToShow = <Blog />;
        break;
      case "Blog Comment":
        moduleToShow = <BlogCount />;
        break;
      case "Subscriber":
        moduleToShow = <Subscriber />;
        break;
      default:
        break;
    }
    return moduleToShow;
  };
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          {tabsToShow.map((ele, ind) => {
            return (
              <Tab
                style={{ textTransform: "capitalize" }}
                label={ele?.tabName}
                {...a11yProps(ind)}
              />
            );
          })}
        </Tabs>
      </AppBar>

      {tabsToShow.map((ele, ind) => {
        return (
          <TabPanel value={value} index={ind}>
            {getTabPanel(ele?.tabName)}
          </TabPanel>
        );
      })}
    </div>
  );
}
