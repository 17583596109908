import React, { useState, useEffect } from "react";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import * as yup from 'yup'
import { useFormik } from 'formik';
import {
  Grid,
  Button,
  Card,
  Box,
  CardContent,
  Dialog, Zoom, DialogTitle, DialogContent, TextField,
  IconButton
} from "@material-ui/core";
import {
  useUpdateFacultyMutation,
  useGetSingleFacultyMutation
} from '../../../../services/services'
import DeleteIcon from '@material-ui/icons/Delete';
import { Label } from "@material-ui/icons";
import DataNotFound from "../DataNotFound";


export default function Discounts({ viewDialog, data, setFacultyData }) {

  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "from_date",
      label: "From Date",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "to_date",
      label: "To Date",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "discount_by_faculty",
      label: "Discount By Faculty",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "discount_by_us",
      label: "Discount By Us",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];
  function createData(SNo, from_date, to_date, discount_by_faculty, discount_by_us, actions) {
    return {
      SNo, from_date, to_date, discount_by_faculty, discount_by_us, actions
    };
  }
  const [render, setRender] = useState(false);

  let rows = [];
  const [updateData, updateDataInfo] = useUpdateFacultyMutation();
  const [singleFacultyData, singleFacultyDataInfo] = useGetSingleFacultyMutation();
  const [addDialog, setAddDialog] = useState(false);

  if (typeof data.faculty_discount == 'object') {
    data.faculty_discount.map((ele, ind) => {
      rows.push(
        createData(
          ind + 1,
          ele.from_date,
          ele.to_date,
          ele.discount_by_faculty,
          ele.discount_by_us,
          <div>
            <IconButton onClick={() => { deleteDiscount(ele._id) }} color="secondary"><DeleteIcon /></IconButton>
          </div>
        )
      );
    })
  }


  useEffect(() => {
    singleFacultyData(data._id)
  }, [updateDataInfo])

  const deleteDiscount = (discountId) => {
    var form_data = new FormData()
    form_data.append('key', 'remove_faculty_discount')
    form_data.append('update_data', JSON.stringify({ discount_id: discountId }))
    updateData({ data: form_data, id: data._id })
  }

  const dialogclose = () => {
    setAddDialog(false);
    setRender(!render);
  };

  return (
    <>
      <Card sx={{ display: "flex" }} className="m-3" >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Button
              onClick={() => {
                setAddDialog(true);
              }}
              variant="contained"
              color="primary"
            >
              Add Discount
            </Button>
            <Button
              onClick={() => viewDialog(false)}
              className="text-light bg-danger float-right btn btn-sm btn-danger"
            >
              Close
            </Button>
          </CardContent>
          {singleFacultyDataInfo.isSuccess == true && singleFacultyDataInfo.data.data.length ?
            singleFacultyDataInfo.isLoading == false ?
              <div>
                {setFacultyData(singleFacultyDataInfo.data.data[0])}


                {
                  rows.length ?
                    <CreateTableAddButton
                      editDialog={null}
                      setPage={null}
                      rows={rows}
                      editform={null}
                      columns={columns}
                      isButton={true}
                      editFormName={null}
                    /> :
                    <Grid xs={12} className="my-1 text-center bg-light p-3" >NO DATA FOUND</Grid>
                }


              </div>
              :
              <SkeletonListing />

            :
            <Grid container justifyContent="center">
              <DataNotFound />
            </Grid>

          }
          {addDialog ? <AddDiscount addDialog={addDialog} setAddDialog={setAddDialog} updateData={updateData} facultyId={data._id} /> : null}
          {updateDataInfo.data && (
            <Toster info={updateDataInfo} dialogclose={dialogclose} />
          )}
        </Box>
      </Card>
    </>

  );
}

function AddDiscount({ addDialog, setAddDialog, updateData, facultyId }) {

  const formik = useFormik({

    initialValues: {
      from_date: "",
      to_date: "",
      discount_by_faculty: "",
      discount_by_us: ""
    },
    validationSchema: yup.object({
      from_date: yup.string().required("Required!"),
      to_date: yup.string().required("Required!"),
      discount_by_faculty: yup.string().required("Required!"),
      discount_by_us: yup.string().required("Required!")
    }),
    onSubmit: (values, e) => {
      var form_data = new FormData()
      form_data.append('key', 'add_faculty_discount')
      form_data.append('update_data', JSON.stringify(values))
      updateData({ data: form_data, id: facultyId })
    },
  })

  return (
    <Dialog
      fullScreen={false}
      open={addDialog}
      //scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
      TransitionComponent={Zoom}
    >
      <DialogTitle id="scroll-dialog-title">Add Details</DialogTitle>
      <DialogContent
      //dividers={scroll === "paper"}
      >
        <form id="addfacultyform" onSubmit={formik.handleSubmit}>
          <Grid style={{ paddingLeft: 10, paddingRight: 10, marginTop: 20, marginLeft: 10 }} >
            <Grid container spacing={1} >
              <Grid item xs={6}>
                <span>From Date</span>
                <TextField
                  name="from_date"
                  label=""
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.from_date}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                  type="date" />
                {formik.touched.from_date && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.from_date}</span>}
              </Grid>
              <Grid item xs={6}>
                <span>To Date</span>
                <TextField
                  name="to_date"
                  label=""
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.to_date}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                  type="date" />
                {formik.touched.to_date && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.to_date}</span>}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="discount_by_faculty"
                  label="Discount By Faculty"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.discount_by_faculty}
                  fullWidth={true}
                  variant="outlined"
                  size="medium" />
                {formik.touched.discount_by_faculty && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.discount_by_faculty}</span>}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="discount_by_us"
                  label="Discount By Us"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.discount_by_us}
                  fullWidth={true}
                  variant="outlined"
                  size="medium" />
                {formik.touched.discount_by_us && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.discount_by_us}</span>}
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid container spacing={3} >
              <Grid item xs={12}>
                <div className="text-right">
                  <Button
                    onClick={() => setAddDialog(false)}
                    variant="contained"
                    color="secondary">
                    Close</Button>
                  <Button
                    type="submit"
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    color="primary">
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid >
        </form >
      </DialogContent>
    </Dialog>
  )

}
