import React, { useState, useEffect } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import AddDataHeader from "../AddDataHeader";
import DataNotFound from "../DataNotFound";
import FormDialog from "../reusableComponents/NewFormDialog";




export default function BatchType() {

    const AllbatchType = {
        data: [
            { _id: 1, batch_type: "LIBRARY" },
            { _id: 2, batch_type: "ONLY BOOK SELL" },
            { _id: 3, batch_type: "ONLY BOOK SELL" },
            { _id: 4, batch_type: "ONLY BOOK SELL" },
            { _id: 5, batch_type: "ONLY BOOK SELL" },

        ]
    }



    const columns = [
        {
            id: "SNo",
            label: "S.No",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "batchType",
            label: "Batch Type",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "actions",
            label: "Actions",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
    ];
    function createData(SNo, batchType, actions) {
        return {
            SNo,
            batchType,
            actions,
        };
    }
    let rows = [];
    let [getid, setGetid] = useState("");
    const [data, setData] = useState("");
    const [addDialog, setAddDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);

    let totalData = "ok";


    // useEffect(() => {
    //     getid && deleteExpenseCategory(getid) && setGetid("");
    // }, [getid]);

    if (
        AllbatchType
    ) {
        rows = AllbatchType.data.map((ele, index) => {
            return createData(
                index + 1,
                ele.batch_type,
                <div>
                    <EditDelete
                        setEditDialog={setEditDialog}
                        type="edit"
                        setData={setData}
                        data={ele}
                    />
                    <EditDelete type="delete" setGetid={setGetid} id={ele._id} />
                </div>
            );
        });
    }


    // const dialogclose = () => {
    //     setEditDialog(false);
    //     setViewDialog(false);
    //     setAddDialog(false);
    //     setRender(!render);
    // };

    // const updateBtnClickHandler = (dataForUpdate) => {
    //     updateExpenseCategory({
    //         body: dataForUpdate,
    //         id: data._id,
    //     });
    // };

    return (
        <div>
            {/* {!viewDialog && ( */}
            <AddDataHeader
                addFormName={"Add New Batch Type"}
                isSearch={true}
                isPagination={true}
                setAddDialog={setAddDialog}
                isButton={true}
                Adddialog={addDialog}
                form={
                    <FormDialog
                        open={addDialog}
                        setOpen={setAddDialog}
                        formName={"Add New Batch Type"}
                        btnName={"Save Batch Type"}
                        onBtnClick={(dataForAdd) => {
                              
                        }}
                        fields={[
                            {
                                fieldType: "text",
                                fieldName: "Batch Type",
                                initialValue: "",
                                //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                                accessKey: "batch_type",
                            },
                        ]}
                    />
                }
            />
            {/* )} */}


            <>
                {rows.length ? (
                    <CreateTableAddButton
                        editDialog={editDialog}
                        // setPage={setPage}
                        rows={rows}
                        editform={
                            <FormDialog
                                open={editDialog}
                                setOpen={setEditDialog}
                                formName={"Edit Batch Type  "}
                                btnName={"Update Batch Type"}
                                onBtnClick={(dataForAdd) => {
                                      
                                }}

                                fields={[
                                    {
                                        fieldType: "text",
                                        fieldName: "Batch Type",
                                        initialValue: data.batch_type,
                                        //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                                        accessKey: "batch_type",
                                    },
                                ]}
                            />
                        }
                        columns={columns}
                        isButton={true}
                        editFormName={"Edit Batch Type"}
                    />
                ) : totalData == 0 ? (
                    <DataNotFound />
                ) : (
                    <SkeletonListing />
                )}

            </>


            {/* {addExpenseCategoryDataInfo && (
                <Toster info={addExpenseCategoryDataInfo} dialogclose={dialogclose} />
            )}

            {updateExpenseCategoryInfo && (
                <Toster info={updateExpenseCategoryInfo} dialogclose={dialogclose} />
            )}

            {deleteExpenseCategoryInfo && (
                <Toster info={deleteExpenseCategoryInfo} dialogclose={dialogclose} />
            )} */}
        </div>
    );
}
