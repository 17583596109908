import axios from "axios";


const BASE_URL = "http://api.smartlearningdestination.com";
//const BASE_URL = "http://192.168.1.13:4000";

var axiosInstance = axios.create({
    baseURL: BASE_URL,
    /* other custom settings */
});

export default axiosInstance;