import Delete from "@material-ui/icons/Delete";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../constant/BaseURL";

const token = localStorage.getItem("__authToken__");

export const productCartApi = createApi({
  tagTypes: ["productCart"],
  reducerPath: "productCartApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/cart/`,
  }),
  endpoints: (builder) => ({

    GetAllUserCart: builder.query({
      providesTags: ["productCart"],
      query: (body) => {
        return {
          url: "all-users-cart",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,

        }
      }
    }),

    ExportProductCartData: builder.mutation({
        providesTags: ["productCart"],
        query: (body) => {
          return {
            url: "all-users-cart",
            headers: {
              "x-access-token": token,
            },
            method: "POST",
            body,
  
          }
        }
      }),

  }),

});

export const {
  useGetAllUserCartQuery,
  useExportProductCartDataMutation
} = productCartApi;