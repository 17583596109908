import React from "react";
import {
  Button,
  Grid,
  Box,
  makeStyles,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: 18,
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  table: {
    padding: 10,
    margin: 5,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function ViewGSTInvoiceRequest({ viewDialog, data, setViewDialog }) {
  const classes = useStyles();

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  return (
    <Dialog
      open={viewDialog}
      onClose={() => setViewDialog(false)}
      fullWidth
      maxWidth="lg"
    >
      <Grid
        container
        item
        xs={12}
        className="px-3 py-3"
        justifyContent="space-between"
        style={{ borderBottomRightRadius: "20px", backgroundColor: "#5664d2" }}
      >
        <Typography variant="h6" className="text-white">
          {" "}
          GST Request{" "}
        </Typography>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setViewDialog(false)}
          >
            {" "}
            Close{" "}
          </Button>
        </Grid>
      </Grid>

      <DialogContent>
        <div className="d-flex justify-content-center my-3">
          <TableContainer style={{ maxWidth: 570 }} className="m-2 border">
            <Table className={classes.table} aria-label="customized table">
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell
                    className="m-1 text-info font-weight-bold"
                    align="left"
                  >
                    <p className="d-flex align-items-center "> SLD Order Id </p>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center">
                      {" "}
                      {data.sld_order_id}{" "}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell
                    className="m-1 text-info font-weight-bold"
                    align="left"
                  >
                    <p className="d-flex align-items-center "> Order Date </p>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center">
                      {" "}
                      {data.order_date
                        ? moment(data.order_date).format(
                          "DD-MM-YYYY hh:mm:ss A"
                        )
                        : data.order_date}{" "}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell
                    className="m-1 text-info font-weight-bold"
                    align="left"
                  >
                    <p className="d-flex align-items-center ">Order Title </p>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center">
                      {" "}
                      {data.order_title}{" "}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell
                    className="m-1 text-info font-weight-bold"
                    align="left"
                  >
                    <p className="d-flex align-items-center "> Order Type </p>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center">
                      {" "}
                      {data.order_type}{" "}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell
                    className="m-1 text-info font-weight-bold"
                    align="left"
                  >
                    <p className="d-flex align-items-center "> Request Status </p>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center">
                      {data.status}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell
                    className="m-1 text-info font-weight-bold"
                    align="left"
                  >
                    <p className="d-flex align-items-center ">
                      {" "}
                      Applied On Date{" "}
                    </p>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center">
                      {data.applied_on_date
                        ? moment(data.applied_on_date).format(
                          "DD-MM-YYYY hh:mm:ss A"
                        )
                        : ""}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell
                    className="m-1 text-info font-weight-bold"
                    align="left"
                  >
                    <p className="d-flex align-items-center ">
                      {" "}
                      Shipping Details{" "}
                    </p>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">Name : </b>{" "}
                      {data.shipping_details.first_name +
                        " " +
                        data.shipping_details.last_name}{" "}
                    </p>
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">Email : </b>{" "}
                      {data.shipping_details.email}{" "}
                    </p>
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">Mobile : </b>{" "}
                      {data.shipping_details.mobile}{" "}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell
                    className="m-1 text-info font-weight-bold"
                    align="left"
                  >
                    <p className="d-flex align-items-center "> HSN/SAC </p>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center">
                      {" "}
                      {data.hsn_sac}{" "}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell
                    className="m-1 text-info font-weight-bold"
                    align="left"
                  >
                    <p className="d-flex align-items-center "> Total Amount In Words </p>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center">
                      {" "}
                      {data.total_amount_in_words}{" "}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell
                    className="m-1 text-info font-weight-bold"
                    align="left"
                  >
                    <p className="d-flex align-items-center ">
                      {" "}
                      Invoice Created{" "}
                    </p>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center">
                      {" "}
                      {data.invoice_created ? "YES" : "NO"}{" "}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell
                    className="m-1 text-info font-weight-bold"
                    align="left"
                  >
                    <p className="d-flex align-items-center "> Invoice No. </p>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center">
                      {" "}
                      {data.invoice_no}{" "}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell
                    className="m-1 text-info font-weight-bold"
                    align="left"
                  >
                    <p className="d-flex align-items-center ">
                      {" "}
                      Invoice Created On Date{" "}
                    </p>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center">
                      {" "}
                      {data.invoice_created_on_date
                        ? moment(data.invoice_created_on_date).format(
                          "DD-MM-YYYY hh:mm:ss A"
                        )
                        : ""}{" "}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell
                    className="m-1 text-info font-weight-bold"
                    align="left"
                  >
                    <p className="d-flex align-items-center ">
                      {" "}
                      Invoice Created By{" "}
                    </p>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center">
                      {" "}
                      {data.invoice_created_by_type}{" "}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer style={{ maxWidth: 570 }} className="m-2 border">
            <Table className={classes.table} aria-label="customized table">
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell
                    className="m-1 text-info font-weight-bold"
                    align="left"
                  >
                    <p className="d-flex align-items-center "> User Details </p>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">Name : </b>{" "}
                      {data.user_details.first_name}{" "}
                      {data.user_details.last_name}
                    </p>
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">Mobile : </b>{" "}
                      {data.user_details.mobile}{" "}
                    </p>
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">Email : </b>{" "}
                      {data.user_details.email}{" "}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell
                    className="m-1 text-info font-weight-bold"
                    align="left"
                  >
                    <p className="d-flex align-items-center "> GST Details </p>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">GST amount : </b>₹{" "}
                      {data.GST_amount}
                    </p>
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">IGST : </b>₹ {data.IGST}{" "}
                    </p>
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">CGST : </b>₹ {data.CGST}{" "}
                    </p>
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">SGST : </b>₹ {data.SGST}{" "}
                    </p>
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">actual amount : </b>₹{" "}
                      {data.actual_amount}{" "}
                    </p>
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">total amount : </b>₹{" "}
                      {data.total_amount}{" "}
                    </p>
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">gst number : </b>{" "}
                      {data.gst_number}{" "}
                    </p>
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">gst billing name : </b>{" "}
                      {data.gst_billing_name}{" "}
                    </p>
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">gst applied : </b>{" "}
                      {data.gst_applied}{" "}
                    </p>
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">email : </b> {data.email}{" "}
                    </p>
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">mobile : </b> {data.mobile}{" "}
                    </p>
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">address line : </b>{" "}
                      {data.address_line1}, {data.address_line2}
                    </p>
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">city : </b> {data.city}{" "}
                    </p>
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">pincode : </b>{" "}
                      {data.pincode}{" "}
                    </p>
                    <p className="d-flex align-items-center">
                      <b className="mx-1 text-muted">state : </b> {data.state}{" "}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell className="m-1 text-info font-weight-bold" align="left">
                    <p className="d-flex align-items-center "> Approved </p>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center">  {data.is_approved ? "YES" : "NO"} </p>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell className="m-1 text-info font-weight-bold" align="left">
                    <p className="d-flex align-items-center "> Approval Remark </p>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center">  {data.approval_remark} </p>
                  </StyledTableCell>
                </StyledTableRow>

                {!data.is_approved &&
                  <StyledTableRow>
                    <StyledTableCell className="m-1 text-info font-weight-bold" align="left">
                      <p className="d-flex align-items-center "> Disapproval Remark </p>
                    </StyledTableCell>
                    <StyledTableCell className="m-1" align="left">
                      <p className="d-flex align-items-center">  {data.disapproval_remark} </p>
                    </StyledTableCell>
                  </StyledTableRow>
                }

                <StyledTableRow>
                  <StyledTableCell className="m-1 text-info font-weight-bold" align="left">
                    <p className="d-flex align-items-center "> Approved Date </p>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center">  {data.approved_date ? moment(data.approved_date).format("DD-MM-YYYY hh:mm:ss A") : ""} </p>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell className="m-1 text-info font-weight-bold" align="left">
                    <p className="d-flex align-items-center "> Approved By </p>
                  </StyledTableCell>
                  <StyledTableCell className="m-1" align="left">
                    <p className="d-flex align-items-center">  {data.approved_by_type} </p>
                  </StyledTableCell>
                </StyledTableRow>


              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ViewGSTInvoiceRequest;
