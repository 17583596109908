import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const msgTempletApi = createApi({
  tagTypes: ["msgtemplet"],
  reducerPath: "msgTempletapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/msg-templet/`,
  }),
  endpoints: (builder) => ({
    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET "msgtemplet" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    GetAllmsgTemplet: builder.query({
      providesTags: ["msgtemplet"],
      query: (body) => {
        return {
          url: "all-msg-templets",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ADD "msgtemplet" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    DeleteMsgTemplet: builder.mutation({
      invalidatesTags: ["msgtemplet"],
      query: (id) => {
        return {
          url:id,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
          
        };
      },
    }),

    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< UPDATE "msgtemplet" IS_SHOW STATUS  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    AddMsgTemplet: builder.mutation({
      invalidatesTags: ["msgtemplet"],
      query: (body) => {
        return {
          url: "add-msg-templet",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< UPDATE SLIDER IMAGE STATUS IN "msgtemplet" SECTION >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    UpdateMsgTempletData: builder.mutation({
      invalidatesTags: ["msgtemplet"],
      query: ({data,id}) => {
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body:data
        };
      },
    }),


    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET "SMS templet List" >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    getSMSTempletList: builder.query({
      query: () => {
        return {
          url: "msg-templet-list" , 
          headers: {
            "x-access-token": token,
          },
          method: "GET",
          
        };
      },
    }),


      // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< SEND "SMS" >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

      sendSMS: builder.mutation({
        query: (body) => {
          return {
            url: "send-sms" , 
            headers: {
              "x-access-token": token,
            },
            method: "POST",
            body
          };
        },
      }),
    



  }),
});

export const {
  useDeleteMsgTempletMutation,
  useGetAllmsgTempletQuery,
  useAddMsgTempletMutation,
  useGetSMSTempletListQuery , 
  useUpdateMsgTempletDataMutation,
  useSendSMSMutation, 
} = msgTempletApi;



