import React from 'react'
import {
    Button,
    Grid
} from '@material-ui/core';


export default function ViewInquiry({ data, viewDialog }) {
    return (
        <div className="p-5">
            <Grid container spacing={3} >

                <Grid xs={12}>
                    <Button
                        onClick={() => viewDialog(false)}
                        className="text-light bg-danger float-right btn btn-sm btn-danger">
                        Close
                    </Button>
                </Grid>

                <Grid item xs={6} className="">
                    <div className="card">
                        <div className="card-body row p-0">
                            <div className="col-3 d-flex justify-content-center">Name</div>
                            <div className="col-1">:-</div>
                            <div className="col-8 d-flex justify-content-start text-muted"> {data.fullname}</div>
                        </div>

                    </div>
                </Grid>
                <Grid item xs={6} className="">
                    <div className="card">
                        <div className="card-body row p-0">
                            <div className="col-3 d-flex justify-content-center">Email</div>
                            <div className="col-1">:-</div>
                            <div className="col-8 d-flex justify-content-start text-muted"> {data.email}</div>
                        </div>

                    </div>
                </Grid>

                <Grid item xs={6} className="">
                    <div className="card">
                        <div className="card-body row p-0">
                            <div className="col-3 d-flex justify-content-center">Mobile</div>
                            <div className="col-1">:-</div>
                            <div className="col-8 d-flex justify-content-start text-muted"> {data.mobile}</div>
                        </div>

                    </div>
                </Grid>

                <Grid item xs={6} className="">
                    <div className="card">
                        <div className="card-body row p-0">
                            <div className="col-3 d-flex justify-content-center">Course</div>
                            <div className="col-1">:-</div>
                            <div className="col-8 d-flex justify-content-start text-muted"> {data.course}</div>
                        </div>
                    </div>
                </Grid>



                <Grid item xs={6} className="">
                    <div className="card">
                        <div className="card-body row p-0">
                            <div className="col-3 d-flex justify-content-center">Level</div>
                            <div className="col-1">:-</div>
                            <div className="col-8 d-flex justify-content-start text-muted"> {data.level}</div>
                        </div>

                    </div>
                </Grid>

                <Grid item xs={6} className="">
                    <div className="card">
                        <div className="card-body row p-0">
                            <div className="col-3 d-flex justify-content-center">Subject</div>
                            <div className="col-1">:-</div>
                            <div className="col-8 d-flex justify-content-start text-muted"> {data.subject}</div>
                        </div>

                    </div>
                </Grid>

                <Grid item xs={6} className="">
                    <div className="card">
                        <div className="card-body row p-0">
                            <div className="col-3 d-flex justify-content-center">Attempts</div>
                            <div className="col-1">:-</div>
                            <div className="col-8 d-flex justify-content-start text-muted"> {data.attempt_count}</div>
                        </div>

                    </div>
                </Grid>

                <Grid item xs={6} className="">
                    <div className="card">
                        <div className="card-body row p-0 ">
                            <div className="col-3 d-flex justify-content-center">Faculty</div>
                            <div className="col-1">:-</div>
                            <div className="col-8 d-flex justify-content-start text-muted"> {data.faculty}</div>
                        </div>

                    </div>
                </Grid>

                <Grid item xs={6} className="">
                    <div className="card">
                        <div className="card-body row p-0 ">
                            <div className="col-3 d-flex justify-content-center">Created At</div>
                            <div className="col-1">:-</div>
                            <div className="col-8 d-flex justify-content-start text-muted"> {data.createdAt}</div>
                        </div>

                    </div>
                </Grid>

                <Grid item xs={6} className="">
                    <div className="card">
                        <div className="card-body row p-0">
                            <div className="col-3 d-flex justify-content-center">Discription</div>
                            <div className="col-1">:-</div>
                            <div className="col-8 d-flex justify-content-start text-muted"> {data.discription}</div>
                        </div>

                    </div>
                </Grid>

            </Grid>

        </div>
    )
}
