import React, { useState, useEffect } from "react";
import exportFromJSON from "export-from-json";
import Toster from "./Toster";
import AddIcon from "@material-ui/icons/Add";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import * as yup from "yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Tooltip,
  Chip,
  Link,
  CircularProgress,
} from "@material-ui/core";
import BackupIcon from "@material-ui/icons/Backup";
import { useFormik } from "formik";
import {
  useAddVideoLectureSheetMutation,
  useAddFacultySheetMutation,
} from "../../../services/excelFileService";
import FileSaver from "file-saver";
import Loader from "./Loader";
import { PYTHON_URL } from "../../../constant/BaseURL";

const BASE_URL =PYTHON_URL;
const token = localStorage.getItem("__authToken__");
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function UploadExcelFile(props) {
  const [uploadVideoExcelData, uploadVideoExcelDataInfo] =
    useAddVideoLectureSheetMutation();
  const [uploadFacultyExcelData, uploadFacultyExcelDataInfo] =
    useAddFacultySheetMutation();
  const AllData =
    props.currentPageName == "videoLecture"
      ? uploadVideoExcelDataInfo.data
      : props.currentPageName == "faculty"
        ? uploadFacultyExcelDataInfo.data
        : [];

  const [open, setOpen] = React.useState(false);
  const [excelVal, setExcelVal] = useState();

  const formik = useFormik({
    initialValues: {
      excel: "",
    },
    validationSchema: yup.object({
      excel: yup.string().required("Required!"),
    }),
    onSubmit: (values) => {
      values.excel = excelVal;
      var form_data = new FormData();
      form_data.append("data", excelVal);
      if (props.currentPageName == "videoLecture") {
        uploadVideoExcelData(form_data);
      }
      if (props.currentPageName == "faculty") {
        uploadFacultyExcelData(form_data);
      }
    },
  });

  const handleClickOpen = () => {
    setExcelVal("");
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (AllData) {
      if (AllData.status == false && AllData.error_data.length > 0) {
        const data = AllData.error_data;
        const fileName = "download";
        const exportType = exportFromJSON.types.csv;

        exportFromJSON({ data, fileName, exportType });
        handleClose();
      }
    }
  }, [AllData]);

  return (
    <div className="d-flex justify-content-end">
      {props.currentPageName == "videoLecture" && props.addExcelVideoLecture ||
        props.currentPageName == "faculty" && props.addExcelFaculty ? (
        <Tooltip placement="bottom-end" title="Add Excel" arrow>
          <Chip
            label={
              <span className="d-flex justify-content-center align-items-center">
                <AddIcon fontSize="" />
                <span> Excel</span>
              </span>
            }
            color="primary"
            onClick={handleClickOpen}
            variant="outlined"
            clickable
          />
        </Tooltip>
      ) : null}

      {props.currentPageName == "videoLecture" && props.downloadExcelVideoLecture ? (
        <Tooltip
          placement="bottom-end"
          title="Download Excel"
          arrow
          onClick={() => props.onDownloadExcelClick(props.searchValue)}
        >
          {/* <Link className="round"
                        target='_blank'
                        href={ props.filteredPagination 
                              ? BASE_URL+"get-videolecture-sheet?token="+token+"&search="+props.searchValue+"&filter_by="+ JSON.stringify(props.filteredPagination)
                              : BASE_URL+"get-videolecture-sheet?token="+token+"&search="+props.searchValue}> */}
          <Chip
            label={
              <span className="d-flex justify-content-center align-items-center">
                <ArrowDownwardIcon fontSize="small" />
                <span> Excel</span>
              </span>
            }
            color="primary"
            variant="outlined"
            clickable
          />
          {/* </Link> */}
        </Tooltip>
      ) : null}


      {props.currentPageName == "faculty" && props?.downloadExcelFaculty ? (
        <Tooltip placement="bottom-end" title="Download Excel" arrow>
          <Link
            className="round"
            target="_blank"
            href={
              props.filteredPagination
                ? BASE_URL +
                "get-faculty-sheet?token=" +
                token +
                "&filter_by=" +
                JSON.stringify(props.filteredPagination)
                : BASE_URL + "get-faculty-sheet?token=" + token
            }
          >
            <Chip
              label={
                <span className="d-flex justify-content-center align-items-center">
                  <ArrowDownwardIcon fontSize="small" />
                  <span> Excel</span>
                </span>
              }
              color="primary"
              variant="outlined"
              clickable
            />
          </Link>
        </Tooltip>
      ) : null}

      {props.currentPageName == "mobileNumberLibrary" && props?.downloadMobileExcel ? (
        <Tooltip placement="bottom-end" title="Download Excel" arrow>
          <Link
            className="round"
            target="_blank"
            href={
              props.filteredPagination
                ? BASE_URL +
                "get-mobile-num-liberary/?token=" +
                token +
                "&filter_by=" +
                JSON.stringify(props.filteredPagination)
                : BASE_URL + "get-mobile-num-liberary/?token=" + token
            }
          >
            <Chip
              label={
                <span className="d-flex justify-content-center align-items-center">
                  <ArrowDownwardIcon fontSize="small" />
                  <span> Excel</span>
                </span>
              }
              color="primary"
              variant="outlined"
              clickable
            />
          </Link>
        </Tooltip>
      ) : null}

      {props.currentPageName == "videoLectureOrder" ? (
        <Tooltip placement="bottom-end" title="Download Excel" arrow>
          <Link
            className="round"
            target="_blank"
            href={"https://ms.smartlearningdestination.com/get-order-sheet/?token=" + token}
          >
            <Chip
              label={
                <span className="d-flex justify-content-center align-items-center">
                  <ArrowDownwardIcon fontSize="small" />
                  <span> Excel</span>
                </span>
              }
              color="primary"
              variant="outlined"
              clickable
            />
          </Link>
        </Tooltip>
      ) : null}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>Upload Excel File</DialogTitle>
          {uploadVideoExcelDataInfo.status == "pending" ||
            uploadFacultyExcelDataInfo.status == "pending" ? (
            <div
              className="d-flex justify-content-center align-items-center "
              style={{ height: "200px", width: "200px" }}
            >
              <div
                style={{ zIndex: 2000, height: "100%", width: "100%" }}
                className=" d-flex justify-content-center align-items-center "
              >
                <CircularProgress size="4rem" />
              </div>
            </div>
          ) : (
            <>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  <Button
                    variant="outlined"
                    fullWidth={true}
                    startIcon={<BackupIcon />}
                    color="primary"
                    component="label"
                  >
                    Upload Excel
                    <input
                      onChange={(e) => {
                        setExcelVal(e.target.files[0]);
                        formik.values.excel = e.target.files[0];
                      }}
                      name="image"
                      type="file"
                      hidden
                    />
                  </Button>

                  {formik.touched.excel && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.excel}
                    </span>
                  )}
                  <br />
                  {excelVal ? <span>{excelVal.name}</span> : ""}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="secondary"
                  size="small"
                  variant="contained"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  size="small"
                  variant="contained"
                >
                  Upload
                </Button>
              </DialogActions>
            </>
          )}
        </form>
      </Dialog>

      {uploadVideoExcelDataInfo && (
        <Toster info={uploadVideoExcelDataInfo} dialogclose={handleClose} />
      )}
      {uploadFacultyExcelDataInfo && (
        <Toster info={uploadFacultyExcelDataInfo} dialogclose={handleClose} />
      )}
    </div>
  );
}
