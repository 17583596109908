import React from "react";
import TabPanel from "./CreateTabPanel";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DefinedSMS from "./DefinedSMS";
import CustomSMS from "./CustomSMS";
import MobileNoLibrary from "./MobileNoLibrary";
import SmsTemplate from "../dashboard/smstemplate/SmsTemplate";
import { userAccess } from "../../../helpers/userAccess";
import AuthWrapper from "../../../utils/AuthWrapper";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const tabsNames = [
  {
    tabName: "Send SMS",
    tabId: "SMS_SEND_SMS",
  },
  {
    tabName: "Mobile No Library",
    tabId: "SMS_MOBILE_NUMBER_LIBRARY",
  },
  {
    tabName: "Sms Template",
    tabId: "SMS_SMS_TEMPLATE",
  },
];

export default function Sms() {
  let tabsToShow = tabsNames?.filter((e, i) => {
    return AuthWrapper({ type: "TAB", name: e?.tabId, inBoolean: true });
  });
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getTabPanel = (ele) => {
    let moduleToShow;
    switch (ele) {
      case "Send SMS":
        moduleToShow = <CustomSMS />;
        break;
      case "Mobile No Library":
        moduleToShow = <MobileNoLibrary />;
        break;
      case "Sms Template":
        moduleToShow = <SmsTemplate />;
        break;
      default:
        break;
    }
    return moduleToShow;
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            {tabsToShow.map((ele, ind) => {
              return (
                <Tab
                  style={{ textTransform: "capitalize" }}
                  label={ele?.tabName}
                  {...a11yProps(ind)}
                />
              );
            })}
          </Tabs>
        </AppBar>

        {tabsToShow.map((ele, ind) => {
          return (
            <TabPanel value={value} index={ind}>
              {getTabPanel(ele?.tabName)}
            </TabPanel>
          );
        })}
      </div>
    </>
  );
}
