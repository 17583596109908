import React, { useState, useEffect } from "react";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import * as yup from 'yup'
import { useFormik } from 'formik';
import {
  Grid,
  Button,
  Card,
  Box,
  CardContent,
  Dialog, Zoom, DialogTitle, DialogContent, TextField,
  IconButton,
  FormControl, InputLabel, Select, MenuItem
} from "@material-ui/core";
import {
  useGetSingleFacultyMutation
} from '../../../../services/services'
import {
  useAddFacultyPaymentMutation,
  useDeleteFacultyPaymentMutation
} from '../../../../services/facultyPaymentServices'
import DeleteIcon from '@material-ui/icons/Delete';
import { Label } from "@material-ui/icons";
import DataNotFound from "../DataNotFound";


export default function PaymentDetails({ viewDialog, data, setFacultyData }) {

  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "added_by_name",
      label: "Added By Name",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "added_by_type",
      label: "Added By Type",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "applicable_from_date",
      label: "Applicable From Date",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "other_details",
      label: "Other Details",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "payment_amount",
      label: "Payment Amount",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "payment_date",
      label: "Payment Date",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "payment_mode",
      label: "Payment Mode",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "payment_type",
      label: "Payment Type",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "remark",
      label: "Remark",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "title_deal_name",
      label: "Title Deal Name",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];
  function createData(SNo,
    added_by_name,
    added_by_type,
    applicable_from_date,
    other_details,
    payment_amount,
    payment_date,
    payment_mode,
    payment_type,
    remark,
    title_deal_name,
    actions) {
    return {
      SNo,
      added_by_name,
      added_by_type,
      applicable_from_date,
      other_details,
      payment_amount,
      payment_date,
      payment_mode,
      payment_type,
      remark,
      title_deal_name,
      actions
    };
  }
  const [render, setRender] = useState(false);

  let rows = [];
  const [addData, addDataInfo] = useAddFacultyPaymentMutation();
  const [deleteFacultyPayment, deleteFacultyPaymentInfo] = useDeleteFacultyPaymentMutation();
  const [singleFacultyData, singleFacultyDataInfo] = useGetSingleFacultyMutation();
  const [addDialog, setAddDialog] = useState(false);
  const [lastActionPerformed, setLastActionPerformed] = useState("")

  if (typeof data.faculty_payment_list == 'object') {
    data.faculty_payment_list.map((ele, ind) => {
      rows.push(
        createData(
          ind + 1,
          ele.added_by_name,
          ele.added_by_type,
          ele.applicable_from_date,
          ele.other_details,
          ele.payment_amount,
          ele.payment_date,
          ele.payment_mode,
          ele.payment_type,
          ele.remark,
          ele.title_deal_name,
          <div>
            <IconButton onClick={() => { deleteDetails(ele._id) }} color="secondary"><DeleteIcon /></IconButton>
          </div>
        )
      );
    })
  }


  useEffect(() => {
    singleFacultyData(data._id)
  }, [addDataInfo, deleteFacultyPaymentInfo])


  const deleteDetails = (paymentId) => {
    setLastActionPerformed("DELETE")
    deleteFacultyPayment(paymentId)
  }

  const dialogclose = () => {
    setAddDialog(false);
    setRender(!render);
  };

  return (
    <>
      <Card sx={{ display: "flex" }} className="m-3" >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Button
              onClick={() => {
                setAddDialog(true);
              }}
              variant="contained"
              color="primary"
            >
              Add Details
            </Button>
            <Button
              onClick={() => viewDialog(false)}
              className="text-light bg-danger float-right btn btn-sm btn-danger"
            >
              Close
            </Button>
          </CardContent>

          {
            singleFacultyDataInfo.isSuccess == true && singleFacultyDataInfo.data.data.length ?
              singleFacultyDataInfo.isLoading == false ?
                <div>
                  {setFacultyData(singleFacultyDataInfo.data.data[0])}

                  {
                    rows.length ?
                      <CreateTableAddButton
                        editDialog={null}
                        setPage={null}
                        rows={rows}
                        editform={null}
                        columns={columns}
                        isButton={true}
                        editFormName={null}
                      /> :
                      <Grid xs={12} className="my-1 text-center bg-light p-3" >NO DATA FOUND</Grid>
                  }
                  {addDataInfo.data != undefined && lastActionPerformed == "ADD" && (
                    <Toster info={addDataInfo} dialogclose={dialogclose} />)}

                  {deleteFacultyPaymentInfo.data != undefined && lastActionPerformed == "DELETE" && (
                    <Toster info={deleteFacultyPaymentInfo} dialogclose={dialogclose} />)}

                </div>
                :
                <SkeletonListing />
              :
              <Grid container justifyContent="center">
                <DataNotFound />
              </Grid>

          }
          {addDialog ? <AddPaymentDetails addDialog={addDialog} setAddDialog={setAddDialog} setLastActionPerformed={setLastActionPerformed} addData={addData} facultyId={data._id} /> : null}
        </Box>
      </Card>
    </>

  );
}

function AddPaymentDetails({ addDialog, setAddDialog, setLastActionPerformed, addData, facultyId }) {

  const formik = useFormik({

    initialValues: {
      faculty_id: facultyId,
      title_deal_name: "",
      remark: "",
      payment_amount: "",
      payment_type: "",
      payment_date: "",
      applicable_from_date: "",
      applicable_to_date: "",
      payment_mode: "",
      other_details: "",
      commission: ""
    },
    validationSchema: yup.object({
      title_deal_name: yup.string().required("Required!"),
      remark: yup.string().required("Required!"),
      payment_amount: yup.string().required("Required!"),
      payment_type: yup.string().required("Required!"),
      payment_date: yup.string().required("Required!"),
      applicable_from_date: yup.string().required("Required!"),
      applicable_to_date: yup.string().required("Required!"),
      payment_mode: yup.string().required("Required!"),
      other_details: yup.string().required("Required!"),
      commission: yup.string().required("Required!")
    }),
    onSubmit: (values, e) => {
      setLastActionPerformed("ADD")
      addData(values)
      setAddDialog(false)
    },
  })

  return (
    <Dialog
      fullScreen={false}
      open={addDialog}
      //scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
      TransitionComponent={Zoom}
    >
      <DialogTitle id="scroll-dialog-title">Add Details</DialogTitle>
      <DialogContent>
        <form id="addfacultyform" onSubmit={formik.handleSubmit}>
          <Grid style={{ paddingLeft: 10, paddingRight: 10, marginTop: 20, marginLeft: 10 }} >
            <Grid container spacing={1} >

              <Grid item xs={6}>
                <TextField
                  name="title_deal_name"
                  label="Title Deal Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title_deal_name}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.title_deal_name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.title_deal_name}</span>}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="remark"
                  label="Remark"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.remark}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.remark && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.remark}</span>}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="payment_amount"
                  label="Payment Amount"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.payment_amount}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.payment_amount && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.payment_amount}</span>}
              </Grid>

              <Grid item xs={6}>
                <FormControl
                  style={{ margin: 0 }}
                  fullWidth={true}
                  variant="outlined"
                  className={null}>

                  <InputLabel
                    htmlFor="outlined-institute-Name-native-simple">
                    Payment Type
                  </InputLabel>

                  <Select
                    onChange={formik.handleChange}
                    id="newSkill"
                    onBlur={formik.handleBlur}
                    value={formik.values.payment_type}
                    label="Payment Type"
                    name="payment_type">
                    <MenuItem value="BULK_DEAL_ADVANCE">BULK DEAL ADVANCE</MenuItem>
                    <MenuItem value="POST_PAYMENT">POST PAYMENT</MenuItem>
                  </Select>
                </FormControl>
                {formik.touched.payment_type && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.payment_type}</span>}
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid container spacing={1} >
              <Grid item xs={6}>
                <span>Payment Date</span>
                <TextField
                  name="payment_date"
                  label=""
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.payment_date}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                  type="date"
                />
                {formik.touched.payment_date && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.payment_date}</span>}
              </Grid>

              <Grid item xs={6}>
                <span>Applicable From Date</span>
                <TextField
                  name="applicable_from_date"
                  label=""
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.applicable_from_date}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                  type="date"
                />
                {formik.touched.applicable_from_date && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.applicable_from_date}</span>}
              </Grid>

              <Grid item xs={6}>
                <span>Applicable To Date</span>
                <TextField
                  name="applicable_to_date"
                  label=""
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.applicable_to_date}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                  type="date"
                />
                {formik.touched.applicable_to_date && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.applicable_to_date}</span>}
              </Grid>

            </Grid>
            <br />
            <br />
            <Grid container spacing={1} >
              <Grid item xs={6}>
                <FormControl
                  style={{ margin: 0 }}
                  fullWidth={true}
                  variant="outlined"
                  className={null}>

                  <InputLabel
                    htmlFor="outlined-institute-Name-native-simple">
                    Payment Mode
                  </InputLabel>

                  <Select
                    onChange={formik.handleChange}
                    id="newSkill"
                    onBlur={formik.handleBlur}
                    value={formik.values.payment_mode}
                    label="Payment Mode"
                    name="payment_mode">
                    <MenuItem value="ONLINE">ONLINE</MenuItem>
                    <MenuItem value="CASH">CASH</MenuItem>
                  </Select>
                </FormControl>
                {formik.touched.payment_mode && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.payment_mode}</span>}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="other_details"
                  label="Other Details"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.other_details}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.other_details && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.other_details}</span>}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="commission"
                  label="Commission"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.commission}
                  fullWidth={true}
                  variant="outlined"
                  size="medium"
                />
                {formik.touched.commission && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.commission}</span>}
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid container spacing={3} >
              <Grid item xs={12}>
                <div className="text-right">
                  <Button
                    onClick={() => setAddDialog(false)}
                    variant="contained"
                    color="secondary">
                    Close</Button>
                  <Button
                    type="submit"
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    color="primary">
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid >
        </form >
      </DialogContent>
    </Dialog>
  )

}
