import React from 'react'
import { Box,Typography } from '@material-ui/core';
import {PropTypes} from 'prop-types';
import {  makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import BillingOrder from './BillingOrder';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}

        >
            {value === index && (
                <Box p={10} style={{ padding: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}



TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({ 

    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    
}));

function BillingModule() {
    const classes = useStyles();    
    const [value, setValue] = React.useState(0);
    const[slideval,setSlideVal]=React.useState(false)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const slideHandle=()=>{
        setSlideVal(!slideval);
          
    }
      
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab style={{ textTransform: 'capitalize' }} label="Order" {...a11yProps(0)} />
                    <Tab style={{ textTransform: 'capitalize' }} label="Config" {...a11yProps(1)} />
                    <Tab style={{ textTransform: 'capitalize' }} label="Center" {...a11yProps(2)} />
                    <Tab style={{ textTransform: 'capitalize' }} label="Users" {...a11yProps(3)} />
                    <Tab style={{ textTransform: 'capitalize' }} label="Withdrawl & Deposit" {...a11yProps(4)} />
                    <Tab style={{ textTransform: 'capitalize' }} label="Account" {...a11yProps(5)} />
                    <Tab style={{ textTransform: 'capitalize' }} label="Data Download" {...a11yProps(6)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <BillingOrder/>
               
            </TabPanel>
            <TabPanel style={{ paddingBottom: 50 }} value={value} index={1}>
                
            </TabPanel >
            <TabPanel style={{ paddingBottom: 50 }} value={value} index={2}>
                
            </TabPanel >
            <TabPanel style={{ paddingBottom: 50 }} value={value} index={3}>
                
            </TabPanel >
        </div >
    )
}

export default BillingModule
