import React, { useState, useEffect } from "react";
import EditDelete from "./EditDelete";
import CreateTableAddButton from "./CreateTableAddButton";
import UpdateCoupon from "./UpdateCoupon";
import ViewCoupons from "./ViewCoupons";
import Toster from "./Toster";
import AddCoupon from "./AddCoupon";
import Loader from "./Loader";
import AddDataHeader from "./AddDataHeader";
import {
  useGetAllCouponMutation,
  useDeletCouponMutation,
  useAddCouponMutation,
  useUpdateCouponMutation,
} from "../../../services/services";
import SkeletonListing from "./SkeletonListing";
import DataNotFound from "./DataNotFound";
import moment from "moment";
import AuthWrapper from "../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../utils/getFilterFunction";

export default function Coupons() {
  const columns = [
    {
      id: "SNo",
      label: "Sr. No.",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "couponCode",
      label: "Coupon Code",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "expiryTime",
      label: "Expiry Time",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "CouponType",
      label: "Coupon Type",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "Appliedby",
      label: "Apply By",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "discountBy",
      label: "Discount By",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "maxUse",
      label: "Coupon Use",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "Action",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: [
        "COUPON_SCRATCH_CARD_COUPON_VIEW",
        "COUPON_SCRATCH_CARD_COUPON_EDIT",
        "COUPON_SCRATCH_CARD_COUPON_DELETE",
      ],
    },
  ];

  function createData(
    SNo,
    couponCode,
    expiryTime,
    CouponType,
    Appliedby,
    discountBy,
    maxUse,
    Action
  ) {
    return {
      SNo,
      couponCode,
      expiryTime: expiryTime
        ? moment(expiryTime).format("DD-MM-YYYY hh:mm:ss A")
        : "",
      CouponType,
      Appliedby,
      discountBy,
      maxUse,
      Action,
    };
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: ["coupon_code"],
    page: page + 1,
  };

  let rows = [];

  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [AddDialog, setDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [getAllData, getAllDataInfo] = useGetAllCouponMutation();
  const [deleteData, deleteDataInfo] = useDeletCouponMutation();
  const [addData, addDataInfo] = useAddCouponMutation();
  const [updateCouponData, updateDataInfo] = useUpdateCouponMutation();
  const AllData = getAllDataInfo.data;
  const totalItem = AllData ? AllData.totalItem : 0;
  const [render, setRender] = useState(false);

  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page]);

  useEffect(() => {
    getid && deleteData(getid) && setGetid("");
  }, [getid]);

  const AddData = (data) => {
    addData(data);
  };

  let totalData = "ok";
  if (AllData) {
    totalData = AllData.totalItem;
    rows = AllData.data.map((data, index) =>
      createData(
        index + 1,
        data.coupon_code,
        data.expiry_time,
        data.coupon_type,
        data.generated_by_sldUserType == "ADMIN"
          ? "ADMIN"
          : data?.generated_by_sldUser[0]?.name,
        data.discountBy ? data.discountBy : "-",
        data.max_count,
        <div className="d-flex justify-content-center">
          <AuthWrapper type="ACTION" name="COUPON_SCRATCH_CARD_COUPON_VIEW">
            <EditDelete
              setViewDialog={setViewDialog}
              type="view"
              setData={setData}
              setGetid={setGetid}
              data={data}
            />
          </AuthWrapper>

          <AuthWrapper type="ACTION" name="COUPON_SCRATCH_CARD_COUPON_EDIT">
            <EditDelete
              setEditDialog={setEditDialog}
              type="edit"
              setData={setData}
              setGetid={setGetid}
              data={data}
              onClick={() => setEditDialog(true)} // Add this line to handle edit button click
            />
          </AuthWrapper>

          <AuthWrapper type="ACTION" name="COUPON_SCRATCH_CARD_COUPON_DELETE">
            <EditDelete type="delete" setGetid={setGetid} id={data._id} />
          </AuthWrapper>
        </div>
      )
    );
  }
  const dialogclose = () => {
    setEditDialog(false);
    setViewDialog(false);
    setDialog(false);
    setRender(!render);
  };
  return (
    <div>
      {!viewDialog && (
        <AddDataHeader
          addFormName={"Add Coupon"}
          setPage={setPage}
          page={page}
          excelBtn={false}
          totalItem={totalItem}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setAddDialog={setDialog}
          searchBarApi={getAllData}
          paginationData={paginationData}
          fullsize={false}
          isButton={true}
          isSearch={
            !AuthWrapper({
              type: "ACTION",
              name: "COUPON_SCRATCH_CARD_COUPON_LIST",
              inBoolean: true,
            })
          }
          isPagination={
            !AuthWrapper({
              type: "ACTION",
              name: "COUPON_SCRATCH_CARD_COUPON_LIST",
              inBoolean: true,
            })
          }
          addBtn={
            !AuthWrapper({
              type: "ACTION",
              name: "COUPON_SCRATCH_CARD_COUPON_ADD",
              inBoolean: true,
            })
          }
          Adddialog={AddDialog}
          form={<AddCoupon AddData={AddData} setDialog={setDialog} />}
        />
      )}
      {deleteDataInfo.isLoading ||
      addDataInfo.isLoading ||
      updateDataInfo.isLoading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <AuthWrapper type="ACTION" name="COUPON_SCRATCH_CARD_COUPON_LIST">
            {!viewDialog ? (
              rows.length ? (
                <CreateTableAddButton
                  editDialog={editDialog}
                  rows={rows}
                  fullsize={true}
                  editform={
                    <UpdateCoupon
                      updateCouponData={updateCouponData}
                      editData={data}
                      editDialog={setEditDialog}
                    />
                  }
                  columns={getFilterdColumns(columns)}
                  editFormName={"Edit Coupon"}
                />
              ) : totalData == 0 ? (
                <DataNotFound />
              ) : (
                <SkeletonListing />
              )
            ) : (
              <ViewCoupons viewDialog={setViewDialog} data={data} />
            )}
          </AuthWrapper>
        </>
      )}
      {deleteDataInfo.data && (
        <Toster info={deleteDataInfo} dialogclose={dialogclose} />
      )}
      {addDataInfo.data && (
        <Toster info={addDataInfo} dialogclose={dialogclose} />
      )}
      {updateDataInfo && (
        <Toster info={updateDataInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
