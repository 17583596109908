
import React, { useState, useEffect } from 'react'
import {
    Zoom, Dialog,
    Box, Grid, Button,
    DialogContent, DialogTitle,
} from '@material-ui/core';
import { useGetUserGroupByIdQuery } from '../../../services/services';
import Loader from './Loader';

function ViewUserGroup({ viewDialog, data }) {
    let getUserGroup = useGetUserGroupByIdQuery(data._id)
    let [userGroup, setUserGroup] = useState()

    useEffect(() => {
        setUserGroup(getUserGroup.data)
    }, [getUserGroup])

    const [scroll, setScroll] = React.useState('paper');
    return (

        <Dialog

            open={viewDialog}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth
            TransitionComponent={Zoom}

        >
            <DialogTitle id="scroll-dialog-title">
                <Grid container>
                    <Grid item xs={6}>
                        Users In Group
                    </Grid>

                    <Grid item xs={6}>
                        <Button
                            onClick={() => viewDialog(false)}
                            className="text-light bg-danger float-right btn btn-sm btn-danger">
                            Close
                        </Button>
                    </Grid>
                </Grid>

            </DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
                {
                    userGroup ? (
                        userGroup.data[0].sldusers.length ? 
                        userGroup.data[0].sldusers.map((data) => {
                            return <Grid container className="p-3">
                                <Grid item xs={5}>
                                    <h5>    {data.name}</h5>
                                </Grid>
    
                                <Grid item xs={5}>
                                    {data.email}
                                </Grid>
                            </Grid>
    
                        })
                        :
                        <Box className='p-2 text-center text-muted' >NO DATA FOUND</Box>
                    )
                        : "Loading....."
                }

                {/* <div className='p-3'>
                    <Box sx={{
                        flexGrow: 1,
                        bgcolor: 'background.paper',
                        display: 'flex',
                        height: 554
                    }}>
                        {<div>

                            <h1>Name : {data.name} </h1>

                        </div>}

                        <div className="card ">
                            <div className="card-body">





                                <Grid container spacing={3} >
                                    <Grid xs={12} className='m-5 '>
                                    

                                    </Grid>

                                    <Grid xs={6} className='p-5 my-2 text-center'>
                                        <div className="text-light card bg-secondary">
                                            <div className="card-body">
                                                <h4> Group Name </h4>
                                                <h5>{data.group_name}</h5>
                                            </div>
                                        </div>
                                    </Grid>


                                    <Grid xs={6} className='p-5 my-2 text-center'>
                                        <div className="text-light card bg-success">
                                            <div className="card-body">
                                                <h4> Module Name </h4>
                                                <h5>{(data.module_group + " , ")}</h5>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid xs={6} className='p-5 my-2 text-center'>
                                        <div className="text-light card bg-info">
                                            <div className="card-body">
                                                <h4> Active </h4>
                                                <h5>{data.is_active ? "Yes" : "No"}</h5>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid xs={6} className='p-5 my-2 text-center'>
                                        <div className="text-light card bg-primary">
                                            <div className="card-body">
                                                <h4> Create At </h4>
                                                <h5>{data.createdAt}</h5>
                                            </div>
                                        </div>
                                    </Grid>



                                </Grid>

                            </div>
                        </div>
                    </Box>

                </div>
 */}


            </DialogContent>
        </Dialog >




    )
}

export default ViewUserGroup
