// **** React Imports
import React, { useEffect, useState } from "react";

//  **** Material Imports
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Grid,
  IconButton,
  Paper,
  Rating,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

// **** Icon Imports
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";

// **** Services Import
import {
  useGetallInstituteNamesQuery,
  useGetSingleVideoLectureQuery,
  useUpdateVideoLectureSingleFieldMutation,
} from "../../../../services/services";
import SkeletonListing from "../SkeletonListing";
import ButtonBaseImage from "../reusableComponents/ButtonBaseImage";
import VarientDetailsPage from "./VarientDetailsPage";
import UpdateVideoLectureSingleField from "../UpdateVideoLectureSingleField";
import Toster from "../../dashboard/Toster";
import AutoCompleteUpdateVideoLectureSingleField from "../AutoCompleteUpdateVideoLectureSingleField";
import AuthWrapper from "../../../../utils/AuthWrapper";
// **** Render Accordian Function
const renderAccordian = (label, arr, renderKey) => {
  return (
    <Table size="small" sx={{ borderRadius: "20px" }}>
      <TableBody>
        <Accordion sx={{ borderRadius: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon htmlColor="#242870" />}
            sx={{
              bgcolor: "#e0e0e0",
              borderBottom: "1px solid black",
              borderRadius: "10px",
            }}
          >
            <Typography color="#242870" variant="h6" py={0}>
              {" "}
              {label}{" "}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <Table size="small">
              <TableBody>
                {arr?.map((ele, index, array) => (
                  <TableRow>
                    <TableCell
                      sx={{
                        borderBottom: index != array.length - 1 && "1px solid",
                        bgcolor: index % 2 == 0 ? "white" : "#e7eeff",
                      }}
                    >
                      {" "}
                      <li> {ele[renderKey]} </li>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      </TableBody>
    </Table>
  );
};

// **** Main Function Starts
function ViewVideoLectureNew(props) {
  // **** States
  const [viewDetails, setViewDetails] = useState(false);
  const [varientData, setVarientData] = useState("");
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editData, setEditData] = useState("");
  const [updateData, updateDataInfo] =
    useUpdateVideoLectureSingleFieldMutation();
  const [openAutocompleteDialog, setOpenAutocompleteDialog] = useState(false);

  const [instituteNames, setInstituteNames] = useState([
    {
      label: "",
      value: "",
    },
  ]);

  // fetch institute names and set them in state variable
  const {
    data: allInstitutes,
    isFetching: isAllInstitutesFetching,
    isLoading: isAllInstitutesLoading,
  } = useGetallInstituteNamesQuery();

  useEffect(() => {
    if (!isAllInstitutesFetching && !isAllInstitutesLoading) {
      let instituteOptions = allInstitutes?.data?.map((ele) => {
        return {
          label: ele.name,
          value: ele._id,
        };
      });
      setInstituteNames(instituteOptions);
    }
  }, [allInstitutes, isAllInstitutesFetching, isAllInstitutesLoading]);

  // **** Destructuring Props
  const { data, onClose } = props;

  // **** Render Edit Button
  const editButton = (data) => {
    return (
      <Button
        size="small"
        sx={{ fontSize: "15px" }}
        onClick={() => {
          setEditData(data);
          setOpenEditDialog(true);
        }}
      >
        <EditIcon fontSize="small" /> &nbsp; Edit
      </Button>
    );
  };

  // **** Render Edit Button
  const autoCompleteButton = (data) => {
    return (
      <Button
        size="small"
        sx={{ fontSize: "15px" }}
        onClick={() => {
          setEditData(data);
          setOpenAutocompleteDialog(true);
        }}
      >
        <EditIcon fontSize="small" /> &nbsp; Edit
      </Button>
    );
  };

  // ***** Handle Switch Button Change
  const handleSwitchButton = (key, value) => {
    updateData({
      body: {
        key: key,
        update_data: value,
      },
      id: data._id,
    });
  };

  // **** Services
  const {
    data: SingleVideoLectureData,
    isFetching,
    isLoading,
  } = useGetSingleVideoLectureQuery(data._id);

  return isFetching || isLoading ? (
    <SkeletonListing />
  ) : (
    <div>
      <Grid container spacing={1} alignItems="stretch">
        <Grid item xs={12}>
          <Button p={3} size="large" onClick={onClose}>
            {" "}
            <KeyboardBackspaceIcon /> &nbsp; Video Lecture{" "}
          </Button>
        </Grid>
        <Grid item lg={8} xs={12}>
          <Paper elevation={1} sx={{ height: "100%" }}>
            <Grid container p={3}>
              {/* Product Name */}
              <Grid item xs={12}>
                {SingleVideoLectureData?.data?.video_lecture_title !=
                  undefined && (
                  <Typography variant="h5" color="primary">
                    {" "}
                    {SingleVideoLectureData?.data?.video_lecture_title}{" "}
                  </Typography>
                )}
                {SingleVideoLectureData?.data?.nickname != undefined &&
                  SingleVideoLectureData?.data?.code != undefined && (
                    <Typography variant="body1">
                      {" "}
                      {`${SingleVideoLectureData?.data?.nickname} (${SingleVideoLectureData?.data?.code}) `}{" "}
                    </Typography>
                  )}
              </Grid>

              {/* Images */}
              {SingleVideoLectureData?.data?.image != undefined && (
                <Grid item xs={12} mt={3}>
                  <Grid container columnGap={3}>
                    {SingleVideoLectureData?.data?.image?.map((img, index) => (
                      <Grid item md={2} xs={4}>
                        <Avatar
                          src={img}
                          variant="rounded"
                          sx={{ width: "100%", height: "100%" }}
                        />
                      </Grid>
                    ))}

                    <Grid
                      container
                      item
                      md={2}
                      xs={4}
                      border="1px dashed gray"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="10px"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOpenEditDialog(true);
                        setEditData({
                          type: "multi-dynamic",
                          name: "image",
                          label: "Image",
                          multiline: true,
                          initialValue: SingleVideoLectureData?.data?.image,
                        });
                      }}
                    >
                      <AddAPhotoIcon sx={{ color: "gray" }} fontSize="large" />
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {SingleVideoLectureData?.data?.imp_description != undefined && (
                <Grid item xs={12} mt={3}>
                  <Typography fullWidth variant="h6">
                    {" "}
                    Important Description &nbsp;
                    {editButton({
                      type: "input",
                      name: "imp_description",
                      label: "Important Description ",
                      initialValue:
                        SingleVideoLectureData?.data?.imp_description,
                    })}
                  </Typography>
                  <Typography
                    component="p"
                    dangerouslySetInnerHTML={{
                      __html: SingleVideoLectureData?.data?.imp_description,
                    }}
                  />
                </Grid>
              )}

              <Grid container spacing={2} mt={4}>
                {/* Courses */}
                {SingleVideoLectureData?.data?.course != undefined && (
                  <Grid item lg={2.5} md={4} xs={6}>
                    {renderAccordian(
                      "Courses",
                      SingleVideoLectureData?.data?.course,
                      "course_name"
                    )}
                  </Grid>
                )}

                {/* Levels */}
                {SingleVideoLectureData?.data?.level != undefined && (
                  <Grid item lg={2.5} md={4} xs={6}>
                    {renderAccordian(
                      "Levels",
                      SingleVideoLectureData?.data?.level,
                      "level_name"
                    )}
                  </Grid>
                )}

                {/* Categories */}
                {SingleVideoLectureData?.data?.category != undefined && (
                  <Grid item lg={3} md={4} xs={6}>
                    {renderAccordian(
                      "Categories",
                      SingleVideoLectureData?.data?.category,
                      "category_name"
                    )}
                  </Grid>
                )}

                {/* Subjects */}
                {SingleVideoLectureData?.data?.subject != undefined && (
                  <Grid item lg={4} md={4} xs={6}>
                    {renderAccordian(
                      "Subjects",
                      SingleVideoLectureData?.data?.subject,
                      "subject_name"
                    )}
                  </Grid>
                )}

                {/* Modes */}
                <Grid item xs={12} mt={3}>
                  <Grid container spacing={2}>
                    {SingleVideoLectureData?.data?.varient?.map((ele) => (
                      <Grid item md={4} xs={12}>
                        <Paper elevation={3} sx={{ p: 3 }}>
                          <Typography
                            textAlign="center"
                            color="primary"
                            variant="h6"
                            mb={2}
                          >
                            {" "}
                            {ele.mode_name}{" "}
                          </Typography>

                          <Grid container spacing={3} mt={1}>
                            {ele.varient?.map((varEle, index) => (
                              <Grid item xs={6}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    setViewDetails(true);
                                    setVarientData(varEle);
                                  }}
                                >
                                  {" "}
                                  {`Varient ${index + 1}`}{" "}
                                </Button>
                              </Grid>
                            ))}
                          </Grid>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  {viewDetails && (
                    <VarientDetailsPage
                      data={varientData}
                      videoLecture_id={data?._id}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item lg={4} xs={12}>
          <Paper elevation={1} style={{ width: "100%" }}>
            <Grid container p={3}>
              <Grid item xs={12} borderRadius="15px">
                <AuthWrapper
                  type="ACTION"
                  name="VIDEO_LECTURE_SINGLE_VIEW_LIST_TABLE_ONE"
                >
                  {" "}
                  <Table
                    sx={{ border: "1px solid", marginBottom: "20px" }}
                    size="small"
                  >
                    <p
                      style={{
                        padding: "2px",
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      Table 1 :
                    </p>
                    <TableBody>
                      {" "}
                      {/* Also Purchase */}
                      {SingleVideoLectureData?.data?.student_also_purchased !=
                        undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Also Purchase :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              {SingleVideoLectureData?.data?.student_also_purchased?.map(
                                (ele) => {
                                  return <li> {ele} </li>;
                                }
                              )}
                              &nbsp;
                              {editButton({
                                type: "multi-dynamic",
                                name: "student_also_purchased",
                                label: "Also Purchase",
                                initialValue:
                                  SingleVideoLectureData?.data
                                    ?.student_also_purchased,
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                      {/* Amendments */}
                      {SingleVideoLectureData?.data?.details_amendments !=
                        undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Amendments :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              {SingleVideoLectureData?.data
                                ?.details_amendments || "N/A"}
                              &nbsp;
                              {editButton({
                                type: "input",
                                name: "details_amendments",
                                label: "Amendments",
                                initialValue:
                                  SingleVideoLectureData?.data
                                    ?.details_amendments,
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                      {/* Combo */}
                      {SingleVideoLectureData?.data?.is_combo != undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Combo :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            <Switch
                              checked={SingleVideoLectureData?.data?.is_combo}
                            />{" "}
                          </TableCell>
                        </TableRow>
                      )}
                      {/* Hidden */}
                      {SingleVideoLectureData?.data?.is_hidden != undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Hidden :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            <Switch
                              onChange={() =>
                                handleSwitchButton(
                                  "is_hidden",
                                  !SingleVideoLectureData?.data?.is_hidden
                                )
                              }
                              checked={SingleVideoLectureData?.data?.is_hidden}
                            />{" "}
                          </TableCell>
                        </TableRow>
                      )}
                      {/* Courier Charges */}
                      {SingleVideoLectureData?.data?.courier_charges !=
                        undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Courier Charges :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              {SingleVideoLectureData?.data?.courier_charges?.map(
                                (ele) => {
                                  for (let key in ele) {
                                    return (
                                      <Typography>
                                        {" "}
                                        {key} : &#8377; {ele[key]}{" "}
                                      </Typography>
                                    );
                                  }
                                }
                              )}
                              &nbsp;
                              {editButton({
                                type: "courier_charges",
                                name: "courier_charges",
                                label: "Courier Charges",
                                initialValue:
                                  SingleVideoLectureData?.data?.courier_charges?.map(
                                    (ele) => {
                                      ele = Object.entries(ele);

                                      return {
                                        term: ele[0][0],
                                        charges: ele[0][1],
                                      };
                                    }
                                  ),
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                      {/* product explanation video url */}
                      {SingleVideoLectureData?.data
                        ?.product_explanation_video_url != undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Product Explanation Video Url :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              <Typography>
                                {
                                  SingleVideoLectureData?.data
                                    ?.product_explanation_video_url
                                }
                              </Typography>
                              &nbsp;
                              {editButton({
                                type: "input",
                                multiline: true,
                                name: "product_explanation_video_url",
                                label: "Product Explanation Video Url",
                                initialValue:
                                  SingleVideoLectureData?.data
                                    ?.product_explanation_video_url,
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                      {/* Demo Link Availability */}
                      {SingleVideoLectureData?.data?.demo_link_is_available !=
                        undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Demo Link Availability :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            <Switch
                              checked={
                                SingleVideoLectureData?.data
                                  ?.demo_link_is_available
                              }
                            />{" "}
                          </TableCell>
                        </TableRow>
                      )}
                      {/* Demo Name */}
                      {SingleVideoLectureData?.data?.demo_name != undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Demo Name :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              {SingleVideoLectureData?.data?.demo_name || "N/A"}
                              &nbsp;
                              <Grid item container justifyContent="flex-end">
                                {editButton({
                                  type: "input",
                                  name: "demo_name",
                                  label: " Demo Name ",
                                  initialValue:
                                    SingleVideoLectureData?.data?.demo_name,
                                  multiline: true,
                                })}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                      {/* Demo Link */}
                      {SingleVideoLectureData?.data?.demo_links !=
                        undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Demo Link :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              {SingleVideoLectureData?.data?.demo_links ? (
                                <a
                                  href={
                                    SingleVideoLectureData?.data?.demo_links
                                  }
                                  target="blank"
                                >
                                  {" "}
                                  View{" "}
                                </a>
                              ) : (
                                "N/A"
                              )}
                              &nbsp;
                              {editButton({
                                type: "input",
                                name: "demo_links",
                                label: "Demo Link",
                                initialValue:
                                  SingleVideoLectureData?.data?.demo_links,
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                      {/* Doubt Solving */}
                      {SingleVideoLectureData?.data
                        ?.contact_for_doubt_solving != undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Doubt Solving :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              {SingleVideoLectureData?.data
                                ?.contact_for_doubt_solving || "N/A"}
                              &nbsp;
                              {editButton({
                                type: "input",
                                name: "contact_for_doubt_solving",
                                label: "Doubt Solving",
                                initialValue:
                                  SingleVideoLectureData?.data
                                    ?.contact_for_doubt_solving,
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                      {/* Faculty Emails */}
                      {SingleVideoLectureData?.data?.faculty_multiple_email !=
                        undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Faculty Emails :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            {SingleVideoLectureData?.data
                              ?.faculty_multiple_email || "N/A"}{" "}
                          </TableCell>
                        </TableRow>
                      )}
                      {/* GD Link */}
                      {SingleVideoLectureData?.data?.gd_link != undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            GD Link :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              {SingleVideoLectureData?.data?.gd_link ? (
                                <a
                                  href={SingleVideoLectureData?.data?.gd_link}
                                  target="blank"
                                >
                                  {" "}
                                  View
                                </a>
                              ) : (
                                "N/A"
                              )}
                              &nbsp;
                              {editButton({
                                type: "input",
                                name: "gd_link",
                                label: "GD Link",
                                initialValue:
                                  SingleVideoLectureData?.data?.gd_link,
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                      {/* Index */}
                      {SingleVideoLectureData?.data?.index != undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Index :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    SingleVideoLectureData?.data?.index == ""
                                      ? "-"
                                      : SingleVideoLectureData?.data?.index.replace(
                                          /<[^>]+>/g,
                                          ""
                                        ),
                                }}
                              />
                              &nbsp;
                              {editButton({
                                type: "input",
                                name: "index",
                                label: "Index",
                                initialValue:
                                  SingleVideoLectureData?.data?.index,
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                      {/* Institute */}
                      {SingleVideoLectureData?.data?.institute
                        ?.institute_name != undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Institute :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              {
                                SingleVideoLectureData?.data?.institute
                                  ?.institute_name
                              }
                              &nbsp;
                              {autoCompleteButton({
                                type: "autoComplete", // specify the type of field
                                name: "institute", // specify the name of the field
                                label: "Invoice Institute Name", // specify the label of the field
                                initialValue: {
                                  label:
                                    SingleVideoLectureData?.data?.institute
                                      ?.institute_name,
                                  value:
                                    SingleVideoLectureData?.data?.institute
                                      ?.institute_id,
                                },
                                // pass the initial value for the field
                                options: instituteNames, // pass an array of options for the Autocomplete component
                                getOptionLabel: (option) => option.label, // pass a function to extract the label from the option object
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                      {/* Invoice Institute Name */}
                      {SingleVideoLectureData?.data?.invoice_institute_name !=
                        undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Invoice Institute Name :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              {
                                SingleVideoLectureData?.data
                                  ?.invoice_institute_name
                              }
                              &nbsp;
                              {editButton({
                                type: "input",
                                name: "invoice_institute_name",
                                label: "Invoice Institute Name",
                                initialValue:
                                  SingleVideoLectureData?.data
                                    ?.invoice_institute_name,
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                      {/* Lecture Slag */}
                      {SingleVideoLectureData?.data?.videoLecture_url_slag !=
                        undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Lecture Slag :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              {SingleVideoLectureData?.data
                                ?.videoLecture_url_slag || "N/A"}
                              &nbsp;
                              {editButton({
                                type: "input",
                                name: "videoLecture_url_slag",
                                label: "Lecture Slag",
                                initialValue:
                                  SingleVideoLectureData?.data
                                    ?.videoLecture_url_slag,
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                      {/* Meta Title */}
                      {SingleVideoLectureData?.data?.meta_title !=
                        undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Meta Title:{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              {SingleVideoLectureData?.data?.meta_title ||
                                "N/A"}
                              &nbsp;
                              {editButton({
                                type: "input",
                                name: "meta_title",
                                label: "Meta Title",
                                initialValue:
                                  SingleVideoLectureData?.data?.meta_title,
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                      {SingleVideoLectureData?.data
                        ?.view_explaination_vid_url != undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            View Explaination :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              {SingleVideoLectureData?.data
                                ?.view_explaination_vid_url ? (
                                <a
                                  href={
                                    SingleVideoLectureData?.data
                                      ?.view_explaination_vid_url
                                  }
                                  target="blank"
                                >
                                  {" "}
                                  View
                                </a>
                              ) : (
                                "N/A"
                              )}
                              &nbsp;
                              {editButton({
                                type: "input",
                                name: "view_explaination_vid_url",
                                label: "View Explaination Link",
                                initialValue:
                                  SingleVideoLectureData?.data
                                    ?.view_explaination_vid_url,
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                      {SingleVideoLectureData?.data?.unboxing_vid_links !=
                        undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Unboxing Link :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              {SingleVideoLectureData?.data?.unboxing_vid_links?.map(
                                (link) =>
                                  link ? (
                                    <li>
                                      {" "}
                                      <a href={link} target="blank">
                                        {" "}
                                        View{" "}
                                      </a>{" "}
                                    </li>
                                  ) : (
                                    <span> N/A </span>
                                  )
                              )}
                              &nbsp;
                              {editButton({
                                type: "multi-dynamic",
                                name: "unboxing_vid_links",
                                label: "Unboxing Link",
                                initialValue:
                                  SingleVideoLectureData?.data
                                    ?.unboxing_vid_links,
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </AuthWrapper>
                <AuthWrapper
                  type="ACTION"
                  name="VIDEO_LECTURE_SINGLE_VIEW_LIST_TABLE_TWO"
                >
                  <Table
                    sx={{ border: "1px solid", marginBottom: "20px" }}
                    size="small"
                  >
                    <p
                      style={{
                        padding: "2px",
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      Table 2 :
                    </p>
                    <TableBody>
                      {/* Meta */}
                      {SingleVideoLectureData?.data?.meta != undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Meta:{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              {SingleVideoLectureData?.data?.meta || "N/A"}
                              &nbsp;
                              {editButton({
                                type: "input",
                                name: "meta",
                                label: "Meta",
                                initialValue:
                                  SingleVideoLectureData?.data?.meta,
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}

                      {/* Meta */}
                      {SingleVideoLectureData?.data?.image_alt_text !=
                        undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Image Alt Text:{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              {SingleVideoLectureData?.data?.image_alt_text ||
                                "N/A"}
                              &nbsp;
                              {editButton({
                                type: "input",
                                name: "image_alt_text",
                                label: "Image Alt Text",
                                initialValue:
                                  SingleVideoLectureData?.data?.image_alt_text,
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}

                      {SingleVideoLectureData?.data?.rank != undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Rank :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            {SingleVideoLectureData?.data?.rank}{" "}
                          </TableCell>
                        </TableRow>
                      )}
                      {(SingleVideoLectureData?.data?.rating == 0 ||
                        SingleVideoLectureData?.data?.rating) && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Rating :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            <Rating
                              value={SingleVideoLectureData?.data?.rating}
                              readOnly
                            />{" "}
                          </TableCell>
                        </TableRow>
                      )}

                      {SingleVideoLectureData?.data
                        ?.contact_technical_support != undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Tachnical Support :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              {SingleVideoLectureData?.data
                                ?.contact_technical_support || "N/A"}
                              &nbsp;
                              {editButton({
                                type: "input",
                                name: "contact_technical_support",
                                label: "Tachnical Support",
                                initialValue:
                                  SingleVideoLectureData?.data
                                    ?.contact_technical_support,
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}

                      {SingleVideoLectureData?.data?.view_demo != undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            View Demo :{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              <div>
                                {SingleVideoLectureData?.data?.view_demo
                                  ? SingleVideoLectureData?.data?.view_demo
                                      ?.split(",")?.map((ele) => (
                                        <li>
                                          {" "}
                                          <a href={ele} target="blank">
                                            {" "}
                                            View{" "}
                                          </a>{" "}
                                        </li>
                                      ))
                                  : "N/A"}
                              </div>
                              &nbsp;
                              {editButton({
                                type: "input",
                                name: "view_demo",
                                label: " View Demo",
                                initialValue:
                                  SingleVideoLectureData?.data?.view_demo,
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                      {SingleVideoLectureData?.data?.sld_benefits !=
                        undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            SLD Benfits:{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    SingleVideoLectureData?.data?.sld_benefits,
                                }}
                              />
                              &nbsp;
                              {editButton({
                                type: "input",
                                name: "sld_benefits",
                                label: "SLD Benfits",
                                initialValue:
                                  SingleVideoLectureData?.data?.sld_benefits,
                                multiline: true,
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}

                      {SingleVideoLectureData?.data
                        ?.document_to_be_provided_for_order_processing !=
                        undefined && (
                        <TableRow>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            {" "}
                            Required Doucuments:{" "}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid black" }}>
                            <Grid container justifyContent="space-between">
                              {SingleVideoLectureData?.data
                                ?.document_to_be_provided_for_order_processing ||
                                "N/A"}
                              &nbsp;
                              {editButton({
                                type: "input",
                                name: "document_to_be_provided_for_order_processing",
                                label: "Required Doucuments",
                                initialValue:
                                  SingleVideoLectureData?.data
                                    ?.document_to_be_provided_for_order_processing,
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>{" "}
                    {SingleVideoLectureData?.data?.shipping_method !=
                      undefined && (
                      <TableRow>
                        <TableCell sx={{ borderBottom: "1px solid black" }}>
                          {" "}
                          Shipping Method :{" "}
                        </TableCell>
                        <TableCell sx={{ borderBottom: "1px solid black" }}>
                          <Grid container justifyContent="space-between">
                            {SingleVideoLectureData?.data?.shipping_method ||
                              "N/A"}
                            &nbsp;
                            {editButton({
                              type: "input",
                              name: "shipping_method",
                              label: "Shipping Method",
                              initialValue:
                                SingleVideoLectureData?.data?.shipping_method,
                            })}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                    {SingleVideoLectureData?.data?.allowed_payment_gateway !=
                      undefined && (
                      <TableRow>
                        <TableCell sx={{ borderBottom: "1px solid black" }}>
                          {" "}
                          Payment Gateway :{" "}
                        </TableCell>
                        <TableCell sx={{ borderBottom: "1px solid black" }}>
                          <Grid container justifyContent="space-between">
                            <ol>
                              {SingleVideoLectureData?.data?.allowed_payment_gateway?.map(
                                (ele) => (
                                  <li> {ele} </li>
                                )
                              )}
                            </ol>
                            &nbsp;
                            {editButton({
                              type: "multi-dynamic",
                              name: "allowed_payment_gateway",
                              label: "Payment Gateway",
                              initialValue:
                                SingleVideoLectureData?.data
                                  ?.allowed_payment_gateway,
                            })}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                    {SingleVideoLectureData?.data?.relevant_products !=
                      undefined && (
                      <TableRow>
                        <TableCell sx={{ borderBottom: "1px solid black" }}>
                          {" "}
                          Relevant Products :{" "}
                        </TableCell>
                        <TableCell sx={{ borderBottom: "1px solid black" }}>
                          <Grid container justifyContent="space-between">
                            {SingleVideoLectureData?.data?.relevant_products?.map(
                              (ele) => (
                                <Typography> {ele} </Typography>
                              )
                            )}
                            &nbsp;
                            {editButton({
                              type: "multi-dynamic",
                              name: "relevant_products",
                              label: "Relevant Products",
                              initialValue:
                                SingleVideoLectureData?.data?.relevant_products,
                            })}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                    {SingleVideoLectureData?.data?.contact_tracking_support !=
                      undefined && (
                      <TableRow>
                        <TableCell sx={{ borderBottom: "1px solid black" }}>
                          {" "}
                          Tracking Support :{" "}
                        </TableCell>
                        <TableCell sx={{ borderBottom: "1px solid black" }}>
                          <Grid container justifyContent="space-between">
                            {SingleVideoLectureData?.data
                              ?.contact_tracking_support || "N/A"}
                            &nbsp;
                            {editButton({
                              type: "input",
                              name: "contact_tracking_support",
                              label: "Tracking Support",
                              initialValue:
                                SingleVideoLectureData?.data
                                  ?.contact_tracking_support,
                            })}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                    {SingleVideoLectureData?.data
                      ?.validity_explaination_vid_url != undefined && (
                      <TableRow>
                        <TableCell sx={{ borderBottom: "1px solid black" }}>
                          {" "}
                          Validity Explaination :{" "}
                        </TableCell>
                        <TableCell sx={{ borderBottom: "1px solid black" }}>
                          <Grid container justifyContent="space-between">
                            {SingleVideoLectureData?.data
                              ?.validity_explaination_vid_url ? (
                              <a
                                href={
                                  SingleVideoLectureData?.data
                                    ?.validity_explaination_vid_url
                                }
                                target="blank"
                              >
                                {" "}
                                View{" "}
                              </a>
                            ) : (
                              "N/A"
                            )}
                            &nbsp;
                            {editButton({
                              type: "input",
                              name: "validity_explaination_vid_url",
                              label: "Validity Explaination Link",
                              initialValue:
                                SingleVideoLectureData?.data
                                  ?.validity_explaination_vid_url,
                            })}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                    {SingleVideoLectureData?.data?.order_google_sheet_link !=
                      undefined && (
                      <TableRow>
                        <TableCell sx={{ borderBottom: "1px solid black" }}>
                          {" "}
                          Order Google Sheet Link :{" "}
                        </TableCell>
                        <TableCell sx={{ borderBottom: "1px solid black" }}>
                          {SingleVideoLectureData?.data
                            ?.order_google_sheet_link ? (
                            <a
                              href={
                                SingleVideoLectureData?.data
                                  ?.order_google_sheet_link
                              }
                              target="blank"
                            >
                              {" "}
                              View{" "}
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                    {SingleVideoLectureData?.data?.additional_requirement !=
                      undefined && (
                      <TableRow>
                        <TableCell sx={{ borderBottom: "1px solid black" }}>
                          {" "}
                          Additional Requirements :{" "}
                        </TableCell>
                        <TableCell sx={{ borderBottom: "1px solid black" }}>
                          <Grid container justifyContent="space-between">
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  SingleVideoLectureData?.data?.additional_requirement.replace(
                                    /<[^>]+>/g,
                                    ""
                                  ),
                              }}
                            />
                            &nbsp;
                            {editButton({
                              type: "input",
                              name: "additional_requirement",
                              label: "Additional Requirement",
                              initialValue:
                                SingleVideoLectureData?.data
                                  ?.additional_requirement,
                            })}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                    {SingleVideoLectureData?.data?.remark != undefined && (
                      <TableRow>
                        <TableCell sx={{ borderBottom: "1px solid black" }}>
                          {" "}
                          Remark :{" "}
                        </TableCell>
                        <TableCell sx={{ borderBottom: "1px solid black" }}>
                          <Grid container justifyContent="space-between">
                            {SingleVideoLectureData?.data?.remark}
                            &nbsp;
                            {editButton({
                              type: "input",
                              name: "remark",
                              label: "Remark",
                              initialValue:
                                SingleVideoLectureData?.data?.remark,
                            })}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                  </Table>
                </AuthWrapper>
                {/* Duration */}
                {/* <TableRow  >
                                                <TableCell sx={{ borderBottom: "1px solid black" }} >  Duration : </TableCell>
                                                <TableCell sx={{ borderBottom: "1px solid black" }}  >
                                                    <Grid container justifyContent='space-between' >

                                                        {SingleVideoLectureData?.data?.duration_of_lecture} &nbsp; {editButton({
                                                            type: "input",
                                                            label: "Duration",
                                                            name: "duration_of_lecture",
                                                            initialValue: SingleVideoLectureData?.data?.duration_of_lecture
                                                        })}
                                                    </Grid>
                                                </TableCell>
                                            </TableRow> */}

                {/* Forward Speed */}
                {/* <TableRow>
                                                <TableCell sx={{ borderBottom: "1px solid black" }} > Forward Speed : </TableCell>
                                                <TableCell sx={{ borderBottom: "1px solid black" }} >

                                                    <Grid container justifyContent='space-between' >

                                                        {SingleVideoLectureData?.data?.fast_forward_speed}

                                                        &nbsp;

                                                        {editButton({
                                                            type: "input",
                                                            name: "fast_forward_speed",
                                                            label: "Forward Speed",
                                                            initialValue: SingleVideoLectureData?.data?.fast_forward_speed
                                                        })

                                                        }

                                                    </Grid>
                                                </TableCell>
                                            </TableRow> */}

                {/* Internet */}
                {/* <TableRow>
                                                <TableCell sx={{ borderBottom: "1px solid black" }} > Internet : </TableCell>
                                                <TableCell sx={{ borderBottom: "1px solid black" }} >   {SingleVideoLectureData?.data?.internet_connectivity} </TableCell>
                                            </TableRow> */}

                {/* Institute */}

                {/* In Offer */}
                {/* <TableRow>
                                                <TableCell sx={{ borderBottom: "1px solid black" }} > In Offer : </TableCell>
                                                <TableCell sx={{ borderBottom: "1px solid black" }} > <Switch  checked={SingleVideoLectureData?.data?.in_offer} /> </TableCell>
                                            </TableRow> */}

                {/* Kit Content */}
                {/* <TableRow>
                                                <TableCell sx={{ borderBottom: "1px solid black" }} > Kit Content : </TableCell>
                                                <TableCell sx={{ borderBottom: "1px solid black" }} >
                                                    <Grid container justifyContent='space-between' >

                                                        {SingleVideoLectureData?.data?.kit_content}

                                                        &nbsp;

                                                        {editButton({
                                                            type: "input",
                                                            name: "kit_content",
                                                            label: "Kit Content",
                                                            initialValue: SingleVideoLectureData?.data?.kit_content,
                                                            multiline: true
                                                        })

                                                        }
                                                    </Grid>

                                                </TableCell>
                                            </TableRow> */}

                {/* Lecture Recorded On */}
                {/* <TableRow>
                                                <TableCell sx={{ borderBottom: "1px solid black" }} > Lecture Recorded On: </TableCell>
                                                <TableCell sx={{ borderBottom: "1px solid black" }} >
                                                    <Grid container justifyContent='space-between' >

                                                        {SingleVideoLectureData?.data?.lecture_recorded_on}

                                                        &nbsp;

                                                        {editButton({
                                                            type: "input",
                                                            name: "lecture_recorded_on",
                                                            label: "Lecture Recorded On",
                                                            initialValue: SingleVideoLectureData?.data?.lecture_recorded_on
                                                        })

                                                        }
                                                    </Grid>
                                                </TableCell>
                                            </TableRow> */}

                {/* <TableRow>
                                                <TableCell sx={{ borderBottom: "1px solid black" }} > Topics Covered: </TableCell>
                                                <TableCell sx={{ borderBottom: "1px solid black" }} >

                                                    <Grid container justifyContent='space-between' >

                                                        {SingleVideoLectureData?.data?.topics_covered}

                                                        &nbsp;

                                                        {editButton({
                                                            type: "input",
                                                            name: "topics_covered",
                                                            label: "Topics Covered",
                                                            initialValue: SingleVideoLectureData?.data?.topics_covered
                                                        })

                                                        }
                                                    </Grid>
                                                </TableCell>
                                            </TableRow> */}

                {/* <TableRow>
                                                <TableCell sx={{ borderBottom: "1px solid black" }} > Runs On : </TableCell>
                                                <TableCell sx={{ borderBottom: "1px solid black" }} >
                                                    <Grid container justifyContent='space-between' >

                                                        {SingleVideoLectureData?.data?.video_runs_on}

                                                        &nbsp;

                                                        {editButton({
                                                            type: "input",
                                                            name: "video_runs_on",
                                                            label: "Runs On",
                                                            initialValue: SingleVideoLectureData?.data?.video_runs_on
                                                        })

                                                        }

                                                    </Grid>
                                                </TableCell>
                                            </TableRow> */}

                {/* <TableRow>
                                                <TableCell sx={{ borderBottom: "1px solid black" }} > System Requirement : </TableCell>
                                                <TableCell sx={{ borderBottom: "1px solid black" }} >
                                                    <Grid container justifyContent='space-between' >


                                                        <p dangerouslySetInnerHTML={{ __html: SingleVideoLectureData?.data?.system_requirements }} />

                                                        &nbsp;

                                                        {editButton({
                                                            type: "input",
                                                            name: "system_requirements",
                                                            label: "System Requirement",
                                                            initialValue: SingleVideoLectureData?.data?.system_requirements,
                                                            multiline: true
                                                        })

                                                        }
                                                    </Grid>
                                                </TableCell>
                                            </TableRow> */}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {openEditDialog && (
        <UpdateVideoLectureSingleField
          type={editData.type}
          label={editData.label}
          name={editData.name}
          onClose={() => setOpenEditDialog(false)}
          initialValue={editData.initialValue}
          multiline={editData.multiline}
          id={SingleVideoLectureData?.data?._id}
        />
      )}
      {openAutocompleteDialog && (
        <AutoCompleteUpdateVideoLectureSingleField
          type={editData.type}
          label={editData.label}
          name={editData.name}
          onClose={() => setOpenAutocompleteDialog(false)}
          initialValue={editData.initialValue}
          id={SingleVideoLectureData?.data?._id}
          options={editData.options}
          getOptionLabel={editData.getOptionLabel}
        />
      )}
      {updateDataInfo.data && <Toster info={updateDataInfo} />}
    </div>
  );
}

export default ViewVideoLectureNew;
