// **** React Imports
import React from 'react'

// **** Formik Imports
import { ErrorMessage, Field, Form, Formik } from 'formik'

// **** Yup Import
import * as Yup from "yup"

// **** Material Imports
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, TextField } from '@mui/material'

// **** Other Imports
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import TimePicker from '@mui/lab/TimePicker'
import moment from 'moment'

function EditBatchTiming(props) {

    // **** Destructuring of Props
    const { open, onClose, onSubmit, initialData } = props

    // **** Form Initial Values
    const initialValues = {
        batch_time_from: moment(initialData.batch_time_from, "hh:mm A").format("ddd DD-MMM-YYYY, hh:mm:ss"),
        batch_time_to: moment(initialData.batch_time_to, "hh:mm A").format("ddd DD-MMM-YYYY, hh:mm:ss"),
    }

    // **** Validation Schema
    const validationSchema = Yup.object({
        batch_time_from: Yup.date().required("This field is required !"),
        batch_time_to: Yup.date().required("This field is required !")
    })

    // **** On Submit Handler
    const onSubmitHandler = (values) => {
        values = {
            ...values,
            batch_time_from: moment(values.batch_time_from).format("hh:mm a"),
            batch_time_to: moment(values.batch_time_to).format("hh:mm a"),
        }
        onSubmit(values)
          

    }

    // **** Main Function Return
    return (
        <div>
            <Dialog open={open} onClose={() => onClose()} disableEnforceFocus maxWidth="md" >
                <DialogTitle sx={{ bgcolor: "#5664d2", borderBottomRightRadius: "20px" }} className="text-white"  >
                    Edit Batch Timing
                </DialogTitle>
                <DialogContent>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        // validationSchema={validationSchema}
                        onSubmit={onSubmitHandler}
                    >
                        {
                            ({ values, setFieldValue }) => (
                                <Form >
                                    <Grid container spacing={3} className="mt-3" >

                                        {/* Batch Time From */}
                                        <Grid item xs={12}>
                                            <InputLabel htmlFor='batch_time_from' className='text-dark' > Batch Time From  </InputLabel>
                                            <Field name="batch_time_from">
                                                {
                                                    ({ field }) => (
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <TimePicker
                                                                id="batch_time_from"
                                                                value={values.batch_time_from}
                                                                onChange={newValue => setFieldValue("batch_time_from", newValue)}
                                                                renderInput={params => <TextField fullWidth variant='standard' {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    )
                                                }
                                            </Field>

                                            <ErrorMessage name='batch_time_from'>
                                                {
                                                    (errMsg) => <span className='text-danger'> {errMsg}  </span>
                                                }
                                            </ErrorMessage>
                                        </Grid>

                                        {/* Batch Time To */}
                                        <Grid item xs={12}>
                                            <InputLabel htmlFor='batch_time_to' className='text-dark' > Batch Time To  </InputLabel>
                                            <Field name="batch_time_to">
                                                {
                                                    ({ field }) => (
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <TimePicker
                                                                value={values.batch_time_to}
                                                                // minTime={new Date(0, 0, 0, 8)}
                                                                // maxTime={new Date(0, 0, 0, 18, 45)}
                                                                onChange={newValue => setFieldValue("batch_time_to", newValue)}
                                                                renderInput={params => <TextField fullWidth variant='standard' {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    )
                                                }
                                            </Field>
                                            <ErrorMessage name='batch_time_to'>
                                                {
                                                    (errMsg) => <span className='text-danger'> {errMsg}  </span>
                                                }
                                            </ErrorMessage>
                                        </Grid>

                                        <Grid container item xs={12} justifyContent="flex-end" mt={2}  >
                                            <Grid item xs={3}>
                                                <Button fullWidth variant='contained' onClick={() => onClose()} color="error" > cancel </Button>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Button fullWidth variant='contained' className='ml-2' type='submit' sx={{ backgroundColor: "#5664d2" }} > Save </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }
                    </Formik>

                </DialogContent>
            </Dialog>
        </div>
    )
}

export default EditBatchTiming
