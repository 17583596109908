import React, { useEffect, useState } from "react";
import { Button, Grid, Typography, TextField, Checkbox } from "@material-ui/core";
import {
    useSldUserResetPasswordByAdminMutation,
    useGetAllSldUsersListQuery
} from "../../../../services/services"
import * as yup from "yup";
import { ErrorMessage, FieldArray, Formik, Field, Form } from "formik";
import Toster from "../Toster";
import Autocomplete from '@mui/material/Autocomplete';

export default function SldUsersChangePassword() {

    const [updatePassword, updatePasswordInfo] = useSldUserResetPasswordByAdminMutation();
    const allSldUsersList = useGetAllSldUsersListQuery()
    const [allSldUsers, setAllSldUsers] = useState()

    useEffect(() => {
        let { isSuccess, data } = allSldUsersList;
        if (isSuccess) {
            setAllSldUsers(data.data)
        }
    }, [allSldUsersList])

    const initialValues = {
        sld_user_id: null,
        new_password: "",
        confirm_password: ""
    };

    const validationSchema = yup.object({
        sld_user_id: yup.object().required("Required!").nullable(),
        new_password: yup.string().required("Required!"),
        confirm_password: yup.string().oneOf([yup.ref('new_password'), null], "New passwords don't match!").required("Required!")
    });

    const onSubmit = (values, { resetForm }) => {
        updatePassword({ ...values, sld_user_id: values.sld_user_id._id }).then((res) => {
            if (res?.data?.status) {
                resetForm()
            }
        }).catch((err) => {
              
        })
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {
                    ({ values, setFieldValue }) => {
                        return <Form>
                            <Grid
                                style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    marginTop: 20,
                                    marginLeft: 10,
                                }}
                            >
                                <Typography
                                    //className={classes.title}
                                    variant="h6"
                                    color="primary"
                                    gutterBottom
                                >
                                    Change Your Password
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <Field name="sld_user_id">
                                            {(props) => {
                                                const { field, va } = props;
                                                return (
                                                    <Autocomplete
                                                        variant="outlined"
                                                        fullWidth={true}
                                                        value={values.sld_user_id}
                                                        onChange={(e, newValue) => {
                                                            setFieldValue("sld_user_id", newValue)
                                                        }}
                                                        isOptionEqualToValue={(option, value) => {
                                                            return option._id === value._id
                                                        }}
                                                        options={allSldUsers}
                                                        getOptionLabel={(option) => option["name"]}
                                                        renderOption={(props, option) => (
                                                            <li {...props}>
                                                                {option["name"]}
                                                            </li>
                                                        )}
                                                        // style={{ width: 500 }}
                                                        renderInput={(params) => (
                                                            <TextField label="Choose User" variant="outlined" {...params} />
                                                        )}
                                                    />
                                                );
                                            }}
                                        </Field>
                                        <ErrorMessage name="sld_user_id">
                                            {(errorMsg) => (
                                                <span className="text-danger"> {errorMsg} </span>
                                            )}
                                        </ErrorMessage>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Field name="new_password">
                                            {(props) => {
                                                const { field } = props;
                                                return (
                                                    <TextField
                                                        {...field}
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                        label="New Password"
                                                        minRows={2}
                                                        multiline
                                                    />
                                                );
                                            }}
                                        </Field>
                                        <ErrorMessage name="new_password">
                                            {(errorMsg) => (
                                                <span className="text-danger"> {errorMsg} </span>
                                            )}
                                        </ErrorMessage>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Field name="confirm_password">
                                            {(props) => {
                                                const { field } = props;
                                                return (
                                                    <TextField
                                                        {...field}
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                        label="Confirm Password"
                                                        minRows={2}
                                                        multiline
                                                    />
                                                );
                                            }}
                                        </Field>
                                        <ErrorMessage name="confirm_password">
                                            {(errorMsg) => (
                                                <span className="text-danger"> {errorMsg} </span>
                                            )}
                                        </ErrorMessage>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="text-right">
                                            <Button
                                                type="submit"
                                                style={{ marginLeft: 20 }}
                                                variant="contained"
                                                color="primary"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    }
                }
            </Formik>
            {
                updatePasswordInfo.data && (
                    <Toster info={updatePasswordInfo} />
                )
            }
        </>
    );
}
