import React, { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function Toster({ info, dialogclose }) {
    useEffect(() => {
        let { status,isLoading } = info
        if (status == 'fulfilled') {
            let { status, message, msg,res } = info.data
            if (!status) {
                message != undefined ? notify(message) : notify(msg)
            } else {
                message != undefined ? notify(message) : res != undefined ? notify(res) : notify(msg)
                dialogclose && dialogclose()
            }
        }
    }, [info])
    const notify = (msg) => toast(msg);
    return (
        <div>
            <ToastContainer />
        </div>
    )
}
