import React, { useState, useEffect } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import AddDataHeader from "../AddDataHeader";
import DataNotFound from "../DataNotFound";
import FormDialog from "../reusableComponents/NewFormDialog";
import { useGetConfigQuery, useAddConfigMutation, useUpdateConfigDataMutation, useDeleteConfigDataMutation } from "../../../../services/offlineModuleServices/configServices";

export default function TermsAndConditions() {

    let [getid, setGetid] = useState("");
    const [data, setData] = useState("");
    const [addDialog, setAddDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [render, setRender] = useState(false)

    const { data: AllTermsAndConditions, isAllTermsAndConditionsFetching, isAllTermsAndConditionsLoading } = useGetConfigQuery({
        key: "instructions_to_follow",
        limit: "",
        page: "",
    })
    const [addTermsAndConditions, addTermsAndConditionsInfo] = useAddConfigMutation()
    const [updateTermsAndConditions, updateTermsAndConditionsInfo] = useUpdateConfigDataMutation()
    const [deleteTermsAndConditions, deleteTermsAndConditionsInfo] = useDeleteConfigDataMutation()


    const columns = [
        {
            id: "SNo",
            label: "S.No",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "termsAndConditions",
            label: "Terms & Conditions",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "actions",
            label: "Actions",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
    ];
    function createData(SNo, termsAndConditions, actions) {
        return {
            SNo,
            termsAndConditions,
            actions,
        };
    }
    let rows = [];
    const totalItem = AllTermsAndConditions ? AllTermsAndConditions.totalItem : 0;

    let totalData = "ok";


    useEffect(() => {
        getid && deleteTermsAndConditions({
            key: "instructions_to_follow",
            configData: {
                _id: getid
            }
        }) && setGetid("");
    }, [getid]);

    if (
        AllTermsAndConditions?.status
    ) {
        totalData = AllTermsAndConditions.totalItem;
        rows = AllTermsAndConditions.data.map((ele, index) => {
            return createData(
                index + 1,
                ele.instruction,
                <div>
                    <EditDelete
                        setEditDialog={setEditDialog}
                        type="edit"
                        setData={setData}
                        data={ele}
                    />
                    <EditDelete type="delete" setGetid={setGetid} id={ele._id} />
                </div>
            );
        });
    }else{
        totalData= 0
    }


    const dialogclose = () => {
        setEditDialog(false);
        setAddDialog(false);
        setRender(!render);
    };

    const updateBtnClickHandler = (dataForUpdate) => {
        updateTermsAndConditions({
            key: "instructions_to_follow",
            configData: {
                is_active: true,
                _id: data._id,
                instruction: dataForUpdate.instruction
            }
        });
    };

    const addBtnClickHandler = (dataForAdd) => {
        addTermsAndConditions({
            key: "instructions_to_follow",
            configData: {
                is_active: true,
                instruction: dataForAdd.instruction
            }
        })
    }


    return (
        <div>
            {/* {!viewDialog && ( */}
            <AddDataHeader
                addFormName={"Add New Terms"}
                isSearch={true}
                totalItem={totalItem}
                isPagination={true}
                setAddDialog={setAddDialog}
                isButton={true}
                Adddialog={addDialog}
                form={
                    <FormDialog
                        open={addDialog}
                        setOpen={setAddDialog}
                        formName={"Add New Terms"}
                        btnName={"Save Terms"}
                        onBtnClick={addBtnClickHandler}
                        fields={[
                            {
                                fieldType: "text",
                                fieldName: "Terms",
                                initialValue: "",
                                //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                                accessKey: "instruction",
                            },
                        ]}
                    />
                }
            />
            {/* )} */}


            <>
                {rows.length ? (
                    <CreateTableAddButton
                        editDialog={editDialog}
                        fullWidth= {false}
                        // setPage={setPage}
                        rows={rows}
                        editform={
                            <FormDialog
                                useAs= "form"
                                open={editDialog}
                                setOpen={setEditDialog}
                                formName={"Edit Terms  "}
                                btnName={"Update Terms"}
                                onBtnClick={updateBtnClickHandler}

                                fields={[
                                    {
                                        fieldType: "text",
                                        fieldName: "Terms",
                                        initialValue: data.instruction,
                                        //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                                        accessKey: "instruction",
                                    },
                                ]}
                            />
                        }
                        columns={columns}
                        isButton={true}
                        editFormName={"Edit Terms"}
                    />
                ) : totalData == 0 ? (
                    <DataNotFound />
                ) : (
                    <SkeletonListing />
                )}

            </>





            {addTermsAndConditionsInfo && (
                <Toster info={addTermsAndConditionsInfo} dialogclose={dialogclose} />
            )}

            {updateTermsAndConditionsInfo && (
                <Toster info={updateTermsAndConditionsInfo} dialogclose={dialogclose} />
            )}

            {deleteTermsAndConditionsInfo && (
                <Toster info={deleteTermsAndConditionsInfo} dialogclose={dialogclose} />
            )}

        </div>
    );
}
