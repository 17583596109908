import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import { Grid, Button, Card } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import { useGetAllmailblurbQuery } from "../../../services/EmailServices";
import Autocomplete from "@mui/material/Autocomplete";
import SendEmailDetails from "./SendEmailDetails";
import AuthWrapper from "../../../utils/AuthWrapper";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 800,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    minWidth: 100,
  },
  tab: {
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 100,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function CustomEmail() {
  const [mailType, setMailType] = useState({
    mail_type: "",
    cc: "",
    bcc: "",
    _id: "",
    subject: "",
    from: "",
  });
  const { data: allMailBlurbLists } = useGetAllmailblurbQuery();

  let mailTypeOptions = [];
  if (allMailBlurbLists) {
    mailTypeOptions = allMailBlurbLists.data.map((ele) => {
      return {
        mail_type: ele.mail_type,
        cc: ele.cc,
        bcc: ele.bcc,
        _id: ele._id,
        subject: ele.subject,
        from: ele.from,
      };
    });
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "80vh" }}
    >
      <Grid item className="d-flex align-items-center">
        <Card
          elevation={24}
          className="p-4"
          style={{
            display: "block",
            width: "30vw",
            transitionDuration: "0.3s",
          }}
        >
          <h5 className="border-bottom pb-2"> Email Details </h5>
          <Autocomplete
            size="large"
            fullWidth={true}
            className="mb-4"
            onChange={(event, newValue) => {
              setMailType(newValue);
            }}
            value={mailType}
            options={mailTypeOptions}
            getOptionLabel={(option) => option.mail_type}
            renderInput={(params) => {
              return <TextField {...params} label="Mail Type" />;
            }}
          />
          <AuthWrapper type="ACTION" name="CUSTOM_EMAIL_SEND_EMAIL_ADD">
            {mailType?.mail_type != "" && (
              <SendEmailDetails mailType={mailType} setMailType={setMailType} />
            )}
          </AuthWrapper>
        </Card>
      </Grid>
    </Grid>
  );
}
