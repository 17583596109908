import React from 'react'
import '../../.././ViewBook.css'
import { Button, makeStyles, withStyles, } from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TitleIcon from '@material-ui/icons/Title';
import SubjectIcon from '@material-ui/icons/Subject';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LayersIcon from '@material-ui/icons/Layers';
import DateRangeIcon from '@mui/icons-material/DateRange';
import moment from "moment";
import BarChartIcon from '@mui/icons-material/BarChart';
import OutboxIcon from '@mui/icons-material/Outbox';
import InboxIcon from '@mui/icons-material/Inbox';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: 18,
        flexBasis: '33.33%',
        flexShrink: 0,

    },
    table: {
        padding: 10,
        margin: 5

    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));


export default function ViewEmail({ viewDialog, data }) {
   var data=data.data
    const classes = useStyles();

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    return (
        <div className="d-flex justify-content-center my-3">

            <Button onClick={() => {
                viewDialog(false)
            }} style={{ position: "absolute", right: -10, zIndex: 1000, top: 120 }}>
                <HighlightOffIcon color='secondary' />
            </Button>

            <TableContainer style={{ maxWidth: "90%" }} className="m-2 border">
                <Table className={classes.table} aria-label="customized table">
                    <TableBody>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><InboxIcon className="mx-4" fontSize='medium' /> Email From </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted"> {data.from} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><OutboxIcon className="mx-4" fontSize='medium' /> Send To </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted"> {data.sendTo} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><TitleIcon className="mx-4" fontSize='medium' />  Subject </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted"> {data.subject?data.subject:data.emailSubject} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><VpnKeyIcon className="mx-4" fontSize='medium' />   cc </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted"> {data.cc} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><SubjectIcon className="mx-4" fontSize='medium' />   bcc </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted"> {data.bcc} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><CollectionsBookmarkIcon className="mx-4" fontSize='medium' />   Mail Type </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted"> {data.mail_type?data.mail_type:data.emailType} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><LayersIcon className="mx-4" fontSize='medium' /> Body </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted"> {data.body?data.body.replace(/<[^>]+>/g, ''):data.emailBody.replace(/<[^>]+>/g, '')} </p>
                                {/* {data.emailBody.replace(/<[^>]+>/g, '')} */}
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><DateRangeIcon className="mx-4" fontSize='medium' /> Created At </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted"> {moment(data.createdAt).format("DD-MM-YYYY hh:mm:ss A")} </p>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell className="m-1 " align="left">
                                <p className="d-flex align-items-center "><BarChartIcon className="mx-4" fontSize='medium' /> Status </p>
                            </StyledTableCell>
                            <StyledTableCell className="m-1" align="left">
                                <p className="d-flex align-items-center text-muted"> {data.status} </p>
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
