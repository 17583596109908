import React, { Component } from 'react'
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import TheSidebar from './TheSidebar';

import { TheContent, TheHeader } from './index';

const drawerWidth = 248;

const useStyles = theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: {
        ...theme.mixins.toolbar,
        paddingLeft: "15px",
        paddingRight: "15px",
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
});

class TheLayout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false
        }
    }

    handleDrawerOpen = () => {
        this.setState({ open: true })
    };

    handleDrawerClose = () => {
        this.setState({ open: false })
    };

    handleDrawerToggle = () => {
        this.setState({ open:!this.state.open })
    };


    render() {
        const { classes } = this.props;
        const { theme } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <div className={classes.root}>
                    <CssBaseline />
                    <TheHeader open={this.state.open} theme={this.props.theme} classes={this.props.classes} handleDrawerOpen={this.handleDrawerToggle} />
                    <TheSidebar theme={this.props.theme} classes={this.props.classes} open={this.state.open} handleDrawerClose={this.handleDrawerClose} />
                    
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        <TheContent />
                    </main>                    

                </div>
            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles, { withTheme: true })(TheLayout);