import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const centersApi = createApi({
    tagTypes: ["center"],
    reducerPath: "centersApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/center/`,
    }),
    endpoints: (builder) => ({
        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET "center" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        GetAllCenter: builder.query({
            providesTags: ["center"],
            query: () => {
                return {
                    url: "get-all-center", //******** *
                    headers: {
                        "x-access-token": token,
                    },
                    method: "GET",
                };
            },
        }),

        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ADD "center" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        AddCenter: builder.mutation({
            invalidatesTags: ["center"],
            query: (body) => {
                return {
                    url: "add-center", // ***********
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,
                };
            },
        }),


        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< UPDATE "center" DATA SECTION >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        UpdateCenter: builder.mutation({
            invalidatesTags: ["center"],
            query: ({ body, id }) => {
                return {
                    url: id, //********** *
                    headers: {
                        "x-access-token": token,
                    },
                    method: "PUT",
                    body,
                };
            },
        }),


        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< DELETE DATA IN "center" SECTION >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        DeleteCenter: builder.mutation({
            invalidatesTags: ["center"],
            query: (id) => {
                return {
                    url: id, //********** *
                    headers: {
                        "x-access-token": token,
                    },
                    method: "DELETE",
                };
            },
        }),


        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Change Default Center  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        ChangeDefaultCenter: builder.mutation({
            invalidatesTags: ["center"],
            query: (id) => {
                return {
                    url: `default-invoice/${id}`, //********** *
                    headers: {
                        "x-access-token": token,
                    },
                    method: "PUT",
                };
            },
        }),


    }),
});

export const {
    useGetAllCenterQuery,
    useAddCenterMutation,
    useUpdateCenterMutation,
    useDeleteCenterMutation,
    useChangeDefaultCenterMutation , 
} = centersApi;


