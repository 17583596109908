import React, { useState, useEffect } from "react";
import EditDelete from "./EditDelete";
import CreateTableAddButton from "./CreateTableAddButton";
import {
  withStyles,
  Switch,
  TableContainer,
  Paper,
  TableHead,
  Table,
  TableRow,
  TableCell,
  makeStyles,
  TableBody,
} from "@material-ui/core";
import UpdateFaculty from "./faculty/UpdateFaculty";
import Loader from "./Loader";
import AddDataHeader from "./AddDataHeader";
import {
  useDeleteReviewsMutation,
  useGetAllReviewsMutation,
  useGetReviewsByIdMutation,
  useUpdateReviewsMutation,
} from "../../../services/ReviewServices";
import Toster from "./Toster";
import SkeletonListing from "./SkeletonListing";
import DataNotFound from "./DataNotFound";
import AuthWrapper from "../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../utils/getFilterFunction";
import moment from "moment";
import ViewQuestion from "./Questions/View/ViewQuestion";
import ViewReviews from "./ViewReviews";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.light,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 10,
  },
});

export default function Reviews() {
  const classes = useStyles();
  const columns = [
    {
      id: "SNO",
      label: "SNO",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "createdAt",
      label: "Created At",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "username",
      label: "User Name",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "producttype",
      label: "Product Type",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "varifieduser",
      label: "User Varified",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "reviewsubject",
      label: "Over All Rating",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "stars",
      label: "Stars",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "rating",
      label: (
        <TableContainer className="" component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell className="p-1 videoModeHeading">
                  doubt
                  <br />
                  solving
                  <br />
                  stars
                </StyledTableCell>
                <StyledTableCell className="p-1 videoModeHeading">
                  lecture
                  <br />
                  coverage
                  <br />
                  stars
                </StyledTableCell>
                <StyledTableCell className="p-1 videoModeHeading">
                  study
                  <br />
                  material
                  <br />
                  stars
                </StyledTableCell>
                <StyledTableCell className="p-1 videoModeHeading">
                  teaching
                  <br />
                  style
                  <br />
                  stars
                </StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      ),
      minWidth: 50,
      align: "center",
    },
    {
      id: "feedback",
      label: "Feedback",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "videolecturetitle",
      label: "title",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "approved_user",
      label: "Approved status / Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: [
        "REVIEW_QUESTIONS_REVIEW_APPROVE",
        "REVIEW_QUESTIONS_REVIEW_DELETE",
      ],
    },
  ];
  function createData(
    SNO,
    createdAt,
    username,
    producttype,
    varifieduser,
    reviewsubject,
    stars,
    rating,
    feedback,
    videolecturetitle,
    approved_user,
    isApproved,
    actions
  ) {
    return {
      SNO,
      createdAt,
      username,
      producttype,
      varifieduser,
      reviewsubject,
      stars,
      rating,
      feedback,
      videolecturetitle,
      approved_user,
      isApproved,
      actions,
    };
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: [
      "videos_lecture_title",
      "book_product_title",
      "product_type",
      "user_name",
      "approval",
      "varified_user",
      "stars",
      "review_subject",
      "review_discription",
      "feedback",
    ],
    page: page + 1,
    filterBy: [],
  };

  let rows = [];
  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [AddDialog, setDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [getAllData, getAllDataInfo] = useGetAllReviewsMutation();
  const [updateData, updateDataInfo] = useUpdateReviewsMutation();
  const allReviews = getAllDataInfo.data;
  const totalItem = allReviews ? allReviews.totalItem : 0;
  const [render, setRender] = useState(false);
  const [deleteData, deleteDataInfo] = useDeleteReviewsMutation();

  const [singleDataId, setSingleDataId] = useState("");
  
  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page]);

  useEffect(() => {
    getid && deleteData(getid) && setGetid("");
  }, [getid]);

  const UpdateData = (data) => {
    updateData(data);
  };
  let totalData = "ok";
  if (allReviews) {
    totalData = allReviews.totalItem;
    rows = allReviews?.data?.map((data, index) => {

      let tableComponent;

      switch (data.product_type) {
        case "SLD_VIDEO_LECTURE":
          tableComponent = (
            <TableContainer component={Paper} style={{ maxHeight: 130 }}>
              <Table className={classes.table} aria-label="customized table">
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      className="videoModeHeading"
                      component="th"
                      scope="row"
                    >
                      {data.review_discription.doubt_solving}
                    </StyledTableCell>
                    <StyledTableCell className="m-1 videoModeHeading">
                      {data.review_discription.lecture_coverage}
                    </StyledTableCell>
                    <StyledTableCell className="m-1 videoModeHeading">
                      {data.review_discription.study_material}
                    </StyledTableCell>
                    <StyledTableCell className="m-1 videoModeHeading">
                      {data.review_discription.teaching_style}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          );
          break;

        case "SLD_BOOK_PRODUCT":
        case "SLD_TEST_SERIES":
          tableComponent = (
            <TableContainer component={Paper} style={{ maxHeight: 130 }}>
              <Table className={classes.table} aria-label="customized table">
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      className="bookRating"
                      component="th"
                      scope="row"
                    >
                      {data.bookRating}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          );
          break;

        // Add more cases if needed

        default:
          // Handle other cases or provide a default component
          tableComponent = null;
      }

      return createData(
        index + 1,
        // <div className="d-flex justify-content-center">
        //     <Avatar src={data.image} />
        // </div>,
        moment(data?.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        data.user_name,

        data.product_type.replaceAll("_", " "),
        data.varified_user ? "YES" : "NO",
        data.reviewsubject ? data?.reviewsubject : "-",
        data.stars,
        tableComponent,
        // data.product_type == "SLD_VIDEO_LECTURE" ? (
        //   <TableContainer component={Paper} style={{ maxHeight: 130 }}>
        //     <Table className={classes.table} aria-label="customized table">
        //       <TableBody>
        //         <StyledTableRow>
        //           <StyledTableCell
        //             className="videoModeHeading"
        //             component="th"
        //             scope="row"
        //           >
        //             {data.review_discription.doubt_solving}
        //           </StyledTableCell>
        //           <StyledTableCell className="m-1 videoModeHeading">
        //             {data.review_discription.lecture_coverage}
        //           </StyledTableCell>
        //           <StyledTableCell className="m-1 videoModeHeading">
        //             {data.review_discription.study_material}
        //           </StyledTableCell>
        //           <StyledTableCell className="m-1 videoModeHeading">
        //             {data.review_discription.teaching_style}
        //           </StyledTableCell>
        //         </StyledTableRow>
        //       </TableBody>
        //     </Table>
        //   </TableContainer>
        // ) : null,

        // data.product_type == "SLD_BOOK_PRODUCT" ||
        //   data?.product_type == "SLD_TEST_SERIES" ? (
        // <TableContainer component={Paper} style={{ maxHeight: 130 }}>
        //   <Table className={classes.table} aria-label="customized table">
        //     <TableBody>
        //       <StyledTableRow>
        //         <StyledTableCell
        //           className="bookRating"
        //           component="th"
        //           scope="row"
        //         >
        //           {data.bookRating}
        //         </StyledTableCell>
        //       </StyledTableRow>
        //     </TableBody>
        //   </Table>
        // </TableContainer>
        // ) : null,

        data.feedback,
        data.product_type == "SLD_BOOK_PRODUCT"
          ? data.book_product_title
          : data.videos_lecture_title,

        <div className="d-flex justify-content-center">
          <div
            onClick={() => {
                setSingleDataId(data?._id)
            }}
          >
            <AuthWrapper type="ACTION" name="REVIEW_QUESTIONS_REVIEW_VIEW">
              <EditDelete
                setViewDialog={setViewDialog}
                type="view"
                setData={setData}
                setGetid={setGetid}
                data={data}
              />
            </AuthWrapper>
          </div>
          <AuthWrapper type="ACTION" name="REVIEW_QUESTIONS_REVIEW_APPROVE">
            <Switch
              size="small"
              onClick={() => {
                UpdateData({
                  approval_action:
                    data.approval == "APPROVED" ? "REJECTED" : "APPROVED",
                  id: data._id,
                });
              }}
              checked={data.approval == "APPROVED" ? true : false}
            />
          </AuthWrapper>
          <AuthWrapper type="ACTION" name="REVIEW_QUESTIONS_REVIEW_DELETE ">
            <EditDelete type="delete" setGetid={setGetid} id={data._id} />
          </AuthWrapper>
        </div>

        // data.is_active ? "Yes" : "No",
        // data.approval == "PENDING" ?
        // <div>
        //     <FormControl component="fieldset">

        //         <RadioGroup row aria-label="position" name="position" defaultValue="top">

        //             <FormControlLabel value="APPROVED" control={<Radio />} label="Approved" onClick={(e) => {
        //                 UpdateData({ approval_action: e.target.value, varify_status: data.varified_user, id: data._id })

        //             }} />
        //             <FormControlLabel value="REJECTED" control={<Radio />} label="Reject" onClick={(e) => {

        //                 UpdateData({ approval_action: e.target.value, varify_status: data.varified_user, id: data._id })
        //             }} />
        //         </RadioGroup>
        //     </FormControl>
        // </div> : "YES",

        // data.createdAt,

        // <div className='d-flex'>
        //     <EditDelete type="delete"
        //         setGetid={setGetid}

        //         id={data._id} />
        // </div>
      );
    });
  }
  const dialogclose = () => {
    setEditDialog(false);
    setViewDialog(false);
    setDialog(false);
    setRender(!render);
  };
  return (
    <div>
      {!viewDialog && (
        <AddDataHeader
          addFormName={"Add Faculty"}
          setPage={setPage}
          page={page}
          isSearch={
            !AuthWrapper({
              type: "ACTION",
              name: "REVIEW_QUESTIONS_REVIEW_LIST",
              inBoolean: true,
            })
          }
          isPagination={
            !AuthWrapper({
              type: "ACTION",
              name: "REVIEW_QUESTIONS_REVIEW_LIST",
              inBoolean: true,
            })
          }
          excelBtn={false}
          totalItem={totalItem}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setAddDialog={setDialog}
          searchBarApi={getAllData}
          paginationData={paginationData}
          isButton={true}
          Adddialog={AddDialog}
          addBtn={true}
          // form={<AddFaculty AddData={AddData} setDialog={setDialog} />}
        />
      )}
      <AuthWrapper type="ACTION" name="REVIEW_QUESTIONS_REVIEW_LIST">
        {getAllDataInfo.isLoading || getAllDataInfo.isFetching ? (
          <SkeletonListing />
        ) : (
          <>
            {!viewDialog ? (
              rows.length ? (
                <AuthWrapper type="ACTION" name="REVIEW_QUESTIONS_REVIEW_LIST">
                  <CreateTableAddButton
                    editDialog={null}
                    setPage={null}
                    page={null}
                    totalItem={null}
                    rowsPerPage={null}
                    setRowsPerPage={null}
                    searchBarApi={null}
                    paginationData={null}
                    setAddDialog={null}
                    Adddialog={null}
                    rows={rows}
                    form={null}
                    editform={null}
                    columns={getFilterdColumns(columns)}
                    isButton={true}
                    addFormName={null}
                    editFormName={null}
                  />
                </AuthWrapper>
              ) : totalData == 0 ? (
                <DataNotFound />
              ) : (
                <SkeletonListing />
              )
            ) : (
              <ViewReviews
                viewDialog={setViewDialog}
                singleDataId={singleDataId}
              />
            )}
          </>
        )}
      </AuthWrapper>
      {/* {addDataInfo.data && <Toster info={addDataInfo} dialogclose={dialogclose} />} */}
      {/* {deleteFacultyInfo.data && <Toster info={deleteFacultyInfo} dialogclose={dialogclose} />} */}
      {updateDataInfo && (
        <Toster info={updateDataInfo} dialogclose={dialogclose} />
      )}
      {deleteDataInfo.data && (
        <Toster info={deleteDataInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
