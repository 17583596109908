import React, { useState, useEffect, Link, state } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import AddDataHeader from "../AddDataHeader";
import {
  useGetCashbackRequestsMutation,
  useApproveRequestMutation,
  useDisapproveRequestMutation,
  useUpdateRequestStatusMutation,
} from "../../../../services/StudentRequestsSevices";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import DataNotFound from "../DataNotFound";
import moment from "moment";
import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Avatar,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Switch,
} from "@material-ui/core";
import ViewCashbackRequest from "./cashback-request/ViewCashbackRequest";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@material-ui/icons/Edit";
import * as yup from "yup";
import { useFormik } from "formik";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListIcon from "@material-ui/icons/FilterList";
import Filters from "../Filters";
import AuthWrapper from "../../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../../utils/getFilterFunction";
import { handleOnlyNumbers } from "../../../../helpers/validation";

export default function CashBackRequests() {
  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 20,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "user",
      label: "User",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "order",
      label: "Order",
      minWidth: 20,
      maxWidth: 10,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      style: { maxWidth: 250, overFlow: "scroll" },
    },
    {
      id: "dates",
      label: "Dates",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "amounts",
      label: "Amounts (₹)",
      minWidth: 20,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "requestStatus",
      label: "Request Status",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "action",
      label: "Actions",
      minWidth: 20,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: ["ORDER_REQUEST_CASHBACK_REQUEST_VIEW"],
    },
  ];

  function createData(SNo, user, order, dates, amounts, requestStatus, action) {
    return {
      SNo,
      user,
      order,
      dates,
      amounts,
      requestStatus,
      action,
    };
  }

  const orderRequestStatusList = ["PENDING", "APPROVE", "DISAPPROVE"];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredPagination, setFilteredPagination] = useState([]);
  let startDate = filteredPagination.filter((e, i) => {
    return e.fieldName == "startDate" && e.value;
  });
  let endDate = filteredPagination.filter((e, i) => {
    return e.fieldName == "endDate" && e.value;
  });
  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: [
        "sld_order_id",
        "order_title",
        "shipping_details.mobile",
        "shipping_details.email",
    ],
    filterBy: filteredPagination.filter((e, i) => {
      return e.fieldName == "STATUS";
    }),
    page: page + 1,
    dateFilter: {
      dateFilterKey: "cashback.applied_on_date",
      startDate: startDate?.length ? startDate[0].value : null,
      endDate:  endDate?.length ? endDate[0].value : null,
    },
  };
  let rows = [];
  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [cashbackInfo,setCashbackInfo]= useState({})
  const [initiateDialog, setInitiateDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [getAllData, getAllDataInfo] = useGetCashbackRequestsMutation();
  const [approveRequest, approveRequestInfo] = useApproveRequestMutation();
  const [disapproveRequest, disapproveRequestInfo] =
    useDisapproveRequestMutation();
  const [initiateRequest, initiateRequestInfo] =
    useUpdateRequestStatusMutation();
  const AllData = getAllDataInfo.data;
  const totalItem = AllData ? AllData.totalItem : 0;
  const [render, setRender] = useState(false);
  const [remarkDialog, setRemarkDialog] = useState({});
  const userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null

  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page, filteredPagination]);

  let totalData = "ok";
  if (AllData) {
    totalData = AllData.totalItem;
    rows = AllData?.data?.map((ele, index) =>
      createData(
        index + 1,
        <div className="">
          <span className="text-info font-weight-bold">
            {ele?.user_details.first_name} {ele?.user_details.last_name}
          </span>
          <br />
          <span className="text-muted font-weight-bold">
            {ele?.user_details.mobile}
          </span>
          <br />
          <span className="text-muted">({ele?.user_details.email})</span>
        </div>,
        <div className="">
          <span className="text-info font-weight-bold">
            {ele?.sld_order_id}
          </span>
          <br />
          <span className="text-muted">({ele?.order_title})</span>
        </div>,
        <div className="">
          <span className="text-info font-weight-bold">Order Date</span>
          <br />
          <span className="text-muted font-weight-bold">
            {ele?.order_date
              ? moment(ele?.order_date).format("DD-MM-YYYY hh:mm:ss A")
              : ""}
          </span>
          <br />
          <ArrowDownwardIcon fontSize="small" className="text-info" />
          <br />
          <span className="text-info font-weight-bold">Applied Date</span>
          <br />
          <span className="text-muted font-weight-bold">
            {ele?.applied_on_date
              ? moment(ele?.applied_on_date).format("DD-MM-YYYY hh:mm:ss A")
              : ""}
          </span>
          <br />
          <ArrowDownwardIcon fontSize="small" className="text-info" />
          <br />
          <span className="text-info font-weight-bold">Payment Date</span>
          <br />
          <span className="text-muted font-weight-bold">
            {ele?.payment_date
              ? moment(ele?.payment_date).format("DD-MM-YYYY")
              : ""}
          </span>
          <br />
        </div>,
        <div className="">
          <span className="text-info font-weight-bold">Demand Amount</span>
          <br />
          <span className="text-muted font-weight-bold">
            ₹ {ele?.demandAmount}
          </span>
          <br />
          <ArrowDownwardIcon fontSize="small" className="text-info" />
          <br/>
          <span className="text-info font-weight-bold">Approved Amount</span>
          <br />
          <span className="text-muted font-weight-bold">
            ₹ {ele?.approved_amount}
          </span>
          <br />
          <ArrowDownwardIcon fontSize="small" className="text-info" />
          <br />
          <span className="text-info font-weight-bold">Cashback Amount</span>
          <br />
          <span className="text-muted font-weight-bold">₹ {ele?.amount}</span>
        </div>,
        <span className="text-info font-weight-bold">{ele?.status}</span>,
        <div className="">
          {ele?.applied_for_cashback &&
          !ele?.cashback_initiated &&
          !ele?.is_approved && !ele?.disapproval_remark ? (
            <>
              <Tooltip placement="top" title="Approve Request" arrow>
                <IconButton
                  onClick={() => {
                    setRemarkDialog({
                      approve:true,
                      disApprove:false
                    });
                    setGetid(ele?._id);
                    setCashbackInfo({
                    demandedAmount :ele?.demandAmount
                    })
                  }}
                >
                  <CheckIcon color="primary" />
                </IconButton>
              </Tooltip>
              <Tooltip placement="top" title="Disapprove Request" arrow>
                <IconButton
                  onClick={() => {
                    setRemarkDialog({
                      approve:false,
                      disApprove:true
                    });
                    setGetid(ele?._id);
                  }}
                >
                  <CloseIcon color="primary" />
                </IconButton>
              </Tooltip>
            </>
          ) : null}
          {!ele?.cashback_initiated && ele?.is_approved && (userData?.is_accountant || userData?.type==="ADMIN") ? (
            <Tooltip placement="top" title="Initiate Request" arrow>
              <IconButton
                onClick={() => {
                  setInitiateDialog(true);
                  setGetid(ele?._id);
                  setCashbackInfo({
                    approvedAmount :ele?.approved_amount
                    })
                }}
              >
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
          ) : null}
          <AuthWrapper type="ACTION" name="ORDER_REQUEST_CASHBACK_REQUEST_VIEW">
            <EditDelete
              setViewDialog={setViewDialog}
              type="view"
              setData={setData}
              data={ele}
            />
          </AuthWrapper>
        </div>
      )
    );
  }

  const dialogclose = () => {
    setInitiateDialog(false);
    setViewDialog(false);
    setRender(!render);
    setRemarkDialog({
      approve:false,
      disApprove:false
    });
  };

  return (
    <div>
      <AddDataHeader
        setPage={setPage}
        page={page}
        totalItem={totalItem}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchBarApi={getAllData}
        paginationData={paginationData}
        isButton={false}
        addBtn={true}
        isSearch={
          !AuthWrapper({
            type: "ACTION",
            name: "ORDER_REQUEST_CASHBACK_REQUEST_LIST",
            inBoolean: true,
          })
        }
        isPagination={
          !AuthWrapper({
            type: "ACTION",
            name: "ORDER_REQUEST_CASHBACK_REQUEST_LIST",
            inBoolean: true,
          })
        }
        filteredPagination={filteredPagination}
      />
      <AuthWrapper type="ACTION" name="ORDER_REQUEST_CASHBACK_REQUEST_LIST">
        {/* For now commented filter code */}
        {/* {!viewDialog && (
          <Accordion className="">
            <AccordionSummary
              className="text-primary"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="d-flex align-items-center">
                <FilterListIcon /> <span> Filter</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="">
              <Filters
                setFilteredPagination={setFilteredPagination}
                isOrderRequestStatusFilter={false}
                module="orderRequest"
                filterListingData={{
                  orderRequestStatusList: orderRequestStatusList,
                }}
                isFromFilter={true}
                isToFilter={true}
              />
            </AccordionDetails>
          </Accordion>
        )} */}
        {/* end */}
        {/* {addDataInfo.isLoading || deleteDataInfo.isLoading || updateDataInfo.isLoading ? <Loader /> : <> */}

        {!viewDialog ? (
          rows?.length ? (
            <CreateTableAddButton
              maxWidth="md"
              fullWidth={false}
              rows={rows}
              columns={getFilterdColumns(columns)}
              isButton={true}
            />
          ) : totalData == 0 ? (
            <DataNotFound />
          ) : (
            <SkeletonListing />
          )
        ) : (
          <ViewCashbackRequest
            viewDialog={viewDialog}
            data={data}
            setViewDialog={setViewDialog}
          />
        )}
      </AuthWrapper>
      {/* </>} */}
      {remarkDialog?.approve && (
        <ApproveRequestForm
          onClose={()=>setRemarkDialog({
            approve:false,
            disApprove:false
          })}
          open={remarkDialog?.approve}
          approveRequest={approveRequest}
          getid={getid}
          cashbackInfo = {cashbackInfo}
        />
      )}
      {remarkDialog?.disApprove && (
        <DisApproveRequestForm
          onClose={()=>setRemarkDialog({
            approve:false,
            disApprove:false
          })}
          open={remarkDialog?.disApprove}
          disapproveRequest={disapproveRequest}
          getid={getid}
        />
      )}

     
      {initiateDialog && (
        <InitiateForm
          setInitiateDialog={setInitiateDialog}
          initiateDialog={initiateDialog}
          initiateRequest={initiateRequest}
          getid={getid}
          cashbackInfo = {cashbackInfo}
        />
      )}
      {approveRequestInfo && (
        <Toster info={approveRequestInfo} dialogclose={dialogclose} />
      )}
      {disapproveRequestInfo && (
        <Toster info={disapproveRequestInfo} dialogclose={dialogclose} />
      )}
      {initiateRequestInfo && (
        <Toster info={initiateRequestInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}

function InitiateForm({
  setInitiateDialog,
  initiateDialog,
  initiateRequest,
  getid,
  cashbackInfo
}) {
  let now = new Date();
  const formik = useFormik({
    initialValues: {
      refund_or_cashback: "CASHBACK",
      amount: "",
      payment_date: moment(now).format("YYYY-MM-DD"),
      remark: "",
      transaction_details:""
    },
    validationSchema: yup.object({
      amount: yup.number().typeError("Please enter amount").test("amount-test","Amount must be less than approved amount ",(value)=>{
        return Number(value) <= Number(cashbackInfo?.approvedAmount)
      }).required("Amount is required"),
      payment_date: yup.date().required("Required !"),
      remark: yup.string().required("Required !"),
      transaction_details:yup.string().required("Required !")
    }),
    onSubmit: (values) => {
      initiateRequest({ body: values, id: getid });
    },
  });

  return (
    <Dialog
      fullWidth={true}
      open={initiateDialog}
      onClose={() => {
        setInitiateDialog(false);
      }}
    >
      <DialogTitle>Initiate Cashback</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <TextField
            margin="dense"
            // id="name"
            name="amount"
            label="amount"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.amount}
          />
          {formik.touched.amount && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.amount}
            </span>
          )}
          <br />
          <br />

          <TextField
            margin="dense"
            // id="name"
            name="payment_date"
            label="payment date"
            type="date"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.payment_date}
          />
          {formik.touched.payment_date && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.payment_date}
            </span>
          )}
          <br />
          <br />

          <TextField
            margin="dense"
            // id="name"
            name="remark"
            label="remark"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.remark}
            multiline
            minRows={3}
          />
          {formik.touched.remark && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.remark}
            </span>
          )}
          <TextField
            margin="dense"
            name="transaction_details"
            label="Transaction Details"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.transaction_details}
            multiline
            minRows={2}
          />
          {formik.touched.transaction_details && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.transaction_details}
            </span>
          )}
          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setInitiateDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            style={{ marginLeft: 20 }}
            variant="contained"
            color="primary"
          >
            {" "}
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function ApproveRequestForm({ getid,open,onClose,approveRequest,cashbackInfo }) {
  
  const formik = useFormik({
    initialValues: {
      key: "cashback",
      approval_remark: "",
      approved_amount: "",
      show_approved_amount: false,
      show_details_to_student: false,
    },
    validationSchema: yup.object({
      approved_amount: yup.number().test("amount-test","Approved amount must be less than demanded amount ",(value)=>{
        return Number(value) <= Number(cashbackInfo?.demandedAmount)
      }).required("Approved amount is required"),
      approval_remark: yup.string().trim().required("Remark is required"),
      show_details_to_student: yup.boolean().required("Required !"),
      show_approved_amount: yup.boolean().required("Required !"),
    }),
  
    onSubmit: (values) => {
      approveRequest({
        body: values,
        id: getid,
      });
    },
  });

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Approve Cashback</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
            <label>Approved Amount</label>
          <TextField
            margin="dense"
            name="approved_amount"
            type="text"
            fullWidth
            variant="outlined"
            onChange={(e)=>handleOnlyNumbers(e.target.value) && formik.setFieldValue("approved_amount",e.target.value)}
            onBlur={formik.handleBlur}
            value={formik.values.approved_amount}
          />
          {formik.touched.approved_amount && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.approved_amount}
            </span>
          )}
          <br />
          <br />
          <label>Show Approved Amount</label>
          <div className="border rounded px-4 d-flex justify-content-between align-items-center">
              <span className="">{formik.values?.show_approved_amount ? "Yes" : "No"}</span>
              <Switch
                checked={formik.values?.show_approved_amount}
                name="show_approved_amount"
                onChange={(e)=>formik.setFieldValue("show_approved_amount",e.target.checked)}
                onBlur={formik.handleBlur}
              />
            </div>
            {formik.touched.show_approved_amount && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.show_approved_amount}
            </span>
          )}
            <br/>

            <label>Show Details to Student</label>
          <div className="border rounded px-4 d-flex justify-content-between align-items-center">
              <span className="">{formik.values?.show_details_to_student ? "Yes" : "No"}</span>
              <Switch
                checked={formik.values?.show_details_to_student}
                name="show_details_to_student"
                onChange={(e)=>formik.setFieldValue("show_details_to_student",e.target.checked)}
                onBlur={formik.handleBlur}
              />
            </div>
            {formik.touched.show_details_to_student && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.show_details_to_student}
            </span>
          )}
            <br/>
         
          <label>Approvel remark</label>
          <TextField
            margin="dense"
            name="approval_remark"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.approval_remark}
            multiline
            minRows={3}
          />
          {formik.touched.approval_remark && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.approval_remark}
            </span>
          )}
          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            style={{ marginLeft: 20 }}
            variant="contained"
            color="primary"
          >
            {" "}
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function DisApproveRequestForm({ getid,open,onClose,disapproveRequest }) {
  
  const formik = useFormik({
    initialValues: {
      key: "cashback",
      disapproval_remark: "",
    },
    validationSchema: yup.object({
      disapproval_remark: yup.string().trim().required("Remark is required"),
    }),
  
    onSubmit: (values) => {
      disapproveRequest({
        body: values,
        id: getid,
      });
    },
  });

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Disapprove Cashback</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <label>Disapproval remark</label>
          <TextField
            margin="dense"
            name="disapproval_remark"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.disapproval_remark}
            multiline
            minRows={3}
          />
          {formik.touched.disapproval_remark && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.disapproval_remark}
            </span>
          )}
          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            style={{ marginLeft: 20 }}
            variant="contained"
            color="primary"
          >
            {" "}
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
