import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Grid, Typography, Card, CardContent, Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 24,
        color: "black",
        fontWeight: 'bolder'
    },
    other: {
        fontSize: 12,
        color: "black",

    },
    pos: {
        marginBottom: 12,
    },
});
export default function SkeletonListing() {
    const classes = useStyles();
    return (
        <SkeletonTheme baseColor="#a8aaad" highlightColor="#999" height={45} width="100%">
            <Grid container spacing={2} className="p-2">
                <Grid item xs={12} className="my-1">
                    <Card className={classes.root} style={{ width: "100%" }} >
                        <CardContent style={{ width: "100%" }} >
                            <Grid container >
                                <Grid item xs={12}>
                                    <Skeleton className="my-2" count={9} width="100%" />
                                </Grid>

                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SkeletonTheme>
    )
}
