import React from 'react'
import * as yup from 'yup';
import { Button, Dialog, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import Toster from '../Toster';
import fileUrl from 'file-url';



export default function AddEditFile({ edit, setOpen, onSave, initialData }) {




    const initialValues = {
        file: edit ? initialData.url : "",
        name: edit ? initialData.name : "",
        type: edit ? initialData.type : ""
    }

    const validationSchema = yup.object({
        // file: yup.object().required("This field is required"),
        name: yup.string().required("This field is required"),
        type: yup.string().required("This field is required"),
    })

    return (

        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {

                    let form_data = new FormData;
                    for (let key in values) {
                        form_data.append(key, values[key])
                    }
                    edit ? onSave({ body: form_data, id: initialData._id }) : onSave(form_data)
                      
                }}
            >

                {
                    (props) => {

                        const { values } = props

                          

                        return (
                            <Form>
                                <Grid container >

                                    <Grid item xs={6} className="p-3" >
                                        <Grid container spacing={2} style={{ height: "200px", border: "1px dashed grey" }} className="d-flex align-items-center justify-content-evenly rounded " >
                                            <Grid item xs={3}>
                                                <Grid container justifyContent='center' className='border border-danger '>

                                                    <img
                                                        src={typeof values.file == "string" ? values.file : URL.createObjectURL(new Blob([values.file]))}

                                                        style={{ width: 100, height: 100 }}
                                                    />
                                                </Grid>

                                            </Grid>
                                            <Grid item xs={8}>
                                                <Grid item xs={12} > <Typography className='text-center text-muted'> <CloudUploadOutlinedIcon fontSize='large' /> </Typography> </Grid>
                                                <Grid item xs={12} >
                                                    <Field name="file">
                                                        {
                                                            (props) => {
                                                                const { field, form } = props
                                                                  
                                                                return (
                                                                    <TextField

                                                                        onChange={(e) => {
                                                                            form.setFieldValue("file", e.target.files[0])
                                                                        }} name="file" type="file" />
                                                                )
                                                            }
                                                        }

                                                    </Field>


                                                </Grid>
                                            </Grid>


                                        </Grid>

                                    </Grid>
                                    <Grid item xs={6} className="p-3" >

                                        <Field name="name" >
                                            {
                                                (props) => {
                                                    const { field, } = props

                                                      

                                                    return <TextField
                                                        {...field}
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                        label="File Name"
                                                        className='mb-5'


                                                    />
                                                }
                                            }
                                        </Field>


                                        <Field name="type">
                                            {
                                                (props) => {
                                                    const { field, } = props

                                                      

                                                    return (

                                                        <FormControl fullWidth >
                                                            <InputLabel id="type"> Type</InputLabel>
                                                            <Select
                                                                {...field}
                                                                labelId="type"
                                                                id="type"
                                                                label="Type"
                                                            // renderValue={(value) => value == "MAIN_COURSE" ? "Main Course" : "Other Course"}

                                                            >
                                                                <MenuItem value="Faculty"> Faculty </MenuItem>
                                                                <MenuItem value="videoLecture"> Video Lecture </MenuItem>
                                                                <MenuItem value="blog"> Blog </MenuItem>
                                                                <MenuItem value="others"> Others </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    )
                                                }
                                            }


                                        </Field>


                                    </Grid>

                                    <Grid container item xs={12} justifyContent="flex-end" className='mt-5' >
                                        <Grid>
                                            <Button variant='contained' color='secondary' onClick={() => { setOpen(false) }} > Close </Button>
                                            <Button variant='contained' color='primary' className='ml-2' type='submit' > Save </Button>
                                        </Grid>
                                    </Grid>


                                </Grid>

                            </Form>
                        )
                    }
                }

            </Formik >


        </div>
    )
}
