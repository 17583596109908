import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import VideoLectureExcelLogs from './VideoLectureExcelLogs';
import FacultyExcelLogs from './FacultyExcelLogs';
import AuthWrapper from '../../../../utils/AuthWrapper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}

        >
            {value === index && (
                <Box p={10} style={{ padding: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },

}));

const tabsNames = [
    {
        tabName: "Video Lecture",
        tabId: "EXCEL_LOGS_VIDEO_LECTURE",
    },
    {
        tabName: "Faculty",
        tabId: "EXCEL_LOGS_FACULTY",
    },
]

export default function ExcelLogs() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    let tabsToShow = tabsNames.filter((e, i) => { return AuthWrapper({ type: "TAB", name: e?.tabId, inBoolean: true }) })

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getTabPanel = (ele) => {
        let moduleToShow;
        switch (ele) {
            case "Video Lecture":
                moduleToShow = <VideoLectureExcelLogs />;
                break;
            case "Faculty":
                moduleToShow = <FacultyExcelLogs />;
                break;
            default:
                break;
        }
        return moduleToShow;
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    {
                        tabsToShow?.map((ele, ind) => {
                            return <Tab style={{ textTransform: 'capitalize' }} label={ele?.tabName} {...a11yProps(ind)} />
                        })
                    }
                </Tabs>
            </AppBar>

            {
                tabsToShow?.map((ele, ind) => {
                    return <TabPanel value={value} index={ind} >{getTabPanel(ele?.tabName)}</TabPanel>
                })
            }
        </div >
    )
}
