import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const cashCollectionApi = createApi({
    reducerPath: "cashCollectionApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/cash-collection/`,
    }),
    endpoints: (builder) => ({
        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ADD  "CASH"   >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        AddCash: builder.mutation({
            query: (body) => {
                return {
                    url: "add-cash",
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,
                };
            },
        }),

        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET CASH DATA FOR VALIDATION  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        GetCashDataForValidation: builder.query({
            query: (id) => {
                return {
                    url: `get-cash-data/${id}`,
                    headers: {
                        "x-access-token": token,
                    },
                    method: "GET",


                };
            },
        }),

        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET ONLINE AMOUNT  DATA FOR VALIDATION  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        GetOnlineAmountDataForValidation: builder.query({
            query: (id) => {
                return {
                    url: `get-online-payment-data/${id}`,
                    headers: {
                        "x-access-token": token,
                    },
                    method: "GET",

                };
            },
        }),

        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  "GET ALL CASH COLLECTION DATA"  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        GetAllCashCollectionData: builder.mutation({
            query: ({ body, id }) => {
                  
                return {
                    url: `get-all-payment-data/${id}`,
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,

                };
            },
        }),



    }),
});

export const {
    useAddCashMutation,
    useGetCashDataForValidationQuery,
    useGetOnlineAmountDataForValidationQuery , 
    useGetAllCashCollectionDataMutation,
} = cashCollectionApi;



