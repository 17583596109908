import { ViewQuiltIcon } from "../constant/material";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SubjectIcon from "@material-ui/icons/Subject";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import SettingsSystemDaydreamIcon from "@material-ui/icons/SettingsSystemDaydream";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AddIcCallIcon from "@material-ui/icons/AddIcCall";
import SmsIcon from "@material-ui/icons/Sms";
import EventIcon from "@material-ui/icons/Event";
import ClassIcon from "@material-ui/icons/Class";
import EmailIcon from "@material-ui/icons/Email";
import CodeIcon from "@material-ui/icons/Code";
import GradeOutlinedIcon from "@material-ui/icons/GradeOutlined";
import PermDataSettingIcon from "@material-ui/icons/PermDataSetting";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import MoneyIcon from "@material-ui/icons/Money";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import ReceiptIcon from "@material-ui/icons/Receipt";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GridOnIcon from "@material-ui/icons/GridOn";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import UsbIcon from "@material-ui/icons/Usb";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import BatchPredictionIcon from "@mui/icons-material/BatchPrediction";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
// import CreditCardIcon from '@mui/icons-material/CreditCard';
import AssignmentIcon from "@mui/icons-material/Assignment";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import DashboardIcon from "@material-ui/icons/Dashboard";
import VpnKeyIcon from "@material-ui/icons/VpnKey";


const navigation = [
  {
    name: " Dashboard",
    fontIcon: "fa fa-empire",
    _children: [
      // {
      //     name: "Overview ",
      //     to: "/dashboard",
      //     siteurl: "/user/dashboard-count",
      //     icon: <ViewQuiltIcon color="primary" fontSize="large" />,
      //     value: false,
      //     _children: [],
      //     authModuleName: ["dashboard"]
      // },
      {
        name: " Dashboard ",
        to: "/dashboard",
        siteurl: "/user/dashboard-count",
        icon: <DashboardIcon color="primary" fontSize="large" />,
        value: false,
        _children: [],
        authModuleName: "DASHBOARD",
      },

      {
        name: " Video Lectures ",
        to: "/video-lectures",
        siteurl: "/user/dashboard-count",
        icon: <VideoLibraryIcon color="primary" fontSize="large" />,
        value: false,
        _children: [],
        authModuleName: "VIDEO_LECTURE",
      },
      // {
      //     name: "Books & Product",
      //     to: "/book-and-product",
      //     siteurl: "/user/dashboard-count",
      //     icon: <MenuBookIcon color="primary" fontSize="large" />,
      //     value: false,
      //     _children: [],
      //     authModuleName: ["book", "book-product"]
      // },
      {
        name: "Faculties & Institute",
        to: "/faculty-and-institute",
        siteurl: "/user/dashboard-count",
        icon: <RecentActorsIcon color="primary" fontSize="large" />,
        value: false,
        _children: [],
        authModuleName: "FACULTY_INSTITUTE",
      },
      {
        name: "Users & Franchise",
        to: "/users-and-franchise",
        siteurl: "/user/dashboard-count",
        icon: <GroupIcon color="primary" fontSize="large" />,
        value: false,
        _children: [],
        authModuleName: "USER_FRANCHISE",
      },
      {
        name: "Orders",
        siteurl: "/user/dashboard-count",
        fontIcon: "fa fa-empire",
        icon: <ShoppingCartIcon color="primary" fontSize="large" />,
        value: true,
        authModuleName: [
          "SUCCESSFULL_ORDERS",
          "PROCESSING_ORDERS",
          "PAYMENT_PENDING",
          "CANCEL_ORDERS",
        ],
        _children: [
          {
            name: "Success Full Orders",
            to: "/orders",
            siteurl: "/user/dashboard-count",
            icon: (
              <ShoppingCartIcon style={{ color: "#1ea01e" }} fontSize="large" />
            ),
            value: false,
            _children: [],
            authModuleName: "SUCCESSFULL_ORDERS",
          },
          {
            name: "Proccessing Orders",
            to: "/pending-orders",
            siteurl: "/user/dashboard-count",
            icon: <ShoppingCartIcon color="warning" fontSize="large" />,
            value: false,
            _children: [],
            authModuleName: "PROCESSING_ORDERS",
          },
          {
            name: "Payment Pending Orders",
            to: "/failed-orders",
            siteurl: "/user/dashboard-count",
            icon: (
              <ShoppingCartIcon style={{ color: "#F29339" }} fontSize="large" />
            ),
            value: false,
            _children: [],
            authModuleName: "PAYMENT_PENDING",
          },
          // {
          //     name: "Pendrive Orders",
          //     to: "/pendriveOrders",
          //     siteurl: "/user/dashboard-count",
          //     icon: <UsbIcon color="primary" fontSize="small" />,
          //     value: false,
          //     _children: [],
          //     authModuleName: ["offline-order"]
          // },
          {
            name: "Cancel Orders",
            to: "/cancelorders",
            siteurl: "/user/dashboard-count",
            icon: <ShoppingCartIcon color="secondary" fontSize="large" />,
            value: false,
            _children: [],
            authModuleName: "CANCEL_ORDERS",
          },
        ],
      },

      // {
      //     name: "Course & Subjects",
      //     to: "/course-subject",
      //     siteurl: "/user/dashboard-count",
      //     icon: <SubjectIcon color="primary" fontSize="large" />,
      //     value: false,
      //     _children: [],
      //     authModuleName: ["course", "subject"]
      // },
      // {
      //     name: "Courier Details",
      //     to: "/courier",
      //     siteurl: "/user/dashboard-count",
      //     icon: <LocalShippingIcon color="primary" fontSize="large" />,
      //     value: false,
      //     _children: [],
      //     authModuleName: ["courier"]
      // },
      // {
      //     name: "Employees",
      //     to: "/employees",
      //     siteurl: "/user/dashboard-count",
      //     icon: <AssignmentIndIcon color="primary" fontSize="large" />,
      //     value: false,
      //     _children: [],
      //     authModuleName:"video-lecture"
      // },
      {
        name: "CMS",
        to: "/cms",
        siteurl: "/user/dashboard-count",
        icon: <SettingsSystemDaydreamIcon color="primary" fontSize="large" />,
        value: false,
        _children: [],
        authModuleName: "CMS",
      },
      // {
      //     name: "Product Cart",
      //     to: "/product-cart",
      //     siteurl: "/user/dashboard-count",
      //     icon: <ShoppingCartIcon color="primary" fontSize="large" />,
      //     value: false,
      //     _children: [],
      //     authModuleName: ["user"]
      // },
      {
        name: "Reviews &  Questions",
        to: "/reviews",
        siteurl: "/user/dashboard-count",
        icon: <GradeOutlinedIcon color="primary" fontSize="large" />,
        value: false,
        _children: [],
        authModuleName: "REVIEW_QUESTIONS",
      },
      // {
      //     name: "Quote Your Price",
      //     to: "/quotes",
      //     siteurl: "/user/dashboard-count",
      //     icon: <LocalAtmIcon color="primary" fontSize="large" />,
      //     value: false,
      //     _children: [],
      //     authModuleName: ["quote-your-price"]
      // },
      {
        name: "Configuration",
        to: "/configuration",
        siteurl: "/user/dashboard-count",
        icon: <PermDataSettingIcon color="primary" fontSize="large" />,
        value: false,
        _children: [],
        authModuleName: "CONFIGURATION",
      },

      // {
      //     name: "Billing",
      //     to: "/billing",
      //     siteurl: "/user/dashboard-count",
      //     icon: <ReceiptIcon color="primary" fontSize="large" />,
      //     value: false,
      //     _children: [],
      //     authModuleName:"video-lecture"
      // },
      // {
      //     name: "Finance",
      //     to: "/finance",
      //     siteurl: "/user/dashboard-count",
      //     icon: <CreditCardIcon color="primary" fontSize="large" />,
      //     value: false,
      //     _children: [],
      //     authModuleName:"video-lecture"
      // },
      // {
      //     name: "Account",
      //     to: "/account",
      //     siteurl: "/user/dashboard-count",
      //     icon: <AccountCircleIcon color="primary" fontSize="large" />,
      //     value: false,
      //     _children: [],
      //     authModuleName:"video-lecture"
      // }
    ],
  },
  {
    name: "Support",
    fontIcon: "fa fa-empire",
    _children: [
      {
        name: "Pre-Sell Support",
        to: "/inquiries",
        siteurl: "/user/dashboard-count",
        icon: <LiveHelpIcon color="primary" fontSize="large" />,
        value: false,
        _children: [],
        authModuleName: "PRE_SELL_SUPPORT",
      },
      {
        name: "Post-Sell Support",
        to: "/tickets",
        siteurl: "/user/dashboard-count",
        icon: <ConfirmationNumberIcon color="primary" fontSize="large" />,
        value: false,
        _children: [],
        authModuleName: "POST_SELL_SUPPORT",
      },
      {
        name: "Order Requests",
        to: "/studentrequests",
        siteurl: "/user/dashboard-count",
        icon: <ListAltIcon color="primary" fontSize="large" />,
        value: false,
        _children: [],
        authModuleName: "ORDER_REQUEST",
      },
    ],
  },
  {
    name: "Employee",
    fontIcon: "fa fa-empire",
    _children: [
      {
        name: "Change Password",
        to: "/changePassword",
        siteurl: "/user/dashboard-count",
        icon: <VpnKeyIcon color="primary" fontSize="large" />,
        value: false,
        _children: [],
        authModuleName: "CHANGE_PASSWORD",
      },
    ],
  },
  
  {
    name: "MANAGEMENT",
    fontIcon: "fa fa-empire",

    _children: [
      {
        name: "Coupons & Scratch Cards",
        to: "/coupons-scatch-cards",
        siteurl: "/user/dashboard-count",
        icon: <LocalOfferIcon color="primary" fontSize="large" />,
        value: false,
        _children: [],
        authModuleName: "COUPON_SCRATCH_CARD",
      },

      {
        name: "File Explorer",
        to: "/file-explorer",
        siteurl: "/user/dashboard-count",
        icon: <FolderOpenIcon color="primary" fontSize="large" />,
        value: false,
        _children: [],
        authModuleName: "FILE_EXPLORER",
      },

      // {
      //     name: "Time Sheet",
      //     to: "/time-sheet",
      //     siteurl: "/user/dashboard-count",
      //     icon: <AccessTimeIcon color="primary" fontSize="large" />,
      //     value: false,
      //     _children: [],
      //     authModuleName: []
      // },
      // {
      //     name: "Categories",
      //     to: "/categories",
      //     siteurl: "/user/dashboard-count",
      //     icon: <CategoryIcon color="primary" fontSize="large" />,
      //     value: false,
      //     _children: [],
      //     authModuleName:"video-lecture"
      // },
      // {
      //     name: "Transactions",
      //     to: "/transaction",
      //     siteurl: "/user/dashboard-count",
      //     icon: <AccountBalanceWalletIcon color="primary" fontSize="large" />,
      //     value: false,
      //     _children: [],
      //     authModuleName: ["transaction"]
      // },
      // {
      //     name: "Review Email",
      //     to: "/reviewemail",
      //     siteurl: "/user/dashboard-count",
      //     icon: <AllInboxIcon color="primary" fontSize="large" />,
      //     value: false,
      //     _children: [],
      //     authModuleName:"video-lecture"
      // },

      // {
      //     name: "Modes",
      //     to: "/modes",
      //     siteurl: "/user/dashboard-count",
      //     icon: <MergeTypeIcon color="primary" fontSize="large" />,
      //     value: false,
      //     _children: [],
      //     authModuleName: ["mode"]
      // },

      //         // {
      //         //     name: "Details",
      //         //     to: "/dashboard",
      //         //     siteurl: "/user/dashboard-count",
      //         //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //         //     value: false,
      //         // },
      //         // {
      //         //     name: "Edit",
      //         //     to: "/dashboard",
      //         //     siteurl: "/user/dashboard-count",
      //         //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //         //     value: false,
      //         // }
      //     ],
      // },
      // {
      //     name: "Call Requests",
      //     to: "/callrequests",
      //     siteurl: "/user/dashboard-count",
      //     icon: <AddIcCallIcon color="primary" fontSize="large" />,
      //     value: false,
      //     _children: [

      //         // {
      //         //     name: "Details",
      //         //     to: "/dashboard",
      //         //     siteurl: "/user/dashboard-count",
      //         //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //         //     value: false,
      //         // },
      //         // {
      //         //     name: "Edit",
      //         //     to: "/dashboard",
      //         //     siteurl: "/user/dashboard-count",
      //         //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //         //     value: false,
      //         // }
      //     ],
      //     authModuleName: ["enquiry"]
      // },
      {
        name: "SMS",
        to: "/sms",
        siteurl: "/user/dashboard-count",
        icon: <SmsIcon color="primary" fontSize="large" />,
        value: false,
        _children: [
          // {
          //     name: "Details",
          //     to: "/dashboard",
          //     siteurl: "/user/dashboard-count",
          //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
          //     value: false,
          // },
          // {
          //     name: "Edit",
          //     to: "/dashboard",
          //     siteurl: "/user/dashboard-count",
          //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
          //     value: false,
          // }
        ],
        authModuleName: "SMS",
      },
      // {
      //     name: "Events",
      //     to: "/events",
      //     siteurl: "/user/dashboard-count",
      //     icon: <EventIcon color="primary" fontSize="large" />,
      //     value: false,
      //     _children: [

      //         // {
      //         //     name: "Details",
      //         //     to: "/dashboard",
      //         //     siteurl: "/user/dashboard-count",
      //         //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //         //     value: false,
      //         // },
      //         // {
      //         //     name: "Edit",
      //         //     to: "/dashboard",
      //         //     siteurl: "/user/dashboard-count",
      //         //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //         //     value: false,
      //         // }
      //     ],
      //     authModuleName: []
      // },
      // {
      //     name: "Virtual Classes",
      //     to: "/virtual-class",
      //     siteurl: "/user/dashboard-count",
      //     icon: <ClassIcon color="primary" fontSize="large" />,
      //     value: false,
      //     _children: [

      //         // {
      //         //     name: "Details",
      //         //     to: "/dashboard",
      //         //     siteurl: "/user/dashboard-count",
      //         //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //         //     value: false,
      //         // },
      //         // {
      //         //     name: "Edit",
      //         //     to: "/dashboard",
      //         //     siteurl: "/user/dashboard-count",
      //         //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //         //     value: false,
      //         // }
      //     ],
      //     authModuleName: []
      // },
      {
        name: "Custom Email",
        to: "/customemail",
        siteurl: "/user/dashboard-count",
        icon: <EmailIcon color="primary" fontSize="large" />,
        value: false,
        _children: [
          // {
          //     name: "Details",
          //     to: "/dashboard",
          //     siteurl: "/user/dashboard-count",
          //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
          //     value: false,
          // },
          // {
          //     name: "Edit",
          //     to: "/dashboard",
          //     siteurl: "/user/dashboard-count",
          //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
          //     value: false,
          // }
        ],
        authModuleName: "CUSTOM_EMAIL",
      },
      // {
      //     name: "HTML Editor",
      //     to: "/htmleditor",
      //     siteurl: "/user/dashboard-count",
      //     icon: <CodeIcon color="primary" fontSize="large" />,
      //     value: false,
      //     _children: [

      //         // {
      //         //     name: "Details",
      //         //     to: "/dashboard",
      //         //     siteurl: "/user/dashboard-count",
      //         //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //         //     value: false,
      //         // },
      //         // {
      //         //     name: "Edit",
      //         //     to: "/dashboard",
      //         //     siteurl: "/user/dashboard-count",
      //         //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //         //     value: false,
      //         // }
      //     ],
      //     authModuleName: []
      // },
      // {
      //     name: "Downloads",
      //     to: "/downloads",
      //     siteurl: "/user/dashboard-count",
      //     icon: <CloudDownloadIcon color="primary" fontSize="large" />,
      //     value: false,
      //     _children: [

      //         // {
      //         //     name: "Details",
      //         //     to: "/dashboard",
      //         //     siteurl: "/user/dashboard-count",
      //         //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //         //     value: false,
      //         // },
      //         // {
      //         //     name: "Edit",
      //         //     to: "/dashboard",
      //         //     siteurl: "/user/dashboard-count",
      //         //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //         //     value: false,
      //         // }
      //     ],
      //     authModuleName: []
      // },

      {
        name: "All Wallet Transactions",
        to: "/all-wallet-transactions",
        siteurl: "/user/dashboard-count",
        icon: <AccountBalanceWalletIcon color="primary" fontSize="large" />,
        value: false,
        _children: [],
        authModuleName: "WALLET_TRANSACTION",
      },

      // {
      //   name: "Expenses",
      //   to: "/expenses",
      //   siteurl: "/user/dashboard-count",
      //   icon: <CreditCardIcon color="primary" fontSize="large" />,
      //   value: false,
      //   _children: [],
      //   authModuleName: ["expense-category", "expense-register"],
      // },

      // {
      //   name: "Incomes",
      //   to: "/incomes",
      //   siteurl: "/user/dashboard-count",
      //   icon: <MoneyIcon color="primary" fontSize="large" />,
      //   value: false,
      //   _children: [],
      //   authModuleName: ["income-category", "income-register"],
      // },

      {
        name: "Excel Logs",
        to: "/excellogs",
        siteurl: "/user/dashboard-count",
        icon: <ListAltIcon color="primary" fontSize="large" />,
        value: false,
        _children: [],
        authModuleName: "EXCEL_LOGS",
      },
      {
        name: "Caller Performance",
        to: "/callerperformance",
        siteurl: "/user/dashboard-count",
        icon: <AssessmentIcon color="primary" fontSize="large" />,
        value: false,
        _children: [],
        authModuleName: "CALLER_PERFORMANCE",
      },

      {
        name: "Mobile Number Library",
        to: "/mobilenolibrary",
        siteurl: "/user/dashboard-count",
        icon: <ContactPhoneIcon color="primary" fontSize="large" />,
        value: false,
        _children: [],
        authModuleName: "SMS_MOBILE_NUMBER_LIBRARY",
      },

      // {
      //     name: "Orders",
      //     to: "/dashboard",
      //     siteurl: "/user/dashboard-count",
      //     icon: "assets/images/sidebar-menu-image/order.svg",
      //     value: false,
      //     _children: [
      //         {
      //             name: "List",
      //             to: "/dashboard",
      //             siteurl: "/user/dashboard-count",
      //             icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //             value: false,
      //         },
      //         // {
      //         //     name: "Details",
      //         //     to: "/dashboard",
      //         //     siteurl: "/user/dashboard-count",
      //         //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //         //     value: false,
      //         // },
      //         // {
      //         //     name: "Edit",
      //         //     to: "/dashboard",
      //         //     siteurl: "/user/dashboard-count",
      //         //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //         //     value: false,
      //         // }
      //     ],
      // },
      // {
      //     name: "Invoices",
      //     to: "/dashboard",
      //     siteurl: "/user/dashboard-count",
      //     icon: "assets/images/sidebar-menu-image/bill.svg",
      //     value: false,
      //     _children: [
      //         {
      //             name: "List",
      //             to: "/dashboard",
      //             siteurl: "/user/dashboard-count",
      //             icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //             value: false,
      //         },
      //         // {
      //         //     name: "Details",
      //         //     to: "/dashboard",
      //         //     siteurl: "/user/dashboard-count",
      //         //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //         //     value: false,
      //         // },
      //         // {
      //         //     name: "Edit",
      //         //     to: "/dashboard",
      //         //     siteurl: "/user/dashboard-count",
      //         //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //         //     value: false,
      //         // }
      //     ],
      // },
      // {
      //     name: "Reports",
      //     to: "/dashboard",
      //     siteurl: "/user/dashboard-count",
      //     icon: "assets/images/sidebar-menu-image/report.svg",
      //     value: false,
      //     _children: [
      //         {
      //             name: "List",
      //             to: "/dashboard",
      //             siteurl: "/user/dashboard-count",
      //             icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //             value: false,
      //         },
      //         // {
      //         //     name: "Details",
      //         //     to: "/dashboard",
      //         //     siteurl: "/user/dashboard-count",
      //         //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //         //     value: false,
      //         // },
      //         // {
      //         //     name: "Edit",
      //         //     to: "/dashboard",
      //         //     siteurl: "/user/dashboard-count",
      //         //     icon: "assets/images/sidebar-menu-image/pos-terminal.svg",
      //         //     value: false,
      //         // }
      //     ],
      // },
    ],
  },

  // {
  //   name: "Offline Orders",
  //   fontIcon: "fa fa-empire",
  //   _children: [
  //     // {
  //     //     name: "Offline Orders",
  //     //     to: "/offlineOrders",
  //     //     siteurl: "/user/dashboard-count",
  //     //     icon: <ReceiptIcon color="primary" fontSize="large" />,
  //     //     value: false,
  //     //     _children: [],
  //     //     authModuleName: []
  //     // },
  //     {
  //       name: "Offline Dashboard",
  //       to: "/offlineDashboard",
  //       siteurl: "/user/dashboard-count",
  //       icon: <ReceiptIcon color="primary" fontSize="large" />,
  //       value: false,
  //       _children: [],
  //       authModuleName: ["offline-dashboard"],
  //     },
  //     {
  //       name: "Offline Orders",
  //       siteurl: "/user/dashboard-count",
  //       icon: <ShoppingBasketIcon color="primary" fontSize="large" />,
  //       value: false,
  //       _children: [
  //         // {
  //         //     name: "Virtual Orders",
  //         //     to: "/virtualOrders",
  //         //     siteurl: "/user/dashboard-count",
  //         //     icon: <DesktopMacIcon color="primary" fontSize="small" />,
  //         //     value: false,
  //         //     _children: [],
  //         //     authModuleName: ["offline-order"]
  //         // },
  //         // {
  //         //     name: "Semi-Virtual Orders",
  //         //     to: "/semiVirtualOrders",
  //         //     siteurl: "/user/dashboard-count",
  //         //     icon: <UsbIcon color="primary" fontSize="small" />,
  //         //     value: false,
  //         //     _children: [],
  //         //     authModuleName: ["offline-order"]
  //         // },
  //         {
  //           name: "Pendrive Orders",
  //           to: "/pendriveOrders",
  //           siteurl: "/user/dashboard-count",
  //           icon: <UsbIcon color="primary" fontSize="small" />,
  //           value: false,
  //           _children: [],
  //           authModuleName: ["offline-order"],
  //         },
  //       ],
  //       authModuleName: ["offline-order"],
  //     },

  //     {
  //       name: "Configuration",
  //       siteurl: "/user/dashboard-count",
  //       icon: <DesktopMacIcon color="primary" fontSize="large" />,
  //       value: false,
  //       _children: [
  //         {
  //           name: "Batch Timing",
  //           to: "/batchTiming",
  //           siteurl: "/user/dashboard-count",
  //           icon: <AccessTimeIcon color="primary" fontSize="small" />,
  //           value: false,
  //           _children: [],
  //           authModuleName: ["offline-config"],
  //         },
  //         {
  //           name: "Halls",
  //           to: "/halls",
  //           siteurl: "/user/dashboard-count",
  //           icon: <MeetingRoomIcon color="primary" fontSize="small" />,
  //           value: false,
  //           _children: [],
  //           authModuleName: ["offline-config"],
  //         },
  //         {
  //           name: "Attempts",
  //           to: "/attempts",
  //           siteurl: "/user/dashboard-count",
  //           icon: <ImportContactsIcon color="primary" fontSize="small" />,
  //           value: false,
  //           _children: [],
  //           authModuleName: ["offline-config"],
  //         },
  //         {
  //           name: "Batches",
  //           to: "/batches",
  //           siteurl: "/user/dashboard-count",
  //           icon: <BatchPredictionIcon color="primary" fontSize="small" />,
  //           value: false,
  //           _children: [],
  //           authModuleName: ["offline-batch"],
  //         },
  //         {
  //           name: "Currency",
  //           to: "/currency",
  //           siteurl: "/user/dashboard-count",
  //           icon: <CurrencyRupeeIcon color="primary" fontSize="small" />,
  //           value: false,
  //           _children: [],
  //           authModuleName: ["currency"],
  //         },
  //         {
  //           name: "Other Payment Modes",
  //           to: "/otherPaymentModes",
  //           siteurl: "/user/dashboard-count",
  //           icon: <CreditCardIcon color="primary" fontSize="small" />,
  //           value: false,
  //           _children: [],
  //           authModuleName: ["payment-mode"],
  //         },
  //         {
  //           name: "Terms",
  //           to: "/terms",
  //           siteurl: "/user/dashboard-count",
  //           icon: <AssignmentIcon color="primary" fontSize="small" />,
  //           value: false,
  //           _children: [],
  //           authModuleName: ["offline-config"],
  //         },
  //       ],
  //       authModuleName: [
  //         "offline-config",
  //         "payment-mode",
  //         "currency",
  //         "offline-batch",
  //       ],
  //     },
  //     {
  //       name: "Centers",
  //       to: "/centers",
  //       siteurl: "/user/dashboard-count",
  //       icon: <HomeWorkIcon color="primary" fontSize="large" />,
  //       value: false,
  //       _children: [],
  //       authModuleName: ["center"],
  //     },
  //     {
  //       name: "Withdrawal & Deposit",
  //       to: "/withdrawalAndDeposit",
  //       siteurl: "/user/dashboard-count",
  //       icon: <DashboardIcon color="primary" fontSize="large" />,
  //       value: false,
  //       _children: [],
  //       authModuleName: ["withdrawal-deposit"],
  //     },
  //     {
  //       name: "All Transactions",
  //       to: "/allTransactions",
  //       siteurl: "/user/dashboard-count",
  //       icon: <DashboardIcon color="primary" fontSize="large" />,
  //       value: false,
  //       _children: [],
  //       authModuleName: ["offline-transaction"],
  //     },
  //     {
  //       name: "Account",
  //       to: "/account",
  //       siteurl: "/user/dashboard-count",
  //       icon: <AccountBalanceWalletIcon color="primary" fontSize="large" />,
  //       value: false,
  //       _children: [],
  //       authModuleName: ["cash-collection"],
  //     },
  //     // {
  //     //     name: "Withdrawal All",
  //     //     to: "/withdrawalAll",
  //     //     siteurl: "/user/dashboard-count",
  //     //     icon: <MoneyIcon color="primary" fontSize="large" />,
  //     //     value: false,
  //     //     _children: [],
  //     //     authModuleName: []
  //     // },
  //     // {
  //     //     name: "Data Download",
  //     //     to: "/dataDownload",
  //     //     siteurl: "/user/dashboard-count",
  //     //     icon: <CloudDownloadIcon color="primary" fontSize="large" />,
  //     //     value: false,
  //     //     _children: [],
  //     //     authModuleName: []
  //     // },
  //   ],
  // },
];

export default navigation;
