import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup'
import ChipInput from 'material-ui-chip-input'
import BackupIcon from '@material-ui/icons/Backup';
import {
    Checkbox, TextField, Grid, Button, makeStyles, Select,
    FormControl, InputLabel, OutlinedInput, MenuItem, ListItemText,
} from '@material-ui/core'

import {
    useGetAallFacultieNamesQuery, useGetAllCategoriesQuery,
    useGetAllLevelNamesQuery, useGetAllCoursesQuery, useGetAllBooksNameQuery
} from '../../../services/services';
import { updateLocale } from 'moment';

const useStyles = makeStyles((theme) => ({
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        minWidth: 100,
    },
    tab: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export default function UpdateProduct({ UpdateProductData, editProduct, editDialog }) {
    const classes = useStyles();
    const getAllCategories = useGetAllCategoriesQuery()
    const allCategories = getAllCategories.data
    const getAllLevels = useGetAllLevelNamesQuery()
    const allLevels = getAllLevels.data
    const getAllCourse = useGetAllCoursesQuery()
    const allCourse = getAllCourse.data
    const getAllBookNames = useGetAllBooksNameQuery()
    const allBook = getAllBookNames.data
    const getAallFaculties = useGetAallFacultieNamesQuery()
    const allFaculty = getAallFaculties.data
    var [chipPaymentGateway, setChipPaymentGateway] = useState([...editProduct.allowed_payment_gateway])
    const [imageval, setImageVal] = useState(editProduct.image)

      
    const formik = useFormik({
        initialValues: {
            product_title: editProduct.product_title,
            book_title: editProduct.books[0].title,
            about_book_product: editProduct.about_book_product,
            category_name: editProduct.category_name,
            product_mrp: editProduct.product_mrp,
            course_name: editProduct.course_name,
            level_name: editProduct.level_name,
            product_format: editProduct.product_format,
            is_active: editProduct.is_active,
            nickname: editProduct.nickname,
            image: editProduct.image,
            is_mrp_show: editProduct.is_mrp_show,
            selling_price: editProduct.selling_price,
            syllabus: editProduct.syllabus,
            important_notice: editProduct.important_notice,
            note: editProduct.note,
            meta: editProduct.meta,
            link: editProduct.link,
            wallet_discount_is_applied: editProduct.wallet_discount_is_applied,
            wallet_discount: editProduct.wallet_discount,
            sld_discount_50: editProduct.sld_discount_50,
            franchise_discount_50: editProduct.franchise_discount_50,
            cod_eligible: editProduct.cod_eligible,
            gst_applied_percentage: editProduct.gst_applied_percentage,
            allowed_payment_gateway: editProduct.allowed_payment_gateway,
            dispatch_by: editProduct.dispatch_by,
            dispatch_time: editProduct.dispatch_time,
            delievery_time: editProduct.delievery_time,
            commission_offered: editProduct.commission_offered,
            invoice_name: editProduct.invoice_name,
            payment_to_faculty: editProduct.payment_to_faculty,
            commision_amount: editProduct.commision_amount,
            price_onwhich_commission_calculated: editProduct.price_onwhich_commission_calculated,
            sharing_percent: editProduct.sharing_percent,
            is_combo: editProduct.is_combo,
            is_hidden: editProduct.is_hidden,
            other_coupon_applied: editProduct.other_coupon_applied,
        },
        // validationSchema: yup.object({
        //     product_title: yup.string().required("Required !"),
        //     book_title: yup.string().required("Required !"),
        //     about_book_product: yup.string().required("Required !"),
        //     category_name: yup.string().required("Required !"),
        //     product_mrp: yup.number().required("Required !"),
        //     product_format: yup.string().required("Required !"),
        //     is_active: yup.string().required("Required !"),
        //     nickname: yup.string().required("Required !"),
        //     is_mrp_show: yup.string().required("Required !"),
        //     selling_price: yup.number().required("Required !"),
        //     syllabus: yup.string().required("Required !"),
        //     important_notice: yup.string().required("Required !"),
        //     note: yup.string().required("Required !"),
        //     meta: yup.string().required("Required !"),
        //     link: yup.string().required("Required !"),
        //     wallet_discount_is_applied: yup.string().required("Required !"),
        //     wallet_discount: yup.number().required("Required !"),
        //     sld_discount_50: yup.number().required("Required !"),
        //     franchise_discount_50: yup.number().required("Required !"),
        //     cod_eligible: yup.string().required("Required !"),
        //     gst_applied_percentage: yup.number().required("Required !"),
        //     dispatch_by: yup.string().required("Required !"),
        //     dispatch_time: yup.string().required("Required !"),
        //     delievery_time: yup.string().required("Required !"),
        //     commission_offered: yup.number().required("Required !"),
        //     invoice_name: yup.string().required("Required !"),
        //     payment_to_faculty: yup.number().required("Required !"),
        //     commision_amount: yup.number().required("Required !"),
        //     price_onwhich_commission_calculated: yup.number().required("Required !"),
        //     sharing_percent: yup.number().required("Required !"),
        //     is_combo: yup.string().required("Required !"),
        //     is_hidden: yup.string().required("Required !"),
        //     // is_deleted: yup.string().required("Required !"),
        //     other_coupon_applied: yup.string().required("Required !"),
        //     course_name: yup.string().required("Required !"),
        //     level_name: yup.string().required("Required !"),

        // }),
        onSubmit: (values) => {
            values.allowed_payment_gateway = chipPaymentGateway
            values.image = imageval
              
            var form_data = new FormData()
            for (let key in values) {
                form_data.append(key, values[key])
            }
              
            UpdateProductData(form_data, editProduct._id)
        }
    })

    const [facultyValue, setFacultyValue] = useState([])

    const facultyChange = (event) => {
        const { target: { value } } = event;
        setFacultyValue(typeof value === 'string' ? value.split(',') : value);
    };

    const dialogclose = () => {
        editDialog(false)
    }
    const ChipChange = (chip) => {

    }
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3} >

                    <Grid item xs={6}>

                        <Button
                            onChange={(e) => {
                                formik.setFieldValue("image", {
                                    // fieldname: "image",
                                    name: e.target.files[0].name,
                                    // size: e.target.files[0].size,
                                    type: e.target.files[0].type,
                                })
                            }}
                            onBlur={formik.handleBlur}
                            variant="outlined"
                            fullWidth={true}
                            startIcon={<BackupIcon />}
                            color="primary"
                            component="label" >
                            Upload Product Image
                            <input onChange={(e) => {
                                // setImageVal(e.target.value)
                                setImageVal(e.target.files[0])
                            }} name="image" type="file" hidden />
                        </Button>
                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="product_title"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.product_title}
                            fullWidth={true}
                            label="Product Title"
                            variant="outlined"
                            size="large"
                        />
                        {formik.touched.product_title && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.product_title}</span>}

                    </Grid>


                    <Grid item xs={6}  >

                        <FormControl style={{ margin: 0 }} fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel htmlFor="outlined-Institue-Name-native-simple">Book Title</InputLabel>
                            <Select native
                                size='large'
                                name="book_title"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.book_title}
                                label="Book Title">
                                <option aria-label="None" value="" />
                                {allBook && allBook.data.map((data) => <option value={data.title}>{data.title}</option>)}
                            </Select>
                        </FormControl>
                        {formik.touched.book_title && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.book_title}</span>}
                    </Grid>


                    <Grid item xs={6}  >
                        <TextField
                            name="about_book_product"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.about_book_product}
                            fullWidth={true}
                            label="About Book Product"
                            variant="outlined"
                            size="large"
                        />
                        {formik.touched.about_book_product && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.about_book_product}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="selling_price"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.selling_price}
                            fullWidth={true}
                            label="Selling Price"
                            variant="outlined"
                            size="large"
                        />
                        {formik.touched.selling_price && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.selling_price}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="important_notice"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.important_notice}
                            fullWidth={true}
                            label="Important Notice"
                            variant="outlined"
                            size="large"
                        />
                        {formik.touched.important_notice && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.important_notice}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="note"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.note}
                            fullWidth={true}
                            label="Note"
                            variant="outlined"
                            size="large"
                        />
                        {formik.touched.note && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.note}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="meta"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.meta}
                            fullWidth={true}
                            label="Meta"
                            variant="outlined"
                            size="large"
                        />
                        {formik.touched.meta && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.meta}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="link"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.link}
                            fullWidth={true}
                            label="Link"
                            variant="outlined"
                            size="large"
                        />
                        {formik.touched.link && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.link}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="wallet_discount"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.wallet_discount}
                            fullWidth={true}
                            label="Wallet Discount"
                            variant="outlined"
                            size="large"
                        />
                        {formik.touched.wallet_discount && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.wallet_discount}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="sld_discount_50"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.sld_discount_50}
                            fullWidth={true}
                            label="Sld Discount 50"
                            variant="outlined"
                            size="large"
                        />
                        {formik.touched.sld_discount_50 && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.sld_discount_50}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="franchise_discount_50"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.franchise_discount_50}
                            fullWidth={true}
                            label="Franchise Discount 50"
                            variant="outlined"
                            size="large"
                        />
                        {formik.touched.franchise_discount_50 && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.franchise_discount_50}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="gst_applied_percentage"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.gst_applied_percentage}
                            fullWidth={true}
                            label="GST Applied Percentage"
                            variant="outlined"
                            size="large"
                        />
                        {formik.touched.gst_applied_percentage && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.gst_applied_percentage}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="dispatch_by"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.dispatch_by}
                            fullWidth={true}
                            label="Dispatch By"
                            variant="outlined"
                            size="large"
                        />
                        {formik.touched.dispatch_by && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.dispatch_by}</span>}

                    </Grid>

                    <Grid item xs={6}  >


                        <TextField
                            fullWidth={true}
                            variant="outlined"
                            name="dispatch_time"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.dispatch_time}
                            id="time"
                            label="Dispatch Time"
                            type="time"
                            size="large"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                            sx={{ width: 150 }}
                        />

                        {formik.touched.dispatch_time && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.dispatch_time}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            fullWidth={true}
                            variant="outlined"
                            name="delievery_time"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.delievery_time}
                            id="time"
                            label="Processing Time"
                            type="time"
                            size="large"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                            sx={{ width: 150 }}
                        />
                        {formik.touched.delievery_time && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.delievery_time}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="commission_offered"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.commission_offered}
                            fullWidth={true}
                            label="Commission Offered"
                            variant="outlined"
                            size="large"
                        />
                        {formik.touched.commission_offered && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.commission_offered}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="invoice_name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.invoice_name}
                            fullWidth={true}
                            label="Invoice Name"
                            variant="outlined"
                            size="large"
                        />
                        {formik.touched.invoice_name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.invoice_name}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="payment_to_faculty"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.payment_to_faculty}
                            fullWidth={true}
                            label="Payment To Faculty"
                            variant="outlined"
                            size="large"
                        />
                        {formik.touched.payment_to_faculty && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.payment_to_faculty}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="nickname"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.nickname}
                            fullWidth={true}
                            label="Nickname"
                            variant="outlined"
                            size="large"
                        />
                        {formik.touched.nickname && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.nickname}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="commision_amount"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.commision_amount}
                            fullWidth={true}
                            label="Commision Amount"
                            variant="outlined"
                            size="large"
                        />
                        {formik.touched.commision_amount && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.commision_amount}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="price_onwhich_commission_calculated"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.price_onwhich_commission_calculated}
                            fullWidth={true}
                            label="Price Onwhich Commission Calculated"
                            variant="outlined"
                            size="large"
                        />
                        {formik.touched.price_onwhich_commission_calculated && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.price_onwhich_commission_calculated}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="sharing_percent"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.sharing_percent}
                            fullWidth={true}
                            label="Sharing Percent"
                            variant="outlined"
                            size="large"
                        />
                        {formik.touched.sharing_percent && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.sharing_percent}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <FormControl style={{ margin: 0 }} fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel htmlFor="outlined-Institue-Name-native-simple">Category Name </InputLabel>
                            <Select native
                                name="category_name"
                                size='large'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.category_name}
                                label="Category Name">
                                <option aria-label="None" value="" />
                                {allCategories && allCategories.data.map((data) => <option value={data.category_name}>{data.category_name}</option>)}
                            </Select>
                        </FormControl>
                        {formik.touched.category_name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.category_name}</span>}

                    </Grid>


                    <Grid item xs={6}  >
                        <FormControl style={{ margin: 0 }} fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel htmlFor="outlined-Institue-Name-native-simple">Level</InputLabel>
                            <Select native
                                name="level_name"
                                size='large'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.level_name}
                                label="Level">
                                <option aria-label="None" value="" />
                                {allLevels && allLevels.data.map((data) => <option value={data.level_name}>{data.level_name}</option>)}
                            </Select>
                        </FormControl>
                        {formik.touched.level_name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.level_name}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <FormControl style={{ margin: 0 }} fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel htmlFor="outlined-Institue-Name-native-simple">Course</InputLabel>
                            <Select native
                                name="course_name"
                                size='large'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.course_name}
                                label="Course">
                                {allCourse && allCourse.data.map((data) => <option value={data.name}>{data.name}</option>)}
                            </Select>
                        </FormControl>
                        {formik.touched.course_name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.course_name}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <FormControl style={{ margin: 0 }} fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel htmlFor="outlined-Institue-Name-native-simple">Product Formate</InputLabel>
                            <Select native
                                name="product_format"
                                size='large'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.product_format}
                                label="Product Format">
                                <option aria-label="None" value="" />
                                <option>Hard Copy</option>
                                <option>Soft Copy</option>
                            </Select>
                        </FormControl>
                        {formik.touched.product_format && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.product_format}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <TextField
                            name="product_mrp"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.product_mrp}
                            fullWidth={true}
                            label="Product MRP"
                            variant="outlined"
                            size="large"
                        />
                        {formik.touched.product_mrp && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.product_mrp}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <FormControl style={{ margin: 0 }} fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel htmlFor="outlined-Institue-Name-native-simple">Active</InputLabel>
                            <Select native
                                name="is_active"
                                size='large'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.is_active}
                                label="Active">
                                <option aria-label="None" value="" />
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </Select>
                        </FormControl>
                        {formik.touched.is_active && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.is_active}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <FormControl style={{ margin: 0 }} fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel htmlFor="outlined-Institue-Name-native-simple">MRP Show</InputLabel>
                            <Select native
                                name="is_mrp_show"
                                size='large'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.is_mrp_show}
                                label="MRP Show">
                                <option aria-label="None" value="" />
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </Select>
                        </FormControl>
                        {formik.touched.is_mrp_show && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.is_mrp_show}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <FormControl style={{ margin: 0 }} fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel htmlFor="outlined-Institue-Name-native-simple">Combo</InputLabel>
                            <Select native
                                name="is_combo"
                                size='large'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.is_combo}
                                label="Combo">
                                <option aria-label="None" value="" />
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </Select>
                        </FormControl>
                        {formik.touched.is_combo && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.is_combo}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <FormControl style={{ margin: 0 }} fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel htmlFor="outlined-Institue-Name-native-simple">Hidden</InputLabel>
                            <Select native
                                name="is_hidden"
                                size='large'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.is_hidden}
                                label="Hidden">
                                <option aria-label="None" value="" />
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </Select>
                        </FormControl>
                        {formik.touched.is_hidden && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.is_hidden}</span>}

                    </Grid>


                    {/* <Grid item xs={6}  >
                        <FormControl style={{ margin: 0 }} fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel htmlFor="outlined-Institue-Name-native-simple">Deleted</InputLabel>
                            <Select native
                                name="is_deleted"
                                size='large'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.is_deleted}
                                label="Deleted">
                                <option aria-label="None" value="" />
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </Select>
                        </FormControl>
                        {formik.touched.is_deleted && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.is_deleted}</span>}

                    </Grid> */}

                    <Grid item xs={6}  >
                        <FormControl style={{ margin: 0 }} fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel htmlFor="outlined-Institue-Name-native-simple">Other Coupon Applied</InputLabel>
                            <Select native
                                name="other_coupon_applied"
                                size='large'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.other_coupon_applied}
                                label="Other Coupon Applied">
                                <option aria-label="None" value="" />
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </Select>
                        </FormControl>
                        {formik.touched.other_coupon_applied && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.other_coupon_applied}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <FormControl style={{ margin: 0 }} fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel htmlFor="outlined-Institue-Name-native-simple">Syllabus</InputLabel>
                            <Select native
                                name="syllabus"
                                size='large'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.syllabus}
                                label="Syllabus">
                                <option aria-label="None" value="" />
                                <option value="OLD">OLD</option>
                                <option value="NEW">NEW</option>
                            </Select>
                        </FormControl>
                        {formik.touched.syllabus && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.syllabus}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <FormControl style={{ margin: 0 }} fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel htmlFor="outlined-Institue-Name-native-simple">Wallet discount Applied</InputLabel>
                            <Select native
                                name="wallet_discount_is_applied"
                                size='large'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.wallet_discount_is_applied}
                                label="Wallet discount Applied">
                                <option aria-label="None" value="" />
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </Select>
                        </FormControl>
                        {formik.touched.wallet_discount_is_applied && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.wallet_discount_is_applied}</span>}

                    </Grid>

                    <Grid item xs={6}  >
                        <FormControl style={{ margin: 0 }} fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel htmlFor="outlined-Institue-Name-native-simple">COD Eligible</InputLabel>
                            <Select native
                                name="cod_eligible"
                                size='large'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.cod_eligible}
                                label="COD Eligible">
                                <option aria-label="None" value="" />
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </Select>
                        </FormControl>
                        {formik.touched.cod_eligible && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.cod_eligible}</span>}

                    </Grid>

                    <Grid item xs={6}>
                        <ChipInput
                            defaultValue={chipPaymentGateway}
                            onBlur={formik.handleBlur}
                            label="Allow Payment Gateway "
                            variant="outlined"
                            fullWidth={true}
                            newChipKeyCodes={[13, 32, 9]}
                            onChange={(chips) => {
                                ChipChange(chips)
                                setChipPaymentGateway(chips)
                            }}
                        />

                    </Grid>

                    <Grid item xs={12}  >
                        <div className="d-flex justify-content-end">
                            <Button
                                onClick={dialogclose}
                                variant="contained"
                                color="secondary"
                            >Close</Button>
                            <Button
                                type="submit"
                                style={{ marginLeft: 20 }}
                                variant="contained"
                                color="primary"
                            > Save</Button>
                        </div>
                    </Grid>
                    {/* 2nd Grid */}

                </Grid>

            </form>

        </>

    )
}
