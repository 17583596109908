import React from 'react'
import { Button, Grid, Box } from '@material-ui/core'
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import ContactMailIcon from '@material-ui/icons/ContactMail';
import WifiIcon from '@material-ui/icons/Wifi';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

function ViewQuote({ viewDialog, data }) {
      
    return (
        <div className='p-3'>

            <Box sx={{
                flexGrow: 1,
                bgcolor: 'background.paper',
                //  display: 'flex', 
                //  height: 554 
            }}>
                {/* <div>

                    <h1>Name : {data.name} </h1>

                </div> */}

                <div class="card">
                    <div class="card-body">
                        <Grid container >

                            <Grid xs={6} className=' my-1'>
                                <div style={{ width: 200 }}>
                                    {/* <img src={data.image} alt="..." class="img-thumbnail" /> */}
                                </div>
                            </Grid>

                            <Grid xs={12} className=' my-1'>
                                <Button
                                    onClick={() => viewDialog(false)}
                                    className="text-light bg-danger float-right btn btn-sm btn-danger">
                                    Close
                                </Button>

                            </Grid>

                            <Grid xs={6} className='my-2'>

                                <h5 className="mb-3  m-2 d-flex align-items-center">
                                    <PersonIcon className="mr-1" /> : <span className="text-muted px-2">
                                        {data.first_name} {data.name}
                                    </span>
                                </h5>

                                <h5 className="mb-3  m-2 d-flex align-items-center">
                                    <PhoneIcon className="mr-1" /> : <span className="text-muted px-2">
                                        {data.mobile}
                                    </span>
                                </h5>

                                <h5 className="mb-3  m-2 d-flex align-items-center">
                                    <EmailIcon className="mr-1" /> : <span className="text-muted px-2">
                                        {data.email}
                                    </span>
                                </h5>


                            </Grid>


                            <Grid xs={6} className='my-2'>

                                <h5 className="mb-3  m-2 d-flex align-items-center">
                                    User Name : <span className="text-muted px-2">
                                        {data.username}
                                    </span>
                                </h5>


                                <h5 className="mb-3  m-2 d-flex ">
                                    LogIn : <span className="text-muted px-2">
                                        {data.is_logged_in ? "Yes" : "No"}
                                    </span>
                                </h5>

                                <h5 className="mb-3  m-2 d-flex ">
                                    Active : <span className="text-muted px-2">
                                        {data.is_active ? "Yes" : "No"}
                                    </span>
                                </h5>
                            </Grid>



                        </Grid>

                    </div>
                </div>
            </Box>

        </div>
    )
}

export default ViewQuote
