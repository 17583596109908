import React, { useEffect, useState } from "react";
import { Button, Grid, Box, makeStyles, withStyles, TextField, FormControl, InputLabel, Select, MenuItem, Typography, Dialog, Zoom, DialogTitle, DialogContent } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import EditDelete from "../EditDelete";
import Toster from "../Toster";
import BackupIcon from '@material-ui/icons/Backup';
import * as yup from 'yup'
import { useFormik } from 'formik';
import {
  useUpdateFacultyMutation,
  useGetSingleFacultyMutation
} from '../../../../services/services'
import { phoneRegex } from '../../../../constant/regularExpression'
import moment from "moment";
import SkeletonListing from "../SkeletonListing"
import DataNotFound from "../DataNotFound";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: 18,
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  table: {
    padding: 10,
    margin: 5,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function FacultyImage({ viewDialog, data, setFacultyData }) {
  const classes = useStyles();
  const [dataForUpdate, setDataForUpdate] = useState({})
  const [editDialog, setEditDialog] = useState(false)
  const [updateData, updateDataInfo] = useUpdateFacultyMutation();
  const [singleFacultyData, singleFacultyDataInfo] = useGetSingleFacultyMutation();

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  useEffect(() => {
    singleFacultyData(data._id)
  }, [updateDataInfo])

  const dialogclose = () => {
    setEditDialog(false);
  };

  return (
    <div className="p-3">
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
        }}
      >
        <div class="card">
          <div class="card-body">
            <Grid container>
              <Grid xs={12} className=" my-1">
                <EditDelete setEditDialog={setEditDialog} setData={setDataForUpdate} type="edit"
                  data={singleFacultyDataInfo.isLoading == false && singleFacultyDataInfo.isSuccess == true && singleFacultyDataInfo.data.data.length
                    ? singleFacultyDataInfo.data.data[0]
                    : data}
                />
                <Button
                  onClick={() => viewDialog(false)}
                  className="text-light bg-danger float-right btn btn-sm btn-danger"
                >
                  Close
                </Button>
              </Grid>
              {singleFacultyDataInfo.isSuccess == true && singleFacultyDataInfo.data.data.length ?
                singleFacultyDataInfo.isLoading == false ?

                  <Grid xs={3} className="my-1">
                    {setFacultyData(singleFacultyDataInfo.data.data[0])}
                    <div style={{ width: 200 }}>
                      <img src={singleFacultyDataInfo.data.data[0].image} alt="..." class="img-thumbnail" />
                    </div>
                  </Grid>


                  :
                  <SkeletonListing />
                :
                <Grid container justifyContent="center">
                 <DataNotFound/>
                </Grid>
              }

            </Grid>
          </div>
        </div>
      </Box>
      {editDialog ? <UpdateFaculty updateDialog={editDialog} setUpdateDialog={setEditDialog} dataForUpdate={dataForUpdate} updateData={updateData} /> : null}
      {updateDataInfo.data && (
        <Toster info={updateDataInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}

function UpdateFaculty({ updateDialog, setUpdateDialog, dataForUpdate, updateData }) {
  const classes = useStyles();
  const [imageval, setImageVal] = useState("")

  const formik = useFormik({

    initialValues: {
      image: ""
    },
    validationSchema: yup.object({
      image: yup.string().required("Required!"),
    }),
    onSubmit: (values, e) => {
      var form_data = new FormData()
      form_data.append('key', 'image')
      form_data.append('update_data', '')
      form_data.append('image', imageval)
      updateData({ data: form_data, id: dataForUpdate._id })
    },
  })

  return (
    <Dialog
      fullScreen={false}
      open={updateDialog}
      //scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      // maxWidth="lg"
      fullWidth={true}
      TransitionComponent={Zoom}
    >
      <DialogTitle id="scroll-dialog-title">Update Image</DialogTitle>
      <DialogContent
      //dividers={scroll === "paper"}
      >
        <form id="addfacultyform" onSubmit={formik.handleSubmit}>
          <Grid style={{ paddingLeft: 10, paddingRight: 10, marginTop: 20, marginLeft: 10 }} >
            <Button
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth={true}
              startIcon={<BackupIcon />}
              color="primary"
              component="label">
              Upload Image
              <input
                onChange={(e) => {
                  setImageVal(e.target.files[0])
                }} name="image" type="file" hidden />
            </Button>
            {imageval.name != undefined ? <span>{imageval.name}</span> : null}
            {formik.touched.image && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.image}</span>}

            <br />
            <br />
            <Grid container spacing={3} >
              <Grid item xs={12}>
                <div className="text-right">
                  <Button
                    onClick={() => setUpdateDialog(false)}
                    variant="contained"
                    color="secondary">
                    Close</Button>
                  <Button
                    type="submit"
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    color="primary">
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid >
        </form >
      </DialogContent>
    </Dialog>
  )
}

export default FacultyImage;
