import React, { useState, useEffect } from "react";
import EditDelete from "./EditDelete";
import CreateTableAddButton from "./CreateTableAddButton";
import { Avatar } from "@material-ui/core";
import UpdateInstitute from "./UpdateInstitute";
import ViewInstitute from "./ViewInstitute";
import Loader from "./Loader";
import AddDataHeader from "./AddDataHeader";
import {
  useGetallInstitutesMutation,
  useDeleteInstituteMutation,
  useAddInstitutesMutation,
  useUpdateInstituteMutation,
} from "../../../services/services";
import Toster from "./Toster";
import AddInstitute from "./AddInstitute";
import SkeletonListing from "./SkeletonListing";
import DataNotFound from "./DataNotFound";
import AuthWrapper from "../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../utils/getFilterFunction";

export default function Institute() {
  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 20,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "InstituteName",
      label: "Institute",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },

    {
      id: "specialization",
      label: "Specialization",
      minWidth: 20,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "description",
      label: "Description",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "metaDescription",
      label: "Meta Description",
      minWidth: 20,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "metaKeywords",
      label: "Meta Keywords",
      minWidth: 20,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    // {
    //     id: 'IsActive',
    //     label: 'Is Active',
    //     minWidth: 50,
    //     align: 'center',
    //     format: (value) => value.toLocaleString('en-US'),
    // },
    {
      id: "Actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: [
        "FACULTY_INSTITUTE_INSTITUTE_EDIT",
        "FACULTY_INSTITUTE_INSTITUTE_DELETE",
      ],
    },
  ];
  function createData(
    SNo,
    InstituteName,
    specialization,
    description,
    metaDescription,
    metaKeywords,
    IsActive,
    Actions
  ) {
    return {
      SNo,
      InstituteName,
      specialization,
      description,
      IsActive,
      metaKeywords,
      metaDescription,
      Actions,
    };
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: ["name"],
    page: page + 1,
  };

  let rows = [];

  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [AddDialog, setDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [getAllData, getAllDataInfo] = useGetallInstitutesMutation();
  const [deleteInstitute, deleteInstituteInfo] = useDeleteInstituteMutation();
  const [addinstitue, addInstituteInfo] = useAddInstitutesMutation();
  const [updateInstitute, updateInstituteInfo] = useUpdateInstituteMutation();
  const AallInstitute = getAllDataInfo.data;
  const totalItem = AallInstitute ? AallInstitute.totalItem : 0;
  const [render, setRender] = useState(false);

  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page]);

  useEffect(() => {
    getid && deleteInstitute(getid) && setGetid("");
  }, [getid]);

  const AddData = (data) => {
    addinstitue(data);
  };
  const UpdateData = (data, id) => {
    updateInstitute({ data, id: id });
  };

  let totalData = "ok";
  if (AallInstitute) {
    totalData = AallInstitute.totalItem;
    rows = AallInstitute.data.map((data, index) =>
      createData(
        index + 1,
        <div className="">
          <div className="d-flex justify-content-center">
            <Avatar src={data.image} />
          </div>
          <span className="text-info font-weight-bold">{data.name}</span>
          <br />
        </div>,
        data.specialization,
        data.description,
        data?.metaDescription || "-",
        data?.metaKeywords || "-",
        data.is_active ? "Yes" : "No",
        <div className="d-flex justify-content-center">
          {/* <EditDelete setViewDialog={setViewDialog}
                    type="view" setData={setData}
                    setGetid={setGetid} data={data} /> */}
          <AuthWrapper type="ACTION" name="FACULTY_INSTITUTE_INSTITUTE_EDIT">
            <EditDelete
              setEditDialog={setEditDialog}
              type="edit"
              setData={setData}
              setGetid={setGetid}
              data={data}
            />
          </AuthWrapper>
          <AuthWrapper type="ACTION" name="FACULTY_INSTITUTE_INSTITUTE_DELETE">
            <EditDelete type="delete" setGetid={setGetid} id={data._id} />
          </AuthWrapper>
        </div>
      )
    );
  }
  const dialogclose = () => {
    setEditDialog(false);
    setViewDialog(false);
    setDialog(false);
    setRender(!render);
  };
  return (
    <div>
      {!viewDialog && (
        <AddDataHeader
          addFormName={"Add Institute"}
          setPage={setPage}
          page={page}
          excelBtn={false}
          totalItem={totalItem}
          isSearch={
            !AuthWrapper({
              type: "ACTION",
              name: "FACULTY_INSTITUTE_INSTITUTE_LIST",
              inBoolean: true,
            })
          }
          isPagination={
            !AuthWrapper({
              type: "ACTION",
              name: "FACULTY_INSTITUTE_INSTITUTE_LIST",
              inBoolean: true,
            })
          }
          addBtn={
            !AuthWrapper({
              type: "ACTION",
              name: "FACULTY_INSTITUTE_INSTITUTE_ADD",
              inBoolean: true,
            })
          }
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setAddDialog={setDialog}
          searchBarApi={getAllData}
          paginationData={paginationData}
          isButton={true}
          Adddialog={AddDialog}
          form={<AddInstitute AddData={AddData} setDialog={setDialog} />}
        />
      )}
      <AuthWrapper type="ACTION" name="FACULTY_INSTITUTE_INSTITUTE_LIST">
        {deleteInstituteInfo.isLoading ||
        updateInstituteInfo.isLoading ||
        addInstituteInfo.isLoading ? (
          <Loader />
        ) : (
          <>
            {" "}
            {!viewDialog ? (
              rows.length ? (
                <CreateTableAddButton
                  editDialog={editDialog}
                  rows={rows}
                  editform={
                    <UpdateInstitute
                      UpdateData={UpdateData}
                      editData={data}
                      editDialog={setEditDialog}
                    />
                  }
                  columns={getFilterdColumns(columns)}
                  isButton={true}
                  fullWidth={false}
                  editFormName={"Edit Institute"}
                />
              ) : totalData == 0 ? (
                <DataNotFound />
              ) : (
                <SkeletonListing />
              )
            ) : (
              <ViewInstitute viewDialog={setViewDialog} data={data} />
            )}
          </>
        )}
      </AuthWrapper>
      {deleteInstituteInfo.data && (
        <Toster info={deleteInstituteInfo} dialogclose={dialogclose} />
      )}
      {addInstituteInfo.data && (
        <Toster info={addInstituteInfo} dialogclose={dialogclose} />
      )}
      {updateInstituteInfo && (
        <Toster info={updateInstituteInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
