import React, { useState, useEffect } from "react";
import EditDelete from "../../EditDelete";
import CreateTableAddButton from "../../CreateTableAddButton";
import ViewBlog from "../../blog/ViewBlog";
import Toster from "../../Toster";
import AddBlog from "../../blog/AddBlog";
import Loader from "../../Loader";
import AddDataHeader from "../../AddDataHeader";
import {
  useGetAllQuestionMutation,
  useUpdateAnswerMutation,
  useDeleteQuestionMutation,
  useGetQuestionByIdMutation,
  useDeleteAnswerMutation,
} from "../../../../../services/questionService";

import SkeletonListing from "../../SkeletonListing";
import DataNotFound from "../../DataNotFound";
import { Avatar } from "@material-ui/core";
import UpdateQuestion from "../Update/UpdateQuestion";
import ViewQuestion from "../View/ViewQuestion";
import AuthWrapper from "../../../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../../../utils/getFilterFunction";

export default function QuestionList() {
  const columns = [
    {
      id: "SNo",
      label: "Sr. No.",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "question",
      label: "Question",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "productType",
      label: "ProductType",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "askedByUserName",
      label: "UserName",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "ansBy",
      label: "AnswerCount",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "Action",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: ["REVIEW_QUESTIONS_QUESTIONS_DELETE", "REVIEW_QUESTIONS_QUESTIONS_VIEW", "REVIEW_QUESTIONS_QUESTIONS_EDIT"],
    },
  ];

  function createData(
    SNo,
    question,
    productType,
    askedByUserName,
    ansBy,
    Action
  ) {
    return {
      SNo,
      question,
      productType,
      askedByUserName,
      ansBy,
      Action,
    };
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: ["askedByUserName"],
    page: page + 1,
  };

  let rows = [];

  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [singledata, setSingleData] = useState("");
  const [AddDialog, setDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [getAllData, getAllDataInfo] = useGetAllQuestionMutation();
  const [deleteData, deleteDataInfo] = useDeleteQuestionMutation();
  const [updateAns, updateDataInfo] = useUpdateAnswerMutation();
  const [deleteAns, deleteAnsInfo] = useDeleteAnswerMutation();
  const [getDataById, getDataByIdInfo] = useGetQuestionByIdMutation();
  const AllData = getAllDataInfo.data;
  const totalItem = AllData ? AllData.totalItem : 0;
  const [render, setRender] = useState(false);

  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page]);

  useEffect(() => {
    getid && deleteData(getid) && setGetid("");
  }, [getid]);

  const UpdateData = (data) => {
    updateAns(data);
  };

  let totalData = "ok";
  if (AllData) {
    totalData = AllData.totalItem;
    rows = AllData.data.map((data, index) =>
      createData(
        index + 1,
        data?.question ? (
          <div>
            <span className="text-info font-weight-bold">{data?.question}</span>
            <br />
          </div>
        ) : null,
        data?.productType,
        data?.askedByUserName,
        data?.answeres?.length,
        <div className="d-flex justify-content-center">
          <div
            onClick={() => {
              getDataById(data._id).then((res) => {
                setSingleData(res?.data?.data);
              });
            }}
          >
            <AuthWrapper type="ACTION" name="REVIEW_QUESTIONS_QUESTIONS_VIEW">
              <EditDelete
                setViewDialog={setViewDialog}
                type="view"
                setData={setData}
                setGetid={setGetid}
                data={data}
              />
            </AuthWrapper>
          </div>
          <AuthWrapper type="ACTION" name="REVIEW_QUESTIONS_QUESTIONS_EDIT">
            <EditDelete
              setEditDialog={setEditDialog}
              type="edit"
              setData={setData}
              setGetid={setGetid}
              data={data}
            />
          </AuthWrapper>
          <AuthWrapper type="ACTION" name="REVIEW_QUESTIONS_QUESTIONS_DELETE">
            <EditDelete type="delete" setGetid={setGetid} id={data._id} />
          </AuthWrapper>
        </div>
      )
    );
  }
  const dialogclose = () => {
    setEditDialog(false);
    setViewDialog(false);
    setDialog(false);
    setRender(!render);
  };
  return (
    <div>
      {!viewDialog && (
        <AddDataHeader
          setPage={setPage}
          page={page}
          excelBtn={false}
          totalItem={totalItem}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          isSearch={
            !AuthWrapper({
              type: "ACTION",
              name: "REVIEW_QUESTIONS_QUESTIONS_LIST",
              inBoolean: true,
            })
          }
          isPagination={
            !AuthWrapper({
              type: "ACTION",
              name: "REVIEW_QUESTIONS_QUESTIONS_LIST",
              inBoolean: true,
            })
          }
          setAddDialog={setDialog}
          searchBarApi={getAllData}
          paginationData={paginationData}
          fullsize={false}
          isButton={true}
          Adddialog={AddDialog}
        />
      )}
      {deleteDataInfo.isLoading ||
      deleteAnsInfo.isLoading ||
      updateDataInfo.isLoading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <AuthWrapper type="ACTION" name="REVIEW_QUESTIONS_QUESTIONS_LIST">
            {!viewDialog ? (
              rows?.length ? (
                <CreateTableAddButton
                  editDialog={editDialog}
                  rows={rows}
                  columns={getFilterdColumns(columns)}
                />
              ) : totalData == 0 ? (
                <DataNotFound />
              ) : (
                <SkeletonListing />
              )
            ) : (
              <ViewQuestion
                viewDialog={setViewDialog}
                data={singledata}
                deleteAns={deleteAns}
                isLoading={getDataByIdInfo?.isLoading}
              />
            )}
          </AuthWrapper>
        </>
      )}
      {editDialog && (
        <UpdateQuestion
          UpdateData={UpdateData}
          data={data}
          editDialog={setEditDialog}
        />
      )}
      {deleteDataInfo.data && (
        <Toster info={deleteDataInfo} dialogclose={dialogclose} />
      )}
      {updateDataInfo && (
        <Toster info={updateDataInfo} dialogclose={dialogclose} />
      )}
      {deleteAnsInfo && (
        <Toster info={deleteAnsInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
