import React, { useEffect, useState } from "react";
import {
  Card,
  CardActions,
  Divider,
  Menu,
  MenuItem,
  Fade,
  TextField,
  Typography,
  Accordion,
  AccordionDetails,
  Button,
  CardContent,
  Grid,
  AccordionSummary,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import {
  useOrderStatusMutation,
  useUpdateCancelOrderMutation,
  useAlreadyPurchasedDownloadMutation,
  useUpdateModeOrderMutation,
  useUpdateRemarkOrderMutation,
  useSendInvoiceMutation,
  useUpdateCashbackRefundRequestMutation,
  useUpdateOrderRequestFlageMutation,
} from "../../../services/services";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ViewOrder from "./ViewOrder";
import EditOrder from "./EditOrder";
import EditStatus from "./userAndFranchise/EditStatus";
import Toster from "./Toster";
import moment from "moment";
import MaterialTables from "./MaterialTables";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  Switch,
  FormControlLabel,
} from "@mui/material";
import AuthWrapper from "../../../utils/AuthWrapper";
import getCurrentUrl from "../../../utils/getAuthName";

export default function OrderListing({ data, inWallet }) {
  const currentPath = window.location.pathname;
  const [anchorEl, setAnchorEl] = useState(null);
  const [orderStatus, setOrderStatus] = useState([
    {
      status: "",
      sld_order_id: "",
      order_id: "",
      remark: "",
      order_type: "NORMAL",
    },
  ]);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [stausDialog, setStausDialog] = useState(false);
  const [remarkDialog, setAddRemark] = useState(false);
  const [remark, setStausRemark] = useState("");
  const [remarkError, setStausRemarkError] = useState(false);
  const [cancelRemark, setCancelRemark] = useState("");
  const [cancelRemarkError, setCancelRemarkError] = useState(false);
  const [prepaymentData, setPrepaymentData] = useState([]);
  const [prePaymentSelected, setPrePaymentSelected] = useState(false);
  const [cashbackDialog, setCashbackDialog] = useState(false);
  const [refundDialog, setRefundDialog] = useState(false);
  let initiateCashback = [];
  let initiateRefund = [];
  initiateCashback.push(
    <Button
      onClick={() => {
        setCashbackDialog(true);
      }}
      variant="contained"
      color="primary"
    >
      Initiate
    </Button>
  );
  initiateRefund.push(
    <Button
      onClick={() => {
        setRefundDialog(true);
      }}
      variant="contained"
      color="primary"
    >
      Initiate
    </Button>
  );
  let cashBackRequestLink = [];
  let refundRequestLink = [];
  cashBackRequestLink.push(
    <Link to="/studentrequests" variant="contained" color="primary">
      See Request
    </Link>
  );
  refundRequestLink.push(
    <Link
      to={{
        pathname: "/studentrequests",
        search: "?refund=true",
      }}
      variant="contained"
      color="primary"
    >
      See Request
    </Link>
  );

  const firstTableData = [
    {
      "Order Id": data.sld_order_id,
      "Payment Gateway": data.payment_gateway,
      "Transaction Id": data.payment_id,
      Status: data.current_status,
      MRP: data.all_order_mrp,
      "Effective Price": data.order_amount_received,
      QTY: data.all_order_quantity,
    },
  ];
  const secondTableData = [
    {
      Discount: data.all_order_discount_amount,
      "Discount Type": data.product_discount_type,
      Faculties: data.faculties.map((ele) => {
        return ele.faculty_name + ", ";
      }),
      "Product Code": data.video_lecture_details.code,
      Mode: data.video_lecture_details.varient.mode_name,
      Remark: data.order_remark,
      "Varient Code": data.video_lecture_details.varient.varient_code,
    },
  ];
  const thirdTableData = [
    {
      "Product Price": data.product_total_mrp / data.product_quantity,
      // "Selling Price": (data.product_total_mrp - data.product_discount_amount)/data.product_quantity,
      "Product Discount": data.product_discount_amount,
      "Product Quantity": data.product_quantity,
      "Product MRP": data.product_total_mrp,
      "Order Total": data.all_order_mrp,
      "Ordered On": data.order_date
        ? moment(data.order_date).format("DD-MM-YYYY hh:mm A")
        : "",
    },
  ];
  const trackingTableData = [
    {
      "Tracking Email": data.tracking_email,
      "Tracking Id": data.tracking_id,
      "Tracking Link": data.tracking_link,
      "Tracking Support Number": data.tracking_support_number,
      "Note for seller": data.note_for_seller,
    },
  ];
  const shippingTableData = [
    {
      "Shipping Name":
        data.shipping_details.first_name +
        " " +
        data.shipping_details.last_name,
      "Shipping Mobile": data.shipping_details.mobile,
      "Alternate Mobile": data.shipping_details.alt_mobile || "-",
      "Shipping Email": data.shipping_details.email,
      "Shipping Address":
        data.shipping_address.address_line1 +
        ", " +
        data.shipping_address.address_line2,
      "Shipping Pincode": data.shipping_address.pincode,
      "Shipping City": data.shipping_address.city,
      "Shipping State": data.shipping_address.state,
    },
  ];

  const orderPlacedByAccount = [
    {
      "Order Placed Name":
        data?.order_placed_by?.first_name +
        " " +
        data?.order_placed_by?.last_name,
      "Order Placed Mobile": data?.order_placed_by?.mobile,
      "Order Placed  Email": data?.order_placed_by?.email,
      "Assistance Provided By Caller":
        data?.assistance_provided_by?.sld_user_name,
    },
  ];

  const cashbackTableData = [
    {
      Amount: data?.cashback?.amount,
      "Applied For Cashback": data?.cashback?.applied_for_cashback
        ? "YES"
        : "NO",
      "Applied On Date": data?.cashback?.applied_on_date,
      "Cashback Initiated": data?.cashback?.cashback_initiated ? "YES" : "NO",
      // "Created By Id":data?.cashback?.created_by_id,
      "Demand Amount": data?.cashback?.demandAmount,
      "Created By Type": data?.cashback?.created_by_type,
      "Payment Date": data?.cashback?.payment_date,
      Remark: data?.cashback?.remark,
      Action:
        data.cashback.applied_for_cashback &&
        !data.cashback.cashback_initiated &&
        data.cashback.is_approved
          ? initiateCashback
          : data.cashback.applied_for_cashback &&
            !data.cashback.cashback_initiated &&
            !data.cashback.is_approved
          ? cashBackRequestLink
          : null,
    },
  ];
  const refundTableData = [
    {
      Amount: data?.refund?.amount,
      "Applied For Refund": data?.refund?.applied_for_refund ? "YES" : "NO",
      "Applied On Date": data?.refund?.applied_on_date,
      // "Created By Id": data?.refund?.created_by_id,
      "Created By Type": data?.refund?.created_by_type,
      "Payment Date": data?.refund?.payment_date,
      "Refund Initiated": data?.refund?.refund_initiated ? "YES" : "NO",
      Remark: data?.refund?.remark,
      Action:
        data.refund.applied_for_refund &&
        !data.refund.refund_initiated &&
        data.refund.is_approved
          ? initiateRefund
          : data.refund.applied_for_refund &&
            !data.refund.refund_initiated &&
            !data.refund.is_approved
          ? refundRequestLink
          : null,
    },
  ];
  let invoiceData = {
    sld_order_id: data.sld_order_id,
    order_id: data._id,
  };

  const [comp, setComp] = useState(false);
  const [count, setCount] = useState(0);

  const [dataOrder, setData] = useState(false);
  const [editeDialog, setEditeDialog] = useState(false);
  const [statusDialog, setStatusDialog] = useState(false);

  const [orderStatusData, setOrderStatusInfo] = useOrderStatusMutation();
  const [orderCancel, setOrderCancelInfo] = useUpdateCancelOrderMutation();
  const [downloadInvoice, setDownloadInvoice] =
    useAlreadyPurchasedDownloadMutation();
  const [orderMode, setOrderModeInfo] = useUpdateModeOrderMutation();
  const [orderRemark, setOrderRemarkInfo] = useUpdateRemarkOrderMutation();
  const [sendInvoice, setSendInvoiceInfo] = useSendInvoiceMutation();
  const [updateRefundCashback, setUpdateRefundCashbackInfo] =
    useUpdateCashbackRefundRequestMutation();
  const [updateOrderRequestFlage, setUpdateOrderRequestFlageInfo] =
    useUpdateOrderRequestFlageMutation();
  // const [alreadyLoadingState, setAlreadyLoadingState] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setStausRemarkError(false);
    setStausRemark("");
    setCancelDialog(false);
    setCancelRemarkError(false);
    setCancelRemark("");
  };
  const handleSwitch = (order_id, request_type, action_type) => {
    updateOrderRequestFlage({
      order_id,
      request_type,
      action_type: action_type ? "SHOW" : "HIDE",
    });
  };
  useEffect(() => {
    if (count > 0) {
      if (
        prepaymentData.length > 0 &&
        orderStatus.status == "CONFIRMED" &&
        prePaymentSelected
      ) {
        let dataToSend = {
          ...orderStatus,
          order_type: "PRE_PAYMENT",
          faculty_pre_payment: prepaymentData,
        };
        orderStatusData(dataToSend);
        setStausDialog(true);
      } else {
        orderStatusData(orderStatus);
        setStausDialog(true);
      }
    }
  }, [count]);

  const UpdateData = (body) => {
    orderMode(body);
    setEditeDialog(false);
  };
  const UpdateRemark = () => {
    if (remark) {
      setStausRemarkError(false);
      var body = { remark: remark, id: data._id };
      orderRemark(body);
      setAddRemark(false);
      setStausRemark("");
    } else {
      setStausRemarkError(true);
    }
  };
  const dialogclose = () => {
    setCancelDialog(false);
    setStausDialog(false);
    setEditeDialog(false);
  };
  const handleCancelRemark = () => {
    if (cancelRemark) {
      setCancelRemarkError(false);
      var body = { remark: { remark: cancelRemark }, id: data._id };
      orderCancel(body);
      setCancelDialog(false);
      setCancelRemark("");
    } else {
      setCancelRemarkError(true);
    }
  };

  // download invoice
  const DownloadInvoice = (id) => {
    downloadInvoice(id).then((res) => {
      if (res.error) {
      } else {
        window.open(res.data.data, "_blank");
      }
    });
  };

  if (setSendInvoiceInfo.isLoading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <div>
          <div className="d-flex justify-content-center mb-2">
            <CircularProgress color="inherit" />
          </div>
          Please Wait a while , We are trying to send Email Invoice
        </div>
      </Backdrop>
    );
  }

  return setUpdateOrderRequestFlageInfo.isLoading ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <div className="d-flex justify-content-center mb-2">
        <CircularProgress color="inherit" />
      </div>
    </Backdrop>
  ) : (
    <div className="mb-5 px-2">
      <Card
        style={{ borderTop: "2px solid #5664D2" }}
        sx={{ minWidth: 275 }}
        className=""
      >
        <CardContent style={{ borderTop: "5px solid #9cadbf" }}>
          <Grid container spacing={3}>
            <Grid item xs={9}>
              <MaterialTables
                columns={Object.keys(firstTableData[0])}
                rows={firstTableData}
              />
              <MaterialTables
                columns={Object.keys(secondTableData[0])}
                rows={secondTableData}
              />
              <MaterialTables
                columns={Object.keys(thirdTableData[0])}
                rows={thirdTableData}
              />
              <MaterialTables
                columns={Object.keys(trackingTableData[0])}
                rows={trackingTableData}
              />
            </Grid>
            <Grid
              className="text-center bg-light"
              sx={{ borderRight: 1 }}
              item
              xs={3}
            >
              <img
                style={{ width: 150 }}
                src={
                  data.video_lecture_details
                    ? data.video_lecture_details?.image[0]
                    : data.book_product_details?.image[0]
                }
                alt="..."
                class="img-thumbnail"
              />
              <br />
              <Grid
                className="text-wrap text-break m-2"
                style={{ overflow: "auto" }}
              >
                <Typography
                  style={{ fontSize: "15px", fontWeight: "500" }}
                  color="primary"
                >
                  {data.order_title}
                </Typography>
              </Grid>
              <AuthWrapper
                type="ACTION"
                name={getCurrentUrl(
                  [
                    {
                      url: "/orders",
                      name: "SUCCESSFULL_ORDERS_ORDER_CHANGE_STATUS",
                    },
                    {
                      url: "/pending-orders",
                      name: "PROCESSING_ORDERS_ORDER_CHANGE_STATUS",
                    },
                    {
                      url: "/failed-orders",
                      name: "PAYMENT_PENDING_ORDERS_CHANGE_STATUS",
                    },
                  ],
                  currentPath
                )}
              >
                {!["COMPLETED", "CANCELLED"].includes(data.current_status) && (
                  <Button
                    fullWidth={true}
                    variant="contained"
                    color="primary"
                    className="mb-1"
                    size="small"
                    onClick={(e) => {
                      setStatusDialog(true);
                      setPrepaymentData([]);
                    }}
                  >
                    change status
                  </Button>
                )}
              </AuthWrapper>
              {![
                "ORDER_PENDING",
                "CANCELLED",
                "AWAITING",
                "ORDER_ADDED",
              ].includes(data.current_status) &&
                data?.user_gst_details?.invoice_created && (
                  <Button
                    fullWidth={true}
                    variant="contained"
                    color="primary"
                    className="mb-1"
                    size="small"
                    onClick={() => {
                      sendInvoice(invoiceData);
                    }}
                  >
                    Mail Invoice
                  </Button>
                )}
              <AuthWrapper
                type="ACTION"
                name={getCurrentUrl(
                  [
                    {
                      url: "/orders",
                      name: "SUCCESSFULL_ORDERS_ORDER_EDIT_ORDER",
                    },
                    {
                      url: "/pending-orders",
                      name: "PROCESSING_ORDERS_ORDER_EDIT_ORDER",
                    },
                    {
                      url: "/failed-orders",
                      name: "PAYMENT_PENDING_ORDER_EDIT_ORDER",
                    },
                  ],
                  currentPath
                )}
              >
                {![
                  "CANCEL_REFUND_PENDING",
                  "REFUND_PENDING",
                  "DISPATCHED",
                  "COMPLETED",
                  "CANCELLED",
                ].includes(data.current_status) && (
                  <Button
                    fullWidth={true}
                    variant="contained"
                    color="primary"
                    className="mb-1"
                    size="small"
                    onClick={() => {
                      setEditeDialog(true);
                      handleClose();
                    }}
                  >
                    Edit Order
                  </Button>
                )}
              </AuthWrapper>
              <AuthWrapper
                type="ACTION"
                name={getCurrentUrl(
                  [
                    {
                      url: "/orders",
                      name: "SUCCESSFULL_ORDERS_ORDER_ADD_REMARK",
                    },
                    {
                      url: "/pending-orders",
                      name: "PROCESSING_ORDERS_ORDER_ADD_REMARK",
                    },
                    {
                      url: "/failed-orders",
                      name: "PAYMENT_PENDING_ORDERS_ORDER_ADD_REMARK",
                    },
                    {
                      url: "/cancelorders",
                      name: "CANCEL_ORDERS_ORDERS_ADD_REMARK",
                    },
                  ],
                  currentPath
                )}
              >
                {" "}
                <Button
                  fullWidth={true}
                  variant="contained"
                  color="primary"
                  className="mb-1"
                  size="small"
                  onClick={() => {
                    setAddRemark(true);
                    handleClose();
                  }}
                >
                  Add Remark
                </Button>
              </AuthWrapper>
              <AuthWrapper
                type="ACTION"
                name={getCurrentUrl(
                  [
                    {
                      url: "/orders",
                      name: "SUCCESSFULL_ORDERS_ORDER_DOWNLOAD_RECIEPT",
                    },
                    {
                      url: "/pending-orders",
                      name: "PROCESSING_ORDERS_ORDER_DOWNLOAD_RECIEPT",
                    },
                    {
                      url: "/failed-orders",
                      name: "PAYMENT_PENDING_ORDERS_ORDER_DOWNLOAD_EXCEL",
                    },
                    {
                      url: "/cancelorders",
                      name: "CANCEL_ORDERS_ORDERS_DOWNLOAD_RECIEPT",
                    },
                  ],
                  currentPath
                )}
              >
                <Button
                  fullWidth={true}
                  variant="contained"
                  color="primary"
                  className="mb-1"
                  size="small"
                  onClick={() => {
                    DownloadInvoice(data?._id);
                  }}
                  // disabled={alreadyLoadingState}
                >
                  {/* {alreadyLoadingState ? "Downloading..." : "Download Receipt"} */}
                  Download Receipt
                </Button>
              </AuthWrapper>
              {data.current_status == "CANCELLED" ? null : data?.cancelRequest
                  ?.is_requested && !data?.cancelRequest?.is_approved ? (
                <Link to="/cancelorders?cancel=true" activeClassName="selected">
                  <AuthWrapper
                type="ACTION"
                name={getCurrentUrl(
                  [
                    {
                      url: "/orders",
                      name: "SUCCESSFULL_ORDERS_ORDER_STUDENT_REQUESTED_CANCEL_ORDER",
                    },
                    {
                      url: "/pending-orders",
                      name: "PROCESSING_ORDERS_ORDER_STUDENT_REQUESTED_CANCEL_ORDER",
                    },
                    {
                      url: "/failed-orders",
                      name: "PAYMENT_PENDING_ORDERS_ORDER_STUDENT_REQUESTED_CANCEL_ORDER",
                    },
                    {
                      url: "/cancelorders",
                      name: "CANCEL_ORDERS_ORDERS_STUDENT_REQUESTED_CANCEL_ORDER",
                    },
                  ],
                  currentPath
                )}
              >
                 <Button
                    fullWidth={true}
                    variant="contained"
                    style={{ backgroundColor: "#7da5ba", color: "white" }}
                    className="mb-1"
                    size="small"
                    onClick={() => {
                      //     // setCancelDialog(true)
                      //     //orderCancel(data._id);
                    }}
                  >
                    {" "}
                    Student Requested Cancel Order
                  </Button>
              </AuthWrapper>
                 
                </Link>
              ) : data?.cancelRequest?.is_requested &&
                data?.cancelRequest?.is_approved ? (
                <Button
                  fullWidth={true}
                  variant="contained"
                  style={{ backgroundColor: "#d9423b", color: "white" }}
                  className="mb-1"
                  size="small"
                  onClick={() => {
                    setCancelDialog(true);
                    //orderCancel(data._id);
                  }}
                >
                  {" "}
                  Cancel Requested Approved please cancel
                </Button>
              ) : null}

              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="flex-start"
              >
                <AuthWrapper
                  type="ACTION"
                  name={getCurrentUrl(
                    [
                      {
                        url: "/orders",
                        name: "SUCCESSFULL_ORDERS_ORDER_CASHBACK_SHOW_STATUS",
                      },
                      {
                        url: "/pending-orders",
                        name: "PROCESSING_ORDERS_ORDER_CASHBACK_SHOW_STATUS",
                      },
                      {
                        url: "/failed-orders",
                        name: "PAYMENT_PENDING_ORDERS_CASHBACK_SHOW_STATUS",
                      },
                      {
                        url: "/cancelorders",
                        name: "CANCEL_ORDERS_ORDERS_CASHBACK_SHOW_STATUS",
                      },
                    ],
                    currentPath
                  )}
                >
                  {" "}
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onClick={() => {
                            handleSwitch(
                              data._id,
                              "CASHBACK",
                              !data.cashback.can_apply_for_cashback
                            );
                          }}
                          checked={data.cashback.can_apply_for_cashback}
                        />
                      }
                      label="Cashback Button On Web"
                    />
                  </Grid>
                </AuthWrapper>
                <AuthWrapper
                  type="ACTION"
                  name={getCurrentUrl(
                    [
                      {
                        url: "/orders",
                        name: "SUCCESSFULL_ORDERS_ORDER_CANCEL_REQUEST_SHOW_STATUS",
                      },
                      {
                        url: "/pending-orders",
                        name: "PROCESSING_ORDERS_ORDER_CANCEL_REQUEST_SHOW_STATUS",
                      },
                      {
                        url: "/failed-orders",
                        name: "PAYMENT_PENDING_ORDERS_CANCEL_REQUEST_SHOW_STATUS",
                      },
                      {
                        url: "/cancelorders",
                        name: "CANCEL_ORDERS_ORDERS_CANCEL_REQUEST_SHOW_STATUS",
                      },
                    ],
                    currentPath
                  )}
                >
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onClick={() => {
                            handleSwitch(
                              data._id,
                              "CANCELORDER",
                              !data.cancelRequest.can_apply_for_cancelRequest
                            );
                          }}
                          checked={
                            data.cancelRequest.can_apply_for_cancelRequest
                          }
                        />
                      }
                      label="Cancel Request Button On Web"
                    />
                  </Grid>
                </AuthWrapper>
                <AuthWrapper
                  type="ACTION"
                  name={getCurrentUrl(
                    [
                      {
                        url: "/orders",
                        name: "SUCCESSFULL_ORDERS_ORDER_REFUND_SHOW_STATUS",
                      },
                      {
                        url: "/pending-orders",
                        name: "PROCESSING_ORDERS_ORDER_REFUND_SHOW_STATUS",
                      },
                      {
                        url: "/failed-orders",
                        name: "PAYMENT_PENDING_ORDERS_REFUND_SHOW_STATUS",
                      },
                      {
                        url: "/cancelorders",
                        name: "CANCEL_ORDERS_ORDERS_REFUND_SHOW_STATUS",
                      },
                    ],
                    currentPath
                  )}
                >
                  {" "}
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onClick={() => {
                            handleSwitch(
                              data._id,
                              "REFUND",
                              !data.refund.can_apply_for_refund
                            );
                          }}
                          checked={data.refund.can_apply_for_refund}
                        />
                      }
                      label="Refund Button On Web"
                    />
                  </Grid>
                </AuthWrapper>
                <AuthWrapper
                  type="ACTION"
                  name={getCurrentUrl(
                    [
                      {
                        url: "/orders",
                        name: "SUCCESSFULL_ORDERS_ORDER_GST_SHOW_STATUS",
                      },
                      {
                        url: "/pending-orders",
                        name: "PROCESSING_ORDERS_ORDER_GST_SHOW_STATUS",
                      },
                      {
                        url: "/failed-orders",
                        name: "PAYMENT_PENDING_ORDERS_GST_SHOW_STATUS",
                      },
                      {
                        url: "/cancelorders",
                        name: "CANCEL_ORDERS_ORDERS_GST_SHOW_STATUS",
                      },
                    ],
                    currentPath
                  )}
                >
                  {" "}
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onClick={() => {
                            handleSwitch(
                              data._id,
                              "GST",
                              !data.user_gst_details.can_apply_for_gst
                            );
                          }}
                          checked={data.user_gst_details.can_apply_for_gst}
                        />
                      }
                      label="GST Button On Web"
                    />
                  </Grid>
                </AuthWrapper>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions></CardActions>
        {
          <div>
            <Accordion>
              <AccordionSummary
                className="text-primary"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography classes="d-flex align-items-center">
                  <span>Read More &#8811;</span>{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <div className="my-1 mx-5">
                      {" "}
                      <span className="text-muted">
                        <strong> Shipping Data</strong>
                      </span>
                    </div>
                    <MaterialTables
                      columns={Object.keys(shippingTableData[0])}
                      rows={shippingTableData}
                    />
                  </Grid>

                  {/* { ["COMPLETED"].includes(data.current_status) &&  */}
                  <Grid item xs={6}>
                    <div className="my-1 mx-5 ">
                      {" "}
                      <span className="text-muted">
                        <strong> Cashback Data</strong>
                      </span>
                    </div>
                    <MaterialTables
                      columns={Object.keys(cashbackTableData[0])}
                      rows={cashbackTableData}
                    />
                  </Grid>
                  {/* } */}

                  <Grid item xs={6}>
                    <div className="my-1 mx-5">
                      {" "}
                      <span className="text-muted">
                        <strong> Refund Data</strong>
                      </span>
                    </div>
                    <MaterialTables
                      columns={Object.keys(refundTableData[0])}
                      rows={refundTableData}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className="my-1 mx-5">
                      {" "}
                      <span className="text-muted">
                        <strong>Order Placed By Account</strong>
                      </span>
                    </div>
                    <MaterialTables
                      columns={Object.keys(orderPlacedByAccount[0])}
                      rows={orderPlacedByAccount}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        }
      </Card>
      {editeDialog && (
        <EditOrder
          setEditeDialog={setEditeDialog}
          editeDialog={editeDialog}
          data={data}
          UpdateData={UpdateData}
        />
      )}
      <EditStatus
        setStatusDialog={setStatusDialog}
        statusDialog={statusDialog}
        data={data}
        setOrderStatus={setOrderStatus}
        setPrepaymentData={setPrepaymentData}
        setCount={setCount}
        count={count}
        setPrePaymentSelected={setPrePaymentSelected}
      />
      {remarkDialog && (
        <Dialog fullWidth={true} open={remarkDialog} onClose={handleClose}>
          <DialogTitle>Remark</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              // id="name"
              name="remark"
              label="remark"
              type="text"
              fullWidth
              variant="outlined"
              onChange={(e) => {
                // setStausRemark(e.target.value)
                setStausRemark({ remark: e.target.value });
              }}
              multiline
              minRows={4}
              // onBlur={handleBlur}
              // value={value}
            />
            {remarkError ? <p className="text-danger">Required !</p> : null}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setAddRemark(false);
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              // type="submit"
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
              onClick={UpdateRemark}
            >
              {" "}
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {cancelDialog && (
        <Dialog fullWidth={true} open={cancelDialog} onClose={handleClose}>
          <DialogTitle>Cancel Remark</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              value={cancelRemark}
              name="cancelRemark"
              label="cancel remark"
              type="text"
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setCancelRemark(e.target.value);
              }}
              // onBlur={handleBlur}
              // value={value}
              multiline
              minRows={4}
            />
            {cancelRemarkError ? (
              <p className="text-danger">Required !</p>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              // type="submit"
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
              onClick={handleCancelRemark}
            >
              {" "}
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {cashbackDialog && (
        <CashbackForm
          orderId={data._id}
          setCashbackDialog={setCashbackDialog}
          cashbackDialog={cashbackDialog}
          UpdateCashback={updateRefundCashback}
        />
      )}
      {refundDialog && (
        <RefundForm
          orderId={data._id}
          setRefundDialog={setRefundDialog}
          refundDialog={refundDialog}
          UpdateRefund={updateRefundCashback}
        />
      )}

      {setOrderCancelInfo && (
        <Toster info={setOrderCancelInfo} dialogclose={dialogclose} />
      )}
      {setOrderModeInfo && (
        <Toster info={setOrderModeInfo} dialogclose={dialogclose} />
      )}
      {setOrderStatusInfo && (
        <Toster info={setOrderStatusInfo} dialogclose={dialogclose} />
      )}
      {setSendInvoiceInfo && (
        <Toster info={setSendInvoiceInfo} dialogclose={dialogclose} />
      )}
      {setOrderRemarkInfo && (
        <Toster info={setOrderRemarkInfo} dialogclose={dialogclose} />
      )}
      {setUpdateRefundCashbackInfo && (
        <Toster info={setUpdateRefundCashbackInfo} dialogclose={dialogclose} />
      )}
      {setUpdateOrderRequestFlageInfo && (
        <Toster
          info={setUpdateOrderRequestFlageInfo}
          dialogclose={dialogclose}
        />
      )}
    </div>
  );
}

function CashbackForm({
  setCashbackDialog,
  cashbackDialog,
  UpdateCashback,
  orderId,
}) {
  let now = new Date();
  const formik = useFormik({
    initialValues: {
      refund_or_cashback: "CASHBACK",
      amount: "",
      payment_date: moment(now).format("YYYY-MM-DD"),
      remark: "",
    },
    validationSchema: yup.object({
      amount: yup.number().required("Only Number !"),
      payment_date: yup.date().required("Required !"),
      remark: yup.string().required("Required !"),
    }),
    onSubmit: (values) => {
      UpdateCashback({ body: values, id: orderId });
      setCashbackDialog(false);
    },
  });

  return (
    <Dialog
      fullWidth={true}
      open={cashbackDialog}
      onClose={() => {
        setCashbackDialog(false);
      }}
    >
      <DialogTitle>Initiate Refund</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <TextField
            margin="dense"
            // id="name"
            name="amount"
            label="amount"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.amount}
          />
          {formik.touched.amount && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.amount}
            </span>
          )}
          <br />
          <br />

          <TextField
            margin="dense"
            // id="name"
            name="payment_date"
            label="payment date"
            type="date"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.payment_date}
          />
          {formik.touched.payment_date && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.payment_date}
            </span>
          )}
          <br />
          <br />

          <TextField
            margin="dense"
            // id="name"
            name="remark"
            label="remark"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.remark}
            multiline
            minRows={3}
          />
          {formik.touched.remark && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.remark}
            </span>
          )}
          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCashbackDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            style={{ marginLeft: 20 }}
            variant="contained"
            color="primary"
          >
            {" "}
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function RefundForm({ setRefundDialog, refundDialog, UpdateRefund, orderId }) {
  let now = new Date();
  const formik = useFormik({
    initialValues: {
      refund_or_cashback: "REFUND",
      amount: "",
      payment_date: moment(now).format("YYYY-MM-DD"),
      remark: "",
    },
    validationSchema: yup.object({
      amount: yup.number().required("Only Number !"),
      payment_date: yup.date().required("Required !"),
      remark: yup.string().required("Required !"),
    }),
    onSubmit: (values) => {
      UpdateRefund({ body: values, id: orderId });
      setRefundDialog(false);
    },
  });

  return (
    <Dialog
      fullWidth={true}
      open={refundDialog}
      onClose={() => {
        setRefundDialog(false);
      }}
    >
      <DialogTitle>Initiate Refund</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <TextField
            margin="dense"
            // id="name"
            name="amount"
            label="amount"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.amount}
          />
          {formik.touched.amount && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.amount}
            </span>
          )}
          <br />
          <br />

          <TextField
            margin="dense"
            // id="name"
            name="payment_date"
            label="payment date"
            type="date"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.payment_date}
          />
          {formik.touched.payment_date && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.payment_date}
            </span>
          )}
          <br />
          <br />

          <TextField
            margin="dense"
            // id="name"
            name="remark"
            label="remark"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.remark}
            multiline
            minRows={3}
          />
          {formik.touched.remark && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.remark}
            </span>
          )}
          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setRefundDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            style={{ marginLeft: 20 }}
            variant="contained"
            color="primary"
          >
            {" "}
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
