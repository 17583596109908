import React, { useState, useEffect } from "react";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import * as yup from 'yup'
import { useFormik } from 'formik';
import {
  Grid,
  Button,
  Card,
  Box,
  CardContent,
  Dialog, Zoom, DialogTitle, DialogContent, TextField,
  FormControl, InputLabel, Select, MenuItem
} from "@material-ui/core";
import {
  useUpdateFacultyMutation,
  useGetSingleFacultyMutation,
  useGetallInstituteNamesQuery
} from '../../../../services/services'
import DataNotFound from "../DataNotFound";


export default function Institute({ viewDialog, data, setFacultyData }) {

  const [updateData, updateDataInfo] = useUpdateFacultyMutation();
  const [singleFacultyData, singleFacultyDataInfo] = useGetSingleFacultyMutation();
  const [updateDialog, setUpdateDialog] = useState(false);

  useEffect(() => {
    singleFacultyData(data._id)
  }, [updateDataInfo])

  const dialogclose = () => {
    setUpdateDialog(false);
  };

  return (
    <>
      <Card sx={{ display: "flex" }} className="m-3" >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>

            <Button
              onClick={() => viewDialog(false)}
              className="text-light bg-danger float-right btn btn-sm btn-danger"
            >
              Close
            </Button>
          </CardContent>

          {
            singleFacultyDataInfo.isSuccess == true && singleFacultyDataInfo.data.data.length ?
              singleFacultyDataInfo.isLoading == false ?
                <>
                  {setFacultyData(singleFacultyDataInfo.data.data[0])}
                  <Button
                  style={{width:'10%'}}
                 size="small"
                    onClick={() => {
                      setUpdateDialog(true);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Update Institute
                  </Button>
                  <Card className="m-3 pb-3 d-flex justify-content-start">

                    {
                      singleFacultyDataInfo.data.data[0].institute != undefined ?
                        <CardContent className="bg-light p-3 border-primary infoCards col-12 m-2">

                          <Grid container spacing={9}>
                            <Grid className="text-muted text-left" item md={6}>
                              <h6 className="">Institute Name</h6>

                            </Grid>
                            <Grid className="text-muted text-left" item md={6}>
                              <h6>{singleFacultyDataInfo.data.data[0].institute.institute_name}</h6>

                            </Grid>

                          </Grid>
                          <hr />
                        </CardContent> :
                        <CardContent className="bg-light p-3 border-top border-bottom border-primary infoCards">
                          NO DATA FOUND
                        </CardContent>
                    }



                  </Card>


                  {updateDialog ? <UpdateInstituteDetails
                    updateDialog={updateDialog}
                    setUpdateDialog={setUpdateDialog}
                    updateData={updateData}
                    facultyId={data._id}
                    currentData={{ ...data.institute }} /> : null}
                  {updateDataInfo.data && (
                    <Toster info={updateDataInfo} dialogclose={dialogclose} />
                  )}
                </>
                :
                <SkeletonListing />

              :
              <Grid container justifyContent="center">
                <DataNotFound />
              </Grid>

          }

        </Box>
      </Card>
    </>

  );
}

function UpdateInstituteDetails({ updateDialog, setUpdateDialog, updateData, facultyId, currentData }) {
  const { data, isSuccess } = useGetallInstituteNamesQuery();
  const formik = useFormik({
    initialValues: {
      ...currentData
    },
    validationSchema: yup.object({
      institute_id: yup.string().required("Required!")
    }),
    onSubmit: (values, e) => {
      var form_data = new FormData()
      form_data.append('key', 'institute')
      form_data.append('update_data', JSON.stringify(values))
      updateData({ data: form_data, id: facultyId })
    }
  })

  return (
    <Dialog
      fullScreen={false}
      open={updateDialog}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      TransitionComponent={Zoom}
    >
      <DialogTitle id="scroll-dialog-title">Update Institute</DialogTitle>
      <DialogContent
      >
        <form id="updateForm" onSubmit={formik.handleSubmit}>
          <Grid item  style={{ paddingLeft: 10, paddingRight: 10, marginTop: 20, marginLeft: 10 }} >

            <Grid item lg={12}>
              <FormControl
                style={{ margin: 0 }}
                fullWidth={true}
                variant="outlined"
                className={null}>

                <InputLabel
                  htmlFor="outlined-institute-Name-native-simple">
                  Institute Name
                </InputLabel>

                <Select
                  onChange={formik.handleChange}
                  id="newSkill"
                  onBlur={formik.handleBlur}
                  value={formik.values.institute_id}
                  label="Institute Name"
                  name="institute_id">
                  {isSuccess == true ? data.data.map(instituteData => {
                    return <MenuItem value={instituteData._id}>{instituteData.name}</MenuItem>
                  }) : <MenuItem value="">Select</MenuItem>}
                </Select>
              </FormControl>
              {formik.touched.institute_id && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.institute_id}</span>}
            </Grid>
            <br />
            <br />
            <Grid container spacing={3} >
              <Grid item xs={12}>
                <div className="text-right">
                  <Button
                    onClick={() => setUpdateDialog(false)}
                    variant="contained"
                    color="secondary">
                    Close</Button>
                  <Button
                    type="submit"
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    color="primary">
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid >
        </form >
      </DialogContent>
    </Dialog>
  )

}
