import React, { useState, useEffect } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import Loader from "../Loader";
import AddDataHeader from "../AddDataHeader";
import DataNotFound from "../DataNotFound";
import {
  AccordionDetails,
  AccordionSummary,
  Typography,
  Accordion,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListIcon from "@material-ui/icons/FilterList";
import Filters from "../Filters";
import FormDialog from "../reusableComponents/NewFormDialog";

import {
  useGetAllExpenseDetailsMutation,
  useAddExpenseDetailsMutation,
  useUpdateExpenseDetailsMutation,
  useDeleteExpenseDetailsMutation,
  useGetSingleExpenseDetailsQuery,
} from "../../../../services/services";
import AddExpenseForm from "./AddExpenseForm";
import moment from "moment";

export default function ExpenseStatement() {
  const [getAllExpenses, getAllExpensesInfo] =
    useGetAllExpenseDetailsMutation();

  const [deleteExpense, deleteExpenseInfo] = useDeleteExpenseDetailsMutation();

  const [filteredPagination, setFilteredPagination] = React.useState([]);

  const [updateExpenseInfo, setUpdateExpenseInfo] = useState("")
  const [addExpenseInfo, setAddExpenseInfo] = useState("")

    

  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "recordedAt",
      label: "Recorded At",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "recordedBy",
      label: "Recorded By",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "expensedAt",
      label: "Expense Date",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "expenseCategory",
      label: "Expense Category",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "expenseName",
      label: "Expense Name",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "description",
      label: "Description",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "paymentMode",
      label: "Payment Mode",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "amount",
      label: "Amount (₹)",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "expensedBy",
      label: "Expensed By (Role)",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "expensedOn",
      label: "Expensed On (Role)",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];
  function createData(
    SNo,
    recordedAt,
    recordedBy,
    expensedAt,
    expenseCategory,
    expenseName,
    description,
    paymentMode,
    amount,
    expensedBy,
    expensedOn,
    actions
  ) {
    return {
      SNo,
      recordedAt,
      recordedBy,
      expensedAt,
      expenseCategory,
      expenseName,
      description,
      paymentMode,
      amount,
      expensedBy,
      expensedOn,
      actions,
    };
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [render, setRender] = useState(false);
  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: ["expensed_on_name", "expensed_by_name", "category_name", "title", "created_by_name"],
    filterBy: filteredPagination,
    page: page + 1,
  };

  let rows = [];
  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [addDialog, setAddDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const allExpenses = getAllExpensesInfo.data;
    

  const totalItem = allExpenses ? allExpenses.totalItem : 0;

  useEffect(() => {
      
    getAllExpenses(paginationData);
  }, [render, rowsPerPage, page, filteredPagination,]);

  useEffect(() => {
    getid && deleteExpense(getid) && setGetid("");
  }, [getid]);

  let totalData = "ok";

  if (allExpenses) {
    totalData = allExpenses?.totalItem;
    rows = allExpenses?.data?.map((ele, index) =>
      createData(
        index + 1,
        moment(ele.created_on_date).format("DD-MM-YYYY hh:mm:ss A"),
        <div className="d-flex">
          {" "}
          <h6 className="text-primary">
            {" "}
            {ele.created_by_name}
          </h6>
          <span className="text-success ml-2">
            {" "}
            ({ele.created_by_type}){" "}
          </span>{" "}
        </div>,
        moment(ele.expensed_date).format("DD-MM-YYYY hh:mm:ss A") ,
        ele.category_name,
        ele.title,
        ele.note,
        ele.payment_mode,
        <h6 className="text-danger"> {ele.expensed_amount} </h6>,
        <div className="d-flex">
          {" "}
          <h6 className="text-primary">
            {" "}
            {ele.expensed_by_name}
          </h6>
          <span className="text-success ml-2">
            {" "}
            ({ele.expensed_by_type}){" "}
          </span>{" "}
        </div>,
        <div className="d-flex">
          {" "}
          <h6 className="text-primary">
            {ele.expensed_on_name}{" "}
          </h6>
          <span className="text-success ml-2">
            {" "}
            ({ele.expensed_on_type}){" "}
          </span>{" "}
        </div>,
        <div className="d-flex">
          <EditDelete
            setEditDialog={setEditDialog}
            type="edit"
            setData={setData}
            setGetid={setGetid}
            data={ele}
          />
          <EditDelete type="delete" setGetid={setGetid} id={ele._id} />
        </div>
      )
    );
  }

  // );
  //   }
  const dialogclose = () => {
    setEditDialog(false);
    setViewDialog(false);
    setAddDialog(false);
    setRender(!render);
  };
  return (
    <div>
      {!viewDialog && (
        <AddDataHeader
          addFormName={"Add Expense"}
          setPage={setPage}
          page={page}
          excelBtn={false}
          totalItem={totalItem}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setAddDialog={setAddDialog}
          searchBarApi={getAllExpenses}
          paginationData={paginationData}
          isButton={true}
          Adddialog={addDialog}
          form={
            <AddExpenseForm
              setUpdateExpenseInfo={setUpdateExpenseInfo}
              setAddExpenseInfo={setAddExpenseInfo}
              setAddDialog={setAddDialog}
              dialogclose={dialogclose}
            />
          }
        />
      )}

      <Accordion className="">
        <AccordionSummary
          className="text-primary  "
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="d-flex align-items-center ">
            <FilterListIcon /> <span> Filter</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className=" d-flex justify-content-between">
          <Filters
            setFilteredPagination={setFilteredPagination}
            // isSubFilter={true}
            // isFacFilter={true}
            // isSyllabusFilter={false}
            isFromFilter={true}
            isToFilter={true}
          // isBookName={true}
          />
        </AccordionDetails>
      </Accordion>

      {/* {deleteBookStockInfo.isLoading || updateBookInfo.isLoading ? ( */}
      {/* <Loader /> */}
      {/* ) : ( */}
      <>
        {/* {!viewDialog ? ( */}
        {rows?.length ? (
          <CreateTableAddButton
            editDialog={editDialog}
            setPage={setPage}
            rows={rows}
            editform={
              <AddExpenseForm
                formType="edit"
                setUpdateExpenseInfo={setUpdateExpenseInfo}
                setAddExpenseInfo={setAddExpenseInfo}
                dialogclose={dialogclose}
                setEditDialog={setEditDialog}
                data={data}
              />
            }
            columns={columns}
            isButton={true}
            editFormName={"Edit Expense"}
          />
        ) : totalData == 0 ? (
          <DataNotFound />
        ) : (
          <SkeletonListing />
        )}
      </>

      {deleteExpenseInfo.data && (
        <Toster info={deleteExpenseInfo} dialogclose={dialogclose} />
      )}
      {addExpenseInfo.data && (
        <Toster info={addExpenseInfo} dialogclose={dialogclose} />
      )}

      {updateExpenseInfo.data && (
        <Toster info={updateExpenseInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
