import React, { useState, useEffect } from "react";
import CreateTableAddButton from "../CreateTableAddButton";
import SkeletonListing from "../SkeletonListing";
import AddDataHeader from "../AddDataHeader";
import DataNotFound from "../DataNotFound";
import {
  useGetUserWalletQuery,
  useGetAllUsersWalletTransactionsQuery,
} from "../../../../services/services";
import { IconButton } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import moment from "moment";
import AuthWrapper from "../../../../utils/AuthWrapper";

export default function AllWalletTransactions() {
  const [paginationData, setpaginationData] = useState({
    orderBy: "",
    limit: "",
    page: "",
  });

  const { data: allUsersWalletTransactions } =
    useGetAllUsersWalletTransactionsQuery(paginationData);

  useEffect(() => {
    setpaginationData();
  }, []);

  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "userName",
      label: "User Name",
      minWidth: 150,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "email",
      label: "Email",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "transactionDate",
      label: "Transaction Date",
      minWidth: 120,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "remark",
      label: "Remark",
      minWidth: 120,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },

    {
      id: "previousAmount",
      label: "Previous Amount",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "usedAmount",
      label: "Used / Added Amount",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "balance",
      label: "Balance Amount",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];

  function createData(
    SNo,
    userName,
    email,
    transactionDate,
    remark,
    expiryDate,
    previousAmount,
    usedAmount,
    balance
  ) {
    return {
      SNo,
      userName,
      email,
      transactionDate,
      remark,
      previousAmount,
      usedAmount,
      balance,
    };
  }

  let rows = [];
  const totalItem = allUsersWalletTransactions
    ? allUsersWalletTransactions.totalItem
    : 0;

  let totalData = "ok";

  if (allUsersWalletTransactions) {
    totalData = allUsersWalletTransactions.totalItem;
    rows = allUsersWalletTransactions.data.map((ele, index) => {
      return createData(
        index + 1,
        ele.user_first_name + " " + ele.user_last_name,
        ele.user_email,
        moment(ele.transaction_date).format("DD-MM-YYYY hh:mm:ss A"),
        ele.remark,
        <span className="text-primary"> {ele.previous_amount} </span>,

        ele.used_amount == 0 ? (
          <span className="text-success">
            {" "}
            {ele.added_amount}{" "}
            <IconButton>
              {" "}
              <ArrowUpwardIcon fontSize="small" className="text-success" />{" "}
            </IconButton>{" "}
          </span>
        ) : (
          <span className="text-danger">
            {" "}
            {ele.used_amount}{" "}
            <IconButton>
              {" "}
              <ArrowDownwardIcon
                fontSize="small"
                className="text-danger"
              />{" "}
            </IconButton>{" "}
          </span>
        ),

        <span className="text-info"> {ele.remaining_amount} </span>
      );
    });
  }

  return (
    <div>
      {/* {!viewDialog && ( */}
      <AddDataHeader
        addBtn={true}
        isSearch={true}
        totalItem={totalItem}
        isPagination={true}
        isButton={true}
      />
      {/* )} */}

      <>
        <AuthWrapper type="ACTION" name="WALLET_TRANSACTION_LIST">
          {rows.length ? (
            <CreateTableAddButton
              // setPage={setPage}
              rows={rows}
              columns={columns}
              isButton={true}
            />
          ) : totalData == 0 ? (
            <DataNotFound />
          ) : (
            <SkeletonListing />
          )}
        </AuthWrapper>
      </>
    </div>
  );
}
