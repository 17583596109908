import React, { useState, useEffect } from "react";
import EditDelete from "./EditDelete";
import CreateTableAddButton from "./CreateTableAddButton";
import { Avatar } from "@material-ui/core";
import EditCourier from "./EditCourier";
import Loader from "./Loader";
import AddDataHeader from "./AddDataHeader";
import {
  useGetAllCourierDetailsMutation,
  useAddCourieMutation,
  useUpdateCourierMutation,
  useDeleteCourierMutation,
} from "../../../services/services";
import Toster from "./Toster";
import AddCourieDetails from "./AddCourieDetails";
import ViewCourier from "./ViewCourier";
import SkeletonListing from "./SkeletonListing";
import DataNotFound from "./DataNotFound";
import moment from "moment";
import AuthWrapper from "../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../utils/getFilterFunction";

export default function CourierDetails() {
  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "order",
      label: "order",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "tracking_id",
      label: "tracking id",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "courier_details",
      label: "courier details",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "status",
      label: "status",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    // {
    //     id: 'product_type',
    //     label: 'product type',
    //     minWidth: 50,
    //     align: 'center',
    //     format: (value) => value.toLocaleString('en-US'),
    // },
    {
      id: "tracking_support_contact",
      label: "tracking support contact",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "courier_amount_paid",
      label: "courier amount paid",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "date",
      label: "Date",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },

    {
      id: "Actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];
  function createData(
    SNo,
    order,
    tracking_id,
    courier_details,
    status,
    product_type,
    tracking_support_contact,
    courier_amount_paid,
    date,
    Actions
  ) {
    return {
      SNo,
      order,
      tracking_id,
      courier_details,
      status,
      product_type,
      tracking_support_contact,
      courier_amount_paid,
      date,
      Actions,
    };
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const paginationData = {
    limit: "",
    query: "",
    params: ["tracking_id", "courier_date", "sld_order_id"],
    page: "1",
  };

  let rows = [];
  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [AddDialog, setDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [getAllData, getAllDataInfo] = useGetAllCourierDetailsMutation();
  const [deleteCourier, deleteCourierInfo] = useDeleteCourierMutation();
  const [addData, addDataInfo] = useAddCourieMutation();
  const [updateData, updateDataInfo] = useUpdateCourierMutation();
  const AallCourierDetails = getAllDataInfo.data;
  const totalItem = AallCourierDetails ? AallCourierDetails.totalItem : 0;
  const [render, setRender] = useState(false);

  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page]);

  useEffect(() => {
    getid && deleteCourier(getid) && setGetid("");
  }, [getid]);

  const AddData = (data) => {
    addData(data);
  };
  const UpdateData = (data, id) => {
    updateData({
      data,
      id: id,
    });
  };
  let totalData = "ok";
  if (AallCourierDetails) {
    totalData = AallCourierDetails.totalItem;
    rows = AallCourierDetails.data.map((data, index) =>
      createData(
        index + 1,
        <div className="">
          <span className="text-info font-weight-bold">
            {data.sld_order_id}
          </span>
          <br />
          <span className="text-muted font-weight-bold">
            {data.order_details[0].product_type.replaceAll("_", " ")} ({" "}
            {data.order_details[0].payment_status} )
          </span>
          <br />
          <span className="text-muted">
            ({data.order_details[0].order_title})
          </span>
        </div>,
        data.tracking_id,
        <div className="">
          <span className="text-info font-weight-bold">
            {data.courier_company}
          </span>
          <br />
          <span className="text-muted font-weight-bold">
            ({data.courier_weight} KG)
          </span>
          <br />
        </div>,
        <div className="">
          <span className="text-info font-weight-bold">
            {data.current_status}
          </span>
          <br />
        </div>,
        data.product_type,
        <div className="">
          <span className="text-info font-weight-bold">
            {data.tracking_support_number}
          </span>
          <br />
          <span className="text-muted font-weight-bold">
            {data.tracking_email}
          </span>
          <br />
        </div>,
        "₹ " + data.courier_amount_paid,
        data.courier_date
          ? moment(data.courier_date).format("DD-MM-YYYY hh:mm:ss A")
          : "",
        <div className="d-flex">
          <AuthWrapper
            type="ACTION"
            name="SUCCESSFULL_ORDERS_TRACKING_DETAILS_VIEW"
          >
            <EditDelete
              setViewDialog={setViewDialog}
              type="view"
              setData={setData}
              setGetid={setGetid}
              data={data}
            />
          </AuthWrapper>
          <AuthWrapper
            type="ACTION"
            name="SUCCESSFULL_ORDERS_TRACKING_DETAILS_EDIT"
          >
            <EditDelete
              setEditDialog={setEditDialog}
              type="edit"
              setData={setData}
              setGetid={setGetid}
              data={data}
            />
          </AuthWrapper>
          <AuthWrapper
            type="ACTION"
            name="SUCCESSFULL_ORDERS_TRACKING_DETAILS_DELETE"
          >
            <EditDelete type="delete" setGetid={setGetid} id={data._id} />
          </AuthWrapper>
        </div>
      )
    );
  }
  const dialogclose = () => {
    setEditDialog(false);
    setViewDialog(false);

    setDialog(false);
    setRender(!render);
  };
  return (
    <div>
      {!viewDialog && (
        <AddDataHeader
          addFormName={"Add Courier Details"}
          setPage={setPage}
          page={page}
          excelBtn={false}
          isSearch={
            !AuthWrapper({
              type: "ACTION",
              name: "SUCCESSFULL_ORDERS_TRACKING_DETAILS_LIST_COURIER",
              inBoolean: true,
            })
          }
          addBtn={
            !AuthWrapper({
              type: "ACTION",
              name: "SUCCESSFULL_ORDERS_TRACKING_DETAILS_ADD_COURIER",
              inBoolean: true,
            })
          }
          isPagination={
            !AuthWrapper({
              type: "ACTION",
              name: "SUCCESSFULL_ORDERS_TRACKING_DETAILS_LIST_COURIER",
              inBoolean: true,
            })
          }
          totalItem={totalItem}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setAddDialog={setDialog}
          searchBarApi={getAllData}
          paginationData={paginationData}
          isButton={true}
          Adddialog={AddDialog}
          form={<AddCourieDetails AddData={AddData} setDialog={setDialog} />}
        />
      )}

      <AuthWrapper type="ACTION" name="SUCCESSFULL_ORDERS_TRACKING_DETAILS_LIST_COURIER">
        {deleteCourierInfo.isLoading ||
        updateDataInfo.isLoading ||
        addDataInfo.isLoading ? (
          <Loader />
        ) : (
          <>
            {!viewDialog ? (
              rows.length ? (
                <CreateTableAddButton
                  editDialog={editDialog}
                  rows={rows}
                  editform={
                    <EditCourier
                      UpdateData={UpdateData}
                      editCourier={data}
                      editDialog={setEditDialog}
                    />
                  }
                  columns={getFilterdColumns(columns)}
                  isButton={true}
                  editFormName={"Change Courier Status"}
                />
              ) : totalData == 0 ? (
                <DataNotFound />
              ) : (
                <SkeletonListing />
              )
            ) : (
              <ViewCourier viewDialog={setViewDialog} data={data} />
            )}
          </>
        )}
      </AuthWrapper>
      {addDataInfo.data && (
        <Toster info={addDataInfo} dialogclose={dialogclose} />
      )}
      {deleteCourierInfo.data && (
        <Toster info={deleteCourierInfo} dialogclose={dialogclose} />
      )}
      {updateDataInfo && (
        <Toster info={updateDataInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
