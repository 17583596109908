import React, { Component, useState } from 'react';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

export default function EditorConvertToHTML({ keyName, value, setFieldValue }) {
    let prefilledData
    if (value) {
        const contentBlock = convertFromHTML(value);
        //const contentBlock = htmlToDraft(value);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        prefilledData = EditorState.createWithContent(contentState);
    }
    const [editorState, setEditorState] = useState(value ? prefilledData : EditorState.createEmpty())
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        let htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        setFieldValue(keyName, htmlContent)
    };

    return (
        <div className='border border-secondary p-2 rounded' >
            <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
            />
        </div>
    );

}