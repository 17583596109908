import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, TextField, TextareaAutosize, Typography, Checkbox, Select, FormControl, InputLabel } from '@material-ui/core';
import { Grid, Paper } from '@material-ui/core';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Autocomplete from '@mui/material/Autocomplete';
import { CardContent } from '@material-ui/core';
import { useGetSMSTempletListQuery , useSendSMSMutation} from '../../../services/smsServices';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
    useGetAllMobileNoListQuery,
} from "../../../services/mobileNoLibraryServices";
import Toster from './Toster';
import { toast } from 'react-toastify';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function AddCustomSMS({ title, setTitle }) {

    const [sendSMS , sendSMSInfo] = useSendSMSMutation() 


    const { data: allMobileNos } = useGetAllMobileNoListQuery();

    let mobileNoOptions=[{ name: "", mobile: "" }]
    if (allMobileNos) {
        mobileNoOptions = allMobileNos.data.map((ele) => {
            return { name: ele.name, mobile: ele.mobile }
        })
    }



    const formik = useFormik({
        initialValues: {
            title: title.title,
            templet_id: title.templet_id,
            msg_body: title.msg_body,
            mobile_no: [],
        },
        validationSchema: yup.object({
            title: yup.string().required("Required"),
            msg_body: yup.string().required("Required"),
            mobile_no: yup.array().min(1,"Please select any mobile number.").required("Required")
        }),
        onSubmit: (values) => {
            if(values.msg_body.includes("{#") || values.msg_body.includes("#}")){
                toast("Please replace variables with their respective values (eg {#mobile#} -> 9926087962)")
                return
            }
            let mobile_no = values.mobile_no.map((e,i)=>{return e.mobile})
            values = {...values ,  mobile_no : mobile_no.join(",") }
            sendSMS(values)    
        },
    });

    useEffect(()=>{
        formik.setFieldValue("title",title.title)
        formik.setFieldValue("templet_id",title.templet_id)
        formik.setFieldValue("msg_body",title.msg_body)
    },[title])

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
            <Grid container className=''>
                
                     <Grid item xs={12} className=" my-2" >
                       <TextField
                            label="Templet Id"
                            id='templet_id'
                            name='templet_id'
                            type='text'
                            value={formik.values.templet_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant='outlined'
                            inputProps={
                                { readOnly: true, }
                            }
                            fullWidth
                        />
                        {formik.touched.templet_id && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                                {formik.errors.templet_id}
                            </span>)}
                    </Grid> 
                        
                     <Grid item xs={12} className=" my-2" >
                        <TextField
                            label="Message Body"
                            id='msg_body'
                            placeholder="Enter Message Body Here ..."
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.msg_body}
                            fullWidth
                            variant='outlined'
                            multiline
                            minRows={3}
                        />
                        {formik.touched.msg_body && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                                {formik.errors.msg_body}
                            </span>)}
                    </Grid>   
                    <Grid item xs={12} className=" my-2" >
                       
                        {
                            mobileNoOptions && 
                            <Autocomplete
                                multiple
                                id="mobile_no"
                                onChange={(e,newValue)=>{
                                     formik.setFieldValue("mobile_no",newValue)
                                }}
                                value={formik.values.mobile_no}
                                name="mobile_no"
                                options={mobileNoOptions}
                                isOptionEqualToValue={(option, value) => option.mobile === value.mobile}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name+" ( "+option.mobile+" ) "}
                                renderOption={(props, option, { selected }) => (
                                    <li key={option.mobile} {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                        key={option.mobile}
                                    />
                                    {option.name} ( {option.mobile} )
                                    </li>
                                )}
                                style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Mobile No." placeholder="Select Mobile No." />
                                )}
                            />
                        }
                        {formik.touched.mobile_no && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                            {formik.errors.mobile_no}
                        </span>)}

                    </Grid> 

                    <Grid xs={12} className=" my-2">
                        <Button variant='contained' color='primary' type='submit'>Send SMS</Button>
                        <Button className='ml-2' variant='contained' onClick={()=>{setTitle({ title: "", templet_id: "", msg_body: "" })}} color='secondary' >Reset</Button>
                    </Grid>
            </Grid>
            </form>
            {sendSMSInfo && <Toster info={sendSMSInfo} />}
        </div>
    )
}
