import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "./CreateTabPanel";
import Subject from "./Subject";
import Levels from "./Levels";
import Courses from "./Courses";
import Categories from "./Categories";
import { userAccess } from "../../../helpers/userAccess";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function CourseandSubjects() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          {userAccess("category") ? (
            <Tab
              style={{ textTransform: "capitalize" }}
              label="Categories"
              {...a11yProps(0)}
            />
          ) : null}
          {userAccess("course") ? (
            <Tab
              style={{ textTransform: "capitalize" }}
              label="Course"
              {...a11yProps(1)}
            />
          ) : null}
          {userAccess("level") ? (
            <Tab
              style={{ textTransform: "capitalize" }}
              label="Levels"
              {...a11yProps(2)}
            />
          ) : null}
          {userAccess("subject") ? (
            <Tab
              style={{ textTransform: "capitalize" }}
              label="Subjects"
              {...a11yProps(3)}
            />
          ) : null}
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <Categories />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Courses />
      </TabPanel>
      <TabPanel style={{ paddingBottom: 50 }} value={value} index={2}>
        <Levels />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Subject />
      </TabPanel>
    </div>
  );
}

// import React, { useState, useEffect } from "react";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import {
//   Box,
//   Button,
//   Card,
//   Grid,
//   IconButton,
//   TextField,
//   Typography,
//   Snackbar,
//   Slide,
//   Chip,
//   makeStyles,
//   withStyles,
//   Grow, Fade,

// } from "@material-ui/core";
// import DeleteIcon from '@material-ui/icons/Delete';
// import EditIcon from '@material-ui/icons/Edit';
// import CheckIcon from '@material-ui/icons/Check';
// import EditDelete from "./EditDelete";
// import TableCell from "@material-ui/core/TableCell";
// import TableRow from "@material-ui/core/TableRow";
// import {
//   useGetAllCoursesQuery,
//   useAddCourseMutation,
//   useDeleteCourseMutation,
//   useUpdateCourseMutation,

//   useGetAllCourseLevelQuery,
//   useAddCourseLevelMutation,
//   useDeleteCourseLevelMutation,
//   useUpdateCourseLevelMutation,

//   useGetAllSubjectsQuery,
//   useAddSubjectMutation,
//   useDeleteSubjectMutation,
//   useUpdateSubjectMutation,
// } from "../../../services/services";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: "3%",
//   },
//   mr: {
//     marginLeft: "2%",
//     marginTop: "5%",
//     marginRight: "2%",
//   },
//   course: {
//     fontSize: 18,
//   },
// }));

// const StyledTableRow = withStyles((theme) => ({
//   root: {
//     "&:nth-of-type(odd)": {
//       backgroundColor: theme.palette.action.hover,
//       innerWidth: "100%",
//       outerWidth: "100%",
//     },
//   },
// }))(TableRow);

// const StyledTableCell = withStyles((theme) => ({
//   head: {
//     backgroundColor: theme.palette.common.black,
//     color: theme.palette.common.white,
//     fontSize: 18,
//     fontWeight: "bold",
//   },
//   body: {
//     fontSize: 17,
//   },
// }))(TableCell);
// function SlideTransition(props) {
//   return <Slide {...props} direction="up" />;
// }

// function GrowTransition(props) {
//   return <Grow {...props} />;
// }
// export default function CourseAndSubjects() {
//   const classes = useStyles();

//   const [state, setState] = React.useState({
//     open: false,
//     Transition: Fade,
//   });
//   const handleClick = (Transition) => () => {
//     setState({
//       open: true,
//       Transition,
//     });
//   };
//   const handleClose = () => {
//     setState({
//       ...state,
//       open: false,
//     });
//   };

//   const [addCourseLevel, addCourseLevelInfo] = useAddCourseLevelMutation()
//   const [deletCouseLevel, deleteCourseLevelInfo] = useDeleteCourseLevelMutation()
//   const [updateCourseLevelName, updateCourseLevelInfo] = useUpdateCourseLevelMutation()
//   const [courseId, setCourseId] = useState("")
//   const getcourseLevel = useGetAllCourseLevelQuery(courseId)
//   const { data, isSuccess } = useGetAllCoursesQuery()
//   const [deletCouse, deletCouseInfo] = useDeleteCourseMutation()
//   const [addCourse, addCourseInfo] = useAddCourseMutation()
//   const [updateCourseName, updateCourseInfo] = useUpdateCourseMutation()
//   const [allSubjectsData, setAllSubjectData] = useState()
//   const getAllSubjects = useGetAllSubjectsQuery(allSubjectsData)
//   const allSubjects = getAllSubjects.data
//   const [addSubject, addSubjectData] = useAddSubjectMutation()
//   const [deleteSubject, deleteSubjectInfo] = useDeleteSubjectMutation()
//   const [updateSubject, updateSubjectInfo] = useUpdateSubjectMutation()
//   const [subjectName, setSubjectName] = useState("")
//   const [checked, setChecked] = useState(false);
//   const [checked1, setChecked1] = useState(false);
//   const [showCourseAddButton, setShowCourseAddButton] = useState(false);
//   const [showLevelsAddButton, setShowLevelsAddButton] = useState(false);
//   const [showSubjectsAddButton, setShowSubjectsAddButton] = useState(false);
//   const [courseName, setCourseName] = useState("");
//   const [courselevelName, setCourselevelName] = useState("")
//   const [courseLevelId, setCourseLevelId] = useState("")
//   const [courseActions, setCourseActions] = useState([])
//   const [courseLevelAction, setCourseLevelActions] = useState([])
//   const [subjectAction, setSubjectActions] = useState([])
//   const [editedCourse, setEditedCourse] = useState({ name: "", id: "" })
//   const [editedLevel, setEditedLevel] = useState({ name: "", id: "" })
//   const [editedSubject, setEditedSubject] = useState({ name: "", id: "" })
//   const [editedCourseLevel, setEditedCourseLevel] = useState({ name: "", id: "" })
//   const [render, setRender] = useState(false)
//   const [subjectCode, setSubjectCode] = useState("")

//   useEffect(() => {
//     let { status } = addSubjectData
//     if (status == 'fulfilled') {
//       let { status, message } = addSubjectData.data
//       if (!status) {
//         notify(message)
//       } else {
//         notify(message)
//       }
//     }
//   }, [addSubjectData])
//   useEffect(() => {
//     let { status } = deleteSubjectInfo
//     if (status == 'fulfilled') {
//       let { status, message } = deleteSubjectInfo.data
//       if (!status) {
//         notify(message)
//       } else {
//         notify(message)
//       }
//     }
//   }, [deleteSubjectInfo])
//   useEffect(() => {
//     let { status } = updateSubjectInfo
//     if (status == 'fulfilled') {
//       let { status, message } = updateSubjectInfo.data
//       if (!status) {
//         notify(message)
//       } else {
//         notify(message)
//       }
//     }
//   }, [updateSubjectInfo])

//   useEffect(() => {
//     let { status } = addCourseInfo
//     if (status == 'fulfilled') {
//       let { status, message } = addCourseInfo.data
//       if (!status) {
//         notify(message)
//       } else {
//         notify(message)
//       }
//     }
//   }, [addCourseInfo])
//   useEffect(() => {
//     let { status } = deletCouseInfo
//     if (status == 'fulfilled') {
//       let { status, message } = deletCouseInfo.data
//       if (!status) {
//         notify(message)
//       } else {
//         notify(message)
//       }
//     }
//   }, [deletCouseInfo])
//   useEffect(() => {
//     let { status } = updateCourseInfo
//     if (status == 'fulfilled') {
//       let { status, message } = updateCourseInfo.data
//       if (!status) {
//         notify(message)
//       } else {
//         notify(message)
//       }
//     }
//   }, [updateCourseInfo])

//   useEffect(() => {
//     let { status } = addCourseLevelInfo
//     if (status == 'fulfilled') {
//       let { status, message } = addCourseLevelInfo.data
//       if (!status) {
//         notify(message)
//       } else {
//         notify(message)
//       }
//     }
//   }, [addCourseLevelInfo])
//   useEffect(() => {
//     let { status } = deleteCourseLevelInfo
//     if (status == 'fulfilled') {
//       let { status, message } = deleteCourseLevelInfo.data
//       if (!status) {
//         notify(message)
//       } else {
//         notify(message)
//       }
//     }
//   }, [deleteCourseLevelInfo])
//   useEffect(() => {
//     let { status } = updateCourseLevelInfo
//     if (status == 'fulfilled') {
//       let { status, message } = updateCourseLevelInfo.data
//       if (!status) {
//         notify(message)
//       } else {
//         notify(message)
//       }
//     }
//   }, [updateCourseLevelInfo])

//   const selectSubHandler = (e) => {
//     setChecked1(true)
//   };
//   const showAddSubject = () => {
//     setShowSubjectsAddButton(!showSubjectsAddButton)
//   }
//   const showAddLevel = () => {
//     setShowLevelsAddButton(!showLevelsAddButton)
//   }
//   const showCourseButton = () => {
//     setShowCourseAddButton(!showCourseAddButton)
//   }
//   const editedCourseAction = (course_id, index) => {
//     if (editedCourse.name) {
//       return false
//     }
//     let tempCourse = [...courseActions]
//     tempCourse[index].is_edited = true
//     setCourseActions(tempCourse)
//     setEditedCourse({ name: data.data[index].name, id: data.data[index]._id })
//   }
//   const editedCourseLevelAction = (course_id, index) => {
//     if (editedLevel.name) {
//       return false
//     }
//     let tempCourseLevel = [...courseLevelAction]
//     tempCourseLevel[index].is_edited = true
//     setCourseLevelActions(tempCourseLevel)
//     setEditedLevel({ name: courseLevel.data[index].level_name, id: courseLevel.data[index]._id })
//     setEditedCourseLevel({ name: courseLevel.data[index].name, id: courseLevel.data[index]._id })
//   }
//   const editedSubjectAction = (course_id, index) => {
//     if (editedSubject.name) {
//       return false
//     }
//     let tempCourseLevel = [...subjectAction]
//     tempCourseLevel[index].is_edited = true
//     setSubjectActions(tempCourseLevel)
//     setEditedSubject({ name: allSubjects.data[index].subject_name, id: allSubjects.data[index].subject_id })
//     // setEditedSubject({ name: allSubjects.data[index].name, id: allSubjects.data[index]._id })
//   }
//   const updateCourse = (course_id, index) => {
//     updateCourseName(editedCourse)
//     let tempCourse = [...courseActions]
//     tempCourse[index].is_edited = false
//     setCourseActions(tempCourse)
//     setEditedCourse({
//       name: "",
//       id: ""
//     })
//   }
//   const courseLevelNameFunc = (course_id, index) => {
//     updateCourseLevelName(editedCourseLevel)
//     let tempCourseName = [...courseLevelAction]
//     tempCourseName[index].is_edited = false
//     setCourseLevelActions(tempCourseName)
//     setEditedLevel({
//       name: "",
//       id: ""
//     })
//     setEditedCourseLevel({
//       name: "",
//     })
//   }

//   const courseLevel = getcourseLevel.data;
//   var getallcourses = data

//   useEffect(() => {
//     if (data != undefined) {
//       let temp = []
//       data.data.map((ele, ind) => {
//         temp.push({
//           is_edited: false,
//           _id: ele._id,
//           ind: ind
//         })
//       })
//       setCourseActions(temp)
//     }
//     if (courseLevel != undefined) {
//       let templevel = []
//       courseLevel.data.map((ele, ind) => {
//         templevel.push({
//           is_edited: false,
//           _id: ele._id,
//           ind: ind
//         })
//       })
//       setCourseLevelActions(templevel)
//     }
//     if (allSubjects != undefined && allSubjects.status) {
//       let templevel = []
//       allSubjects.data.map((ele, ind) => {
//         templevel.push({
//           is_edited: false,
//           _id: ele.subject_id,
//           ind: ind
//         })
//       })
//       setSubjectActions(templevel)
//     }
//   }, [data, courseLevel, render, getcourseLevel.isSuccess, allSubjects])
//   const notify = (msg) => toast(msg);
//   return (
//     <>
//       <Grid container>
//         {/* Course Starts */}
//         <Grid item xs={4} >
//           <Box boxShadow={15} className={classes.mr}>
//             <Card className={classes.root}>
//               <Typography variant="h5" align="center">
//                 Courses
//               </Typography>
//               <hr />
//               <div style={{ height: 300, overflowY: "auto" }}>

//                 {isSuccess && (getallcourses.data.length == courseActions.length) ?
//                   <>
//                     {getallcourses.data.length > 0 ?
//                       getallcourses.data.map((data, index) => {
//                         return (
//                           <Grid container key={data._id} >
//                             <Grid item xs={8} style={{ paddingTop: 10 }}>
//                               {courseActions[index].is_edited == false ?
//                                 <Chip
//                                   style={{ fontSize: 20, color: "#000000" }}
//                                   component="div"
//                                   label={data.name}
//                                   variant="outlined"
//                                   onClick={() => {
//                                     setChecked(true)
//                                     setChecked1(false)
//                                     setCourseId(data._id)
//                                   }}
//                                 />
//                                 :
//                                 <TextField
//                                   onChange={(e) => {
//                                     setEditedCourse({ name: e.target.value, id: data._id })
//                                   }}
//                                   value={editedCourse.name}
//                                   label="Update Course"
//                                   fullWidth={true}
//                                   variant="outlined"
//                                   size="small"
//                                 />
//                               }
//                             </Grid>
//                             <Grid item xs={4}>
//                               {courseActions[index].is_edited == false ?
//                                 <IconButton
//                                   onClick={() => {
//                                     editedCourseAction(data._id, index)
//                                   }}
//                                   color="primary">
//                                   <EditIcon />
//                                 </IconButton> :
//                                 <Button
//                                   style={{ margin: "10px 15px" }}
//                                   variant="contained"
//                                   size="large"
//                                   color="primary"
//                                   onClick={(e) => {
//                                     updateCourse(data._id, index)
//                                   }}>
//                                   <CheckIcon />
//                                 </Button>}
//                               {courseActions[index].is_edited == false ?
//                                 <IconButton
//                                   onClick={() => {
//                                     deletCouse(data._id)
//                                     setRender(!render)
//                                     setChecked(false)

//                                   }}
//                                   color="secondary">
//                                   <DeleteIcon />
//                                 </IconButton> : ""}
//                             </Grid>
//                           </Grid>
//                         )
//                       })
//                       : "Data Not Found"}
//                   </>

//                   : "Loading.."}
//               </div>

//               {showCourseAddButton &&

//                 <Grid container style={{ padding: "5%", }}>
//                   <Grid item xs={7} style={{ marginLeft: 15 }}>
//                     <TextField
//                       variant="outlined"
//                       size="small"
//                       label="Add Course"
//                       onChange={(e) => setCourseName(e.target.value)}
//                     />
//                   </Grid>

//                   <Grid item xs={1}></Grid>

//                   <Grid item xs={2}>
//                     <Button
//                       variant="contained"
//                       size="large"
//                       color="primary"
//                       onClick={() => {
//                         addCourse({ name: courseName })
//                         setShowCourseAddButton(!showCourseAddButton)
//                         setRender(!render)
//                         handleClick(GrowTransition)
//                       }
//                       }
//                     >Add</Button>
//                   </Grid>
//                   <Snackbar
//                     open={state.open}
//                     onClose={handleClose}
//                     TransitionComponent={state.Transition}
//                     message="I love snacks"
//                     key={state.Transition.name}
//                   />
//                 </Grid>
//               }
//               {!showCourseAddButton && <EditDelete onclick={showCourseButton} type="add" />}
//             </Card>
//           </Box>
//         </Grid>

//         {/* Levels Starts */}
//         <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
//           <Grid item xs={4}>
//             <Box boxShadow={15} className={classes.mr}>
//               <Card className={classes.root}>
//                 <Typography variant="h5" align="center">
//                   Levels
//                 </Typography>
//                 <hr />
//                 <div style={{ height: 300, overflowY: "auto" }}>
//                   {getcourseLevel.isSuccess && (courseLevelAction.length == courseLevel.data.length) ?
//                     <>

//                       {courseLevel.data.length > 0 ?
//                         courseLevel.data.map((data, index) => (
//                           <Grid container key={data._id} style={{ paddingTop: 10 }}>

//                             <Grid item xs={8}>
//                               {
//                                 courseLevelAction[index].is_edited == false ?
//                                   <Chip
//                                     style={{ fontSize: 20, color: "#000000" }}
//                                     component="div"
//                                     label={data.level_name}
//                                     variant="outlined"
//                                     onClick={(e) => {
//                                       selectSubHandler(e)
//                                       setCourseLevelId(data._id)
//                                       setAllSubjectData(data._id)
//                                     }}
//                                   />
//                                   :
//                                   <TextField
//                                     onChange={(e) => {
//                                       setEditedCourseLevel({ level_name: e.target.value, course_id: courseId, id: data._id })
//                                       setEditedLevel({ name: e.target.value, id: data._id })
//                                     }}
//                                     value={editedLevel.name}
//                                     label="Update Course Level"
//                                     fullWidth={true}
//                                     variant="outlined"
//                                     size="small"
//                                   />
//                               }
//                             </Grid>
//                             <Grid item xs={4}>
//                               {courseLevelAction[index].is_edited == false ?
//                                 <IconButton
//                                   onClick={() => {
//                                     editedCourseLevelAction(data._id, index)
//                                   }}
//                                   color="primary">
//                                   <EditIcon />
//                                 </IconButton> :

//                                 <Button
//                                   style={{ margin: "-1px 15px" }}
//                                   variant="contained"
//                                   size="large"
//                                   color="primary"
//                                   onClick={(e) => {
//                                     courseLevelNameFunc(data._id, index)
//                                   }}>
//                                   <CheckIcon />
//                                 </Button>

//                               }
//                               {courseLevelAction[index].is_edited == false && <IconButton
//                                 onClick={() => {

//                                   deletCouseLevel({ course_id: courseId, id: data._id })
//                                   setRender(!render)
//                                   setChecked1(false)

//                                 }}
//                                 color="secondary">
//                                 <DeleteIcon />
//                               </IconButton>}
//                             </Grid>
//                           </Grid>
//                         )) : "No Data Found"}

//                     </>

//                     : "Loading..."}
//                 </div>
//                 {showLevelsAddButton &&
//                   <Grid container style={{}}>

//                     <Grid item xs={7} style={{ marginLeft: 15 }}>
//                       <TextField
//                         variant="outlined"
//                         size="small"
//                         label="Add Level"
//                         onChange={(e) => {
//                           setCourselevelName(e.target.value)
//                         }}
//                       />
//                     </Grid>
//                     <Grid item xs={1}></Grid>
//                     <Grid item xs={2}>
//                       <Button
//                         variant="contained"
//                         size="large"
//                         color="primary"
//                         onClick={() => {
//                           addCourseLevel({ level_name: courselevelName, course_id: courseId })
//                           setShowLevelsAddButton(!showLevelsAddButton)
//                           showAddLevel()

//                         }}
//                       >Add</Button>

//                     </Grid>
//                   </Grid>
//                 }
//                 {!showLevelsAddButton && <EditDelete onclick={showAddLevel} type="add" />}
//               </Card>
//             </Box>
//           </Grid>
//         </Slide>

//         {/* subject starts */}
//         <Slide direction="up" in={checked1} mountOnEnter unmountOnExit>
//           <Grid item xs={4}>
//             <Box boxShadow={15} className={classes.mr}>
//               <Card className={classes.root}>
//                 <Typography variant="h5" align="center">
//                   Subjects
//                 </Typography>
//                 <hr />
//                 <div style={{ height: 300, overflowY: "auto" }}>
//                   {getAllSubjects.status == "fulfilled" && subjectAction.length && (subjectAction.length == allSubjects.data.length) ?
//                     <>
//                       {allSubjects.data.length > 0 ?
//                         allSubjects.data.map((subject, index) => (
//                           <Grid container style={{ paddingTop: 10 }}>
//                             <Grid item xs={8}>
//                               {subjectAction[index].is_edited == false ?
//                                 <>
//                                   <Chip
//                                     style={{ fontSize: 16, color: "#000000" }}
//                                     component="div"
//                                     label={`${subject.subject_name} - ( ${subject.subject_code} )`}
//                                     variant="outlined"
//                                     onClick={(e) => { selectSubHandler(e) }}
//                                   />
//                                 </>
//                                 :
//                                 <TextField
//                                   onChange={(e) => {
//                                     setEditedCourseLevel({ level_name: e.target.value, course_id: courseId, id: data._id })
//                                     setEditedSubject({ name: e.target.value, id: subject.subject_id })
//                                   }}
//                                   value={editedSubject.name}
//                                   label="Update Subject"
//                                   fullWidth={true}
//                                   variant="outlined"
//                                   size="small"
//                                 />
//                               }

//                             </Grid>
//                             <Grid item xs={4}>
//                               {subjectAction[index].is_edited == false ?
//                                 <IconButton
//                                   onClick={() => {
//                                     editedSubjectAction(subject.subject_id, index)
//                                   }}
//                                   color="primary">
//                                   <EditIcon />
//                                 </IconButton> :
//                                 <Button
//                                   style={{ margin: "-1px 15px" }}
//                                   variant="contained"
//                                   size="large"
//                                   color="primary"
//                                   onClick={(e) => {
//                                     updateSubject({
//                                       subject_name: editedSubject,
//                                       id: subject._id,
//                                     })
//                                     let tempCourseName = [...subjectAction]
//                                     tempCourseName[index].is_edited = false
//                                     setSubjectActions(tempCourseName)
//                                     setEditedSubject({
//                                       name: "",
//                                       id: ""
//                                     })
//                                   }}>
//                                   <CheckIcon />
//                                 </Button>
//                               }
//                               {subjectAction[index].is_edited == false &&
//                                 <IconButton
//                                   // (subject.subject_id)
//                                   onClick={() => deleteSubject(subject._id)}
//                                   color="secondary">
//                                   <DeleteIcon />
//                                 </IconButton>}
//                             </Grid>
//                           </Grid>
//                         ))
//                         : "No Data Found"}
//                     </>
//                     : "Loading..."}
//                 </div>
//                 {showSubjectsAddButton &&
//                   <Grid container style={{ padding: "5%" }}>
//                     <Grid item xs={12} style={{ marginLeft: 15 }}>
//                       <TextField
//                         variant="outlined"
//                         size="small"
//                         fullWidth={true}
//                         label="Subject Name"
//                         onChange={(e) => {
//                           setSubjectName(e.target.value)
//                         }}
//                       /><br /><br />
//                       <TextField
//                         variant="outlined"
//                         size="small"
//                         label="Subject Code"
//                         fullWidth={true}
//                         onChange={(e) => {
//                           setSubjectCode(e.target.value)
//                         }}

//                       /><br /><br />
//                       {showSubjectsAddButton &&
//                         <Button
//                           variant="contained"
//                           fullWidth={true}

//                           onClick={() => {
//                             addSubject({
//                               "level_id": courseLevelId,
//                               "subject_name": subjectName,
//                               "subject_code": subjectCode,
//                             })
//                             showAddSubject()
//                           }}
//                           size="large" color="primary">Add</Button>}
//                     </Grid>

//                   </Grid>
//                 }
//                 {!showSubjectsAddButton && <EditDelete onclick={showAddSubject} type="add" />}
//               </Card>
//             </Box>
//           </Grid>
//         </Slide>

//         <ToastContainer />
//       </Grid >
//     </>
//   );
// }
