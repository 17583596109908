import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const fileExplorerApi = createApi({
  tagTypes: ["fileExplorer"],
  reducerPath: "fileExplorerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/file-explorer/`,


  }),
  endpoints: (builder) => ({
    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET "fileExplorer" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    GetAllFiles: builder.query({
      providesTags: ["fileExplorer"],
      query: (body) => {
        return {
          url: "",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ADD "fileExplorer" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    AddFile: builder.mutation({
      invalidatesTags: ["fileExplorer"],
      query: (body) => {
        return {
          url: "add-file-to-s3",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< UPDATE "fileExplorer" IS_SHOW STATUS  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    UpdateFile: builder.mutation({
      invalidatesTags: ["fileExplorer"],
      query: ({ body, id }) => {
        return {
          url: id,
          headers: {
            "x-access-token": token,
          },
          method: "PUT",
          body,
        };
      },
    }),




    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< UPDATE DATA IN "fileExplorer" SECTION >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    DeleteFile: builder.mutation({
      invalidatesTags: ["fileExplorer"],
      query: (id) => {
        return {
          url: id ,
          headers: {
            "x-access-token": token,
          },
          method: "DELETE",
        };
      },
    }),


  }),
});

export const {
  useGetAllFilesQuery,
  useAddFileMutation,
  useUpdateFileMutation,
  useDeleteFileMutation,
} = fileExplorerApi;



