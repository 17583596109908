// /

import Delete from "@material-ui/icons/Delete";
import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem("__authToken__");
export const TicketApi=createApi({
    tagTypes: ["ticket"],
  reducerPath: "ticket",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/ticket-support/`,
  }),
  endpoints:(builder)=>({
    
      GetTicket:builder.mutation({
          providesTags:["ticket"],
          query:(body)=>{
              return{
                  url:"all-tickets",
                  headers: {
                    "x-access-token": token,
                  },
                  method: "POST",
                  body,

              }
          }
      }),
      DeleteTicket:builder.mutation({
        invalidatesTags:["ticket"],
        query:(id)=>{
            return{
                url:id,
                headers: {
                  "x-access-token": token,
                },
                method: "DELETE",
               

            }
        }
    }),
    getTicketDetails:builder.mutation({
      providesTags:["ticket"],
      query:(id)=>{
          return{
              url:id,
              headers: {
                "x-access-token": token,
              },
              method: "GET",
             

          }
      }
  }),
  updateTicketDetails:builder.mutation({
    invalidatesTags:["ticket"],
    query:(data, id) => {
        
        return{
            url:data.id,
            headers: {
              "x-access-token": token,
            },
            method: "PUT",
            body:{
              description :data.description,
              status :data.status,
              sld_user_note :data.sld_user_note,
              website_user_note :data.website_user_note
            }        

        }
    }
}),


})
});
export const { 
    useGetTicketMutation,
    useDeleteTicketMutation,
    useGetTicketDetailsMutation,
    useUpdateTicketDetailsMutation,
} =TicketApi;