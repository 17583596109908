import React, { useState } from 'react';
import {
    TextField, Typography, Button, makeStyles, Select, InputLabel, FormControl
} from '@material-ui/core'
import { useFormik } from 'formik';
import * as yup from 'yup'
import {
    useGetAallFacultieNamesQuery, useGetAllCategoriesQuery,
    useGetAllLevelNamesQuery, useGetAllCoursesQuery,
    useGetAllBooksNameQuery,
} from '../../../services/services';



const useStyles = makeStyles((theme) => ({
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        minWidth: 100,
    },
    tab: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

}));

export default function AddCallbackRequest({ AddData, setDialog }) {
    const classes = useStyles();
    const getAllCourse = useGetAllCoursesQuery()
    const allCourse = getAllCourse.data
    const getAallFaculties = useGetAallFacultieNamesQuery()
    const allFaculty = getAallFaculties.data
    const phoneRegex = RegExp(
        /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/
    );

    const formik = useFormik({
        initialValues: {
            fullname: "",
            mobile: "",
            email: "",
            course: "",
            faculty: "",
            discription: "",
             },
        validationSchema: yup.object({
            fullname: yup.string().required("Required !"),
            mobile: yup.string().matches(phoneRegex, "Invalid phone").required("Mobile Number is required"),
            email: yup.string().email('Invalid Email ').required("Email is Required !"),
            course: yup.string().required("Required !"),
            faculty: yup.string().required("Required !"),
            discription: yup.string().required("Required !"),
        }),
        onSubmit: (values) => {
            var form_data = new FormData()
            for (let key in values) {
                form_data.append(key, values[key])
            }
              
            AddData(values)
        }
    })
    const dialogclose = () => {
        setDialog(false)
    }
    return (
        <>
            <form onSubmit={formik.handleSubmit} className="p-2" >
                <TextField
                    name="fullname"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullname}
                    fullWidth={true}
                    label="Student Name"
                    variant="outlined"
                    size="large"
                />
                {formik.touched.fullname && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.fullname}</span>}
                <br /><br />
                <TextField
                    name="mobile"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobile}
                    label="Moile number"
                    fullWidth={true}
                    variant="outlined"
                    size="large" />
                {formik.touched.mobile && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.mobile}</span>}

                <br /><br />
                <TextField
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    label="Email"
                    fullWidth={true}
                    multiline
                    variant="outlined"
                    size="large" />
                {formik.touched.email && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.email}</span>}
                <br /><br />

                <FormControl style={{ margin: 0 }} fullWidth={true}
                    variant="outlined"
                    className={classes.formControl}>
                    <InputLabel htmlFor="outlined-course-Name-native-simple">Course</InputLabel>
                    <Select native
                        name="course"
                        size='large'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.course}
                        label="Course">
                        <option aria-label="None" value="" />
                        {allCourse && allCourse.data.map((data) => <option value={data.name}>{data.name}</option>)}
                    </Select>
                </FormControl>
                {formik.touched.course && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.course}</span>}
                <br /><br />


                <FormControl style={{ margin: 0 }} fullWidth={true}
                    variant="outlined"
                    className={classes.formControl}>
                    <InputLabel htmlFor="outlined-faculty-Name-native-simple">Faculty</InputLabel>
                    <Select native
                        name="faculty"
                        size='large'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.faculty}
                        label="Faculty">
                        <option aria-label="None" value="" />
                        {allFaculty && allFaculty.data.map((data) => <option value={data.name}>{data.name}</option>)}
                    </Select>
                </FormControl>
                {formik.touched.faculty && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.faculty}</span>}
                <br /><br />

                <TextField
                    name="discription"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.discription}
                    multiline
                    label="Discription"
                    fullWidth={true}
                    variant="outlined"
                    size="large"
                    minRows={3} />
                {formik.touched.discription && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.discription}</span>}
                <br /><br />

                <div className="d-flex justify-content-end">
                    <Button
                        onClick={dialogclose}
                        variant="contained"
                        color="secondary"
                    >Close</Button>
                    <Button
                        type="submit"
                        style={{ marginLeft: 20 }}
                        variant="contained"
                        color="primary"
                    > Save</Button>
                </div>
            </form>
        </>
    )
}
