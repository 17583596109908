// **** React Import
import React, { useMemo, useState, useCallback } from "react";

// **** Image Capture
import Webcam from "react-webcam";

// **** Formik Imports
import { ErrorMessage, Field, Form, Formik } from "formik";

// **** Yup Import
import * as Yup from "yup";

// **** Material Imports
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

// ** Material Icons Import
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SkeletonListing from "../../SkeletonListing";

// **** Custom File Imports
import CurrencyDetailsForm from "../CurrencyDetailsForm";

// **** Services Imports
import { useGetConfigQuery } from "../../../../../services/offlineModuleServices/configServices";
import { useGetAllBatchQuery } from "../../../../../services/offlineModuleServices/batchServices";
import { Avatar } from "@material-ui/core";
import AddCurrencyDetailsForm from "../../AddCurrencyDetailsForm";

// **** Mobile RegEx
const mobileRegEx = /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/;

const renderTextError = (name) => (
  <ErrorMessage name={name}>
    {(errMsg) => <Typography color="error"> {errMsg} </Typography>}
  </ErrorMessage>
);

// **** Main Function Starts
function AddVirtualClassOrder(props) {
  // **** Destructuring Props
  const { setOpenOrderForm, orderType } = props;

  // **** States
  const [viewCurrencyDetailsForm, setViewCurrencyDetailsForm] = useState(false);
  const [orderFormData, setOrderFormData] = useState("");
  const [imgSrc, setImgSrc] = useState(null);
  const [isWebCam, setIsWebCam] = useState(false);

  const videoConstraints = {
    width: "100%",
    facingMode: "user",
  };

  // **** Check Device Has Webcam
  navigator.mediaDevices.getUserMedia(
    {
      video: true,
    },
    () => {
      setIsWebCam(true);
    },
    () => {
      setIsWebCam(false);
    }
  );

  // **** Services
  const {
    data: AllBatches,
    isFetching: isAllBatchesFetching,
    isLoading: isAllBatchesLoading,
  } = useGetAllBatchQuery();
  const {
    data: AllAttempts,
    isFetching: isAllAttemptsFetching,
    isLoading: isAllAttemptsLoading,
  } = useGetConfigQuery({
    limit: "",
    page: "",
    key: "attempt",
  });

  // **** Form Initial Values
  const initialValues = {
    student_name: "",
    email: "",
    mobile_number: "",
    cro_number: "",
    fee_paid: 0,
    book_issued: "",
    remark: "",
    combo: "",
    due_amount: 0,
    attempt_due: "",
    batch_details: "",
    batch: [],
    combo_with: "",
  };

  // **** Form Validation Schema
  const validationSchema = Yup.object({
    student_name: Yup.string().required("this field is required"),
    email: Yup.string()
      .email("Invalid Email")
      .required("this field is required"),
    mobile_number: Yup.string()
      .matches(mobileRegEx, "Invalid Mobile Number")
      .typeError("Invalid Mobile Number")
      .required("this field is required"),
    fee_paid: Yup.number()
      .typeError("Fees paid should be in number format")
      .test({
        name: "max",
        exclusive: false,
        params: {},
        message: "Fees paid should be less than or equal to Total Fees",
        test: (value, ctx) => value <= ctx.parent.batch_details?.fees,
      })
      .required("This field is required"),
    book_issued: Yup.string(),
    remark: Yup.string(),
    attempt_due: Yup.string().required("Please Select Attempt Due"),
    batch_details: Yup.object().required("Please Select Batch Name"),
    combo: Yup.string().required("Please Select Combo"),
    combo_with: Yup.string().when("combo", {
      is: "yes",
      then: Yup.string().required("This field is required"),
    }),
  });

  // **** On Submit Handler
  const onSubmitHandler = (values) => {
    values = {
      ...values,
      due_amount: values.batch_details.fees - values.fee_paid,
      batch: [values.batch_details._id],
      amount_after_discount: values.batch_details.fees,
    };

    if (values.combo == "yes") {
      values = {
        ...values,
        batch: [...values.batch, values.combo_with],
      };
    }

    setViewCurrencyDetailsForm(true);
    setOrderFormData(values);
      
  };

  return isAllAttemptsFetching ||
    isAllAttemptsLoading ||
    isAllBatchesFetching ||
    isAllBatchesLoading ? (
    <SkeletonListing />
  ) : (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {({ values, setFieldValue }) => (
          <>
            {!viewCurrencyDetailsForm ? (
              <Form>
                <Grid container justifyContent="center">
                  <Grid item xs={11} mt={5}>
                    <Paper elevation={20}>
                      <Grid container justifyContent="space-between">
                        {/* **** Form Heading **** */}
                        <Grid
                          container
                          item
                          xs={12}
                          justifyContent="center"
                          bgcolor="#5664d2"
                          sx={{ borderBottomRightRadius: "20px" }}
                          py={1}
                        >
                          <Typography variant="h6" className="text-white">
                            {" "}
                            Create a Virtual Class Order{" "}
                          </Typography>
                        </Grid>
                      </Grid>

                      {/* Form Fields */}
                      <Grid container spacing={5} p={5}>
                        {/* Image */}
                        <Grid container item xs={12} spacing={3}>
                          <Grid item md={2.5} sm={3} xs={6}>
                            {isWebCam && (
                              <Webcam
                                audio={false}
                                screenshotFormat="image/webp"
                                videoConstraints={videoConstraints}
                                height={200}
                                style={{
                                  width: "100%",
                                  objectFit: "fill",
                                }}
                              >
                                {({ getScreenshot }) => (
                                  <Grid container justifyContent="center">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      type="button"
                                      fullWidth
                                      onClick={() => {
                                        const imageSrc = getScreenshot();
                                          
                                        setImgSrc(imageSrc);
                                      }}
                                    >
                                      Capture Image
                                    </Button>
                                  </Grid>
                                )}
                              </Webcam>
                            )}
                          </Grid>

                          <Grid item md={2.5} sm={3} xs={6}>
                            {imgSrc && (
                              <Avatar
                                variant="rounded"
                                style={{ width: "100%", height: "200px" }}
                                src={imgSrc}
                                alt="Image"
                              />
                            )}
                          </Grid>

                          {/* **** Back Button **** */}
                          <Grid item xs={7} container justifyContent="flex-end">
                            <Grid item>
                              <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                  setOpenOrderForm(false);
                                }}
                              >
                                {/* <KeyboardBackspaceIcon />  */}
                                {/* &nbsp; */}
                                Back
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* **** Name **** */}
                        <Grid item xs={3}>
                          <InputLabel htmlFor="student_name">
                            {" "}
                            <span className="text-danger"> * </span> Name{" "}
                          </InputLabel>
                          <Field name="student_name">
                            {({ field }) => (
                              <TextField
                                {...field}
                                placeholder="Name"
                                size="small"
                                variant="standard"
                                id="student_name"
                                fullWidth
                              />
                            )}
                          </Field>
                          {renderTextError("student_name")}
                        </Grid>

                        {/* **** Email **** */}
                        <Grid item xs={3}>
                          <InputLabel htmlFor="email">
                            {" "}
                            <span className="text-danger"> * </span> Email{" "}
                          </InputLabel>
                          <Field name="email">
                            {({ field }) => (
                              <TextField
                                {...field}
                                placeholder="Email"
                                size="small"
                                variant="standard"
                                id="email"
                                fullWidth
                              />
                            )}
                          </Field>
                          {renderTextError("email")}
                        </Grid>

                        {/* **** Mobile No. **** */}
                        <Grid item xs={3}>
                          <InputLabel htmlFor="mobile_number">
                            {" "}
                            <span className="text-danger"> * </span> Mobile No.{" "}
                          </InputLabel>
                          <Field name="mobile_number">
                            {({ field }) => (
                              <TextField
                                {...field}
                                placeholder="Mobile No."
                                size="small"
                                variant="standard"
                                id="mobile_number"
                                fullWidth
                              />
                            )}
                          </Field>
                          {renderTextError("mobile_number")}
                        </Grid>

                        {/* **** CRO No. **** */}
                        <Grid item xs={3}>
                          <InputLabel htmlFor="cro_number">
                            {" "}
                            CRO No.{" "}
                          </InputLabel>
                          <Field name="cro_number">
                            {({ field }) => (
                              <TextField
                                {...field}
                                placeholder="CRO No."
                                size="small"
                                variant="standard"
                                id="cro_number"
                                fullWidth
                              />
                            )}
                          </Field>
                          {renderTextError("cro_number")}
                        </Grid>

                        {/* **** Attempt Due **** */}
                        <Grid item xs={3}>
                          <InputLabel htmlFor="attempt_due">
                            {" "}
                            <span className="text-danger"> * </span> Attempt Due{" "}
                          </InputLabel>
                          <Field name="attempt_due">
                            {({ field }) => (
                              <Select
                                {...field}
                                defaultValue=""
                                size="small"
                                variant="standard"
                                id="attempt_due"
                                fullWidth
                              >
                                {AllAttempts?.status == false ? (
                                  <Button className="px-3" disabled>
                                    {" "}
                                    No Attempts Found{" "}
                                  </Button>
                                ) : (
                                  <MenuItem value="">
                                    {" "}
                                    <em> Select Attempt Due </em>{" "}
                                  </MenuItem>
                                )}
                                {AllAttempts &&
                                  AllAttempts?.data?.map((ele) => {
                                    return (
                                      <MenuItem value={ele.attempt_due_date}>
                                        {ele.attempt_due_date}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            )}
                          </Field>
                          {renderTextError("attempt_due")}
                        </Grid>

                        {/* **** Batch Name **** */}
                        <Grid item xs={3}>
                          <InputLabel htmlFor="batch_details">
                            {" "}
                            <span className="text-danger"> * </span> Batch Name{" "}
                          </InputLabel>
                          <Field name="batch_details">
                            {({ field }) => (
                              <Select
                                {...field}
                                placeholder="Select Batch"
                                size="small"
                                variant="standard"
                                id="batch_details"
                                fullWidth
                              >
                                {AllBatches?.data?.length == 0 ? (
                                  <Button className="px-3" disabled>
                                    {" "}
                                    No Batches Found{" "}
                                  </Button>
                                ) : (
                                  <MenuItem value="">
                                    {" "}
                                    <em> Select Batch</em>{" "}
                                  </MenuItem>
                                )}
                                {AllBatches &&
                                  AllBatches.data.map((ele) => {
                                    return (
                                      <MenuItem value={ele}>
                                        {" "}
                                        {ele.batch_name}{" "}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            )}
                          </Field>
                          {renderTextError("batch_details")}
                        </Grid>

                        {/* **** Batch Time **** */}
                        <Grid item xs={3}>
                          <InputLabel htmlFor="batch_time">
                            {" "}
                            <span className="text-danger"> * </span> Batch Time{" "}
                          </InputLabel>
                          <Field>
                            {() => (
                              <TextField
                                placeholder="Batch Time"
                                size="small"
                                variant="standard"
                                id="batch_time"
                                value={values.batch_details.batch_timing}
                                disabled
                                fullWidth
                              />
                            )}
                          </Field>
                        </Grid>

                        {/* **** Batch Number  **** */}
                        <Grid item xs={3}>
                          <InputLabel htmlFor="batch_number">
                            {" "}
                            <span className="text-danger"> * </span> Batch
                            Number{" "}
                          </InputLabel>
                          <Field>
                            {() => (
                              <TextField
                                placeholder="Batch No."
                                size="small"
                                variant="standard"
                                id="batch_number"
                                value={values.batch_details.batch_name}
                                disabled
                                fullWidth
                              />
                            )}
                          </Field>
                        </Grid>

                        {/* **** Batch Type **** */}
                        <Grid item xs={3}>
                          <InputLabel htmlFor="batch_type">
                            {" "}
                            <span className="text-danger"> * </span> Batch Type{" "}
                          </InputLabel>
                          <Field>
                            {() => (
                              <TextField
                                placeholder="Batch Type"
                                size="small"
                                variant="standard"
                                id="batch_type"
                                value={values.batch_details.category_name}
                                disabled
                                fullWidth
                              />
                            )}
                          </Field>
                        </Grid>

                        {/* **** Course **** */}
                        <Grid item xs={3}>
                          <InputLabel htmlFor="course">
                            {" "}
                            <span className="text-danger"> * </span> Course{" "}
                          </InputLabel>
                          <Field>
                            {() => (
                              <TextField
                                placeholder="Course"
                                size="small"
                                variant="standard"
                                id="course"
                                value={values.batch_details.course_name}
                                disabled
                                fullWidth
                              />
                            )}
                          </Field>
                        </Grid>

                        {/* **** Subjects **** */}
                        <Grid item xs={3}>
                          <InputLabel htmlFor="subjects">
                            {" "}
                            <span className="text-danger">
                              {" "}
                              *{" "}
                            </span> Subjects{" "}
                          </InputLabel>
                          <Field>
                            {() => (
                              <Tooltip
                                title={values.batch_details.subject
                                  ?.map((ele, index) => ele.subject_name)
                                  .join(" , ")}
                              >
                                <TextField
                                  placeholder="Subjects"
                                  size="small"
                                  variant="standard"
                                  id="subjects"
                                  // multiline
                                  // minRows={1}
                                  value={values.batch_details.subject
                                    ?.map((ele, index) => ele.subject_name)
                                    .join(" , ")}
                                  disabled
                                  fullWidth
                                />
                              </Tooltip>
                            )}
                          </Field>
                        </Grid>

                        {/* **** Faculties **** */}
                        <Grid item xs={3}>
                          <InputLabel htmlFor="faculties">
                            {" "}
                            <span className="text-danger">
                              {" "}
                              *{" "}
                            </span> Faculties{" "}
                          </InputLabel>
                          <Field>
                            {() => (
                              <Tooltip
                                title={values.batch_details.faculty
                                  ?.map((ele) => ele.faculty_name)
                                  .join(" , ")}
                              >
                                <TextField
                                  placeholder="Faculties"
                                  size="small"
                                  variant="standard"
                                  id="faculties"
                                  // multiline
                                  // minRows={1}
                                  value={values.batch_details.faculty
                                    ?.map((ele) => ele.faculty_name)
                                    .join(" , ")}
                                  disabled
                                  fullWidth
                                />
                              </Tooltip>
                            )}
                          </Field>
                        </Grid>

                        {/* **** Level **** */}
                        <Grid item xs={3}>
                          <InputLabel htmlFor="level">
                            {" "}
                            <span className="text-danger"> * </span> Level{" "}
                          </InputLabel>
                          <Field>
                            {() => (
                              <TextField
                                placeholder="Level"
                                size="small"
                                variant="standard"
                                id="level"
                                value={values.batch_details.level_name}
                                disabled
                                fullWidth
                              />
                            )}
                          </Field>
                        </Grid>

                        {/* **** Total Fees **** */}
                        <Grid item xs={3}>
                          <InputLabel htmlFor="total_fees">
                            {" "}
                            <span className="text-danger"> * </span> Total Fees{" "}
                          </InputLabel>
                          <Field>
                            {() => (
                              <TextField
                                placeholder="Total Fees"
                                size="small"
                                variant="standard"
                                id="total_fees"
                                value={values.batch_details.fees}
                                disabled
                                fullWidth
                              />
                            )}
                          </Field>
                        </Grid>

                        {/* **** Fees Paid **** */}
                        <Grid item xs={3}>
                          <InputLabel htmlFor="fee_paid">
                            {" "}
                            <span className="text-danger"> * </span> Fees Paid{" "}
                          </InputLabel>
                          <Field name="fee_paid">
                            {({ field }) => (
                              <TextField
                                {...field}
                                placeholder="Fees Paid"
                                size="small"
                                variant="standard"
                                id="fee_paid"
                                fullWidth
                              />
                            )}
                          </Field>
                          {renderTextError("fee_paid")}
                        </Grid>

                        {/* **** Remaining Fees **** */}
                        <Grid item xs={3}>
                          <InputLabel htmlFor="remaining_fees">
                            {" "}
                            <span className="text-danger"> * </span> Remaining
                            Fees{" "}
                          </InputLabel>
                          <Field>
                            {() => (
                              <TextField
                                placeholder="Remaining Fees"
                                size="small"
                                variant="standard"
                                id="remaining_fees"
                                value={
                                  values.batch_details
                                    ? values.batch_details.fees -
                                      values.fee_paid
                                    : 0
                                }
                                disabled
                                fullWidth
                              />
                            )}
                          </Field>
                        </Grid>

                        {/* **** Book Issued **** */}
                        <Grid item xs={3}>
                          <InputLabel htmlFor="book_issued">
                            {" "}
                            Book Issued{" "}
                          </InputLabel>
                          <Field name="book_issued">
                            {({ field }) => (
                              <TextField
                                {...field}
                                placeholder="Book Issued"
                                size="small"
                                variant="standard"
                                id="book_issued"
                                fullWidth
                              />
                            )}
                          </Field>
                          {renderTextError("book_issued")}
                        </Grid>

                        {/* **** Hall **** */}
                        <Grid item xs={3}>
                          <InputLabel htmlFor="hall">
                            {" "}
                            <span className="text-danger"> * </span> Hall{" "}
                          </InputLabel>
                          <Field>
                            {() => (
                              <TextField
                                placeholder="Hall"
                                size="small"
                                variant="standard"
                                id="hall"
                                value={values.batch_details.hall_name}
                                disabled
                                fullWidth
                              />
                            )}
                          </Field>
                        </Grid>

                        {/* **** Combo **** */}
                        <Grid item xs={3}>
                          <InputLabel htmlFor="combo">
                            {" "}
                            <span className="text-danger"> * </span> Combo{" "}
                          </InputLabel>
                          <Field name="combo">
                            {({ field }) => (
                              <FormControl fullWidth>
                                <Select
                                  {...field}
                                  placeholder="Combo"
                                  id="combo"
                                  size="small"
                                  fullWidth
                                  variant="standard"
                                >
                                  <MenuItem value="yes">YES</MenuItem>
                                  <MenuItem value="no">NO</MenuItem>
                                </Select>
                              </FormControl>
                            )}
                          </Field>
                          {renderTextError("combo")}
                        </Grid>

                        {/* **** Combo With **** */}
                        {values.combo == "yes" && (
                          <Grid item xs={3}>
                            <InputLabel htmlFor="combo_with">
                              {" "}
                              <span className="text-danger"> * </span> Combo
                              With{" "}
                            </InputLabel>
                            <Field name="combo_with">
                              {({ field }) => (
                                <Select
                                  {...field}
                                  placeholder="Select Combo"
                                  size="small"
                                  variant="standard"
                                  id="combo_with"
                                  fullWidth
                                >
                                  {AllBatches?.data?.length == 0 ? (
                                    <Button className="px-3" disabled>
                                      {" "}
                                      No Batches Found{" "}
                                    </Button>
                                  ) : (
                                    <MenuItem value="">
                                      {" "}
                                      <em> Select Combo</em>{" "}
                                    </MenuItem>
                                  )}
                                  {AllBatches &&
                                    AllBatches.data.map((ele) => {
                                      return (
                                        <MenuItem value={ele._id}>
                                          {" "}
                                          {ele.batch_name}{" "}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              )}
                            </Field>
                            {renderTextError("combo_with")}
                          </Grid>
                        )}

                        {/* **** Remark **** */}
                        <Grid item xs={12}>
                          <InputLabel htmlFor="remark"> Remark </InputLabel>
                          <Field name="remark">
                            {({ field }) => (
                              <TextField
                                {...field}
                                placeholder="Remark"
                                size="small"
                                variant="standard"
                                id="remark"
                                fullWidth
                              />
                            )}
                          </Field>
                          {renderTextError("remark")}
                        </Grid>

                        {/* Save Button */}
                        <Grid item xs={12}>
                          <Button
                            className="translate-btn"
                            variant="contained"
                            sx={{ backgroundColor: "#5664d2" }}
                            fullWidth
                            type="submit"
                          >
                            SAVE
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Form>
            ) : (
              <AddCurrencyDetailsForm
                onClose={() => setViewCurrencyDetailsForm(false)}
                paidFees={values.fee_paid}
                data={orderFormData}
                orderFor="virtual_class"
              />
            )}

            {/* {viewCurrencyDetailsForm && (
                        <CurrencyDetailsForm
                            open={viewCurrencyDetailsForm}
                            onClose={setViewCurrencyDetailsForm}
                            paidFees={values.fee_paid}
                            data={orderFormData}
                            orderFor="virtual_class"
                        />
                    )} */}
          </>
        )}
      </Formik>
    </>
  );
}

export default AddVirtualClassOrder;
