import React, { useState, useEffect } from "react";
import EditDelete from "./EditDelete";
import CreateTableAddButton from "./CreateTableAddButton";
import UpdateFaculty from "./faculty/UpdateFaculty";
import Loader from "./Loader";
import AddDataHeader from "./AddDataHeader";
import {
  useGetAllQuotesMutation,
  useDeleteQuoteMutation,
  useUpdateQuoteMutation,
} from "../../../services/services";
import Toster from "./Toster";
import AddFaculty from "./faculty/AddFaculty";
import ViewQuote from "./ViewQuote";
import SkeletonListing from "./SkeletonListing";
import DataNotFound from "./DataNotFound";
import AddProcessedBY from "./AddProcessedBy";
import { Button, Grid, TextField } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import MaterialTables from "../dashboard/MaterialTables";
import { Avatar } from "@mui/material";
import AuthWrapper from "../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../utils/getFilterFunction";

export default function Quotes() {
  const columns = [
    {
      id: "sno",
      label: "SNO.",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "username",
      label: "User Name",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "email",
      label: "Email",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    // {
    //     id: 'usermo',
    //     label: 'User mobile no',
    //     minWidth: 50,
    //     align: 'center',
    //     format: (value) => value.toLocaleString('en-US'),
    // },
    // {
    //     id: 'useremail',
    //     label: 'User email',
    //     minWidth: 50,
    //     align: 'center',
    //     format: (value) => value.toLocaleString('en-US'),
    // },
    {
      id: "image",
      label: "Image",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "vrcode",
      label: "varient code ",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "title",
      label: "title",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    // {
    //     id: 'processedBy',
    //     label: 'processedBy',
    //     minWidth: 50,
    //     align: 'center',
    //     format: (value) => value.toLocaleString('en-US'),
    // },
    // {
    //     id: 'procedes',
    //     label: 'Description',
    //     minWidth: 50,
    //     align: 'center',
    //     format: (value) => value.toLocaleString('en-US'),
    // },
    {
      id: "reference_price",
      label: "ref price",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "quoted_price",
      label: "Quote Price",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "isreflink",
      label: "Refrence Link",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: ['PRE_SELL_SUPPORT_QUOTE_YOUR_PRICE_DELETE', 'PRE_SELL_SUPPORT_QUOTE_YOUR_PRICE_ADD_FOLLOWUP', 'PRE_SELL_SUPPORT_QUOTE_YOUR_PRICE_LIST_FOLLOWUP']
    },
  ];
  function createData(
    sno,
    username,
    email,
    image,
    vrcode,
    title,
    //processedBy, procedes,
    reference_price,
    quoted_price,
    isreflink,
    actions
  ) {
    return {
      sno,
      username,
      email,
      image,
      vrcode,
      title,
      //processedBy, procedes,
      reference_price,
      quoted_price,
      isreflink,
      actions,
    };
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: ["user_name", "mobile_number"],
    page: page + 1,
    filterBy: [],
  };
  let now = new Date();
  let rows = [];
  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  // const [name, setName] = useState("");

  const [AddDialog, setDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [getAllData, getAllDataInfo] = useGetAllQuotesMutation();
  const [deleteQuote, deleteQuoteInfo] = useDeleteQuoteMutation();
  const [addFollowUpData, addFollowUpDataInfo] = useUpdateQuoteMutation();
  const [feedbackDialogs, setFeedbackDialogs] = useState({
    viewFeedback: false,
    addFeedback: false,
  });
  const [updateStatus, updateDataInfo] = useDeleteQuoteMutation();
  const AallReviews = getAllDataInfo.data;
  const totalItem = AallReviews ? AallReviews.totalItem : 0;
  const [render, setRender] = useState(false);
  const [name, setName] = useState("");
  const [addFeedbackData, setAddFeedbackData] = useState({
    inquiry_id: "",
    discription: "",
    discription_error: "",
    next_date: moment(now).format("YYYY-MM-DD"),
    next_date_error: "",
    next_time: "12:00",
    next_time_error: "",
    intrested_or_not: "",
    intrested_or_not_error: "",
    more_than_3: "YES",
  });
  const [followUpData, setFollowUpData] = useState([]);
  const followUpTableHeaders = [
    "added_by_sldUser",
    "added_on_date",
    "discription",
    "intrested_or_not",
    "next_date_time",
    "sldUser_type",
  ];

  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page]);

  useEffect(() => {
    getid && deleteQuote(getid) && setGetid("");
  }, [getid]);

  const AddData = (data) => {
    addFollowUpData(data);
  };
  const UpdateData = (data, id) => {
    // updateStatus(data, id)
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    alert(`Submitting Name ${name}`);
  };

  let totalData = "ok";
  if (AallReviews) {
    totalData = AallReviews.totalItem;
    rows = AallReviews.data.map((data, index) =>
      createData(
        index + 1,
        <Grid container direction="column">
          <Grid item>{data.user_name}</Grid>
          {data?.mobile_number && (
            <Grid item className="text-primary">
              ({data?.mobile_number})
            </Grid>
          )}
        </Grid>,

        <div> {data.email || "-"} </div>,
        // data.user.mobile,
        // data.user.email,
        data.image ? (
          <Avatar
            variant="rounded"
            src={data.image}
            alt=""
            sx={{ height: "80px", width: "80px" }}
          />
        ) : (
          <div
            style={{
              height: "80px",
              width: "80px",
              border: "1px solid gray",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "17px",
              color: "gray",
            }}
          >
            {" "}
            No Image{" "}
          </div>
        ),
        data.product_varient_code,

        data.product_type == "SLD_BOOK_PRODUCT"
          ? data.books.product_title
          : data.videos.video_lecture_name,
        // data.processedBy == " " ? "-" : data.processedBy.name,
        // data.mark_as_processed ? data.processed_discription : <div>
        //     <AddProcessedBY AddData={AddData} id={data._id} />
        // </div>,

        data.reference_price,
        data.quoted_price,
        data.reference_site,

        <div className="">
          <AuthWrapper type="ACTION" name="PRE_SELL_SUPPORT_QUOTE_YOUR_PRICE_ADD_FOLLOWUP">
            <Button
              variant="contained"
              color="primary"
              style={{ fontSize: "11px" }}
              onClick={() => {
                setFeedbackDialogs({ ...feedbackDialogs, addFeedback: true });
                setAddFeedbackData({
                  inquiry_id: data._id,
                  discription: "",
                  discription_error: "",
                  next_date: moment(now).format("YYYY-MM-DD"),
                  next_date_error: "",
                  next_time: "12:00",
                  next_time_error: "",
                  intrested_or_not: "",
                  intrested_or_not_error: "",
                  more_than_3: "YES",
                });
              }}
            >
              + Follow-Up
            </Button>
          </AuthWrapper>
          <br />
          <AuthWrapper type="ACTION" name="PRE_SELL_SUPPORT_QUOTE_YOUR_PRICE_LIST_FOLLOWUP">
            <Button
              className="mt-2"
              variant="contained"
              color="success"
              style={{ fontSize: "11px" }}
              onClick={() => {
                setFeedbackDialogs({ ...feedbackDialogs, viewFeedback: true });
                setFollowUpData(
                  data.follow_up != undefined ? [...data.follow_up] : []
                );
              }}
            >
              Follow-Up
            </Button>
          </AuthWrapper>
          <br />
          <AuthWrapper type="ACTION" name="PRE_SELL_SUPPORT_QUOTE_YOUR_PRICE_DELETE"> <EditDelete type="delete" setGetid={setGetid} id={data._id} /></AuthWrapper>
        </div>
      )
    );
  }
  const dialogclose = () => {
    setEditDialog(false);
    setViewDialog(false);

    setDialog(false);
    setRender(!render);
  };
  const handleAddFollowUpSubmit = () => {
    let feedbackData = Object.assign({}, addFeedbackData);
    let isValid = true;

    for (let key in feedbackData) {
      if (feedbackData[key] == "" && !key.includes("_error")) {
        isValid = false;
        feedbackData[key + "_error"] = "Required !";
      } else {
        feedbackData[key + "_error"] = "";
      }
    }
    setAddFeedbackData(Object.assign({}, feedbackData));
    if (isValid) {
      let dataToSend = {
        discription: feedbackData.discription,
        next_date_time: feedbackData.next_date + " " + feedbackData.next_time,
        intrested_or_not: feedbackData.intrested_or_not,
        more_than_3: "YES",
      };
      addFollowUpData({ body: dataToSend, id: feedbackData.inquiry_id });
      setFeedbackDialogs({ ...feedbackDialogs, addFeedback: false });
    }
  };
  return (
    <>
      <div>
        {!viewDialog && (
          <AddDataHeader
            addFormName={"Add Faculty"}
            setPage={setPage}
            page={page}
            excelBtn={false}
            totalItem={totalItem}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setAddDialog={setDialog}
            searchBarApi={getAllData}
            paginationData={paginationData}
            isButton={true}
            Adddialog={AddDialog}
            addBtn={true}
            isSearch={
              !AuthWrapper({
                type: "ACTION",
                name: "PRE_SELL_SUPPORT_QUOTE_YOUR_PRICE_LIST",
                inBoolean: true,
              })
            }
            isPagination={
              !AuthWrapper({
                type: "ACTION",
                name: "PRE_SELL_SUPPORT_QUOTE_YOUR_PRICE_LIST",
                inBoolean: true,
              })
            }
            form={<AddFaculty AddData={AddData} setDialog={setDialog} />}
          />
        )}
        <AuthWrapper type="ACTION" name="PRE_SELL_SUPPORT_QUOTE_YOUR_PRICE_LIST">
          {deleteQuoteInfo.isLoading ||
            updateDataInfo.isLoading ||
            addFollowUpDataInfo.isLoading ? (
            <Loader />
          ) : (
            <>
              {!viewDialog ? (
                rows.length ? (
                  <CreateTableAddButton
                    editDialog={editDialog}
                    rows={rows}
                    editform={
                      <UpdateFaculty
                        UpdateData={UpdateData}
                        editfaculty={data}
                        editDialog={setEditDialog}
                      />
                    }
                    columns={getFilterdColumns(columns)}
                    isButton={true}
                    editFormName={"Edit Faculty"}
                  />
                ) : totalData == 0 ? (
                  <DataNotFound />
                ) : (
                  <SkeletonListing />
                )
              ) : (
                <ViewQuote viewDialog={setViewDialog} data={data} />
              )}
            </>
          )}
        </AuthWrapper>
        {addFollowUpDataInfo.data && (
          <Toster info={addFollowUpDataInfo} dialogclose={dialogclose} />
        )}
        {deleteQuoteInfo.data && (
          <Toster info={deleteQuoteInfo} dialogclose={dialogclose} />
        )}
        {updateDataInfo && (
          <Toster info={updateDataInfo} dialogclose={dialogclose} />
        )}
      </div>

      <Dialog
        fullWidth={true}
        open={feedbackDialogs.addFeedback}
        onClose={() => {
          setFeedbackDialogs({ ...feedbackDialogs, addFeedback: false });
        }}
      >
        <DialogTitle>Add Follow-Up</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container>
              <Grid xs={12} className="my-2">
                <TextField
                  type="text"
                  fullWidth
                  onChange={(e) => {
                    setAddFeedbackData({
                      ...addFeedbackData,
                      discription: e.target.value,
                    });
                  }}
                  name="discription"
                  value={addFeedbackData.discription}
                  label="Discription"
                  variant="outlined"
                  multiline
                  minRows={3}
                />
                <span style={{ fontSize: "12px" }} className="text-danger">
                  {addFeedbackData.discription_error}
                </span>
              </Grid>
              <Grid xs={12} className="my-2">
                <TextField
                  type="date"
                  fullWidth
                  onChange={(e) => {
                    setAddFeedbackData({
                      ...addFeedbackData,
                      next_date: e.target.value,
                    });
                  }}
                  name="next_date"
                  value={addFeedbackData.next_date}
                  label="Next Follow Up Date"
                  variant="outlined"
                />
                <span style={{ fontSize: "12px" }} className="text-danger">
                  {addFeedbackData.next_date_error}
                </span>
              </Grid>
              <Grid xs={12} className="my-2">
                <TextField
                  type="time"
                  fullWidth
                  onChange={(e) => {
                    setAddFeedbackData({
                      ...addFeedbackData,
                      next_time: e.target.value,
                    });
                  }}
                  name="next_time"
                  value={addFeedbackData.next_time}
                  label="Next Follow Up Time"
                  variant="outlined"
                />
                <span style={{ fontSize: "12px" }} className="text-danger">
                  {addFeedbackData.next_time_error}
                </span>
              </Grid>
              <Grid xs={12} className="my-2">
                <TextField
                  type="text"
                  fullWidth
                  onChange={(e) => {
                    setAddFeedbackData({
                      ...addFeedbackData,
                      intrested_or_not: e.target.value,
                    });
                  }}
                  name="intrested_or_not"
                  value={addFeedbackData.intrested_or_not}
                  label="Intrested Or Not ?"
                  variant="outlined"
                />
                <span style={{ fontSize: "12px" }} className="text-danger">
                  {addFeedbackData.intrested_or_not_error}
                </span>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="p-3">
          <Button
            className="bg-danger text-light"
            onClick={() => {
              setFeedbackDialogs({ ...feedbackDialogs, addFeedback: false });
            }}
          >
            Close
          </Button>
          <Button
            className="bg-success text-light"
            onClick={() => handleAddFollowUpSubmit()}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={feedbackDialogs.viewFeedback}
        size="large"
        onClose={() => {
          setFeedbackDialogs({ ...feedbackDialogs, viewFeedback: false });
        }}
      >
        <DialogTitle>Follow-Up List</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {followUpData.length ? (
              <MaterialTables
                columns={followUpTableHeaders}
                rows={followUpData}
              />
            ) : (
              <div className="text-center bg-light">No Data Found</div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="p-3">
          <Button
            className="bg-danger text-light"
            onClick={() => {
              setFeedbackDialogs({ ...feedbackDialogs, viewFeedback: false });
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
