import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem("__authToken__");
import { PYTHON_URL } from "../constant/BaseURL";
export const timeSheetApi = createApi({
  tagTypes: ["activeTimers"],
  reducerPath: "timeSheetApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/time-sheet/`,
  }),
  endpoints: (builder) => ({
    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET "All Active Timers"  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    GetAllActiveTimers: builder.query({
      providesTags: ["activeTimers"],
      query: () => {
        return {
          url: "all-active-timers",
          headers: {
            "x-access-token": token,
          },
          method: "GET",

        };
      },
    }),

    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET CURRENT TIMER DETAILS OF AN USER >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    getTimerDetailsByUserId: builder.query({
      providesTags: ["currentTimer"],
      query: ({ body, id }) => {
        return {
          url: `active-timer/${id}`,
          headers: {
            "x-access-token": token,
          },
          method: "GET",
          body,
        };
      },
    }),

    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Start/Stop Timer  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    startTimer: builder.mutation({
      invalidatesTags: ["currentTimer"],
      query: (body) => {
        return {
          url: "start-timer",
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        };
      },
    }),

  }),
});

export const {
  useGetAllActiveTimersQuery,
  useGetTimerDetailsByUserIdQuery,
  useStartTimerMutation,
} = timeSheetApi;


export const timeTrackingSheetApi = createApi({
  tagTypes: ["timeSheet"],
  reducerPath: "timeTrackingSheetApi",
  baseQuery: fetchBaseQuery({
    baseUrl: PYTHON_URL,
  }),
  endpoints: (builder) => ({
    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET TIME SHEET DATA >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    getTimeSheetData: builder.query({
      invalidatesTags: ["timeSheet"],
      query: ({ startDate, endDate, users }) => {
        return {
          url: `get-time-range?startDate=${startDate}&&endDate=${endDate}&&person=${users}`,
          headers: {
            "x-access-token": token,
          },
          method: "GET",
        };
      },
    }),


  }),
});

export const {
  useGetTimeSheetDataQuery
} = timeTrackingSheetApi;

