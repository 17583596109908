import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const mobileNoLibraryApi = createApi({
    tagTypes: ["mobileNo"],
    reducerPath: "mobileNoLibraryApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/mobile-no-library/`,
    }),
    endpoints: (builder) => ({
        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET " All Mobile Numbers" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        GetAllMobileNo: builder.query({
            providesTags: ["mobileNo"],
            query: (body) => {
                return {
                    url: "all-mobile-no",
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,
                };
            },
        }),

        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET " All Mobile Numbers list" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        GetAllMobileNoList: builder.query({
            providesTags: ["mobileNo"],
            query: () => {
                return {
                    url: "mobile-no-list",
                    headers: {
                        "x-access-token": token,
                    },
                    method: "GET"
                };
            },
        }),

        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ADD " New Mobile Numbers" DATA  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        AddNewMobileNo: builder.mutation({
            invalidatesTags: ["mobileNo"],
            query: (body) => {
                return {
                    url: "add-mobile-no",
                    headers: {
                        "x-access-token": token,
                    },
                    method: "POST",
                    body,
                };
            },
        }),

        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< UPDATE "Mobile Numbers" >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        UpdateMobileNo: builder.mutation({
            invalidatesTags: ["mobileNo"],
            query: ({ body, id }) => {
                return {
                    url: id,
                    headers: {
                        "x-access-token": token,
                    },
                    method: "PUT",
                    body,
                };
            },
        }),

        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< DELETE "mobileNo"  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        deleteMobileNo: builder.mutation({
            invalidatesTags: ["mobileNo"],
            query: (id) => {
                return {
                    url: id,
                    headers: {
                        "x-access-token": token,
                    },
                    method: "DELETE",
                };
            },
        }),


        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GET "Single Mobile No By Id" >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        getSingleMobileNoById: builder.query({
            query: (id) => {
                return {
                    url: id,
                    headers: {
                        "x-access-token": token,
                    },
                    method: "GET",
                };
            },
        }),


    }),
});

export const {
    useGetAllMobileNoQuery,
    useGetAllMobileNoListQuery,
    useAddNewMobileNoMutation,
    useUpdateMobileNoMutation,
    useDeleteMobileNoMutation,
    useGetSingleMobileNoByIdQuery,
} = mobileNoLibraryApi;



