import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Grid } from '@mui/material';
import moment from 'moment';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const color = ["#008b8b", "#bdb76b", "#556b2f", "#9932cc", "#8b008b", "#006400", "#a52a2a", "#a9a9a9", "#00008b", "#00ffff", "#0000ff", "#ff8c00", "#8b0000", "#e9967a", "#9400d3", "#ff00ff", "#ffd700", "#008000", "#4b0082", "#f0e68c", "#add8e6", "#e0ffff", "#90ee90", "#d3d3d3", "#ffb6c1", "#ffffe0", "#00ff00", "#ff00ff", "#800000", "#000080", "#808000", "#ffa500", "#ffc0cb", "#800080", "#800080", "#ff0000", "#c0c0c0", "#ffff00", "#000000", "#f5f5dc"]

export function TimeSheetChart({ chartData }) {

    // **** Option Prop for Bar Chart
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Total Logged Time',
            },
        },
    };

    const labels = []
    const labelsArrForData = []
    if (chartData) {

        for (let key in chartData[0]) {
            if (key == "sld_user_id" || key == "name") continue
            labels.push(moment(key).format("DD MMM"))
            labelsArrForData.push(key)
        }
    }


    // **** For Multiple User With Multiple Bars 
    // const datasets = chartData.map((ele, index) => {

    //     let data = []
    //     for (let key in ele) {
    //         if (key == "_id" || key == "name") continue
    //         data.push(ele[key])
    //     }
    //     return {
    //         label: ele.name,
    //         data: data,
    //         backgroundColor: color[index]
    //     }
    // })

    // **** For Single Bar
    const dataArr = labelsArrForData.map((ele, index) => {
        let dateWiseSum = chartData.reduce((acc, cv) => (acc + cv[ele]), 0)
        return dateWiseSum / 60
    })

    // **** Data for Chart
    const data = {
        labels,
        datasets: [
            {
                label: "Total",
                data: dataArr,
                backgroundColor: '#3498db'
            }
        ]
    };




    return (
        <Grid >
            <Bar options={options} data={data} height={50} />
        </Grid>)
}
