import React from 'react';
import { FormControl,  InputLabel,  Grid,  Select, } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import {  CardContent, Button, } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';



export default function AddVirtualClass() {
    
    return (
       
        <Grid container >
        <Grid>

            <CardContent>
                <Button variant="outlined" fullWidth={true} startIcon={<BackupIcon />} color="primary" component="label">
                    Upload Image
                    <input type="file" hidden />
                </Button><br /><br />
                <TextField fullWidth={true} label="Class Heading" variant="outlined" size="small" /><br /><br />
                <TextField fullWidth={true} type="datetime-local" defaultValue="2021-08-16T01:55" label="Class Date & Time " variant="outlined" size="small" /><br /><br />
                <TextField fullWidth={true} label="No of Lectures" variant="outlined" size="small" /><br /><br />
                <TextField label="Class Duration" fullWidth={true} multiline variant="outlined" size="medium" /><br /><br />
                <TextField fullWidth={true} label="Class Fees" variant="outlined" size="small" /><br /><br />
                <TextField fullWidth={true} label="Demo Lecture Link" variant="outlined" size="small" /><br /><br />
                <TextField fullWidth={true} label="Combo Fees" variant="outlined" size="small" /><br /><br />
                <FormControl style={{ margin: 0 }} fullWidth={true} variant="outlined" >
                        <InputLabel htmlFor="outlined-Institue-Name-native-simple">Status</InputLabel>
                        <Select  label="Institute Name">
                            <option aria-label="None" value="" />
                            <option style={{fontSize:18}} value={10}>Active</option><br/>
                            <option style={{fontSize:18}} value={20}>InActive</option>
                            
                        </Select>
                    </FormControl>
            </CardContent>
        </Grid>
    </Grid>
    )
}