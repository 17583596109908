import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { useUpdateConfigDataMutation } from "../../../../../../services/configuration/ConfigDataServices";
import Toster from "../../../Toster";
import AddSource from "../add/AddSource";
import AuthWrapper from "../../../../../../utils/AuthWrapper";

const SourceListing = ({ configDataId, columns, rows }) => {
  const [isOpenAddDialog, setIsOpenAddDialog] = useState(false);
  const [add, addInfo] = useUpdateConfigDataMutation();

  return (
    <div>
      <AuthWrapper type="ACTION" name="CONFIGURATION_SOURCE_ADD">
        <Grid container justifyContent="flex-start" className="my-3 px-3">
          <Button variant="contained" onClick={() => setIsOpenAddDialog(true)}>
            {" "}
            Add new source{" "}
          </Button>
        </Grid>
      </AuthWrapper>
      <AuthWrapper type="ACTION" name="CONFIGURATION_SOURCE_LIST">
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </AuthWrapper>
      {isOpenAddDialog && (
        <AddSource
          onClose={() => setIsOpenAddDialog(false)}
          configDataId={configDataId}
          add={add}
        />
      )}
      {addInfo?.data && <Toster info={addInfo} />}
    </div>
  );
};

export default SourceListing;
