import React, { useEffect, useState } from "react";
import {
  useDeleteConfigDataMutation,
  useGetAllConfigDataQuery,
  useUpdateConfigDataMutation,
} from "../../../../../../services/configuration/ConfigDataServices";
import EditDelete from "../../../EditDelete";
import Toster from "../../../Toster";
import EditSource from "../edit/EditSource";
import SourceListing from "./SourceListing";
import AuthWrapper from "../../../../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../../../../utils/getFilterFunction";

const columns = [
  {
    id: "source_name",
    label: "Source Name",
    minWidth: 50,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "order_rank",
    label: "Order",
    minWidth: 50,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 50,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
    authName: ["CONFIGURATION_SOURCE_DELETE", "CONFIGURATION_SOURCE_EDIT"],

  },
];

const SourceListingWrapper = () => {
  const [rows, setRows] = useState([]);
  const [configDataId, setConfigDataId] = useState("");
  const [getId, setGetId] = useState("");
  const [editDialog, setEditDialog] = useState(false);
  const [editData, setEditData] = useState("");

  const { data, isFetching, isLoading } = useGetAllConfigDataQuery("source");
  const [deleteConfigData, deleteConfigDataInfo] =
    useDeleteConfigDataMutation();

  const [update, updateInfo] = useUpdateConfigDataMutation();

  useEffect(() => {
    deleteConfigData({
      id: configDataId,
      body: {
        action: "DELETE",
        data: {
          _id: getId,
        },
      },
    });
  }, [getId]);

  const createData = (source_name, order_rank, actions) => {
    return { source_name, order_rank, actions };
  };

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setRows(
        data?.data[0]?.configurationValues?.map((source, index) => {
          return createData(
            source.value_name,
            source.order_rank,
            <div>
              <AuthWrapper type="ACTION" name="CONFIGURATION_SOURCE_DELETE">
                <EditDelete
                  type="delete"
                  setGetid={setGetId}
                  id={source?._id}
                />
              </AuthWrapper>
              <AuthWrapper type="ACTION" name="CONFIGURATION_SOURCE_EDIT">
                <EditDelete
                  type="edit"
                  setEditDialog={setEditDialog}
                  setData={setEditData}
                  data={source}
                />
              </AuthWrapper>
            </div>
          );
        }) || []
      );

      setConfigDataId(data?.data[0]?._id);
    }
  }, [isFetching, isLoading, data]);

  return (
    <>
      <SourceListing
        columns={getFilterdColumns(columns)}
        rows={rows}
        configDataId={configDataId}
      />
      {editDialog && (
        <EditSource
          editData={editData}
          onClose={() => setEditDialog(false)}
          configDataId={configDataId}
          update={update}
        />
      )}

      {deleteConfigDataInfo?.data && <Toster info={deleteConfigDataInfo} />}
      {updateInfo?.data && <Toster info={updateInfo} />}
    </>
  );
};

export default SourceListingWrapper;
