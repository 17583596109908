import React, { useState, useEffect, useCallback } from "react";
import Toster from "../Toster";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {
  useGetAllSldUserMutation,
  useGetAallFacultieNamesQuery,
  useGetAllUsersQuery,
  useGetAllExpenseCategoryQuery,
  useAddExpenseDetailsMutation,
  useUpdateExpenseDetailsMutation
} from "../../../../services/services";
import {
  Grid,
  Typography,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { string } from "yup/lib/locale";

let initialValues;

export default function AddExpenseForm(props) {

  props.formType == "edit"
    ? (initialValues = {
      title: props.data.title,
      note: props.data.note,
      expensed_by_type: props.data.expensed_by_type,
      expensed_by_id: props.data.expensed_by_id,
      expensed_by_name: props.data.expensed_by_name,
      expensed_date: props.data.expensed_date,
      expensed_amount: props.data.expensed_amount,
      payment_mode: props.data.payment_mode,
      expensed_on_type: props.data.expensed_on_type,
      expensed_on_id: props.data.expensed_on_id,
      expensed_on_name: props.data.expensed_on_name,
      category_id: props.data.category_id,
    })
    : (initialValues = {
      title: "",
      note: "",
      expensed_by_type: "",
      expensed_by_id: "",
      expensed_by_name: "",
      expensed_date: "",
      expensed_amount: "",
      payment_mode: "",
      expensed_on_type: "",
      expensed_on_id: "",
      expensed_on_name: "",
      category_id: "",
    });



  const validationSchema = yup.object({
    title: yup.string().required("Required !"),
    note: yup.string().required("Required !"),
    expensed_by_type: yup.string().required("Required !"),
    expensed_by_name: yup.string().when("expensed_by_type", {
      is: (expensed_by_type) => expensed_by_type !== "ADMIN",
      then: yup.string().required("Required !"),
    }),
    expensed_date: yup.string().required("Required !"),
    expensed_amount: yup.number().required("Required !"),
    payment_mode: yup.string().required("Required !"),
    expensed_on_type: yup.string().required("Required !"),
    expensed_on_name: yup.string().when("expensed_on_type", {
      is: (expensed_on_type) => expensed_on_type !== "ADMIN",
      then: yup.string().required("Required !"),
    }),
    category_id: yup.string().required("Required !"),
  });

  const [getAllSldUser, getAllSldUserInfo] = useGetAllSldUserMutation();
  const getAllFaculties = useGetAallFacultieNamesQuery();
  const getAllWebUsers = useGetAllUsersQuery({
    limit: "",
    query: "",
    params: [
      "first_name", "last_name", "type", "mobile", "email"
    ],
    page: "",
    filterBy: {
      fieldName: "type",
      value: "STUDENT"

    }
  });
  const getAllWebFranchise = useGetAllUsersQuery({
    limit: "",
    query: "",
    params: [
      "first_name", "last_name", "type", "mobile", "email"
    ],
    page: "",
    filterBy: {
      fieldName: "type",
      value: "FRANCHISE"

    }
  });
  const getAllExpensecategories = useGetAllExpenseCategoryQuery();
  const [addExpense, addExpenseInfo] = useAddExpenseDetailsMutation();
  const [updateExpense, updateExpenseInfo] = useUpdateExpenseDetailsMutation();
  const allSldUser = getAllSldUserInfo.data;
  const allFaculties = getAllFaculties.data;
  const allWebUsers = getAllWebUsers.data;
  const allWebFranchise = getAllWebFranchise.data;

  const allExpenseCategories = getAllExpensecategories.data;


    

  useEffect(() => {
    getAllSldUser({
      limit: "",
      query: "",
      params: ["name"],
      page: "",
    });
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const expenseByDetails = values.expensed_by_name.split("||");
      values.expensed_by_id = expenseByDetails[1];
      values.expensed_by_name = expenseByDetails[0];
      const expenseOnDetails = values.expensed_on_name.split("||");
      values.expensed_on_id = expenseOnDetails[1];
      values.expensed_on_name = expenseOnDetails[0];


      props.formType != "edit" ? addExpense(values) : updateExpense({ body: values, id: props.data._id });
    },
  });

    

  const dropDownsData = (type) => {
    switch (type) {
      case "SLD_USER":
        return (
          allSldUser &&
          allSldUser.data.map((ele) => {
            return (
              <MenuItem value={`${ele.name}||${ele._id}`}> {ele.name}</MenuItem>
            );
          })
        );
        break;

      case "FACULTY":
        return (
          allFaculties &&
          allFaculties.data.map((ele) => {
            return (
              <MenuItem value={`${ele.faculty_name}||${ele._id}`}>
                {" "}
                {ele.faculty_name}
              </MenuItem>
            );
          })
        );

      case "WEB_USER":
        return (
          allWebUsers &&
          allWebUsers.data.map((ele) => {
            return (
              <MenuItem value={`${ele.first_name + ele.last_name}||${ele._id}`}>
                {" "}
                {`${ele.first_name} ${ele.last_name}`}
              </MenuItem>
            );
          })
        );

      case "WEB_FRANCHISE":
        return (
          allWebFranchise &&
          allWebFranchise.data.map((ele) => {
            return (
              <MenuItem value={`${ele.first_name + ele.last_name}||${ele._id}`}>
                {" "}
                {`${ele.first_name} ${ele.last_name}`}
              </MenuItem>
            );
          })
        );

      default:
        break;
    }
  };


  addExpenseInfo && props.setAddExpenseInfo(addExpenseInfo)
  updateExpenseInfo && props.setUpdateExpenseInfo(updateExpenseInfo)

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 20,
            marginLeft: 10,
          }}
        >
          <Typography variant="h6" color="primary" gutterBottom>
            Expense Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                name="title"
                label="Expense Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                fullWidth={true}
                variant="outlined"
                size="small"
              />
              {formik.touched.title && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.title}
                </span>
              )}
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="note"
                label="Note"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.note}
                fullWidth={true}
                variant="outlined"
                size="small"
              />
              {formik.touched.note && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.note}
                </span>
              )}
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="date"
                name="expensed_date"
                // label="Expense Date "
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                value={formik.values.expensed_date}
                fullWidth={true}
                variant="outlined"
                size="small"
              />
              {formik.touched.expensed_date && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.expensed_date}
                </span>
              )}
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="category">Category</InputLabel>
                <Select
                  name="category_id"
                  labelId="category"
                  id="category"
                  value={formik.values.category_id}
                  label="category"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {allExpenseCategories &&
                    allExpenseCategories.data.map((ele) => {
                      return (
                        <MenuItem value={ele._id}>{ele.category_name}</MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              {formik.touched.category_id && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.category_id}
                </span>
              )}
            </Grid>

            <Grid item xs={4}>
              <TextField
                type="number"
                name="expensed_amount"
                label="Amount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.expensed_amount}
                fullWidth={true}
                variant="outlined"
                size="small"
              />
              {formik.touched.expensed_amount && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.expensed_amount}
                </span>
              )}
            </Grid>

            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="paymentMode">Payment Mode</InputLabel>
                <Select
                  name="payment_mode"
                  labelId="paymentMode"
                  id="paymentMode"
                  value={formik.values.payment_mode}
                  label="paymentMode"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="Online">Online</MenuItem>
                </Select>
              </FormControl>
              {formik.touched.payment_mode && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.payment_mode}
                </span>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>  EXPENSED ON DETAILS SECTION   <<<<<<<<<<<<<<<<<<<<<<<<< */}

        <hr />
        <Grid
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 20,
            marginLeft: 10,
          }}
        >
          <Typography variant="h6" color="primary" gutterBottom>
            Expensed On
          </Typography>

          <Grid container spacing={3}>
            {/*>>>>>>>>>>>>>>>>>>>>>>>>>>  EXPENSED ON TYPE INPUT FIELD  <<<<<<<<<<<<<<<<<<<<<<<< */}

            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="role">Role</InputLabel>
                <Select
                  name="expensed_on_type"
                  labelId="role"
                  id="role"
                  value={formik.values.expensed_on_type}
                  label="role"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={"SLD_USER"}>SLD User</MenuItem>
                  <MenuItem value={"FACULTY"}>Faculty</MenuItem>
                  <MenuItem value={"WEB_USER"}>Web User</MenuItem>
                  <MenuItem value={"WEB_FRANCHISE"}>Web Franchise</MenuItem>
                  <MenuItem value={"OFFLINE_USER"}>Offline User</MenuItem>
                  <MenuItem value={"OFFICIAL"}>Officials</MenuItem>
                  <MenuItem value={"OTHERS"}>Others </MenuItem>
                  <MenuItem value={"ADMIN"}>Admin</MenuItem>
                </Select>
              </FormControl>
              {formik.touched.expensed_on_type && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.expensed_on_type}
                </span>
              )}
            </Grid>

            {/*>>>>>>>>>>> EXPENSED ON NAME INPUT FIELD "BASED ON SELECTED EXPENSED ON TYPE <<<<<<<<<<<<<<< */}

            {formik.values.expensed_on_type !== "ADMIN" &&
              (formik.values.expensed_on_type !== "OFFLINE_USER" &&
                formik.values.expensed_on_type !== "OFFICIAL" &&
                formik.values.expensed_on_type !== "OTHERS" ? (
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="expense-on-name">Name</InputLabel>
                    <Select
                      name="expensed_on_name"
                      labelId="expense-on-name"
                      id="expense-on-name"
                      value={formik.values.expensed_on_name}
                      label="expense-on-name"
                      onChange={formik.handleChange}
                    >
                      {dropDownsData(formik.values.expensed_on_type)}
                    </Select>
                  </FormControl>
                  {formik.touched.expensed_on_name && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.expensed_on_name}
                    </span>
                  )}
                </Grid>
              ) : (
                <Grid item xs={4}>
                  <TextField
                    name="expensed_on_name"
                    label="Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.expensed_on_name}
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                  />
                  {formik.touched.expensed_on_name && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.expensed_on_name}
                    </span>
                  )}
                </Grid>
              ))}
          </Grid>
        </Grid>

        {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  EXPENSED BY DETAILS SECTION   <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */}

        <Grid
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 20,
            marginLeft: 10,
          }}
        >
          <Typography variant="h6" color="primary" gutterBottom>
            Expensed By
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="role">Role</InputLabel>
                <Select
                  name="expensed_by_type"
                  labelId="role"
                  id="role"
                  value={formik.values.expensed_by_type}
                  label="role"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={"ADMIN"}>Admin</MenuItem>
                  <MenuItem value={"SLD_USER"}>SLD User</MenuItem>
                  <MenuItem value={"OTHERS"}>Others</MenuItem>
                </Select>
              </FormControl>
              {formik.touched.expensed_by_type && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.expensed_by_type}
                </span>
              )}
            </Grid>
            {formik.values.expensed_by_type !== "ADMIN" &&
              (formik.values.expensed_by_type != "OTHERS" ? (
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="expense-by-name">Name</InputLabel>
                    <Select
                      name="expensed_by_name"
                      labelId="expense-by-name"
                      id="expense-by-name"
                      value={formik.values.expensed_by_name}
                      label="expense-by-name"
                      onChange={formik.handleChange}
                    >
                      {dropDownsData(formik.values.expensed_by_type)}
                    </Select>
                  </FormControl>
                  {formik.touched.expensed_by_name && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.expensed_by_name}
                    </span>
                  )}
                </Grid>
              ) : (
                <Grid item xs={4}>
                  <TextField
                    name="expensed_by_name"
                    label="Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.expensed_by_name}
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                  />
                  {formik.touched.expensed_by_name && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.expensed_by_name}
                    </span>
                  )}
                </Grid>
              ))}
          </Grid>
        </Grid>

        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              props.formType != "edit" ?
                props.setAddDialog(false) :
                props.setEditDialog(false)
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {props.formType == "edit" ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </form>

    </div>
  );
}
