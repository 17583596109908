import React from 'react'
import { Button, Grid, Box, makeStyles, withStyles, Typography, } from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: 18,
        flexBasis: '33.33%',
        flexShrink: 0,

    },
    table: {
        padding: 10,
        margin: 5

    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));
export default function ViewCourier({ viewDialog, data }) {
    const classes = useStyles();
    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);
    return (
        <div>
            <div class="card">
                <div class="card-body">
                    <Grid container >
                        <Grid xs={12} className=' my-1'>
                            <Button
                                onClick={() => viewDialog(false)}
                                className="text-light bg-danger float-right btn btn-sm btn-danger">
                                Close
                            </Button>
                        </Grid>
                        <Grid xs={6} className='my-2'>
                            <TableContainer style={{ maxWidth: "99%" }} className="m-2 border">
                                <Table className={classes.table} aria-label="customized table">
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell className="m-1 font-weight-bold text-info border-info" align="left">
                                              Sld Order Id
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1" align="left"> {data.sld_order_id} </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell className="m-1 font-weight-bold text-info border-info" align="left">
                                            Order Details
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1" align="left"> {data.order_details[0].order_title} </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell className="m-1 font-weight-bold text-info border-info" align="left">
                                            Order Status
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1" align="left"> {data.order_details[0].payment_status} </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell className="m-1 font-weight-bold text-info border-info" align="left">
                                            Tracking Id  
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1" align="left">
                                            {data.tracking_id}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell className="m-1 font-weight-bold text-info border-info" align="left">
                                            Tracking Status
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1" align="left"> {data.tracking_status[0].tracking_status} </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell className="m-1 font-weight-bold text-info border-info" align="left">
                                            Tracking Description
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1" align="left"> {data.tracking_status[0].tracking_description} </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell className="m-1 font-weight-bold text-info border-info" align="left">
                                            Tracking support number
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1" align="left"> {data.tracking_support_number} </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell className="m-1 font-weight-bold text-info border-info" align="left">
                                                Tracking Url
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1" align="left"> {data.tracking_url} </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell className="m-1 font-weight-bold text-info border-info" align="left">
                                                Tracking email
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1" align="left"> {data.tracking_email} </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid xs={6} className='my-2'>
                            <TableContainer style={{ maxWidth: "99%" }} className="m-2 border">
                                <Table className={classes.table} aria-label="customized table">
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell className="m-1 font-weight-bold text-info border-info" align="left">
                                            Product Type
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1" align="left"> {data.order_details[0].product_type} </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell className="m-1 font-weight-bold text-info border-info" align="left">
                                            Courier Company
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1" align="left"> {data.courier_company} </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell className="m-1 font-weight-bold text-info border-info" align="left">
                                                Courier Details
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1" align="left"> {data.courier_details} </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell className="m-1 font-weight-bold text-info border-info" align="left">
                                                Courier Weight
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1" align="left"> {data.courier_weight} KG</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell className="m-1 font-weight-bold text-info border-info" align="left">
                                            Paid Amount
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1" align="left">₹ {data.courier_amount_paid} </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell className="m-1 font-weight-bold text-info border-info" align="left">
                                            Courier Date
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1" align="left"> {data.courier_date?moment(data.courier_date).format("DD-MM-YYYY hh:mm:ss A"):""} </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell className="m-1 font-weight-bold text-info border-info" align="left">
                                                Current Status
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1" align="left">
                                            {data.current_status}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell className="m-1 font-weight-bold text-info border-info" align="left">
                                                Created By
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1" align="left">
                                            {data.created_by_type}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell className="m-1 font-weight-bold text-info border-info" align="left">
                                                Current Status
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1" align="left">
                                            {data.current_status}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid xs={12} className='my-3'>
                            <Typography className='mx-3' variant='h6' >Status Flow</Typography>
                            <TableContainer style={{ maxWidth: "99%" }} className="m-2 border">
                                <Table className={classes.table} aria-label="customized table">
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell className="m-1 font-weight-bold text-light bg-info" align="center">
                                             Tracking Status
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1 font-weight-bold text-light bg-info" align="center"> 
                                            Tracking Description
                                            </StyledTableCell>
                                            <StyledTableCell className="m-1 font-weight-bold text-light bg-info" align="center"> 
                                            Date
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        {
                                            data.tracking_status.map((ele,ind)=>{
                                               return <StyledTableRow>
                                                            <StyledTableCell className="m-1" align="center">
                                                                {ele.tracking_status}
                                                            </StyledTableCell>
                                                            <StyledTableCell className="m-1" align="center">
                                                            {ele.tracking_description}
                                                            </StyledTableCell>
                                                            <StyledTableCell className="m-1" align="center">
                                                            {ele.createdAt?moment(ele.createdAt).format("DD-MM-YYYY hh:mm:ss A"):""}
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                            })
                                        }
                                        
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

