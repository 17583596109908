// **** React Imports
import React, { useEffect, useState } from "react";

// **** Formik
import { useFormik } from "formik";

// **** Material Imports
import {
    Backdrop,
    Button,
    CircularProgress,
    Divider,
    Grid,
    Paper,
    TextField,
    Typography,
} from "@mui/material";

// **** Services Imports
import { useGetAllCurrenciesQuery } from "../../../services/offlineModuleServices/currencyServices";
import { useGetAllPaymentModesQuery } from "../../../services/offlineModuleServices/paymentModeServices";
import { useGetCashDataForValidationQuery } from "../../../services/offlineModuleServices/cashCollectionServices";
import { useGetAllCenterQuery } from "../../../services/offlineModuleServices/centersServices";
import { useAddPendriveOrderMutation, useAddSemiVirtualOrderMutation, useAddVirtualOrderMutation, useUpdateOfflineOrderMutation } from "../../../services/offlineModuleServices/offlineOrdersServices";

// **** Custom File Imports
import SkeletonListing from "./SkeletonListing";
import Toster from "./Toster";
import OfflineOrderInvoice from "./offlineOrders/OfflineOrderInvoice";
import PendriveOrderInvoice from "./offlineOrders/offline-invoice/PendriveOrderInvoice";

// **** Main Function Start
function AddCurrencyDetailsForm(props) {
    // **** Destructuring Props
    const { onClose, paidFees, data, orderFor, id, updateOrder, updateOrderInfo } = props;
      

    // **** States
    const [totalReceivedAmount, setTotalReceivedAmount] = useState(0);
    const [totalReturnedAmount, setTotalReturnedAmount] = useState(0);
    const [defaultCenter, setDefaultCenter] = useState("")
    const [viewInvoice, setViewInvoice] = useState(false);
    const [invoiceData, setInvoiceData] = useState("");
    const [loading, setLoading] = useState(false)


    // **** Services
    const {
        data: AllCurrencyNotes,
        isFetching: isAllCurrencyNotesFetching,
        isLoading: isAllCurrencyNotesLoading,
    } = useGetAllCurrenciesQuery();
    const {
        data: AllPaymentModes,
        isFetching: isAllPaymentModesFetching,
        isLoading: isAllPaymentModesLoading,
    } = useGetAllPaymentModesQuery();
    const {
        data: AllCenters,
        isFetching: isAllCentersFetching,
        isLoading: isAllCentersLoading,
    } = useGetAllCenterQuery(defaultCenter?._id);
    const [addVirtualOrder, addVirtualOrderInfo] = useAddVirtualOrderMutation();
    const [addSemiVirtualOrder, addSemiVirtualOrderInfo] = useAddSemiVirtualOrderMutation();
      
    const [addPendriveOrder, addPendriveOrderInfo] = useAddPendriveOrderMutation();


    useEffect(() => {
        setDefaultCenter(AllCenters?.data.find((center) => center.defaultOnInvoice))
    }, [AllCenters])

    useEffect(() => {
        if (updateOrderInfo) {

            if (updateOrderInfo?.status != "uninitialized") {
                setLoading(true)
            }
            if (updateOrderInfo?.status == "fulfilled") {
                setLoading(false)
            }
        }
        if (addVirtualOrderInfo) {

            if (addVirtualOrderInfo?.status != "uninitialized") {
                setLoading(true)
            }
            if (addVirtualOrderInfo?.status == "fulfilled") {
                setLoading(false)
            }
        }
        if (addSemiVirtualOrderInfo) {

            if (addSemiVirtualOrderInfo?.status != "uninitialized") {
                setLoading(true)
            }
            if (addSemiVirtualOrderInfo?.status == "fulfilled") {
                setLoading(false)
            }
        }
        if (addPendriveOrderInfo) {

            if (addPendriveOrderInfo?.status != "uninitialized") {
                setLoading(true)
            }
            if (addPendriveOrderInfo?.status == "fulfilled") {
                setLoading(false)
            }
        }
    }, [updateOrderInfo, addVirtualOrderInfo, addSemiVirtualOrderInfo, addPendriveOrderInfo])

    const {
        data: AvailableCashData,
        isFetching: isAvailableCashDataFetching,
        isLoading: isAvailableCashDataLoading,
    } = useGetCashDataForValidationQuery();

    const quantityAvailable = (currencyName) => {
        let availableQty = 0;
        AvailableCashData?.data.forEach((ele) => {
            if (ele.currency_note_name == currencyName) {
                availableQty = ele.available_note_count;
            }
        });

        return availableQty;
    };

    // **** Form Initial Values
    const initialValues = {
        depositCashNoteData: [],
        depositOnlineModeData: [],
        withdrawalCashNoteData: [],
        withdrawalOnlineModeData: [],

        balance_amount: "",
        transaction_type: "Deposite",
        payment_mode: [],
        added_on_date: "",
        remark: "",
        deposite_amount: "",
        withdraw_amount: "",
        exchange_amount: "",
        center_id: "",
        deposite_cash_data: [],
        withdraw_cash_data: [],
    };

    // **** On Submit Handler
    const onSubmitHandler = (values) => {
        values = {
            ...values,
            deposite_cash_data: [
                ...values.depositCashNoteData,
                ...values.depositOnlineModeData,
            ],
            withdraw_cash_data: [
                ...values.withdrawalCashNoteData,
                ...values.withdrawalOnlineModeData,
            ],
        };

        values = {
            ...values,
            deposite_cash_data: values.deposite_cash_data.filter(
                (ele) => ele && (parseInt(ele.quantity) || parseInt(ele.currency_note_value))
            ),
            withdraw_cash_data: values.withdraw_cash_data.filter(
                (ele) => ele && parseInt(ele.quantity)
            ),
        };

        let tempMode = []

        if (values.deposite_cash_data.some((ele) => ele?.payment_mode == "CASH")) {
            tempMode.push("CASH")
        }

        if (values.deposite_cash_data.some((ele) => ele?.payment_mode == "ONLINE")) {
            tempMode.push("ONLINE")
        }

        values = {
            ...values,
            balance_amount: paidFees,
            payment_mode: tempMode,
            added_on_date: new Date(),
            remark: `Order Amount of Rs. ${paidFees} is Received `,
            deposite_amount: (totalReceivedAmount + ""),
            withdraw_amount: (totalReturnedAmount + ""),
            center_id: defaultCenter._id,
        }

        delete values.depositCashNoteData
        delete values.depositOnlineModeData
        delete values.withdrawalCashNoteData
        delete values.withdrawalOnlineModeData

        let formData = new FormData();

        for (let key in data) {
            if (key == "batch_details" || key == "combo_with") continue;

            formData.append(key, props.data[key]);
        }

        formData.append("cashCollectionData", JSON.stringify(values));

        switch (orderFor) {
            case "virtual_class":
                addVirtualOrder(formData);
                break;

            case "semi_virtual_class":
                addSemiVirtualOrder(formData);
                break;
            case "pendrive_class":
                addPendriveOrder(formData);
                break;
            case "update_fees":
                updateOrder({
                    body: {
                        fee_paid: paidFees,
                        cashCollectionData: values
                    },
                    id: id
                });
                break;

            default:
                break;
        }

    };

    // **** Formik
    let formik = useFormik({
        initialValues: initialValues,
        onSubmit: onSubmitHandler,
    });

    const { handleSubmit, values, setFieldValue } = formik;

    // **** Use Effect
    useEffect(() => {
        setTotalReceivedAmount(
            values.depositOnlineModeData?.reduce(
                (acc, cv) => acc + (cv ? parseInt(cv.currency_note_value) || 0 : 0),
                0
            ) +
            values.depositCashNoteData?.reduce(
                (acc, cv) =>
                    acc +
                    (cv ? (parseInt(cv.quantity) || 0) * cv.currency_note_value : 0),
                0
            )
        );
    }, [values.depositCashNoteData, values.depositOnlineModeData]);

    // **** Use Effect For Total Returned Amount
    useEffect(() => {
        setTotalReturnedAmount(

            values.withdrawalCashNoteData?.reduce(
                (acc, cv) =>
                    acc +
                    (cv ? (parseInt(cv.quantity) || 0) * cv.currency_note_value : 0),
                0
            )
        );
    }, [values.withdrawalCashNoteData, values.withdrawalOnlineModeData]);

    return (
        <div>
            {isAllCurrencyNotesFetching ||
                isAllCurrencyNotesLoading ||
                isAllPaymentModesFetching ||
                isAllPaymentModesLoading ||
                isAvailableCashDataFetching ||
                isAvailableCashDataLoading ||
                isAllCentersFetching ||
                isAllCentersLoading ? (
                <SkeletonListing />
            ) : (
                !viewInvoice ?
                    <form onSubmit={handleSubmit}>
                        <Grid container justifyContent="center">
                            <Grid container item xs={11.9} mt={2}>
                                <Paper elevation={10} sx={{ width: "100%" }}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h5"
                                            bgcolor="#5664d2"
                                            color="white"
                                            sx={{ p: 2, borderBottomRightRadius: "20px" }}
                                        >
                                            {" "}
                                            Add Note Details{" "}
                                        </Typography>
                                    </Grid>

                                    {/* Received Amount Details */}
                                    <Grid container>
                                        {/* Heading */}
                                        <Grid item xs={12} p={2}>
                                            <Typography variant="h6">
                                                {" "}
                                                Received Amount Details{" "}
                                            </Typography>
                                        </Grid>

                                        {/* Receivable Amount */}
                                        <Grid container item xs={12} justifyContent="flex-end" px={3}>
                                            <Typography component={"em"}>
                                                {" "}
                                                Receivable Amount : &nbsp;{" "}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                color="primary"
                                                fontWeight={900}
                                            >
                                                {" "}
                                                &#8377; {paidFees}{" "}
                                            </Typography>
                                        </Grid>

                                        {/* Total Received Amount  */}
                                        <Grid container item xs={12} justifyContent="flex-end" px={3}>
                                            <Typography component={"em"}>
                                                {" "}
                                                Total Received Amount : &nbsp;{" "}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="text-success"
                                                fontWeight={900}
                                            >
                                                {" "}
                                                &#8377; {totalReceivedAmount}{" "}
                                            </Typography>
                                        </Grid>

                                        {/* Currency Details */}

                                        {/* Cash  */}
                                        <Grid container spacing={4} p={2} mt={3.5}>
                                            {AllCurrencyNotes?.data?.map((currencyNote, index) => (
                                                <>
                                                    <Grid
                                                        key={currencyNote._id}
                                                        container
                                                        item
                                                        md={4}
                                                        sm={6}
                                                        xs={12}
                                                        spacing={2}
                                                    >
                                                        <Grid item xs={4}>
                                                            {currencyNote.currency_note}
                                                        </Grid>

                                                        <Grid item xs={4}>
                                                            <TextField
                                                                value={
                                                                    values.depositCashNoteData[index]?.quantity
                                                                }
                                                                onChange={(e) => {
                                                                    setFieldValue(`depositCashNoteData[${index}]`, {
                                                                        currency_note_name:
                                                                            currencyNote.currency_note,
                                                                        currency_note_value: currencyNote.value,
                                                                        payment_mode: "CASH",
                                                                        quantity: e.target.value,
                                                                    });
                                                                }}
                                                                size="small"
                                                                fullWidth
                                                                label="Count"
                                                                type="number"
                                                            />
                                                        </Grid>

                                                        <Grid
                                                            textAlign="center"
                                                            item
                                                            xs={4}
                                                            borderRight={
                                                                index != AllCurrencyNotes?.data?.length - 1 &&
                                                                "1px solid gray"
                                                            }
                                                        >
                                                            <span className="text-success">
                                                                &#8377;{" "}
                                                                {parseInt(
                                                                    values.depositCashNoteData[index]?.quantity
                                                                ) *
                                                                    parseInt(
                                                                        values.depositCashNoteData[index]
                                                                            ?.currency_note_value
                                                                    ) || 0}
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            ))}
                                        </Grid>

                                        {/* Payment Mode */}
                                        <Grid container spacing={4} p={2}>
                                            {AllPaymentModes?.data?.map((paymentMode, index) => (
                                                <>
                                                    <Grid
                                                        key={paymentMode._id}
                                                        container
                                                        item
                                                        md={4}
                                                        sm={6}
                                                        xs={12}
                                                        spacing={2}
                                                    >
                                                        <Grid item xs={4}>
                                                            {paymentMode.UPI_ID_ACCOUNT_NO}
                                                        </Grid>

                                                        <Grid item xs={4}>
                                                            <TextField
                                                                value={
                                                                    values.depositOnlineModeData[index]?.currency_note_value
                                                                }
                                                                onChange={(e) => {
                                                                    setFieldValue(
                                                                        `depositOnlineModeData[${index}]`,
                                                                        {
                                                                            currency_note_name:
                                                                                paymentMode.UPI_ID_ACCOUNT_NO,
                                                                            currency_note_value: e.target.value,
                                                                            payment_mode: "ONLINE",
                                                                            quantity: 0,
                                                                        }
                                                                    );
                                                                }}
                                                                size="small"
                                                                fullWidth
                                                                label="Amount"
                                                                type="number"
                                                            />
                                                        </Grid>

                                                        <Grid
                                                            textAlign="center"
                                                            item
                                                            xs={4}
                                                            borderRight={
                                                                index != AllPaymentModes?.data?.length - 1 &&
                                                                "1px solid gray"
                                                            }
                                                        >
                                                            <span className="text-success">
                                                                &#8377;{" "}
                                                                {values.depositOnlineModeData[index]?.currency_note_value || 0}
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            ))}
                                        </Grid>
                                    </Grid>

                                    <Grid m={2}>
                                        <Divider />
                                    </Grid>

                                    {/* Returned Amount Details */}
                                    {totalReceivedAmount > paidFees && (
                                        <Grid container>
                                            {/* Alert Message When Returned amount is More Than Returnable Amount */}
                                            {totalReturnedAmount > totalReceivedAmount - paidFees && (
                                                <Grid item xs={12}>
                                                    <Typography color="error" textAlign="center">
                                                        {" "}
                                                        You are returning{" "}
                                                        <Typography component="span" fontSize={20}>
                                                            {" "}
                                                            &#8377;{" "}
                                                            {totalReturnedAmount -
                                                                (totalReceivedAmount - paidFees)}{" "}
                                                        </Typography>{" "}
                                                        more{" "}
                                                    </Typography>
                                                </Grid>
                                            )}

                                            {/* Heading */}
                                            <Grid item xs={12} p={2}>
                                                <Typography variant="h6">
                                                    {" "}
                                                    Returned Amount Details{" "}
                                                </Typography>
                                            </Grid>

                                            {/* Returnable Amount */}
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                justifyContent="flex-end"
                                                px={3}
                                            >
                                                <Typography component={"em"}>
                                                    {" "}
                                                    Returnable Amount : &nbsp;{" "}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    color="primary"
                                                    fontWeight={900}
                                                >
                                                    {" "}
                                                    &#8377; {totalReceivedAmount - paidFees}{" "}
                                                </Typography>
                                            </Grid>

                                            {/* Total Returned Amount  */}
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                justifyContent="flex-end"
                                                px={3}
                                            >
                                                <Typography component={"em"}>
                                                    {" "}
                                                    Total Returned Amount : &nbsp;{" "}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    className="text-success"
                                                    fontWeight={900}
                                                >
                                                    {" "}
                                                    &#8377; {totalReturnedAmount}{" "}
                                                </Typography>
                                            </Grid>

                                            {/* Currency Details */}

                                            {/* Cash  */}
                                            <Grid container spacing={4} p={2} mt={3.5}>
                                                {AllCurrencyNotes?.data?.map((currencyNote, index) => (
                                                    <>
                                                        <Grid
                                                            key={currencyNote._id}
                                                            container
                                                            item
                                                            md={4}
                                                            sm={6}
                                                            xs={12}
                                                            spacing={2}
                                                        >
                                                            <Grid item xs={4}>
                                                                {currencyNote.currency_note}
                                                            </Grid>

                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    value={
                                                                        values.withdrawalCashNoteData[index]?.quantity
                                                                    }
                                                                    onChange={(e) => {
                                                                        setFieldValue(
                                                                            `withdrawalCashNoteData[${index}]`,
                                                                            {
                                                                                currency_note_name:
                                                                                    currencyNote.currency_note,
                                                                                currency_note_value: currencyNote.value,
                                                                                payment_mode: "CASH",
                                                                                quantity: e.target.value,
                                                                            }
                                                                        );
                                                                    }}
                                                                    size="small"
                                                                    fullWidth
                                                                    label="Count"
                                                                    type="number"
                                                                />

                                                                <Grid container>
                                                                    <Typography> Available : </Typography>
                                                                    <Typography> {quantityAvailable(currencyNote.currency_note)}  </Typography>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid
                                                                textAlign="center"
                                                                item
                                                                xs={4}
                                                                borderRight={
                                                                    index != AllCurrencyNotes?.data?.length - 1 &&
                                                                    "1px solid gray"
                                                                }
                                                            >
                                                                <span className="text-success">
                                                                    &#8377;{" "}
                                                                    {parseInt(
                                                                        values.withdrawalCashNoteData[index]?.quantity
                                                                    ) *
                                                                        parseInt(
                                                                            values.withdrawalCashNoteData[index]
                                                                                ?.currency_note_value
                                                                        ) || 0}
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                ))}
                                            </Grid>

                                            {/* Payment Mode */}
                                            {/* <Grid container spacing={4} p={2}>
                      {AllPaymentModes?.data?.map((paymentMode, index) => (
                        <>
                          <Grid
                            key={paymentMode._id}
                            container
                            item
                            md={4}
                            sm={6}
                            xs={12}
                            spacing={2}
                          >
                            <Grid item xs={4}>
                              {paymentMode.UPI_ID_ACCOUNT_NO}
                            </Grid>

                            <Grid item xs={4}>
                              <TextField
                                value={
                                  values.withdrawalOnlineModeData[index]
                                    ?.quantity
                                }
                                onChange={(e) => {
                                  setFieldValue(
                                    `withdrawalOnlineModeData[${index}]`,
                                    {
                                      currency_note_name:
                                        paymentMode.UPI_ID_ACCOUNT_NO,
                                      currency_note_value: 0,
                                      payment_mode: "ONLINE",
                                      quantity: e.target.value,
                                    }
                                  );
                                }}
                                size="small"
                                fullWidth
                                label="Amount"
                                type="number"
                              />

                              <Grid container>
                                <Typography> Available : </Typography>
                                <Typography> 0 </Typography>
                              </Grid>
                            </Grid>

                            <Grid
                              textAlign="center"
                              item
                              xs={4}
                              borderRight={
                                index != AllPaymentModes?.data?.length - 1 &&
                                "1px solid gray"
                              }
                            >
                              <span className="text-success">
                                &#8377;{" "}
                                {values.withdrawalOnlineModeData[index]
                                  ?.quantity || 0}
                              </span>
                            </Grid>
                          </Grid>
                        </>
                      ))}
                    </Grid> */}
                                        </Grid>
                                    )}
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        justifyContent="flex-end"
                                        p={3}
                                        spacing={3}
                                    >
                                        {/* Close Button */}
                                        <Grid item md={2} sm={4} xs={6}>
                                            <Button
                                                variant="contained"
                                                color="error"
                                                onClick={() => onClose()}
                                                fullWidth
                                            >
                                                {" "}
                                                Close
                                            </Button>
                                        </Grid>

                                        {/* Save Button */}
                                        <Grid item md={2} sm={4} xs={6}>
                                            <Button
                                                disabled={totalReceivedAmount != (parseInt(paidFees) + totalReturnedAmount)}
                                                variant="contained"
                                                sx={{ bgcolor: "#5664d2" }}
                                                type="submit"
                                                fullWidth
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </form>
                    :

                    orderFor == "virtual_class" ?
                        <OfflineOrderInvoice data={invoiceData} receiptFor="Virtual" />
                        :

                        orderFor == "semi_virtual_class" ?
                            <OfflineOrderInvoice data={invoiceData} receiptFor="Semi-Virtual" />
                            :
                            orderFor == "pendrive_class" ?
                                <PendriveOrderInvoice data={invoiceData} />
                                :
                                null


            )}
            {addVirtualOrderInfo && <Toster info={addVirtualOrderInfo} dialogclose={() => { setViewInvoice(true); setInvoiceData(addVirtualOrderInfo.data) }} />}
            {addSemiVirtualOrderInfo && <Toster info={addSemiVirtualOrderInfo} dialogclose={() => { setViewInvoice(true); setInvoiceData(addSemiVirtualOrderInfo.data) }} />}
            {addPendriveOrderInfo && <Toster info={addPendriveOrderInfo} dialogclose={() => { setViewInvoice(true); setInvoiceData(addPendriveOrderInfo.data) }} />}
            {
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                    children={"hello"}
                // onClick={handleClose}
                >
                    <CircularProgress color="inherit" /> &nbsp; &nbsp; &nbsp;
                    <Typography variant="h5" >
                        Saving...
                    </Typography>
                </Backdrop>
            }

        </div>
    );
}

export default AddCurrencyDetailsForm;
