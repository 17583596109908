import React, { useState, useEffect } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import AddDataHeader from "../AddDataHeader";
import DataNotFound from "../DataNotFound";
import FormDialog from "../reusableComponents/NewFormDialog";
import { useGetConfigQuery, useAddConfigMutation, useUpdateConfigDataMutation, useDeleteConfigDataMutation } from "../../../../services/offlineModuleServices/configServices";


export default function Hall() {

    let [getid, setGetid] = useState("");
    const [data, setData] = useState("");
    const [addDialog, setAddDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [render, setRender] = useState(false)

    const { data: AllHalls, isAllHallsFetching, isAllHallsLoading } = useGetConfigQuery({
        limit: "",
        page: "",
        key: "hall"
    })
    const [addHall, addHallInfo] = useAddConfigMutation()
    const [updateHall, updateHallInfo] = useUpdateConfigDataMutation()
    const [deleteHall, deleteHallInfo] = useDeleteConfigDataMutation()

    const columns = [
        {
            id: "SNo",
            label: "S.No",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "hallName",
            label: "Hall Name",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "actions",
            label: "Actions",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
    ];
    function createData(SNo, hallName, actions) {
        return {
            SNo,
            hallName,
            actions,
        };
    }
    let rows = [];
    const totalItem = AllHalls ? AllHalls.totalItem : 0;
    let totalData = "ok";


    useEffect(() => {
        getid && deleteHall({
            key: "hall",
            configData: {
                _id: getid
            }
        }) && setGetid("");
    }, [getid]);



    if (
        AllHalls?.status
    ) {
        totalData = AllHalls?.totalItem;
        rows = AllHalls.data.map((ele, index) => {
            return createData(
                index + 1,
                ele.hall_name,
                <div>
                    <EditDelete
                        setEditDialog={setEditDialog}
                        type="edit"
                        setData={setData}
                        data={ele}
                    />
                    <EditDelete type="delete" setGetid={setGetid} id={ele._id} />
                </div>
            );
        });
    } 


    const dialogclose = () => {
        setEditDialog(false);
        setAddDialog(false);
        setRender(!render);
    };

    const updateBtnClickHandler = (dataForUpdate) => {
        updateHall({
            key: "hall",
            configData: {
                is_active: true,
                _id: data._id,
                hall_name: dataForUpdate.hall_name
            }
        });
    };

    const addBtnClickHandler = (dataForAdd) => {
        addHall({
            key: "hall",
            configData: {
                is_active: true,
                hall_name: dataForAdd.hall_name
            }
        })
    }

    return (
        <div>
            {/* {!viewDialog && ( */}
            <AddDataHeader
                addFormName={"Add New Hall"}
                isSearch={true}
                totalItem={totalItem}
                isPagination={true}
                setAddDialog={setAddDialog}
                isButton={true}
                Adddialog={addDialog}
                form={
                    <FormDialog
                        open={addDialog}
                        setOpen={setAddDialog}
                        formName={"Add New Hall"}
                        btnName={"Save Hall"}
                        onBtnClick={addBtnClickHandler}
                        fields={[
                            {
                                fieldType: "text",
                                fieldName: "Hall Name",
                                initialValue: "",
                                //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                                accessKey: "hall_name",
                            },
                        ]}
                    />
                }
            />
            {/* )} */}


            <>
                {rows.length ? (
                    <CreateTableAddButton
                        editDialog={editDialog}
                        // setPage={setPage}
                        rows={rows}
                        fullWidth={false}
                        editform={
                            <FormDialog
                                useAs='form'
                                open={editDialog}
                                setOpen={setEditDialog}
                                formName={"Edit Expense Category "}
                                btnName={"Update"}
                                onBtnClick={updateBtnClickHandler}

                                fields={[
                                    {
                                        fieldType: "text",
                                        fieldName: "Hall Name",
                                        initialValue: data.hall_name,
                                        //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                                        accessKey: "hall_name",
                                    },
                                ]}
                            />
                        }
                        columns={columns}
                        isButton={true}
                        editFormName={"Edit Hall"}
                    />
                ) : totalData == 0 ? (
                    <DataNotFound />
                ) : (
                    <SkeletonListing />
                )}

            </>


            {addHallInfo && (
                <Toster info={addHallInfo} dialogclose={dialogclose} />
            )}

            {updateHallInfo && (
                <Toster info={updateHallInfo} dialogclose={dialogclose} />
            )}

            {deleteHallInfo && (
                <Toster info={deleteHallInfo} dialogclose={dialogclose} />
            )}

        </div>
    );
}
