import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Grid, makeStyles, withStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import CreateTable from "../CreateTableAddButton";
import EditIcon from "@mui/icons-material/Edit";
import UpdateVideoLectureSingleVarientField from "../UpdateVideoLectureSingleVarientField";
import * as Yup from "yup";
import UpdatePricesWrapper from "../VideoLecture/UpdatePrices/UpdatePricesWrapper";
import AuthWrapper from "../../../../utils/AuthWrapper";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: 18,
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  table: {
    padding: 10,
    margin: 5,
    marginBottom: 10,
    border: 1,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function VarientDetailsPage(props) {
  const { data, videoLecture_id } = props;
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openEditPricesDialog, setOpenEditPricesDialog] = useState(false);
  const [editData, setEditData] = useState("");

  const columns = [
    {
      id: "sno",
      label: "S.No",
      minWidth: 50,
      align: "center",
    },
    {
      id: "name",
      label: "Name",
      minWidth: 50,
      align: "center",
    },
    {
      id: "discountDateRangeByFaculty",
      label: "Dicount Date Range (by faculty)",
      minWidth: 100,
      align: "center",
    },
    {
      id: "discountDateRangeByUs",
      label: "Dicount Date Range (by us)",
      minWidth: 100,
      align: "center",
    },
    {
      id: "mrp",
      label: "MRP",
      minWidth: 100,
      align: "center",
    },
    {
      id: "dicountByFaculty",
      label: "Dicount By Faculty",
      minWidth: 50,
      align: "center",
    },
    {
      id: "dicountByUs",
      label: "Dicount By Us",
      minWidth: 50,
      align: "center",
    },
    {
      id: "sld50",
      label: "SLD 50",
      minWidth: 50,
      align: "center",
    },
    {
      id: "gstPercentage",
      label: "GST Percentage",
      minWidth: 50,
      align: "center",
    },
    {
      id: "gstSharingPercentage",
      label: "GST Sharing Percentage",
      minWidth: 50,
      align: "center",
    },
    {
      id: "priceOnline",
      label: "Price For Commission Calculation (Online)",
      minWidth: 50,
      align: "center",
    },
    {
      id: "commissionOnline",
      label: "Commission Online",
      minWidth: 50,
      align: "center",
    },
    {
      id: "paymentToFacultyOnline",
      label: "Payment To Faculty (Online)",
      minWidth: 50,
      align: "center",
    },
    {
      id: "priceOffline",
      label: "Price For Commission Calculation (Offline)",
      minWidth: 50,
      align: "center",
    },
    {
      id: "commissionOffline",
      label: "Commission Offline",
      minWidth: 50,
      align: "center",
    },
    {
      id: "paymentToFacultyOffline",
      label: "Payment To Faculty (Offline)",
      minWidth: 50,
      align: "center",
    },
  ];

  function createData(
    sno,
    name,
    discountDateRangeByFaculty,
    discountDateRangeByUs,
    mrp,
    dicountByFaculty,
    dicountByUs,
    sld50,
    gstPercentage,
    gstSharingPercentage,
    priceOnline,
    commissionOnline,
    paymentToFacultyOnline,
    priceOffline,
    commissionOffline,
    paymentToFacultyOffline
  ) {
    return {
      sno,
      name,
      discountDateRangeByFaculty,
      discountDateRangeByUs,
      mrp,
      dicountByFaculty,
      dicountByUs,
      sld50,
      gstPercentage,
      gstSharingPercentage,
      priceOnline,
      commissionOnline,
      paymentToFacultyOnline,
      priceOffline,
      commissionOffline,
      paymentToFacultyOffline,
    };
  }

  let rows = data?.faculty?.map((ele, index) =>
    createData(
      index + 1,
      ele.faculty_name,
      ele.start_date_dis_by_faculty
        ? `${ele.start_date_dis_by_faculty} to ${ele.end_date_dis_by_faculty} `
        : `-`,
      ele.start_date_dis_by_us
        ? `${ele.start_date_dis_by_us} to ${ele.end_date_dis_by_us}`
        : `-`,
      <span>
        {" "}
        <span style={{ fontSize: "20px" }}> &#8377; </span>{" "}
        <span className="text-primary"> {ele.faculty_mrp} </span>{" "}
      </span>,
      <span>
        {" "}
        <span style={{ fontSize: "20px" }}> &#8377; </span>{" "}
        {ele.discount_by_faculty}{" "}
      </span>,
      <span>
        {" "}
        <span style={{ fontSize: "20px" }}> &#8377; </span> {ele.discount_by_us}{" "}
      </span>,
      ele.sld_50,
      `${ele.gst_applied_percent} %`,
      `${ele.gst_sharing_percent} %`,
      <span className="text-info">
        {" "}
        <span style={{ fontSize: "20px" }}> &#8377; </span>{" "}
        {ele.price_on_which_commission_is_calculated}{" "}
      </span>,
      <div>
        {" "}
        <span className="text-primary">
          {" "}
          <span style={{ fontSize: "20px" }}> &#8377; </span>{" "}
          {ele.commission_amount_online}{" "}
        </span>{" "}
        <span className="text-danger">
          ({ele.commission_percentage_online.toFixed(2)} %)
        </span>{" "}
      </div>,
      <span className="text-success">
        {" "}
        <span style={{ fontSize: "20px" }}> &#8377; </span>{" "}
        {ele.payment_to_faculty}{" "}
      </span>,
      <span className="text-info">
        {" "}
        <span style={{ fontSize: "20px" }}> &#8377; </span>{" "}
        {ele.price_on_which_commission_is_calculated_offline}{" "}
      </span>,
      <div>
        {" "}
        <span className="text-primary">
          {" "}
          <span style={{ fontSize: "20px" }}> &#8377; </span>{" "}
          {ele.commission_amount_offline}{" "}
        </span>{" "}
        <span className="text-danger">
          ({ele.commission_percentage_offline.toFixed(2)} %)
        </span>{" "}
      </div>,
      <span className="text-success">
        {" "}
        <span style={{ fontSize: "20px" }}> &#8377; </span>{" "}
        {ele.payment_to_faculty_offline}{" "}
      </span>
    )
  );

  const classes = useStyles();

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // **** Render Edit Button
  const editButton = (data) => {
    return (
      <Button
        size="small"
        sx={{ fontSize: "15px" }}
        color="primary"
        onClick={() => {
          setEditData(data);
          setOpenEditDialog(true);
        }}
      >
        <EditIcon fontSize="small" /> &nbsp; Edit
      </Button>
    );
  };

  return (
    <>
      <Box sx={{ bgcolor: "background.paper", mt: 3 }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Faculty" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Grid container spacing={2} item xs={12} alignItems="stretch">
              <Grid item xs={12}>
                <AuthWrapper
                  type="ACTION"
                  name="VIDEO_LECTURE_SINGLE_VIEW_LIST_PRICING_TABLE"
                >
                  <TableContainer className="m-2 mb-3  border border-slate-600">
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          paddingLeft: "10px",
                        }}
                      >
                        Pricing Table:
                      </p>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell
                            className="m-1 font-weight-bold"
                            align="left"
                          >
                            Maximum Discount :
                          </StyledTableCell>
                          <StyledTableCell className="m-1" align="left">
                            {" "}
                            {data.max_discount}{" "}
                          </StyledTableCell>
                          <Grid container alignItems="justify-between">
                            <Grid item xs={4} className="m-3 text-blue"></Grid>
                          </Grid>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            className="m-1 font-weight-bold"
                            align="left"
                          >
                            Combo Discount
                          </StyledTableCell>
                          <StyledTableCell className="m-1" align="left">
                            {data?.combo_discount || "-"}
                          </StyledTableCell>
                          <Grid container alignItems="justify-between">
                            <Grid item xs={4} className="m-3 text-blue">
                              {editButton({
                                type: "input",
                                name: "combo_discount",
                                label: "Combo Discount",
                                initialValue: data?.combo_discount,
                              })}
                            </Grid>
                          </Grid>
                        </StyledTableRow>
                      </TableBody>{" "}
                    </Table>{" "}
                  </TableContainer>{" "}
                </AuthWrapper>
                <TableContainer className="m-2 mb-3  border border-slate-600">
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell
                          className="m-1 font-weight-bold"
                          align="left"
                        >
                          Processing Time :
                        </StyledTableCell>
                        <StyledTableCell className="m-1" align="left">
                          {" "}
                          {data.delievery_time}{" "}
                        </StyledTableCell>

                        <Grid container alignItems="justify-between">
                          <Grid item xs={4} className="m-3 text-blue">
                            {editButton({
                              type: "input",
                              name: "delievery_time",
                              label: "Processing Time ",
                              initialValue: data?.delievery_time,
                            })}
                          </Grid>
                        </Grid>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCell
                          className="m-1 font-weight-bold"
                          align="left"
                        >
                          Duration Of Lecture :
                        </StyledTableCell>
                        <StyledTableCell className="m-1" align="left">
                          {data?.lecture_duration_numbers}{" "}
                          {data.duration_of_lecture}
                        </StyledTableCell>

                        <Grid container alignItems="justify-between">
                          <Grid item xs={4} className="m-3">
                            {editButton({
                              type: "multi-input",
                              initialValue: {
                                lecture_duration_numbers:
                                  data?.lecture_duration_numbers,
                                duration_of_lecture: data?.duration_of_lecture,
                              },
                              fields: [
                                {
                                  name: "lecture_duration_numbers",
                                  label: "Duration in Numbers",
                                },
                                {
                                  name: "duration_of_lecture",
                                  label: "Duration",
                                },
                              ],
                            })}
                          </Grid>
                        </Grid>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCell
                          className="m-1 font-weight-bold"
                          align="left"
                        >
                          Kit Content :
                        </StyledTableCell>
                        <StyledTableCell className="m-1" align="left">
                          {" "}
                          {data.kit_content}{" "}
                        </StyledTableCell>
                        <Grid container alignItems="justify-between">
                          <Grid item xs={4} className="m-3 text-blue">
                            {editButton({
                              type: "input",
                              name: "kit_content",
                              label: "Kit Content ",
                              initialValue: data?.kit_content,
                            })}
                          </Grid>
                        </Grid>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCell
                          className="m-1 font-weight-bold"
                          align="left"
                        >
                          Lecture Recorded On :
                        </StyledTableCell>
                        <StyledTableCell className="m-1" align="left">
                          {" "}
                          {data.lecture_recorded_on}{" "}
                        </StyledTableCell>
                        <Grid container alignItems="justify-between">
                          <Grid item xs={4} className="m-3 text-blue">
                            {editButton({
                              type: "input",
                              name: "lecture_recorded_on",
                              label: "Lecture Recorded On ",
                              initialValue: data?.lecture_recorded_on,
                            })}
                          </Grid>
                        </Grid>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCell
                          className="m-1 font-weight-bold"
                          align="left"
                        >
                          Topics Covered :
                        </StyledTableCell>
                        <StyledTableCell className="m-1" align="left">
                          {" "}
                          {data.topics_covered}{" "}
                        </StyledTableCell>
                        <Grid container alignItems="justify-between">
                          <Grid item xs={4} className="m-3 text-blue">
                            {editButton({
                              type: "input",
                              name: "topics_covered",
                              label: "Topics Covered ",
                              initialValue: data?.topics_covered,
                            })}
                          </Grid>
                        </Grid>
                      </StyledTableRow>
                    </TableBody>{" "}
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Video Runs On :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data.video_runs_on}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "input",
                            name: "video_runs_on",
                            label: "Video Runs On ",
                            initialValue: data?.video_runs_on,
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        System Requirements :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data.system_requirements.replace(/<[^>]+>/g, "")}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "input",
                            name: "system_requirements",
                            label: "System Requirements ",
                            initialValue: data?.system_requirements.replace(
                              /<[^>]+>/g,
                              ""
                            ),
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Lecture Language :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data.lecture_languages}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "input",
                            name: "lecture_languages",
                            label: "Lecture Language ",
                            initialValue: data?.lecture_languages,
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Mode Discription :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data.mode_discription}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "input",
                            name: "mode_discription",
                            label: "Mode Discription",
                            initialValue: data?.mode_discription,
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Book Type :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data.book_type}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "input",
                            name: "book_type",
                            label: "Book Type ",
                            initialValue: data?.book_type,
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Relevant Attempt :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data.relevant_attempt}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "input",
                            name: "relevant_attempt",
                            label: "Relevant Attempt ",
                            initialValue: data?.relevant_attempt,
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Fast Forward Speed :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data.fast_forward_speed}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "input",
                            name: "fast_forward_speed",
                            label: "Fast Forward Speed ",
                            initialValue: data?.fast_forward_speed,
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Internet Connectivity :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data.internet_connectivity === "true"
                          ? "YES"
                          : "NO"}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "boolean",
                            name: "internet_connectivity",
                            label: " Internet Connectivity",
                            initialValue:
                              data.internet_connectivity === "true"
                                ? "Yes"
                                : "No",
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Sld Selling Price :
                      </StyledTableCell>
                      <StyledTableCell className="m-1 text-info" align="left">
                        {" "}
                        &#8377; {data.sld_selling_price}{" "}
                      </StyledTableCell>
                      {/* <Grid container alignItems="justify-between">
                          <Grid item xs={4} className="m-3 text-blue">
                            {editButton({
                              type: "input",
                              name: "sld_selling_price",
                              label: " Sld Selling Price ",
                              initialValue: data?.sld_selling_price,
                            })}
                          </Grid>
                        </Grid> */}
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Validity :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data.validity}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "input",
                            name: "validity",
                            label: "Validity ",
                            initialValue: data?.validity,
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Validity Start :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data.validity_start}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "input",
                            name: "validity_start",
                            label: " Validity Start ",
                            initialValue: data?.validity_start,
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Validity Extension :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data.validity_extension}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "input",
                            name: "validity_extension",
                            label: "Validity Extension ",
                            initialValue: data?.validity_extension,
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Referral To Percentage :
                      </StyledTableCell>
                      <StyledTableCell className="m-1 text-danger" align="left">
                        {data.referral_to_percentage} %{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue"></Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Referral By Percentage :
                      </StyledTableCell>
                      <StyledTableCell className="m-1 text-danger" align="left">
                        {data.referral_by_percentage} %{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue"></Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Note :
                      </StyledTableCell>
                      <StyledTableCell className="m-1 " align="left">
                        {data.note}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "input",
                            name: "note",
                            label: "Note:",
                            initialValue: data?.note,
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Franchise Discount 50 :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {data.franchise_discount_50}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue"></Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Mode MRP :
                      </StyledTableCell>
                      <StyledTableCell className="m-1 text-info" align="left">
                        {" "}
                        &#8377; {data.mode_mrp}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue"></Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        No Of View :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {data.no_of_views}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "input",
                            name: "no_of_views",
                            label: " No Of View",
                            initialValue: data?.no_of_views,
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Additional Offers :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {data.additional_offers}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "input",
                            name: "additional_offers",
                            label: "Additional Offers ",
                            initialValue: data?.additional_offers,
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>{" "}
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        In Offer :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {data.in_offer ? "YES" : "NO"}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "boolean",
                            name: "in_offer",
                            label: "In Offer ",
                            initialValue: data?.in_offer ? "Yes" : "No",
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Varient Code :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data.varient_code}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue"></Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        View Count :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data.view_count}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "input",
                            name: "view_count",
                            label: "View Count ",
                            initialValue: data?.view_count,
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Views Extension :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data.views_extension}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "input",
                            name: "views_extension",
                            label: "Views Extension ",
                            initialValue: data?.views_extension,
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Important Notice :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data.important_notice}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "input",
                            name: "important_notice",
                            label: "Important Notice ",
                            initialValue: data?.important_notice,
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>{" "}
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Availability :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data.is_available ? "YES" : "NO"}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "boolean",
                            name: "is_available",
                            label: "Availability ",
                            initialValue: data?.is_available ? "Yes" : "No",
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Hidden :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data.is_hidden ? "YES" : "NO"}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "boolean",
                            name: "is_hidden",
                            label: "Hidden ",
                            initialValue: data?.is_hidden ? "Yes" : "No",
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Wallet Discount :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data?.wallet_discount}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue"></Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Wallet Discount Applied :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data?.wallet_discount_is_applied ? "YES" : "NO"}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "boolean",
                            name: "wallet_discount_is_applied",
                            label: "Wallet Discount Applied",
                            initialValue: data?.wallet_discount_is_applied
                              ? "Yes"
                              : "No",
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Other Counpon Applied :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data?.other_coupon_applied ? "YES" : "NO"}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "boolean",
                            name: "other_coupon_applied",
                            label: " Other Counpon Applied",
                            initialValue: data?.other_coupon_applied
                              ? "Yes"
                              : "No",
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Printable :
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data?.printable_or_not ? "YES" : "NO"}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "boolean",
                            name: "printable_or_not",
                            label: "Printable",
                            initialValue: data?.printable_or_not ? "Yes" : "No",
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        No.of Lectures :{" "}
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {data?.no_of_lectures_in_numbers} {data.no_of_lectures}
                      </StyledTableCell>

                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3">
                          {editButton({
                            type: "multi-input",
                            initialValue: {
                              no_of_lectures_in_numbers:
                                data?.lecture_duration_numbers,
                              no_of_lectures: data?.no_of_lectures,
                            },
                            fields: [
                              {
                                name: "no_of_lectures_in_numbers",
                                label: "Number of lectures in Numbers",
                              },
                              {
                                name: "no_of_lectures",
                                label: "Number of lectures ",
                              },
                            ],
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Active Status
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data?.is_active === true ? "Active" : "Deactive"}{" "}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "activeStatus",
                            name: "is_active",
                            label: "Active Status",
                            initialValue:
                              data?.is_active === true ? "Active" : "Deactive",
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Show MRP
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {" "}
                        {data?.is_mrp_show ? "YES" : "NO"}{" "}
                        {/* === 'true' ? "YES" : "NO" */}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "boolean",
                            name: "is_mrp_show",
                            label: " Show MRP",
                            initialValue: data?.is_mrp_show ? "Yes" : "No",
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Shipping Method
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {data?.shipping_method || "-"}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "input",
                            name: "shipping_method",
                            label: "Shipping Method",
                            initialValue: data?.shipping_method,
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        className="m-1 font-weight-bold"
                        align="left"
                      >
                        Batch Recording
                      </StyledTableCell>
                      <StyledTableCell className="m-1" align="left">
                        {data?.batch_recording || "-"}
                      </StyledTableCell>
                      <Grid container alignItems="justify-between">
                        <Grid item xs={4} className="m-3 text-blue">
                          {editButton({
                            type: "input",
                            name: "batch_recording",
                            label: "Batch Recording",
                            initialValue: data?.batch_recording,
                          })}
                        </Grid>
                      </Grid>
                    </StyledTableRow>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={1} dir={theme.direction}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={() => setOpenEditPricesDialog(true)}
              >
                {" "}
                Edit Prices{" "}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <CreateTable
                rows={rows}
                style={{ maxWidth: "100%" }}
                columns={columns}
                isButton={true}
              />
            </Grid>
          </TabPanel>
        </SwipeableViews>
      </Box>
      {openEditDialog && (
        <UpdateVideoLectureSingleVarientField
          onClose={() => setOpenEditDialog(false)}
          initialValue={editData.initialValue}
          multiInitialValues={editData.initialValue}
          fields={editData.fields}
          label={editData.label}
          videoLecture_id={videoLecture_id}
          name={editData.name}
          multiline={editData.multiline}
          varient_id={data?._id}
          type={editData.type}
        />
      )}

      {openEditPricesDialog && (
        <UpdatePricesWrapper
          onClose={() => setOpenEditPricesDialog(false)}
          initialData={data}
          videoLectureId={videoLecture_id}
        />
      )}
    </>
  );
}
