import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import axios from "../../config/Axios";
let _this;

const useStyles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {

        color: theme.palette.text.secondary,
    },
    grid: {
        padding: theme.spacing(4),
    },
    authBgGrid: {
        padding: theme.spacing(4),
        background: "#5664D291",
        height: "100%"
    },
    inputRoot: {
        fontSize: 16
    },
    labelRoot: {
        fontSize: 14,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Register extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            password: "",
            confirmPassword: "",
            notifyMsg: "",
            isLoading: false,
            msgType: "error"
        }

        _this = this

        // if (
        //     localStorage.getItem("__authToken__") !== null &&
        //     localStorage.getItem("__authToken__") !== undefined
        // ) {
        //     props.history.goBack();
        // }
    }

    handleInput = (value, name) => {
        this.setState({
            [name]: value
        })
    }



    signup = (e) => {
        e.preventDefault();
        let { mobileNumber, password, firstName, lastName, emailAddress, } = this.state

        if (firstName === "") {
            this.setState({ notifyMsg: "First Name is required", msgType: "error" })
            return;
        }

        if (lastName === "") {
            this.setState({ notifyMsg: "Last Name is required", msgType: "error" })
            return;
        }

        if (mobileNumber === "") {
            this.setState({ notifyMsg: "Mobile Number is required", msgType: "error" })
            return;
        }

        if (password === "") {
            this.setState({ notifyMsg: "Password is required", msgType: "error" })
            return;
        }

        let data = {
            "first_name": firstName,
            "last_name": lastName,
            "email": emailAddress,
            "mobile": mobileNumber,
            "password": password,
        }

        this.setState({ isLoading: true })

        axios.post('/user/add-user', data)
            .then(function (response) {
                let { data } = response
                if (data.status) {
                    // localStorage.setItem('__authToken__', data.token)
                    localStorage.setItem('__authUserData__', JSON.stringify(data.data))
                    _this.setState({ isLoading: false })
                    _this.setState({ notifyMsg: "Your Base Details Added Successfully", isLoading: false, msgType: "success" })
                    window.location.replace('/otp-verification')
                } else {
                    _this.setState({ notifyMsg: "opps! something went wrong", isLoading: false, msgType: "error" })
                }
            })
            .catch(function (error) {
                  
                _this.setState({ notifyMsg: "opps! something went wrong", isLoading: false, msgType: "error" })
            });
    }

    render() {
        const { classes } = this.props;
        const { theme } = this.props;
        return (
            <ThemeProvider theme={theme}>
                {this.state.notifyMsg !== "" ? <Snackbar open={this.state.notifyMsg !== "" ? true : false} autoHideDuration={6000} onClose={() => this.setState({ notifyMsg: "" })}>
                    <Alert onClose={() => this.setState({ notifyMsg: "" })} severity={this.state.msgType}>
                        {this.state.notifyMsg}
                    </Alert>
                </Snackbar> : null}
                <div className={classes.root}>
                    <Grid container justify="center"
                        alignItems="center" style={{ height: "100vh" }}>
                        <Grid item xs={6} md={6} lg={6} xl={6}>
                            <Paper className={classes.paper}>
                                <Grid container justify="center" alignItems="center" direction="row">
                                    <Grid item xs={6} md={6} lg={6} xl={6} className={classes.grid}>
                                        <CssBaseline />
                                        <div className={classes.paper}>
                                            <div className="text-center mb-4">
                                                <Typography component="h2" className="mb-3" variant="h5" style={{ fontWeight: 500, color: "#424040" }}>
                                                    Welcome To <Link to="/login" style={{ textDecoration: "none" }}>LikhaPadi</Link></Typography>
                                            </div>
                                            <Typography component="h1" className="mb-3" variant="h5" style={{ fontWeight: 500, color: "#424040" }}>
                                                Create an account</Typography>
                                            <form className={classes.form} noValidate>
                                                <Grid container spacing={2}>
                                                    <Grid item md={6} sm={6}><TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        label="First Name"
                                                        onChange={(e) => this.handleInput(e.target.value, 'firstName')}
                                                        value={this.state.firstName}
                                                        
                                                        InputProps={{ classes: { root: classes.inputRoot } }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.labelRoot,
                                                            }
                                                        }}
                                                    /></Grid>
                                                    <Grid item md={6} sm={6}><TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        label="Last Name"
                                                        onChange={(e) => this.handleInput(e.target.value, 'lastName')}
                                                        value={this.state.lastName}
                                                        InputProps={{ classes: { root: classes.inputRoot } }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.labelRoot,
                                                            }
                                                        }}
                                                    /></Grid>
                                                </Grid>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    label="Mobile Number"
                                                    onChange={(e) => this.handleInput(e.target.value, 'mobileNumber')}
                                                    value={this.state.mobileNumber}
                                                    InputProps={{ classes: { root: classes.inputRoot } }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label="Email Address"
                                                    onChange={(e) => this.handleInput(e.target.value, 'emailAddress')}
                                                    value={this.state.emailAddress}
                                                    InputProps={{ classes: { root: classes.inputRoot } }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    label="Password"
                                                    type="password"
                                                    onChange={(e) => this.handleInput(e.target.value, 'password')}
                                                    value={this.state.password}
                                                    InputProps={{ classes: { root: classes.inputRoot } }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    label="Confirm Password"
                                                    type="password"
                                                    onChange={(e) => this.handleInput(e.target.value, 'confirmPassword')}
                                                    value={this.state.confirmPassword}
                                                    InputProps={{ classes: { root: classes.inputRoot } }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                        }
                                                    }}
                                                />
                                                <Grid container alignItems="center" className="my-3">
                                                    <Grid item xs>
                                                        <div className="d-flex align-items-center">
                                                            <div className='position-relative'>
                                                                <Button
                                                                    onClick={(e) => this.signup(e)}
                                                                    type="submit"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className={classes.submit}
                                                                    disabled={this.state.isLoading}
                                                                >Sign Up</Button>
                                                                {this.state.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item>
                                                        <Link to="/login" style={{ fontWeight: 600 }}>
                                                            I am already a member</Link>
                                                    </Grid>
                                                </Grid>


                                                <Grid container alignItems="center" justify="center">
                                                    <Grid item>
                                                        <h6 className="mt-3">Have an account? <Link to="/login" style={{ fontWeight: 600 }}>
                                                            Sign In</Link></h6>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6} xl={6} className={classes.authBgGrid}>
                                        <img src="assets/images/sign-up-img.png" alt="" className="img-fluid" />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                    </Grid>
                </div>
            </ThemeProvider>
        )
    }
}


export default withStyles(useStyles, { withTheme: true })(Register);