
import React, { useState, useEffect } from 'react';
import EditDelete from '../EditDelete';
import CreateTableAddButton from '../CreateTableAddButton'
import AddMsgTemplate from '.././smstemplate/AddMsgTemplate';
import Toster from '../Toster';
import SkeletonListing from '../SkeletonListing';
import Loader from '../Loader';
import AddDataHeader from '../AddDataHeader';
import DataNotFound from '../DataNotFound';
import { AccordionDetails, AccordionSummary, Typography, Accordion, Grid, Box, Button, Card } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Filters from '../Filters';

import {
    useGetAllmsgTempletQuery,
    useDeleteMsgTempletMutation,
    useAddMsgTempletMutation,
    useUpdateMsgTempletDataMutation,
} from "../../../../services/smsServices"
import EditMsgTemplate from './EditMsgTemplate';
import moment from 'moment';
import AuthWrapper from '../../../../utils/AuthWrapper';
import { getFilterdColumns } from '../../../../utils/getFilterFunction';

export default function SmsTemplate() {
    const [filteredPagination, setFilteredPagination] = React.useState([]);

    const columns = [
        {
            id: 'SNo',
            label: 'S.No',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'title',
            label: ' title',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'templet_id',
            label: 'templet id',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'msg_body',
            label: 'Msg Body',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },

        {
            id: 'createdAt',
            label: 'created At',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'Actions',
            label: 'Actions',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
      authName: ['SMS_SMS_TEMPLATE_VIEW', 'SMS_SMS_TEMPLATE_EDIT', 'SMS_SMS_TEMPLATE_DELETE']

        },
    ];
    function createData(SNo, title, templet_id, msg_body, createdAt, Actions) {
        return { SNo, title, templet_id, msg_body, createdAt, Actions };
    }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [render, setRender] = useState(false)

    const paginationData = {
        "limit": rowsPerPage,
        "query": "",
        "params": [
            "templet_id",
            "msg_body",
            "title"
        ],
        "filterBy": filteredPagination,

        "page": page + 1
    }
    const [pagination, setpagination] = useState(paginationData)

    let rows = []
    let [getid, setGetid] = useState('')
    const [data, setData] = useState('')
    const [AddDialog, setDialog] = useState(false)
    const [editDialog, setEditDialog] = useState(false)
    const [viewDialog, setViewDialog] = useState(false)
    const getAllData = useGetAllmsgTempletQuery(pagination)
    const [deleteBookStock, deleteBookStockInfo] = useDeleteMsgTempletMutation()
    const [addMsgtemp, addMsgTemplateInfo] = useAddMsgTempletMutation()

    const [updateMsgTemplate, updateMsgTemplateInfo] = useUpdateMsgTempletDataMutation()
    const allBookstock = getAllData.data
    const totalItem = allBookstock ? allBookstock.totalItem : 0
    useEffect(() => {
        setpagination(paginationData)
    }, [render, rowsPerPage, page, filteredPagination])

    useEffect(() => {
        getid && deleteBookStock(getid) && setGetid("")
    }, [getid])

    const AddData = (data) => {
        addMsgtemp(data)
    }
    const UpdateData = (data, id) => {
        updateMsgTemplate({ data, id })
    }
    let totalData = 'ok'
    if (allBookstock) {
        totalData = allBookstock.totalItem
        rows = allBookstock.data.map((data, index) => createData(
            index + 1,
            data.title,
            data.templet_id,
            data.msg_body,

            data.createdAt ? moment(data.createdAt).format("DD-MM-YYYY hh:mm:ss A") : "",
            <div className='d-flex'>
                <AuthWrapper type="ACTION" name="SMS_SMS_TEMPLATE_VIEW">
                    <EditDelete setViewDialog={setViewDialog}
                        type="view" setData={setData}
                        setGetid={setGetid} data={data} />
                </AuthWrapper>

                <AuthWrapper type="ACTION" name="SMS_SMS_TEMPLATE_EDIT">
                    <EditDelete
                        setEditDialog={setEditDialog}
                        type="edit" setData={setData}
                        setGetid={setGetid} data={data} />
                </AuthWrapper>

                <AuthWrapper type="ACTION" name="SMS_SMS_TEMPLATE_DELETE">
                    <EditDelete type="delete"
                        setGetid={setGetid}
                        id={data._id} />
                </AuthWrapper>
            </div>
        )
        )
    }
    const dialogclose = () => {
        setEditDialog(false)
        setViewDialog(false)
        setDialog(false)
        setRender(!render)
    }
    return (
        <div>
                {!viewDialog && <AddDataHeader
                    addFormName={"Add Template Sms"}
                    setPage={setPage}
                    page={page}
                    excelBtn={false}
                    totalItem={totalItem}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    setAddDialog={setDialog}
                    searchBarApi={setpagination}
                    paginationData={paginationData}
                    isButton={true}
                    isSearch={
                        !AuthWrapper({
                          type: "ACTION",
                          name: "SMS_SMS_TEMPLATE_LIST",
                          inBoolean: true,
                        })
                      }
                      isPagination={
                        !AuthWrapper({
                          type: "ACTION",
                          name: "SMS_SMS_TEMPLATE_LIST",
                          inBoolean: true,
                        })
                      }
                      addBtn={
                        !AuthWrapper({
                          type: "ACTION",
                          name: "SMS_SMS_TEMPLATE_ADD",
                          inBoolean: true,
                        })
                      }
                    Adddialog={AddDialog}
                    form={<AddMsgTemplate AddData={AddData} setDialog={setDialog} />}
                />}
            {deleteBookStockInfo.isLoading || updateMsgTemplateInfo.isLoading ? <Loader /> :
                <>
                    <AuthWrapper type="ACTION" name="SMS_SMS_TEMPLATE_LIST">
                        {!viewDialog ?
                            rows.length ?
                                <CreateTableAddButton
                                    editDialog={editDialog}
                                    setPage={setPage}
                                    rows={rows}
                                    editform={
                                        <EditMsgTemplate editData={data}
                                            UpdateData={UpdateData}
                                            editDialog={setEditDialog} />
                                    }
                                    columns={getFilterdColumns(columns)}

                                     isButton={true}
                                    editFormName={'Edit Sms Template'} />
                                : totalData == 0 ? <DataNotFound /> : <SkeletonListing />
                            :
                            <ViewSmsTemp viewDialog={setViewDialog} data={data} />
                        }
                        </AuthWrapper>
                </>
            }
            {addMsgTemplateInfo.data && <Toster info={addMsgTemplateInfo} dialogclose={dialogclose} />}
            {deleteBookStockInfo.data && <Toster info={deleteBookStockInfo} dialogclose={dialogclose} />}
            {updateMsgTemplateInfo && <Toster info={updateMsgTemplateInfo} dialogclose={dialogclose} />}
        </div>
    )
}

const ViewSmsTemp = function ({ viewDialog, data }) {
    return (
        <div className="p-3">
            <Box
                sx={{
                    flexGrow: 1,
                    bgcolor: "background.paper",
                }}
            >
                <div class="card">
                    <div class="card-body">
                        <Grid container justifyContent="center" >
                            <Grid xs={12} className="d-flex justify-content-end">
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => viewDialog(false)}
                                    className=""
                                >
                                    Close
                                </Button>
                            </Grid>
                            <AuthWrapper type="ACTION" name="SMS_SMS_TEMPLATE_LIST">
                                <>
                                    <Grid xs={2} className="ml-1">
                                        <Card style={{ height: "50px", overflow: "scroll" }} className="border text-right px-4 py-1 text-info font-weight-bold">
                                            Title
                                        </Card>

                                        <Card style={{ height: "50px", overflow: "scroll" }} className="border text-right px-4 py-1 text-info font-weight-bold">
                                            Templet id
                                        </Card>
                                        <Card style={{ height: "50px", overflow: "scroll" }} className="border text-right px-4 py-1 text-info font-weight-bold">
                                            Deleted
                                        </Card>
                                    </Grid>
                                    <Grid xs={3} className="">
                                        <Card style={{ height: "50px", overflow: "scroll" }} className="border text-left px-4 py-1 text-muted font-weight-bold">
                                            {data.title}
                                        </Card>
                                        <Card style={{ height: "50px", overflow: "scroll" }} className="border text-left px-4 py-1 text-muted font-weight-bold">
                                            {data.templet_id}
                                        </Card>
                                        <Card style={{ height: "50px", overflow: "scroll" }} className="border text-left px-4 py-1 text-muted font-weight-bold">
                                            {data.is_deleted ? "YES" : "NO"}
                                        </Card>
                                    </Grid>
                                    <Grid xs={2} className="ml-1">
                                        <Card style={{ height: "50px", overflow: "scroll" }} className="border text-right px-4 py-1 text-info font-weight-bold">
                                            Created Date
                                        </Card>
                                        <Card style={{ height: "50px", overflow: "scroll" }} className="border text-right px-4 py-1 text-info font-weight-bold">
                                            Updated Date
                                        </Card>
                                    </Grid>
                                    <Grid xs={3} className="">
                                        <Card style={{ height: "50px", overflow: "scroll" }} className="border text-left px-4 py-1 text-muted font-weight-bold">
                                            {moment(data.createdAt).format("DD-MM-YYYY hh:mm:ss A")}
                                        </Card>
                                        <Card style={{ height: "50px", overflow: "scroll" }} className="border text-left px-4 py-1 text-muted font-weight-bold">
                                            {moment(data.updatedAt).format("DD-MM-YYYY hh:mm:ss A")}
                                        </Card>
                                    </Grid>
                                    <Grid xs={2} className="ml-1">
                                        <Card style={{ height: "200px", overflow: "scroll" }} className="border text-right px-4 py-1 text-info font-weight-bold">
                                            SMS Body
                                        </Card>
                                    </Grid>
                                    <Grid xs={8} className="">
                                        <Card style={{ height: "200px", overflow: "scroll" }} className="border text-left px-4 py-1 text-muted font-weight-bold">
                                            {data.msg_body}
                                        </Card>
                                    </Grid>
                                </>
                            </AuthWrapper>
                        </Grid>
                    </div>
                </div>
            </Box>
        </div>
    )
}