import React, { useState, useEffect } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import AddDataHeader from "../AddDataHeader";
import DataNotFound from "../DataNotFound";
import FormDialog from "../reusableComponents/NewFormDialog";
import {
  useGetAllIncomeCategoryQuery,
  useGetIncomeByIdQuery,
  useAddIncomeCategoryMutation,
  useUpdateIncomeCategoryMutation,
  useDeleteIncomeCategoryMutation,
} from "../../../../services/incomeServices";

export default function IncomeCategory() {
  const {
    data: AllIncomeCategories,
    isAllIncomeCategoriesFetching,
    isAllIncomeCategoriesLoading,
  } = useGetAllIncomeCategoryQuery();

  const [addIncomeCategoryData, addIncomeCategoryDataInfo] =
  useAddIncomeCategoryMutation();
 
    const [updateIncomeCategory, updateIncomeCategoryInfo] =
    useUpdateIncomeCategoryMutation();

    const [deleteIncomeCategory, deleteIncomeCategoryInfo] =
    useDeleteIncomeCategoryMutation();



  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "categoryName",
      label: "Category Name",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "department",
      label: "Department",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "remark",
      label: "Remark",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];
  function createData(SNo, categoryName, department, remark, actions) {
    return {
      SNo,
      categoryName,
      department,
      remark,
      actions,
    };
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [render, setRender] = useState(false);
  let rows = [];
  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [addDialog, setAddDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);

  let totalData = "ok";

  useEffect(() => {
    getid && deleteIncomeCategory(getid) && setGetid("");
  }, [getid]);

  if (
    !isAllIncomeCategoriesFetching &&
    !isAllIncomeCategoriesLoading &&
    AllIncomeCategories
  ) {
    
    rows = AllIncomeCategories.data.map((ele, index) => {
      return createData(
        index + 1,
        ele.category_name,
        ele.department,
        ele.remark,
        <div>
          <EditDelete
            setEditDialog={setEditDialog}
            type="edit"
            setData={setData}
            data={ele}
          />
          <EditDelete type="delete" setGetid={setGetid} id={ele._id} />
        </div>
      );
    });
  }


  // );
  //   }
  const dialogclose = () => {
    setEditDialog(false);
    setViewDialog(false);
    setAddDialog(false);
    setRender(!render);
  };

  const updateBtnClickHandler = (dataForUpdate)=>{
    updateIncomeCategory({
      body: dataForUpdate , 
      id: data._id
    })
  }

  return (
    <div>
      {!viewDialog && (
        <AddDataHeader
          addFormName={"Add Income Category"}
          setPage={setPage}
          page={page}
          excelBtn={false}
          //   totalItem={totalItem}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setAddDialog={setAddDialog}
          isButton={true}
          isPagination={true}
          isSearch={true}
          Adddialog={addDialog}
          form={
            <FormDialog
              open={addDialog}
              setOpen={setAddDialog}
              formName={"Add Income Category "}
              btnName={"Add"}
              onBtnClick={(dataForAdd) => {
                addIncomeCategoryData(dataForAdd);
              }}
              fields={[
                {
                  fieldType: "text",
                  fieldName: "Income Category",
                  initialValue: "",
                  //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                  accessKey: "category_name",
                },
                {
                  fieldType: "text",
                  fieldName: "Department",
                  initialValue: "",
                  //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                  accessKey: "department",
                },
                {
                  fieldType: "text",
                  fieldName: "Remark",
                  initialValue: "",
                  //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                  accessKey: "remark",
                },
              ]}
            />
          }
        />
      )}

      
      <>
        {rows.length ? (
        <CreateTableAddButton
          editDialog={editDialog}
          setPage={setPage}
          rows={rows}
          editform={
            <FormDialog
              open={editDialog}
              setOpen={setEditDialog}
              formName={"Edit Income Category "}
              btnName={"Update"}
              onBtnClick={updateBtnClickHandler}
              fields={[
                {
                  fieldType: "text",
                  fieldName: "Income Category",
                  initialValue: data.category_name ,
                  //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                  accessKey: "category_name",
                },
                {
                  fieldType: "text",
                  fieldName: "Department",
                  initialValue: data.department,
                  //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                  accessKey: "department",
                },
                {
                  fieldType: "text",
                  fieldName: "Remark",
                  initialValue: data.remark,
                  //   <<<<<<<<<<<<<<<<<<<<<  THIS KEY SHOULD BE SAME AS THE KEY EXIST IN DATABASE >>>>>>>>>>>>>>>>>>>
                  accessKey: "remark",
                },
              ]}
            />
          }
          columns={columns}
          isButton={true}
          editFormName={"Edit Income Category"}
        />
         ) : totalData == 0 ? (
         <DataNotFound /> 
         ) : ( 
           <SkeletonListing />
         ) 
        }
        
      </>

      {addIncomeCategoryDataInfo && (
        <Toster info={addIncomeCategoryDataInfo} dialogclose={dialogclose} />
      )}

      {updateIncomeCategoryInfo && (
        <Toster info={updateIncomeCategoryInfo} dialogclose={dialogclose} />
      )}

      {deleteIncomeCategoryInfo && (
        <Toster info={deleteIncomeCategoryInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
