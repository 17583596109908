import React, { useState, useEffect } from "react";
import EditDelete from "./EditDelete";
import CreateTableAddButton from "./CreateTableAddButton";
import Toster from "./Toster";
import SkeletonListing from "./SkeletonListing";
import AddDataHeader from "./AddDataHeader";
import DataNotFound from "./DataNotFound";
import moment from 'moment'
import {
    AccordionDetails,
    AccordionSummary,
    Typography,
    Accordion,
    Button,
    Paper,
    Grid,
    Card,
    TextField
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListIcon from "@material-ui/icons/FilterList";
import Filters from "./Filters";

import {
    useGetCallerPerformanceDataQuery,
} from "../../../services/callerPerformanceServices";
import FormDialog from "./reusableComponents/NewFormDialog";
import AuthWrapper from "../../../utils/AuthWrapper";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import AddExpenseForm from "./AddExpenseForm";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export default function CallerPerformance() {
    const [filteredPagination, setFilteredPagination] = React.useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [render, setRender] = useState(false);
    const paginationData = {
        start_date: startDate === '' ? moment().startOf('month').format('YYYY-MM-DD') : startDate,
        end_date: endDate === '' ? moment().endOf('month').format('YYYY-MM-DD') : endDate,
    }
    const [pagination, setPagination] = useState(paginationData)
    const { data: allData, isFetching, isLoading } = useGetCallerPerformanceDataQuery(paginationData);

    useEffect(() => {
        filteredPagination.forEach((ele) => {

            switch (ele.fieldName) {
                case "from":
                    setStartDate(ele.value)
                    break;
                case "to":
                    setEndDate(ele.value)
                    break;
                default:
                    break;
            }
        })
        setPagination(paginationData);
    }, [render, filteredPagination,]);


    const columns = [
        {
            id: "SNo",
            label: "S.No",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "name",
            label: "Name",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "totalProducts",
            label: "Total Products",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "orderValue",
            label: "Total Order Value",
            minWidth: 50,
            align: "center",
            format: (value) => value.toLocaleString("en-US"),
        },

    ];


    function createData(
        SNo,
        name,
        totalProducts,
        orderValue
    ) {
        return {
            SNo,
            name,
            totalProducts,
            orderValue
        };
    }

    // const orderColumns = [
    //     {
    //         id: "SNo",
    //         label: "S.No",
    //         minWidth: 50,
    //         align: "center",
    //         format: (value) => value.toLocaleString("en-US"),
    //     },
    //     {
    //         id: "productDetails",
    //         label: "Product Details",
    //         minWidth: 50,
    //         align: "center",
    //         format: (value) => value.toLocaleString("en-US"),
    //     },
    //     {
    //         id: "mrp",
    //         label: "MRP",
    //         minWidth: 50,
    //         align: "center",
    //         format: (value) => value.toLocaleString("en-US"),
    //     },
    //     {
    //         id: "discount",
    //         label: "Discount",
    //         minWidth: 50,
    //         align: "center",
    //         format: (value) => value.toLocaleString("en-US"),
    //     },
    //     {
    //         id: "amountReceived",
    //         label: "Amount Received",
    //         minWidth: 50,
    //         align: "center",
    //         format: (value) => value.toLocaleString("en-US"),
    //     },
    // ]

    // function orderCreateData(
    //     SNo,
    //     productDetails,
    //     mrp,
    //     discount,
    //     amountReceived
    // ) {
    //     return {
    //         SNo,
    //         productDetails,
    //         mrp,
    //         discount,
    //         amountReceived
    //     };
    // }

    // const productColumns = [
    //     {
    //         id: "SNo",
    //         label: "S.No",
    //         minWidth: 50,
    //         align: "center",
    //         format: (value) => value.toLocaleString("en-US"),
    //     },
    //     {
    //         id: "title",
    //         label: "Title",
    //         minWidth: 50,
    //         align: "center",
    //         format: (value) => value.toLocaleString("en-US"),
    //     },
    //     {
    //         id: "quantity",
    //         label: "Quantity",
    //         minWidth: 50,
    //         align: "center",
    //         format: (value) => value.toLocaleString("en-US"),
    //     },
    //     {
    //         id: "status",
    //         label: "Status",
    //         minWidth: 50,
    //         align: "center",
    //         format: (value) => value.toLocaleString("en-US"),
    //     },

    // ];

    function productCreateData(
        SNo,
        title,
        quantity,
        status,
    ) {
        return {
            SNo,
            title,
            quantity,
            status,
        };
    }



    let rows = [];
    let orderRows = [];
    let productRows = [];
    const [data, setData] = useState("");
    const [viewDialog, setViewDialog] = useState(false);




    if (allData) {
        rows = allData.data.map((ele, index) => {
            // let totalOrderValue = ele.orders.reduce((acc , cv )=> acc+ cv.order_amount_received , 0)
            return createData(
                index + 1,
                ele.sldUserDetails.name,
                <span className="text-primary"> {ele.totalQuantity} </span>,
                <span className="text-success"> {ele.totalAmount} </span>
            )
        }
        );
    }


    // if (data) {
    //     orderRows = data.orders.map((ele, index) =>
    //         orderCreateData(
    //             index + 1,
    //             ele.product_details.length &&
    //             <CreateTableAddButton
    //                 rows={ele.product_details.map((ele, index) =>
    //                     productCreateData(
    //                         index + 1,
    //                         ele.order_title,
    //                         ele.quantity,
    //                         ele.status
    //                     )
    //                 )}
    //                 columns={productColumns}
    //             />,
    //             <span className="text-primary" > {ele.all_order_mrp} </span>,
    //             <span className="text-danger" > {ele.discount_amount} </span>,
    //             <span className="text-success" > {ele.order_amount_received} </span>,

    //         )
    //     );
    // }

    // );
    //   }
    const dialogclose = () => {
        setViewDialog(false);
        setRender(!render);
    };






    const resetClickHandler = () => {
        setStartDate("")
        setEndDate("")
        setRender(!render)
    }


    // const totalAmount = (array) => {
    //     let sum = 0
    //     array.forEach((ele) => {
    //         sum += ele.order_amount_received
    //     })
    //     return sum
    // }

    // let amt = data && totalAmount(data.orders)


    return (
        <div>
            <AuthWrapper type="ACTION" name="CALLER_PERFORMANCE_LIST">

                <div className="d-flex gap-4 px-4 align-items-center my-4">
                    <div className="font-weight-bold fs-6">Filter :</div>

                    <div className="d-flex px-4">
                        <div className="mr-4">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    leftArrowButtonText='dfd'
                                    clearable
                                    name="date"
                                    onChange={(val) => { setStartDate(moment(val).format("yyyy-MM-DD")) }}
                                    value={startDate}
                                    inputFormat="dd/MM/yyyy"
                                    renderInput={(params) => <TextField name='date' className="bg-light px-2"   {...params} />}
                                    maxDate={new Date()}
                                />
                            </LocalizationProvider>

                        </div>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    leftArrowButtonText='dfd'
                                    clearable
                                    name="date"
                                    onChange={(val) => { setEndDate(moment(val).format("yyyy-MM-DD")) }}
                                    value={endDate}
                                    inputFormat="dd/MM/yyyy"
                                    renderInput={(params) => <TextField name='date' className="bg-light px-2" {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                </div>

                <>
                    {rows.length ? (
                        <CreateTableAddButton
                            rows={rows}
                            columns={columns}
                            isButton={true}
                        />
                    ) : isFetching && isLoading ? (
                        <SkeletonListing />
                    ) : (
                        <DataNotFound />
                    )}
                </>
            </AuthWrapper>

        </div>
        // <div >
        //     <Grid container justifyContent="space-between" alignItems="flex-start" className="my-2">


        //         <Grid
        //             item
        //             // justifyContent=""
        //             className="mb-5 p-3"
        //         >
        //             <Paper elevation={3} className="p-3" >
        //                 <Grid
        //                     container
        //                     justifyContent="space-between"

        //                 >
        //                     <Grid item>

        //                         <Grid
        //                             style={{ height: "150px" }}
        //                             container
        //                             direction="column"
        //                             justifyContent="space-evenly"
        //                         >
        //                             <div>  Name : <span className="text-info text-capitalize"> {data && data._id[0].name} </span>  </div>
        //                             <div>  Email :  <span className="text-info">{data && data._id[0].email} </span>  </div>
        //                             <div>  Username : <span className="text-info"> {data && data._id[0].username} </span></div>

        //                         </Grid>
        //                     </Grid>

        //                 </Grid>
        //             </Paper>

        //         </Grid>
        //         <Button variant="contained" color="secondary" onClick={() => { setViewDialog(false) }} className="mr-3" >Close</Button>
        //     </Grid>
        //     <Grid
        //         className="mb-3 px-3"
        //         container
        //         direction="column"
        //         justifyContent="space-between"
        //     >
        //         <div> <span className=" font-weight-bold font-italic "> Total Orders :</span>  <span className="text-primary font-weight-bold  px-2"> {data && data.orders.length} </span> </div>
        //         <br />
        //         <div> <span className=" font-weight-bold font-italic "> Total Amount Recieved :</span>  <span className="text-success font-weight-bold  px-2"> {data && totalAmount(data.orders)} </span> </div>
        //     </Grid>

        //     {orderRows.length ? (
        //         <CreateTableAddButton
        //             rows={orderRows}
        //             columns={orderColumns}
        //         />
        //     ) : (<DataNotFound />)

        //     }
        // </div>

    );
}
