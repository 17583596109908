import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Link, Paper, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useGetOrderDetailsQuery } from '../../../../../services/offlineModuleServices/offlineOrdersServices'
import SkeletonListing from '../../SkeletonListing'
import OfflineOrderInvoice from '../OfflineOrderInvoice'

// **** Icon Imports
import DoneAllIcon from '@mui/icons-material/DoneAll';
import OfflineOrderDetailsViewPage from '../OfflineOrderDetailsViewPage'
import OfflinePendriveOrderDetailsViewPage from '../OfflinePendriveOrderDetailsViewPage'
import PendriveOrderInvoice from '../offline-invoice/PendriveOrderInvoice'

function AllOrderHistoryListView(props) {

    // **** Destructuring Props
    const { onClose, id, action, pendriveOrder, receiptFor } = props
      

    // **** Services
    const { data: orderDetails, isLoading: isOrderDetailsLoading, isFetching: isOrderDetailsFetching } = useGetOrderDetailsQuery(id)
      

    // **** States
    const [viewInvoice, setViewInvoice] = useState(false);
    const [viewOrderDetails, setViewOrderDetails] = useState(false);

    const [data, setData] = useState("");
      

    return (

        <div>

            <Dialog open={true} onClose={onClose} maxWidth="lg" fullWidth >
                <DialogTitle style={{ backgroundColor: "#5664d2", borderBottomRightRadius: "20px" }} className="text-white mb-3 ">
                    <Grid container justifyContent="space-between" px={2} alignItems="center"  >
                        Order History
                        <Button variant='contained' color='error' onClick={() => onClose()} > Close </Button>
                    </Grid>
                </DialogTitle>
                <DialogContent sx={{ mt: 3 }} >
                    {
                        isOrderDetailsFetching ||
                            isOrderDetailsLoading ?
                            <SkeletonListing />
                            :
                            <Grid container spacing={3} minHeight={300} >
                                {orderDetails?.data?.previous_updated_orders?.map(ele => (
                                    <Grid item md={4} sm={6} xs={12}>
                                        <Paper elevation={10} sx={{ py: 2 }} className="offline-order-view-card"  >

                                            <Grid container  >
                                                <Table size='small' >
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>Invoice No. </TableCell>
                                                            <TableCell > <Typography color="primary" > {ele.invoice_no} </Typography> </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Fees Paid </TableCell>
                                                            <TableCell> <Typography sx={{ color: "#4caf50" }}  > &#8377; {ele.fee_paid} </Typography> </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Due Amount </TableCell>
                                                            <TableCell> <Typography color="error" >&#8377; {ele.due_amount} </Typography>  </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                                <Grid item xs={12} px={2} mt={3}>
                                                    <Button variant='contained' fullWidth size="small" onClick={() => { action == "view" ? setViewOrderDetails(true) : setViewInvoice(true); setData(ele) }} > {action == "view" ? "View" : "Print"} </Button>
                                                </Grid>

                                            </Grid>
                                        </Paper>
                                    </Grid>
                                ))
                                }
                                <Grid item md={4} sm={6} xs={12}>
                                    <Paper elevation={10} sx={{ py: 2 }} className="offline-order-view-card" >

                                        <Grid container  >
                                            <Table size='small' >
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>Invoice No. </TableCell>
                                                        <TableCell > <Typography color="primary" > {orderDetails?.data?.invoice_no} </Typography> </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Fees Paid </TableCell>
                                                        <TableCell> <Typography sx={{ color: "#4caf50" }}  > &#8377; {orderDetails?.data?.fee_paid} </Typography> </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Due Amount </TableCell>
                                                        <TableCell>
                                                            {
                                                                orderDetails?.data?.due_amount != 0 ?
                                                                    <Typography color="error" >&#8377; {orderDetails?.data?.due_amount} </Typography>
                                                                    :
                                                                    <Button variant='contained' color="success" size="small" > <DoneAllIcon fontSize="small" /> &nbsp; Fully Paid  </Button>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            <Grid item xs={12} px={2} mt={3}>
                                                <Button variant='contained' fullWidth size="small" onClick={() => { action == "view" ? setViewOrderDetails(true) : setViewInvoice(true); setData({ ...orderDetails?.data, sold_by_slduser_type: orderDetails?.data?.sold_by_name }) }} > {action == "view" ? "View" : "Print"} </Button>
                                            </Grid>

                                        </Grid>
                                    </Paper>
                                </Grid>

                            </Grid>
                    }
                </DialogContent>
            </Dialog>

            {
                !pendriveOrder && viewInvoice &&
                <OfflineOrderInvoice data={{ data: data }} receiptFor={receiptFor || "Virtual"} />
            }
            {
                pendriveOrder && viewInvoice &&
                <PendriveOrderInvoice data={{ data: data }} onClose={() => setViewInvoice(false)} />
            }
            {
                !pendriveOrder && viewOrderDetails &&
                <OfflineOrderDetailsViewPage open={viewOrderDetails} onClose={setViewOrderDetails} data={data} />
            }

            {
                pendriveOrder && viewOrderDetails &&
                <OfflinePendriveOrderDetailsViewPage open={viewOrderDetails} onClose={setViewOrderDetails} data={data} />
            }

        </div >
    )
}

export default AllOrderHistoryListView
