import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import BackupIcon from '@material-ui/icons/Backup';
import { useFormik } from 'formik';
import * as yup from 'yup'

import {
    FormControl, InputLabel,
    Grid, Select, MenuItem,
    Checkbox, ListItemText,
    OutlinedInput, CircularProgress, Box,
    TextField, Card, CardContent, Button,
    TextareaAutosize, Typography, makeStyles
} from '@material-ui/core'

import {
    useGetAllModeQuery,
    useGetAllSubjectsQuery,
    useGetAallFacultieNamesQuery,
    useGetAllCategoriesQuery,
    useGetAllCoursesQuery,
    useGetAllCourseLevelQuery,
    useGetallInstituteNamesQuery,
    useAddVideoLectureMutation,
} from '../../../services/services';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        minWidth: 100,
    },
    tab: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function CourseForm({AddData,setDialog}) {
    const classes = useStyles();
    const [manageId, setManageId] = useState()
    const [manageLevelId, setManageLevelId] = useState()
    const [imageval, setImageVal] = useState("")
    const [modes, setModes] = useState()
    const [allLevels, setAllLevels] = useState()
    const [allCourse, setAllCourse] = useState()
    const [flag, setFlag] = useState(true)
    const [allInstitute, setAllInstitute] = useState()
    const [subject, setSubject] = useState()
    const [faculty, setFaculty] = useState()
    const [category, setCategory] = useState()
    const [categorySearch, setCategorySearch] = useState("")
    const getAllMode = useGetAllModeQuery()
    const getAllSubjectNames = useGetAllSubjectsQuery(manageLevelId)
    const getAllCategory = useGetAllCategoriesQuery()
    const getAllCourse = useGetAllCoursesQuery()
    const getAllInstitute = useGetallInstituteNamesQuery()
    const getAllLevel = useGetAllCourseLevelQuery(manageId)
    const allFacultieNames = useGetAallFacultieNamesQuery()
    const [addVideoLecture, videolectureInfo] = useAddVideoLectureMutation()


    allCourse && allCourse.forEach((data) => {
        if (data.name == manageId) {
            setManageId(data._id)
        }
    })


    allLevels && allLevels.forEach((data) => {
        if (data.level_name == manageLevelId) {
              
            setManageLevelId(data._id)
        }
    })

    useEffect(() => {
        let { status } = videolectureInfo
        if (status == 'fulfilled') {
              
            let { status, message } = videolectureInfo.data
            if (!status) {
                notify(message)
                setFlag(true)

            } else {
                notify(message)
                setFlag(true)
            }
        }
    }, [videolectureInfo])
    useEffect(() => {
        let { isSuccess, data } = getAllMode;
        if (isSuccess) {
            setModes(data.data)
        }
    }, [getAllMode])
    useEffect(() => {
        let { isSuccess, data } = getAllInstitute;
        if (isSuccess) {
            setAllInstitute(data.data)
        }
    }, [getAllInstitute])
    useEffect(() => {
        let { isSuccess, data } = getAllLevel;
        if (isSuccess) {
            setAllLevels(data.data)
        }
    }, [getAllLevel])
    useEffect(() => {
        let { isSuccess, data } = getAllCourse;
        if (isSuccess) {
            setAllCourse(data.data)
        }
    }, [getAllCourse])
    useEffect(() => {
        let { isSuccess, data } = getAllCategory;
        if (isSuccess) {
            setCategory(data.data)
        }
    }, [getAllCategory])
    useEffect(() => {
        let { isSuccess, data } = getAllSubjectNames;
        if (isSuccess) {
            setSubject(data.data)
        }
    }, [getAllSubjectNames])
    useEffect(() => {
        let { isSuccess, data } = allFacultieNames;
        if (isSuccess) {
            setFaculty(data.data)
        }
    }, [allFacultieNames])
    const notify = (msg) => toast(msg);
    const [subjectValue, setSubjectvalue] = useState([]);
    const subjectChange = (event) => {
        const { target: { value }, } = event;
        setSubjectvalue(typeof value === 'string' ? value.split(',') : value,);
    };
    const [facultyValue, setFacultyvalue] = useState([]);
    const facultyChange = (event) => {
        const { target: { value }, } = event;
        setFacultyvalue(typeof value === 'string' ? value.split(',') : value,);
    };
    const [categoryValue, setcategoryValue] = useState([]);
    const categoryChange = (event) => {
        const { target: { value }, } = event;
        setcategoryValue(typeof value === 'string' ? value.split(',') : value,);
    };
    const formik = useFormik({
        onSubmit: (values) => {
            // setFlag(false)
            values.subject = subjectValue
            values.faculty = facultyValue
            values.category = categoryValue
            values.image = imageval

            var form_data = new FormData()
            for (let key in values) {
                form_data.append(key, values[key])
            }
            AddData(form_data)
        },
        initialValues: {
            subject: "",
            category: "",
            faculty: "",
            faculty_email: "",
            faculty_multiple_email: "",
            course_name: "",
            course_level: "",
            institute: "",
            mode: "",
            mode_discription: "",
            code: "",
            subcode: "",
            course_key_code: "",
            video_lecture_name: "",
            nickname: "",
            franchise_discount_50: "",
            image: "",
            mrp: "",
            sld_selling_price: "",
            gd_link: "",
            demo_link: "",
            demo_name: "",
            demo_link_is_available: "",
            eligible_cod: "",
            contact_technical_support: "",
            contact_tracking_support: "",
            validity: "",
            relevant_attempts: "",
            lecture_languages: "",
            duration_of_lecture: "",
            no_of_lectures: "",
            view_count: "",
            internet_connectivity: "",
            material_language: "",
            video_runs_on: "",
            lecture_recorded_on: "",
            system_requirements: "",
            additional_requirement: "",
            rank: "",
            sld_benefits: "",
            syllabus: "",
            validity_start: "",
            no_of_book: "",
            book_type: "",
            printable_or_not: "",
            no_of_views: "",
            topics_covered: "",
            details_amendments: "",
            fast_forward_speed: "",
            no_of_pd: 0,
            size_of_pd: 0,
            views_extension: "",
            validity_extension: "",
            why_to_buy: "",
            dispatch_by: "",
            dispatch_time: "",
            delievery_time: "",
            contact_for_doubt_solving: "",
            sld_discount_50: 0,
            discount_on_mrp_sp_by_faculty: "",
            discount_by_faculty: 0,
            discounnt_by_us: 0,
            start_date_dis_by_faculty: "",
            end_date_dis_by_faculty: "",
            start_date_dis_by_us: "",
            end_date_dis_by_us: "",
            discount_on_mrp_sp_by_us: "",
            imp_description: "",
            is_mrp_show: "",
            note: "",
            wallet_discount: 0,
            wallet_discount_is_applied: "",
            meta: "",
            video_lecture_title: "",
            is_not_available: "",
            allowed_payment_gateway: "",
            commission_offered: 0,
            referral_to_percentage: 0,
            referral_by_percentage: 0,
            invoice_institute_name: "",
            payment_to_faculty: 0,
            commision_amount: 0,
            price_onwhich_commission_calculated: 0,
            gst_applied_percent: 0,
            sharing_percent: 0,
            is_hidden: "",
        },


        // validationSchema: yup.object({
        //     faculty_email: yup.string().required(" faculty_email Required !"),
        //     faculty_multiple_email: yup.string().required(" faculty_multiple_email Required !"),
        //     course_level: yup.string().required(" course_level Required !"),
        //     course_name: yup.string().required(" course_name Required !"),
        //     institute: yup.string().required(" institute Required !"),
        //     mode: yup.string().required(" mode Required !"),
        //     mode_discription: yup.string().required(" mode_discription Required !"),
        //     code: yup.string().required(" code Required !"),
        //     subcode: yup.string().required(" subcode Required !"),
        //     course_key_code: yup.string().required(" course_key_code Required !"),
        //     type: yup.string().required(" type Required !"),
        //     video_lecture_name: yup.string().required(" video_lecture_name Required !"),
        //     nickname: yup.string().required(" nickname Required !"),
        //     franchise_discount_50: yup.string().required(" franchise_discount_50 Required !"),
        //     mrp: yup.string().required(" mrp Required !"),
        //     sld_selling_price: yup.string().required(" sld_selling_price Required !"),
        //     gd_link: yup.string().required(" gd_link Required !"),
        //     demo_link: yup.string().required(" demo_link Required !"),
        //     demo_name: yup.string().required(" demo_name Required !"),
        //     demo_link_is_available: yup.string().required(" demo_link_is_available Required !"),
        //     eligible_cod: yup.string().required(" eligible_cod Required !"),
        //     contact_technical_support: yup.string().required(" contact_technical_support Required !"),
        //     contact_tracking_support: yup.string().required(" contact_tracking_support Required !"),
        //     validity: yup.string().required(" validity Required !"),
        //     relevant_attempts: yup.string().required(" relevant_attempts Required !"),
        //     lecture_languages: yup.string().required(" lecture_languages Required !"),
        //     duration_of_lecture: yup.string().required(" duration_of_lecture Required !"),
        //     no_of_lectures: yup.string().required(" no_of_lectures Required !"),
        //     view_count: yup.string().required(" view_count Required !"),
        //     internet_connectivity: yup.string().required(" internet_connectivity Required !"),
        //     material_language: yup.string().required(" material_language Required !"),
        //     video_runs_on: yup.string().required(" video_runs_on Required !"),
        //     lecture_recorded_on: yup.string().required(" lecture_recorded_on Required !"),
        //     system_requirements: yup.string().required(" system_requirements Required !"),
        //     additional_requirement: yup.string().required(" additional_requirement Required !"),
        //     rank: yup.string().required(" rank Required !"),
        //     sld_benefits: yup.string().required(" sld_benefits Required !"),
        //     syllabus: yup.string().required(" syllabus Required !"),
        //     validity_start: yup.string().required(" validity_start Required !"),
        //     no_of_book: yup.string().required(" no_of_book Required !"),
        //     book_type: yup.string().required(" book_type Required !"),
        //     printable_or_not: yup.string().required(" printable_or_not Required !"),
        //     no_of_views: yup.string().required(" no_of_views Required !"),
        //     topics_covered: yup.string().required(" topics_covered Required !"),
        //     details_amendments: yup.string().required(" details_amendments Required !"),
        //     fast_forward_speed: yup.string().required(" fast_forward_speed Required !"),
        //     no_of_pd: yup.string().required(" no_of_pd Required !"),
        //     size_of_pd: yup.string().required(" size_of_pd Required !"),
        //     views_extension: yup.string().required(" views_extension Required !"),
        //     validity_extension: yup.string().required(" validity_extension Required !"),
        //     why_to_buy: yup.string().required(" why_to_buy Required !"),
        //     dispatch_by: yup.string().required(" dispatch_by Required !"),
        //     dispatch_time: yup.string().required(" dispatch_time Required !"),
        //     delievery_time: yup.string().required(" delievery_time Required !"),
        //     contact_for_doubt_solving: yup.string().required(" contact_for_doubt_solving Required !"),
        //     sld_discount_50: yup.string().required(" sld_discount_50 Required !"),
        //     discount_on_mrp_sp_by_faculty: yup.string().required(" discount_on_mrp_sp_by_faculty Required !"),
        //     discount_by_faculty: yup.string().required(" discount_by_faculty Required !"),
        //     discounnt_by_us: yup.string().required(" discounnt_by_us Required !"),
        //     start_date_dis_by_faculty: yup.string().required(" start_date_dis_by_faculty Required !"),
        //     end_date_dis_by_faculty: yup.string().required(" end_date_dis_by_faculty Required !"),
        //     start_date_dis_by_us: yup.string().required(" start_date_dis_by_us Required !"),
        //     end_date_dis_by_us: yup.string().required(" end_date_dis_by_us Required !"),
        //     discount_on_mrp_sp_by_us: yup.string().required(" discount_on_mrp_sp_by_us Required !"),
        //     imp_description: yup.string().required(" imp_description Required !"),
        //     is_mrp_show: yup.string().required(" is_mrp_show Required !"),
        //     note: yup.string().required(" note Required !"),
        //     wallet_discount: yup.string().required(" wallet_discount Required !"),
        //     wallet_discount_is_applied: yup.string().required(" wallet_discount_is_applied Required !"),
        //     meta: yup.string().required(" meta Required !"),
        //     video_lecture_title: yup.string().required(" video_lecture_title Required !"),
        //     is_not_available: yup.string().required(" is_not_available Required !"),
        //     allowed_payment_gateway: yup.string().required(" allowed_payment_gateway Required !"),
        //     commission_offered: yup.string().required(" commission_offered Required !"),
        //     referral_to_percentage: yup.string().required(" referral_to_percentage Required !"),
        //     referral_by_percentage: yup.string().required(" referral_by_percentage Required !"),
        //     invoice_institute_name: yup.string().required(" invoice_institute_name Required !"),
        //     payment_to_faculty: yup.string().required(" payment_to_faculty Required !"),
        //     commision_amount: yup.string().required(" commision_amount Required !"),
        //     price_onwhich_commission_calculated: yup.string().required(" price_onwhich_commission_calculated Required !"),
        //     gst_applied_percent: yup.string().required(" gst_applied_percent Required !"),
        //     sharing_percent: yup.string().required(" sharing_percent Required !"),
        //     is_hidden: yup.string().required(" is_hidden Required !"),
        // }),



    })
    const dialogclose =()=>{
        setDialog(false)  
    }
    return (
        <>

            {flag ?
                <form id="addfacultyform" onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}
                        style={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            marginTop: 0,
                            marginLeft: 0
                        }} >
                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined">
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Video Lectures Details
                                    </Typography>
                                    <Grid container spacing={3}>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label=" Video Lecture Name "
                                                variant="outlined"
                                                size="medium"
                                                name="video_lecture_name"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.video_lecture_name}
                                            />
                                            {formik.touched.video_lecture_name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.video_lecture_name}</span>}

                                        </Grid>

                                        <Grid item xs={4} >
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">
                                                    Course Name</InputLabel>
                                                <Select native
                                                    name="course_name"
                                                    onChange={(e) => {
                                                        formik.values.course_name = e.target.value
                                                        setManageId(e.target.value)
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.course_name}
                                                    label="Course Name">
                                                    <option aria-label="None" value="" />
                                                    {allCourse && allCourse.map(
                                                        (course) => <option value={course.name}>
                                                            {course.name}</option>)}
                                                </Select>
                                            </FormControl>
                                            {formik.touched.course_name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.course_name}</span>}

                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Video Lecture Title "
                                                variant="outlined"
                                                size="medium"
                                                name="video_lecture_title"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.video_lecture_title}
                                            />
                                            {formik.touched.video_lecture_title && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.video_lecture_title}</span>}

                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label=" Code"
                                                variant="outlined"
                                                size="medium"
                                                name="code"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.code}
                                            />
                                            {formik.touched.code && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.code}</span>}

                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Key Code"
                                                variant="outlined"
                                                size="medium"
                                                name="course_key_code"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.course_key_code}
                                            />
                                            {formik.touched.course_key_code && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.course_key_code}</span>}

                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                label=" Subcode"
                                                fullWidth={true}
                                                variant="outlined"
                                                size="medium"
                                                name="subcode"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.subcode}
                                            />
                                            {formik.touched.subcode && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.subcode}</span>}

                                        </Grid>

                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }}
                                                variant="outlined"
                                                className={classes.formControl}
                                                fullWidth={true}
                                                sx={{ m: 1, width: 300 }}>
                                                <InputLabel id="category-multiple-checkbox-label">Category</InputLabel>
                                                <Select
                                                    labelId="category-multiple-checkbox-label"
                                                    id="category-multiple-checkbox"
                                                    multiple
                                                    name="category"
                                                    onChange={categoryChange}
                                                    value={categoryValue}
                                                    input={<OutlinedInput label="category" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                >
                                                    <MenuItem>
                                                        <TextField
                                                            label=" Search"
                                                            fullWidth={true}
                                                            variant="outlined"
                                                            size="small"
                                                            name="Search"
                                                            onClick={(e) => setCategorySearch(e.target.value)}
                                                        />
                                                    </MenuItem>

                                                    {category && category.map((category) => (
                                                        <MenuItem key={category._id} value={category.category_name}>
                                                            <Checkbox checked={categoryValue.indexOf(category.category_name) > -1} />
                                                            <ListItemText primary={category.category_name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                        </Grid>

                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Mode</InputLabel>
                                                <Select native
                                                    name="mode"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.mode}
                                                    label="Mode">
                                                    {modes && modes.map((mode) => <option >{mode.name}</option>)}
                                                </Select>
                                            </FormControl>
                                            {formik.touched.mode && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.mode}</span>}

                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Mode description"
                                                variant="outlined"
                                                size="medium"
                                                name="mode_discription"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.mode_discription}
                                            />
                                            {formik.touched.mode_discription && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.mode_discription}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Lectures recorded on"
                                                variant="outlined"
                                                size="medium"
                                                name="lecture_recorded_on"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.lecture_recorded_on}
                                            />
                                            {formik.touched.lecture_recorded_on && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.lecture_recorded_on}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }}
                                                variant="outlined"
                                                className={classes.formControl}
                                                fullWidth={true}
                                                sx={{ m: 1, width: 300 }}>
                                                <InputLabel id="subject-multiple-checkbox-label">Subject</InputLabel>
                                                <Select
                                                    labelId="subject-multiple-checkbox-label"
                                                    id="subject-multiple-checkbox"
                                                    multiple
                                                    name="subject"
                                                    onChange={subjectChange}
                                                    value={subjectValue}
                                                    input={<OutlinedInput label="Subject" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                >
                                                    <MenuItem>
                                                        <TextField
                                                            label=" Search"
                                                            fullWidth={true}
                                                            variant="outlined"
                                                            size="small"
                                                            name="Search"
                                                        />
                                                    </MenuItem>
                                                    {subject && subject.map((subject) => (
                                                        <MenuItem key={subject._id} value={subject.subject_name}>
                                                            <Checkbox checked={subjectValue.indexOf(subject.subject_name) > -1} />
                                                            <ListItemText primary={subject.subject_name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                        </Grid>






                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Lecture Languages</InputLabel>
                                                <Select native
                                                    name="lecture_languages"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.lecture_languages}
                                                    label="Select Languages ">
                                                    <option aria-label="None" value="" />
                                                    <option>HINDI</option>
                                                    <option>ENGLISH</option>
                                                </Select>
                                            </FormControl>
                                            {formik.touched.lecture_languages && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.lecture_languages}</span>}

                                        </Grid>
                                        <Grid item xs={4}>

                                            <FormControl style={{ margin: 0 }} fullWidth={true}

                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">COD Eligible </InputLabel>
                                                <Select native
                                                    name="eligible_cod"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.eligible_cod}
                                                    label="Eligible COD">
                                                    <option aria-label="None" value={false} ></option>
                                                    <option value={true} >Yes</option>
                                                    <option value={false}>No</option>
                                                </Select>
                                            </FormControl>
                                            {formik.touched.eligible_cod && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.eligible_cod}</span>}

                                        </Grid>

                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Level</InputLabel>
                                                <Select native
                                                    name="course_level"


                                                    onChange={(e) => {
                                                        formik.values.course_level = e.target.value
                                                        setManageLevelId(e.target.value)
                                                          
                                                    }}

                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.course_level}
                                                    label="Level">
                                                    <option aria-label="None" value="" />
                                                    {allLevels && allLevels.map((level) => <option >{level.level_name}</option>)}
                                                </Select>
                                            </FormControl>
                                            {formik.touched.course_level && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.course_level}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Book type"
                                                variant="outlined"
                                                size="medium"
                                                name="book_type"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.book_type}
                                            />
                                            {formik.touched.book_type && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.book_type}</span>}

                                        </Grid>
                                        <Grid item xs={4}>

                                            <Button
                                                variant="outlined"
                                                fullWidth={true}
                                                startIcon={<BackupIcon />}
                                                color="primary"
                                                component="label" >
                                                Upload Image
                                                <input onChange={(e) => {
                                                    setImageVal(e.target.files[0])
                                                }} name="image" type="file" hidden />
                                            </Button>

                                        </Grid>
                                    </Grid>

                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        About Video Lectures
                                    </Typography>



                                    <Grid container spacing={3}>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Nick name"
                                                variant="outlined"
                                                size="medium"
                                                name="nickname"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.nickname}
                                            />
                                            {formik.touched.nickname && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.nickname}</span>}

                                        </Grid>

                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Printable</InputLabel>
                                                <Select native
                                                    name="printable_or_not"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.printable_or_not}
                                                    label="Printable">
                                                    <option aria-label="None" value={false} ></option>
                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>
                                                </Select>
                                            </FormControl>
                                            {formik.touched.printable_or_not && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.printable_or_not}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}

                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Select Syllabus</InputLabel>
                                                <Select native
                                                    name="syllabus"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.syllabus}
                                                    label="Select Syllabus">
                                                    <option aria-label="None" value="" />
                                                    <option >New</option>
                                                    <option >Old</option>
                                                </Select>
                                            </FormControl>
                                            {formik.touched.syllabus && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.syllabus}</span>}

                                        </Grid>

                                        <Grid item xs={4}>

                                            <TextField
                                                label="Topics Covered" fullWidth={true}
                                                name="topics_covered"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.topics_covered}
                                                variant="outlined"
                                                size="medium"
                                            />
                                            {formik.touched.topics_covered && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.topics_covered}</span>}

                                        </Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Duration"
                                                variant="outlined"
                                                size="medium"
                                                name="duration_of_lecture"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.duration_of_lecture}
                                            />
                                            {formik.touched.duration_of_lecture && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.duration_of_lecture}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Number of Lectures"
                                                variant="outlined"
                                                size="medium"
                                                name="no_of_lectures"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.no_of_lectures}
                                            />
                                            {formik.touched.no_of_lectures && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.no_of_lectures}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Relevant Attempts"
                                                variant="outlined"
                                                size="medium"
                                                name="relevant_attempts"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.relevant_attempts}
                                            />
                                            {formik.touched.relevant_attempts && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.relevant_attempts}</span>}

                                        </Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Rank"
                                                variant="outlined"
                                                size="medium"
                                                name="rank"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.rank}
                                            />
                                            {formik.touched.rank && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.rank}</span>}

                                        </Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Fast Forward Speed"
                                                variant="outlined"
                                                size="medium"
                                                name="fast_forward_speed"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.fast_forward_speed}
                                            />
                                            {formik.touched.fast_forward_speed && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.fast_forward_speed}</span>}

                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Validity
                                    </Typography>
                                    <Grid container spacing={3}>

                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Validity "
                                                variant="outlined"
                                                size="medium"
                                                name="validity"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.validity}
                                            />
                                            {formik.touched.validity && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.validity}</span>}

                                        </Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Starts From"
                                                variant="outlined"
                                                size="medium"
                                                name="validity_start"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.validity_start}
                                            />
                                            {formik.touched.validity_start && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.validity_start}</span>}

                                        </Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Extension "
                                                variant="outlined"
                                                size="medium"
                                                name="validity_extension"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.validity_extension}
                                            />
                                            {formik.touched.validity_extension && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.validity_extension}</span>}

                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Requirements
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4}  >

                                            <TextField
                                                label="System Requirements" fullWidth={true}
                                                multiline
                                                variant="outlined"
                                                size="medium"
                                                name="system_requirements"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.system_requirements}
                                            />
                                            {formik.touched.system_requirements && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.system_requirements}</span>}

                                        </Grid>
                                        <Grid item xs={4}  >

                                            <TextField
                                                fullWidth={true}
                                                label="Internet Connectivity"
                                                variant="outlined"
                                                size="medium"
                                                name="internet_connectivity"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.internet_connectivity}
                                            />
                                            {formik.touched.internet_connectivity && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.internet_connectivity}</span>}

                                        </Grid>
                                        <Grid item xs={4}  >
                                            <TextField
                                                fullWidth={true}
                                                label="Video Runs On"
                                                variant="outlined"
                                                size="medium"
                                                name="video_runs_on"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.video_runs_on}
                                            />
                                            {formik.touched.video_runs_on && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.video_runs_on}</span>}

                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Pricing
                                    </Typography>

                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="PRICE ON WHICH COMMISSION IS CALCULATED"
                                                variant="outlined"
                                                size="medium"
                                                name="price_onwhich_commission_calculated"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.price_onwhich_commission_calculated}
                                            />
                                            {formik.touched.price_onwhich_commission_calculated && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.price_onwhich_commission_calculated}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Payment to faculty"
                                                variant="outlined"
                                                size="medium"
                                                name="payment_to_faculty"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.payment_to_faculty}
                                            />
                                            {formik.touched.payment_to_faculty && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.payment_to_faculty}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Selling Price"
                                                variant="outlined"
                                                size="medium"
                                                name="sld_selling_price"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.sld_selling_price}
                                            />
                                            {formik.touched.sld_selling_price && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.sld_selling_price}</span>}

                                        </Grid>
                                        <Grid item xs={4}>

                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Show MRP</InputLabel>
                                                <Select native
                                                    name="is_mrp_show"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.is_mrp_show}
                                                    label="Show MRP">
                                                    <option aria-label="None" value={false} ></option>
                                                    <option value={true}>Show</option>
                                                    <option value={true}>Hide</option>
                                                </Select>
                                            </FormControl>
                                            {formik.touched.is_mrp_show && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.is_mrp_show}</span>}

                                        </Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="MRP"
                                                variant="outlined"
                                                size="medium"
                                                name="mrp"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.mrp}
                                            />
                                            {formik.touched.mrp && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.mrp}</span>}

                                        </Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Commission Offered"
                                                variant="outlined"
                                                size="medium"
                                                name="commission_offered"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.commission_offered}
                                            />
                                            {formik.touched.commission_offered && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.commission_offered}</span>}

                                        </Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                label="GST Applied %" fullWidth={true}
                                                multiline
                                                variant="outlined"
                                                size="medium"
                                                name="gst_applied_percent"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.gst_applied_percent}
                                            />
                                            {formik.touched.gst_applied_percent && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.gst_applied_percent}</span>}

                                        </Grid>
                                    </Grid>

                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Others
                                    </Typography>
                                    <Grid container spacing={3}>

                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}

                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Is Not Available</InputLabel>
                                                <Select native
                                                    label="Is Not Available"
                                                    name="is_not_available"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.is_not_available}>
                                                    <option aria-label="None" value={false} ></option>

                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>

                                                </Select>
                                            </FormControl>
                                            {formik.touched.is_not_available && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.is_not_available}</span>}

                                        </Grid>


                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Is Hidden</InputLabel>
                                                <Select native label="Is Hideen"
                                                    name="is_hidden"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.is_hidden}
                                                >
                                                    <option aria-label="None" value={false} ></option>

                                                    <option value={true} >yes</option>
                                                    <option value={false}>No</option>
                                                </Select>
                                            </FormControl>
                                            {formik.touched.is_hidden && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.is_hidden}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Invoice/Institute name"
                                                variant="outlined"
                                                size="medium"
                                                name="invoice_institute_name"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.invoice_institute_name}
                                            />
                                            {formik.touched.invoice_institute_name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.invoice_institute_name}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Google Drive Link"
                                                variant="outlined"
                                                size="medium"
                                                name="gd_link"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.gd_link}
                                            />
                                            {formik.touched.gd_link && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.gd_link}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Additional requirement "
                                                variant="outlined"
                                                size="medium"
                                                name="additional_requirement"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.additional_requirement}
                                            />
                                            {formik.touched.additional_requirement && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.additional_requirement}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Details Amendments"
                                                variant="outlined"
                                                size="medium"
                                                name="details_amendments"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.details_amendments}
                                            />
                                            {formik.touched.details_amendments && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.details_amendments}</span>}

                                        </Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                label="why to Buy" fullWidth={true}
                                                multiline
                                                variant="outlined"
                                                size="medium"
                                                name="why_to_buy"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.why_to_buy}
                                            />
                                            {formik.touched.why_to_buy && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.why_to_buy}</span>}

                                        </Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Note" multiline
                                                variant="outlined"
                                                size="medium"
                                                name="note"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.note}
                                            />
                                            {formik.touched.note && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.note}</span>}

                                        </Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Meta"
                                                variant="outlined"
                                                size="medium"
                                                name="meta"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.meta}
                                            />
                                            {formik.touched.meta && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.meta}</span>}

                                        </Grid>









                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Faculty
                                    </Typography>

                                    <Grid container spacing={3}>

                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Institute</InputLabel>
                                                <Select native
                                                    name="institute"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.institute}
                                                    label="Institute">
                                                    <option aria-label="None" value="" />
                                                    {allInstitute && allInstitute.map((institute) => <option >{institute.name}</option>)}
                                                </Select>
                                            </FormControl>
                                            {formik.touched.institute && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.institute}</span>}

                                        </Grid>

                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Email"
                                                variant="outlined"
                                                size="medium"
                                                name="faculty_email"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.faculty_email}
                                            />
                                            {formik.touched.faculty_email && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.faculty_email}</span>}

                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Multipal Email"
                                                variant="outlined"
                                                size="medium"
                                                name="faculty_multiple_email"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.faculty_multiple_email}
                                            />
                                            {formik.touched.faculty_multiple_email && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.faculty_multiple_email}</span>}

                                        </Grid>

                                        <Grid item xs={4}>

                                            <FormControl style={{ margin: 0 }}
                                                variant="outlined"
                                                className={classes.formControl}
                                                fullWidth={true}
                                                sx={{ m: 1, width: 300 }}>
                                                <InputLabel id="faculty-multiple-checkbox-label">Select Faculty</InputLabel>
                                                <Select
                                                    labelId="faculty-multiple-checkbox-label"
                                                    id="faculty-multiple-checkbox"
                                                    multiple
                                                    name="faculty"
                                                    onChange={facultyChange}
                                                    value={facultyValue}
                                                    input={<OutlinedInput label="Select Faculty" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                >
                                                    <MenuItem>
                                                        <TextField
                                                            label=" Search"
                                                            fullWidth={true}
                                                            variant="outlined"
                                                            size="small"
                                                            name="Search"
                                                        />
                                                    </MenuItem>
                                                    {faculty && faculty.map((faculty) => (
                                                        <MenuItem key={faculty._id} value={faculty.name}>
                                                            <Checkbox checked={facultyValue.indexOf(faculty.name) > -1} />
                                                            <ListItemText primary={faculty.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Discount
                                    </Typography>


                                    <Grid container spacing={3}>




                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Sharing %"
                                                variant="outlined"
                                                size="medium"
                                                name="sharing_percent"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.sharing_percent}
                                            />
                                            {formik.touched.sharing_percent && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.sharing_percent}</span>}

                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Comission Amount"
                                                variant="outlined"
                                                size="medium"
                                                name="commision_amount"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.commision_amount}
                                            />
                                            {formik.touched.commision_amount && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.commision_amount}</span>}

                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="SLD benefits"
                                                variant="outlined"
                                                size="medium"
                                                name="sld_benefits"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.sld_benefits}
                                            />
                                            {formik.touched.sld_benefits && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.sld_benefits}</span>}

                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                label="SLD50 Coupon Discount" fullWidth={true}
                                                variant="outlined"
                                                size="medium"
                                                name="sld_discount_50"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.sld_discount_50}
                                            />
                                            {formik.touched.sld_discount_50 && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.sld_discount_50}</span>}

                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                label="DIS50 Franchise Discount" fullWidth={true}
                                                variant="outlined"
                                                size="medium"
                                                name="franchise_discount_50"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.franchise_discount_50}
                                            />
                                            {formik.touched.franchise_discount_50 && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.franchise_discount_50}</span>}

                                        </Grid>

                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">wallet Discount Applied</InputLabel>
                                                <Select native
                                                    label="wallet Discount Applied"
                                                    name="wallet_discount_is_applied"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.wallet_discount_is_applied}>
                                                    <option aria-label="None" value={false} ></option>

                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>
                                                </Select>
                                            </FormControl>
                                            {formik.touched.wallet_discount_is_applied && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.wallet_discount_is_applied}</span>}

                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Wallet %"
                                                variant="outlined"
                                                size="medium"
                                                name="wallet_discount"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.wallet_discount}
                                            />
                                            {formik.touched.wallet_discount && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.wallet_discount}</span>}

                                        </Grid>




                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Referral by In %"
                                                variant="outlined"
                                                size="medium"
                                                name="referral_by_percentage"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.referral_by_percentage}
                                            />
                                            {formik.touched.referral_by_percentage && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.referral_by_percentage}</span>}

                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                label="Referal to in %" fullWidth={true}
                                                variant="outlined"
                                                size="medium"
                                                name="referral_to_percentage"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.referral_to_percentage}
                                            />
                                            {formik.touched.referral_to_percentage && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.referral_to_percentage}</span>}

                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography className={classes.title}
                                                variant="h6"
                                                color="primary" gutterBottom>
                                                Discount By faculty
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Discount By Faculty "
                                                variant="outlined"
                                                size="medium"
                                                name="discount_by_faculty"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.discount_by_faculty}
                                            />
                                            {formik.touched.discount_by_faculty && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.discount_by_faculty}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Discount On MRP/SP </InputLabel>
                                                <Select native
                                                    label="Discount On"
                                                    name="discount_on_mrp_sp_by_faculty"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.discount_on_mrp_sp_by_faculty}>
                                                    <option aria-label="None" value="" />
                                                    <option >MPR</option>
                                                    <option >SP</option>
                                                </Select>
                                            </FormControl>
                                            {formik.touched.discount_on_mrp_sp_by_faculty && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.discount_on_mrp_sp_by_faculty}</span>}

                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="start_date_dis_by_faculty"
                                                variant="outlined"
                                                size="medium"
                                                name="start_date_dis_by_faculty"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.start_date_dis_by_faculty}
                                            />
                                            {formik.touched.start_date_dis_by_faculty && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.start_date_dis_by_faculty}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="end_date_dis_by_faculty"
                                                variant="outlined"
                                                size="medium"
                                                name="end_date_dis_by_faculty"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.end_date_dis_by_faculty}
                                            />
                                            {formik.touched.end_date_dis_by_faculty && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.end_date_dis_by_faculty}</span>}

                                        </Grid>



                                        <Grid item xs={12}>
                                            <Typography className={classes.title}
                                                variant="h6"
                                                color="primary" gutterBottom>
                                                Discount By Us
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Discount By Us"
                                                variant="outlined"
                                                size="medium"
                                                name="discounnt_by_us"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.discounnt_by_us}
                                            />
                                            {formik.touched.discounnt_by_us && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.discounnt_by_us}</span>}

                                        </Grid>


                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Discount On MRP/SP</InputLabel>
                                                <Select native
                                                    label="Discount On"
                                                    name="discount_on_mrp_sp_by_us"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.discount_on_mrp_sp_by_us}>
                                                    <option aria-label="None" value="" />
                                                    <option >MPR</option>
                                                    <option >SP</option>
                                                </Select>
                                            </FormControl>
                                            {formik.touched.discount_on_mrp_sp_by_us && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.discount_on_mrp_sp_by_us}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="start_date_dis_by_us"
                                                variant="outlined"
                                                size="medium"
                                                name="start_date_dis_by_us"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.start_date_dis_by_us}
                                            />
                                            {formik.touched.start_date_dis_by_us && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.start_date_dis_by_us}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="end_date_dis_by_us"
                                                variant="outlined"
                                                size="medium"
                                                name="end_date_dis_by_us"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.end_date_dis_by_us}
                                            />
                                            {formik.touched.end_date_dis_by_us && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.end_date_dis_by_us}</span>}

                                        </Grid>
                                        {/* 

                                    <Grid item xs={4}>
                                        <TextField
                                            label="Discount On MRP" fullWidth={true}
                                            variant="outlined"
                                            size="medium"
                                            name="discount_on_mrp"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.discount_on_mrp}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField
                                            label="Discount On MRP SP" fullWidth={true}
                                            variant="outlined"
                                            size="medium"
                                            name="discount_on_mrp_sp"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.discount_on_mrp_sp}
                                        />
                                    </Grid> */}


                                    </Grid>

                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Views
                                    </Typography>



                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Views Extension"
                                                variant="outlined"
                                                size="medium"
                                                name="views_extension"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.views_extension}
                                            />
                                            {formik.touched.views_extension && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.views_extension}</span>}

                                        </Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Number Of Views"
                                                variant="outlined"
                                                size="medium"
                                                name="no_of_views"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.no_of_views}
                                            />
                                            {formik.touched.no_of_views && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.no_of_views}</span>}

                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Views Count"
                                                variant="outlined"
                                                size="medium"
                                                name="view_count"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.view_count}
                                            />
                                            {formik.touched.view_count && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.view_count}</span>}

                                        </Grid>

                                    </Grid>

                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Link
                                    </Typography>

                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Demo Name "
                                                variant="outlined"
                                                size="medium"
                                                name="demo_name"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.demo_name}
                                            />
                                            {formik.touched.demo_name && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.demo_name}</span>}

                                        </Grid>
                                        {/* <Grid item xs={4}>
                                        <TextField
                                            fullWidth={true}
                                            label="Demo lectures"
                                            variant="outlined"
                                            size="medium"
                                            name="demo_link"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.demo_link}
                                        />
                                    </Grid> */}
                                        <Grid item xs={4}>
                                            <TextField
                                                label="Demo Link" fullWidth={true}
                                                variant="outlined"
                                                size="medium"
                                                name="demo_link"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.demo_link}
                                            />
                                            {formik.touched.demo_link && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.demo_link}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}
                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">
                                                    Demo Link Is Available</InputLabel>
                                                <Select native
                                                    name="demo_link_is_available"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.demo_link_is_available}
                                                    label="Demo Link Is Available">
                                                    <option aria-label="None" value={false} ></option>
                                                    <option value={true} >Yes</option>
                                                    <option value={false}>No</option>
                                                </Select>
                                            </FormControl>
                                            {formik.touched.demo_link_is_available && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.demo_link_is_available}</span>}

                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Material/Package
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <FormControl style={{ margin: 0 }} fullWidth={true}

                                                variant="outlined"
                                                className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-Institue-Name-native-simple">Choose Material Language</InputLabel>
                                                <Select native
                                                    name="material_language"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.material_language}
                                                    label="Choose Material Language ">
                                                    <option aria-label="None" value="" />
                                                    <option >Hindi</option>
                                                    <option >English</option>
                                                </Select>
                                            </FormControl>
                                            {formik.touched.material_language && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.material_language}</span>}


                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Number Of Books"
                                                variant="outlined"
                                                size="medium"
                                                name="no_of_book"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.no_of_book}
                                            />
                                            {formik.touched.no_of_book && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.no_of_book}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Size of PD"
                                                variant="outlined"
                                                size="medium"
                                                name="size_of_pd"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.size_of_pd}
                                            />
                                            {formik.touched.size_of_pd && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.size_of_pd}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                label="Number of PD" fullWidth={true}
                                                variant="outlined"
                                                size="medium"
                                                name="no_of_pd"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.no_of_pd}
                                            />
                                            {formik.touched.no_of_pd && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.no_of_pd}</span>}

                                        </Grid>
                                    </Grid>

                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent>
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Shipping/Delivery
                                    </Typography>

                                    <Grid container spacing={3}>
                                        {/* <Grid item xs={4}>
                                        <TextField
                                            fullWidth={true}
                                            label="Shipping method"
                                            variant="outlined"
                                            size="medium"
                                            name="meta"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.meta}
                                        />
                                    </Grid> */}


                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Allowed payment gateway"
                                                variant="outlined"
                                                size="medium"
                                                name="allowed_payment_gateway"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.allowed_payment_gateway}
                                            />
                                            {formik.touched.allowed_payment_gateway && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.allowed_payment_gateway}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="Dispatch By"
                                                variant="outlined"
                                                size="medium"
                                                name="dispatch_by"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.dispatch_by}
                                            />
                                            {formik.touched.dispatch_by && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.dispatch_by}</span>}

                                        </Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Dispatch Time"
                                                variant="outlined"
                                                size="medium"
                                                name="dispatch_time"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.dispatch_time}
                                            />
                                            {formik.touched.dispatch_time && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.dispatch_time}</span>}

                                        </Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth={true}
                                                label="Processing Time"
                                                variant="outlined"
                                                size="medium"
                                                name="delievery_time"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.delievery_time}
                                            />
                                            {formik.touched.delievery_time && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.delievery_time}</span>}

                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}  >
                            <Card className={classes.root}
                                variant="outlined"
                            >
                                <CardContent >
                                    <Typography className={classes.title}
                                        variant="h6"
                                        color="primary" gutterBottom>
                                        Support
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth='true'
                                                label="Contact for Doubt"
                                                variant="outlined"
                                                size="medium"
                                                name="contact_for_doubt_solving"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.contact_for_doubt_solving}
                                            />
                                            {formik.touched.contact_for_doubt_solving && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.contact_for_doubt_solving}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth={true}
                                                label="TRACKING SUPPORT"
                                                variant="outlined"
                                                size="medium"
                                                name="contact_tracking_support"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.contact_tracking_support}
                                            />
                                            {formik.touched.contact_tracking_support && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.contact_tracking_support}</span>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                label="Technical Support" fullWidth={true}
                                                variant="outlined"
                                                size="medium"
                                                name="contact_technical_support"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.contact_technical_support}
                                            />
                                            {formik.touched.contact_technical_support && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.contact_technical_support}</span>}

                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <TextareaAutosize fullWidth={true}
                                aria-label="IMP Description" style={{ width: "100%" }}
                                minRows={6} placeholder="Description"
                                name="imp_description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.imp_description}
                            />
                            {formik.touched.imp_description && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.imp_description}</span>}


                            {/* <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={this.onEditorStateChange}
                        />; */}
                        </Grid>


                        <Grid item xs={10}></Grid>
                        <Grid item xs={2} >
                            <Button
                                variant="contained"
                                onClick={dialogclose}
                                color="secondary">
                                Close
                            </Button>
                            <Button style={{ marginLeft: 20 }}
                                type="submit"
                                variant="contained"
                                color="primary">
                                Save
                            </Button>
                        </Grid>

                    </Grid>

                </form>
                :
                <div style={{ height: "80vh" }} className=" d-flex justify-content-center d-flex align-items-center">
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                </div>
            }

            <ToastContainer />

        </>

    )
}












// faculty_name, //#array
// faculty_email, //#array
// faculty_mrp, //#array
// discount_by_faculty, //#array
// discount_by_us, //#array
// start_date_dis_by_faculty, //#array
// end_date_dis_by_faculty, //#array
// start_date_dis_by_us, //#array
// end_date_dis_by_us, //#array
// commission_percentage, //#array
// gst_applied_percent, //#array
// gst_sharing_percent, //#array
// subject_code, //#array
// category_code, //#array
// varient_code, //#array
// mode_name, //#array
// mode_mrp, //#array
// mode_discription, //#array
// franchise_discount_50, //#array
// sld_discount_50, //#array
// view_count, //#array
// views_extension, //#array
// no_of_views, //#array
// no_of_pd, //#array
// size_of_pd, //#array
// validity, //#array
// validity_extension, //#array
// dispatch_by, //#array
// dispatch_time, //#array
// delievery_time, //#array
// is_deleted, //#array
// is_active, //#array
// is_hidden, //#array
// is_mrp_show, //#array
// wallet_discount, //#array
// wallet_discount_is_applied, //#array
// other_coupon_applied, //#array
// faculty_multiple_email, //#array
// course_name,
// level_name,
// institute_name,
// code,
// search_key_code,
// type,
// video_lecture_name,
// video_lecture_title,
// nickname,
// image,
// gd_link,
// demo_links, //#array
// demo_name,
// demo_link_is_available,
// eligible_cod,
// contact_technical_support, //#array
// contact_tracking_support, //#array
// relevant_attempt,
// lecture_languages, //#array
// duration_of_lecture,
// no_of_lectures,
// internet_connectivity,
// material_languages, //#array
// video_runs_on,
// lecture_recorded_on,
// system_requirements, //#array
// additional_requirement, //#array
// rank,
// sld_benefits, //#array
// syllabus,
// validity_start,
// no_and_type_of_book,
// book_type,
// printable_or_not,
// topics_covered,
// details_amendments,
// fast_forward_speed,
// why_to_buy, //#array
// contact_for_doubt_solving,
// imp_description, //#array
// shipping_method,
// note, //#array
// meta,
// meta_title,
// is_combo,
// is_available,
// allowed_payment_gateway, //#array
// referral_to_percentage,
// referral_by_percentage,
// invoice_institute_name,
// videoLecture_url_slag,