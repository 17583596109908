import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constant/BaseURL";
const token = localStorage.getItem("__authToken__");

export const failedOrderRequestApi = createApi({
    reducerPath: "failedOrderRequestapi",
    baseQuery: fetchBaseQuery({
      baseUrl: `${BASE_URL}/failed-orders-request/`,
    }),
    endpoints: (builder) => ({
  
      getAllFailedOrderRequest: builder.mutation({
        query: (body) => ({
          url: `all-failed-request`,
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body,
        }),
      }),
      deleteFailedOrderRequest: builder.mutation({
        query(id) {
          return {
            url: `${id}`,
            headers: {
              "x-access-token": token,
            },
            method: "DELETE",
          };
        },
      }),
      updateFailedOrderRequest: builder.mutation({
        query({body,id}) {
          return {
            url: `${id}`,
            headers: {
              "x-access-token": token,
            },
            method: "PUT",
            body: body,
          };
        },
      })
    }),
  });
  export const {
    useGetAllFailedOrderRequestMutation,
    useDeleteFailedOrderRequestMutation,
    useUpdateFailedOrderRequestMutation
  } = failedOrderRequestApi;