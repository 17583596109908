import React, { useState } from "react";
import {
  Grid,
  FormControl,
  Select,
  InputLabel,
  TextareaAutosize,
  MenuItem,
  Backdrop,
} from "@mui/material";
import TextField from "@material-ui/core/TextField";
import { CardContent, Button, CircularProgress } from "@material-ui/core";
import { useGetAllCallersQuery, useGetAllCategoriesQuery } from "../../../../services/services";
import { useFormik } from "formik";
import * as yup from "yup";
import { OutlinedInput } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "480px",
    },
  },
};

export default function AddMsgTemplate({ AddData, setDialog }) {
  const {data, isFetching , isLoading } = useGetAllCallersQuery()

  const formik = useFormik({
    initialValues: {
      templet_id: "",
      msg_body: "",
      title: "",
      sldUserIds: []
    },
    validationSchema: yup.object({
      templet_id: yup.string().required("Required !"),
      msg_body: yup.string().required("Required !"),
      title: yup.string().required("Required !"),
    }),
    onSubmit: (values) => {

      AddData({...values , sldUserIds: values.sldUserIds.map(caller=> caller.value) });
        
    },
  });



  if(isFetching || isLoading){
    return (
        <Backdrop 
        open ={true}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
  }

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: "500px" }}>
      <TextField
        name="templet_id"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.templet_id}
        fullWidth={true}
        label="Template Id "
        variant="outlined"
        size="small"
      />
      {formik.touched.templet_id && (
        <span style={{ color: "red", fontSize: "12px" }}>
          {formik.errors.templet_id}
        </span>
      )}

      <br />
      <br />

      <TextField
        name="title"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.title}
        fullWidth={true}
        label="title "
        variant="outlined"
        size="small"
      />
      {formik.touched.title && (
        <span style={{ color: "red", fontSize: "12px" }}>
          {formik.errors.title}
        </span>
      )}

      <br />
      <br />

      <TextareaAutosize
        name="msg_body"
        onChange={formik.handleChange}
        InputProps={{ inputProps: { min: 0 } }}
        onBlur={formik.handleBlur}
        value={formik.values.msg_body}
        fullWidth={true}
        variant="outlined"
        placeholder=" Msg Body"
        aria-label="minimum height"
        minRows={3}
        style={{ width: "100%" }}
      />
      {formik.touched.msg_body && (
        <span style={{ color: "red", fontSize: "12px" }}>
          {formik.errors.msg_body}
        </span>
      )}

      <br />
      <br />

       <InputLabel> Callers </InputLabel>
      <FormControl variant="outlined" fullWidth>
        <Select
        name="sldUserIds"
        multiple
        value={formik.values.sldUserIds}
        onBlur={formik.handleBlur}
        input={<OutlinedInput/>}
        renderValue={(selected)=> { return selected.map(ele=> ele.label).join(', ') } }
        MenuProps={MenuProps}

        >
            {
                data?.data.map(ele=>  ({label: ele.name , value: ele._id} ))?.map((option)=> {
                    return (
                        <MenuItem 
                        key={option.value} 
                        value={option} 
                        onClick={()=> {
                            if(formik.values.sldUserIds.findIndex(caller=> caller.value === option.value) > -1 ){
                                formik.setFieldValue('sldUserIds' , formik.values.sldUserIds.filter(caller=> caller.value !== option.value) )
                            }else {
                                formik.setFieldValue('sldUserIds', [...formik.values.sldUserIds, option])
                            }
                        }}

                        style={{   backgroundColor: formik.values.sldUserIds.findIndex(caller=> caller.value === option.value) > -1 && "rgb(151 194 245 / 87%)"  }}

                          >
                            {option.label}
                        </MenuItem>
                    )
                })
            }

        </Select>
      </FormControl>
      <Grid container justifyContent="flex-end" spacing={2} mt={2}>
        <Grid item>
          <Button
            onClick={() => setDialog(false)}
            variant="contained"
            color="secondary"
          >
            Close
          </Button>
        </Grid>
        <Grid item>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
