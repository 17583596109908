import React from 'react'
import { useFormik } from 'formik';

import {
    FormControl, TextField, Grid,
    InputLabel, Select, makeStyles, Button , FormControlLabel,Checkbox

} from '@material-ui/core';
import { useGetAllUserGroupNamesQuery } from '../../../services/services';
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 450,
    },
});


export default function Updateslduser({ editfaculty, editDialog, UpdateData }) {
    const classes = useStyles();
    const getAllUserGroup = useGetAllUserGroupNamesQuery()
    let alluserGroup = getAllUserGroup.data

    const formik = useFormik({
        initialValues: {
            email: editfaculty.email,
            name: editfaculty.name,
            username: editfaculty.username,
            //password: editfaculty.password,
            mobile: editfaculty.mobile,
            group_id: editfaculty.group_id,
            can_view_lecture_sharing: editfaculty.can_view_lecture_sharing ,
            offline_caller:editfaculty?.offline_caller,
            online_caller:editfaculty?.online_caller
        },
        onSubmit: (values) => {
            var form_data = new FormData()
            for (let key in values) {
                form_data.append(key, values[key])
            }
            UpdateData(values, editfaculty._id)
        }
    })
    const dialogclose = () => { editDialog(false) }
    return (
        <div>
            <form onSubmit={formik.handleSubmit}
                style={{ width: 400 }}
            >
                <Grid container spacing={3} >
                    <Grid item xs={12}>

                        <FormControl
                            style={{ margin: 0 }}
                            fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel
                                htmlFor="outlined-institute-Name-native-simple">
                                Group Name
                            </InputLabel>
                            <Select
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.group_id}
                                label="Group Name"
                                name="group_id">
                                <option aria-labe />

                                {alluserGroup &&
                                    alluserGroup.data.map(data => <option value={data._id}>
                                        {data.group_name}
                                    </option>)}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>

                        <FormControl
                            style={{ margin: 0 }}
                            fullWidth={true}
                            variant="outlined"
                            className={classes.formControl}>
                            <InputLabel
                                htmlFor="outlined-institute-Name-native-simple">
                                Can View Lecture Sharing
                            </InputLabel>
                            <Select
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.can_view_lecture_sharing}
                                label="Can View Lecture Sharing"
                                name="can_view_lecture_sharing">
                                <option aria-labe />
                                <option value={true}>
                                    Yes
                                </option>
                                <option value={false}>
                                    No
                                </option>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            fullWidth={true}
                            label="Name"
                            variant="outlined"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="username"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.username}
                            fullWidth={true}
                            label="Username"
                            variant="outlined"
                            size="small"
                        />
                    </Grid >

                    <Grid item xs={12}>
                        <TextField
                            name="mobile"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.mobile}
                            fullWidth={true}
                            label="Mobile"
                            variant="outlined"
                            size="small"
                        />
                    </Grid >

                    <Grid item xs={12}>
                        <TextField
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            fullWidth={true}
                            label="Email"
                            variant="outlined"
                            size="small"
                        />
                    </Grid > 
                    <Grid item xs={6}>
                    <FormControlLabel
                       control={<Checkbox name="online_caller"
                        checked={formik?.values?.online_caller}
                          color="primary" 
                          value={formik?.values?.online_caller}
                         onChange={(e)=>{
                            formik?.setFieldValue('online_caller' ,e.target.checked )
                        }} 
                         />}
                    label="Online User"
                  />
                        {formik.touched.online_caller && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.online_caller}</span>}


                    </Grid> 
                    <Grid item xs={6}>
                    <FormControlLabel
                       control={<Checkbox name="offline_caller"
                         checked={formik?.values?.offline_caller}
                          color="primary" 
                          value={formik?.values?.offline_caller}
                         onChange={(e)=>{
                            formik?.setFieldValue('offline_caller' ,e.target.checked )
                        }} 
                         />}
                    label="Offline User"
                  />
                        {formik.touched.offline_caller && <span style={{ color: "red", fontSize: "12px" }}>{formik.errors.offline_caller}</span>}


                    </Grid>

                    {/* <Grid item xs={12}>
                        <TextField
                            name="password"
                            type="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            fullWidth={true}
                            label="Password"
                            variant="outlined"
                            size="small"
                        />
                    </Grid> */}

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => dialogclose()}
                        >
                            Close</Button>
                        <Button
                            type="submit"
                            style={{ marginLeft: 20 }}
                            variant="contained"
                            color="primary">
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form >
        </div >
    )
}
