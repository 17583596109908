import React, { useState, useEffect, Link, state } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import AddDataHeader from "../AddDataHeader";
import {
  useGetGstRequestsMutation,
  useUpdateGSTRequestDetailsMutation,
  useApproveRequestMutation,
  useDisapproveRequestMutation,
} from "../../../../services/StudentRequestsSevices";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import DataNotFound from "../DataNotFound";
import moment from "moment";
import {
  Button,
  Grid,
  IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, TextField,
  Avatar, Accordion, AccordionSummary, Typography, AccordionDetails
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import * as yup from "yup";
import { useFormik } from "formik";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ViewGSTInvoiceRequest from "./gst-invoice-request/ViewGSTInvoiceRequest";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Filters from '../Filters';
import AuthWrapper from "../../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../../utils/getFilterFunction";

export default function GSTInvoiceRequests() {
  const columns = [
    {
      id: "SNo",
      label: "S.No",
      minWidth: 20,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "user",
      label: "User",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "order",
      label: "Order",
      minWidth: 20,
      maxWidth: 10,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      style: { maxWidth: 250, overFlow: "scroll" },
    },
    {
      id: "dates",
      label: "Dates",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "gstDetails",
      label: "GST Details",
      minWidth: 20,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "invoiceCreated",
      label: "Invoice Created",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "requestStatus",
      label: "Request Status",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "action",
      label: "Actions",
      minWidth: 20,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: ["ORDER_REQUEST_GST_INVOICE_REQUEST_VIEW", "ORDER_REQUEST_GST_INVOICE_REQUEST_INITIATE_GST"]
    },
  ];

  function createData(
    SNo,
    user,
    order,
    dates,
    gstDetails,
    invoiceCreated,
    requestStatus,
    action
  ) {
    return {
      SNo,
      user,
      order,
      dates,
      gstDetails,
      invoiceCreated,
      requestStatus,
      action,
    };
  }

  const orderRequestStatusList = ["PENDING", "APPROVE", "DISAPPROVE"]
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredPagination, setFilteredPagination] = useState([]);
  let startDate = filteredPagination.filter((e, i) => { return e.fieldName == "startDate" && e.value })
  let endDate = filteredPagination.filter((e, i) => { return e.fieldName == "endDate" && e.value })
  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: [
      "sld_order_id",
      "order_title",
      "shipping_details.mobile",
      "shipping_details.email",],
    filterBy: filteredPagination,
    page: page + 1,
    "dateFilter": {
      dateFilterKey: "user_gst_details.applied_on_date",
      startDate: startDate.length ? startDate?.[0].value : null,
      endDate: endDate.length ? endDate?.[0].value : null
    }
  };
  let rows = [];
  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [initiateDialog, setInitiateDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [getAllData, getAllDataInfo] = useGetGstRequestsMutation();
  const [approveRequest, approveRequestInfo] = useApproveRequestMutation()
  const [disapproveRequest, disapproveRequestInfo] = useDisapproveRequestMutation()
  const [initiateRequest, initiateRequestInfo] =
    useUpdateGSTRequestDetailsMutation();
  const AllData = getAllDataInfo.data;
  const totalItem = AllData ? AllData.totalItem : 0;
  const [render, setRender] = useState(false);
  const [remarkDialog, setRemarkDialog] = useState(false)
  const [approveRemarkData, setApproveRemarkData] = useState({
    key: "user_gst_details",
    approval_remark: "",
    remarkError: ""
  })
  const [typeOfBtnClicked, setTypeOfBtnClicked] = useState('')

  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page, filteredPagination]);

  let totalData = "ok";
  if (AllData) {
    totalData = AllData.totalItem;
    rows = AllData.data.map((ele, index) =>
      createData(
        index + 1,
        <div className="">
          <span className="text-info font-weight-bold">
            {ele?.user_details?.first_name} {ele?.user_details?.last_name}
          </span>
          <br />
          <span className="text-muted font-weight-bold">
            {ele?.user_details?.mobile}
          </span>
          <br />
          <span className="text-muted">({ele?.user_details?.email})</span>
        </div>,
        <div className="">
          <span className="text-info font-weight-bold">{ele?.sld_order_id}</span>
          <br />
          <span className="text-muted">({ele?.order_title})</span>
        </div>,
        <div className="">
          <span className="text-info font-weight-bold">Order Date</span>
          <br />
          <span className="text-muted font-weight-bold">
            {ele.order_date
              ? moment(ele?.order_date).format("DD-MM-YYYY hh:mm:ss A")
              : ""}
          </span>
          <br />
          <ArrowDownwardIcon fontSize="small" className="text-info" />
          <br />
          <span className="text-info font-weight-bold">Applied Date</span>
          <br />
          <span className="text-muted font-weight-bold">
            {ele?.applied_on_date
              ? moment(ele?.applied_on_date).format("DD-MM-YYYY hh:mm:ss A")
              : ""}
          </span>
          <br />
          <ArrowDownwardIcon fontSize="small" className="text-info" />
          <br />
          <span className="text-info font-weight-bold">
            Invoice Created Date
          </span>
          <br />
          <span className="text-muted font-weight-bold">
            {ele?.invoice_created_on_date
              ? moment(ele?.invoice_created_on_date).format("DD-MM-YYYY")
              : ""}
          </span>
          <br />
        </div>,
        <div className="">
          <span className="text-info font-weight-bold">Gst Billing Name</span>
          <br />
          <span className="text-muted font-weight-bold">
            {ele?.gst_billing_name}
          </span>
          <br />
          <span className="text-info font-weight-bold">Gst Number</span>
          <br />
          <span className="text-muted font-weight-bold">{ele?.gst_number}</span>
          <br />
          <span className="text-info font-weight-bold">Mobile Number</span>
          <br />
          <span className="text-muted font-weight-bold">{ele?.mobile}</span>
        </div>,
        ele?.invoice_created ? "YES" : "NO",
        <span className="text-info font-weight-bold">
          {ele?.status}
        </span>,
        <div className="">
          {
            ele?.status == "PENDING" ?
              <>
                <Tooltip placement="top" title="Approve Request" arrow>
                  <IconButton onClick={() => {
                    setRemarkDialog(true)
                    setGetid(ele?._id)
                    setTypeOfBtnClicked("APPROVE")
                  }} ><CheckIcon color="primary" /></IconButton>
                </Tooltip>
                <Tooltip placement="top" title="Disapprove Request" arrow>
                  <IconButton onClick={() => {
                    setRemarkDialog(true)
                    setGetid(ele?._id)
                    setTypeOfBtnClicked("DISAPPROVE")
                  }} ><CloseIcon color="primary" /></IconButton>
                </Tooltip>
              </>
              : null
          }
          {!ele?.invoice_created && ele?.is_approved ? (
            <AuthWrapper type="ACTION" name="ORDER_REQUEST_GST_INVOICE_REQUEST_INITIATE_GST">
              <Tooltip placement="top" title="Initiate Request" arrow>
                <IconButton
                  onClick={() => {
                    setInitiateDialog(true);
                    setGetid(ele?._id);
                  }}
                >
                  <EditIcon color="primary" />
                </IconButton>
              </Tooltip>
            </AuthWrapper>
          ): null}
          <AuthWrapper type="ACTION" name="ORDER_REQUEST_GST_INVOICE_REQUEST_VIEW">
            <EditDelete
              setViewDialog={setViewDialog}
              type="view"
              setData={setData}
              data={ele}
            />
          </AuthWrapper>
        </div>
      )
    );
  }

  const dialogclose = () => {
    setInitiateDialog(false)
    setViewDialog(false)
    setRender(!render)
    setRemarkDialog(false)
  };

  const handleApproveRemarkSubmit = () => {
    if (!approveRemarkData.approval_remark) {
      setApproveRemarkData({ ...approveRemarkData, remarkError: "Required!" })
      return
    }
    if (approveRemarkData.approval_remark && getid) {
      approveRequest({ body: { key: approveRemarkData.key, approval_remark: approveRemarkData.approval_remark }, id: getid })
      return
    }
  }

  const handleDisapproveRemarkSubmit = () => {
    if (!approveRemarkData.approval_remark) {
      setApproveRemarkData({ ...approveRemarkData, remarkError: "Required!" })
      return
    }
    if (approveRemarkData.approval_remark && getid) {
      disapproveRequest({ body: { key: approveRemarkData.key, disapproval_remark: approveRemarkData.approval_remark }, id: getid })
      return
    }
  }

  return (
    <div>
      <AddDataHeader
        setPage={setPage}
        page={page}
        totalItem={totalItem}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchBarApi={getAllData}
        paginationData={paginationData}
        isButton={false}
        addBtn={true}
        isSearch={
          !AuthWrapper({
            type: "ACTION",
            name: "ORDER_REQUEST_GST_INVOICE_REQUEST_LIST",
            inBoolean: true,
          })
        }
        isPagination={
          !AuthWrapper({
            type: "ACTION",
            name: "ORDER_REQUEST_GST_INVOICE_REQUEST_LIST",
            inBoolean: true,
          })
        }
        filteredPagination={filteredPagination}
      />

      <AuthWrapper type="ACTION" name="ORDER_REQUEST_GST_INVOICE_REQUEST_LIST">
        {/* {
          !viewDialog &&
          <Accordion className=''>
            <AccordionSummary className='text-primary'
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header" >
              <Typography className='d-flex align-items-center' ><FilterListIcon /> <span > Filter</span></Typography>
            </AccordionSummary>
            <AccordionDetails className=''>
              <Filters
                setFilteredPagination={setFilteredPagination}
                isOrderRequestStatusFilter={false}
                module="orderRequest"
                filterListingData={{ orderRequestStatusList: orderRequestStatusList }}
                isFromFilter={true}
                isToFilter={true}
              />
            </AccordionDetails>
          </Accordion>
        } */}
        {/* {addDataInfo.isLoading || deleteDataInfo.isLoading || updateDataInfo.isLoading ? <Loader /> : <> */}
        {!viewDialog ? (
          rows.length ? (
            <CreateTableAddButton
              maxWidth="md"
              fullWidth={false}
              rows={rows}
              columns={getFilterdColumns(columns)}
              isButton={true}
            />
          ) : totalData == 0 ? (
            <DataNotFound />
          ) : (
            <SkeletonListing />
          )
        ) : (
          <ViewGSTInvoiceRequest
            viewDialog={viewDialog}
            data={data}
            setViewDialog={setViewDialog}
          />
        )}
      </AuthWrapper>
      {/* </>} */}
      {
        remarkDialog && <Dialog fullWidth={true} open={remarkDialog} onClose={dialogclose}>

          <DialogTitle>Remark</DialogTitle>
          <DialogContent>

            <TextField

              margin="dense"
              // id="name"
              name="remark"
              label="remark"
              type="text"
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setApproveRemarkData({ ...approveRemarkData, approval_remark: e.target.value })

              }}
              multiline
              minRows={4}
            // onBlur={handleBlur}
            // value={value}
            />
            <p className='text-danger'>{approveRemarkData.remarkError}</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setRemarkDialog(false)
              setApproveRemarkData({ ...approveRemarkData, remarkError: "" })
            }}>Cancel</Button>
            <Button
              // type="submit"
              style={{ marginLeft: 20 }}
              variant="contained" color="primary"
              onClick={() => {
                typeOfBtnClicked == "APPROVE"
                  ? handleApproveRemarkSubmit()
                  : typeOfBtnClicked == "DISAPPROVE"
                    ? handleDisapproveRemarkSubmit()
                    : null
              }}
            > Save</Button>
          </DialogActions>
        </Dialog>
      }
      {initiateDialog && (
        <InitiateForm
          setInitiateDialog={setInitiateDialog}
          initiateDialog={initiateDialog}
          initiateRequest={initiateRequest}
          getid={getid}
          data={AllData.data}
        />
      )}
      {initiateRequestInfo && (
        <Toster info={initiateRequestInfo} dialogclose={dialogclose} />
      )}
      {approveRequestInfo && <Toster info={approveRequestInfo} dialogclose={dialogclose} />}
      {disapproveRequestInfo && <Toster info={disapproveRequestInfo} dialogclose={dialogclose} />}
    </div>
  );
}

function InitiateForm({
  setInitiateDialog,
  initiateDialog,
  initiateRequest,
  getid,
  data,
}) {
  let currentData = data.filter((e, i) => {
    return e._id == getid;
  });
  const formik = useFormik({
    initialValues: {
      actual_amount: 0,
      hsn_sac: "",
      CGST: 0,
      SGST: 0,
      IGST: 0,
      total_amount: 0,
      gst_amount: 0,

      net_amount_after_deducting_refund_cashback: 0,
      taxable_amount: 0,
      hsn_no_for_taxable_item: '',
      tax_amount: 0,
      tax_rate: 0,
      exempt_amount: 0,
      hsn_no_for_exempt_item: '',
      invoice_amount: 0,

      sld_order_id: currentData[0].sld_order_id,
      user_id: currentData[0].user_id,
      invoice_no: "",
    },
    validationSchema: yup.object({
      actual_amount: yup
        .number("Only Number !")
        .min(1, "Required !")
        .required("Only Number !"),
      CGST: yup.number("Only Number !"),
      SGST: yup.number("Only Number !"),
      IGST: yup.number("Only Number !"),
      total_amount: yup
        .number("Only Number !")
        .min(1, "Required !")
        .required("Only Number !"),
      gst_amount: yup
        .number("Only Number !")
        .min(1, "Required !")
        .required("Only Number !"),
      invoice_no: yup.string().required("Required"),

      net_amount_after_deducting_refund_cashback:yup
        .number("Only Number !")
        .min(1, "Required !")
        .required("Only Number !"),
      taxable_amount:yup
        .number("Only Number !")
        .min(1, "Required !")
        .required("Only Number !"),
      hsn_no_for_taxable_item:  yup.string().required("Required"),
      tax_amount:yup
        .number("Only Number !")
        .min(1, "Required !")
        .required("Only Number !"),
      tax_rate:yup
        .number("Only Number !")
        .min(1, "Required !")
        .required("Only Number !"),
      exempt_amount:yup
        .number("Only Number !")
        .min(1, "Required !")
        .required("Only Number !"),
      hsn_no_for_exempt_item:  yup.string().required("Required"),
      invoice_amount:yup
        .number("Only Number !")
        .min(1, "Required !")
        .required("Only Number !"),
    }),
    onSubmit: (values) => {
      initiateRequest({ body: values, id: getid });
    },
  });

  return (
    <Dialog
      fullWidth={true}
      open={initiateDialog}
      onClose={() => {
        setInitiateDialog(false);
      }}
    >
      <DialogTitle>Initiate GST Request</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <TextField
            margin="dense"
            // id="name"
            name="invoice_no"
            label="Invoice No."
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.invoice_no}
          />
          {formik.touched.invoice_no && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.invoice_no}
            </span>
          )}
          <br />
          <br />

          <TextField
            margin="dense"
            // id="name"
            name="actual_amount"
            label="Actual amount"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.actual_amount}
          />
          {formik.touched.actual_amount && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.actual_amount}
            </span>
          )}
          <br />
          <br />

          {/* HSN/SAC */}
          <TextField
            margin="dense"
            // id="name"
            name="hsn_sac"
            label="HSN/SAC"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.hsn_sac}
          />
          {formik.touched.hsn_sac && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.hsn_sac}
            </span>
          )}
          <br />
          <br />

          <TextField
            margin="dense"
            // id="name"
            name="CGST"
            label="CGST"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.CGST}
          />
          {formik.touched.CGST && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.CGST}
            </span>
          )}
          <br />
          <br />

          <TextField
            margin="dense"
            // id="name"
            name="SGST"
            label="SGST"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.SGST}
          />
          {formik.touched.SGST && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.SGST}
            </span>
          )}
          <br />
          <br />

          <TextField
            margin="dense"
            // id="name"
            name="IGST"
            label="IGST"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.IGST}
          />
          {formik.touched.IGST && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.IGST}
            </span>
          )}
          <br />
          <br />

          <TextField
            margin="dense"
            // id="name"
            name="total_amount"
            label="Total amount"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.total_amount}
          />
          {formik.touched.total_amount && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.total_amount}
            </span>
          )}
          <br />
          <br />

          <TextField
            margin="dense"
            // id="name"
            name="gst_amount"
            label="GST amount"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.gst_amount}
          />
          {formik.touched.gst_amount && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.gst_amount}
            </span>
          )}
          <TextField
            margin="dense"
            // id="name"
            name="net_amount_after_deducting_refund_cashback"
            label="Net Amount After Deducting Refund Cashback"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.net_amount_after_deducting_refund_cashback}
          />
          {formik.touched.net_amount_after_deducting_refund_cashback && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.net_amount_after_deducting_refund_cashback}
            </span>
          )}

          <TextField
            margin="dense"
            // id="name"
            name="taxable_amount"
            label="Taxable Amount"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.taxable_amount}
          />
          {formik.touched.taxable_amount && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.taxable_amount}
            </span>
          )}

          <TextField
            margin="dense"
            // id="name"
            name="hsn_no_for_taxable_item"
            label="Hsn No For Taxable Item"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.hsn_no_for_taxable_item}
          />
          {formik.touched.hsn_no_for_taxable_item && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.hsn_no_for_taxable_item}
            </span>
          )}

          <TextField
            margin="dense"
            // id="name"
            name="tax_amount"
            label="Tax Amount"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.tax_amount}
          />
          {formik.touched.tax_amount && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.tax_amount}
            </span>
          )}

          <TextField
            margin="dense"
            // id="name"
            name="tax_rate"
            label="Tax Rate"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.tax_rate}
          />
          {formik.touched.tax_rate && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.tax_rate}
            </span>
          )}

          <TextField
            margin="dense"
            // id="name"
            name="exempt_amount"
            label="Exempt Amount"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.exempt_amount}
          />
          {formik.touched.exempt_amount && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.exempt_amount}
            </span>
          )}


          <TextField
            margin="dense"
            // id="name"
            name="hsn_no_for_exempt_item"
            label="Hsn No For Exempt Item"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.hsn_no_for_exempt_item}
          />
          {formik.touched.hsn_no_for_exempt_item && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.hsn_no_for_exempt_item}
            </span>
          )}

          <TextField
            margin="dense"
            // id="name"
            name="invoice_amount"
            label="Invoice Amount"
            type="text"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.invoice_amount}
          />
          {formik.touched.invoice_amount && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.invoice_amount}
            </span>
          )}

          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setInitiateDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            style={{ marginLeft: 20 }}
            variant="contained"
            color="primary"
          >
            {" "}
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
