import React, { useState } from "react";
import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FileDownload } from "@mui/icons-material";
import * as FileSaver from 'file-saver'
import * as XLSX from "xlsx";
import { useExportProductCartDataMutation } from "../../../../../services/ProductCartServices/ProductCartServices";
import moment from "moment";
import Toster from "../../Toster";

const exportDataHeaders = [
  {
    label: "Date & Time",
    key: "createdAt",
  },
  {
    label: "First Name",
    key: "first_name",
  },
  {
    label: "Last Name",
    key: "last_name",
  },
  {
    label: "Email",
    key: "email",
  },
  {
    label: "Mobile No.",
    key: "mobile",
  },
  {
    label: "Note For Seller",
    key: "note_for_seller",
  },
  {
    label: "Product Name",
    key: "product_name",
  },
  {
    label: "MRP",
    key: "mode_mrp",
  },
  {
    label: "SLD Selling Price",
    key: "sld_selling_price",
  },
  {
    label: "Quantity",
    key: "product_quantity",
  },
  {
    label: "Product Type",
    key: "product_type",
  },
  {
    label: "Code",
    key: "code",
  },
  {
    label: "Varient Code",
    key: "product_varient_code",
  },
  {
    label: "Mode Name",
    key: "mode_name",
  },
  {
    label: "Validity",
    key: "validity",
  },
  {
    label: "Views",
    key: "no_of_views",
  },
  {
    label: "Relevant Attempt",
    key: "relevant_attempt",
  },
  {
    label: "Book Type",
    key: "book_type",
  },
  {
    label: "Languages",
    key: "lecture_languages",
  },
  {
    label: "Shipping Method",
    key: "shipping_method",
  },
  {
    label: "Additional Offers",
    key: "additional_offers",
  },
  {
    label: "Group/Module",
    key: "group_or_module",
  },
  {
    label: "Syllabus",
    key: "syllabus",
  },
  {
    label: "Courier Charges",
    key: "book_test_courier_charges",
  },
  {
    label: "Lecture Courier Type",
    key: "lecture_courier_type",
  },
  {
    label: "Is Active",
    key: "is_active",
  },
  {
    label: "Is Available",
    key: "is_available",
  },
  {
    label: "Is Faculty Active",
    key: "is_faculty_active",
  },
  {
    label: "Is Hidden",
    key: "is_hidden",
  },
];
const ParentComponent = ({ rowsPerPage, page , dateFilter }) => {
  const [isExporting, setIsExporting] = useState(false);
  const [exportData] = useExportProductCartDataMutation();

  const showSuccess = (message) => {
    const successInfo = {
      status: "fulfilled",
      data: {
        status: true,
        message: message,
      },
    };
    <Toster info={successInfo} />;
  };

  const showInfo = (message) => {
    const info = {
      status: "fulfilled",
      data: {
        status: false,
        message: message,
      },
    };
    <Toster info={info} />;
  };

  const showError = (message) => {
    const errorInfo = {
      status: "rejected",
      error: {
        message: message,
      },
    };
    <Toster info={errorInfo} />;
  };
  const handleExport = () => {
    setIsExporting(true);
    exportData({
      limit: rowsPerPage,
      query: "",
      params: ["first_name", "last_name", "product_name"],
      page: page,
      filterBy: [
        {
          fieldName: "",
          value: "",
        },
      ],
      dateFilter: {
        dateFilterKey :'createdAt',
        startDate: dateFilter?.startDate ? dateFilter?.startDate : "",
        endDate: dateFilter?.endDate ? dateFilter?.endDate : "",
      },
    })
      .then((res) => {
        const formattedData = res?.data?.data?.map((data) => ({
          ...data,
          createdAt: moment(data.createdAt).format("DD-MM-yyyy hh:mm A"),
        }));

        if (formattedData && formattedData.length > 0) {
          exportToExcel(formattedData, "product-cart");
          showSuccess("Data successfully downloaded");
        } else {
          showInfo("Data Not Found");
        }

        setIsExporting(false);
      })
      .catch((error) => {
        console.error("Export API error:", error);
        setIsExporting(false);
        showError("Error!!");
      });
  };

  const exportToExcel = (excelData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.aoa_to_sheet([
      exportDataHeaders.map((header) => header.label),
      ...excelData.map((data) =>
        exportDataHeaders.map((header) => data[header.key])
      ),
    ]);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Grid item>
      <LoadingButton
        variant="contained"
        size="small"
        onClick={handleExport}
        startIcon={<FileDownload />}
        loading={isExporting}
      >
        Export
      </LoadingButton>
    </Grid>
  );
};

export default ParentComponent;
