import React, { useEffect, useState } from "react";
import ChipInput from "material-ui-chip-input";
import {
  makeStyles,
  TextField,
  FormControl,
  InputLabel,
  Grid,
  Select,
  Button,
  Typography,
} from "@material-ui/core";
import BackupIcon from "@material-ui/icons/Backup";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  useGetallInstituteNamesQuery,
  useGetAllStatesQuery,
  useGetAllSocialNetworksQuery,
} from "../../../../services/services";

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    minWidth: 100,
  },
  tab: {
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));
export default function UpdateFaculty({ editfaculty, editDialog, UpdateData }) {
    
  const classes = useStyles();
  const [imageval, setImageVal] = useState("");
  const { data, isLoading } = useGetallInstituteNamesQuery();
  const getstates = useGetAllStatesQuery();
  let allStates = getstates.data;
  const getAllSocialNetworks = useGetAllSocialNetworksQuery();
  let allSocial = getAllSocialNetworks.data;
  const [chipRemark, setChipRemark] = useState();
  const [chipEmail, setChipEmail] = useState();
  const [socialLinks, setSocialLinks] = useState([]);
  const socialNetwork = [];
  if (!isLoading) {
    var institutedata = data.data;
  }
  const dialogclose = () => {
    editDialog(false);
  };
  const ChipChange = () => { };
  let chipmail = editfaculty.email[0];
  const defaultEmail = chipmail.split(",");

  let chipremark1 = editfaculty.remark[0];
  const defaultRemark = chipremark1 && chipremark1.split(",");

  useEffect(() => {
    setImageVal(editfaculty.image);
    setChipEmail(defaultEmail);
    setChipRemark(defaultRemark);
  }, []);

  const formik = useFormik({
    initialValues: {
      title: editfaculty.title,
      name: editfaculty.name,
      email: [],
      is_active: editfaculty.is_active,
      personal_number: editfaculty.personal_number,
      specialization: editfaculty.specialization,
      description: editfaculty.description,
      image: "",
      bank_ac_number: editfaculty.bank_ac_number,
      management_number: editfaculty.management_number,
      // state_gst_code: editfaculty.gst_state_code,
      gst_number: editfaculty.gst_number,
      remark: [],
      meta: editfaculty.meta,
      institute_id: editfaculty.institute.institute_id,
      social_media: [],
      keywords : editfaculty.keywords,
    },
    validationSchema: yup.object({
      title: yup.string().required("Required!"),
      // image: yup.object().required("Required!"),
      name: yup.string().required("Required !"),
      personal_number: yup.number().required("Required !"),
      specialization: yup.string().required("Required !"),
      description: yup.string().required("Required !"),
      bank_ac_number: yup.number().required("Required !"),
      management_number: yup.number().required("Required !"),
      gst_number: yup.string().required("Required !"),
      // state_gst_code: yup.number().required("Required !"),
      meta: yup.string().required("Required !"),
      is_active: yup.boolean().required("Required !"),
    }),
    onSubmit: (values) => {
        
      values.social_media = JSON.stringify(socialLinks);
      values.image = imageval;
      values.email = chipEmail;
      values.remark = chipRemark;
      var form_data = new FormData();
      for (let key in values) {
        form_data.append(key, values[key]);
      }
      UpdateData(form_data, editfaculty._id);
    },
  });

  return (
    <>
      <form id="addfacultyform" onSubmit={formik.handleSubmit}>
        <Grid
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 20,
            marginLeft: 10,
          }}
        >
          <Typography
            className={classes.title}
            variant="h6"
            color="primary"
            gutterBottom
          >
            Personal Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                name="title"
                label="Faculty Title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                fullWidth={true}
                variant="outlined"
                size="small"
              />
              {formik.touched.title && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.title}
                </span>
              )}
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="name"
                label="Faculty Name "
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                fullWidth={true}
                variant="outlined"
                size="small"
              />
              {formik.touched.name && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.name}
                </span>
              )}
            </Grid>

            <Grid item xs={4}>
              <TextField
                name="personal_number"
                label="Personal Number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.personal_number}
                fullWidth={true}
                variant="outlined"
                size="small"
              />
              {formik.touched.personal_number && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.personal_number}
                </span>
              )}
            </Grid>
            <Grid item xs={4}>
              <ChipInput
                label="Faculty Email "
                variant="outlined"
                fullWidth={true}
                newChipKeyCodes={[13, 32, 9]}
                defaultValue={defaultEmail}
                newChipKeys={["Space"]}
                onChange={(chips) => {
                  ChipChange(chips);
                  setChipEmail(chips);
                }}
              />
              {formik.touched.email && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.email}
                </span>
              )}
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="specialization"
                label="Faculty Specialization"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.specialization}
                fullWidth={true}
                multiline
                variant="outlined"
                size="medium"
              />
              {formik.touched.specialization && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.specialization}
                </span>
              )}
            </Grid>

            <Grid item xs={4}>
              <TextField
                name="description"
                label="Faculty Description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                fullWidth={true}
                multiline
                variant="outlined"
                size="medium"
              />
              {formik.touched.description && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.description}
                </span>
              )}
            </Grid>
            <Grid item xs={4}>
              <Button
                onChange={(e) => {
                  formik.setFieldValue("image", {
                    // fieldname: "image",
                    name: e.target.files[0].name,
                    // size: e.target.files[0].size,
                    type: e.target.files[0].type,
                  });
                }}
                onBlur={formik.handleBlur}
                variant="outlined"
                fullWidth={true}
                startIcon={<BackupIcon />}
                color="primary"
                component="label"
              >
                Upload Faculty Image
                <input
                  onChange={(e) => {
                    // setImageVal(e.target.value)
                    setImageVal(e.target.files[0]);
                  }}
                  name="image"
                  type="file"
                  hidden
                />
              </Button>
              {formik.touched.image && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.image}
                </span>
              )}
            </Grid>
          </Grid>
          <br />
          <Typography
            className={classes.title}
            variant="h6"
            color="primary"
            gutterBottom
          >
            Finance Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                name="bank_ac_number"
                label="Bank Account Number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bank_ac_number}
                fullWidth={true}
                variant="outlined"
                size="small"
              />
              {formik.touched.bank_ac_number && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.bank_ac_number}
                </span>
              )}
            </Grid>

            {/* <Grid item xs={4}>
              <FormControl
                style={{ margin: 0 }}
                fullWidth={true}
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-state_gst_code-Name-native-simple">
                  State
                </InputLabel>

                <Select
                  onChange={formik.handleChange}
                  id="newSkill"
                  onBlur={formik.handleBlur}
                  value={formik.values.state_gst_code}
                  label="State"
                  name="state_gst_code"
                >
                  <option aria-labe />

                  {getstates.isSuccess &&
                    allStates.date.map((data) => {
                      return <option value={data.code}>{data.state}</option>;
                    })}
                </Select>
              </FormControl>
              {formik.touched.state_gst_code && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.state_gst_code}
                </span>
              )}
            </Grid> */}

            <Grid item xs={4}>
              <TextField
                name="gst_number"
                label="GST Number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.gst_number}
                fullWidth={true}
                variant="outlined"
                size="small"
              />
              {formik.touched.gst_number && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.gst_number}
                </span>
              )}
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="management_number"
                label="Management Number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.management_number}
                fullWidth={true}
                variant="outlined"
                size="small"
              />
              {formik.touched.management_number && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.management_number}
                </span>
              )}
            </Grid>

            <Grid item xs={4}>
              <FormControl
                style={{ margin: 0 }}
                fullWidth={true}
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-institute-Name-native-simple">
                  Institute Name
                </InputLabel>

                <Select
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.institute_id}
                  label="Institute Name"
                  name="institute_id"
                >
                  <option aria-labe />

                  {!isLoading &&
                    institutedata.map((data) => (
                      <option id="Twenty" value={data._id}>
                        {data.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                style={{ margin: 0 }}
                fullWidth={true}
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-institute-Name-native-simple">
                  Active/Dective
                </InputLabel>

                <Select
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.is_active}
                  label="Active/Dective"
                  name="is_active"
                >
                  <option aria-labe />
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Select>
              </FormControl>
              {formik.touched.is_active && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.is_active}
                </span>
              )}
            </Grid>
          </Grid>
          <br />
          <Typography
            className={classes.title}
            variant="h6"
            color="primary"
            gutterBottom
          >
            Other Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <ChipInput
                label="Remark "
                variant="outlined"
                fullWidth={true}
                defaultValue={defaultRemark}
                newChipKeyCodes={[13, 32, 9]}
                newChipKeys={["Space"]}
                onChange={(chips) => {
                  ChipChange(chips);
                  setChipRemark(chips);
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                name="meta"
                label="Faculty Meta"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.meta}
                fullWidth={true}
                variant="outlined"
                size="small"
              />
              {formik.touched.meta && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.meta}
                </span>
              )}
            </Grid>

            <Grid item xs={4}>
              <TextField
                name="keywords"
                label="Keywords"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.keywords}
                fullWidth={true}
                variant="outlined"
                size="small"
              />
              {formik.touched.keywords && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.keywords}
                </span>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Typography
          className={classes.title}
          variant="h6"
          color="primary"
          gutterBottom
        >
          Social Networks
        </Typography>
        <Grid container spacing={3}>
          {allSocial &&
            allSocial.data.map((data, ind) => {
              socialNetwork.push({
                social_id: data._id,
                social_name: data.social_media_name,
                social_link: "",
              });
              return (
                <Grid item xs={6}>
                  <TextField
                    name="social_media"
                    label={data.social_media_name + " Link"}
                    onChange={(e) =>
                      (socialNetwork[ind].social_link = e.target.value)
                    }
                    fullWidth={true}
                    variant="outlined"
                    size="medium"
                  />
                </Grid>
              );
            })}
          <Grid item xs={12}>
            <div className="text-right">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => dialogclose()}
              >
                Close
              </Button>
              <Button
                type="submit"
                onClick={() => setSocialLinks(socialNetwork)}
                style={{ marginLeft: 20 }}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
