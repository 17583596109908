import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {

        color: theme.palette.text.secondary,
    },
    grid: {
        padding: theme.spacing(4),
    },
    authBgGrid: {
        padding: theme.spacing(4),
        background: "#5664D291",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    inputRoot: {
        fontSize: 16
    },
    labelRoot: {
        fontSize: 14,
    },
});

class ForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }



    render() {
        const { classes } = this.props;
        const { theme } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <div className={classes.root}>
                    <Grid container justify="center"
                        alignItems="center" style={{ height: "100vh" }}>
                        <Grid item xs={6} md={6} lg={6} xl={6}>
                            <Paper className={classes.paper}>
                                <Grid container justify="center" alignItems="center" direction="row">
                                    <Grid item xs={6} md={6} lg={6} xl={6} className={classes.grid}>
                                        <CssBaseline />
                                        <div className={classes.paper}>
                                            <div className="text-center mb-4">
                                                <Typography component="h2" className="mb-3" variant="h5" style={{ fontWeight: 500, color: "#424040" }}>
                                                    Welcome To <Link to="/login" style={{ textDecoration: "none" }}>LikhaPadi</Link></Typography>
                                            </div>
                                            <Typography component="h1" className="mb-3" variant="h5" style={{ fontWeight: 500, color: "#424040" }}>
                                                Forgot Password</Typography>
                                            <form className={classes.form} noValidate>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    id="mobile"
                                                    label="Mobile Number"
                                                    name="mobile"
                                                    autoComplete="mobile"
                                                    
                                                    InputProps={{ classes: { root: classes.inputRoot } }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.labelRoot,
                                                        }
                                                    }}
                                                />
                                                <Grid container alignItems="center" className="mt-3">
                                                    <Grid item xs>
                                                        <Link to="/otp-verification" style={{ textDecoration: "none" }}><Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.submit}
                                                        >Reset Password</Button></Link>
                                                    </Grid>
                                                </Grid>


                                                <Grid container alignItems="center">
                                                    <Grid item>
                                                        <h6 className="mt-3">Don't remember your mobile? <Link to="#" style={{ fontWeight: 600 }}>
                                                            Contact Support</Link></h6>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6} xl={6} className={classes.authBgGrid}>
                                        <img src="assets/images/password-pana.png" alt="" className="img-fluid" />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                    </Grid>
                </div>
            </ThemeProvider>
        )
    }
}


export default withStyles(useStyles, { withTheme: true })(ForgotPassword);