import React, { useState, useEffect, forwardRef } from "react";
import EditDelete from "../EditDelete";
import CreateTableAddButton from "../CreateTableAddButton";
import Toster from "../Toster";
import SkeletonListing from "../SkeletonListing";
import Loader from "../Loader";
import AddDataHeader from "../AddDataHeader";
import DataNotFound from "../DataNotFound";
import {
  AccordionDetails,
  AccordionSummary,
  Typography,
  Accordion,
  IconButton,
  TextField,
  Grid,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListIcon from "@material-ui/icons/FilterList";
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import Filters from "../Filters";
import FormDialog from "../reusableComponents/NewFormDialog";
import Add from "@material-ui/icons/Add";
import AddEditOfflineOrder from "./AddEditOfflineOrder";
import { useGetAllVirtualOrdersQuery, useUpdateOfflineOrderMutation } from "../../../../services/offlineModuleServices/offlineOrdersServices";
import moment from "moment";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

// **** Icon Imports
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { Button, Tooltip } from "@mui/material";
import UpdateFeesDialog from "./UpdateFeesDialog";
import AllOrderHistoryListView from "./order-history/AllOrderHistoryListView";

export default function AllVirtualOrders() {

  // **** services
  const [updateOrder, updateOrderInfo] = useUpdateOfflineOrderMutation();

  const columns = [
    {
      id: "orderDate",
      label: "Date",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "orderId",
      label: "Order Id",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "name",
      label: "Name",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "mobile",
      label: "Mobile",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "course",
      label: "Course",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "amount",
      label: "Amount",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "dueAmount",
      label: "Due Amount",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "receivePayment",
      label: "Receive Payment",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];




  function createData(
    orderDate,
    orderId,
    name,
    mobile,
    course,
    amount,
    dueAmount,
    receivePayment,
    actions
  ) {
    return {
      orderDate,
      orderId,
      name,
      mobile,
      course,
      amount,
      dueAmount,
      receivePayment,
      actions,
    };
  }

  const [filteredPagination, setFilteredPagination] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [render, setRender] = useState(false);
  const [dates, setDates] = useState([])
  const [endDateRange, setEndDateRange] = useState(null)
  const [startDateRange, setStartDateRange] = useState(null)
  const [viewOrderHistoryList, setViewOrderHistoryList] = useState(false)
  const [viewDateFilter, setViewDateFilter] = useState(false);


  const CustomInput = forwardRef((props, ref) => {
    const startDate = props.start !== null ? moment(props.start).format("DD-MM-YYYY") : ''
    const endDate = props.end !== null ? ` - ${moment(props.end).format("DD-MM-YYYY")}` : null
    const value = `${startDate}${endDate !== null ? endDate : ''}`
    if (props.start === null && props.dates.length && props.setDates) {
      props.setDates([])
    }
    // props.start === null && props.dates.length && props.setDates ? props.setDates([]) : null
    const updatedProps = { ...props }
    delete updatedProps.setDates
    return <TextField fullWidth inputRef={ref} {...updatedProps} label={props.label || ''} value={value} />
  })

  const paginationData = {
    orderBy: "",
    page: "",
    limit: "",
    dateFilter: {
      startDate: startDateRange ? moment(startDateRange).utcOffset("+05:30").format() : null,
      endDate: endDateRange ? moment(endDateRange).utcOffset("+05:30").format() : null,
    },
    search: "",
    searchIn: [
      "batch.batch_name",
      "email",
      "mobile_number",
      "student_name",
      "payment_method",
      "category.category_name",
      "subject.subject_name",
      "faculty.faculty_name",
      "course.course_name",
      "level.level_name",
      "cro_number",
      "sold_by_name"
    ]
  }

  const [pagination, setPagination] = useState(paginationData);


  const { data: AllVirtualOrders, isFetching: isAllVirtualOrderFetching, isLoading: isAllVirtualOrderLoading } = useGetAllVirtualOrdersQuery(pagination)

  useEffect(() => {
    setPagination(paginationData);
  }, [render, page, rowsPerPage, filteredPagination, startDateRange, endDateRange]);


  let rows = [];
  let [id, setId] = useState("");
  const [data, setData] = useState("");
  const [addDialog, setAddDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);

  const totalItem = AllVirtualOrders ? AllVirtualOrders.totalItem : 0;

  // useEffect(() => {
  //   getid && deleteExpense(getid) && setGetid("");
  // }, [getid]);

  let totalData = "ok";

  const handleOnChangeRange = dates => {
    const [start, end] = dates
    if (start !== null && end !== null) {
      setDates(dates)
    }
    setStartDateRange(start)
    setEndDateRange(end)
  }

  if (AllVirtualOrders) {
    totalData = AllVirtualOrders.totalItem;
    rows = AllVirtualOrders.data.map((ele, index) =>
      createData(
        <span> {moment(ele.order_date).format("DD MMM yyyy")} at {moment(ele.order_date).format("hh:mm:ss A")} </span>,
        ele.sld_order_id,
        ele.student_name,
        ele.mobile_number,
        ele.course.course_name,
        <span className="text-info"> &#8377; {ele.amount_after_discount} </span>,
        <span className="text-danger"> &#8377; {ele.due_amount} </span>,

        ele.due_amount != 0 ?
          <Button variant="contained" sx={{ bgcolor: "#5664d2" }} onClick={() => { setEditDialog(true); setData(ele) }} size="small"  > <SaveAltIcon fontSize="small" /> &nbsp; Payment In </Button>
          :
          <Button variant="contained" color="success" size="small" > <DoneAllIcon fontSize="small" /> &nbsp; Fully Paid </Button>,
        <div className="d-flex justify-content-center">
          <EditDelete
            type="view"
            setViewDialog={setViewDialog}
            setData={setData}
            data={ele._id}
          />
          {/* <EditDelete
            setEditDialog={setEditDialog}
            type="edit"
            setData={setData}
            setGetid={setGetid}
            data={ele}
          /> */}

          <IconButton onClick={() => { setViewOrderHistoryList(true); setId(ele._id) }} className="text-warning"><LocalPrintshopIcon /></IconButton>
          {/* <EditDelete type="delete" setGetid={setGetid} id={ele._id} /> */}

        </div>
      )
    );
  }

  // );
  //   }
  const dialogclose = () => {
    setEditDialog(false);
    setViewDialog(false);
    setAddDialog(false);
    setRender(!render);
  };
  return (
    <div>
      {!viewDialog && (
        <AddDataHeader
          addBtn={true}
          setPage={setPage}
          page={page}
          excelBtn={false}
          totalItem={totalItem}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setAddDialog={setAddDialog}
          searchBarApi={setPagination}
          paginationData={paginationData}
          isButton={true}
        />
      )}


      <Grid className=" d-flex justify-content-start pr-5 mb-3 "  >

        <Grid item >
          <Tooltip title="Search by date">
            <IconButton onClick={() => setViewDateFilter(!viewDateFilter)} >
              <CalendarMonthIcon fontSize="large" className="text-primary" />
            </IconButton>
          </Tooltip>
        </Grid>

        {viewDateFilter &&

          <Grid item xs={3} style={{ zIndex: 100 }}>
            <DatePicker
              isClearable
              selectsRange
              monthsShown={2}
              endDate={endDateRange}
              selected={startDateRange}
              startDate={startDateRange}
              shouldCloseOnSelect={false}
              id='date-range-picker-months'
              onChange={handleOnChangeRange}
              customInput={
                <CustomInput
                  dates={dates}
                  setDates={setDates}
                  label='Search by Date..'
                  end={endDateRange}
                  start={startDateRange}
                />
              }
            />
          </Grid>
        }
      </Grid >

      <>
        {
          isAllVirtualOrderFetching ||
            isAllVirtualOrderLoading ?
            <SkeletonListing />
            :
            rows.length ? (
              <CreateTableAddButton
                // editDialog={editDialog}
                setPage={setPage}
                rows={rows}
                // editform={
                //   <AddEditOfflineOrder formType="edit" setOpenOrderForm={setEditDialog} initialData={data} />
                // }
                columns={columns}
                isButton={true}
              // editFormName={"Edit Virtual Order"}
              />
            )
              :
              <DataNotFound />

        }

      </>

      {/* {
        viewDialog &&
        <OfflineOrderDetailsViewPage open={viewDialog} onClose={setViewDialog} data={data} />
      } */}
      {
        editDialog &&
        <UpdateFeesDialog onClose={(closeDialog) => { setEditDialog(false); closeDialog && closeDialog() }} data={data} updateOrder={updateOrder} />
      }
      {
        viewOrderHistoryList &&
        <AllOrderHistoryListView onClose={() => setViewOrderHistoryList(false)} id={id} />
      }
      {
        viewDialog &&
        <AllOrderHistoryListView onClose={() => setViewDialog(false)} id={data} action="view" />
      }

      {updateOrderInfo && <Toster info={updateOrderInfo} dialogclose={() => { setEditDialog(false) }} />}
    </div>
  );
}
