import React, { useState, useEffect } from "react";
import EditDelete from "./EditDelete";
import CreateTableAddButton from "./CreateTableAddButton";
import ViewCategory from "./ViewCategory";
import Toster from "./Toster";
import AddCourse from "./AddCourse";
import Loader from "./Loader";
import {
  useGetAllCourseFilterMutation,
  useDeleteCourseMutation,
  useAddCourseMutation,
  useUpdateCourseMutation,
  useGetAllSubjectNamesQuery,
} from "../../../services/services";
import { useGetAllLevelNamesQuery } from "../../../services/services";

import SkeletonListing from "./SkeletonListing";
import UpdateCourse from "./UpdateCourse.js";
import AddDataHeader from "./AddDataHeader";
import DataNotFound from "./DataNotFound";
import FormDialog from "./reusableComponents/NewFormDialog";
import ViewCourseDetails from "./ViewCourseDetails";
import moment from "moment";
import AuthWrapper from "../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../utils/getFilterFunction";

export default function Courses() {
  const columns = [
    { id: "sno", label: "S.No", align: "center", minWidth: 50 },

    { id: "name", label: "Course Name", align: "center", minWidth: 50 },
    { id: "code", label: "Course Code", align: "center", minWidth: 50 },
    { id: "rank_order", label: "Order Rank", align: "center", minWidth: 50 },
    { id: "type", label: "Type", align: "center", minWidth: 50 },
    {
      id: "isactive",
      label: "Active",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "createat",
      label: "Create At",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US").tg,
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: [
        "CONFIGURATION_COURSE_VIEW",
        "CONFIGURATION_COURSE_EDIT",
        "CONFIGURATION_COURSE_DELETE",
      ],
    },
  ];
  
  function createData(
    sno,
    name,
    code,
    rank_order,
    type,
    isactive,
    createat,
    actions
  ) {
    let date = createat.split("T");
    createat = date[0];
    return { sno, name, code, rank_order, type, isactive, createat, actions };
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: ["name"],
    page: page + 1,
  };

  let rows = [];

  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");

  const [AddDialog, setDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [getAllData, getAllDataInfo] = useGetAllCourseFilterMutation();
  const [deleteData, deleteDataInfo] = useDeleteCourseMutation();
  const [addData, addDataInfo] = useAddCourseMutation();
  const [updateSubjectData, updateDataInfo] = useUpdateCourseMutation();
  const {
    data: AllLevels,
    isAllLevelsFetching,
    isAllLevelsLoading,
  } = useGetAllLevelNamesQuery();

  const {
    data: AllSubjects,
    isAllSubjectsFetching,
    isAllSubjectsLoading,
  } = useGetAllSubjectNamesQuery();

  let AllSubjectsDetails = [];

  if (!isAllSubjectsFetching && !isAllSubjectsLoading && AllSubjects) {
    AllSubjectsDetails = [...AllSubjects.data];
  }

  const AllData = getAllDataInfo.data;

  const totalItem = AllData ? AllData.totalItem : 0;
  const [render, setRender] = useState(false);

  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page]);

  useEffect(() => {
    getid && deleteData(getid) && setGetid("");
  }, [getid]);

  const AddData = (data) => {
    addData(data);
  };
  const UpdateData = (data, id) => {
    updateSubjectData({ body: data, id: id });
  };
  let totalData = "ok";
  if (AllData) {
    totalData = AllData.totalItem;
    rows = AllData.data.map((data, index) =>
      createData(
        index + 1,
        data.name,
        data.course_code,
        data.rank_order,
        data.type,
        data.is_active ? "Yes" : "No",
        moment(data.createdAt).format("DD-MM-YYYY hh:mm:ss A"),
        <div className="d-flex justify-content-center">
          <AuthWrapper type="ACTION" name="CONFIGURATION_COURSE_VIEW">
            <EditDelete
              setViewDialog={setViewDialog}
              type="view"
              setData={setData}
              setGetid={setGetid}
              data={data}
            />
          </AuthWrapper>
          <AuthWrapper type="ACTION" name="CONFIGURATION_COURSE_EDIT">
            <EditDelete
              setEditDialog={setEditDialog}
              type="edit"
              setData={setData}
              setGetid={setGetid}
              data={data}
            />
          </AuthWrapper>
          <AuthWrapper type="ACTION" name="CONFIGURATION_COURSE_DELETE">
            <EditDelete type="delete" setGetid={setGetid} id={data._id} />
          </AuthWrapper>
        </div>
      )
    );
  }
  const dialogclose = () => {
    setEditDialog(false);
    setViewDialog(false);
    setDialog(false);
    setRender(!render);
  };
  return (
    <div>
      <AddDataHeader
        addFormName={"Add Course"}
        setPage={setPage}
        page={page}
        isSearch={
          !AuthWrapper({
            type: "ACTION",
            name: "CONFIGURATION_COURSE_LIST",
            inBoolean: true,
          })
        }
        isPagination={
          !AuthWrapper({
            type: "ACTION",
            name: "CONFIGURATION_COURSE_LIST",
            inBoolean: true,
          })
        }
        addBtn={
          !AuthWrapper({
            type: "ACTION",
            name: "CONFIGURATION_COURSE_ADD",
            inBoolean: true,
          })
        }
        excelBtn={false}
        totalItem={totalItem}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setAddDialog={setDialog}
        searchBarApi={getAllData}
        paginationData={paginationData}
        isButton={true}
        Adddialog={AddDialog}
        form={
          <AddCourse AddData={AddData} setDialog={setDialog} formType="add" />
        }
      />
      <AuthWrapper type="ACTION" name="CONFIGURATION_COURSE_LIST">
        {deleteDataInfo.isLoading ||
        addDataInfo.isLoading ||
        updateDataInfo.isLoading ? (
          <Loader />
        ) : (
          <>
            {" "}
            {!viewDialog ? (
              rows.length ? (
                <CreateTableAddButton
                  editDialog={editDialog}
                  rows={rows}
                  editform={
                    <AddCourse
                      formType="edit"
                      UpdateData={UpdateData}
                      editData={data}
                      editDialog={setEditDialog}
                    />
                  }
                  columns={getFilterdColumns(columns)}
                  isButton={true}
                  editFormName={"Edit Course"}
                />
              ) : totalData == 0 ? (
                <DataNotFound />
              ) : (
                <SkeletonListing />
              )
            ) : (
              <ViewCourseDetails
                open={viewDialog}
                setOpen={setViewDialog}
                data={data}
              />
            )}
          </>
        )}
      </AuthWrapper>
      {deleteDataInfo.data && (
        <Toster info={deleteDataInfo} dialogclose={dialogclose} />
      )}
      {addDataInfo.data && (
        <Toster info={addDataInfo} dialogclose={dialogclose} />
      )}
      {updateDataInfo && (
        <Toster info={updateDataInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
