import React, { useState, useEffect } from "react";
import EditDelete from "./EditDelete";
import CreateTableAddButton from "./CreateTableAddButton";
import UpdateCoupon from "./UpdateCoupon";
import ViewCoupons from "./ViewCoupons";
import Toster from "./Toster";
import Loader from "./Loader";
import AddDataHeader from "./AddDataHeader";
import {
  useGetAllModePaginationMutation,
  useGetAllModeQuery,
  useAddModesMutation,
  useDeleteModesMutation,
  useUpdateModesMutation,
} from "../../../services/services";
import SkeletonListing from "./SkeletonListing";
import DataNotFound from "./DataNotFound";
import AddScratchCards from "./AddScratchCards";
import ModeForm from "./ModeForm";
import moment from "moment";
import AuthWrapper from "../../../utils/AuthWrapper";
import { getFilterdColumns } from "../../../utils/getFilterFunction";

export default function Modes() {
  const columns = [
    {
      id: "modeName",
      label: "Mode",
      minWidth: 170,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "modeDescription",
      label: "Description",
      minWidth: 100,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "bookType",
      label: "Book Type",
      minWidth: 100,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "modeIsAtive",
      label: "Ative",
      minWidth: 170,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },

    {
      id: "modifiedAt",
      label: "Modified At",
      minWidth: 170,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "Action",
      label: "Action",
      minWidth: 170,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      authName: ["CONFIGURATION_MODE_EDIT", "CONFIGURATION_MODE_DELETE"],
    },
  ];

  function createData(
    modeName,
    modeDescription,
    bookType,
    modeIsAtive,
    modifiedAt,
    Action
  ) {
    return {
      modeName,
      modeDescription,
      bookType,
      modeIsAtive,
      modifiedAt,
      Action,
    };
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const paginationData = {
    limit: rowsPerPage,
    query: "",
    params: [
      "_id",
      "book_type",
      "is_active",
      "name",
      "description",
      "mode_code",
      "createdAt",
      "updatedAt",
    ],
    page: page + 1,
  };

  let rows = [];

  let [getid, setGetid] = useState("");
  const [data, setData] = useState("");
  const [AddDialog, setDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  // const getAllData = useGetAllModeQuery()
  const [getAllData, getAllDataInfo] =
    useGetAllModePaginationMutation(paginationData);
  const [deleteData, deleteDataInfo] = useDeleteModesMutation();
  const [addData, addDataInfo] = useAddModesMutation();
  const [updateModeData, updateModeDataInfo] = useUpdateModesMutation();
  const AllData = getAllDataInfo.data;
  const totalItem = AllData ? AllData.totalItem : 0;

  const [render, setRender] = useState(false);

  useEffect(() => {}, [render, rowsPerPage, page]);

  useEffect(() => {
    getid && deleteData(getid) && setGetid("");
  }, [getid]);

  const AddData = (data) => {
    addData(data);
  };

  useEffect(() => {
    getAllData(paginationData);
  }, [render, rowsPerPage, page]);
  let totalData = "ok";
  if (AllData) {
    totalData = AllData.totalItem;

    rows = AllData.data.map((data, index) =>
      createData(
        <div>
          <span className="text-info font-weight-bold">{data.name}</span>
          <br />
          <span className="text-muted">( {data.mode_code} )</span>
        </div>,
        data.description,
        data.book_type,
        data.is_active ? "Yes" : "No",
        moment(data.updatedAt).format("DD-MM-YYYY hh:mm:ss A"),
        <div className="d-flex justify-content-center">
          <AuthWrapper type="ACTION" name="CONFIGURATION_MODE_EDIT">
            <EditDelete
              setEditDialog={setEditDialog}
              type="edit"
              setData={setData}
              data={data}
            />
          </AuthWrapper>
          <AuthWrapper type="ACTION" name="CONFIGURATION_MODE_DELETE">
            <EditDelete type="delete" setGetid={setGetid} id={data._id} />
          </AuthWrapper>
        </div>
      )
    );
  }
  const dialogclose = () => {
    setEditDialog(false);
    setViewDialog(false);
    setDialog(false);
    setRender(!render);
  };
  return (
    <div>
      {!viewDialog && (
        <AddDataHeader
          addFormName={"Add Modes"}
          setPage={setPage}
          page={page}
          excelBtn={false}
          totalItem={totalItem}
          rowsPerPage={rowsPerPage}
          isSearch={
            !AuthWrapper({
              type: "ACTION",
              name: "CONFIGURATION_MODE_LIST",
              inBoolean: true,
            })
          }
          isPagination={
            !AuthWrapper({
              type: "ACTION",
              name: "CONFIGURATION_MODE_LIST",
              inBoolean: true,
            })
          }
          addBtn={
            !AuthWrapper({
              type: "ACTION",
              name: "CONFIGURATION_MODE_ADD",
              inBoolean: true,
            })
          }
          setRowsPerPage={setRowsPerPage}
          setAddDialog={setDialog}
          searchBarApi={getAllData}
          paginationData={paginationData}
          fullsize={false}
          isButton={true}
          Adddialog={AddDialog}
          form={<ModeForm AddData={AddData} setDialog={setDialog} />}
        />
      )}
      <AuthWrapper type="ACTION" name="CONFIGURATION_MODE_LIST">
        {deleteDataInfo.isLoading ||
        addDataInfo.isLoading ||
        updateModeDataInfo.isLoading ? (
          <Loader />
        ) : (
          <>
            {" "}
            {!viewDialog ? (
              rows.length ? (
                <CreateTableAddButton
                  editDialog={editDialog}
                  rows={rows}
                  editform={
                    <ModeForm
                      formType="edit"
                      initialValue={data}
                      updateModeData={updateModeData}
                      setEditDialog={setEditDialog}
                      open={editDialog}
                    />
                  }
                  columns={getFilterdColumns(columns)}
                  editFormName={"Edit Mode"}
                />
              ) : totalData == 0 ? (
                <DataNotFound />
              ) : (
                <SkeletonListing />
              )
            ) : (
              <ViewCoupons viewDialog={setViewDialog} data={data} />
            )}
          </>
        )}{" "}
      </AuthWrapper>

      {deleteDataInfo.data && (
        <Toster info={deleteDataInfo} dialogclose={dialogclose} />
      )}
      {addDataInfo.data && (
        <Toster info={addDataInfo} dialogclose={dialogclose} />
      )}
      {updateModeDataInfo && (
        <Toster info={updateModeDataInfo} dialogclose={dialogclose} />
      )}
    </div>
  );
}
