import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Checkbox,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import {
  useGetAllCoursesQuery,
  useGetAllSubjectNamesQuery,
  useGetAallFacultieNamesQuery,
  useGetAllLevelNamesQuery,
  useUpdateVideoLectureSingleFieldMutation,
} from "../../../services/services";
import SkeletonListing from "./SkeletonListing";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Delete from "@mui/icons-material/Delete";
import Add from "@material-ui/icons/Add";
import Toster from "./Toster";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function UpdateVideoLectureSingleField(props) {
  // **** Services
  const {
    data: allCourses,
    isFetching: isAllCoursesFetching,
    isLoading: isAllCoursesLoading,
  } = useGetAllCoursesQuery();
  const {
    data: allSubjects,
    isFetching: isAllSubjectFetching,
    isLoading: isAllSubjectLoading,
  } = useGetAllSubjectNamesQuery();
  const {
    data: allFaculties,
    isFetching: isAllFacultiesFetching,
    isLoading: isAllFacultiesLoading,
  } = useGetAallFacultieNamesQuery();
  const {
    data: allLevels,
    isFetching: isAllLevelsFetching,
    isLoading: isAllLevelsLoading,
  } = useGetAllLevelNamesQuery();
  const [updateData, updateDataInfo] =
    useUpdateVideoLectureSingleFieldMutation();


  // **** Destructuring Props
  const { type, onClose, name, label,options, getOptionLabel,initialValue, id, multiline } = props;

  // **** Form Initial Values
  let initialValues = {
    [name]: initialValue,
  };

  // **** Validation Schema
  let validationSchema = Yup.object({
    [name]:
      name == "course"
        ? Yup.array()
            .min(1, "This field is required ")
            .required("This field is required ")
        : name == "email"
        ? Yup.string()
            .email("Please enter valid email !")
            .required("This field is required !")
        : type == "multi-dynamic"
        ? Yup.array()
            .min(1, "This field is required")
            .required("This field is required")
        : name == "index"
        ? Yup.number()
            .typeError("Index should be number !")
            .required("This field is required !")
        : Yup.string().required("This field is required !"),
  });

  // **** on Submit Handler
  const onSubmitHandler = (values) => {
    let keyAndValueArray = Object.entries(values);
    let dataForUpdate;
      
    switch (type) {
      case "input":
        dataForUpdate = {
          key: keyAndValueArray[0][0],
          update_data: keyAndValueArray[0][1],
        };
          
        updateData({ body: dataForUpdate, id: id });
        break;

      case "multi-dynamic":
        dataForUpdate = {
          key: keyAndValueArray[0][0],
          update_data: keyAndValueArray[0][1].join(","),
        };
          
        updateData({ body: dataForUpdate, id: id });
        break;

      case "courier_charges":
        dataForUpdate = {
          key: keyAndValueArray[0][0],
          update_data: Object.fromEntries(
            keyAndValueArray[0][1].map((ele) => [ele.term, Number(ele.charges)])
          ),
        };
          
        updateData({ body: dataForUpdate, id: id });
        break;

      default:
        break;
    }
  };

  return isAllCoursesFetching ||
    isAllCoursesLoading ||
    isAllSubjectFetching ||
    isAllSubjectLoading ? (
    <SkeletonListing />
  ) : (
    <div>
      <Dialog open={true} maxWidth="md" fullWidth>
        <DialogTitle> Update {label} </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={onSubmitHandler}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <InputLabel style={{ marginBottom: 18 }} htmlFor={name}>
                      {" "}
                      {label}{" "}
                    </InputLabel>

                    {((type) => {
                      switch (type) {
                        case "input":
                          return (
                            <Field name={name}>
                              {({ field }) => (
                                <TextField
                                  {...field}
                                  fullWidth
                                  size="small"
                                  multiline={multiline}
                                />
                              )}
                            </Field>
                          );
                        case "autoComplete":
                          return (
                            <Field name={name}>
                              {({ field, form }) => (
                                <Autocomplete
                                  options={options}
                                  getOptionLabel={getOptionLabel}
                                  value={field.value}
                                  onChange={(e, newValue) => {
                                    form.setFieldValue(name, newValue);
                                  }}
                                  onBlur={() =>
                                    form.setFieldTouched(name, true)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={name}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />
                              )}
                            </Field>
                          );

                        case "multi-dynamic":
                          return (
                            <FieldArray name={name}>
                              {({ push, remove, form }) => (
                                <Grid>
                                  {form.values[name].map((ele, index) => (
                                    <Grid container spacing={2} sx={{ mt: 2 }}>
                                      <Grid item xs={11}>
                                        <Field name={`${name}[${index}]`}>
                                          {({ field }) => (
                                            <TextField
                                              {...field}
                                              fullWidth
                                              size="small"
                                              variant="outlined"
                                              multiline={multiline}
                                            />
                                          )}
                                        </Field>
                                      </Grid>

                                      <Grid item xs={1}>
                                        <Button
                                          variant="outlined"
                                          color="secondary"
                                          onClick={() => remove(index)}
                                        >
                                          <Delete color="error" />
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  ))}

                                  <Grid
                                    container
                                    justifyContent="flex-end"
                                    style={{ marginTop: 20 }}
                                  >
                                    <Button
                                      variant="contained"
                                      onClick={() => push("")}
                                      color="primary"
                                    >
                                      {" "}
                                      <Add /> Add{" "}
                                    </Button>
                                  </Grid>
                                </Grid>
                              )}
                            </FieldArray>
                          );

                        case "courier_charges":
                          return (
                            <FieldArray name={name}>
                              {({ push, remove, form }) => (
                                <Grid>
                                  {form.values[name].map((ele, index) => (
                                    <Grid container spacing={2} sx={{ mt: 2 }}>
                                      <Grid item xs={5.5}>
                                        <InputLabel mb={2}> Term </InputLabel>
                                        <Field name={`${name}[${index}].term`}>
                                          {({ field }) => (
                                            <TextField
                                              {...field}
                                              fullWidth
                                              size="small"
                                              variant="outlined"
                                            />
                                          )}
                                        </Field>
                                      </Grid>

                                      <Grid item xs={5.5}>
                                        <InputLabel mb={2}>
                                          {" "}
                                          Amount &#8377;{" "}
                                        </InputLabel>
                                        <Field
                                          name={`${name}[${index}].charges`}
                                        >
                                          {({ field }) => (
                                            <TextField
                                              {...field}
                                              fullWidth
                                              size="small"
                                              variant="outlined"
                                            />
                                          )}
                                        </Field>
                                      </Grid>

                                      <Grid
                                        container
                                        alignItems="flex-end"
                                        item
                                        xs={1}
                                      >
                                        <Button
                                          variant="outlined"
                                          color="secondary"
                                          onClick={() => remove(index)}
                                        >
                                          <Delete color="error" />
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  ))}

                                  <Grid
                                    container
                                    justifyContent="flex-end"
                                    style={{ marginTop: 20 }}
                                  >
                                    <Button
                                      variant="contained"
                                      onClick={() =>
                                        push({ term: "", charges: "" })
                                      }
                                      color="primary"
                                    >
                                      {" "}
                                      <Add /> Add{" "}
                                    </Button>
                                  </Grid>
                                </Grid>
                              )}
                            </FieldArray>
                          );

                        default:
                          break;
                      }
                    })(type)}

                    <ErrorMessage name={name}>
                      {(errMsg) => (
                        <Typography color="error"> {errMsg} </Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>
                <DialogActions className="mt-5">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onClose}
                  >
                    {" "}
                    Close{" "}
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    {" "}
                    Save{" "}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>

          {
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={updateDataInfo.isLoading}
              children={""}
            >
              <CircularProgress color="inherit" /> &nbsp; &nbsp; &nbsp;
              <Typography variant="h5">Saving...</Typography>
            </Backdrop>
          }
        </DialogContent>
      </Dialog>

      {updateDataInfo && <Toster info={updateDataInfo} dialogclose={onClose} />}
    </div>
  );
}

export default UpdateVideoLectureSingleField;
