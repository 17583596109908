import React from 'react';
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function HTMLeditor() {
    const [editor, setEditor] = React.useState(EditorState.createEmpty())
    return (
        <div className="m-4 cardShadow" >
            <Editor 
                editorState={editor}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => setEditor(e)}
            />
        </div>
    )
}
//