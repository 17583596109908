import React from "react";
import { Redirect } from "react-router-dom";

export default function WrapMainApp({ children, location }) {
  const token = localStorage.getItem("__authToken__");
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  if (token !== null && token !== undefined && isLoggedIn == "true") {
    let modules = localStorage.getItem("modules")
      ? JSON.parse(localStorage.getItem("modules"))
      : [];
    let authType = localStorage.getItem("authType");
    let is_module_auth = false;

    if (authType == "ADMIN" && modules.length == 0) {
      is_module_auth = true;
    } else {
      children.map((el, ind) => {
        if (
          el.props?.authModuleName?.some((ele) => {
            return modules.includes(ele);
          }) ||
          //&& el.props.path == location.pathname
          location.pathname == "/welcome" ||
          location.pathname == "/404"
        ) {
          is_module_auth = true;
        }
      });
    }

    if (true) {
      return children;
    }
  } else {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { massage: "You must be login", from: location.pathname },
        }}
      />
    );
  }
}
