import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Checkbox,
} from "@material-ui/core";
import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { Select, MenuItem } from "@material-ui/core";
import { useUpdateVideoLectureSingleVarientFieldMutation } from "../../../services/services";
import Toster from "./Toster";
import * as Yup from "yup";

function UpdateVideoLectureSingleVarientField(props) {
  const [updateData, updateDataInfo] =
    useUpdateVideoLectureSingleVarientFieldMutation();
  const {
    initialValue,
    onClose,
    name,
    label,
    type,
    varient_id,
    videoLecture_id,
    multiline,
    fields,
    multiInitialValues,
  } = props;

  let initialValues = {
    [name]: initialValue,
  };

   
  // **** on Submit Handler
  const onSubmitHandler = async (values) => {
    let keyAndValueArray = Object.entries(values);
    let dataForUpdate;
     
    switch (type) {
      case "input":
        dataForUpdate = {
          varient_id: varient_id,
          fieldName: keyAndValueArray[0][0],
          fieldData: keyAndValueArray[0][1],
        };
         
        updateData({ body: dataForUpdate, id: videoLecture_id });
        onClose();
        break;
      case "multi-input":
         
        for (let i = 0; i < keyAndValueArray.length; i++) {
          const dataForUpdate = {
            varient_id: varient_id,
            fieldName: keyAndValueArray[i][0],
            fieldData: keyAndValueArray[i][1],
          };
           
          await updateData({
            body: dataForUpdate,
            id: videoLecture_id,
          }).then((res) => {
             
            if (res && res.data && res.data?.status && i === keyAndValueArray.length - 1) {
              onClose();
            }
          });
        }
        break;

      case "boolean":
        const fieldData = values[name] === "Yes" ? true : false;
         

        dataForUpdate = {
          varient_id: varient_id,
          fieldName: name,
          fieldData: fieldData,
        };
         
        updateData({ body: dataForUpdate, id: videoLecture_id });
        onClose();
        break;

      case "activeStatus":
        const fieldDataStatus = values[name] === "Active" ? true : false;
         

        dataForUpdate = {
          varient_id: varient_id,
          fieldName: name,
          fieldData: fieldDataStatus,
        };
         
        updateData({ body: dataForUpdate, id: videoLecture_id });
        onClose();
        break;


      case "number":
        dataForUpdate = {
          varient_id: varient_id,
          fieldName: keyAndValueArray[0][0],
          fieldData: Number(keyAndValueArray[0][1]),
        };
         
        updateData({ body: dataForUpdate, id: videoLecture_id });
        onClose();
        break;
      default:
        break;
    }
  };
  let validationSchemaForInput = Yup.object({
    [name]: Yup.string().required(` ${label}  is required !`),
  });
  let validationSchemaForNumber = Yup.object({
    [name]: Yup.number()
      .min(1, ` ${label}  must have at one or more than one value !`)
      .required(` ${label}  is required !`),
  });

  const getValidationSchema = (type) => {
    switch (type) {
      case "input":
        return validationSchemaForInput;
      case "number":
        return validationSchemaForNumber;
      default:
        return undefined;
    }
  };

  return (
    <div>
      <Dialog open={true} maxWidth="md" fullWidth>
        <DialogTitle> Update field varient </DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            validationSchema={getValidationSchema(type)}
            initialValues={
              type === "multi-input" ? multiInitialValues : initialValues
            }
            onSubmit={onSubmitHandler}
          >
            {({ errors, touched }) => (
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    {type !== "multi-input" && (
                      <InputLabel style={{ marginBottom: 18 }} htmlFor={name}>
                        {" "}
                        {label}{" "}
                      </InputLabel>
                    )}
                    {((type) => {
                      switch (type) {
                        case "input":
                          return (
                            <div>
                              <Field name={name}>
                                {({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    size="small"
                                    multiline={multiline}
                                  />
                                )}
                              </Field>
                              {errors[name] && touched[name] && (
                                <Typography color="error">
                                  {errors[name]}
                                </Typography>
                              )}
                            </div>
                          );
                        case "multi-input":
                          return (
                            <>
                              <Grid container spacing={4}>
                                {fields?.map((field, fieldIndex) => {
                                  return (
                                    <Grid key={fieldIndex} item xs={12}>
                                      <InputLabel
                                        style={{ marginBottom: 18 }}
                                        htmlFor={field.name}
                                      >
                                        {" "}
                                        {field.label}{" "}
                                      </InputLabel>
                                      <Field name={field.name}>
                                        {({ field }) => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            size="small"
                                            multiline={field.multiline}
                                          />
                                        )}
                                      </Field>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </>
                          );
                        case "boolean":
                          return (
                            <Field name={name} initialValue={initialValue}>
                              {({ field, form }) => (
                                <>
                                  <Select
                                    {...field}
                                    fullWidth
                                    size="small"
                                    value={field.value}
                                    onChange={(event) => {
                                      form.setFieldValue(
                                        name,
                                        event.target.value
                                      );
                                        
                                    }}
                                  >
                                    <MenuItem value={"Yes"}>Yes</MenuItem>
                                    <MenuItem value={"No"}>No</MenuItem>
                                  </Select>
                                </>
                              )}
                            </Field>
                          );


                        case "activeStatus":
                          return (
                            <Field name={name} initialValue={initialValue}>
                              {({ field, form }) => (
                                <>
                                  <Select
                                    {...field}
                                    fullWidth
                                    size="small"
                                    value={field.value}
                                    onChange={(event) => {
                                      form.setFieldValue(
                                        name,
                                        event.target.value
                                      );
                                       
                                    }}
                                  >
                                    <MenuItem value={"Active"}>Active</MenuItem>
                                    <MenuItem value={"Deactive"}>Deactive</MenuItem>
                                  </Select>
                                </>
                              )}
                            </Field>
                          );


                        case "number":
                          return (
                            <div>
                              <Field name={name}>
                                {({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    type="number"
                                    size="small"
                                    multiline={multiline}
                                  />
                                )}
                              </Field>
                              {errors[name] && touched[name] && (
                                <Typography color="error">
                                  {errors[name]}
                                </Typography>
                              )}
                            </div>
                          );

                        default:
                          break;
                      }
                    })(type)}
                  </Grid>
                </Grid>

                <DialogActions className="mt-5">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onClose}
                  >
                    {" "}
                    Close{" "}
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    {" "}
                    Save{" "}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
          {
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={updateDataInfo.isLoading}
              children={""}
            >
              <CircularProgress color="inherit" /> &nbsp; &nbsp; &nbsp;
              <Typography variant="h5">Saving...</Typography>
            </Backdrop>
          }
        </DialogContent>
      </Dialog>

      {updateDataInfo && <Toster info={updateDataInfo} dialogclose={onClose} />}
    </div>
  );
}

export default UpdateVideoLectureSingleVarientField;
