import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Formik, Form } from "formik";
import { object, string, number, array } from "yup";
import React from "react";
import UpdatePrices from "./UpdatePrices";
import { useUpdateVideoLectureVarientPricesMutation } from "../../../../../services/services";
import Toster from "../../Toster";

const keyToDeleteList = [
  "franchiseDiscount50Type",
  "referralByPercentageType",
  "referralToPercentageType",
  "walletDiscountType",
];

const getStringifyValue = (value, type) => {
  if (type === "PERCENTAGE") {
    return `${value}%`;
  }
  return `${value}`;
};

const sperateAmountAndType = (value) => {
  let result = {
    amount: 0,
    type: "FLAT",
  };

  if (value?.includes("%")) {
    result.amount = parseFloat(value?.split("%")[0]);
    result.type = "PERCENTAGE";
  } else {
    result.amount = parseInt(value);
    result.type = "FLAT";
  }

  return result;
};

const getAmount = (principal, value, type) => {
  if (type === "FLAT") {
    return value;
  }
  return (principal * value) / 100;
};

const UpdatePricesWrapper = ({ initialData, onClose, videoLectureId }) => {
  const [updatePrices, updatePricesInfo] =
    useUpdateVideoLectureVarientPricesMutation();
  const initialValues = {
    varient_id: initialData?._id,
    combo_discount: initialData?.combo_discount,
    facultyWiseData: initialData?.faculty?.map((faculty) => {
      return {
        faculty_id: faculty?.faculty_id || "",
        faculty_name: faculty?.faculty_name || "",
        faculty_mrp: faculty?.faculty_mrp,
        discount_by_faculty:
          sperateAmountAndType(faculty?.discount_by_faculty).amount || 0,
        discountByFacultyType: sperateAmountAndType(
          faculty?.discount_by_faculty
        ).type,
        price_on_which_commission_is_calculated:
          faculty?.price_on_which_commission_is_calculated || 0,
        commissionOnline: faculty?.commission_amount_online || 0,
        commissionOnlineType: "FLAT",
        commissionOffline: faculty?.commission_amount_offline || 0,
        commissionOfflineType: "FLAT",
        commission_percentage_online: 0,
        commission_amount_online: 0,
        payment_to_faculty: faculty?.payment_to_faculty || 0,
        discount_by_us:
          sperateAmountAndType(faculty?.discount_by_us).amount || 0,
        discountByUsType: sperateAmountAndType(faculty?.discount_by_us).type,
        sld_50: sperateAmountAndType(faculty?.sld_50).amount,
        sld50Type: sperateAmountAndType(faculty?.sld_50).type,
        start_date_dis_by_faculty: faculty?.start_date_dis_by_faculty || null,
        end_date_dis_by_faculty: faculty?.end_date_dis_by_faculty || null,
        start_date_dis_by_us: faculty?.start_date_dis_by_us || null,
        end_date_dis_by_us: faculty?.end_date_dis_by_us || null,
        gst_applied_percent: faculty?.gst_applied_percent || 0,
        gst_sharing_percent: faculty?.gst_sharing_percent || 0,

        price_on_which_commission_is_calculated_offline:
          faculty?.price_on_which_commission_is_calculated || 0,
        commission_percentage_offline:
          faculty?.commission_percentage_offline || 0,
        payment_to_faculty_offline: faculty?.payment_to_faculty,
      };
    }),
    max_discount: sperateAmountAndType(initialData?.max_discount)?.amount || 0,
    wallet_discount:
      sperateAmountAndType(initialData?.wallet_discount)?.amount || 0,
    walletDiscountType:
      sperateAmountAndType(initialData?.wallet_discount)?.type || "FLAT",
    franchise_discount_50:
      sperateAmountAndType(initialData?.franchise_discount_50)?.amount || 0,
    franchiseDiscount50Type:
      sperateAmountAndType(initialData?.franchise_discount_50)?.type || "FLAT",
    referral_to_percentage:
      sperateAmountAndType(initialData?.referral_to_percentage)?.amount || 0,
    referralToPercentageType:
      sperateAmountAndType(initialData?.referral_to_percentage)?.type || "FLAT",
    referral_by_percentage:
      sperateAmountAndType(initialData?.referral_by_percentage)?.amount || 0,
    referralByPercentageType:
      sperateAmountAndType(initialData?.referral_by_percentage)?.type || "FLAT",
  };

  const validationSchema = object({
    facultyWiseData: array().of(
      object().shape({
        faculty_mrp: number()
          .typeError("Must be number")
          .min(0, "Must be positive")
          .required("Required"),
        discountByFacultyType: string().required("Required"),
        discount_by_faculty: number()
          .typeError("Must be number")
          .min(0, "Must be positive")
          .required("Required"),

        commissionOnline: number()
          .typeError("Must be number")
          .required("Required"),
        commissionOnlineType: string(),
        price_on_which_commission_is_calculated: number(),
        discountByUsType: string(),
        discount_by_us: number()
          .typeError("Must be number")
          .min(0, "Must be positive")
          .required("Required")
          .test({
            name: "discount_by_us",
            message: "Must be less than commission",
            test: function (value) {
              return (
                getAmount(
                  this.parent.price_on_which_commission_is_calculated,
                  value,
                  this.parent.discountByUsType
                ) <
                parseFloat(
                  getAmount(
                    this.parent.price_on_which_commission_is_calculated,
                    this.parent.commissionOnline,
                    this.parent.commissionOnlineType
                  )
                )
              );
            },
          }),
        sld_50: number()
          .typeError("Must be number")
          .min(0, "Must be positive")
          .required("Required")
          .test({
            name: "discount_by_us",
            message: "Must be less than commission",
            test: function (value) {
              return (
                getAmount(
                  this.parent.price_on_which_commission_is_calculated,
                  value,
                  this.parent.sld50Type
                ) <
                parseFloat(
                  getAmount(
                    this.parent.price_on_which_commission_is_calculated,
                    this.parent.commissionOnline,
                    this.parent.commissionOnlineType
                  )
                )
              );
            },
          }),
        start_date_dis_by_faculty: string().nullable(),
        end_date_dis_by_faculty: string().nullable(),
        start_date_dis_by_us: string().nullable(),
        end_date_dis_by_us: string().nullable(),
        gst_applied_percent: number()
          .typeError("Must be number")
          .required("Required"),
        gst_sharing_percent: number()
          .typeError("Must be number")
          .required("Required"),
      })
    ),
    max_discount: number()
    .typeError("Must be a number")
    .min(0, "Must be a positive number")
    .required("Required")
    .test("max_discount-validation", "Max discount cannot exceed commission", function(value) {
      const commissionOnline = parseFloat(this.parent.commission_percentage_online) || 0;
      const commissionOffline = parseFloat(this.parent.commission_percentage_offline) || 0;

      return value <= commissionOnline && value <= commissionOffline;
    }),

    wallet_discount: number()
      .typeError("Must be number")
      .min(0, "Must be positive")
      .required("Required"),

    franchise_discount_50: number()
      .typeError("Must be number")
      .min(0, "Must be positive")
      .required("Required"),

    referral_to_percentage: number()
      .typeError("Must be number")
      .min(0, "Must be positive")
      .required("Required"),
    referral_by_percentage: number()
      .typeError("Must be number")
      .min(0, "Must be positive")
      .required("Required"),
  });

  const getAmountAndPercent = (principal, value, type) => {
    let result = {
      amount: 0,
      percent: 0,
    };
    if (type === "FLAT") {
      result.amount = parseFloat(value);
      result.percent = parseFloat((value * 100) / principal);
    } else {
      result.amount = parseFloat((principal * value) / 100);
      result.percent = parseFloat(value);
    }
    return result;
  };

  const getDiscountedValue = (
    principleAmount = 0,
    discount = 0,
    discountType = "FLAT"
  ) => {
    if (discountType === "PERCENTAGE") {
      return parseFloat(discount) || 0;
    } else {
      const calculatedDiscount =
        ((parseFloat(discount) || 0) * 100) /
        (parseFloat(principleAmount) || 0);
      return parseFloat(calculatedDiscount.toFixed(3)) || 0;
    }
  };

  const handleSubmit = (values) => {
    values = {
      faculty: values?.facultyWiseData?.map((faculty) => ({
        start_date_dis_by_faculty: faculty?.start_date_dis_by_faculty || "",
        end_date_dis_by_faculty: faculty?.end_date_dis_by_faculty || "",
        start_date_dis_by_us: faculty?.start_date_dis_by_us || "",
        end_date_dis_by_us: faculty?.end_date_dis_by_us || "",
        faculty_id: faculty?.faculty_id || "",
        faculty_name: faculty?.faculty_name || "",
        faculty_mrp: faculty?.faculty_mrp || 0,
        discount_by_faculty: getStringifyValue(
          faculty?.discount_by_faculty,
          faculty?.discountByFacultyType
        ),
        discount_by_us: getStringifyValue(
          faculty?.discount_by_us,
          faculty?.discountByUsType
        ),

        sld_50: getStringifyValue(faculty?.sld_50, faculty?.sld50Type),
        commission_percentage_online: getDiscountedValue(
          faculty.price_on_which_commission_is_calculated,
          faculty.commissionOnline,
          faculty.commissionOnlineType
        ),

        commission_amount_online: getAmountAndPercent(
          faculty?.faculty_mrp,
          faculty?.commissionOnline,
          faculty?.commissionOnlineType
        ).amount,

        gst_applied_percent: faculty?.gst_applied_percent || 0,
        gst_sharing_percent: faculty?.gst_sharing_percent || 0,
        price_on_which_commission_is_calculated:
          faculty?.price_on_which_commission_is_calculated || 0,
        payment_to_faculty: faculty?.payment_to_faculty || 0,
        commission_percentage_offline: getDiscountedValue(
          faculty.price_on_which_commission_is_calculated,
          faculty.commissionOffline,
          faculty.commissionOfflineType
        ),

        price_on_which_commission_is_calculated_offline:
          faculty?.price_on_which_commission_is_calculated_offline || 0,
        commission_amount_offline:
          getAmountAndPercent(
            faculty?.faculty_mrp,
            faculty?.commissionOffline,
            faculty?.commissionOfflineType
          ).amount || 0,
        payment_to_faculty_offline: faculty?.payment_to_faculty_offline || 0,
      })),
      combo_discount: 0,
      varient_id: values?.varient_id,
      franchise_discount_50: getStringifyValue(
        values?.franchise_discount_50,
        values?.franchiseDiscount50Type
      ),
      max_discount: getStringifyValue(values?.max_discount, "FLAT"),
      referral_by_percentage: getStringifyValue(
        values?.referral_by_percentage,
        values?.referralByPercentageType
      ),
      referral_to_percentage: getStringifyValue(
        values?.referral_to_percentage,
        values?.referralToPercentageType
      ),
      wallet_discount: getStringifyValue(
        values?.wallet_discount,
        values?.walletDiscountType
      ),
    };
    updatePrices({ body: values, id: videoLectureId });
  };

  return (
    <>
      <Dialog maxWidth={"xl"} fullWidth open={true}>
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            Edit Prices
            <IconButton onClick={onClose}> X </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {(formikProps) => (
              <Form>
                <UpdatePrices formikProps={formikProps} onClose={onClose} />
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      {updatePricesInfo && (
        <Toster info={updatePricesInfo} dialogclose={onClose} />
      )}
    </>
  );
};

export default UpdatePricesWrapper;
