import React, { useState, useEffect } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Typography,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import AddIcon from "@material-ui/icons/Add";
import {
  useGetAllSubjectNamesQuery,
  useGetAllLevelNamesQuery,
} from "../../../services/services";

import { ErrorMessage, FieldArray, Formik, Field, Form } from "formik";
import * as yup from "yup";

import { useGetAllSubjectsFilterMutation } from "../../../services/services";

import { useGetAllLevelFilterMutation } from "../../../services/services";
import SkeletonListing from "./SkeletonListing";
import Loader from "./Loader";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddCourse({
  AddData,
  setDialog,
  UpdateData,
  editDialog,
  editData,
  formType,
}) {
  const {
    data: allSubjects,
    isFetching: isAllSubjectsFetching,
    isLoading: isAllSubjectsLoading,
  } = useGetAllSubjectNamesQuery();
  const {
    data: allLevels,
    isFetching: isAllLevelsFetching,
    isLoading: isAllLevelsLoading,
  } = useGetAllLevelNamesQuery();

  const initialValues = {
    name: formType == "edit" ? editData?.name : "",
    course_code: formType == "edit" ? editData?.course_code : "",
    type: formType == "edit" ? editData?.type : "",
    level:
      formType == "edit"
        ? editData?.level?.map((ele) => {
            let objectWithoutId = {};
            for (let key in ele) {
              if (key == "_id") continue;
              objectWithoutId = { ...objectWithoutId, [key]: ele[key] };
            }
            return objectWithoutId;
          })
        : [
            {
              level_id: "",
              subject: [],
            },
          ],
    rank_order: formType === "edit" ? editData.rank_order : "",
  };

  const validationSchema = yup.object({
    name: yup.string().required("Please enter Course Name"),
    course_code: yup.string().required("Please enter Course Code"),
    type: yup.string().required("Please select Course Type"),
    level: yup.array().required("Please select Level & Subject"),
    rank_order: yup
      .number()
      .typeError("Order rank must be number")
      .min(1, "order rank should be greater than or equal to 1")
      .required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    values.level.map((ele) => {
      ele.subject = ele.subject.map((ele) => {
        if (typeof ele == "string") {
          return ele;
        } else {
          return ele._id;
        }
      });
    });

    formType == "edit" ? UpdateData(values, editData?._id) : AddData(values);

    resetForm();
  };

  return (
    <>
      {isAllSubjectsFetching ||
      isAllSubjectsLoading ||
      isAllLevelsFetching ||
      isAllLevelsLoading ? (
        <Grid style={{ width: "800px" }}>
          <SkeletonListing />
        </Grid>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <Grid className="mb-3">
              <Field name="name">
                {(props) => {
                  const { field } = props;

                    
                  return (
                    <TextField
                      {...field}
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Course Name"
                      minRows={2}
                      multiline
                    />
                  );
                }}
              </Field>
              <ErrorMessage name="name">
                {(errorMsg) => (
                  <span className="text-danger"> {errorMsg} </span>
                )}
              </ErrorMessage>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={6} className="mb-3">
                <Field name="course_code">
                  {(props) => {
                    const { field } = props;

                    return (
                      <TextField
                        {...field}
                        type="text"
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Course Code"
                      />
                    );
                  }}
                </Field>
                <ErrorMessage name="course_code">
                  {(errorMsg) => (
                    <span className="text-danger"> {errorMsg} </span>
                  )}
                </ErrorMessage>
              </Grid>

              <Grid item xs={6} className="mb-3">
                <Field name="rank_order">
                  {(props) => {
                    const { field } = props;

                    return (
                      <TextField
                        {...field}
                        type="number"
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Order Rank"
                      />
                    );
                  }}
                </Field>
                <ErrorMessage name="rank_order">
                  {(errorMsg) => (
                    <span className="text-danger"> {errorMsg} </span>
                  )}
                </ErrorMessage>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <Field name="type">
                  {(props) => {
                    const { field, form } = props;
                    return (
                      <FormControl fullWidth>
                        <InputLabel id="type">Course Type</InputLabel>
                        <Select
                          {...field}
                          labelId="type"
                          id="type"
                          label="Type"
                          // renderValue={(value) => value == "MAIN_COURSE" ? "Main Course" : "Other Course"}
                        >
                          <MenuItem value="MAIN_COURSE"> Main Course </MenuItem>
                          <MenuItem value="OTHER_COURSE">
                            {" "}
                            Other Course{" "}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    );
                  }}
                </Field>

                <ErrorMessage name="type">
                  {(errorMsg) => (
                    <span className="text-danger"> {errorMsg} </span>
                  )}
                </ErrorMessage>
              </Grid>

              <Grid item xs={6} className="px-5">
                <Typography
                  className="text-danger"
                  style={{ fontSize: "12px" }}
                >
                  {" "}
                  ** Note :- Main courses will be shown under Video Lecture
                  Section and Other courses will shown under other Courses
                  Section in Front End.{" "}
                </Typography>
              </Grid>
            </Grid>

            <FieldArray name="level">
              {(fieldArrayProps) => {
                const { push, remove, swap, form } = fieldArrayProps;
                const { values } = form;
                const { level } = values;
                return (
                  <div className="mt-3">
                    {level.map((ele, index) => (
                      <Grid key={index} container spacing={2} className="mt-2">
                        <Grid item xs={4}>
                          <Field name={`level[${index}].level_id`}>
                            {(props) => {
                              const { field } = props;
                              return (
                                <FormControl fullWidth>
                                  <InputLabel id="level">Level</InputLabel>
                                  <Select
                                    {...field}
                                    labelId="level"
                                    id="level"
                                    label="Age"
                                  >
                                    {allLevels?.data?.map((ele) => (
                                      <MenuItem value={ele._id}>
                                        {" "}
                                        {ele.level_name}{" "}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              );
                            }}
                          </Field>
                        </Grid>

                        <Grid item xs={5}>
                          <Field name={`level[${index}].subject`}>
                            {(props) => {
                              const { field, form } = props;
                              const { values } = form;
                              const { level } = values;
                              return (
                                <FormControl fullWidth>
                                  <InputLabel id="level_subject">
                                    Subjects
                                  </InputLabel>
                                  <Select
                                    {...field}
                                    labelId="level_subject"
                                    id="level_subject"
                                    multiple
                                    renderValue={(selected) => {
                                      return selected
                                        .map((ele) => {
                                          if (typeof ele == "string") {
                                            let subjectName;
                                            allSubjects?.data?.forEach(
                                              (subject) => {
                                                if (subject._id == ele) {
                                                  subjectName =
                                                    subject.subject_name;
                                                }
                                              }
                                            );
                                            return subjectName;
                                          } else {
                                            return ele.subject_name;
                                          }
                                        })
                                        .join(", ");
                                    }}
                                    MenuProps={MenuProps}
                                    variant="standard"
                                  >
                                    {allSubjects?.data?.map((ele) => (
                                      <MenuItem key={ele._id} value={ele._id}>
                                        <Checkbox
                                          checked={level[
                                            index
                                          ].subject.includes(ele._id)}
                                        />
                                        <ListItemText
                                          primary={ele.subject_name}
                                        />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              );
                            }}
                          </Field>
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          className="d-flex justify-content-end"
                        >
                          {/* {index != 0 &&

                            <IconButton onClick={() => { swap(index, index - 1) }} color="secondary"> <ArrowUpwardIcon /> </IconButton>
                          } */}
                          {level.length > 1 && (
                            <IconButton
                              onClick={() => {
                                remove(index);
                              }}
                              color="secondary"
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Grid>
                        {index == level.length - 1 && (
                          <Grid
                            item
                            xs={12}
                            className="d-flex justify-content-end"
                          >
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => {
                                push({ level_id: "", subject: [] });
                              }}
                            >
                              {" "}
                              <AddIcon />{" "}
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    ))}
                  </div>
                );
              }}
            </FieldArray>

            <ErrorMessage name="level">
              {(errorMsg) => <span className="text-danger"> {errorMsg} </span>}
            </ErrorMessage>

            <Grid container className="mt-5">
              <Grid className="d-flex justify-content-end" xs={12}>
                <Button
                  onClick={() =>
                    setDialog ? setDialog(false) : editDialog(false)
                  }
                  variant="contained"
                  color="secondary"
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="ml-2"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      )}
    </>
  );
}
