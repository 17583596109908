import React from "react";
type Props = {
    type: "MODULE" | "TAB" | "ACTION";
    name: string | string[];
    alt?: React.ReactNode;
    inBoolean?: boolean;
    children?: any;
};
const AuthWrapper = ({
    type,
    name,
    children = null,
    alt = null,
    inBoolean = false,
}: Props) => {

    const authAccess = (localStorage.getItem("accessModules")) ? JSON.parse(localStorage.getItem("accessModules") || "") : []
    const authType = localStorage.getItem("authType") || ""
    const returnResult = (hasAccess: boolean) => {
        if (hasAccess) {
            return inBoolean ? true : children;
        } else {
            return inBoolean ? false : alt;
        }
    };
    if (authType === "ADMIN") {
        return returnResult(true)
    }
    else if (name === undefined) {
        return returnResult(true)
    }
    else {
        switch (type) {
            case "MODULE":
                if (typeof name === "string") {
                    const hasAccess =
                        authAccess?.findIndex((access: any) => access.moduleId === name) > -1;
                    return returnResult(hasAccess);
                } else {
                    const hasAccess = name?.some(
                        (moduleName) =>
                            authAccess?.findIndex((access: any) => access.moduleId === moduleName) >
                            -1
                    );
                    return returnResult(hasAccess);
                }
            case "ACTION":
                if (typeof name === "string") {
                    const hasAccess =
                        authAccess?.findIndex((access: any) => access.actionId === name) > -1;
                    return returnResult(hasAccess);
                } else {
                    const hasAccess = name?.some(
                        (actionName) =>
                            authAccess?.findIndex((access: any) => access.actionId === actionName) >
                            -1
                    );
                    return returnResult(hasAccess);
                }
            case "TAB":
                if (typeof name === "string") {
                    const hasAccess =
                        authAccess?.findIndex((access: any) => access.tabId === name) > -1;
                    return returnResult(hasAccess);
                } else {
                    const hasAccess = name?.some(
                        (tabName) =>
                            authAccess?.findIndex((access: any) => access.tabId === tabName) > -1
                    );
                    return returnResult(hasAccess);
                }
        }
    }
};
export default AuthWrapper;