import React, { useState, useEffect } from 'react'
import { Tabs, Tab, AppBar } from "@material-ui/core";
import TabPanel from './CreateTabPanel';
import PropTypes from 'prop-types';
import UserWallet from './UserWallet';
import Cart from './Cart';
import OrderListing from './OrderListing';
import DataNotFound from './DataNotFound';
import Toster from "../../screens/dashboard/Toster";

import { useGetAllUserOrderQuery } from '../../../services/services';
import {
    useGetCartQuery, useDeleteCartProductMutation,
    useGetApplyCoupenCodeMutation,
    useGetApplyReferralCodeMutation,
    useUpdateProductQntyMutation, useAddToCartProductMutation
}
    from '../../../services/cartServices';
import { useGetOrderSheetMutation } from '../../../services/pyhtonApiSeevices';
import CartDetails from './cart/CartDetails'
import { Alert } from '@mui/material';
import { userAccess } from '../../../helpers/userAccess'

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function MyTable({ userData }) {




    // const [getUserData, setGetUserData] = React.useState("")
    const getAllCart = useGetCartQuery({ "user_id": userData._id })
    const getAallUserOrder = useGetAllUserOrderQuery(userData._id)
    const [setDelete, deleteCartInfo] = useDeleteCartProductMutation()
    const [python, pyInfo] = useGetOrderSheetMutation()
    const [setReferralCode, referralCartInfo] = useGetApplyReferralCodeMutation()
    const [setCoupenCode, coupenCodeInfo] = useGetApplyCoupenCodeMutation()
    const [setItemQunty, itemCountInfo] = useUpdateProductQntyMutation()
    const [addToCartDetail, addToCartDetailInfo] = useAddToCartProductMutation()

    const getUserData = getAallUserOrder.data
    const getCart = getAllCart.data
    const [value, setValue] = useState(0);
    const [deleteCart, setDeleteCart] = useState({ user_id: "", product_id: "", product_varient_code: "" });

    const handleChange = (event, newValue) => {
        setValue(newValue);

    };
    const [itemCount, setItemCount] = React.useState({
        "user_id": "",
        "product_id": "",
        "product_varient_code": "",
        "type": ""
    });

    useEffect(() => {

        setDelete(deleteCart)

    }, [deleteCart])


    useEffect(
        () => {
            setItemQunty(itemCount)
        }, [itemCount]
    )
    const dialogclose = () => {
    }

    return (
        <>
            <div >
                <AppBar style={{ background: "rgb(59 67 136)" }} position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        {userAccess("order") ? <Tab style={{ textTransform: 'capitalize' }} label="Order" {...a11yProps(0)} />: null}
                        {userAccess("cart") ? <Tab style={{ textTransform: 'capitalize' }} label="Cart" {...a11yProps(1)} />: null}
                        {userAccess("wallet-transaction") ? <Tab style={{ textTransform: 'capitalize' }} label="Wallet" {...a11yProps(2)} />: null}

                    </Tabs>
                </AppBar>

                <TabPanel value={value} index={0}>
                    {getUserData != undefined ? getUserData.data.map((order) => <OrderListing data={order} inWallet={false} />) : <DataNotFound />}




                </TabPanel>

                <TabPanel style={{ paddingBottom: 50 }} value={value} index={1}>
                    {/* { getCart!=undefined ? <Cart getCart={getCart} inWallet={false}/>:<DataNotFound /> } */}
                    {getCart != undefined ? <CartDetails
                        getCart={getCart}
                        setDeleteCart={setDeleteCart}
                        userData={userData}
                        // itemCountInfo={itemCountInfo}
                        setItemCount={setItemCount}
                        setReferralCode={setReferralCode}
                        setCoupenCode={setCoupenCode}
                        addToCartDetail={addToCartDetail}
                        inWallet={false} /> : <DataNotFound />}



                </TabPanel >

                <TabPanel style={{ paddingBottom: 50 }} value={value} index={2}>
                    {getCart != undefined ? <UserWallet userId = {userData._id}/>: <DataNotFound />}



                </TabPanel >

                {coupenCodeInfo.data && (
                    <Toster info={coupenCodeInfo} dialogclose={dialogclose} />
                )}
                {
                    referralCartInfo.data && (
                        <Toster info={referralCartInfo} dialogclose={dialogclose} />
                    )}
                {deleteCartInfo.data && deleteCartInfo.data.status != false && (
                    <Toster info={deleteCartInfo} dialogclose={dialogclose} />
                )}
                {itemCountInfo.data && itemCountInfo.data.status != false && (
                    <Toster info={itemCountInfo} dialogclose={dialogclose} />
                )}
                {addToCartDetailInfo.data && addToCartDetailInfo.data.status != false && (
                    <Toster info={addToCartDetailInfo} dialogclose={dialogclose} />
                )}

            </div >

        </>
    )
}
