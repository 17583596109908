import React from "react";
import { Tabs, Tab, AppBar } from "@material-ui/core";
import TabPanel from "./CreateTabPanel";
import PropTypes from "prop-types";
import VideoLectureOrders from "./VideoLectureOrders";
import { userAccess } from "../../../helpers/userAccess";
import CancelOrderRequests from "./studentRequests/CancelOrderRequests";
import { useLocation } from "react-router-dom";
import AuthWrapper from "../../../utils/AuthWrapper";
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const tabsNames = [
  {
    tabName: "orders",
    tabId: "CANCEL_ORDERS_ORDER",
  },
  {
    tabName: "Cancel Order Requests",
    tabId: "CANCEL_ORDERS_REQUEST",
  },
];

export default function CancelOrders({ inWallet }) {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  let cancel = query.get("cancel");
  let tabsToShow = tabsNames?.filter((e, i) => {
    return AuthWrapper({ type: "TAB", name: e?.tabId, inBoolean: true });
  });
  const [value, setValue] = React.useState(cancel == "true" ? 1 : 0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let orderStatus = ["CANCELLED"];
  const getTabPanel = (ele) => {
    let moduleToShow;
    switch (ele) {
      case "orders":
        moduleToShow = <VideoLectureOrders orderStatus={orderStatus} />;
        break;
      case "Cancel Order Requests":
        moduleToShow = <CancelOrderRequests />;
        break;
      // case "order-transaction":
      //   moduleToShow = <OnlineOrderTransaction/>
      //   break;
      default:
        break;
    }
    return moduleToShow;
  };
  return (
    <div>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          {tabsToShow?.map((ele, ind) => {
            return (
              <Tab
                style={{ textTransform: "capitalize" }}
                label={ele?.tabName}
                {...a11yProps(ind)}
              />
            );
          })}
        </Tabs>
      </AppBar>

      {tabsToShow?.map((ele, ind) => {
        return (
          <TabPanel value={value} index={ind}>
            {getTabPanel(ele?.tabName)}
          </TabPanel>
        );
      })}
    </div>
  );
}
