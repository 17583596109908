// **** React Import
import React, { useState, useEffect, forwardRef } from "react";

// **** Custom File Imports
import SkeletonListing from "../SkeletonListing";
import DataNotFound from "../DataNotFound";

// **** Services Import
import { useGetAllActiveTimersQuery, useGetTimeSheetDataQuery } from "../../../../services/timeSheetServices";

// **** Moment Import
import moment from "moment";

// **** Data Grid Import
import { DataGrid } from '@mui/x-data-grid'

// **** Date Picker Import
import DatePicker from 'react-datepicker'

// **** Import React Chart
import {
    Chart,
    ArgumentAxis,
    ValueAxis,
    BarSeries,
    SplineSeries,
    Legend,
} from "@devexpress/dx-react-chart-material-ui";

// **** Material Imports
import { Grid, MenuItem, Paper, Select } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { Autocomplete, Button, Checkbox, InputLabel, TextField } from "@mui/material";
import { useGetAllSldUserMutation } from "../../../../services/services";
import { TimeSheetChart } from "./TimeSheetChart";

// **** Material Icon Imports
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const color = ["#00ffff", "#f0ffff", "#f5f5dc", "#000000", "#0000ff", "#a52a2a", "#00ffff", "#00008b", "#008b8b", "#a9a9a9", "#006400", "#bdb76b", "#8b008b", "#556b2f", "#ff8c00", "#9932cc", "#8b0000", "#e9967a", "#9400d3", "#ff00ff", "#ffd700", "#008000", "#4b0082", "#f0e68c", "#add8e6", "#e0ffff", "#90ee90", "#d3d3d3", "#ffb6c1", "#ffffe0", "#00ff00", "#ff00ff", "#800000", "#000080", "#808000", "#ffa500", "#ffc0cb", "#800080", "#800080", "#ff0000", "#c0c0c0", "#ffffff", "#ffff00"]

// const timeSheetData = [
//     {
//         _id: 1,
//         name: "Himanshu",
//         "2022-03-01": 540,
//         "2022-03-02": 257,
//         "2022-03-03": 360,
//         "2022-03-04": 540,
//         "2022-03-05": 600,
//         "2022-03-06": 300,
//         "2022-03-07": 330,
//         "2022-03-08": 540,
//         "2022-03-09": 540,
//         "2022-03-10": 420,
//         "2022-03-11": 480,
//         "2022-03-12": 540,
//         "2022-03-13": 540,
//         "2022-03-14": 540,
//         "2022-03-15": 540,
//         "2022-03-16": 540,
//         "2022-03-17": 540,
//         "2022-03-18": 540,
//         "2022-03-19": 540,
//         "2022-03-20": 540,
//         "2022-03-21": 540,
//         "2022-03-22": 540,
//         "2022-03-23": 540,
//         "2022-03-24": 540,
//         "2022-03-25": 540,
//         "2022-03-26": 540,
//         "2022-03-27": 540,
//         "2022-03-28": 540,
//         "2022-03-29": 540,
//         "2022-03-30": 540,
//         "2022-03-31": 540,

//     },
//     {
//         _id: 2,
//         name: "Aamarsh",
//         "2022-03-01": 540,
//         "2022-03-02": 450,
//         "2022-03-03": 320,
//         "2022-03-04": 420,
//         "2022-03-05": 510,
//         "2022-03-06": 450,
//         "2022-03-07": 540,
//         "2022-03-08": 540,
//         "2022-03-09": 540,
//         "2022-03-10": 360,
//         "2022-03-11": 420,
//         "2022-03-12": 480,
//         "2022-03-13": 540,
//         "2022-03-14": 540,
//         "2022-03-15": 540,
//         "2022-03-16": 540,
//         "2022-03-17": 540,
//         "2022-03-18": 540,
//         "2022-03-19": 540,
//         "2022-03-20": 540,
//         "2022-03-21": 540,
//         "2022-03-22": 540,
//         "2022-03-23": 540,
//         "2022-03-24": 540,
//         "2022-03-25": 540,
//         "2022-03-26": 540,
//         "2022-03-27": 540,
//         "2022-03-28": 540,
//         "2022-03-29": 540,
//         "2022-03-30": 540,
//         "2022-03-31": 540,

//     },
//     {
//         _id: 3,
//         name: "Parth",
//         "2022-03-01": 540,
//         "2022-03-02": 540,
//         "2022-03-03": 540,
//         "2022-03-04": 540,
//         "2022-03-05": 540,
//         "2022-03-06": 540,
//         "2022-03-07": 540,
//         "2022-03-08": 540,
//         "2022-03-09": 540,
//         "2022-03-10": 360,
//         "2022-03-11": 420,
//         "2022-03-12": 480,
//         "2022-03-13": 540,
//         "2022-03-14": 540,
//         "2022-03-15": 540,
//         "2022-03-16": 540,
//         "2022-03-17": 540,
//         "2022-03-18": 540,
//         "2022-03-19": 540,
//         "2022-03-20": 540,
//         "2022-03-21": 540,
//         "2022-03-22": 540,
//         "2022-03-23": 540,
//         "2022-03-24": 540,
//         "2022-03-25": 540,
//         "2022-03-26": 540,
//         "2022-03-27": 540,
//         "2022-03-28": 540,
//         "2022-03-29": 540,
//         "2022-03-30": 540,
//         "2022-03-31": 540,

//     },
//     // {
//     //     _id: 4,
//     //     name: "Prasoon",
//     //     "2022-03-01": 540,
//     //     "2022-03-02": 540,
//     //     "2022-03-03": 540,
//     //     "2022-03-04": 540,
//     //     "2022-03-05": 540,
//     //     "2022-03-06": 540,
//     //     "2022-03-07": 540,
//     //     "2022-03-08": 540,
//     //     "2022-03-09": 540,
//     //     "2022-03-10": 360,
//     //     "2022-03-11": 420,
//     //     "2022-03-12": 480,
//     //     "2022-03-13": 540,
//     //     "2022-03-14": 540,
//     //     "2022-03-15": 540,
//     //     "2022-03-16": 540,
//     //     "2022-03-17": 540,
//     //     "2022-03-18": 540,
//     //     "2022-03-19": 540,
//     //     "2022-03-20": 540,
//     //     "2022-03-21": 540,
//     //     "2022-03-22": 540,
//     //     "2022-03-23": 540,
//     //     "2022-03-24": 540,
//     //     "2022-03-25": 540,
//     //     "2022-03-26": 540,
//     //     "2022-03-27": 540,
//     //     "2022-03-28": 540,
//     //     "2022-03-29": 540,
//     //     "2022-03-30": 540,
//     //     "2022-03-31": 540,

//     // },
// ]

export default function TimeSheet() {

    // **** States
    const [sldUserOptions, setSldUserOptions] = useState([]);
    const [startDate, setStartDate] = useState(moment(new Date()).format("yyyy-MM-DD"))
      
    const [endDate, setEndDate] = useState(moment(new Date()).format("yyyy-MM-DD"))
      
    const [users, setUsers] = useState("627defe466046ae311f8b576")
      
    const [viewDatePicker, setViewDatePicker] = useState(false);
    const [timeSheetData, setTimeSheetData] = useState("");


    // **** State for Date Range Picker
    const [startDateRange, setStartDateRange] = useState(new Date());
    const [endDateRange, setEndDateRange] = useState(null);


    // **** Services
    const { data: AllActiveTimers, isFetching: isAllActiveTimerFetching, isLoading: isAllActiveTimerLoading } = useGetAllActiveTimersQuery()
    const { data: allTimeSheetData, isFetching: isAllTimeSheetDataFetching, isLoading: isAllTimeSheetDataLoading } = useGetTimeSheetDataQuery({ startDate, endDate, users })
      

    const [getAllData, getAllDataInfo] = useGetAllSldUserMutation()

    useEffect(() => {
        getAllData({
            limit: "",
            query: "",
            params: [
                "name"
            ],
            page: ""
        })
    }, [])


    useEffect(() => {
        setTimeSheetData(allTimeSheetData)
    }, [allTimeSheetData])

    const AllData = getAllDataInfo.data

    // ****  Convert AllData to Array of this type of Object
    // => { label: _SLD USER NAME_ , id:_id }
    useEffect(() => {
        if (AllData) {
            setSldUserOptions(AllData?.data?.map(ele => (
                { label: ele.name, id: ele._id }
            )))
        }

    }, [AllData])

    // **** Form InitialValues
    const initialValues = {
        dateFilter: "",
        users: []
    }

    // **** Date Filter Options
    const dateFilterOptions = [
        "Today",
        "This Week",
        "Last Week",
        "This Month",
        "Last Month",
    ]

    let column = [
        {
            field: "name",
            headerName: "Name",
            flex: 2,
            hideable: false,
            minWidth: 150,
            sortable: false,
            filterable: false,
            hideable: false
        }
    ]
    if (!isAllTimeSheetDataFetching && !isAllTimeSheetDataLoading && timeSheetData) {

        for (let key in timeSheetData[0]) {
            if (key == "sld_user_id" || key == "name") continue
            column.push(
                {
                    field: key,
                    headerName: moment(key).format("DD MMM"),
                    renderCell: ({ row }) => (
                        <span className="text-success" >  {(((row[key] - (row[key] % 60)) / 60) + "").padStart(2, "0")} : {((row[key] % 60) + "").padStart(2, "0")} </span>
                    ),
                    flex: 1,
                    sortable: false,
                    filterable: false,
                    hideable: false,
                    minWidth: 100
                }
            )
        }
    }
    const [data, setData] = useState("");
    const [viewDialog, setViewDialog] = useState(false);

    // **** On submit Handler (Apply Button)
    const onSubmitHandler = (values) => {

        setUsers(values.users.map(ele => ele.id).join(","))

        switch (values.dateFilter) {
            case "Today":
                setStartDate(moment(new Date()).format("yyyy-MM-DD"))
                setEndDate(moment(new Date()).format("yyyy-MM-DD"))
                break;
            case "This Week":
                setStartDate(moment(moment(new Date()).startOf('isoweek')._d).format("yyyy-MM-DD").toString())
                setEndDate(moment(moment(new Date()).endOf('isoweek')._d).format("yyyy-MM-DD").toString())
                break;
            case "Last Week":
                const lastWeekDay = moment(moment().startOf("isoWeek")._d).subtract(1, 'days')._d
                setStartDate(moment(moment(lastWeekDay).startOf('isoweek')._d).format("yyyy-MM-DD").toString())
                setEndDate(moment(lastWeekDay).format("yyyy-MM-DD").toString())
                break;
            case "This Month":
                const now = new Date()
                setStartDate(moment(new Date(now.getFullYear(), now.getMonth(), 1)).format("yyyy-MM-DD").toString())
                setEndDate(moment(new Date(now.getFullYear(), now.getMonth() + 1, 0)).format("yyyy-MM-DD").toString())
                break;
            case "Last Month":
                setStartDate(moment(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)).format("yyyy-MM-DD").toString())
                setEndDate(moment(new Date(new Date().getFullYear(), new Date().getMonth(), 0)).format("yyyy-MM-DD").toString())
                break;
            case "Custom Range":
                setStartDate(moment(startDateRange).format("yyyy-MM-DD").toString())
                setEndDate(moment(endDateRange).format("yyyy-MM-DD").toString())
                break;
            default:
                break;
        }
    }

    // **** Handle On Change 
    const handleOnChange = dates => {
        const [start, end] = dates
        setStartDateRange(start)
        setEndDateRange(end)
    }

    // **** Custom Input For Date Picker
    const CustomInput = forwardRef((props, ref) => {
        const startDate = moment(props.start).format("DD MMM yyyy")
        const endDate = props.end !== null ? ` - ${moment(props.end).format('DD MMM yyyy')}` : null
        const value = `${startDate}${endDate !== null ? endDate : ''}`

        return <TextField size="small" variant="standard" fullWidth inputRef={ref} label={props.label || ''} {...props} value={value} />
    })


    return (
        !AllData ||
            !column.length ||
            isAllTimeSheetDataLoading ||
            isAllActiveTimerFetching
            ?
            <SkeletonListing />
            :
            <Grid>

                <Grid item xs={12}  >
                    <Paper >
                        <TimeSheetChart chartData={timeSheetData} />
                    </Paper>
                </Grid>

                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmitHandler}
                >
                    {
                        ({ values, setFieldValue }) => (
                            <Form>
                                <Grid container spacing={5} className="px-5 mt-4">
                                    <Grid item xs={3}  >
                                        <Grid container direction="column"  >

                                            <Grid item xs={12}>
                                                <InputLabel htmlFor="period" > Period </InputLabel>
                                                <Field name="dateFilter">
                                                    {
                                                        ({ field }) => (
                                                            <Select
                                                                {...field}
                                                                size="small"
                                                                fullWidth
                                                                variant="standard"
                                                                id="period"
                                                            >
                                                                {
                                                                    dateFilterOptions.map(option => (
                                                                        <MenuItem value={option} onClick={() => setViewDatePicker(false)} > {option} </MenuItem>
                                                                    ))
                                                                }
                                                                <MenuItem value="Custom Range" onClick={() => setViewDatePicker(true)} > Custom Range </MenuItem>

                                                            </Select>
                                                        )
                                                    }
                                                </Field>
                                            </Grid>

                                            {/* Custom Date Range Picker */}
                                            {/* {
                                                viewDatePicker &&
                                                <Grid item xs={12} className="mt-3" >
                                                    <InputLabel htmlFor="range" > Date Range </InputLabel>
                                                    <DatePicker
                                                        selectsRange
                                                        endDate={endDateRange}
                                                        selected={startDateRange}
                                                        startDate={startDateRange}
                                                        id='date-range-picker'
                                                        onChange={handleOnChange}
                                                        shouldCloseOnSelect={false}
                                                        customInput={<CustomInput start={startDateRange} end={endDateRange} />}
                                                    />
                                                </Grid>
                                            } */}
                                        </Grid>


                                    </Grid>



                                    <Grid item xs={3}>
                                        <InputLabel htmlFor="users" > Users </InputLabel>
                                        <Field name="user" >
                                            {
                                                ({ field }) => (
                                                    <Autocomplete
                                                        multiple
                                                        id="users"
                                                        value={field.value}
                                                        onChange={(e, newValue) => setFieldValue('users', newValue)}
                                                        options={sldUserOptions}
                                                        disableCloseOnSelect
                                                        isOptionEqualToValue={(opt, value) => opt.id === value.id}
                                                        renderOption={(props, option, { selected }) => (
                                                            <li {...props}>
                                                                <Checkbox
                                                                    icon={icon}
                                                                    checkedIcon={checkedIcon}
                                                                    style={{ marginRight: 8 }}
                                                                    checked={selected}
                                                                />
                                                                {option.label}
                                                            </li>
                                                        )}
                                                        renderInput={(params) => <TextField {...params} variant="standard" placeholder="User" />}
                                                        size="small"
                                                        fullWidth
                                                    />
                                                )
                                            }
                                        </Field>
                                    </Grid>



                                    <Grid item xs={3}>
                                        <Button variant="contained" color="primary" type="submit" > Apply </Button>
                                    </Grid>
                                </Grid>

                            </Form>
                        )
                    }

                </Formik>
                <Grid container className="px-3" >
                    <Grid item xs={12}>
                        <DataGrid
                            autoHeight
                            rows={timeSheetData}
                            columns={column}
                            getRowId={(row) => row.sld_user_id}
                            sx={{ mt: 5 }}
                            hideFooterPagination

                        />
                    </Grid>
                </Grid>


            </Grid >


    );
}
