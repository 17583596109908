import React, { useEffect, useState } from 'react'
// import { Dialog, DialogActions, Button, DialogContent, DialogTitle, DialogContentText, TextField } from '@material-ui/core';
import { Dialog, DialogActions, Button, DialogContent, DialogTitle, DialogContentText, TextField, MenuItem, FormControl, InputLabel, Select, Grid } from '@material-ui/core';
import moment from 'moment';
import { useFormik } from 'formik';
import * as yup from 'yup'
import { ToastContainer, toast } from 'react-toastify';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';

const notify = (msg) => toast(msg);

function EditPrePayment({ setPrePayment, 
                          prePaymentDialog, 
                          data, 
                          setPrepaymentData, 
                          setPrePaymentChecked}) {
  const [selectedFaculties,setSelectedFaculties] = useState([])                           
  let now = new Date()
  const handleFacultySelection = (facultyId) => {
       if(selectedFaculties.includes(facultyId)){
        setSelectedFaculties(selectedFaculties.filter((ele,ind)=>{ return ele != facultyId}))
       }else{
        setSelectedFaculties([...selectedFaculties,facultyId])
       }
  }                          
  const formik = useFormik({
    initialValues: {
        prepaymentData: data.faculties.map((facultyEle,facultyInd)=>{
             return {
              faculty_id: facultyEle.faculty_id,
              title_deal_name: "",
              remark: "",
              payment_amount: "",
              payment_type: "PRE_PAYMENT",
              payment_date: moment(now).format("YYYY-MM-DD"),
              applicable_from_date: moment(now).format("YYYY-MM-DD"),
              applicable_to_date:moment(now).format("YYYY-MM-DD"),
              payment_mode: "",
              other_details: "",
              commission: "",
              sld_order_id: data.sld_order_id,
              order_id: data._id,
              order_date:data.order_date
             }
        })
    },
    onSubmit: (values) => {
      if(selectedFaculties.length > 0){
        let isValid = true
        for(let ind in values.prepaymentData){
          if(selectedFaculties.includes(values.prepaymentData[ind].faculty_id)){
            if(moment(values.prepaymentData[ind].applicable_from_date).isAfter(values.prepaymentData[ind].applicable_to_date) ||
            moment(values.prepaymentData[ind].applicable_from_date).isSame(values.prepaymentData[ind].applicable_to_date)){
              notify("Applicable from date should be previous date of applicable to date.")
              return
            }
            if(values.prepaymentData[ind].payment_amount 
              && (isNaN(values.prepaymentData[ind].payment_amount) || parseInt(values.prepaymentData[ind].payment_amount) < 1) ){
              notify("Payment amount should be a number.")
              return
            }
            if(!values.prepaymentData[ind].title_deal_name
              ||!values.prepaymentData[ind].remark
              ||!values.prepaymentData[ind].payment_amount
              ||!values.prepaymentData[ind].payment_type
              ||!values.prepaymentData[ind].payment_date
              ||!values.prepaymentData[ind].applicable_from_date
              ||!values.prepaymentData[ind].applicable_to_date
              ||!values.prepaymentData[ind].payment_mode
              ||!values.prepaymentData[ind].other_details
              ||!values.prepaymentData[ind].commission){
                isValid = false
            }
          }
        }
        if(isValid){
         setPrePaymentChecked(true)
         setPrepaymentData(values.prepaymentData.filter((ele,ind)=>{return selectedFaculties.includes(ele.faculty_id)}))
         setPrePayment(false)
        }
      }else{
        notify("Please select any faculty for prepayment.")
      }
    }
  })

  const handleClose = () => {
    setPrePaymentChecked(false)
    setPrePayment(false);
  };
  return (
    <div>
      <div>
          <Dialog open={prePaymentDialog} onClose={handleClose} fullScreen={true} >
            <DialogTitle className='bg-light border' >
              <Grid container justifyContent='space-between' >
                <span>Prepayment To Faculty</span>
              <Button variant="contained" color="secondary" onClick={handleClose}>Close</Button>
              </Grid>
         
            </DialogTitle>
            <form onSubmit={formik.handleSubmit}>
            <DialogContent>
            <Grid container spacing={3}>
              {data.faculties.map((facultyEle,facultyInd)=>{
                return  <Grid item xs={3}>
                        <Card >
                            <CardContent>
                              <h5><Checkbox 
                                    checked={selectedFaculties.includes(facultyEle.faculty_id)?true:false}  
                                    onChange={()=>{handleFacultySelection(facultyEle.faculty_id)}} />   
                                    Payment To {facultyEle.faculty_name} -
                              </h5>
                                  <TextField
                                    
                                    margin="dense"
                                    // id="name"
                                    name={"prepaymentData["+facultyInd+"].remark"}
                                    label="Remark"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.prepaymentData[facultyInd].remark}
                                  />
                                  {selectedFaculties.includes(facultyEle.faculty_id)&&!formik.values.prepaymentData[facultyInd].remark && <span style={{ color: "red", fontSize: "12px" }}>Required !</span>}
                                  <br /><br />
                                  <TextField
                                    
                                    margin="dense"
                                    // id="name"
                                    name={"prepaymentData["+facultyInd+"].title_deal_name"}
                                    label="Title Deal Name"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.prepaymentData[facultyInd].title_deal_name}
                                  />
                                  {selectedFaculties.includes(facultyEle.faculty_id)&&!formik.values.prepaymentData[facultyInd].title_deal_name && <span style={{ color: "red", fontSize: "12px" }}>Required !</span>}
                                  <br /><br />
                                  <TextField
                                    
                                    margin="dense"
                                    // id="name"
                                    name={"prepaymentData["+facultyInd+"].payment_amount"}
                                    label="Payment Amount"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.prepaymentData[facultyInd].payment_amount}
                                  />
                                  {selectedFaculties.includes(facultyEle.faculty_id)&&!formik.values.prepaymentData[facultyInd].payment_amount && <span style={{ color: "red", fontSize: "12px" }}>Required !</span>}
                                  <br /><br />
                                  
                                  <TextField
                                    
                                    margin="dense"
                                    // id="name"
                                    name={"prepaymentData["+facultyInd+"].payment_date"}
                                    label="Payment Date"
                                    type="date"
                                    fullWidth
                                    variant="standard"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.prepaymentData[facultyInd].payment_date}
                                    
                                  />
                                  {selectedFaculties.includes(facultyEle.faculty_id)&&!formik.values.prepaymentData[facultyInd].payment_date && <span style={{ color: "red", fontSize: "12px" }}>Required !</span>}
                                  <br /><br />
                                  <TextField
                                    
                                    margin="dense"
                                    // id="name"
                                    name={"prepaymentData["+facultyInd+"].applicable_from_date"}
                                    label="Applicable From Date"
                                    type="date"
                                    fullWidth
                                    variant="standard"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.prepaymentData[facultyInd].applicable_from_date}
                                    
                                  />
                                  {selectedFaculties.includes(facultyEle.faculty_id)&&!formik.values.prepaymentData[facultyInd].applicable_from_date && <span style={{ color: "red", fontSize: "12px" }}>Required !</span>}
                                  <br /><br />
          
                                  <TextField
                                    
                                    margin="dense"
                                    // id="name"
                                    name={"prepaymentData["+facultyInd+"].applicable_to_date"}
                                    label="Applicable To Date"
                                    type="date"
                                    fullWidth
                                    variant="standard"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.prepaymentData[facultyInd].applicable_to_date}
                                    
                                  />
                                  {selectedFaculties.includes(facultyEle.faculty_id)&&!formik.values.prepaymentData[facultyInd].applicable_to_date && <span style={{ color: "red", fontSize: "12px" }}>Required !</span>}
                                  <br /><br />
          
                                  <TextField
                                    
                                    margin="dense"
                                    // id="name"
                                    name={"prepaymentData["+facultyInd+"].payment_mode"}
                                    label="Payment Mode"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.prepaymentData[facultyInd].payment_mode}
                                  />
                                  {selectedFaculties.includes(facultyEle.faculty_id)&&!formik.values.prepaymentData[facultyInd].payment_mode && <span style={{ color: "red", fontSize: "12px" }}>Required !</span>}
                                  <br /><br />
          
                                  <TextField
                                    
                                    margin="dense"
                                    // id="name"
                                    name={"prepaymentData["+facultyInd+"].other_details"}
                                    label="Other Details"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.prepaymentData[facultyInd].other_details}
                                  />
                                  {selectedFaculties.includes(facultyEle.faculty_id)&&!formik.values.prepaymentData[facultyInd].other_details && <span style={{ color: "red", fontSize: "12px" }}>Required !</span>}
                                  <br /><br />
                                  <TextField
                                    
                                    margin="dense"
                                    // id="name"
                                    name={"prepaymentData["+facultyInd+"].commission"}
                                    label="Commission"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.prepaymentData[facultyInd].commission}
                                  />
                                  {selectedFaculties.includes(facultyEle.faculty_id)&&!formik.values.prepaymentData[facultyInd].commission && <span style={{ color: "red", fontSize: "12px" }}>Required !</span>}
                                  <br /><br />
                            </CardContent>
                        </Card>
                        </Grid>
              })}
            </Grid>  
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="secondary" onClick={handleClose}>Cancel</Button>
              <Button
                type="submit"
                style={{ marginLeft: 20 }}
                variant="contained"
                color="primary"
              > Save</Button>
            </DialogActions>
            </form>

          </Dialog>


      </div>
    </div>
  )
}

export default EditPrePayment
